// source: map/proto/map.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var geometry_proto_transform_pb = require('../../geometry/proto/transform_pb.js');
goog.object.extend(proto, geometry_proto_transform_pb);
var detection_proto_detection_pb = require('../../detection/proto/detection_pb.js');
goog.object.extend(proto, detection_proto_detection_pb);
var planner_proto_types_pb = require('../../planner/proto/types_pb.js');
goog.object.extend(proto, planner_proto_types_pb);
goog.exportSymbol('proto.fox.proto.Color', null, global);
goog.exportSymbol('proto.fox.proto.DelocalizationDetectorState', null, global);
goog.exportSymbol('proto.fox.proto.DynamicObstacle', null, global);
goog.exportSymbol('proto.fox.proto.Grid', null, global);
goog.exportSymbol('proto.fox.proto.GridMap', null, global);
goog.exportSymbol('proto.fox.proto.GridMap.DataCase', null, global);
goog.exportSymbol('proto.fox.proto.LocalizationError', null, global);
goog.exportSymbol('proto.fox.proto.LocalizationError.Code', null, global);
goog.exportSymbol('proto.fox.proto.LocalizeMap', null, global);
goog.exportSymbol('proto.fox.proto.LocalizeState', null, global);
goog.exportSymbol('proto.fox.proto.LocalizeState.LocalizeLoopStatus', null, global);
goog.exportSymbol('proto.fox.proto.Map', null, global);
goog.exportSymbol('proto.fox.proto.MapTOdomHistory', null, global);
goog.exportSymbol('proto.fox.proto.PalletsSnapshot', null, global);
goog.exportSymbol('proto.fox.proto.Particle', null, global);
goog.exportSymbol('proto.fox.proto.ParticleFilterConfig', null, global);
goog.exportSymbol('proto.fox.proto.ParticleFilterState', null, global);
goog.exportSymbol('proto.fox.proto.PoseScan', null, global);
goog.exportSymbol('proto.fox.proto.Slot', null, global);
goog.exportSymbol('proto.fox.proto.Slots', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PoseScan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.PoseScan.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.PoseScan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PoseScan.displayName = 'proto.fox.proto.PoseScan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PalletsSnapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.PalletsSnapshot.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.PalletsSnapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PalletsSnapshot.displayName = 'proto.fox.proto.PalletsSnapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LocalizeMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.LocalizeMap.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.LocalizeMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LocalizeMap.displayName = 'proto.fox.proto.LocalizeMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LocalizationError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.LocalizationError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LocalizationError.displayName = 'proto.fox.proto.LocalizationError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LocalizeState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.LocalizeState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LocalizeState.displayName = 'proto.fox.proto.LocalizeState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.DelocalizationDetectorState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.DelocalizationDetectorState.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.DelocalizationDetectorState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.DelocalizationDetectorState.displayName = 'proto.fox.proto.DelocalizationDetectorState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.MapTOdomHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.MapTOdomHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.MapTOdomHistory.displayName = 'proto.fox.proto.MapTOdomHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Particle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.Particle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Particle.displayName = 'proto.fox.proto.Particle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ParticleFilterState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.ParticleFilterState.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.ParticleFilterState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ParticleFilterState.displayName = 'proto.fox.proto.ParticleFilterState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Color = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.Color, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Color.displayName = 'proto.fox.proto.Color';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Grid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.Grid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Grid.displayName = 'proto.fox.proto.Grid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.GridMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fox.proto.GridMap.oneofGroups_);
};
goog.inherits(proto.fox.proto.GridMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.GridMap.displayName = 'proto.fox.proto.GridMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.DynamicObstacle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.DynamicObstacle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.DynamicObstacle.displayName = 'proto.fox.proto.DynamicObstacle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Slot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.Slot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Slot.displayName = 'proto.fox.proto.Slot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Slots = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.Slots.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.Slots, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Slots.displayName = 'proto.fox.proto.Slots';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Map = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.Map, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Map.displayName = 'proto.fox.proto.Map';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ParticleFilterConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ParticleFilterConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ParticleFilterConfig.displayName = 'proto.fox.proto.ParticleFilterConfig';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.PoseScan.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PoseScan.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PoseScan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PoseScan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PoseScan.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapTRobot: (f = msg.getMapTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    scanMapList: jspb.Message.toObjectList(msg.getScanMapList(),
    geometry_proto_transform_pb.Vector2f.toObject, includeInstance),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PoseScan}
 */
proto.fox.proto.PoseScan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PoseScan;
  return proto.fox.proto.PoseScan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PoseScan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PoseScan}
 */
proto.fox.proto.PoseScan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTRobot(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Vector2f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2f.deserializeBinaryFromReader);
      msg.addScanMap(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PoseScan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PoseScan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PoseScan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PoseScan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapTRobot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getScanMapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      geometry_proto_transform_pb.Vector2f.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional Transform2f map_t_robot = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.PoseScan.prototype.getMapTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.PoseScan} returns this
*/
proto.fox.proto.PoseScan.prototype.setMapTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PoseScan} returns this
 */
proto.fox.proto.PoseScan.prototype.clearMapTRobot = function() {
  return this.setMapTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PoseScan.prototype.hasMapTRobot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Vector2f scan_map = 2;
 * @return {!Array<!proto.fox.proto.Vector2f>}
 */
proto.fox.proto.PoseScan.prototype.getScanMapList = function() {
  return /** @type{!Array<!proto.fox.proto.Vector2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Vector2f, 2));
};


/**
 * @param {!Array<!proto.fox.proto.Vector2f>} value
 * @return {!proto.fox.proto.PoseScan} returns this
*/
proto.fox.proto.PoseScan.prototype.setScanMapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.Vector2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Vector2f}
 */
proto.fox.proto.PoseScan.prototype.addScanMap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.Vector2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PoseScan} returns this
 */
proto.fox.proto.PoseScan.prototype.clearScanMapList = function() {
  return this.setScanMapList([]);
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.fox.proto.PoseScan.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PoseScan} returns this
 */
proto.fox.proto.PoseScan.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 zone_id = 4;
 * @return {number}
 */
proto.fox.proto.PoseScan.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PoseScan} returns this
 */
proto.fox.proto.PoseScan.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.PalletsSnapshot.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PalletsSnapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PalletsSnapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PalletsSnapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PalletsSnapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    palletsList: jspb.Message.toObjectList(msg.getPalletsList(),
    detection_proto_detection_pb.TrackedPallet.toObject, includeInstance),
    time: jspb.Message.getFieldWithDefault(msg, 2, 0),
    zoneId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PalletsSnapshot}
 */
proto.fox.proto.PalletsSnapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PalletsSnapshot;
  return proto.fox.proto.PalletsSnapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PalletsSnapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PalletsSnapshot}
 */
proto.fox.proto.PalletsSnapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new detection_proto_detection_pb.TrackedPallet;
      reader.readMessage(value,detection_proto_detection_pb.TrackedPallet.deserializeBinaryFromReader);
      msg.addPallets(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PalletsSnapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PalletsSnapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PalletsSnapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PalletsSnapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPalletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      detection_proto_detection_pb.TrackedPallet.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated TrackedPallet pallets = 1;
 * @return {!Array<!proto.fox.proto.TrackedPallet>}
 */
proto.fox.proto.PalletsSnapshot.prototype.getPalletsList = function() {
  return /** @type{!Array<!proto.fox.proto.TrackedPallet>} */ (
    jspb.Message.getRepeatedWrapperField(this, detection_proto_detection_pb.TrackedPallet, 1));
};


/**
 * @param {!Array<!proto.fox.proto.TrackedPallet>} value
 * @return {!proto.fox.proto.PalletsSnapshot} returns this
*/
proto.fox.proto.PalletsSnapshot.prototype.setPalletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.TrackedPallet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TrackedPallet}
 */
proto.fox.proto.PalletsSnapshot.prototype.addPallets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.TrackedPallet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PalletsSnapshot} returns this
 */
proto.fox.proto.PalletsSnapshot.prototype.clearPalletsList = function() {
  return this.setPalletsList([]);
};


/**
 * optional int64 time = 2;
 * @return {number}
 */
proto.fox.proto.PalletsSnapshot.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PalletsSnapshot} returns this
 */
proto.fox.proto.PalletsSnapshot.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 zone_id = 3;
 * @return {number}
 */
proto.fox.proto.PalletsSnapshot.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PalletsSnapshot} returns this
 */
proto.fox.proto.PalletsSnapshot.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.LocalizeMap.repeatedFields_ = [1,2,3,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LocalizeMap.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LocalizeMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LocalizeMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LocalizeMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    legacyPoseScansList: jspb.Message.toObjectList(msg.getLegacyPoseScansList(),
    proto.fox.proto.PoseScan.toObject, includeInstance),
    truckMapList: jspb.Message.toObjectList(msg.getTruckMapList(),
    geometry_proto_transform_pb.Vector2f.toObject, includeInstance),
    palletsList: jspb.Message.toObjectList(msg.getPalletsList(),
    detection_proto_detection_pb.TrackedPallet.toObject, includeInstance),
    palletUpdateTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gridTWorld: (f = msg.getGridTWorld()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    palletsSnapshotsList: jspb.Message.toObjectList(msg.getPalletsSnapshotsList(),
    proto.fox.proto.PalletsSnapshot.toObject, includeInstance),
    zoneIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    poseScansList: jspb.Message.toObjectList(msg.getPoseScansList(),
    planner_proto_types_pb.RawPoseScan.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LocalizeMap}
 */
proto.fox.proto.LocalizeMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LocalizeMap;
  return proto.fox.proto.LocalizeMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LocalizeMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LocalizeMap}
 */
proto.fox.proto.LocalizeMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.PoseScan;
      reader.readMessage(value,proto.fox.proto.PoseScan.deserializeBinaryFromReader);
      msg.addLegacyPoseScans(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Vector2f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2f.deserializeBinaryFromReader);
      msg.addTruckMap(value);
      break;
    case 3:
      var value = new detection_proto_detection_pb.TrackedPallet;
      reader.readMessage(value,detection_proto_detection_pb.TrackedPallet.deserializeBinaryFromReader);
      msg.addPallets(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPalletUpdateTime(value);
      break;
    case 5:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setGridTWorld(value);
      break;
    case 6:
      var value = new proto.fox.proto.PalletsSnapshot;
      reader.readMessage(value,proto.fox.proto.PalletsSnapshot.deserializeBinaryFromReader);
      msg.addPalletsSnapshots(value);
      break;
    case 7:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setZoneIdsList(value);
      break;
    case 8:
      var value = new planner_proto_types_pb.RawPoseScan;
      reader.readMessage(value,planner_proto_types_pb.RawPoseScan.deserializeBinaryFromReader);
      msg.addPoseScans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LocalizeMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LocalizeMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LocalizeMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LocalizeMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLegacyPoseScansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.PoseScan.serializeBinaryToWriter
    );
  }
  f = message.getTruckMapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      geometry_proto_transform_pb.Vector2f.serializeBinaryToWriter
    );
  }
  f = message.getPalletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      detection_proto_detection_pb.TrackedPallet.serializeBinaryToWriter
    );
  }
  f = message.getPalletUpdateTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getGridTWorld();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getPalletsSnapshotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.fox.proto.PalletsSnapshot.serializeBinaryToWriter
    );
  }
  f = message.getZoneIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
  f = message.getPoseScansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      planner_proto_types_pb.RawPoseScan.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PoseScan legacy_pose_scans = 1;
 * @return {!Array<!proto.fox.proto.PoseScan>}
 */
proto.fox.proto.LocalizeMap.prototype.getLegacyPoseScansList = function() {
  return /** @type{!Array<!proto.fox.proto.PoseScan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.PoseScan, 1));
};


/**
 * @param {!Array<!proto.fox.proto.PoseScan>} value
 * @return {!proto.fox.proto.LocalizeMap} returns this
*/
proto.fox.proto.LocalizeMap.prototype.setLegacyPoseScansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.PoseScan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PoseScan}
 */
proto.fox.proto.LocalizeMap.prototype.addLegacyPoseScans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.PoseScan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LocalizeMap} returns this
 */
proto.fox.proto.LocalizeMap.prototype.clearLegacyPoseScansList = function() {
  return this.setLegacyPoseScansList([]);
};


/**
 * repeated Vector2f truck_map = 2;
 * @return {!Array<!proto.fox.proto.Vector2f>}
 */
proto.fox.proto.LocalizeMap.prototype.getTruckMapList = function() {
  return /** @type{!Array<!proto.fox.proto.Vector2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Vector2f, 2));
};


/**
 * @param {!Array<!proto.fox.proto.Vector2f>} value
 * @return {!proto.fox.proto.LocalizeMap} returns this
*/
proto.fox.proto.LocalizeMap.prototype.setTruckMapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.Vector2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Vector2f}
 */
proto.fox.proto.LocalizeMap.prototype.addTruckMap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.Vector2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LocalizeMap} returns this
 */
proto.fox.proto.LocalizeMap.prototype.clearTruckMapList = function() {
  return this.setTruckMapList([]);
};


/**
 * repeated TrackedPallet pallets = 3;
 * @return {!Array<!proto.fox.proto.TrackedPallet>}
 */
proto.fox.proto.LocalizeMap.prototype.getPalletsList = function() {
  return /** @type{!Array<!proto.fox.proto.TrackedPallet>} */ (
    jspb.Message.getRepeatedWrapperField(this, detection_proto_detection_pb.TrackedPallet, 3));
};


/**
 * @param {!Array<!proto.fox.proto.TrackedPallet>} value
 * @return {!proto.fox.proto.LocalizeMap} returns this
*/
proto.fox.proto.LocalizeMap.prototype.setPalletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.TrackedPallet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TrackedPallet}
 */
proto.fox.proto.LocalizeMap.prototype.addPallets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.TrackedPallet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LocalizeMap} returns this
 */
proto.fox.proto.LocalizeMap.prototype.clearPalletsList = function() {
  return this.setPalletsList([]);
};


/**
 * optional int64 pallet_update_time = 4;
 * @return {number}
 */
proto.fox.proto.LocalizeMap.prototype.getPalletUpdateTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LocalizeMap} returns this
 */
proto.fox.proto.LocalizeMap.prototype.setPalletUpdateTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Transform2f grid_t_world = 5;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.LocalizeMap.prototype.getGridTWorld = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 5));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.LocalizeMap} returns this
*/
proto.fox.proto.LocalizeMap.prototype.setGridTWorld = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.LocalizeMap} returns this
 */
proto.fox.proto.LocalizeMap.prototype.clearGridTWorld = function() {
  return this.setGridTWorld(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.LocalizeMap.prototype.hasGridTWorld = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated PalletsSnapshot pallets_snapshots = 6;
 * @return {!Array<!proto.fox.proto.PalletsSnapshot>}
 */
proto.fox.proto.LocalizeMap.prototype.getPalletsSnapshotsList = function() {
  return /** @type{!Array<!proto.fox.proto.PalletsSnapshot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.PalletsSnapshot, 6));
};


/**
 * @param {!Array<!proto.fox.proto.PalletsSnapshot>} value
 * @return {!proto.fox.proto.LocalizeMap} returns this
*/
proto.fox.proto.LocalizeMap.prototype.setPalletsSnapshotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.fox.proto.PalletsSnapshot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PalletsSnapshot}
 */
proto.fox.proto.LocalizeMap.prototype.addPalletsSnapshots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.fox.proto.PalletsSnapshot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LocalizeMap} returns this
 */
proto.fox.proto.LocalizeMap.prototype.clearPalletsSnapshotsList = function() {
  return this.setPalletsSnapshotsList([]);
};


/**
 * repeated int64 zone_ids = 7;
 * @return {!Array<number>}
 */
proto.fox.proto.LocalizeMap.prototype.getZoneIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LocalizeMap} returns this
 */
proto.fox.proto.LocalizeMap.prototype.setZoneIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LocalizeMap} returns this
 */
proto.fox.proto.LocalizeMap.prototype.addZoneIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LocalizeMap} returns this
 */
proto.fox.proto.LocalizeMap.prototype.clearZoneIdsList = function() {
  return this.setZoneIdsList([]);
};


/**
 * repeated RawPoseScan pose_scans = 8;
 * @return {!Array<!proto.fox.proto.RawPoseScan>}
 */
proto.fox.proto.LocalizeMap.prototype.getPoseScansList = function() {
  return /** @type{!Array<!proto.fox.proto.RawPoseScan>} */ (
    jspb.Message.getRepeatedWrapperField(this, planner_proto_types_pb.RawPoseScan, 8));
};


/**
 * @param {!Array<!proto.fox.proto.RawPoseScan>} value
 * @return {!proto.fox.proto.LocalizeMap} returns this
*/
proto.fox.proto.LocalizeMap.prototype.setPoseScansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.fox.proto.RawPoseScan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.RawPoseScan}
 */
proto.fox.proto.LocalizeMap.prototype.addPoseScans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.fox.proto.RawPoseScan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LocalizeMap} returns this
 */
proto.fox.proto.LocalizeMap.prototype.clearPoseScansList = function() {
  return this.setPoseScansList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LocalizationError.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LocalizationError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LocalizationError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LocalizationError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LocalizationError}
 */
proto.fox.proto.LocalizationError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LocalizationError;
  return proto.fox.proto.LocalizationError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LocalizationError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LocalizationError}
 */
proto.fox.proto.LocalizationError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.LocalizationError.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LocalizationError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LocalizationError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LocalizationError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LocalizationError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.LocalizationError.Code = {
  NONE: 0,
  FIRST_SCAN_BLOCKED: 1,
  LIKELY_DELOCALIZED: 2
};

/**
 * optional Code code = 1;
 * @return {!proto.fox.proto.LocalizationError.Code}
 */
proto.fox.proto.LocalizationError.prototype.getCode = function() {
  return /** @type {!proto.fox.proto.LocalizationError.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.LocalizationError.Code} value
 * @return {!proto.fox.proto.LocalizationError} returns this
 */
proto.fox.proto.LocalizationError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LocalizeState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LocalizeState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LocalizeState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LocalizeState.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    particleFilter: (f = msg.getParticleFilter()) && proto.fox.proto.ParticleFilterState.toObject(includeInstance, f),
    lastSensorFrameTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastOdomTRobot: (f = msg.getLastOdomTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LocalizeState}
 */
proto.fox.proto.LocalizeState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LocalizeState;
  return proto.fox.proto.LocalizeState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LocalizeState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LocalizeState}
 */
proto.fox.proto.LocalizeState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.LocalizeState.LocalizeLoopStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = new proto.fox.proto.ParticleFilterState;
      reader.readMessage(value,proto.fox.proto.ParticleFilterState.deserializeBinaryFromReader);
      msg.setParticleFilter(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSensorFrameTime(value);
      break;
    case 4:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setLastOdomTRobot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LocalizeState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LocalizeState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LocalizeState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LocalizeState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getParticleFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.ParticleFilterState.serializeBinaryToWriter
    );
  }
  f = message.getLastSensorFrameTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLastOdomTRobot();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.LocalizeState.LocalizeLoopStatus = {
  UNINITIALIZED: 0,
  RUNNING: 1,
  DELOCALIZED: 2
};

/**
 * optional LocalizeLoopStatus status = 1;
 * @return {!proto.fox.proto.LocalizeState.LocalizeLoopStatus}
 */
proto.fox.proto.LocalizeState.prototype.getStatus = function() {
  return /** @type {!proto.fox.proto.LocalizeState.LocalizeLoopStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.LocalizeState.LocalizeLoopStatus} value
 * @return {!proto.fox.proto.LocalizeState} returns this
 */
proto.fox.proto.LocalizeState.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ParticleFilterState particle_filter = 2;
 * @return {?proto.fox.proto.ParticleFilterState}
 */
proto.fox.proto.LocalizeState.prototype.getParticleFilter = function() {
  return /** @type{?proto.fox.proto.ParticleFilterState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ParticleFilterState, 2));
};


/**
 * @param {?proto.fox.proto.ParticleFilterState|undefined} value
 * @return {!proto.fox.proto.LocalizeState} returns this
*/
proto.fox.proto.LocalizeState.prototype.setParticleFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.LocalizeState} returns this
 */
proto.fox.proto.LocalizeState.prototype.clearParticleFilter = function() {
  return this.setParticleFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.LocalizeState.prototype.hasParticleFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 last_sensor_frame_time = 3;
 * @return {number}
 */
proto.fox.proto.LocalizeState.prototype.getLastSensorFrameTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LocalizeState} returns this
 */
proto.fox.proto.LocalizeState.prototype.setLastSensorFrameTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Transform2f last_odom_t_robot = 4;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.LocalizeState.prototype.getLastOdomTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 4));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.LocalizeState} returns this
*/
proto.fox.proto.LocalizeState.prototype.setLastOdomTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.LocalizeState} returns this
 */
proto.fox.proto.LocalizeState.prototype.clearLastOdomTRobot = function() {
  return this.setLastOdomTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.LocalizeState.prototype.hasLastOdomTRobot = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.DelocalizationDetectorState.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.DelocalizationDetectorState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.DelocalizationDetectorState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.DelocalizationDetectorState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DelocalizationDetectorState.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapTOdomHistoryList: jspb.Message.toObjectList(msg.getMapTOdomHistoryList(),
    proto.fox.proto.MapTOdomHistory.toObject, includeInstance),
    delocalizationStartTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    confirmedDelocalized: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.DelocalizationDetectorState}
 */
proto.fox.proto.DelocalizationDetectorState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.DelocalizationDetectorState;
  return proto.fox.proto.DelocalizationDetectorState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.DelocalizationDetectorState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.DelocalizationDetectorState}
 */
proto.fox.proto.DelocalizationDetectorState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.MapTOdomHistory;
      reader.readMessage(value,proto.fox.proto.MapTOdomHistory.deserializeBinaryFromReader);
      msg.addMapTOdomHistory(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDelocalizationStartTime(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirmedDelocalized(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.DelocalizationDetectorState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.DelocalizationDetectorState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.DelocalizationDetectorState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DelocalizationDetectorState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapTOdomHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.MapTOdomHistory.serializeBinaryToWriter
    );
  }
  f = message.getDelocalizationStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getConfirmedDelocalized();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated MapTOdomHistory map_t_odom_history = 1;
 * @return {!Array<!proto.fox.proto.MapTOdomHistory>}
 */
proto.fox.proto.DelocalizationDetectorState.prototype.getMapTOdomHistoryList = function() {
  return /** @type{!Array<!proto.fox.proto.MapTOdomHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.MapTOdomHistory, 1));
};


/**
 * @param {!Array<!proto.fox.proto.MapTOdomHistory>} value
 * @return {!proto.fox.proto.DelocalizationDetectorState} returns this
*/
proto.fox.proto.DelocalizationDetectorState.prototype.setMapTOdomHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.MapTOdomHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.MapTOdomHistory}
 */
proto.fox.proto.DelocalizationDetectorState.prototype.addMapTOdomHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.MapTOdomHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.DelocalizationDetectorState} returns this
 */
proto.fox.proto.DelocalizationDetectorState.prototype.clearMapTOdomHistoryList = function() {
  return this.setMapTOdomHistoryList([]);
};


/**
 * optional int64 delocalization_start_time = 2;
 * @return {number}
 */
proto.fox.proto.DelocalizationDetectorState.prototype.getDelocalizationStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.DelocalizationDetectorState} returns this
 */
proto.fox.proto.DelocalizationDetectorState.prototype.setDelocalizationStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool confirmed_delocalized = 3;
 * @return {boolean}
 */
proto.fox.proto.DelocalizationDetectorState.prototype.getConfirmedDelocalized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.DelocalizationDetectorState} returns this
 */
proto.fox.proto.DelocalizationDetectorState.prototype.setConfirmedDelocalized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.MapTOdomHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.MapTOdomHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.MapTOdomHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MapTOdomHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapTOdom: (f = msg.getMapTOdom()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.MapTOdomHistory}
 */
proto.fox.proto.MapTOdomHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.MapTOdomHistory;
  return proto.fox.proto.MapTOdomHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.MapTOdomHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.MapTOdomHistory}
 */
proto.fox.proto.MapTOdomHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTOdom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.MapTOdomHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.MapTOdomHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.MapTOdomHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MapTOdomHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapTOdom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional Transform2f map_t_odom = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.MapTOdomHistory.prototype.getMapTOdom = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.MapTOdomHistory} returns this
*/
proto.fox.proto.MapTOdomHistory.prototype.setMapTOdom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MapTOdomHistory} returns this
 */
proto.fox.proto.MapTOdomHistory.prototype.clearMapTOdom = function() {
  return this.setMapTOdom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MapTOdomHistory.prototype.hasMapTOdom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.fox.proto.MapTOdomHistory.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MapTOdomHistory} returns this
 */
proto.fox.proto.MapTOdomHistory.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Particle.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Particle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Particle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Particle.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapTRobot: (f = msg.getMapTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Particle}
 */
proto.fox.proto.Particle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Particle;
  return proto.fox.proto.Particle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Particle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Particle}
 */
proto.fox.proto.Particle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTRobot(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Particle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Particle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Particle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Particle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapTRobot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getWeight();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional Transform2f map_t_robot = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.Particle.prototype.getMapTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.Particle} returns this
*/
proto.fox.proto.Particle.prototype.setMapTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Particle} returns this
 */
proto.fox.proto.Particle.prototype.clearMapTRobot = function() {
  return this.setMapTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Particle.prototype.hasMapTRobot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double weight = 2;
 * @return {number}
 */
proto.fox.proto.Particle.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Particle} returns this
 */
proto.fox.proto.Particle.prototype.setWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.ParticleFilterState.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ParticleFilterState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ParticleFilterState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ParticleFilterState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ParticleFilterState.toObject = function(includeInstance, msg) {
  var f, obj = {
    particlesList: jspb.Message.toObjectList(msg.getParticlesList(),
    proto.fox.proto.Particle.toObject, includeInstance),
    odomTRobot: (f = msg.getOdomTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    odomTResample: (f = msg.getOdomTResample()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    breadcrumbsList: jspb.Message.toObjectList(msg.getBreadcrumbsList(),
    geometry_proto_transform_pb.Transform2f.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ParticleFilterState}
 */
proto.fox.proto.ParticleFilterState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ParticleFilterState;
  return proto.fox.proto.ParticleFilterState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ParticleFilterState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ParticleFilterState}
 */
proto.fox.proto.ParticleFilterState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.Particle;
      reader.readMessage(value,proto.fox.proto.Particle.deserializeBinaryFromReader);
      msg.addParticles(value);
      break;
    case 3:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setOdomTRobot(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setOdomTResample(value);
      break;
    case 4:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.addBreadcrumbs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ParticleFilterState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ParticleFilterState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ParticleFilterState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ParticleFilterState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParticlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.Particle.serializeBinaryToWriter
    );
  }
  f = message.getOdomTRobot();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getOdomTResample();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getBreadcrumbsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Particle particles = 1;
 * @return {!Array<!proto.fox.proto.Particle>}
 */
proto.fox.proto.ParticleFilterState.prototype.getParticlesList = function() {
  return /** @type{!Array<!proto.fox.proto.Particle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.Particle, 1));
};


/**
 * @param {!Array<!proto.fox.proto.Particle>} value
 * @return {!proto.fox.proto.ParticleFilterState} returns this
*/
proto.fox.proto.ParticleFilterState.prototype.setParticlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.Particle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Particle}
 */
proto.fox.proto.ParticleFilterState.prototype.addParticles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.Particle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ParticleFilterState} returns this
 */
proto.fox.proto.ParticleFilterState.prototype.clearParticlesList = function() {
  return this.setParticlesList([]);
};


/**
 * optional Transform2f odom_t_robot = 3;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.ParticleFilterState.prototype.getOdomTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 3));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.ParticleFilterState} returns this
*/
proto.fox.proto.ParticleFilterState.prototype.setOdomTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ParticleFilterState} returns this
 */
proto.fox.proto.ParticleFilterState.prototype.clearOdomTRobot = function() {
  return this.setOdomTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ParticleFilterState.prototype.hasOdomTRobot = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Transform2f odom_t_resample = 2;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.ParticleFilterState.prototype.getOdomTResample = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 2));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.ParticleFilterState} returns this
*/
proto.fox.proto.ParticleFilterState.prototype.setOdomTResample = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ParticleFilterState} returns this
 */
proto.fox.proto.ParticleFilterState.prototype.clearOdomTResample = function() {
  return this.setOdomTResample(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ParticleFilterState.prototype.hasOdomTResample = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Transform2f breadcrumbs = 4;
 * @return {!Array<!proto.fox.proto.Transform2f>}
 */
proto.fox.proto.ParticleFilterState.prototype.getBreadcrumbsList = function() {
  return /** @type{!Array<!proto.fox.proto.Transform2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Transform2f, 4));
};


/**
 * @param {!Array<!proto.fox.proto.Transform2f>} value
 * @return {!proto.fox.proto.ParticleFilterState} returns this
*/
proto.fox.proto.ParticleFilterState.prototype.setBreadcrumbsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.fox.proto.Transform2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Transform2f}
 */
proto.fox.proto.ParticleFilterState.prototype.addBreadcrumbs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.fox.proto.Transform2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ParticleFilterState} returns this
 */
proto.fox.proto.ParticleFilterState.prototype.clearBreadcrumbsList = function() {
  return this.setBreadcrumbsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Color.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Color.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Color} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Color.toObject = function(includeInstance, msg) {
  var f, obj = {
    red: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    green: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    blue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    alpha: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Color}
 */
proto.fox.proto.Color.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Color;
  return proto.fox.proto.Color.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Color} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Color}
 */
proto.fox.proto.Color.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRed(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGreen(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBlue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAlpha(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Color.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Color.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Color} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Color.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRed();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getGreen();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAlpha();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float red = 1;
 * @return {number}
 */
proto.fox.proto.Color.prototype.getRed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Color} returns this
 */
proto.fox.proto.Color.prototype.setRed = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float green = 2;
 * @return {number}
 */
proto.fox.proto.Color.prototype.getGreen = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Color} returns this
 */
proto.fox.proto.Color.prototype.setGreen = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float blue = 3;
 * @return {number}
 */
proto.fox.proto.Color.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Color} returns this
 */
proto.fox.proto.Color.prototype.setBlue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float alpha = 4;
 * @return {number}
 */
proto.fox.proto.Color.prototype.getAlpha = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Color} returns this
 */
proto.fox.proto.Color.prototype.setAlpha = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Grid.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Grid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Grid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Grid.toObject = function(includeInstance, msg) {
  var f, obj = {
    cells: msg.getCells_asB64(),
    height: jspb.Message.getFieldWithDefault(msg, 2, 0),
    width: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Grid}
 */
proto.fox.proto.Grid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Grid;
  return proto.fox.proto.Grid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Grid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Grid}
 */
proto.fox.proto.Grid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCells(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Grid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Grid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Grid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Grid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCells_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bytes cells = 1;
 * @return {!(string|Uint8Array)}
 */
proto.fox.proto.Grid.prototype.getCells = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes cells = 1;
 * This is a type-conversion wrapper around `getCells()`
 * @return {string}
 */
proto.fox.proto.Grid.prototype.getCells_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCells()));
};


/**
 * optional bytes cells = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCells()`
 * @return {!Uint8Array}
 */
proto.fox.proto.Grid.prototype.getCells_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCells()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.fox.proto.Grid} returns this
 */
proto.fox.proto.Grid.prototype.setCells = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional int32 height = 2;
 * @return {number}
 */
proto.fox.proto.Grid.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Grid} returns this
 */
proto.fox.proto.Grid.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 width = 3;
 * @return {number}
 */
proto.fox.proto.Grid.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Grid} returns this
 */
proto.fox.proto.Grid.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fox.proto.GridMap.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.fox.proto.GridMap.DataCase = {
  DATA_NOT_SET: 0,
  GRID: 2,
  PATH: 3
};

/**
 * @return {proto.fox.proto.GridMap.DataCase}
 */
proto.fox.proto.GridMap.prototype.getDataCase = function() {
  return /** @type {proto.fox.proto.GridMap.DataCase} */(jspb.Message.computeOneofCase(this, proto.fox.proto.GridMap.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.GridMap.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.GridMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.GridMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.GridMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    origin: (f = msg.getOrigin()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    grid: (f = msg.getGrid()) && proto.fox.proto.Grid.toObject(includeInstance, f),
    path: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.GridMap}
 */
proto.fox.proto.GridMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.GridMap;
  return proto.fox.proto.GridMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.GridMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.GridMap}
 */
proto.fox.proto.GridMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setOrigin(value);
      break;
    case 2:
      var value = new proto.fox.proto.Grid;
      reader.readMessage(value,proto.fox.proto.Grid.deserializeBinaryFromReader);
      msg.setGrid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.GridMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.GridMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.GridMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.GridMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrigin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getGrid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.Grid.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Transform2f origin = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.GridMap.prototype.getOrigin = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.GridMap} returns this
*/
proto.fox.proto.GridMap.prototype.setOrigin = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.GridMap} returns this
 */
proto.fox.proto.GridMap.prototype.clearOrigin = function() {
  return this.setOrigin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.GridMap.prototype.hasOrigin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Grid grid = 2;
 * @return {?proto.fox.proto.Grid}
 */
proto.fox.proto.GridMap.prototype.getGrid = function() {
  return /** @type{?proto.fox.proto.Grid} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.Grid, 2));
};


/**
 * @param {?proto.fox.proto.Grid|undefined} value
 * @return {!proto.fox.proto.GridMap} returns this
*/
proto.fox.proto.GridMap.prototype.setGrid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.fox.proto.GridMap.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.GridMap} returns this
 */
proto.fox.proto.GridMap.prototype.clearGrid = function() {
  return this.setGrid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.GridMap.prototype.hasGrid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string path = 3;
 * @return {string}
 */
proto.fox.proto.GridMap.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.GridMap} returns this
 */
proto.fox.proto.GridMap.prototype.setPath = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.fox.proto.GridMap.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.GridMap} returns this
 */
proto.fox.proto.GridMap.prototype.clearPath = function() {
  return jspb.Message.setOneofField(this, 3, proto.fox.proto.GridMap.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.GridMap.prototype.hasPath = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.DynamicObstacle.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.DynamicObstacle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.DynamicObstacle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DynamicObstacle.toObject = function(includeInstance, msg) {
  var f, obj = {
    pose: (f = msg.getPose()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.DynamicObstacle}
 */
proto.fox.proto.DynamicObstacle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.DynamicObstacle;
  return proto.fox.proto.DynamicObstacle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.DynamicObstacle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.DynamicObstacle}
 */
proto.fox.proto.DynamicObstacle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setPose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.DynamicObstacle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.DynamicObstacle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.DynamicObstacle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DynamicObstacle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPose();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
};


/**
 * optional Transform2f pose = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.DynamicObstacle.prototype.getPose = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.DynamicObstacle} returns this
*/
proto.fox.proto.DynamicObstacle.prototype.setPose = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.DynamicObstacle} returns this
 */
proto.fox.proto.DynamicObstacle.prototype.clearPose = function() {
  return this.setPose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.DynamicObstacle.prototype.hasPose = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Slot.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Slot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Slot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Slot.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pose: (f = msg.getPose()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    color: (f = msg.getColor()) && proto.fox.proto.Color.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Slot}
 */
proto.fox.proto.Slot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Slot;
  return proto.fox.proto.Slot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Slot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Slot}
 */
proto.fox.proto.Slot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setPose(value);
      break;
    case 3:
      var value = new proto.fox.proto.Color;
      reader.readMessage(value,proto.fox.proto.Color.deserializeBinaryFromReader);
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Slot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Slot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Slot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Slot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPose();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getColor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.Color.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.fox.proto.Slot.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.Slot} returns this
 */
proto.fox.proto.Slot.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Transform2f pose = 2;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.Slot.prototype.getPose = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 2));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.Slot} returns this
*/
proto.fox.proto.Slot.prototype.setPose = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Slot} returns this
 */
proto.fox.proto.Slot.prototype.clearPose = function() {
  return this.setPose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Slot.prototype.hasPose = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Color color = 3;
 * @return {?proto.fox.proto.Color}
 */
proto.fox.proto.Slot.prototype.getColor = function() {
  return /** @type{?proto.fox.proto.Color} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.Color, 3));
};


/**
 * @param {?proto.fox.proto.Color|undefined} value
 * @return {!proto.fox.proto.Slot} returns this
*/
proto.fox.proto.Slot.prototype.setColor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Slot} returns this
 */
proto.fox.proto.Slot.prototype.clearColor = function() {
  return this.setColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Slot.prototype.hasColor = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.Slots.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Slots.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Slots.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Slots} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Slots.toObject = function(includeInstance, msg) {
  var f, obj = {
    slotList: jspb.Message.toObjectList(msg.getSlotList(),
    proto.fox.proto.Slot.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Slots}
 */
proto.fox.proto.Slots.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Slots;
  return proto.fox.proto.Slots.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Slots} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Slots}
 */
proto.fox.proto.Slots.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.Slot;
      reader.readMessage(value,proto.fox.proto.Slot.deserializeBinaryFromReader);
      msg.addSlot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Slots.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Slots.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Slots} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Slots.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlotList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.Slot.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Slot slot = 1;
 * @return {!Array<!proto.fox.proto.Slot>}
 */
proto.fox.proto.Slots.prototype.getSlotList = function() {
  return /** @type{!Array<!proto.fox.proto.Slot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.Slot, 1));
};


/**
 * @param {!Array<!proto.fox.proto.Slot>} value
 * @return {!proto.fox.proto.Slots} returns this
*/
proto.fox.proto.Slots.prototype.setSlotList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.Slot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Slot}
 */
proto.fox.proto.Slots.prototype.addSlot = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.Slot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.Slots} returns this
 */
proto.fox.proto.Slots.prototype.clearSlotList = function() {
  return this.setSlotList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Map.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Map.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Map} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Map.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Map}
 */
proto.fox.proto.Map.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Map;
  return proto.fox.proto.Map.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Map} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Map}
 */
proto.fox.proto.Map.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Map.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Map.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Map} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Map.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ParticleFilterConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ParticleFilterConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ParticleFilterConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ParticleFilterConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    numParticles: jspb.Message.getFieldWithDefault(msg, 1, 0),
    laserSigma: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    measurementSigma: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    truckDetectionSigma: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    motionTranslationSigma: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    motionDirectionSigma: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    motionRotationSigma: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    motionSteeringSigma: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    motionSpeedPercentSigma: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    truckAxisSigma: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    pointWeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    maxDistancePenalty: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    subsample: jspb.Message.getFieldWithDefault(msg, 13, 0),
    truckAxisDeadReckonSigma: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ParticleFilterConfig}
 */
proto.fox.proto.ParticleFilterConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ParticleFilterConfig;
  return proto.fox.proto.ParticleFilterConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ParticleFilterConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ParticleFilterConfig}
 */
proto.fox.proto.ParticleFilterConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumParticles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLaserSigma(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMeasurementSigma(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTruckDetectionSigma(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMotionTranslationSigma(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMotionDirectionSigma(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMotionRotationSigma(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMotionSteeringSigma(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMotionSpeedPercentSigma(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTruckAxisSigma(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPointWeight(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxDistancePenalty(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubsample(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTruckAxisDeadReckonSigma(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ParticleFilterConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ParticleFilterConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ParticleFilterConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ParticleFilterConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumParticles();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLaserSigma();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMeasurementSigma();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTruckDetectionSigma();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getMotionTranslationSigma();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getMotionDirectionSigma();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getMotionRotationSigma();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getMotionSteeringSigma();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getMotionSpeedPercentSigma();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getTruckAxisSigma();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getPointWeight();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMaxDistancePenalty();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getSubsample();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getTruckAxisDeadReckonSigma();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
};


/**
 * optional int32 num_particles = 1;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getNumParticles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setNumParticles = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float laser_sigma = 2;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getLaserSigma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setLaserSigma = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float measurement_sigma = 3;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getMeasurementSigma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setMeasurementSigma = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float truck_detection_sigma = 9;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getTruckDetectionSigma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setTruckDetectionSigma = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float motion_translation_sigma = 6;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getMotionTranslationSigma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setMotionTranslationSigma = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float motion_direction_sigma = 7;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getMotionDirectionSigma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setMotionDirectionSigma = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float motion_rotation_sigma = 8;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getMotionRotationSigma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setMotionRotationSigma = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float motion_steering_sigma = 10;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getMotionSteeringSigma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setMotionSteeringSigma = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float motion_speed_percent_sigma = 11;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getMotionSpeedPercentSigma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setMotionSpeedPercentSigma = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float truck_axis_sigma = 12;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getTruckAxisSigma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setTruckAxisSigma = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float point_weight = 4;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getPointWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setPointWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float max_distance_penalty = 5;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getMaxDistancePenalty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setMaxDistancePenalty = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 subsample = 13;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getSubsample = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setSubsample = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional float truck_axis_dead_reckon_sigma = 14;
 * @return {number}
 */
proto.fox.proto.ParticleFilterConfig.prototype.getTruckAxisDeadReckonSigma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ParticleFilterConfig} returns this
 */
proto.fox.proto.ParticleFilterConfig.prototype.setTruckAxisDeadReckonSigma = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


goog.object.extend(exports, proto.fox.proto);
