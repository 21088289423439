import React from "react";
import { connect } from "react-redux";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { PieChart, Pie, Cell } from "recharts";
import { COLORS } from "../Utils/Colors";

interface Props {
  stoppages: Array<m_pb.Stoppage.AsObject>;
  groupAsOtherThreshold: number;
  filteredFaultTypes: string[];
  height: number;
  width: number;
  radius: number;
}

interface PieChartEntry {
  name: string;
  value: number;
}
const ChartStoppageCategory = (props: Props) => {
  const filteredStoppages = props.stoppages.filter((stoppage) => !props.filteredFaultTypes.includes(stoppage.interventionRobotFaultCodeTranslated));
  const stoppageCountsByFaultCategoryMap = filteredStoppages.reduce(
    (
      countByFaultCategoryMap: Map<string, number>,
      stoppage: m_pb.Stoppage.AsObject
    ) => {
      if (
        countByFaultCategoryMap.has(stoppage.robotFaultCategory)
      ) {
        const currentFaultCount =
          countByFaultCategoryMap.get(
            stoppage.robotFaultCategory
          ) || 0;
        countByFaultCategoryMap.set(
          stoppage.robotFaultCategory,
          currentFaultCount + 1
        );
      } else {
        countByFaultCategoryMap.set(
          stoppage.robotFaultCategory,
          1
        );
      }
      return countByFaultCategoryMap;
    },
    new Map<string, number>()
  );
  const stoppageCountsByFaultCategoryList: Array<PieChartEntry> = [];
  const other: PieChartEntry = {
    name: "Other",
    value: 0
  };
  stoppageCountsByFaultCategoryMap.forEach((value, key) => {
    if (value / props.stoppages.length < props.groupAsOtherThreshold) {
      other.value += value;
    } else {
      stoppageCountsByFaultCategoryList.push({
        name: key,
        value
      });
    }
  });
  if (other.value > 0) {
    stoppageCountsByFaultCategoryList.push(other);
  }
  // @ts-ignore
  const renderCustomizedLabel = variable => {
    const { percent, index } = variable;
    return `${stoppageCountsByFaultCategoryList[index].name || "None"}: ${
      Math.round(100 * percent)
    }%`;
  };
  return (
    <PieChart width={props.width} height={props.height}>
      <Pie
        data={stoppageCountsByFaultCategoryList}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={props.radius}
        fill="#8884d8"
        label={renderCustomizedLabel}
      >
        {stoppageCountsByFaultCategoryList.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default connect()(ChartStoppageCategory);
