import React, { Component } from "react";

import memoizeOne from "memoize-one";
import { CSVLink } from "react-csv";

import Button from "@material-ui/core/Button";
import { CloudDownload } from "@material-ui/icons";

export interface Column {
  key: string;
  key2?: string;
  name: string;
  width?: number;
  formatter: any;
  compositeFormatter?: any;
  componentMaker?: any;
}
export interface CellValue {
  value: number | string;
  component?: Component;
}
export interface FormattedData {
  cells: Array<Array<CellValue>>;
  downloadable: Array<Array<string>>;
}
function statToRow<T extends unknown>(
  stats: T,
  omittedFieldNames: Set<string>,
  defaultColumnProperties: Object,
  columns: Array<Column>
): Array<CellValue> {
  return columns
    .filter(c => !omittedFieldNames.has(c.key))
    .map(c => ({
      ...defaultColumnProperties,
      value: c.compositeFormatter
        ? c.compositeFormatter((stats as any)[c.key], (stats as any)[c.key2])
        : c.formatter ? c.formatter((stats as any)[c.key])
        : (stats as any)[c.key],
      width: c.width,
      component: c.componentMaker
        ? c.componentMaker((stats as any)[c.key])
        : undefined,
      forceComponent: !!c.componentMaker
    }));
}

export const statsToRows = memoizeOne(
  <T extends unknown>(
    stats: Array<T>,
    omittedFieldNamesList: Array<string>,
    columns: Array<Column>,
    defaultColumnProperties: Object
  ): FormattedData => {
    const omittedFieldNames = new Set(omittedFieldNamesList);
    const headers = columns.map(c => ({
      value: c.name,
      width: c.width,
      key: c.key,
      key2: c.key2
    }));
    const cells: Array<Array<CellValue>> = [
      headers.filter(h => !omittedFieldNames.has(h.key)),
      ...stats.map(s =>
        statToRow(s, omittedFieldNames, defaultColumnProperties, columns)
      )
    ];
    return {
      cells,
      downloadable: cells.map(r => r.map(c => c.value as string))
    };
  }
);

interface CSVLinkProps {
  csvData: Array<Array<string>>;
  filename: string;
}
const csvLinkStyle = {
  color: "inherit",
  textDecoration: "none"
};
const buttonContainer = {
  padding: 8
};
export const StylableCSVLink = (props: CSVLinkProps) => (
  <div style={buttonContainer}>
    <Button variant="contained" color="primary" startIcon={<CloudDownload />}>
      <CSVLink
        style={csvLinkStyle}
        filename={props.filename}
        data={props.csvData.slice(1)}
        headers={props.csvData[0]}
      >
        Download CSV
      </CSVLink>
    </Button>
  </div>
);
