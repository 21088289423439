import React, { Component } from "react";

import {createStyles, FormControl, Typography, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import { createSyncDemand } from "../../redux/actions";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import moment from "moment";
import { Moment } from "moment-timezone/moment-timezone";
import { Alert } from "@material-ui/lab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateTimePicker from 'react-datetime-picker';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column"
    },
    dialog: {
      position: "absolute",
      top: "60%"
    }
  });

type Props = {
  classes: any;
  epochOffset: number;
  runName: string;
  runStartTime: number;
  runEndTime: number;
  eventDateTime: Date | null;
  dispatch: any;
  open: boolean;
  onClose: () => void;
  onSuccess: (n: m_pb.SyncDemand.AsObject) => void;
};
type State = {
  startTime: Moment;
  endTime: Moment;
  error: string | null;
  uploadFullRun: boolean;
};

class CreateSyncDemandDialog extends Component<Props, State> {
  state = {
    startTime: moment.max(
      moment(this.props.eventDateTime || 0),
      moment(this.props.runStartTime / 1e6)
    ),
    endTime: moment.min(
      moment(this.props.runEndTime / 1e6),
      moment
        .max(
          moment(this.props.eventDateTime || 0).add(1, "minute"),
          moment(this.props.runStartTime / 1e6)
        )
    ),
    error: null,
    uploadFullRun: false
  };

  _submit = () => {
    const { startTime, endTime, uploadFullRun } = this.state;
    const { epochOffset, onSuccess, runName } = this.props;
    const request = new m_pb.CreateSyncDemandRequest();
    request.setRunName(runName);
    if (startTime && endTime) {
      request.setStartTime((startTime.valueOf() + 1) * 1e6 - epochOffset);
      request.setEndTime((endTime.valueOf() - 1) * 1e6 - epochOffset);
    }
    this.props.dispatch(createSyncDemand(request)).then((payload: any) => {
      onSuccess(payload);
    });
  };

  _validateDates = (startTime: Moment, endTime: Moment) => {
    const { runStartTime, runEndTime } = this.props;
    const errors = [];
    if (!endTime.isAfter(startTime)) {
      errors.push("End Time must be after startTime");
    }
    if (endTime.isAfter(moment(runEndTime / 1e6))) {
      errors.push(
        `End Time must be less than or equal to run end time: ${moment(
          runEndTime / 1e6
        )}`
      );
    }
    if (startTime.isBefore(moment(runStartTime / 1e6))) {
      errors.push(
        `Start Time must be greater than or equal to run start time: ${moment(
          runStartTime / 1e6
        )}`
      );
    }
    return errors.join(",\n");
  };

  render() {
    const { startTime, endTime, error } = this.state;
    const { classes, open, onClose } = this.props;
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={() => onClose && onClose()}
          classes={{
            paper: classes.dialog
          }}
        >
          <DialogTitle>Request Run Log Upload</DialogTitle>
          <DialogContent>
            <div>
              {error && <Alert severity="warning">{error}</Alert>}
              <FormControl>
                <span>Start Time:</span>
                <DateTimePicker
                  disabled={this.state.uploadFullRun}
                  disableCalendar={true}
                  disableClock={true}
                  clearIcon={null}
                  maxDetail="second"
                  value={startTime.toDate()}
                  onChange={e => {
                    const error = this._validateDates(
                      moment(e),
                      endTime
                    );
                    this.setState({
                      startTime: moment(e),
                      error: error
                    });
                  }}
                />
              </FormControl>
              <FormControl>
                <span>End Time:</span>
                <DateTimePicker
                  disabled={this.state.uploadFullRun}
                  disableCalendar={true}
                  disableClock={true}
                  clearIcon={null}
                  maxDetail="second"
                  value={endTime.toDate()}
                  onChange={e => {
                    const error = this._validateDates(
                      startTime,
                      moment(e),
                    );
                    this.setState({
                      endTime: moment(e),
                      error: error
                    });
                  }}
                />
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.uploadFullRun}
                    onChange={e =>
                      this.setState({
                        uploadFullRun: e.target.checked,
                        startTime: moment(this.props.runStartTime / 1e6),
                        endTime: moment(this.props.runEndTime / 1e6),
                      })
                    }
                  />
                }
                label="Upload full run"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onClose()} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => this._submit()}
              color="primary"
              disabled={!!error}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default connect()(withStyles(styles)(CreateSyncDemandDialog));
