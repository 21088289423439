import React, { Component } from "react";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";

import MuiLink from "@material-ui/core/Link";

import { ApplicationState } from "../../redux";
import { getTrelloCardRequest } from "../../redux/actions";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    wrapper: {
      width: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "column"
    },
    card: {
      width: "100%"
    },
    smallPaddingLeft: {
      paddingLeft: 16
    },
    content: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: 0
    },
    logs: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      paddingTop: 0
    },
    referencesAndRuns: {
      display: "flex",
      justifyContent: "start",
      flexDirection: "column",
      width: 480,
      overflowX: "auto",
      paddingLeft: 16
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center"
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start"
    },
    nested: {
      paddingLeft: theme.spacing(12)
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
  cardUrl: string;
  noFallback?: boolean;
}
interface State {
  trelloCardData?: any;
}

class TrelloCard extends Component<Props, State> {
  state = {
    trelloCardData: undefined
  };

  _fetchTrelloData = () => {
    const maybeMatch = this.props.cardUrl.match(
      /trello.com\/c\/([a-zA-Z0-9]+)\/[0-9]+.*/
    );
    if (!maybeMatch || maybeMatch.length < 2) {
      console.warn(`Cannot find trello card id in url ${this.props.cardUrl}`);
      return undefined;
    }
    const trelloId = maybeMatch[1];
    this.props.dispatch(getTrelloCardRequest(trelloId)).then((c: any) => {
      this.setState({ trelloCardData: c.card });
    });
  };

  componentDidMount(): void {
    this._fetchTrelloData();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { cardUrl } = this.props;
    if (prevProps.cardUrl === cardUrl) {
      return;
    }
    this.setState({ trelloCardData: undefined }, () => this._fetchTrelloData());
  }

  render() {
    const { cardUrl, noFallback } = this.props;
    const { trelloCardData } = this.state;
    const tryFallback = !trelloCardData && !noFallback;
    const fallback = tryFallback ? (
      <MuiLink href={cardUrl}>{cardUrl}</MuiLink>
    ) : null;
    const trelloCard = trelloCardData ? (
      <_TrelloCardWrapper card={trelloCardData} />
    ) : null;
    return (
      <React.Fragment>
        {trelloCard}
        {fallback}
      </React.Fragment>
    );
  }
}

class _TrelloCardWrapper extends React.Component {
  el: any | undefined;
  props: any;
  constructor(props: any) {
    super(props);

    this.setRef = this.setRef.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onLabelTextChanged = this.onLabelTextChanged.bind(this);
  }

  componentDidMount() {
    if (!this.el) {
      return;
    }
    this.el.card = this.props.card;
    this.el.addEventListener("click", this.onClick);
    this.el.addEventListener("labelTextChanged", this.onLabelTextChanged);
  }

  componentDidUpdate() {
    if (!this.el) {
      return;
    }

    this.el.card = this.props.card;
  }

  onClick(e: any) {
    if (typeof this.props.onClick === "function") {
      e.preventDefault();
      this.props.onClick();
    }
  }

  onLabelTextChanged(e: any) {
    if (typeof this.props.onLabelTextChanged === "function") {
      this.props.onLabelTextChanged(e.detail.labeltext);
    }
  }

  setRef(el: any) {
    this.el = el;
  }

  render() {
    return (
      // @ts-ignore
      <trello-card
        ref={this.setRef}
        colorblind={this.props.colorblind}
        labeltext={this.props.labelText}
      />
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(TrelloCard));
