import React from "react";
import { connect } from "react-redux";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { PieChart, Pie, PieLabelRenderProps, Cell } from "recharts";
import { COLORS } from "../Utils/Colors";

interface Props {
  stoppages: Array<m_pb.Stoppage.AsObject>;
  groupAsOtherThreshold: number;
  filteredFaultTypes: string[];
  height: number;
  width: number;
  radius: number;
}

interface PieChartEntry {
  name: string;
  value: number;
}
const ChartStoppageCause = (props: Props) => {
  const filteredStoppages = props.stoppages.filter((stoppage) => !props.filteredFaultTypes.includes(stoppage.interventionRobotFaultCodeTranslated));
  const stoppageCountsByFaultTypeMap = filteredStoppages.reduce(
    (
      countByFaultTypeMap: Map<string, number>,
      stoppage: m_pb.Stoppage.AsObject
    ) => {
      if (
        countByFaultTypeMap.has(stoppage.interventionRobotFaultCodeTranslated)
      ) {
        const currentFaultCount =
          countByFaultTypeMap.get(
            stoppage.interventionRobotFaultCodeTranslated
          ) || 0;
        countByFaultTypeMap.set(
          stoppage.interventionRobotFaultCodeTranslated,
          currentFaultCount + 1
        );
      } else {
        countByFaultTypeMap.set(
          stoppage.interventionRobotFaultCodeTranslated,
          1
        );
      }
      return countByFaultTypeMap;
    },
    new Map<string, number>()
  );
  const stoppageCountsByFaultTypeList: Array<PieChartEntry> = [];
  const other: PieChartEntry = {
    name: "Other",
    value: 0
  };
  stoppageCountsByFaultTypeList.push(other);
  stoppageCountsByFaultTypeMap.forEach((value, key) => {
    if (value / props.stoppages.length < props.groupAsOtherThreshold) {
      other.value += value;
    } else {
      stoppageCountsByFaultTypeList.push({
        name: key,
        value
      });
    }
  });
  // @ts-ignore
  const renderCustomizedLabel = variable => {
    const { percent, index } = variable;
    return `${stoppageCountsByFaultTypeList[index].name || "None"}: ${
      100 * percent
    }%`;
  };
  return (
    <PieChart width={props.width} height={props.height}>
      <Pie
        data={stoppageCountsByFaultTypeList}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={props.radius}
        fill="#8884d8"
        label={renderCustomizedLabel}
      >
        {stoppageCountsByFaultTypeList.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default connect()(ChartStoppageCause);
