import React, { FunctionComponent, useEffect, useState } from "react";
import {
  createStyles,
  Theme,
  Button,
  Paper,
  Box,
  TextField,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TablePagination,
  withStyles,
  WithStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import { useTable, useSortBy, Column, useGlobalFilter, useFilters, useAsyncDebounce, usePagination } from 'react-table';
import {
  Search,
  ArrowUpward,
  ArrowDownward,
} from '@material-ui/icons';

const styles = (theme: Theme) =>
  createStyles({
    outerPaper: {
      width: '100%',
      padding: 16,
    },
    innerPaper: {
      width: '100%',
    }, 
    cell: {
      opacity: .78
    },
    head: {
      backgroundColor: '#d1dce2'
    },
    formContainer: {
      display: 'flex',
      marginBottom: '32px',
      marginTop: '32px',
      justifyContent: 'flex-start',
      paddingRight: '16px',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    titleDivider: {
      marginRight: 12,
      marginLeft: 12,
    },
    searchAdornment: {
      marginRight: 8,
    }
  });

interface Props<T extends object> extends WithStyles<typeof styles> {
  data: Array<T>;
  columns: Column<T>[];
  title?: string;
  icon?: JSX.Element;
  renderEmptyRows?: boolean;
}

const FoxGrid = <T extends object>(props: Props<T>) => {
  const { classes, columns, data, icon, title, renderEmptyRows } = props;
  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    getTableProps,
    getTableBodyProps, 
    headerGroups,
    state,
    setGlobalFilter,
    rows,
    page,
    prepareRow,
    preGlobalFilteredRows,
    gotoPage,
    setPageSize,
  } = useTable({columns, data, initialState: { pageIndex: 0 }}, useFilters, useGlobalFilter, useSortBy, usePagination);

  const handleChangeRowsPerPage = (event) => {
    const n = parseInt(event.target.value, 10);
    setRowsPerPage(n);
    setPageSize(n);
    gotoPage(0);
    setPageNo(0);
  };

  const [value, setValue] = useState(state.globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
    gotoPage(0);
    setPageNo(0);
  }, 200)

  const FoxTableHeader = () => {
    return(
      <TableHead className={classes.head}>
        {
          headerGroups.map((headerGroup, i) => {
            return(
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map((column, i) => {
                    return(
                      <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <Button
                          endIcon={
                            column.isSorted ?
                              column.isSortedDesc ?
                                <ArrowDownward />
                                :
                                <ArrowUpward />
                              : ''
                          }
                        >
                          {
                            column.render('Header')
                          }
                        </Button>
                      </TableCell>
                    )
                  })
                }
              </TableRow>
            )
          })
        }
      </TableHead>
    )
  }

  const FoxTableBody = () => {
    return(
      <TableBody>
        {
          page?.map((row, i) => {
            prepareRow(row)

            return(
              <TableRow hover={true} {...row.getRowProps()}>
                {
                  row.cells.map((cell, i) => {
                    return <TableCell className={classes.cell} {...cell.getCellProps()}>
                      {
                        cell.render('Cell')
                      }
                    </TableCell>
                  })
                }
              </TableRow>
            )
          })
        }
        {
          renderEmptyRows && [...Array(rowsPerPage - page.length)].map((v, i) => {
            return(
              <TableRow hover={true} key={i}>
                {
                  [...Array(columns.length)].map((c, i) => {
                    return(
                      <TableCell className={classes.cell} key={page.length + i}>
                        -
                      </TableCell>
                    )
                  })
                }
              </TableRow>
            )
          })
        }
      </TableBody>
    )
  }

  return (
    <Paper className={classes.outerPaper}>
      <Box className={classes.formContainer}>
        <FormControl>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            type='search'
            size='small'
            autoComplete="off"
            spellCheck={false}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            value={value || ""}    
            InputProps={{
              startAdornment: (
                <Search className={classes.searchAdornment}/>
              )
            }}
          />
        </FormControl>
      {
        icon && title &&
          <>
          <Box flexGrow={1}/>
          <Box className={classes.title}>
            <Typography  variant="h5">{title}</Typography>
          </Box>
          <Divider className={classes.titleDivider} orientation="vertical" flexItem />
          <Box display='flex' alignItems='center'>
            {
              icon
            }
          </Box>
          </>
      }
      </Box>
     
      <Paper className={classes.innerPaper} elevation={3}>
        <TableContainer component={Paper}>
          <Table {...getTableProps()}>
            <FoxTableHeader/>
            <FoxTableBody/>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={pageNo}
                  onChangePage={(e, page: number) => {
                    gotoPage(page)
                    setPageNo(page)
                  }}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Paper>
  );
};

export default withStyles(styles)(FoxGrid);
