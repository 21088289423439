import { fade, Theme, createStyles } from "@material-ui/core/styles";

export const commonStyles = (theme: Theme) => ({
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    minWidth: 100,
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  search: {
    position: "relative",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      maxWidth: 160,
      width: "auto"
    }
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    overflow: "auto"
  },
  querybar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    minHeight: "34px"
  },
  queryItem: {
    paddingLeft: 15,
    paddingRight: 15
  },
  queryText: {
    color: "orange"
  },
  chipArray: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    background: "inherit",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: 16,
    maxHeight: 37,
    "& button": {
      maxHeight: 37
    }
  },
  chipAttributes: {
    display: "flex",
    height: 53,
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  addAttributes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    maxWidth: 300,
    textTransform: "capitalize"
  },
  isDirty: {
    border: "2px solid orange"
  },
  isDirtyAlt: {
    border: "1px solid #78909c",
    margin: 0
  },
  altBtn: {
    margin: "2px"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  sideBtn: {
    display: "float",
    flex: 1
  },
  fileSystemWrapper: {
    flexDirection: "column",
    display: "inline-block",
    maxHeight: 800,
    margin: 16
  },
  historyWrapper: {
    display: "inline-block",
    verticalAlign: "top",
    margin: 16,
    flex: 1
  }
});

export function combineStyles(...allStyles: any[]) {
  return function CombineStyles(theme: any) {
    let firstStyle = allStyles[0];
    if (typeof firstStyle === "function") {
      // apply theme
      const themedStyle = firstStyle(theme);
      firstStyle = themedStyle;
    }

    for (let i = 1; i < allStyles.length; i++) {
      let objectToMerge = allStyles[i];
      if (typeof objectToMerge === "function") {
        // apply theme
        objectToMerge = objectToMerge(theme);
      }
      // combine by key
      let keys = Object.keys(objectToMerge);
      keys.forEach(key => {
        if (firstStyle.hasOwnProperty(key)) {
          firstStyle[key] = { ...firstStyle[key], ...objectToMerge[key] };
        } else {
          firstStyle[key] = objectToMerge[key];
        }
      });
    }
    return firstStyle;
  };
}
