import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import CardContent from "@material-ui/core/CardContent";
import ProgressSpinner from "../Utils/ProgressSpinner";
import Link from "@material-ui/core/Link";
import { secondary, primary } from "../../App";

import { ApplicationState } from "../../redux";
import { Annotation, RunMetadata } from "../../redux/payloads";
import {
  getRunMetadataRequest,
  getRunPlacedPalletsRequest,
  listInterventionsRequest,
  listPickStatsRequest,
  deleteAnnotationRequest,
  listGoalMetadasRequest,
  listSyncDemands,
  listFaultsRequest
} from "../../redux/actions";
import { CardHeader, Typography } from "@material-ui/core";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import { logInPath, robotLogsPath, bitbucketSource } from "../../utils/Paths";
import GoalDetailsDialog from "./GoalDetailsDialog";
import { grpc } from "@improbable-eng/grpc-web";
import { protoEnumFieldName } from "../../utils/protos";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateAnnotationDialog from "./CreateAnnotationDialog";
import UpdateAnnotationDialog from "./UpdateAnnotationDialog";
import CreateSyncDemandDialog from "./CreateSyncDemandDialog";
import Tooltip from "./Tooltip";
import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
// @ts-ignore
import Timeline from "react-visjs-timeline";

import PickStatsSheet from "../Sheets/PickStatsSheet";
import InterventionsSheet from "../Sheets/InterventionsSheet";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {CheckBox} from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    divider: {
      width: "100%"
    },
    textLogs: {
      fontWeight: "bold"
    },
    annotationsHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: 8
    },
    runHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    },
    annotation: {
      padding: 8,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      height: "100%"
    },
    padLeft: {
      paddingLeft: 2
    },
    marginLeft: {
      marginLeft: 16
    },
    wrapper: {
      width: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: 400
    },
    card: {
      width: "100%",
      padding: 8
    },
    topLinks: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: 16,
      paddingTop: 8
    },
    smallPaddingLeft: {
      paddingLeft: 16
    },
    content: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: 0
    },
    logs: {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      justifyContent: "flex-start",
      paddingTop: 0
    },
    notification: {
      paddingTop: 80
    },
    timeline: {
      width: "100%",
      display: "block"
    },
    timelineContent: {
      backgroundColor: secondary[100],
      borderColor: secondary[800]
    },
    newAnnotationTime: {
      backgroundColor: primary[50],
      borderColor: secondary[800]
    },
    interventionContent: {
      backgroundColor: primary[400],
      borderColor: secondary[800]
    },
    ignoredInterventionContent: {
      backgroundColor: primary[50],
      borderColor: secondary[800]
    },
    failedTimelineContent: {
      backgroundColor: primary[100],
      borderColor: secondary[800]
    },
    fatalFaultTimelineContent: {
      backgroundColor: "#ff5d54",
      borderColor: "#ff0000"
    },
    errorFaultTimelineContent: {
      backgroundColor: "#ffb854",
      borderColor: "#ff8c00",
    },
    warnFaultTimelineContent: {
      backgroundColor: "#f0fa66",
      borderColor: "#eeff00"
    },
    infoFaultTimelineContent: {
      backgroundColor: "#9cdeff",
      borderColor: "#00aaff"
    },
    annotations: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "start",
      width: "100%",
      marginTop: 16,
      padding: 16,
      maxHeight: 640,
      overflowY: "auto"
    },
    stats: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "start",
      width: "100%",
      height: 440,
      marginTop: 16,
      padding: 16,
      maxHeight: 640,
      overflowY: "auto"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: (action: any) => Promise<any>;
}
interface State {
  isLoading: boolean;
  redirectTo: string | null;
  error: ServiceError | null;
  run: RunMetadata | null;
  placedPallets: m_pb.RunPlacedPallets.AsObject | null;
  goals: Array<m_pb.GoalMetadata.AsObject>;
  pickStats: Array<m_pb.PickStats.AsObject>;
  stoppages: Array<m_pb.Stoppage.AsObject>;
  syncDemands: Array<m_pb.SyncDemand.AsObject>;
  faults: Array<m_pb.Fault.AsObject>;
  isCreatingAnnotation: boolean;
  deleteAnnotationId: string | null;
  newAnnotationTime: number | null;
  newSyncDemandTime: Date | null;
  updateAnnotationId: null | string;
  highlightGoalId: null | string;
  tooltipProps: null | TooltipProps;
  isCopied: boolean;
  hideManualModeFaults: boolean;
}

interface TooltipProps {
  xPosition: number;
  yPosition: number;
  text: string;
}

enum TimelineGroup {
  PALLET = "Pallets",
  MANUAL_MODE = "Manual Mode",
  ESTOP = "EStop",
  STOPPAGE = "Intervention",
  ANNOTATIONS = "Annotations",
  GOALS = "Goals",
  SYNC_DEMANDS = "Log Upload Requests (click timeline to create)",
  FAULTS = "Faults",
  HARS= "Human Assistance Requests",
}
const InterventionGroup: Map<
  m_pb.InterventionMechanism,
  TimelineGroup
> = new Map([
  [m_pb.InterventionMechanism.MANUAL_MODE, TimelineGroup.MANUAL_MODE],
  [m_pb.InterventionMechanism.ESTOP, TimelineGroup.ESTOP]
]);

interface EventClick {
  event: MouseEvent;
  group: TimelineGroup;
  item: string;
  time: Date;
}

class RunPage extends Component<
  Props & RouteComponentProps<{ runName: string }>,
  State
> {
  state: State = {
    isLoading: false,
    redirectTo: null,
    error: null,
    run: null,
    placedPallets: null,
    pickStats: [],
    stoppages: [],
    goals: [],
    syncDemands: [],
    faults: [],
    highlightGoalId: null,
    isCreatingAnnotation: false,
    deleteAnnotationId: null,
    newAnnotationTime: null,
    updateAnnotationId: null,
    newSyncDemandTime: null,
    tooltipProps: null,
    isCopied: false,
    hideManualModeFaults: true
  };

  _get_fault_text = (fault: m_pb.Fault.AsObject) => {
    switch (fault.faultType) {
      case 4:
        return "path_blocked";
      case 5:
        return "planning_failure";
      case 6:
        return "safety_scanner_engaged";
      case 7:
        return "mast_stuck";
      case 8:
        return "mast_stuck";
      case 9:
        return "shallow_pick";
      case 13:
        return "stale_sensor_data";
      case 14:
        return "hesitation";
      case 17:
        return "off_path";
      case 18:
        return "pick_place_zone_error";
      case 20:
        return "validation_error";
      case 21:
        // TODO(chris): reverse-lookup error codes and severities to make them more readable
        return `${fault.errorCode} | ${fault.severity}`;
      default:
        return "unknown";
    }
  }

  copyTextToClipboard = (text: string) => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
    this.setState({isCopied: true}, () => {
      setTimeout(() => {
        this.setState({isCopied: false});
      }, 1500);
    });
  }

  rightClickHandler = (e: EventClick) => {
    const {
      run
    } = this.state;
    if (!run) {
      return;
    }
    const eventTimeMs = e.time.valueOf();
    const robotEventTimeNs = eventTimeMs * 1e6 - run.description.robotEpochOffset;
    this.copyTextToClipboard(robotEventTimeNs.toString(10));

    // @ts-ignore
    e.event.preventDefault();
  }

  eventClickHandler = (e: EventClick) => {
    const {
      isCreatingAnnotation,
      updateAnnotationId,
      newAnnotationTime,
      run
    } = this.state;
    if (!run) {
      return;
    }
    if (e.group === TimelineGroup.SYNC_DEMANDS) {
      // @ts-ignore
      this.setState({newSyncDemandTime: e.time});
    }
    if (!(!!updateAnnotationId || isCreatingAnnotation) || newAnnotationTime) {
      return;
    }
    const eventTimeMs = e.time.valueOf();
    const robotEventTimeNs =
        // @ts-ignore
        eventTimeMs * 1e6 - run.description.robotEpochOffset;
    this.setState({newAnnotationTime: robotEventTimeNs});
  };

  eventDoubleClickHandler = (e: EventClick) => {
    const {run, isCreatingAnnotation, newAnnotationTime, goals} = this.state;
    if (!run) {
      return;
    }
    if (e.group === TimelineGroup.GOALS) {
      const goal = (goals as Array<m_pb.GoalMetadata.AsObject>).find(
          g => g.id == e.item
      );
      if (!goal) {
        return;
      }
      this.setState({highlightGoalId: goal.id});
      return;
    }
    if (e.group === TimelineGroup.ANNOTATIONS) {
      if (isCreatingAnnotation || newAnnotationTime) {
        return;
      }
      const updateAnnotationId = e.item;
      if (updateAnnotationId !== null) {
        // @ts-ignore
        const annotation = run.annotations.find(a => a.id === e.item);
        if (annotation) {
          this.setState({
            updateAnnotationId,
            isCreatingAnnotation: false,
            newAnnotationTime: annotation.eventTime
          });
          this._addAnnotationHash(updateAnnotationId);
          return;
        }
      }
      const eventTimeMs = e.time.valueOf();
      const robotEventTimeNs =
          // @ts-ignore
          eventTimeMs * 1e6 - run.description.robotEpochOffset;
      this.setState({
        isCreatingAnnotation: true,
        newAnnotationTime: robotEventTimeNs
      });
      return;
    }
  };

  mouseOverHandler = (e: EventClick) => {
    const { faults } = this.state;
    if (e.group === TimelineGroup.FAULTS) {
      const fault = (faults as Array<m_pb.Fault.AsObject>).find(
        f => f.id == e.item
      );
      if (!fault) {
        this.setState({
          tooltipProps: null
        });
        return;
      }
      let faultTypeText = this._get_fault_text(fault);
      this.setState({
        tooltipProps: {
          text: faultTypeText,
          xPosition: e.event.pageX + 10,
          yPosition: e.event.pageY
        }
      });
      return;
    }
  };

  componentDidMount() {
    const { run } = this.state;
    const hashAnnotation =
      this.props.location.hash
        .split("#")
        .filter(h => h.startsWith("annotation-"))
        .map(h => h.replace("annotation-", ""))
        .find(a => !!a) || null;
    if (run) {
      return;
    }
    const { runName } = this.props.match.params;
    this.setState({ isLoading: true, error: null }, () =>
      this.props
        .dispatch(getRunMetadataRequest(runName))
        .then((payload: any) => {
          const run: RunMetadata = payload.run;
          if (run.goalIds.length) {
            this.props
              .dispatch(
                listGoalMetadasRequest(
                  { pageSize: 20, pageToken: 0 },
                  run.goalIds
                )
              )
              .then(res => {
                this.setState({ goals: res.metadatas });
              });
          }
          let newAnnotationTime = null;
          if (hashAnnotation) {
            const annotationToUpdate = run.annotations.find(
              a => a.id === hashAnnotation
            );
            if (annotationToUpdate) {
              newAnnotationTime = annotationToUpdate.eventTime;
            }
          }
          this.setState({
            run: payload.run,
            newAnnotationTime,
            updateAnnotationId: hashAnnotation
          });
        })
        .catch((e: ServiceError) => {
          switch (e.code) {
            case grpc.Code.Unauthenticated: {
              this.setState({
                redirectTo: logInPath(window.location.pathname)
              });
              break;
            }
            default: {
              this.setState({ error: e });
            }
          }
        })
        .finally(() => this.setState({ isLoading: false }))
    );
    const filter = new m_pb.EventFilter();
    filter.setRunIdsList([runName]);
    this.props
      .dispatch(listPickStatsRequest(filter, ""))
      .then((res: m_pb.ListPickStatsResponse.AsObject) => {
        this.setState({
          pickStats: res.pickStatsList
        });
      });
    this.props
      .dispatch(listInterventionsRequest(filter))
      .then((res: m_pb.ListStoppagesResponse.AsObject) => {
        this.setState({
          stoppages: res.stoppagesList
        });
      });
    this.props
      .dispatch(getRunPlacedPalletsRequest(runName))
      .then((placedPallets: m_pb.RunPlacedPallets.AsObject) =>
        this.setState({ placedPallets })
      );
    this.props
      .dispatch(listSyncDemands(runName))
      .then((res: m_pb.ListSyncDemandsResponse.AsObject) => {
        this.setState({
          syncDemands: res.syncsList
        });
      });
    this.props
      .dispatch(listFaultsRequest([runName]))
      .then((res: m_pb.ListFaultsResponse.AsObject) => {
        this.setState({
          faults: res.faultsList
        });
      });
  }

  _deleteAnnotation(annotationId: string) {
    this.setState({
      deleteAnnotationId: null,
      updateAnnotationId: null,
      newAnnotationTime: null
    });
    this.props.dispatch(deleteAnnotationRequest(annotationId)).then(() => {
      const run = this.state.run as RunMetadata | null;
      if (!run) {
        return;
      }
      run.annotations = run.annotations.filter(n => n.id !== annotationId);

      const filter = new m_pb.EventFilter();
      filter.setRunIdsList([run.runId]);
      this.props
        .dispatch(listPickStatsRequest(filter, ""))
        .then((res: m_pb.ListPickStatsResponse.AsObject) => {
          this.setState({
            pickStats: res.pickStatsList
          });
        });
      this.props
        .dispatch(listInterventionsRequest(filter))
        .then((res: m_pb.ListStoppagesResponse.AsObject) => {
          this.setState({
            stoppages: res.stoppagesList
          });
        });
      this.props
        .dispatch(getRunPlacedPalletsRequest(run.runId))
        .then((placedPallets: m_pb.RunPlacedPallets.AsObject) =>
          this.setState({ placedPallets })
        );
      this.setState({ run });
    });
  }

  _addAnnotationHash = (annotationId: string) => {
    const location = this.props.location;
    const nonAnnotationHashParts = location.hash
      .split("#")
      .filter(h => h && !h.startsWith("annotation-"));
    location.hash = [
      `annotation-${annotationId}`,
      ...nonAnnotationHashParts
    ].join("#");
    this.props.history.replace(location);
  };

  render() {
    const { classes, match } = this.props;
    const { runName } = match.params;
    const {
      isLoading,
      redirectTo,
      error,
      placedPallets,
      goals,
      highlightGoalId,
      tooltipProps,
      pickStats,
      stoppages,
      syncDemands,
      faults,
      newAnnotationTime,
      isCreatingAnnotation,
      deleteAnnotationId,
      updateAnnotationId,
      newSyncDemandTime
    } = this.state;
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    const updatePicksAfterAnnotation = () => {
      const filter = new m_pb.EventFilter();
      filter.setRunIdsList([runName]);
      this.props
        .dispatch(listPickStatsRequest(filter, ""))
        .then((res: m_pb.ListPickStatsResponse.AsObject) => {
          this.setState({
            pickStats: res.pickStatsList
          });
        });
      this.props
        .dispatch(listInterventionsRequest(filter))
        .then((res: m_pb.ListStoppagesResponse.AsObject) => {
          this.setState({
            stoppages: res.stoppagesList
          });
        });
      this.props
        .dispatch(getRunPlacedPalletsRequest(runName))
        .then((placedPallets: m_pb.RunPlacedPallets.AsObject) =>
          this.setState({ placedPallets })
        );
    };
    const deleteAnnotationDialog = deleteAnnotationId ? (
      <Dialog open>
        <DialogTitle>Delete Annotation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this annotation?
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => this.setState({ deleteAnnotationId: null })}
          >
            Cancel
          </Button>
          <Button
            color={"primary"}
            onClick={() =>
              deleteAnnotationId &&
              this._deleteAnnotation((deleteAnnotationId as unknown) as string)
            }
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
    const run = this.state.run as RunMetadata | null;
    const progressSpinner = isLoading ? <ProgressSpinner /> : null;
    const errorMsg = error ? (
      <Typography variant={"h3"} color={"error"}>
        {(error as ServiceError).message}
      </Typography>
    ) : null;
    const timelineItems = [];
    const timelineIds = new Set();
    const epochOffset = run ? run.description.robotEpochOffset : 0;
    if (stoppages) {
      const timeline = stoppages as Array<m_pb.Stoppage.AsObject>;
      for (const p of timeline) {
        timelineItems.push({
          content: ``,
          start: new Date((epochOffset + p.startTime) / 1e6),
          end: new Date((epochOffset + p.endTime) / 1e6),
          group: TimelineGroup.STOPPAGE,
          className: p.manualStop
            ? classes.interventionContent
            : classes.ignoredInterventionContent
        });
      }
    }
    if (faults) {
      // Only display robot faults
      for (const fault of faults.filter(f => f.faultType === 21 && !(this.state.hideManualModeFaults && f.manualMode))) {
        if (fault.errorType === "human_assistance_request") {
          timelineItems.push({
            id: fault.id,
            content: "",
            start: new Date(fault.startTime / 1e6),
            end: new Date(fault.endTime / 1e6),
            group: TimelineGroup.HARS,
            className: null
          });
        } else {
          let className = classes.infoFaultTimelineContent;
          switch(fault.severity) {
            case "FAULT_SEVERITY_FATAL":
              className = classes.fatalFaultTimelineContent;
              break;
            case "FAULT_SEVERITY_ERROR":
              className = classes.errorFaultTimelineContent;
              break;
            case "FAULT_SEVERITY_WARN":
              className = classes.warnFaultTimelineContent;
              break;
          }
          timelineItems.push({
            id: fault.id,
            content: this._get_fault_text(fault),
            start: new Date(fault.startTime / 1e6),
            end: new Date(fault.endTime / 1e6),
            group: TimelineGroup.FAULTS,
            className
          });
        }
      }
    }
    if (syncDemands) {
      const demands = syncDemands as Array<m_pb.SyncDemand.AsObject>;
      for (const demand of demands) {
        timelineItems.push({
          content: ``,
          start: new Date((epochOffset + parseInt(demand.startTime, 10)) / 1e6),
          end: new Date((epochOffset + parseInt(demand.endTime, 10)) / 1e6),
          group: TimelineGroup.SYNC_DEMANDS,
          // TODO(chris): add styling based on status
          className: null
        });
      }
    }
    for (const goal of goals as Array<m_pb.GoalMetadata.AsObject>) {
      timelineItems.push({
        id: goal.id,
        content: `Goal (double click for details)`,
        start: new Date(goal.startTime / 1e6),
        end: new Date(goal.endTime / 1e6),
        group: TimelineGroup.GOALS,
        className: classes.timelineContent
      });
    }
    if (placedPallets) {
      const timeline = placedPallets as m_pb.RunPlacedPallets.AsObject;
      for (const p of timeline.placedPalletsList) {
        const requiredStoppage =
          stoppages &&
          (stoppages as Array<m_pb.Stoppage.AsObject>).some(stoppage => {
            const startTimeConverted = stoppage.startTime + epochOffset;
            const endTimeConverted = stoppage.endTime + epochOffset;
            return (
              !stoppage.excused &&
              ((startTimeConverted >= p.startTime &&
                startTimeConverted < p.endTime) ||
                (startTimeConverted < p.startTime &&
                  endTimeConverted > p.pickTime))
            );
          });
        const interventionDuringPlace = timeline.interventionsList.some(
          i =>
            !i.exclude &&
            i.palletId === p.palletId &&
            ((i.startTime >= p.startTime && i.startTime < p.endTime) ||
              (i.startTime < p.startTime && i.endTime > p.pickTime))
        );
        timelineItems.push({
          content: `${p.palletId}`,
          start: new Date(p.startTime / 1e6),
          end: new Date(p.endTime / 1e6),
          group: TimelineGroup.PALLET,
          className:
            requiredStoppage || (!stoppages && interventionDuringPlace)
              ? classes.failedTimelineContent
              : classes.timelineContent
        });
      }
      for (const i of timeline.interventionsList) {
        const id = `${i.mechanism}-${i.startTime}`;
        if (timelineIds.has(id)) {
          console.warn(`Duplicate intervention id: ${id}`);
          continue;
        }
        timelineIds.add(id);
        timelineItems.push({
          id: id,
          content: "",
          start: new Date(i.startTime / 1e6),
          end: new Date(i.endTime / 1e6),
          group: InterventionGroup.get(i.mechanism),
          className: i.exclude
            ? classes.ignoredInterventionContent
            : classes.interventionContent
        });
      }
    }
    const annotations = run ? run.annotations : [];
    for (const annotation of annotations) {
      timelineItems.push({
        id: annotation.id,
        content: `${protoEnumFieldName(
          cc_pb.InterventionType,
          annotation.intervention
        ).replace("INTERVENTION_", "")}${
          annotation.description && `: ${annotation.description}`
        }`,
        start: new Date((epochOffset + annotation.eventTime) / 1e6),
        group: TimelineGroup.ANNOTATIONS,
        className: classes.timelineContent
      });
    }
    const timelineGroups = [
      TimelineGroup.ANNOTATIONS,
      TimelineGroup.PALLET,
      TimelineGroup.STOPPAGE,
      TimelineGroup.MANUAL_MODE,
      TimelineGroup.ESTOP,
      TimelineGroup.GOALS,
      TimelineGroup.SYNC_DEMANDS,
      TimelineGroup.HARS,
      TimelineGroup.FAULTS,
    ].map((n, i) => ({ id: n, content: n }));

    const createAnnotationDialog =
      isCreatingAnnotation && run ? (
        <CreateAnnotationDialog
          dispatch={this.props.dispatch}
          runId={run.runId}
          epochOffset={run.description.robotEpochOffset}
          requestClearTime={() => this.setState({ newAnnotationTime: null })}
          eventTime={newAnnotationTime}
          open={!!newAnnotationTime}
          onClose={() =>
            this.setState({
              isCreatingAnnotation: false,
              newAnnotationTime: null
            })
          }
          onSuccess={(n: m_pb.Annotation) => {
            run.annotations.unshift(Annotation.fromProto(n));
            updatePicksAfterAnnotation();
            this.setState({
              isCreatingAnnotation: false,
              newAnnotationTime: null,
              run
            });
          }}
        />
      ) : null;
    const annotationToUpdate =
      updateAnnotationId && run
        ? run.annotations.find(a => a.id === updateAnnotationId)
        : undefined;
    const updateAnnotationDialog =
      annotationToUpdate && !createAnnotationDialog && run ? (
        <UpdateAnnotationDialog
          runId={run.runId}
          dispatch={this.props.dispatch}
          originalAnnotation={annotationToUpdate.toProto()}
          epochOffset={run.description.robotEpochOffset}
          requestClearTime={() => this.setState({ newAnnotationTime: null })}
          requestDeleteAnnotation={() =>
            this.setState({ deleteAnnotationId: annotationToUpdate.id })
          }
          eventTime={newAnnotationTime}
          open={!!newAnnotationTime}
          onClose={() => {
            this.setState({
              updateAnnotationId: null,
              newAnnotationTime: null
            });
            const location = this.props.location;
            const nonAnnotationHashParts = location.hash
              .split("#")
              .filter(h => h && !h.startsWith("annotation-"));
            location.hash = nonAnnotationHashParts.join("#");
            this.props.history.replace(location);
          }}
          onSuccess={(n: m_pb.Annotation) => {
            const i = run.annotations.findIndex(a => a.id === n.getId());
            if (i < 0) {
              console.warn("Cannot find updated annotation in current run.");
              return;
            }
            if (i > run.annotations.length - 1) {
              console.warn("Updated annotation has out-of-bounds index.");
              return;
            }
            run.annotations[i] = Annotation.fromProto(n);
            updatePicksAfterAnnotation();
            this.setState({
              run,
              newAnnotationTime: n.getEventTime()
            });
          }}
        />
      ) : null;
    const createSyncDemandDialog = !!newSyncDemandTime && run && (
      <CreateSyncDemandDialog
        runName={run.runId}
        runStartTime={run.startTime}
        runEndTime={run.endTime}
        epochOffset={run.description.robotEpochOffset}
        eventDateTime={newSyncDemandTime}
        open={!!newSyncDemandTime}
        onClose={() =>
          this.setState({
            newSyncDemandTime: null
          })
        }
        onSuccess={(n: m_pb.SyncDemand.AsObject) => {
          const updatedSyncDemands = syncDemands.slice();
          updatedSyncDemands.push(n);
          this.setState({
            newSyncDemandTime: null,
            run,
            syncDemands: updatedSyncDemands
          });
        }}
      />
    );

    const hasUpdatedTime =
      updateAnnotationId &&
      annotationToUpdate &&
      newAnnotationTime !== annotationToUpdate.eventTime;
    if (newAnnotationTime && (isCreatingAnnotation || hasUpdatedTime)) {
      timelineItems.push({
        content: isCreatingAnnotation
          ? "New annotation will go here"
          : "Time after update",
        start: new Date((epochOffset + newAnnotationTime) / 1e6),
        group: TimelineGroup.ANNOTATIONS,
        className: classes.newAnnotationTime
      });
    }
    const isMutatingAnnotation = isCreatingAnnotation || !!updateAnnotationId;
    const pickTimeSnackbar =
      isMutatingAnnotation && !newAnnotationTime ? (
        <Snackbar open={true}>
          <MuiAlert severity={"error"} elevation={6} variant={"filled"}>
            Select a time in the timeline
          </MuiAlert>
        </Snackbar>
      ) : null;

    const highlightedGoal = (goals as Array<m_pb.GoalMetadata.AsObject>).find(
      g => g.id === highlightGoalId
    );
    const goalDetailsDialog = highlightedGoal ? (
      <GoalDetailsDialog
        goal={highlightedGoal}
        onClose={() => this.setState({ highlightGoalId: null })}
      />
    ) : null;

    return (
      <div className={classes.wrapper}>
        {createAnnotationDialog}
        {createSyncDemandDialog}
        {updateAnnotationDialog}
        {deleteAnnotationDialog}
        {pickTimeSnackbar}
        {goalDetailsDialog}
        <Card className={classes.card}>
          <CardHeader
            titleTypographyProps={{ className: classes.runHeader }}
            title={<Typography variant="h3">{runName}</Typography>}
          />
          <CardContent className={classes.logs}>
            {progressSpinner}
            {errorMsg}
            {this.state.isCopied && <Tooltip
                        x={window.innerWidth / 2}
                        y={window.innerHeight / 3}
                        displayText={"Robot time copied"}
                      />}
            {run && (
              <React.Fragment>
                <div className={classes.timeline}>
                  <Card raised>
                    <Timeline
                      options={{ width: "100%" }}
                      items={timelineItems}
                      groups={timelineGroups}
                      clickHandler={this.eventClickHandler}
                      doubleClickHandler={this.eventDoubleClickHandler}
                      mouseOverHandler={this.mouseOverHandler}
                      contextmenuHandler={this.rightClickHandler}
                    />
                    {tooltipProps && (
                      <Tooltip
                        x={tooltipProps.xPosition}
                        y={tooltipProps.yPosition}
                        displayText={tooltipProps.text}
                      />
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.hideManualModeFaults}
                          onChange={e =>
                            this.setState({ hideManualModeFaults: e.target.checked })
                          }
                        />
                      }
                      label="Hide Manual Mode Faults"
                    />
                  </Card>
                </div>
                <Card raised className={classes.annotations}>
                  {run && (
                    <React.Fragment>
                      <div className={classes.card}>
                        <Typography color={"textSecondary"}>Summary</Typography>
                        <Typography>{run.summary}</Typography>
                      </div>
                      <div className={classes.card}>
                        <Typography color={"textSecondary"}>Links</Typography>
                        <Link
                          target={"_blank"}
                          color="textPrimary"
                          href={robotLogsPath(run.robotName, run.runId)}
                        >
                          Text Logs
                        </Link>
                        {run.description.gitBranch &&
                          run.description.gitCommitHash && (
                            <Link
                              target={"_blank"}
                              color="textPrimary"
                              className={classes.smallPaddingLeft}
                              href={bitbucketSource(
                                run.description.gitCommitHash
                              )}
                            >
                              {`Code: ${run.description.gitBranch}@${run.description.gitCommitHash}`}
                            </Link>
                          )}
                      </div>
                      <div className={classes.card}>
                        <Typography color={"textSecondary"}>
                          Start command
                        </Typography>
                        <Typography>{run.description.startCommand}</Typography>
                      </div>
                      <div className={classes.card}>
                        <Typography color={"textSecondary"}>
                          Release channel
                        </Typography>
                        <Typography>{run.description.channel}</Typography>
                      </div>
                      <div className={classes.card}>
                        <Typography color={"textSecondary"}>
                          Flag file
                        </Typography>
                        <Typography>{run.flagFile}</Typography>
                      </div>
                      <div className={classes.card}>
                        <Typography color={"textSecondary"}>
                          Files Uploaded
                        </Typography>
                        <Typography>{run.numImportedLogFiles}/{run.numImportedLogFiles + run.numPendingLogFiles}</Typography>
                      </div>
                    </React.Fragment>
                  )}
                </Card>
                <Card raised className={classes.annotations}>
                  <div className={classes.annotationsHeader}>
                    <Typography variant={"h5"}>Annotations</Typography>
                    {!isCreatingAnnotation && (
                      <Button
                        onClick={() =>
                          this.setState({ isCreatingAnnotation: true })
                        }
                        className={classes.marginLeft}
                        color={"secondary"}
                      >
                        Create
                      </Button>
                    )}
                  </div>

                  {run.annotations.map((n: Annotation, i: number) => (
                    <React.Fragment key={n.id}>
                      <div className={classes.annotation}>
                        <div>
                          <Typography
                            color={"textSecondary"}
                          >{`${protoEnumFieldName(
                            cc_pb.InterventionType,
                            n.intervention
                          ).replace("INTERVENTION_", "")} (${
                            n.eventTime
                          }) `}</Typography>
                          <Typography className={classes.padLeft}>
                            {`${" " + n.description}`}
                          </Typography>
                        </div>
                        <div>
                          <IconButton
                            size={"small"}
                            onClick={() => {
                              this.setState({
                                updateAnnotationId: n.id,
                                newAnnotationTime: n.eventTime
                              });
                              this._addAnnotationHash(n.id);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            size={"small"}
                            onClick={() =>
                              this.setState({ deleteAnnotationId: n.id })
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                      {i < run.annotations.length - 1 && (
                        <Divider
                          className={classes.divider}
                          variant={"fullWidth"}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Card>
              </React.Fragment>
            )}
            <Card raised className={classes.stats}>
              <CardHeader title={"Stoppages"} />
              {stoppages.length && (
                <InterventionsSheet
                  omittedFieldNames={["runName"]}
                  stats={stoppages}
                />
              )}
            </Card>
            <Card raised className={classes.stats}>
              <CardHeader title={"Pick Stats"} />
              {pickStats.length && (
                <PickStatsSheet
                  stats={pickStats}
                  omittedFieldNames={["runName", "objectiveId"]}
                />
              )}
            </Card>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(RunPage))
);
