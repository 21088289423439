import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ProgressSpinner from "../Utils/ProgressSpinner";

import EventFilterSearchBar from "../Utils/EventFilterSearchBar";

import { ApplicationState } from "../../redux";
import { listExternalBugsRequest } from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { grpc } from "@improbable-eng/grpc-web";
import { logInPath } from "../../utils/Paths";
import Button from "@material-ui/core/Button";
import ExternalBugListItem from "./ExternalBugListItem";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    wrapper: {
      width: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "column"
    },
    card: {
      width: "100%"
    },
    smallPaddingLeft: {
      paddingLeft: 16
    },
    content: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: 0
    },
    logs: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      paddingTop: 0
    },
    referencesAndRuns: {
      display: "flex",
      justifyContent: "start",
      flexDirection: "column",
      width: 480,
      overflowX: "auto"
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  externalBugs: Array<m_pb.ExternalBug.AsObject>;
  runFilter: m_pb.EventFilter;
  isLoading: boolean;
  redirectTo: string | null;
  responseCount: number;
}

class ListRuns extends Component<Props, State> {
  state: State = {
    isLoading: false,
    runFilter: new m_pb.EventFilter(),
    redirectTo: null,
    externalBugs: [],
    responseCount: 0
  };

  _fetchMore() {
    this.setState({ isLoading: true }, () => {
      const finish = () => this.setState({ isLoading: false });
      const { runFilter, externalBugs } = this.state;
      const count = externalBugs.length;
      this.props
        .dispatch(
          listExternalBugsRequest({ pageToken: count, pageSize: 15 }, runFilter)
        )
        .then(({ count: responseCount, metadatas }: any) => {
          let { externalBugs } = this.state;
          // @ts-ignore
          externalBugs.push(...metadatas);
          this.setState({ responseCount, externalBugs });
          finish();
        })
        .catch((e: ServiceError) => {
          switch (e.code) {
            case grpc.Code.Unauthenticated: {
              this.setState({
                redirectTo: logInPath(window.location.pathname)
              });
              break;
            }
            // TODO(malcolm): Add pages for permission denied, 500 error
          }
        })
        .finally(finish);
    });
  }

  render() {
    const { classes } = this.props;
    const { isLoading, responseCount, redirectTo, externalBugs } = this.state;
    if (redirectTo !== null) {
      return <Redirect to={redirectTo as string} />;
    }
    const hasMore = responseCount > externalBugs.length;
    const progressSpinner = isLoading ? <ProgressSpinner /> : null;
    return (
      <div className={classes.wrapper}>
        <EventFilterSearchBar
          disable={isLoading}
          title={"Bugs"}
          onRequestSubmit={f =>
            this.setState({ externalBugs: [], runFilter: f }, () =>
              this._fetchMore()
            )
          }
        />
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <List component="nav" className={classes.root}>
              {externalBugs.map((c: m_pb.ExternalBug.AsObject) => {
                return <ExternalBugListItem key={c.id} externalBug={c} />;
              })}
            </List>
            {progressSpinner}
            <Button
              fullWidth
              disabled={isLoading || !hasMore}
              onClick={() => this._fetchMore()}
            >
              Load More
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(ListRuns));
