import React, { Component } from "react";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";

import { ApplicationState } from "../../redux";
import { Typography } from "@material-ui/core";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import UpdateCohortDialog from "./UpdateCohortDialog";

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    main: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center"
    },
    button: {
      margin: 0,
      padding: 0
    },
    collapse: {
      width: "100%",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      height: "100%"
    },
    robotName: {
      padding: 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "start"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  cohort: m_pb.ForkliftCohort.AsObject;
  organizations: Array<m_pb.Organization.AsObject>;
  sites: Array<m_pb.Site.AsObject>;
  dispatch: any;
  isAllExpanded: boolean;
}
interface State {
  open: boolean;
  editing: boolean;
}

class CohortListItem extends Component<Props, State> {
  state = {
    open: false,
    editing: false,
  };

  componentDidMount(): void {
    const { isAllExpanded } = this.props;
    this.setState({ open: isAllExpanded });
  }
  componentDidUpdate(prevProps: { isAllExpanded: boolean }) {
    const { isAllExpanded } = this.props;
    if (prevProps.isAllExpanded !== isAllExpanded)
      this.setState({ open: isAllExpanded });
  }
  render() {
    const { cohort, classes } = this.props;
    const { open } = this.state;
    return (
      <ListItem divider className={classes.listItem}>
        <div className={classes.main}>
          <ListItemText
            primary={cohort.displayName}
            secondary={cohort.site?.formattedSiteId}
          />
          <Button
            color={"secondary"}
            variant={"outlined"}
            onClick={() => this.setState({editing: true})}
          >
            Edit
          </Button>
          <IconButton
            className={classes.button}
            onClick={() => this.setState({ open: !open })}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <Collapse
          in={open}
          className={classes.collapse}
          timeout="auto"
          unmountOnExit
        >
          <div>
            {cohort.robotNamesList.map(robotName => {
              return (
                <div className={classes.robotName} key={robotName}>
                  <Typography>{`${robotName}`}</Typography>
                </div>
              );
            })}
          </div>
        </Collapse>
        <UpdateCohortDialog
          open={this.state.editing}
          cohortName={cohort.displayName}
          organizationName={cohort.organizationName}
          siteName={cohort.site?.formattedSiteId || ""}
          locationCode={cohort.locationCode}
          organizations={this.props.organizations}
          sites={this.props.sites}
          onClose={() => this.setState({ editing: false })}
          onSuccess={(updatedCohort: m_pb.ForkliftCohort) =>
            this.setState({
              editing: false
            })
          }
        />
      </ListItem>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(CohortListItem));
