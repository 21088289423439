import React, { Component } from "react";
import { VariableSizeGrid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";

import { ApplicationState, ViewerState } from "../../redux";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import Datasheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { run } from "../../utils/Paths";
import {
  Column,
  FormattedData,
  statsToRows,
  StylableCSVLink
} from "./StatSheetUtils";

export interface GridElement extends Datasheet.Cell<GridElement, number> {
  value: number | string;
}

class RunStatsReactDataSheet extends Datasheet<GridElement, number> {}

const styles = (theme: Theme) =>
  createStyles({
    sheet: {
      width: "100%",
      height: "100%",
      marginBottom: 24
    },
    wrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 24
    },
    cell: {
      border: "1px solid",
      textAlign: "center"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return { viewer: state.viewer };
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
  stats: Array<m_pb.RunStats.AsObject>;
  omittedFieldNames?: Array<string>;
  viewer: ViewerState;
}
interface State {
  rows: GridElement[][];
  topLeft: { rowIdx: number; colIdx: number };
  botRight: { rowIdx: number; colIdx: number };
}
const clipDigits = (val: number, places: number): number =>
  Math.round(val * 10 ** places) / 10 ** places;

const defaultColumnProperties = {};
type formatter = (val: number | string) => number | string;
const emptyFormatter: formatter = (v: number | string) => String(v);
const durationFormatter = (v: number) => clipDigits(v / 1e9, 2);
const noNewlineFormatter = (v: string) => v.replace(/[\r\n]+/gm, "; ");

class RunStatsSheet extends Component<Props, State> {
  state = {
    rows: [],
    topLeft: { rowIdx: 0, colIdx: 0 },
    botRight: { rowIdx: 0, colIdx: 0 }
  };
  columns: Array<Column> = [
    {
      key: "runName",
      name: "Run",
      formatter: emptyFormatter,
      componentMaker:
        this.props.viewer.account &&
        this.props.viewer.account.isStaff &&
        ((runName: string) => (
          <a href={run(runName)} target="_blank">
            {runName}
          </a>
        ))
    },
    {
      key: "versionName",
      name: "Version",
      formatter: emptyFormatter
    },
    {
      key: "loadDescription",
      name: "Load",
      formatter: noNewlineFormatter
    },
    {
      key: "setupDuration",
      name: "Setup Time (s)",
      formatter: durationFormatter
    },
    {
      key: "stoppageDuration",
      name: "Stoppage Time (s)",
      formatter: durationFormatter
    },
    {
      key: "totalDuration",
      name: "Total Runtime (s)",
      formatter: durationFormatter
    },
    {
      key: "placedPalletSuccessCount",
      name: "Successful Pallets",
      formatter: emptyFormatter
    },
    {
      key: "cohortName",
      name: "Site",
      formatter: emptyFormatter
    },
    {
      key: "tallMode",
      name: "Is tall?",
      formatter: emptyFormatter
    },
    {
      key: "placedPalletTotalCount",
      name: "# Pallets",
      formatter: emptyFormatter
    }
  ].map(c => ({ ...defaultColumnProperties, ...c }));

  render() {
    const { classes, stats, omittedFieldNames } = this.props;
    const formattedData: FormattedData = statsToRows(
      stats,
      omittedFieldNames || [],
      this.columns,
      defaultColumnProperties
    );
    // @ts-ignore
    const renderCell = ({ columnIndex, rowIndex, style }) => {
      const cell = formattedData.cells[rowIndex][columnIndex];
      return (
        <div style={style} className={classes.cell}>
          {cell.component || cell.value}
        </div>
      );
    };
    return (
      <div className={classes.wrapper}>
        <StylableCSVLink
          filename="all_runs.csv"
          csvData={formattedData.downloadable}
        />
        <div className={classes.sheet}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <VariableSizeGrid
                  columnWidth={index => {
                    // Allow a comfortable amount of room for each column
                    return (
                      10 *
                      Math.max(
                        ...formattedData.cells
                          .map(row => row[index])
                          .map(cellValue =>
                            cellValue.value.toString().indexOf(" ") >= 0
                              ? cellValue.value.toString().length / 2
                              : cellValue.value.toString().length
                          )
                      )
                    );
                  }}
                  rowHeight={() => {
                    return height / 15;
                  }}
                  columnCount={this.columns.length}
                  height={height}
                  rowCount={formattedData.cells.length}
                  width={width}
                >
                  {renderCell}
                </VariableSizeGrid>
              );
            }}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(RunStatsSheet));
