import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { connect } from "react-redux";

interface Props {
  loadingProgress: number;
}

const LabeledCircularProgress = (props: Props) => (
  <Box
    position="relative"
    display="inline-flex"
    marginTop="40px"
    marginBottom="40px"
  >
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="caption"
        component="div"
        color="textSecondary"
      >{`${Math.round(props.loadingProgress)}%`}</Typography>
    </Box>
  </Box>
);

export default connect()(LabeledCircularProgress);
