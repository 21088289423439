/**
 * @interface NormalizedEntities
 *
 * A convenience interface for a collection of entities, e.g. RunMetadata.
 *
 * See
 * https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape#designing-a-normalized-state
 */
 export interface NormalizedEntities<T, V> {
  // allIds is an array of entity IDs to indicate entity ordering.
  allIds: V[];
  byId: Map<V, T>;
}

export function orderedEntities<T, V>(
  entities: NormalizedEntities<T, V>
): Array<T> {
  const { allIds, byId } = entities;
  return allIds.map(id => byId.get(id)).filter(Boolean) as Array<T>;
}

export type hasId<T> = {
  id: T;
};

export function upsertEntity<T extends hasId<V>, V>(
  entity: T,
  state: NormalizedEntities<T, V>,
  sortComparer?: (a: T, b: T) => boolean
): NormalizedEntities<T, V> {
  let { allIds, byId } = state;
  byId.set(entity.id, entity);
  if (!allIds.includes(entity.id)) {
    allIds.push(entity.id);
    if (sortComparer) {
      const entityComparer = (idA: V, idB: V) => {
        const a = byId.get(idA);
        const b = byId.get(idB);
        if (!a || !b) {
          return 0;
        }
        return sortComparer(a, b) ? -1 : 1;
      };
      allIds.sort(entityComparer);
    }
  }
  return { allIds, byId };
}
