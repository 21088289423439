/**
 * protoFieldName returns the field name for a given proto enum class and field
 * value. E.g. for an enum Suits {CLUBS=0; HEARTS=1; DIAMONDS=2; SPADES=3;},
 * protoEnumFieldName(Suits, 2) will return "DIAMONDS".
 *
 * @param protoClass the Enum class
 * @param value the value of the enum field
 *
 * @throws an error if there is no field for the given value.
 */
export function protoEnumFieldName(protoClass: any, value: number): string {
  const values = Object.values(protoClass);
  let valueIndex = values.findIndex(v => v === value);
  if (valueIndex < 0) {
    console.warn(
      `Could not find value ${value} in ${protoClass}, defaulting to 0`
    );
    valueIndex = 0;
  }
  return Object.keys(protoClass)[valueIndex];
}
