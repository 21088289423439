import React, { Component } from "react";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import * as payloads from "../../redux/payloads";

import { ApplicationState } from "../../redux";
import { RunMetadata, Account } from "../../redux/payloads";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { upsertRunReviewRequest } from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const styles = (theme: Theme) =>
  createStyles({
    menuTitle: {
      textTransform: "capitalize"
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 16
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {
    accountsById: state.entities.accounts.byId,
    viewer: state.viewer.account
  };
};

interface Props extends WithStyles<typeof styles> {
  run: RunMetadata;
  viewer: Account | null;
  accountsById: Map<string, Account>;
  dispatch: any;
  onClose: () => void;
}
interface State {
  open: boolean;
  waitingOnUpsert: boolean;
}

class RunReviewersDialog extends Component<Props, State> {
  state = {
    open: false,
    waitingOnUpsert: false
  };

  _updateRunReview(review: m_pb.RunReview) {
    this.setState({ waitingOnUpsert: true }, () =>
      this.props
        .dispatch(upsertRunReviewRequest(review))
        .then(() =>
          this.setState({
            waitingOnUpsert: false
          })
        )
        .catch((e: ServiceError) => {
          alert(`Failed to delete attribute: ${e}`);
          this.setState({ waitingOnUpsert: false });
        })
    );
  }

  render() {
    const { run, classes, accountsById, viewer, onClose } = this.props;
    if (!viewer) {
      return null;
    }
    const { waitingOnUpsert } = this.state;
    const { runReviews } = run;
    const viewerReview: payloads.RunReview | undefined = runReviews.find(
      r => r.reviewerAccountId === viewer.id
    );
    const accountName = (accountId: string): string => {
      const account = accountsById.get(accountId);
      if (!account) {
        return accountId;
      }
      return account.email;
    };
    const reviewerIds = new Set(runReviews.map(r => r.reviewerAccountId));
    return (
      <Dialog
        fullWidth
        onClose={() => onClose()}
        aria-labelledby="simple-dialog-title"
        open={true}
      >
        <DialogTitle id="simple-dialog-title">Edit Run Reviewers</DialogTitle>
        <List>
          {!viewerReview && (
            <Button
              fullWidth
              disabled={waitingOnUpsert}
              onClick={() => {
                const review = new m_pb.RunReview();
                review.setStatus(
                  m_pb.RunReviewStatus.RUN_REVIEW_STATUS_STARTED
                );
                review.setReviewerAccountId(viewer.id);
                review.setRunMetadataId(run.id);
                this._updateRunReview(review);
              }}
            >
              START REVIEWING
            </Button>
          )}
          {run.runReviews.map((review: payloads.RunReview, i) => (
            <ListItem key={i}>
              <ListItemText primary={accountName(review.reviewerAccountId)} />
              <ListItemSecondaryAction>
                <Select
                  disabled={waitingOnUpsert}
                  className={classes.menuTitle}
                  inputProps={{ id: "component-select" }}
                  value={review.status}
                  onChange={e => {
                    review.status = parseInt(e.target.value as string);
                    this._updateRunReview(review.toProto());
                  }}
                >
                  {Object.entries(m_pb.RunReviewStatus).map(e => (
                    <MenuItem
                      key={e[1]}
                      value={e[1]}
                      className={classes.menuTitle}
                    >
                      {e[0]
                        .replace("RUN_REVIEW_STATUS_", "")
                        .replace(/_/g, " ")
                        .toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Divider />
        <div className={classes.row}>
          <Typography>Assign a reviewer:</Typography>
          <Select
            disabled={waitingOnUpsert}
            inputProps={{ id: "component-select" }}
            onChange={e => {
              const chosenId = e.target.value as string;
              if (!chosenId) {
                return;
              }
              const review = new m_pb.RunReview();
              review.setStatus(
                m_pb.RunReviewStatus.RUN_REVIEW_STATUS_UNREVIEWED
              );
              review.setReviewerAccountId(chosenId);
              review.setRunMetadataId(run.id);
              this._updateRunReview(review);
            }}
          >
            {Array.from(accountsById.entries())
              .filter(e => !reviewerIds.has(e[0]))
              .map(e => (
                <MenuItem key={e[0]} value={e[0]}>
                  {e[1].email}
                </MenuItem>
              ))}
          </Select>
        </div>
      </Dialog>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(RunReviewersDialog));
