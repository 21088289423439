import React, { Component } from "react";
import { isEqual, cloneDeep, isEmpty } from "lodash";
import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  fade,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import { combineStyles, commonStyles } from "./CommonStyles";
import {ApplicationState, ViewerState} from "../../redux";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import InputBase from "@material-ui/core/InputBase";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import moment from "moment-timezone";
import queryString from "query-string";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { InputLabel } from "@material-ui/core";
import { classicNameResolver } from "typescript";

const defaultRunAttributes = new Map();

const stringToEntry = (raw: string): [string, string] => {
  return raw.split(",") as [string, string];
};

const prepareMap = (map: Map<string, string>): Array<string> => {
  return Array.from(map.entries()).map(e => e.join(","));
};

const parseAttrs = (attrs: string | string[] | null | undefined) => {
  if (attrs === undefined || attrs === null) {
    return defaultRunAttributes;
  }
  if (attrs === "") {
    return new Map();
  }
  if (Array.isArray(attrs)) {
    return new Map(attrs.map(raw => stringToEntry(raw as string)));
  }
  return new Map([stringToEntry(attrs)]);
};

const localStyles = (theme: Theme) => ({
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  dates: {
    display: "flex",
    flexDirection: "row"
  },
  title: {
    display: "none",
    overflow: "visible",
    marginTop: 16,
    padding: "5px 10px",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  grow: {
    flexGrow: 1
  },
  search: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    marginTop: 16,
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    }
  },
  searchLabeled: {
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    "& > div > div": {
      width: 160,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: theme.shape.borderRadius // margin: 0
    },
    "& > div > label": {
      padding: 4
      // transform: "translate(4px, 8px) scale(1)"
    },
    "& > div > div > input": {
      padding: 8,
      minWidth: 100
      // transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    width: 160,
    marginRight: 16,
    marginLeft: 8
  },
  attributeInputs: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    padding: 2,
    marginLeft: 4,
    marginRight: 4,
    width: "100%",
    minWidth: 120,
    [theme.breakpoints.up("sm")]: {
      width: "auto"
    }
  },
  appBar: {
    overflowX: "auto",
    display: "flex",
    flexDirection: "row"
  },
  submitEl: {
    padding: 2,
    marginLeft: 8,
    marginRight: 8,
    minWidth: 60,
    width: "auto",
    maxWidth: 160,
    position: "sticky",
    right: 0,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    display: "flex",
    justifyContent: "center"
  },
  addAttributeBtn: {
    lineHeight: "unset"
  }
});
const styles = combineStyles(localStyles, commonStyles);

const mapStateToProps = (state: ApplicationState) => {
  const viewer = state.viewer.account;
  return { showCustomerView: viewer ? !viewer.isStaff : false };
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
  title: string;
  onEventFilterChange?: (f: m_pb.EventFilter) => any;
  // onRequestSubmit is fetch request
  onRequestSubmit?: (f: m_pb.EventFilter) => any;
  disable: boolean;
  showCustomerView: boolean;
  hideSubmitButton: boolean | null;
}

interface State {
  filter: m_pb.EventFilter;
  isDirty: boolean;
  isAddingAttribute: boolean;
  newAttributeKey: string;
  newAttributeValue: string;
  originalFilterValues: any;
}

class EventFilterSearchBar extends Component<
  Props & RouteComponentProps,
  State
> {
  state = {
    filter: new m_pb.EventFilter(),
    isDirty: false,
    isAddingAttribute: false,
    newAttributeKey: "",
    newAttributeValue: "",
    originalFilterValues: new m_pb.EventFilter()
  };

  maybeSubmit = () => {
    const {
      state: { isDirty, filter },
      props: { onRequestSubmit }
    } = this;
    if (!isDirty) {
      return;
    }
    this.setState(
      { isDirty: false, originalFilterValues: cloneDeep(filter) },
      () => onRequestSubmit && onRequestSubmit(filter)
    );
  };

  _submitForm = () => {
    // TODO validations
    this.maybeSubmit();
  };
  _areFiltersEmpty = (filter: m_pb.EventFilter): boolean => {
    return (
      isEmpty(filter.getRunNameLike()) &&
      isEmpty(filter.getRobotIdsList()) &&
      isEmpty(filter.getCohortDisplayNameLike()) &&
      isEmpty(filter.getOrganizationNameLike()) &&
      isEmpty(filter.getReleaseChannel()) &&
      isEmpty(filter.getVersionName()) &&
      isEmpty(filter.getMinimumRunMinutes()) &&
      isEmpty(filter.getLogUploadThreshold()) &&
      isEmpty(filter.getRunNote())
    );
  };

  componentWillMount(): void {
    const {
      maybeSubmit,
      props: { location, history },
      state: { filter }
    } = this;

    const values = queryString.parse(location.search);
    const startMs = values.startMs
      ? parseInt(values.startMs as string)
      : moment()
          .subtract(moment.duration({ days: 1 }))
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .valueOf();
    const autoEndNow =
      values.autoEndNow === undefined ? true : values.autoEndNow === "true";
    const endMs =
      !autoEndNow && values.endMs
        ? parseInt(values.endMs as string)
        : moment().valueOf();
    filter.setStartTime(startMs * 1e6);
    filter.setEndTime(endMs * 1e6);
    const runAttrsMap = parseAttrs(values.attrs);
    const toAttr = (k: string, v: string) => {
      const attr = new m_pb.RunAttributeFilter();
      attr.setKey(k);
      attr.setValue(v);
      return attr;
    };
    const attributes = Array.from(runAttrsMap.entries()).map(e =>
      toAttr(e[0], e[1])
    );
    filter.setAttributeFiltersList(attributes);

    const runName = (values.runName as string) || "";
    const robotName = (values.robotName as string) || "";
    const releaseChannel = (values.releaseChannel as string) || "";
    const cohort = (values.cohort as string) || "";
    const gitBranch = (values.gitBranch as string) || "";
    const flagFile = (values.flagFile as string) || "";
    const versionName = (values.versionName as string) || "";
    const minRunMinutes = (values.minRunMinutes as string) || "";
    const runNote = (values.runNote as string) || "";
    // Peter wants default value to be Fox
    let organizationName: string;
    if (values.organizationName === undefined) {
      organizationName = this.props.showCustomerView ? "" : "Fox";
    } else {
      organizationName = values.organizationName as string;
    }
    filter.setRunNameLike(runName);
    filter.setReleaseChannel(releaseChannel);
    filter.setCohortDisplayNameLike(cohort);
    filter.setGitBranchLike(gitBranch);
    filter.setRobotIdsList(robotName.length ? [robotName] : []);
    filter.setFlagFile(flagFile);
    filter.setVersionName(versionName);
    filter.setRunNote(runNote);
    filter.setOrganizationNameLike(organizationName);
    minRunMinutes &&
      filter.setMinimumRunMinutes(Number.parseFloat(minRunMinutes));
    history.push({
      search: queryString.stringify({
        ...values,
        startMs,
        endMs,
        autoEndNow,
        runName,
        releaseChannel,
        cohort,
        gitBranch,
        robotName,
        flagFile,
        versionName,
        runNote,
        minRunMinutes,
        organizationName,
        attrs: runAttrsMap.size ? prepareMap(runAttrsMap) : ""
      })
    });
    this.setState(
      {
        filter,
        isDirty: true,
        originalFilterValues: cloneDeep(filter)
      },
      () => maybeSubmit()
    );
  }

  renderValue(key: any, value: any) {
    const {
      props: { classes }
    } = this;
    const valueText = [];
    const keyText = (key.charAt(0).toUpperCase() + key.slice(1))
      .replace(/([a-z])([A-Z][^\s]*)/g, "$1 $2")
      .replace(/([A-Z])/g, " $1")
      .trim();
    switch (key) {
      case "attributeFiltersList":
        for (const item of value) {
          valueText.push(`${item.key}: ${item.value}`);
        }
        break;
      default:
        valueText.push(value);
    }
    const returnHtml = [];
    for (const index in valueText) {
      const item = [
        <span className={classes.queryText}>{keyText}: </span>,
        valueText[index]
      ];
      returnHtml.push(
        <Chip
          key={key}
          id={key}
          label={item}
          onDelete={() => this._deleteQueryAttribute(key)}
        />
      );
    }
    return returnHtml;
  }
  renderFilterQuery() {
    const {
      props: { classes },
      state: { originalFilterValues }
    } = this;
    const html = [];
    // only show filters that apply to results on page
    // dont show date filters bc no default value
    for (const [key, value] of Object.entries(
      originalFilterValues.toObject()
    )) {
      if (value && value != "" && !key.toString().includes("Time")) {
        const valueText = this.renderValue(key, value);
        html.push(<div className={classes.queryItem}>{valueText}</div>);
      }
    }
    return <>{html}</>;
  }
  render() {
    const {
      props: { classes, title, onRequestSubmit, showCustomerView },
      state: { filter, isDirty, isAddingAttribute, newAttributeKey },
      maybeSubmit,
      _areFiltersEmpty
    } = this;
    // force not disabled so bar can be touched while loading
    const disable = false;
    const robotIds = filter.getRobotIdsList();
    const robotName = robotIds.length === 1 ? robotIds[0] : "";
    const showAppliedFilters = !_areFiltersEmpty(filter);

    return (
      <div>
        <AppBar
          color={"default"}
          position="relative"
          style={{ flexDirection: "row" }}
        >
          {/* Title  */}
          <Typography
            color={"textSecondary"}
            className={classes.title}
            variant="h6"
            noWrap
          >
            {title}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {/* Dates  */}
            <div className={classes.dates}>
              <TextField
                label={"Starting at"}
                defaultValue={moment(filter.getStartTime() / 1e6).format(
                  "YYYY-MM-DDTHH:mm:ss"
                )}
                onChange={e => this._updateStartTime(e.target.value as string)}
                InputLabelProps={{
                  shrink: true
                }}
                type={"datetime-local"}
              />
              <TextField
                label={"Ending at"}
                defaultValue={moment(filter.getEndTime() / 1e6).format(
                  "YYYY-MM-DDTHH:mm:ss"
                )}
                onChange={e => this._updateEndTime(e.target.value as string)}
                InputLabelProps={{
                  shrink: true
                }}
                type={"datetime-local"}
              />
            </div>
            {/* Run Name  */}
            <div className={classes.searchLabeled}>
              <TextField
                onChange={e => {
                  this._updateRunNameLike(e.currentTarget.value);
                }}
                onKeyDown={e => e.key === "Enter" && maybeSubmit()}
                disabled={disable}
                label="Run Name"
                value={filter.getRunNameLike()}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            {/* Robot Name  */}
            <div className={classes.searchLabeled}>
              <TextField
                onKeyDown={e => e.key === "Enter" && maybeSubmit()}
                onChange={e => {
                  this._updateRobotName(e.currentTarget.value);
                }}
                value={robotName}
                disabled={disable}
                label="Robot Name"
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            {/* Cohort  */}
            <div className={classes.searchLabeled}>
              <TextField
                onChange={e => {
                  this._updateCohort(e.currentTarget.value);
                }}
                value={filter.getCohortDisplayNameLike()}
                onKeyDown={e => e.key === "Enter" && maybeSubmit()}
                disabled={disable}
                onSubmit={() => onRequestSubmit && onRequestSubmit(filter)}
                label="Cohort"
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            {/* Org Name  */}
            <div className={classes.searchLabeled}>
              <TextField
                onChange={e => {
                  this._updateOrganizationName(e.currentTarget.value);
                }}
                value={filter.getOrganizationNameLike()}
                onKeyDown={e => e.key === "Enter" && maybeSubmit()}
                disabled={disable}
                onSubmit={() => onRequestSubmit && onRequestSubmit(filter)}
                label="Organization Name"
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            {/* Release Channel  */}
            {!showCustomerView && (
              <div className={classes.searchLabeled}>
                <TextField
                  onChange={e => {
                    this._updateReleaseChannel(e.currentTarget.value);
                  }}
                  value={filter.getReleaseChannel()}
                  onKeyDown={e => e.key === "Enter" && maybeSubmit()}
                  disabled={disable}
                  onSubmit={() => onRequestSubmit && onRequestSubmit(filter)}
                  label="Release Channel"
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
            )}
            {/* Version Name  */}

            <div className={classes.searchLabeled}>
              <TextField
                onChange={e => {
                  this._updateVersionName(e.currentTarget.value);
                }}
                value={filter.getVersionName()}
                onKeyDown={e => e.key === "Enter" && maybeSubmit()}
                disabled={disable}
                onSubmit={() => onRequestSubmit && onRequestSubmit(filter)}
                label="Version Name"
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            {/* Run Minutes */}
            {!showCustomerView && (
              <div className={classes.searchLabeled}>
                <TextField
                  onKeyDown={e => e.key === "Enter" && maybeSubmit()}
                  onChange={e => {
                    this._updateMinimumRunMinutes(e.currentTarget.value);
                  }}
                  type={"number"}
                  value={filter.getMinimumRunMinutes() || ""}
                  disabled={disable}
                  label="Run Minutes"
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
            )}

            {/* Log Upload Threshold */}
            {!showCustomerView && (
              <div className={classes.searchLabeled}>
                <TextField
                  onChange={e => {
                    this._updateLogUploadThreshold(e.currentTarget.value);
                  }}
                  onKeyDown={e => e.key === "Enter" && maybeSubmit()}
                  disabled={disable}
                  onSubmit={() => onRequestSubmit && onRequestSubmit(filter)}
                  label="Log Upload Threshold"
                  value={filter.getLogUploadThreshold()}
                  inputProps={{
                    "aria-label": "search",
                    type: "number",
                    step: 0.01,
                    min: 0,
                    max: 1
                  }}
                />
              </div>
            )}
            {/* Run Note */}
            {!showCustomerView && (
              <div className={classes.searchLabeled}>
                <TextField
                  onChange={e => {
                    this._updateRunNote(e.currentTarget.value);
                  }}
                  onKeyDown={e => e.key === "Enter" && maybeSubmit()}
                  disabled={disable}
                  onSubmit={() => onRequestSubmit && onRequestSubmit(filter)}
                  label="Note"
                  value={filter.getRunNote()}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
            )}

            {/* resulting chips */}
            <div className={classes.chipAttributes}>
              {Array.from(filter.getAttributeFiltersList()).map(a => {
                const key = a.getKey();
                const val = a.getValue();
                return (
                  <Chip
                    key={key}
                    label={`${key}: ${val}`}
                    onDelete={() => this._deleteRunAttribute(key)}
                  />
                );
              })}
            </div>
            {!showCustomerView && (
              <Paper className={classes.chipArray}>
                {!isAddingAttribute && (
                  <Button
                    color={"secondary"}
                    size={"small"}
                    variant={"contained"}
                    onClick={() => this.setState({ isAddingAttribute: true })}
                    className={classes.addAttributeBtn}
                  >
                    Add Attribute
                  </Button>
                )}
                {/* add form */}
                {isAddingAttribute && (
                  <div className={classes.addAttributes}>
                    <div className={classes.attributeInputs}>
                      <InputBase
                        placeholder={"key"}
                        onChange={e =>
                          this.setState({ newAttributeKey: e.target.value })
                        }
                      />
                      <InputBase
                        placeholder={"value"}
                        onChange={e =>
                          this.setState({
                            newAttributeValue: e.target.value
                          })
                        }
                      />
                    </div>

                    <Button
                      disabled={!newAttributeKey.length}
                      size={"small"}
                      color={"primary"}
                      variant={"contained"}
                      onClick={() => this._addRunAttribute()}
                    >
                      Submit
                    </Button>
                    <Button
                      size={"small"}
                      onClick={() =>
                        this.setState({ isAddingAttribute: false })
                      }
                      color={"secondary"}
                      variant={"contained"}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </Paper>
            )}
          </Toolbar>
          {/* Submit Button */}
          {!this.props.hideSubmitButton && <div className={classes.submitEl}>
            <Button
              className={isDirty ? classes.isDirty : ""}
              color={"secondary"}
              size={"small"}
              variant={"contained"}
              onClick={this._submitForm}
            >
              Submit
            </Button>
          </div>}
        </AppBar>

        {showAppliedFilters && (
          <AppBar
            color={"default"}
            position="relative"
            style={{ flexDirection: "row" }}
          >
            <Toolbar className={`${classes.toolbar} ${classes.querybar}`}>
              {this.renderFilterQuery()}
            </Toolbar>
          </AppBar>
        )}
      </div>
    );
  }

  /* 
    state change functions 
  */
  _deleteRunAttribute(key: string) {
    const { filter } = this.state;
    const attributes = filter
      .getAttributeFiltersList()
      .filter(attr => attr.getKey() !== key);
    filter.setAttributeFiltersList(attributes);
    this.setState({
      newAttributeKey: "",
      newAttributeValue: "",
      isAddingAttribute: false,
      filter
    });
  }

  _deleteQueryAttribute(id: string) {
    const {
      props: { onRequestSubmit },
      state: { filter, originalFilterValues }
    } = this;

    switch (id) {
      case "minimumRunMinutes":
        this._updateMinimumRunMinutes("");
        break;
      case "robotIdsList":
        this._updateRobotName("");
        break;
      case "runNameLike":
        this._updateRunNameLike("");
        break;
      case "gitBranchLike":
        this._updateGitBranch("");
        break;
      case "cohortDisplayNameLike":
        this._updateCohort("");
        break;
      case "releaseChannel":
        this._updateReleaseChannel("");
        break;
      case "flagFile":
        this._updateFlagFile("");
        break;
      case "versionName":
        this._updateVersionName("");
        break;
      case "runNote":
        this._updateRunNote("");
        break;
      case "organizationNameLike":
        this._updateOrganizationName("");
        break;
      default:
    }
    this.setState(
      { filter, isDirty: false, originalFilterValues: cloneDeep(filter) },
      () => onRequestSubmit && onRequestSubmit(filter)
    );
  }
  _addRunAttribute() {
    const { filter, newAttributeKey, newAttributeValue } = this.state;
    const newAttribute = new m_pb.RunAttributeFilter();
    newAttribute.setKey(newAttributeKey);
    newAttribute.setValue(newAttributeValue);
    const attributes = filter
      .getAttributeFiltersList()
      .filter(attr => attr.getKey() !== newAttributeKey);
    attributes.push(newAttribute);
    filter.setAttributeFiltersList(attributes);
    this.setState({
      newAttributeKey: "",
      newAttributeValue: "",
      isAddingAttribute: false,
      isDirty: !isEqual(this.state.originalFilterValues, filter),
      filter
    });
  }

  _updateRunNameLike(name: string) {
    const { filter } = this.state;
    filter.setRunNameLike(name);

    const values = queryString.parse(this.props.location.search);
    values.runName = name;
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateReleaseChannel(channel: string) {
    const { filter } = this.state;
    filter.setReleaseChannel(channel);

    const values = queryString.parse(this.props.location.search);
    values.releaseChannel = channel;
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateCohort(cohort: string) {
    const { filter } = this.state;
    filter.setCohortDisplayNameLike(cohort);

    const values = queryString.parse(this.props.location.search);
    values.cohort = cohort;
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateFlagFile(channel: string) {
    const { filter } = this.state;
    filter.setFlagFile(channel);

    const values = queryString.parse(this.props.location.search);
    values.flagFile = channel;
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateVersionName(channel: string) {
    const { filter } = this.state;
    filter.setVersionName(channel);

    const values = queryString.parse(this.props.location.search);
    values.versionName = channel;
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateGitBranch(branch: string) {
    const { filter } = this.state;
    filter.setGitBranchLike(branch);

    const values = queryString.parse(this.props.location.search);
    values.gitBranch = branch;
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateRobotName(name: string) {
    const { filter } = this.state;
    filter.setRobotIdsList(name.length ? [name] : []);

    const values = queryString.parse(this.props.location.search);
    values.robotName = name;
    this.props.history.push({ search: queryString.stringify(values) });
    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateMinimumRunMinutes(minimumRunMinutes: string) {
    const { filter } = this.state;
    const minutes = Number.parseFloat(minimumRunMinutes) || 0;
    filter.setMinimumRunMinutes(minutes);

    const values = queryString.parse(this.props.location.search);
    values.minRunMinutes = minimumRunMinutes;
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateRunNote(runNote: string) {
    const { filter } = this.state;
    filter.setRunNote(runNote);

    const values = queryString.parse(this.props.location.search);
    values.runNote = runNote;
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateLogUploadThreshold(logUploadThreshold: string) {
    const { filter } = this.state;
    filter.setLogUploadThreshold(parseFloat(logUploadThreshold));

    const values = queryString.parse(this.props.location.search);
    values.logUploadThreshold = logUploadThreshold;
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateStartTime(rawTime: string) {
    const startTime = moment(rawTime);
    const startMs = startTime.valueOf();
    const { filter } = this.state;
    filter.setStartTime(startMs * 1e6);

    const values = queryString.parse(this.props.location.search);
    values.startMs = startTime.valueOf().toString();
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateEndTime(rawTime: string) {
    const endTime = moment(rawTime);
    const endMs = endTime.valueOf();
    const { filter } = this.state;
    filter.setEndTime(endMs * 1e6);

    const values = queryString.parse(this.props.location.search);
    values.endMs = endTime.valueOf().toString();
    values.autoEndNow = "false";
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }

  _updateOrganizationName(organizationName: string) {
    const { filter } = this.state;
    filter.setOrganizationNameLike(organizationName);

    const values = queryString.parse(this.props.location.search);
    values.organizationName = organizationName;
    this.props.history.push({ search: queryString.stringify(values) });

    this.setState({
      filter,
      isDirty: !isEqual(this.state.originalFilterValues, filter)
    });
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(EventFilterSearchBar))
);
