import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles,
  fade
} from "@material-ui/core/styles";
import { Button, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
import memoize from "memoize-one";
import ReactDiffViewer from "react-diff-viewer";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import RefreshIcon from "@material-ui/icons/Refresh";

import moment from "moment";

import {
  ConfigurationFileRevision,
  ConfigurationRevisionMetadata,
  GetConfigurationFileRevisionRequest,
  RobotConfigurationRevisionState
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { getConfigurationFileRevisionRequest } from "../../redux/actions";
import { ApplicationState } from "../../redux";
import Typography from "@material-ui/core/Typography";
import { ServiceError } from "../../_proto/command_control/proto/command_control_pb_service";
import CreateConfigFileModal from "./CreateConfigFileModal";
import { Account } from "../../redux/payloads";
import ErrorIcon from "@material-ui/icons/Error";
import { combineStyles, commonStyles } from "../Utils/CommonStyles";
import { isEmpty } from "lodash";

const localStyles = (theme: Theme) =>
  createStyles({
    table: {
      minWidth: 800
    },
    container: {
      padding: 20
    },
    closePreview: {
      float: "right",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25)
      }
    },
    row: {
      "& > *": {
        borderBottom: "unset"
      }
    },
    filePreview: {
      maxHeight: 400,
      overflow: "auto"
    },
    diffMessage: {
      fontSize: 20
    },
    actionIconTableCell: {
      align: "right",
      width: "64px",
      height: "70px"
    },
    actionIcon: {
      cursor: "pointer",
      textAlign: "center",
      "&:hover": {
        fontSize: "xx-large",
        float: "unset",
        opacity: 0.25
      }
    }
  });
const styles = combineStyles(localStyles, commonStyles);

const mapStateToProps = (state: ApplicationState) => {
  return {
    monitoringAccounts: state.entities.accounts.byId
  };
};

interface Props extends WithStyles<typeof styles> {
  description: string;
  revisions: ConfigurationRevisionMetadata[];
  dispatch: any;
  entityType: string;
  entityIdentifier: string;
  entityCount: number;
  configId: string;
  reloadData: () => void;
  activeRevisions: RobotConfigurationRevisionState[];
  monitoringAccounts: Map<string, Account>;
  alertError: any;
  allowCreateFile: boolean;
}
interface State {
  expandedRevisionIds: string[];
  diffedRevisionIds: Map<string, string>;
  retrievedFileRevisions: Map<string, string>;
  editingRevisionId: string;
}

class ConfigurationRevisionHistory extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expandedRevisionIds: [],
      diffedRevisionIds: new Map<string, string>(),
      retrievedFileRevisions: new Map<string, string>(),
      editingRevisionId: ""
    };
  }

  _fetchRevisions = (revisionIds: string[]) => {
    const promises = new Array<Promise<any>>();
    revisionIds.forEach(revisionId => {
      if (!this.state.retrievedFileRevisions.has(revisionId)) {
        promises.push(this._fetchRevision(revisionId));
      }
    });
    return Promise.all(promises);
  };

  _fetchRevision = (revisionId: string) => {
    const request = new GetConfigurationFileRevisionRequest();
    request.setRevisionId(revisionId);
    return new Promise((resolve, reject) => {
      this.props
        .dispatch(getConfigurationFileRevisionRequest(request))
        .then((payload: ConfigurationFileRevision) => {
          const newRetrievedFileRevisions = new Map(
            this.state.retrievedFileRevisions
          );
          newRetrievedFileRevisions.set(
            revisionId,
            new TextDecoder("utf-8").decode(payload.getContents_asU8())
          );
          this.setState({ retrievedFileRevisions: newRetrievedFileRevisions });
          resolve();
        })
        .catch((e: ServiceError) => {
          console.error(e);
          this.props.alertError(e);
          reject();
        });
    });
  };

  _displayRevision = (revisionId: string) => () => {
    if (!this.state.retrievedFileRevisions.has(revisionId)) {
      this._fetchRevision(revisionId).then(() => {
        this.setState({
          expandedRevisionIds: [...this.state.expandedRevisionIds, revisionId]
        });
      });
    } else {
      this.setState({
        expandedRevisionIds: [...this.state.expandedRevisionIds, revisionId]
      });
    }
  };

  _displayDiff = (revisionId: string) => () => {
    if (this.props.revisions.length === 1) {
      return;
    }
    const revisionIndex = this.props.revisions.findIndex(
      revision => revision.getRevisionId() === revisionId
    );
    // By default, compare with the latest version
    // (or if latest version was selected, diff w/ previous)
    const diffWithRevisionIndex = revisionIndex === 0 ? revisionIndex + 1 : 0;
    const diffWithRevisionId =
      this.props.revisions[diffWithRevisionIndex].getRevisionId();
    this._fetchRevisions([revisionId, diffWithRevisionId]).then(() => {
      this.setState({
        diffedRevisionIds: new Map(
          this.state.diffedRevisionIds.set(revisionId, diffWithRevisionId)
        )
      });
    });
  };

  _collapseRevision = (revisionId: string) => () => {
    const expandedRevisionIds = [...this.state.expandedRevisionIds];
    const index = expandedRevisionIds.indexOf(revisionId);
    this.state.diffedRevisionIds.delete(revisionId);
    if (index >= 0) {
      expandedRevisionIds.splice(index, 1);
    }
    this.setState({
      expandedRevisionIds,
      diffedRevisionIds: new Map(this.state.diffedRevisionIds)
    });
  };

  _editRevision = (revisionId: string) => () => {
    if (!this.state.retrievedFileRevisions.has(revisionId)) {
      this._fetchRevision(revisionId).then(() => {
        this.setState({
          editingRevisionId: revisionId
        });
      });
    } else {
      this.setState({
        editingRevisionId: revisionId
      });
    }
  };

  _constructTableRow = (
    metadata: ConfigurationRevisionMetadata,
    index: number,
    conflictedRobotNames: String[],
    robotStateByRevisionId: Map<String, RobotConfigurationRevisionState[]>
  ) => {
    // TODO: only show controls that are applicable based on file extension/human readability
    // TODO: add context on any conflicts
    const editorMonitoringAccount = this.props.monitoringAccounts.get(
      metadata.getEditorAccountId()
    );
    const revisionCreator =
      metadata.getEditorRobotName() ||
      (editorMonitoringAccount && editorMonitoringAccount.email);
    const robotsAppliedCount = (
      robotStateByRevisionId.get(metadata.getRevisionId()) || []
    ).length;

    return (
      <>
        <TableRow
          key={metadata.getRevisionId()}
          className={this.props.classes.row}
        >
          <TableCell component="th" scope="row">
            {index === 0 && this.props.entityCount - robotsAppliedCount > 0
              ? "Pending on " +
                (this.props.entityCount - robotsAppliedCount) +
                " robots"
              : ""}
          </TableCell>
          <TableCell align="right">
            {"created: " +
              moment(Number.parseInt(metadata.getTimestamp()) / 1000000).format(
                "ddd, MMM DD YYYY, h:mm:ss a"
              ) +
              " by " +
              revisionCreator}
          </TableCell>
          <TableCell align="right">
            {this.props.entityType === "robot"
              ? robotStateByRevisionId.has(metadata.getRevisionId())
                ? "Applied on " + this.props.entityIdentifier
                : null
              : robotStateByRevisionId.has(metadata.getRevisionId())
              ? "Applied on " +
                (robotStateByRevisionId.get(metadata.getRevisionId()) || [])
                  .map(robotState => {
                    return robotState.getRobotName();
                  })
                  .join(", ")
              : null}
          </TableCell>
          <TableCell align="right" color="error">
            {index === 0 && conflictedRobotNames.length > 0 ? (
              <>
                <Tooltip title={conflictedRobotNames.join(",")} arrow>
                  <ErrorIcon color="error" />
                </Tooltip>
                <span>
                  Conflicts with active version on {conflictedRobotNames.length}{" "}
                  robots
                </span>
              </>
            ) : (
              ""
            )}
          </TableCell>
          <TableCell className={this.props.classes.actionIconTableCell}>
            {index === 0 && this.props.allowCreateFile ? (
              <Tooltip title="Edit">
                <EditIcon
                  onClick={this._editRevision(metadata.getRevisionId())}
                  className={this.props.classes.actionIcon}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </TableCell>
          <TableCell className={this.props.classes.actionIconTableCell}>
            <Tooltip title="View">
              <VisibilityIcon
                onClick={this._displayRevision(metadata.getRevisionId())}
                className={this.props.classes.actionIcon}
              />
            </Tooltip>
          </TableCell>
          <TableCell className={this.props.classes.actionIconTableCell}>
            <Tooltip title="Compare">
              <CompareArrowsIcon
                className={this.props.classes.actionIcon}
                onClick={this._displayDiff(metadata.getRevisionId())}
              />
            </Tooltip>
          </TableCell>
          <TableCell className={this.props.classes.actionIconTableCell}>
            <Tooltip title="Download">
              <CloudDownloadIcon
                className={this.props.classes.actionIcon}
                onClick={this._downloadFile(metadata)}
              />
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse
              in={
                this.state.expandedRevisionIds.includes(
                  metadata.getRevisionId()
                ) || this.state.diffedRevisionIds.has(metadata.getRevisionId())
              }
              timeout="auto"
              unmountOnExit
            >
              {this._renderPreviewOrDiff(metadata.getRevisionId())}
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  _renderPreviewOrDiff = (revisionId: string) => {
    // @ts-ignore
    return (
      <Container maxWidth="md">
        <CloseIcon
          className={this.props.classes.closePreview}
          onClick={this._collapseRevision(revisionId)}
        />
        <Paper
          className={this.props.classes.filePreview}
          elevation={4}
          style={{
            marginBottom: 20,
            marginTop: 20,
            padding: 12,
            whiteSpace: "pre-line"
          }}
        >
          {this.state.diffedRevisionIds.has(revisionId) ? (
            <div>
              <span className={this.props.classes.diffMessage}>
                Diffing{" "}
                {
                  <FormControl>
                    <Select
                      value={this.state.diffedRevisionIds.get(revisionId) || ""}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        this.setState({
                          diffedRevisionIds: new Map(
                            this.state.diffedRevisionIds.set(
                              revisionId,
                              event.target.value as string
                            )
                          )
                        });
                      }}
                    >
                      {this.props.revisions
                        .filter(
                          revision => revision.getRevisionId() !== revisionId
                        )
                        .map(revision => {
                          return (
                            <MenuItem value={revision.getRevisionId()}>
                              {this._getDisplayTimestamp(
                                revision.getTimestamp()
                              )}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                }{" "}
                revision with this version
              </span>
              <ReactDiffViewer
                oldValue={this.state.retrievedFileRevisions.get(
                  this.state.diffedRevisionIds.get(revisionId) || ""
                )}
                newValue={this.state.retrievedFileRevisions.get(revisionId)}
                splitView={true}
              />
            </div>
          ) : (
            <Typography variant="body2">
              {this.state.retrievedFileRevisions.get(revisionId)}
            </Typography>
          )}
        </Paper>
      </Container>
    );
  };

  _downloadFile = (metadata: ConfigurationRevisionMetadata) => () => {
    const filename = metadata.getPath().split("/").slice(-1)[0];
    this._fetchRevisions([metadata.getRevisionId()]).then(() => {
      const file_contents =
        this.state.retrievedFileRevisions.get(metadata.getRevisionId()) || "";
      let blob = new Blob([file_contents], {
        type: "application/octet-stream;"
        // type: "text/csv;charset=utf8;"
      });

      // create hidden link
      let element = document.createElement("a");
      document.body.appendChild(element);
      element.setAttribute("href", window.URL.createObjectURL(blob));
      element.setAttribute("download", filename);
      element.style.display = "";
      element.click();
      document.body.removeChild(element);
    });
  };
  _getDisplayTimestamp = (timestamp: string) => {
    return moment(Number.parseInt(timestamp) / 1000000).format(
      "ddd, MMM DD YYYY, h:mm:ss a"
    );
  };

  _getRevisionHistoryContent = (
    conflictedRobotNames: String[],
    robotStateByRevisionId: Map<String, RobotConfigurationRevisionState[]>
  ) => {
    return (
      <TableContainer component={Paper}>
        <Table className={this.props.classes.table} aria-label="simple table">
          <TableBody>
            {this.props.revisions.map((revision, index) => {
              return this._constructTableRow(
                revision,
                index,
                conflictedRobotNames,
                robotStateByRevisionId
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  _getActiveTimestamps = (
    robotStateByRevisionId: Map<String, RobotConfigurationRevisionState[]>
  ): Array<string> => {
    return Array.from(robotStateByRevisionId.keys()).map(revisionId => {
      const revision = this.props.revisions.find(
        revision => revision.getRevisionId() === revisionId
      );
      if (revision) {
        return revision.getTimestamp();
      }
      return "0";
    });
  };

  _getRobotStateInfo = memoize(
    (activeRevisions: RobotConfigurationRevisionState[]) => {
      const conflictedRobotNames = [];
      const robotStateByRevisionId = new Map<
        string,
        RobotConfigurationRevisionState[]
      >();
      for (let robotState of activeRevisions) {
        const revisionId = robotState.getActiveRevisionId();
        if (robotState.getCurrentRevisionConflict()) {
          conflictedRobotNames.push(robotState.getRobotName());
        }
        if (robotStateByRevisionId.has(revisionId)) {
          const robotStatesForRevision =
            robotStateByRevisionId.get(revisionId) || [];
          robotStatesForRevision.push(robotState);
          robotStateByRevisionId.set(revisionId, robotStatesForRevision);
        } else {
          robotStateByRevisionId.set(revisionId, [robotState]);
        }
      }
      return {
        conflictedRobotNames,
        robotStateByRevisionId
      };
    }
  );

  render() {
    const {
      props: {
        classes,
        revisions,
        description,
        entityType,
        entityIdentifier,
        configId,
        reloadData,
        activeRevisions
      },
      state: { editingRevisionId, retrievedFileRevisions }
    } = this;
    const { conflictedRobotNames, robotStateByRevisionId } =
      this._getRobotStateInfo(activeRevisions);
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h6">
            Revision History for: {revisions[0].getPath()}
          </Typography>
          <Button onClick={reloadData} startIcon={<RefreshIcon />}>
            Refresh
          </Button>
        </div>

        {this._getRevisionHistoryContent(
          conflictedRobotNames,
          robotStateByRevisionId
        )}

        {editingRevisionId ? (
          <CreateConfigFileModal
            open={true}
            entityType={entityType}
            entityIdentifier={entityIdentifier}
            onModalClose={() => {
              this.setState({ editingRevisionId: "" });
            }}
            onSuccess={() => {
              reloadData();
              this.setState({ editingRevisionId: "" });
            }}
            configId={configId}
            stringData={retrievedFileRevisions.get(editingRevisionId)}
            filepath={revisions[0].getPath()}
            parentTimestamps={[
              revisions[0].getTimestamp(),
              ...this._getActiveTimestamps(robotStateByRevisionId)
            ]}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(ConfigurationRevisionHistory)
);
