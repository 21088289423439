import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";

interface Props {
  disabled: boolean;
  className: string;
  color: "inherit" | "primary" | "secondary" | "default";
  fileContents: string;
  filename: string;
  label: string;
}


const _downloadFile = (fileContents: string, filename: string) => () => {
  let blob = new Blob([fileContents], {
    type: "application/octet-stream;"
  });

  // create hidden link
  let element = document.createElement("a");
  document.body.appendChild(element);
  element.setAttribute("href", window.URL.createObjectURL(blob));
  element.setAttribute("download", filename);
  element.style.display = "";
  element.click();
  document.body.removeChild(element);
}

const FileDownloadButton = (props: Props) => (
  <Button
    disabled={props.disabled}
    className={props.className}
    variant="outlined"
    component="label"
    color={props.color}
    onClick={_downloadFile(props.fileContents, props.filename)}
  >
    {props.label}
  </Button>
);

export default connect()(FileDownloadButton);
