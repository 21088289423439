import {connect} from "react-redux";
import {Box, Button, withStyles} from "@material-ui/core";
import {createStyles, WithStyles} from "@material-ui/core/styles";

import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import FoxGrid from "../FoxGrid/foxGrid";
import {CloudUpload} from "@material-ui/icons";
import {Column} from "react-table";
import React, {useMemo} from "react";
import {protoEnumFieldName} from "../../utils/protos";

const styles = () => createStyles({});

interface Props<T extends object> extends WithStyles<typeof styles> {
  dispatch: any;
  faultTranslations: cc_pb.FaultTranslation.AsObject[];
  editFaultQuestions: (faultTranslation: cc_pb.FaultTranslation.AsObject) => void;
}

const FaultTable = <T extends object>(props: Props<T>) => {

  const columns: Column<cc_pb.FaultTranslation.AsObject>[] = useMemo(
      () => [
        {
          Header: 'Autonomy Fault Code',
          accessor: 'faultCode',
        },
        {
          Header: 'Autonomy Fault Type',
          accessor: 'faultType',
        },
        {
          Header: 'Numeric Code',
          accessor: 'numericCode',
        },
        {
          Header: 'Fault Message',
          accessor: 'faultDescription',
        },
        {
          Header: 'Suggested Intervention',
          accessor: 'faultSuggestedIntervention',
        },
        {
          Header: 'Avoidable by Robot default',
          accessor: (faultTranslation) => !!faultTranslation.avoidableByRobotDefault ? "Yes" : "No",
        },
        {
          Header: 'Has operator feedback questions',
          accessor: (faultTranslation) => !!faultTranslation.firstOperatorQuestion ? "Yes" : "No",
        },
        {
          Header: " ",
          accessor: (faultTranslation) => <EditOperatorQuestionsButton
              faultTranslation={faultTranslation}
              onClickHandler={() => props.editFaultQuestions(faultTranslation)}
          />,
        },
      ],
      []
    );

  return <FoxGrid
            title={'Fault Text'}
            data={props.faultTranslations}
            columns={columns as Column<any>[]}
  />;
}

interface EditOperatorQuestionsProps<T extends object> {
  faultTranslation: cc_pb.FaultTranslation.AsObject;
  onClickHandler: () => void;
}

const EditOperatorQuestionsButton = <T extends object>(props: EditOperatorQuestionsProps<T>) => {
    const {faultTranslation, onClickHandler} = props;
    return <Button
        variant="outlined"
        onClick={onClickHandler}
    >{faultTranslation.firstOperatorQuestion ? "View/Edit Questions" : "Add Questions"}</Button>;
}

export default connect()(withStyles(styles)(FaultTable));
