import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router";
import moment from "moment-timezone";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { secondary } from "../../App";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CardContent from "@material-ui/core/CardContent";
import ProgressSpinner from "../Utils/ProgressSpinner";
import queryString from "query-string";
import EventFilterSearchBar from "../Utils/EventFilterSearchBar";
import ChartInterventionFaultTimeSeries from "./ChartInterventionFaultTimeSeries";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import { ApplicationState } from "../../redux";
import {
  listFaultCodesRequest,
  listFaultsEventFilterRequest,
  listPickStatsRequest,
  listStoppagesRequest,
  listInterventionsRequest,
} from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import { logInPath } from "../../utils/Paths";
import { combineStyles, commonStyles } from "../Utils/CommonStyles";
import ChartStoppageCause from "./ChartStoppageCause";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FaultTimeSeriesChart from "./FaultTimeSeriesChart";
import Button from "@material-ui/core/Button";

const localStyles = (theme: Theme) =>
  createStyles({
    root: {},
    wrapper: {},
    card: { paddingTop: 0, paddingLeft: 0, paddingRight: 0 },
    content: {
      height: 700,
      width: "100%"
    },
    chipArray: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap"
    },
    textField: {
      margin: 4,
      marginLeft: 8,
      marginRight: 8
    },
    csvLink: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      color: secondary[400]
    }
  });
const styles = combineStyles(localStyles, commonStyles);

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  isLoading: boolean;
  loadingProgress: number;
  bucketWidth: number;
  redirectTo: string | null;
  timeSeries: m_pb.FaultCountTimeSeries | null;
  eventFilter: m_pb.EventFilter;
  chartType: string;
  stoppages: Array<m_pb.Stoppage.AsObject>;
  pickStats: Array<m_pb.PickStats.AsObject>;
  faults: Array<m_pb.Fault.AsObject>;
  hideFaultTypes: string[];
  showFaultTypes: string[];
  filterInclusive: boolean;
  faultTypes: Set<string>;
  error: string;
  useLegacyInterventionDefinition: boolean;
}

const second = 1e9;
enum BucketWidths {
  MINUTE = 60 * second,
  HOUR = 60 * 60 * second,
  DAY = 24 * 60 * 60 * second,
  WEEK = 7 * 24 * 60 * 60 * second,
  MONTH = 30 * 24 * 60 * 60 * second
}

type formatter = (val: moment.Moment) => string;
function xAxisFormatter(width: BucketWidths): formatter {
  switch (width) {
    case BucketWidths.MINUTE: {
      return val => val.format("DD MMM hh:mm:ss");
    }
    case BucketWidths.HOUR: {
      return val => val.format("DD MMM hh:mm a");
    }
    case BucketWidths.DAY: {
      return val => val.format("DD MMM hha");
    }
    case BucketWidths.WEEK: {
      return val => val.format("DD MMM");
    }
    case BucketWidths.MONTH: {
      return val => val.format("DD MMM");
    }
  }
  throw new Error(`Unrecognized bucket width: ${width}`);
}
class FaultChartPage extends Component<Props & RouteComponentProps, State> {
  state: State = {
    isLoading: false,
    bucketWidth: BucketWidths.DAY,
    redirectTo: null,
    timeSeries: null,
    eventFilter: new m_pb.EventFilter(),
    chartType: "fault-breakdown",
    loadingProgress: 0,
    stoppages: [],
    pickStats: [],
    hideFaultTypes: [],
    showFaultTypes: [],
    filterInclusive: false,
    faultTypes: new Set(),
    error: "",
    useLegacyInterventionDefinition: false
  };

  _fetchFaultCodes() {
    this.setState({ isLoading: true }, () => {
      this.props
        .dispatch(listFaultCodesRequest())
        .then((res: m_pb.ListFaultCodesResponse.AsObject) => {
          this.setState({ faultTypes: new Set(res.faultCodesList), isLoading: false });
        });
    });
  }

  _fetchAllFaults(nextPageToken = "", newFetch = true) {
    const {
      eventFilter,
      showFaultTypes,
    } = this.state;
    this.setState(
      {
        isLoading: true,
        loadingProgress: 0
      },
      () => {
        this.props
          .dispatch(
            listFaultsEventFilterRequest(
              eventFilter,
              showFaultTypes,
              [],
              [],
              nextPageToken,
              false
            )
          )
          .then((res: m_pb.ListFaultsResponse.AsObject) => {
            const faults = newFetch
              ? res.faultsList
              : this.state.faults.concat(res.faultsList);
            if (res.nextPageToken && faults.length < 50000) {
              this.setState({ faults: faults, isLoading: true });
              this._fetchAllFaults(res.nextPageToken, false);
            } else {
              this.setState({ faults: faults, isLoading: false });
            }
          })
          .catch((e: ServiceError) => {
            this.setState({ isLoading: false, error: e.message });
          });
      }
    );
  }

  _fetchMore(nextPageToken = "", newFetch = true) {
    const { bucketWidth, eventFilter, chartType, useLegacyInterventionDefinition } = this.state;
    const fetchInterventionsAction = useLegacyInterventionDefinition ? listStoppagesRequest(eventFilter, nextPageToken) : listInterventionsRequest(eventFilter, nextPageToken)
    this.setState(
      {
        isLoading: true,
        loadingProgress: newFetch ? 0 : this.state.loadingProgress
      },
      () => {
        this.props
          .dispatch(fetchInterventionsAction)
          .then((res: m_pb.ListStoppagesResponse.AsObject) => {
            const stoppages = newFetch
              ? res.stoppagesList
              : this.state.stoppages.concat(res.stoppagesList);
            if (res.nextPageToken) {
              this.setState({
                stoppages,
                // faultTypes,
                isLoading: true,
                loadingProgress: (100 * stoppages.length) / res.count
              });
              this._fetchMore(res.nextPageToken, false);
            } else {
              this.setState(
                {
                  stoppages,
                  isLoading: false
                },
                () => {
                  this._fetchPickStats(eventFilter);
                }
              );
            }
          })
          .catch((e: ServiceError) => {
            switch (e.code) {
              case grpc.Code.Unauthenticated: {
                this.setState({
                  redirectTo: logInPath(window.location.pathname)
                });
                break;
              }
              // TODO(malcolm): Add pages for permission denied, 500 error
            }
            this.setState({ isLoading: false });
          });
      }
    );
    if (this.state.chartType === "all-fault-frequency-time-series") {
      this._fetchAllFaults();
    }
  }

  _fetchPickStats(
    filter: m_pb.EventFilter,
    nextPageToken = "",
    newFetch = true
  ) {
    this.setState(
      {
        isLoading: true,
        loadingProgress: newFetch ? 0 : this.state.loadingProgress
      },
      () => {
        this.props
          .dispatch(
            listPickStatsRequest(
              filter,
              nextPageToken,
              true,
              false,
              true,
              true,
              true,
              true,
              true
            )
          )
          .then((res: m_pb.ListPickStatsResponse.AsObject) => {
            // Append stats to existing state if this is a 2nd (or more) fetch of a particular dataset
            const pickStats = newFetch
              ? res.pickStatsList
              : this.state.pickStats.concat(res.pickStatsList);
            if (res.nextPageToken) {
              this.setState(
                {
                  pickStats,
                  isLoading: true,
                  loadingProgress: (100 * pickStats.length) / res.count
                },
                () => {}
              );
              this._fetchPickStats(filter, res.nextPageToken, false);
            } else {
              this.setState(
                {
                  pickStats,
                  isLoading: false
                },
                () => {}
              );
            }
          })
          .catch((e: ServiceError) => {
            switch (e.code) {
              case grpc.Code.Unauthenticated: {
                this.setState({
                  redirectTo: logInPath(window.location.pathname)
                });
                break;
              }
            }
            this.setState({ isLoading: false });
          });
      }
    );
  }

  _updateBucket(rawDuration: string) {
    const parsed = Number.parseInt(rawDuration);
    const values = queryString.parse(this.props.location.search);
    values.bucketWidth = rawDuration;
    values.brushStart = undefined;
    values.brushEnd = undefined;
    this.props.history.push({ search: queryString.stringify(values) });
    this.setState({ bucketWidth: parsed });
  }

  componentDidMount() {
    this._fetchFaultCodes();
  }

  render() {
    const { classes } = this.props;
    const { isLoading, bucketWidth, chartType, stoppages, filterInclusive } = this.state;
    const progressSpinner = isLoading ? <ProgressSpinner /> : null;
    return (
      <div className={classes.wrapper}>
        <EventFilterSearchBar
          disable={isLoading}
          title={"Fault Charts"}
          onRequestSubmit={f =>
            this.setState({ eventFilter: f })
          }
          hideSubmitButton={true}
        />
        <AppBar position={"static"} color={"default"}>
          <Toolbar>
            {this.state.chartType !== "fault-breakdown" && <FormControl className={classes.formControl}>
              <InputLabel htmlFor={"select-duration"}>Bucket Size</InputLabel>
              <Select
                onChange={w => this._updateBucket(w.target.value as string)}
                value={bucketWidth}
                input={<Input id={"select-duration"} />}
              >
                {Object.values(BucketWidths)
                  .filter(key => !isNaN(Number(key)))
                  .map(width => (
                    <MenuItem key={width} value={width}>
                      {BucketWidths[width as number]}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>}
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor={"select-duration"}>Chart Type</InputLabel>
              <Select
                onChange={e => {
                    if (e.target.value === "all-fault-frequency-time-series") {
                      this.setState({ chartType: e.target.value as string, filterInclusive: true })
                    } else {
                      this.setState({ chartType: e.target.value as string })
                    }
                  }
                }
                value={chartType}
                input={<Input id={"select-chart-type"} />}
              >
                <MenuItem value={"intervention-fault-frequency-time-series"}>
                  Intervention Cause Frequency Time Series
                </MenuItem>
                <MenuItem value={"fault-breakdown"}>
                  Intervention Breakdown By Fault Type
                </MenuItem>
                <MenuItem value={"all-fault-frequency-time-series"}>
                  Fault Frequency Time Series
                </MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterInclusive}
                  disabled={this.state.chartType === "all-fault-frequency-time-series"}
                  onChange={e =>
                    this.setState({ filterInclusive: e.target.checked })
                  }
                />
              }
              label="Filter by fault type inclusive?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.useLegacyInterventionDefinition}
                  disabled={this.state.chartType === "all-fault-frequency-time-series"}
                  onChange={e =>
                    this.setState({ useLegacyInterventionDefinition: e.target.checked })
                  }
                />
              }
              label="Use legacy stoppage-based interventions"
            />
            { !filterInclusive && <Autocomplete
              multiple
              id="exclude-faults-autocomplete"
              options={Array.from(this.state.faultTypes).concat(["MANUAL", "MANUAL_GOAL_END"])}
              onChange={(event: any, newValues: Array<string> | null) => {
                this.setState({ hideFaultTypes: newValues || [] });
              }}
              value={this.state.hideFaultTypes}
              style={{
                minWidth: 200,
                height: 56,
                margin: 6,
                display: "inline"
              }}
              renderInput={params => (
                <TextField {...params} label="Exclude fault types" />
              )}
            />}
            {filterInclusive && <Autocomplete
              multiple
              id="include-faults-autocomplete"
              options={Array.from(this.state.faultTypes).concat(["MANUAL", "MANUAL_GOAL_END"])}
              onChange={(event: any, newValues: Array<string> | null) => {
                this.setState({ showFaultTypes: newValues || [] });
              }}
              value={this.state.showFaultTypes}
              style={{
                minWidth: 200,
                height: 56,
                margin: 6,
                display: "inline"
              }}
              renderInput={params => (
                <TextField {...params} label="Search fault types" />
              )}
            />}
            <Button
              color={"secondary"}
              size={"small"}
              variant={"contained"}
              onClick={() => this._fetchMore()}
            >
              Submit
            </Button>
          </Toolbar>
        </AppBar>
        {progressSpinner}
        <CardContent className={classes.content}>
          {chartType === "intervention-fault-frequency-time-series" &&
            this.state.stoppages && (
              <ChartInterventionFaultTimeSeries
                stoppages={this.state.stoppages}
                pickStats={this.state.pickStats}
                filteredFaultTypes={filterInclusive ? [...this.state.faultTypes].filter(faultType => !this.state.showFaultTypes.includes(faultType)) : this.state.hideFaultTypes}
                bucketWidth={this.state.bucketWidth}
                eventFilter={this.state.eventFilter}
              />
            )}
          {chartType === "all-fault-frequency-time-series" &&
            this.state.stoppages && (
              <FaultTimeSeriesChart
                faults={this.state.faults}
                pickStats={this.state.pickStats}
                bucketWidth={this.state.bucketWidth}
                eventFilter={this.state.eventFilter}
              />
            )}
          {chartType === "fault-breakdown" &&
            stoppages &&
            stoppages.length > 0 && (
              <ChartStoppageCause
                height={800}
                width={1200}
                radius={250}
                stoppages={stoppages}
                groupAsOtherThreshold={0.015}
                filteredFaultTypes={filterInclusive ? [...this.state.faultTypes].filter(faultType => !this.state.showFaultTypes.includes(faultType)) : this.state.hideFaultTypes}
              />
            )}
        </CardContent>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(FaultChartPage))
);
