// source: planner/proto/kinematics.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var planner_proto_types_pb = require('../../planner/proto/types_pb.js');
goog.object.extend(proto, planner_proto_types_pb);
var planner_proto_steering_control_pb = require('../../planner/proto/steering_control_pb.js');
goog.object.extend(proto, planner_proto_steering_control_pb);
goog.exportSymbol('proto.fox.proto.InnerWheelStrategy', null, global);
goog.exportSymbol('proto.fox.proto.TricycleKinematicConfiguration', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.TricycleKinematicConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.TricycleKinematicConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.TricycleKinematicConfiguration.displayName = 'proto.fox.proto.TricycleKinematicConfiguration';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.TricycleKinematicConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.TricycleKinematicConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TricycleKinematicConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackWidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    wheelBase: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    steerWheelOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    rightDriveWheelRadius: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    leftDriveWheelRadius: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    driveGearRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    minSteering: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    maxSteering: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    maxSteeringDot: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    minDriveSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    maxDriveSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    maxDriveSpeedTurning: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    maxDriveDot: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    innerWheelStrategy: jspb.Message.getFieldWithDefault(msg, 13, 0),
    controlLagNs: jspb.Message.getFieldWithDefault(msg, 14, 0),
    controlPeriodNs: jspb.Message.getFieldWithDefault(msg, 15, 0),
    stopDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    slowPathEnd: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    collisionFootprint: (f = msg.getCollisionFootprint()) && planner_proto_types_pb.CollisionFootprint.toObject(includeInstance, f),
    fineCollisionFootprint: (f = msg.getFineCollisionFootprint()) && planner_proto_types_pb.CollisionFootprint.toObject(includeInstance, f),
    mastKinematics: (f = msg.getMastKinematics()) && planner_proto_types_pb.MastKinematicConfiguration.toObject(includeInstance, f),
    steeringModel: (f = msg.getSteeringModel()) && planner_proto_steering_control_pb.SteeringControlModel.toObject(includeInstance, f),
    laserFieldPaddingSpeedPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    laserFieldPaddingAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    maxDriveSpeedFieldsOff: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    maxSteerAngleFieldsForwardOnly: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    maxShiftForwardOnly: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.TricycleKinematicConfiguration}
 */
proto.fox.proto.TricycleKinematicConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.TricycleKinematicConfiguration;
  return proto.fox.proto.TricycleKinematicConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.TricycleKinematicConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.TricycleKinematicConfiguration}
 */
proto.fox.proto.TricycleKinematicConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTrackWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWheelBase(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSteerWheelOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRightDriveWheelRadius(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLeftDriveWheelRadius(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDriveGearRatio(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinSteering(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxSteering(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxSteeringDot(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinDriveSpeed(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxDriveSpeed(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxDriveSpeedTurning(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxDriveDot(value);
      break;
    case 13:
      var value = /** @type {!proto.fox.proto.InnerWheelStrategy} */ (reader.readEnum());
      msg.setInnerWheelStrategy(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setControlLagNs(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setControlPeriodNs(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStopDistance(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSlowPathEnd(value);
      break;
    case 19:
      var value = new planner_proto_types_pb.CollisionFootprint;
      reader.readMessage(value,planner_proto_types_pb.CollisionFootprint.deserializeBinaryFromReader);
      msg.setCollisionFootprint(value);
      break;
    case 25:
      var value = new planner_proto_types_pb.CollisionFootprint;
      reader.readMessage(value,planner_proto_types_pb.CollisionFootprint.deserializeBinaryFromReader);
      msg.setFineCollisionFootprint(value);
      break;
    case 20:
      var value = new planner_proto_types_pb.MastKinematicConfiguration;
      reader.readMessage(value,planner_proto_types_pb.MastKinematicConfiguration.deserializeBinaryFromReader);
      msg.setMastKinematics(value);
      break;
    case 21:
      var value = new planner_proto_steering_control_pb.SteeringControlModel;
      reader.readMessage(value,planner_proto_steering_control_pb.SteeringControlModel.deserializeBinaryFromReader);
      msg.setSteeringModel(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLaserFieldPaddingSpeedPercent(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLaserFieldPaddingAngle(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxDriveSpeedFieldsOff(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxSteerAngleFieldsForwardOnly(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxShiftForwardOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.TricycleKinematicConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.TricycleKinematicConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TricycleKinematicConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getWheelBase();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getSteerWheelOffset();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRightDriveWheelRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLeftDriveWheelRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDriveGearRatio();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getMinSteering();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getMaxSteering();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getMaxSteeringDot();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getMinDriveSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getMaxDriveSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getMaxDriveSpeedTurning();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getMaxDriveDot();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getInnerWheelStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getControlLagNs();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getControlPeriodNs();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getStopDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getSlowPathEnd();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getCollisionFootprint();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      planner_proto_types_pb.CollisionFootprint.serializeBinaryToWriter
    );
  }
  f = message.getFineCollisionFootprint();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      planner_proto_types_pb.CollisionFootprint.serializeBinaryToWriter
    );
  }
  f = message.getMastKinematics();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      planner_proto_types_pb.MastKinematicConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getSteeringModel();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      planner_proto_steering_control_pb.SteeringControlModel.serializeBinaryToWriter
    );
  }
  f = message.getLaserFieldPaddingSpeedPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getLaserFieldPaddingAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getMaxDriveSpeedFieldsOff();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getMaxSteerAngleFieldsForwardOnly();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getMaxShiftForwardOnly();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
};


/**
 * optional double track_width = 1;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getTrackWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setTrackWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double wheel_base = 2;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getWheelBase = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setWheelBase = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double steer_wheel_offset = 3;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getSteerWheelOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setSteerWheelOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double right_drive_wheel_radius = 4;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getRightDriveWheelRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setRightDriveWheelRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double left_drive_wheel_radius = 5;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getLeftDriveWheelRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setLeftDriveWheelRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double drive_gear_ratio = 6;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getDriveGearRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setDriveGearRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double min_steering = 7;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getMinSteering = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setMinSteering = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double max_steering = 8;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getMaxSteering = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setMaxSteering = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double max_steering_dot = 9;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getMaxSteeringDot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setMaxSteeringDot = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double min_drive_speed = 10;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getMinDriveSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setMinDriveSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double max_drive_speed = 11;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getMaxDriveSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setMaxDriveSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double max_drive_speed_turning = 18;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getMaxDriveSpeedTurning = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setMaxDriveSpeedTurning = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double max_drive_dot = 12;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getMaxDriveDot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setMaxDriveDot = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional InnerWheelStrategy inner_wheel_strategy = 13;
 * @return {!proto.fox.proto.InnerWheelStrategy}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getInnerWheelStrategy = function() {
  return /** @type {!proto.fox.proto.InnerWheelStrategy} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.fox.proto.InnerWheelStrategy} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setInnerWheelStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional int64 control_lag_ns = 14;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getControlLagNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setControlLagNs = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 control_period_ns = 15;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getControlPeriodNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setControlPeriodNs = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional double stop_distance = 16;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getStopDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setStopDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double slow_path_end = 17;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getSlowPathEnd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setSlowPathEnd = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional CollisionFootprint collision_footprint = 19;
 * @return {?proto.fox.proto.CollisionFootprint}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getCollisionFootprint = function() {
  return /** @type{?proto.fox.proto.CollisionFootprint} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.CollisionFootprint, 19));
};


/**
 * @param {?proto.fox.proto.CollisionFootprint|undefined} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
*/
proto.fox.proto.TricycleKinematicConfiguration.prototype.setCollisionFootprint = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.clearCollisionFootprint = function() {
  return this.setCollisionFootprint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.hasCollisionFootprint = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional CollisionFootprint fine_collision_footprint = 25;
 * @return {?proto.fox.proto.CollisionFootprint}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getFineCollisionFootprint = function() {
  return /** @type{?proto.fox.proto.CollisionFootprint} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.CollisionFootprint, 25));
};


/**
 * @param {?proto.fox.proto.CollisionFootprint|undefined} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
*/
proto.fox.proto.TricycleKinematicConfiguration.prototype.setFineCollisionFootprint = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.clearFineCollisionFootprint = function() {
  return this.setFineCollisionFootprint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.hasFineCollisionFootprint = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional MastKinematicConfiguration mast_kinematics = 20;
 * @return {?proto.fox.proto.MastKinematicConfiguration}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getMastKinematics = function() {
  return /** @type{?proto.fox.proto.MastKinematicConfiguration} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.MastKinematicConfiguration, 20));
};


/**
 * @param {?proto.fox.proto.MastKinematicConfiguration|undefined} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
*/
proto.fox.proto.TricycleKinematicConfiguration.prototype.setMastKinematics = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.clearMastKinematics = function() {
  return this.setMastKinematics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.hasMastKinematics = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional SteeringControlModel steering_model = 21;
 * @return {?proto.fox.proto.SteeringControlModel}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getSteeringModel = function() {
  return /** @type{?proto.fox.proto.SteeringControlModel} */ (
    jspb.Message.getWrapperField(this, planner_proto_steering_control_pb.SteeringControlModel, 21));
};


/**
 * @param {?proto.fox.proto.SteeringControlModel|undefined} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
*/
proto.fox.proto.TricycleKinematicConfiguration.prototype.setSteeringModel = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.clearSteeringModel = function() {
  return this.setSteeringModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.hasSteeringModel = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional double laser_field_padding_speed_percent = 22;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getLaserFieldPaddingSpeedPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setLaserFieldPaddingSpeedPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double laser_field_padding_angle = 23;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getLaserFieldPaddingAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setLaserFieldPaddingAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double max_drive_speed_fields_off = 24;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getMaxDriveSpeedFieldsOff = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setMaxDriveSpeedFieldsOff = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional double max_steer_angle_fields_forward_only = 26;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getMaxSteerAngleFieldsForwardOnly = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setMaxSteerAngleFieldsForwardOnly = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional double max_shift_forward_only = 27;
 * @return {number}
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.getMaxShiftForwardOnly = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleKinematicConfiguration} returns this
 */
proto.fox.proto.TricycleKinematicConfiguration.prototype.setMaxShiftForwardOnly = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * @enum {number}
 */
proto.fox.proto.InnerWheelStrategy = {
  UNCONSTRAINED: 0,
  PREFER_STEERING: 1,
  PREFER_OUTER_WHEEL: 2
};

goog.object.extend(exports, proto.fox.proto);
