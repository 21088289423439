import {connect} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Box, FormControl, MenuItem, Paper, Select, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import m_pb, {
    CreateTabletFlagOptionRequest,
    CreateTabletOptionalFeatureRequest, TabletFlagOption,
    TabletOptionalFeature
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import {ApplicationState, createTabletFlagOption, createTabletOptionalFeature} from "../../redux";
import {
    selectTabletFlagOptions,
    selectTabletOptionalFeatures
} from "../../redux/selectors";
import {useAppDispatch} from "../../redux/hooks";
import {client} from "../../redux/store";
import {Column} from "react-table";
import FoxGrid from "../FoxGrid/foxGrid";
import {
    FlagOption,
    FlagValue,
    LocalizableText,
    LocalizedText
} from "../../_proto/command_control/proto/command_control_pb";

interface Props {
  classes: any;
  dispatch: any;
  open: boolean;
  onClose: () => void;
  flagOptions: TabletFlagOption.AsObject[];
}

const mapStateToProps = (state: ApplicationState) => ({
  flagOptions: selectTabletFlagOptions(state),
});

const newFlagValueWithLocalizedText = () => {
    return {...new FlagValue().toObject(),
        displayText: {
            localizedTextList: [new LocalizedText().toObject()]
        }
    };
}


const AddFlagOptionsDialog = (props: Props) => {
    const { flagOptions, onClose } = props;
    const dispatch = useAppDispatch();

    const [ error, setError ] = useState<string>("");

    const [ flagName, setFlagName ] = useState<string>("");
    const [ localizedDisplayNames, setLocalizedDisplayNames ] = useState<LocalizedText.AsObject[]>([new LocalizedText().toObject()]);
    const [ localizedTooltips, setLocalizedTooltips ] = useState<LocalizedText.AsObject[]>([new LocalizedText().toObject()]);
    const [ flagValues, setFlagValues ] = useState<FlagValue.AsObject[]>([newFlagValueWithLocalizedText()]);

    const _submit = () => {
        const flagOption = new FlagOption();
        flagOption.setFlagName(flagName);

        // Display name
        const displayNameLocalizableText = new LocalizableText();
        const displayNameLocalizedTextList = localizedDisplayNames.map(displayName => {
            const localizedTextMessage = new LocalizedText();
            localizedTextMessage.setLocale(displayName.locale);
            localizedTextMessage.setText(displayName.text);
            return localizedTextMessage
        })
        displayNameLocalizableText.setLocalizedTextList(displayNameLocalizedTextList.filter(displayName => !!displayName.getLocale()))
        flagOption.setDisplayName(displayNameLocalizableText);

        // Tooltip
        const tooltipLocalizableText = new LocalizableText();
        const tooltipLocalizedTextList = localizedTooltips.map(tooltip => {
            const tooltipMessage = new LocalizedText();
            tooltipMessage.setLocale(tooltip.locale);
            tooltipMessage.setText(tooltip.text);
            return tooltipMessage
        })
        tooltipLocalizableText.setLocalizedTextList(tooltipLocalizedTextList.filter(tooltip => !!tooltip.getLocale()))
        flagOption.setTooltip(tooltipLocalizableText);

        // Flag values
        flagOption.setFlagValuesList(flagValues.map(flagValue => {
            const flagValueMessage = new FlagValue();
            flagValueMessage.setValue(flagValue.value);
            const localizableDisplayText = new LocalizableText();
            const localizedTextList = flagValue.displayText?.localizedTextList.map(localizedText => {
                const localizedTextMessage = new LocalizedText();
                localizedTextMessage.setLocale(localizedText.locale);
                localizedTextMessage.setText(localizedText.text);
                return localizedTextMessage;
            }) || []
            localizableDisplayText.setLocalizedTextList(localizedTextList);
            flagValueMessage.setDisplayText(localizableDisplayText)
            return flagValueMessage
        }).filter(flagValue => !!flagValue.getValue()));

        const req = new CreateTabletFlagOptionRequest();
        req.setFlagOption(flagOption);
        dispatch(createTabletFlagOption({client, req}))
            .then(() => onClose())
            .catch(err => setError(err.toString()));
    }

      const existingFlagOptionsColumns: Column<TabletFlagOption.AsObject>[] = useMemo(
          () => [
          {
              Header: 'Flag Name',
              accessor: (tabletFlagOption) => tabletFlagOption?.flagOption?.flagName || "",
          },
          {
              Header: 'Flag Display Name (english)',
              accessor: (tabletFlagOption) => tabletFlagOption?.flagOption?.displayName?.localizedTextList?.find(localizedText => localizedText.locale === "en_us")?.text || "",
          },
          {
              Header: 'Flag Values',
              accessor: (tabletFlagOption) => tabletFlagOption?.flagOption?.flagValuesList?.map(flagValue => flagValue.value).join(",\n") || "",
          },
      ],
      []
    );


    const _handleDisplayNameChange = (newText: string, newLocale: string, index: number) => {
        const displayNames = [...localizedDisplayNames];
        const updatedDisplayName = new LocalizedText().toObject();
        updatedDisplayName.text = newText;
        updatedDisplayName.locale = newLocale;
        displayNames.splice(index, 1, updatedDisplayName);
        // Make sure there's an empty element at the end for users to continue
        // adding more seamlessly
        const lastElement = displayNames[displayNames.length -1];
        if (lastElement.text || lastElement.locale) {
            displayNames.push(new LocalizedText().toObject());
        }
        setLocalizedDisplayNames(displayNames);
    }

    const _handleTooltipChange = (newText: string, newLocale: string, index: number) => {
        const tooltips = [...localizedTooltips];
        const updatedTooltip = new LocalizedText().toObject();
        updatedTooltip.text = newText;
        updatedTooltip.locale = newLocale;
        tooltips.splice(index, 1, updatedTooltip);
        // Make sure there's an empty element at the end for users to continue
        // adding more seamlessly
        const lastElement = tooltips[tooltips.length -1];
        if (lastElement.text || lastElement.locale) {
            tooltips.push(new LocalizedText().toObject());
        }
        setLocalizedTooltips(tooltips);
    }

    const _handleFlagValueChange = (newValue: string, index) => {
        const values = [...flagValues];
        values[index] = {...values[index], value: newValue};
        const lastElement = values[values.length -1];
        if (lastElement.value || lastElement.displayText?.localizedTextList.length) {
            values.push(newFlagValueWithLocalizedText());
        }
        setFlagValues(values);
    }

    const _handleFlagValueDisplayTextChange = (newText: string, newLocale: string, flagIndex: number, localeIndex: number) => {
        const values = [...flagValues];
        const displayTexts = [...(values[flagIndex].displayText?.localizedTextList || [])];
        const newDisplayText = { text: newText, locale: newLocale };
        displayTexts.splice(localeIndex, 1, newDisplayText);
        const lastElement = displayTexts[displayTexts.length - 1];
        if (lastElement.text || lastElement.locale) {
            displayTexts.push(new LocalizedText().toObject());
        }
        values[flagIndex] = {...values[flagIndex], displayText: {localizedTextList: displayTexts}}
        setFlagValues(values);
    }


    return <React.Fragment>
        <Dialog
            open={props.open}
            onClose={() => props.onClose && props.onClose()}
            maxWidth={false}
        >
            <DialogTitle>Add New Tablet Optional Feature</DialogTitle>
            <DialogContent>
                {error && <Typography>{error}</Typography>}
                <Paper style={{margin: "24px"}} elevation={2}>
                    <Paper>
                        <Typography variant="h6" style={{padding: "12px"}}>Flag Name (command-line argument)</Typography>
                        <TextField
                            label={"Name of the flag (use lower_snake_case)"}
                            value={flagName}
                            style={{display: "block", padding: "12px"}}
                            onChange={(e) => setFlagName(e.target.value)}
                        />
                    </Paper>
                    <Paper>
                        <Typography variant="h6" style={{padding: "12px"}}>Flag display name (on tablet)</Typography>
                        {localizedDisplayNames.map((displayName, index) => {
                            return <div>
                                <TextField
                                    label={"Name to display"}
                                    value={displayName.text}
                                    style={{paddingLeft: "12px", paddingRight: "12px"}}
                                    onChange={(e) => _handleDisplayNameChange(e.target.value, displayName.locale, index)}
                                />
                                <TextField
                                    label={"Locale"}
                                    value={displayName.locale}
                                    style={{paddingLeft: "12px", paddingRight: "12px"}}
                                    onChange={(e) => _handleDisplayNameChange(displayName.text, e.target.value, index)}
                                />
                            </div>
                        })}
                    </Paper>
                    <Paper>
                        <Typography variant="h6" style={{padding: "12px"}}>Flag Values</Typography>
                        {flagValues.map((flagValue, index) => {
                            return <div>
                                <TextField
                                    label={"Value"}
                                    value={flagValue.value}
                                    style={{display: "block", padding: "12px"}}
                                    onChange={(e) => _handleFlagValueChange(e.target.value, index)}
                                />
                                <Typography style={{padding: "12px", textAlign: "center"}}>Value to display on tablet</Typography>
                                {flagValue.displayText?.localizedTextList.map((flagValueDisplayText, displayTextIndex) => {
                                    return <div style={{paddingLeft: "48px"}}>
                                        <TextField
                                            label={"Value to display"}
                                            value={flagValueDisplayText.text}
                                            style={{paddingLeft: "12px", paddingRight: "12px"}}
                                            onChange={(e) => _handleFlagValueDisplayTextChange(e.target.value, flagValueDisplayText.locale, index, displayTextIndex)}
                                        />
                                        <TextField
                                            label={"Locale"}
                                            value={flagValueDisplayText.locale}
                                            style={{paddingLeft: "12px", paddingRight: "12px"}}
                                            onChange={(e) => _handleFlagValueDisplayTextChange(flagValueDisplayText.text, e.target.value, index, displayTextIndex)}
                                        />
                                    </div>
                                })}
                            </div>
                        })}
                    </Paper>
                    <Paper>
                        <Typography variant="h6" style={{padding: "12px"}}>Tooltip text (on tablet)</Typography>
                        {localizedTooltips.map((tooltip, index) => {
                            return <div>
                                <TextField
                                    label={"Tooltip text"}
                                    value={tooltip.text}
                                    style={{padding: "12px"}}
                                    onChange={(e) => _handleTooltipChange(e.target.value, tooltip.locale, index)}
                                />
                                <TextField
                                    label={"Locale"}
                                    value={tooltip.locale}
                                    style={{padding: "12px"}}
                                    onChange={(e) => _handleTooltipChange(tooltip.text, e.target.value, index)}
                                />
                            </div>
                        })}
                    </Paper>

                </Paper>
                {flagOptions && <div>
                    <FoxGrid
                        title={'Existing flag options'}
                        data={props.flagOptions}
                        columns={existingFlagOptionsColumns as Column<any>[]}
                        icon={<React.Fragment/>}
                    />
                </div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={() => _submit()}
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
};

export default connect(mapStateToProps)(AddFlagOptionsDialog);