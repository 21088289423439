// source: config/proto/config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var calibration_proto_extrinsics_pb = require('../../calibration/proto/extrinsics_pb.js');
goog.object.extend(proto, calibration_proto_extrinsics_pb);
var image_image_pb = require('../../image/image_pb.js');
goog.object.extend(proto, image_image_pb);
var map_proto_map_pb = require('../../map/proto/map_pb.js');
goog.object.extend(proto, map_proto_map_pb);
var planner_proto_kinematics_pb = require('../../planner/proto/kinematics_pb.js');
goog.object.extend(proto, planner_proto_kinematics_pb);
var planner_proto_types_pb = require('../../planner/proto/types_pb.js');
goog.object.extend(proto, planner_proto_types_pb);
var robot_proto_config_pb = require('../../robot/proto/config_pb.js');
goog.object.extend(proto, robot_proto_config_pb);
var sensors_laser_scan_pb = require('../../sensors/laser_scan_pb.js');
goog.object.extend(proto, sensors_laser_scan_pb);
goog.exportSymbol('proto.fox.proto.AddressConfig', null, global);
goog.exportSymbol('proto.fox.proto.ConfigurationHeader', null, global);
goog.exportSymbol('proto.fox.proto.ConfigurationHeader.EditorCase', null, global);
goog.exportSymbol('proto.fox.proto.FirmwareVersion', null, global);
goog.exportSymbol('proto.fox.proto.MicroscanNetworkConfig', null, global);
goog.exportSymbol('proto.fox.proto.RobotConfiguration', null, global);
goog.exportSymbol('proto.fox.proto.SensorIntrinsics', null, global);
goog.exportSymbol('proto.fox.proto.VersionInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ConfigurationHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.ConfigurationHeader.repeatedFields_, proto.fox.proto.ConfigurationHeader.oneofGroups_);
};
goog.inherits(proto.fox.proto.ConfigurationHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ConfigurationHeader.displayName = 'proto.fox.proto.ConfigurationHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.MicroscanNetworkConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.MicroscanNetworkConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.MicroscanNetworkConfig.displayName = 'proto.fox.proto.MicroscanNetworkConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.AddressConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.AddressConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.AddressConfig.displayName = 'proto.fox.proto.AddressConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SensorIntrinsics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SensorIntrinsics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SensorIntrinsics.displayName = 'proto.fox.proto.SensorIntrinsics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.VersionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.VersionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.VersionInfo.displayName = 'proto.fox.proto.VersionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.FirmwareVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.FirmwareVersion.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.FirmwareVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.FirmwareVersion.displayName = 'proto.fox.proto.FirmwareVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.RobotConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.RobotConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.RobotConfiguration.displayName = 'proto.fox.proto.RobotConfiguration';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.ConfigurationHeader.repeatedFields_ = [13];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fox.proto.ConfigurationHeader.oneofGroups_ = [[12,14]];

/**
 * @enum {number}
 */
proto.fox.proto.ConfigurationHeader.EditorCase = {
  EDITOR_NOT_SET: 0,
  EDITOR_ACCOUNT_ID: 12,
  EDITOR_ROBOT_NAME: 14
};

/**
 * @return {proto.fox.proto.ConfigurationHeader.EditorCase}
 */
proto.fox.proto.ConfigurationHeader.prototype.getEditorCase = function() {
  return /** @type {proto.fox.proto.ConfigurationHeader.EditorCase} */(jspb.Message.computeOneofCase(this, proto.fox.proto.ConfigurationHeader.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ConfigurationHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ConfigurationHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ConfigurationHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ConfigurationHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    configId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    robotId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cohortId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    parentTimestampsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    syncPullTime: jspb.Message.getFieldWithDefault(msg, 10, "0"),
    syncPushSubmitTime: jspb.Message.getFieldWithDefault(msg, 11, "0"),
    syncPushAcceptTime: jspb.Message.getFieldWithDefault(msg, 15, "0"),
    path: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    checksumSha256: jspb.Message.getFieldWithDefault(msg, 9, ""),
    editorAccountId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    editorRobotName: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ConfigurationHeader}
 */
proto.fox.proto.ConfigurationHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ConfigurationHeader;
  return proto.fox.proto.ConfigurationHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ConfigurationHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ConfigurationHeader}
 */
proto.fox.proto.ConfigurationHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTimestamp(value);
      break;
    case 13:
      var value = /** @type {!Array<string>} */ (reader.readPackedInt64String());
      msg.setParentTimestampsList(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSyncPullTime(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSyncPushSubmitTime(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setSyncPushAcceptTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setChecksumSha256(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditorAccountId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditorRobotName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ConfigurationHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ConfigurationHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ConfigurationHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ConfigurationHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRobotId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCohortId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getParentTimestampsList();
  if (f.length > 0) {
    writer.writePackedInt64String(
      13,
      f
    );
  }
  f = message.getSyncPullTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      10,
      f
    );
  }
  f = message.getSyncPushSubmitTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      11,
      f
    );
  }
  f = message.getSyncPushAcceptTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      15,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getChecksumSha256();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string config_id = 4;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string robot_id = 1;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getRobotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setRobotId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cohort_id = 5;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getCohortId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setCohortId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 timestamp = 3;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * repeated int64 parent_timestamps = 13;
 * @return {!Array<string>}
 */
proto.fox.proto.ConfigurationHeader.prototype.getParentTimestampsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setParentTimestampsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.addParentTimestamps = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.clearParentTimestampsList = function() {
  return this.setParentTimestampsList([]);
};


/**
 * optional int64 sync_pull_time = 10;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getSyncPullTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, "0"));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setSyncPullTime = function(value) {
  return jspb.Message.setProto3StringIntField(this, 10, value);
};


/**
 * optional int64 sync_push_submit_time = 11;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getSyncPushSubmitTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, "0"));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setSyncPushSubmitTime = function(value) {
  return jspb.Message.setProto3StringIntField(this, 11, value);
};


/**
 * optional int64 sync_push_accept_time = 15;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getSyncPushAcceptTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, "0"));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setSyncPushAcceptTime = function(value) {
  return jspb.Message.setProto3StringIntField(this, 15, value);
};


/**
 * optional string path = 7;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_deleted = 8;
 * @return {boolean}
 */
proto.fox.proto.ConfigurationHeader.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string checksum_sha256 = 9;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getChecksumSha256 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setChecksumSha256 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string editor_account_id = 12;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getEditorAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setEditorAccountId = function(value) {
  return jspb.Message.setOneofField(this, 12, proto.fox.proto.ConfigurationHeader.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.clearEditorAccountId = function() {
  return jspb.Message.setOneofField(this, 12, proto.fox.proto.ConfigurationHeader.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ConfigurationHeader.prototype.hasEditorAccountId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string editor_robot_name = 14;
 * @return {string}
 */
proto.fox.proto.ConfigurationHeader.prototype.getEditorRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.setEditorRobotName = function(value) {
  return jspb.Message.setOneofField(this, 14, proto.fox.proto.ConfigurationHeader.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.ConfigurationHeader} returns this
 */
proto.fox.proto.ConfigurationHeader.prototype.clearEditorRobotName = function() {
  return jspb.Message.setOneofField(this, 14, proto.fox.proto.ConfigurationHeader.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ConfigurationHeader.prototype.hasEditorRobotName = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.MicroscanNetworkConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.MicroscanNetworkConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.MicroscanNetworkConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MicroscanNetworkConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostUdpPort: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.MicroscanNetworkConfig}
 */
proto.fox.proto.MicroscanNetworkConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.MicroscanNetworkConfig;
  return proto.fox.proto.MicroscanNetworkConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.MicroscanNetworkConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.MicroscanNetworkConfig}
 */
proto.fox.proto.MicroscanNetworkConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHostUdpPort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.MicroscanNetworkConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.MicroscanNetworkConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.MicroscanNetworkConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MicroscanNetworkConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostUdpPort();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 host_udp_port = 1;
 * @return {number}
 */
proto.fox.proto.MicroscanNetworkConfig.prototype.getHostUdpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MicroscanNetworkConfig} returns this
 */
proto.fox.proto.MicroscanNetworkConfig.prototype.setHostUdpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.AddressConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.AddressConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.AddressConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.AddressConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    blackflyIp: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scanLeftIp: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scanRightIp: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scanFrontIp: jspb.Message.getFieldWithDefault(msg, 4, ""),
    plcIp: jspb.Message.getFieldWithDefault(msg, 5, ""),
    realsenseBackIp: jspb.Message.getFieldWithDefault(msg, 6, ""),
    leftMicroscan: (f = msg.getLeftMicroscan()) && proto.fox.proto.MicroscanNetworkConfig.toObject(includeInstance, f),
    rightMicroscan: (f = msg.getRightMicroscan()) && proto.fox.proto.MicroscanNetworkConfig.toObject(includeInstance, f),
    frontMicroscan: (f = msg.getFrontMicroscan()) && proto.fox.proto.MicroscanNetworkConfig.toObject(includeInstance, f),
    oakdForkwardTopIp: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.AddressConfig}
 */
proto.fox.proto.AddressConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.AddressConfig;
  return proto.fox.proto.AddressConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.AddressConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.AddressConfig}
 */
proto.fox.proto.AddressConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlackflyIp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanLeftIp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRightIp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanFrontIp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlcIp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRealsenseBackIp(value);
      break;
    case 7:
      var value = new proto.fox.proto.MicroscanNetworkConfig;
      reader.readMessage(value,proto.fox.proto.MicroscanNetworkConfig.deserializeBinaryFromReader);
      msg.setLeftMicroscan(value);
      break;
    case 8:
      var value = new proto.fox.proto.MicroscanNetworkConfig;
      reader.readMessage(value,proto.fox.proto.MicroscanNetworkConfig.deserializeBinaryFromReader);
      msg.setRightMicroscan(value);
      break;
    case 9:
      var value = new proto.fox.proto.MicroscanNetworkConfig;
      reader.readMessage(value,proto.fox.proto.MicroscanNetworkConfig.deserializeBinaryFromReader);
      msg.setFrontMicroscan(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOakdForkwardTopIp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.AddressConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.AddressConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.AddressConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.AddressConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlackflyIp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScanLeftIp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScanRightIp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScanFrontIp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPlcIp();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRealsenseBackIp();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLeftMicroscan();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.fox.proto.MicroscanNetworkConfig.serializeBinaryToWriter
    );
  }
  f = message.getRightMicroscan();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.fox.proto.MicroscanNetworkConfig.serializeBinaryToWriter
    );
  }
  f = message.getFrontMicroscan();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.fox.proto.MicroscanNetworkConfig.serializeBinaryToWriter
    );
  }
  f = message.getOakdForkwardTopIp();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string blackfly_ip = 1;
 * @return {string}
 */
proto.fox.proto.AddressConfig.prototype.getBlackflyIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.AddressConfig} returns this
 */
proto.fox.proto.AddressConfig.prototype.setBlackflyIp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scan_left_ip = 2;
 * @return {string}
 */
proto.fox.proto.AddressConfig.prototype.getScanLeftIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.AddressConfig} returns this
 */
proto.fox.proto.AddressConfig.prototype.setScanLeftIp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scan_right_ip = 3;
 * @return {string}
 */
proto.fox.proto.AddressConfig.prototype.getScanRightIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.AddressConfig} returns this
 */
proto.fox.proto.AddressConfig.prototype.setScanRightIp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string scan_front_ip = 4;
 * @return {string}
 */
proto.fox.proto.AddressConfig.prototype.getScanFrontIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.AddressConfig} returns this
 */
proto.fox.proto.AddressConfig.prototype.setScanFrontIp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string plc_ip = 5;
 * @return {string}
 */
proto.fox.proto.AddressConfig.prototype.getPlcIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.AddressConfig} returns this
 */
proto.fox.proto.AddressConfig.prototype.setPlcIp = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string realsense_back_ip = 6;
 * @return {string}
 */
proto.fox.proto.AddressConfig.prototype.getRealsenseBackIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.AddressConfig} returns this
 */
proto.fox.proto.AddressConfig.prototype.setRealsenseBackIp = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional MicroscanNetworkConfig left_microscan = 7;
 * @return {?proto.fox.proto.MicroscanNetworkConfig}
 */
proto.fox.proto.AddressConfig.prototype.getLeftMicroscan = function() {
  return /** @type{?proto.fox.proto.MicroscanNetworkConfig} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MicroscanNetworkConfig, 7));
};


/**
 * @param {?proto.fox.proto.MicroscanNetworkConfig|undefined} value
 * @return {!proto.fox.proto.AddressConfig} returns this
*/
proto.fox.proto.AddressConfig.prototype.setLeftMicroscan = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.AddressConfig} returns this
 */
proto.fox.proto.AddressConfig.prototype.clearLeftMicroscan = function() {
  return this.setLeftMicroscan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.AddressConfig.prototype.hasLeftMicroscan = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional MicroscanNetworkConfig right_microscan = 8;
 * @return {?proto.fox.proto.MicroscanNetworkConfig}
 */
proto.fox.proto.AddressConfig.prototype.getRightMicroscan = function() {
  return /** @type{?proto.fox.proto.MicroscanNetworkConfig} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MicroscanNetworkConfig, 8));
};


/**
 * @param {?proto.fox.proto.MicroscanNetworkConfig|undefined} value
 * @return {!proto.fox.proto.AddressConfig} returns this
*/
proto.fox.proto.AddressConfig.prototype.setRightMicroscan = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.AddressConfig} returns this
 */
proto.fox.proto.AddressConfig.prototype.clearRightMicroscan = function() {
  return this.setRightMicroscan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.AddressConfig.prototype.hasRightMicroscan = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional MicroscanNetworkConfig front_microscan = 9;
 * @return {?proto.fox.proto.MicroscanNetworkConfig}
 */
proto.fox.proto.AddressConfig.prototype.getFrontMicroscan = function() {
  return /** @type{?proto.fox.proto.MicroscanNetworkConfig} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MicroscanNetworkConfig, 9));
};


/**
 * @param {?proto.fox.proto.MicroscanNetworkConfig|undefined} value
 * @return {!proto.fox.proto.AddressConfig} returns this
*/
proto.fox.proto.AddressConfig.prototype.setFrontMicroscan = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.AddressConfig} returns this
 */
proto.fox.proto.AddressConfig.prototype.clearFrontMicroscan = function() {
  return this.setFrontMicroscan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.AddressConfig.prototype.hasFrontMicroscan = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string oakd_forkward_top_ip = 10;
 * @return {string}
 */
proto.fox.proto.AddressConfig.prototype.getOakdForkwardTopIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.AddressConfig} returns this
 */
proto.fox.proto.AddressConfig.prototype.setOakdForkwardTopIp = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SensorIntrinsics.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SensorIntrinsics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SensorIntrinsics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SensorIntrinsics.toObject = function(includeInstance, msg) {
  var f, obj = {
    blackflyCameraModel: (f = msg.getBlackflyCameraModel()) && image_image_pb.CameraModel.toObject(includeInstance, f),
    d435ColorCameraModel: (f = msg.getD435ColorCameraModel()) && image_image_pb.CameraModel.toObject(includeInstance, f),
    d435DepthCameraModel: (f = msg.getD435DepthCameraModel()) && image_image_pb.CameraModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SensorIntrinsics}
 */
proto.fox.proto.SensorIntrinsics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SensorIntrinsics;
  return proto.fox.proto.SensorIntrinsics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SensorIntrinsics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SensorIntrinsics}
 */
proto.fox.proto.SensorIntrinsics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new image_image_pb.CameraModel;
      reader.readMessage(value,image_image_pb.CameraModel.deserializeBinaryFromReader);
      msg.setBlackflyCameraModel(value);
      break;
    case 2:
      var value = new image_image_pb.CameraModel;
      reader.readMessage(value,image_image_pb.CameraModel.deserializeBinaryFromReader);
      msg.setD435ColorCameraModel(value);
      break;
    case 3:
      var value = new image_image_pb.CameraModel;
      reader.readMessage(value,image_image_pb.CameraModel.deserializeBinaryFromReader);
      msg.setD435DepthCameraModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SensorIntrinsics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SensorIntrinsics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SensorIntrinsics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SensorIntrinsics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlackflyCameraModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      image_image_pb.CameraModel.serializeBinaryToWriter
    );
  }
  f = message.getD435ColorCameraModel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      image_image_pb.CameraModel.serializeBinaryToWriter
    );
  }
  f = message.getD435DepthCameraModel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      image_image_pb.CameraModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional CameraModel blackfly_camera_model = 1;
 * @return {?proto.fox.proto.CameraModel}
 */
proto.fox.proto.SensorIntrinsics.prototype.getBlackflyCameraModel = function() {
  return /** @type{?proto.fox.proto.CameraModel} */ (
    jspb.Message.getWrapperField(this, image_image_pb.CameraModel, 1));
};


/**
 * @param {?proto.fox.proto.CameraModel|undefined} value
 * @return {!proto.fox.proto.SensorIntrinsics} returns this
*/
proto.fox.proto.SensorIntrinsics.prototype.setBlackflyCameraModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.SensorIntrinsics} returns this
 */
proto.fox.proto.SensorIntrinsics.prototype.clearBlackflyCameraModel = function() {
  return this.setBlackflyCameraModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.SensorIntrinsics.prototype.hasBlackflyCameraModel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CameraModel d435_color_camera_model = 2;
 * @return {?proto.fox.proto.CameraModel}
 */
proto.fox.proto.SensorIntrinsics.prototype.getD435ColorCameraModel = function() {
  return /** @type{?proto.fox.proto.CameraModel} */ (
    jspb.Message.getWrapperField(this, image_image_pb.CameraModel, 2));
};


/**
 * @param {?proto.fox.proto.CameraModel|undefined} value
 * @return {!proto.fox.proto.SensorIntrinsics} returns this
*/
proto.fox.proto.SensorIntrinsics.prototype.setD435ColorCameraModel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.SensorIntrinsics} returns this
 */
proto.fox.proto.SensorIntrinsics.prototype.clearD435ColorCameraModel = function() {
  return this.setD435ColorCameraModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.SensorIntrinsics.prototype.hasD435ColorCameraModel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CameraModel d435_depth_camera_model = 3;
 * @return {?proto.fox.proto.CameraModel}
 */
proto.fox.proto.SensorIntrinsics.prototype.getD435DepthCameraModel = function() {
  return /** @type{?proto.fox.proto.CameraModel} */ (
    jspb.Message.getWrapperField(this, image_image_pb.CameraModel, 3));
};


/**
 * @param {?proto.fox.proto.CameraModel|undefined} value
 * @return {!proto.fox.proto.SensorIntrinsics} returns this
*/
proto.fox.proto.SensorIntrinsics.prototype.setD435DepthCameraModel = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.SensorIntrinsics} returns this
 */
proto.fox.proto.SensorIntrinsics.prototype.clearD435DepthCameraModel = function() {
  return this.setD435DepthCameraModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.SensorIntrinsics.prototype.hasD435DepthCameraModel = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.VersionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.VersionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.VersionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.VersionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    component: jspb.Message.getFieldWithDefault(msg, 1, ""),
    majorVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minorVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    patchNumber: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.VersionInfo}
 */
proto.fox.proto.VersionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.VersionInfo;
  return proto.fox.proto.VersionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.VersionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.VersionInfo}
 */
proto.fox.proto.VersionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComponent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMajorVersion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinorVersion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPatchNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.VersionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.VersionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.VersionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.VersionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMajorVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMinorVersion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPatchNumber();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string component = 1;
 * @return {string}
 */
proto.fox.proto.VersionInfo.prototype.getComponent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.VersionInfo} returns this
 */
proto.fox.proto.VersionInfo.prototype.setComponent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 major_version = 2;
 * @return {number}
 */
proto.fox.proto.VersionInfo.prototype.getMajorVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.VersionInfo} returns this
 */
proto.fox.proto.VersionInfo.prototype.setMajorVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 minor_version = 3;
 * @return {number}
 */
proto.fox.proto.VersionInfo.prototype.getMinorVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.VersionInfo} returns this
 */
proto.fox.proto.VersionInfo.prototype.setMinorVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 patch_number = 4;
 * @return {number}
 */
proto.fox.proto.VersionInfo.prototype.getPatchNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.VersionInfo} returns this
 */
proto.fox.proto.VersionInfo.prototype.setPatchNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.FirmwareVersion.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.FirmwareVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.FirmwareVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.FirmwareVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.FirmwareVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    packageVersion: (f = msg.getPackageVersion()) && proto.fox.proto.VersionInfo.toObject(includeInstance, f),
    componentVersionsList: jspb.Message.toObjectList(msg.getComponentVersionsList(),
    proto.fox.proto.VersionInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.FirmwareVersion}
 */
proto.fox.proto.FirmwareVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.FirmwareVersion;
  return proto.fox.proto.FirmwareVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.FirmwareVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.FirmwareVersion}
 */
proto.fox.proto.FirmwareVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.VersionInfo;
      reader.readMessage(value,proto.fox.proto.VersionInfo.deserializeBinaryFromReader);
      msg.setPackageVersion(value);
      break;
    case 2:
      var value = new proto.fox.proto.VersionInfo;
      reader.readMessage(value,proto.fox.proto.VersionInfo.deserializeBinaryFromReader);
      msg.addComponentVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.FirmwareVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.FirmwareVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.FirmwareVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.FirmwareVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackageVersion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.VersionInfo.serializeBinaryToWriter
    );
  }
  f = message.getComponentVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.VersionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional VersionInfo package_version = 1;
 * @return {?proto.fox.proto.VersionInfo}
 */
proto.fox.proto.FirmwareVersion.prototype.getPackageVersion = function() {
  return /** @type{?proto.fox.proto.VersionInfo} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.VersionInfo, 1));
};


/**
 * @param {?proto.fox.proto.VersionInfo|undefined} value
 * @return {!proto.fox.proto.FirmwareVersion} returns this
*/
proto.fox.proto.FirmwareVersion.prototype.setPackageVersion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.FirmwareVersion} returns this
 */
proto.fox.proto.FirmwareVersion.prototype.clearPackageVersion = function() {
  return this.setPackageVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.FirmwareVersion.prototype.hasPackageVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated VersionInfo component_versions = 2;
 * @return {!Array<!proto.fox.proto.VersionInfo>}
 */
proto.fox.proto.FirmwareVersion.prototype.getComponentVersionsList = function() {
  return /** @type{!Array<!proto.fox.proto.VersionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.VersionInfo, 2));
};


/**
 * @param {!Array<!proto.fox.proto.VersionInfo>} value
 * @return {!proto.fox.proto.FirmwareVersion} returns this
*/
proto.fox.proto.FirmwareVersion.prototype.setComponentVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.VersionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.VersionInfo}
 */
proto.fox.proto.FirmwareVersion.prototype.addComponentVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.VersionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.FirmwareVersion} returns this
 */
proto.fox.proto.FirmwareVersion.prototype.clearComponentVersionsList = function() {
  return this.setComponentVersionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.RobotConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.RobotConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.RobotConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RobotConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    header: (f = msg.getHeader()) && proto.fox.proto.ConfigurationHeader.toObject(includeInstance, f),
    kinematics: (f = msg.getKinematics()) && planner_proto_kinematics_pb.TricycleKinematicConfiguration.toObject(includeInstance, f),
    extrinsics: (f = msg.getExtrinsics()) && calibration_proto_extrinsics_pb.Extrinsics.toObject(includeInstance, f),
    particleFilterConfig: (f = msg.getParticleFilterConfig()) && map_proto_map_pb.ParticleFilterConfig.toObject(includeInstance, f),
    laserMasks: (f = msg.getLaserMasks()) && sensors_laser_scan_pb.LaserMasks.toObject(includeInstance, f),
    mastHome: (f = msg.getMastHome()) && planner_proto_types_pb.MastControl.toObject(includeInstance, f),
    cameraModel: (f = msg.getCameraModel()) && image_image_pb.CameraModel.toObject(includeInstance, f),
    fieldsetTable: (f = msg.getFieldsetTable()) && planner_proto_types_pb.LaserFieldSetSwitchingTable.toObject(includeInstance, f),
    safetyScannerTables: (f = msg.getSafetyScannerTables()) && planner_proto_types_pb.SafetyScannerTables.toObject(includeInstance, f),
    addressConfig: (f = msg.getAddressConfig()) && proto.fox.proto.AddressConfig.toObject(includeInstance, f),
    sensorIntrinsics: (f = msg.getSensorIntrinsics()) && proto.fox.proto.SensorIntrinsics.toObject(includeInstance, f),
    firmwareVersion: (f = msg.getFirmwareVersion()) && proto.fox.proto.FirmwareVersion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.RobotConfiguration}
 */
proto.fox.proto.RobotConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.RobotConfiguration;
  return proto.fox.proto.RobotConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.RobotConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.RobotConfiguration}
 */
proto.fox.proto.RobotConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = new proto.fox.proto.ConfigurationHeader;
      reader.readMessage(value,proto.fox.proto.ConfigurationHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 1:
      var value = new planner_proto_kinematics_pb.TricycleKinematicConfiguration;
      reader.readMessage(value,planner_proto_kinematics_pb.TricycleKinematicConfiguration.deserializeBinaryFromReader);
      msg.setKinematics(value);
      break;
    case 2:
      var value = new calibration_proto_extrinsics_pb.Extrinsics;
      reader.readMessage(value,calibration_proto_extrinsics_pb.Extrinsics.deserializeBinaryFromReader);
      msg.setExtrinsics(value);
      break;
    case 3:
      var value = new map_proto_map_pb.ParticleFilterConfig;
      reader.readMessage(value,map_proto_map_pb.ParticleFilterConfig.deserializeBinaryFromReader);
      msg.setParticleFilterConfig(value);
      break;
    case 4:
      var value = new sensors_laser_scan_pb.LaserMasks;
      reader.readMessage(value,sensors_laser_scan_pb.LaserMasks.deserializeBinaryFromReader);
      msg.setLaserMasks(value);
      break;
    case 5:
      var value = new planner_proto_types_pb.MastControl;
      reader.readMessage(value,planner_proto_types_pb.MastControl.deserializeBinaryFromReader);
      msg.setMastHome(value);
      break;
    case 6:
      var value = new image_image_pb.CameraModel;
      reader.readMessage(value,image_image_pb.CameraModel.deserializeBinaryFromReader);
      msg.setCameraModel(value);
      break;
    case 7:
      var value = new planner_proto_types_pb.LaserFieldSetSwitchingTable;
      reader.readMessage(value,planner_proto_types_pb.LaserFieldSetSwitchingTable.deserializeBinaryFromReader);
      msg.setFieldsetTable(value);
      break;
    case 9:
      var value = new planner_proto_types_pb.SafetyScannerTables;
      reader.readMessage(value,planner_proto_types_pb.SafetyScannerTables.deserializeBinaryFromReader);
      msg.setSafetyScannerTables(value);
      break;
    case 10:
      var value = new proto.fox.proto.AddressConfig;
      reader.readMessage(value,proto.fox.proto.AddressConfig.deserializeBinaryFromReader);
      msg.setAddressConfig(value);
      break;
    case 11:
      var value = new proto.fox.proto.SensorIntrinsics;
      reader.readMessage(value,proto.fox.proto.SensorIntrinsics.deserializeBinaryFromReader);
      msg.setSensorIntrinsics(value);
      break;
    case 13:
      var value = new proto.fox.proto.FirmwareVersion;
      reader.readMessage(value,proto.fox.proto.FirmwareVersion.deserializeBinaryFromReader);
      msg.setFirmwareVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.RobotConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.RobotConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.RobotConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RobotConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.fox.proto.ConfigurationHeader.serializeBinaryToWriter
    );
  }
  f = message.getKinematics();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      planner_proto_kinematics_pb.TricycleKinematicConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getExtrinsics();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      calibration_proto_extrinsics_pb.Extrinsics.serializeBinaryToWriter
    );
  }
  f = message.getParticleFilterConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      map_proto_map_pb.ParticleFilterConfig.serializeBinaryToWriter
    );
  }
  f = message.getLaserMasks();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      sensors_laser_scan_pb.LaserMasks.serializeBinaryToWriter
    );
  }
  f = message.getMastHome();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      planner_proto_types_pb.MastControl.serializeBinaryToWriter
    );
  }
  f = message.getCameraModel();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      image_image_pb.CameraModel.serializeBinaryToWriter
    );
  }
  f = message.getFieldsetTable();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      planner_proto_types_pb.LaserFieldSetSwitchingTable.serializeBinaryToWriter
    );
  }
  f = message.getSafetyScannerTables();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      planner_proto_types_pb.SafetyScannerTables.serializeBinaryToWriter
    );
  }
  f = message.getAddressConfig();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.fox.proto.AddressConfig.serializeBinaryToWriter
    );
  }
  f = message.getSensorIntrinsics();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.fox.proto.SensorIntrinsics.serializeBinaryToWriter
    );
  }
  f = message.getFirmwareVersion();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.fox.proto.FirmwareVersion.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConfigurationHeader header = 12;
 * @return {?proto.fox.proto.ConfigurationHeader}
 */
proto.fox.proto.RobotConfiguration.prototype.getHeader = function() {
  return /** @type{?proto.fox.proto.ConfigurationHeader} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ConfigurationHeader, 12));
};


/**
 * @param {?proto.fox.proto.ConfigurationHeader|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional TricycleKinematicConfiguration kinematics = 1;
 * @return {?proto.fox.proto.TricycleKinematicConfiguration}
 */
proto.fox.proto.RobotConfiguration.prototype.getKinematics = function() {
  return /** @type{?proto.fox.proto.TricycleKinematicConfiguration} */ (
    jspb.Message.getWrapperField(this, planner_proto_kinematics_pb.TricycleKinematicConfiguration, 1));
};


/**
 * @param {?proto.fox.proto.TricycleKinematicConfiguration|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setKinematics = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearKinematics = function() {
  return this.setKinematics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasKinematics = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Extrinsics extrinsics = 2;
 * @return {?proto.fox.proto.Extrinsics}
 */
proto.fox.proto.RobotConfiguration.prototype.getExtrinsics = function() {
  return /** @type{?proto.fox.proto.Extrinsics} */ (
    jspb.Message.getWrapperField(this, calibration_proto_extrinsics_pb.Extrinsics, 2));
};


/**
 * @param {?proto.fox.proto.Extrinsics|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setExtrinsics = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearExtrinsics = function() {
  return this.setExtrinsics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasExtrinsics = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ParticleFilterConfig particle_filter_config = 3;
 * @return {?proto.fox.proto.ParticleFilterConfig}
 */
proto.fox.proto.RobotConfiguration.prototype.getParticleFilterConfig = function() {
  return /** @type{?proto.fox.proto.ParticleFilterConfig} */ (
    jspb.Message.getWrapperField(this, map_proto_map_pb.ParticleFilterConfig, 3));
};


/**
 * @param {?proto.fox.proto.ParticleFilterConfig|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setParticleFilterConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearParticleFilterConfig = function() {
  return this.setParticleFilterConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasParticleFilterConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LaserMasks laser_masks = 4;
 * @return {?proto.fox.proto.LaserMasks}
 */
proto.fox.proto.RobotConfiguration.prototype.getLaserMasks = function() {
  return /** @type{?proto.fox.proto.LaserMasks} */ (
    jspb.Message.getWrapperField(this, sensors_laser_scan_pb.LaserMasks, 4));
};


/**
 * @param {?proto.fox.proto.LaserMasks|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setLaserMasks = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearLaserMasks = function() {
  return this.setLaserMasks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasLaserMasks = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MastControl mast_home = 5;
 * @return {?proto.fox.proto.MastControl}
 */
proto.fox.proto.RobotConfiguration.prototype.getMastHome = function() {
  return /** @type{?proto.fox.proto.MastControl} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.MastControl, 5));
};


/**
 * @param {?proto.fox.proto.MastControl|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setMastHome = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearMastHome = function() {
  return this.setMastHome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasMastHome = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CameraModel camera_model = 6;
 * @return {?proto.fox.proto.CameraModel}
 */
proto.fox.proto.RobotConfiguration.prototype.getCameraModel = function() {
  return /** @type{?proto.fox.proto.CameraModel} */ (
    jspb.Message.getWrapperField(this, image_image_pb.CameraModel, 6));
};


/**
 * @param {?proto.fox.proto.CameraModel|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setCameraModel = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearCameraModel = function() {
  return this.setCameraModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasCameraModel = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LaserFieldSetSwitchingTable fieldset_table = 7;
 * @return {?proto.fox.proto.LaserFieldSetSwitchingTable}
 */
proto.fox.proto.RobotConfiguration.prototype.getFieldsetTable = function() {
  return /** @type{?proto.fox.proto.LaserFieldSetSwitchingTable} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.LaserFieldSetSwitchingTable, 7));
};


/**
 * @param {?proto.fox.proto.LaserFieldSetSwitchingTable|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setFieldsetTable = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearFieldsetTable = function() {
  return this.setFieldsetTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasFieldsetTable = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SafetyScannerTables safety_scanner_tables = 9;
 * @return {?proto.fox.proto.SafetyScannerTables}
 */
proto.fox.proto.RobotConfiguration.prototype.getSafetyScannerTables = function() {
  return /** @type{?proto.fox.proto.SafetyScannerTables} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.SafetyScannerTables, 9));
};


/**
 * @param {?proto.fox.proto.SafetyScannerTables|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setSafetyScannerTables = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearSafetyScannerTables = function() {
  return this.setSafetyScannerTables(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasSafetyScannerTables = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional AddressConfig address_config = 10;
 * @return {?proto.fox.proto.AddressConfig}
 */
proto.fox.proto.RobotConfiguration.prototype.getAddressConfig = function() {
  return /** @type{?proto.fox.proto.AddressConfig} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.AddressConfig, 10));
};


/**
 * @param {?proto.fox.proto.AddressConfig|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setAddressConfig = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearAddressConfig = function() {
  return this.setAddressConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasAddressConfig = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional SensorIntrinsics sensor_intrinsics = 11;
 * @return {?proto.fox.proto.SensorIntrinsics}
 */
proto.fox.proto.RobotConfiguration.prototype.getSensorIntrinsics = function() {
  return /** @type{?proto.fox.proto.SensorIntrinsics} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.SensorIntrinsics, 11));
};


/**
 * @param {?proto.fox.proto.SensorIntrinsics|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setSensorIntrinsics = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearSensorIntrinsics = function() {
  return this.setSensorIntrinsics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasSensorIntrinsics = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional FirmwareVersion firmware_version = 13;
 * @return {?proto.fox.proto.FirmwareVersion}
 */
proto.fox.proto.RobotConfiguration.prototype.getFirmwareVersion = function() {
  return /** @type{?proto.fox.proto.FirmwareVersion} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.FirmwareVersion, 13));
};


/**
 * @param {?proto.fox.proto.FirmwareVersion|undefined} value
 * @return {!proto.fox.proto.RobotConfiguration} returns this
*/
proto.fox.proto.RobotConfiguration.prototype.setFirmwareVersion = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotConfiguration} returns this
 */
proto.fox.proto.RobotConfiguration.prototype.clearFirmwareVersion = function() {
  return this.setFirmwareVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotConfiguration.prototype.hasFirmwareVersion = function() {
  return jspb.Message.getField(this, 13) != null;
};


goog.object.extend(exports, proto.fox.proto);
