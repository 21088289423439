import React, { Component } from "react";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";

import { ApplicationState } from "../../redux";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import Datasheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import {
  Column,
  FormattedData,
  statsToRows,
  StylableCSVLink
} from "./StatSheetUtils";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid } from "react-window";
import moment from "moment";

export interface GridElement extends Datasheet.Cell<GridElement, number> {
  value: number | string;
}

const styles = (theme: Theme) =>
  createStyles({
    sheet: {
      width: "100%",
      height: "100%",
      marginBottom: 24
    },
    wrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 24
    },
    cell: {
      border: "1px solid",
      textAlign: "center"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  loading?: boolean;
  stats: Array<m_pb.Fault.AsObject>;
}
interface State {
  rows: GridElement[][];
  topLeft: { rowIdx: number; colIdx: number };
  botRight: { rowIdx: number; colIdx: number };
}

const defaultColumnProperties = {};
type formatter = (val: number | string) => number | string;
const emptyFormatter: formatter = (v: number | string) => String(v);
const timeFormatter = (ns: number) => new Date(ns / 1e6).toISOString();

const columns: Array<Column> = [
  { key: "id", name: "Fault ID", formatter: emptyFormatter },
  { key: "runName", name: "Run Name", formatter: emptyFormatter },
  {
    key: "startTime",
    name: "Start Time",
    width: 320,
    formatter: timeFormatter
  },
  {
    key: "endTime",
    name: "End Time",
    width: 320,
    formatter: timeFormatter
  },
  {
    key: "robotTime",
    name: "Robot Time",
    width: 320,
    formatter: emptyFormatter
  },
  { key: "errorType", name: "Fault Type", formatter: emptyFormatter },
  { key: "errorCode", name: "Fault Code", formatter: emptyFormatter },
  { key: "severity", name: "Fault Severity", formatter: emptyFormatter },
  { key: "region", name: "Region", formatter: emptyFormatter },
  { key: "action", name: "Action", formatter: emptyFormatter },
  { key: "manualMode", name: "Manual Mode", formatter: emptyFormatter },
].map(c => ({ ...defaultColumnProperties, ...c }));

class FaultsSheet extends Component<Props, State> {
  state = {
    rows: [],
    topLeft: { rowIdx: 0, colIdx: 0 },
    botRight: { rowIdx: 0, colIdx: 0 }
  };

  render() {
    const { classes, stats, loading } = this.props;
    const formattedData: FormattedData = statsToRows(
      stats,
      [],
      columns,
      defaultColumnProperties
    );
    // @ts-ignore
    const renderCell = ({ columnIndex, rowIndex, style }) => {
      const cell = formattedData.cells[rowIndex][columnIndex];
      return (
        <div style={style} className={classes.cell}>
          {cell.component || cell.value}
        </div>
      );
    };
    return (
      <div className={classes.wrapper}>
        {!loading && (
          <StylableCSVLink
            filename="faults.csv"
            csvData={formattedData.downloadable}
          />
        )}
        <div className={classes.sheet}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <VariableSizeGrid
                  columnWidth={index => {
                    // Allow a comfortable amount of room for each column
                    return (
                      10 *
                      Math.max(
                        ...formattedData.cells
                          .map(row => row[index])
                          .map(cellValue => cellValue.value.toString().length)
                      )
                    );
                  }}
                  rowHeight={() => {
                    // Display 20 rows at a time in the viewport
                    return height / 20;
                  }}
                  columnCount={columns.length}
                  height={height}
                  rowCount={formattedData.cells.length}
                  width={width}
                >
                  {renderCell}
                </VariableSizeGrid>
              );
            }}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(FaultsSheet));
