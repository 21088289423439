import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import { grpc } from "@improbable-eng/grpc-web";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";

import { ApplicationState, ViewerState } from "../../redux";
import { landingPath, logInPath } from "../../utils/Paths";
import { signUpRequest } from "../../redux/actions";

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    error: {
      backgroundColor: theme.palette.error.dark
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1)
    },
    link: {
      margin: theme.spacing(1)
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return { viewer: state.viewer };
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
  viewer: ViewerState;
}

interface State {
  password1: string;
  password2: string;
  email: string;
  isLoading: boolean;
  errorMessage: string | null;
}

class SignUp extends Component<Props, State> {
  state = {
    password1: "",
    password2: "",
    email: "",
    errorMessage: null,
    isLoading: false
  };
  render() {
    const { classes, viewer } = this.props;
    const { isLoading, errorMessage } = this.state;

    if (viewer.account) {
      return <Redirect to={landingPath()} />;
    }
    const error = errorMessage ? (
      <SnackbarContent className={classes.error} message={errorMessage} />
    ) : null;

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <div className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                onChange={e => this.setState({ email: e.currentTarget.value })}
                onKeyDown={e => {
                  e.key === "Enter" && this._signUp();
                }}
                name="email"
                autoComplete="email"
                autoFocus
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                onChange={e =>
                  this.setState({ password1: e.currentTarget.value })
                }
                onKeyDown={e => {
                  e.key === "Enter" && this._signUp();
                }}
                name="password-1"
                type="password"
                id="password-1"
                autoComplete="current-password"
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Confirm password</InputLabel>
              <Input
                onChange={e =>
                  this.setState({ password2: e.currentTarget.value })
                }
                onKeyDown={e => {
                  e.key === "Enter" && this._signUp();
                }}
                name="password-2"
                type="password"
                id="password-2"
                required={false}
                autoComplete="current-password"
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => this._signUp()}
              disabled={isLoading}
            >
              Sign Up
            </Button>
            {error}
            <Typography>
              <Link
                color="secondary"
                href={logInPath()}
                className={classes.link}
              >
                or log in
              </Link>
            </Typography>
          </div>
        </Paper>
      </main>
    );
  }

  _signUp() {
    const { dispatch } = this.props;
    const { email, password1, password2 } = this.state;
    if (!email) {
      return this.setState({ errorMessage: "Please enter your email" });
    }
    if (!password1 || !password2) {
      return this.setState({ errorMessage: "Please enter your password" });
    }
    if (password1 !== password2) {
      return this.setState({ errorMessage: "Passwords do not match" });
    }
    this.setState({ isLoading: true }, () =>
      dispatch(signUpRequest(email, password1))
        .catch((e: ServiceError) => {
          console.error(`SignUp failed with ${e}`);
          switch (e.code) {
            case grpc.Code.PermissionDenied: {
              return this.setState({
                errorMessage: "Email is not an active Fox account"
              });
            }
            case grpc.Code.AlreadyExists: {
              return this.setState({
                errorMessage: `Account already exists with email ${email}`
              });
            }
            case grpc.Code.InvalidArgument: {
              return this.setState({
                errorMessage: "Please enter your email and password"
              });
            }
          }
        })
        .finally(() => this.setState({ isLoading: false }))
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(SignUp));
