import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ProgressSpinner from "../Utils/ProgressSpinner";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import { ApplicationState } from "../../redux";
import {
  createForkliftCohortRequest,
  listForkliftCohortsRequest,
  listOrganizationsRequest,
  listSitesRequest
} from "../../redux/actions";
import { CardHeader } from "@material-ui/core";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { grpc } from "@improbable-eng/grpc-web";
import { logInPath } from "../../utils/Paths";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CohortListItem from "./CohortListItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Toolbar from "@material-ui/core/Toolbar";
import CreateSiteDialog from "./CreateSiteDialog";
import CreateCohortDialog from "./CreateCohortDialog";
import UpdateCohortDialog from "./UpdateCohortDialog";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    wrapper: {
      width: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "column"
    },
    card: {
      width: "100%"
    },
    content: {
      width: "100%",
      display: "flex",
      alignItems: "start",
      flexDirection: "column",
      paddingTop: 0
    },
    listHead: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  cohorts: Array<m_pb.ForkliftCohort>;
  organizations: Array<m_pb.Organization.AsObject>;
  sites: Array<m_pb.Site.AsObject>;
  isLoading: boolean;
  isCreatingCohort: boolean;
  editingCohortName: string;
  redirectTo: string | null;
  responseCount: number;
  pendingCohortName: string;
  pendingCohortOrg: string;
  isAllExpanded: boolean;
  isCreatingSite: boolean;
}

class CohortsDashboard extends Component<Props, State> {
  state: State = {
    cohorts: [],
    organizations: [],
    sites: [],
    isLoading: false,
    isCreatingCohort: false,
    editingCohortName: "",
    redirectTo: null,
    responseCount: 0,
    pendingCohortName: "",
    pendingCohortOrg: "",
    isAllExpanded: false,
    isCreatingSite: false
  };

  componentDidMount(): void {
    this._fetchMore();
  }

  toggleAllListItems = () => {
    this.setState(prevState => ({
      isAllExpanded: !prevState.isAllExpanded
    }));
  };

  _fetchMore() {
    this.setState({ isLoading: true }, () => {
      const finish = () => this.setState({ isLoading: false });
      const { cohorts } = this.state;
      const count = cohorts.length;
      this.props
        .dispatch(
          listForkliftCohortsRequest({ pageToken: count, pageSize: 500 })
        )
        .then((payload: { response: m_pb.ListForkliftCohortsResponse }) => {
          let { cohorts } = this.state;
          const { response } = payload;
          // @ts-ignore
          cohorts.push(...response.getCohortsList());
          this.setState({ responseCount: response.getCount(), cohorts }, () => {
            this.props
              .dispatch(listOrganizationsRequest())
              .then((response: m_pb.ListOrganizationsResponse.AsObject) => {
                this.setState(
                  { organizations: response.organizationsList },
                  () => {
                    this.props
                      .dispatch(listSitesRequest())
                      .then((response: m_pb.ListSitesResponse.AsObject) => {
                        this.setState({ sites: response.sitesList });
                        finish();
                      });
                  }
                );
              });
          });
        })
        .catch((e: ServiceError) => {
          switch (e.code) {
            case grpc.Code.Unauthenticated: {
              this.setState({
                redirectTo: logInPath(window.location.pathname)
              });
              break;
            }
            // TODO(malcolm): Add pages for permission denied, 500 error
          }
        })
        .finally(finish);
    });
  }

  render() {
    const {
      props: { classes },
      state: {
        isLoading,
        responseCount,
        redirectTo,
        cohorts,
        isCreatingCohort,
        isAllExpanded
      }
    } = this;
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    const hasMore = responseCount > cohorts.length;
    const progressSpinner = isLoading ? <ProgressSpinner /> : null;
    return (
      <div className={classes.wrapper}>
        <CreateSiteDialog
          open={this.state.isCreatingSite}
          onClose={() => this.setState({ isCreatingSite: false })}
          onSuccess={() => this.setState({ isCreatingSite: false })}
        />
        <CreateCohortDialog
          open={isCreatingCohort}
          organizations={this.state.organizations}
          sites={this.state.sites}
          onClose={() => this.setState({ isCreatingCohort: false })}
          onSuccess={(newCohort: m_pb.ForkliftCohort) =>
            this.setState({
              isCreatingCohort: false,
              cohorts: [newCohort, ...this.state.cohorts]
            })
          }
        />
        <Card className={classes.card}>
          <CardHeader title={"Cohorts"} />
          <CardContent className={classes.content}>
            <div className={classes.listHead}>
              <Button
                color={"secondary"}
                variant={"outlined"}
                disabled={isLoading}
                onClick={() => this.setState({ isCreatingCohort: true })}
              >
                Create Cohort
              </Button>
              <Button
                color={"secondary"}
                variant={"outlined"}
                disabled={isLoading}
                onClick={() => this.setState({ isCreatingSite: true })}
              >
                Create Site
              </Button>
              <Button
                color={"secondary"}
                variant={"outlined"}
                disabled={isLoading}
                onClick={this.toggleAllListItems}
              >
                {isAllExpanded ? "Collapse" : "Expand"} All
              </Button>
            </div>
            <List component="nav" className={classes.root}>
              {cohorts.map((c, i) => (
                <CohortListItem
                  cohort={(c as m_pb.ForkliftCohort).toObject()}
                  isAllExpanded={isAllExpanded}
                  organizations={this.state.organizations}
                  sites={this.state.sites}
                />
              ))}
            </List>
            {progressSpinner}
            <Button
              fullWidth
              disabled={isLoading || !hasMore}
              onClick={() => this._fetchMore()}
            >
              Load More
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(CohortsDashboard));
