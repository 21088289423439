import React, { Component } from "react";

import { createStyles, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import { createAnnotationRequest } from "../../redux/actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TrelloCard from "../Utils/TrelloCard";

const styles = (theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column"
    },
    interventionRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      marginBottom: 16,
      padding: 16
    },
    interventionButton: {
      margin: "auto"
    },
    topRow: {
      paddingTop: 8,
      paddingBottom: 16,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    interventionText: {
      flexGrow: 1
    },
    description: {
      width: "100%",
      minWidth: 440
    },
    addTimestamp: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    },
    times: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      padding: theme.spacing(2)
    },
    timeWrapper: {
      padding: 16,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4
    },
    menuTitle: {
      textTransform: "capitalize"
    }
  });

type Props = {
  classes: any;
  epochOffset: number;
  runId: string;
  eventTime: number | null;
  dispatch: any;
  open: boolean;
  onClose: () => void;
  onSuccess: (n: m_pb.Annotation) => void;
  requestClearTime: () => void;
};
type State = {
  note: m_pb.Annotation;
  isWaiting: boolean;
  error: string | null;
};

class CreateAnnotationDialog extends Component<Props, State> {
  state = {
    note: new m_pb.Annotation(),
    isWaiting: false,
    error: null
  };

  componentDidMount(): void {
    const { note } = this.state;
    note.setRunId(this.props.runId);
    this.setState({ note });
  }

  _updateDescription(description: string) {
    const { note } = this.state;
    note.setDescription(description);
    this.setState({ note });
  }

  _updateBugURL(url: string) {
    const { note } = this.state;
    let externalBug: m_pb.ExternalBug | undefined = undefined;
    if (url.trim().length) {
      externalBug = note.getExternalBug();
      if (!externalBug) {
        externalBug = new m_pb.ExternalBug();
      }
      externalBug.setUrl(url.trim());
      note.setExternalBug(externalBug);
    } else {
      note.clearExternalBug();
    }
    this.setState({
      note
    });
  }

  _submit = () => {
    const { note } = this.state;
    const { eventTime, onSuccess } = this.props;
    if (!eventTime) {
      this.setState({ error: "The note must have a timestamp" });
      return;
    }
    note.setEventTime(eventTime);
    this.props
      .dispatch(createAnnotationRequest(note))
      .then((payload: any) => {
        console.log(payload);
        onSuccess(payload);
      })
      .catch((e: any) => this.setState({ error: e }));
  };

  render() {
    const { note } = this.state;
    const { classes, open, onClose, epochOffset, eventTime } = this.props;
    const eventDate = eventTime
      ? new Date((eventTime + epochOffset) / 1e6)
      : null;
    const externalBug = note.getExternalBug();
    const trelloUrl: string | null = externalBug ? externalBug.getUrl() : null;
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={() => onClose && onClose()}
          aria-labelledby="create-note-dialog-title"
        >
          <DialogTitle id="create-note-dialog-title">
            Create Annotation
          </DialogTitle>
          <DialogContent>
            <div className={classes.topRow}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="component-select">Intervention</InputLabel>
                <Select
                  className={classes.menuTitle}
                  inputProps={{ id: "component-select" }}
                  value={note.getIntervention()}
                  onChange={e => {
                    note.setIntervention(parseInt(e.target.value as string));

                    this.setState({
                      note
                    });
                  }}
                >
                  {Object.entries(cc_pb.InterventionType).map(e => (
                    <MenuItem
                      key={e[1]}
                      value={e[1]}
                      className={classes.menuTitle}
                    >
                      {e[0]
                        .replace("INTERVENTION_", "")
                        .replace(/_/g, " ")
                        .toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.topRow}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="root-select">Root Cause</InputLabel>
                <Select
                  className={classes.menuTitle}
                  inputProps={{ id: "root-select" }}
                  value={note.getInterventionCause()}
                  onChange={e => {
                    note.setInterventionCause(
                      parseInt(e.target.value as string)
                    );
                    this.setState({
                      note
                    });
                  }}
                >
                  {Object.entries(cc_pb.InterventionCause).map(e => (
                    <MenuItem
                      key={e[1]}
                      value={e[1]}
                      className={classes.menuTitle}
                    >
                      {e[0]
                        .replace("INTERVENTION_CAUSE", "")
                        .replace(/_/g, " ")
                        .toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.interventionRow}>
              <TextField
                id="create-note-description-textfield"
                key="create-note-description-textfield"
                label="Description"
                autoFocus
                className={classes.interventionText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this._updateDescription(e.target.value)
                }
                multiline
                rows={2}
                value={note.getDescription()}
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className={classes.interventionRow}>
              <div className={classes.column}>
                <TextField
                  id="update-annotation-url-textfield"
                  key="update-annotation-url-textfield"
                  label="Bug URL"
                  className={classes.interventionText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const trimmedUrl = e.target.value.replace(
                      /(trello.com\/c\/[a-zA-Z0-9]+\/[0-9]+).*/,
                      "$1"
                    );
                    this._updateBugURL(trimmedUrl);
                  }}
                  multiline={false}
                  value={externalBug ? externalBug.getUrl() : ""}
                  margin="normal"
                  variant="outlined"
                />
                {trelloUrl && <TrelloCard noFallback cardUrl={trelloUrl} />}
              </div>
            </div>
            <div className={classes.interventionRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={note.getPalletDamage()}
                    onChange={e => {
                      note.setPalletDamage(e.target.checked);
                      this.setState({ note });
                    }}
                    name="pallet-damage-check"
                    color="secondary"
                  />
                }
                label="Collision damaged the pallet"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={note.getGoodsDamage()}
                    onChange={e => {
                      note.setGoodsDamage(e.target.checked);
                      this.setState({ note });
                    }}
                    name="goods-damage-check"
                    color="secondary"
                  />
                }
                label="Collision damaged case(s) on the pallet"
              />
            </div>
            <div className={classes.timeWrapper}>
              <div className={classes.addTimestamp}>
                {eventDate && (
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    color={"textSecondary"}
                  >
                    Time: {eventDate.toString()}
                  </Typography>
                )}
                <Button
                  size={"small"}
                  onClick={() => this.props.requestClearTime()}
                  color={"secondary"}
                >
                  Clear
                </Button>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onClose()} color="secondary">
              Cancel
            </Button>
            <Button onClick={() => this._submit()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = () => undefined;

export default connect(mapStateToProps)(
  withStyles(styles)(CreateAnnotationDialog)
);
