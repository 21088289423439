import React, { Component } from "react";
import { VariableSizeGrid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";

import { ApplicationState, ViewerState } from "../../redux";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import cc_pb from "../../_proto/command_control/proto/command_control_pb";

import "react-datasheet/lib/react-datasheet.css";
import { protoEnumFieldName } from "../../utils/protos";
import f_pb from "../../_proto/log/fault_pb";
import { annotation, run } from "../../utils/Paths";
import { StylableCSVLink, statsToRows } from "./StatSheetUtils";

const styles = (theme: Theme) =>
  createStyles({
    sheet: {
      width: "100%",
      height: "100%",
      marginBottom: 24
    },
    wrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 24
    },
    cell: {
      border: "1px solid",
      textAlign: "center"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return { viewer: state.viewer };
};

interface Props extends WithStyles<typeof styles> {
  loading?: boolean;
  dispatch: any;
  stats: Array<m_pb.Stoppage.AsObject>;
  omittedFieldNames?: Array<string>;
  viewer: ViewerState;
}

const clipDigits = (val: number, places: number): number =>
  Math.round(val * 10 ** places) / 10 ** places;

const defaultColumnProperties = {};
type formatter = (val: number | string) => number | string;
const emptyFormatter: formatter = (v: any) => v;
const boolFormatter: formatter = (v: any) => v ? "Yes" : "No";
const listFormatter = (v: Array<string>) => v ? v.join(",") : "";
const durationFormatter = (v: number) => clipDigits(v / 1e9, 2);
const timeFormatter = (ns: number) =>
  new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }).format(new Date(ns / 1e6));
const faultsFormatter = (v: Array<m_pb.Fault.AsObject>) =>
  Array.from(new Set(v.map(v => v.faultType)))
    .map(t => protoEnumFieldName(f_pb.Fault.DetailsCase, t))
    .join(", ");
const typesFormatter = (v: Array<cc_pb.InterventionType>) =>
  v
    .map(f =>
      protoEnumFieldName(cc_pb.InterventionType, f).replace("INTERVENTION_", "")
    )
    .join(", ");
const causesFormatter = (v: Array<cc_pb.InterventionCause>) =>
  v
    .map(f =>
      protoEnumFieldName(cc_pb.InterventionCause, f).replace(
        "INTERVENTION_CAUSE_",
        ""
      )
    )
    .join(", ");
const categoriesFormatter = (v: Array<cc_pb.InterventionCauseCategory>) =>
  v
    .map(f =>
      protoEnumFieldName(cc_pb.InterventionCauseCategory, f).replace(
        "INTERVENTION_CAUSE_CATEGORY_",
        ""
      )
    )
    .join(", ");

class StoppageSheet extends Component<Props> {
  columns = [
    {
      key: "runName",
      name: "Run",
      formatter: emptyFormatter,
      componentMaker:
        this.props.viewer.account &&
        this.props.viewer.account.isStaff &&
        ((runName: string) => (
          <a href={run(runName)} target="_blank">
            {runName}
          </a>
        ))
    },
    {
      key: "id",
      name: "Stoppage Id",
      formatter: emptyFormatter
    },
    {
      key: "cohortName",
      name: "Cohort",
      formatter: emptyFormatter
    },
    {
      key: "annotationsList",
      name: "Annotations",
      formatter: emptyFormatter,
      componentMaker: (annotations: Array<m_pb.Annotation.AsObject>) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          {annotations.map(a =>
            this.props.viewer.account && this.props.viewer.account.isStaff ? (
              <a key={a.id} href={annotation(a.runId, a.id)} target="_blank">
                {a.description}
              </a>
            ) : (
              <div>{a.description}</div>
            )
          )}
        </div>
      )
    },
    {
      key: "startTime",
      name: "Start time (robot epoch)",
      formatter: emptyFormatter
    },
    { key: "endTime", name: "End time (robot epoch)", width: 160, formatter: emptyFormatter },
    {
      key: "startTimeUtc",
      name: "Start time",
      formatter: timeFormatter
    },
    { key: "endTimeUtc", name: "End time", width: 160, formatter: timeFormatter },
    {
      key: "duration",
      name: "Duration (s)",
      formatter: durationFormatter
    },
    {
      key: "manualTime",
      name: "Manual Time (s)",
      formatter: durationFormatter
    },
    {
      key: "responseTime",
      name: "Response Time (s)",
      formatter: durationFormatter
    },
    {
      key: "interventionRobotFaultCodeTranslated",
      name: "Primary Fault Code",
      formatter: emptyFormatter
    },
    {
      key: "faultTitle",
      name: "Primary Fault Title",
      formatter: emptyFormatter
    },
    {
      key: "numericCode",
      name: "Primary Fault Numeric Code",
      formatter: emptyFormatter
    },
    {
      key: "interventionTypesList",
      name: "Operator-specified intervention type(s)",
      formatter: typesFormatter
    },
    {
      key: "interventionCausesList",
      name: "Operator-specified root cause(s)",
      formatter: causesFormatter
    },
    {
      key: "humanAssistanceRequestStart",
      name: "HAR Start Time (robot epoch)",
      formatter: emptyFormatter
    },
    {
      key: "humanAssistanceRequestEnd",
      name: "HAR End Time (robot epoch)",
      formatter: emptyFormatter
    },
    {
      key: "interventionRobotFaultCodeFirst",
      name: "HAR First Fault (raw)",
      formatter: emptyFormatter
    },
    {
      key: "firstFaultDuration",
      name: "HAR First Fault Duration (s)",
      formatter: durationFormatter
    },
    {
      key: "interventionRobotFaultCodesAllList",
      name: "HAR All Faults",
      formatter: listFormatter
    },
    {
      key: "motionType",
      name: "Motion Type",
      formatter: emptyFormatter
    },
    {
      key: "region",
      name: "Region",
      formatter: emptyFormatter
    },{
      key: "hasPicked",
      name: "Stoppage started after pick?",
      formatter: boolFormatter
    },{
      key: "versionName",
      name: "Version",
      formatter: emptyFormatter
    },{
      key: "interventionTrigger",
      name: "Intervention Trigger",
      formatter: emptyFormatter
    },{
      key: "harCount",
      name: "HAR Count",
      formatter: emptyFormatter
    },{
      key: "manualSegmentCount",
      name: "Manual Mode Switches Count",
      formatter: emptyFormatter
    },{
      key: "primaryInterventionAction",
      name: "Main Operator Action",
      formatter: emptyFormatter
    },{
      key: "allInterventionActions",
      name: "All Operator Actions",
      formatter: listFormatter
    }
  ].map(c => ({ ...defaultColumnProperties, ...c }));

  render() {
    const { classes, stats, omittedFieldNames, loading } = this.props;
    const formattedData = statsToRows(
      stats,
      omittedFieldNames || [],
      this.columns,
      defaultColumnProperties
    );
    // @ts-ignore
    const renderCell = ({ columnIndex, rowIndex, style }) => {
      const cell = formattedData.cells[rowIndex][columnIndex];
      return (
        <div style={style} className={classes.cell}>
          {cell.component || cell.value}
        </div>
      );
    };
    return (
      <div className={classes.wrapper}>
        { !loading && <StylableCSVLink
          filename="all_stoppages.csv"
          csvData={formattedData.downloadable}
        />}
        <div className={classes.sheet}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <VariableSizeGrid
                  columnWidth={index => {
                    // Allow a comfortable amount of room for each column
                    return (
                      8.5 *
                      Math.max(
                        ...formattedData.cells
                          .map(row => row[index])
                          .map(cellValue =>
                            cellValue.value.toString().indexOf(" ") >= 0
                              ? cellValue.value.toString().length / 2
                              : cellValue.value.toString().length
                          )
                      )
                    );
                  }}
                  rowHeight={() => {
                    return height / 7;
                  }}
                  columnCount={
                    this.columns.length - (omittedFieldNames || []).length
                  }
                  height={height}
                  rowCount={formattedData.cells.length}
                  width={width}
                >
                  {renderCell}
                </VariableSizeGrid>
              );
            }}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(StoppageSheet));
