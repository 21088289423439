import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { MonitoringClient, ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import {
  ListRobotTabletConfigurationsRequest,
  ListRobotTabletConfigurationsResponse, RobotTabletConfiguration,
  RobotTabletConfigurationSummary, UpdateRobotTabletConfigurationRequest
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { BrowserHeaders } from "browser-headers";
import { NormalizedEntities, upsertEntity } from '../normalizedEntities'
import {authHeader} from "../sagas";


export const fetchTabletConfigurations = createAsyncThunk<
ListRobotTabletConfigurationsResponse | null,
  { client: MonitoringClient, req: ListRobotTabletConfigurationsRequest },
  { rejectValue: ServiceError }
>(
  'tabletConfigs/list',
  async (arg: {client: MonitoringClient, req: ListRobotTabletConfigurationsRequest}, { rejectWithValue }) => {
    const data = new Promise<ListRobotTabletConfigurationsResponse | null>((resolve, reject) => {
      arg.client.listRobotTabletConfigurations(arg.req, authHeader(), (err, response) => {
        if (err) {
          reject(err)
        } else {
          resolve(response)
        }
      })
    })

    try {
      return await data;
    } catch (err) {
      return rejectWithValue(err as ServiceError)
    }
  }
);

export const updateRobotTabletConfiguration = createAsyncThunk<
RobotTabletConfigurationSummary | null,
  { client: MonitoringClient, req: UpdateRobotTabletConfigurationRequest },
  { rejectValue: ServiceError }
>(
  'tabletConfigs/update',
  async (arg: {client: MonitoringClient, req: UpdateRobotTabletConfigurationRequest}, { rejectWithValue }) => {
    const data = new Promise<RobotTabletConfigurationSummary | null>((resolve, reject) => {
      arg.client.updateRobotTabletConfiguration(arg.req, authHeader(), (err, response) => {
        if (err) {
          reject(err)
        } else {
          resolve(response)
        }
      })
    })

    try {
      return await data;
    } catch (err) {
      return rejectWithValue(err as ServiceError)
    }
  }
);

export interface TabletConfigEntityState {
  entities: NormalizedEntities<RobotTabletConfigurationSummary.AsObject, string>
  loading: 'idle' | 'pending';
  reqId: string | undefined;
  error: ServiceError | undefined;
}

const initialState: TabletConfigEntityState = {
  entities: {
    byId: new Map<string, RobotTabletConfigurationSummary.AsObject>(),
    allIds: [],
  },
  loading: 'idle',
  reqId:  undefined,
  error: undefined,
}

const tabletConfigEntitySlice = createSlice({
  name: 'tabletConfigEntities',
  initialState: initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchTabletConfigurations.pending,
      (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.reqId = action.meta.requestId;
        }
      })
    .addCase(fetchTabletConfigurations.fulfilled,
      (state, action) => {
        const { requestId } = action.meta;

        if (
          state.loading === 'pending' &&
          state.reqId === requestId
        ) {
          state.loading = 'idle';
          state.reqId = undefined;

          if (action.payload) {
            state.entities = action.payload.getRobotTabletConfigurationsList()
              .reduce((agg, a, i) => {
                const asObject = a.toObject();

                if (asObject.robotName) {
                  agg.byId.set(asObject.robotName, asObject)
                  if (!agg.allIds.includes(asObject.robotName)) {
                    agg.allIds.push(asObject.robotName)
                  }
                }

                return agg;
              }, state.entities)
          }
        }
      })
    .addCase(fetchTabletConfigurations.rejected,
      (state, action) => {
        const { requestId } = action.meta;

        if (
          state.loading === 'pending' &&
          state.reqId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.payload;
          state.reqId = undefined;
        }
      })
    .addCase(updateRobotTabletConfiguration.fulfilled,
      (state, action) => {
        if (action.payload) {
          const robotConfig = action.payload.toObject();
          if (robotConfig.robotName) {
            state.entities.byId.set(robotConfig.robotName, robotConfig)
            if (!state.entities.allIds.includes(robotConfig.robotName)) {
              state.entities.allIds.push(robotConfig.robotName)
            }
          }
        }
      })
  }
})

export default tabletConfigEntitySlice.reducer;
