import m_pb, { RobotStatus } from "../_proto/command_control/monitoring/proto/monitoring_pb";
import cc_pb from "../_proto/command_control/proto/command_control_pb";
import rd_pb from "../_proto/log/run_description_pb";

export class DataLogUrls {
  state: string;
  sensor: string;
  plc: string;
  video: string;

  constructor(state: string, sensor: string, plc: string, video: string) {
    this.state = state;
    this.sensor = sensor;
    this.plc = plc;
    this.video = video;
  }

  static fromProto(proto: m_pb.DataLogUrls): DataLogUrls {
    return new DataLogUrls(
      proto.getState(),
      proto.getSensor(),
      proto.getPlc(),
      proto.getVideo()
    );
  }

  toProto(): m_pb.DataLogUrls {
    const proto = new m_pb.DataLogUrls();
    proto.setState(this.state);
    proto.setSensor(this.sensor);
    proto.setPlc(this.plc);
    proto.setVideo(this.video);
    return proto;
  }
}

export type NoteComponent = cc_pb.Note.Component;
export type NoteBugPriority = cc_pb.Note.BugPriority;

export class Annotation {
  id: string;
  runId: string;
  description: string;
  eventTime: number;
  intervention: cc_pb.InterventionType;
  interventionCause: cc_pb.InterventionCause;
  palletDamage: boolean;
  goodsDamage: boolean;
  externalBug?: m_pb.ExternalBug;

  constructor(
    id: string,
    runId: string,
    description: string,
    eventTime: number,
    intervention: cc_pb.InterventionType,
    interventionCause: cc_pb.InterventionCause,
    palletDamage: boolean,
    goodsDamage: boolean,
    externalBug?: m_pb.ExternalBug
  ) {
    this.id = id;
    this.runId = runId;
    this.description = description;
    this.eventTime = eventTime;
    this.intervention = intervention;
    this.interventionCause = interventionCause;
    this.palletDamage = palletDamage;
    this.goodsDamage = goodsDamage;
    this.externalBug = externalBug;
  }

  static fromProto(proto: m_pb.Annotation): Annotation {
    return new Annotation(
      proto.getId(),
      proto.getRunId(),
      proto.getDescription(),
      proto.getEventTime(),
      proto.getIntervention(),
      proto.getInterventionCause(),
      proto.getPalletDamage(),
      proto.getGoodsDamage(),
      proto.getExternalBug()
    );
  }

  toProto(): m_pb.Annotation {
    const proto = new m_pb.Annotation();
    proto.setId(this.id);
    proto.setRunId(this.runId);
    proto.setDescription(this.description);
    proto.setEventTime(this.eventTime);
    proto.setIntervention(this.intervention);
    proto.setInterventionCause(this.interventionCause);
    proto.setPalletDamage(this.palletDamage);
    proto.setGoodsDamage(this.goodsDamage);
    proto.setExternalBug(this.externalBug);
    return proto;
  }
}

export class RunDescription {
  runId: string;
  robotName: string;
  gitCommitHash: string;
  gitBranch: string;
  startCommand: string;
  channel: string;
  robotEpochOffset: number;
  startTime: number;
  endTime: number;
  versionName: string;
  attributes: Map<string, string>;
  constructor(
    runId: string,
    robotName: string,
    gitCommitHash: string,
    gitBranch: string,
    startCommand: string,
    channel: string,
    robotEpochOffset: number,
    startTime: number,
    endTime: number,
    versionName: string,
    attributes: Map<string, string>
  ) {
    this.runId = runId;
    this.robotName = robotName;
    this.gitCommitHash = gitCommitHash;
    this.gitBranch = gitBranch;
    this.startCommand = startCommand;
    this.channel = channel;
    this.robotEpochOffset = robotEpochOffset;
    this.startTime = startTime;
    this.endTime = endTime;
    this.versionName = versionName;
    this.attributes = attributes;
  }

  static fromProto(proto: rd_pb.RunDescription): RunDescription {
    const attributes = new Map();
    proto
      .getAttributesMap()
      .forEach((val: string, key: string) => attributes.set(key, val));
    return new RunDescription(
      proto.getRunId(),
      proto.getRobotName(),
      proto.getGitCommitHash(),
      proto.getGitBranch(),
      proto.getStartCommand(),
      proto.getChannel(),
      proto.getRobotEpochOffset(),
      proto.getStartTime(),
      proto.getEndTime(),
      proto.getVersionName(),
      attributes
    );
  }

  toProto(): rd_pb.RunDescription {
    const proto = new rd_pb.RunDescription();
    proto.setRunId(this.runId);
    proto.setRobotName(this.robotName);
    proto.setGitCommitHash(this.gitCommitHash);
    proto.setGitBranch(this.gitBranch);
    proto.setStartCommand(this.startCommand);
    proto.setChannel(this.channel);
    proto.setRobotEpochOffset(this.robotEpochOffset);
    proto.setStartTime(this.startTime);
    proto.setEndTime(this.endTime);
    proto.setVersionName(this.versionName);
    this.attributes.forEach((value: string, key: string) => {
      proto.getAttributesMap().set(key, value);
    });
    return proto;
  }
}

export class RunReview {
  runMetadataId: string;
  reviewerAccountId: string;
  assignerAccountId: string;
  status: m_pb.RunReviewStatus;

  constructor(
    runMetadata: string,
    reviewerAccountId: string,
    assignerAccountId: string,
    status: m_pb.RunReviewStatus
  ) {
    this.runMetadataId = runMetadata;
    this.reviewerAccountId = reviewerAccountId;
    this.assignerAccountId = assignerAccountId;
    this.status = status;
  }

  static fromProto(proto: m_pb.RunReview): RunReview {
    return new RunReview(
      proto.getRunMetadataId(),
      proto.getReviewerAccountId(),
      proto.getAssignerAccountId(),
      proto.getStatus()
    );
  }
  toProto(): m_pb.RunReview {
    const proto = new m_pb.RunReview();
    proto.setRunMetadataId(this.runMetadataId);
    proto.setReviewerAccountId(this.reviewerAccountId);
    proto.setAssignerAccountId(this.assignerAccountId);
    proto.setStatus(this.status);
    return proto;
  }
}

export class RunMetadata {
  id: string;
  runId: string;
  robotName: string;
  dataLogUrls: DataLogUrls;
  startTime: number;
  endTime: number;
  annotations: Array<Annotation>;
  description: RunDescription;
  summary: string;
  flagFile: string;
  goalIds: Array<string>;
  runReviews: Array<RunReview>;
  numPendingLogFiles: number;
  numImportedLogFiles: number;

  constructor(
    id: string,
    runId: string,
    robotName: string,
    dataLogUrls: DataLogUrls,
    startTime: number,
    endTime: number,
    annotations: Array<Annotation>,
    description: RunDescription,
    summary: string,
    flagFile: string,
    goalIds?: Array<string>,
    runReviews?: Array<RunReview>,
    numPendingLogFiles?: number,
    numImportedLogFiles?: number
  ) {
    this.id = id;
    this.runId = runId;
    this.robotName = robotName;
    this.dataLogUrls = dataLogUrls;
    this.startTime = startTime;
    this.endTime = endTime;
    this.annotations = annotations;
    this.description = description;
    this.summary = summary;
    this.flagFile = flagFile;
    this.goalIds = goalIds || [];
    this.runReviews = runReviews || [];
    this.numPendingLogFiles = numPendingLogFiles || 0;
    this.numImportedLogFiles = numImportedLogFiles || 0;
  }

  static fromProto(proto: m_pb.RunMetadata): RunMetadata {
    return new RunMetadata(
      proto.getId(),
      proto.getRunId(),
      proto.getRobotName(),
      DataLogUrls.fromProto(proto.getDataLogUrls() || new m_pb.DataLogUrls()),
      proto.getStartTime(),
      proto.getEndTime(),
      proto.getAnnotationsList().map(n => Annotation.fromProto(n)),
      RunDescription.fromProto(
        proto.getDescription() || new rd_pb.RunDescription()
      ),
      proto.getSummary(),
      proto.getFlagFile(),
      proto.getGoalIdsList(),
      proto.getReviewsList().map(r => RunReview.fromProto(r)),
      proto.getNumPendingLogFiles(),
      proto.getNumImportedLogFiles()
    );
  }

  toProto(): m_pb.RunMetadata {
    const proto = new m_pb.RunMetadata();
    proto.setId(this.id);
    proto.setRunId(this.runId);
    proto.setRobotName(this.robotName);
    proto.setDataLogUrls(this.dataLogUrls.toProto());
    proto.setStartTime(this.startTime);
    proto.setEndTime(this.endTime);
    proto.setAnnotationsList(this.annotations.map(n => n.toProto()));
    proto.setDescription(this.description.toProto());
    proto.setSummary(this.summary);
    proto.setFlagFile(this.flagFile);
    proto.setGoalIdsList(this.goalIds);
    proto.setNumPendingLogFiles(this.numPendingLogFiles);
    proto.setNumImportedLogFiles(this.numImportedLogFiles);
    return proto;
  }
}

export class Account {
  id: string;
  email: string;
  isApprovedForAccess: boolean;
  isEmailVerified: boolean;
  isStaff: boolean;
  organization: string;

  constructor(
    id: string,
    email: string,
    isApprovedForAccess?: boolean,
    isEmailVerified?: boolean,
    isStaff?: boolean,
    organization?: string
  ) {
    this.id = id;
    this.email = email;
    this.isApprovedForAccess = Boolean(isApprovedForAccess);
    this.isEmailVerified = Boolean(isEmailVerified);
    this.isStaff = Boolean(isStaff);
    this.organization = String(organization);
  }

  toProto(): m_pb.Account {
    const proto = new m_pb.Account();
    proto.setEmail(this.email);
    proto.setId(this.id);
    proto.setIsApprovedForAccess(this.isApprovedForAccess);
    proto.setIsEmailVerified(this.isEmailVerified);
    proto.setIsStaff(this.isStaff);
    return proto;
  }

  static fromProto(proto: m_pb.Account): Account {
    return new Account(
      proto.getId(),
      proto.getEmail(),
      proto.getIsApprovedForAccess(),
      proto.getIsEmailVerified(),
      proto.getIsStaff()
    );
  }
}

export class RobotAccount {
  id: string;
  robotName: string;
  cohortId: string;
  ipAddress: string;
  lifecycleState: string;
  autonomyVersion: string;
  lastHeartbeatTime: number;
  siteId: string;
  status: RobotStatus | undefined;

  constructor(
    id: string,
    robotName: string,
    cohortId: string,
    ipAddress = "",
    lifecycleState = "",
    autonomyVersion = "",
    lastHeartbeatTime: number,
    siteId: string,
    status: RobotStatus | undefined,
    ) {
    this.id = id;
    this.robotName = robotName;
    this.cohortId = cohortId;
    this.ipAddress = ipAddress;
    this.lifecycleState = lifecycleState;
    this.autonomyVersion = autonomyVersion;
    this.lastHeartbeatTime = lastHeartbeatTime;
    this.siteId = siteId;
    this.status = status;
  }

  toProto(): m_pb.RobotAccount {
    const proto = new m_pb.RobotAccount();
    proto.setRobotName(this.robotName);
    proto.setCohortId(this.cohortId);
    proto.setIpAddress(this.ipAddress);
    proto.setLifecycleState(this.lifecycleState);
    proto.setAutonomyVersion(this.autonomyVersion);
    proto.setLastHeartbeatTime(this.lastHeartbeatTime);
    proto.setSiteId(this.siteId);
    proto.setStatus(this.status);
    return proto;
  }

  static fromProto(proto: m_pb.RobotAccount): RobotAccount {
    return new RobotAccount(
      proto.getRobotName(),
      proto.getRobotName(),
      proto.getCohortId(),
      proto.getIpAddress(),
      proto.getLifecycleState(),
      proto.getAutonomyVersion(),
      proto.getLastHeartbeatTime(),
      proto.getSiteId(),
      proto.getStatus(),
    );
  }
}
