import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import detection_pb from "../../_proto/detection/proto/detection_pb";
import { TableRow, TableCell, Button } from "@material-ui/core";

const styles = () => createStyles({});

interface Props extends WithStyles<typeof styles> {
  zoneConfig: detection_pb.ZoneConfig;
  edit: () => void;
  delete: () => void;
  canEdit: boolean;
}
interface State {}

const PALLET_LONG_DIMENSION = 1.219;
const PALLET_SHORT_DIMENSION = 1.016;

const getPalletWidth = (
  omniPadding: boolean,
  orientationList: detection_pb.PlaceZoneConfig.Orientation[]
): number => {
  return (omniPadding || orientationList.includes(detection_pb.PlaceZoneConfig.Orientation.TURNED)) ? PALLET_LONG_DIMENSION : PALLET_SHORT_DIMENSION;
};
const getPalletLength = (
  omniPadding: boolean,
  orientationList: detection_pb.PlaceZoneConfig.Orientation[]
): number => {
  return (omniPadding || orientationList.includes(detection_pb.PlaceZoneConfig.Orientation.STRAIGHT)) ? PALLET_LONG_DIMENSION : PALLET_SHORT_DIMENSION;
};

class PlaceZoneConfiguration extends Component<Props, State> {
  state: State = {};

  render() {
    const { placeConfig, templateName } = this.props.zoneConfig.toObject();
    if (!placeConfig) {
      return null;
    }
    const { numColumns, numRows, placePattern, rowPadding, columnPadding, orientationList } = placeConfig;

    const palletWidthOmniPaddingTrue = getPalletWidth(true, orientationList);
    const palletLengthOmniPaddingTrue = getPalletLength(true, orientationList);
    const placeZoneWidthOmniPaddingTrue = numColumns * palletWidthOmniPaddingTrue + (numColumns - 1) * columnPadding;
    const placeZoneDepthOmniPaddingTrue = numRows * palletLengthOmniPaddingTrue + (numRows - 1) * rowPadding;

    const palletWidthOmniPaddingFalse = getPalletWidth(false, orientationList);
    const palletLengthOmniPaddingFalse = getPalletLength(false, orientationList);
    const placeZoneWidthOmniPaddingFalse = numColumns * palletWidthOmniPaddingFalse + (numColumns - 1) * columnPadding;
    const placeZoneDepthOmniPaddingFalse = numRows * palletLengthOmniPaddingFalse + (numRows - 1) * rowPadding;

    return (
      <TableRow>
        <TableCell>{templateName}</TableCell>
        <TableCell align="right">{numRows}</TableCell>
        <TableCell align="right">{numColumns}</TableCell>
        <TableCell align="right">
          <div>omni_padding: true = {placeZoneWidthOmniPaddingTrue.toFixed(3)}</div>
          <div>omni_padding: false = {placeZoneWidthOmniPaddingFalse.toFixed(3)}</div>
        </TableCell>
        <TableCell align="right">
          <div>omni_padding: true = {placeZoneDepthOmniPaddingTrue.toFixed(3)}</div>
          <div>omni_padding: false = {placeZoneDepthOmniPaddingFalse.toFixed(3)}</div>
        </TableCell>
        <TableCell align="right">{// @ts-ignore
          Object.keys(detection_pb.PlaceZoneConfig.PlacePattern).find(key => detection_pb.PlaceZoneConfig.PlacePattern[key] === placePattern)}</TableCell>
        <TableCell align="right">
          <Button onClick={this.props.edit} disabled={!this.props.canEdit}>
            Edit
          </Button>
        </TableCell>
        <TableCell align="right">
          <Button onClick={this.props.delete} disabled={!this.props.canEdit}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

export default connect()(withStyles(styles)(PlaceZoneConfiguration));
