// source: robot/proto/fault.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var planner_proto_planner_status_pb = require('../../planner/proto/planner_status_pb.js');
goog.object.extend(proto, planner_proto_planner_status_pb);
var planner_proto_types_pb = require('../../planner/proto/types_pb.js');
goog.object.extend(proto, planner_proto_types_pb);
var map_proto_map_pb = require('../../map/proto/map_pb.js');
goog.object.extend(proto, map_proto_map_pb);
goog.exportSymbol('proto.fox.proto.FaultSeverity', null, global);
goog.exportSymbol('proto.fox.proto.HumanAssistanceRequest', null, global);
goog.exportSymbol('proto.fox.proto.HumanAssistanceRequest.Code', null, global);
goog.exportSymbol('proto.fox.proto.HumanAssistanceRequest.Trigger', null, global);
goog.exportSymbol('proto.fox.proto.PerceptionError', null, global);
goog.exportSymbol('proto.fox.proto.PerceptionError.Code', null, global);
goog.exportSymbol('proto.fox.proto.RobotFault', null, global);
goog.exportSymbol('proto.fox.proto.RobotFault.ErrorCase', null, global);
goog.exportSymbol('proto.fox.proto.SensorError', null, global);
goog.exportSymbol('proto.fox.proto.SensorError.Code', null, global);
goog.exportSymbol('proto.fox.proto.SensorError.Detail', null, global);
goog.exportSymbol('proto.fox.proto.SynchronizerError', null, global);
goog.exportSymbol('proto.fox.proto.SynchronizerError.Code', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SensorError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SensorError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SensorError.displayName = 'proto.fox.proto.SensorError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PerceptionError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PerceptionError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PerceptionError.displayName = 'proto.fox.proto.PerceptionError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SynchronizerError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SynchronizerError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SynchronizerError.displayName = 'proto.fox.proto.SynchronizerError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.HumanAssistanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.HumanAssistanceRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.HumanAssistanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.HumanAssistanceRequest.displayName = 'proto.fox.proto.HumanAssistanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.RobotFault = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fox.proto.RobotFault.oneofGroups_);
};
goog.inherits(proto.fox.proto.RobotFault, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.RobotFault.displayName = 'proto.fox.proto.RobotFault';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SensorError.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SensorError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SensorError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SensorError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    detail: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SensorError}
 */
proto.fox.proto.SensorError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SensorError;
  return proto.fox.proto.SensorError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SensorError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SensorError}
 */
proto.fox.proto.SensorError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.SensorError.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.SensorError.Detail} */ (reader.readEnum());
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SensorError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SensorError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SensorError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SensorError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDetail();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.SensorError.Code = {
  CODE_OK: 0,
  CODE_CARRIAGE_CAMERA: 1,
  CODE_LEFT_SCAN: 2,
  CODE_RIGHT_SCAN: 3,
  CODE_STEERWARD_SCAN: 4,
  CODE_WHEEL_ODOMETRY: 5
};

/**
 * @enum {number}
 */
proto.fox.proto.SensorError.Detail = {
  DETAIL_OK: 0,
  DETAIL_UNSPECIFIED_ERROR: 1,
  DETAIL_DISCONNECTED: 2,
  DETAIL_STALE: 3
};

/**
 * optional Code code = 1;
 * @return {!proto.fox.proto.SensorError.Code}
 */
proto.fox.proto.SensorError.prototype.getCode = function() {
  return /** @type {!proto.fox.proto.SensorError.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.SensorError.Code} value
 * @return {!proto.fox.proto.SensorError} returns this
 */
proto.fox.proto.SensorError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Detail detail = 2;
 * @return {!proto.fox.proto.SensorError.Detail}
 */
proto.fox.proto.SensorError.prototype.getDetail = function() {
  return /** @type {!proto.fox.proto.SensorError.Detail} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.SensorError.Detail} value
 * @return {!proto.fox.proto.SensorError} returns this
 */
proto.fox.proto.SensorError.prototype.setDetail = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PerceptionError.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PerceptionError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PerceptionError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PerceptionError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PerceptionError}
 */
proto.fox.proto.PerceptionError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PerceptionError;
  return proto.fox.proto.PerceptionError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PerceptionError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PerceptionError}
 */
proto.fox.proto.PerceptionError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.PerceptionError.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PerceptionError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PerceptionError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PerceptionError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PerceptionError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.PerceptionError.Code = {
  CODE_OK: 0,
  CODE_LIDAR_UNLEVEL_LEFT: 1,
  CODE_LIDAR_UNLEVEL_RIGHT: 2,
  CODE_LIDAR_UNLEVEL_FRONT: 3,
  CODE_ROBOT_BODY_RETURNS_LEFT: 4,
  CODE_ROBOT_BODY_RETURNS_RIGHT: 5,
  CODE_ROBOT_BODY_RETURNS_FRONT: 6,
  CODE_LIDAR_GUARD_RETURNS_LEFT: 7,
  CODE_LIDAR_GUARD_RETURNS_RIGHT: 8,
  CODE_LIDAR_GUARD_RETURNS_FRONT: 9,
  CODE_LIDAR_LENS_RETURNS_LEFT: 10,
  CODE_LIDAR_LENS_RETURNS_RIGHT: 11,
  CODE_LIDAR_LENS_RETURNS_FRONT: 12
};

/**
 * optional Code code = 1;
 * @return {!proto.fox.proto.PerceptionError.Code}
 */
proto.fox.proto.PerceptionError.prototype.getCode = function() {
  return /** @type {!proto.fox.proto.PerceptionError.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.PerceptionError.Code} value
 * @return {!proto.fox.proto.PerceptionError} returns this
 */
proto.fox.proto.PerceptionError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SynchronizerError.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SynchronizerError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SynchronizerError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SynchronizerError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SynchronizerError}
 */
proto.fox.proto.SynchronizerError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SynchronizerError;
  return proto.fox.proto.SynchronizerError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SynchronizerError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SynchronizerError}
 */
proto.fox.proto.SynchronizerError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.SynchronizerError.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SynchronizerError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SynchronizerError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SynchronizerError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SynchronizerError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.SynchronizerError.Code = {
  CODE_OK: 0,
  CODE_STALE_IMAGE: 1,
  CODE_STALE_LEFT_SCAN: 2,
  CODE_STALE_RIGHT_SCAN: 3,
  CODE_STALE_STEERWARD_SCAN: 4,
  CODE_STALE_WHEEL_ODOMETRY: 5
};

/**
 * optional Code code = 1;
 * @return {!proto.fox.proto.SynchronizerError.Code}
 */
proto.fox.proto.SynchronizerError.prototype.getCode = function() {
  return /** @type {!proto.fox.proto.SynchronizerError.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.SynchronizerError.Code} value
 * @return {!proto.fox.proto.SynchronizerError} returns this
 */
proto.fox.proto.SynchronizerError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.HumanAssistanceRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.HumanAssistanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.HumanAssistanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.HumanAssistanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HumanAssistanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    faultIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    trigger: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.HumanAssistanceRequest}
 */
proto.fox.proto.HumanAssistanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.HumanAssistanceRequest;
  return proto.fox.proto.HumanAssistanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.HumanAssistanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.HumanAssistanceRequest}
 */
proto.fox.proto.HumanAssistanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.HumanAssistanceRequest.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFaultIds(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.HumanAssistanceRequest.Trigger} */ (reader.readEnum());
      msg.setTrigger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.HumanAssistanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.HumanAssistanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.HumanAssistanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HumanAssistanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFaultIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getTrigger();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.HumanAssistanceRequest.Code = {
  CODE_OK: 0,
  CODE_MANUAL: 1
};

/**
 * @enum {number}
 */
proto.fox.proto.HumanAssistanceRequest.Trigger = {
  TRIGGER_NONE: 0,
  TRIGGER_FAULT_REQUESTED: 1,
  TRIGGER_ESTOP: 2,
  TRIGGER_FAULTED: 3,
  TRIGGER_MANUAL: 4,
  TRIGGER_RESET: 5
};

/**
 * optional Code code = 1;
 * @return {!proto.fox.proto.HumanAssistanceRequest.Code}
 */
proto.fox.proto.HumanAssistanceRequest.prototype.getCode = function() {
  return /** @type {!proto.fox.proto.HumanAssistanceRequest.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.HumanAssistanceRequest.Code} value
 * @return {!proto.fox.proto.HumanAssistanceRequest} returns this
 */
proto.fox.proto.HumanAssistanceRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string fault_ids = 2;
 * @return {!Array<string>}
 */
proto.fox.proto.HumanAssistanceRequest.prototype.getFaultIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.HumanAssistanceRequest} returns this
 */
proto.fox.proto.HumanAssistanceRequest.prototype.setFaultIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.HumanAssistanceRequest} returns this
 */
proto.fox.proto.HumanAssistanceRequest.prototype.addFaultIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.HumanAssistanceRequest} returns this
 */
proto.fox.proto.HumanAssistanceRequest.prototype.clearFaultIdsList = function() {
  return this.setFaultIdsList([]);
};


/**
 * optional Trigger trigger = 3;
 * @return {!proto.fox.proto.HumanAssistanceRequest.Trigger}
 */
proto.fox.proto.HumanAssistanceRequest.prototype.getTrigger = function() {
  return /** @type {!proto.fox.proto.HumanAssistanceRequest.Trigger} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.HumanAssistanceRequest.Trigger} value
 * @return {!proto.fox.proto.HumanAssistanceRequest} returns this
 */
proto.fox.proto.HumanAssistanceRequest.prototype.setTrigger = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fox.proto.RobotFault.oneofGroups_ = [[100,101,102,103,104,105,106,107,108,200]];

/**
 * @enum {number}
 */
proto.fox.proto.RobotFault.ErrorCase = {
  ERROR_NOT_SET: 0,
  PLANNING_FAULT: 100,
  VALIDATION_FAULT: 101,
  LOCALIZATION_FAULT: 102,
  CONTROLS_FAULT: 103,
  HARDWARE_FAULT: 104,
  SYNCHRONIZER_FAULT: 105,
  PERCEPTION_FAULT: 106,
  SENSOR_FAULT: 107,
  DEVICE_FAULT: 108,
  HUMAN_ASSISTANCE_REQUEST: 200
};

/**
 * @return {proto.fox.proto.RobotFault.ErrorCase}
 */
proto.fox.proto.RobotFault.prototype.getErrorCase = function() {
  return /** @type {proto.fox.proto.RobotFault.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.fox.proto.RobotFault.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.RobotFault.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.RobotFault.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.RobotFault} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RobotFault.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    severity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    planningFault: (f = msg.getPlanningFault()) && planner_proto_planner_status_pb.ActionError.toObject(includeInstance, f),
    validationFault: (f = msg.getValidationFault()) && planner_proto_types_pb.ValidationError.toObject(includeInstance, f),
    localizationFault: (f = msg.getLocalizationFault()) && map_proto_map_pb.LocalizationError.toObject(includeInstance, f),
    controlsFault: (f = msg.getControlsFault()) && planner_proto_types_pb.ControlsError.toObject(includeInstance, f),
    hardwareFault: (f = msg.getHardwareFault()) && planner_proto_types_pb.HardwareError.toObject(includeInstance, f),
    synchronizerFault: (f = msg.getSynchronizerFault()) && proto.fox.proto.SynchronizerError.toObject(includeInstance, f),
    perceptionFault: (f = msg.getPerceptionFault()) && proto.fox.proto.PerceptionError.toObject(includeInstance, f),
    sensorFault: (f = msg.getSensorFault()) && proto.fox.proto.SensorError.toObject(includeInstance, f),
    deviceFault: (f = msg.getDeviceFault()) && planner_proto_types_pb.DeviceError.toObject(includeInstance, f),
    humanAssistanceRequest: (f = msg.getHumanAssistanceRequest()) && proto.fox.proto.HumanAssistanceRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.RobotFault}
 */
proto.fox.proto.RobotFault.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.RobotFault;
  return proto.fox.proto.RobotFault.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.RobotFault} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.RobotFault}
 */
proto.fox.proto.RobotFault.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.FaultSeverity} */ (reader.readEnum());
      msg.setSeverity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 100:
      var value = new planner_proto_planner_status_pb.ActionError;
      reader.readMessage(value,planner_proto_planner_status_pb.ActionError.deserializeBinaryFromReader);
      msg.setPlanningFault(value);
      break;
    case 101:
      var value = new planner_proto_types_pb.ValidationError;
      reader.readMessage(value,planner_proto_types_pb.ValidationError.deserializeBinaryFromReader);
      msg.setValidationFault(value);
      break;
    case 102:
      var value = new map_proto_map_pb.LocalizationError;
      reader.readMessage(value,map_proto_map_pb.LocalizationError.deserializeBinaryFromReader);
      msg.setLocalizationFault(value);
      break;
    case 103:
      var value = new planner_proto_types_pb.ControlsError;
      reader.readMessage(value,planner_proto_types_pb.ControlsError.deserializeBinaryFromReader);
      msg.setControlsFault(value);
      break;
    case 104:
      var value = new planner_proto_types_pb.HardwareError;
      reader.readMessage(value,planner_proto_types_pb.HardwareError.deserializeBinaryFromReader);
      msg.setHardwareFault(value);
      break;
    case 105:
      var value = new proto.fox.proto.SynchronizerError;
      reader.readMessage(value,proto.fox.proto.SynchronizerError.deserializeBinaryFromReader);
      msg.setSynchronizerFault(value);
      break;
    case 106:
      var value = new proto.fox.proto.PerceptionError;
      reader.readMessage(value,proto.fox.proto.PerceptionError.deserializeBinaryFromReader);
      msg.setPerceptionFault(value);
      break;
    case 107:
      var value = new proto.fox.proto.SensorError;
      reader.readMessage(value,proto.fox.proto.SensorError.deserializeBinaryFromReader);
      msg.setSensorFault(value);
      break;
    case 108:
      var value = new planner_proto_types_pb.DeviceError;
      reader.readMessage(value,planner_proto_types_pb.DeviceError.deserializeBinaryFromReader);
      msg.setDeviceFault(value);
      break;
    case 200:
      var value = new proto.fox.proto.HumanAssistanceRequest;
      reader.readMessage(value,proto.fox.proto.HumanAssistanceRequest.deserializeBinaryFromReader);
      msg.setHumanAssistanceRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.RobotFault.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.RobotFault.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.RobotFault} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RobotFault.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeverity();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPlanningFault();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      planner_proto_planner_status_pb.ActionError.serializeBinaryToWriter
    );
  }
  f = message.getValidationFault();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      planner_proto_types_pb.ValidationError.serializeBinaryToWriter
    );
  }
  f = message.getLocalizationFault();
  if (f != null) {
    writer.writeMessage(
      102,
      f,
      map_proto_map_pb.LocalizationError.serializeBinaryToWriter
    );
  }
  f = message.getControlsFault();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      planner_proto_types_pb.ControlsError.serializeBinaryToWriter
    );
  }
  f = message.getHardwareFault();
  if (f != null) {
    writer.writeMessage(
      104,
      f,
      planner_proto_types_pb.HardwareError.serializeBinaryToWriter
    );
  }
  f = message.getSynchronizerFault();
  if (f != null) {
    writer.writeMessage(
      105,
      f,
      proto.fox.proto.SynchronizerError.serializeBinaryToWriter
    );
  }
  f = message.getPerceptionFault();
  if (f != null) {
    writer.writeMessage(
      106,
      f,
      proto.fox.proto.PerceptionError.serializeBinaryToWriter
    );
  }
  f = message.getSensorFault();
  if (f != null) {
    writer.writeMessage(
      107,
      f,
      proto.fox.proto.SensorError.serializeBinaryToWriter
    );
  }
  f = message.getDeviceFault();
  if (f != null) {
    writer.writeMessage(
      108,
      f,
      planner_proto_types_pb.DeviceError.serializeBinaryToWriter
    );
  }
  f = message.getHumanAssistanceRequest();
  if (f != null) {
    writer.writeMessage(
      200,
      f,
      proto.fox.proto.HumanAssistanceRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.RobotFault.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FaultSeverity severity = 2;
 * @return {!proto.fox.proto.FaultSeverity}
 */
proto.fox.proto.RobotFault.prototype.getSeverity = function() {
  return /** @type {!proto.fox.proto.FaultSeverity} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.FaultSeverity} value
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.setSeverity = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 start_time = 3;
 * @return {number}
 */
proto.fox.proto.RobotFault.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 end_time = 4;
 * @return {number}
 */
proto.fox.proto.RobotFault.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional ActionError planning_fault = 100;
 * @return {?proto.fox.proto.ActionError}
 */
proto.fox.proto.RobotFault.prototype.getPlanningFault = function() {
  return /** @type{?proto.fox.proto.ActionError} */ (
    jspb.Message.getWrapperField(this, planner_proto_planner_status_pb.ActionError, 100));
};


/**
 * @param {?proto.fox.proto.ActionError|undefined} value
 * @return {!proto.fox.proto.RobotFault} returns this
*/
proto.fox.proto.RobotFault.prototype.setPlanningFault = function(value) {
  return jspb.Message.setOneofWrapperField(this, 100, proto.fox.proto.RobotFault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.clearPlanningFault = function() {
  return this.setPlanningFault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotFault.prototype.hasPlanningFault = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional ValidationError validation_fault = 101;
 * @return {?proto.fox.proto.ValidationError}
 */
proto.fox.proto.RobotFault.prototype.getValidationFault = function() {
  return /** @type{?proto.fox.proto.ValidationError} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.ValidationError, 101));
};


/**
 * @param {?proto.fox.proto.ValidationError|undefined} value
 * @return {!proto.fox.proto.RobotFault} returns this
*/
proto.fox.proto.RobotFault.prototype.setValidationFault = function(value) {
  return jspb.Message.setOneofWrapperField(this, 101, proto.fox.proto.RobotFault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.clearValidationFault = function() {
  return this.setValidationFault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotFault.prototype.hasValidationFault = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional LocalizationError localization_fault = 102;
 * @return {?proto.fox.proto.LocalizationError}
 */
proto.fox.proto.RobotFault.prototype.getLocalizationFault = function() {
  return /** @type{?proto.fox.proto.LocalizationError} */ (
    jspb.Message.getWrapperField(this, map_proto_map_pb.LocalizationError, 102));
};


/**
 * @param {?proto.fox.proto.LocalizationError|undefined} value
 * @return {!proto.fox.proto.RobotFault} returns this
*/
proto.fox.proto.RobotFault.prototype.setLocalizationFault = function(value) {
  return jspb.Message.setOneofWrapperField(this, 102, proto.fox.proto.RobotFault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.clearLocalizationFault = function() {
  return this.setLocalizationFault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotFault.prototype.hasLocalizationFault = function() {
  return jspb.Message.getField(this, 102) != null;
};


/**
 * optional ControlsError controls_fault = 103;
 * @return {?proto.fox.proto.ControlsError}
 */
proto.fox.proto.RobotFault.prototype.getControlsFault = function() {
  return /** @type{?proto.fox.proto.ControlsError} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.ControlsError, 103));
};


/**
 * @param {?proto.fox.proto.ControlsError|undefined} value
 * @return {!proto.fox.proto.RobotFault} returns this
*/
proto.fox.proto.RobotFault.prototype.setControlsFault = function(value) {
  return jspb.Message.setOneofWrapperField(this, 103, proto.fox.proto.RobotFault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.clearControlsFault = function() {
  return this.setControlsFault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotFault.prototype.hasControlsFault = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional HardwareError hardware_fault = 104;
 * @return {?proto.fox.proto.HardwareError}
 */
proto.fox.proto.RobotFault.prototype.getHardwareFault = function() {
  return /** @type{?proto.fox.proto.HardwareError} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.HardwareError, 104));
};


/**
 * @param {?proto.fox.proto.HardwareError|undefined} value
 * @return {!proto.fox.proto.RobotFault} returns this
*/
proto.fox.proto.RobotFault.prototype.setHardwareFault = function(value) {
  return jspb.Message.setOneofWrapperField(this, 104, proto.fox.proto.RobotFault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.clearHardwareFault = function() {
  return this.setHardwareFault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotFault.prototype.hasHardwareFault = function() {
  return jspb.Message.getField(this, 104) != null;
};


/**
 * optional SynchronizerError synchronizer_fault = 105;
 * @return {?proto.fox.proto.SynchronizerError}
 */
proto.fox.proto.RobotFault.prototype.getSynchronizerFault = function() {
  return /** @type{?proto.fox.proto.SynchronizerError} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.SynchronizerError, 105));
};


/**
 * @param {?proto.fox.proto.SynchronizerError|undefined} value
 * @return {!proto.fox.proto.RobotFault} returns this
*/
proto.fox.proto.RobotFault.prototype.setSynchronizerFault = function(value) {
  return jspb.Message.setOneofWrapperField(this, 105, proto.fox.proto.RobotFault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.clearSynchronizerFault = function() {
  return this.setSynchronizerFault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotFault.prototype.hasSynchronizerFault = function() {
  return jspb.Message.getField(this, 105) != null;
};


/**
 * optional PerceptionError perception_fault = 106;
 * @return {?proto.fox.proto.PerceptionError}
 */
proto.fox.proto.RobotFault.prototype.getPerceptionFault = function() {
  return /** @type{?proto.fox.proto.PerceptionError} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PerceptionError, 106));
};


/**
 * @param {?proto.fox.proto.PerceptionError|undefined} value
 * @return {!proto.fox.proto.RobotFault} returns this
*/
proto.fox.proto.RobotFault.prototype.setPerceptionFault = function(value) {
  return jspb.Message.setOneofWrapperField(this, 106, proto.fox.proto.RobotFault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.clearPerceptionFault = function() {
  return this.setPerceptionFault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotFault.prototype.hasPerceptionFault = function() {
  return jspb.Message.getField(this, 106) != null;
};


/**
 * optional SensorError sensor_fault = 107;
 * @return {?proto.fox.proto.SensorError}
 */
proto.fox.proto.RobotFault.prototype.getSensorFault = function() {
  return /** @type{?proto.fox.proto.SensorError} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.SensorError, 107));
};


/**
 * @param {?proto.fox.proto.SensorError|undefined} value
 * @return {!proto.fox.proto.RobotFault} returns this
*/
proto.fox.proto.RobotFault.prototype.setSensorFault = function(value) {
  return jspb.Message.setOneofWrapperField(this, 107, proto.fox.proto.RobotFault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.clearSensorFault = function() {
  return this.setSensorFault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotFault.prototype.hasSensorFault = function() {
  return jspb.Message.getField(this, 107) != null;
};


/**
 * optional DeviceError device_fault = 108;
 * @return {?proto.fox.proto.DeviceError}
 */
proto.fox.proto.RobotFault.prototype.getDeviceFault = function() {
  return /** @type{?proto.fox.proto.DeviceError} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.DeviceError, 108));
};


/**
 * @param {?proto.fox.proto.DeviceError|undefined} value
 * @return {!proto.fox.proto.RobotFault} returns this
*/
proto.fox.proto.RobotFault.prototype.setDeviceFault = function(value) {
  return jspb.Message.setOneofWrapperField(this, 108, proto.fox.proto.RobotFault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.clearDeviceFault = function() {
  return this.setDeviceFault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotFault.prototype.hasDeviceFault = function() {
  return jspb.Message.getField(this, 108) != null;
};


/**
 * optional HumanAssistanceRequest human_assistance_request = 200;
 * @return {?proto.fox.proto.HumanAssistanceRequest}
 */
proto.fox.proto.RobotFault.prototype.getHumanAssistanceRequest = function() {
  return /** @type{?proto.fox.proto.HumanAssistanceRequest} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.HumanAssistanceRequest, 200));
};


/**
 * @param {?proto.fox.proto.HumanAssistanceRequest|undefined} value
 * @return {!proto.fox.proto.RobotFault} returns this
*/
proto.fox.proto.RobotFault.prototype.setHumanAssistanceRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 200, proto.fox.proto.RobotFault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RobotFault} returns this
 */
proto.fox.proto.RobotFault.prototype.clearHumanAssistanceRequest = function() {
  return this.setHumanAssistanceRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RobotFault.prototype.hasHumanAssistanceRequest = function() {
  return jspb.Message.getField(this, 200) != null;
};


/**
 * @enum {number}
 */
proto.fox.proto.FaultSeverity = {
  FAULT_SEVERITY_CLEAR: 0,
  FAULT_SEVERITY_INFO: 1,
  FAULT_SEVERITY_WARNING: 2,
  FAULT_SEVERITY_ERROR: 3,
  FAULT_SEVERITY_FATAL: 4
};

goog.object.extend(exports, proto.fox.proto);
