import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router";

import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import ProgressSpinner from "../Utils/ProgressSpinner";

import { ApplicationState } from "../../redux";
import {getApexStatsRequest, listTrailerStatsRequest} from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { grpc } from "@improbable-eng/grpc-web";
import { logInPath } from "../../utils/Paths";
import TrailerStatsSheet from "../Sheets/TrailerStatsSheet";
import EventFilterSearchBar from "../Utils/EventFilterSearchBar";
import LabeledCircularProgress from "../Utils/LabeledCircularProgress";
import Histogram from "../ChartTimeSeries/Histogram";
import {
  interventionDistributionFromTrailerStats,
  interventionFreeSpeedDistributionFromTrailerStats, interventionRateDistributionFromTrailerStats,
  speedDistributionFromTrailerStats
} from "../ChartScalars/ScalarUtils";
import {bucketTrailerStatsByMonth} from "../ChartTimeSeries/TimeSeriesUtils";
import Ridgeline from "../ChartTimeSeries/Ridgeline";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      height: "100%",
      padding: 0,
      paddingBottom: 16,
      display: "flex",
      flexDirection: "column"
    },
    chartPanel: {
      width: "120em",
      height: "30em"
    },
    chartContainer: {
      display: "inline-block",
      width: "40em",
      height: "30em"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  isLoading: boolean;
  redirectTo: string | null;
  stats: Map<string, m_pb.TrailerStats.AsObject>;
  loadingProgress: number;
  apexTrailerStatsUrl: string;
}

class ListTrailerStats extends Component<Props, State> {
  state: State = {
    isLoading: false,
    redirectTo: null,
    stats: new Map(),
    loadingProgress: 0,
    apexTrailerStatsUrl: ""
  };

  _fetch(filter: m_pb.EventFilter, nextPageToken = "", newFetch = true) {
    this.setState({ isLoading: true, loadingProgress: newFetch ? 0 : this.state.loadingProgress }, () => {
      this.props
        .dispatch(listTrailerStatsRequest(filter, nextPageToken))
        .then((res: m_pb.ListTrailerStatsResponse.AsObject) => {
          const stats = newFetch ? new Map() : this.state.stats;
          for (const trailerStat of res.trailerStatsList) {
            stats.set(trailerStat.objectiveId, trailerStat);
          }
          // const stats = newFetch ? res.trailerStatsList : this.state.stats.concat(res.trailerStatsList);
          // stats.sort((s1, s2) =>
          //     s1.startTime > s2.startTime ? -1 : 1
          // )
          if (res.nextPageToken) {
            this.setState({
              stats,
              isLoading: true,
              loadingProgress: 100 * stats.size / res.count
            });
            this._fetch(filter, res.nextPageToken, false);
          } else {
            this.setState({
              stats,
              isLoading: false,
            });
          }
        })
        .catch((e: ServiceError) => {
          switch (e.code) {
            case grpc.Code.Unauthenticated: {
              this.setState({
                redirectTo: logInPath(window.location.pathname)
              });
              break;
            }
            default:
              this.setState({isLoading: false});
              break;
          }
        });
    });
    this.props.dispatch(getApexStatsRequest()).then((res: m_pb.ApexStatsUrls.AsObject) => {
      this.setState({apexTrailerStatsUrl: res.trailerStatsUrl});
    });
  }

  render() {
    const { classes } = this.props;
    const { isLoading, stats, redirectTo, loadingProgress, apexTrailerStatsUrl } = this.state;
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    const progressSpinner = isLoading ? <LabeledCircularProgress loadingProgress={loadingProgress} /> : null;
    const sheet = stats.size ? <TrailerStatsSheet
        stats={Array.from(stats.values())}
        loading={isLoading}
        omittedFieldNames={["allPicksDuration", "successfulPicksDuration", "autonomousPicksDuration", "totalInterventions", "autonomouslyAttemptedStacks", "placedStackSuccessCountNew", "harStoppageDuration"]}
    /> : null;
    const showApexStatsLink = !!apexTrailerStatsUrl;
    return (
      <div className={classes.wrapper}>
        <EventFilterSearchBar
          disable={isLoading}
          title={"Trailer Unload Statistics"}
          onRequestSubmit={f => this._fetch(f)}
        />
        {showApexStatsLink ? <div><a href={apexTrailerStatsUrl}>Download Apex trailer stats for last two weeks</a></div> : null}
        <div className={classes.chartPanel}>
          <div className={classes.chartContainer}>
            <Histogram
              data={speedDistributionFromTrailerStats(Array.from(stats.values()))}
              dataKey="frequency"
              xAxisDataKey="palletsPerHour"
              label="Pulls per Hour"
            />
          </div>
          <div className={classes.chartContainer}>
            <Histogram
              data={interventionRateDistributionFromTrailerStats(Array.from(stats.values()))}
              dataKey="frequency"
              xAxisDataKey="interventions"
              label="Interventions per 25 Pallets"
            />
          </div>
          <div className={classes.chartContainer}>
            <Histogram
              data={interventionDistributionFromTrailerStats(Array.from(stats.values()))}
              dataKey="frequency"
              xAxisDataKey="interventions"
              label="Interventions"
            />
          </div>
        </div>
        {progressSpinner}
        {sheet}
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(ListTrailerStats));
