import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";

import { ApplicationState, orderedEntities } from "../../redux";
import RobotCohortSelector, { ConfigEntity } from "./RobotCohortSelector";
import m_pb, {
  ForkliftCohort
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { grpc } from "@improbable-eng/grpc-web";

import {
  listForkliftCohortsRequest,
  listRobotAccountsRequest
} from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import ConfigurationPage from "./ConfigurationPage";
import { RouteComponentProps, withRouter } from "react-router-dom";
import queryString from "query-string";
import { Location, History } from "history";
import {
  cloneDeep,
  isArray,
  isEmpty,
  isNil,
  isString,
  isUndefined
} from "lodash";
import * as payloads from "../../redux/payloads";
import { logInPath } from "../../utils/Paths";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const styles = () => createStyles({});

const mapStateToProps = (state: ApplicationState) => {
  const robotAccounts: Array<payloads.RobotAccount> = orderedEntities(
    state.entities.robotAccounts
  );

  return { robotAccounts };
};

interface BrowserStateProps {
  scope: string;
  entity: string;
  entityRevisionHistory: string;
  compareEntity: string;
  comparisonName: string;
  comparisonRevisionHistory: string;
}

const emptyBrowserStateProps: BrowserStateProps = {
  scope: "",
  entity: "",
  entityRevisionHistory: "",
  compareEntity: "",
  comparisonName: "",
  comparisonRevisionHistory: ""
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  scope: string;
  displayedEntity: ConfigEntity;
  entityRevisionHistory: string;
  comparisonHistory: {
    compareEntity: string;
    comparisonName: string;
    comparisonRevisionHistory: string;
  };
  errorMessage: string;
  cohorts: ForkliftCohort.AsObject[];
  robotAccounts: Array<payloads.RobotAccount>;
  options: Array<any>;
  redirectTo: string | null;
}

class ManageConfigs extends Component<Props & RouteComponentProps, State> {
  state: State = {
    displayedEntity: {
      displayName: ""
    },
    entityRevisionHistory: "",
    comparisonHistory: {
      compareEntity: "",
      comparisonName: "",
      comparisonRevisionHistory: ""
    },
    errorMessage: "",
    cohorts: [],
    robotAccounts: [],
    options: [],
    scope: "",
    redirectTo: null
  };

  componentDidMount() {
    const {
      state: {},
      props: { location },
      _setErrorMessage
    } = this;

    const searchValues = queryString.parse(location.search);
    const scope = (searchValues.scope as string) || "robot";
    // get data
    this.props
      .dispatch(listRobotAccountsRequest(0, 1000))
      .then((payload: any) => {
        const options =
          scope === "robot" || scope === ""
            ? payload.accounts
            : this.state.options;
        this.setState({
          robotAccounts: payload.accounts,
          options: options
        });
      })
      .catch((e: ServiceError) => {
        switch (e.code) {
          case grpc.Code.Unauthenticated: {
            this.setState({
              redirectTo: logInPath(window.location.pathname)
            });
            break;
          }
          // TODO(malcolm): Add pages for permission denied, 500 error
        }
      });
    this.props
      .dispatch(listForkliftCohortsRequest({ pageToken: 0, pageSize: 500 }))
      .then((payload: { response: m_pb.ListForkliftCohortsResponse }) => {
        const cohorts = payload.response.toObject().cohortsList;
        const options = scope === "cohort" ? cohorts : this.state.options;
        this.setState({ cohorts: cohorts, options: options });

        this._updateBrowserState(searchValues, false);
      })
      .catch((e: ServiceError) => {
        // TODO(malcolm): Add pages for permission denied, 500 error
        _setErrorMessage(e);
      });
  }

  updatePath = (item: any, value: any, toRemove?: boolean) => {
    const browserUrlValues = queryString.parse(location.search);
    this.setState({ errorMessage: "" });
    if (isNil(value)) {
      this._updateBrowserState({ [item]: value }, true);
    } else if (isArray(item) && item[0] === "compareEntity") {
      this._updateBrowserState({ compareEntity: value[0] }, toRemove || false);
    } else {
      const entry = value.displayName ? value.displayName : value;
      this._updateBrowserState({ [item]: entry }, toRemove || false);
    }
  };

  // State and URL management
  _updateBrowserState = (urlSearch: any, toRemove: boolean) => {
    // todo: show error if not match
    const {
      state: { displayedEntity, options, scope },
      props: { history }
    } = this;
    const browserUrlValues = queryString.parse(location.search);
    // get values from url if exits otherwise from state or default
    const newScope = (urlSearch.scope as string) || scope || "robot";
    const newEntity =
      (urlSearch.entity as string) || displayedEntity.displayName || "";
    const enteredValues = toRemove
      ? this._objMinusObj(browserUrlValues, urlSearch)
      : { ...browserUrlValues, ...urlSearch };
    const searchValuesWhole = this._pullUrlValues(
      enteredValues,
      scope,
      displayedEntity.displayName
    );
    const searchValues = this._validateSearchEntries(searchValuesWhole);

    const cleanedUrlQuery = this._fixQueryString(searchValues);

    const identifier =
      newScope === "robot"
        ? `robotName`
        : newScope === "cohort"
        ? `displayName`
        : "";
    const correctItem = this._getOption(options, newEntity, identifier);
    const newDisplayEntity =
      correctItem && correctItem.displayName === cleanedUrlQuery.entity
        ? { ...correctItem, displayName: correctItem[identifier] }
        : { ...correctItem, displayName: cleanedUrlQuery.entity };

    const newOptions =
      newScope === "robot"
        ? this.state.robotAccounts
        : newScope === "cohort"
        ? this.state.cohorts
        : [];

    // fix url
    if (!this._isEqualQuery(urlSearch, cleanedUrlQuery)) {
      history.push({
        search: queryString.stringify({
          ...cleanedUrlQuery
        })
      });
    }
    // fix state
    this.setState({
      scope: cleanedUrlQuery.scope,
      displayedEntity: newDisplayEntity,
      entityRevisionHistory: cleanedUrlQuery.entityRevisionHistory,
      comparisonHistory: {
        compareEntity: cleanedUrlQuery.compareEntity,
        comparisonName: cleanedUrlQuery.comparisonName,
        comparisonRevisionHistory: cleanedUrlQuery.comparisonRevisionHistory
      },
      options: newOptions
    });
  };

  // get state values from url
  _pullUrlValues = (
    urlSearch: any,
    scope: string,
    displayName: string
  ): BrowserStateProps => {
    // get values from url if exits otherwise from state or default
    const newScope = (urlSearch.scope as string) || scope || "robot";
    const newEntity = (urlSearch.entity as string) || displayName || "";
    const newEntityRevisionHistory =
      (urlSearch.entityRevisionHistory as string) || "";
    const newCompareEntity = (urlSearch.compareEntity as string) || "";
    const newComparisonName = (urlSearch.comparisonName as string) || "";
    const newComparisonRevisionHistory =
      (urlSearch.comparisonRevisionHistory as string) || "";

    return {
      scope: newScope,
      entity: newEntity,
      entityRevisionHistory: newEntityRevisionHistory,
      compareEntity: newCompareEntity,
      comparisonName: newComparisonName,
      comparisonRevisionHistory: newComparisonRevisionHistory
    };
  };

  _validateSearchEntries = (searchEntries: BrowserStateProps) => {
    const newSearchValues = cloneDeep(searchEntries);
    if (!isEmpty(searchEntries.entity)) {
      // ensure entity is within scope
      const identifier =
        searchEntries.scope === "robot"
          ? `robotName`
          : searchEntries.scope === "cohort"
          ? `displayName`
          : "";
      const match = this._getOption(
        this.state.options,
        searchEntries.entity,
        identifier
      );
      if (isUndefined(match)) {
        newSearchValues["entity"] = "";
      }
    }
    return newSearchValues;
  };

  _objMinusObj = (parent: object, child: object) => {
    const solution = cloneDeep(emptyBrowserStateProps);
    const childKeys = Object.keys(child);
    const parentKeys = Object.keys(parent);
    const parentValues = Object.values(parent);

    solution["scope"] = childKeys.includes("scope")
      ? ""
      : parentValues[parentKeys.indexOf("scope")];
    solution["entity"] = childKeys.includes("entity")
      ? ""
      : parentValues[parentKeys.indexOf("entity")];
    solution["entityRevisionHistory"] = childKeys.includes(
      "entityRevisionHistory"
    )
      ? ""
      : parentValues[parentKeys.indexOf("entityRevisionHistory")];
    solution["compareEntity"] = childKeys.includes("compareEntity")
      ? ""
      : parentValues[parentKeys.indexOf("compareEntity")];
    solution["comparisonName"] = childKeys.includes("comparisonName")
      ? ""
      : parentValues[parentKeys.indexOf("comparisonName")];
    solution["comparisonRevisionHistory"] = childKeys.includes(
      "comparisonRevisionHistory"
    )
      ? ""
      : parentValues[parentKeys.indexOf("comparisonRevisionHistory")];
    return solution;
  };

  _fixQueryString = (searchValues: BrowserStateProps): BrowserStateProps => {
    const { scope, entity, compareEntity, comparisonName } = searchValues;
    const newSearchValues = cloneDeep(searchValues);

    // check for improper empty values
    if (isEmpty(scope)) {
      //  reset all
      newSearchValues["scope"] = "robot";
      newSearchValues["entity"] = "";
      newSearchValues["entityRevisionHistory"] = "";
      newSearchValues["compareEntity"] = "";
      newSearchValues["comparisonName"] = "";
      newSearchValues["comparisonRevisionHistory"] = "";
    } else if (isEmpty(entity)) {
      // reset rest
      newSearchValues["entity"] = "";
      newSearchValues["entityRevisionHistory"] = "";

      newSearchValues["compareEntity"] = "";
      newSearchValues["comparisonName"] = "";
      newSearchValues["comparisonRevisionHistory"] = "";
    } else if (isEmpty(compareEntity)) {
      // reset rest
      newSearchValues["compareEntity"] = "";
      newSearchValues["comparisonName"] = "";
      newSearchValues["comparisonRevisionHistory"] = "";
    } else if (isEmpty(comparisonName)) {
      // reset rest
      newSearchValues["comparisonName"] = "";
      newSearchValues["comparisonRevisionHistory"] = "";
    }

    // return completely filled, correct BrowserStateProps
    return newSearchValues;
  };

  _isEqualQuery = (query: BrowserStateProps, object: BrowserStateProps) => {
    return (
      query.scope === object.scope &&
      query.entity === object.entity &&
      query.entityRevisionHistory === object.entityRevisionHistory &&
      query.compareEntity === object.compareEntity &&
      query.comparisonName === object.comparisonName &&
      query.comparisonRevisionHistory === object.comparisonRevisionHistory
    );
  };
  // find item in list with correct identifier
  _getOption = (options: any[], item: any, identifier: string) => {
    return options.find(option => option[identifier] === item);
  };

  _setErrorMessage = (e: any) => {
    const errorMessage = this.state.errorMessage;
    const error = e.message || e || "Unknown Error";
    if (!errorMessage.includes(error)) {
      this.setState({
        errorMessage: errorMessage.concat("\n", error)
      });
    }
  };

  renderConfigurationPage = () => {
    const {
      state: {
        cohorts,
        displayedEntity,
        scope,
        robotAccounts,
        entityRevisionHistory,
        comparisonHistory
      },
      updatePath,
      _setErrorMessage
    } = this;

    const isCohort = scope === "cohort";
    const isRobot = scope === "robot";
    const configurationPageProps = {
      displayName: displayedEntity.displayName,
      cohortId: displayedEntity.cohortId || displayedEntity.id || "",
      robotName: displayedEntity.robotName || "",
      cohorts: cohorts,
      updatePath: updatePath,
      robotAccounts,
      isCohort,
      isRobot,
      comparisonHistory,
      entityRevisionHistory: entityRevisionHistory || "",
      alertError: _setErrorMessage
    };
    return <ConfigurationPage {...configurationPageProps} />;
  };

  renderError(errorMsg: string) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Error accessing config file
        {!isEmpty(errorMsg) ? ` — ${errorMsg}` : ""}
      </Alert>
    );
  }
  render() {
    const {
      state: { displayedEntity, scope, options, errorMessage },
      updatePath,
      renderConfigurationPage: getConfigurationPage
    } = this;

    const isCohort = scope === "cohort";
    const isRobot = scope === "robot";
    const hasError = !isEmpty(errorMessage);
    return (
      <div>
        <RobotCohortSelector
          options={options}
          onChangeRequest={updatePath}
          scope={scope}
          entity={displayedEntity}
          isCohort={isCohort}
          isRobot={isRobot}
        />
        {hasError && this.renderError(errorMessage)}
        {displayedEntity && getConfigurationPage()}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(ManageConfigs))
);
