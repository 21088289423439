// source: log/run_description.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.fox.proto.CommandLineFlagInfo', null, global);
goog.exportSymbol('proto.fox.proto.RunDescription', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.CommandLineFlagInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.CommandLineFlagInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.CommandLineFlagInfo.displayName = 'proto.fox.proto.CommandLineFlagInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.RunDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.RunDescription.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.RunDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.RunDescription.displayName = 'proto.fox.proto.RunDescription';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.CommandLineFlagInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.CommandLineFlagInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.CommandLineFlagInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CommandLineFlagInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currentValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    defaultValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hasValidatorFn: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.CommandLineFlagInfo}
 */
proto.fox.proto.CommandLineFlagInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.CommandLineFlagInfo;
  return proto.fox.proto.CommandLineFlagInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.CommandLineFlagInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.CommandLineFlagInfo}
 */
proto.fox.proto.CommandLineFlagInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasValidatorFn(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.CommandLineFlagInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.CommandLineFlagInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.CommandLineFlagInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CommandLineFlagInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrentValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDefaultValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHasValidatorFn();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.fox.proto.CommandLineFlagInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.CommandLineFlagInfo} returns this
 */
proto.fox.proto.CommandLineFlagInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.fox.proto.CommandLineFlagInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.CommandLineFlagInfo} returns this
 */
proto.fox.proto.CommandLineFlagInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.fox.proto.CommandLineFlagInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.CommandLineFlagInfo} returns this
 */
proto.fox.proto.CommandLineFlagInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string current_value = 4;
 * @return {string}
 */
proto.fox.proto.CommandLineFlagInfo.prototype.getCurrentValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.CommandLineFlagInfo} returns this
 */
proto.fox.proto.CommandLineFlagInfo.prototype.setCurrentValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string default_value = 5;
 * @return {string}
 */
proto.fox.proto.CommandLineFlagInfo.prototype.getDefaultValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.CommandLineFlagInfo} returns this
 */
proto.fox.proto.CommandLineFlagInfo.prototype.setDefaultValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string filename = 6;
 * @return {string}
 */
proto.fox.proto.CommandLineFlagInfo.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.CommandLineFlagInfo} returns this
 */
proto.fox.proto.CommandLineFlagInfo.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool has_validator_fn = 7;
 * @return {boolean}
 */
proto.fox.proto.CommandLineFlagInfo.prototype.getHasValidatorFn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.CommandLineFlagInfo} returns this
 */
proto.fox.proto.CommandLineFlagInfo.prototype.setHasValidatorFn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_default = 8;
 * @return {boolean}
 */
proto.fox.proto.CommandLineFlagInfo.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.CommandLineFlagInfo} returns this
 */
proto.fox.proto.CommandLineFlagInfo.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.RunDescription.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.RunDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.RunDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.RunDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RunDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    runId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    robotName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gitCommitHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gitBranch: jspb.Message.getFieldWithDefault(msg, 4, ""),
    versionName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    startCommand: jspb.Message.getFieldWithDefault(msg, 5, ""),
    allFlagsList: jspb.Message.toObjectList(msg.getAllFlagsList(),
    proto.fox.proto.CommandLineFlagInfo.toObject, includeInstance),
    channel: jspb.Message.getFieldWithDefault(msg, 6, ""),
    robotEpochOffset: jspb.Message.getFieldWithDefault(msg, 7, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    attributesMap: (f = msg.getAttributesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.RunDescription}
 */
proto.fox.proto.RunDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.RunDescription;
  return proto.fox.proto.RunDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.RunDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.RunDescription}
 */
proto.fox.proto.RunDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGitCommitHash(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGitBranch(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartCommand(value);
      break;
    case 12:
      var value = new proto.fox.proto.CommandLineFlagInfo;
      reader.readMessage(value,proto.fox.proto.CommandLineFlagInfo.deserializeBinaryFromReader);
      msg.addAllFlags(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRobotEpochOffset(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 10:
      var value = msg.getAttributesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.RunDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.RunDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.RunDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RunDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGitCommitHash();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGitBranch();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStartCommand();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAllFlagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.fox.proto.CommandLineFlagInfo.serializeBinaryToWriter
    );
  }
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRobotEpochOffset();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getAttributesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string run_id = 1;
 * @return {string}
 */
proto.fox.proto.RunDescription.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string robot_name = 2;
 * @return {string}
 */
proto.fox.proto.RunDescription.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string git_commit_hash = 3;
 * @return {string}
 */
proto.fox.proto.RunDescription.prototype.getGitCommitHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.setGitCommitHash = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string git_branch = 4;
 * @return {string}
 */
proto.fox.proto.RunDescription.prototype.getGitBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.setGitBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string version_name = 11;
 * @return {string}
 */
proto.fox.proto.RunDescription.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string start_command = 5;
 * @return {string}
 */
proto.fox.proto.RunDescription.prototype.getStartCommand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.setStartCommand = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated CommandLineFlagInfo all_flags = 12;
 * @return {!Array<!proto.fox.proto.CommandLineFlagInfo>}
 */
proto.fox.proto.RunDescription.prototype.getAllFlagsList = function() {
  return /** @type{!Array<!proto.fox.proto.CommandLineFlagInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.CommandLineFlagInfo, 12));
};


/**
 * @param {!Array<!proto.fox.proto.CommandLineFlagInfo>} value
 * @return {!proto.fox.proto.RunDescription} returns this
*/
proto.fox.proto.RunDescription.prototype.setAllFlagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.fox.proto.CommandLineFlagInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.CommandLineFlagInfo}
 */
proto.fox.proto.RunDescription.prototype.addAllFlags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.fox.proto.CommandLineFlagInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.clearAllFlagsList = function() {
  return this.setAllFlagsList([]);
};


/**
 * optional string channel = 6;
 * @return {string}
 */
proto.fox.proto.RunDescription.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 robot_epoch_offset = 7;
 * @return {number}
 */
proto.fox.proto.RunDescription.prototype.getRobotEpochOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.setRobotEpochOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 start_time = 8;
 * @return {number}
 */
proto.fox.proto.RunDescription.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 end_time = 9;
 * @return {number}
 */
proto.fox.proto.RunDescription.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * map<string, string> attributes = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.fox.proto.RunDescription.prototype.getAttributesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.fox.proto.RunDescription} returns this
 */
proto.fox.proto.RunDescription.prototype.clearAttributesMap = function() {
  this.getAttributesMap().clear();
  return this;};


goog.object.extend(exports, proto.fox.proto);
