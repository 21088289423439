import React, {Component} from "react";

import {connect} from "react-redux";
import {Redirect} from "react-router";
import moment from "moment-timezone";
import {RouteComponentProps, withRouter} from "react-router-dom";

import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import {primary, secondary} from "../../App";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Typography} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import queryString from "query-string";
import {CSVLink} from "react-csv";
import EventFilterSearchBar from "../Utils/EventFilterSearchBar";
import m_pb, {TimeSeriesMetricType} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import cc_pb from "../../_proto/command_control/proto/command_control_pb";

import {ApplicationState} from "../../redux";
import {listPickStatsRequest} from "../../redux/actions";
import {ServiceError} from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import {grpc} from "@improbable-eng/grpc-web";
import {logInPath} from "../../utils/Paths";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {protoEnumFieldName} from "../../utils/protos";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {combineStyles, commonStyles} from "../Utils/CommonStyles";
import {
  interventionRateFromPickStats,
  pullCountFromPickStats,
  speedFromPickStats,
  successRateFromPickStats
} from "./TimeSeriesUtils";
import LabeledCircularProgress from "../Utils/LabeledCircularProgress";
import {COLORS} from "../Utils/Colors";

const localStyles = (theme: Theme) =>
  createStyles({
    root: {},
    wrapper: {
      display: "flex",
      flexDirection: "column"
    },
    card: { paddingTop: 0, paddingLeft: 0, paddingRight: 0 },
    chartWrapper: {},
    formControl: {
      display: "flex",
      flexDirection: "row"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 1),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 200
      }
    },
    search: {
      position: "relative",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        maxWidth: 160,
        width: "auto"
      }
    },
    toolbar: {
      display: "flex",
      flexDirection: "row",
      overflow: "auto"
    },
    content: {
      height: 500,
      width: "100%"
    },
    textField: {
      margin: 4,
      marginLeft: 8,
      marginRight: 8
    },
    csvLink: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      color: secondary[400]
    },
    graphControls: {
      marginLeft: "60px",
      marginRight: "60px",
      display: "flex",
      justifyContent: "space-around",
      width: "90em"
    }
  });
const styles = combineStyles(localStyles, commonStyles);

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  isLoading: boolean;
  bucketWidth: number;
  redirectTo: string | null;
  timeSeries: Array<any> | null;
  metricType: TimeSeriesMetricType;
  eventFilter: m_pb.EventFilter;
  pickPlaceFilter: m_pb.PickPlaceFilter;
  showMean: boolean;
  showPercentile: number;
  loadingProgress: number;
  stats: Array<m_pb.PickStats.AsObject>;
  splitByEntity: boolean;
  splitByEntityType: string;
  splitThreshold: number | null;
  snapToEndOFDay: boolean;
}

const second = 1e9;
enum BucketWidths {
  MINUTE = 60 * second,
  HOUR = 60 * 60 * second,
  DAY = 24 * 60 * 60 * second,
  WEEK = 7 * 24 * 60 * 60 * second,
  MONTH = 30 * 24 * 60 * 60 * second
}

export enum SplitEntityTypes {
  NONE = "none",
  ROBOT = "robot",
  COHORT = "cohort",
  VERSION = "version",
  LOAD_HEIGHT = "load height",
  PICK_DEPTH = "pick depth",
  TIME_OF_DAY = "time of day",
  USER_ID = "user id",
  PICK_ZONE_ID = "pick zone id",
  PALLET_HEIGHT_SETTING = "pallet height setting",
  PALLET_SUPPORT_TYPE = "pallet type",
  PLASTIC_DETECTED = "plastic detected",
}

type dataMaxFunction = (dataMax: number) => number;
interface DataPrepper {
  tickFormatter: (y: number) => string;
  valuePrepper: (val: number) => number;
  axisName: string;
  valueName: string;
  countName: string;
  unit: string;
  countDomain: [string | number, string | number | dataMaxFunction];
  valueDomain: [string | number, string | number | dataMaxFunction];
  onlyShowCount: boolean;
}

// Round up to the nearest 10
const numberPadder = function (grouping: number) {
  return (dataMax: number) => Math.ceil(dataMax / grouping) * grouping;
};

const durationToPPH = (durationSeconds: number) => {
  return Math.round((10 * (3600 * 1e9)) / durationSeconds) / 10;
};

const formatters: Map<TimeSeriesMetricType, DataPrepper> = new Map([
  [
    TimeSeriesMetricType.PALLET_PICK_PLACE_SUCCESS_RATE,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(y * 1000) / 10,
      axisName: "percent",
      valueName: "Place Percent",
      countName: "# Attempts",
      unit: "%",
      valueDomain: [0, 100],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.AUTO_INTERVENTION_RATE,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(y * 1000) / 10,
      axisName: "percent",
      valueName: "Intervention Rate",
      countName: "# Attempts",
      unit: "%",
      valueDomain: [0, 100],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.INTERVENTION_RATE,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(y * 1000) / 10,
      axisName: "percent",
      valueName: "Intervention Rate",
      countName: "# Attempts",
      unit: "%",
      valueDomain: [0, 100],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => (y ? durationToPPH(y) : 0),
      valueName: "Pulls per hour (only successes)",
      countName: "# Successful Places",
      axisName: "Pulls",
      unit: " pulls / hr",
      valueDomain: [0, numberPadder(10)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION_ALL_PLACES,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => (y ? durationToPPH(y) : 0),
      valueName: "Pulls per hour (all attempts)",
      countName: "# Places",
      axisName: "Pulls",
      unit: " pulls / hr",
      valueDomain: [0, numberPadder(2)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.VEHICLE_THROUGHPUT,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => (y ? durationToPPH(y) : 0),
      valueName: "Vehicle Throughput",
      countName: "# Auto Pulls",
      axisName: "Pulls",
      unit: " pulls / hr",
      valueDomain: [0, numberPadder(2)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.CUMULATIVE_PALLET_PICK_PLACE_ALL_PLACES,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => y,
      axisName: "Pulls",
      valueName: "Pull Count",
      countName: "# Auto Pulls",
      unit: " autonomous pulls",
      valueDomain: [0, numberPadder(2)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
]);

function valuesFormatter(metricType: TimeSeriesMetricType): DataPrepper {
  const formatter = formatters.get(metricType);
  if (!formatter) {
    throw new Error(`Unrecognized metric type: ${metricType}`);
  }
  return formatter;
}

type formatter = (val: moment.Moment) => string;
function xAxisFormatter(width: BucketWidths): formatter {
  switch (width) {
    case BucketWidths.MINUTE: {
      return val => val.format("DD MMM hh:mm:ss");
    }
    case BucketWidths.HOUR: {
      return val => val.format("DD MMM hh:mm a");
    }
    case BucketWidths.DAY: {
      return val => val.format("DD MMM");
    }
    case BucketWidths.WEEK: {
      return val => val.format("DD MMM");
    }
    case BucketWidths.MONTH: {
      return val => val.format("DD MMM");
    }
  }
  throw new Error(`Unrecognized bucket width: ${width}`);
}
const toolTipStyles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 8
    }
  });

interface TooltipContentProps extends WithStyles<typeof toolTipStyles> {
  active?: boolean;
  label?: string;
  payload?: Array<{
    value: string;
    payload: any;
    stroke: string;
    dataKey: string;
  }>;
  formatter: DataPrepper;
  bucketWidth: BucketWidths;
}

const TooltipContent = withStyles(toolTipStyles)(
  (props: TooltipContentProps) => {
    const { active, payload, label, bucketWidth, formatter, classes } = props;
    if (!active || !label || !payload) {
      return null;
    }
    const value = payload[formatter.onlyShowCount ? 0 : 1];
    if (value === undefined) {
      return null;
    }
    const startMsUtc = parseInt(label);
    const endMsUtc = startMsUtc + bucketWidth / 1e6;
    const formatMs = (ms: number): string =>
      xAxisFormatter(bucketWidth)(moment.tz(ms, "GMT").tz("America/Chicago"));
    return (
      <Card className={classes.root}>
        <Typography variant={"caption"}>
          {`${formatMs(startMsUtc)} to ${formatMs(endMsUtc)}`}
        </Typography>
        {!formatter.onlyShowCount &&
          payload.map(line => {
            return line.dataKey == "count" ? null : (
              <Typography style={{ color: line.stroke }}>{`${
                line.dataKey === "value" ? "Average" : line.dataKey
              } : ${line.payload[line.dataKey]} ${formatter.unit}`}</Typography>
            );
          })}
        <Typography
          color={"secondary"}
        >{`${formatter.countName} : ${payload[0].payload.count}`}</Typography>
      </Card>
    );
  }
);

interface CSVLinkProps {
  csvData: Array<[string, string, string]>;
  metricType: TimeSeriesMetricType;
  formatter: DataPrepper;
}
const csvLinkStyle = {
  color: "inherit",
  textDecoration: "none"
};
const StylableCSVLink = (props: CSVLinkProps) => (
  <CSVLink
    style={csvLinkStyle}
    filename={`${protoEnumFieldName(
      TimeSeriesMetricType,
      props.metricType
    )}.csv`}
    data={props.csvData}
    headers={[
      "Bucket start time",
      props.formatter.valueName,
      props.formatter.countName
    ]}
  >
    Download CSV
  </CSVLink>
);

class ChartTimeSeriesNew extends Component<Props & RouteComponentProps, State> {
  state: State = {
    isLoading: false,
    bucketWidth: BucketWidths.DAY,
    redirectTo: null,
    timeSeries: null,
    metricType: TimeSeriesMetricType.PALLET_PICK_PLACE_SUCCESS_RATE,
    showMean: false,
    showPercentile: 0,
    eventFilter: new m_pb.EventFilter(),
    pickPlaceFilter: new m_pb.PickPlaceFilter(),
    loadingProgress: 0,
    stats: [],
    splitByEntity: false,
    splitByEntityType: "",
    splitThreshold: null,
    snapToEndOFDay: true
  };

  // TODO: remove deprecated function
  componentWillMount(): void {
    const values = queryString.parse(this.props.location.search);
    const showMean =
      values.showMean === "true" || values.showMean === undefined;
    const bucketWidth = values.bucketWidth
      ? parseInt(values.bucketWidth as string)
      : BucketWidths.DAY;
    const metricType = values.metricType
      ? parseInt(values.metricType as string)
      : TimeSeriesMetricType.PALLET_PICK_PLACE_SUCCESS_RATE;
    const pickDepthMin = parseFloat((values.pickDepthMin as string) || "0");
    const pickDepthMax = parseFloat((values.pickDepthMax as string) || "0");
    const excusedInterventionCauses = (
      (values.excusedInterventionCauses as Array<string>) ||
      (values.excusedInterventionCauses === ""
        ? []
        : [
            cc_pb.InterventionCause.INTERVENTION_CAUSE_OPERATIONAL_STOP,
            cc_pb.InterventionCause.INTERVENTION_CAUSE_OPERATOR_ERROR
          ])
    )
      .filter(i => i !== "")
      .map(i => parseInt(i));
    const excludedInterventionCauses = (
      (values.excludedInterventionCauses as Array<string>) || []
    )
      .filter(i => i !== "")
      .map(i => parseInt(i));
    const excludedInterventionTypes = (
      (values.excludedInterventionTypes as Array<string>) || []
    )
      .filter(i => i !== "")
      .map(i => parseInt(i));

    this.props.history.push({
      search: queryString.stringify({
        ...values,
        bucketWidth,
        metricType,
        showMean,
        pickDepthMin,
        pickDepthMax,
        excusedInterventionCauses,
        excludedInterventionCauses,
        excludedInterventionTypes
      })
    });
    const { pickPlaceFilter } = this.state;
    pickPlaceFilter.setPickRowDepthMin(pickDepthMin);
    pickPlaceFilter.setPickRowDepthMax(pickDepthMax);
    pickPlaceFilter.setExcusedInterventionCausesList(excusedInterventionCauses);
    pickPlaceFilter.setExcludedInterventionCausesList(
      excludedInterventionCauses
    );
    pickPlaceFilter.setExcludedInterventionTypesList(excludedInterventionTypes);
    this.setState({
      bucketWidth,
      metricType,
      showMean
    });
  }

  _pickStatsToTimeSeries() {
    const {
      stats,
      metricType,
      eventFilter,
      splitByEntity,
      splitByEntityType,
      bucketWidth,
      splitThreshold,
      snapToEndOFDay,
      showMean,
      showPercentile
    } = this.state;
    const endTime = snapToEndOFDay
      ? moment(eventFilter.getEndTime() / 1e6)
          .endOf("day")
          .unix() * 1e9
      : eventFilter.getEndTime();
    let timeSeriesValues;
    switch (metricType) {
      case TimeSeriesMetricType.PALLET_PICK_PLACE_SUCCESS_RATE:
        timeSeriesValues = successRateFromPickStats(
          stats,
          bucketWidth,
          eventFilter.getStartTime(),
          endTime,
          splitByEntity ? splitByEntityType : "",
          splitByEntity ? splitThreshold : null
        );
        this.setState({ timeSeries: timeSeriesValues });
        break;
      case TimeSeriesMetricType.INTERVENTION_RATE:
        timeSeriesValues = interventionRateFromPickStats(
          stats,
          bucketWidth,
          eventFilter.getStartTime(),
          endTime,
          false,
          splitByEntity ? splitByEntityType : "",
          splitByEntity ? splitThreshold : null
        );
        this.setState({ timeSeries: timeSeriesValues });
        break;
      case TimeSeriesMetricType.AUTO_INTERVENTION_RATE:
        timeSeriesValues = interventionRateFromPickStats(
          stats,
          bucketWidth,
          eventFilter.getStartTime(),
          endTime,
          true,
          splitByEntity ? splitByEntityType : "",
          splitByEntity ? splitThreshold : null
        );
        this.setState({ timeSeries: timeSeriesValues });
        break;
      case TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION:
        timeSeriesValues = speedFromPickStats(
          stats,
          bucketWidth,
          eventFilter.getStartTime(),
          endTime,
          true,
          false,
          splitByEntity ? splitByEntityType : "",
          splitByEntity ? splitThreshold : null,
          showMean ? null : showPercentile
        );
        this.setState({ timeSeries: timeSeriesValues });
        break;
      case TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION_ALL_PLACES:
        timeSeriesValues = speedFromPickStats(
          stats,
          bucketWidth,
          eventFilter.getStartTime(),
          endTime,
          false,
          false,
          splitByEntity ? splitByEntityType : "",
          splitByEntity ? splitThreshold : null,
          showMean ? null : showPercentile
        );
        this.setState({ timeSeries: timeSeriesValues });
        break;
      case TimeSeriesMetricType.VEHICLE_THROUGHPUT:
        timeSeriesValues = speedFromPickStats(
          stats,
          bucketWidth,
          eventFilter.getStartTime(),
          endTime,
          false,
          true,
          splitByEntity ? splitByEntityType : "",
          splitByEntity ? splitThreshold : null,
          showMean ? null : showPercentile
        );
        this.setState({ timeSeries: timeSeriesValues });
        break;
      case TimeSeriesMetricType.CUMULATIVE_PALLET_PICK_PLACE_ALL_PLACES:
        timeSeriesValues = pullCountFromPickStats(
          stats,
          bucketWidth,
          eventFilter.getStartTime(),
          endTime,
          splitByEntity ? splitByEntityType : "",
          splitByEntity ? splitThreshold : null
        );
        this.setState({ timeSeries: timeSeriesValues });
        break;
      default:
        break;
    }
  }

  _fetchPickStats(
    filter: m_pb.EventFilter,
    nextPageToken = "",
    newFetch = true
  ) {
    this.setState(
      {
        isLoading: true,
        loadingProgress: newFetch ? 0 : this.state.loadingProgress
      },
      () => {
        this.props
          .dispatch(
            listPickStatsRequest(
              filter,
              nextPageToken,
              true,
              false,
              true,
              false,
              true,
              true,
              false,
              false,
              false,
            )
          )
          .then((res: m_pb.ListPickStatsResponse.AsObject) => {
            // Append stats to existing state if this is a 2nd (or more) fetch of a particular dataset
            const stats = newFetch
              ? res.pickStatsList
              : this.state.stats.concat(res.pickStatsList);
            if (res.nextPageToken) {
              this.setState(
                {
                  stats,
                  isLoading: true,
                  loadingProgress: (100 * stats.length) / res.count
                },
                () => {
                  this._pickStatsToTimeSeries();
                }
              );
              this._fetchPickStats(filter, res.nextPageToken, false);
            } else {
              this.setState(
                {
                  stats,
                  isLoading: false
                },
                () => {
                  this._pickStatsToTimeSeries();
                }
              );
            }
          })
          .catch((e: ServiceError) => {
            switch (e.code) {
              case grpc.Code.Unauthenticated: {
                this.setState({
                  redirectTo: logInPath(window.location.pathname)
                });
                break;
              }
              // TODO(malcolm): Add pages for permission denied, 500 error
            }
            this.setState({ isLoading: false });
          });
      }
    );
  }

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      timeSeries,
      redirectTo,
      bucketWidth,
      metricType,
      showMean,
      splitByEntity,
      splitByEntityType,
      loadingProgress
    } = this.state;
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    const currentFormatter = valuesFormatter(metricType);
    const progressSpinner = isLoading ? (
      <LabeledCircularProgress loadingProgress={loadingProgress} />
    ) : null;
    let lineChartData: Array<any> = [];
    let entityNames = new Set<string>();
    if (timeSeries) {
      for (const value of timeSeries) {
        const valueByEntity = {};
        for (const entityName of Array.from(value.valueByEntity.keys())) {
          if (typeof entityName === "string") {
            entityNames.add(entityName);
          }
          const rawValue = value.valueByEntity.get(entityName) || 0;
          const chartValue = rawValue
            ? currentFormatter.valuePrepper(rawValue)
            : undefined;
          // @ts-ignore
          valueByEntity[entityName] = chartValue;
        }
        const rawValue = value.value;
        const chartValue =
          value.count || currentFormatter.onlyShowCount
            ? currentFormatter.valuePrepper(rawValue)
            : undefined;
        lineChartData.push({
          count: value.count,
          value: chartValue,
          time: value.startTime / 1e6,
          ...valueByEntity
        });
      }
    }
    const xFormatter = xAxisFormatter(bucketWidth);

    return (
      <div className={classes.wrapper}>
        <EventFilterSearchBar
          disable={isLoading}
          title={"Time Series"}
          onRequestSubmit={f =>
            this.setState({ eventFilter: f }, () => this._fetchPickStats(f))
          }
        />
        {progressSpinner}
        <CardContent className={classes.content}>
          {timeSeries && (
            <ResponsiveContainer>
              <LineChart
                data={lineChartData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis
                  tickFormatter={secs => xFormatter(moment(secs))}
                  dataKey="time"
                  scale="time"
                  type="number"
                  allowDataOverflow
                  domain={["dataMin", "dataMax"]}
                />
                {!currentFormatter.onlyShowCount && (
                  <YAxis
                    unit={currentFormatter.unit}
                    type={"number"}
                    tickFormatter={currentFormatter.tickFormatter}
                    scale={"linear"}
                    yAxisId={"left"}
                    domain={currentFormatter.valueDomain}
                  />
                )}
                <YAxis
                  yAxisId={"right"}
                  orientation={"right"}
                  domain={currentFormatter.countDomain}
                />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip
                  content={
                    <TooltipContent
                      formatter={currentFormatter}
                      bucketWidth={bucketWidth}
                    />
                  }
                />
                <Legend />
                {!currentFormatter.onlyShowCount && (
                  <Line
                    name={currentFormatter.valueName}
                    strokeWidth={4}
                    yAxisId={"left"}
                    dataKey="value"
                    isAnimationActive={false}
                    stroke={primary[400]}
                    activeDot={{ r: 12 }}
                  />
                )}
                {splitByEntity &&
                  Array.from(entityNames).map((entityName, index) => {
                    return (
                      <Line
                        name={entityName}
                        strokeWidth={2}
                        yAxisId={"left"}
                        dataKey={entityName}
                        isAnimationActive={false}
                        stroke={
                          index < COLORS.length ? COLORS[index] : "#000000"
                        }
                        activeDot={{ r: 6 }}
                      />
                    );
                  })}
                <Line
                  name={currentFormatter.countName}
                  yAxisId={"right"}
                  dataKey="count"
                  isAnimationActive={false}
                  type={"step"}
                  strokeWidth={2}
                  stroke={secondary[200]}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </CardContent>
        <div className={classes.graphControls}>
          <FormControl>
            <InputLabel id="metric-dropdown-label">Metric:</InputLabel>
            <Select
              labelId="metric-dropdown-label"
              id="metric-dropdown"
              label="Metric:"
              value={metricType}
              onChange={e =>
                this.setState({ metricType: e.target.value as number }, () =>
                  this._pickStatsToTimeSeries()
                )
              }
            >
              <MenuItem
                value={TimeSeriesMetricType.PALLET_PICK_PLACE_SUCCESS_RATE}
              >
                Place Percent
              </MenuItem>
              <MenuItem
                value={TimeSeriesMetricType.AUTO_INTERVENTION_RATE}
              >
                Intervention Rate
              </MenuItem>
              <MenuItem
                value={TimeSeriesMetricType.INTERVENTION_RATE}
              >
                Intervention Rate (incl. operator-initiated)
              </MenuItem>
              <MenuItem value={TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION}>
                Success-only PPH
              </MenuItem>
              <MenuItem
                value={
                  TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION_ALL_PLACES
                }
              >
                All attempts PPH
              </MenuItem>
              <MenuItem
                value={
                  TimeSeriesMetricType.VEHICLE_THROUGHPUT
                }
              >
                Vehicle Throughput
              </MenuItem>
              <MenuItem
                value={
                  TimeSeriesMetricType.CUMULATIVE_PALLET_PICK_PLACE_ALL_PLACES
                }
              >
                Pull Count
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="bucket-width-dropdown-label">
              Bucket width:
            </InputLabel>
            <Select
              labelId="bucket-width-dropdown-label"
              id="bucket-width-dropdown"
              value={this.state.bucketWidth}
              label="Bucket width:"
              onChange={e =>
                this.setState({ bucketWidth: e.target.value as number }, () =>
                  this._pickStatsToTimeSeries()
                )
              }
            >
              <MenuItem value={BucketWidths.HOUR}>Hour</MenuItem>
              <MenuItem value={BucketWidths.DAY}>Day</MenuItem>
              <MenuItem value={BucketWidths.WEEK}>Week</MenuItem>
              <MenuItem value={BucketWidths.MONTH}>Month</MenuItem>
            </Select>
          </FormControl>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showMean}
                  onChange={e =>
                    this.setState({ showMean: e.target.checked }, () => this._pickStatsToTimeSeries())
                  }
                />
              }
              label="Show Average"
            />
            {!showMean && <TextField
                label="Percentile"
                value={this.state.showPercentile}
                type="number"
                inputProps={{
                  type: "number",
                  step: 1,
                  min: 1,
                  max: 100
                }}
                onChange={e =>
                  this.setState(
                    { showPercentile: parseInt(e.target.value, 10) },
                    () => this._pickStatsToTimeSeries()
                  )
                }
              />}
            <FormControlLabel
              control={
                <Checkbox
                  checked={splitByEntity}
                  onChange={e =>
                    this.setState({ splitByEntity: e.target.checked })
                  }
                />
              }
              label="Split"
            />
            <FormControl>
              <InputLabel id="split-by-dropdown-label">Split by:</InputLabel>
              <Select
                labelId="split-by-dropdown-label"
                id="split-by-dropdown"
                value={splitByEntityType}
                label="Split by:"
                onChange={e =>
                  this.setState(
                    {
                      splitByEntityType: (
                        e.target.value as SplitEntityTypes
                      ).valueOf()
                    },
                    () => this._pickStatsToTimeSeries()
                  )
                }
                disabled={!splitByEntity}
              >
                <MenuItem value={SplitEntityTypes.NONE}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={SplitEntityTypes.COHORT}>Cohort</MenuItem>
                <MenuItem value={SplitEntityTypes.ROBOT}>Robot</MenuItem>
                <MenuItem value={SplitEntityTypes.VERSION}>Version</MenuItem>
                <MenuItem value={SplitEntityTypes.LOAD_HEIGHT}>
                  Load Height
                </MenuItem>
                <MenuItem value={SplitEntityTypes.PICK_DEPTH}>
                  Pick Depth
                </MenuItem>
                <MenuItem value={SplitEntityTypes.TIME_OF_DAY}>
                  Time of Day
                </MenuItem>
                <MenuItem value={SplitEntityTypes.USER_ID}>
                  User Id
                </MenuItem>
                <MenuItem value={SplitEntityTypes.PICK_ZONE_ID}>
                  Pick Zone Id
                </MenuItem>
                <MenuItem value={SplitEntityTypes.PALLET_HEIGHT_SETTING}>
                  Pallet Height Setting
                </MenuItem>
                <MenuItem value={SplitEntityTypes.PALLET_SUPPORT_TYPE}>
                  Pallet Type
                </MenuItem>
                <MenuItem value={SplitEntityTypes.PLASTIC_DETECTED}>
                  Plastic Detection
                </MenuItem>
              </Select>
            </FormControl>
            {[
              SplitEntityTypes.LOAD_HEIGHT.valueOf(),
              SplitEntityTypes.PICK_DEPTH.valueOf(),
              SplitEntityTypes.TIME_OF_DAY.valueOf()
            ].includes(splitByEntityType) && (
              <TextField
                label={splitByEntityType + " threshold"}
                value={this.state.splitThreshold}
                type="number"
                inputProps={{
                  type: "number",
                  step: 0.01,
                  min: 0
                }}
                onChange={e =>
                  this.setState(
                    { splitThreshold: parseFloat(e.target.value) },
                    () => this._pickStatsToTimeSeries()
                  )
                }
              />
            )}
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.snapToEndOFDay}
                onChange={e =>
                  this.setState({ snapToEndOFDay: e.target.checked }, () =>
                    this._pickStatsToTimeSeries()
                  )
                }
              />
            }
            label="Snap display to end of day"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(ChartTimeSeriesNew))
);
