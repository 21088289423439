import React, {useState} from "react";
import { connect } from "react-redux";
import {Divider, List, ListItem, ListItemText} from "@material-ui/core";

import {NavLink as RouterLink} from "react-router-dom";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import IconExpandLess from "@material-ui/icons/ExpandLess";

export interface ChildMenuItem {
  onClick: () => void;
  path: string;
  text: string;
}

interface Props {
  onClick: () => void;
  path: string;
  text: string;
  childMenuItems: ChildMenuItem[];
  isChild?: boolean;
}

const AppMenuItem = (props: Props) => {
    const [isOpen, setOpen] = useState(false);
    const hasChildren = !!props.childMenuItems.length;
    const isLink = !!props.path;

    const toggleOpen = () => {
        setOpen(!isOpen);
    }
    return <>
            <div style={{"display": "flex", "alignItems": "center"}}>
             <ListItem
                onClick={hasChildren ? toggleOpen : props.onClick}
                {...{to: props.path}}
                component={isLink ? RouterLink as any : ListItem}
                // @ts-ignore
                button={isLink}
            >
                <ListItemText style={props.isChild ? {"marginLeft": "1rem"} : {}}>{props.text}</ListItemText>
            </ListItem>
            {hasChildren && !isOpen && <IconExpandMore onClick={() => setOpen(true)}/>}
            {hasChildren && isOpen && <IconExpandLess onClick={() => setOpen(false)}/>}
            </div>
            {isOpen && props.childMenuItems.map((childMenuItem) => {
                return <AppMenuItem onClick={childMenuItem.onClick} path={childMenuItem.path} text={childMenuItem.text} isChild={true} childMenuItems={[]}/>
            })}
            {hasChildren && isOpen && <Divider />}
        </>
};

export default connect()(AppMenuItem);
