// package: fox.proto.monitoring
// file: command_control/monitoring/proto/monitoring.proto

var command_control_monitoring_proto_monitoring_pb = require("../../../command_control/monitoring/proto/monitoring_pb");
var command_control_proto_command_control_pb = require("../../../command_control/proto/command_control_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Monitoring = (function () {
  function Monitoring() {}
  Monitoring.serviceName = "fox.proto.monitoring.Monitoring";
  return Monitoring;
}());

Monitoring.GetRunMetadata = {
  methodName: "GetRunMetadata",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetRunMetadataRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RunMetadata
};

Monitoring.GetRunPlacedPallets = {
  methodName: "GetRunPlacedPallets",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetRunPlacedPalletsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RunPlacedPallets
};

Monitoring.ListRunMetadatas = {
  methodName: "ListRunMetadatas",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListRunMetadatasRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListRunMetadatasResponse
};

Monitoring.ListGoalMetadatas = {
  methodName: "ListGoalMetadatas",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListGoalMetadatasRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListGoalMetadatasResponse
};

Monitoring.ListExternalBugs = {
  methodName: "ListExternalBugs",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListExternalBugsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListExternalBugsResponse
};

Monitoring.ListMonitoringAccounts = {
  methodName: "ListMonitoringAccounts",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListMonitoringAccountsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListMonitoringAccountsResponse
};

Monitoring.UpsertRunReview = {
  methodName: "UpsertRunReview",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpsertRunReviewRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RunMetadata
};

Monitoring.LogIn = {
  methodName: "LogIn",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.LogInRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.LogInResponse
};

Monitoring.SignUp = {
  methodName: "SignUp",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.SignUpRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.SignUpResponse
};

Monitoring.GetViewer = {
  methodName: "GetViewer",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetViewerRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.Viewer
};

Monitoring.CreateRobotAccount = {
  methodName: "CreateRobotAccount",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.CreateRobotAccountRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RobotAccount
};

Monitoring.ListRobotAccounts = {
  methodName: "ListRobotAccounts",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListRobotAccountsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListRobotAccountsResponse
};

Monitoring.GetRobotAccount = {
  methodName: "GetRobotAccount",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetRobotAccountRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RobotAccount
};

Monitoring.CreateRobotAccountKey = {
  methodName: "CreateRobotAccountKey",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.CreateRobotAccountKeyRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RobotAccountKey
};

Monitoring.UpdateRobotAccountCohort = {
  methodName: "UpdateRobotAccountCohort",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpdateRobotAccountCohortRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RobotAccount
};

Monitoring.UpdateRobotAccountIp = {
  methodName: "UpdateRobotAccountIp",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpdateRobotAccountIpRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RobotAccount
};

Monitoring.UpdateRobotAccount = {
  methodName: "UpdateRobotAccount",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpdateRobotAccountRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RobotAccount
};

Monitoring.CreateForkliftCohort = {
  methodName: "CreateForkliftCohort",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.CreateForkliftCohortRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ForkliftCohort
};

Monitoring.UpdateForkliftCohort = {
  methodName: "UpdateForkliftCohort",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpdateForkliftCohortRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ForkliftCohort
};

Monitoring.GetForkliftCohort = {
  methodName: "GetForkliftCohort",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetForkliftCohortRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ForkliftCohort
};

Monitoring.ListForkliftCohorts = {
  methodName: "ListForkliftCohorts",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListForkliftCohortsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListForkliftCohortsResponse
};

Monitoring.CreateSite = {
  methodName: "CreateSite",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.CreateSiteRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.Site
};

Monitoring.ListSites = {
  methodName: "ListSites",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListSitesRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListSitesResponse
};

Monitoring.GetTimeSeriesMetric = {
  methodName: "GetTimeSeriesMetric",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetTimeSeriesMetricRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.TimeSeriesMetric
};

Monitoring.GetScalarMetric = {
  methodName: "GetScalarMetric",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetScalarMetricRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ScalarMetric
};

Monitoring.CreateAnnotation = {
  methodName: "CreateAnnotation",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.CreateAnnotationRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.Annotation
};

Monitoring.UpdateAnnotation = {
  methodName: "UpdateAnnotation",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpdateAnnotationRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.Annotation
};

Monitoring.DeleteAnnotation = {
  methodName: "DeleteAnnotation",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.DeleteAnnotationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

Monitoring.UpdateRunAttribute = {
  methodName: "UpdateRunAttribute",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpdateRunAttributeRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RunMetadata
};

Monitoring.DeleteRunAttribute = {
  methodName: "DeleteRunAttribute",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.DeleteRunAttributeRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RunMetadata
};

Monitoring.SetExcludeIntervention = {
  methodName: "SetExcludeIntervention",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.SetExcludeInterventionRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RunPlacedPallets
};

Monitoring.ListPickStats = {
  methodName: "ListPickStats",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListPickStatsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListPickStatsResponse
};

Monitoring.ListPickStatsDatastore = {
  methodName: "ListPickStatsDatastore",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListPickStatsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListPickStatsResponse
};

Monitoring.ListRunStats = {
  methodName: "ListRunStats",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListRunStatsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListRunStatsResponse
};

Monitoring.ListStoppages = {
  methodName: "ListStoppages",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListStoppagesRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListStoppagesResponse
};

Monitoring.ListInterventions = {
  methodName: "ListInterventions",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListStoppagesRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListStoppagesResponse
};

Monitoring.GetLogUploadInfo = {
  methodName: "GetLogUploadInfo",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetLogUploadInfoRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.LogUploadInfo
};

Monitoring.GetFaultCountTimeSeries = {
  methodName: "GetFaultCountTimeSeries",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetFaultCountTimeSeriesRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.FaultCountTimeSeries
};

Monitoring.CreateSyncDemand = {
  methodName: "CreateSyncDemand",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.CreateSyncDemandRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.SyncDemand
};

Monitoring.ListSyncDemands = {
  methodName: "ListSyncDemands",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListSyncDemandsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListSyncDemandsResponse
};

Monitoring.UpdateSyncDemandStatus = {
  methodName: "UpdateSyncDemandStatus",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpdateSyncDemandStatusRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.SyncDemand
};

Monitoring.ListCohortEnvironmentConfigs = {
  methodName: "ListCohortEnvironmentConfigs",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListCohortEnvironmentConfigsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListCohortEnvironmentConfigsResponse
};

Monitoring.UpsertCohortEnvironmentConfig = {
  methodName: "UpsertCohortEnvironmentConfig",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpsertCohortEnvironmentConfigRequest,
  responseType: command_control_proto_command_control_pb.CohortEnvironmentConfig
};

Monitoring.BatchCreateConfigurationFileRevisions = {
  methodName: "BatchCreateConfigurationFileRevisions",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.BatchCreateConfigurationFileRevisionsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.BatchCreateConfigurationFileRevisionsResponse
};

Monitoring.UploadFaultTranslations = {
  methodName: "UploadFaultTranslations",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_proto_command_control_pb.FaultTranslations,
  responseType: command_control_proto_command_control_pb.FaultTranslations
};

Monitoring.PublishFaultTranslations = {
  methodName: "PublishFaultTranslations",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.PublishFaultTranslationsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.FaultConfigurationVersion
};

Monitoring.GetConfigurationFileRevision = {
  methodName: "GetConfigurationFileRevision",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetConfigurationFileRevisionRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ConfigurationFileRevision
};

Monitoring.PublishForkliftConfigurationState = {
  methodName: "PublishForkliftConfigurationState",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.PublishForkliftConfigurationStateRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.PublishForkliftConfigurationStateResponse
};

Monitoring.ListConfigurationHeaderRevisions = {
  methodName: "ListConfigurationHeaderRevisions",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListConfigurationHeaderRevisionsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListConfigurationHeaderRevisionsResponse
};

Monitoring.ListConfigurationRevisionHistory = {
  methodName: "ListConfigurationRevisionHistory",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListConfigurationRevisionHistoryRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListConfigurationRevisionHistoryResponse
};

Monitoring.ListTrailers = {
  methodName: "ListTrailers",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListTrailersRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListTrailersResponse
};

Monitoring.ListTrailerStats = {
  methodName: "ListTrailerStats",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListTrailerStatsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListTrailerStatsResponse
};

Monitoring.ListTrailerStatsDatastore = {
  methodName: "ListTrailerStatsDatastore",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListTrailerStatsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListTrailerStatsResponse
};

Monitoring.GetTabularDataset = {
  methodName: "GetTabularDataset",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetTabularDatasetRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.TabularDataset
};

Monitoring.ListFaults = {
  methodName: "ListFaults",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListFaultsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListFaultsResponse
};

Monitoring.PublishRobotHeartbeat = {
  methodName: "PublishRobotHeartbeat",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.PublishRobotHeartbeatRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.PublishRobotHeartbeatResponse
};

Monitoring.GenerateEnvironmentConfig = {
  methodName: "GenerateEnvironmentConfig",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GenerateEnvironmentConfigRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.GenerateEnvironmentConfigResponse
};

Monitoring.ParseEnvironmentConfig = {
  methodName: "ParseEnvironmentConfig",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ParseEnvironmentConfigRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.EnvironmentConfigInputs
};

Monitoring.ListFaultCodes = {
  methodName: "ListFaultCodes",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListFaultCodesRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListFaultCodesResponse
};

Monitoring.ListOrganizations = {
  methodName: "ListOrganizations",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListOrganizationsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListOrganizationsResponse
};

Monitoring.CreateAutonomyVersion = {
  methodName: "CreateAutonomyVersion",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.CreateAutonomyVersionRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.AutonomyVersion
};

Monitoring.ListAutonomyVersions = {
  methodName: "ListAutonomyVersions",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListAutonomyVersionsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListAutonomyVersionsResponse
};

Monitoring.UpdateAutonomyVersion = {
  methodName: "UpdateAutonomyVersion",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpdateAutonomyVersionRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.AutonomyVersion
};

Monitoring.GetApexStats = {
  methodName: "GetApexStats",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetApexStatsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ApexStatsUrls
};

Monitoring.CreateBulkLogUpload = {
  methodName: "CreateBulkLogUpload",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.BulkLogUploadRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.BulkLogUpload
};

Monitoring.ListBulkLogUploads = {
  methodName: "ListBulkLogUploads",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListBulkLogUploadsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListBulkLogUploadsResponse
};

Monitoring.UpsertFaultRateAlertThreshold = {
  methodName: "UpsertFaultRateAlertThreshold",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpsertFaultRateAlertThresholdRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.FaultRateAlertThreshold
};

Monitoring.ListFaultRateAlertThresholds = {
  methodName: "ListFaultRateAlertThresholds",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListFaultRateAlertThresholdsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListFaultRateAlertThresholdsResponse
};

Monitoring.CreateOperatorQuestion = {
  methodName: "CreateOperatorQuestion",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.CreateOperatorQuestionRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.OperatorQuestion
};

Monitoring.UpsertOperatorQuestionTranslation = {
  methodName: "UpsertOperatorQuestionTranslation",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpsertOperatorQuestionTranslationRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.OperatorQuestion
};

Monitoring.CreateOperatorAnswerChoice = {
  methodName: "CreateOperatorAnswerChoice",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.CreateOperatorAnswerChoiceRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.OperatorAnswerChoice
};

Monitoring.UpsertOperatorAnswerChoiceTranslation = {
  methodName: "UpsertOperatorAnswerChoiceTranslation",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpsertOperatorAnswerChoiceTranslationRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.OperatorAnswerChoice
};

Monitoring.ListOperatorQuestions = {
  methodName: "ListOperatorQuestions",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListOperatorQuestionsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListOperatorQuestionsResponse
};

Monitoring.ListOperatorAnswerChoices = {
  methodName: "ListOperatorAnswerChoices",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListOperatorAnswerChoicesRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListOperatorAnswerChoicesResponse
};

Monitoring.UpdateFaultCodeQuestions = {
  methodName: "UpdateFaultCodeQuestions",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpdateFaultCodeQuestionsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.UpdateFaultCodeQuestionsResponse
};

Monitoring.ListFaultCodeQuestions = {
  methodName: "ListFaultCodeQuestions",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListFaultCodeQuestionsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListFaultCodeQuestionsResponse
};

Monitoring.ListFaultTranslations = {
  methodName: "ListFaultTranslations",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListFaultTranslationsRequest,
  responseType: command_control_proto_command_control_pb.FaultTranslations
};

Monitoring.ListFaultConfigurationVersions = {
  methodName: "ListFaultConfigurationVersions",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListFaultConfigurationVersionsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListFaultConfigurationVersionsResponse
};

Monitoring.GetRobotTabletConfigurationSyncStatus = {
  methodName: "GetRobotTabletConfigurationSyncStatus",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetRobotTabletConfigurationSyncStatusRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RobotTabletConfigurationSyncStatus
};

Monitoring.UpdateRobotTabletConfigurationSyncStatus = {
  methodName: "UpdateRobotTabletConfigurationSyncStatus",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpdateRobotTabletConfigurationSyncStatusRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RobotTabletConfigurationSyncStatus
};

Monitoring.GetRobotTabletConfiguration = {
  methodName: "GetRobotTabletConfiguration",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.GetRobotTabletConfigurationRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RobotTabletConfiguration
};

Monitoring.ListRobotTabletConfigurations = {
  methodName: "ListRobotTabletConfigurations",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListRobotTabletConfigurationsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListRobotTabletConfigurationsResponse
};

Monitoring.UpdateRobotTabletConfiguration = {
  methodName: "UpdateRobotTabletConfiguration",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.UpdateRobotTabletConfigurationRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.RobotTabletConfigurationSummary
};

Monitoring.ListTabletOptionalFeatures = {
  methodName: "ListTabletOptionalFeatures",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListTabletOptionalFeaturesRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListTabletOptionalFeaturesResponse
};

Monitoring.CreateTabletOptionalFeature = {
  methodName: "CreateTabletOptionalFeature",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.CreateTabletOptionalFeatureRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.TabletOptionalFeature
};

Monitoring.ListTabletFlagOptions = {
  methodName: "ListTabletFlagOptions",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.ListTabletFlagOptionsRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.ListTabletFlagOptionsResponse
};

Monitoring.CreateTabletFlagOption = {
  methodName: "CreateTabletFlagOption",
  service: Monitoring,
  requestStream: false,
  responseStream: false,
  requestType: command_control_monitoring_proto_monitoring_pb.CreateTabletFlagOptionRequest,
  responseType: command_control_monitoring_proto_monitoring_pb.TabletFlagOption
};

exports.Monitoring = Monitoring;

function MonitoringClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MonitoringClient.prototype.getRunMetadata = function getRunMetadata(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetRunMetadata, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getRunPlacedPallets = function getRunPlacedPallets(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetRunPlacedPallets, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listRunMetadatas = function listRunMetadatas(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListRunMetadatas, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listGoalMetadatas = function listGoalMetadatas(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListGoalMetadatas, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listExternalBugs = function listExternalBugs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListExternalBugs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listMonitoringAccounts = function listMonitoringAccounts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListMonitoringAccounts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.upsertRunReview = function upsertRunReview(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpsertRunReview, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.logIn = function logIn(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.LogIn, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.signUp = function signUp(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.SignUp, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getViewer = function getViewer(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetViewer, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createRobotAccount = function createRobotAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateRobotAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listRobotAccounts = function listRobotAccounts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListRobotAccounts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getRobotAccount = function getRobotAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetRobotAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createRobotAccountKey = function createRobotAccountKey(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateRobotAccountKey, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.updateRobotAccountCohort = function updateRobotAccountCohort(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpdateRobotAccountCohort, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.updateRobotAccountIp = function updateRobotAccountIp(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpdateRobotAccountIp, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.updateRobotAccount = function updateRobotAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpdateRobotAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createForkliftCohort = function createForkliftCohort(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateForkliftCohort, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.updateForkliftCohort = function updateForkliftCohort(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpdateForkliftCohort, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getForkliftCohort = function getForkliftCohort(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetForkliftCohort, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listForkliftCohorts = function listForkliftCohorts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListForkliftCohorts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createSite = function createSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listSites = function listSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getTimeSeriesMetric = function getTimeSeriesMetric(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetTimeSeriesMetric, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getScalarMetric = function getScalarMetric(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetScalarMetric, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createAnnotation = function createAnnotation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateAnnotation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.updateAnnotation = function updateAnnotation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpdateAnnotation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.deleteAnnotation = function deleteAnnotation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.DeleteAnnotation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.updateRunAttribute = function updateRunAttribute(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpdateRunAttribute, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.deleteRunAttribute = function deleteRunAttribute(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.DeleteRunAttribute, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.setExcludeIntervention = function setExcludeIntervention(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.SetExcludeIntervention, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listPickStats = function listPickStats(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListPickStats, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listPickStatsDatastore = function listPickStatsDatastore(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListPickStatsDatastore, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listRunStats = function listRunStats(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListRunStats, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listStoppages = function listStoppages(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListStoppages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listInterventions = function listInterventions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListInterventions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getLogUploadInfo = function getLogUploadInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetLogUploadInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getFaultCountTimeSeries = function getFaultCountTimeSeries(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetFaultCountTimeSeries, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createSyncDemand = function createSyncDemand(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateSyncDemand, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listSyncDemands = function listSyncDemands(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListSyncDemands, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.updateSyncDemandStatus = function updateSyncDemandStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpdateSyncDemandStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listCohortEnvironmentConfigs = function listCohortEnvironmentConfigs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListCohortEnvironmentConfigs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.upsertCohortEnvironmentConfig = function upsertCohortEnvironmentConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpsertCohortEnvironmentConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.batchCreateConfigurationFileRevisions = function batchCreateConfigurationFileRevisions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.BatchCreateConfigurationFileRevisions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.uploadFaultTranslations = function uploadFaultTranslations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UploadFaultTranslations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.publishFaultTranslations = function publishFaultTranslations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.PublishFaultTranslations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getConfigurationFileRevision = function getConfigurationFileRevision(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetConfigurationFileRevision, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.publishForkliftConfigurationState = function publishForkliftConfigurationState(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.PublishForkliftConfigurationState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listConfigurationHeaderRevisions = function listConfigurationHeaderRevisions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListConfigurationHeaderRevisions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listConfigurationRevisionHistory = function listConfigurationRevisionHistory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListConfigurationRevisionHistory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listTrailers = function listTrailers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListTrailers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listTrailerStats = function listTrailerStats(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListTrailerStats, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listTrailerStatsDatastore = function listTrailerStatsDatastore(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListTrailerStatsDatastore, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getTabularDataset = function getTabularDataset(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetTabularDataset, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listFaults = function listFaults(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListFaults, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.publishRobotHeartbeat = function publishRobotHeartbeat(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.PublishRobotHeartbeat, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.generateEnvironmentConfig = function generateEnvironmentConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GenerateEnvironmentConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.parseEnvironmentConfig = function parseEnvironmentConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ParseEnvironmentConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listFaultCodes = function listFaultCodes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListFaultCodes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listOrganizations = function listOrganizations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListOrganizations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createAutonomyVersion = function createAutonomyVersion(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateAutonomyVersion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listAutonomyVersions = function listAutonomyVersions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListAutonomyVersions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.updateAutonomyVersion = function updateAutonomyVersion(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpdateAutonomyVersion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getApexStats = function getApexStats(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetApexStats, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createBulkLogUpload = function createBulkLogUpload(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateBulkLogUpload, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listBulkLogUploads = function listBulkLogUploads(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListBulkLogUploads, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.upsertFaultRateAlertThreshold = function upsertFaultRateAlertThreshold(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpsertFaultRateAlertThreshold, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listFaultRateAlertThresholds = function listFaultRateAlertThresholds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListFaultRateAlertThresholds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createOperatorQuestion = function createOperatorQuestion(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateOperatorQuestion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.upsertOperatorQuestionTranslation = function upsertOperatorQuestionTranslation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpsertOperatorQuestionTranslation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createOperatorAnswerChoice = function createOperatorAnswerChoice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateOperatorAnswerChoice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.upsertOperatorAnswerChoiceTranslation = function upsertOperatorAnswerChoiceTranslation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpsertOperatorAnswerChoiceTranslation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listOperatorQuestions = function listOperatorQuestions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListOperatorQuestions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listOperatorAnswerChoices = function listOperatorAnswerChoices(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListOperatorAnswerChoices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.updateFaultCodeQuestions = function updateFaultCodeQuestions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpdateFaultCodeQuestions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listFaultCodeQuestions = function listFaultCodeQuestions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListFaultCodeQuestions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listFaultTranslations = function listFaultTranslations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListFaultTranslations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listFaultConfigurationVersions = function listFaultConfigurationVersions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListFaultConfigurationVersions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getRobotTabletConfigurationSyncStatus = function getRobotTabletConfigurationSyncStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetRobotTabletConfigurationSyncStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.updateRobotTabletConfigurationSyncStatus = function updateRobotTabletConfigurationSyncStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpdateRobotTabletConfigurationSyncStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.getRobotTabletConfiguration = function getRobotTabletConfiguration(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.GetRobotTabletConfiguration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listRobotTabletConfigurations = function listRobotTabletConfigurations(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListRobotTabletConfigurations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.updateRobotTabletConfiguration = function updateRobotTabletConfiguration(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.UpdateRobotTabletConfiguration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listTabletOptionalFeatures = function listTabletOptionalFeatures(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListTabletOptionalFeatures, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createTabletOptionalFeature = function createTabletOptionalFeature(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateTabletOptionalFeature, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.listTabletFlagOptions = function listTabletFlagOptions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.ListTabletFlagOptions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MonitoringClient.prototype.createTabletFlagOption = function createTabletFlagOption(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Monitoring.CreateTabletFlagOption, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MonitoringClient = MonitoringClient;

