import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router";

import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import ProgressSpinner from "../Utils/ProgressSpinner";

import { ApplicationState } from "../../redux";
import { listRunStatsRequest } from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { grpc } from "@improbable-eng/grpc-web";
import { logInPath } from "../../utils/Paths";
import RunStatsSheet from "../Sheets/RunStatsSheet";
import EventFilterSearchBar from "../Utils/EventFilterSearchBar";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      height: "100%",
      padding: 0,
      paddingBottom: 16,
      display: "flex",
      flexDirection: "column"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  isLoading: boolean;
  redirectTo: string | null;
  stats: Array<m_pb.RunStats.AsObject>;
}

class ListRunStats extends Component<Props, State> {
  state: State = {
    isLoading: false,
    redirectTo: null,
    stats: []
  };

  _fetch(filter: m_pb.EventFilter) {
    this.setState({ isLoading: true }, () => {
      const finish = () => this.setState({ isLoading: false });
      this.props
        .dispatch(listRunStatsRequest(filter))
        .then((res: m_pb.ListRunStatsResponse.AsObject) =>
          this.setState({
            stats: res.runStatsList.sort((s1, s2) =>
              s1.runName > s2.runName ? -1 : 1
            ),
            isLoading: false
          })
        )
        .catch((e: ServiceError) => {
          switch (e.code) {
            case grpc.Code.Unauthenticated: {
              this.setState({
                redirectTo: logInPath(window.location.pathname)
              });
              break;
            }
            default:
            // TODO(malcolm): Add pages for permission denied, 500 error
          }
        })
        .finally(finish);
    });
  }

  render() {
    const { classes } = this.props;
    const { isLoading, stats, redirectTo } = this.state;
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    const progressSpinner = isLoading ? <ProgressSpinner /> : null;
    const sheet = stats.length ? <RunStatsSheet stats={stats} /> : null;
    return (
      <div className={classes.wrapper}>
        <EventFilterSearchBar
          disable={isLoading}
          title={"Run Statistics"}
          onRequestSubmit={f => this._fetch(f)}
        />
        {progressSpinner}
        {sheet}
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(ListRunStats));
