// source: image/image.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.fox.proto.CameraModel', null, global);
goog.exportSymbol('proto.fox.proto.Image', null, global);
goog.exportSymbol('proto.fox.proto.ImageCompression', null, global);
goog.exportSymbol('proto.fox.proto.ImageEncoding', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Image.displayName = 'proto.fox.proto.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.CameraModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.CameraModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.CameraModel.displayName = 'proto.fox.proto.CameraModel';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: jspb.Message.getFieldWithDefault(msg, 1, 0),
    height: jspb.Message.getFieldWithDefault(msg, 2, 0),
    encoding: jspb.Message.getFieldWithDefault(msg, 3, 0),
    compression: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cameraModel: (f = msg.getCameraModel()) && proto.fox.proto.CameraModel.toObject(includeInstance, f),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Image}
 */
proto.fox.proto.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Image;
  return proto.fox.proto.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Image}
 */
proto.fox.proto.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.ImageEncoding} */ (reader.readEnum());
      msg.setEncoding(value);
      break;
    case 5:
      var value = /** @type {!proto.fox.proto.ImageCompression} */ (reader.readEnum());
      msg.setCompression(value);
      break;
    case 6:
      var value = new proto.fox.proto.CameraModel;
      reader.readMessage(value,proto.fox.proto.CameraModel.deserializeBinaryFromReader);
      msg.setCameraModel(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getEncoding();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCompression();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCameraModel();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.fox.proto.CameraModel.serializeBinaryToWriter
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional uint32 width = 1;
 * @return {number}
 */
proto.fox.proto.Image.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Image} returns this
 */
proto.fox.proto.Image.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 height = 2;
 * @return {number}
 */
proto.fox.proto.Image.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Image} returns this
 */
proto.fox.proto.Image.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ImageEncoding encoding = 3;
 * @return {!proto.fox.proto.ImageEncoding}
 */
proto.fox.proto.Image.prototype.getEncoding = function() {
  return /** @type {!proto.fox.proto.ImageEncoding} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.ImageEncoding} value
 * @return {!proto.fox.proto.Image} returns this
 */
proto.fox.proto.Image.prototype.setEncoding = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ImageCompression compression = 5;
 * @return {!proto.fox.proto.ImageCompression}
 */
proto.fox.proto.Image.prototype.getCompression = function() {
  return /** @type {!proto.fox.proto.ImageCompression} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fox.proto.ImageCompression} value
 * @return {!proto.fox.proto.Image} returns this
 */
proto.fox.proto.Image.prototype.setCompression = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional CameraModel camera_model = 6;
 * @return {?proto.fox.proto.CameraModel}
 */
proto.fox.proto.Image.prototype.getCameraModel = function() {
  return /** @type{?proto.fox.proto.CameraModel} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.CameraModel, 6));
};


/**
 * @param {?proto.fox.proto.CameraModel|undefined} value
 * @return {!proto.fox.proto.Image} returns this
*/
proto.fox.proto.Image.prototype.setCameraModel = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Image} returns this
 */
proto.fox.proto.Image.prototype.clearCameraModel = function() {
  return this.setCameraModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Image.prototype.hasCameraModel = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes data = 4;
 * @return {!(string|Uint8Array)}
 */
proto.fox.proto.Image.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes data = 4;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.fox.proto.Image.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.fox.proto.Image.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.fox.proto.Image} returns this
 */
proto.fox.proto.Image.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.CameraModel.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.CameraModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.CameraModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CameraModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: jspb.Message.getFieldWithDefault(msg, 1, 0),
    height: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fx: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    fy: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    cx: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    cy: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    k1: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    k2: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    p1: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    p2: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    k3: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    k4: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    k5: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    k6: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    s1: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    s2: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    s3: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    s4: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    tx: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    ty: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.CameraModel}
 */
proto.fox.proto.CameraModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.CameraModel;
  return proto.fox.proto.CameraModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.CameraModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.CameraModel}
 */
proto.fox.proto.CameraModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFx(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCx(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCy(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setK1(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setK2(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setP1(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setP2(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setK3(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setK4(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setK5(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setK6(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setS1(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setS2(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setS3(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setS4(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTx(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.CameraModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.CameraModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.CameraModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CameraModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFx();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getFy();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getCx();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCy();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getK1();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getK2();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getP1();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getP2();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getK3();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getK4();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getK5();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getK6();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getS1();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getS2();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getS3();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getS4();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getTx();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getTy();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
};


/**
 * optional int32 width = 1;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 height = 2;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float fx = 3;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getFx = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setFx = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float fy = 4;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getFy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setFy = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float cx = 5;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getCx = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setCx = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float cy = 6;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getCy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setCy = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float k1 = 7;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getK1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setK1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float k2 = 8;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getK2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setK2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float p1 = 9;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getP1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setP1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float p2 = 10;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getP2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setP2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float k3 = 11;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getK3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setK3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float k4 = 12;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getK4 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setK4 = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float k5 = 13;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getK5 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setK5 = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float k6 = 14;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getK6 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setK6 = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float s1 = 15;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getS1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setS1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float s2 = 16;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getS2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setS2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float s3 = 17;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getS3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setS3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float s4 = 18;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getS4 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setS4 = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float tx = 19;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getTx = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setTx = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional float ty = 20;
 * @return {number}
 */
proto.fox.proto.CameraModel.prototype.getTy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CameraModel} returns this
 */
proto.fox.proto.CameraModel.prototype.setTy = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * @enum {number}
 */
proto.fox.proto.ImageEncoding = {
  UKNOWN: 0,
  DEPTH32: 1,
  MONO8: 2,
  RGB8: 3,
  MONO16: 4,
  XYZ32: 5
};

/**
 * @enum {number}
 */
proto.fox.proto.ImageCompression = {
  NONE: 0,
  PNG: 1,
  JPEG: 2,
  H264: 3,
  FFV1: 4
};

goog.object.extend(exports, proto.fox.proto);
