import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Typography} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import cc_pb, {
    AnswerAndFollowUpQuestion,
    FaultAvoidableByRobot
} from "../../_proto/command_control/proto/command_control_pb";
import m_pb, {
    CreateOperatorQuestionRequest,
    UpsertOperatorAnswerChoiceTranslationRequest,
    UpsertOperatorQuestionTranslationRequest
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import {
    createOperatorAnswerChoiceRequest,
    createOperatorQuestionRequest,
    listOperatorAnswerChoicesRequest,
    listOperatorQuestionsRequest,
    updateFaultCodeQuestionsRequest,
    upsertOperatorAnswerChoiceTranslationRequest,
    upsertOperatorQuestionTranslationRequest
} from "../../redux/actions";
import {ServiceError} from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";

interface Props {
  classes: any;
  dispatch: any;
  open: boolean;
  onClose: () => void;
  title: string;
  faultTranslation: cc_pb.FaultTranslation.AsObject;
}

const ccPbAnswerChoicesToMPbAnswerChoices = (answerChoices: cc_pb.AnswerAndFollowUpQuestion.AsObject[],
                                             operatorQuestions: m_pb.OperatorQuestion.AsObject[],
                                             operatorAnswerChoices: m_pb.OperatorAnswerChoice.AsObject[]): m_pb.FaultCodeAnswerChoice[] => {
    const faultCodeAnswerChoices: m_pb.FaultCodeAnswerChoice[] = [];
    for (const answerChoice of answerChoices.filter(answerChoice => !!answerChoice.answerChoice)) {
        const faultCodeAnswerChoice = new m_pb.FaultCodeAnswerChoice();
        const nextQuestion = answerChoice.nextQuestion;
        if (!nextQuestion) {
            // Terminal questions should be "unavoidable" unless marked otherwise.
            // All other questions default to "unknown".
            const avoidability = answerChoice.avoidableByRobot === FaultAvoidableByRobot.AVOIDABILITY_AVOIDABLE
                ? FaultAvoidableByRobot.AVOIDABILITY_AVOIDABLE : FaultAvoidableByRobot.AVOIDABILITY_UNAVOIDABLE;
            faultCodeAnswerChoice.setAvoidableByRobot(avoidability);
        }
        const answerChoiceId = operatorAnswerChoices.find(operatorAnswerChoice => answerChoice.answerChoice === operatorAnswerChoice.answer)?.id;
        if (answerChoiceId) {
            faultCodeAnswerChoice.setAnswerChoiceId(answerChoiceId);
        } else {
            console.error("Couldn't find answer choice ID for " + answerChoice.answerChoice);
            continue;
        }
        if (nextQuestion) {
            const nextQuestionId = operatorQuestions.find(operatorQuestion => nextQuestion.question === operatorQuestion.question)?.id;
            if (nextQuestionId) {
                faultCodeAnswerChoice.setNextQuestionId(nextQuestionId);
                faultCodeAnswerChoice.setNextQuestionAnswerChoicesList(ccPbAnswerChoicesToMPbAnswerChoices(nextQuestion.answerChoicesList, operatorQuestions, operatorAnswerChoices));
            } else {
                console.error("Couldn't find question ID for " + nextQuestion.question);
                continue;
            }
        }
        faultCodeAnswerChoices.push(faultCodeAnswerChoice);
    }
    return faultCodeAnswerChoices;
}


const OperatorQuestionsDialog = (props: Props) => {
    const {title, faultTranslation } = props;

    const [firstQuestion, setFirstQuestion] = useState<cc_pb.Question.AsObject>(faultTranslation.firstOperatorQuestion || new cc_pb.Question().toObject());
    const [operatorQuestions, setOperatorQuestions] = useState<m_pb.OperatorQuestion.AsObject[]>([]);
    const [operatorAnswerChoices, setOperatorAnswerChoices] = useState<m_pb.OperatorAnswerChoice.AsObject[]>([]);

    useEffect(() => {
        _loadOperatorQuestions();
        _loadOperatorAnswerChoices();
    }, []);

    const _loadOperatorQuestions = () => {
        const request = new m_pb.ListOperatorQuestionsRequest();
        props.dispatch(listOperatorQuestionsRequest(request))
            .then((payload: m_pb.ListOperatorQuestionsResponse.AsObject) => {
                setOperatorQuestions(payload.questionsList);
            })
            .catch((e: ServiceError) => {
                console.error(e);
            });
    }

    const _loadOperatorAnswerChoices = () => {
        const request = new m_pb.ListOperatorAnswerChoicesRequest();
        props.dispatch(listOperatorAnswerChoicesRequest(request))
            .then((payload: m_pb.ListOperatorAnswerChoicesResponse.AsObject) => {
                setOperatorAnswerChoices(payload.answersList);
            })
            .catch((e: ServiceError) => {
                console.error(e);
            });
    }

    const _saveQuestion = (question: string) => {
        return new Promise((resolve, reject) => {
            const request = new CreateOperatorQuestionRequest();
            request.setQuestion(question);
            props.dispatch(createOperatorQuestionRequest(request))
                .then((resp: m_pb.OperatorQuestion.AsObject) => {
                    const translationRequest = new UpsertOperatorQuestionTranslationRequest();
                    translationRequest.setQuestionId(resp.id);
                    translationRequest.setLocale("en_us");
                    translationRequest.setTranslation(question);
                    props.dispatch(upsertOperatorQuestionTranslationRequest(translationRequest))
                        .then((resp: m_pb.OperatorQuestion.AsObject) => {
                            resolve(resp);
                        })
                        .catch((e: ServiceError) => {
                            console.error(e);
                            reject(e);
                        })
                .catch((e: ServiceError) => {
                    console.error(e);
                    reject(e);
                });
            });
        });
    }

    const _saveAnswerChoice = (answerChoice: string) => {
        return new Promise((resolve, reject) => {
            const request = new m_pb.CreateOperatorAnswerChoiceRequest();
            request.setAnswer(answerChoice);
            props.dispatch(createOperatorAnswerChoiceRequest(request))
                .then((resp: m_pb.OperatorAnswerChoice.AsObject) => {
                   const translationRequest = new UpsertOperatorAnswerChoiceTranslationRequest();
                   translationRequest.setAnswerChoiceId(resp.id);
                   translationRequest.setLocale("en_us");
                   translationRequest.setTranslation(answerChoice);
                   props.dispatch(upsertOperatorAnswerChoiceTranslationRequest(translationRequest))
                        .then((resp: m_pb.OperatorAnswerChoice.AsObject) => {
                            resolve(resp);
                        })
                        .catch((e: ServiceError) => {
                            console.error(e);
                            reject(e);
                        })
                .catch((e: ServiceError) => {
                    console.error(e);
                    reject(e);
                });
            });
        });
    }

    const _submit = () => {
        const originalFirstQuestion = faultTranslation.firstOperatorQuestion;
        const originalQuestions = getQuestions(originalFirstQuestion);
        const originalAnswerChoices = getAnswerChoices(originalFirstQuestion);
        const updatedQuestions = getQuestions(firstQuestion);
        const updatedAnswerChoices = getAnswerChoices(firstQuestion);

        let allOperatorQuestions = [...operatorQuestions];
        let allOperatorAnswerChoices = [...operatorAnswerChoices];

        const newQuestions = updatedQuestions.map(question => {
           return originalQuestions.indexOf(question) === -1 ? question : ""
        }).filter(question => question !== "");
        const newAnswerChoices = updatedAnswerChoices.map(answerChoice => {
           return originalAnswerChoices.indexOf(answerChoice) === -1 ? answerChoice : ""
        }).filter(answerChoice => answerChoice !== "");
        const questionPromises: Promise<any>[] = [];
        const answerChoicePromises: Promise<any>[] = [];
        for (const newQuestion of newQuestions) {
            questionPromises.push(_saveQuestion(newQuestion));
        }
        Promise.all(questionPromises).then((newOperatorQuestions) => {
            allOperatorQuestions = [...allOperatorQuestions, ...newOperatorQuestions];
            for (const newAnswerChoice of newAnswerChoices) {
                answerChoicePromises.push(_saveAnswerChoice(newAnswerChoice));
            }
            Promise.all(answerChoicePromises).then((newOperatorAnswerChoices) => {
                allOperatorAnswerChoices = [...allOperatorAnswerChoices, ...newOperatorAnswerChoices];
                const updateRequest = new m_pb.UpdateFaultCodeQuestionsRequest();
                const updatedQuestion = new m_pb.FaultCodeQuestionConfiguration();
                updatedQuestion.setNumericCode(faultTranslation.numericCode);
                const firstQuestionId = allOperatorQuestions.find(question => question.question === firstQuestion.question)?.id;
                if (firstQuestionId) {
                    updatedQuestion.setFirstQuestionId(firstQuestionId);
                } else {
                    console.error("Could not find question ID for " + firstQuestion.question);
                    return;
                }
                updatedQuestion.setFirstQuestionAnswerChoicesList(ccPbAnswerChoicesToMPbAnswerChoices(firstQuestion.answerChoicesList, allOperatorQuestions, allOperatorAnswerChoices));
                updateRequest.setUpdatedQuestionsList([updatedQuestion]);
                props.dispatch(updateFaultCodeQuestionsRequest(updateRequest))
                    .then(() => {
                        // Finally close the dialog if successful
                        props.onClose();
                    })
                    .catch((e: ServiceError) => {
                        console.error(e);
                    });
            });
        });
    }

    const getQuestions = (question: cc_pb.Question.AsObject | undefined): string[] => {
        if (question) {
            const nestedQuestions = question.answerChoicesList.map(answerChoice => getQuestions(answerChoice?.nextQuestion))
            return [question.question, ...nestedQuestions.flat(2)]
        }
        return [];
    }

    const getAnswerChoices = (question: cc_pb.Question.AsObject | undefined): string[] => {
        if (question) {
            const nestedAnswerChoices = question.answerChoicesList.map(answerChoice => getAnswerChoices(answerChoice?.nextQuestion));
            return [...question.answerChoicesList.map(answerChoice => answerChoice.answerChoice), ...nestedAnswerChoices.flat(2)]
        }
        return [];
    }

    return <React.Fragment>
        <Dialog
            open={props.open}
            onClose={() => props.onClose && props.onClose()}
            maxWidth={false}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography>Fault Numeric Code: {faultTranslation.numericCode}</Typography>
                <QuestionAndAnswerChoices
                    existingQuestion={faultTranslation.firstOperatorQuestion || null}
                    questionLabel="First Question:"
                    depth={0}
                    onUpdate={(updatedQuestion) => setFirstQuestion(updatedQuestion)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={() => _submit()}
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>

};

interface QuestionAndAnswerChoiceProps {
    existingQuestion: cc_pb.Question.AsObject | null;
    questionLabel: string;
    depth: number;
    onUpdate: (updatedQuestion: cc_pb.Question.AsObject) => void;
}

const QuestionAndAnswerChoices = (props: QuestionAndAnswerChoiceProps) => {

    const { existingQuestion, questionLabel, depth, onUpdate } = props;
    const [ question, setQuestion ] = useState(existingQuestion?.question || "");
    const [ answerChoices, setAnswerChoices ] = useState<AnswerAndFollowUpQuestion.AsObject[]>([...(existingQuestion?.answerChoicesList || []),  new AnswerAndFollowUpQuestion().toObject()])

    useEffect(() => {
        // Always add an empty answer choice at the end if there isn't one
        if (answerChoices.slice(-1)[0]?.answerChoice) {
            setAnswerChoices(answerChoices.concat(new AnswerAndFollowUpQuestion().toObject()))
        }
      }, [answerChoices]);

    useEffect(() => {
        onUpdate(toQuestionPb());
    }, [answerChoices, question]);

    const handleChangeQuestion = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setQuestion(e.target.value);
    }

    const handleChangeAnswerChoice = (index, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const updatedAnswerChoices = answerChoices.slice();
        updatedAnswerChoices.splice(index, 1, {...answerChoices[index], answerChoice: e.target.value})
        setAnswerChoices(updatedAnswerChoices);
    }

    const handleChildUpdate = (answerChoiceIndex: number, question: cc_pb.Question.AsObject) => {
        const updatedAnswerChoices = answerChoices.slice();
        updatedAnswerChoices.splice(answerChoiceIndex, 1, {...answerChoices[answerChoiceIndex], nextQuestion: question})
        setAnswerChoices(updatedAnswerChoices);
    }

    const toQuestionPb = (): cc_pb.Question.AsObject => {
        const questionPb = new cc_pb.Question().toObject();
        questionPb.question = question;
        questionPb.answerChoicesList = answerChoices;
        return questionPb;
    }

    return <div style={{paddingLeft: `${8 * depth}px`}}>
        <TextField
            variant="outlined"
            label={questionLabel}
            onChange={handleChangeQuestion}
            value={question ? question : ""}
        />
        {answerChoices.map((answerChoice, index) => {
            return <div>
                <TextField
                    variant="outlined"
                    label="Answer Choice:"
                    onChange={(e) => handleChangeAnswerChoice(index, e)}
                    value={answerChoice.answerChoice}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!answerChoice.nextQuestion}
                            onChange={e => {
                                const updatedAnswerChoices = answerChoices.slice();
                                if (e.target.checked) {
                                    updatedAnswerChoices.splice(index, 1, {...updatedAnswerChoices[index], nextQuestion: new cc_pb.Question().toObject()});
                                } else {
                                    updatedAnswerChoices.splice(index, 1, {...updatedAnswerChoices[index], nextQuestion: undefined});
                                }
                                setAnswerChoices(updatedAnswerChoices);
                            }}
                        />
                    }
                    label="Ask a follow-up question"
                />
                {!answerChoice.nextQuestion && <FormControlLabel
                    control={
                        <Checkbox
                            checked={answerChoice.avoidableByRobot === FaultAvoidableByRobot.AVOIDABILITY_AVOIDABLE}
                            onChange={e => {
                                const updatedAnswerChoices = answerChoices.slice();
                                updatedAnswerChoices.splice(index, 1, {...updatedAnswerChoices[index], avoidableByRobot: e.target.checked ? FaultAvoidableByRobot.AVOIDABILITY_AVOIDABLE : FaultAvoidableByRobot.AVOIDABILITY_UNAVOIDABLE});
                                setAnswerChoices(updatedAnswerChoices);
                            }}
                        />
                    }
                    label="Avoidable by robot"
                />}
                {answerChoice.nextQuestion && <QuestionAndAnswerChoices
                    existingQuestion={answerChoice.nextQuestion}
                    questionLabel="Next Question:"
                    depth={depth + 1}
                    onUpdate={(updatedQuestion) => handleChildUpdate(index, updatedQuestion)}
                />}
            </div>;

        })}
    </div>;

}

export default connect()(OperatorQuestionsDialog);