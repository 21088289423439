import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router";

import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";

import {ApplicationState, ViewerState} from "../../redux";
import {listPickStatsRequest, getApexStatsRequest, listPickStatsDatastoreRequest} from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { grpc } from "@improbable-eng/grpc-web";
import { logInPath } from "../../utils/Paths";
import PickStatsSheet from "../Sheets/PickStatsSheet";
import EventFilterSearchBar from "../Utils/EventFilterSearchBar";
import LabeledCircularProgress from "../Utils/LabeledCircularProgress";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      height: "100%",
      padding: 0,
      paddingBottom: 16,
      display: "flex",
      flexDirection: "column"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return { viewer: state.viewer };
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
  viewer: ViewerState;
}
interface State {
  isLoading: boolean;
  loadingProgress: number;
  redirectTo: string | null;
  stats: Array<m_pb.PickStats.AsObject>;
  apexPickStatsUrl: string;
}

class ListPickStats extends Component<Props, State> {
  state: State = {
    isLoading: false,
    redirectTo: null,
    stats: [],
    loadingProgress: 0,
    apexPickStatsUrl: ""
  };

  _fetch(filter: m_pb.EventFilter, nextPageToken = "", newFetch = true) {
    this.setState({ isLoading: true, loadingProgress: newFetch ? 0 : this.state.loadingProgress }, () => {
      const finish = () => this.setState({ isLoading: false });
      this.props
        .dispatch(listPickStatsDatastoreRequest(filter, nextPageToken))
        // TODO(chris): turn on intervention-based metrics (using below code) once it's fully validated
        // .dispatch(listPickStatsNewRequest(filter, nextPageToken, false, false, false, false, false, false, false, false, false))
        .then((res: m_pb.ListPickStatsResponse.AsObject) => {
          // Append stats to existing state if this is a 2nd (or more) fetch of a particular dataset
          const stats = newFetch ? res.pickStatsList : this.state.stats.concat(res.pickStatsList);
          if (res.nextPageToken) {
            this.setState({
              stats,
              isLoading: true,
              loadingProgress: 100 * stats.length / res.count
            });
            this._fetch(filter, res.nextPageToken, false);
          } else {
            this.setState({
              stats,
              isLoading: false
            });
          }
        })
        .catch((e: ServiceError) => {
          switch (e.code) {
            case grpc.Code.Unauthenticated: {
              this.setState({
                redirectTo: logInPath(window.location.pathname)
              });
              break;
            }
            // TODO(malcolm): Add pages for permission denied, 500 error
          }
          this.setState({isLoading: false});
        })
        this.props.dispatch(getApexStatsRequest()).then((res: m_pb.ApexStatsUrls.AsObject) => {
          this.setState({apexPickStatsUrl: res.pickStatsUrl});
        });
    });
  }

  render() {
    const { classes } = this.props;
    const { isLoading, stats, redirectTo, loadingProgress, apexPickStatsUrl } = this.state;
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    const progressSpinner = isLoading ? <LabeledCircularProgress loadingProgress={loadingProgress} /> : null;
    const sheet = stats.length ? <PickStatsSheet stats={stats} loading={isLoading}/> : null;
    const showApexStatsLink = !!apexPickStatsUrl;

    return (
      <div className={classes.wrapper}>
        <EventFilterSearchBar
          disable={isLoading}
          title={"Pick Statistics"}
          onRequestSubmit={f => this._fetch(f)}
        />
        {progressSpinner}
        {showApexStatsLink ? <div><a href={apexPickStatsUrl}>Download Apex pick stats for last two weeks</a></div> : null}
        {sheet}
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(ListPickStats));
