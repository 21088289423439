import React, {Component} from "react";
import {connect} from "react-redux";
import {createStyles, WithStyles, withStyles} from "@material-ui/core/styles";

import {
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import detection_pb, {ZoneConfig} from "../../_proto/detection/proto/detection_pb";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import config_pb, {EnvironmentConfig} from "../../_proto/robot/proto/config_pb";
import PlaceZoneConfiguration from "./PlaceZoneConfiguration";
import PlaceZoneTemplateEditor from "./PlaceZoneTemplateEditor";
import DockConfigurations from "./DockConfigurations";
import {Vector3f} from "../../_proto/geometry/proto/transform_pb";
import {generateEnvironmentConfigRequest, parseEnvironmentConfigRequest} from "../../redux/actions";
import FileUploadButton from "../Utils/FileUploadButton";
import FileDownloadButton from "../Utils/FileDownloadButton";
import Tooltip from "../RunPage/Tooltip";
import PickZoneTemplateEditor from "./PickZoneTemplateEditor";
import PickZoneConfiguration from "./PickZoneConfiguration";

const styles = () =>
  createStyles({
    buttonContainer: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      margin: "1em",
      display: "block"
    },
    environmentConfigBackground: {
      width: "50em",
      margin: "auto"
    },
    pageSection: {
      margin: "2em",
      padding: "2em",
      position: "relative"
    },
    pageHeader: {
      textAlign: "center"
    }
  });

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  placeZoneTemplates: detection_pb.ZoneConfig[];
  pickZoneTemplates: detection_pb.ZoneConfig[];
  docks: m_pb.DockConfig[];
  editingPlaceZoneTemplateIndex: number | null;
  lastPlaceTemplateSaved: boolean;
  editingPickZoneTemplateIndex: number | null;
  lastPickTemplateSaved: boolean;
  generatedEnvironmentConfig: string;
  isCopied: boolean;
  tallPallets: boolean;
  dockMeasurementsCsv: string;
}

class EnvironmentConfigPage extends Component<Props, State> {
  state: State = {
    placeZoneTemplates: [],
    pickZoneTemplates: [],
    docks: [],
    editingPlaceZoneTemplateIndex: null,
    editingPickZoneTemplateIndex: null,
    generatedEnvironmentConfig: "",
    isCopied: false,
    lastPlaceTemplateSaved: false,
    lastPickTemplateSaved: false,
    tallPallets: false,
    dockMeasurementsCsv: ""
  };

  _addPlaceZone = () => {
    const { placeZoneTemplates } = this.state;
    const zone_config = new detection_pb.ZoneConfig();
    zone_config.setPlaceConfig(new detection_pb.PlaceZoneConfig());
    placeZoneTemplates.push(zone_config);
    this.setState({
      placeZoneTemplates,
      editingPlaceZoneTemplateIndex: placeZoneTemplates.length - 1
    });
  };

  _addPickZone = () => {
    const { pickZoneTemplates } = this.state;
    const zone_config = new detection_pb.ZoneConfig();
    zone_config.setRampConfig(new detection_pb.RampConfig());
    pickZoneTemplates.push(zone_config);
    this.setState({
      pickZoneTemplates,
      editingPickZoneTemplateIndex: pickZoneTemplates.length - 1
    });
  }

  _updatePlaceZone = (
    index: number,
    updatedZoneConfig: detection_pb.ZoneConfig
  ) => {
    const { placeZoneTemplates } = this.state;
    placeZoneTemplates[index] = updatedZoneConfig;
    this.setState({ placeZoneTemplates, editingPlaceZoneTemplateIndex: null });
    // TODO(chris): refactor state objects to play nice with localStorage,
    //  then use localStorage to track my in-progress edits
    // this.setState({placeZoneTemplates, editingPlaceZoneTemplateIndex: null}, () => {
    //     localStorage.setItem("placeZoneTemplates", JSON.stringify(placeZoneTemplates))
    // });
  };

  _updatePickZone = (
    index: number,
    updatedZoneConfig: detection_pb.ZoneConfig
  ) => {
    const { pickZoneTemplates } = this.state;
    pickZoneTemplates[index] = updatedZoneConfig;
    this.setState({ pickZoneTemplates, editingPickZoneTemplateIndex: null, lastPickTemplateSaved: true });
  };

  _deletePlaceZone = (index: number) => {
    const { placeZoneTemplates } = this.state;
    placeZoneTemplates.splice(index, 1);
    this.setState({ placeZoneTemplates, editingPlaceZoneTemplateIndex: null, lastPlaceTemplateSaved: true });
  };

  _deletePickZone = (index: number) => {
    const { pickZoneTemplates } = this.state;
    pickZoneTemplates.splice(index, 1);
    this.setState({ pickZoneTemplates, editingPickZoneTemplateIndex: null });
  }

  _generateEnvironmentConfig = () => {
    const { placeZoneTemplates, pickZoneTemplates, docks, tallPallets } = this.state;
    const consolidatedTemplate = new config_pb.EnvironmentConfig();
    const pickZoneConfigs = pickZoneTemplates.length === 0 ?
        [this._defaultPickZoneConfig()] :
        pickZoneTemplates.map(pickZoneTemplate => {
          const pickZoneConfig = this._defaultPickZoneConfig();
          pickZoneConfig.setRampConfig(pickZoneTemplate.getRampConfig())
          // (re-)Set default ramp pick config values on top of template
          const rampPickConfig = new detection_pb.RampPickConfig();
          rampPickConfig.setType(
            detection_pb.RampPickConfig.PickType.PICK_TYPE_FIXED
          );
          rampPickConfig.setLiftAdjustment(-0.009);
          rampPickConfig.setMinLipDistance(-0.2);
          pickZoneConfig.getRampConfig()?.setRampPickConfigList([rampPickConfig]);
          return pickZoneConfig;
        });
    consolidatedTemplate.setZoneConfigList([
      ...pickZoneConfigs,
      ...placeZoneTemplates.map(placeZoneTemplate => {
        const placeZoneConfig = placeZoneTemplate.getPlaceConfig();
        if (placeZoneConfig) {
          placeZoneConfig.setPalletShape(this._defaultPalletShape());
        }
        return placeZoneTemplate;
      })
    ]);
    consolidatedTemplate.setTallPallets(tallPallets)
    let nextPlaceZoneId = 1001;
    for (const dock of docks) {
      dock.setPlaceZoneId(nextPlaceZoneId);
      nextPlaceZoneId += 2;
    }
    this.props
      .dispatch(generateEnvironmentConfigRequest(consolidatedTemplate, docks))
      .then((resp: string) => {
        this.setState({ generatedEnvironmentConfig: resp });
      });
  };

  _defaultPickZoneConfig = () => {
    const pickZoneConfig = new detection_pb.ZoneConfig();
    pickZoneConfig.setZoneType(detection_pb.ZoneConfig.ZoneType.TRUCK);
    const pickZonePlaceConfig = new detection_pb.PlaceZoneConfig();
    pickZonePlaceConfig.setPalletShape(this._defaultPalletShape());
    pickZoneConfig.setPlaceConfig(pickZonePlaceConfig);
    const rampConfig = new detection_pb.RampConfig();
    const rampPickConfig = new detection_pb.RampPickConfig();
    rampPickConfig.setType(
      detection_pb.RampPickConfig.PickType.PICK_TYPE_FIXED
    );
    rampPickConfig.setLiftAdjustment(-0.009);
    rampPickConfig.setMinLipDistance(-0.2);
    rampConfig.setRampPickConfigList([rampPickConfig]);
    pickZoneConfig.setRampConfig(rampConfig);
    return pickZoneConfig;
  };

  _defaultPalletShape = () => {
    const palletShape = new Vector3f();
    palletShape.setX(1.219);
    palletShape.setY(1.016);
    palletShape.setZ(0.125);
    return palletShape;
  };

  _parseEnvironmentConfig = (envConfigTextProto: string, extract_csv: boolean = false) => {
    this.props.dispatch(parseEnvironmentConfigRequest(envConfigTextProto))
        .then((resp: m_pb.EnvironmentConfigInputs) => {
          const template: EnvironmentConfig | undefined = resp.getTemplate();
          if (template) {
            const placeZoneConfigs = template.getZoneConfigList().filter(zoneConfig =>
              zoneConfig.getZoneType() === ZoneConfig.ZoneType.WAREHOUSE
            );
            const pickZoneConfigs = template.getZoneConfigList().filter(zoneConfig =>
              zoneConfig.getZoneType() === ZoneConfig.ZoneType.TRUCK
            );
            if (extract_csv) {
              this.setState({
                placeZoneTemplates: this.state.placeZoneTemplates.concat(placeZoneConfigs),
                pickZoneTemplates: this.state.pickZoneTemplates.concat(pickZoneConfigs),
                dockMeasurementsCsv: resp.getDockMeasurementsCsv()
              });
            } else {
              this.setState({
                placeZoneTemplates: this.state.placeZoneTemplates.concat(placeZoneConfigs),
                pickZoneTemplates: this.state.pickZoneTemplates.concat(pickZoneConfigs),
              });
            }
          }
    });
  }

  _copyTextToClipboard = (text: string) => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
    this.setState({isCopied: true}, () => {
      setTimeout(() => {
        this.setState({isCopied: false});
      }, 1500);
    });
  }

  _cancelPickTemplateUpdate = (index: number) => {
    // Delete the last template if it's newly added and not saved
    if (
      !this.state.lastPickTemplateSaved &&
      index === this.state.pickZoneTemplates.length - 1
    ) {
      this._deletePickZone(index);
    }
    this.setState({ editingPickZoneTemplateIndex: null });
  };

  _cancelPlaceTemplateUpdate = (index: number) => {
    // Delete the last template if it's newly added and not saved
    if (
      !this.state.lastPlaceTemplateSaved &&
      index === this.state.placeZoneTemplates.length - 1
    ) {
      this._deletePlaceZone(index);
    }
    this.setState({ editingPlaceZoneTemplateIndex: null });
  };


  render() {
    const { placeZoneTemplates, pickZoneTemplates, editingPlaceZoneTemplateIndex, editingPickZoneTemplateIndex } = this.state;
    const placeZoneTemplatesJsx = (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Template Name</TableCell>
              <TableCell align="right">Rows</TableCell>
              <TableCell align="right">Columns</TableCell>
              <TableCell align="right">Place Zone Width</TableCell>
              <TableCell align="right">Place Zone Depth</TableCell>
              <TableCell align="right">Place Pattern</TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {placeZoneTemplates.map((placeZoneTemplate, index) => {
              return index === editingPlaceZoneTemplateIndex ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <PlaceZoneTemplateEditor
                        placeZoneTemplate={
                          placeZoneTemplates[editingPlaceZoneTemplateIndex]
                        }
                        submit={modifiedTemplate => {
                          this._updatePlaceZone(
                            editingPlaceZoneTemplateIndex,
                            modifiedTemplate
                          );
                        }}
                        cancel={() => {this._cancelPlaceTemplateUpdate(editingPlaceZoneTemplateIndex)}}
                      />
                    </TableCell>
                  </TableRow>
              ) : (
                <PlaceZoneConfiguration
                  zoneConfig={placeZoneTemplate}
                  edit={() =>
                    this.setState({ editingPlaceZoneTemplateIndex: index })
                  }
                  delete={() => this._deletePlaceZone(index)}
                  canEdit={editingPlaceZoneTemplateIndex === null}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
    const pickZoneTemplatesJsx = (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Template Name</TableCell>
              <TableCell align="right">Ramp Length</TableCell>
              <TableCell align="right">Ramp Width</TableCell>
              <TableCell align="right">Ramp Walls</TableCell>
              <TableCell align="right">Lip To Tag Line</TableCell>
              <TableCell align="right">Lip To Bumpers</TableCell>
              <TableCell align="right"/>
              <TableCell align="right"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {pickZoneTemplates.map((pickZoneTemplate, index) => {
              return index === editingPickZoneTemplateIndex ? null : (
                <PickZoneConfiguration
                  zoneConfig={pickZoneTemplate}
                  edit={() =>
                    this.setState({ editingPickZoneTemplateIndex: index })
                  }
                  delete={() => this._deletePickZone(index)}
                  canEdit={editingPickZoneTemplateIndex === null}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );

    return (
      <div>
        <Container className={this.props.classes.buttonContainer}>
          <FileUploadButton
            label="Import From Environment Config"
            className={this.props.classes.button}
            disabled={!!this.state.dockMeasurementsCsv}
            color="primary"
            onload={(fileData) => this._parseEnvironmentConfig(fileData, true)}
          />
          <FileDownloadButton
            label="Download Dock Measurements CSV From Imported Environment Config"
            className={this.props.classes.button}
            disabled={!this.state.dockMeasurementsCsv}
            color="primary"
            filename="environment_config_measurements.csv"
            fileContents={this.state.dockMeasurementsCsv}
          />
          {this.state.dockMeasurementsCsv &&
          <Button onClick={() => this.setState({dockMeasurementsCsv: ""})}>
            Clear Imported Config
          </Button>}
        </Container>
        <Paper className={this.props.classes.pageSection}>
          <Typography className={this.props.classes.pageHeader}>Pick Zone Templates:</Typography>
          {pickZoneTemplatesJsx}
          {editingPickZoneTemplateIndex != null && (
            <PickZoneTemplateEditor
              pickZoneTemplate={
                pickZoneTemplates[editingPickZoneTemplateIndex]
              }
              submit={modifiedTemplate => {
                this._updatePickZone(
                  editingPickZoneTemplateIndex,
                  modifiedTemplate
                );
              }}
              cancel={() => {this._cancelPickTemplateUpdate(editingPickZoneTemplateIndex)}}
            />
          )}
          <Container className={this.props.classes.buttonContainer}>
            <Button
              disabled={editingPickZoneTemplateIndex != null}
              onClick={this._addPickZone}
              className={this.props.classes.button}
              variant="outlined"
              color="primary"
            >
              Add a pick zone template
            </Button>
            <FileUploadButton
              label="Import pick/place zone templates"
              className={this.props.classes.button}
              color="primary"
              disabled={editingPlaceZoneTemplateIndex != null}
              onload={(fileData) => this._parseEnvironmentConfig(fileData)}
            />
          </Container>
        </Paper>
        <Paper className={this.props.classes.pageSection}>
          <Typography className={this.props.classes.pageHeader}>Place Zone Templates:</Typography>
          {placeZoneTemplatesJsx}
          <Container className={this.props.classes.buttonContainer}>
            <Button
              disabled={editingPlaceZoneTemplateIndex != null}
              onClick={this._addPlaceZone}
              className={this.props.classes.button}
              variant="outlined"
              color="primary"
            >
              Add a place zone template
            </Button>
            <FileUploadButton
              label="Import pick/place zone templates"
              className={this.props.classes.button}
              color="primary"
              disabled={editingPlaceZoneTemplateIndex != null}
              onload={(fileData) => this._parseEnvironmentConfig(fileData)}
            />
          </Container>
        </Paper>
        <Paper className={this.props.classes.pageSection}>
          <DockConfigurations
            placeZoneTemplateNames={this.state.placeZoneTemplates.map(template =>
              template.getTemplateName()
            )}
            pickZoneTemplateNames={this.state.pickZoneTemplates.map(template =>
              template.getTemplateName()
            )}
            dockConfigs={this.state.docks}
            updateDockConfigs={dockConfigs => {
              this.setState({ docks: dockConfigs });
            }}
          />
        </Paper>
        <Paper className={this.props.classes.pageSection}>
          <Typography className={this.props.classes.pageHeader}>Global Parameters:</Typography>
          <FormControlLabel
            label="Use Tall Pallet Mode by Default?"
            control={
                <Checkbox
                    id="tall-pallets-input"
                    checked={this.state.tallPallets}
                    onChange={e =>
                      this.setState({ tallPallets: e.target.checked })
                    }
                />
              }
          />
        </Paper>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={this._generateEnvironmentConfig}
          >
            Generate Environment Config
          </Button>
          <Paper className={this.props.classes.environmentConfigBackground}>
            {this.state.generatedEnvironmentConfig && <Button
              variant="outlined"
              color="primary"
              onClick={() => this._copyTextToClipboard(this.state.generatedEnvironmentConfig)}
            >
              Copy Environment Config to clipboard
            </Button>}
            {this.state.isCopied && <Tooltip
            x={window.innerWidth / 2}
            y={window.innerHeight / 3}
            displayText={"Env config copied to clipboard"}
          />}
            <pre>{this.state.generatedEnvironmentConfig}</pre>
          </Paper>
        </div>
      </div>
    );
  }
}

export default connect()(withStyles(styles)(EnvironmentConfigPage));
