import { Action } from "redux";
import monitoring_pb from "../_proto/command_control/monitoring/proto/monitoring_pb";
import cc_pb from "../_proto/command_control/proto/command_control_pb";
import config_pb from "../_proto/robot/proto/config_pb";
import * as payloads from "./payloads";
import { ServiceError } from "../_proto/command_control/monitoring/proto/monitoring_pb_service";
import field_mask from "google-protobuf/google/protobuf/field_mask_pb";

// Use FluxStandardAction for compatibility with redux third party libs, which
// have consolidated around this shape.
export interface FluxStandardAction<T> extends Action<string> {
  // https://github.com/redux-utilities/flux-standard-action#type
  type: string;
  // https://github.com/redux-utilities/flux-standard-action#error
  payload: T | Error | null | undefined;
  // https://github.com/redux-utilities/flux-standard-action#error
  error?: boolean;
  // https://github.com/redux-utilities/flux-standard-action#meta
  meta?: any;
}

const APPLICATION_STARTED = "[Application] Started";

const RUN_METADATA_REQUEST = "[Run Metadata] Request";
const RUN_METADATA_RECEIVE = "[Run Metadata] Receive";
const RUN_METADATA_ERROR = "[Run Metadata] Error";
const UPSERT_RUN_REVIEW_REQUEST = "[Run Review/Upsert] Request";
const UPSERT_RUN_REVIEW_RECEIVE = "[Run Review/Upsert] Receive";
const UPSERT_RUN_REVIEW_ERROR = "[Run Review/Upsert] Error";
const LIST_PICK_STATS_REQUEST = "[Pick Stats] Request";
const LIST_PICK_STATS_DATASTORE_REQUEST = "[Datastore Pick Stats] Request";
const LIST_PICK_STATS_RECEIVE = "[Pick Stats] Receive";
const LIST_PICK_STATS_ERROR = "[Pick Stats] Error";
const LIST_ACCOUNTS_REQUEST = "[List Accounts] Request";
const LIST_ACCOUNTS_RECEIVE = "[List Accounts] Receive";
const LIST_ACCOUNTS_ERROR = "[List Accounts] Error";
const LIST_ROBOT_ACCOUNTS_REQUEST = "[List Robot Accounts] Request";
const LIST_ROBOT_ACCOUNTS_RECEIVE = "[List Robot Accounts] Receive";
const LIST_ROBOT_ACCOUNTS_ERROR = "[List Robot Accounts] Error";
const UPDATE_ROBOT_ACCOUNT_COHORT_REQUEST =
  "[Update Robot Account Cohort] Request";
const UPDATE_ROBOT_ACCOUNT_COHORT_RECEIVE =
  "[Update Robot Account Cohort] Receive";
const UPDATE_ROBOT_ACCOUNT_COHORT_ERROR = "[Update Robot Account Cohort] Error";
const UPDATE_ROBOT_ACCOUNT_IP_REQUEST = "[Update Robot Account IP] Request";
const UPDATE_ROBOT_ACCOUNT_IP_RECEIVE = "[Update Robot Account IP] Receive";
const UPDATE_ROBOT_ACCOUNT_IP_ERROR = "[Update Robot Account IP] Error";
const UPDATE_ROBOT_ACCOUNT_REQUEST = "[Update Robot Account] Request";
const UPDATE_ROBOT_ACCOUNT_RECEIVE = "[Update Robot Account] Receive";
const UPDATE_ROBOT_ACCOUNT_ERROR = "[Update Robot Account] Error";
const LIST_RUN_STATS_REQUEST = "[Run Stats] Request";
const LIST_RUN_STATS_RECEIVE = "[Run Stats] Receive";
const LIST_RUN_STATS_ERROR = "[Run Stats] Error";
const LIST_STOPPAGES_REQUEST = "[Stoppages] Request";
const LIST_STOPPAGES_RECEIVE = "[Stoppages] Receive";
const LIST_STOPPAGES_ERROR = "[Stoppages] Error";
const LIST_INTERVENTIONS_REQUEST = "[INTERVENTIONS] Request";
const LIST_INTERVENTIONS_RECEIVE = "[INTERVENTIONS] Receive";
const LIST_INTERVENTIONS_ERROR = "[INTERVENTIONS] Error";
const RUN_PLACED_PALLETS_REQUEST = "[Run Placed Pallets] Request";
const RUN_PLACED_PALLETS_RECEIVE = "[Run Placed Pallets] Receive";
const RUN_PLACED_PALLETS_ERROR = "[Run Placed Pallets] Error";
const ANNOTATION_CREATE_REQUEST = "[Annotation/Create] Request";
const ANNOTATION_CREATE_RECEIVE = "[Annotation/Create] Receive";
const ANNOTATION_CREATE_ERROR = "[Annotation/Create] Error";
const ANNOTATION_UPDATE_REQUEST = "[Annotation/Update] Request";
const ANNOTATION_UPDATE_RECEIVE = "[Annotation/Update] Receive";
const ANNOTATION_UPDATE_ERROR = "[Annotation/Update] Error";
const ANNOTATION_DELETE_REQUEST = "[Annotation/Delete] Request";
const ANNOTATION_DELETE_RECEIVE = "[Annotation/Delete] Receive";
const ANNOTATION_DELETE_ERROR = "[Annotation/Delete] Error";
const EXCLUDE_INTERVENTION_REQUEST =
  "[Run Placed Pallets/Exclude Intervention] Request";
const EXCLUDE_INTERVENTION_RECEIVE =
  "[Run Placed Pallets/Exclude Intervention] Receive";
const EXCLUDE_INTERVENTION_ERROR =
  "[Run Placed Pallets/Exclude Intervention] Error";
const LIST_RUN_METADATA_REQUEST = "[List Run Metadata] Request";
const LIST_RUN_METADATA_RECEIVE = "[List Run Metadata] Receive";
const LIST_RUN_METADATA_ERROR = "[List Run Metadata] Error";
const LIST_GOAL_METADATA_REQUEST = "[List Goal Metadata] Request";
const LIST_GOAL_METADATA_RECEIVE = "[List Goal Metadata] Receive";
const LIST_GOAL_METADATA_ERROR = "[List Goal Metadata] Error";
const LIST_EXTERNAL_BUGS_REQUEST = "[List External Bugs] Request";
const LIST_EXTERNAL_BUGS_RECEIVE = "[List External Bugs] Receive";
const LIST_EXTERNAL_BUGS_ERROR = "[List External Bugs] Error";
const LIST_FORKLIFT_COHORTS_REQUEST = "[List Forklift Cohorts] Request";
const LIST_FORKLIFT_COHORTS_RECEIVE = "[List Forklift Cohorts] Receive";
const LIST_FORKLIFT_COHORTS_ERROR = "[List Forklift Cohorts] Error";
const CREATE_FORKLIFT_COHORT_REQUEST = "[Create Forklift Cohort] Request";
const CREATE_FORKLIFT_COHORT_RECEIVE = "[Create Forklift Cohort] Receive";
const CREATE_FORKLIFT_COHORT_ERROR = "[Create Forklift Cohort] Error";
const UPDATE_FORKLIFT_COHORT_REQUEST = "[Update Forklift Cohort] Request";
const UPDATE_FORKLIFT_COHORT_RECEIVE = "[Update Forklift Cohort] Receive";
const GET_FORKLIFT_COHORT_REQUEST = "[Get Forklift Cohort] Request";
const GET_FORKLIFT_COHORT_RECEIVE = "[Get Forklift Cohort] Receive";
const GET_FORKLIFT_COHORT_ERROR = "[Get Forklift Cohort] Error";
const RUN_ATTRIBUTES_UPDATE_REQUEST = "[RunAttributes/Update] Request";
const RUN_ATTRIBUTES_UPDATE_RECEIVE = "[RunAttributes/Update] Receive";
const RUN_ATTRIBUTES_UPDATE_ERROR = "[RunAttributes/Update] Error";
const RUN_ATTRIBUTES_DELETE_REQUEST = "[RunAttributes/Delete] Request";
const RUN_ATTRIBUTES_DELETE_RECEIVE = "[RunAttributes/Delete] Receive";
const RUN_ATTRIBUTES_DELETE_ERROR = "[RunAttributes/Delete] Error";
const GET_TRELLO_CARD_REQUEST = "[Get Trello Card] Request";
const GET_TRELLO_CARD_RECEIVE = "[Get Trello Card] Receive";
const GET_TRELLO_CARD_ERROR = "[Get Trello Card] Error";

const GET_TIME_SERIES_REQUEST = "[Time Series] Request";
const GET_TIME_SERIES_RECEIVE = "[Time Series] Receive";
const GET_TIME_SERIES_ERROR = "[Time Series] Error";
const GET_SCALAR_METRIC_REQUEST = "[Scalar Metric] Request";
const GET_SCALAR_METRIC_RECEIVE = "[Scalar Metric] Receive";
const GET_SCALAR_METRIC_ERROR = "[Scalar Metric] Error";
const GET_FAULT_COUNT_TIME_SERIES_REQUEST = "[Fault Time Series] Request";
const GET_FAULT_COUNT_TIME_SERIES_RECEIVE = "[Fault Time Series] Receive";
const GET_FAULT_COUNT_TIME_SERIES_ERROR = "[Fault Time Series] Error";

const SIGN_UP_REQUEST = "[Sign Up] Request";
const SIGN_UP_RECEIVE = "[Sign Up] Receive";
const SIGN_UP_ERROR = "[Sign Up] Error";

const LOG_IN_REQUEST = "[Log In] Request";
const LOG_IN_RECEIVE = "[Log In] Receive";
const LOG_IN_ERROR = "[Log In] Error";

const LOG_OUT_REQUEST = "[Log Out] Request";
const LOG_OUT_RECEIVE = "[Log Out] Receive";

const VIEWER_REQUEST = "[Viewer] Request";
const VIEWER_RECEIVE = "[Viewer] Receive";

const CREATE_CONFIGURATION_FILE_REVISIONS_REQUEST =
  "[Create Configuration File Revision] Request";
const CREATE_CONFIGURATION_FILE_REVISIONS_RECEIVE =
  "[Create Configuration File Revision] Receive";
const CREATE_CONFIGURATION_FILE_REVISIONS_ERROR =
  "[Create Configuration File Revision] Error";

const LIST_CONFIGURATION_REVISION_HISTORY_REQUEST =
  "[List Configuration Revision History] Request";
const LIST_CONFIGURATION_REVISION_HISTORY_RECEIVE =
  "[List Configuration Revision History] Receive";
const LIST_CONFIGURATION_REVISION_HISTORY_ERROR =
  "[List Configuration Revision History] Error";

const GET_CONFIGURATION_FILE_REVISION_REQUEST =
  "[Get Configuration File Revision] Request";
const GET_CONFIGURATION_FILE_REVISION_RECEIVE =
  "[Get Configuration File Revision] Receive";
const GET_CONFIGURATION_FILE_REVISION_ERROR =
  "[Get Configuration File Revision] Error";
const LIST_TRAILER_STATS_REQUEST = "[Trailer Stats] Request";
const LIST_TRAILER_STATS_RECEIVE = "[Trailer Stats] Receive";
const LIST_TRAILER_STATS_ERROR = "[Trailer Stats] Error";
const LIST_TRAILER_STATS_DATASTORE_REQUEST = "[Trailer Stats Datastore] Request";
const LIST_TRAILER_STATS_DATASTORE_RECEIVE = "[Trailer Stats Datastore] Receive";
const LIST_SYNC_DEMANDS = "[List Sync Demands] Request";
const LIST_SYNC_DEMANDS_RECEIVE = "[List Sync Demands] Receive";
const CREATE_SYNC_DEMAND = "[Create Sync Demand] Request";
const CREATE_SYNC_DEMAND_RECEIVE = "[Create Sync Demand] Receive";
const LIST_FAULTS_REQUEST = "[List Faults] Request";
const LIST_FAULTS_RECEIVE = "[List Faults] Receive";
const GENERATE_ENVIRONMENT_CONFIG_REQUEST =
  "[Generate Environment Config] Request";
const GENERATE_ENVIRONMENT_CONFIG_RECEIVE =
  "[Generate Environment Config] Receive";
const GENERATE_ENVIRONMENT_CONFIG_ERROR = "[Generate Environment Config] Error";
const PARSE_ENVIRONMENT_CONFIG_REQUEST = "[Parse Environment Config] Request";
const PARSE_ENVIRONMENT_CONFIG_RECEIVE = "[Parse Environment Config] Receive";
const PARSE_ENVIRONMENT_CONFIG_ERROR = "[Parse Environment Config] Error";
const LIST_FAULT_CODES_REQUEST = "[List Fault Codes] Request";
const LIST_FAULT_CODES_RECEIVE = "[List Faults Codes] Receive";
const LIST_ORGANIZATIONS_REQUEST = "[List Organizations] Request";
const LIST_ORGANIZATIONS_RECEIVE = "[List Organizations] Receive";
const LIST_SITES_REQUEST = "[List Sites] Request";
const LIST_SITES_RECEIVE = "[List Sites] Receive";

const LIST_AUTONOMY_VERSIONS_REQUEST = "[List Autonomy Versions] Request";
const LIST_AUTONOMY_VERSIONS_RECEIVE = "[List Autonomy Versions] Receive";
const CREATE_AUTONOMY_VERSION_REQUEST = "[Create Autonomy Version] Request";
const CREATE_AUTONOMY_VERSION_RECEIVE = "[Create Autonomy Version] Receive";


const CREATE_SITE_REQUEST = "[Create Site] Request";
const CREATE_SITE_RECEIVE = "[Create Site] Receive";
const CREATE_SITE_ERROR = "[Create Site] Error";

const GET_APEX_STATS_REQUEST = "[Get Apex Stats] Request";
const GET_APEX_STATS_RECEIVE = "[Get Apex Stats] Receive";

const CREATE_BULK_LOG_UPLOAD = "[Create Bulk Log Upload] Request";
const CREATE_BULK_LOG_UPLOAD_RECEIVE = "[Create Bulk Log Upload] Receive";
const LIST_BULK_LOG_UPLOADS_REQUEST = "[List Bulk Log Uploads] Request";
const LIST_BULK_LOG_UPLOADS_RECEIVE = "[List Bulk Log Uploads] Receive";

const LIST_FAULT_RATE_ALERT_THRESHOLDS_REQUEST = "[List Fault Rate Alert Thresholds] Request";
const LIST_FAULT_RATE_ALERT_THRESHOLDS_RECEIVE = "[List Fault Rate Alert Thresholds] Receive";
const UPSERT_FAULT_RATE_ALERT_THRESHOLD_REQUEST = "[Upsert Fault Rate Alert Threshold] Request";
const UPSERT_FAULT_RATE_ALERT_THRESHOLD_RECEIVE = "[Upsert Fault Rate Alert Threshold] Receive";

const UPLOAD_FAULT_TRANSLATIONS_REQUEST = "[Upload Fault Translations] Request";
const UPLOAD_FAULT_TRANSLATIONS_RECEIVE = "[Upload Fault Translations] Receive";
const UPLOAD_FAULT_TRANSLATIONS_ERROR = "[Upload Fault Translations] Error";

const PUBLISH_FAULT_TRANSLATIONS_REQUEST = "[Publish Fault Translations] Request";
const PUBLISH_FAULT_TRANSLATIONS_RECEIVE = "[Publish Fault Translations] Receive";
const PUBLISH_FAULT_TRANSLATIONS_ERROR = "[Publish Fault Translations] Error";

const LIST_FAULT_TRANSLATIONS_REQUEST = "[List Fault Translations] Request";
const LIST_FAULT_TRANSLATIONS_RECEIVE = "[List Fault Translations] Receive";
const LIST_FAULT_TRANSLATIONS_ERROR = "[List Fault Translations] Error";

const CREATE_OPERATOR_QUESTION_REQUEST = "CREATE_OPERATOR_QUESTION_REQUEST";
const CREATE_OPERATOR_QUESTION_RECEIVE = "CREATE_OPERATOR_QUESTION_RECEIVE";
const CREATE_OPERATOR_QUESTION_ERROR = "CREATE_OPERATOR_QUESTION_ERROR";

const UPSERT_OPERATOR_QUESTION_TRANSLATION_REQUEST = "UPSERT_OPERATOR_QUESTION_TRANSLATION_REQUEST";
const UPSERT_OPERATOR_QUESTION_TRANSLATION_RECEIVE = "UPSERT_OPERATOR_QUESTION_TRANSLATION_RECEIVE";
const UPSERT_OPERATOR_QUESTION_TRANSLATION_ERROR = "UPSERT_OPERATOR_QUESTION_TRANSLATION_ERROR";

const CREATE_OPERATOR_ANSWER_CHOICE_REQUEST = "CREATE_OPERATOR_ANSWER_CHOICE_REQUEST";
const CREATE_OPERATOR_ANSWER_CHOICE_RECEIVE = "CREATE_OPERATOR_ANSWER_CHOICE_RECEIVE";
const CREATE_OPERATOR_ANSWER_CHOICE_ERROR = "CREATE_OPERATOR_ANSWER_CHOICE_ERROR";

const UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_REQUEST = "UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_REQUEST";
const UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_RECEIVE = "UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_RECEIVE";
const UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_ERROR = "UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_ERROR";

const LIST_OPERATOR_QUESTIONS_REQUEST = "LIST_OPERATOR_QUESTIONS_REQUEST";
const LIST_OPERATOR_QUESTIONS_RECEIVE = "LIST_OPERATOR_QUESTIONS_RECEIVE";
const LIST_OPERATOR_QUESTIONS_ERROR = "LIST_OPERATOR_QUESTIONS_ERROR";

const LIST_OPERATOR_ANSWER_CHOICES_REQUEST = "LIST_OPERATOR_ANSWER_CHOICES_REQUEST";
const LIST_OPERATOR_ANSWER_CHOICES_RECEIVE = "LIST_OPERATOR_ANSWER_CHOICES_RECEIVE";
const LIST_OPERATOR_ANSWER_CHOICES_ERROR = "LIST_OPERATOR_ANSWER_CHOICES_ERROR";

const UPDATE_FAULT_CODE_QUESTIONS_REQUEST = "UPDATE_FAULT_CODE_QUESTIONS_REQUEST";
const UPDATE_FAULT_CODE_QUESTIONS_RECEIVE = "UPDATE_FAULT_CODE_QUESTIONS_RECEIVE";
const UPDATE_FAULT_CODE_QUESTIONS_ERROR = "UPDATE_FAULT_CODE_QUESTIONS_ERROR";


export const ActionTypes = {
  APPLICATION_STARTED,

  RUN_METADATA_REQUEST,
  RUN_METADATA_RECEIVE,
  RUN_METADATA_ERROR,

  UPSERT_RUN_REVIEW_REQUEST,
  UPSERT_RUN_REVIEW_RECEIVE,
  UPSERT_RUN_REVIEW_ERROR,

  LIST_PICK_STATS_REQUEST,
  LIST_PICK_STATS_DATASTORE_REQUEST,
  LIST_PICK_STATS_RECEIVE,
  LIST_PICK_STATS_ERROR,

  LIST_ACCOUNTS_REQUEST,
  LIST_ACCOUNTS_RECEIVE,
  LIST_ACCOUNTS_ERROR,

  LIST_RUN_STATS_REQUEST,
  LIST_RUN_STATS_RECEIVE,
  LIST_RUN_STATS_ERROR,

  LIST_STOPPAGES_REQUEST: LIST_STOPPAGES_REQUEST,
  LIST_STOPPAGES_RECEIVE: LIST_STOPPAGES_RECEIVE,
  LIST_STOPPAGES_ERROR: LIST_STOPPAGES_ERROR,

  LIST_INTERVENTIONS_REQUEST,
  LIST_INTERVENTIONS_RECEIVE,
  LIST_INTERVENTIONS_ERROR,

  RUN_PLACED_PALLETS_REQUEST,
  RUN_PLACED_PALLETS_RECEIVE,
  RUN_PLACED_PALLETS_ERROR,

  ANNOTATION_CREATE_REQUEST,
  ANNOTATION_CREATE_RECEIVE,
  ANNOTATION_CREATE_ERROR,
  ANNOTATION_UPDATE_REQUEST,
  ANNOTATION_UPDATE_RECEIVE,
  ANNOTATION_UPDATE_ERROR,
  ANNOTATION_DELETE_REQUEST,
  ANNOTATION_DELETE_RECEIVE,
  ANNOTATION_DELETE_ERROR,

  EXCLUDE_INTERVENTION_REQUEST,
  EXCLUDE_INTERVENTION_RECEIVE,
  EXCLUDE_INTERVENTION_ERROR,

  LIST_RUN_METADATA_REQUEST,
  LIST_RUN_METADATA_RECEIVE,
  LIST_RUN_METADATA_ERROR,

  LIST_GOAL_METADATA_REQUEST,
  LIST_GOAL_METADATA_RECEIVE,
  LIST_GOAL_METADATA_ERROR,

  LIST_EXTERNAL_BUGS_REQUEST,
  LIST_EXTERNAL_BUGS_RECEIVE,
  LIST_EXTERNAL_BUGS_ERROR,

  GET_TRELLO_CARD_REQUEST,
  GET_TRELLO_CARD_RECEIVE,
  GET_TRELLO_CARD_ERROR,

  CREATE_FORKLIFT_COHORT_REQUEST,
  CREATE_FORKLIFT_COHORT_RECEIVE,
  CREATE_FORKLIFT_COHORT_ERROR,
  UPDATE_FORKLIFT_COHORT_REQUEST,
  UPDATE_FORKLIFT_COHORT_RECEIVE,

  LIST_ROBOT_ACCOUNTS_REQUEST,
  LIST_ROBOT_ACCOUNTS_RECEIVE,
  LIST_ROBOT_ACCOUNTS_ERROR,

  UPDATE_ROBOT_ACCOUNT_COHORT_REQUEST,
  UPDATE_ROBOT_ACCOUNT_COHORT_RECEIVE,
  UPDATE_ROBOT_ACCOUNT_COHORT_ERROR,

  UPDATE_ROBOT_ACCOUNT_IP_REQUEST,
  UPDATE_ROBOT_ACCOUNT_IP_RECEIVE,
  UPDATE_ROBOT_ACCOUNT_IP_ERROR,

  UPDATE_ROBOT_ACCOUNT_REQUEST,
  UPDATE_ROBOT_ACCOUNT_RECEIVE,
  UPDATE_ROBOT_ACCOUNT_ERROR,

  GET_FORKLIFT_COHORT_REQUEST,
  GET_FORKLIFT_COHORT_RECEIVE,
  GET_FORKLIFT_COHORT_ERROR,

  LIST_FORKLIFT_COHORTS_REQUEST,
  LIST_FORKLIFT_COHORTS_RECEIVE,
  LIST_FORKLIFT_COHORTS_ERROR,

  RUN_ATTRIBUTES_UPDATE_REQUEST,
  RUN_ATTRIBUTES_UPDATE_RECEIVE,
  RUN_ATTRIBUTES_UPDATE_ERROR,
  RUN_ATTRIBUTES_DELETE_REQUEST,
  RUN_ATTRIBUTES_DELETE_RECEIVE,
  RUN_ATTRIBUTES_DELETE_ERROR,

  GET_TIME_SERIES_REQUEST,
  GET_TIME_SERIES_RECEIVE,
  GET_TIME_SERIES_ERROR,

  GET_SCALAR_METRIC_REQUEST,
  GET_SCALAR_METRIC_RECEIVE,
  GET_SCALAR_METRIC_ERROR,

  GET_FAULT_COUNT_TIME_SERIES_REQUEST,
  GET_FAULT_COUNT_TIME_SERIES_RECEIVE,
  GET_FAULT_COUNT_TIME_SERIES_ERROR,

  SIGN_UP_REQUEST,
  SIGN_UP_RECEIVE,
  SIGN_UP_ERROR,

  LOG_IN_REQUEST,
  LOG_IN_RECEIVE,
  LOG_IN_ERROR,

  LOG_OUT_REQUEST,
  LOG_OUT_RECEIVE,

  VIEWER_REQUEST,
  VIEWER_RECEIVE,

  CREATE_CONFIGURATION_FILE_REVISIONS_REQUEST,
  CREATE_CONFIGURATION_FILE_REVISIONS_RECEIVE,
  CREATE_CONFIGURATION_FILE_REVISIONS_ERROR,

  LIST_CONFIGURATION_REVISION_HISTORY_REQUEST,
  LIST_CONFIGURATION_REVISION_HISTORY_RECEIVE,
  LIST_CONFIGURATION_REVISION_HISTORY_ERROR,

  GET_CONFIGURATION_FILE_REVISION_REQUEST,
  GET_CONFIGURATION_FILE_REVISION_RECEIVE,
  GET_CONFIGURATION_FILE_REVISION_ERROR,

  LIST_TRAILER_STATS_REQUEST,
  LIST_TRAILER_STATS_RECEIVE,
  LIST_TRAILER_STATS_ERROR,
  LIST_TRAILER_STATS_DATASTORE_REQUEST,
  LIST_TRAILER_STATS_DATASTORE_RECEIVE,

  LIST_SYNC_DEMANDS,
  LIST_SYNC_DEMANDS_RECEIVE,
  CREATE_SYNC_DEMAND,
  CREATE_SYNC_DEMAND_RECEIVE,

  LIST_FAULTS_REQUEST,
  LIST_FAULTS_RECEIVE,

  GENERATE_ENVIRONMENT_CONFIG_REQUEST,
  GENERATE_ENVIRONMENT_CONFIG_RECEIVE,
  GENERATE_ENVIRONMENT_CONFIG_ERROR,

  PARSE_ENVIRONMENT_CONFIG_REQUEST,
  PARSE_ENVIRONMENT_CONFIG_RECEIVE,
  PARSE_ENVIRONMENT_CONFIG_ERROR,

  LIST_FAULT_CODES_REQUEST,
  LIST_FAULT_CODES_RECEIVE,

  LIST_ORGANIZATIONS_REQUEST,
  LIST_ORGANIZATIONS_RECEIVE,

  LIST_SITES_REQUEST,
  LIST_SITES_RECEIVE,

  LIST_AUTONOMY_VERSIONS_REQUEST,
  LIST_AUTONOMY_VERSIONS_RECEIVE,
  CREATE_AUTONOMY_VERSION_REQUEST,
  CREATE_AUTONOMY_VERSION_RECEIVE,

  CREATE_SITE_REQUEST,
  CREATE_SITE_RECEIVE,
  CREATE_SITE_ERROR,

  GET_APEX_STATS_REQUEST,
  GET_APEX_STATS_RECEIVE,

  CREATE_BULK_LOG_UPLOAD,
  CREATE_BULK_LOG_UPLOAD_RECEIVE,
  LIST_BULK_LOG_UPLOADS_REQUEST,
  LIST_BULK_LOG_UPLOADS_RECEIVE,

  LIST_FAULT_RATE_ALERT_THRESHOLDS_REQUEST,
  LIST_FAULT_RATE_ALERT_THRESHOLDS_RECEIVE,
  UPSERT_FAULT_RATE_ALERT_THRESHOLD_REQUEST,
  UPSERT_FAULT_RATE_ALERT_THRESHOLD_RECEIVE,

  UPLOAD_FAULT_TRANSLATIONS_REQUEST,
  UPLOAD_FAULT_TRANSLATIONS_RECEIVE,
  UPLOAD_FAULT_TRANSLATIONS_ERROR,
  PUBLISH_FAULT_TRANSLATIONS_REQUEST,
  PUBLISH_FAULT_TRANSLATIONS_RECEIVE,
  PUBLISH_FAULT_TRANSLATIONS_ERROR,
  LIST_FAULT_TRANSLATIONS_REQUEST,
  LIST_FAULT_TRANSLATIONS_RECEIVE,
  LIST_FAULT_TRANSLATIONS_ERROR,

  CREATE_OPERATOR_QUESTION_REQUEST,
  CREATE_OPERATOR_QUESTION_RECEIVE,
  CREATE_OPERATOR_QUESTION_ERROR,
  UPSERT_OPERATOR_QUESTION_TRANSLATION_REQUEST,
  UPSERT_OPERATOR_QUESTION_TRANSLATION_RECEIVE,
  UPSERT_OPERATOR_QUESTION_TRANSLATION_ERROR,
  CREATE_OPERATOR_ANSWER_CHOICE_REQUEST,
  CREATE_OPERATOR_ANSWER_CHOICE_RECEIVE,
  CREATE_OPERATOR_ANSWER_CHOICE_ERROR,
  UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_REQUEST,
  UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_RECEIVE,
  UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_ERROR,
  LIST_OPERATOR_QUESTIONS_REQUEST,
  LIST_OPERATOR_QUESTIONS_RECEIVE,
  LIST_OPERATOR_QUESTIONS_ERROR,
  LIST_OPERATOR_ANSWER_CHOICES_REQUEST,
  LIST_OPERATOR_ANSWER_CHOICES_RECEIVE,
  LIST_OPERATOR_ANSWER_CHOICES_ERROR,
  UPDATE_FAULT_CODE_QUESTIONS_REQUEST,
  UPDATE_FAULT_CODE_QUESTIONS_RECEIVE,
  UPDATE_FAULT_CODE_QUESTIONS_ERROR,
};

type ReduxSagaThunkMeta = {
  thunk: boolean;
  [x: string]: any;
};

function makeThunk(action: FluxStandardAction<any>) {
  return {
    meta: {
      thunk: true
    },
    ...action
  };
}

interface Pagination {
  pageSize: number;
  pageToken: number;
}

// Run Metadata action creators and typedefs
export function listRunMetadasRequest(
  pagination: Pagination,
  runFilter: monitoring_pb.EventFilter
) {
  return makeThunk({
    type: ActionTypes.LIST_RUN_METADATA_REQUEST,
    payload: { pagination, runFilter }
  });
}
export function listRunMetadasReceive(
  data: monitoring_pb.ListRunMetadatasResponse,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_RUN_METADATA_RECEIVE,
    meta,
    payload: {
      metadatas: data
        .getMetadatasList()
        .map(r => payloads.RunMetadata.fromProto(r)),
      count: data.getCount()
    }
  };
}
export function listRunMetadatasError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_RUN_METADATA_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ListRunMetadatasRequestTypeDef = ReturnType<
  typeof listRunMetadasRequest
>;
export type ListRunMetadatasReceiveTypeDef = ReturnType<
  typeof listRunMetadasReceive
>;
export function getRunMetadataRequest(runName: string) {
  return makeThunk({
    type: ActionTypes.RUN_METADATA_REQUEST,
    payload: { runName }
  });
}
export function getRunMetadaReceive(
  data: monitoring_pb.RunMetadata,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.RUN_METADATA_RECEIVE,
    meta,
    payload: {
      run: payloads.RunMetadata.fromProto(data)
    }
  };
}
export function getRunMetadataError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.RUN_METADATA_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type GetRunMetadatasRequestTypeDef = ReturnType<
  typeof getRunMetadataRequest
>;
export type GetRunMetadatasReceiveTypeDef = ReturnType<
  typeof getRunMetadaReceive
>;

export function listGoalMetadasRequest(
  pagination: Pagination,
  goalIds: Array<string>
) {
  return makeThunk({
    type: ActionTypes.LIST_GOAL_METADATA_REQUEST,
    payload: { pagination, goalIds }
  });
}
export function listGoalMetadasReceive(
  data: monitoring_pb.ListGoalMetadatasResponse,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_GOAL_METADATA_RECEIVE,
    meta,
    payload: {
      metadatas: data.getMetadatasList().map(r => r.toObject()),
      count: data.getCount()
    }
  };
}
export function listGoalMetadatasError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_GOAL_METADATA_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ListGoalMetadatasRequestTypeDef = ReturnType<
  typeof listGoalMetadasRequest
>;
export type ListGoalMetadatasReceiveTypeDef = ReturnType<
  typeof listGoalMetadasReceive
>;

export function listExternalBugsRequest(
  pagination: Pagination,
  runFilter: monitoring_pb.EventFilter
) {
  return makeThunk({
    type: ActionTypes.LIST_EXTERNAL_BUGS_REQUEST,
    payload: { pagination, runFilter }
  });
}
export function listExternalBugsReceive(
  data: monitoring_pb.ListExternalBugsResponse,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_EXTERNAL_BUGS_RECEIVE,
    meta,
    payload: {
      metadatas: data.getExternalBugsList().map(b => b.toObject()),
      count: data.getCount()
    }
  };
}
export function listExternalBugsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_EXTERNAL_BUGS_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ListExternalBugsRequestTypeDef = ReturnType<
  typeof listExternalBugsRequest
>;
export type ListExternalBugsReceiveTypeDef = ReturnType<
  typeof listExternalBugsReceive
>;

export function getTrelloCardRequest(cardId: string) {
  return makeThunk({
    type: ActionTypes.GET_TRELLO_CARD_REQUEST,
    payload: { cardId }
  });
}
export function getTrelloCardReceive(card: any, meta: ReduxSagaThunkMeta) {
  return {
    type: ActionTypes.GET_TRELLO_CARD_RECEIVE,
    meta,
    payload: {
      card
    }
  };
}
export function getTrelloCardError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_TRELLO_CARD_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type GetTrelloCardRequestTypeDef = ReturnType<
  typeof getTrelloCardRequest
>;
export type GetTrelloCardReceiveTypeDef = ReturnType<
  typeof getTrelloCardReceive
>;

export function listForkliftCohortsRequest(pagination: Pagination) {
  return makeThunk({
    type: ActionTypes.LIST_FORKLIFT_COHORTS_REQUEST,
    payload: { pagination }
  });
}
export function listForkliftCohortsReceive(
  response: monitoring_pb.ListForkliftCohortsResponse,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_FORKLIFT_COHORTS_RECEIVE,
    meta,
    payload: {
      response
    }
  };
}
export function listForkliftCohortsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_FORKLIFT_COHORTS_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ListForkliftCohortsRequestTypeDef = ReturnType<
  typeof listForkliftCohortsRequest
>;
export type ListForkliftCohortsReceiveTypeDef = ReturnType<
  typeof listForkliftCohortsReceive
>;

export function createForkliftCohortRequest(displayName: string, organizationName: string, siteId: string, locationCode: string = "") {
  return makeThunk({
    type: ActionTypes.CREATE_FORKLIFT_COHORT_REQUEST,
    payload: { displayName, organizationName, siteId, locationCode }
  });
}
export function createForkliftCohortReceive(
  response: monitoring_pb.ForkliftCohort,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_FORKLIFT_COHORT_RECEIVE,
    meta,
    payload: {
      response
    }
  };
}
export function createForkliftCohortError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_FORKLIFT_COHORT_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type CreateForkliftCohortRequestTypeDef = ReturnType<
  typeof createForkliftCohortRequest
>;
export type CreateForkliftCohortReceiveTypeDef = ReturnType<
  typeof createForkliftCohortReceive
>;

export function updateForkliftCohortRequest(displayName: string, organizationName: string, siteId: string, locationCode: string) {
  return makeThunk({
    type: ActionTypes.UPDATE_FORKLIFT_COHORT_REQUEST,
    payload: { displayName, organizationName, siteId, locationCode }
  });
}
export function updateForkliftCohortReceive(
  response: monitoring_pb.ForkliftCohort,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPDATE_FORKLIFT_COHORT_RECEIVE,
    meta,
    payload: {
      response
    }
  };
}
export type UpdateForkliftCohortRequestTypeDef = ReturnType<
  typeof updateForkliftCohortRequest
>;
export type UpdateForkliftCohortReceiveTypeDef = ReturnType<
  typeof updateForkliftCohortReceive
>;

export function createSiteRequest(customer: string, subCustomer: string, city: string, state: string, postalCode: string, streetAddress: string, nearestAirportCode: string) {
  return makeThunk({
    type: ActionTypes.CREATE_SITE_REQUEST,
    payload: { customer, subCustomer, city, state, postalCode, streetAddress, nearestAirportCode }
  });
}
export function createSiteReceive(
  response: monitoring_pb.Site,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_SITE_RECEIVE,
    meta,
    payload: {
      response
    }
  };
}
export function createSiteError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_SITE_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type CreateSiteRequestTypeDef = ReturnType<
  typeof createSiteRequest
>;
export type CreateSiteReceiveTypeDef = ReturnType<
  typeof createSiteReceive
>;

export function updateRobotAccountIpRequest(
  robotId: string,
  ipAddress: string
) {
  return makeThunk({
    type: ActionTypes.UPDATE_ROBOT_ACCOUNT_IP_REQUEST,
    payload: { robotId, ipAddress }
  });
}
export function updateRobotAccountIpReceive(
  response: monitoring_pb.RobotAccount,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPDATE_ROBOT_ACCOUNT_IP_RECEIVE,
    meta,
    payload: {
      response
    }
  };
}
export function updateRobotAccountIpError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPDATE_ROBOT_ACCOUNT_IP_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type UpdateRobotAccountIpRequestTypeDef = ReturnType<
  typeof updateRobotAccountIpRequest
>;
export type UpdateRobotAccountIpReceiveTypeDef = ReturnType<
  typeof updateRobotAccountIpReceive
>;

export function updateRobotAccountRequest(
  robotAccount: payloads.RobotAccount
) {
  return makeThunk({
    type: ActionTypes.UPDATE_ROBOT_ACCOUNT_REQUEST,
    payload: { robotAccount }
  });
}
export function updateRobotAccountReceive(
  response: monitoring_pb.RobotAccount,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPDATE_ROBOT_ACCOUNT_RECEIVE,
    meta,
    payload: {
      response
    }
  };
}
export function updateRobotAccountError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPDATE_ROBOT_ACCOUNT_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type UpdateRobotAccountRequestTypeDef = ReturnType<
  typeof updateRobotAccountRequest
>;
export type UpdateRobotAccountReceiveTypeDef = ReturnType<
  typeof updateRobotAccountReceive
>;

export function updateRobotAccountCohortRequest(
  robotId: string,
  cohortId: string
) {
  return makeThunk({
    type: ActionTypes.UPDATE_ROBOT_ACCOUNT_COHORT_REQUEST,
    payload: { robotId, cohortId }
  });
}
export function updateRobotAccountCohortReceive(
  response: monitoring_pb.RobotAccount,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPDATE_ROBOT_ACCOUNT_COHORT_RECEIVE,
    meta,
    payload: {
      response
    }
  };
}
export function updateRobotAccountCohortError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPDATE_ROBOT_ACCOUNT_COHORT_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type UpdateRobotAccountCohortRequestTypeDef = ReturnType<
  typeof updateRobotAccountCohortRequest
>;
export type UpdateRobotAccountCohortReceiveTypeDef = ReturnType<
  typeof updateRobotAccountCohortReceive
>;

export function getForkliftCohortRequest(cohortId: string) {
  return makeThunk({
    type: ActionTypes.GET_FORKLIFT_COHORT_REQUEST,
    payload: { cohortId }
  });
}
export function getForkliftCohortReceive(
  response: monitoring_pb.ForkliftCohort,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_FORKLIFT_COHORT_RECEIVE,
    meta,
    payload: {
      response
    }
  };
}
export function getForkliftCohortError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_FORKLIFT_COHORT_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type GetForkliftCohortRequestTypeDef = ReturnType<
  typeof getForkliftCohortRequest
>;
export type GetForkliftCohortReceiveTypeDef = ReturnType<
  typeof getForkliftCohortReceive
>;

export function getRunPlacedPalletsRequest(runName: string) {
  return makeThunk({
    type: ActionTypes.RUN_PLACED_PALLETS_REQUEST,
    payload: { runName }
  });
}
export function getRunPlacedPalletsReceive(
  data: monitoring_pb.RunPlacedPallets,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.RUN_PLACED_PALLETS_RECEIVE,
    meta,
    payload: data.toObject()
  };
}
export function getRunPlacedPalletsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.RUN_PLACED_PALLETS_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type GetRunPlacedPalletsRequestTypeDef = ReturnType<
  typeof getRunPlacedPalletsRequest
>;
export type GetRunPlacedPalletsReceiveTypeDef = ReturnType<
  typeof getRunPlacedPalletsReceive
>;

export function listPickStatsDatastoreRequest(filter: monitoring_pb.EventFilter, nextPageToken: string = "") {
    return makeThunk({
      type: ActionTypes.LIST_PICK_STATS_DATASTORE_REQUEST,
      payload: {
        filter,
        nextPageToken,
      }
    });
}

export function listPickStatsRequest(
  filter: monitoring_pb.EventFilter,
  nextPageToken: string,
  excludeTimingStats: boolean = true,
  excludePalletContext: boolean = false,
  excludeDriveStats: boolean = false,
  excludeManualModeStats: boolean = false,
  excludeHumanAssistanceRequests: boolean = false,
  excludeRunNotes: boolean = false,
  excludeStoppages: boolean = false,
  excludeInterventions: boolean = true,
  excludePathFollowerStatus: boolean = true,
  excludePlasticDetections: boolean = true,
) {
  return makeThunk({
    type: ActionTypes.LIST_PICK_STATS_REQUEST,
    payload: {
      filter,
      nextPageToken,
      excludeTimingStats,
      excludePalletContext,
      excludeDriveStats,
      excludeManualModeStats,
      excludeHumanAssistanceRequests,
      excludeRunNotes,
      excludeStoppages,
      excludeInterventions,
      excludePathFollowerStatus,
      excludePlasticDetections,
    }
  });
}
export function listPickStatsReceive(
  data: monitoring_pb.ListPickStatsResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_PICK_STATS_RECEIVE,
    meta,
    payload: data
  };
}
export function listPickStatsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_PICK_STATS_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ListPickStatsRequestTypeDef = ReturnType<
  typeof listPickStatsRequest
>;
export type ListPickStatsReceiveTypeDef = ReturnType<
  typeof listPickStatsReceive
>;

export function listAccountsRequest(accountIds: Array<string>) {
  return makeThunk({
    type: ActionTypes.LIST_ACCOUNTS_REQUEST,
    payload: { accountIds }
  });
}
export function listAccountsReceive(
  data: monitoring_pb.ListMonitoringAccountsResponse,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_ACCOUNTS_RECEIVE,
    meta,
    payload: { accounts: data.getAccountsList() }
  };
}
export function listAccountsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_ACCOUNTS_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ListAccountsRequestTypeDef = ReturnType<typeof listAccountsRequest>;
export type ListAccountsReceiveTypeDef = ReturnType<typeof listAccountsReceive>;

export function listRobotAccountsRequest(
  pageToken: number,
  pageSize: number = 5000
) {
  return makeThunk({
    type: ActionTypes.LIST_ROBOT_ACCOUNTS_REQUEST,
    payload: { pageToken, pageSize }
  });
}
export function listRobotAccountsReceive(
  data: monitoring_pb.ListRobotAccountsResponse,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_ROBOT_ACCOUNTS_RECEIVE,
    meta,
    payload: {
      accounts: data
        .getRobotAccountsList()
        .map(a => payloads.RobotAccount.fromProto(a)),
      count: data.getCount()
    }
  };
}
export function listRobotAccountsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_ROBOT_ACCOUNTS_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ListRobotAccountsRequestTypeDef = ReturnType<
  typeof listRobotAccountsRequest
>;
export type ListRobotAccountsReceiveTypeDef = ReturnType<
  typeof listRobotAccountsReceive
>;

export function upsertRunReviewRequest(runReview: monitoring_pb.RunReview) {
  return makeThunk({
    type: ActionTypes.UPSERT_RUN_REVIEW_REQUEST,
    payload: { runReview }
  });
}
export function upsertRunReviewReceive(
  run: monitoring_pb.RunMetadata,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPSERT_RUN_REVIEW_RECEIVE,
    meta,
    payload: { run }
  };
}
export function upsertRunReviewError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPSERT_RUN_REVIEW_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type UpsertRunReviewRequestTypeDef = ReturnType<
  typeof upsertRunReviewRequest
>;
export type UpsertRunReviewReceiveTypeDef = ReturnType<
  typeof upsertRunReviewReceive
>;

export function listRunStatsRequest(filter: monitoring_pb.EventFilter) {
  return makeThunk({
    type: ActionTypes.LIST_RUN_STATS_REQUEST,
    payload: { filter }
  });
}
export function listRunStatsReceive(
  data: monitoring_pb.ListRunStatsResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_RUN_STATS_RECEIVE,
    meta,
    payload: data
  };
}
export function listRunStatsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_RUN_STATS_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ListRunStatsRequestTypeDef = ReturnType<typeof listRunStatsRequest>;
export type ListRunStatsReceiveTypeDef = ReturnType<typeof listRunStatsReceive>;

export function listStoppagesRequest(
  filter: monitoring_pb.EventFilter,
  nextPageToken: string = ""
) {
  return makeThunk({
    type: ActionTypes.LIST_STOPPAGES_REQUEST,
    payload: { filter, nextPageToken }
  });
}
export function listStoppagesReceive(
  data: monitoring_pb.ListStoppagesResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_STOPPAGES_RECEIVE,
    meta,
    payload: data
  };
}
export function listStoppagesError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_STOPPAGES_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ListStoppagesRequestTypeDef = ReturnType<
  typeof listStoppagesRequest
>;
export type ListStoppagesReceiveTypeDef = ReturnType<
  typeof listStoppagesReceive
>;

export function listInterventionsRequest(
  filter: monitoring_pb.EventFilter,
  nextPageToken: string = ""
) {
  return makeThunk({
    type: ActionTypes.LIST_INTERVENTIONS_REQUEST,
    payload: { filter, nextPageToken }
  });
}
export function listInterventionsReceive(
  data: monitoring_pb.ListStoppagesResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_STOPPAGES_RECEIVE,
    meta,
    payload: data
  };
}
export function listInterventionsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_STOPPAGES_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ListInterventionsRequestTypeDef = ReturnType<
  typeof listInterventionsRequest
>;
export type ListInterventionsReceiveTypeDef = ReturnType<
  typeof listInterventionsReceive
>;

export function createAnnotationRequest(annotation: monitoring_pb.Annotation) {
  return makeThunk({
    type: ActionTypes.ANNOTATION_CREATE_REQUEST,
    payload: annotation
  });
}
export function createAnnotationReceive(
  annotation: monitoring_pb.Annotation,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.ANNOTATION_CREATE_RECEIVE,
    meta,
    payload: annotation
  };
}
export function createAnnotationError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.ANNOTATION_CREATE_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type CreateAnnotationRequestTypeDef = ReturnType<
  typeof createAnnotationRequest
>;

export function updateAnnotationRequest(
  annotation: monitoring_pb.Annotation,
  paths: Array<string>
) {
  const mask = new field_mask.FieldMask();
  mask.setPathsList(paths);
  return makeThunk({
    type: ActionTypes.ANNOTATION_UPDATE_REQUEST,
    payload: { annotation, mask }
  });
}
export function updateAnnotationReceive(
  annotation: monitoring_pb.Annotation,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.ANNOTATION_UPDATE_RECEIVE,
    meta,
    payload: annotation
  };
}
export function updateAnnotationError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.ANNOTATION_UPDATE_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type UpdateAnnotationRequestTypeDef = ReturnType<
  typeof updateAnnotationRequest
>;

export function deleteAnnotationRequest(annotationId: string) {
  return makeThunk({
    type: ActionTypes.ANNOTATION_DELETE_REQUEST,
    payload: annotationId
  });
}
export function deleteAnnotationReceive(meta: ReduxSagaThunkMeta) {
  return {
    type: ActionTypes.ANNOTATION_DELETE_RECEIVE,
    meta,
    payload: undefined
  };
}
export function deleteAnnotationError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.ANNOTATION_DELETE_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type DeleteAnnotationRequestTypeDef = ReturnType<
  typeof deleteAnnotationRequest
>;

export function updateRunAttributeRequest(
  runName: string,
  key: string,
  value: string
) {
  return makeThunk({
    type: ActionTypes.RUN_ATTRIBUTES_UPDATE_REQUEST,
    payload: { runName, key, value }
  });
}
export function updateRunAttributeReceive(
  run: monitoring_pb.RunMetadata,
  meta: ReduxSagaThunkMeta
) {
  return makeThunk({
    type: ActionTypes.RUN_ATTRIBUTES_UPDATE_RECEIVE,
    meta,
    payload: { run: payloads.RunMetadata.fromProto(run) }
  });
}
export function updateRunAttributeError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.RUN_ATTRIBUTES_UPDATE_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type UpdateRunAttributeRequestTypeDef = ReturnType<
  typeof updateRunAttributeRequest
>;
export type UpdateRunAttributeReceiveTypeDef = ReturnType<
  typeof updateRunAttributeReceive
>;
export function deleteRunAttributeRequest(runName: string, key: string) {
  return makeThunk({
    type: ActionTypes.RUN_ATTRIBUTES_DELETE_REQUEST,
    payload: { key, runName }
  });
}
export function deleteRunAttributeReceive(
  run: monitoring_pb.RunMetadata,
  meta: ReduxSagaThunkMeta
) {
  return makeThunk({
    type: ActionTypes.RUN_ATTRIBUTES_DELETE_RECEIVE,
    meta,
    payload: { run: payloads.RunMetadata.fromProto(run) }
  });
}
export function deleteRunAttributeError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.RUN_ATTRIBUTES_DELETE_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type DeleteRunAttributeRequestTypeDef = ReturnType<
  typeof deleteRunAttributeRequest
>;
export type DeleteRunAttributeReceiveTypeDef = ReturnType<
  typeof deleteRunAttributeReceive
>;

export function getTimeSeriesRequest(
  request?: monitoring_pb.GetTimeSeriesMetricRequest.AsObject
) {
  return makeThunk({
    type: ActionTypes.GET_TIME_SERIES_REQUEST,
    payload: { request }
  });
}
export function getTimeSeriesReceive(
  response: monitoring_pb.TimeSeriesMetric,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_TIME_SERIES_RECEIVE,
    meta,
    payload: {
      response
    }
  };
}
export function getTimeSeriesError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_TIME_SERIES_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type GetTimeSeriesRequestTypeDef = ReturnType<
  typeof getTimeSeriesRequest
>;
export type GetTimeSeriesReceiveTypeDef = ReturnType<
  typeof getTimeSeriesReceive
>;
export function getScalarMetricRequest(
  request?: monitoring_pb.GetScalarMetricRequest.AsObject
) {
  return makeThunk({
    type: ActionTypes.GET_SCALAR_METRIC_REQUEST,
    payload: { request }
  });
}
export function getScalarMetricReceive(
  response: monitoring_pb.ScalarMetric,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_SCALAR_METRIC_RECEIVE,
    meta,
    payload: {
      response
    }
  };
}
export function getScalarMetricError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_SCALAR_METRIC_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type GetScalarMetricRequestTypeDef = ReturnType<
  typeof getScalarMetricRequest
>;
export type GetScalarMetricReceiveTypeDef = ReturnType<
  typeof getScalarMetricReceive
>;

export function getFaultCountTimeSeriesRequest(
  request?: monitoring_pb.GetFaultCountTimeSeriesRequest
) {
  return makeThunk({
    type: ActionTypes.GET_FAULT_COUNT_TIME_SERIES_REQUEST,
    payload: { request }
  });
}
export function getFaultCountTimeSeriesReceive(
  response: monitoring_pb.FaultCountTimeSeries,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_FAULT_COUNT_TIME_SERIES_RECEIVE,
    meta,
    payload: {
      response
    }
  };
}
export function getFaultCountTimeSeriesError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_FAULT_COUNT_TIME_SERIES_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type GetFaultCountTimeSeriesRequestTypeDef = ReturnType<
  typeof getFaultCountTimeSeriesRequest
>;
export type GetFaultCountTimeSeriesReceiveTypeDef = ReturnType<
  typeof getFaultCountTimeSeriesReceive
>;

export function createApplicationStartedAction() {
  return { type: ActionTypes.APPLICATION_STARTED };
}

export function signUpRequest(email: string, password: string) {
  return makeThunk({
    type: ActionTypes.SIGN_UP_REQUEST,
    payload: { email, password }
  });
}
export function signUpError(error: ServiceError, meta: ReduxSagaThunkMeta) {
  return { type: ActionTypes.SIGN_UP_ERROR, payload: error, error: true, meta };
}
export function signUpReceive(
  account: payloads.Account,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.SIGN_UP_RECEIVE,
    meta,
    payload: { account }
  };
}
export type SignUpRequestTypeDef = ReturnType<typeof signUpRequest>;
export type SignUpReceiveTypeDef = ReturnType<typeof signUpReceive>;

export function logInRequest(email: string, password: string) {
  return makeThunk({
    type: ActionTypes.LOG_IN_REQUEST,
    payload: { email, password }
  });
}
export function logInError(error: ServiceError, meta: ReduxSagaThunkMeta) {
  return { type: ActionTypes.LOG_IN_ERROR, payload: error, error: true, meta };
}
export function logInReceive(
  account: payloads.Account,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LOG_IN_RECEIVE,
    payload: { account },
    meta
  };
}
export type LogInRequestTypeDef = ReturnType<typeof logInRequest>;
export type LogInReceiveTypeDef = ReturnType<typeof logInReceive>;

export function logoutRequest() {
  return { type: ActionTypes.LOG_OUT_REQUEST, payload: undefined };
}
export function logoutReceive() {
  return { type: ActionTypes.LOG_OUT_RECEIVE, payload: undefined };
}

export function viewerRequest() {
  return makeThunk({ type: ActionTypes.VIEWER_REQUEST, payload: undefined });
}
export function viewerReceive(
  viewer: { account: payloads.Account | null },
  meta?: ReduxSagaThunkMeta
) {
  return { type: ActionTypes.VIEWER_RECEIVE, payload: viewer, meta };
}
export type ViewerRequestTypeDef = ReturnType<typeof viewerRequest>;
export type ViewerReceiveTypeDef = ReturnType<typeof viewerReceive>;

export function batchCreateConfigurationFileRevisionsRequest(
  configurationFileRevisions: monitoring_pb.ConfigurationFileRevision[]
) {
  return makeThunk({
    type: ActionTypes.CREATE_CONFIGURATION_FILE_REVISIONS_REQUEST,
    payload: { configurationFileRevisions }
  });
}

export function batchCreateConfigurationFileRevisionsReceive(
  response: monitoring_pb.ConfigurationFileRevision[],
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_CONFIGURATION_FILE_REVISIONS_RECEIVE,
    meta
  };
}
export function batchCreateConfigurationFileRevisionsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_CONFIGURATION_FILE_REVISIONS_ERROR,
    payload: error,
    error: true,
    meta
  };
}

export type batchCreateConfigurationFileRevisionsRequestTypeDef = ReturnType<
  typeof batchCreateConfigurationFileRevisionsRequest
>;
export type batchCreateConfigurationFileRevisionsReceiveTypeDef = ReturnType<
  typeof batchCreateConfigurationFileRevisionsReceive
>;

export function listConfigurationRevisionHistoryRequest(
  request: monitoring_pb.ListConfigurationRevisionHistoryRequest
) {
  return makeThunk({
    type: ActionTypes.LIST_CONFIGURATION_REVISION_HISTORY_REQUEST,
    payload: { request }
  });
}
export function listConfigurationRevisionHistoryReceive(
  response: monitoring_pb.ConfigurationRevisionHistory[],
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_CONFIGURATION_REVISION_HISTORY_RECEIVE,
    payload: response,
    meta
  };
}
export function listConfigurationRevisionHistoryError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_CONFIGURATION_REVISION_HISTORY_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type listConfigurationRevisionHistoryRequestTypeDef = ReturnType<
  typeof listConfigurationRevisionHistoryRequest
>;
export type listConfigurationRevisionHistoryReceiveTypeDef = ReturnType<
  typeof listConfigurationRevisionHistoryReceive
>;

export function getConfigurationFileRevisionRequest(
  request: monitoring_pb.GetConfigurationFileRevisionRequest
) {
  return makeThunk({
    type: ActionTypes.GET_CONFIGURATION_FILE_REVISION_REQUEST,
    payload: { request }
  });
}
export function getConfigurationFileRevisionReceive(
  response: monitoring_pb.ConfigurationFileRevision,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_CONFIGURATION_FILE_REVISION_RECEIVE,
    payload: response,
    meta
  };
}
export function getConfigurationFileRevisionError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_CONFIGURATION_FILE_REVISION_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type getConfigurationFileRevisionRequestTypeDef = ReturnType<
  typeof getConfigurationFileRevisionRequest
>;
export type getConfigurationFileRevisionReceiveTypeDef = ReturnType<
  typeof getConfigurationFileRevisionReceive
>;
export function listTrailerStatsRequest(filter: monitoring_pb.EventFilter, nextPageToken = "") {
  return makeThunk({
    type: ActionTypes.LIST_TRAILER_STATS_REQUEST,
    payload: { filter, nextPageToken }
  });
}
export function listTrailerStatsReceive(
  data: monitoring_pb.ListTrailerStatsResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_TRAILER_STATS_RECEIVE,
    meta,
    payload: data
  };
}
export function listTrailerStatsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_TRAILER_STATS_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ListTrailerStatsRequestTypeDef = ReturnType<
  typeof listTrailerStatsRequest
>;
export type ListTrailerStatsReceiveTypeDef = ReturnType<
  typeof listTrailerStatsReceive
>;
export function listTrailerStatsDatastoreRequest(filter: monitoring_pb.EventFilter, nextPageToken = "") {
  return makeThunk({
    type: ActionTypes.LIST_TRAILER_STATS_DATASTORE_REQUEST,
    payload: { filter, nextPageToken }
  });
}
export function listTrailerStatsDatastoreReceive(
  data: monitoring_pb.ListTrailerStatsResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_TRAILER_STATS_DATASTORE_RECEIVE,
    meta,
    payload: data
  };
}
export type ListTrailerStatsDatastoreRequestTypeDef = ReturnType<
  typeof listTrailerStatsDatastoreRequest
>;
export type ListTrailerStatsDatastoreReceiveTypeDef = ReturnType<
  typeof listTrailerStatsDatastoreReceive
>;

export function listSyncDemands(run_name: string = "") {
  return makeThunk({
    type: ActionTypes.LIST_SYNC_DEMANDS,
    payload: { run_name }
  });
}
export function listSyncDemandsReceive(
  data: monitoring_pb.ListSyncDemandsResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return makeThunk({
    type: ActionTypes.LIST_SYNC_DEMANDS_RECEIVE,
    meta,
    payload: data
  });
}
export type ListSyncDemandsTypeDef = ReturnType<typeof listSyncDemands>;
export type ListSyncDemandsReceiveTypeDef = ReturnType<
  typeof listSyncDemandsReceive
>;

export function createSyncDemand(
  request: monitoring_pb.CreateSyncDemandRequest
) {
  return makeThunk({
    type: ActionTypes.CREATE_SYNC_DEMAND,
    payload: { request }
  });
}
export function createSyncDemandReceive(
  data: monitoring_pb.SyncDemand.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return makeThunk({
    type: ActionTypes.CREATE_SYNC_DEMAND_RECEIVE,
    meta,
    payload: data
  });
}
export type CreateSyncDemandTypeDef = ReturnType<typeof createSyncDemand>;
export type CreateSyncDemandReceiveTypeDef = ReturnType<
  typeof createSyncDemandReceive
>;
export function listFaultsRequest(run_names: string[]) {
  return makeThunk({
    type: ActionTypes.LIST_FAULTS_REQUEST,
    payload: { run_names }
  });
}
export function listFaultsEventFilterRequest(
  eventFilter: monitoring_pb.EventFilter,
  faultCodes: Array<string>,
  faultTypes: Array<string>,
  faultSeverities: Array<string>,
  nextPageToken: string,
  includeActionAndRegion: boolean
) {
  return makeThunk({
    type: ActionTypes.LIST_FAULTS_REQUEST,
    payload: {
      eventFilter,
      faultCodes,
      faultTypes,
      faultSeverities,
      nextPageToken,
      includeActionAndRegion
    }
  });
}
export function listFaultsReceive(
  data: monitoring_pb.ListFaultsResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_FAULTS_RECEIVE,
    meta,
    payload: data
  };
}
export type ListFaultsRequestTypeDef = ReturnType<typeof listFaultsRequest>;
export type ListFaultsReceiveTypeDef = ReturnType<typeof listFaultsReceive>;
export function generateEnvironmentConfigRequest(
  template: config_pb.EnvironmentConfig,
  docks: monitoring_pb.DockConfig[]
) {
  return makeThunk({
    type: ActionTypes.GENERATE_ENVIRONMENT_CONFIG_REQUEST,
    payload: { template, docks }
  });
}
export function generateEnvironmentConfigReceive(
  data: string,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GENERATE_ENVIRONMENT_CONFIG_RECEIVE,
    meta,
    payload: data
  };
}
export function generateEnvironmentConfigError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GENERATE_ENVIRONMENT_CONFIG_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type GenerateEnvironmentConfigRequestTypeDef = ReturnType<
  typeof generateEnvironmentConfigRequest
>;
export type GenerateEnvironmentConfigReceiveTypeDef = ReturnType<
  typeof generateEnvironmentConfigReceive
>;

export function parseEnvironmentConfigRequest(
  environmentConfigTextProto: string
) {
  return makeThunk({
    type: ActionTypes.PARSE_ENVIRONMENT_CONFIG_REQUEST,
    payload: { environmentConfigTextProto }
  });
}
export function parseEnvironmentConfigReceive(
  data: string,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.PARSE_ENVIRONMENT_CONFIG_RECEIVE,
    meta,
    payload: data
  };
}
export function parseEnvironmentConfigError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.PARSE_ENVIRONMENT_CONFIG_ERROR,
    payload: error,
    error: true,
    meta
  };
}
export type ParseEnvironmentConfigRequestTypeDef = ReturnType<
  typeof parseEnvironmentConfigRequest
>;
export type ParseEnvironmentConfigReceiveTypeDef = ReturnType<
  typeof parseEnvironmentConfigReceive
>;

export function listFaultCodesRequest() {
  return makeThunk({
    type: ActionTypes.LIST_FAULT_CODES_REQUEST,
    payload: {}
  });
}
export function listFaultCodesReceive(
  data: monitoring_pb.ListFaultCodesResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_FAULT_CODES_RECEIVE,
    meta,
    payload: data
  };
}
export type ListFaultCodesRequestTypeDef = ReturnType<
  typeof listFaultCodesRequest
>;
export type ListFaultCodesReceiveTypeDef = ReturnType<
  typeof listFaultCodesReceive
>;

export function listOrganizationsRequest() {
  return makeThunk({
    type: ActionTypes.LIST_ORGANIZATIONS_REQUEST,
    payload: {}
  });
}
export function listOrganizationsReceive(
  data: monitoring_pb.ListOrganizationsResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_ORGANIZATIONS_RECEIVE,
    meta,
    payload: data
  };
}
export type ListOrganizationsRequestTypeDef = ReturnType<
  typeof listOrganizationsRequest
>;
export type ListOrganizationsReceiveTypeDef = ReturnType<
  typeof listOrganizationsReceive
>;

export function listSitesRequest() {
  return makeThunk({
    type: ActionTypes.LIST_SITES_REQUEST,
    payload: {}
  });
}
export function listSitesReceive(
  data: monitoring_pb.ListSitesResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_SITES_RECEIVE,
    meta,
    payload: data
  };
}
export type ListSitesRequestTypeDef = ReturnType<
  typeof listSitesRequest
>;
export type ListSitesReceiveTypeDef = ReturnType<
  typeof listSitesReceive
>;

export function listAutonomyVersionsRequest() {
  return makeThunk({
    type: ActionTypes.LIST_AUTONOMY_VERSIONS_REQUEST,
    payload: {}
  });
}
export function listAutonomyVersionsReceive(
  data: monitoring_pb.ListAutonomyVersionsResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_AUTONOMY_VERSIONS_RECEIVE,
    meta,
    payload: data
  };
}
export type ListAutonomyVersionsRequestTypeDef = ReturnType<
  typeof listAutonomyVersionsRequest
>;
export type ListAutonomyVersionsReceiveTypeDef = ReturnType<
  typeof listAutonomyVersionsReceive
>;

export function createAutonomyVersionRequest(request: monitoring_pb.CreateAutonomyVersionRequest) {
  return makeThunk({
    type: ActionTypes.CREATE_AUTONOMY_VERSION_REQUEST,
    payload: {request}
  });
}
export function createAutonomyVersionRecieve(
  data: monitoring_pb.AutonomyVersion.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_AUTONOMY_VERSION_RECEIVE,
    meta,
    payload: data
  };
}
export type CreateAutonomyVersionRequestTypeDef = ReturnType<
  typeof createAutonomyVersionRequest
>;
export type CreateAutonomyVersionReceiveTypeDef = ReturnType<
  typeof createAutonomyVersionRecieve
>;

export function getApexStatsRequest() {
  return makeThunk({
    type: ActionTypes.GET_APEX_STATS_REQUEST,
    payload: {}
  });
}
export function getApexStatsReceive(
  data: monitoring_pb.ApexStatsUrls.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.GET_APEX_STATS_RECEIVE,
    meta,
    payload: data
  };
}
export type GetApexStatsRequestTypeDef = ReturnType<
  typeof getApexStatsRequest
>;
export type GetApexStatsReceiveTypeDef = ReturnType<
  typeof getApexStatsReceive
>;
export function createBulkLogUpload(runNames: string[]) {
  return makeThunk({
    type: ActionTypes.CREATE_BULK_LOG_UPLOAD,
    payload: {runNames}
  });
}
export function createBulkLogUploadReceive(
  data: monitoring_pb.BulkLogUpload.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_BULK_LOG_UPLOAD_RECEIVE,
    meta,
    payload: data
  };
}
export type CreateBulkLogUploadTypeDef = ReturnType<
  typeof createBulkLogUpload
>;
export type CreateBulkLogUploadReceiveTypeDef = ReturnType<
  typeof createBulkLogUploadReceive
>;
export function listBulkLogUploadsRequest() {
  return makeThunk({
    type: ActionTypes.LIST_BULK_LOG_UPLOADS_REQUEST,
    payload: {}
  });
}
export function listBulkLogUploadsReceive(
  data: monitoring_pb.ListBulkLogUploadsResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_BULK_LOG_UPLOADS_RECEIVE,
    meta,
    payload: data
  };
}
export type ListBulkLogUploadsRequestTypeDef = ReturnType<
  typeof listBulkLogUploadsRequest
>;
export type ListBulkLogUploadsReceiveTypeDef = ReturnType<
  typeof listBulkLogUploadsReceive
>;
export function listFaultRateAlertThresholdsRequest() {
  return makeThunk({
    type: ActionTypes.LIST_FAULT_RATE_ALERT_THRESHOLDS_REQUEST,
    payload: {}
  });
}
export function listFaultRateAlertThresholdsReceive(
  data: monitoring_pb.ListFaultRateAlertThresholdsResponse.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_FAULT_RATE_ALERT_THRESHOLDS_RECEIVE,
    meta,
    payload: data
  };
}
export type ListFaultRateAlertThresholdsRequestTypeDef = ReturnType<
  typeof listFaultRateAlertThresholdsRequest
>;
export type ListFaultRateAlertThresholdsReceiveTypeDef = ReturnType<
  typeof listFaultRateAlertThresholdsReceive
>;

export function upsertFaultRateAlertThresholdRequest(faultCode: string, alertThreshold: number) {
  return makeThunk({
    type: ActionTypes.UPSERT_FAULT_RATE_ALERT_THRESHOLD_REQUEST,
    payload: {faultCode, alertThreshold}
  });
}
export function upsertFaultRateAlertThresholdReceive(
  data: monitoring_pb.FaultRateAlertThreshold.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPSERT_FAULT_RATE_ALERT_THRESHOLD_RECEIVE,
    meta,
    payload: data
  };
}
export type UpsertFaultRateAlertThresholdRequestTypeDef = ReturnType<
  typeof upsertFaultRateAlertThresholdRequest
>;
export type UpsertFaultRateAlertThresholdReceiveTypeDef = ReturnType<
  typeof upsertFaultRateAlertThresholdReceive
>;

export function uploadFaultTranslationsRequest(request: cc_pb.FaultTranslations) {
  return makeThunk({
    type: ActionTypes.UPLOAD_FAULT_TRANSLATIONS_REQUEST,
    payload: {request}
  });
}
export function uploadFaultTranslationsReceive(
  data: cc_pb.FaultTranslations.AsObject,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPLOAD_FAULT_TRANSLATIONS_RECEIVE,
    meta,
    payload: data
  };
}
export function uploadFaultTranslationsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPLOAD_FAULT_TRANSLATIONS_ERROR,
    payload: error,
    error: true,
    meta,
  };
}
export type UploadFaultTranslationsRequestTypeDef = ReturnType<
  typeof uploadFaultTranslationsRequest
>;
export type UploadFaultTranslationsReceiveTypeDef = ReturnType<
  typeof uploadFaultTranslationsReceive
>;

export function publishFaultTranslations(versionName: string) {
  return makeThunk({
    type: ActionTypes.PUBLISH_FAULT_TRANSLATIONS_REQUEST,
    payload: {versionName}
  });
}
export function publishFaultTranslationsReceive(
  data: monitoring_pb.PublishFaultTranslationsResponse,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.PUBLISH_FAULT_TRANSLATIONS_RECEIVE,
    meta,
    payload: data
  };
}
export function publishFaultTranslationsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.PUBLISH_FAULT_TRANSLATIONS_ERROR,
    payload: error,
    error: true,
    meta,
  };
}
export type PublishFaultTranslationsTypeDef = ReturnType<
  typeof publishFaultTranslations
>;
export type PublishFaultTranslationsReceiveTypeDef = ReturnType<
  typeof publishFaultTranslationsReceive
>;

export function listFaultTranslationsRequest(request: monitoring_pb.ListFaultTranslationsRequest) {
  return makeThunk({
    type: ActionTypes.LIST_FAULT_TRANSLATIONS_REQUEST,
    payload: {request}
  });
}
export function listFaultTranslationsReceive(
  data: cc_pb.FaultTranslations,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_FAULT_TRANSLATIONS_RECEIVE,
    meta,
    payload: data.toObject()
  };
}
export function listFaultTranslationsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_FAULT_TRANSLATIONS_ERROR,
    payload: error,
    error: true,
    meta,
  };
}
export type ListFaultTranslationsRequestTypeDef = ReturnType<
  typeof listFaultTranslationsRequest
>;
export type ListFaultTranslationsReceiveTypeDef = ReturnType<
  typeof listFaultTranslationsReceive
>;

export function createOperatorQuestionRequest(request: monitoring_pb.CreateOperatorQuestionRequest) {
  return makeThunk({
    type: ActionTypes.CREATE_OPERATOR_QUESTION_REQUEST,
    payload: {request}
  });
}
export function createOperatorQuestionReceive(
  data: monitoring_pb.OperatorQuestion,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_OPERATOR_QUESTION_RECEIVE,
    meta,
    payload: data.toObject()
  };
}
export function createOperatorQuestionError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_OPERATOR_QUESTION_ERROR,
    payload: error,
    error: true,
    meta,
  };
}
export type createOperatorQuestionRequestTypeDef = ReturnType<
  typeof createOperatorQuestionRequest
>;
export type createOperatorQuestionReceiveTypeDef = ReturnType<
  typeof createOperatorQuestionReceive
>;

export function upsertOperatorQuestionTranslationRequest(request: monitoring_pb.UpsertOperatorQuestionTranslationRequest) {
  return makeThunk({
    type: ActionTypes.UPSERT_OPERATOR_QUESTION_TRANSLATION_REQUEST,
    payload: {request}
  });
}
export function upsertOperatorQuestionTranslationReceive(
  data: monitoring_pb.OperatorQuestion,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPSERT_OPERATOR_QUESTION_TRANSLATION_RECEIVE,
    meta,
    payload: data.toObject()
  };
}
export function upsertOperatorQuestionTranslationError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPSERT_OPERATOR_QUESTION_TRANSLATION_ERROR,
    payload: error,
    error: true,
    meta,
  };
}
export type upsertOperatorQuestionTranslationRequestTypeDef = ReturnType<
  typeof upsertOperatorQuestionTranslationRequest
>;
export type upsertOperatorQuestionTranslationReceiveTypeDef = ReturnType<
  typeof upsertOperatorQuestionTranslationReceive
>;

export function createOperatorAnswerChoiceRequest(request: monitoring_pb.CreateOperatorAnswerChoiceRequest) {
  return makeThunk({
    type: ActionTypes.CREATE_OPERATOR_ANSWER_CHOICE_REQUEST,
    payload: {request}
  });
}
export function createOperatorAnswerChoiceReceive(
  data: monitoring_pb.OperatorAnswerChoice,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_OPERATOR_ANSWER_CHOICE_RECEIVE,
    meta,
    payload: data.toObject()
  };
}
export function createOperatorAnswerChoiceError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.CREATE_OPERATOR_ANSWER_CHOICE_ERROR,
    payload: error,
    error: true,
    meta,
  };
}
export type createOperatorAnswerChoiceRequestTypeDef = ReturnType<
  typeof createOperatorAnswerChoiceRequest
>;
export type createOperatorAnswerChoiceReceiveTypeDef = ReturnType<
  typeof createOperatorAnswerChoiceReceive
>;

export function upsertOperatorAnswerChoiceTranslationRequest(request: monitoring_pb.UpsertOperatorAnswerChoiceTranslationRequest) {
  return makeThunk({
    type: ActionTypes.UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_REQUEST,
    payload: {request}
  });
}
export function upsertOperatorAnswerChoiceTranslationReceive(
  data: monitoring_pb.OperatorAnswerChoice,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_RECEIVE,
    meta,
    payload: data.toObject()
  };
}
export function upsertOperatorAnswerChoiceTranslationError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_ERROR,
    payload: error,
    error: true,
    meta,
  };
}
export type upsertOperatorAnswerChoiceTranslationRequestTypeDef = ReturnType<
  typeof upsertOperatorAnswerChoiceTranslationRequest
>;
export type upsertOperatorAnswerChoiceTranslationReceiveTypeDef = ReturnType<
  typeof upsertOperatorAnswerChoiceTranslationReceive
>;

export function listOperatorQuestionsRequest(request: monitoring_pb.ListOperatorQuestionsRequest) {
  return makeThunk({
    type: ActionTypes.LIST_OPERATOR_QUESTIONS_REQUEST,
    payload: {request}
  });
}
export function listOperatorQuestionsReceive(
  data: monitoring_pb.ListOperatorQuestionsResponse,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_OPERATOR_QUESTIONS_RECEIVE,
    meta,
    payload: data.toObject()
  };
}
export function listOperatorQuestionsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_OPERATOR_QUESTIONS_ERROR,
    payload: error,
    error: true,
    meta,
  };
}
export type ListOperatorQuestionsRequestTypeDef = ReturnType<
  typeof listOperatorQuestionsRequest
>;
export type ListOperatorQuestionsReceiveTypeDef = ReturnType<
  typeof listOperatorQuestionsReceive
>;

export function listOperatorAnswerChoicesRequest(request: monitoring_pb.ListOperatorAnswerChoicesRequest) {
  return makeThunk({
    type: ActionTypes.LIST_OPERATOR_ANSWER_CHOICES_REQUEST,
    payload: {request}
  });
}
export function listOperatorAnswerChoicesReceive(
  data: monitoring_pb.ListOperatorAnswerChoicesResponse,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_OPERATOR_ANSWER_CHOICES_RECEIVE,
    meta,
    payload: data.toObject()
  };
}
export function listOperatorAnswerChoicesError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.LIST_OPERATOR_ANSWER_CHOICES_ERROR,
    payload: error,
    error: true,
    meta,
  };
}
export type ListOperatorAnswerChoicesRequestTypeDef = ReturnType<
  typeof listOperatorAnswerChoicesRequest
>;
export type ListOperatorAnswerChoicesReceiveTypeDef = ReturnType<
  typeof listOperatorAnswerChoicesReceive
>;

export function updateFaultCodeQuestionsRequest(request: monitoring_pb.UpdateFaultCodeQuestionsRequest) {
  return makeThunk({
    type: ActionTypes.UPDATE_FAULT_CODE_QUESTIONS_REQUEST,
    payload: {request}
  });
}
export function updateFaultCodeQuestionsReceive(
  data: monitoring_pb.UpdateFaultCodeQuestionsResponse,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPDATE_FAULT_CODE_QUESTIONS_RECEIVE,
    meta,
    payload: data.toObject()
  };
}
export function updateFaultCodeQuestionsError(
  error: ServiceError,
  meta: ReduxSagaThunkMeta
) {
  return {
    type: ActionTypes.UPDATE_FAULT_CODE_QUESTIONS_ERROR,
    payload: error,
    error: true,
    meta,
  };
}
export type UpdateFaultCodeQuestionsRequestTypeDef = ReturnType<
  typeof updateFaultCodeQuestionsRequest
>;
export type UpdateFaultCodeQuestionsReceiveTypeDef = ReturnType<
  typeof updateFaultCodeQuestionsReceive
>;

