import React, { Component } from "react";
import { VariableSizeGrid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";

import { ApplicationState, ViewerState } from "../../redux";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import { protoEnumFieldName } from "../../utils/protos";

import Datasheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { run } from "../../utils/Paths";
import { FormattedData, statsToRows, StylableCSVLink } from "./StatSheetUtils";

export interface GridElement extends Datasheet.Cell<GridElement, number> {
  value: number | string;
}

const styles = (theme: Theme) =>
  createStyles({
    sheet: {
      width: "100%",
      height: "100%",
      marginBottom: 24
    },
    wrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 24
    },
    cell: {
      border: "1px solid",
      textAlign: "center"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return { viewer: state.viewer };
};

interface Props extends WithStyles<typeof styles> {
  loading?: boolean;
  dispatch: any;
  stats: Array<m_pb.PickStats.AsObject>;
  omittedFieldNames?: Array<string>;
  viewer: ViewerState;
}

const clipDigits = (val: number, places: number): number =>
  Math.round(val * 10 ** places) / 10 ** places;

const defaultColumnProperties = {};
type formatter = (val: number | string) => number | string;
const emptyFormatter: formatter = (v: number | string) => v;
const boolFormatter: formatter = (v: any) => v ? "Yes" : "No";
const timeFormatter = (ns: number) => new Date(ns / 1e6).toISOString();
const durationFormatter = (v: number) => clipDigits(v / 1e9, 2);
const durationListFormatter = (v: Array<number>) =>
  v.map(v => durationFormatter(v)).join(", ");
const clipFormatter = (v: number) => clipDigits(v, 2);
const singleEnumFormatter = <T extends unknown>(enumClass: T, prefix: string) => (enumValue: T) =>
  protoEnumFieldName(enumClass, enumValue as number).replace(prefix, "");
const enumFormatter = <T extends unknown>(enumClass: T, prefix: string) => (
  enums: Array<T>
) => {
  return enums
    .map(e => protoEnumFieldName(enumClass, e as number))
    .map(e => e.replace(prefix, ""))
    .join(", ");
};

class PickStatsSheet extends Component<Props> {
  columns = [
    {
      key: "runName",
      name: "Run",
      formatter: emptyFormatter,
      componentMaker:
        this.props.viewer.account &&
        this.props.viewer.account.isStaff &&
        ((runName: string) => (
          <a href={run(runName)} target="_blank">
            {runName}
          </a>
        ))
    },
    { key: "objectiveId", name: "Objective_Id", formatter: emptyFormatter },
    { key: "palletId", name: "Pallet_Id", formatter: emptyFormatter },
    {
      key: "cohortName",
      name: "Cohort",
      formatter: emptyFormatter
    },
    {
      key: "versionName",
      name: "Version",
      formatter: emptyFormatter
    },
    { key: "startTimeUtc", name: "Start", formatter: timeFormatter },
    { key: "targetTimeUtc", name: "Target Time", formatter: timeFormatter },
    { key: "pickTimeUtc", name: "Pick Time", formatter: timeFormatter },
    { key: "endTimeUtc", name: "End", formatter: timeFormatter },
    { key: "duration", name: "Duration_s", formatter: durationFormatter },
    {
      key: "pickRowDepth",
      name: "Pick_Depth",
      formatter: clipFormatter
    },
    {
      key: "interventionTypesList",
      name: "Intervention_Types",
      formatter: enumFormatter(cc_pb.InterventionType, "INTERVENTION_")
    },
    {
      key: "interventionCausesList",
      name: "Intervention_Causes",
      formatter: enumFormatter(cc_pb.InterventionCause, "INTERVENTION_CAUSE_")
    },
    {
      key: "interventionCauseCategoriesList",
      name: "Intervention_Cause_Categories",
      formatter: enumFormatter(
        cc_pb.InterventionCauseCategory,
        "INTERVENTION_CAUSE_CATEGORY_"
      )
    },
    {
      key: "stoppageDurationsList",
      name: "Stoppages_s",
      formatter: durationListFormatter
    },
    {
      key: "stoppageResponseDelaysList",
      name: "Stoppage_Response_Delays_s",
      formatter: durationListFormatter
    },
    {
      key: "stoppageResponseDelay",
      name: "Total_Stoppage_Response_Delay_s",
      formatter: durationFormatter
    },
    {
      key: "stoppageDuration",
      name: "Total_Stoppage_s",
      formatter: durationFormatter
    },
    {
      key: "stackHeight",
      name: "Stack_Height",
      formatter: clipFormatter
    },
    {
      key: "stackWeight",
      name: "Stack_Weight",
      formatter: clipFormatter
    },
    {
      key: "stackCount",
      name: "Stack_Count",
      formatter: emptyFormatter
    },
    {
      key: "forwardTravelDuration",
      name: "Forward_s",
      formatter: durationFormatter
    },
    {
      key: "forwardSpeed",
      name: "Forwards_mps",
      formatter: clipFormatter
    },
    {
      key: "backwardsTravelDuration",
      name: "Backwards_s",
      formatter: durationFormatter
    },
    { key: "backwardsTravel", name: "Backwards_m", formatter: clipFormatter },
    { key: "manualDuration", name: "Manual_s", formatter: durationFormatter },
    { key: "stoppedDuration", name: "Stopped_s", formatter: durationFormatter },
    { key: "forwardTravel", name: "Forward_m", formatter: clipFormatter },
    {
      key: "backwardsSpeed",
      name: "Backwards_mps",
      formatter: clipFormatter
    },
    {
      key: "interventions",
      name: "Interventions",
      formatter: emptyFormatter
    },
    { key: "palletsPerHour", name: "Pallets_per_hr", formatter: clipFormatter },
    { key: "interventionRobotFaultCode", name: "Intervention_Robot_Fault_Codes", formatter: emptyFormatter },
    { key: "userId", name: "User ID", formatter: emptyFormatter },
    { key: "pickZoneId", name: "Pick Zone ID", formatter: emptyFormatter },
    { key: "placeZoneId", name: "Place Zone ID", formatter: emptyFormatter },
    { key: "palletHeightMode", name: "Pallet Height Setting", formatter: singleEnumFormatter(cc_pb.PalletHeight, "PALLET_HEIGHT_") },
    { key: "hangingPlasticDetected", name: "Hanging Plastic Detected", formatter: boolFormatter },
    { key: "interventionTrigger", name: "Intervention Trigger", formatter: emptyFormatter },
    { key: "supportType", name: "Pallet Type", formatter: emptyFormatter },
    {
      key: "pickNearSpaceExtractionDuration",
      name: "Pick Near Space Extraction Duration",
      formatter: durationFormatter
    },
    {
      key: "pickFreeSpaceDurationWarehouse",
      name: "Pick Free Space Duration Warehouse",
      formatter: durationFormatter
    },
    {
      key: "pickFreeSpaceDurationRamp",
      name: "Pick Free Space Duration Ramp",
      formatter: durationFormatter
    },
    {
      key: "pickFreeSpaceDurationTruck",
      name: "Pick Free Space Duration Truck",
      formatter: durationFormatter
    },
    {
      key: "pickNearSpaceInsertionDuration",
      name: "Pick Near Space Insertion Duration",
      formatter: durationFormatter
    },
    {
      key: "placeNearSpaceExtractionDuration",
      name: "Place Near Space Extraction Duration",
      formatter: durationFormatter
    },
    {
      key: "placeFreeSpaceDurationTruck",
      name: "Place Free Space Duration Truck",
      formatter: durationFormatter
    },
    {
      key: "placeFreeSpaceDurationRamp",
      name: "Place Free Space Duration Ramp",
      formatter: durationFormatter
    },
    {
      key: "placeFreeSpaceDurationWarehouse",
      name: "Place Free Space Duration Warehouse",
      formatter: durationFormatter
    },
    {
      key: "placeNearSpaceInsertionDuration",
      name: "Place Near Space Insertion Duration",
      formatter: durationFormatter
    }
  ].map(c => ({ ...defaultColumnProperties, ...c }));

  render() {
    const { classes, stats, omittedFieldNames, loading } = this.props;
    const formattedData: FormattedData = statsToRows(
      stats,
      omittedFieldNames || [],
      this.columns,
      defaultColumnProperties
    );
    // @ts-ignore
    const renderCell = ({ columnIndex, rowIndex, style }) => {
      const cell = formattedData.cells[rowIndex][columnIndex];
      return (
        <div style={style} className={classes.cell}>
          {cell.component || cell.value}
        </div>
      );
    };
    return (
      <div className={classes.wrapper}>
        { !loading && <StylableCSVLink
          filename="all_picks.csv"
          csvData={formattedData.downloadable}
        />}
        <div className={classes.sheet}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <VariableSizeGrid
                  columnWidth={index => {
                    // Allow a comfortable amount of room for each column
                    return (
                      10 *
                      Math.max(
                        ...formattedData.cells
                          .map(row => row[index])
                          .map(cellValue =>
                            cellValue.value.toString().indexOf(" ") >= 0
                              ? cellValue.value.toString().length / 2
                              : cellValue.value.toString().length
                          )
                      )
                    );
                  }}
                  rowHeight={() => {
                    return height / 15;
                  }}
                  columnCount={
                    this.columns.length - (omittedFieldNames || []).length
                  }
                  height={height}
                  rowCount={formattedData.cells.length}
                  width={width}
                >
                  {renderCell}
                </VariableSizeGrid>
              );
            }}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(PickStatsSheet));
