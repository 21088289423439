import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import detection_pb from "../../_proto/detection/proto/detection_pb";
import { TableRow, TableCell, Button } from "@material-ui/core";

const styles = () => createStyles({});

interface Props extends WithStyles<typeof styles> {
  zoneConfig: detection_pb.ZoneConfig;
  edit: () => void;
  delete: () => void;
  canEdit: boolean;
}
interface State {}

class PickZoneConfiguration extends Component<Props, State> {
  state: State = {};

  render() {
    const { rampConfig, templateName } = this.props.zoneConfig.toObject();
    if (!rampConfig) {
      return null;
    }
    const { length, width, hasWalls, lipToBumpers, lipToTagLine } = rampConfig;
    // @ts-ignore
      // @ts-ignore
      return (
      <TableRow>
        <TableCell>{templateName}</TableCell>
        <TableCell align="right">{length}</TableCell>
        <TableCell align="right">{width}</TableCell>
        <TableCell align="right">{hasWalls ? "yes" : "no"}</TableCell>
        <TableCell align="right">{lipToTagLine}</TableCell>
        <TableCell align="right">{lipToBumpers}</TableCell>
        <TableCell align="right">
          <Button onClick={this.props.edit} disabled={!this.props.canEdit}>
            Edit
          </Button>
        </TableCell>
        <TableCell align="right">
          <Button onClick={this.props.delete} disabled={!this.props.canEdit}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

export default connect()(withStyles(styles)(PickZoneConfiguration));
