// source: sensors/laser_scan.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var geometry_proto_transform_pb = require('../geometry/proto/transform_pb.js');
goog.object.extend(proto, geometry_proto_transform_pb);
goog.exportSymbol('proto.fox.proto.HoneycombReturn', null, global);
goog.exportSymbol('proto.fox.proto.HoneycombScan', null, global);
goog.exportSymbol('proto.fox.proto.HoneycombShot', null, global);
goog.exportSymbol('proto.fox.proto.HoneycombSpin', null, global);
goog.exportSymbol('proto.fox.proto.LaserMask', null, global);
goog.exportSymbol('proto.fox.proto.LaserMasks', null, global);
goog.exportSymbol('proto.fox.proto.LaserScan', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LaserMask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.LaserMask.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.LaserMask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LaserMask.displayName = 'proto.fox.proto.LaserMask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LaserMasks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.LaserMasks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LaserMasks.displayName = 'proto.fox.proto.LaserMasks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LaserScan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.LaserScan.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.LaserScan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LaserScan.displayName = 'proto.fox.proto.LaserScan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.HoneycombReturn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.HoneycombReturn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.HoneycombReturn.displayName = 'proto.fox.proto.HoneycombReturn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.HoneycombShot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.HoneycombShot.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.HoneycombShot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.HoneycombShot.displayName = 'proto.fox.proto.HoneycombShot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.HoneycombScan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.HoneycombScan.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.HoneycombScan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.HoneycombScan.displayName = 'proto.fox.proto.HoneycombScan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.HoneycombSpin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.HoneycombSpin.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.HoneycombSpin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.HoneycombSpin.displayName = 'proto.fox.proto.HoneycombSpin';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.LaserMask.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LaserMask.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LaserMask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LaserMask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LaserMask.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    minRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    maxRangesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LaserMask}
 */
proto.fox.proto.LaserMask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LaserMask;
  return proto.fox.proto.LaserMask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LaserMask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LaserMask}
 */
proto.fox.proto.LaserMask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStepAngle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinRange(value);
      break;
    case 6:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setMaxRangesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LaserMask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LaserMask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LaserMask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LaserMask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getMinRange();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getMaxRangesList();
  if (f.length > 0) {
    writer.writePackedFloat(
      6,
      f
    );
  }
};


/**
 * optional float step_angle = 5;
 * @return {number}
 */
proto.fox.proto.LaserMask.prototype.getStepAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LaserMask} returns this
 */
proto.fox.proto.LaserMask.prototype.setStepAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float min_range = 3;
 * @return {number}
 */
proto.fox.proto.LaserMask.prototype.getMinRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LaserMask} returns this
 */
proto.fox.proto.LaserMask.prototype.setMinRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated float max_ranges = 6;
 * @return {!Array<number>}
 */
proto.fox.proto.LaserMask.prototype.getMaxRangesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LaserMask} returns this
 */
proto.fox.proto.LaserMask.prototype.setMaxRangesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LaserMask} returns this
 */
proto.fox.proto.LaserMask.prototype.addMaxRanges = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LaserMask} returns this
 */
proto.fox.proto.LaserMask.prototype.clearMaxRangesList = function() {
  return this.setMaxRangesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LaserMasks.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LaserMasks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LaserMasks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LaserMasks.toObject = function(includeInstance, msg) {
  var f, obj = {
    leftMask: (f = msg.getLeftMask()) && proto.fox.proto.LaserMask.toObject(includeInstance, f),
    rightMask: (f = msg.getRightMask()) && proto.fox.proto.LaserMask.toObject(includeInstance, f),
    sickMask: (f = msg.getSickMask()) && proto.fox.proto.LaserMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LaserMasks}
 */
proto.fox.proto.LaserMasks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LaserMasks;
  return proto.fox.proto.LaserMasks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LaserMasks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LaserMasks}
 */
proto.fox.proto.LaserMasks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.LaserMask;
      reader.readMessage(value,proto.fox.proto.LaserMask.deserializeBinaryFromReader);
      msg.setLeftMask(value);
      break;
    case 2:
      var value = new proto.fox.proto.LaserMask;
      reader.readMessage(value,proto.fox.proto.LaserMask.deserializeBinaryFromReader);
      msg.setRightMask(value);
      break;
    case 3:
      var value = new proto.fox.proto.LaserMask;
      reader.readMessage(value,proto.fox.proto.LaserMask.deserializeBinaryFromReader);
      msg.setSickMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LaserMasks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LaserMasks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LaserMasks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LaserMasks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeftMask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.LaserMask.serializeBinaryToWriter
    );
  }
  f = message.getRightMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.LaserMask.serializeBinaryToWriter
    );
  }
  f = message.getSickMask();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.LaserMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional LaserMask left_mask = 1;
 * @return {?proto.fox.proto.LaserMask}
 */
proto.fox.proto.LaserMasks.prototype.getLeftMask = function() {
  return /** @type{?proto.fox.proto.LaserMask} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.LaserMask, 1));
};


/**
 * @param {?proto.fox.proto.LaserMask|undefined} value
 * @return {!proto.fox.proto.LaserMasks} returns this
*/
proto.fox.proto.LaserMasks.prototype.setLeftMask = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.LaserMasks} returns this
 */
proto.fox.proto.LaserMasks.prototype.clearLeftMask = function() {
  return this.setLeftMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.LaserMasks.prototype.hasLeftMask = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LaserMask right_mask = 2;
 * @return {?proto.fox.proto.LaserMask}
 */
proto.fox.proto.LaserMasks.prototype.getRightMask = function() {
  return /** @type{?proto.fox.proto.LaserMask} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.LaserMask, 2));
};


/**
 * @param {?proto.fox.proto.LaserMask|undefined} value
 * @return {!proto.fox.proto.LaserMasks} returns this
*/
proto.fox.proto.LaserMasks.prototype.setRightMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.LaserMasks} returns this
 */
proto.fox.proto.LaserMasks.prototype.clearRightMask = function() {
  return this.setRightMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.LaserMasks.prototype.hasRightMask = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LaserMask sick_mask = 3;
 * @return {?proto.fox.proto.LaserMask}
 */
proto.fox.proto.LaserMasks.prototype.getSickMask = function() {
  return /** @type{?proto.fox.proto.LaserMask} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.LaserMask, 3));
};


/**
 * @param {?proto.fox.proto.LaserMask|undefined} value
 * @return {!proto.fox.proto.LaserMasks} returns this
*/
proto.fox.proto.LaserMasks.prototype.setSickMask = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.LaserMasks} returns this
 */
proto.fox.proto.LaserMasks.prototype.clearSickMask = function() {
  return this.setSickMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.LaserMasks.prototype.hasSickMask = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.LaserScan.repeatedFields_ = [3,4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LaserScan.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LaserScan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LaserScan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LaserScan.toObject = function(includeInstance, msg) {
  var f, obj = {
    minAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    stepAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    rangesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    intensitiesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    statusList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    tapeIntensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    scanPeriod: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LaserScan}
 */
proto.fox.proto.LaserScan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LaserScan;
  return proto.fox.proto.LaserScan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LaserScan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LaserScan}
 */
proto.fox.proto.LaserScan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinAngle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStepAngle(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setRangesList(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setIntensitiesList(value);
      break;
    case 7:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setStatusList(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTapeIntensity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScanPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LaserScan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LaserScan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LaserScan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LaserScan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getStepAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRangesList();
  if (f.length > 0) {
    writer.writePackedFloat(
      3,
      f
    );
  }
  f = message.getIntensitiesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
  f = message.getTapeIntensity();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getScanPeriod();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional double min_angle = 1;
 * @return {number}
 */
proto.fox.proto.LaserScan.prototype.getMinAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.setMinAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double step_angle = 2;
 * @return {number}
 */
proto.fox.proto.LaserScan.prototype.getStepAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.setStepAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated float ranges = 3;
 * @return {!Array<number>}
 */
proto.fox.proto.LaserScan.prototype.getRangesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.setRangesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.addRanges = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.clearRangesList = function() {
  return this.setRangesList([]);
};


/**
 * repeated int32 intensities = 4;
 * @return {!Array<number>}
 */
proto.fox.proto.LaserScan.prototype.getIntensitiesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.setIntensitiesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.addIntensities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.clearIntensitiesList = function() {
  return this.setIntensitiesList([]);
};


/**
 * repeated int32 status = 7;
 * @return {!Array<number>}
 */
proto.fox.proto.LaserScan.prototype.getStatusList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * optional float tape_intensity = 5;
 * @return {number}
 */
proto.fox.proto.LaserScan.prototype.getTapeIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.setTapeIntensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 scan_period = 6;
 * @return {number}
 */
proto.fox.proto.LaserScan.prototype.getScanPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LaserScan} returns this
 */
proto.fox.proto.LaserScan.prototype.setScanPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.HoneycombReturn.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.HoneycombReturn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.HoneycombReturn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HoneycombReturn.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: (f = msg.getPosition()) && geometry_proto_transform_pb.Vector3f.toObject(includeInstance, f),
    intensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    normal: (f = msg.getNormal()) && geometry_proto_transform_pb.Vector3f.toObject(includeInstance, f),
    range: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    planarity: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    cosAngleIncidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    reflectivity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    noise: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    selfReturn: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    index: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.HoneycombReturn}
 */
proto.fox.proto.HoneycombReturn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.HoneycombReturn;
  return proto.fox.proto.HoneycombReturn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.HoneycombReturn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.HoneycombReturn}
 */
proto.fox.proto.HoneycombReturn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Vector3f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector3f.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setIntensity(value);
      break;
    case 3:
      var value = new geometry_proto_transform_pb.Vector3f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector3f.deserializeBinaryFromReader);
      msg.setNormal(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRange(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPlanarity(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCosAngleIncidence(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setReflectivity(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoise(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelfReturn(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.HoneycombReturn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.HoneycombReturn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.HoneycombReturn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HoneycombReturn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Vector3f.serializeBinaryToWriter
    );
  }
  f = message.getIntensity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getNormal();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      geometry_proto_transform_pb.Vector3f.serializeBinaryToWriter
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getPlanarity();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCosAngleIncidence();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getReflectivity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getNoise();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSelfReturn();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional Vector3f position = 1;
 * @return {?proto.fox.proto.Vector3f}
 */
proto.fox.proto.HoneycombReturn.prototype.getPosition = function() {
  return /** @type{?proto.fox.proto.Vector3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector3f, 1));
};


/**
 * @param {?proto.fox.proto.Vector3f|undefined} value
 * @return {!proto.fox.proto.HoneycombReturn} returns this
*/
proto.fox.proto.HoneycombReturn.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.HoneycombReturn} returns this
 */
proto.fox.proto.HoneycombReturn.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.HoneycombReturn.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double intensity = 2;
 * @return {number}
 */
proto.fox.proto.HoneycombReturn.prototype.getIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HoneycombReturn} returns this
 */
proto.fox.proto.HoneycombReturn.prototype.setIntensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Vector3f normal = 3;
 * @return {?proto.fox.proto.Vector3f}
 */
proto.fox.proto.HoneycombReturn.prototype.getNormal = function() {
  return /** @type{?proto.fox.proto.Vector3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector3f, 3));
};


/**
 * @param {?proto.fox.proto.Vector3f|undefined} value
 * @return {!proto.fox.proto.HoneycombReturn} returns this
*/
proto.fox.proto.HoneycombReturn.prototype.setNormal = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.HoneycombReturn} returns this
 */
proto.fox.proto.HoneycombReturn.prototype.clearNormal = function() {
  return this.setNormal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.HoneycombReturn.prototype.hasNormal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double range = 4;
 * @return {number}
 */
proto.fox.proto.HoneycombReturn.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HoneycombReturn} returns this
 */
proto.fox.proto.HoneycombReturn.prototype.setRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double planarity = 5;
 * @return {number}
 */
proto.fox.proto.HoneycombReturn.prototype.getPlanarity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HoneycombReturn} returns this
 */
proto.fox.proto.HoneycombReturn.prototype.setPlanarity = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double cos_angle_incidence = 6;
 * @return {number}
 */
proto.fox.proto.HoneycombReturn.prototype.getCosAngleIncidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HoneycombReturn} returns this
 */
proto.fox.proto.HoneycombReturn.prototype.setCosAngleIncidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double reflectivity = 7;
 * @return {number}
 */
proto.fox.proto.HoneycombReturn.prototype.getReflectivity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HoneycombReturn} returns this
 */
proto.fox.proto.HoneycombReturn.prototype.setReflectivity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool noise = 8;
 * @return {boolean}
 */
proto.fox.proto.HoneycombReturn.prototype.getNoise = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.HoneycombReturn} returns this
 */
proto.fox.proto.HoneycombReturn.prototype.setNoise = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool self_return = 9;
 * @return {boolean}
 */
proto.fox.proto.HoneycombReturn.prototype.getSelfReturn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.HoneycombReturn} returns this
 */
proto.fox.proto.HoneycombReturn.prototype.setSelfReturn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 index = 10;
 * @return {number}
 */
proto.fox.proto.HoneycombReturn.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HoneycombReturn} returns this
 */
proto.fox.proto.HoneycombReturn.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.HoneycombShot.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.HoneycombShot.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.HoneycombShot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.HoneycombShot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HoneycombShot.toObject = function(includeInstance, msg) {
  var f, obj = {
    returnList: jspb.Message.toObjectList(msg.getReturnList(),
    proto.fox.proto.HoneycombReturn.toObject, includeInstance),
    beamSide: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.HoneycombShot}
 */
proto.fox.proto.HoneycombShot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.HoneycombShot;
  return proto.fox.proto.HoneycombShot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.HoneycombShot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.HoneycombShot}
 */
proto.fox.proto.HoneycombShot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.HoneycombReturn;
      reader.readMessage(value,proto.fox.proto.HoneycombReturn.deserializeBinaryFromReader);
      msg.addReturn(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBeamSide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.HoneycombShot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.HoneycombShot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.HoneycombShot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HoneycombShot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReturnList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.HoneycombReturn.serializeBinaryToWriter
    );
  }
  f = message.getBeamSide();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated HoneycombReturn return = 1;
 * @return {!Array<!proto.fox.proto.HoneycombReturn>}
 */
proto.fox.proto.HoneycombShot.prototype.getReturnList = function() {
  return /** @type{!Array<!proto.fox.proto.HoneycombReturn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.HoneycombReturn, 1));
};


/**
 * @param {!Array<!proto.fox.proto.HoneycombReturn>} value
 * @return {!proto.fox.proto.HoneycombShot} returns this
*/
proto.fox.proto.HoneycombShot.prototype.setReturnList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.HoneycombReturn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.HoneycombReturn}
 */
proto.fox.proto.HoneycombShot.prototype.addReturn = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.HoneycombReturn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.HoneycombShot} returns this
 */
proto.fox.proto.HoneycombShot.prototype.clearReturnList = function() {
  return this.setReturnList([]);
};


/**
 * optional int32 beam_side = 2;
 * @return {number}
 */
proto.fox.proto.HoneycombShot.prototype.getBeamSide = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HoneycombShot} returns this
 */
proto.fox.proto.HoneycombShot.prototype.setBeamSide = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.HoneycombScan.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.HoneycombScan.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.HoneycombScan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.HoneycombScan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HoneycombScan.toObject = function(includeInstance, msg) {
  var f, obj = {
    shotList: jspb.Message.toObjectList(msg.getShotList(),
    proto.fox.proto.HoneycombShot.toObject, includeInstance),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.HoneycombScan}
 */
proto.fox.proto.HoneycombScan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.HoneycombScan;
  return proto.fox.proto.HoneycombScan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.HoneycombScan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.HoneycombScan}
 */
proto.fox.proto.HoneycombScan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.HoneycombShot;
      reader.readMessage(value,proto.fox.proto.HoneycombShot.deserializeBinaryFromReader);
      msg.addShot(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.HoneycombScan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.HoneycombScan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.HoneycombScan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HoneycombScan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShotList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.HoneycombShot.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated HoneycombShot shot = 1;
 * @return {!Array<!proto.fox.proto.HoneycombShot>}
 */
proto.fox.proto.HoneycombScan.prototype.getShotList = function() {
  return /** @type{!Array<!proto.fox.proto.HoneycombShot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.HoneycombShot, 1));
};


/**
 * @param {!Array<!proto.fox.proto.HoneycombShot>} value
 * @return {!proto.fox.proto.HoneycombScan} returns this
*/
proto.fox.proto.HoneycombScan.prototype.setShotList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.HoneycombShot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.HoneycombShot}
 */
proto.fox.proto.HoneycombScan.prototype.addShot = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.HoneycombShot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.HoneycombScan} returns this
 */
proto.fox.proto.HoneycombScan.prototype.clearShotList = function() {
  return this.setShotList([]);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.fox.proto.HoneycombScan.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HoneycombScan} returns this
 */
proto.fox.proto.HoneycombScan.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.HoneycombSpin.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.HoneycombSpin.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.HoneycombSpin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.HoneycombSpin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HoneycombSpin.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanList: jspb.Message.toObjectList(msg.getScanList(),
    proto.fox.proto.HoneycombScan.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.HoneycombSpin}
 */
proto.fox.proto.HoneycombSpin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.HoneycombSpin;
  return proto.fox.proto.HoneycombSpin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.HoneycombSpin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.HoneycombSpin}
 */
proto.fox.proto.HoneycombSpin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.HoneycombScan;
      reader.readMessage(value,proto.fox.proto.HoneycombScan.deserializeBinaryFromReader);
      msg.addScan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.HoneycombSpin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.HoneycombSpin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.HoneycombSpin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HoneycombSpin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.HoneycombScan.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HoneycombScan scan = 1;
 * @return {!Array<!proto.fox.proto.HoneycombScan>}
 */
proto.fox.proto.HoneycombSpin.prototype.getScanList = function() {
  return /** @type{!Array<!proto.fox.proto.HoneycombScan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.HoneycombScan, 1));
};


/**
 * @param {!Array<!proto.fox.proto.HoneycombScan>} value
 * @return {!proto.fox.proto.HoneycombSpin} returns this
*/
proto.fox.proto.HoneycombSpin.prototype.setScanList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.HoneycombScan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.HoneycombScan}
 */
proto.fox.proto.HoneycombSpin.prototype.addScan = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.HoneycombScan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.HoneycombSpin} returns this
 */
proto.fox.proto.HoneycombSpin.prototype.clearScanList = function() {
  return this.setScanList([]);
};


goog.object.extend(exports, proto.fox.proto);
