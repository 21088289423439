import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles,
  fade
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { combineStyles, commonStyles } from "../Utils/CommonStyles";
import { getOptions } from "./ConfigurationPage";
import { isEmpty } from "lodash";

const localStyles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    title: {
      flexGrow: 1,
      display: "none",
      padding: 6,
      [theme.breakpoints.up("sm")]: {
        display: "block"
      }
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25)
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto"
      }
    },
    selector: {
      flexGrow: 2
    },
    selectorObject: {
      fontSize: "1.25rem",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: "0.0075em"
    }
  });
const styles = combineStyles(localStyles, commonStyles);

const mapStateToProps = () => {
  return {};
};

export interface ConfigEntity {
  robotName?: string;
  cohortId?: string;
  displayName: string;
  id?: string;
}

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
  options: Array<any>;
  onChangeRequest: any;
  scope: string;
  entity: ConfigEntity;
  isRobot: boolean;
  isCohort: boolean;
}

interface State {
  scope: "robot" | "cohort";
  entity: ConfigEntity;
  options: any[];
  isDirty: boolean;
}

class RobotCohortSelector extends Component<Props, State> {
  state: Readonly<State> = {
    scope: "robot",
    entity: { displayName: "" },
    options: [],
    isDirty: false
  };

  componentDidMount() {
    if (this.props.scope === "robot" || this.props.scope === "cohort") {
      this.setState({
        scope: this.props.scope,
        options: this.props.options
      });
    } else {
      this.setState({ options: this.props.options });
    }
  }
  componentDidUpdate(prevProps: any) {
    const newScope = this.props.scope;
    const newEntity = this.props.entity;
    const newOptions = this.props.options;

    if (
      newScope !== prevProps.scope &&
      (newScope === "robot" || newScope === "cohort")
    ) {
      this.setState({
        scope: newScope,
        options: newOptions
      });
    }
    if (newEntity !== prevProps.entity) {
      this.setState({ entity: newEntity });
    }
    if (newOptions !== prevProps.options) {
      this.setState({ options: newOptions });
    }
  }
  _handleScopeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const {
      props: { onChangeRequest }
    } = this;
    // update URL
    onChangeRequest("scope", event.target.value);
    if (
      typeof event.target.value === "string" &&
      (event.target.value === "robot" || event.target.value === "cohort")
    ) {
      this.setState({
        scope: event.target.value,
        entity: { displayName: "" }
      });
    }
  };
  _handleEntityChange = (
    _event: React.ChangeEvent<{}>,
    value: { displayName: string } | null
  ): void => {
    // do not update URL. only change on Retrieve button click
    if (value) {
      this.setState({
        entity: value,
        isDirty: true
      });
    } else {
      this.setState({
        entity: { displayName: "" }
      });
    }
  };

  _handleAutocompleteOptions = (objectArray: any[]) => {
    const { isRobot, isCohort } = this.props;
    return getOptions({ isRobot, isCohort }, objectArray);
  };

  _getOptionSelected = (option: any, value: any) => {
    return option.displayName === value.displayName;
  };

  onRetrieveClick = (_target: any) => {
    const {
      props: { options, onChangeRequest },
      state: { scope, entity }
    } = this;
    // tell parent to load info for robot/cohort
    if (scope === "robot") {
      const name = entity.robotName || entity.displayName;

      !isEmpty(name) &&
        onChangeRequest("entity", {
          robotName: name,
          displayName: entity.displayName
        });
    } else {
      const cohort = options.find(
        cohort => cohort.displayName === entity.displayName
      );
      if (cohort) {
        !isEmpty(cohort.id) &&
          onChangeRequest("entity", {
            cohortId: cohort.id,
            displayName: entity.displayName
          });
      }
    }
    this.setState({ isDirty: false });
  };

  render() {
    const {
      _handleScopeChange,
      _handleEntityChange,
      _handleAutocompleteOptions,
      onRetrieveClick,
      _getOptionSelected,
      props: { classes },
      state: { scope, entity, options, isDirty }
    } = this;
    const getLabel = () => {
      if (scope === "robot") {
        return "Robot name";
      } else {
        return "Cohort name";
      }
    };
    const autoCompleteValue = { displayName: entity.displayName };

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={classes.header}>
            <div className={classes.addAttributes}>
              <Typography className={classes.title} variant="h6" noWrap>
                Manage
              </Typography>
              <div className={classes.selector}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className={classes.selectorObject}
                  value={scope}
                  onChange={_handleScopeChange}
                >
                  <MenuItem value={"robot"}>Robot</MenuItem>
                  <MenuItem value={"cohort"}>Cohort</MenuItem>
                </Select>
              </div>
              <Typography className={classes.title} variant="h6" noWrap>
                View
              </Typography>
            </div>
            <div className={classes.toolbar}>
              <Autocomplete
                id="combo-box-demo"
                options={_handleAutocompleteOptions(options)}
                getOptionLabel={option => option.displayName}
                getOptionSelected={(option, value) =>
                  _getOptionSelected(option, value)
                }
                onChange={_handleEntityChange}
                value={autoCompleteValue}
                style={{ width: 300, height: 56, margin: 6 }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={getLabel()}
                    variant="outlined"
                  />
                )}
              />
              <Button
                className={isDirty ? classes.isDirtyAlt : classes.altBtn}
                variant="contained"
                color="primary"
                onClick={onRetrieveClick}
                disabled={!entity}
              >
                Retrieve
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(RobotCohortSelector)
);
