import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router";

import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";

import { ApplicationState } from "../../redux";
import { listStoppagesRequest } from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { grpc } from "@improbable-eng/grpc-web";
import { logInPath } from "../../utils/Paths";
import InterventionsSheet from "../Sheets/InterventionsSheet";
import EventFilterSearchBar from "../Utils/EventFilterSearchBar";
import LabeledCircularProgress from "../Utils/LabeledCircularProgress";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      height: "100%",
      padding: 0,
      paddingBottom: 16,
      display: "flex",
      flexDirection: "column"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  isLoading: boolean;
  loadingProgress: number;
  redirectTo: string | null;
  stats: Array<m_pb.Stoppage.AsObject>;
}

class ListStoppages extends Component<Props, State> {
  state: State = {
    isLoading: false,
    redirectTo: null,
    stats: [],
    loadingProgress: 0
  };

  _fetch(filter: m_pb.EventFilter, nextPageToken = "", newFetch = true) {
    this.setState({ isLoading: true, loadingProgress: newFetch ? 0 : this.state.loadingProgress }, () => {
      this.props
        .dispatch(listStoppagesRequest(filter, nextPageToken))
        .then((res: m_pb.ListStoppagesResponse.AsObject) => {
          const stats = newFetch ? res.stoppagesList : this.state.stats.concat(res.stoppagesList);
          if (res.nextPageToken) {
            this.setState({
              stats,
              isLoading: true,
              loadingProgress: 100 * stats.length / res.count
            });
            this._fetch(filter, res.nextPageToken, false);
          } else {
            this.setState({
              stats,
              isLoading: false
            });
          }
        })
        .catch((e: ServiceError) => {
          switch (e.code) {
            case grpc.Code.Unauthenticated: {
              this.setState({
                redirectTo: logInPath(window.location.pathname)
              });
              break;
            }
            // TODO(malcolm): Add pages for permission denied, 500 error
          }
          this.setState({ isLoading: false })
        })
    });
  }

  render() {
    const { classes } = this.props;
    const { isLoading, stats, redirectTo, loadingProgress } = this.state;
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    const progressSpinner = isLoading ? <LabeledCircularProgress loadingProgress={loadingProgress} /> : null;
    const sheet = stats.length ? <InterventionsSheet stats={stats} loading={isLoading}/> : null;
    return (
      <div className={classes.wrapper}>
        <EventFilterSearchBar
          disable={isLoading}
          title={"Stoppages"}
          onRequestSubmit={f => this._fetch(f)}
        />
        {progressSpinner}
        {sheet}
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(ListStoppages));
