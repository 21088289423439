// source: planner/proto/steering_control.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.fox.proto.LinearTrajectoryStats', null, global);
goog.exportSymbol('proto.fox.proto.LinearTrajectoryTestRun', null, global);
goog.exportSymbol('proto.fox.proto.SteeringControlModel', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LinearTrajectoryStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.LinearTrajectoryStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LinearTrajectoryStats.displayName = 'proto.fox.proto.LinearTrajectoryStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SteeringControlModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SteeringControlModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SteeringControlModel.displayName = 'proto.fox.proto.SteeringControlModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LinearTrajectoryTestRun = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.LinearTrajectoryTestRun.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.LinearTrajectoryTestRun, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LinearTrajectoryTestRun.displayName = 'proto.fox.proto.LinearTrajectoryTestRun';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LinearTrajectoryStats.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LinearTrajectoryStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LinearTrajectoryStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LinearTrajectoryStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    endVelocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    endError: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    targetVelocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    kpCurtis: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LinearTrajectoryStats}
 */
proto.fox.proto.LinearTrajectoryStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LinearTrajectoryStats;
  return proto.fox.proto.LinearTrajectoryStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LinearTrajectoryStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LinearTrajectoryStats}
 */
proto.fox.proto.LinearTrajectoryStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEndVelocity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEndError(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTargetVelocity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setKpCurtis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LinearTrajectoryStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LinearTrajectoryStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LinearTrajectoryStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LinearTrajectoryStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEndVelocity();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getEndError();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTargetVelocity();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getKpCurtis();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double end_velocity = 1;
 * @return {number}
 */
proto.fox.proto.LinearTrajectoryStats.prototype.getEndVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LinearTrajectoryStats} returns this
 */
proto.fox.proto.LinearTrajectoryStats.prototype.setEndVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double end_error = 2;
 * @return {number}
 */
proto.fox.proto.LinearTrajectoryStats.prototype.getEndError = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LinearTrajectoryStats} returns this
 */
proto.fox.proto.LinearTrajectoryStats.prototype.setEndError = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double target_velocity = 3;
 * @return {number}
 */
proto.fox.proto.LinearTrajectoryStats.prototype.getTargetVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LinearTrajectoryStats} returns this
 */
proto.fox.proto.LinearTrajectoryStats.prototype.setTargetVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double kp_curtis = 4;
 * @return {number}
 */
proto.fox.proto.LinearTrajectoryStats.prototype.getKpCurtis = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LinearTrajectoryStats} returns this
 */
proto.fox.proto.LinearTrajectoryStats.prototype.setKpCurtis = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SteeringControlModel.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SteeringControlModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SteeringControlModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SteeringControlModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    delayS: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    kp: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    kpCurtis: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SteeringControlModel}
 */
proto.fox.proto.SteeringControlModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SteeringControlModel;
  return proto.fox.proto.SteeringControlModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SteeringControlModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SteeringControlModel}
 */
proto.fox.proto.SteeringControlModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDelayS(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setKp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setKpCurtis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SteeringControlModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SteeringControlModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SteeringControlModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SteeringControlModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDelayS();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getKp();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getKpCurtis();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double delay_s = 1;
 * @return {number}
 */
proto.fox.proto.SteeringControlModel.prototype.getDelayS = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.SteeringControlModel} returns this
 */
proto.fox.proto.SteeringControlModel.prototype.setDelayS = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double kp = 2;
 * @return {number}
 */
proto.fox.proto.SteeringControlModel.prototype.getKp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.SteeringControlModel} returns this
 */
proto.fox.proto.SteeringControlModel.prototype.setKp = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double kp_curtis = 3;
 * @return {number}
 */
proto.fox.proto.SteeringControlModel.prototype.getKpCurtis = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.SteeringControlModel} returns this
 */
proto.fox.proto.SteeringControlModel.prototype.setKpCurtis = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.LinearTrajectoryTestRun.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LinearTrajectoryTestRun.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LinearTrajectoryTestRun} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LinearTrajectoryTestRun.toObject = function(includeInstance, msg) {
  var f, obj = {
    statsList: jspb.Message.toObjectList(msg.getStatsList(),
    proto.fox.proto.LinearTrajectoryStats.toObject, includeInstance),
    computedModel: (f = msg.getComputedModel()) && proto.fox.proto.SteeringControlModel.toObject(includeInstance, f),
    minSteer: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    maxSteer: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LinearTrajectoryTestRun}
 */
proto.fox.proto.LinearTrajectoryTestRun.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LinearTrajectoryTestRun;
  return proto.fox.proto.LinearTrajectoryTestRun.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LinearTrajectoryTestRun} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LinearTrajectoryTestRun}
 */
proto.fox.proto.LinearTrajectoryTestRun.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.LinearTrajectoryStats;
      reader.readMessage(value,proto.fox.proto.LinearTrajectoryStats.deserializeBinaryFromReader);
      msg.addStats(value);
      break;
    case 2:
      var value = new proto.fox.proto.SteeringControlModel;
      reader.readMessage(value,proto.fox.proto.SteeringControlModel.deserializeBinaryFromReader);
      msg.setComputedModel(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinSteer(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxSteer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LinearTrajectoryTestRun.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LinearTrajectoryTestRun} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LinearTrajectoryTestRun.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.LinearTrajectoryStats.serializeBinaryToWriter
    );
  }
  f = message.getComputedModel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.SteeringControlModel.serializeBinaryToWriter
    );
  }
  f = message.getMinSteer();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMaxSteer();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * repeated LinearTrajectoryStats stats = 1;
 * @return {!Array<!proto.fox.proto.LinearTrajectoryStats>}
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.getStatsList = function() {
  return /** @type{!Array<!proto.fox.proto.LinearTrajectoryStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.LinearTrajectoryStats, 1));
};


/**
 * @param {!Array<!proto.fox.proto.LinearTrajectoryStats>} value
 * @return {!proto.fox.proto.LinearTrajectoryTestRun} returns this
*/
proto.fox.proto.LinearTrajectoryTestRun.prototype.setStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.LinearTrajectoryStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LinearTrajectoryStats}
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.addStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.LinearTrajectoryStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LinearTrajectoryTestRun} returns this
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.clearStatsList = function() {
  return this.setStatsList([]);
};


/**
 * optional SteeringControlModel computed_model = 2;
 * @return {?proto.fox.proto.SteeringControlModel}
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.getComputedModel = function() {
  return /** @type{?proto.fox.proto.SteeringControlModel} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.SteeringControlModel, 2));
};


/**
 * @param {?proto.fox.proto.SteeringControlModel|undefined} value
 * @return {!proto.fox.proto.LinearTrajectoryTestRun} returns this
*/
proto.fox.proto.LinearTrajectoryTestRun.prototype.setComputedModel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.LinearTrajectoryTestRun} returns this
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.clearComputedModel = function() {
  return this.setComputedModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.hasComputedModel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double min_steer = 3;
 * @return {number}
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.getMinSteer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LinearTrajectoryTestRun} returns this
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.setMinSteer = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double max_steer = 4;
 * @return {number}
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.getMaxSteer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LinearTrajectoryTestRun} returns this
 */
proto.fox.proto.LinearTrajectoryTestRun.prototype.setMaxSteer = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


goog.object.extend(exports, proto.fox.proto);
