import { all, put, take, takeEvery, takeLatest } from "redux-saga/effects";

import m_pb from "../_proto/command_control/monitoring/proto/monitoring_pb";
import cc_pb from "../_proto/command_control/proto/command_control_pb";
import { MonitoringClient } from "../_proto/command_control/monitoring/proto/monitoring_pb_service";
import config_pb from "../_proto/robot/proto/config_pb";

import * as actions from "./actions";
import { ActionTypes } from "./actions";
import * as payloads from "./payloads";
import { BrowserHeaders } from "browser-headers";
import { getTrelloToken, trelloApiKey } from "../utils/trello";

const authTokenName = "monitoringJwtToken";

export function setJwt(token: string) {
  localStorage.setItem(authTokenName, token);
}

export function clearJwt(): void {
  localStorage.removeItem(authTokenName);
}

export function authHeader(): BrowserHeaders {
  const token: string | null = localStorage.getItem(authTokenName);
  if (!token) {
    return new BrowserHeaders();
  }
  return new BrowserHeaders({ Authorization: `Bearer ${token}` });
}

function* listRunMetadatasRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_RUN_METADATA_REQUEST],
    function* (action: actions.ListRunMetadatasRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListRunMetadatasRequest();
      if (payload.pagination) {
        request.setPageSize(payload.pagination.pageSize);
        request.setPageToken(payload.pagination.pageToken);
      }
      request.setRunFilter(payload.runFilter);
      try {
        const response = yield new Promise<m_pb.ListRunMetadatasResponse>(
          (resolve, reject) => {
            client.listRunMetadatas(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listRunMetadasReceive(response, meta));
      } catch (e) {
        yield put(actions.listRunMetadatasError(e, meta));
      }
    }
  );
}

function* listGoalMetadatasRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_GOAL_METADATA_REQUEST],
    function* (action: actions.ListGoalMetadatasRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListGoalMetadatasRequest();
      if (payload.pagination) {
        request.setPageSize(payload.pagination.pageSize);
        request.setPageToken(payload.pagination.pageToken);
      }
      request.setGoalIdsList(payload.goalIds);
      try {
        const response = yield new Promise<m_pb.ListGoalMetadatasResponse>(
          (resolve, reject) => {
            client.listGoalMetadatas(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listGoalMetadasReceive(response, meta));
      } catch (e) {
        yield put(actions.listGoalMetadatasError(e, meta));
      }
    }
  );
}

function* listExternalBugsRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_EXTERNAL_BUGS_REQUEST],
    function* (action: actions.ListExternalBugsRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListExternalBugsRequest();
      if (payload.pagination) {
        request.setPageSize(payload.pagination.pageSize);
        request.setPageToken(payload.pagination.pageToken);
      }
      request.setRunFilter(payload.runFilter);
      try {
        const response = yield new Promise<m_pb.ListExternalBugsResponse>(
          (resolve, reject) => {
            client.listExternalBugs(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listExternalBugsReceive(response, meta));
      } catch (e) {
        yield put(actions.listExternalBugsError(e, meta));
      }
    }
  );
}

function* listAccountsRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_ACCOUNTS_REQUEST],
    function* (action: actions.ListAccountsRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListMonitoringAccountsRequest();
      request.setAccountIdsList(payload.accountIds);
      try {
        const response = yield new Promise<m_pb.ListMonitoringAccountsResponse>(
          (resolve, reject) => {
            client.listMonitoringAccounts(
              request,
              authHeader(),
              (error, res) => {
                if (error) {
                  reject(error);
                }
                if (!res) {
                  reject(new Error("Empty response"));
                }
                res && resolve(res);
              }
            );
          }
        );
        yield put(actions.listAccountsReceive(response, meta));
      } catch (e) {
        yield put(actions.listAccountsError(e, meta));
      }
    }
  );
}

function* listRobotAccountsRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_ROBOT_ACCOUNTS_REQUEST],
    function* (action: actions.ListRobotAccountsRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListRobotAccountsRequest();
      request.setPageToken(payload.pageToken);
      request.setPageSize(payload.pageSize);
      try {
        const response = yield new Promise<m_pb.ListRobotAccountsResponse>(
          (resolve, reject) => {
            client.listRobotAccounts(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listRobotAccountsReceive(response, meta));
      } catch (e) {
        yield put(actions.listRobotAccountsError(e, meta));
      }
    }
  );
}

function* upsertRunReviewRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.UPSERT_RUN_REVIEW_REQUEST],
    function* (action: actions.UpsertRunReviewRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.UpsertRunReviewRequest();
      request.setRunReview(payload.runReview);
      try {
        const response = yield new Promise<m_pb.RunMetadata>(
          (resolve, reject) => {
            client.upsertRunReview(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.upsertRunReviewReceive(response, meta));
      } catch (e) {
        yield put(actions.upsertRunReviewError(e, meta));
      }
    }
  );
}

function* getTrelloCard(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.GET_TRELLO_CARD_REQUEST],
    function* (action: actions.GetTrelloCardRequestTypeDef) {
      const { meta, payload } = action;
      const trelloToken = getTrelloToken();
      if (!trelloToken) {
        return;
      }
      const cardUrl = `https://api.trello.com/1/card/${payload.cardId.trim()}/?fields=name,closed,url,badges,idAttachmentCover,labels&attachments=cover&customFields=true&customFieldItems=true&members=true&stickers=true&key=${trelloApiKey}&token=${trelloToken}`;
      try {
        const response = yield fetch(cardUrl).then(resp => resp.json());
        yield put(actions.getTrelloCardReceive(response, meta));
      } catch (e) {
        console.warn(`Failed to fetch trello card: ${e}`);
        yield put(actions.getTimeSeriesError(e, meta));
      }
    }
  );
}

function* listForkliftCohortsRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_FORKLIFT_COHORTS_REQUEST],
    function* (action: actions.ListForkliftCohortsRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListForkliftCohortsRequest();
      if (payload.pagination) {
        request.setPageSize(payload.pagination.pageSize);
        request.setPageToken(payload.pagination.pageToken);
      }
      try {
        const response = yield new Promise<m_pb.ListForkliftCohortsResponse>(
          (resolve, reject) => {
            client.listForkliftCohorts(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listForkliftCohortsReceive(response, meta));
      } catch (e) {
        yield put(actions.listForkliftCohortsError(e, meta));
      }
    }
  );
}

function* createForkliftCohortRequest(client: MonitoringClient) {
    yield takeLatest(
        [ActionTypes.CREATE_FORKLIFT_COHORT_REQUEST],
        function* (action: actions.CreateForkliftCohortRequestTypeDef) {
            const {meta, payload} = action;
            const request = new m_pb.CreateForkliftCohortRequest();
            if (!payload.displayName) {
                console.warn("Cohort must have display name");
                return;
            }
            request.setDisplayName(payload.displayName);
            request.setOrganizationName(payload.organizationName);
            request.setSiteId(payload.siteId);
            request.setLocationCode(payload.locationCode);
            try {
                const response = yield new Promise<m_pb.ForkliftCohort>(
                    (resolve, reject) => {
                        client.createForkliftCohort(request, authHeader(), (error, res) => {
                            if (error) {
                                reject(error);
                            }
                            if (!res) {
                                reject(new Error("Empty response"));
                            }
                            res && resolve(res);
                        });
                    }
                );
                yield put(actions.createForkliftCohortReceive(response, meta));
            } catch (e) {
                yield put(actions.createForkliftCohortError(e, meta));
            }
        }
    );
}

function* updateForkliftCohortRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.UPDATE_FORKLIFT_COHORT_REQUEST],
    function* (action: actions.UpdateForkliftCohortRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.UpdateForkliftCohortRequest();
      if (!payload.displayName) {
        console.warn("Cohort must have display name");
        return;
      }
      request.setDisplayName(payload.displayName);
      request.setOrganizationName(payload.organizationName);
      request.setSiteId(payload.siteId);
      request.setLocationCode(payload.locationCode);
      try {
        const response = yield new Promise<m_pb.ForkliftCohort>(
          (resolve, reject) => {
            client.updateForkliftCohort(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.updateForkliftCohortReceive(response, meta));
      } catch (e) {
        console.error(`Failed to update cohort: ${e}`);
      }
    }
  );
}

function* createSiteRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.CREATE_SITE_REQUEST],
    function* (action: actions.CreateSiteRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.CreateSiteRequest();
      request.setCustomer(payload.customer);
      request.setSubCustomer(payload.subCustomer);
      request.setCity(payload.city);
      request.setState(payload.state);
      request.setPostalCode(payload.postalCode);
      request.setStreetAddress(payload.streetAddress);
      request.setNearestAirportCode(payload.nearestAirportCode);
      try {
        const response = yield new Promise<m_pb.Site>(
          (resolve, reject) => {
            client.createSite(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.createSiteReceive(response, meta));
      } catch (e) {
        yield put(actions.createSiteError(e, meta));
      }
    }
  );
}

function* getForkliftCohortRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.GET_FORKLIFT_COHORT_REQUEST],
    function* (action: actions.GetForkliftCohortRequestTypeDef) {
      const { meta, payload } = action;
      if (!payload.cohortId) {
        console.warn("request must have a cohort id");
        return;
      }
      const request = new m_pb.GetForkliftCohortRequest();
      request.setCohortId(payload.cohortId);
      try {
        const response = yield new Promise<m_pb.ForkliftCohort>(
          (resolve, reject) => {
            client.getForkliftCohort(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.getForkliftCohortReceive(response, meta));
      } catch (e) {
        yield put(actions.getForkliftCohortError(e, meta));
      }
    }
  );
}

function* updateRobotAccountCohortRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.UPDATE_ROBOT_ACCOUNT_COHORT_REQUEST],
    function* (action: actions.UpdateRobotAccountCohortRequestTypeDef) {
      const { meta, payload } = action;
      const { cohortId, robotId } = payload;
      if (!cohortId) {
        console.warn("request must have a cohort id");
        return;
      }
      if (!robotId) {
        console.warn("request must have an account id");
        return;
      }
      const request = new m_pb.UpdateRobotAccountCohortRequest();
      request.setCohortId(cohortId);
      request.setRobotName(robotId);
      try {
        const response = yield new Promise<m_pb.RobotAccount>(
          (resolve, reject) => {
            client.updateRobotAccountCohort(
              request,
              authHeader(),
              (error, res) => {
                if (error) {
                  reject(error);
                }
                if (!res) {
                  reject(new Error("Empty response"));
                }
                res && resolve(res);
              }
            );
          }
        );
        yield put(actions.updateRobotAccountCohortReceive(response, meta));
      } catch (e) {
        yield put(actions.updateRobotAccountCohortError(e, meta));
      }
    }
  );
}

function* updateRobotAccountIpRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.UPDATE_ROBOT_ACCOUNT_IP_REQUEST],
    function* (action: actions.UpdateRobotAccountIpRequestTypeDef) {
      const { meta, payload } = action;
      const { ipAddress, robotId } = payload;
      if (!ipAddress) {
        console.warn("request must have a ip address");
        return;
      }
      if (!robotId) {
        console.warn("request must have an account id");
        return;
      }
      const request = new m_pb.UpdateRobotAccountIpRequest();
      request.setIpAddress(ipAddress);
      request.setRobotName(robotId);
      try {
        const response = yield new Promise<m_pb.RobotAccount>(
          (resolve, reject) => {
            client.updateRobotAccountIp(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.updateRobotAccountIpReceive(response, meta));
      } catch (e) {
        yield put(actions.updateRobotAccountIpError(e, meta));
      }
    }
  );
}

function* updateRobotAccountRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.UPDATE_ROBOT_ACCOUNT_REQUEST],
    function* (action: actions.UpdateRobotAccountRequestTypeDef) {
      const { meta, payload } = action;
      const robotAccount: payloads.RobotAccount = payload.robotAccount;
      const request = new m_pb.UpdateRobotAccountRequest();
      request.setRobotName(robotAccount.robotName);
      if (robotAccount.ipAddress) {
        request.setIpAddress(robotAccount.ipAddress);
      }
      if (robotAccount.lifecycleState) {
        request.setLifecycleState(robotAccount.lifecycleState);
      }
      if (robotAccount.cohortId) {
          request.setCohortId(robotAccount.cohortId);
      }
      if (robotAccount.autonomyVersion) {
          request.setAutonomyVersion(robotAccount.autonomyVersion);
      }
      try {
        const response = yield new Promise<m_pb.RobotAccount>(
          (resolve, reject) => {
            client.updateRobotAccount(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.updateRobotAccountReceive(response, meta));
      } catch (e) {
        yield put(actions.updateRobotAccountError(e, meta));
      }
    }
  );
}

function* createAnnotationRequest(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.ANNOTATION_CREATE_REQUEST],
    function* (action: actions.CreateAnnotationRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.CreateAnnotationRequest();
      request.setAnnotation(payload);
      try {
        const response = yield new Promise<m_pb.Annotation>(
          (resolve, reject) => {
            client.createAnnotation(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.createAnnotationReceive(response, meta));
      } catch (e) {
        yield put(actions.createAnnotationError(e, meta));
      }
    }
  );
}

function* updateAnnotationRequest(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.ANNOTATION_UPDATE_REQUEST],
    function* (action: actions.UpdateAnnotationRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.UpdateAnnotationRequest();
      request.setAnnotation(payload.annotation);
      request.setFieldMask(payload.mask);
      try {
        const response = yield new Promise<m_pb.Annotation>(
          (resolve, reject) => {
            client.updateAnnotation(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.updateAnnotationReceive(response, meta));
      } catch (e) {
        yield put(actions.updateAnnotationError(e, meta));
      }
    }
  );
}

function* deleteAnnotationRequest(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.ANNOTATION_DELETE_REQUEST],
    function* (action: actions.DeleteAnnotationRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.DeleteAnnotationRequest();
      request.setAnnotationId(payload);
      try {
        yield new Promise<void>((resolve, reject) => {
          client.deleteAnnotation(request, authHeader(), (error, res) => {
            if (error) {
              reject(error);
            }
            if (!res) {
              reject(new Error("Empty response"));
            }
            res && resolve();
          });
        });
        yield put(actions.deleteAnnotationReceive(meta));
      } catch (e) {
        yield put(actions.deleteAnnotationError(e, meta));
      }
    }
  );
}

function* getRunMetadataRequest(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.RUN_METADATA_REQUEST],
    function* (action: actions.GetRunMetadatasRequestTypeDef) {
      const { meta, payload } = action;
      if (!payload.runName) {
        console.warn("Must provide run name to get run metadata");
        return;
      }
      const request = new m_pb.GetRunMetadataRequest();
      request.setRunId(payload.runName);
      try {
        const response = yield new Promise<m_pb.RunMetadata>(
          (resolve, reject) => {
            client.getRunMetadata(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.getRunMetadaReceive(response, meta));
      } catch (e) {
        yield put(actions.getRunMetadataError(e, meta));
      }
    }
  );
}

function* getRunPlacedPalletsRequest(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.RUN_PLACED_PALLETS_REQUEST],
    function* (action: actions.GetRunPlacedPalletsRequestTypeDef) {
      const { meta, payload } = action;
      if (!payload.runName) {
        console.warn("Must provide run name to get run metadata");
        return;
      }
      const request = new m_pb.GetRunPlacedPalletsRequest();
      request.setRunName(payload.runName);
      try {
        const response = yield new Promise<m_pb.RunPlacedPallets>(
          (resolve, reject) => {
            client.getRunPlacedPallets(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.getRunPlacedPalletsReceive(response, meta));
      } catch (e) {
        yield put(actions.getRunPlacedPalletsError(e, meta));
      }
    }
  );
}

function* listPickStatsRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_PICK_STATS_REQUEST],
    function* (action: actions.ListPickStatsRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListPickStatsRequest();
      request.setEventFilter(payload.filter);
      request.setNextPageToken(payload.nextPageToken);
      request.setExcludeDriveStats(payload.excludeDriveStats);
      request.setExcludeHumanAssistanceRequests(
        payload.excludeHumanAssistanceRequests
      );
      request.setExcludeManualModeStats(payload.excludeManualModeStats);
      request.setExcludePalletContext(payload.excludePalletContext);
      request.setExcludeRunNotes(payload.excludeRunNotes);
      request.setExcludeStoppages(payload.excludeStoppages);
      request.setExcludeTimingStats(payload.excludeTimingStats);
      request.setExcludeInterventions(payload.excludeInterventions);
      request.setExcludePathFollowerStatus(payload.excludePathFollowerStatus);
      request.setExcludePlasticDetections(payload.excludePlasticDetections);
      try {
        const response = yield new Promise<m_pb.ListPickStatsResponse>(
          (resolve, reject) => {
            client.listPickStats(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listPickStatsReceive(response.toObject(), meta));
      } catch (e) {
        yield put(actions.listPickStatsError(e, meta));
      }
    }
  );
}

function* listPickStatsDatastoreRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_PICK_STATS_DATASTORE_REQUEST],
    function* (action: actions.ListPickStatsRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListPickStatsRequest();
      request.setEventFilter(payload.filter);
      request.setNextPageToken(payload.nextPageToken);
      try {
        const response = yield new Promise<m_pb.ListPickStatsResponse>(
          (resolve, reject) => {
            client.listPickStatsDatastore(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listPickStatsReceive(response.toObject(), meta));
      } catch (e) {
        yield put(actions.listPickStatsError(e, meta));
      }
    }
  );
}

function* listRunStatsRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_RUN_STATS_REQUEST],
    function* (action: actions.ListRunStatsRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListRunStatsRequest();
      request.setEventFilter(payload.filter);
      try {
        const response = yield new Promise<m_pb.ListRunStatsResponse>(
          (resolve, reject) => {
            client.listRunStats(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listRunStatsReceive(response.toObject(), meta));
      } catch (e) {
        yield put(actions.listRunStatsError(e, meta));
      }
    }
  );
}

function* listStoppagesRequest(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.LIST_STOPPAGES_REQUEST],
    function* (action: actions.ListStoppagesRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListStoppagesRequest();
      request.setEventFilter(payload.filter);
      request.setNextPageToken(payload.nextPageToken);
      try {
        const response = yield new Promise<m_pb.ListStoppagesResponse>(
          (resolve, reject) => {
            client.listStoppages(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listStoppagesReceive(response.toObject(), meta));
      } catch (e) {
        yield put(actions.listStoppagesError(e, meta));
      }
    }
  );
}

function* listInterventionsRequest(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.LIST_INTERVENTIONS_REQUEST],
    function* (action: actions.ListStoppagesRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListStoppagesRequest();
      request.setEventFilter(payload.filter);
      request.setNextPageToken(payload.nextPageToken);
      try {
        const response = yield new Promise<m_pb.ListStoppagesResponse>(
          (resolve, reject) => {
            client.listInterventions(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listInterventionsReceive(response.toObject(), meta));
      } catch (e) {
        yield put(actions.listInterventionsError(e, meta));
      }
    }
  );
}

function* updateRunAttributeRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.RUN_ATTRIBUTES_UPDATE_REQUEST],
    function* (action: actions.UpdateRunAttributeRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.UpdateRunAttributeRequest();
      request.setAttributeKey(payload.key);
      request.setAttributeValue(payload.value);
      request.setRunName(payload.runName);
      try {
        const response = yield new Promise<m_pb.RunMetadata>(
          (resolve, reject) => {
            client.updateRunAttribute(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.updateRunAttributeReceive(response, meta));
      } catch (e) {
        yield put(actions.updateRunAttributeError(e, meta));
      }
    }
  );
}
function* deleteRunAttributeRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.RUN_ATTRIBUTES_DELETE_REQUEST],
    function* (action: actions.DeleteRunAttributeRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.DeleteRunAttributeRequest();
      request.setAttributeKey(payload.key);
      request.setRunName(payload.runName);
      try {
        const response = yield new Promise<m_pb.RunMetadata>(
          (resolve, reject) => {
            client.deleteRunAttribute(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.deleteRunAttributeReceive(response, meta));
      } catch (e) {
        yield put(actions.deleteRunAttributeError(e, meta));
      }
    }
  );
}

function* getTimeSeriesMetadataRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.GET_TIME_SERIES_REQUEST],
    function* (action: actions.GetTimeSeriesRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.GetTimeSeriesMetricRequest();
      request.setBucketDuration(action.payload.request.bucketDuration);
      request.setType(action.payload.request.type);
      const pickPlaceFilter = new m_pb.PickPlaceFilter();
      pickPlaceFilter.setPickRowDepthMin(
        payload.request.pickPlaceFilter.pickRowDepthMin
      );
      pickPlaceFilter.setPickRowDepthMax(
        payload.request.pickPlaceFilter.pickRowDepthMax
      );
      pickPlaceFilter.setExcludedInterventionTypesList(
        payload.request.pickPlaceFilter.excludedInterventionTypesList
      );
      pickPlaceFilter.setExcludedInterventionCausesList(
        payload.request.pickPlaceFilter.excludedInterventionCausesList
      );
      pickPlaceFilter.setExcusedInterventionCausesList(
        payload.request.pickPlaceFilter.excusedInterventionCausesList
      );
      request.setPickPlaceFilter(pickPlaceFilter);
      // TODO(malcolm): Move GetTimeSeriesRequestTypeDef from .AsObject to a pure
      // m_pb.EventFilter proto and copy the event filter directly to the request
      // payload.
      const filter = new m_pb.EventFilter();
      filter.setStartTime(payload.request.eventFilter.startTime);
      filter.setEndTime(payload.request.eventFilter.endTime);
      filter.setMinimumRunMinutes(
        payload.request.eventFilter.minimumRunMinutes
      );
      filter.setRobotIdsList(payload.request.eventFilter.robotIdsList);
      filter.setRunIdsList(payload.request.eventFilter.runIdsList);
      filter.setRunNameLike(payload.request.eventFilter.runNameLike);
      filter.setCohortDisplayNameLike(
        payload.request.eventFilter.cohortDisplayNameLike
      );
      filter.setReleaseChannel(payload.request.eventFilter.releaseChannel);
      filter.setGitBranchLike(payload.request.eventFilter.gitBranchLike);
      filter.setFlagFile(payload.request.eventFilter.flagFile);
      filter.setVersionName(payload.request.eventFilter.versionName);
      filter.setRunNote(payload.request.eventFilter.runNote);
      filter.setOrganizationNameLike(
        payload.request.eventFilter.organizationNameLike
      );
      payload.request.eventFilter.attributeFiltersList.forEach(
        (attr: m_pb.RunAttributeFilter.AsObject) => {
          const pilotFilter = new m_pb.RunAttributeFilter();
          pilotFilter.setKey(attr.key);
          pilotFilter.setValue(attr.value);
          filter.getAttributeFiltersList().push(pilotFilter);
        }
      );
      request.setEventFilter(filter);
      try {
        const response = yield new Promise<m_pb.TimeSeriesMetric>(
          (resolve, reject) => {
            client.getTimeSeriesMetric(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.getTimeSeriesReceive(response.toObject(), meta));
      } catch (e) {
        yield put(actions.getTimeSeriesError(e, meta));
      }
    }
  );
}

function* getFaultCountTimeSeriesRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.GET_FAULT_COUNT_TIME_SERIES_REQUEST],
    function* (action: actions.GetFaultCountTimeSeriesRequestTypeDef) {
      const { meta, payload } = action;
      const { request } = payload;
      try {
        const response = yield new Promise<m_pb.FaultCountTimeSeries>(
          (resolve, reject) => {
            client.getFaultCountTimeSeries(
              request,
              authHeader(),
              (error, res) => {
                if (error) {
                  reject(error);
                }
                if (!res) {
                  reject(new Error("Empty response"));
                }
                res && resolve(res);
              }
            );
          }
        );
        yield put(actions.getFaultCountTimeSeriesReceive(response, meta));
      } catch (e) {
        yield put(actions.getFaultCountTimeSeriesError(e, meta));
      }
    }
  );
}

function* getScalarMetricRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.GET_SCALAR_METRIC_REQUEST],
    function* (action: actions.GetScalarMetricRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.GetScalarMetricRequest();
      request.setType(action.payload.request.type);
      // TODO(malcolm): Move GetScalarMetricRequestTypeDef from .AsObject to a
      // pure m_pb.EventFilter proto and copy the event filter directly to the
      // request payload.
      const filter = new m_pb.EventFilter();
      filter.setStartTime(payload.request.eventFilter.startTime);
      filter.setEndTime(payload.request.eventFilter.endTime);
      filter.setMinimumRunMinutes(
        payload.request.eventFilter.minimumRunMinutes
      );
      filter.setRobotIdsList(payload.request.eventFilter.robotIdsList);
      filter.setRunIdsList(payload.request.eventFilter.runIdsList);
      filter.setRunNameLike(payload.request.eventFilter.runNameLike);
      filter.setCohortDisplayNameLike(
        payload.request.eventFilter.cohortDisplayNameLike
      );
      filter.setReleaseChannel(payload.request.eventFilter.releaseChannel);
      filter.setGitBranchLike(payload.request.eventFilter.gitBranchLike);
      filter.setFlagFile(payload.request.eventFilter.flagFile);
      filter.setVersionName(payload.request.eventFilter.versionName);
      filter.setRunNote(payload.request.eventFilter.runNote);
      payload.request.eventFilter.attributeFiltersList.forEach(
        (attr: m_pb.RunAttributeFilter.AsObject) => {
          const pilotFilter = new m_pb.RunAttributeFilter();
          pilotFilter.setKey(attr.key);
          pilotFilter.setValue(attr.value);
          filter.getAttributeFiltersList().push(pilotFilter);
        }
      );
      request.setEventFilter(filter);
      try {
        const response = yield new Promise<m_pb.ScalarMetric>(
          (resolve, reject) => {
            client.getScalarMetric(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.getScalarMetricReceive(response.toObject(), meta));
      } catch (e) {
        yield put(actions.getScalarMetricError(e, meta));
      }
    }
  );
}

function* logOutRequest(client: MonitoringClient) {
  yield takeLatest([ActionTypes.LOG_OUT_REQUEST], function* () {
    clearJwt();
    yield put(actions.logoutReceive());
    // TODO(malcolm): don't call reload directly so that this is testable
    window.location.reload();
  });
}

function* signUpRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.SIGN_UP_REQUEST],
    function* (action: actions.SignUpRequestTypeDef) {
      const { meta, payload } = action;
      const { email, password } = payload;
      const request = new m_pb.SignUpRequest();
      request.setEmail(email);
      request.setPassword(password);
      try {
        const response: m_pb.SignUpResponse =
          yield new Promise<m_pb.SignUpResponse>((resolve, reject) => {
            client.signUp(request, (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && setJwt(res.getAuthToken());
              res && resolve(res);
            });
          });
        const accountProto = response.getAccount();
        if (!accountProto) {
          yield put(actions.logoutRequest());
          return;
        }
        const account = payloads.Account.fromProto(accountProto);
        yield put(actions.signUpReceive(account, meta));
      } catch (e) {
        yield put(actions.signUpError(e, meta));
      }
    }
  );
}

function* logInRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LOG_IN_REQUEST],
    function* (action: actions.LogInRequestTypeDef) {
      const { meta, payload } = action;
      const { email, password } = payload;
      const request = new m_pb.LogInRequest();
      request.setEmail(email);
      request.setPassword(password);
      try {
        const response: m_pb.LogInResponse =
          yield new Promise<m_pb.LogInResponse>((resolve, reject) => {
            client.logIn(request, (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && setJwt(res.getAuthToken());
              res && resolve(res);
            });
          });
        const accountProto = response.getAccount();
        if (!accountProto) {
          yield put(actions.logoutRequest());
          return;
        }
        const account = payloads.Account.fromProto(accountProto);
        yield put(actions.logInReceive(account, meta));
      } catch (e) {
        yield put(actions.logInError(e, meta));
      }
    }
  );
}

function* viewerRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.VIEWER_REQUEST],
    function* (action: actions.ViewerRequestTypeDef) {
      const request = new m_pb.GetViewerRequest();
      try {
        const response: m_pb.Viewer = yield new Promise<m_pb.Viewer>(
          (resolve, reject) => {
            client.getViewer(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        const authToken = response.getAuthToken();
        authToken && setJwt(authToken);
        const accountProto = response.getAccount();
        const account = accountProto
          ? payloads.Account.fromProto(accountProto)
          : null;
        yield put(actions.viewerReceive({ account }));
      } catch (e) {
        console.error(`Failed to retrieve viewer: ${e}`);
      }
    }
  );
}

function* batchCreateConfigurationFileRevisionsRequest(
  client: MonitoringClient
) {
  yield takeEvery(
    [ActionTypes.CREATE_CONFIGURATION_FILE_REVISIONS_REQUEST],
    function* (
      action: actions.batchCreateConfigurationFileRevisionsRequestTypeDef
    ) {
      const { meta, payload } = action;
      const request = new m_pb.BatchCreateConfigurationFileRevisionsRequest();
      request.setFilesList(payload.configurationFileRevisions);
      try {
        const response: m_pb.BatchCreateConfigurationFileRevisionsResponse =
          yield new Promise<m_pb.BatchCreateConfigurationFileRevisionsResponse>(
            (resolve, reject) => {
              client.batchCreateConfigurationFileRevisions(
                request,
                authHeader(),
                (error, res) => {
                  if (error) {
                    reject(error);
                  }
                  if (!res) {
                    reject(new Error("Empty response"));
                  }
                  res && resolve(res);
                }
              );
            }
          );
        yield put(
          actions.batchCreateConfigurationFileRevisionsReceive(
            response.getFilesList(),
            meta
          )
        );
      } catch (e) {
        yield put(actions.batchCreateConfigurationFileRevisionsError(e, meta));
      }
    }
  );
}

function* listConfigurationRevisionHistoryRequest(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.LIST_CONFIGURATION_REVISION_HISTORY_REQUEST],
    function* (action: actions.listConfigurationRevisionHistoryRequestTypeDef) {
      const { meta, payload } = action;
      try {
        const response: m_pb.ListConfigurationRevisionHistoryResponse =
          yield new Promise<m_pb.ListConfigurationRevisionHistoryResponse>(
            (resolve, reject) => {
              client.listConfigurationRevisionHistory(
                payload.request,
                authHeader(),
                (error, res) => {
                  if (error) {
                    reject(error);
                  }
                  if (!res) {
                    reject(new Error("Empty response"));
                  }
                  res && resolve(res);
                }
              );
            }
          );
        yield put(
          actions.listConfigurationRevisionHistoryReceive(
            response.getRevisionHistoriesList(),
            meta
          )
        );
      } catch (e) {
        yield put(actions.listConfigurationRevisionHistoryError(e, meta));
      }
    }
  );
}

function* getConfigurationFileRevisionRequest(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.GET_CONFIGURATION_FILE_REVISION_REQUEST],
    function* (action: actions.getConfigurationFileRevisionRequestTypeDef) {
      const { meta, payload } = action;
      try {
        const response: m_pb.ConfigurationFileRevision =
          yield new Promise<m_pb.ConfigurationFileRevision>(
            (resolve, reject) => {
              client.getConfigurationFileRevision(
                payload.request,
                authHeader(),
                (error, res) => {
                  if (error) {
                    reject(error);
                  }
                  if (!res) {
                    reject(new Error("Empty response"));
                  }
                  res && resolve(res);
                }
              );
            }
          );
        yield put(actions.getConfigurationFileRevisionReceive(response, meta));
      } catch (e) {
        yield put(actions.getConfigurationFileRevisionError(e, meta));
      }
    }
  );
}

function* uploadFaultTranslations(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.UPLOAD_FAULT_TRANSLATIONS_REQUEST],
    function* (action: actions.UploadFaultTranslationsRequestTypeDef) {
      const {meta, payload} = action;
      const request = payload.request;
      try {
        const response = yield new Promise<cc_pb.FaultTranslations>(
            (resolve, reject) => {
                client.uploadFaultTranslations(request, authHeader(), (error, res) => {
                    if (error) {
                        reject(error);
                    }
                    if (!res) {
                        reject(new Error("Empty response"));
                    }
                    res && resolve(res);
                });
            }
        );
        yield put(actions.uploadFaultTranslationsReceive(response.toObject(), meta));
      } catch (e) {
        yield put(actions.uploadFaultTranslationsError(e, meta));
      }
    }
  );
}
function* publishFaultTranslations(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.PUBLISH_FAULT_TRANSLATIONS_REQUEST],
    function* (action: actions.PublishFaultTranslationsTypeDef) {
      const {meta, payload} = action;
      const request = new m_pb.PublishFaultTranslationsRequest();
      request.setVersionName(payload.versionName);
      try {
        const response = yield new Promise<m_pb.PublishFaultTranslationsResponse>(
            (resolve, reject) => {
                client.publishFaultTranslations(request, authHeader(), (error, res) => {
                    if (error) {
                        reject(error);
                    }
                    if (!res) {
                        reject(new Error("Empty response"));
                    }
                    res && resolve(res);
                });
            }
        );
        yield put(actions.publishFaultTranslationsReceive(response, meta));
      } catch (e) {
        yield put(actions.publishFaultTranslationsError(e, meta));
      }
    }
  );
}
function* listFaultTranslations(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_FAULT_TRANSLATIONS_REQUEST],
    function* (action: actions.ListFaultTranslationsRequestTypeDef) {
      const {meta, payload} = action;
      const {request} = payload;
      try {
        const response = yield new Promise<cc_pb.FaultTranslations>(
            (resolve, reject) => {
                client.listFaultTranslations(request, authHeader(), (error, res) => {
                    if (error) {
                        reject(error);
                    }
                    if (!res) {
                        reject(new Error("Empty response"));
                    }
                    res && resolve(res);
                });
            }
        );
        yield put(actions.listFaultTranslationsReceive(response, meta));
      } catch (e) {
        yield put(actions.listFaultTranslationsError(e, meta));
      }
    }
  );
}
function* createOperatorQuestion(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.CREATE_OPERATOR_QUESTION_REQUEST],
    function* (action: actions.createOperatorQuestionRequestTypeDef) {
      const {meta, payload} = action;
      const {request} = payload;
      try {
        const response = yield new Promise<m_pb.OperatorQuestion>(
            (resolve, reject) => {
                client.createOperatorQuestion(request, authHeader(), (error, res) => {
                    if (error) {
                        reject(error);
                    }
                    if (!res) {
                        reject(new Error("Empty response"));
                    }
                    res && resolve(res);
                });
            }
        );
        yield put(actions.createOperatorQuestionReceive(response, meta));
      } catch (e) {
        yield put(actions.createOperatorQuestionError(e, meta));
      }
    }
  );
}
function* upsertOperatorQuestionTranslation(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.UPSERT_OPERATOR_QUESTION_TRANSLATION_REQUEST],
    function* (action: actions.upsertOperatorQuestionTranslationRequestTypeDef) {
      const {meta, payload} = action;
      const {request} = payload;
      try {
        const response = yield new Promise<m_pb.OperatorQuestion>(
            (resolve, reject) => {
                client.upsertOperatorQuestionTranslation(request, authHeader(), (error, res) => {
                    if (error) {
                        reject(error);
                    }
                    if (!res) {
                        reject(new Error("Empty response"));
                    }
                    res && resolve(res);
                });
            }
        );
        yield put(actions.upsertOperatorQuestionTranslationReceive(response, meta));
      } catch (e) {
        yield put(actions.upsertOperatorQuestionTranslationError(e, meta));
      }
    }
  );
}
function* createOperatorAnswerChoice(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.CREATE_OPERATOR_ANSWER_CHOICE_REQUEST],
    function* (action: actions.createOperatorAnswerChoiceRequestTypeDef) {
      const {meta, payload} = action;
      const {request} = payload;
      try {
        const response = yield new Promise<m_pb.OperatorAnswerChoice>(
            (resolve, reject) => {
                client.createOperatorAnswerChoice(request, authHeader(), (error, res) => {
                    if (error) {
                        reject(error);
                    }
                    if (!res) {
                        reject(new Error("Empty response"));
                    }
                    res && resolve(res);
                });
            }
        );
        yield put(actions.createOperatorAnswerChoiceReceive(response, meta));
      } catch (e) {
        yield put(actions.createOperatorAnswerChoiceError(e, meta));
      }
    }
  );
}
function* upsertOperatorAnswerChoiceTranslation(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.UPSERT_OPERATOR_ANSWER_CHOICE_TRANSLATION_REQUEST],
    function* (action: actions.upsertOperatorAnswerChoiceTranslationRequestTypeDef) {
      const {meta, payload} = action;
      const {request} = payload;
      try {
        const response = yield new Promise<m_pb.OperatorAnswerChoice>(
            (resolve, reject) => {
                client.upsertOperatorAnswerChoiceTranslation(request, authHeader(), (error, res) => {
                    if (error) {
                        reject(error);
                    }
                    if (!res) {
                        reject(new Error("Empty response"));
                    }
                    res && resolve(res);
                });
            }
        );
        yield put(actions.upsertOperatorAnswerChoiceTranslationReceive(response, meta));
      } catch (e) {
        yield put(actions.upsertOperatorAnswerChoiceTranslationError(e, meta));
      }
    }
  );
}
function* listOperatorQuestions(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_OPERATOR_QUESTIONS_REQUEST],
    function* (action: actions.ListOperatorQuestionsRequestTypeDef) {
      const {meta, payload} = action;
      const {request} = payload;
      try {
        const response = yield new Promise<m_pb.ListOperatorQuestionsResponse>(
            (resolve, reject) => {
                client.listOperatorQuestions(request, authHeader(), (error, res) => {
                    if (error) {
                        reject(error);
                    }
                    if (!res) {
                        reject(new Error("Empty response"));
                    }
                    res && resolve(res);
                });
            }
        );
        yield put(actions.listOperatorQuestionsReceive(response, meta));
      } catch (e) {
        yield put(actions.listOperatorQuestionsError(e, meta));
      }
    }
  );
}
function* listOperatorAnswerChoices(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_OPERATOR_ANSWER_CHOICES_REQUEST],
    function* (action: actions.ListOperatorAnswerChoicesRequestTypeDef) {
      const {meta, payload} = action;
      const {request} = payload;
      try {
        const response = yield new Promise<m_pb.ListOperatorAnswerChoicesResponse>(
            (resolve, reject) => {
                client.listOperatorAnswerChoices(request, authHeader(), (error, res) => {
                    if (error) {
                        reject(error);
                    }
                    if (!res) {
                        reject(new Error("Empty response"));
                    }
                    res && resolve(res);
                });
            }
        );
        yield put(actions.listOperatorAnswerChoicesReceive(response, meta));
      } catch (e) {
        yield put(actions.listOperatorAnswerChoicesError(e, meta));
      }
    }
  );
}
function* updateFaultCodeQuestions(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.UPDATE_FAULT_CODE_QUESTIONS_REQUEST],
    function* (action: actions.UpdateFaultCodeQuestionsRequestTypeDef) {
      const {meta, payload} = action;
      const {request} = payload;
      try {
        const response = yield new Promise<m_pb.UpdateFaultCodeQuestionsResponse>(
            (resolve, reject) => {
                client.updateFaultCodeQuestions(request, authHeader(), (error, res) => {
                    if (error) {
                        reject(error);
                    }
                    if (!res) {
                        reject(new Error("Empty response"));
                    }
                    res && resolve(res);
                });
            }
        );
        yield put(actions.updateFaultCodeQuestionsReceive(response, meta));
      } catch (e) {
        yield put(actions.updateFaultCodeQuestionsError(e, meta));
      }
    }
  );
}
function* listTrailerStatsRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_TRAILER_STATS_REQUEST],
    function* (action: actions.ListTrailerStatsRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListTrailerStatsRequest();
      request.setEventFilter(payload.filter);
      request.setNextPageToken(payload.nextPageToken)
      try {
        const response = yield new Promise<m_pb.ListTrailerStatsResponse>(
          (resolve, reject) => {
            client.listTrailerStats(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listTrailerStatsReceive(response.toObject(), meta));
      } catch (e) {
        yield put(actions.listTrailerStatsError(e, meta));
      }
    }
  );
}
function* listTrailerStatsDatastoreRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_TRAILER_STATS_DATASTORE_REQUEST],
    function* (action: actions.ListTrailerStatsRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListTrailerStatsRequest();
      request.setEventFilter(payload.filter);
      request.setNextPageToken(payload.nextPageToken)
      try {
        const response = yield new Promise<m_pb.ListTrailerStatsResponse>(
          (resolve, reject) => {
            client.listTrailerStatsDatastore(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listTrailerStatsDatastoreReceive(response.toObject(), meta));
      } catch (e) {
        yield put(actions.listTrailerStatsError(e, meta));
      }
    }
  );
}
function* listSyncDemandsRequest(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_SYNC_DEMANDS],
    function* (action: actions.ListSyncDemandsTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListSyncDemandsRequest();
      request.setRunName(payload.run_name);
      try {
        const response = yield new Promise<m_pb.ListSyncDemandsResponse>(
          (resolve, reject) => {
            client.listSyncDemands(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listSyncDemandsReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to list sync demands: ${e}`);
      }
    }
  );
}
function* createSyncDemand(client: MonitoringClient) {
  yield takeEvery(
    [ActionTypes.CREATE_SYNC_DEMAND],
    function* (action: actions.CreateSyncDemandTypeDef) {
      const { meta, payload } = action;
      const { request } = payload;
      try {
        const response = yield new Promise<m_pb.SyncDemand>(
          (resolve, reject) => {
            client.createSyncDemand(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.createSyncDemandReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to create sync demand: ${e}`);
      }
    }
  );
}
function* listFaults(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_FAULTS_REQUEST],
    function* (action: actions.ListFaultsRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ListFaultsRequest();
      if (payload.run_names) {
        request.setRunNamesList(payload.run_names);
      }
      if (payload.includeActionAndRegion) {
          request.setIncludeActionAndRegion(true);
      }
      if (payload.eventFilter) {
        request.setRunFilter(payload.eventFilter);
      }
      if (payload.faultCodes) {
        request.setFaultCodesList(payload.faultCodes);
      }
      if (payload.faultTypes) {
        request.setFaultTypesList(payload.faultTypes);
      }
      if (payload.faultSeverities) {
        request.setFaultSeveritiesList(payload.faultSeverities);
      }
      if (payload.nextPageToken) {
        request.setNextPageToken(payload.nextPageToken);
      }
      try {
        const response = yield new Promise<m_pb.ListFaultsResponse>(
          (resolve, reject) => {
            client.listFaults(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listFaultsReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to list sync faults: ${e}`);
      }
    }
  );
}
function* generateEnvironmentConfig(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.GENERATE_ENVIRONMENT_CONFIG_REQUEST],
    function* (action: actions.GenerateEnvironmentConfigRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.GenerateEnvironmentConfigRequest();
      request.setTemplate(payload.template);
      request.setDockConfigsList(payload.docks);
      try {
        const response: m_pb.GenerateEnvironmentConfigResponse =
          yield new Promise<m_pb.GenerateEnvironmentConfigResponse>(
            (resolve, reject) => {
              client.generateEnvironmentConfig(
                request,
                authHeader(),
                (error, res) => {
                  if (error) {
                    reject(error);
                  }
                  if (!res) {
                    reject(new Error("Empty response"));
                  }
                  res && resolve(res);
                }
              );
            }
          );
        yield put(
          actions.generateEnvironmentConfigReceive(
            response.getEnvironmentConfig(),
            meta
          )
        );
      } catch (e) {
        console.error(`Failed to generate environment config: ${e}`);
        yield put(actions.generateEnvironmentConfigError(e, meta));
      }
    }
  );
}
function* parseEnvironmentConfig(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.PARSE_ENVIRONMENT_CONFIG_REQUEST],
    function* (action: actions.GenerateEnvironmentConfigRequestTypeDef) {
      const { meta, payload } = action;
      const request = new m_pb.ParseEnvironmentConfigRequest();
      request.setEnvironmentConfigTextProto(payload.environmentConfigTextProto);
      try {
        const response: m_pb.ParseEnvironmentConfigResponse =
          yield new Promise<m_pb.GenerateEnvironmentConfigResponse>(
            (resolve, reject) => {
              client.parseEnvironmentConfig(
                request,
                authHeader(),
                (error, res) => {
                  if (error) {
                    reject(error);
                  }
                  if (!res) {
                    reject(new Error("Empty response"));
                  }
                  res && resolve(res);
                }
              );
            }
          );
        yield put(actions.parseEnvironmentConfigReceive(response, meta));
      } catch (e) {
        console.error(`Failed to parse environment config: ${e}`);
        yield put(actions.parseEnvironmentConfigError(e, meta));
      }
    }
  );
}
function* listFaultCodes(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_FAULT_CODES_REQUEST],
    function* (action: actions.ListFaultCodesRequestTypeDef) {
      const { meta } = action;
      const request = new m_pb.ListFaultCodesRequest();
      try {
        const response = yield new Promise<m_pb.ListFaultCodesResponse>(
          (resolve, reject) => {
            client.listFaultCodes(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listFaultCodesReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to list fault codes: ${e}`);
      }
    }
  );
}
function* listOrganizations(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_ORGANIZATIONS_REQUEST],
    function* (action: actions.ListOrganizationsRequestTypeDef) {
      const { meta } = action;
      const request = new m_pb.ListOrganizationsRequest();
      try {
        const response = yield new Promise<m_pb.ListOrganizationsResponse>(
          (resolve, reject) => {
            client.listOrganizations(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listOrganizationsReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to list organizations: ${e}`);
      }
    }
  );
}
function* listSites(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_SITES_REQUEST],
    function* (action: actions.ListSitesRequestTypeDef) {
      const { meta } = action;
      const request = new m_pb.ListSitesRequest();
      try {
        const response = yield new Promise<m_pb.ListSitesResponse>(
          (resolve, reject) => {
            client.listSites(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listSitesReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to list sites: ${e}`);
      }
    }
  );
}

function* listAutonomyVersions(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_AUTONOMY_VERSIONS_REQUEST],
    function* (action: actions.ListAutonomyVersionsRequestTypeDef) {
      const { meta } = action;
      const request = new m_pb.ListAutonomyVersionsRequest();
      try {
        const response = yield new Promise<m_pb.ListAutonomyVersionsResponse>(
          (resolve, reject) => {
            client.listAutonomyVersions(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listAutonomyVersionsReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to list autonomy versions: ${e}`);
      }
    }
  );
}

function* createAutonomyVersion(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.CREATE_AUTONOMY_VERSION_REQUEST],
    function* (action: actions.CreateAutonomyVersionRequestTypeDef) {
      const { meta,payload } = action;
      const { request }  = payload;
      try {
        const response = yield new Promise<m_pb.AutonomyVersion>(
          (resolve, reject) => {
            client.createAutonomyVersion(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.createAutonomyVersionRecieve(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to create autonomy version: ${e}`);
      }
    }
  );
}

function* getApexStats(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.GET_APEX_STATS_REQUEST],
    function* (action: actions.GetApexStatsRequestTypeDef) {
      const { meta } = action;
      const request = new m_pb.GetApexStatsRequest();
      try {
        const response = yield new Promise<m_pb.ApexStatsUrls>(
          (resolve, reject) => {
            client.getApexStats(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.getApexStatsReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to get apex stats urls: ${e}`);
      }
    }
  );
}

function* createBulkLogUpload(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.CREATE_BULK_LOG_UPLOAD],
    function* (action: actions.CreateBulkLogUploadTypeDef) {
      const { meta, payload } = action;
      const { runNames }  = payload;
      const request = new m_pb.BulkLogUploadRequest();
      request.setRunNamesList(runNames);
      try {
        const response = yield new Promise<m_pb.BulkLogUpload>(
          (resolve, reject) => {
            client.createBulkLogUpload(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.createBulkLogUploadReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to create bulk log upload: ${e}`);
      }
    }
  );
}

function* listBulkLogUploads(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_BULK_LOG_UPLOADS_REQUEST],
    function* (action: actions.ListBulkLogUploadsRequestTypeDef) {
      const { meta, } = action;
      const request = new m_pb.ListBulkLogUploadsRequest();
      try {
        const response = yield new Promise<m_pb.ListBulkLogUploadsResponse>(
          (resolve, reject) => {
            client.listBulkLogUploads(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listBulkLogUploadsReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to list bulk log uploads: ${e}`);
      }
    }
  );
}

function* listFaultRateAlertThresholds(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.LIST_FAULT_RATE_ALERT_THRESHOLDS_REQUEST],
    function* (action: actions.ListFaultRateAlertThresholdsRequestTypeDef) {
      const { meta, } = action;
      const request = new m_pb.ListFaultRateAlertThresholdsRequest();
      try {
        const response = yield new Promise<m_pb.ListFaultRateAlertThresholdsResponse>(
          (resolve, reject) => {
            client.listFaultRateAlertThresholds(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.listFaultRateAlertThresholdsReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to list fault rate alert thresholds: ${e}`);
      }
    }
  );
}

function* upsertFaultRateAlertThreshold(client: MonitoringClient) {
  yield takeLatest(
    [ActionTypes.UPSERT_FAULT_RATE_ALERT_THRESHOLD_REQUEST],
    function* (action: actions.UpsertFaultRateAlertThresholdRequestTypeDef) {
      const { meta, payload } = action;
      const {faultCode, alertThreshold} = payload;
      const request = new m_pb.UpsertFaultRateAlertThresholdRequest();
      request.setFaultCode(faultCode);
      request.setThreshold(alertThreshold);
      try {
        const response = yield new Promise<m_pb.FaultRateAlertThreshold>(
          (resolve, reject) => {
            client.upsertFaultRateAlertThreshold(request, authHeader(), (error, res) => {
              if (error) {
                reject(error);
              }
              if (!res) {
                reject(new Error("Empty response"));
              }
              res && resolve(res);
            });
          }
        );
        yield put(actions.upsertFaultRateAlertThresholdReceive(response.toObject(), meta));
      } catch (e) {
        console.error(`Failed to upsert fault rate alert threshold: ${e}`);
      }
    }
  );
}

function* applicationStartedSaga() {
  yield take(ActionTypes.APPLICATION_STARTED);
  yield put(actions.viewerRequest());
  const accountsPromise = yield put(actions.listAccountsRequest([]));
  accountsPromise.catch((e: Error) => console.warn(e));
}

/**
 * @generator rootSaga
 *
 * This generator is the entry point to all saga generators. It should be
 * registered with the redux-saga middleware.
 *
 * The clientScanner is parameterized for testing.
 */
export default function* rootSaga(client: MonitoringClient) {
  yield all([
    applicationStartedSaga(),
    listRunMetadatasRequest(client),
    listGoalMetadatasRequest(client),
    listExternalBugsRequest(client),
    getTrelloCard(client),
    createForkliftCohortRequest(client),
    updateForkliftCohortRequest(client),
    createAnnotationRequest(client),
    updateAnnotationRequest(client),
    deleteAnnotationRequest(client),
    listForkliftCohortsRequest(client),
    listAccountsRequest(client),
    listRobotAccountsRequest(client),
    upsertRunReviewRequest(client),
    getRunMetadataRequest(client),
    getRunPlacedPalletsRequest(client),
    listPickStatsRequest(client),
    listPickStatsDatastoreRequest(client),
    listRunStatsRequest(client),
    listStoppagesRequest(client),
    listInterventionsRequest(client),
    updateRunAttributeRequest(client),
    deleteRunAttributeRequest(client),
    getFaultCountTimeSeriesRequest(client),
    getTimeSeriesMetadataRequest(client),
    getScalarMetricRequest(client),
    logInRequest(client),
    logOutRequest(client),
    signUpRequest(client),
    viewerRequest(client),
    getForkliftCohortRequest(client),
    updateRobotAccountCohortRequest(client),
    updateRobotAccountIpRequest(client),
    updateRobotAccountRequest(client),
    batchCreateConfigurationFileRevisionsRequest(client),
    listConfigurationRevisionHistoryRequest(client),
    getConfigurationFileRevisionRequest(client),
    listTrailerStatsRequest(client),
    listTrailerStatsDatastoreRequest(client),
    listSyncDemandsRequest(client),
    createSyncDemand(client),
    listFaults(client),
    generateEnvironmentConfig(client),
    parseEnvironmentConfig(client),
    listFaultCodes(client),
    listOrganizations(client),
    listSites(client),
    listAutonomyVersions(client),
    createAutonomyVersion(client),
    createSiteRequest(client),
    getApexStats(client),
    createBulkLogUpload(client),
    listBulkLogUploads(client),
    listFaultRateAlertThresholds(client),
    upsertFaultRateAlertThreshold(client),
    uploadFaultTranslations(client),
    publishFaultTranslations(client),
    listFaultTranslations(client),
    createOperatorQuestion(client),
    upsertOperatorQuestionTranslation(client),
    createOperatorAnswerChoice(client),
    upsertOperatorAnswerChoiceTranslation(client),
    listOperatorQuestions(client),
    listOperatorAnswerChoices(client),
    updateFaultCodeQuestions(client),
  ]);
}
