import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

export const speedDistributionFromTrailerStats = (trailerStats: m_pb.TrailerStats.AsObject[]) => {
    // TODO: bucket into arbitrary size
    // const bucketSize = 1;
    const speeds = trailerStats.filter(entry => entry.placedStackTotalCount > 10).map(entry => {
        return Math.round(3600 * 1e9 * entry.placedStackTotalCount / entry.allPicksDuration);
    });
    const countBySpeed = new Map<number, number>();
    for (const speed of speeds) {
        countBySpeed.set(speed, (countBySpeed.get(speed) || 0) + 1);
    }
    const result = [];
    for (const entry of countBySpeed.entries()) {
        result.push({palletsPerHour: entry[0], frequency: entry[1] / trailerStats.length});
    }
    return result.sort((a, b) => a.palletsPerHour - b.palletsPerHour);
}

export const interventionFreeSpeedDistributionFromTrailerStats = (trailerStats: m_pb.TrailerStats.AsObject[]) => {
    // TODO: bucket into arbitrary size
    // const bucketSize = 1;
    const speeds = trailerStats.filter(entry => entry.placedStackTotalCount > 10).map(entry => {
        return Math.round(3600 * 1e9 * entry.placedStackSuccessCount / entry.successfulPicksDuration);
    });
    const countBySpeed = new Map<number, number>();
    for (const speed of speeds) {
        countBySpeed.set(speed, (countBySpeed.get(speed) || 0) + 1);
    }
    const result = [];
    for (const entry of countBySpeed.entries()) {
        result.push({palletsPerHour: entry[0], frequency: entry[1] / trailerStats.length});
    }
    return result.sort((a, b) => a.palletsPerHour - b.palletsPerHour);
}


export const interventionDistributionFromTrailerStats = (trailerStats: m_pb.TrailerStats.AsObject[]) => {
    const interventionCounts = trailerStats.filter(entry => entry.placedStackTotalCount > 10).map(entry => {
        return entry.totalInterventions - entry.manuallyInitiatedInterventions;
    });
    const frequencyByInterventionCount = new Map<number, number>();
    for (const interventionCount of interventionCounts) {
        frequencyByInterventionCount.set(interventionCount, (frequencyByInterventionCount.get(interventionCount) || 0) + 1);
    }
    const result = [];
    for (const entry of frequencyByInterventionCount.entries()) {
        result.push({interventions: entry[0], frequency: entry[1] / trailerStats.length});
    }
    return result.sort((a, b) => a.interventions - b.interventions);
}

export const interventionRateDistributionFromTrailerStats = (trailerStats: m_pb.TrailerStats.AsObject[]) => {
    const interventionRates = trailerStats.filter(entry => entry.placedStackTotalCount > 10).map(entry => {
        return Math.round((entry.totalInterventions - entry.manuallyInitiatedInterventions) * 25 / entry.placedStackTotalCount);
    });
    const frequencyByInterventionCount = new Map<number, number>();
    for (const interventionRate of interventionRates) {
        frequencyByInterventionCount.set(interventionRate, (frequencyByInterventionCount.get(interventionRate) || 0) + 1);
    }
    const result = [];
    for (const entry of frequencyByInterventionCount.entries()) {
        result.push({interventions: entry[0], frequency: entry[1] / trailerStats.length});
    }
    return result.sort((a, b) => a.interventions - b.interventions);
}

export const interventionTimeDistribution = (stoppages: m_pb.Stoppage.AsObject[]) => {
    const interventionTimes = stoppages.filter(stoppage => stoppage.duration > 0).filter(stoppage => stoppage.duration < 30 * 60e9).map(stoppage => {
        return Math.round(stoppage.duration / 60e9);
    });
    const frequencyByInterventionTime = new Map<number, number>();
    for (const time of interventionTimes) {
        frequencyByInterventionTime.set(time, (frequencyByInterventionTime.get(time) || 0) + 1);
    }
    const result = [];
    for (const entry of frequencyByInterventionTime.entries()) {
        result.push({duration: entry[0], frequency: entry[1] / stoppages.length});
    }
    return result.sort((a, b) => a.duration - b.duration);
}

export const interventionResponseTimeDistribution = (stoppages: m_pb.Stoppage.AsObject[]) => {
    const interventionResponseTimes = stoppages.filter(stoppage => stoppage.responseTime > 0).filter(stoppage => stoppage.responseTime < 30 * 60e9).map(stoppage => {
        return Math.round(stoppage.responseTime / 60e9);
    });
    const frequencyByInterventionTime = new Map<number, number>();
    for (const time of interventionResponseTimes) {
        frequencyByInterventionTime.set(time, (frequencyByInterventionTime.get(time) || 0) + 1);
    }
    const result = [];
    for (const entry of frequencyByInterventionTime.entries()) {
        result.push({duration: entry[0], frequency: entry[1] / stoppages.length});
    }
    return result.sort((a, b) => a.duration - b.duration);
}
