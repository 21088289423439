import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router";
import moment from "moment-timezone";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { primary, secondary } from "../../App";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ProgressSpinner from "../Utils/ProgressSpinner";
import { Button, Radio, Typography } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import queryString from "query-string";
import { CSVLink } from "react-csv";
import EventFilterSearchBar from "../Utils/EventFilterSearchBar";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import cc_pb, {
  InterventionType
} from "../../_proto/command_control/proto/command_control_pb";

import { ApplicationState } from "../../redux";
import { getTimeSeriesRequest } from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import { logInPath } from "../../utils/Paths";
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {
  TimeBucketValue,
  TimeSeriesMetric,
  TimeSeriesMetricType
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { protoEnumFieldName } from "../../utils/protos";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import { combineStyles, commonStyles } from "../Utils/CommonStyles";

const localStyles = (theme: Theme) =>
  createStyles({
    root: {},
    wrapper: {},
    card: { paddingTop: 0, paddingLeft: 0, paddingRight: 0 },
    chartWrapper: {},
    formControl: {
      display: "flex",
      flexDirection: "row"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 1),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 200
      }
    },
    search: {
      position: "relative",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        maxWidth: 160,
        width: "auto"
      }
    },
    toolbar: {
      display: "flex",
      flexDirection: "row",
      overflow: "auto"
    },
    content: {
      height: 500,
      width: "100%"
    },
    chipArray: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap"
    },
    addAttributes: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around"
    },
    textField: {
      margin: 4,
      marginLeft: 8,
      marginRight: 8
    },
    csvLink: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      color: secondary[400]
    },
    chipPaper: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(1),
      margin: 0
    },
    menuTitle: {
      textTransform: "capitalize",
      paddingLeft: 16
    }
  });
const styles = combineStyles(localStyles, commonStyles);

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  isLoading: boolean;
  bucketWidth: number;
  redirectTo: string | null;
  timeSeries: TimeSeriesMetric.AsObject | null;
  metricType: TimeSeriesMetricType;
  brushStartIndex: number | undefined;
  brushEndIndex: number | null | undefined;
  eventFilter: m_pb.EventFilter;
  pickPlaceFilter: m_pb.PickPlaceFilter;
  showMean: boolean;
  showExcludedInterventionCauseModal: boolean;
  showExcusedInterventionCauseModal: boolean;
  showExcludedInterventionTypeModal: boolean;
}

const second = 1e9;
enum BucketWidths {
  MINUTE = 60 * second,
  HOUR = 60 * 60 * second,
  DAY = 24 * 60 * 60 * second,
  WEEK = 7 * 24 * 60 * 60 * second,
  MONTH = 30 * 24 * 60 * 60 * second
}

type dataMaxFunction = (dataMax: number) => number;
interface DataPrepper {
  tickFormatter: (y: number) => string;
  valuePrepper: (val: number) => number;
  axisName: string;
  valueName: string;
  countName: string;
  unit: string;
  countDomain: [string | number, string | number | dataMaxFunction];
  valueDomain: [string | number, string | number | dataMaxFunction];
  onlyShowCount: boolean;
}

// Round up to the nearest 10
const numberPadder = function (grouping: number) {
  return (dataMax: number) => Math.ceil(dataMax / grouping) * grouping;
};

const durationToPPH = (durationSeconds: number) => {
  return Math.round((10 * (3600 * 1e9)) / durationSeconds) / 10;
};

const formatters: Map<TimeSeriesMetricType, DataPrepper> = new Map([
  [
    TimeSeriesMetricType.PALLET_PICK_PLACE_SUCCESS_RATE,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(y * 1000) / 10,
      axisName: "percent",
      valueName: "Place Percent",
      countName: "# Attempts",
      unit: "%",
      valueDomain: [0, 100],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => (y ? durationToPPH(y) : 0),
      valueName: "Pallets per hour (only successes)",
      countName: "# Successful Places",
      axisName: "Pallets",
      unit: " pallets / hr",
      valueDomain: [0, numberPadder(10)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION_WITH_INTERVENTION,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => (y ? durationToPPH(y) : 0),
      valueName: "Pallets per hour (only interventions)",
      countName: "# Failed Places",
      axisName: "Pallets",
      unit: " pallets / hr",
      valueDomain: [0, numberPadder(10)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION_ALL_PLACES,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => (y ? durationToPPH(y) : 0),
      valueName: "Pallets per hour (all attempts)",
      countName: "# Places",
      axisName: "Pallets",
      unit: " pallets / hr",
      valueDomain: [0, numberPadder(2)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION_INTERVENTION_COST,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(y / 1e9),
      valueName: "Avg Intervention Cost",
      countName: "# Interventions",
      axisName: "Seconds",
      unit: " secs",
      valueDomain: [0, numberPadder(10)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.MANUAL_MODE_DURATION,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(y / 1e9),
      valueName: "Manual Mode Avg Duration",
      countName: "# Manual Mode Interventions",
      axisName: "Seconds",
      unit: " secs",
      valueDomain: [0, numberPadder(10)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.MANUAL_MODE_PER_RUN,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(y / 1e9),
      valueName: "Manual Mode Time Per Run",
      countName: "# Runs",
      axisName: "Seconds",
      unit: " secs",
      valueDomain: [0, numberPadder(10)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.MANUAL_MODE_SETUP_TIME,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(y / 1e9),
      valueName: "Manual Mode Setup Time",
      countName: "# Manual Mode Interventions",
      axisName: "Seconds",
      unit: " secs",
      valueDomain: [0, numberPadder(10)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.RUN_TIME,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(y / 60e9),
      valueName: "Avg Run Time",
      countName: "# Runs",
      axisName: "Minutes",
      unit: " min",
      valueDomain: [0, numberPadder(10)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.CUMULATIVE_PALLET_PICK_PLACE_SUCCESSES,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(y / 1e9),
      valueName: "",
      countName: "# Successful pallets",
      axisName: "",
      unit: "",
      valueDomain: [0, numberPadder(10)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: true
    }
  ],
  [
    TimeSeriesMetricType.CUMULATIVE_PALLET_PICK_PLACE_ALL_PLACES,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(y / 1e9),
      valueName: "",
      countName: "# Total pallets",
      axisName: "",
      unit: "",
      valueDomain: [0, numberPadder(10)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: true
    }
  ],
  [
    TimeSeriesMetricType.SPEED_TOWARDS_FORKS,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(100 * y) / 100,
      valueName: "Speed Towards Forks",
      countName: "# Segments",
      axisName: "",
      unit: "m/s",
      valueDomain: [0, numberPadder(2)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.SPEED_FROM_FORKS,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(100 * y) / 100,
      valueName: "Speed From Forks",
      countName: "# Segments",
      axisName: "",
      unit: "m/s",
      valueDomain: [0, numberPadder(2)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.SPEED_ANY_DIRECTION,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(100 * y) / 100,
      valueName: "Speed Any Direction",
      countName: "# Segments",
      axisName: "",
      unit: "m/s",
      valueDomain: [0, numberPadder(2)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.MAST_LIFT_SPEED_LIFTING,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(100 * y) / 100,
      valueName: "Mast lift speed (lifting)",
      countName: "# Segments",
      axisName: "",
      unit: "m/s",
      valueDomain: [0, numberPadder(0.1)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ],
  [
    TimeSeriesMetricType.MAST_LIFT_SPEED_DESCENDING,
    {
      tickFormatter: (y: number) => y.toString(),
      valuePrepper: (y: number) => Math.round(100 * y) / 100,
      valueName: "Mast lift speed (descending)",
      countName: "# Segments",
      axisName: "",
      unit: "m/s",
      valueDomain: [0, numberPadder(0.1)],
      countDomain: [0, numberPadder(10)],
      onlyShowCount: false
    }
  ]
]);

function valuesFormatter(metricType: TimeSeriesMetricType): DataPrepper {
  const formatter = formatters.get(metricType);
  if (!formatter) {
    throw new Error(`Unrecognized metric type: ${metricType}`);
  }
  return formatter;
}

type formatter = (val: moment.Moment) => string;
function xAxisFormatter(width: BucketWidths): formatter {
  switch (width) {
    case BucketWidths.MINUTE: {
      return val => val.format("DD MMM hh:mm:ss");
    }
    case BucketWidths.HOUR: {
      return val => val.format("DD MMM hh:mm a");
    }
    case BucketWidths.DAY: {
      return val => val.format("DD MMM");
    }
    case BucketWidths.WEEK: {
      return val => val.format("DD MMM");
    }
    case BucketWidths.MONTH: {
      return val => val.format("DD MMM");
    }
  }
  throw new Error(`Unrecognized bucket width: ${width}`);
}
const toolTipStyles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 8
    }
  });

interface TooltipContentProps extends WithStyles<typeof toolTipStyles> {
  active?: boolean;
  label?: string;
  payload?: Array<{ value: string }>;
  formatter: DataPrepper;
  bucketWidth: BucketWidths;
}

const TooltipContent = withStyles(toolTipStyles)(
  (props: TooltipContentProps) => {
    const { active, payload, label, bucketWidth, formatter, classes } = props;
    if (!active || !label || !payload) {
      return null;
    }
    const value = payload[formatter.onlyShowCount ? 0 : 1];
    if (value === undefined) {
      return null;
    }
    const startMsUtc = parseInt(label);
    const endMsUtc = startMsUtc + bucketWidth / 1e6;
    const formatMs = (ms: number): string =>
      xAxisFormatter(bucketWidth)(moment.tz(ms, "GMT").tz("America/Chicago"));
    return (
      <Card className={classes.root}>
        <Typography variant={"caption"}>
          {`${formatMs(startMsUtc)} to ${formatMs(endMsUtc)}`}
        </Typography>
        {!formatter.onlyShowCount && (
          <Typography
            color={"primary"}
          >{`${formatter.valueName} : ${payload[0].value} ${formatter.unit}`}</Typography>
        )}
        <Typography
          color={"secondary"}
        >{`${formatter.countName} : ${value.value}`}</Typography>
      </Card>
    );
  }
);

interface CSVLinkProps {
  csvData: Array<[string, string, string]>;
  metricType: TimeSeriesMetricType;
  formatter: DataPrepper;
}
const csvLinkStyle = {
  color: "inherit",
  textDecoration: "none"
};
const StylableCSVLink = (props: CSVLinkProps) => (
  <CSVLink
    style={csvLinkStyle}
    filename={`${protoEnumFieldName(
      TimeSeriesMetricType,
      props.metricType
    )}.csv`}
    data={props.csvData}
    headers={[
      "Bucket start time",
      props.formatter.valueName,
      props.formatter.countName
    ]}
  >
    Download CSV
  </CSVLink>
);

class ChartTimeSeries extends Component<Props & RouteComponentProps, State> {
  state: State = {
    isLoading: false,
    bucketWidth: BucketWidths.DAY,
    redirectTo: null,
    timeSeries: null,
    metricType: TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION,
    brushStartIndex: 0,
    brushEndIndex: null,
    showMean: false,
    eventFilter: new m_pb.EventFilter(),
    pickPlaceFilter: new m_pb.PickPlaceFilter(),
    showExcusedInterventionCauseModal: false,
    showExcludedInterventionCauseModal: false,
    showExcludedInterventionTypeModal: false
  };

  // TODO: remove deprecated function
  componentWillMount(): void {
    const values = queryString.parse(this.props.location.search);
    const showMean =
      values.showMean === "true" || values.showMean === undefined;
    const bucketWidth = values.bucketWidth
      ? parseInt(values.bucketWidth as string)
      : BucketWidths.DAY;
    const metricType = values.metricType
      ? parseInt(values.metricType as string)
      : TimeSeriesMetricType.PALLET_FULL_PLACE_DURATION;
    const brushStart = parseInt((values.brushStart as string) || "0");
    const brushEnd = values.brushEnd
      ? parseInt(values.brushEnd as string)
      : null;
    const pickDepthMin = parseFloat((values.pickDepthMin as string) || "0");
    const pickDepthMax = parseFloat((values.pickDepthMax as string) || "0");
    const excusedInterventionCauses = (
      (values.excusedInterventionCauses as Array<string>) ||
      (values.excusedInterventionCauses === ""
        ? []
        : [
            cc_pb.InterventionCause.INTERVENTION_CAUSE_OPERATIONAL_STOP,
            cc_pb.InterventionCause.INTERVENTION_CAUSE_OPERATOR_ERROR
          ])
    )
      .filter(i => i !== "")
      .map(i => parseInt(i));
    const excludedInterventionCauses = (
      (values.excludedInterventionCauses as Array<string>) || []
    )
      .filter(i => i !== "")
      .map(i => parseInt(i));
    const excludedInterventionTypes = (
      (values.excludedInterventionTypes as Array<string>) || []
    )
      .filter(i => i !== "")
      .map(i => parseInt(i));

    this.props.history.push({
      search: queryString.stringify({
        ...values,
        bucketWidth,
        metricType,
        brushStart,
        brushEnd,
        showMean,
        pickDepthMin,
        pickDepthMax,
        excusedInterventionCauses,
        excludedInterventionCauses,
        excludedInterventionTypes
      })
    });
    const { pickPlaceFilter } = this.state;
    pickPlaceFilter.setPickRowDepthMin(pickDepthMin);
    pickPlaceFilter.setPickRowDepthMax(pickDepthMax);
    pickPlaceFilter.setExcusedInterventionCausesList(excusedInterventionCauses);
    pickPlaceFilter.setExcludedInterventionCausesList(
      excludedInterventionCauses
    );
    pickPlaceFilter.setExcludedInterventionTypesList(excludedInterventionTypes);
    this.setState({
      bucketWidth,
      metricType,
      showMean,
      brushStartIndex: brushStart,
      brushEndIndex: brushEnd
    });
  }

  _fetchMore() {
    const { bucketWidth, eventFilter, metricType, pickPlaceFilter } =
      this.state;
    if (eventFilter.getEndTime() < eventFilter.getStartTime()) {
      return;
    }
    this.setState({ isLoading: true, timeSeries: null }, () => {
      const finish = () => this.setState({ isLoading: false });
      this.props
        .dispatch(
          getTimeSeriesRequest({
            eventFilter: eventFilter.toObject(),
            bucketDuration: bucketWidth,
            type: metricType,
            pickPlaceFilter: pickPlaceFilter.toObject()
          })
        )
        .then(({ response }: any) => {
          this.setState({ timeSeries: response, isLoading: false });
        })
        .catch((e: ServiceError) => {
          switch (e.code) {
            case grpc.Code.Unauthenticated: {
              this.setState({
                redirectTo: logInPath(window.location.pathname)
              });
              break;
            }
            // TODO(malcolm): Add pages for permission denied, 500 error
          }
        })
        .finally(finish);
    });
  }

  _updateBucket(rawDuration: string) {
    const parsed = Number.parseInt(rawDuration);
    const values = queryString.parse(this.props.location.search);
    values.bucketWidth = rawDuration;
    values.brushStart = undefined;
    values.brushEnd = undefined;
    this.props.history.push({ search: queryString.stringify(values) });
    this.setState({ bucketWidth: parsed }, () => this._fetchMore());
  }

  _updateType(rawType: string) {
    const parsed = Number.parseInt(rawType);
    const values = queryString.parse(this.props.location.search);
    values.metricType = rawType;
    this.props.history.push({ search: queryString.stringify(values) });
    this.setState({ metricType: parsed }, () => this._fetchMore());
  }

  _updateBrushIndexes(brushStartIndex: number, brushEndIndex: number) {
    const values = queryString.parse(this.props.location.search);
    values.brushStart = brushStartIndex.toString();
    values.brushEnd = brushEndIndex.toString();
    const url = this.props.history.createHref({
      ...this.props.location,
      search: queryString.stringify(values)
    });
    window.history.pushState(
      { search: queryString.stringify(values) },
      "",
      url
    );
  }

  _updateShowMean(showMean: boolean) {
    const values = queryString.parse(this.props.location.search);
    values.showMean = showMean ? "true" : "false";
    this.props.history.push({ search: queryString.stringify(values) });
    this.setState({ showMean });
  }

  _updatePickRowDepthMin(min: string) {
    const { pickPlaceFilter } = this.state;
    pickPlaceFilter.setPickRowDepthMin(Number.parseFloat(min));
    const values = queryString.parse(this.props.location.search);
    values.pickDepthMin = min;
    this.props.history.push({ search: queryString.stringify(values) });
    this.setState({ pickPlaceFilter });
  }

  _updatePickRowDepthMax(max: string) {
    const { pickPlaceFilter } = this.state;
    pickPlaceFilter.setPickRowDepthMax(Number.parseFloat(max));
    const values = queryString.parse(this.props.location.search);
    values.pickDepthMax = max;
    this.props.history.push({ search: queryString.stringify(values) });
    this.setState({ pickPlaceFilter });
  }

  _updateExcusedInterventionCauses(causes: Array<cc_pb.InterventionCause>) {
    const { pickPlaceFilter } = this.state;
    pickPlaceFilter.setExcusedInterventionCausesList(causes);
    const values = queryString.parse(this.props.location.search);
    values.excusedInterventionCauses =
      causes.length > 0 ? causes.map(c => c.toString()) : "";
    this.props.history.push({ search: queryString.stringify(values) });
    this.setState({ pickPlaceFilter });
  }

  _updateExcludedInterventionCauses(causes: Array<cc_pb.InterventionCause>) {
    const { pickPlaceFilter } = this.state;
    pickPlaceFilter.setExcludedInterventionCausesList(causes);
    const values = queryString.parse(this.props.location.search);
    values.excusedInterventionCauses = causes.map(c => c.toString());
    this.props.history.push({ search: queryString.stringify(values) });
    this.setState({ pickPlaceFilter });
  }

  _updateExcludedInterventionTypes(types: Array<cc_pb.InterventionType>) {
    const { pickPlaceFilter } = this.state;
    pickPlaceFilter.setExcludedInterventionTypesList(types);
    const values = queryString.parse(this.props.location.search);
    values.excusedInterventionTypes = types.map(t => t.toString());
    this.props.history.push({ search: queryString.stringify(values) });
    this.setState({ pickPlaceFilter });
  }

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      timeSeries,
      redirectTo,
      bucketWidth,
      metricType,
      brushStartIndex,
      brushEndIndex,
      showMean,
      pickPlaceFilter,
      showExcludedInterventionCauseModal,
      showExcusedInterventionCauseModal,
      showExcludedInterventionTypeModal
    } = this.state;
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    const currentFormatter = valuesFormatter(metricType);
    const progressSpinner = isLoading ? <ProgressSpinner /> : null;
    let lineChartData: Array<any> = [];
    const csvData: Array<[string, string, string]> = [];
    if (timeSeries) {
      const valuesList: Array<TimeBucketValue.AsObject> = (timeSeries as any)
        .valuesList;
      let lastZeroValue: TimeBucketValue.AsObject | null = null;
      for (const value of valuesList) {
        // Filter empty buckets to speed up rendering.
        const rawValue = showMean ? value.mean : value.median;
        csvData.push([
          xAxisFormatter(bucketWidth)(
            moment.tz(value.startTime / 1e6, "GMT").tz("America/Chicago")
          ),
          currentFormatter.valuePrepper(rawValue).toString(),
          value.count.toString()
        ]);
        if (
          !rawValue &&
          lastZeroValue &&
          value.startTime - lastZeroValue.startTime < 3000e9
        ) {
          continue;
        }
        const chartValue =
          value.count || currentFormatter.onlyShowCount
            ? currentFormatter.valuePrepper(rawValue)
            : undefined;
        lineChartData.push({
          count: value.count,
          value: chartValue,
          time: value.startTime / 1e6
        });
        if (!rawValue) {
          lastZeroValue = value;
        } else {
          lastZeroValue = null;
        }
      }
    }
    const xFormatter = xAxisFormatter(bucketWidth);
    const safeEndIndex =
      brushEndIndex === null
        ? Math.max(lineChartData.length - 1, 0)
        : brushEndIndex;

    const interventionTypeDialog = !showExcludedInterventionTypeModal ? null : (
      <Dialog
        open
        onClose={() => {
          this._fetchMore();
          this.setState({ showExcludedInterventionTypeModal: false });
        }}
      >
        <DialogTitle id={"intervention-type-filter-title"}>
          Exclude Intervention Types
        </DialogTitle>
        <FormControl component={"fieldset"}>
          <FormGroup>
            {Object.entries(cc_pb.InterventionType).map(iType => (
              <FormControlLabel
                key={iType[1]}
                className={classes.menuTitle}
                label={iType[0]
                  .replace("INTERVENTION_", "")
                  .replace(/_/g, " ")
                  .toLowerCase()}
                control={
                  <Checkbox
                    checked={
                      pickPlaceFilter
                        .getExcludedInterventionTypesList()
                        .find(t => t === iType[1]) !== undefined
                    }
                    onChange={event => {
                      // TODO(chris): fix this
                      event.target.checked
                        ? pickPlaceFilter.addExcludedInterventionTypes(
                            iType[1] as InterventionType
                          )
                        : pickPlaceFilter.setExcludedInterventionTypesList(
                            pickPlaceFilter
                              .getExcludedInterventionTypesList()
                              .filter(i => i !== iType[1])
                          );
                      this._updateExcludedInterventionTypes(
                        pickPlaceFilter.getExcludedInterventionTypesList()
                      );
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      </Dialog>
    );
    const interventionCauseDialog =
      !showExcludedInterventionCauseModal ? null : (
        <Dialog
          open
          onClose={() => {
            this._fetchMore();
            this.setState({ showExcludedInterventionCauseModal: false });
          }}
        >
          <DialogTitle id={"intervention-cause-filter-title"}>
            Exclude Intervention Causes
          </DialogTitle>
          <FormControl component={"fieldset"}>
            <FormGroup>
              {Object.entries(cc_pb.InterventionCause).map(iCause => (
                <FormControlLabel
                  key={iCause[1]}
                  className={classes.menuTitle}
                  label={iCause[0]
                    .replace("INTERVENTION_CAUSE", "")
                    .replace(/_/g, " ")
                    .toLowerCase()}
                  control={
                    <Checkbox
                      checked={
                        pickPlaceFilter
                          .getExcludedInterventionCausesList()
                          .find(t => t === iCause[1]) !== undefined
                      }
                      onChange={event => {
                        // TODO(chris): fix this
                        // event.target.checked
                        //   ? pickPlaceFilter.addExcludedInterventionCauses(
                        //       iCause[1]
                        //     )
                        //   : pickPlaceFilter.setExcludedInterventionCausesList(
                        //       pickPlaceFilter
                        //         .getExcludedInterventionCausesList()
                        //         .filter(i => i !== iCause[1])
                        //     );
                        this._updateExcludedInterventionCauses(
                          pickPlaceFilter.getExcludedInterventionCausesList()
                        );
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Dialog>
      );
    const excusedInterventionCauseDialog =
      !showExcusedInterventionCauseModal ? null : (
        <Dialog
          open
          onClose={() => {
            this._fetchMore();
            this.setState({ showExcusedInterventionCauseModal: false });
          }}
        >
          <DialogTitle id={"excused-intervention-cause-filter-title"}>
            Excused Intervention Causes
          </DialogTitle>
          <FormControl component={"fieldset"}>
            <FormGroup>
              {Object.entries(cc_pb.InterventionCause).map(iCause => (
                <FormControlLabel
                  key={iCause[1]}
                  className={classes.menuTitle}
                  label={iCause[0]
                    .replace("INTERVENTION_CAUSE", "")
                    .replace(/_/g, " ")
                    .toLowerCase()}
                  control={
                    <Checkbox
                      checked={
                        pickPlaceFilter
                          .getExcusedInterventionCausesList()
                          .find(t => t === iCause[1]) !== undefined
                      }
                      onChange={event => {
                        // TODO(chris): fix this
                        // event.target.checked
                        //   ? pickPlaceFilter.addExcusedInterventionCauses(
                        //       iCause[1]
                        //     )
                        //   : pickPlaceFilter.setExcusedInterventionCausesList(
                        //       pickPlaceFilter
                        //         .getExcusedInterventionCausesList()
                        //         .filter(i => i !== iCause[1])
                        //     );
                        this._updateExcusedInterventionCauses(
                          pickPlaceFilter.getExcusedInterventionCausesList()
                        );
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Dialog>
      );

    return (
      <div className={classes.wrapper}>
        <EventFilterSearchBar
          disable={isLoading}
          title={"Time Series"}
          onRequestSubmit={f =>
            this.setState({ eventFilter: f }, () => this._fetchMore())
          }
        />
        {interventionTypeDialog}
        {interventionCauseDialog}
        {excusedInterventionCauseDialog}
        <AppBar position={"static"} color={"default"}>
          <Toolbar className={classes.toolbar}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor={"select-type"}>Metric Type</InputLabel>
              <Select
                onChange={w => this._updateType(w.target.value as any)}
                value={metricType}
                input={<Input id={"select-duration"} />}
              >
                {Array.from(formatters.entries()).map(([mType, formatter]) => (
                  <MenuItem key={mType} value={mType}>
                    {formatter.onlyShowCount
                      ? formatter.countName
                      : formatter.valueName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor={"select-duration"}>Bucket Size</InputLabel>
              <Select
                onChange={w => this._updateBucket(w.target.value as string)}
                value={bucketWidth}
                input={<Input id={"select-duration"} />}
              >
                {Object.values(BucketWidths)
                  .filter(key => !isNaN(Number(key)))
                  //@ts-ignore
                  .map((width: number) => (
                    //@ts-ignore
                    <MenuItem key={width} value={width}>
                      {BucketWidths[width]}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <RadioGroup
                row
                aria-label="Gender"
                name="gender1"
                value={this.state.showMean ? "mean" : "median"}
                onChange={(e: any) => {
                  this._updateShowMean(e.target.value === "mean");
                }}
              >
                <FormControlLabel
                  value="mean"
                  control={<Radio />}
                  label="Mean"
                />
                <FormControlLabel
                  value="median"
                  control={<Radio />}
                  label="Median"
                />
              </RadioGroup>
            </FormControl>
            <div className={classes.search}>
              <TextField
                label={"Min Pick Depth"}
                margin={"dense"}
                variant={"outlined"}
                onChange={e => {
                  this._updatePickRowDepthMin(e.currentTarget.value);
                }}
                defaultValue={pickPlaceFilter.getPickRowDepthMin()}
                onKeyDown={e => e.key === "Enter" && this._fetchMore()}
                disabled={isLoading}
                onBlur={() => this._fetchMore()}
                onSubmit={() => this._fetchMore()}
                type={"number"}
                inputProps={{ "aria-label": "search", step: 0.1 }}
              />
            </div>
            <div className={classes.search}>
              <TextField
                label={"Max Pick Depth"}
                margin={"dense"}
                variant={"outlined"}
                onChange={e => {
                  this._updatePickRowDepthMax(e.currentTarget.value);
                }}
                defaultValue={pickPlaceFilter.getPickRowDepthMax()}
                onKeyDown={e => e.key === "Enter" && this._fetchMore()}
                disabled={isLoading}
                onBlur={() => this._fetchMore()}
                onSubmit={() => this._fetchMore()}
                type={"number"}
                inputProps={{ "aria-label": "search", step: 0.1 }}
              />
            </div>
            <Button
              variant={"outlined"}
              size={"small"}
              color={
                pickPlaceFilter.getExcusedInterventionCausesList().length > 0
                  ? "primary"
                  : "secondary"
              }
              onClick={() =>
                this.setState({
                  showExcusedInterventionCauseModal:
                    !showExcusedInterventionCauseModal
                })
              }
            >
              {`${
                pickPlaceFilter.getExcusedInterventionCausesList().length
              } excused intervention causes`}
            </Button>
            <Button
              variant={"outlined"}
              size={"small"}
              color={
                pickPlaceFilter.getExcludedInterventionCausesList().length > 0
                  ? "primary"
                  : "secondary"
              }
              onClick={() =>
                this.setState({
                  showExcludedInterventionCauseModal:
                    !showExcludedInterventionCauseModal
                })
              }
            >
              {`${
                pickPlaceFilter.getExcludedInterventionCausesList().length
              } excluded intervention causes`}
            </Button>
            <Button
              variant={"outlined"}
              color={
                pickPlaceFilter.getExcludedInterventionTypesList().length > 0
                  ? "primary"
                  : "secondary"
              }
              size={"small"}
              onClick={() =>
                this.setState({
                  showExcludedInterventionTypeModal:
                    !showExcludedInterventionTypeModal
                })
              }
            >
              {`${
                pickPlaceFilter.getExcludedInterventionTypesList().length
              } excluded intervention types`}
            </Button>
          </Toolbar>
        </AppBar>
        {progressSpinner}
        <CardContent className={classes.content}>
          {timeSeries && (
            <ResponsiveContainer>
              <LineChart
                data={lineChartData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis
                  tickFormatter={secs => xFormatter(moment(secs))}
                  dataKey="time"
                  scale="time"
                  type="number"
                  allowDataOverflow
                  domain={["dataMin", "dataMax"]}
                />
                <Brush
                  data={lineChartData}
                  tickFormatter={secs => xFormatter(moment(secs))}
                  dataKey={"time"}
                  stroke={secondary[400]}
                  height={30}
                  onChange={e => {
                    this._updateBrushIndexes(e.startIndex, e.endIndex);
                  }}
                  startIndex={brushStartIndex}
                  endIndex={safeEndIndex || undefined}
                />
                {!currentFormatter.onlyShowCount && (
                  <YAxis
                    unit={currentFormatter.unit}
                    type={"number"}
                    tickFormatter={currentFormatter.tickFormatter}
                    scale={"linear"}
                    yAxisId={"left"}
                    domain={currentFormatter.valueDomain}
                  />
                )}
                <YAxis
                  yAxisId={"right"}
                  orientation={"right"}
                  domain={currentFormatter.countDomain}
                />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip
                  content={
                    <TooltipContent
                      formatter={currentFormatter}
                      bucketWidth={bucketWidth}
                    />
                  }
                />
                <Legend />
                {!currentFormatter.onlyShowCount && (
                  <Line
                    name={currentFormatter.valueName}
                    strokeWidth={4}
                    yAxisId={"left"}
                    dataKey="value"
                    isAnimationActive={false}
                    stroke={primary[400]}
                    activeDot={{ r: 8 }}
                  />
                )}
                <Line
                  name={currentFormatter.countName}
                  yAxisId={"right"}
                  dataKey="count"
                  isAnimationActive={false}
                  type={"step"}
                  strokeWidth={2}
                  stroke={secondary[200]}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
          {timeSeries && (
            <div className={classes.csvLink}>
              <StylableCSVLink
                csvData={csvData}
                metricType={metricType}
                formatter={currentFormatter}
              />
            </div>
          )}
        </CardContent>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(ChartTimeSeries))
);
