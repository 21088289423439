import React, { Component } from "react";
import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";

import { ApplicationState, orderedEntities } from "../../redux";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import {
  updateRobotAccountCohortRequest,
  updateRobotAccountIpRequest, updateRobotAccountRequest
} from "../../redux/actions";
import * as payloads from "../../redux/payloads";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Tooltip, Typography } from "@material-ui/core";

const LIFECYCLE_STATES = ["BURN-IN", "INSTALLATION", "DEPLOYED"];

const styles = (theme: Theme) =>
  createStyles({
    networkItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      minWidth: 200
    },
    robotIp: {
      display: "inline-block",
      width: "160px"
    },
    robotIpLabel: {
      display: "inline-block",
      width: "100%",
      top: 10,
      left: -10
    },
    cohortDropdown: {
      width: "250px",
      top: 5
    },
    cohortLabel: {
      top: -5
    },
    listActions: {
      minWidth: 300,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start"
    },
    fakeBtn: {
      lineHeight: "1.75",
      borderRadius: 4,
      letterSpacing: "0.02857em",
      padding: "6px 16px",
      fontSize: "0.875rem",
      minWidth: 64,
      boxSizing: "border-box"
    },
    typography: {
      fontSize: 12,
      textAlign: "center"
    },
    tooltip: {
      fontSize: 10,
      textAlign: "center"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  robotAccount: payloads.RobotAccount;
  cohorts: Array<m_pb.ForkliftCohort.AsObject>;
  dispatch: any;
  rowClassName: string;
  autonomyVersions: Array<m_pb.AutonomyVersion.AsObject>;
}
interface State {
  waiting: boolean;
  failed: boolean;
}

class RobotAccountListItem extends Component<Props, State> {
  state: State = {
    waiting: false,
    failed: false,
  };

  handleLifecycleStateChange = (e: { target: { value: any } }) => {
    const { robotAccount } = this.props;
    const newLifecycleState = (e && e.target && e.target.value) as string;
    if (!newLifecycleState || newLifecycleState === robotAccount.lifecycleState) {
      return;
    }
    const updatedRobotAccount = new payloads.RobotAccount(robotAccount.id, robotAccount.robotName, robotAccount.cohortId, robotAccount.ipAddress, newLifecycleState, robotAccount.autonomyVersion);
    this.setState({ waiting: true }, () =>
      this.props
        .dispatch(
          updateRobotAccountRequest(updatedRobotAccount)
        )
        .finally(() => this.setState({ waiting: false }))
    );
  }

  handleAutonomyVersionChange = (e: { target: { value: any } }) => {
    const {robotAccount} = this.props;
    const newAutonomyVersion = (e && e.target && e.target.value) as string;
    if (!newAutonomyVersion || newAutonomyVersion === robotAccount.autonomyVersion) {
      return;
    }
    this.setState({waiting: true}, () => {
        const updatedRobotAccount = new payloads.RobotAccount(robotAccount.id, robotAccount.robotName, robotAccount.cohortId, robotAccount.ipAddress, robotAccount.lifecycleState, newAutonomyVersion);
        this.props
            .dispatch(
                updateRobotAccountRequest(updatedRobotAccount)
            )
            .finally(() => this.setState({waiting: false}))

      }
    );
  }
  handleCohortChange = (e: { target: { value: any } }) => {
    const { robotAccount, classes, cohorts } = this.props;
    const { waiting } = this.state;
    const cohortId = (e && e.target && e.target.value) as string;
    if (!cohortId || cohortId === robotAccount.cohortId) {
      return;
    }
    this.setState({ waiting: true }, () =>
      this.props
        .dispatch(
          updateRobotAccountCohortRequest(robotAccount.robotName, cohortId)
        )
        .finally(() => this.setState({ waiting: false }))
    );
  };
  handleIpChange = (e: { target: { value: any } }) => {
    const { robotAccount, classes, cohorts } = this.props;
    const { waiting } = this.state;
    const ipAddress = (e && e.target && e.target.value) as string;
    if (!ipAddress) {
      return;
    }
    this.setState({ waiting: true }, () =>
      this.props
        .dispatch(
          updateRobotAccountIpRequest(robotAccount.robotName, ipAddress)
        )
        .then(() => this.setState({ failed: false }))
        .catch(() => this.setState({ failed: true }))
        .finally(() => this.setState({ waiting: false }))
    );
  };

  render() {
    const { robotAccount, classes, cohorts, rowClassName } = this.props;
    const { waiting, failed } = this.state;
    const tooltipText = waiting ? (
      "processing..."
    ) : failed ? (
      <div className={classes.tooltip}>
        <Typography className={classes.typography}>
          Invalid IP Address:
        </Typography>
        {"Bot is not updated"}
      </div>
    ) : (
      <div className={classes.tooltip}>
        <Typography className={classes.typography}>Valid Configs:</Typography>
        {"Live Bot Configuration"}
      </div>
    );
    return (
      <ListItem divider className={rowClassName}>
        <ListItemText primary={robotAccount.robotName} />
        <ListItemSecondaryAction className={classes.listActions}>
          <FormControl
            size={"small"}
            variant="outlined"
            className={classes.cohortDropdown}
          >
            <InputLabel
              className={classes.cohortLabel}
              htmlFor="component-select"
            >
              Autonomy Version
            </InputLabel>
            <Select
              disabled={waiting}
              inputProps={{ id: "component-select" }}
              value={robotAccount.autonomyVersion}
              onChange={e => this.handleAutonomyVersionChange(e)}
            >
              {this.props.autonomyVersions.map(c => (
                <MenuItem key={c.versionName} value={c.versionName}>
                  {c.versionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            size={"small"}
            variant="outlined"
            className={classes.cohortDropdown}
          >
            <InputLabel
              className={classes.cohortLabel}
              htmlFor="component-select"
            >
              Lifecycle State
            </InputLabel>
            <Select
              disabled={waiting}
              inputProps={{ id: "component-select" }}
              value={robotAccount.lifecycleState}
              onChange={e => this.handleLifecycleStateChange(e)}
            >
              {LIFECYCLE_STATES.map(c => (
                <MenuItem key={c} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            className={classes.networkItem}
            size={"small"}
            variant="outlined"
          >
            <InputLabel
              className={classes.robotIpLabel}
              htmlFor="component-input"
            >
              Robot VPN IP:
            </InputLabel>
            <Input
              className={classes.robotIp}
              defaultValue={robotAccount.ipAddress}
              onChange={e => this.handleIpChange(e)}
            />
          </FormControl>
          <FormControl
            size={"small"}
            variant="outlined"
            className={classes.cohortDropdown}
          >
            <InputLabel
              className={classes.cohortLabel}
              htmlFor="component-select"
            >
              Cohort
            </InputLabel>
            <Select
              disabled={waiting || !cohorts.length}
              inputProps={{ id: "component-select" }}
              value={robotAccount.cohortId}
              onChange={e => this.handleCohortChange(e)}
            >
              {cohorts.map(c => (
                <MenuItem key={c.displayName} value={c.id}>
                  {c.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip title={tooltipText}>
            <div className={classes.fakeBtn}>
              {waiting ? (
                <CircularProgress size={20} />
              ) : failed ? (
                <CloseIcon />
              ) : (
                <CheckIcon />
              )}
            </div>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(RobotAccountListItem)
);
