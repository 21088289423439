
import React, { FunctionComponent } from "react";
import { SvgIconProps } from "@material-ui/core";
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";
import {
  BatteryAlertSharp,
  Battery20Sharp,
  Battery30Sharp,
  Battery50Sharp,
  Battery60Sharp,
  Battery80Sharp,
  Battery90Sharp,
  BatteryFullSharp,
} from '@material-ui/icons';

const styles = (theme: Theme) =>
  createStyles({

  });

interface Props extends WithStyles<typeof styles> {
  level: number;
}

const Component: FunctionComponent<Props & SvgIconProps> = (props: Props & SvgIconProps) => {
  const { level, ...svgProps } = props;

  const getBatteryIcon = (level: number): JSX.Element | null => {
    if (level < 0.2) {
        return <BatteryAlertSharp {...svgProps}/>;
    } else if (level < 0.25) {
        return <Battery20Sharp {...svgProps}/>;
    } else if (level < 0.45) {
        return <Battery30Sharp {...svgProps}/>;
    } else if (level < 0.55) {
        return <Battery50Sharp {...svgProps}/>;
    } else if (level < 0.7) {
        return <Battery60Sharp {...svgProps}/>;
    } else if (level < 0.85) {
        return <Battery80Sharp {...svgProps}/>;
    } else if (level < 0.95) {
        return <Battery90Sharp {...svgProps}/>;
    }
    return <BatteryFullSharp {...svgProps}/>;
  };

  return(getBatteryIcon(level));
};

export default withStyles(styles)(Component);