import React, { Component } from "react";

import {createStyles, FormControl, Typography, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import {createAutonomyVersionRequest} from "../../redux/actions";

const styles = (theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column"
    },
    dialog: {
      position: "absolute",
      top: "60%"
    }
  });

type Props = {
  classes: any;
  dispatch: any;
  open: boolean;
  onClose: () => void;
  onSuccess: (n: m_pb.AutonomyVersion.AsObject) => void;
};
type State = {
  error: string;
  versionName: string;
  dockerTag: string;
};

class AutonomyVersionDialog extends Component<Props, State> {
  state: State = {
    error: "",
    versionName: "",
      dockerTag: ""
  };

  _isValid = () => {
      return this.state.versionName && this.state.dockerTag;
  }

  _submit = () => {
    const { versionName, dockerTag } = this.state;
    const { dispatch, onSuccess } = this.props;
    const request = new m_pb.CreateAutonomyVersionRequest();
    request.setDockerTag(dockerTag);
    request.setVersionName(versionName);
    dispatch(createAutonomyVersionRequest(request)).then((payload: any) => {
      onSuccess(payload);
    });
  };

  render() {
    const { dockerTag, versionName, error } = this.state;
    const { classes, open, onClose } = this.props;
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={() => onClose && onClose()}
          classes={{
            paper: classes.dialog
          }}
        >
          <DialogTitle>Add Autonomy Version</DialogTitle>
          <DialogContent>
            <div>
              {error && <Alert severity="warning">{error}</Alert>}
              <TextField
                  value={versionName}
                  label="Version Display Name"
                  onChange={(e) => {this.setState({versionName: e.target.value})}}/>
              <TextField
                  value={dockerTag}
                  label="Docker Tag"
                  onChange={(e) => {this.setState({dockerTag: e.target.value})}}/>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onClose()} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => this._submit()}
              color="primary"
              disabled={!!error || !this._isValid()}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default connect()(withStyles(styles)(AutonomyVersionDialog));
