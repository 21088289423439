import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";

import { ApplicationState } from "../../redux";
import CreateConfigFileModal from "./CreateConfigFileModal";
import { RobotConfigurationRevisionState } from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { Tooltip, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { isEmpty } from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxHeight: 400,
      overflow: "auto",
      maxWidth: 480,
      margin: 8,
      padding: 16,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4
    },
    nestedLvl1: {
      paddingLeft: theme.spacing(6)
    },
    nestedLvl2: {
      paddingLeft: theme.spacing(12)
    },
    nestedLvl3: {
      paddingLeft: theme.spacing(18)
    },
    nestedLvl4: {
      paddingLeft: theme.spacing(24)
    },
    nestedLvl5: {
      paddingLeft: theme.spacing(30)
    },
    subheader: {
      display: "inline-block"
    },
    newConfigButton: {
      display: "inline-block"
    },
    filename: {
      color: "black"
    },
    deletedFilename: {
      color: "red"
    },
    fileContainer: {
      margin: 8
    },
    inline: {
      display: "inline"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface ConfigFileMeta {
  filePath: string;
  configId: string;
  activeRevisionId: string;
}
interface Props extends WithStyles<typeof styles> {
  entityType: string;
  entityIdentifier: string;
  entityCount: number;
  activeFiles: ConfigFileMeta[];
  deletedFiles: ConfigFileMeta[];
  reloadData: () => void;
  onFileClick: (configId: string) => void;
  selectedFileConfigId: string;
  activeRevisionsByConfigId: Map<string, RobotConfigurationRevisionState[]>;
  allowCreateFile: boolean;
  updatePath: any;
}
interface State {
  creatingConfigFile: boolean;
  // TODO: figure out a more robust solution for this?
  expandedFolders: string[];
}
interface ConfigFile {
  filename: string;
  configId: string;
  isDeleted: boolean;
  appliedByRobotsCount: number;
  conflictedRobotNames: string[];
}
interface Folder {
  expanded: boolean;
  name: string;
  files: ConfigFile[];
  subfolders: Folder[];
}

interface FileMetadata {
  // parent folders of the file
  folders: string[];
  filename: string;
  isDeleted: boolean;
  configId: string;
  appliedByRobotsCount: number;
  conflictedRobotNames: string[];
}

class ConfigurationFileSystemView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      creatingConfigFile: false,
      expandedFolders: [this.props.entityType]
    };
  }
  componentDidMount() {
    const {
      activeFiles,
      activeRevisionsByConfigId,
      selectedFileConfigId,
      allowCreateFile
    } = this.props;
    const foundFile = activeFiles.find(file => {
      return file.configId === selectedFileConfigId;
    });
    if (
      !isEmpty(selectedFileConfigId) &&
      !isEmpty(activeFiles) &&
      !isEmpty(activeRevisionsByConfigId) &&
      foundFile === undefined &&
      activeRevisionsByConfigId.get(selectedFileConfigId) === undefined
    ) {
      //show error then update path
      const entityKey = allowCreateFile
        ? "entityRevisionHistory"
        : "comparisonRevisionHistory";
      this.props.updatePath(entityKey, null, true);
    }
  }

  componentDidUpdate() {
    const {
      activeFiles,
      activeRevisionsByConfigId,
      selectedFileConfigId,
      allowCreateFile
    } = this.props;
    const foundFile = activeFiles.find(file => {
      return file.configId === selectedFileConfigId;
    });
    if (
      !isEmpty(selectedFileConfigId) &&
      !isEmpty(activeFiles) &&
      !isEmpty(activeRevisionsByConfigId) &&
      foundFile === undefined &&
      activeRevisionsByConfigId.get(selectedFileConfigId) === undefined
    ) {
      const entityKey = allowCreateFile
        ? "entityRevisionHistory"
        : "comparisonRevisionHistory";
      this.props.updatePath(entityKey, null, true);
    }
  }
  // Convert filepaths into a more easily rendered structure by first creating collections of nodes (files and folders)
  // and then iterating over them and assembling them into a directory tree.
  _getFolders = () => {
    const rootFolders: Folder[] = [];
    const rootFiles: ConfigFile[] = [];
    const filePaths: FileMetadata[] = [];
    this.props.activeFiles.forEach(file => {
      const folders = file.filePath.split("/").slice(1, -1);
      filePaths.push({
        folders,
        filename: file.filePath.split("/").slice(-1)[0],
        isDeleted: false,
        configId: file.configId,
        appliedByRobotsCount: (
          this.props.activeRevisionsByConfigId.get(file.configId) || []
        ).reduce((acc, activeRevisions) => {
          return (
            acc +
            (activeRevisions.getActiveRevisionId() === file.activeRevisionId
              ? 1
              : 0)
          );
        }, 0),
        conflictedRobotNames: (
          this.props.activeRevisionsByConfigId.get(file.configId) || []
        ).reduce((acc: string[], activeRevisions) => {
          return activeRevisions.getCurrentRevisionConflict()
            ? [...acc, activeRevisions.getRobotName()]
            : acc;
        }, [])
      });
    });
    this.props.deletedFiles.forEach(file => {
      const folders = file.filePath.split("/").slice(1, -1);
      filePaths.push({
        folders,
        filename: file.filePath.split("/").slice(-1)[0],
        isDeleted: true,
        configId: file.configId,
        appliedByRobotsCount: (
          this.props.activeRevisionsByConfigId.get(file.configId) || []
        ).reduce((acc, activeRevisions) => {
          return (
            acc +
            (activeRevisions.getActiveRevisionId() === file.activeRevisionId
              ? 1
              : 0)
          );
        }, 0),
        conflictedRobotNames: []
      });
    });
    filePaths.forEach(fileMetadata => {
      if (fileMetadata.folders.length > 0) {
        let parentFolder: Folder;
        // Check to see if a rootFolder exists with the same name
        const existingRootFolder: Folder | undefined = rootFolders.find(
          folder => folder.name === fileMetadata.folders[0]
        );
        // If so, set to be new parent folder
        if (existingRootFolder) {
          parentFolder = existingRootFolder;
        } else {
          // If not, create a new rootFolder and set to be parentFolder
          const newFolder = {
            expanded: this.state.expandedFolders.includes(
              fileMetadata.folders[0]
            ),
            name: fileMetadata.folders[0],
            files: [],
            subfolders: []
          };
          rootFolders.push(newFolder);
          parentFolder = newFolder;
        }

        for (const folderName of fileMetadata.folders.slice(1)) {
          if (parentFolder) {
            // Check to see if folder name exists
            const existingFolder: Folder | undefined =
              parentFolder.subfolders.find(
                subfolder => subfolder.name === folderName
              );
            // If so, set it to be new parent folder
            if (existingFolder) {
              parentFolder = existingFolder;
            } else {
              // If not, create it and set to be parentFolder
              const newFolder = {
                expanded: this.state.expandedFolders.includes(folderName),
                name: folderName,
                files: [],
                subfolders: []
              };
              parentFolder.subfolders.push(newFolder);
              parentFolder = newFolder;
            }
          }
        }
        // parentFolder is now the direct antecedent of filePath.filename
        parentFolder.files.push({
          filename: fileMetadata.filename,
          isDeleted: fileMetadata.isDeleted,
          configId: fileMetadata.configId,
          appliedByRobotsCount: fileMetadata.appliedByRobotsCount,
          conflictedRobotNames: fileMetadata.conflictedRobotNames
        });
      } else {
        rootFiles.push({
          filename: fileMetadata.filename,
          isDeleted: fileMetadata.isDeleted,
          configId: fileMetadata.configId,
          appliedByRobotsCount: fileMetadata.appliedByRobotsCount,
          conflictedRobotNames: fileMetadata.conflictedRobotNames
        });
      }
    });
    return [
      {
        expanded: this.state.expandedFolders.includes(this.props.entityType),
        name: this.props.entityType,
        files: rootFiles,
        subfolders: rootFolders
      }
    ];
  };

  _getNestedElementClass(depth: number) {
    // eslint-disable-next-line default-case
    switch (depth) {
      case 0:
        break;
      case 1:
        return this.props.classes.nestedLvl1;
      case 2:
        return this.props.classes.nestedLvl2;
      case 3:
        return this.props.classes.nestedLvl3;
      case 4:
        return this.props.classes.nestedLvl4;
      case 5:
        return this.props.classes.nestedLvl5;
    }
  }

  render() {
    const {
      classes,
      entityType,
      entityIdentifier,
      reloadData,
      selectedFileConfigId,
      allowCreateFile
    } = this.props;
    const { creatingConfigFile } = this.state;
    const folders = this._getFolders();
    const handleFolderClick = (folderName: string) => {
      return () => {
        const currentExpandedFolders = [...this.state.expandedFolders];
        const expandedFolderIndex = currentExpandedFolders.indexOf(folderName);
        if (expandedFolderIndex >= 0) {
          currentExpandedFolders.splice(expandedFolderIndex, 1);
          this.setState({
            expandedFolders: currentExpandedFolders
          });
        } else {
          this.setState({
            expandedFolders: [...this.state.expandedFolders, folderName]
          });
        }
      };
    };
    const handleFileClick = (file: ConfigFile) => () => {
      console.log("loading revision history for file: " + file.filename);
      this.props.onFileClick(file.configId);
    };

    const onCreateConfigFileClick = () => {
      this.setState({ creatingConfigFile: true });
    };
    const listFiles = (foldersToList: Folder[], depth = 0) => {
      return foldersToList
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((folder, index) => {
          return (
            <>
              <ListItem
                button
                onClick={handleFolderClick(folder.name)}
                className={this._getNestedElementClass(depth)}
                divider={!folder.expanded}
                key={`${entityType}-${folder.name}`}
              >
                <ListItemText primary={folder.name + "/"} />
                {folder.expanded ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={folder.expanded} timeout="auto" unmountOnExit>
                <List dense={true}>
                  {listFiles(folder.subfolders, depth + 1)}
                  {folder.files
                    .sort((a, b) => (a.filename < b.filename ? -1 : 1))
                    .map(file => {
                      return (
                        <ListItem
                          button
                          onClick={handleFileClick(file)}
                          className={this._getNestedElementClass(depth + 1)}
                          key={`${entityType}-${folder.name}-${file.filename}}`}
                          divider={true}
                          selected={selectedFileConfigId === file.configId}
                        >
                          <ListItemText
                            primary={
                              file.filename +
                              (file.isDeleted ? " (deleted)" : "")
                            }
                            className={
                              file.isDeleted
                                ? classes.deletedFilename
                                : classes.filename
                            }
                            secondary={
                              <>
                                {/* TODO nested typography creates warning */}
                                <Typography variant="body2">
                                  {"Latest revision " +
                                    (this.props.entityCount === 1
                                      ? (file.appliedByRobotsCount === 0
                                          ? "NOT "
                                          : "") + "applied"
                                      : "applied to " +
                                        file.appliedByRobotsCount +
                                        "/" +
                                        this.props.entityCount +
                                        " robots")}
                                </Typography>
                                {file.conflictedRobotNames.length ? (
                                  <>
                                    <Tooltip
                                      className={classes.inline}
                                      title={file.conflictedRobotNames.join(
                                        ","
                                      )}
                                      arrow
                                    >
                                      <ErrorIcon color="error" />
                                    </Tooltip>
                                    <Typography
                                      className={classes.inline}
                                      variant="body2"
                                      color="error"
                                    >
                                      {"Conflicted on " +
                                        file.conflictedRobotNames.length +
                                        " robots"}
                                    </Typography>
                                  </>
                                ) : null}
                              </>
                            }
                          />
                        </ListItem>
                      );
                    })}
                </List>
              </Collapse>
            </>
          );
        });
    };

    return (
      <div>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <div>
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                className={classes.subheader}
              >
                {entityType} config
              </ListSubheader>
              {allowCreateFile && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.newConfigButton}
                  onClick={onCreateConfigFileClick}
                >
                  new {entityType} config file
                </Button>
              )}
            </div>
          }
          className={classes.root}
        >
          <div className={classes.fileContainer}>{listFiles(folders)}</div>
        </List>
        {creatingConfigFile ? (
          <CreateConfigFileModal
            open={true}
            entityType={entityType}
            // cohort id or robot name
            entityIdentifier={entityIdentifier}
            onModalClose={() => {
              this.setState({ creatingConfigFile: false });
            }}
            onSuccess={() => {
              reloadData();
              this.setState({ creatingConfigFile: false });
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(ConfigurationFileSystemView)
);
