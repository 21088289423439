import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
 } from '@material-ui/core/styles';
 import {
  Typography,
  Box,
  Paper,
  Grid,
  Fade,
  Backdrop,
  LinearProgress,
  Divider,
  FormControl,
  Input,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { fetchCohorts, fetchRobotAccounts, fetchSites, setSearchString } from '../../redux'; 
import { client } from '../../redux/store';
import * as payloads from "../../redux/payloads";
import { ForkliftCohort, ListForkliftCohortsRequest, ListRobotAccountsRequest, ListSitesRequest, Site } from '../../_proto/command_control/monitoring/proto/monitoring_pb';
import { useAppDispatch } from '../../redux/hooks';

import RobotStatusCard from '../RobotStatusCard';
import { Search } from '@material-ui/icons';
import { useAsyncDebounce } from 'react-table';
import RobotSearch from '../RobotSearch';

const styles = (theme: Theme) => createStyles({
  content: {
    padding: '24px',
    backgroundColor: '#78909c08',
    borderTop: '1px solid #0000000a',
    flexGrow: 1,
  },
  hero: {
    height: '256px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  batteryIcon: {
    transform: 'rotate(-0.25turn)',
    color: 'rgba(0, 0, 0, .38)'
  },
  backdrop: {
    width: '100%',
    height: '100%',
    zIndex: 11,
    backgroundColor: 'rgba(0, 0, 0, .8)'
  },
  backdropContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '21%'
  },
  backdropTypography: {
    color: 'rgba(255, 255, 255, .8)'
  },
  backdropTypographyContainer: {
    marginBottom: 12,
    display: 'flex',
    justifyContent: 'center'
  },
  contentPaper: {
    padding: 16,
    marginBottom: 3
  },
  siteTypographyContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  siteTypography: {
    marginBottom: 6,
    marginTop: 12
  },
  siteDivider: {
    marginBottom: 24
  },
  searchContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    paddingRight: 24,
    paddingLeft: 24
  },
});

interface Props extends WithStyles<typeof styles> {
  robotsByCohort: Map<ForkliftCohort.AsObject, payloads.RobotAccount[]>;
}

enum E_LOAD_STATUS {
  INITIALIZING = 'Initializing...',
  FETCHING_COHORTS = 'Fetching cohort information...',
  FETCHING_ROBOTS = 'Fetching robots...',
  COMPLETE = 'Complete!'
}

const Component: FunctionComponent<Props> = (props: Props) => {
  const { classes, robotsByCohort } = props;
  const dispatch = useAppDispatch();
  const [ pageLoaded, setPageLoaded ] = useState(false);
  
  const [ pageLoadStatus, setPageLoadStatus ] = useState(E_LOAD_STATUS.INITIALIZING)

  const getRobots = () => {
    const cohortRequest = new ListForkliftCohortsRequest();
    cohortRequest.setPageToken(0)
    cohortRequest.setPageSize(500)

    setPageLoadStatus(E_LOAD_STATUS.FETCHING_COHORTS)
    dispatch(fetchCohorts({
      client: client,
      req: cohortRequest,
    }))
    .then((response) => {
      const robotRequest = new ListRobotAccountsRequest();
      robotRequest.setPageToken(0)
      robotRequest.setPageSize(500)
      setPageLoadStatus(E_LOAD_STATUS.FETCHING_ROBOTS)
  
      dispatch(fetchRobotAccounts({
        client: client,
        req: robotRequest,
      }))
      .then((response) => {
        setPageLoadStatus(E_LOAD_STATUS.COMPLETE)
        setPageLoaded(true)
      })
      .catch((err) => {
        console.warn(err)
        setPageLoadStatus(E_LOAD_STATUS.COMPLETE)
        setPageLoaded(true)
      })
    })
    .catch((err) => {
      console.warn(err)
    })

    setTimeout(() => getRobots(), 10000)
  }

  useEffect(() => {
    getRobots();
  }, []);

  return(
    <div>
      <Backdrop open={!pageLoaded} className={classes.backdrop}>
        <Box className={classes.backdropContainer}>
          <Box className={classes.backdropTypographyContainer}>
            <Typography variant={'h6'} className={classes.backdropTypography}>
              {pageLoadStatus}
            </Typography>
          </Box>
          <LinearProgress />
        </Box>
      </Backdrop>
      <Box className={classes.hero}>
        <Typography variant="h3">Robot Status</Typography>
      </Box>
      <Box className={classes.searchContainer}>
        <Paper className={classes.contentPaper}>
          <RobotSearch />
        </Paper>
      </Box>
      <Box className={classes.content}>
        <Paper className={classes.contentPaper}>
          {
            [...robotsByCohort].map(([cohort, robots], si) => {
              return(
                <Fade key={si} in={pageLoaded} timeout={(si * 200)}>
                  <Box>
                    <Box className={classes.siteTypographyContainer}>
                      <Typography variant={'h5'} className={classes.siteTypography}>
                        {cohort.displayName}
                      </Typography>
                    </Box>
                    <Divider className={classes.siteDivider}/>
                    <Grid container spacing={3}>
                      {
                        robots.map((robot, i) => {
                          return (
                            <Grid
                              key={i}
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              xl={2}
                            >
                              <Fade in={pageLoaded}>
                                <RobotStatusCard robot={robot}/>
                              </Fade>
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  </Box>
                </Fade>
              )
            })
          }          
        </Paper>
      </Box>
    </div>
  )
}

export default withStyles(styles)(Component);
