import React, { Component } from "react";

import {
  createStyles,
  FormControl,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import {
  createForkliftCohortRequest,
  createSiteRequest,
  createSyncDemand
} from "../../redux/actions";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = (theme: Theme) =>
  createStyles({
    createCohortDialog: {
      padding: 32,
      display: "flex",
      alignItems: "center",
      flexDirection: "column"
    }
  });

type Props = {
  classes: any;
  dispatch: any;
  open: boolean;
  organizations: Array<m_pb.Organization.AsObject>;
  sites: Array<m_pb.Site.AsObject>;
  onClose: () => void;
  onSuccess: (newCohort: m_pb.ForkliftCohort) => void;
};
type State = {
  pendingCohortName: string;
  pendingCohortOrg: string;
  pendingCohortSite: string;
  pendingCohortLocationCode: string;
  error: string;
};

class CreateCohortDialog extends Component<Props, State> {
  state: State = {
    pendingCohortName: "",
    pendingCohortOrg: "",
    pendingCohortSite: "",
    pendingCohortLocationCode: "",
    error: ""
  };

  _submit = () => {
    const { onSuccess, sites } = this.props;
    const {
      pendingCohortName,
      pendingCohortOrg,
      pendingCohortSite,
      pendingCohortLocationCode,
    } = this.state;
    if (!pendingCohortName) {
      console.warn("Cannot create cohort with empty name");
      return;
    }
    let siteId = "";
    if (pendingCohortSite) {
      const site = sites.find(
        site => site.formattedSiteId === pendingCohortSite
      );
      siteId = site ? site.id : "";
    }
    this.props
      .dispatch(
        createForkliftCohortRequest(pendingCohortName, pendingCohortOrg, siteId, pendingCohortLocationCode)
      )
      .then((action: { response: m_pb.ForkliftCohort }) => {
        onSuccess(action.response);
      })
      .catch((error: any) => this.setState({ error }));
  };

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <React.Fragment>
        <Dialog open={open} onClose={() => onClose && onClose()}>
          <DialogTitle>Create Forklift Cohort</DialogTitle>
          <div className={classes.createCohortDialog}>
            <TextField
              label="Cohort Name"
              margin={"normal"}
              variant={"outlined"}
              onChange={e => {
                this.setState({ pendingCohortName: e.target.value });
              }}
            />
            <TextField
              label="Customer Location Code (optional)"
              margin={"normal"}
              variant={"outlined"}
              onChange={e => {
                this.setState({ pendingCohortLocationCode: e.target.value });
              }}
            />
            {this.props.organizations && (
              <Autocomplete
                id="cohort-org-autocomplete"
                options={this.props.organizations.map(org => org.name)}
                onChange={(event: any, newValue: string | null) => {
                  this.setState({ pendingCohortOrg: newValue || "" });
                }}
                value={this.state.pendingCohortOrg}
                style={{
                  minWidth: 200,
                  height: 56,
                  margin: 6,
                  display: "inline"
                }}
                renderInput={params => (
                  <TextField {...params} label="Organization" />
                )}
              />
            )}
            {this.props.sites && (
              <Autocomplete
                id="cohort-site-autocomplete"
                options={this.props.sites.map(site => site.formattedSiteId)}
                onChange={(event: any, newValue: string | null) => {
                  this.setState({ pendingCohortSite: newValue || "" });
                }}
                value={this.state.pendingCohortSite}
                style={{
                  minWidth: 200,
                  height: 56,
                  margin: 6,
                  display: "inline"
                }}
                renderInput={params => <TextField {...params} label="Site" />}
              />
            )}
            <Button
              color={"secondary"}
              variant={"contained"}
              fullWidth
              onClick={() => this._submit()}
            >
              Create
            </Button>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default connect()(withStyles(styles)(CreateCohortDialog));
