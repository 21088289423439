import React, { Component } from "react";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import * as paths from "../../utils/Paths";

import { ApplicationState } from "../../redux";
import { listRunMetadasRequest } from "../../redux/actions";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import { run } from "../../utils/Paths";
import { ListItem } from "@material-ui/core";
import List from "@material-ui/core/List";
import MuiLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import TrelloCard from "../Utils/TrelloCard";

import Collapse from "@material-ui/core/Collapse";
import { Annotation } from "../../redux/payloads";
import { protoEnumFieldName } from "../../utils/protos";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    wrapper: {
      width: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "column"
    },
    card: {
      width: "100%"
    },
    smallPaddingLeft: {
      paddingLeft: 16
    },
    content: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: 0
    },
    logs: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      paddingTop: 0
    },
    referencesAndRuns: {
      display: "flex",
      justifyContent: "start",
      flexDirection: "column",
      width: 480,
      overflowX: "auto",
      paddingLeft: 16
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center"
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start"
    },
    nested: {
      paddingLeft: theme.spacing(12)
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
  externalBug: m_pb.ExternalBug.AsObject;
}
interface State {
  expanded: boolean;
  annotationsById: Map<string, Annotation>;
}

class ExternalBugListItem extends Component<Props, State> {
  state = {
    expanded: false,
    annotationsById: new Map()
  };

  _toggleExpansion = () => {
    const { runNamesList, annotationIdsList } = this.props.externalBug;
    const expanded = !this.state.expanded;
    const annotationIds = new Set(annotationIdsList);
    if (expanded && runNamesList.length) {
      const eventFilter = new m_pb.EventFilter();
      eventFilter.setRunIdsList(runNamesList);
      this.props
        .dispatch(
          listRunMetadasRequest({ pageToken: 0, pageSize: 25 }, eventFilter)
        )
        .then(({ count: responseCount, metadatas }: any) => {
          const annotationsById = new Map();
          for (const run of metadatas) {
            for (const annotation of run.annotations.filter((a: Annotation) =>
              annotationIds.has(a.id)
            )) {
              annotationsById.set(annotation.id, annotation);
            }
          }
          this.setState({ annotationsById });
        });
    }
    this.setState({ expanded });
  };

  render() {
    const { classes, externalBug } = this.props;
    const { expanded, annotationsById } = this.state;
    const annotations = externalBug.annotationIdsList
      .map(aid => annotationsById.get(aid))
      .filter(a => !!a);
    return (
      <React.Fragment>
        <ListItem divider={!expanded} dense>
          <IconButton size={"small"} onClick={() => this._toggleExpansion()}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <div className={classes.referencesAndRuns}>
            <div className={classes.row}>
              {" "}
              <Typography color={"textSecondary"}>
                {externalBug.annotationIdsList.length} reference
                {externalBug.annotationIdsList.length === 1 ? "" : "s"}
              </Typography>{" "}
            </div>
            <div className={classes.row}>
              {externalBug.runNamesList.map((c, i) => (
                <MuiLink
                  key={i}
                  target={"_blank"}
                  color="secondary"
                  className={i > 0 ? classes.smallPaddingLeft : undefined}
                  href={run(c)}
                >
                  {c}
                </MuiLink>
              ))}
            </div>
          </div>
          <TrelloCard cardUrl={externalBug.url} />
        </ListItem>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List>
            {annotations.map(annotation => {
              return (
                <ListItem
                  key={annotation.id}
                  divider
                  className={classes.nested}
                >
                  <div className={classes.column}>
                    <MuiLink
                      target={"_blank"}
                      color="secondary"
                      href={paths.annotation(annotation.runId, annotation.id)}
                    >
                      {annotation.runId}
                    </MuiLink>
                    <Typography color={"textSecondary"}>
                      {annotation.description}
                    </Typography>
                    <Typography color={"textSecondary"}>{`${protoEnumFieldName(
                      cc_pb.InterventionType,
                      annotation.intervention
                    ).replace(
                      "INTERVENTION_",
                      ""
                    )} intervention caused by ${protoEnumFieldName(
                      cc_pb.InterventionCause,
                      annotation.interventionCause
                    ).replace("INTERVENTION_CAUSE_", "")}`}</Typography>
                    <Typography
                      color={"textSecondary"}
                    >{`Pallet damage: ${annotation.palletDamage}`}</Typography>
                    <Typography
                      color={"textSecondary"}
                    >{`Goods damage: ${annotation.goodsDamage}`}</Typography>
                    <Typography
                      color={"textSecondary"}
                    >{`Note time: ${annotation.eventTime}`}</Typography>
                  </div>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(ExternalBugListItem)
);
