import React, { Component } from "react";

import { createStyles, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import { updateAnnotationRequest } from "../../redux/actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TrelloCard from "../Utils/TrelloCard";

const styles = (theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column"
    },
    column: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%"
    },
    interventionRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      marginBottom: 16,
      padding: 16
    },
    interventionButton: {
      margin: "auto"
    },
    topRow: {
      paddingTop: 8,
      paddingBottom: 16,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    interventionText: {
      width: "100%",
      flexGrow: 1
    },
    description: {
      width: "100%",
      minWidth: 440
    },
    addTimestamp: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    },
    times: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      padding: theme.spacing(2)
    },
    timeWrapper: {
      padding: 16,
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4
    },
    menuTitle: {
      textTransform: "capitalize"
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: 16
    }
  });

type Props = {
  classes: any;
  epochOffset: number;
  runId: string;
  eventTime: number | null;
  dispatch: any;
  open: boolean;
  originalAnnotation: m_pb.Annotation;
  onClose: () => void;
  onSuccess: (n: m_pb.Annotation) => void;
  requestClearTime: () => void;
  requestDeleteAnnotation: () => void;
};
type State = {
  modifiedAnnotation: m_pb.Annotation;
  isWaiting: boolean;
  dirtyPaths: Set<string>;
  error: string | null;
  isLoading: boolean;
  notifySuccess: boolean;
  trimmedBugUrl: boolean;
};

class UpdateAnnotationDialog extends Component<Props, State> {
  state: State = {
    modifiedAnnotation: new m_pb.Annotation(),
    isWaiting: false,
    dirtyPaths: new Set(),
    error: null,
    isLoading: false,
    notifySuccess: false,
    trimmedBugUrl: false
  };

  componentDidMount(): void {
    const { originalAnnotation } = this.props;
    this.setState({ modifiedAnnotation: originalAnnotation });
  }

  _updateDescription(description: string) {
    const { modifiedAnnotation, dirtyPaths } = this.state;
    modifiedAnnotation.setDescription(description);
    this.setState({
      modifiedAnnotation: modifiedAnnotation,
      dirtyPaths: dirtyPaths.add("description")
    });
  }

  _updateBugURL(url: string) {
    const { modifiedAnnotation, dirtyPaths } = this.state;
    let externalBug: m_pb.ExternalBug | undefined = undefined;
    if (url.trim().length) {
      externalBug = modifiedAnnotation.getExternalBug();
      if (!externalBug) {
        externalBug = new m_pb.ExternalBug();
      }
      externalBug.setUrl(url.trim());
      modifiedAnnotation.setExternalBug(externalBug);
      dirtyPaths.add("external_bug.url");
    } else {
      modifiedAnnotation.clearExternalBug();
      dirtyPaths.add("external_bug");
    }
    this.setState({
      modifiedAnnotation: modifiedAnnotation,
      dirtyPaths
    });
  }

  _submit = () => {
    const { modifiedAnnotation } = this.state;
    let { dirtyPaths } = this.state;
    const { onSuccess, eventTime } = this.props;
    if (dirtyPaths.has("event_time")) {
      if (eventTime) {
        modifiedAnnotation.setEventTime(eventTime);
      } else {
        console.warn("Cannot update event_time with empty value");
        dirtyPaths.delete("event_time");
      }
    }
    if (!dirtyPaths.size) {
      console.log("Ignoring empty update");
      return;
    }
    this.setState({ dirtyPaths: new Set(), isLoading: true }, () => {
      this.props
        .dispatch(
          updateAnnotationRequest(modifiedAnnotation, Array.from(dirtyPaths))
        )
        .then((payload: any) => {
          this.setState({ notifySuccess: true }, () => onSuccess(payload));
        })
        .catch((e: any) => {
          console.error("Failed to update annotation", e);
          this.setState({ error: e, dirtyPaths });
        })
        .finally(() => this.setState({ isLoading: false }));
    });
  };

  render() {
    const {
      modifiedAnnotation,
      dirtyPaths,
      isLoading,
      notifySuccess,
      trimmedBugUrl
    } = this.state;
    const { classes, open, onClose, epochOffset, eventTime } = this.props;
    const eventDate = eventTime
      ? new Date((eventTime + epochOffset) / 1e6)
      : null;
    const externalBug = modifiedAnnotation.getExternalBug();
    const trelloUrl: string | null = externalBug ? externalBug.getUrl() : null;
    return (
      <React.Fragment>
        <Snackbar
          autoHideDuration={5000}
          open={notifySuccess}
          onClose={() => this.setState({ notifySuccess: false })}
        >
          <MuiAlert severity={"success"} elevation={6} variant={"filled"}>
            Updated annotation
          </MuiAlert>
        </Snackbar>
        <Dialog
          open={open}
          onClose={() => onClose && onClose()}
          aria-labelledby="update-annotation-dialog-title"
        >
          <div className={classes.row}>
            <DialogTitle id="update-annotation-dialog-title">
              Update Annotation
            </DialogTitle>
            <Button
              color={"secondary"}
              disabled={isLoading}
              onClick={() => this.props.requestDeleteAnnotation()}
            >
              Delete
            </Button>
          </div>
          <DialogContent>
            <div className={classes.topRow}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="component-select">Intervention</InputLabel>
                <Select
                  disabled={isLoading}
                  className={classes.menuTitle}
                  inputProps={{ id: "component-select" }}
                  value={modifiedAnnotation.getIntervention()}
                  onChange={e => {
                    modifiedAnnotation.setIntervention(
                      parseInt(e.target.value as string)
                    );

                    this.setState({
                      modifiedAnnotation: modifiedAnnotation,
                      dirtyPaths: dirtyPaths.add("intervention")
                    });
                  }}
                >
                  {Object.entries(cc_pb.InterventionType).map(e => (
                    <MenuItem
                      key={e[1]}
                      value={e[1]}
                      className={classes.menuTitle}
                    >
                      {e[0]
                        .replace("INTERVENTION_", "")
                        .replace(/_/g, " ")
                        .toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.topRow}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="component-select">Root Cause</InputLabel>
                <Select
                  disabled={isLoading}
                  className={classes.menuTitle}
                  inputProps={{ id: "component-select" }}
                  value={modifiedAnnotation.getInterventionCause()}
                  onChange={e => {
                    modifiedAnnotation.setInterventionCause(
                      parseInt(e.target.value as string)
                    );

                    this.setState({
                      modifiedAnnotation: modifiedAnnotation,
                      dirtyPaths: dirtyPaths.add("intervention_cause")
                    });
                  }}
                >
                  {Object.entries(cc_pb.InterventionCause).map(e => (
                    <MenuItem
                      key={e[1]}
                      value={e[1]}
                      className={classes.menuTitle}
                    >
                      {e[0]
                        .replace("INTERVENTION_CAUSE", "")
                        .replace(/_/g, " ")
                        .toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.interventionRow}>
              <TextField
                id="update-annotation-description-textfield"
                key="update-annotation-description-textfield"
                label="Description"
                autoFocus
                disabled={isLoading}
                className={classes.interventionText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this._updateDescription(e.target.value)
                }
                multiline
                rows={2}
                value={modifiedAnnotation.getDescription()}
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className={classes.interventionRow}>
              <div className={classes.column}>
                <TextField
                  id="update-annotation-url-textfield"
                  key="update-annotation-url-textfield"
                  label="Bug URL"
                  disabled={isLoading}
                  className={classes.interventionText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const trimmedUrl = e.target.value.replace(
                      /(trello.com\/c\/[a-zA-Z0-9]+\/[0-9]+).*/,
                      "$1"
                    );
                    this.setState({
                      trimmedBugUrl: trimmedUrl !== e.target.value
                    });
                    this._updateBugURL(trimmedUrl);
                  }}
                  multiline={false}
                  value={externalBug ? externalBug.getUrl() : ""}
                  helperText={
                    trimmedBugUrl
                      ? "Trimmed url to stable Trello prefix"
                      : undefined
                  }
                  margin="normal"
                  variant="outlined"
                />
                {trelloUrl && <TrelloCard noFallback cardUrl={trelloUrl} />}
              </div>
            </div>
            <div className={classes.interventionRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isLoading}
                    checked={modifiedAnnotation.getPalletDamage()}
                    onChange={e => {
                      modifiedAnnotation.setPalletDamage(e.target.checked);
                      this.setState({
                        modifiedAnnotation: modifiedAnnotation,
                        dirtyPaths: dirtyPaths.add("pallet_damage")
                      });
                    }}
                    name="pallet-damage-check"
                    color="secondary"
                  />
                }
                label="Collision damaged the pallet"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isLoading}
                    checked={modifiedAnnotation.getGoodsDamage()}
                    onChange={e => {
                      modifiedAnnotation.setGoodsDamage(e.target.checked);
                      this.setState({
                        modifiedAnnotation: modifiedAnnotation,
                        dirtyPaths: dirtyPaths.add("goods_damage")
                      });
                    }}
                    name="goods-damage-check"
                    color="secondary"
                  />
                }
                label="Collision damaged case(s) on the pallet"
              />
            </div>
            <div className={classes.timeWrapper}>
              <div className={classes.addTimestamp}>
                <Typography
                  gutterBottom
                  variant="subtitle2"
                  color={"textSecondary"}
                >
                  {`Time: ${modifiedAnnotation.getEventTime()} ${
                    eventDate &&
                    `(${new Intl.DateTimeFormat("en", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      timeZoneName: "short"
                    }).format(eventDate)})`
                  }`}
                </Typography>
                <Button
                  disabled={isLoading}
                  size={"small"}
                  onClick={() => {
                    this.props.requestClearTime();
                    this.setState({ dirtyPaths: dirtyPaths.add("event_time") });
                  }}
                  color={"secondary"}
                >
                  Edit
                </Button>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoading}
              onClick={() => this.props.onClose()}
              color="secondary"
            >
              {dirtyPaths.size ? "Discard Changes" : "Close"}
            </Button>
            <Button
              disabled={dirtyPaths.size === 0 || isLoading}
              onClick={() => this._submit()}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = () => undefined;

export default connect(mapStateToProps)(
  withStyles(styles)(UpdateAnnotationDialog)
);
