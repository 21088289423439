import m_pb, {
    ConfigurationFileRevision,
    ConfigurationRevisionHistory as ConfigurationRevisionHistoryProto,
    GetConfigurationFileRevisionRequest,
    ListConfigurationRevisionHistoryRequest, OperatorAnswerChoice, OperatorQuestion, ScalarMetricType
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import {ServiceError} from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import {
    getConfigurationFileRevisionRequest,
    listConfigurationRevisionHistoryRequest,
    uploadFaultTranslationsRequest,
    publishFaultTranslations,
    listFaultTranslationsRequest, listOperatorQuestionsRequest, listOperatorAnswerChoicesRequest
} from "../../redux/actions";
import React, {useEffect, useState} from "react";
import {createStyles, WithStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Button, Container, TextField, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import FileUploadButton from "../Utils/FileUploadButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import FaultTable from "./FaultTable";
import FileDownloadButton from "../Utils/FileDownloadButton";
import OperatorQuestionsDialog from "./OperatorQuestionsDialog";

const styles = () => createStyles({
  localeSelect: {
    float: 'right',
  },
});

interface Props<T extends object> extends WithStyles<typeof styles> {
  dispatch: any;
  classes: any;
}

const getFaultTranslationCSVHeaders = (): string[]  =>  {
    return ["Error Type", "Error code", "Numeric Code", "Description", "Suggested Resolution",
        "Avoidable by Robot"];
}
const faultTranslationToArray = (faultTranslation: cc_pb.FaultTranslation.AsObject) => {
    return [faultTranslation.faultType, faultTranslation.faultCode, faultTranslation.numericCode.toString(),
        faultTranslation.faultDescription, faultTranslation.faultSuggestedIntervention,
        faultTranslation.avoidableByRobotDefault ? "Yes" : "No"];
}

const arrayToCSV = (arr: Array<Array<any>>, delimiter = ',') =>
  arr
    .map(v =>
      v.map(x => (isNaN(x) ? `"${x.replace(/"/g, '""')}"` : x)).join(delimiter)
    )
    .join('\n');

function CSVToArray( strData, strDelimiter ){
        // Check to see if the delimiter is defined. If not,
        // then default to comma.
        strDelimiter = (strDelimiter || ",");

        // Create a regular expression to parse the CSV values.
        const objPattern = new RegExp(
            (
                // Delimiters.
                "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
                // Quoted fields.
                "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
                // Standard fields.
                "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ),
            "gi"
            );

        // Create an array to hold our data. Give the array
        // a default empty first row.
        const arrData: Array<Array<string>> = [[]];

        // Create an array to hold our individual pattern
        // matching groups.
        let arrMatches = objPattern.exec( strData );

        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        while (arrMatches){

            // Get the delimiter that was found.
            const strMatchedDelimiter = arrMatches[ 1 ];

            // Check to see if the given delimiter has a length
            // (is not the start of string) and if it matches
            // field delimiter. If id does not, then we know
            // that this delimiter is a row delimiter.
            if (
                strMatchedDelimiter.length &&
                strMatchedDelimiter !== strDelimiter
                ){

                // Since we have reached a new row of data,
                // add an empty row to our data array.
                arrData.push( [] );

            }
            let strMatchedValue: string;
            // Now that we have our delimiter out of the way,
            // let's check to see which kind of value we
            // captured (quoted or unquoted).
            if (arrMatches[ 2 ]){

                // We found a quoted value. When we capture
                // this value, unescape any double quotes.
                strMatchedValue = arrMatches[ 2 ].replace(
                    new RegExp( "\"\"", "g" ),
                    "\""
                    );

            } else {

                // We found a non-quoted value.
                strMatchedValue = arrMatches[ 3 ];

            }


            // Now that we have our value string, let's add
            // it to the data array.
            arrData[ arrData.length - 1 ].push( strMatchedValue );
            arrMatches = objPattern.exec( strData );
        }

      const headers = arrData[0];

      // slice from \n index + 1 to the end of the text
      // use split to create an array of each csv value row
      const rows = arrData.slice(1);

      return rows.map(function (row) {
        return headers.reduce(function (object, header, index) {
          // @ts-ignore
          object[header] = row[index];
          return object;
        }, {});
      });
}

const FaultConfiguration = <T extends object>(props: Props<T>) => {

    const {classes} = props;

    const [errorMessage, setErrorMessage] = useState("");
    const [locale, setLocale] = useState("en_us");
    const [versionName, setVersionName] = useState("");
    const [faultTranslations, setFaultTranslations] = useState(new Array<cc_pb.FaultTranslation.AsObject>());
    const [faultConfigCSV, setFaultConfigCSV] = useState("");
    const [operatorQuestions, setOperatorQuestions] = useState<OperatorQuestion>([]);
    const [operatorAnswerChoices, setOperatorAnswerChoices] = useState<OperatorAnswerChoice>([]);

    const [editingFault, setEditingFault] = useState<cc_pb.FaultTranslation.AsObject | null>(null);

    useEffect(() => {
        _loadFaultTranslations(locale);
      }, [locale]);

    useEffect(() => {
        _setFaultConfigCSV();
      }, [faultTranslations]);

    const _publishFaultTranslations = (versionName: string) => {
        props.dispatch(publishFaultTranslations(versionName))
            .then(() => {
                console.log("Published fault config file")
            })
            .catch((e: ServiceError) => {
                console.error(e);
            })
    }

    const _loadFaultTranslations = (locale: string) => {
        const request = new m_pb.ListFaultTranslationsRequest();
        request.setLocale(locale);
        props.dispatch(listFaultTranslationsRequest(request))
            .then((payload: cc_pb.FaultTranslations.AsObject) => {
                setFaultTranslations(payload.faultTranslationsList);
            })
            .catch((e: ServiceError) => {
                console.error(e);
            });
    }

    const _parseFaultConfigCSV = (fileData: string) => {
        const csvData = CSVToArray(fileData, ",");
        const nonEmptyRows = csvData.filter((row) => {
          return row.hasOwnProperty("Error code") ? row["Error code"] : null;
        });
        const faultTranslations = nonEmptyRows.map((row) => {
            const faultTranslation = new cc_pb.FaultTranslation();
            const isAvoidableByRobotDefault = row["Avoidable by Robot"] === "Yes";
            faultTranslation.setFaultCode(row["Error code"]);
            faultTranslation.setFaultDescription(row["Description"]);
            faultTranslation.setFaultSuggestedIntervention(row["Suggested Resolution"]);
            faultTranslation.setNumericCode(parseInt(row["Numeric Code"] as string, 10));
            faultTranslation.setAvoidableByRobotDefault(isAvoidableByRobotDefault);
            faultTranslation.setFaultType(row["Type"]);
            return faultTranslation;
        });
        const faultTranslationsEntity = new cc_pb.FaultTranslations();
        faultTranslationsEntity.setFaultTranslationsList(faultTranslationsEntity.getFaultTranslationsList().concat(faultTranslations));
        faultTranslationsEntity.setLocale(locale);
        return faultTranslationsEntity;
    }

    const _setFaultConfigCSV = () => {
        const faultTranslationsArray = faultTranslations.reduce((agg, faultTranslation) => {
            agg.push(faultTranslationToArray(faultTranslation));
            return agg;
        }, new Array<Array<string>>(getFaultTranslationCSVHeaders()));
        const csvData = arrayToCSV(faultTranslationsArray);
        setFaultConfigCSV(csvData);
    }

    const _updateFaultConfig = (locale: string, faultConfiguration: cc_pb.FaultTranslations) => {
        faultConfiguration.setLocale(locale);
        console.log(`faultConfiguration: ${JSON.stringify(faultConfiguration)}`);
        props.dispatch(uploadFaultTranslationsRequest(faultConfiguration))
            .then(() => {
                setErrorMessage("");
            })
            .catch((e: ServiceError) => {
                setErrorMessage(`Failed to update fault config: ${e}`);
            });
    }

    return <div style={{textAlign: "center"}}>
        <Typography variant="h2">Fault Configuration</Typography>
        <div>
            <FormControl className={classes.localeSelect}>
                <InputLabel>Locale</InputLabel>
                <Select
                    onChange={e =>
                        setLocale(e.target.value as string)
                    }
                    value={locale}
                    input={<Input id={"select-locale"}
                    />}
                >
                    <MenuItem
                        value="en_us"
                    >en_us</MenuItem>
                    <MenuItem
                        value="es_mx"
                    >es_mx</MenuItem>
                    <MenuItem
                        value="fr_ca"
                    >fr_ca</MenuItem>
                    <MenuItem
                        value="test"
                    >test</MenuItem>
                </Select>
            </FormControl>
        </div>
        {errorMessage && <div>{errorMessage}</div>}
        {faultTranslations && <FaultTable
            faultTranslations={faultTranslations}
            editFaultQuestions={(faultTranslation: cc_pb.FaultTranslation) => {setEditingFault(faultTranslation)}}
        />}
        <div>
            <FileUploadButton
              disabled={false}
              style={{margin: "8px"}}
              color={"primary"}
              onload={(fileData) => {
                  const faultTranslationsEntity = _parseFaultConfigCSV(fileData);
                  _updateFaultConfig(locale, faultTranslationsEntity);
              }}
              label="Upload Fault Configuration CSV"
            />
            <FileDownloadButton
                label="Download Fault Configuration CSV"
                disabled={!faultConfigCSV}
                style={{margin: "8px"}}
                color="primary"
                filename="fault_configuration.csv"
                fileContents={faultConfigCSV}
            />
            <div style={{padding: "12px"}}>
                <TextField
                    onChange={(e) => setVersionName(e.target.value)}
                    value={versionName}
                    label="Version Name to publish"
                    style={{margin: "8px"}}
                />
                <Button
                    variant="contained"
                    style={{margin: "8px"}}
                    color="primary"
                    disabled={!versionName}
                    onClick={() => _publishFaultTranslations(versionName)}
                >Publish Configuration File</Button>
            </div>
        </div>
        {editingFault && <OperatorQuestionsDialog
            open={true}
            onClose={() => {
                setEditingFault(null);
                _loadFaultTranslations(locale);
            }}
            title={editingFault.firstOperatorQuestion ? "Editing fault operator questions" : "Creating fault operator questions"}
            faultTranslation={editingFault}
            operatorQuestions={operatorQuestions}
            operatorAnswerChoices={operatorAnswerChoices}
        />}
    </div>

}

export default connect()(withStyles(styles)(FaultConfiguration));
