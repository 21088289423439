// source: planner/proto/types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var detection_proto_detection_pb = require('../../detection/proto/detection_pb.js');
goog.object.extend(proto, detection_proto_detection_pb);
var geometry_proto_path_pb = require('../../geometry/proto/path_pb.js');
goog.object.extend(proto, geometry_proto_path_pb);
var geometry_proto_transform_pb = require('../../geometry/proto/transform_pb.js');
goog.object.extend(proto, geometry_proto_transform_pb);
var geometry_proto_geometry_pb = require('../../geometry/proto/geometry_pb.js');
goog.object.extend(proto, geometry_proto_geometry_pb);
var sensors_laser_field_pb = require('../../sensors/laser_field_pb.js');
goog.object.extend(proto, sensors_laser_field_pb);
var sensors_laser_scan_pb = require('../../sensors/laser_scan_pb.js');
goog.object.extend(proto, sensors_laser_scan_pb);
goog.exportSymbol('proto.fox.proto.ActionValidationState', null, global);
goog.exportSymbol('proto.fox.proto.BooleanDebouncerState', null, global);
goog.exportSymbol('proto.fox.proto.CarriedObstacleBehaviorMode', null, global);
goog.exportSymbol('proto.fox.proto.CarriedObstacleBehaviorState', null, global);
goog.exportSymbol('proto.fox.proto.CarriedObstacleDetection', null, global);
goog.exportSymbol('proto.fox.proto.CarriedObstacleDetectorState', null, global);
goog.exportSymbol('proto.fox.proto.CarriedObstacleDetectorState.CarriedObstacleDetectionStatus', null, global);
goog.exportSymbol('proto.fox.proto.CarriedObstacleHypothesis', null, global);
goog.exportSymbol('proto.fox.proto.CollisionFootprint', null, global);
goog.exportSymbol('proto.fox.proto.CommandBuffer', null, global);
goog.exportSymbol('proto.fox.proto.CommandedSpeed', null, global);
goog.exportSymbol('proto.fox.proto.ControlsError', null, global);
goog.exportSymbol('proto.fox.proto.ControlsError.Code', null, global);
goog.exportSymbol('proto.fox.proto.ControlsState', null, global);
goog.exportSymbol('proto.fox.proto.DeviceError', null, global);
goog.exportSymbol('proto.fox.proto.DeviceError.Code', null, global);
goog.exportSymbol('proto.fox.proto.DeviceError.SubCode', null, global);
goog.exportSymbol('proto.fox.proto.DriveStallDetectionFlags', null, global);
goog.exportSymbol('proto.fox.proto.DriveStallDetectionState', null, global);
goog.exportSymbol('proto.fox.proto.DualDriveControl', null, global);
goog.exportSymbol('proto.fox.proto.Edge', null, global);
goog.exportSymbol('proto.fox.proto.Edges', null, global);
goog.exportSymbol('proto.fox.proto.ForkliftCommand', null, global);
goog.exportSymbol('proto.fox.proto.ForkliftOdometry', null, global);
goog.exportSymbol('proto.fox.proto.ForkliftState', null, global);
goog.exportSymbol('proto.fox.proto.HandGeneratedTailPalletPolicy', null, global);
goog.exportSymbol('proto.fox.proto.HandGeneratedTailPalletPolicyRow', null, global);
goog.exportSymbol('proto.fox.proto.HardwareDiagnosticMessage', null, global);
goog.exportSymbol('proto.fox.proto.HardwareError', null, global);
goog.exportSymbol('proto.fox.proto.HardwareError.Code', null, global);
goog.exportSymbol('proto.fox.proto.IOStatusFlags', null, global);
goog.exportSymbol('proto.fox.proto.ImuState', null, global);
goog.exportSymbol('proto.fox.proto.KinematicConstraintType', null, global);
goog.exportSymbol('proto.fox.proto.LaserFieldSetSwitchingColumn', null, global);
goog.exportSymbol('proto.fox.proto.LaserFieldSetSwitchingTable', null, global);
goog.exportSymbol('proto.fox.proto.MastControl', null, global);
goog.exportSymbol('proto.fox.proto.MastFollowerStatus', null, global);
goog.exportSymbol('proto.fox.proto.MastHeightTilt', null, global);
goog.exportSymbol('proto.fox.proto.MastKinematicConfiguration', null, global);
goog.exportSymbol('proto.fox.proto.MastStallDetectionState', null, global);
goog.exportSymbol('proto.fox.proto.MastVelocity', null, global);
goog.exportSymbol('proto.fox.proto.MeasureRampAngleState', null, global);
goog.exportSymbol('proto.fox.proto.MotionType', null, global);
goog.exportSymbol('proto.fox.proto.MotionValidationState', null, global);
goog.exportSymbol('proto.fox.proto.Node', null, global);
goog.exportSymbol('proto.fox.proto.PathFollowerStatus', null, global);
goog.exportSymbol('proto.fox.proto.PathFollowerStatus.State', null, global);
goog.exportSymbol('proto.fox.proto.PlaceActionInformation', null, global);
goog.exportSymbol('proto.fox.proto.PlaceLocationSafetyOffZoneState', null, global);
goog.exportSymbol('proto.fox.proto.PlannerCache', null, global);
goog.exportSymbol('proto.fox.proto.PlannerMap', null, global);
goog.exportSymbol('proto.fox.proto.PlannerState', null, global);
goog.exportSymbol('proto.fox.proto.PlcStatusFlags', null, global);
goog.exportSymbol('proto.fox.proto.RampSpeedModel', null, global);
goog.exportSymbol('proto.fox.proto.RawPoseScan', null, global);
goog.exportSymbol('proto.fox.proto.RemoteIoControl', null, global);
goog.exportSymbol('proto.fox.proto.Roadmap', null, global);
goog.exportSymbol('proto.fox.proto.SafetyCase', null, global);
goog.exportSymbol('proto.fox.proto.SafetyFieldState', null, global);
goog.exportSymbol('proto.fox.proto.SafetyMode', null, global);
goog.exportSymbol('proto.fox.proto.SafetyScannerMode', null, global);
goog.exportSymbol('proto.fox.proto.SafetyScannerState', null, global);
goog.exportSymbol('proto.fox.proto.SafetyScannerStatusFlags', null, global);
goog.exportSymbol('proto.fox.proto.SafetyScannerTables', null, global);
goog.exportSymbol('proto.fox.proto.SafetySystemControl', null, global);
goog.exportSymbol('proto.fox.proto.SafetySystemState', null, global);
goog.exportSymbol('proto.fox.proto.SpeedFrame', null, global);
goog.exportSymbol('proto.fox.proto.StuckDetectionState', null, global);
goog.exportSymbol('proto.fox.proto.SystemControl', null, global);
goog.exportSymbol('proto.fox.proto.SystemStatusFlags', null, global);
goog.exportSymbol('proto.fox.proto.ThresholdState', null, global);
goog.exportSymbol('proto.fox.proto.ThresholdState.Status', null, global);
goog.exportSymbol('proto.fox.proto.TrajectoryIndex', null, global);
goog.exportSymbol('proto.fox.proto.TravelDirection', null, global);
goog.exportSymbol('proto.fox.proto.TricycleDriveControl', null, global);
goog.exportSymbol('proto.fox.proto.TricycleSegment', null, global);
goog.exportSymbol('proto.fox.proto.TricycleTrajectory', null, global);
goog.exportSymbol('proto.fox.proto.ValidationError', null, global);
goog.exportSymbol('proto.fox.proto.ValidationError.Action', null, global);
goog.exportSymbol('proto.fox.proto.ValidationError.Code', null, global);
goog.exportSymbol('proto.fox.proto.ValidationErrorLidarPoints', null, global);
goog.exportSymbol('proto.fox.proto.ValidationState', null, global);
goog.exportSymbol('proto.fox.proto.VisualServoRegion', null, global);
goog.exportSymbol('proto.fox.proto.VisualServoState', null, global);
goog.exportSymbol('proto.fox.proto.VisualServoState.VisualServoStatus', null, global);
goog.exportSymbol('proto.fox.proto.VisualServoStrategy', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.TrajectoryIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.TrajectoryIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.TrajectoryIndex.displayName = 'proto.fox.proto.TrajectoryIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlcStatusFlags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PlcStatusFlags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlcStatusFlags.displayName = 'proto.fox.proto.PlcStatusFlags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ImuState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ImuState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ImuState.displayName = 'proto.fox.proto.ImuState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.TricycleDriveControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.TricycleDriveControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.TricycleDriveControl.displayName = 'proto.fox.proto.TricycleDriveControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.CollisionFootprint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.CollisionFootprint.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.CollisionFootprint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.CollisionFootprint.displayName = 'proto.fox.proto.CollisionFootprint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.DualDriveControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.DualDriveControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.DualDriveControl.displayName = 'proto.fox.proto.DualDriveControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.MastControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.MastControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.MastControl.displayName = 'proto.fox.proto.MastControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.MastVelocity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.MastVelocity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.MastVelocity.displayName = 'proto.fox.proto.MastVelocity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.VisualServoState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.VisualServoState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.VisualServoState.displayName = 'proto.fox.proto.VisualServoState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.VisualServoRegion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.VisualServoRegion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.VisualServoRegion.displayName = 'proto.fox.proto.VisualServoRegion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SafetyScannerStatusFlags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SafetyScannerStatusFlags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SafetyScannerStatusFlags.displayName = 'proto.fox.proto.SafetyScannerStatusFlags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SafetySystemControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.SafetySystemControl.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.SafetySystemControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SafetySystemControl.displayName = 'proto.fox.proto.SafetySystemControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.RemoteIoControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.RemoteIoControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.RemoteIoControl.displayName = 'proto.fox.proto.RemoteIoControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SystemControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SystemControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SystemControl.displayName = 'proto.fox.proto.SystemControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ForkliftCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ForkliftCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ForkliftCommand.displayName = 'proto.fox.proto.ForkliftCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ControlsState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ControlsState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ControlsState.displayName = 'proto.fox.proto.ControlsState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SafetyScannerState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SafetyScannerState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SafetyScannerState.displayName = 'proto.fox.proto.SafetyScannerState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SafetySystemState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.SafetySystemState.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.SafetySystemState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SafetySystemState.displayName = 'proto.fox.proto.SafetySystemState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SystemStatusFlags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SystemStatusFlags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SystemStatusFlags.displayName = 'proto.fox.proto.SystemStatusFlags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.IOStatusFlags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.IOStatusFlags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.IOStatusFlags.displayName = 'proto.fox.proto.IOStatusFlags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ForkliftState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ForkliftState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ForkliftState.displayName = 'proto.fox.proto.ForkliftState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.HardwareDiagnosticMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.HardwareDiagnosticMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.HardwareDiagnosticMessage.displayName = 'proto.fox.proto.HardwareDiagnosticMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ForkliftOdometry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ForkliftOdometry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ForkliftOdometry.displayName = 'proto.fox.proto.ForkliftOdometry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SafetyCase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SafetyCase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SafetyCase.displayName = 'proto.fox.proto.SafetyCase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.TricycleSegment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.TricycleSegment.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.TricycleSegment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.TricycleSegment.displayName = 'proto.fox.proto.TricycleSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.TricycleTrajectory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.TricycleTrajectory.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.TricycleTrajectory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.TricycleTrajectory.displayName = 'proto.fox.proto.TricycleTrajectory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.DriveStallDetectionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.DriveStallDetectionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.DriveStallDetectionState.displayName = 'proto.fox.proto.DriveStallDetectionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.DriveStallDetectionFlags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.DriveStallDetectionFlags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.DriveStallDetectionFlags.displayName = 'proto.fox.proto.DriveStallDetectionFlags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.StuckDetectionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.StuckDetectionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.StuckDetectionState.displayName = 'proto.fox.proto.StuckDetectionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PathFollowerStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PathFollowerStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PathFollowerStatus.displayName = 'proto.fox.proto.PathFollowerStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.MastStallDetectionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.MastStallDetectionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.MastStallDetectionState.displayName = 'proto.fox.proto.MastStallDetectionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.MastFollowerStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.MastFollowerStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.MastFollowerStatus.displayName = 'proto.fox.proto.MastFollowerStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LaserFieldSetSwitchingColumn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.LaserFieldSetSwitchingColumn.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.LaserFieldSetSwitchingColumn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LaserFieldSetSwitchingColumn.displayName = 'proto.fox.proto.LaserFieldSetSwitchingColumn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LaserFieldSetSwitchingTable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.LaserFieldSetSwitchingTable.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.LaserFieldSetSwitchingTable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LaserFieldSetSwitchingTable.displayName = 'proto.fox.proto.LaserFieldSetSwitchingTable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SafetyScannerTables = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SafetyScannerTables, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SafetyScannerTables.displayName = 'proto.fox.proto.SafetyScannerTables';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Node.displayName = 'proto.fox.proto.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Edge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.Edge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Edge.displayName = 'proto.fox.proto.Edge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Edges = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.Edges.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.Edges, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Edges.displayName = 'proto.fox.proto.Edges';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Roadmap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.Roadmap.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.Roadmap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Roadmap.displayName = 'proto.fox.proto.Roadmap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.MastKinematicConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.MastKinematicConfiguration.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.MastKinematicConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.MastKinematicConfiguration.displayName = 'proto.fox.proto.MastKinematicConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.RawPoseScan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.RawPoseScan.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.RawPoseScan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.RawPoseScan.displayName = 'proto.fox.proto.RawPoseScan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlannerMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.PlannerMap.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.PlannerMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlannerMap.displayName = 'proto.fox.proto.PlannerMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlannerCache = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.PlannerCache.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.PlannerCache, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlannerCache.displayName = 'proto.fox.proto.PlannerCache';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlaceActionInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PlaceActionInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlaceActionInformation.displayName = 'proto.fox.proto.PlaceActionInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlannerState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PlannerState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlannerState.displayName = 'proto.fox.proto.PlannerState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.MotionValidationState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.MotionValidationState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.MotionValidationState.displayName = 'proto.fox.proto.MotionValidationState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PlaceLocationSafetyOffZoneState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlaceLocationSafetyOffZoneState.displayName = 'proto.fox.proto.PlaceLocationSafetyOffZoneState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.BooleanDebouncerState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.BooleanDebouncerState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.BooleanDebouncerState.displayName = 'proto.fox.proto.BooleanDebouncerState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ActionValidationState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ActionValidationState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ActionValidationState.displayName = 'proto.fox.proto.ActionValidationState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ValidationError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ValidationError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ValidationError.displayName = 'proto.fox.proto.ValidationError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ValidationErrorLidarPoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.ValidationErrorLidarPoints.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.ValidationErrorLidarPoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ValidationErrorLidarPoints.displayName = 'proto.fox.proto.ValidationErrorLidarPoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.HardwareError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.HardwareError.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.HardwareError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.HardwareError.displayName = 'proto.fox.proto.HardwareError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.DeviceError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.DeviceError.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.DeviceError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.DeviceError.displayName = 'proto.fox.proto.DeviceError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.CarriedObstacleDetection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.CarriedObstacleDetection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.CarriedObstacleDetection.displayName = 'proto.fox.proto.CarriedObstacleDetection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.CarriedObstacleHypothesis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.CarriedObstacleHypothesis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.CarriedObstacleHypothesis.displayName = 'proto.fox.proto.CarriedObstacleHypothesis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.CarriedObstacleBehaviorState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.CarriedObstacleBehaviorState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.CarriedObstacleBehaviorState.displayName = 'proto.fox.proto.CarriedObstacleBehaviorState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.CarriedObstacleDetectorState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.CarriedObstacleDetectorState.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.CarriedObstacleDetectorState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.CarriedObstacleDetectorState.displayName = 'proto.fox.proto.CarriedObstacleDetectorState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ThresholdState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ThresholdState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ThresholdState.displayName = 'proto.fox.proto.ThresholdState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.MeasureRampAngleState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.MeasureRampAngleState.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.MeasureRampAngleState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.MeasureRampAngleState.displayName = 'proto.fox.proto.MeasureRampAngleState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ValidationState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ValidationState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ValidationState.displayName = 'proto.fox.proto.ValidationState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ControlsError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ControlsError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ControlsError.displayName = 'proto.fox.proto.ControlsError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SpeedFrame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SpeedFrame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SpeedFrame.displayName = 'proto.fox.proto.SpeedFrame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.CommandedSpeed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.CommandedSpeed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.CommandedSpeed.displayName = 'proto.fox.proto.CommandedSpeed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.CommandBuffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.CommandBuffer.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.CommandBuffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.CommandBuffer.displayName = 'proto.fox.proto.CommandBuffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.RampSpeedModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.RampSpeedModel.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.RampSpeedModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.RampSpeedModel.displayName = 'proto.fox.proto.RampSpeedModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.MastHeightTilt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.MastHeightTilt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.MastHeightTilt.displayName = 'proto.fox.proto.MastHeightTilt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.HandGeneratedTailPalletPolicyRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.HandGeneratedTailPalletPolicyRow.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.HandGeneratedTailPalletPolicyRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.HandGeneratedTailPalletPolicyRow.displayName = 'proto.fox.proto.HandGeneratedTailPalletPolicyRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.HandGeneratedTailPalletPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.HandGeneratedTailPalletPolicy.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.HandGeneratedTailPalletPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.HandGeneratedTailPalletPolicy.displayName = 'proto.fox.proto.HandGeneratedTailPalletPolicy';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.TrajectoryIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.TrajectoryIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.TrajectoryIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TrajectoryIndex.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: (f = msg.getPath()) && geometry_proto_path_pb.PathIndex.toObject(includeInstance, f),
    segment: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.TrajectoryIndex}
 */
proto.fox.proto.TrajectoryIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.TrajectoryIndex;
  return proto.fox.proto.TrajectoryIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.TrajectoryIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.TrajectoryIndex}
 */
proto.fox.proto.TrajectoryIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_path_pb.PathIndex;
      reader.readMessage(value,geometry_proto_path_pb.PathIndex.deserializeBinaryFromReader);
      msg.setPath(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSegment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.TrajectoryIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.TrajectoryIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.TrajectoryIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TrajectoryIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_path_pb.PathIndex.serializeBinaryToWriter
    );
  }
  f = message.getSegment();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional PathIndex path = 1;
 * @return {?proto.fox.proto.PathIndex}
 */
proto.fox.proto.TrajectoryIndex.prototype.getPath = function() {
  return /** @type{?proto.fox.proto.PathIndex} */ (
    jspb.Message.getWrapperField(this, geometry_proto_path_pb.PathIndex, 1));
};


/**
 * @param {?proto.fox.proto.PathIndex|undefined} value
 * @return {!proto.fox.proto.TrajectoryIndex} returns this
*/
proto.fox.proto.TrajectoryIndex.prototype.setPath = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TrajectoryIndex} returns this
 */
proto.fox.proto.TrajectoryIndex.prototype.clearPath = function() {
  return this.setPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TrajectoryIndex.prototype.hasPath = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 segment = 2;
 * @return {number}
 */
proto.fox.proto.TrajectoryIndex.prototype.getSegment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TrajectoryIndex} returns this
 */
proto.fox.proto.TrajectoryIndex.prototype.setSegment = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlcStatusFlags.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlcStatusFlags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlcStatusFlags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlcStatusFlags.toObject = function(includeInstance, msg) {
  var f, obj = {
    canActive: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    computeActive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    safetyActive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    autoModeActive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    systemActive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    mastInMotion: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    estopCircuitActive: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    scannerCircuitActive: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    systemStatus: (f = msg.getSystemStatus()) && proto.fox.proto.SystemStatusFlags.toObject(includeInstance, f),
    ioStatus: (f = msg.getIoStatus()) && proto.fox.proto.IOStatusFlags.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlcStatusFlags}
 */
proto.fox.proto.PlcStatusFlags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlcStatusFlags;
  return proto.fox.proto.PlcStatusFlags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlcStatusFlags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlcStatusFlags}
 */
proto.fox.proto.PlcStatusFlags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanActive(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setComputeActive(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSafetyActive(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoModeActive(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSystemActive(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMastInMotion(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEstopCircuitActive(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setScannerCircuitActive(value);
      break;
    case 9:
      var value = new proto.fox.proto.SystemStatusFlags;
      reader.readMessage(value,proto.fox.proto.SystemStatusFlags.deserializeBinaryFromReader);
      msg.setSystemStatus(value);
      break;
    case 10:
      var value = new proto.fox.proto.IOStatusFlags;
      reader.readMessage(value,proto.fox.proto.IOStatusFlags.deserializeBinaryFromReader);
      msg.setIoStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlcStatusFlags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlcStatusFlags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlcStatusFlags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlcStatusFlags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getComputeActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSafetyActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAutoModeActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSystemActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMastInMotion();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getEstopCircuitActive();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getScannerCircuitActive();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSystemStatus();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.fox.proto.SystemStatusFlags.serializeBinaryToWriter
    );
  }
  f = message.getIoStatus();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.fox.proto.IOStatusFlags.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool can_active = 1;
 * @return {boolean}
 */
proto.fox.proto.PlcStatusFlags.prototype.getCanActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
 */
proto.fox.proto.PlcStatusFlags.prototype.setCanActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool compute_active = 2;
 * @return {boolean}
 */
proto.fox.proto.PlcStatusFlags.prototype.getComputeActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
 */
proto.fox.proto.PlcStatusFlags.prototype.setComputeActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool safety_active = 3;
 * @return {boolean}
 */
proto.fox.proto.PlcStatusFlags.prototype.getSafetyActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
 */
proto.fox.proto.PlcStatusFlags.prototype.setSafetyActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool auto_mode_active = 4;
 * @return {boolean}
 */
proto.fox.proto.PlcStatusFlags.prototype.getAutoModeActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
 */
proto.fox.proto.PlcStatusFlags.prototype.setAutoModeActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool system_active = 5;
 * @return {boolean}
 */
proto.fox.proto.PlcStatusFlags.prototype.getSystemActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
 */
proto.fox.proto.PlcStatusFlags.prototype.setSystemActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool mast_in_motion = 6;
 * @return {boolean}
 */
proto.fox.proto.PlcStatusFlags.prototype.getMastInMotion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
 */
proto.fox.proto.PlcStatusFlags.prototype.setMastInMotion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool estop_circuit_active = 7;
 * @return {boolean}
 */
proto.fox.proto.PlcStatusFlags.prototype.getEstopCircuitActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
 */
proto.fox.proto.PlcStatusFlags.prototype.setEstopCircuitActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool scanner_circuit_active = 8;
 * @return {boolean}
 */
proto.fox.proto.PlcStatusFlags.prototype.getScannerCircuitActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
 */
proto.fox.proto.PlcStatusFlags.prototype.setScannerCircuitActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional SystemStatusFlags system_status = 9;
 * @return {?proto.fox.proto.SystemStatusFlags}
 */
proto.fox.proto.PlcStatusFlags.prototype.getSystemStatus = function() {
  return /** @type{?proto.fox.proto.SystemStatusFlags} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.SystemStatusFlags, 9));
};


/**
 * @param {?proto.fox.proto.SystemStatusFlags|undefined} value
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
*/
proto.fox.proto.PlcStatusFlags.prototype.setSystemStatus = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
 */
proto.fox.proto.PlcStatusFlags.prototype.clearSystemStatus = function() {
  return this.setSystemStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlcStatusFlags.prototype.hasSystemStatus = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional IOStatusFlags io_status = 10;
 * @return {?proto.fox.proto.IOStatusFlags}
 */
proto.fox.proto.PlcStatusFlags.prototype.getIoStatus = function() {
  return /** @type{?proto.fox.proto.IOStatusFlags} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.IOStatusFlags, 10));
};


/**
 * @param {?proto.fox.proto.IOStatusFlags|undefined} value
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
*/
proto.fox.proto.PlcStatusFlags.prototype.setIoStatus = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlcStatusFlags} returns this
 */
proto.fox.proto.PlcStatusFlags.prototype.clearIoStatus = function() {
  return this.setIoStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlcStatusFlags.prototype.hasIoStatus = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ImuState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ImuState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ImuState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ImuState.toObject = function(includeInstance, msg) {
  var f, obj = {
    rotX: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    rotY: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    rotZ: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    accelX: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    accelY: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    accelZ: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    pitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    temperature: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ImuState}
 */
proto.fox.proto.ImuState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ImuState;
  return proto.fox.proto.ImuState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ImuState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ImuState}
 */
proto.fox.proto.ImuState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRotX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRotY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRotZ(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAccelX(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAccelY(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAccelZ(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPitch(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTemperature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ImuState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ImuState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ImuState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ImuState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRotX();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getRotY();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getRotZ();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAccelX();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getAccelY();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAccelZ();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getTemperature();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional float rot_x = 1;
 * @return {number}
 */
proto.fox.proto.ImuState.prototype.getRotX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ImuState} returns this
 */
proto.fox.proto.ImuState.prototype.setRotX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float rot_y = 2;
 * @return {number}
 */
proto.fox.proto.ImuState.prototype.getRotY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ImuState} returns this
 */
proto.fox.proto.ImuState.prototype.setRotY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float rot_z = 3;
 * @return {number}
 */
proto.fox.proto.ImuState.prototype.getRotZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ImuState} returns this
 */
proto.fox.proto.ImuState.prototype.setRotZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float accel_x = 4;
 * @return {number}
 */
proto.fox.proto.ImuState.prototype.getAccelX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ImuState} returns this
 */
proto.fox.proto.ImuState.prototype.setAccelX = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float accel_y = 5;
 * @return {number}
 */
proto.fox.proto.ImuState.prototype.getAccelY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ImuState} returns this
 */
proto.fox.proto.ImuState.prototype.setAccelY = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float accel_z = 6;
 * @return {number}
 */
proto.fox.proto.ImuState.prototype.getAccelZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ImuState} returns this
 */
proto.fox.proto.ImuState.prototype.setAccelZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float pitch = 9;
 * @return {number}
 */
proto.fox.proto.ImuState.prototype.getPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ImuState} returns this
 */
proto.fox.proto.ImuState.prototype.setPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional int32 temperature = 8;
 * @return {number}
 */
proto.fox.proto.ImuState.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ImuState} returns this
 */
proto.fox.proto.ImuState.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.TricycleDriveControl.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.TricycleDriveControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.TricycleDriveControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TricycleDriveControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    rightWheelSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    leftWheelSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    steerAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    steerVelocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    rightMotorEffort: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    leftMotorEffort: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.TricycleDriveControl}
 */
proto.fox.proto.TricycleDriveControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.TricycleDriveControl;
  return proto.fox.proto.TricycleDriveControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.TricycleDriveControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.TricycleDriveControl}
 */
proto.fox.proto.TricycleDriveControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRightWheelSpeed(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLeftWheelSpeed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSteerAngle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSteerVelocity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRightMotorEffort(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLeftMotorEffort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.TricycleDriveControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.TricycleDriveControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.TricycleDriveControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TricycleDriveControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRightWheelSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLeftWheelSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getSteerAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSteerVelocity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRightMotorEffort();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLeftMotorEffort();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional double right_wheel_speed = 1;
 * @return {number}
 */
proto.fox.proto.TricycleDriveControl.prototype.getRightWheelSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleDriveControl} returns this
 */
proto.fox.proto.TricycleDriveControl.prototype.setRightWheelSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double left_wheel_speed = 2;
 * @return {number}
 */
proto.fox.proto.TricycleDriveControl.prototype.getLeftWheelSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleDriveControl} returns this
 */
proto.fox.proto.TricycleDriveControl.prototype.setLeftWheelSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double steer_angle = 3;
 * @return {number}
 */
proto.fox.proto.TricycleDriveControl.prototype.getSteerAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleDriveControl} returns this
 */
proto.fox.proto.TricycleDriveControl.prototype.setSteerAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double steer_velocity = 4;
 * @return {number}
 */
proto.fox.proto.TricycleDriveControl.prototype.getSteerVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleDriveControl} returns this
 */
proto.fox.proto.TricycleDriveControl.prototype.setSteerVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double right_motor_effort = 5;
 * @return {number}
 */
proto.fox.proto.TricycleDriveControl.prototype.getRightMotorEffort = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleDriveControl} returns this
 */
proto.fox.proto.TricycleDriveControl.prototype.setRightMotorEffort = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double left_motor_effort = 6;
 * @return {number}
 */
proto.fox.proto.TricycleDriveControl.prototype.getLeftMotorEffort = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleDriveControl} returns this
 */
proto.fox.proto.TricycleDriveControl.prototype.setLeftMotorEffort = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.CollisionFootprint.repeatedFields_ = [3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.CollisionFootprint.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.CollisionFootprint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.CollisionFootprint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CollisionFootprint.toObject = function(includeInstance, msg) {
  var f, obj = {
    boundsMin: (f = msg.getBoundsMin()) && geometry_proto_transform_pb.Vector2d.toObject(includeInstance, f),
    boundsMax: (f = msg.getBoundsMax()) && geometry_proto_transform_pb.Vector2d.toObject(includeInstance, f),
    circlesList: jspb.Message.toObjectList(msg.getCirclesList(),
    geometry_proto_geometry_pb.Circle2d.toObject, includeInstance),
    bodyBoundsMin: (f = msg.getBodyBoundsMin()) && geometry_proto_transform_pb.Vector2d.toObject(includeInstance, f),
    bodyBoundsMax: (f = msg.getBodyBoundsMax()) && geometry_proto_transform_pb.Vector2d.toObject(includeInstance, f),
    bodyQuadsList: jspb.Message.toObjectList(msg.getBodyQuadsList(),
    geometry_proto_geometry_pb.Quad2d.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.CollisionFootprint}
 */
proto.fox.proto.CollisionFootprint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.CollisionFootprint;
  return proto.fox.proto.CollisionFootprint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.CollisionFootprint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.CollisionFootprint}
 */
proto.fox.proto.CollisionFootprint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Vector2d;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2d.deserializeBinaryFromReader);
      msg.setBoundsMin(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Vector2d;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2d.deserializeBinaryFromReader);
      msg.setBoundsMax(value);
      break;
    case 3:
      var value = new geometry_proto_geometry_pb.Circle2d;
      reader.readMessage(value,geometry_proto_geometry_pb.Circle2d.deserializeBinaryFromReader);
      msg.addCircles(value);
      break;
    case 4:
      var value = new geometry_proto_transform_pb.Vector2d;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2d.deserializeBinaryFromReader);
      msg.setBodyBoundsMin(value);
      break;
    case 5:
      var value = new geometry_proto_transform_pb.Vector2d;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2d.deserializeBinaryFromReader);
      msg.setBodyBoundsMax(value);
      break;
    case 6:
      var value = new geometry_proto_geometry_pb.Quad2d;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad2d.deserializeBinaryFromReader);
      msg.addBodyQuads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.CollisionFootprint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.CollisionFootprint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.CollisionFootprint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CollisionFootprint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBoundsMin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Vector2d.serializeBinaryToWriter
    );
  }
  f = message.getBoundsMax();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Vector2d.serializeBinaryToWriter
    );
  }
  f = message.getCirclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      geometry_proto_geometry_pb.Circle2d.serializeBinaryToWriter
    );
  }
  f = message.getBodyBoundsMin();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      geometry_proto_transform_pb.Vector2d.serializeBinaryToWriter
    );
  }
  f = message.getBodyBoundsMax();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      geometry_proto_transform_pb.Vector2d.serializeBinaryToWriter
    );
  }
  f = message.getBodyQuadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      geometry_proto_geometry_pb.Quad2d.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vector2d bounds_min = 1;
 * @return {?proto.fox.proto.Vector2d}
 */
proto.fox.proto.CollisionFootprint.prototype.getBoundsMin = function() {
  return /** @type{?proto.fox.proto.Vector2d} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector2d, 1));
};


/**
 * @param {?proto.fox.proto.Vector2d|undefined} value
 * @return {!proto.fox.proto.CollisionFootprint} returns this
*/
proto.fox.proto.CollisionFootprint.prototype.setBoundsMin = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.CollisionFootprint} returns this
 */
proto.fox.proto.CollisionFootprint.prototype.clearBoundsMin = function() {
  return this.setBoundsMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.CollisionFootprint.prototype.hasBoundsMin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Vector2d bounds_max = 2;
 * @return {?proto.fox.proto.Vector2d}
 */
proto.fox.proto.CollisionFootprint.prototype.getBoundsMax = function() {
  return /** @type{?proto.fox.proto.Vector2d} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector2d, 2));
};


/**
 * @param {?proto.fox.proto.Vector2d|undefined} value
 * @return {!proto.fox.proto.CollisionFootprint} returns this
*/
proto.fox.proto.CollisionFootprint.prototype.setBoundsMax = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.CollisionFootprint} returns this
 */
proto.fox.proto.CollisionFootprint.prototype.clearBoundsMax = function() {
  return this.setBoundsMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.CollisionFootprint.prototype.hasBoundsMax = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Circle2d circles = 3;
 * @return {!Array<!proto.fox.proto.Circle2d>}
 */
proto.fox.proto.CollisionFootprint.prototype.getCirclesList = function() {
  return /** @type{!Array<!proto.fox.proto.Circle2d>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_geometry_pb.Circle2d, 3));
};


/**
 * @param {!Array<!proto.fox.proto.Circle2d>} value
 * @return {!proto.fox.proto.CollisionFootprint} returns this
*/
proto.fox.proto.CollisionFootprint.prototype.setCirclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.Circle2d=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Circle2d}
 */
proto.fox.proto.CollisionFootprint.prototype.addCircles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.Circle2d, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.CollisionFootprint} returns this
 */
proto.fox.proto.CollisionFootprint.prototype.clearCirclesList = function() {
  return this.setCirclesList([]);
};


/**
 * optional Vector2d body_bounds_min = 4;
 * @return {?proto.fox.proto.Vector2d}
 */
proto.fox.proto.CollisionFootprint.prototype.getBodyBoundsMin = function() {
  return /** @type{?proto.fox.proto.Vector2d} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector2d, 4));
};


/**
 * @param {?proto.fox.proto.Vector2d|undefined} value
 * @return {!proto.fox.proto.CollisionFootprint} returns this
*/
proto.fox.proto.CollisionFootprint.prototype.setBodyBoundsMin = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.CollisionFootprint} returns this
 */
proto.fox.proto.CollisionFootprint.prototype.clearBodyBoundsMin = function() {
  return this.setBodyBoundsMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.CollisionFootprint.prototype.hasBodyBoundsMin = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Vector2d body_bounds_max = 5;
 * @return {?proto.fox.proto.Vector2d}
 */
proto.fox.proto.CollisionFootprint.prototype.getBodyBoundsMax = function() {
  return /** @type{?proto.fox.proto.Vector2d} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector2d, 5));
};


/**
 * @param {?proto.fox.proto.Vector2d|undefined} value
 * @return {!proto.fox.proto.CollisionFootprint} returns this
*/
proto.fox.proto.CollisionFootprint.prototype.setBodyBoundsMax = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.CollisionFootprint} returns this
 */
proto.fox.proto.CollisionFootprint.prototype.clearBodyBoundsMax = function() {
  return this.setBodyBoundsMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.CollisionFootprint.prototype.hasBodyBoundsMax = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Quad2d body_quads = 6;
 * @return {!Array<!proto.fox.proto.Quad2d>}
 */
proto.fox.proto.CollisionFootprint.prototype.getBodyQuadsList = function() {
  return /** @type{!Array<!proto.fox.proto.Quad2d>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_geometry_pb.Quad2d, 6));
};


/**
 * @param {!Array<!proto.fox.proto.Quad2d>} value
 * @return {!proto.fox.proto.CollisionFootprint} returns this
*/
proto.fox.proto.CollisionFootprint.prototype.setBodyQuadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.fox.proto.Quad2d=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Quad2d}
 */
proto.fox.proto.CollisionFootprint.prototype.addBodyQuads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.fox.proto.Quad2d, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.CollisionFootprint} returns this
 */
proto.fox.proto.CollisionFootprint.prototype.clearBodyQuadsList = function() {
  return this.setBodyQuadsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.DualDriveControl.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.DualDriveControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.DualDriveControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DualDriveControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    outerWheelSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    steerAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.DualDriveControl}
 */
proto.fox.proto.DualDriveControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.DualDriveControl;
  return proto.fox.proto.DualDriveControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.DualDriveControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.DualDriveControl}
 */
proto.fox.proto.DualDriveControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOuterWheelSpeed(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSteerAngle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.DualDriveControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.DualDriveControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.DualDriveControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DualDriveControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOuterWheelSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSteerAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double outer_wheel_speed = 1;
 * @return {number}
 */
proto.fox.proto.DualDriveControl.prototype.getOuterWheelSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.DualDriveControl} returns this
 */
proto.fox.proto.DualDriveControl.prototype.setOuterWheelSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double steer_angle = 2;
 * @return {number}
 */
proto.fox.proto.DualDriveControl.prototype.getSteerAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.DualDriveControl} returns this
 */
proto.fox.proto.DualDriveControl.prototype.setSteerAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.MastControl.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.MastControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.MastControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    liftPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    tiltExtensionPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    shiftPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    visualServoStrategy: jspb.Message.getFieldWithDefault(msg, 4, 0),
    visualServoTarget: jspb.Message.getFieldWithDefault(msg, 5, 0),
    allowRestingForks: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    forkSpread: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.MastControl}
 */
proto.fox.proto.MastControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.MastControl;
  return proto.fox.proto.MastControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.MastControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.MastControl}
 */
proto.fox.proto.MastControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLiftPosition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTiltExtensionPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setShiftPosition(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.VisualServoStrategy} */ (reader.readEnum());
      msg.setVisualServoStrategy(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVisualServoTarget(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowRestingForks(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setForkSpread(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.MastControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.MastControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.MastControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiftPosition();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTiltExtensionPosition();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getShiftPosition();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getVisualServoStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVisualServoTarget();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAllowRestingForks();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getForkSpread();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional double lift_position = 1;
 * @return {number}
 */
proto.fox.proto.MastControl.prototype.getLiftPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastControl} returns this
 */
proto.fox.proto.MastControl.prototype.setLiftPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double tilt_extension_position = 2;
 * @return {number}
 */
proto.fox.proto.MastControl.prototype.getTiltExtensionPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastControl} returns this
 */
proto.fox.proto.MastControl.prototype.setTiltExtensionPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double shift_position = 3;
 * @return {number}
 */
proto.fox.proto.MastControl.prototype.getShiftPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastControl} returns this
 */
proto.fox.proto.MastControl.prototype.setShiftPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional VisualServoStrategy visual_servo_strategy = 4;
 * @return {!proto.fox.proto.VisualServoStrategy}
 */
proto.fox.proto.MastControl.prototype.getVisualServoStrategy = function() {
  return /** @type {!proto.fox.proto.VisualServoStrategy} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.VisualServoStrategy} value
 * @return {!proto.fox.proto.MastControl} returns this
 */
proto.fox.proto.MastControl.prototype.setVisualServoStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 visual_servo_target = 5;
 * @return {number}
 */
proto.fox.proto.MastControl.prototype.getVisualServoTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastControl} returns this
 */
proto.fox.proto.MastControl.prototype.setVisualServoTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool allow_resting_forks = 6;
 * @return {boolean}
 */
proto.fox.proto.MastControl.prototype.getAllowRestingForks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.MastControl} returns this
 */
proto.fox.proto.MastControl.prototype.setAllowRestingForks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional double fork_spread = 7;
 * @return {number}
 */
proto.fox.proto.MastControl.prototype.getForkSpread = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastControl} returns this
 */
proto.fox.proto.MastControl.prototype.setForkSpread = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.MastVelocity.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.MastVelocity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.MastVelocity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastVelocity.toObject = function(includeInstance, msg) {
  var f, obj = {
    liftVelocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    tiltVelocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    shiftVelocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    forkVelocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.MastVelocity}
 */
proto.fox.proto.MastVelocity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.MastVelocity;
  return proto.fox.proto.MastVelocity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.MastVelocity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.MastVelocity}
 */
proto.fox.proto.MastVelocity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLiftVelocity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTiltVelocity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setShiftVelocity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setForkVelocity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.MastVelocity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.MastVelocity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.MastVelocity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastVelocity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiftVelocity();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTiltVelocity();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getShiftVelocity();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getForkVelocity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double lift_velocity = 1;
 * @return {number}
 */
proto.fox.proto.MastVelocity.prototype.getLiftVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastVelocity} returns this
 */
proto.fox.proto.MastVelocity.prototype.setLiftVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double tilt_velocity = 2;
 * @return {number}
 */
proto.fox.proto.MastVelocity.prototype.getTiltVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastVelocity} returns this
 */
proto.fox.proto.MastVelocity.prototype.setTiltVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double shift_velocity = 3;
 * @return {number}
 */
proto.fox.proto.MastVelocity.prototype.getShiftVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastVelocity} returns this
 */
proto.fox.proto.MastVelocity.prototype.setShiftVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double fork_velocity = 4;
 * @return {number}
 */
proto.fox.proto.MastVelocity.prototype.getForkVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastVelocity} returns this
 */
proto.fox.proto.MastVelocity.prototype.setForkVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.VisualServoState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.VisualServoState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.VisualServoState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.VisualServoState.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentAction: jspb.Message.getFieldWithDefault(msg, 1, 0),
    trajectory: jspb.Message.getFieldWithDefault(msg, 2, 0),
    segment: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pocketsObservedTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    robotTLeftFork: (f = msg.getRobotTLeftFork()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    targetPoseChangeTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    targetPoseAchievedTime: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.VisualServoState}
 */
proto.fox.proto.VisualServoState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.VisualServoState;
  return proto.fox.proto.VisualServoState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.VisualServoState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.VisualServoState}
 */
proto.fox.proto.VisualServoState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.VisualServoState.VisualServoStatus} */ (reader.readEnum());
      msg.setCurrentAction(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrajectory(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSegment(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPocketsObservedTime(value);
      break;
    case 5:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setRobotTLeftFork(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetPoseChangeTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetPoseAchievedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.VisualServoState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.VisualServoState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.VisualServoState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.VisualServoState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTrajectory();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSegment();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPocketsObservedTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getRobotTLeftFork();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getTargetPoseChangeTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTargetPoseAchievedTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.VisualServoState.VisualServoStatus = {
  IDLE: 0,
  COMPLETE: 1,
  FAILED: 2,
  OPEN_LOOP: 3,
  TRACKING: 4,
  SETTLING: 5,
  FOLLOWING: 6
};

/**
 * optional VisualServoStatus current_action = 1;
 * @return {!proto.fox.proto.VisualServoState.VisualServoStatus}
 */
proto.fox.proto.VisualServoState.prototype.getCurrentAction = function() {
  return /** @type {!proto.fox.proto.VisualServoState.VisualServoStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.VisualServoState.VisualServoStatus} value
 * @return {!proto.fox.proto.VisualServoState} returns this
 */
proto.fox.proto.VisualServoState.prototype.setCurrentAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 trajectory = 2;
 * @return {number}
 */
proto.fox.proto.VisualServoState.prototype.getTrajectory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.VisualServoState} returns this
 */
proto.fox.proto.VisualServoState.prototype.setTrajectory = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 segment = 3;
 * @return {number}
 */
proto.fox.proto.VisualServoState.prototype.getSegment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.VisualServoState} returns this
 */
proto.fox.proto.VisualServoState.prototype.setSegment = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 pockets_observed_time = 4;
 * @return {number}
 */
proto.fox.proto.VisualServoState.prototype.getPocketsObservedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.VisualServoState} returns this
 */
proto.fox.proto.VisualServoState.prototype.setPocketsObservedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Transform3f robot_t_left_fork = 5;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.VisualServoState.prototype.getRobotTLeftFork = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 5));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.VisualServoState} returns this
*/
proto.fox.proto.VisualServoState.prototype.setRobotTLeftFork = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.VisualServoState} returns this
 */
proto.fox.proto.VisualServoState.prototype.clearRobotTLeftFork = function() {
  return this.setRobotTLeftFork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.VisualServoState.prototype.hasRobotTLeftFork = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 target_pose_change_time = 6;
 * @return {number}
 */
proto.fox.proto.VisualServoState.prototype.getTargetPoseChangeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.VisualServoState} returns this
 */
proto.fox.proto.VisualServoState.prototype.setTargetPoseChangeTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 target_pose_achieved_time = 7;
 * @return {number}
 */
proto.fox.proto.VisualServoState.prototype.getTargetPoseAchievedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.VisualServoState} returns this
 */
proto.fox.proto.VisualServoState.prototype.setTargetPoseAchievedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.VisualServoRegion.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.VisualServoRegion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.VisualServoRegion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.VisualServoRegion.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    end: jspb.Message.getFieldWithDefault(msg, 2, 0),
    strategy: jspb.Message.getFieldWithDefault(msg, 3, 0),
    targetPalletId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tolerance: (f = msg.getTolerance()) && geometry_proto_transform_pb.Vector3d.toObject(includeInstance, f),
    offset: (f = msg.getOffset()) && geometry_proto_transform_pb.Vector3d.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.VisualServoRegion}
 */
proto.fox.proto.VisualServoRegion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.VisualServoRegion;
  return proto.fox.proto.VisualServoRegion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.VisualServoRegion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.VisualServoRegion}
 */
proto.fox.proto.VisualServoRegion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEnd(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.VisualServoStrategy} */ (reader.readEnum());
      msg.setStrategy(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetPalletId(value);
      break;
    case 5:
      var value = new geometry_proto_transform_pb.Vector3d;
      reader.readMessage(value,geometry_proto_transform_pb.Vector3d.deserializeBinaryFromReader);
      msg.setTolerance(value);
      break;
    case 6:
      var value = new geometry_proto_transform_pb.Vector3d;
      reader.readMessage(value,geometry_proto_transform_pb.Vector3d.deserializeBinaryFromReader);
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.VisualServoRegion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.VisualServoRegion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.VisualServoRegion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.VisualServoRegion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTargetPalletId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTolerance();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      geometry_proto_transform_pb.Vector3d.serializeBinaryToWriter
    );
  }
  f = message.getOffset();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      geometry_proto_transform_pb.Vector3d.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.fox.proto.VisualServoRegion.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.VisualServoRegion} returns this
 */
proto.fox.proto.VisualServoRegion.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 end = 2;
 * @return {number}
 */
proto.fox.proto.VisualServoRegion.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.VisualServoRegion} returns this
 */
proto.fox.proto.VisualServoRegion.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional VisualServoStrategy strategy = 3;
 * @return {!proto.fox.proto.VisualServoStrategy}
 */
proto.fox.proto.VisualServoRegion.prototype.getStrategy = function() {
  return /** @type {!proto.fox.proto.VisualServoStrategy} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.VisualServoStrategy} value
 * @return {!proto.fox.proto.VisualServoRegion} returns this
 */
proto.fox.proto.VisualServoRegion.prototype.setStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 target_pallet_id = 4;
 * @return {number}
 */
proto.fox.proto.VisualServoRegion.prototype.getTargetPalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.VisualServoRegion} returns this
 */
proto.fox.proto.VisualServoRegion.prototype.setTargetPalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Vector3d tolerance = 5;
 * @return {?proto.fox.proto.Vector3d}
 */
proto.fox.proto.VisualServoRegion.prototype.getTolerance = function() {
  return /** @type{?proto.fox.proto.Vector3d} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector3d, 5));
};


/**
 * @param {?proto.fox.proto.Vector3d|undefined} value
 * @return {!proto.fox.proto.VisualServoRegion} returns this
*/
proto.fox.proto.VisualServoRegion.prototype.setTolerance = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.VisualServoRegion} returns this
 */
proto.fox.proto.VisualServoRegion.prototype.clearTolerance = function() {
  return this.setTolerance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.VisualServoRegion.prototype.hasTolerance = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Vector3d offset = 6;
 * @return {?proto.fox.proto.Vector3d}
 */
proto.fox.proto.VisualServoRegion.prototype.getOffset = function() {
  return /** @type{?proto.fox.proto.Vector3d} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector3d, 6));
};


/**
 * @param {?proto.fox.proto.Vector3d|undefined} value
 * @return {!proto.fox.proto.VisualServoRegion} returns this
*/
proto.fox.proto.VisualServoRegion.prototype.setOffset = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.VisualServoRegion} returns this
 */
proto.fox.proto.VisualServoRegion.prototype.clearOffset = function() {
  return this.setOffset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.VisualServoRegion.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SafetyScannerStatusFlags.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SafetyScannerStatusFlags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SafetyScannerStatusFlags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetyScannerStatusFlags.toObject = function(includeInstance, msg) {
  var f, obj = {
    ready: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    contaminationWarning: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    contaminationError: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SafetyScannerStatusFlags}
 */
proto.fox.proto.SafetyScannerStatusFlags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SafetyScannerStatusFlags;
  return proto.fox.proto.SafetyScannerStatusFlags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SafetyScannerStatusFlags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SafetyScannerStatusFlags}
 */
proto.fox.proto.SafetyScannerStatusFlags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReady(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContaminationWarning(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContaminationError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SafetyScannerStatusFlags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SafetyScannerStatusFlags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SafetyScannerStatusFlags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetyScannerStatusFlags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReady();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getContaminationWarning();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getContaminationError();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool ready = 1;
 * @return {boolean}
 */
proto.fox.proto.SafetyScannerStatusFlags.prototype.getReady = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.SafetyScannerStatusFlags} returns this
 */
proto.fox.proto.SafetyScannerStatusFlags.prototype.setReady = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool contamination_warning = 2;
 * @return {boolean}
 */
proto.fox.proto.SafetyScannerStatusFlags.prototype.getContaminationWarning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.SafetyScannerStatusFlags} returns this
 */
proto.fox.proto.SafetyScannerStatusFlags.prototype.setContaminationWarning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool contamination_error = 3;
 * @return {boolean}
 */
proto.fox.proto.SafetyScannerStatusFlags.prototype.getContaminationError = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.SafetyScannerStatusFlags} returns this
 */
proto.fox.proto.SafetyScannerStatusFlags.prototype.setContaminationError = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.SafetySystemControl.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SafetySystemControl.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SafetySystemControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SafetySystemControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetySystemControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    modesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SafetySystemControl}
 */
proto.fox.proto.SafetySystemControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SafetySystemControl;
  return proto.fox.proto.SafetySystemControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SafetySystemControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SafetySystemControl}
 */
proto.fox.proto.SafetySystemControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<!proto.fox.proto.SafetyScannerMode>} */ (reader.readPackedEnum());
      msg.setModesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SafetySystemControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SafetySystemControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SafetySystemControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetySystemControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated SafetyScannerMode modes = 1;
 * @return {!Array<!proto.fox.proto.SafetyScannerMode>}
 */
proto.fox.proto.SafetySystemControl.prototype.getModesList = function() {
  return /** @type {!Array<!proto.fox.proto.SafetyScannerMode>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.fox.proto.SafetyScannerMode>} value
 * @return {!proto.fox.proto.SafetySystemControl} returns this
 */
proto.fox.proto.SafetySystemControl.prototype.setModesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.fox.proto.SafetyScannerMode} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.SafetySystemControl} returns this
 */
proto.fox.proto.SafetySystemControl.prototype.addModes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.SafetySystemControl} returns this
 */
proto.fox.proto.SafetySystemControl.prototype.clearModesList = function() {
  return this.setModesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.RemoteIoControl.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.RemoteIoControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.RemoteIoControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RemoteIoControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    shortHonk: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    blueStackLight: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.RemoteIoControl}
 */
proto.fox.proto.RemoteIoControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.RemoteIoControl;
  return proto.fox.proto.RemoteIoControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.RemoteIoControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.RemoteIoControl}
 */
proto.fox.proto.RemoteIoControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShortHonk(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlueStackLight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.RemoteIoControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.RemoteIoControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.RemoteIoControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RemoteIoControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShortHonk();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBlueStackLight();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool short_honk = 1;
 * @return {boolean}
 */
proto.fox.proto.RemoteIoControl.prototype.getShortHonk = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.RemoteIoControl} returns this
 */
proto.fox.proto.RemoteIoControl.prototype.setShortHonk = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool blue_stack_light = 2;
 * @return {boolean}
 */
proto.fox.proto.RemoteIoControl.prototype.getBlueStackLight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.RemoteIoControl} returns this
 */
proto.fox.proto.RemoteIoControl.prototype.setBlueStackLight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SystemControl.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SystemControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SystemControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SystemControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    remoteFaultRequest: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    remoteInterlockRequest: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SystemControl}
 */
proto.fox.proto.SystemControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SystemControl;
  return proto.fox.proto.SystemControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SystemControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SystemControl}
 */
proto.fox.proto.SystemControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoteFaultRequest(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoteInterlockRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SystemControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SystemControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SystemControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SystemControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemoteFaultRequest();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRemoteInterlockRequest();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool remote_fault_request = 1;
 * @return {boolean}
 */
proto.fox.proto.SystemControl.prototype.getRemoteFaultRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.SystemControl} returns this
 */
proto.fox.proto.SystemControl.prototype.setRemoteFaultRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool remote_interlock_request = 2;
 * @return {boolean}
 */
proto.fox.proto.SystemControl.prototype.getRemoteInterlockRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.SystemControl} returns this
 */
proto.fox.proto.SystemControl.prototype.setRemoteInterlockRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ForkliftCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ForkliftCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ForkliftCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ForkliftCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    driveCommand: (f = msg.getDriveCommand()) && proto.fox.proto.TricycleDriveControl.toObject(includeInstance, f),
    mastCommand: (f = msg.getMastCommand()) && proto.fox.proto.MastControl.toObject(includeInstance, f),
    steerCommand: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    safetyCommand: (f = msg.getSafetyCommand()) && proto.fox.proto.SafetySystemControl.toObject(includeInstance, f),
    remoteIoCommand: (f = msg.getRemoteIoCommand()) && proto.fox.proto.RemoteIoControl.toObject(includeInstance, f),
    systemCommand: (f = msg.getSystemCommand()) && proto.fox.proto.SystemControl.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ForkliftCommand}
 */
proto.fox.proto.ForkliftCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ForkliftCommand;
  return proto.fox.proto.ForkliftCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ForkliftCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ForkliftCommand}
 */
proto.fox.proto.ForkliftCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.TricycleDriveControl;
      reader.readMessage(value,proto.fox.proto.TricycleDriveControl.deserializeBinaryFromReader);
      msg.setDriveCommand(value);
      break;
    case 2:
      var value = new proto.fox.proto.MastControl;
      reader.readMessage(value,proto.fox.proto.MastControl.deserializeBinaryFromReader);
      msg.setMastCommand(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSteerCommand(value);
      break;
    case 5:
      var value = new proto.fox.proto.SafetySystemControl;
      reader.readMessage(value,proto.fox.proto.SafetySystemControl.deserializeBinaryFromReader);
      msg.setSafetyCommand(value);
      break;
    case 6:
      var value = new proto.fox.proto.RemoteIoControl;
      reader.readMessage(value,proto.fox.proto.RemoteIoControl.deserializeBinaryFromReader);
      msg.setRemoteIoCommand(value);
      break;
    case 7:
      var value = new proto.fox.proto.SystemControl;
      reader.readMessage(value,proto.fox.proto.SystemControl.deserializeBinaryFromReader);
      msg.setSystemCommand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ForkliftCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ForkliftCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ForkliftCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ForkliftCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriveCommand();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.TricycleDriveControl.serializeBinaryToWriter
    );
  }
  f = message.getMastCommand();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.MastControl.serializeBinaryToWriter
    );
  }
  f = message.getSteerCommand();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSafetyCommand();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.fox.proto.SafetySystemControl.serializeBinaryToWriter
    );
  }
  f = message.getRemoteIoCommand();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.fox.proto.RemoteIoControl.serializeBinaryToWriter
    );
  }
  f = message.getSystemCommand();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.fox.proto.SystemControl.serializeBinaryToWriter
    );
  }
};


/**
 * optional TricycleDriveControl drive_command = 1;
 * @return {?proto.fox.proto.TricycleDriveControl}
 */
proto.fox.proto.ForkliftCommand.prototype.getDriveCommand = function() {
  return /** @type{?proto.fox.proto.TricycleDriveControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.TricycleDriveControl, 1));
};


/**
 * @param {?proto.fox.proto.TricycleDriveControl|undefined} value
 * @return {!proto.fox.proto.ForkliftCommand} returns this
*/
proto.fox.proto.ForkliftCommand.prototype.setDriveCommand = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftCommand} returns this
 */
proto.fox.proto.ForkliftCommand.prototype.clearDriveCommand = function() {
  return this.setDriveCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftCommand.prototype.hasDriveCommand = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MastControl mast_command = 2;
 * @return {?proto.fox.proto.MastControl}
 */
proto.fox.proto.ForkliftCommand.prototype.getMastCommand = function() {
  return /** @type{?proto.fox.proto.MastControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MastControl, 2));
};


/**
 * @param {?proto.fox.proto.MastControl|undefined} value
 * @return {!proto.fox.proto.ForkliftCommand} returns this
*/
proto.fox.proto.ForkliftCommand.prototype.setMastCommand = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftCommand} returns this
 */
proto.fox.proto.ForkliftCommand.prototype.clearMastCommand = function() {
  return this.setMastCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftCommand.prototype.hasMastCommand = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double steer_command = 4;
 * @return {number}
 */
proto.fox.proto.ForkliftCommand.prototype.getSteerCommand = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ForkliftCommand} returns this
 */
proto.fox.proto.ForkliftCommand.prototype.setSteerCommand = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional SafetySystemControl safety_command = 5;
 * @return {?proto.fox.proto.SafetySystemControl}
 */
proto.fox.proto.ForkliftCommand.prototype.getSafetyCommand = function() {
  return /** @type{?proto.fox.proto.SafetySystemControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.SafetySystemControl, 5));
};


/**
 * @param {?proto.fox.proto.SafetySystemControl|undefined} value
 * @return {!proto.fox.proto.ForkliftCommand} returns this
*/
proto.fox.proto.ForkliftCommand.prototype.setSafetyCommand = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftCommand} returns this
 */
proto.fox.proto.ForkliftCommand.prototype.clearSafetyCommand = function() {
  return this.setSafetyCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftCommand.prototype.hasSafetyCommand = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RemoteIoControl remote_io_command = 6;
 * @return {?proto.fox.proto.RemoteIoControl}
 */
proto.fox.proto.ForkliftCommand.prototype.getRemoteIoCommand = function() {
  return /** @type{?proto.fox.proto.RemoteIoControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.RemoteIoControl, 6));
};


/**
 * @param {?proto.fox.proto.RemoteIoControl|undefined} value
 * @return {!proto.fox.proto.ForkliftCommand} returns this
*/
proto.fox.proto.ForkliftCommand.prototype.setRemoteIoCommand = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftCommand} returns this
 */
proto.fox.proto.ForkliftCommand.prototype.clearRemoteIoCommand = function() {
  return this.setRemoteIoCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftCommand.prototype.hasRemoteIoCommand = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SystemControl system_command = 7;
 * @return {?proto.fox.proto.SystemControl}
 */
proto.fox.proto.ForkliftCommand.prototype.getSystemCommand = function() {
  return /** @type{?proto.fox.proto.SystemControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.SystemControl, 7));
};


/**
 * @param {?proto.fox.proto.SystemControl|undefined} value
 * @return {!proto.fox.proto.ForkliftCommand} returns this
*/
proto.fox.proto.ForkliftCommand.prototype.setSystemCommand = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftCommand} returns this
 */
proto.fox.proto.ForkliftCommand.prototype.clearSystemCommand = function() {
  return this.setSystemCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftCommand.prototype.hasSystemCommand = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ControlsState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ControlsState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ControlsState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ControlsState.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastValidPathTimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cabinPresenceSensorEngagedTimestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cabinPresenceSensorLatched: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    pedalDepressedTimestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pedalDepressedLatched: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    requireNearSpaceFieldsClear: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    nearSpaceFieldsClearTimestamp: jspb.Message.getFieldWithDefault(msg, 7, 0),
    nearSpaceFieldsWereClear: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ControlsState}
 */
proto.fox.proto.ControlsState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ControlsState;
  return proto.fox.proto.ControlsState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ControlsState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ControlsState}
 */
proto.fox.proto.ControlsState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastValidPathTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCabinPresenceSensorEngagedTimestamp(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCabinPresenceSensorLatched(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPedalDepressedTimestamp(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPedalDepressedLatched(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequireNearSpaceFieldsClear(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNearSpaceFieldsClearTimestamp(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNearSpaceFieldsWereClear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ControlsState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ControlsState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ControlsState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ControlsState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastValidPathTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCabinPresenceSensorEngagedTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCabinPresenceSensorLatched();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPedalDepressedTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPedalDepressedLatched();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRequireNearSpaceFieldsClear();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getNearSpaceFieldsClearTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getNearSpaceFieldsWereClear();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int64 last_valid_path_timestamp = 1;
 * @return {number}
 */
proto.fox.proto.ControlsState.prototype.getLastValidPathTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ControlsState} returns this
 */
proto.fox.proto.ControlsState.prototype.setLastValidPathTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 cabin_presence_sensor_engaged_timestamp = 2;
 * @return {number}
 */
proto.fox.proto.ControlsState.prototype.getCabinPresenceSensorEngagedTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ControlsState} returns this
 */
proto.fox.proto.ControlsState.prototype.setCabinPresenceSensorEngagedTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool cabin_presence_sensor_latched = 3;
 * @return {boolean}
 */
proto.fox.proto.ControlsState.prototype.getCabinPresenceSensorLatched = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.ControlsState} returns this
 */
proto.fox.proto.ControlsState.prototype.setCabinPresenceSensorLatched = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int64 pedal_depressed_timestamp = 4;
 * @return {number}
 */
proto.fox.proto.ControlsState.prototype.getPedalDepressedTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ControlsState} returns this
 */
proto.fox.proto.ControlsState.prototype.setPedalDepressedTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool pedal_depressed_latched = 5;
 * @return {boolean}
 */
proto.fox.proto.ControlsState.prototype.getPedalDepressedLatched = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.ControlsState} returns this
 */
proto.fox.proto.ControlsState.prototype.setPedalDepressedLatched = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool require_near_space_fields_clear = 6;
 * @return {boolean}
 */
proto.fox.proto.ControlsState.prototype.getRequireNearSpaceFieldsClear = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.ControlsState} returns this
 */
proto.fox.proto.ControlsState.prototype.setRequireNearSpaceFieldsClear = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 near_space_fields_clear_timestamp = 7;
 * @return {number}
 */
proto.fox.proto.ControlsState.prototype.getNearSpaceFieldsClearTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ControlsState} returns this
 */
proto.fox.proto.ControlsState.prototype.setNearSpaceFieldsClearTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool near_space_fields_were_clear = 8;
 * @return {boolean}
 */
proto.fox.proto.ControlsState.prototype.getNearSpaceFieldsWereClear = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.ControlsState} returns this
 */
proto.fox.proto.ControlsState.prototype.setNearSpaceFieldsWereClear = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SafetyScannerState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SafetyScannerState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SafetyScannerState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetyScannerState.toObject = function(includeInstance, msg) {
  var f, obj = {
    mode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    motionFieldState: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nearspaceFieldState: jspb.Message.getFieldWithDefault(msg, 4, 0),
    forwardOnlyFieldState: jspb.Message.getFieldWithDefault(msg, 7, 0),
    monitoringCase: jspb.Message.getFieldWithDefault(msg, 3, 0),
    flags: (f = msg.getFlags()) && proto.fox.proto.SafetyScannerStatusFlags.toObject(includeInstance, f),
    commandedMonitoringCase: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SafetyScannerState}
 */
proto.fox.proto.SafetyScannerState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SafetyScannerState;
  return proto.fox.proto.SafetyScannerState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SafetyScannerState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SafetyScannerState}
 */
proto.fox.proto.SafetyScannerState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.SafetyScannerMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.SafetyFieldState} */ (reader.readEnum());
      msg.setMotionFieldState(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.SafetyFieldState} */ (reader.readEnum());
      msg.setNearspaceFieldState(value);
      break;
    case 7:
      var value = /** @type {!proto.fox.proto.SafetyFieldState} */ (reader.readEnum());
      msg.setForwardOnlyFieldState(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonitoringCase(value);
      break;
    case 5:
      var value = new proto.fox.proto.SafetyScannerStatusFlags;
      reader.readMessage(value,proto.fox.proto.SafetyScannerStatusFlags.deserializeBinaryFromReader);
      msg.setFlags(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCommandedMonitoringCase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SafetyScannerState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SafetyScannerState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SafetyScannerState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetyScannerState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMotionFieldState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNearspaceFieldState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getForwardOnlyFieldState();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getMonitoringCase();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFlags();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.fox.proto.SafetyScannerStatusFlags.serializeBinaryToWriter
    );
  }
  f = message.getCommandedMonitoringCase();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional SafetyScannerMode mode = 1;
 * @return {!proto.fox.proto.SafetyScannerMode}
 */
proto.fox.proto.SafetyScannerState.prototype.getMode = function() {
  return /** @type {!proto.fox.proto.SafetyScannerMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.SafetyScannerMode} value
 * @return {!proto.fox.proto.SafetyScannerState} returns this
 */
proto.fox.proto.SafetyScannerState.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SafetyFieldState motion_field_state = 2;
 * @return {!proto.fox.proto.SafetyFieldState}
 */
proto.fox.proto.SafetyScannerState.prototype.getMotionFieldState = function() {
  return /** @type {!proto.fox.proto.SafetyFieldState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.SafetyFieldState} value
 * @return {!proto.fox.proto.SafetyScannerState} returns this
 */
proto.fox.proto.SafetyScannerState.prototype.setMotionFieldState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional SafetyFieldState nearspace_field_state = 4;
 * @return {!proto.fox.proto.SafetyFieldState}
 */
proto.fox.proto.SafetyScannerState.prototype.getNearspaceFieldState = function() {
  return /** @type {!proto.fox.proto.SafetyFieldState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.SafetyFieldState} value
 * @return {!proto.fox.proto.SafetyScannerState} returns this
 */
proto.fox.proto.SafetyScannerState.prototype.setNearspaceFieldState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional SafetyFieldState forward_only_field_state = 7;
 * @return {!proto.fox.proto.SafetyFieldState}
 */
proto.fox.proto.SafetyScannerState.prototype.getForwardOnlyFieldState = function() {
  return /** @type {!proto.fox.proto.SafetyFieldState} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.fox.proto.SafetyFieldState} value
 * @return {!proto.fox.proto.SafetyScannerState} returns this
 */
proto.fox.proto.SafetyScannerState.prototype.setForwardOnlyFieldState = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 monitoring_case = 3;
 * @return {number}
 */
proto.fox.proto.SafetyScannerState.prototype.getMonitoringCase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.SafetyScannerState} returns this
 */
proto.fox.proto.SafetyScannerState.prototype.setMonitoringCase = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional SafetyScannerStatusFlags flags = 5;
 * @return {?proto.fox.proto.SafetyScannerStatusFlags}
 */
proto.fox.proto.SafetyScannerState.prototype.getFlags = function() {
  return /** @type{?proto.fox.proto.SafetyScannerStatusFlags} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.SafetyScannerStatusFlags, 5));
};


/**
 * @param {?proto.fox.proto.SafetyScannerStatusFlags|undefined} value
 * @return {!proto.fox.proto.SafetyScannerState} returns this
*/
proto.fox.proto.SafetyScannerState.prototype.setFlags = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.SafetyScannerState} returns this
 */
proto.fox.proto.SafetyScannerState.prototype.clearFlags = function() {
  return this.setFlags(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.SafetyScannerState.prototype.hasFlags = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 commanded_monitoring_case = 6;
 * @return {number}
 */
proto.fox.proto.SafetyScannerState.prototype.getCommandedMonitoringCase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.SafetyScannerState} returns this
 */
proto.fox.proto.SafetyScannerState.prototype.setCommandedMonitoringCase = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.SafetySystemState.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SafetySystemState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SafetySystemState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SafetySystemState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetySystemState.toObject = function(includeInstance, msg) {
  var f, obj = {
    scannersList: jspb.Message.toObjectList(msg.getScannersList(),
    proto.fox.proto.SafetyScannerState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SafetySystemState}
 */
proto.fox.proto.SafetySystemState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SafetySystemState;
  return proto.fox.proto.SafetySystemState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SafetySystemState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SafetySystemState}
 */
proto.fox.proto.SafetySystemState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.SafetyScannerState;
      reader.readMessage(value,proto.fox.proto.SafetyScannerState.deserializeBinaryFromReader);
      msg.addScanners(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SafetySystemState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SafetySystemState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SafetySystemState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetySystemState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScannersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.SafetyScannerState.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SafetyScannerState scanners = 1;
 * @return {!Array<!proto.fox.proto.SafetyScannerState>}
 */
proto.fox.proto.SafetySystemState.prototype.getScannersList = function() {
  return /** @type{!Array<!proto.fox.proto.SafetyScannerState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.SafetyScannerState, 1));
};


/**
 * @param {!Array<!proto.fox.proto.SafetyScannerState>} value
 * @return {!proto.fox.proto.SafetySystemState} returns this
*/
proto.fox.proto.SafetySystemState.prototype.setScannersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.SafetyScannerState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.SafetyScannerState}
 */
proto.fox.proto.SafetySystemState.prototype.addScanners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.SafetyScannerState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.SafetySystemState} returns this
 */
proto.fox.proto.SafetySystemState.prototype.clearScannersList = function() {
  return this.setScannersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SystemStatusFlags.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SystemStatusFlags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SystemStatusFlags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SystemStatusFlags.toObject = function(includeInstance, msg) {
  var f, obj = {
    operational: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    faultsClear: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    hardwareReady: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SystemStatusFlags}
 */
proto.fox.proto.SystemStatusFlags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SystemStatusFlags;
  return proto.fox.proto.SystemStatusFlags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SystemStatusFlags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SystemStatusFlags}
 */
proto.fox.proto.SystemStatusFlags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOperational(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFaultsClear(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHardwareReady(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SystemStatusFlags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SystemStatusFlags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SystemStatusFlags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SystemStatusFlags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperational();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFaultsClear();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHardwareReady();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool operational = 1;
 * @return {boolean}
 */
proto.fox.proto.SystemStatusFlags.prototype.getOperational = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.SystemStatusFlags} returns this
 */
proto.fox.proto.SystemStatusFlags.prototype.setOperational = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool faults_clear = 2;
 * @return {boolean}
 */
proto.fox.proto.SystemStatusFlags.prototype.getFaultsClear = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.SystemStatusFlags} returns this
 */
proto.fox.proto.SystemStatusFlags.prototype.setFaultsClear = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool hardware_ready = 3;
 * @return {boolean}
 */
proto.fox.proto.SystemStatusFlags.prototype.getHardwareReady = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.SystemStatusFlags} returns this
 */
proto.fox.proto.SystemStatusFlags.prototype.setHardwareReady = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.IOStatusFlags.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.IOStatusFlags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.IOStatusFlags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.IOStatusFlags.toObject = function(includeInstance, msg) {
  var f, obj = {
    reset: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    autoModeActive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    chassisEstopClear: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    externalEnable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    presencePedalDepressed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    tractionPedalDepressed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    cabinPresenceSensorActive: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.IOStatusFlags}
 */
proto.fox.proto.IOStatusFlags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.IOStatusFlags;
  return proto.fox.proto.IOStatusFlags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.IOStatusFlags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.IOStatusFlags}
 */
proto.fox.proto.IOStatusFlags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReset(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoModeActive(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChassisEstopClear(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExternalEnable(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPresencePedalDepressed(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTractionPedalDepressed(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCabinPresenceSensorActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.IOStatusFlags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.IOStatusFlags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.IOStatusFlags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.IOStatusFlags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReset();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAutoModeActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getChassisEstopClear();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getExternalEnable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPresencePedalDepressed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTractionPedalDepressed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCabinPresenceSensorActive();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional bool reset = 1;
 * @return {boolean}
 */
proto.fox.proto.IOStatusFlags.prototype.getReset = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.IOStatusFlags} returns this
 */
proto.fox.proto.IOStatusFlags.prototype.setReset = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool auto_mode_active = 2;
 * @return {boolean}
 */
proto.fox.proto.IOStatusFlags.prototype.getAutoModeActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.IOStatusFlags} returns this
 */
proto.fox.proto.IOStatusFlags.prototype.setAutoModeActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool chassis_estop_clear = 3;
 * @return {boolean}
 */
proto.fox.proto.IOStatusFlags.prototype.getChassisEstopClear = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.IOStatusFlags} returns this
 */
proto.fox.proto.IOStatusFlags.prototype.setChassisEstopClear = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool external_enable = 4;
 * @return {boolean}
 */
proto.fox.proto.IOStatusFlags.prototype.getExternalEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.IOStatusFlags} returns this
 */
proto.fox.proto.IOStatusFlags.prototype.setExternalEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool presence_pedal_depressed = 5;
 * @return {boolean}
 */
proto.fox.proto.IOStatusFlags.prototype.getPresencePedalDepressed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.IOStatusFlags} returns this
 */
proto.fox.proto.IOStatusFlags.prototype.setPresencePedalDepressed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool traction_pedal_depressed = 6;
 * @return {boolean}
 */
proto.fox.proto.IOStatusFlags.prototype.getTractionPedalDepressed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.IOStatusFlags} returns this
 */
proto.fox.proto.IOStatusFlags.prototype.setTractionPedalDepressed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool cabin_presence_sensor_active = 8;
 * @return {boolean}
 */
proto.fox.proto.IOStatusFlags.prototype.getCabinPresenceSensorActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.IOStatusFlags} returns this
 */
proto.fox.proto.IOStatusFlags.prototype.setCabinPresenceSensorActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ForkliftState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ForkliftState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ForkliftState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ForkliftState.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeNs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    steadyTimeNs: jspb.Message.getFieldWithDefault(msg, 5, 0),
    driveStatus: (f = msg.getDriveStatus()) && proto.fox.proto.TricycleDriveControl.toObject(includeInstance, f),
    plcStatus: (f = msg.getPlcStatus()) && proto.fox.proto.PlcStatusFlags.toObject(includeInstance, f),
    mastStatus: (f = msg.getMastStatus()) && proto.fox.proto.MastControl.toObject(includeInstance, f),
    mastVelocity: (f = msg.getMastVelocity()) && proto.fox.proto.MastVelocity.toObject(includeInstance, f),
    safetyStatus: (f = msg.getSafetyStatus()) && proto.fox.proto.SafetySystemState.toObject(includeInstance, f),
    batteryDischargeState: jspb.Message.getFieldWithDefault(msg, 9, 0),
    pressureSensorState: jspb.Message.getFieldWithDefault(msg, 10, 0),
    imuStatus: (f = msg.getImuStatus()) && proto.fox.proto.ImuState.toObject(includeInstance, f),
    operatingHours: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ForkliftState}
 */
proto.fox.proto.ForkliftState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ForkliftState;
  return proto.fox.proto.ForkliftState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ForkliftState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ForkliftState}
 */
proto.fox.proto.ForkliftState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeNs(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSteadyTimeNs(value);
      break;
    case 2:
      var value = new proto.fox.proto.TricycleDriveControl;
      reader.readMessage(value,proto.fox.proto.TricycleDriveControl.deserializeBinaryFromReader);
      msg.setDriveStatus(value);
      break;
    case 3:
      var value = new proto.fox.proto.PlcStatusFlags;
      reader.readMessage(value,proto.fox.proto.PlcStatusFlags.deserializeBinaryFromReader);
      msg.setPlcStatus(value);
      break;
    case 4:
      var value = new proto.fox.proto.MastControl;
      reader.readMessage(value,proto.fox.proto.MastControl.deserializeBinaryFromReader);
      msg.setMastStatus(value);
      break;
    case 8:
      var value = new proto.fox.proto.MastVelocity;
      reader.readMessage(value,proto.fox.proto.MastVelocity.deserializeBinaryFromReader);
      msg.setMastVelocity(value);
      break;
    case 7:
      var value = new proto.fox.proto.SafetySystemState;
      reader.readMessage(value,proto.fox.proto.SafetySystemState.deserializeBinaryFromReader);
      msg.setSafetyStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatteryDischargeState(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPressureSensorState(value);
      break;
    case 11:
      var value = new proto.fox.proto.ImuState;
      reader.readMessage(value,proto.fox.proto.ImuState.deserializeBinaryFromReader);
      msg.setImuStatus(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOperatingHours(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ForkliftState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ForkliftState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ForkliftState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ForkliftState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeNs();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSteadyTimeNs();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDriveStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.TricycleDriveControl.serializeBinaryToWriter
    );
  }
  f = message.getPlcStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.PlcStatusFlags.serializeBinaryToWriter
    );
  }
  f = message.getMastStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.MastControl.serializeBinaryToWriter
    );
  }
  f = message.getMastVelocity();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.fox.proto.MastVelocity.serializeBinaryToWriter
    );
  }
  f = message.getSafetyStatus();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.fox.proto.SafetySystemState.serializeBinaryToWriter
    );
  }
  f = message.getBatteryDischargeState();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPressureSensorState();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getImuStatus();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.fox.proto.ImuState.serializeBinaryToWriter
    );
  }
  f = message.getOperatingHours();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * optional int64 time_ns = 1;
 * @return {number}
 */
proto.fox.proto.ForkliftState.prototype.getTimeNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ForkliftState} returns this
 */
proto.fox.proto.ForkliftState.prototype.setTimeNs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 steady_time_ns = 5;
 * @return {number}
 */
proto.fox.proto.ForkliftState.prototype.getSteadyTimeNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ForkliftState} returns this
 */
proto.fox.proto.ForkliftState.prototype.setSteadyTimeNs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional TricycleDriveControl drive_status = 2;
 * @return {?proto.fox.proto.TricycleDriveControl}
 */
proto.fox.proto.ForkliftState.prototype.getDriveStatus = function() {
  return /** @type{?proto.fox.proto.TricycleDriveControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.TricycleDriveControl, 2));
};


/**
 * @param {?proto.fox.proto.TricycleDriveControl|undefined} value
 * @return {!proto.fox.proto.ForkliftState} returns this
*/
proto.fox.proto.ForkliftState.prototype.setDriveStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftState} returns this
 */
proto.fox.proto.ForkliftState.prototype.clearDriveStatus = function() {
  return this.setDriveStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftState.prototype.hasDriveStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PlcStatusFlags plc_status = 3;
 * @return {?proto.fox.proto.PlcStatusFlags}
 */
proto.fox.proto.ForkliftState.prototype.getPlcStatus = function() {
  return /** @type{?proto.fox.proto.PlcStatusFlags} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlcStatusFlags, 3));
};


/**
 * @param {?proto.fox.proto.PlcStatusFlags|undefined} value
 * @return {!proto.fox.proto.ForkliftState} returns this
*/
proto.fox.proto.ForkliftState.prototype.setPlcStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftState} returns this
 */
proto.fox.proto.ForkliftState.prototype.clearPlcStatus = function() {
  return this.setPlcStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftState.prototype.hasPlcStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MastControl mast_status = 4;
 * @return {?proto.fox.proto.MastControl}
 */
proto.fox.proto.ForkliftState.prototype.getMastStatus = function() {
  return /** @type{?proto.fox.proto.MastControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MastControl, 4));
};


/**
 * @param {?proto.fox.proto.MastControl|undefined} value
 * @return {!proto.fox.proto.ForkliftState} returns this
*/
proto.fox.proto.ForkliftState.prototype.setMastStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftState} returns this
 */
proto.fox.proto.ForkliftState.prototype.clearMastStatus = function() {
  return this.setMastStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftState.prototype.hasMastStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MastVelocity mast_velocity = 8;
 * @return {?proto.fox.proto.MastVelocity}
 */
proto.fox.proto.ForkliftState.prototype.getMastVelocity = function() {
  return /** @type{?proto.fox.proto.MastVelocity} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MastVelocity, 8));
};


/**
 * @param {?proto.fox.proto.MastVelocity|undefined} value
 * @return {!proto.fox.proto.ForkliftState} returns this
*/
proto.fox.proto.ForkliftState.prototype.setMastVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftState} returns this
 */
proto.fox.proto.ForkliftState.prototype.clearMastVelocity = function() {
  return this.setMastVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftState.prototype.hasMastVelocity = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SafetySystemState safety_status = 7;
 * @return {?proto.fox.proto.SafetySystemState}
 */
proto.fox.proto.ForkliftState.prototype.getSafetyStatus = function() {
  return /** @type{?proto.fox.proto.SafetySystemState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.SafetySystemState, 7));
};


/**
 * @param {?proto.fox.proto.SafetySystemState|undefined} value
 * @return {!proto.fox.proto.ForkliftState} returns this
*/
proto.fox.proto.ForkliftState.prototype.setSafetyStatus = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftState} returns this
 */
proto.fox.proto.ForkliftState.prototype.clearSafetyStatus = function() {
  return this.setSafetyStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftState.prototype.hasSafetyStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 battery_discharge_state = 9;
 * @return {number}
 */
proto.fox.proto.ForkliftState.prototype.getBatteryDischargeState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ForkliftState} returns this
 */
proto.fox.proto.ForkliftState.prototype.setBatteryDischargeState = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 pressure_sensor_state = 10;
 * @return {number}
 */
proto.fox.proto.ForkliftState.prototype.getPressureSensorState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ForkliftState} returns this
 */
proto.fox.proto.ForkliftState.prototype.setPressureSensorState = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional ImuState imu_status = 11;
 * @return {?proto.fox.proto.ImuState}
 */
proto.fox.proto.ForkliftState.prototype.getImuStatus = function() {
  return /** @type{?proto.fox.proto.ImuState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ImuState, 11));
};


/**
 * @param {?proto.fox.proto.ImuState|undefined} value
 * @return {!proto.fox.proto.ForkliftState} returns this
*/
proto.fox.proto.ForkliftState.prototype.setImuStatus = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftState} returns this
 */
proto.fox.proto.ForkliftState.prototype.clearImuStatus = function() {
  return this.setImuStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftState.prototype.hasImuStatus = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 operating_hours = 12;
 * @return {number}
 */
proto.fox.proto.ForkliftState.prototype.getOperatingHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ForkliftState} returns this
 */
proto.fox.proto.ForkliftState.prototype.setOperatingHours = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.HardwareDiagnosticMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.HardwareDiagnosticMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HardwareDiagnosticMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subcode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timeNs: jspb.Message.getFieldWithDefault(msg, 4, 0),
    rawTimeNs: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.HardwareDiagnosticMessage}
 */
proto.fox.proto.HardwareDiagnosticMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.HardwareDiagnosticMessage;
  return proto.fox.proto.HardwareDiagnosticMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.HardwareDiagnosticMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.HardwareDiagnosticMessage}
 */
proto.fox.proto.HardwareDiagnosticMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubcode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeNs(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRawTimeNs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.HardwareDiagnosticMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.HardwareDiagnosticMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HardwareDiagnosticMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSubcode();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTimeNs();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getRawTimeNs();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int32 type = 1;
 * @return {number}
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HardwareDiagnosticMessage} returns this
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 code = 2;
 * @return {number}
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HardwareDiagnosticMessage} returns this
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 subcode = 3;
 * @return {number}
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.getSubcode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HardwareDiagnosticMessage} returns this
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.setSubcode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 time_ns = 4;
 * @return {number}
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.getTimeNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HardwareDiagnosticMessage} returns this
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.setTimeNs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 raw_time_ns = 5;
 * @return {number}
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.getRawTimeNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.HardwareDiagnosticMessage} returns this
 */
proto.fox.proto.HardwareDiagnosticMessage.prototype.setRawTimeNs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ForkliftOdometry.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ForkliftOdometry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ForkliftOdometry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ForkliftOdometry.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    odomTRobot: (f = msg.getOdomTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    driveStatus: (f = msg.getDriveStatus()) && proto.fox.proto.TricycleDriveControl.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ForkliftOdometry}
 */
proto.fox.proto.ForkliftOdometry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ForkliftOdometry;
  return proto.fox.proto.ForkliftOdometry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ForkliftOdometry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ForkliftOdometry}
 */
proto.fox.proto.ForkliftOdometry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setOdomTRobot(value);
      break;
    case 3:
      var value = new proto.fox.proto.TricycleDriveControl;
      reader.readMessage(value,proto.fox.proto.TricycleDriveControl.deserializeBinaryFromReader);
      msg.setDriveStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ForkliftOdometry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ForkliftOdometry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ForkliftOdometry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ForkliftOdometry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOdomTRobot();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getDriveStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.TricycleDriveControl.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.fox.proto.ForkliftOdometry.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ForkliftOdometry} returns this
 */
proto.fox.proto.ForkliftOdometry.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Transform2f odom_t_robot = 2;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.ForkliftOdometry.prototype.getOdomTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 2));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.ForkliftOdometry} returns this
*/
proto.fox.proto.ForkliftOdometry.prototype.setOdomTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftOdometry} returns this
 */
proto.fox.proto.ForkliftOdometry.prototype.clearOdomTRobot = function() {
  return this.setOdomTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftOdometry.prototype.hasOdomTRobot = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TricycleDriveControl drive_status = 3;
 * @return {?proto.fox.proto.TricycleDriveControl}
 */
proto.fox.proto.ForkliftOdometry.prototype.getDriveStatus = function() {
  return /** @type{?proto.fox.proto.TricycleDriveControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.TricycleDriveControl, 3));
};


/**
 * @param {?proto.fox.proto.TricycleDriveControl|undefined} value
 * @return {!proto.fox.proto.ForkliftOdometry} returns this
*/
proto.fox.proto.ForkliftOdometry.prototype.setDriveStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForkliftOdometry} returns this
 */
proto.fox.proto.ForkliftOdometry.prototype.clearDriveStatus = function() {
  return this.setDriveStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForkliftOdometry.prototype.hasDriveStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SafetyCase.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SafetyCase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SafetyCase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetyCase.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    safetyMode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    motionType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SafetyCase}
 */
proto.fox.proto.SafetyCase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SafetyCase;
  return proto.fox.proto.SafetyCase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SafetyCase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SafetyCase}
 */
proto.fox.proto.SafetyCase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.SafetyMode} */ (reader.readEnum());
      msg.setSafetyMode(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.MotionType} */ (reader.readEnum());
      msg.setMotionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SafetyCase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SafetyCase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SafetyCase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetyCase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSafetyMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMotionType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.fox.proto.SafetyCase.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.SafetyCase} returns this
 */
proto.fox.proto.SafetyCase.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SafetyMode safety_mode = 2;
 * @return {!proto.fox.proto.SafetyMode}
 */
proto.fox.proto.SafetyCase.prototype.getSafetyMode = function() {
  return /** @type {!proto.fox.proto.SafetyMode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.SafetyMode} value
 * @return {!proto.fox.proto.SafetyCase} returns this
 */
proto.fox.proto.SafetyCase.prototype.setSafetyMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional MotionType motion_type = 3;
 * @return {!proto.fox.proto.MotionType}
 */
proto.fox.proto.SafetyCase.prototype.getMotionType = function() {
  return /** @type {!proto.fox.proto.MotionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.MotionType} value
 * @return {!proto.fox.proto.SafetyCase} returns this
 */
proto.fox.proto.SafetyCase.prototype.setMotionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.TricycleSegment.repeatedFields_ = [2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.TricycleSegment.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.TricycleSegment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.TricycleSegment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TricycleSegment.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: (f = msg.getPath()) && geometry_proto_path_pb.Path2f.toObject(includeInstance, f),
    controlList: jspb.Message.toObjectList(msg.getControlList(),
    proto.fox.proto.TricycleDriveControl.toObject, includeInstance),
    mastPathList: jspb.Message.toObjectList(msg.getMastPathList(),
    proto.fox.proto.MastControl.toObject, includeInstance),
    safetyCasesList: jspb.Message.toObjectList(msg.getSafetyCasesList(),
    proto.fox.proto.SafetyCase.toObject, includeInstance),
    servoRegionsList: jspb.Message.toObjectList(msg.getServoRegionsList(),
    proto.fox.proto.VisualServoRegion.toObject, includeInstance),
    fineStop: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    slowStop: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.TricycleSegment}
 */
proto.fox.proto.TricycleSegment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.TricycleSegment;
  return proto.fox.proto.TricycleSegment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.TricycleSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.TricycleSegment}
 */
proto.fox.proto.TricycleSegment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_path_pb.Path2f;
      reader.readMessage(value,geometry_proto_path_pb.Path2f.deserializeBinaryFromReader);
      msg.setPath(value);
      break;
    case 2:
      var value = new proto.fox.proto.TricycleDriveControl;
      reader.readMessage(value,proto.fox.proto.TricycleDriveControl.deserializeBinaryFromReader);
      msg.addControl(value);
      break;
    case 3:
      var value = new proto.fox.proto.MastControl;
      reader.readMessage(value,proto.fox.proto.MastControl.deserializeBinaryFromReader);
      msg.addMastPath(value);
      break;
    case 4:
      var value = new proto.fox.proto.SafetyCase;
      reader.readMessage(value,proto.fox.proto.SafetyCase.deserializeBinaryFromReader);
      msg.addSafetyCases(value);
      break;
    case 5:
      var value = new proto.fox.proto.VisualServoRegion;
      reader.readMessage(value,proto.fox.proto.VisualServoRegion.deserializeBinaryFromReader);
      msg.addServoRegions(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFineStop(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSlowStop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.TricycleSegment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.TricycleSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.TricycleSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TricycleSegment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_path_pb.Path2f.serializeBinaryToWriter
    );
  }
  f = message.getControlList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.TricycleDriveControl.serializeBinaryToWriter
    );
  }
  f = message.getMastPathList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.MastControl.serializeBinaryToWriter
    );
  }
  f = message.getSafetyCasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.fox.proto.SafetyCase.serializeBinaryToWriter
    );
  }
  f = message.getServoRegionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.fox.proto.VisualServoRegion.serializeBinaryToWriter
    );
  }
  f = message.getFineStop();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSlowStop();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional Path2f path = 1;
 * @return {?proto.fox.proto.Path2f}
 */
proto.fox.proto.TricycleSegment.prototype.getPath = function() {
  return /** @type{?proto.fox.proto.Path2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_path_pb.Path2f, 1));
};


/**
 * @param {?proto.fox.proto.Path2f|undefined} value
 * @return {!proto.fox.proto.TricycleSegment} returns this
*/
proto.fox.proto.TricycleSegment.prototype.setPath = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TricycleSegment} returns this
 */
proto.fox.proto.TricycleSegment.prototype.clearPath = function() {
  return this.setPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TricycleSegment.prototype.hasPath = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TricycleDriveControl control = 2;
 * @return {!Array<!proto.fox.proto.TricycleDriveControl>}
 */
proto.fox.proto.TricycleSegment.prototype.getControlList = function() {
  return /** @type{!Array<!proto.fox.proto.TricycleDriveControl>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.TricycleDriveControl, 2));
};


/**
 * @param {!Array<!proto.fox.proto.TricycleDriveControl>} value
 * @return {!proto.fox.proto.TricycleSegment} returns this
*/
proto.fox.proto.TricycleSegment.prototype.setControlList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.TricycleDriveControl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TricycleDriveControl}
 */
proto.fox.proto.TricycleSegment.prototype.addControl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.TricycleDriveControl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.TricycleSegment} returns this
 */
proto.fox.proto.TricycleSegment.prototype.clearControlList = function() {
  return this.setControlList([]);
};


/**
 * repeated MastControl mast_path = 3;
 * @return {!Array<!proto.fox.proto.MastControl>}
 */
proto.fox.proto.TricycleSegment.prototype.getMastPathList = function() {
  return /** @type{!Array<!proto.fox.proto.MastControl>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.MastControl, 3));
};


/**
 * @param {!Array<!proto.fox.proto.MastControl>} value
 * @return {!proto.fox.proto.TricycleSegment} returns this
*/
proto.fox.proto.TricycleSegment.prototype.setMastPathList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.MastControl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.MastControl}
 */
proto.fox.proto.TricycleSegment.prototype.addMastPath = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.MastControl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.TricycleSegment} returns this
 */
proto.fox.proto.TricycleSegment.prototype.clearMastPathList = function() {
  return this.setMastPathList([]);
};


/**
 * repeated SafetyCase safety_cases = 4;
 * @return {!Array<!proto.fox.proto.SafetyCase>}
 */
proto.fox.proto.TricycleSegment.prototype.getSafetyCasesList = function() {
  return /** @type{!Array<!proto.fox.proto.SafetyCase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.SafetyCase, 4));
};


/**
 * @param {!Array<!proto.fox.proto.SafetyCase>} value
 * @return {!proto.fox.proto.TricycleSegment} returns this
*/
proto.fox.proto.TricycleSegment.prototype.setSafetyCasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.fox.proto.SafetyCase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.SafetyCase}
 */
proto.fox.proto.TricycleSegment.prototype.addSafetyCases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.fox.proto.SafetyCase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.TricycleSegment} returns this
 */
proto.fox.proto.TricycleSegment.prototype.clearSafetyCasesList = function() {
  return this.setSafetyCasesList([]);
};


/**
 * repeated VisualServoRegion servo_regions = 5;
 * @return {!Array<!proto.fox.proto.VisualServoRegion>}
 */
proto.fox.proto.TricycleSegment.prototype.getServoRegionsList = function() {
  return /** @type{!Array<!proto.fox.proto.VisualServoRegion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.VisualServoRegion, 5));
};


/**
 * @param {!Array<!proto.fox.proto.VisualServoRegion>} value
 * @return {!proto.fox.proto.TricycleSegment} returns this
*/
proto.fox.proto.TricycleSegment.prototype.setServoRegionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.fox.proto.VisualServoRegion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.VisualServoRegion}
 */
proto.fox.proto.TricycleSegment.prototype.addServoRegions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.fox.proto.VisualServoRegion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.TricycleSegment} returns this
 */
proto.fox.proto.TricycleSegment.prototype.clearServoRegionsList = function() {
  return this.setServoRegionsList([]);
};


/**
 * optional bool fine_stop = 6;
 * @return {boolean}
 */
proto.fox.proto.TricycleSegment.prototype.getFineStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.TricycleSegment} returns this
 */
proto.fox.proto.TricycleSegment.prototype.setFineStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool slow_stop = 7;
 * @return {boolean}
 */
proto.fox.proto.TricycleSegment.prototype.getSlowStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.TricycleSegment} returns this
 */
proto.fox.proto.TricycleSegment.prototype.setSlowStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.TricycleTrajectory.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.TricycleTrajectory.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.TricycleTrajectory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.TricycleTrajectory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TricycleTrajectory.toObject = function(includeInstance, msg) {
  var f, obj = {
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.fox.proto.TricycleSegment.toObject, includeInstance),
    goalId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    valid: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    id: jspb.Message.getFieldWithDefault(msg, 5, 0),
    objectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    previousTrajectoryId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    previousTrajectoryStart: (f = msg.getPreviousTrajectoryStart()) && proto.fox.proto.TrajectoryIndex.toObject(includeInstance, f),
    previousTrajectoryEnd: (f = msg.getPreviousTrajectoryEnd()) && proto.fox.proto.TrajectoryIndex.toObject(includeInstance, f),
    timestamp: jspb.Message.getFieldWithDefault(msg, 9, 0),
    stepsSinceLastCollision: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.TricycleTrajectory}
 */
proto.fox.proto.TricycleTrajectory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.TricycleTrajectory;
  return proto.fox.proto.TricycleTrajectory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.TricycleTrajectory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.TricycleTrajectory}
 */
proto.fox.proto.TricycleTrajectory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.TricycleSegment;
      reader.readMessage(value,proto.fox.proto.TricycleSegment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoalId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setObjectId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPreviousTrajectoryId(value);
      break;
    case 7:
      var value = new proto.fox.proto.TrajectoryIndex;
      reader.readMessage(value,proto.fox.proto.TrajectoryIndex.deserializeBinaryFromReader);
      msg.setPreviousTrajectoryStart(value);
      break;
    case 8:
      var value = new proto.fox.proto.TrajectoryIndex;
      reader.readMessage(value,proto.fox.proto.TrajectoryIndex.deserializeBinaryFromReader);
      msg.setPreviousTrajectoryEnd(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStepsSinceLastCollision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.TricycleTrajectory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.TricycleTrajectory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.TricycleTrajectory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TricycleTrajectory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.TricycleSegment.serializeBinaryToWriter
    );
  }
  f = message.getGoalId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValid();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getObjectId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPreviousTrajectoryId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPreviousTrajectoryStart();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.fox.proto.TrajectoryIndex.serializeBinaryToWriter
    );
  }
  f = message.getPreviousTrajectoryEnd();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.fox.proto.TrajectoryIndex.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getStepsSinceLastCollision();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * repeated TricycleSegment segments = 1;
 * @return {!Array<!proto.fox.proto.TricycleSegment>}
 */
proto.fox.proto.TricycleTrajectory.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.fox.proto.TricycleSegment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.TricycleSegment, 1));
};


/**
 * @param {!Array<!proto.fox.proto.TricycleSegment>} value
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
*/
proto.fox.proto.TricycleTrajectory.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.TricycleSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TricycleSegment}
 */
proto.fox.proto.TricycleTrajectory.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.TricycleSegment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
 */
proto.fox.proto.TricycleTrajectory.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};


/**
 * optional string goal_id = 3;
 * @return {string}
 */
proto.fox.proto.TricycleTrajectory.prototype.getGoalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
 */
proto.fox.proto.TricycleTrajectory.prototype.setGoalId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool valid = 4;
 * @return {boolean}
 */
proto.fox.proto.TricycleTrajectory.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
 */
proto.fox.proto.TricycleTrajectory.prototype.setValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 id = 5;
 * @return {number}
 */
proto.fox.proto.TricycleTrajectory.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
 */
proto.fox.proto.TricycleTrajectory.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 object_id = 2;
 * @return {number}
 */
proto.fox.proto.TricycleTrajectory.prototype.getObjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
 */
proto.fox.proto.TricycleTrajectory.prototype.setObjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 previous_trajectory_id = 6;
 * @return {number}
 */
proto.fox.proto.TricycleTrajectory.prototype.getPreviousTrajectoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
 */
proto.fox.proto.TricycleTrajectory.prototype.setPreviousTrajectoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional TrajectoryIndex previous_trajectory_start = 7;
 * @return {?proto.fox.proto.TrajectoryIndex}
 */
proto.fox.proto.TricycleTrajectory.prototype.getPreviousTrajectoryStart = function() {
  return /** @type{?proto.fox.proto.TrajectoryIndex} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.TrajectoryIndex, 7));
};


/**
 * @param {?proto.fox.proto.TrajectoryIndex|undefined} value
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
*/
proto.fox.proto.TricycleTrajectory.prototype.setPreviousTrajectoryStart = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
 */
proto.fox.proto.TricycleTrajectory.prototype.clearPreviousTrajectoryStart = function() {
  return this.setPreviousTrajectoryStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TricycleTrajectory.prototype.hasPreviousTrajectoryStart = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TrajectoryIndex previous_trajectory_end = 8;
 * @return {?proto.fox.proto.TrajectoryIndex}
 */
proto.fox.proto.TricycleTrajectory.prototype.getPreviousTrajectoryEnd = function() {
  return /** @type{?proto.fox.proto.TrajectoryIndex} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.TrajectoryIndex, 8));
};


/**
 * @param {?proto.fox.proto.TrajectoryIndex|undefined} value
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
*/
proto.fox.proto.TricycleTrajectory.prototype.setPreviousTrajectoryEnd = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
 */
proto.fox.proto.TricycleTrajectory.prototype.clearPreviousTrajectoryEnd = function() {
  return this.setPreviousTrajectoryEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TricycleTrajectory.prototype.hasPreviousTrajectoryEnd = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 timestamp = 9;
 * @return {number}
 */
proto.fox.proto.TricycleTrajectory.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
 */
proto.fox.proto.TricycleTrajectory.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 steps_since_last_collision = 10;
 * @return {number}
 */
proto.fox.proto.TricycleTrajectory.prototype.getStepsSinceLastCollision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TricycleTrajectory} returns this
 */
proto.fox.proto.TricycleTrajectory.prototype.setStepsSinceLastCollision = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.DriveStallDetectionState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.DriveStallDetectionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.DriveStallDetectionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DriveStallDetectionState.toObject = function(includeInstance, msg) {
  var f, obj = {
    tractionStallDetectionTimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.DriveStallDetectionState}
 */
proto.fox.proto.DriveStallDetectionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.DriveStallDetectionState;
  return proto.fox.proto.DriveStallDetectionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.DriveStallDetectionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.DriveStallDetectionState}
 */
proto.fox.proto.DriveStallDetectionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTractionStallDetectionTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.DriveStallDetectionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.DriveStallDetectionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.DriveStallDetectionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DriveStallDetectionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTractionStallDetectionTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 traction_stall_detection_timestamp = 1;
 * @return {number}
 */
proto.fox.proto.DriveStallDetectionState.prototype.getTractionStallDetectionTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.DriveStallDetectionState} returns this
 */
proto.fox.proto.DriveStallDetectionState.prototype.setTractionStallDetectionTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.DriveStallDetectionFlags.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.DriveStallDetectionFlags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.DriveStallDetectionFlags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DriveStallDetectionFlags.toObject = function(includeInstance, msg) {
  var f, obj = {
    driveStalled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.DriveStallDetectionFlags}
 */
proto.fox.proto.DriveStallDetectionFlags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.DriveStallDetectionFlags;
  return proto.fox.proto.DriveStallDetectionFlags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.DriveStallDetectionFlags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.DriveStallDetectionFlags}
 */
proto.fox.proto.DriveStallDetectionFlags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDriveStalled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.DriveStallDetectionFlags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.DriveStallDetectionFlags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.DriveStallDetectionFlags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DriveStallDetectionFlags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriveStalled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool drive_stalled = 1;
 * @return {boolean}
 */
proto.fox.proto.DriveStallDetectionFlags.prototype.getDriveStalled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.DriveStallDetectionFlags} returns this
 */
proto.fox.proto.DriveStallDetectionFlags.prototype.setDriveStalled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.StuckDetectionState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.StuckDetectionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.StuckDetectionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.StuckDetectionState.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastCheckpointOdometry: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lastCheckpointTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastSystemActiveTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.StuckDetectionState}
 */
proto.fox.proto.StuckDetectionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.StuckDetectionState;
  return proto.fox.proto.StuckDetectionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.StuckDetectionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.StuckDetectionState}
 */
proto.fox.proto.StuckDetectionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastCheckpointOdometry(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastCheckpointTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSystemActiveTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.StuckDetectionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.StuckDetectionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.StuckDetectionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.StuckDetectionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastCheckpointOdometry();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLastCheckpointTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLastSystemActiveTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional double last_checkpoint_odometry = 1;
 * @return {number}
 */
proto.fox.proto.StuckDetectionState.prototype.getLastCheckpointOdometry = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.StuckDetectionState} returns this
 */
proto.fox.proto.StuckDetectionState.prototype.setLastCheckpointOdometry = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int64 last_checkpoint_time = 2;
 * @return {number}
 */
proto.fox.proto.StuckDetectionState.prototype.getLastCheckpointTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.StuckDetectionState} returns this
 */
proto.fox.proto.StuckDetectionState.prototype.setLastCheckpointTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 last_system_active_time = 3;
 * @return {number}
 */
proto.fox.proto.StuckDetectionState.prototype.getLastSystemActiveTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.StuckDetectionState} returns this
 */
proto.fox.proto.StuckDetectionState.prototype.setLastSystemActiveTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PathFollowerStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PathFollowerStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PathFollowerStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PathFollowerStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    control: (f = msg.getControl()) && proto.fox.proto.TricycleDriveControl.toObject(includeInstance, f),
    odomTRobot: (f = msg.getOdomTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currentSegment: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currentTrajectory: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mastStatus: (f = msg.getMastStatus()) && proto.fox.proto.MastFollowerStatus.toObject(includeInstance, f),
    timestamp: jspb.Message.getFieldWithDefault(msg, 8, 0),
    currentPathIndex: (f = msg.getCurrentPathIndex()) && geometry_proto_path_pb.PathIndex.toObject(includeInstance, f),
    reorientTimeNs: jspb.Message.getFieldWithDefault(msg, 10, 0),
    stateTransitionTimestamp: jspb.Message.getFieldWithDefault(msg, 11, 0),
    stuckState: (f = msg.getStuckState()) && proto.fox.proto.StuckDetectionState.toObject(includeInstance, f),
    stallState: (f = msg.getStallState()) && proto.fox.proto.DriveStallDetectionState.toObject(includeInstance, f),
    stallFlags: (f = msg.getStallFlags()) && proto.fox.proto.DriveStallDetectionFlags.toObject(includeInstance, f),
    lastNonzeroControl: (f = msg.getLastNonzeroControl()) && proto.fox.proto.TricycleDriveControl.toObject(includeInstance, f),
    lastNonzeroControlTimestamp: jspb.Message.getFieldWithDefault(msg, 16, 0),
    lastMotionType: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PathFollowerStatus}
 */
proto.fox.proto.PathFollowerStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PathFollowerStatus;
  return proto.fox.proto.PathFollowerStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PathFollowerStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PathFollowerStatus}
 */
proto.fox.proto.PathFollowerStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.TricycleDriveControl;
      reader.readMessage(value,proto.fox.proto.TricycleDriveControl.deserializeBinaryFromReader);
      msg.setControl(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setOdomTRobot(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentSegment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentTrajectory(value);
      break;
    case 7:
      var value = new proto.fox.proto.MastFollowerStatus;
      reader.readMessage(value,proto.fox.proto.MastFollowerStatus.deserializeBinaryFromReader);
      msg.setMastStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 9:
      var value = new geometry_proto_path_pb.PathIndex;
      reader.readMessage(value,geometry_proto_path_pb.PathIndex.deserializeBinaryFromReader);
      msg.setCurrentPathIndex(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReorientTimeNs(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStateTransitionTimestamp(value);
      break;
    case 17:
      var value = new proto.fox.proto.StuckDetectionState;
      reader.readMessage(value,proto.fox.proto.StuckDetectionState.deserializeBinaryFromReader);
      msg.setStuckState(value);
      break;
    case 12:
      var value = new proto.fox.proto.DriveStallDetectionState;
      reader.readMessage(value,proto.fox.proto.DriveStallDetectionState.deserializeBinaryFromReader);
      msg.setStallState(value);
      break;
    case 13:
      var value = new proto.fox.proto.DriveStallDetectionFlags;
      reader.readMessage(value,proto.fox.proto.DriveStallDetectionFlags.deserializeBinaryFromReader);
      msg.setStallFlags(value);
      break;
    case 14:
      var value = new proto.fox.proto.TricycleDriveControl;
      reader.readMessage(value,proto.fox.proto.TricycleDriveControl.deserializeBinaryFromReader);
      msg.setLastNonzeroControl(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastNonzeroControlTimestamp(value);
      break;
    case 15:
      var value = /** @type {!proto.fox.proto.MotionType} */ (reader.readEnum());
      msg.setLastMotionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PathFollowerStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PathFollowerStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PathFollowerStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PathFollowerStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControl();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.TricycleDriveControl.serializeBinaryToWriter
    );
  }
  f = message.getOdomTRobot();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCurrentSegment();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCurrentTrajectory();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMastStatus();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.fox.proto.MastFollowerStatus.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCurrentPathIndex();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      geometry_proto_path_pb.PathIndex.serializeBinaryToWriter
    );
  }
  f = message.getReorientTimeNs();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getStateTransitionTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getStuckState();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.fox.proto.StuckDetectionState.serializeBinaryToWriter
    );
  }
  f = message.getStallState();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.fox.proto.DriveStallDetectionState.serializeBinaryToWriter
    );
  }
  f = message.getStallFlags();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.fox.proto.DriveStallDetectionFlags.serializeBinaryToWriter
    );
  }
  f = message.getLastNonzeroControl();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.fox.proto.TricycleDriveControl.serializeBinaryToWriter
    );
  }
  f = message.getLastNonzeroControlTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getLastMotionType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.PathFollowerStatus.State = {
  UNKNOWN: 0,
  ACTIVE: 1,
  STOPPING: 2,
  COMPLETE: 3,
  INIT: 4,
  BLOCKED: 5,
  OFF_PATH: 6,
  STALE_PATH: 7,
  ERROR: 8,
  STALLED: 9,
  FAULT_REQUESTED: 11,
  FAULTED: 12,
  MANUAL: 13
};

/**
 * optional TricycleDriveControl control = 1;
 * @return {?proto.fox.proto.TricycleDriveControl}
 */
proto.fox.proto.PathFollowerStatus.prototype.getControl = function() {
  return /** @type{?proto.fox.proto.TricycleDriveControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.TricycleDriveControl, 1));
};


/**
 * @param {?proto.fox.proto.TricycleDriveControl|undefined} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
*/
proto.fox.proto.PathFollowerStatus.prototype.setControl = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.clearControl = function() {
  return this.setControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PathFollowerStatus.prototype.hasControl = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Transform2f odom_t_robot = 2;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.PathFollowerStatus.prototype.getOdomTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 2));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
*/
proto.fox.proto.PathFollowerStatus.prototype.setOdomTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.clearOdomTRobot = function() {
  return this.setOdomTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PathFollowerStatus.prototype.hasOdomTRobot = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 state = 4;
 * @return {number}
 */
proto.fox.proto.PathFollowerStatus.prototype.getState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 current_segment = 5;
 * @return {number}
 */
proto.fox.proto.PathFollowerStatus.prototype.getCurrentSegment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.setCurrentSegment = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 current_trajectory = 6;
 * @return {number}
 */
proto.fox.proto.PathFollowerStatus.prototype.getCurrentTrajectory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.setCurrentTrajectory = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional MastFollowerStatus mast_status = 7;
 * @return {?proto.fox.proto.MastFollowerStatus}
 */
proto.fox.proto.PathFollowerStatus.prototype.getMastStatus = function() {
  return /** @type{?proto.fox.proto.MastFollowerStatus} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MastFollowerStatus, 7));
};


/**
 * @param {?proto.fox.proto.MastFollowerStatus|undefined} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
*/
proto.fox.proto.PathFollowerStatus.prototype.setMastStatus = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.clearMastStatus = function() {
  return this.setMastStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PathFollowerStatus.prototype.hasMastStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 timestamp = 8;
 * @return {number}
 */
proto.fox.proto.PathFollowerStatus.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional PathIndex current_path_index = 9;
 * @return {?proto.fox.proto.PathIndex}
 */
proto.fox.proto.PathFollowerStatus.prototype.getCurrentPathIndex = function() {
  return /** @type{?proto.fox.proto.PathIndex} */ (
    jspb.Message.getWrapperField(this, geometry_proto_path_pb.PathIndex, 9));
};


/**
 * @param {?proto.fox.proto.PathIndex|undefined} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
*/
proto.fox.proto.PathFollowerStatus.prototype.setCurrentPathIndex = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.clearCurrentPathIndex = function() {
  return this.setCurrentPathIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PathFollowerStatus.prototype.hasCurrentPathIndex = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 reorient_time_ns = 10;
 * @return {number}
 */
proto.fox.proto.PathFollowerStatus.prototype.getReorientTimeNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.setReorientTimeNs = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 state_transition_timestamp = 11;
 * @return {number}
 */
proto.fox.proto.PathFollowerStatus.prototype.getStateTransitionTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.setStateTransitionTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional StuckDetectionState stuck_state = 17;
 * @return {?proto.fox.proto.StuckDetectionState}
 */
proto.fox.proto.PathFollowerStatus.prototype.getStuckState = function() {
  return /** @type{?proto.fox.proto.StuckDetectionState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.StuckDetectionState, 17));
};


/**
 * @param {?proto.fox.proto.StuckDetectionState|undefined} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
*/
proto.fox.proto.PathFollowerStatus.prototype.setStuckState = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.clearStuckState = function() {
  return this.setStuckState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PathFollowerStatus.prototype.hasStuckState = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional DriveStallDetectionState stall_state = 12;
 * @return {?proto.fox.proto.DriveStallDetectionState}
 */
proto.fox.proto.PathFollowerStatus.prototype.getStallState = function() {
  return /** @type{?proto.fox.proto.DriveStallDetectionState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.DriveStallDetectionState, 12));
};


/**
 * @param {?proto.fox.proto.DriveStallDetectionState|undefined} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
*/
proto.fox.proto.PathFollowerStatus.prototype.setStallState = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.clearStallState = function() {
  return this.setStallState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PathFollowerStatus.prototype.hasStallState = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional DriveStallDetectionFlags stall_flags = 13;
 * @return {?proto.fox.proto.DriveStallDetectionFlags}
 */
proto.fox.proto.PathFollowerStatus.prototype.getStallFlags = function() {
  return /** @type{?proto.fox.proto.DriveStallDetectionFlags} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.DriveStallDetectionFlags, 13));
};


/**
 * @param {?proto.fox.proto.DriveStallDetectionFlags|undefined} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
*/
proto.fox.proto.PathFollowerStatus.prototype.setStallFlags = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.clearStallFlags = function() {
  return this.setStallFlags(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PathFollowerStatus.prototype.hasStallFlags = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional TricycleDriveControl last_nonzero_control = 14;
 * @return {?proto.fox.proto.TricycleDriveControl}
 */
proto.fox.proto.PathFollowerStatus.prototype.getLastNonzeroControl = function() {
  return /** @type{?proto.fox.proto.TricycleDriveControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.TricycleDriveControl, 14));
};


/**
 * @param {?proto.fox.proto.TricycleDriveControl|undefined} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
*/
proto.fox.proto.PathFollowerStatus.prototype.setLastNonzeroControl = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.clearLastNonzeroControl = function() {
  return this.setLastNonzeroControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PathFollowerStatus.prototype.hasLastNonzeroControl = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int64 last_nonzero_control_timestamp = 16;
 * @return {number}
 */
proto.fox.proto.PathFollowerStatus.prototype.getLastNonzeroControlTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.setLastNonzeroControlTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional MotionType last_motion_type = 15;
 * @return {!proto.fox.proto.MotionType}
 */
proto.fox.proto.PathFollowerStatus.prototype.getLastMotionType = function() {
  return /** @type {!proto.fox.proto.MotionType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.fox.proto.MotionType} value
 * @return {!proto.fox.proto.PathFollowerStatus} returns this
 */
proto.fox.proto.PathFollowerStatus.prototype.setLastMotionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.MastStallDetectionState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.MastStallDetectionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.MastStallDetectionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastStallDetectionState.toObject = function(includeInstance, msg) {
  var f, obj = {
    stallDetectionTimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.MastStallDetectionState}
 */
proto.fox.proto.MastStallDetectionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.MastStallDetectionState;
  return proto.fox.proto.MastStallDetectionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.MastStallDetectionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.MastStallDetectionState}
 */
proto.fox.proto.MastStallDetectionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStallDetectionTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.MastStallDetectionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.MastStallDetectionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.MastStallDetectionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastStallDetectionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStallDetectionTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 stall_detection_timestamp = 1;
 * @return {number}
 */
proto.fox.proto.MastStallDetectionState.prototype.getStallDetectionTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastStallDetectionState} returns this
 */
proto.fox.proto.MastStallDetectionState.prototype.setStallDetectionTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.MastFollowerStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.MastFollowerStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.MastFollowerStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastFollowerStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    mastControl: (f = msg.getMastControl()) && proto.fox.proto.MastControl.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stallState: (f = msg.getStallState()) && proto.fox.proto.MastStallDetectionState.toObject(includeInstance, f),
    stallRecoveries: jspb.Message.getFieldWithDefault(msg, 4, 0),
    statusCompleteDelayTimestamp: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.MastFollowerStatus}
 */
proto.fox.proto.MastFollowerStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.MastFollowerStatus;
  return proto.fox.proto.MastFollowerStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.MastFollowerStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.MastFollowerStatus}
 */
proto.fox.proto.MastFollowerStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.MastControl;
      reader.readMessage(value,proto.fox.proto.MastControl.deserializeBinaryFromReader);
      msg.setMastControl(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setState(value);
      break;
    case 3:
      var value = new proto.fox.proto.MastStallDetectionState;
      reader.readMessage(value,proto.fox.proto.MastStallDetectionState.deserializeBinaryFromReader);
      msg.setStallState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStallRecoveries(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatusCompleteDelayTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.MastFollowerStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.MastFollowerStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.MastFollowerStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastFollowerStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMastControl();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.MastControl.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStallState();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.MastStallDetectionState.serializeBinaryToWriter
    );
  }
  f = message.getStallRecoveries();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getStatusCompleteDelayTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional MastControl mast_control = 1;
 * @return {?proto.fox.proto.MastControl}
 */
proto.fox.proto.MastFollowerStatus.prototype.getMastControl = function() {
  return /** @type{?proto.fox.proto.MastControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MastControl, 1));
};


/**
 * @param {?proto.fox.proto.MastControl|undefined} value
 * @return {!proto.fox.proto.MastFollowerStatus} returns this
*/
proto.fox.proto.MastFollowerStatus.prototype.setMastControl = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MastFollowerStatus} returns this
 */
proto.fox.proto.MastFollowerStatus.prototype.clearMastControl = function() {
  return this.setMastControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MastFollowerStatus.prototype.hasMastControl = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 state = 2;
 * @return {number}
 */
proto.fox.proto.MastFollowerStatus.prototype.getState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastFollowerStatus} returns this
 */
proto.fox.proto.MastFollowerStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional MastStallDetectionState stall_state = 3;
 * @return {?proto.fox.proto.MastStallDetectionState}
 */
proto.fox.proto.MastFollowerStatus.prototype.getStallState = function() {
  return /** @type{?proto.fox.proto.MastStallDetectionState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MastStallDetectionState, 3));
};


/**
 * @param {?proto.fox.proto.MastStallDetectionState|undefined} value
 * @return {!proto.fox.proto.MastFollowerStatus} returns this
*/
proto.fox.proto.MastFollowerStatus.prototype.setStallState = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MastFollowerStatus} returns this
 */
proto.fox.proto.MastFollowerStatus.prototype.clearStallState = function() {
  return this.setStallState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MastFollowerStatus.prototype.hasStallState = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 stall_recoveries = 4;
 * @return {number}
 */
proto.fox.proto.MastFollowerStatus.prototype.getStallRecoveries = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastFollowerStatus} returns this
 */
proto.fox.proto.MastFollowerStatus.prototype.setStallRecoveries = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 status_complete_delay_timestamp = 5;
 * @return {number}
 */
proto.fox.proto.MastFollowerStatus.prototype.getStatusCompleteDelayTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastFollowerStatus} returns this
 */
proto.fox.proto.MastFollowerStatus.prototype.setStatusCompleteDelayTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LaserFieldSetSwitchingColumn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LaserFieldSetSwitchingColumn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.toObject = function(includeInstance, msg) {
  var f, obj = {
    outerWheelSpeedsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    fieldSetIndicesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LaserFieldSetSwitchingColumn}
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LaserFieldSetSwitchingColumn;
  return proto.fox.proto.LaserFieldSetSwitchingColumn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LaserFieldSetSwitchingColumn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LaserFieldSetSwitchingColumn}
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedDouble());
      msg.setOuterWheelSpeedsList(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setFieldSetIndicesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LaserFieldSetSwitchingColumn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LaserFieldSetSwitchingColumn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOuterWheelSpeedsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
  f = message.getFieldSetIndicesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * repeated double outer_wheel_speeds = 1;
 * @return {!Array<number>}
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.prototype.getOuterWheelSpeedsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LaserFieldSetSwitchingColumn} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.prototype.setOuterWheelSpeedsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LaserFieldSetSwitchingColumn} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.prototype.addOuterWheelSpeeds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LaserFieldSetSwitchingColumn} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.prototype.clearOuterWheelSpeedsList = function() {
  return this.setOuterWheelSpeedsList([]);
};


/**
 * repeated int32 field_set_indices = 2;
 * @return {!Array<number>}
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.prototype.getFieldSetIndicesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LaserFieldSetSwitchingColumn} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.prototype.setFieldSetIndicesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LaserFieldSetSwitchingColumn} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.prototype.addFieldSetIndices = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LaserFieldSetSwitchingColumn} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingColumn.prototype.clearFieldSetIndicesList = function() {
  return this.setFieldSetIndicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.LaserFieldSetSwitchingTable.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LaserFieldSetSwitchingTable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LaserFieldSetSwitchingTable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LaserFieldSetSwitchingTable.toObject = function(includeInstance, msg) {
  var f, obj = {
    steeringAnglesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    outerWheelSpeedsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
    fieldSetsList: jspb.Message.toObjectList(msg.getFieldSetsList(),
    sensors_laser_field_pb.LaserFieldSet.toObject, includeInstance),
    columnsList: jspb.Message.toObjectList(msg.getColumnsList(),
    proto.fox.proto.LaserFieldSetSwitchingColumn.toObject, includeInstance),
    nearSpaceFieldSet: (f = msg.getNearSpaceFieldSet()) && sensors_laser_field_pb.LaserFieldSet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LaserFieldSetSwitchingTable;
  return proto.fox.proto.LaserFieldSetSwitchingTable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LaserFieldSetSwitchingTable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedDouble());
      msg.setSteeringAnglesList(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedDouble());
      msg.setOuterWheelSpeedsList(value);
      break;
    case 3:
      var value = new sensors_laser_field_pb.LaserFieldSet;
      reader.readMessage(value,sensors_laser_field_pb.LaserFieldSet.deserializeBinaryFromReader);
      msg.addFieldSets(value);
      break;
    case 4:
      var value = new proto.fox.proto.LaserFieldSetSwitchingColumn;
      reader.readMessage(value,proto.fox.proto.LaserFieldSetSwitchingColumn.deserializeBinaryFromReader);
      msg.addColumns(value);
      break;
    case 5:
      var value = new sensors_laser_field_pb.LaserFieldSet;
      reader.readMessage(value,sensors_laser_field_pb.LaserFieldSet.deserializeBinaryFromReader);
      msg.setNearSpaceFieldSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LaserFieldSetSwitchingTable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LaserFieldSetSwitchingTable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LaserFieldSetSwitchingTable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSteeringAnglesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
  f = message.getOuterWheelSpeedsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      2,
      f
    );
  }
  f = message.getFieldSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      sensors_laser_field_pb.LaserFieldSet.serializeBinaryToWriter
    );
  }
  f = message.getColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.fox.proto.LaserFieldSetSwitchingColumn.serializeBinaryToWriter
    );
  }
  f = message.getNearSpaceFieldSet();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      sensors_laser_field_pb.LaserFieldSet.serializeBinaryToWriter
    );
  }
};


/**
 * repeated double steering_angles = 1;
 * @return {!Array<number>}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.getSteeringAnglesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.setSteeringAnglesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.addSteeringAngles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.clearSteeringAnglesList = function() {
  return this.setSteeringAnglesList([]);
};


/**
 * repeated double outer_wheel_speeds = 2;
 * @return {!Array<number>}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.getOuterWheelSpeedsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.setOuterWheelSpeedsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.addOuterWheelSpeeds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.clearOuterWheelSpeedsList = function() {
  return this.setOuterWheelSpeedsList([]);
};


/**
 * repeated LaserFieldSet field_sets = 3;
 * @return {!Array<!proto.fox.proto.LaserFieldSet>}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.getFieldSetsList = function() {
  return /** @type{!Array<!proto.fox.proto.LaserFieldSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, sensors_laser_field_pb.LaserFieldSet, 3));
};


/**
 * @param {!Array<!proto.fox.proto.LaserFieldSet>} value
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
*/
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.setFieldSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.LaserFieldSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LaserFieldSet}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.addFieldSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.LaserFieldSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.clearFieldSetsList = function() {
  return this.setFieldSetsList([]);
};


/**
 * repeated LaserFieldSetSwitchingColumn columns = 4;
 * @return {!Array<!proto.fox.proto.LaserFieldSetSwitchingColumn>}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.getColumnsList = function() {
  return /** @type{!Array<!proto.fox.proto.LaserFieldSetSwitchingColumn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.LaserFieldSetSwitchingColumn, 4));
};


/**
 * @param {!Array<!proto.fox.proto.LaserFieldSetSwitchingColumn>} value
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
*/
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.setColumnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.fox.proto.LaserFieldSetSwitchingColumn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LaserFieldSetSwitchingColumn}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.addColumns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.fox.proto.LaserFieldSetSwitchingColumn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.clearColumnsList = function() {
  return this.setColumnsList([]);
};


/**
 * optional LaserFieldSet near_space_field_set = 5;
 * @return {?proto.fox.proto.LaserFieldSet}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.getNearSpaceFieldSet = function() {
  return /** @type{?proto.fox.proto.LaserFieldSet} */ (
    jspb.Message.getWrapperField(this, sensors_laser_field_pb.LaserFieldSet, 5));
};


/**
 * @param {?proto.fox.proto.LaserFieldSet|undefined} value
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
*/
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.setNearSpaceFieldSet = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.LaserFieldSetSwitchingTable} returns this
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.clearNearSpaceFieldSet = function() {
  return this.setNearSpaceFieldSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.LaserFieldSetSwitchingTable.prototype.hasNearSpaceFieldSet = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SafetyScannerTables.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SafetyScannerTables.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SafetyScannerTables} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetyScannerTables.toObject = function(includeInstance, msg) {
  var f, obj = {
    front: (f = msg.getFront()) && proto.fox.proto.LaserFieldSetSwitchingTable.toObject(includeInstance, f),
    left: (f = msg.getLeft()) && proto.fox.proto.LaserFieldSetSwitchingTable.toObject(includeInstance, f),
    right: (f = msg.getRight()) && proto.fox.proto.LaserFieldSetSwitchingTable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SafetyScannerTables}
 */
proto.fox.proto.SafetyScannerTables.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SafetyScannerTables;
  return proto.fox.proto.SafetyScannerTables.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SafetyScannerTables} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SafetyScannerTables}
 */
proto.fox.proto.SafetyScannerTables.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.LaserFieldSetSwitchingTable;
      reader.readMessage(value,proto.fox.proto.LaserFieldSetSwitchingTable.deserializeBinaryFromReader);
      msg.setFront(value);
      break;
    case 2:
      var value = new proto.fox.proto.LaserFieldSetSwitchingTable;
      reader.readMessage(value,proto.fox.proto.LaserFieldSetSwitchingTable.deserializeBinaryFromReader);
      msg.setLeft(value);
      break;
    case 3:
      var value = new proto.fox.proto.LaserFieldSetSwitchingTable;
      reader.readMessage(value,proto.fox.proto.LaserFieldSetSwitchingTable.deserializeBinaryFromReader);
      msg.setRight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SafetyScannerTables.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SafetyScannerTables.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SafetyScannerTables} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetyScannerTables.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFront();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.LaserFieldSetSwitchingTable.serializeBinaryToWriter
    );
  }
  f = message.getLeft();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.LaserFieldSetSwitchingTable.serializeBinaryToWriter
    );
  }
  f = message.getRight();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.LaserFieldSetSwitchingTable.serializeBinaryToWriter
    );
  }
};


/**
 * optional LaserFieldSetSwitchingTable front = 1;
 * @return {?proto.fox.proto.LaserFieldSetSwitchingTable}
 */
proto.fox.proto.SafetyScannerTables.prototype.getFront = function() {
  return /** @type{?proto.fox.proto.LaserFieldSetSwitchingTable} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.LaserFieldSetSwitchingTable, 1));
};


/**
 * @param {?proto.fox.proto.LaserFieldSetSwitchingTable|undefined} value
 * @return {!proto.fox.proto.SafetyScannerTables} returns this
*/
proto.fox.proto.SafetyScannerTables.prototype.setFront = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.SafetyScannerTables} returns this
 */
proto.fox.proto.SafetyScannerTables.prototype.clearFront = function() {
  return this.setFront(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.SafetyScannerTables.prototype.hasFront = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LaserFieldSetSwitchingTable left = 2;
 * @return {?proto.fox.proto.LaserFieldSetSwitchingTable}
 */
proto.fox.proto.SafetyScannerTables.prototype.getLeft = function() {
  return /** @type{?proto.fox.proto.LaserFieldSetSwitchingTable} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.LaserFieldSetSwitchingTable, 2));
};


/**
 * @param {?proto.fox.proto.LaserFieldSetSwitchingTable|undefined} value
 * @return {!proto.fox.proto.SafetyScannerTables} returns this
*/
proto.fox.proto.SafetyScannerTables.prototype.setLeft = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.SafetyScannerTables} returns this
 */
proto.fox.proto.SafetyScannerTables.prototype.clearLeft = function() {
  return this.setLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.SafetyScannerTables.prototype.hasLeft = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LaserFieldSetSwitchingTable right = 3;
 * @return {?proto.fox.proto.LaserFieldSetSwitchingTable}
 */
proto.fox.proto.SafetyScannerTables.prototype.getRight = function() {
  return /** @type{?proto.fox.proto.LaserFieldSetSwitchingTable} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.LaserFieldSetSwitchingTable, 3));
};


/**
 * @param {?proto.fox.proto.LaserFieldSetSwitchingTable|undefined} value
 * @return {!proto.fox.proto.SafetyScannerTables} returns this
*/
proto.fox.proto.SafetyScannerTables.prototype.setRight = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.SafetyScannerTables} returns this
 */
proto.fox.proto.SafetyScannerTables.prototype.clearRight = function() {
  return this.setRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.SafetyScannerTables.prototype.hasRight = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Node.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapTNode: (f = msg.getMapTNode()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Node}
 */
proto.fox.proto.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Node;
  return proto.fox.proto.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Node}
 */
proto.fox.proto.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTNode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapTNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
};


/**
 * optional Transform2f map_t_node = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.Node.prototype.getMapTNode = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.Node} returns this
*/
proto.fox.proto.Node.prototype.setMapTNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Node} returns this
 */
proto.fox.proto.Node.prototype.clearMapTNode = function() {
  return this.setMapTNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Node.prototype.hasMapTNode = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Edge.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Edge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Edge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Edge.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: (f = msg.getPath()) && proto.fox.proto.TricycleSegment.toObject(includeInstance, f),
    cost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    valid: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    sourceNode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    targetNode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Edge}
 */
proto.fox.proto.Edge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Edge;
  return proto.fox.proto.Edge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Edge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Edge}
 */
proto.fox.proto.Edge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.TricycleSegment;
      reader.readMessage(value,proto.fox.proto.TricycleSegment.deserializeBinaryFromReader);
      msg.setPath(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCost(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSourceNode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetNode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Edge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Edge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Edge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Edge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.TricycleSegment.serializeBinaryToWriter
    );
  }
  f = message.getCost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getValid();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSourceNode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTargetNode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional TricycleSegment path = 1;
 * @return {?proto.fox.proto.TricycleSegment}
 */
proto.fox.proto.Edge.prototype.getPath = function() {
  return /** @type{?proto.fox.proto.TricycleSegment} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.TricycleSegment, 1));
};


/**
 * @param {?proto.fox.proto.TricycleSegment|undefined} value
 * @return {!proto.fox.proto.Edge} returns this
*/
proto.fox.proto.Edge.prototype.setPath = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Edge} returns this
 */
proto.fox.proto.Edge.prototype.clearPath = function() {
  return this.setPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Edge.prototype.hasPath = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float cost = 2;
 * @return {number}
 */
proto.fox.proto.Edge.prototype.getCost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Edge} returns this
 */
proto.fox.proto.Edge.prototype.setCost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool valid = 3;
 * @return {boolean}
 */
proto.fox.proto.Edge.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.Edge} returns this
 */
proto.fox.proto.Edge.prototype.setValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 source_node = 4;
 * @return {number}
 */
proto.fox.proto.Edge.prototype.getSourceNode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Edge} returns this
 */
proto.fox.proto.Edge.prototype.setSourceNode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 target_node = 5;
 * @return {number}
 */
proto.fox.proto.Edge.prototype.getTargetNode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Edge} returns this
 */
proto.fox.proto.Edge.prototype.setTargetNode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.Edges.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Edges.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Edges.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Edges} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Edges.toObject = function(includeInstance, msg) {
  var f, obj = {
    edgeList: jspb.Message.toObjectList(msg.getEdgeList(),
    proto.fox.proto.Edge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Edges}
 */
proto.fox.proto.Edges.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Edges;
  return proto.fox.proto.Edges.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Edges} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Edges}
 */
proto.fox.proto.Edges.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.Edge;
      reader.readMessage(value,proto.fox.proto.Edge.deserializeBinaryFromReader);
      msg.addEdge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Edges.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Edges.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Edges} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Edges.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEdgeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.Edge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Edge edge = 1;
 * @return {!Array<!proto.fox.proto.Edge>}
 */
proto.fox.proto.Edges.prototype.getEdgeList = function() {
  return /** @type{!Array<!proto.fox.proto.Edge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.Edge, 1));
};


/**
 * @param {!Array<!proto.fox.proto.Edge>} value
 * @return {!proto.fox.proto.Edges} returns this
*/
proto.fox.proto.Edges.prototype.setEdgeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.Edge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Edge}
 */
proto.fox.proto.Edges.prototype.addEdge = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.Edge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.Edges} returns this
 */
proto.fox.proto.Edges.prototype.clearEdgeList = function() {
  return this.setEdgeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.Roadmap.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Roadmap.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Roadmap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Roadmap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Roadmap.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeList: jspb.Message.toObjectList(msg.getNodeList(),
    proto.fox.proto.Node.toObject, includeInstance),
    edgeList: jspb.Message.toObjectList(msg.getEdgeList(),
    proto.fox.proto.Edge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Roadmap}
 */
proto.fox.proto.Roadmap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Roadmap;
  return proto.fox.proto.Roadmap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Roadmap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Roadmap}
 */
proto.fox.proto.Roadmap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.Node;
      reader.readMessage(value,proto.fox.proto.Node.deserializeBinaryFromReader);
      msg.addNode(value);
      break;
    case 2:
      var value = new proto.fox.proto.Edge;
      reader.readMessage(value,proto.fox.proto.Edge.deserializeBinaryFromReader);
      msg.addEdge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Roadmap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Roadmap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Roadmap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Roadmap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.Node.serializeBinaryToWriter
    );
  }
  f = message.getEdgeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.Edge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Node node = 1;
 * @return {!Array<!proto.fox.proto.Node>}
 */
proto.fox.proto.Roadmap.prototype.getNodeList = function() {
  return /** @type{!Array<!proto.fox.proto.Node>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.Node, 1));
};


/**
 * @param {!Array<!proto.fox.proto.Node>} value
 * @return {!proto.fox.proto.Roadmap} returns this
*/
proto.fox.proto.Roadmap.prototype.setNodeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.Node=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Node}
 */
proto.fox.proto.Roadmap.prototype.addNode = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.Node, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.Roadmap} returns this
 */
proto.fox.proto.Roadmap.prototype.clearNodeList = function() {
  return this.setNodeList([]);
};


/**
 * repeated Edge edge = 2;
 * @return {!Array<!proto.fox.proto.Edge>}
 */
proto.fox.proto.Roadmap.prototype.getEdgeList = function() {
  return /** @type{!Array<!proto.fox.proto.Edge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.Edge, 2));
};


/**
 * @param {!Array<!proto.fox.proto.Edge>} value
 * @return {!proto.fox.proto.Roadmap} returns this
*/
proto.fox.proto.Roadmap.prototype.setEdgeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.Edge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Edge}
 */
proto.fox.proto.Roadmap.prototype.addEdge = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.Edge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.Roadmap} returns this
 */
proto.fox.proto.Roadmap.prototype.clearEdgeList = function() {
  return this.setEdgeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.MastKinematicConfiguration.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.MastKinematicConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.MastKinematicConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastKinematicConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotTMast: (f = msg.getRobotTMast()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    backplateTPick: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lowerLimits: (f = msg.getLowerLimits()) && proto.fox.proto.MastControl.toObject(includeInstance, f),
    upperLimits: (f = msg.getUpperLimits()) && proto.fox.proto.MastControl.toObject(includeInstance, f),
    backplateShape: (f = msg.getBackplateShape()) && geometry_proto_transform_pb.Vector2d.toObject(includeInstance, f),
    forkShape: (f = msg.getForkShape()) && geometry_proto_transform_pb.Vector2d.toObject(includeInstance, f),
    forksTCarriageVertical: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    forkCenterOffsetList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 8)) == null ? undefined : f,
    tiltM: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    tiltB: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    robotTTiltX: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    robotTTiltZ: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    carriageTRightFork: (f = msg.getCarriageTRightFork()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    carriageTLeftFork: (f = msg.getCarriageTLeftFork()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.MastKinematicConfiguration}
 */
proto.fox.proto.MastKinematicConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.MastKinematicConfiguration;
  return proto.fox.proto.MastKinematicConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.MastKinematicConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.MastKinematicConfiguration}
 */
proto.fox.proto.MastKinematicConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setRobotTMast(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackplateTPick(value);
      break;
    case 3:
      var value = new proto.fox.proto.MastControl;
      reader.readMessage(value,proto.fox.proto.MastControl.deserializeBinaryFromReader);
      msg.setLowerLimits(value);
      break;
    case 4:
      var value = new proto.fox.proto.MastControl;
      reader.readMessage(value,proto.fox.proto.MastControl.deserializeBinaryFromReader);
      msg.setUpperLimits(value);
      break;
    case 5:
      var value = new geometry_proto_transform_pb.Vector2d;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2d.deserializeBinaryFromReader);
      msg.setBackplateShape(value);
      break;
    case 6:
      var value = new geometry_proto_transform_pb.Vector2d;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2d.deserializeBinaryFromReader);
      msg.setForkShape(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setForksTCarriageVertical(value);
      break;
    case 8:
      var value = /** @type {!Array<number>} */ (reader.readPackedDouble());
      msg.setForkCenterOffsetList(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTiltM(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTiltB(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRobotTTiltX(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRobotTTiltZ(value);
      break;
    case 13:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setCarriageTRightFork(value);
      break;
    case 14:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setCarriageTLeftFork(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.MastKinematicConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.MastKinematicConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastKinematicConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotTMast();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getBackplateTPick();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLowerLimits();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.MastControl.serializeBinaryToWriter
    );
  }
  f = message.getUpperLimits();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.MastControl.serializeBinaryToWriter
    );
  }
  f = message.getBackplateShape();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      geometry_proto_transform_pb.Vector2d.serializeBinaryToWriter
    );
  }
  f = message.getForkShape();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      geometry_proto_transform_pb.Vector2d.serializeBinaryToWriter
    );
  }
  f = message.getForksTCarriageVertical();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getForkCenterOffsetList();
  if (f.length > 0) {
    writer.writePackedDouble(
      8,
      f
    );
  }
  f = message.getTiltM();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTiltB();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getRobotTTiltX();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getRobotTTiltZ();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getCarriageTRightFork();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getCarriageTLeftFork();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
};


/**
 * optional Transform2f robot_t_mast = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getRobotTMast = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
*/
proto.fox.proto.MastKinematicConfiguration.prototype.setRobotTMast = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.clearRobotTMast = function() {
  return this.setRobotTMast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.hasRobotTMast = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double backplate_t_pick = 2;
 * @return {number}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getBackplateTPick = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.setBackplateTPick = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional MastControl lower_limits = 3;
 * @return {?proto.fox.proto.MastControl}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getLowerLimits = function() {
  return /** @type{?proto.fox.proto.MastControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MastControl, 3));
};


/**
 * @param {?proto.fox.proto.MastControl|undefined} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
*/
proto.fox.proto.MastKinematicConfiguration.prototype.setLowerLimits = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.clearLowerLimits = function() {
  return this.setLowerLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.hasLowerLimits = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MastControl upper_limits = 4;
 * @return {?proto.fox.proto.MastControl}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getUpperLimits = function() {
  return /** @type{?proto.fox.proto.MastControl} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MastControl, 4));
};


/**
 * @param {?proto.fox.proto.MastControl|undefined} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
*/
proto.fox.proto.MastKinematicConfiguration.prototype.setUpperLimits = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.clearUpperLimits = function() {
  return this.setUpperLimits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.hasUpperLimits = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Vector2d backplate_shape = 5;
 * @return {?proto.fox.proto.Vector2d}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getBackplateShape = function() {
  return /** @type{?proto.fox.proto.Vector2d} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector2d, 5));
};


/**
 * @param {?proto.fox.proto.Vector2d|undefined} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
*/
proto.fox.proto.MastKinematicConfiguration.prototype.setBackplateShape = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.clearBackplateShape = function() {
  return this.setBackplateShape(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.hasBackplateShape = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Vector2d fork_shape = 6;
 * @return {?proto.fox.proto.Vector2d}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getForkShape = function() {
  return /** @type{?proto.fox.proto.Vector2d} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector2d, 6));
};


/**
 * @param {?proto.fox.proto.Vector2d|undefined} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
*/
proto.fox.proto.MastKinematicConfiguration.prototype.setForkShape = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.clearForkShape = function() {
  return this.setForkShape(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.hasForkShape = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double forks_t_carriage_vertical = 7;
 * @return {number}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getForksTCarriageVertical = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.setForksTCarriageVertical = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * repeated double fork_center_offset = 8;
 * @return {!Array<number>}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getForkCenterOffsetList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.setForkCenterOffsetList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.addForkCenterOffset = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.clearForkCenterOffsetList = function() {
  return this.setForkCenterOffsetList([]);
};


/**
 * optional double tilt_m = 9;
 * @return {number}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getTiltM = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.setTiltM = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double tilt_b = 10;
 * @return {number}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getTiltB = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.setTiltB = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double robot_t_tilt_x = 11;
 * @return {number}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getRobotTTiltX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.setRobotTTiltX = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double robot_t_tilt_z = 12;
 * @return {number}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getRobotTTiltZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.setRobotTTiltZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional Transform3f carriage_t_right_fork = 13;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getCarriageTRightFork = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 13));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
*/
proto.fox.proto.MastKinematicConfiguration.prototype.setCarriageTRightFork = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.clearCarriageTRightFork = function() {
  return this.setCarriageTRightFork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.hasCarriageTRightFork = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Transform3f carriage_t_left_fork = 14;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.getCarriageTLeftFork = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 14));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
*/
proto.fox.proto.MastKinematicConfiguration.prototype.setCarriageTLeftFork = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MastKinematicConfiguration} returns this
 */
proto.fox.proto.MastKinematicConfiguration.prototype.clearCarriageTLeftFork = function() {
  return this.setCarriageTLeftFork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MastKinematicConfiguration.prototype.hasCarriageTLeftFork = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.RawPoseScan.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.RawPoseScan.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.RawPoseScan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.RawPoseScan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RawPoseScan.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapTRobot: (f = msg.getMapTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    left: (f = msg.getLeft()) && sensors_laser_scan_pb.LaserScan.toObject(includeInstance, f),
    right: (f = msg.getRight()) && sensors_laser_scan_pb.LaserScan.toObject(includeInstance, f),
    sick: (f = msg.getSick()) && sensors_laser_scan_pb.LaserScan.toObject(includeInstance, f),
    robotTLeftScan: (f = msg.getRobotTLeftScan()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    robotTRightScan: (f = msg.getRobotTRightScan()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    robotTSickScan: (f = msg.getRobotTSickScan()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    palletsList: jspb.Message.toObjectList(msg.getPalletsList(),
    detection_proto_detection_pb.TrackedPallet.toObject, includeInstance),
    travelDirection: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.RawPoseScan}
 */
proto.fox.proto.RawPoseScan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.RawPoseScan;
  return proto.fox.proto.RawPoseScan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.RawPoseScan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.RawPoseScan}
 */
proto.fox.proto.RawPoseScan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTRobot(value);
      break;
    case 2:
      var value = new sensors_laser_scan_pb.LaserScan;
      reader.readMessage(value,sensors_laser_scan_pb.LaserScan.deserializeBinaryFromReader);
      msg.setLeft(value);
      break;
    case 3:
      var value = new sensors_laser_scan_pb.LaserScan;
      reader.readMessage(value,sensors_laser_scan_pb.LaserScan.deserializeBinaryFromReader);
      msg.setRight(value);
      break;
    case 4:
      var value = new sensors_laser_scan_pb.LaserScan;
      reader.readMessage(value,sensors_laser_scan_pb.LaserScan.deserializeBinaryFromReader);
      msg.setSick(value);
      break;
    case 7:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setRobotTLeftScan(value);
      break;
    case 8:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setRobotTRightScan(value);
      break;
    case 9:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setRobotTSickScan(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = new detection_proto_detection_pb.TrackedPallet;
      reader.readMessage(value,detection_proto_detection_pb.TrackedPallet.deserializeBinaryFromReader);
      msg.addPallets(value);
      break;
    case 10:
      var value = /** @type {!proto.fox.proto.TravelDirection} */ (reader.readEnum());
      msg.setTravelDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.RawPoseScan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.RawPoseScan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.RawPoseScan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RawPoseScan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapTRobot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getLeft();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sensors_laser_scan_pb.LaserScan.serializeBinaryToWriter
    );
  }
  f = message.getRight();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sensors_laser_scan_pb.LaserScan.serializeBinaryToWriter
    );
  }
  f = message.getSick();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      sensors_laser_scan_pb.LaserScan.serializeBinaryToWriter
    );
  }
  f = message.getRobotTLeftScan();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getRobotTRightScan();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getRobotTSickScan();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPalletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      detection_proto_detection_pb.TrackedPallet.serializeBinaryToWriter
    );
  }
  f = message.getTravelDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional Transform2f map_t_robot = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.RawPoseScan.prototype.getMapTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.RawPoseScan} returns this
*/
proto.fox.proto.RawPoseScan.prototype.setMapTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RawPoseScan} returns this
 */
proto.fox.proto.RawPoseScan.prototype.clearMapTRobot = function() {
  return this.setMapTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RawPoseScan.prototype.hasMapTRobot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LaserScan left = 2;
 * @return {?proto.fox.proto.LaserScan}
 */
proto.fox.proto.RawPoseScan.prototype.getLeft = function() {
  return /** @type{?proto.fox.proto.LaserScan} */ (
    jspb.Message.getWrapperField(this, sensors_laser_scan_pb.LaserScan, 2));
};


/**
 * @param {?proto.fox.proto.LaserScan|undefined} value
 * @return {!proto.fox.proto.RawPoseScan} returns this
*/
proto.fox.proto.RawPoseScan.prototype.setLeft = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RawPoseScan} returns this
 */
proto.fox.proto.RawPoseScan.prototype.clearLeft = function() {
  return this.setLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RawPoseScan.prototype.hasLeft = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LaserScan right = 3;
 * @return {?proto.fox.proto.LaserScan}
 */
proto.fox.proto.RawPoseScan.prototype.getRight = function() {
  return /** @type{?proto.fox.proto.LaserScan} */ (
    jspb.Message.getWrapperField(this, sensors_laser_scan_pb.LaserScan, 3));
};


/**
 * @param {?proto.fox.proto.LaserScan|undefined} value
 * @return {!proto.fox.proto.RawPoseScan} returns this
*/
proto.fox.proto.RawPoseScan.prototype.setRight = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RawPoseScan} returns this
 */
proto.fox.proto.RawPoseScan.prototype.clearRight = function() {
  return this.setRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RawPoseScan.prototype.hasRight = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LaserScan sick = 4;
 * @return {?proto.fox.proto.LaserScan}
 */
proto.fox.proto.RawPoseScan.prototype.getSick = function() {
  return /** @type{?proto.fox.proto.LaserScan} */ (
    jspb.Message.getWrapperField(this, sensors_laser_scan_pb.LaserScan, 4));
};


/**
 * @param {?proto.fox.proto.LaserScan|undefined} value
 * @return {!proto.fox.proto.RawPoseScan} returns this
*/
proto.fox.proto.RawPoseScan.prototype.setSick = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RawPoseScan} returns this
 */
proto.fox.proto.RawPoseScan.prototype.clearSick = function() {
  return this.setSick(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RawPoseScan.prototype.hasSick = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Transform3f robot_t_left_scan = 7;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.RawPoseScan.prototype.getRobotTLeftScan = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 7));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.RawPoseScan} returns this
*/
proto.fox.proto.RawPoseScan.prototype.setRobotTLeftScan = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RawPoseScan} returns this
 */
proto.fox.proto.RawPoseScan.prototype.clearRobotTLeftScan = function() {
  return this.setRobotTLeftScan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RawPoseScan.prototype.hasRobotTLeftScan = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Transform3f robot_t_right_scan = 8;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.RawPoseScan.prototype.getRobotTRightScan = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 8));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.RawPoseScan} returns this
*/
proto.fox.proto.RawPoseScan.prototype.setRobotTRightScan = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RawPoseScan} returns this
 */
proto.fox.proto.RawPoseScan.prototype.clearRobotTRightScan = function() {
  return this.setRobotTRightScan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RawPoseScan.prototype.hasRobotTRightScan = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Transform3f robot_t_sick_scan = 9;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.RawPoseScan.prototype.getRobotTSickScan = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 9));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.RawPoseScan} returns this
*/
proto.fox.proto.RawPoseScan.prototype.setRobotTSickScan = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RawPoseScan} returns this
 */
proto.fox.proto.RawPoseScan.prototype.clearRobotTSickScan = function() {
  return this.setRobotTSickScan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RawPoseScan.prototype.hasRobotTSickScan = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 timestamp = 5;
 * @return {number}
 */
proto.fox.proto.RawPoseScan.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RawPoseScan} returns this
 */
proto.fox.proto.RawPoseScan.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated TrackedPallet pallets = 6;
 * @return {!Array<!proto.fox.proto.TrackedPallet>}
 */
proto.fox.proto.RawPoseScan.prototype.getPalletsList = function() {
  return /** @type{!Array<!proto.fox.proto.TrackedPallet>} */ (
    jspb.Message.getRepeatedWrapperField(this, detection_proto_detection_pb.TrackedPallet, 6));
};


/**
 * @param {!Array<!proto.fox.proto.TrackedPallet>} value
 * @return {!proto.fox.proto.RawPoseScan} returns this
*/
proto.fox.proto.RawPoseScan.prototype.setPalletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.fox.proto.TrackedPallet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TrackedPallet}
 */
proto.fox.proto.RawPoseScan.prototype.addPallets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.fox.proto.TrackedPallet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.RawPoseScan} returns this
 */
proto.fox.proto.RawPoseScan.prototype.clearPalletsList = function() {
  return this.setPalletsList([]);
};


/**
 * optional TravelDirection travel_direction = 10;
 * @return {!proto.fox.proto.TravelDirection}
 */
proto.fox.proto.RawPoseScan.prototype.getTravelDirection = function() {
  return /** @type {!proto.fox.proto.TravelDirection} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.fox.proto.TravelDirection} value
 * @return {!proto.fox.proto.RawPoseScan} returns this
 */
proto.fox.proto.RawPoseScan.prototype.setTravelDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.PlannerMap.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlannerMap.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlannerMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlannerMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlannerMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    scansList: jspb.Message.toObjectList(msg.getScansList(),
    proto.fox.proto.RawPoseScan.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlannerMap}
 */
proto.fox.proto.PlannerMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlannerMap;
  return proto.fox.proto.PlannerMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlannerMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlannerMap}
 */
proto.fox.proto.PlannerMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.RawPoseScan;
      reader.readMessage(value,proto.fox.proto.RawPoseScan.deserializeBinaryFromReader);
      msg.addScans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlannerMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlannerMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlannerMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlannerMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.RawPoseScan.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RawPoseScan scans = 1;
 * @return {!Array<!proto.fox.proto.RawPoseScan>}
 */
proto.fox.proto.PlannerMap.prototype.getScansList = function() {
  return /** @type{!Array<!proto.fox.proto.RawPoseScan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.RawPoseScan, 1));
};


/**
 * @param {!Array<!proto.fox.proto.RawPoseScan>} value
 * @return {!proto.fox.proto.PlannerMap} returns this
*/
proto.fox.proto.PlannerMap.prototype.setScansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.RawPoseScan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.RawPoseScan}
 */
proto.fox.proto.PlannerMap.prototype.addScans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.RawPoseScan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PlannerMap} returns this
 */
proto.fox.proto.PlannerMap.prototype.clearScansList = function() {
  return this.setScansList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.PlannerCache.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlannerCache.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlannerCache.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlannerCache} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlannerCache.toObject = function(includeInstance, msg) {
  var f, obj = {
    trajectoriesList: jspb.Message.toObjectList(msg.getTrajectoriesList(),
    proto.fox.proto.TricycleTrajectory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlannerCache}
 */
proto.fox.proto.PlannerCache.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlannerCache;
  return proto.fox.proto.PlannerCache.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlannerCache} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlannerCache}
 */
proto.fox.proto.PlannerCache.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.TricycleTrajectory;
      reader.readMessage(value,proto.fox.proto.TricycleTrajectory.deserializeBinaryFromReader);
      msg.addTrajectories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlannerCache.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlannerCache.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlannerCache} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlannerCache.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrajectoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.TricycleTrajectory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TricycleTrajectory trajectories = 1;
 * @return {!Array<!proto.fox.proto.TricycleTrajectory>}
 */
proto.fox.proto.PlannerCache.prototype.getTrajectoriesList = function() {
  return /** @type{!Array<!proto.fox.proto.TricycleTrajectory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.TricycleTrajectory, 1));
};


/**
 * @param {!Array<!proto.fox.proto.TricycleTrajectory>} value
 * @return {!proto.fox.proto.PlannerCache} returns this
*/
proto.fox.proto.PlannerCache.prototype.setTrajectoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.TricycleTrajectory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TricycleTrajectory}
 */
proto.fox.proto.PlannerCache.prototype.addTrajectories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.TricycleTrajectory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PlannerCache} returns this
 */
proto.fox.proto.PlannerCache.prototype.clearTrajectoriesList = function() {
  return this.setTrajectoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlaceActionInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlaceActionInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlaceActionInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceActionInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeLocationIndex: jspb.Message.getFieldWithDefault(msg, 1, 0),
    carriedPalletId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlaceActionInformation}
 */
proto.fox.proto.PlaceActionInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlaceActionInformation;
  return proto.fox.proto.PlaceActionInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlaceActionInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlaceActionInformation}
 */
proto.fox.proto.PlaceActionInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlaceLocationIndex(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCarriedPalletId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlaceActionInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlaceActionInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlaceActionInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceActionInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceLocationIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCarriedPalletId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 place_location_index = 1;
 * @return {number}
 */
proto.fox.proto.PlaceActionInformation.prototype.getPlaceLocationIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceActionInformation} returns this
 */
proto.fox.proto.PlaceActionInformation.prototype.setPlaceLocationIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 carried_pallet_id = 2;
 * @return {number}
 */
proto.fox.proto.PlaceActionInformation.prototype.getCarriedPalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceActionInformation} returns this
 */
proto.fox.proto.PlaceActionInformation.prototype.setCarriedPalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlannerState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlannerState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlannerState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlannerState.toObject = function(includeInstance, msg) {
  var f, obj = {
    placeActionInformation: (f = msg.getPlaceActionInformation()) && proto.fox.proto.PlaceActionInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlannerState}
 */
proto.fox.proto.PlannerState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlannerState;
  return proto.fox.proto.PlannerState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlannerState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlannerState}
 */
proto.fox.proto.PlannerState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.PlaceActionInformation;
      reader.readMessage(value,proto.fox.proto.PlaceActionInformation.deserializeBinaryFromReader);
      msg.setPlaceActionInformation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlannerState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlannerState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlannerState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlannerState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaceActionInformation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.PlaceActionInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaceActionInformation place_action_information = 1;
 * @return {?proto.fox.proto.PlaceActionInformation}
 */
proto.fox.proto.PlannerState.prototype.getPlaceActionInformation = function() {
  return /** @type{?proto.fox.proto.PlaceActionInformation} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlaceActionInformation, 1));
};


/**
 * @param {?proto.fox.proto.PlaceActionInformation|undefined} value
 * @return {!proto.fox.proto.PlannerState} returns this
*/
proto.fox.proto.PlannerState.prototype.setPlaceActionInformation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlannerState} returns this
 */
proto.fox.proto.PlannerState.prototype.clearPlaceActionInformation = function() {
  return this.setPlaceActionInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlannerState.prototype.hasPlaceActionInformation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.MotionValidationState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.MotionValidationState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.MotionValidationState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MotionValidationState.toObject = function(includeInstance, msg) {
  var f, obj = {
    spuriousPointSuppressionSteps: jspb.Message.getFieldWithDefault(msg, 1, 0),
    collisionPoints: (f = msg.getCollisionPoints()) && proto.fox.proto.ValidationErrorLidarPoints.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.MotionValidationState}
 */
proto.fox.proto.MotionValidationState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.MotionValidationState;
  return proto.fox.proto.MotionValidationState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.MotionValidationState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.MotionValidationState}
 */
proto.fox.proto.MotionValidationState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpuriousPointSuppressionSteps(value);
      break;
    case 2:
      var value = new proto.fox.proto.ValidationErrorLidarPoints;
      reader.readMessage(value,proto.fox.proto.ValidationErrorLidarPoints.deserializeBinaryFromReader);
      msg.setCollisionPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.MotionValidationState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.MotionValidationState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.MotionValidationState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MotionValidationState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpuriousPointSuppressionSteps();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCollisionPoints();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.ValidationErrorLidarPoints.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 spurious_point_suppression_steps = 1;
 * @return {number}
 */
proto.fox.proto.MotionValidationState.prototype.getSpuriousPointSuppressionSteps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MotionValidationState} returns this
 */
proto.fox.proto.MotionValidationState.prototype.setSpuriousPointSuppressionSteps = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ValidationErrorLidarPoints collision_points = 2;
 * @return {?proto.fox.proto.ValidationErrorLidarPoints}
 */
proto.fox.proto.MotionValidationState.prototype.getCollisionPoints = function() {
  return /** @type{?proto.fox.proto.ValidationErrorLidarPoints} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ValidationErrorLidarPoints, 2));
};


/**
 * @param {?proto.fox.proto.ValidationErrorLidarPoints|undefined} value
 * @return {!proto.fox.proto.MotionValidationState} returns this
*/
proto.fox.proto.MotionValidationState.prototype.setCollisionPoints = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MotionValidationState} returns this
 */
proto.fox.proto.MotionValidationState.prototype.clearCollisionPoints = function() {
  return this.setCollisionPoints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MotionValidationState.prototype.hasCollisionPoints = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlaceLocationSafetyOffZoneState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlaceLocationSafetyOffZoneState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState.toObject = function(includeInstance, msg) {
  var f, obj = {
    live: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    mapTCenter: (f = msg.getMapTCenter()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlaceLocationSafetyOffZoneState}
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlaceLocationSafetyOffZoneState;
  return proto.fox.proto.PlaceLocationSafetyOffZoneState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlaceLocationSafetyOffZoneState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlaceLocationSafetyOffZoneState}
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLive(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTCenter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlaceLocationSafetyOffZoneState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlaceLocationSafetyOffZoneState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMapTCenter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool live = 1;
 * @return {boolean}
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState.prototype.getLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlaceLocationSafetyOffZoneState} returns this
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState.prototype.setLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Transform2f map_t_center = 2;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState.prototype.getMapTCenter = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 2));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.PlaceLocationSafetyOffZoneState} returns this
*/
proto.fox.proto.PlaceLocationSafetyOffZoneState.prototype.setMapTCenter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlaceLocationSafetyOffZoneState} returns this
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState.prototype.clearMapTCenter = function() {
  return this.setMapTCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlaceLocationSafetyOffZoneState.prototype.hasMapTCenter = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.BooleanDebouncerState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.BooleanDebouncerState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.BooleanDebouncerState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.BooleanDebouncerState.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    result: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.BooleanDebouncerState}
 */
proto.fox.proto.BooleanDebouncerState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.BooleanDebouncerState;
  return proto.fox.proto.BooleanDebouncerState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.BooleanDebouncerState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.BooleanDebouncerState}
 */
proto.fox.proto.BooleanDebouncerState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.BooleanDebouncerState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.BooleanDebouncerState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.BooleanDebouncerState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.BooleanDebouncerState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getResult();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.fox.proto.BooleanDebouncerState.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.BooleanDebouncerState} returns this
 */
proto.fox.proto.BooleanDebouncerState.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 result = 2;
 * @return {number}
 */
proto.fox.proto.BooleanDebouncerState.prototype.getResult = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.BooleanDebouncerState} returns this
 */
proto.fox.proto.BooleanDebouncerState.prototype.setResult = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ActionValidationState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ActionValidationState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ActionValidationState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ActionValidationState.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastPickPallet: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pickSafetyState: (f = msg.getPickSafetyState()) && proto.fox.proto.BooleanDebouncerState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ActionValidationState}
 */
proto.fox.proto.ActionValidationState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ActionValidationState;
  return proto.fox.proto.ActionValidationState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ActionValidationState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ActionValidationState}
 */
proto.fox.proto.ActionValidationState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastPickPallet(value);
      break;
    case 2:
      var value = new proto.fox.proto.BooleanDebouncerState;
      reader.readMessage(value,proto.fox.proto.BooleanDebouncerState.deserializeBinaryFromReader);
      msg.setPickSafetyState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ActionValidationState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ActionValidationState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ActionValidationState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ActionValidationState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastPickPallet();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPickSafetyState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.BooleanDebouncerState.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 last_pick_pallet = 1;
 * @return {number}
 */
proto.fox.proto.ActionValidationState.prototype.getLastPickPallet = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ActionValidationState} returns this
 */
proto.fox.proto.ActionValidationState.prototype.setLastPickPallet = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BooleanDebouncerState pick_safety_state = 2;
 * @return {?proto.fox.proto.BooleanDebouncerState}
 */
proto.fox.proto.ActionValidationState.prototype.getPickSafetyState = function() {
  return /** @type{?proto.fox.proto.BooleanDebouncerState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.BooleanDebouncerState, 2));
};


/**
 * @param {?proto.fox.proto.BooleanDebouncerState|undefined} value
 * @return {!proto.fox.proto.ActionValidationState} returns this
*/
proto.fox.proto.ActionValidationState.prototype.setPickSafetyState = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ActionValidationState} returns this
 */
proto.fox.proto.ActionValidationState.prototype.clearPickSafetyState = function() {
  return this.setPickSafetyState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ActionValidationState.prototype.hasPickSafetyState = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ValidationError.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ValidationError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ValidationError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ValidationError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lidarPoints: (f = msg.getLidarPoints()) && proto.fox.proto.ValidationErrorLidarPoints.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ValidationError}
 */
proto.fox.proto.ValidationError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ValidationError;
  return proto.fox.proto.ValidationError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ValidationError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ValidationError}
 */
proto.fox.proto.ValidationError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.ValidationError.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.ValidationError.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = new proto.fox.proto.ValidationErrorLidarPoints;
      reader.readMessage(value,proto.fox.proto.ValidationErrorLidarPoints.deserializeBinaryFromReader);
      msg.setLidarPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ValidationError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ValidationError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ValidationError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ValidationError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLidarPoints();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.ValidationErrorLidarPoints.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.ValidationError.Code = {
  OK: 0,
  INVALID_PATH: 1,
  INVARIANT_FAILURE: 2,
  PICK_UNSAFE: 3,
  UNSTABLE_PALLET: 4,
  PALLET_LEANING: 5,
  CARRIED_UNTRACKED_PALLET: 6,
  GRID_COLLISION: 7,
  SEMANTIC_COLLISION: 8,
  SAFETY_FIELD_BREACH: 9,
  LOCALIZATION_NOT_CONFIDENT: 10,
  UNEXPECTED_TRUCK_OBSTACLE: 11,
  TRAILER_UN_VERIFIED: 12,
  MISSING_TRAILER: 13,
  RAMP_BLOCKED: 14,
  HANGING_PLASTIC_DETECTED_TRUSTED: 15,
  HANGING_PLASTIC_DETECTED_NOT_TRUSTED: 16,
  HANGING_PLASTIC_DETECTED_TOO_LARGE: 17,
  HANGING_PLASTIC_CLEARED_TRIPPED: 18,
  PICK_PATH_TOO_SHORT: 19,
  RETURNS_NEAR_ROBOT: 20,
  SERVO_FAILED: 21,
  COLLAPSED_PALLET: 22,
  BAD_FORKS_DETECTION: 23,
  PYRAMID_PALLET: 24,
  AIRBAG_DETECTED: 25,
  UNPICKABLE_TAIL_PALLET: 26,
  TOO_TALL_PALLET: 27,
  FORK_PALLET_COLLISION: 28,
  TAG_VERIFICATION_FAILED: 29,
  TAIL_PALLET_CLOSE_TO_RAMP_LIP: 30,
  CORNER_VALIDATION_FAILURE: 31
};

/**
 * @enum {number}
 */
proto.fox.proto.ValidationError.Action = {
  NO_ACTION: 0,
  INVALIDATE_PLAN: 1
};

/**
 * optional Code code = 1;
 * @return {!proto.fox.proto.ValidationError.Code}
 */
proto.fox.proto.ValidationError.prototype.getCode = function() {
  return /** @type {!proto.fox.proto.ValidationError.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.ValidationError.Code} value
 * @return {!proto.fox.proto.ValidationError} returns this
 */
proto.fox.proto.ValidationError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Action action = 2;
 * @return {!proto.fox.proto.ValidationError.Action}
 */
proto.fox.proto.ValidationError.prototype.getAction = function() {
  return /** @type {!proto.fox.proto.ValidationError.Action} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.ValidationError.Action} value
 * @return {!proto.fox.proto.ValidationError} returns this
 */
proto.fox.proto.ValidationError.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ValidationErrorLidarPoints lidar_points = 3;
 * @return {?proto.fox.proto.ValidationErrorLidarPoints}
 */
proto.fox.proto.ValidationError.prototype.getLidarPoints = function() {
  return /** @type{?proto.fox.proto.ValidationErrorLidarPoints} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ValidationErrorLidarPoints, 3));
};


/**
 * @param {?proto.fox.proto.ValidationErrorLidarPoints|undefined} value
 * @return {!proto.fox.proto.ValidationError} returns this
*/
proto.fox.proto.ValidationError.prototype.setLidarPoints = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ValidationError} returns this
 */
proto.fox.proto.ValidationError.prototype.clearLidarPoints = function() {
  return this.setLidarPoints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ValidationError.prototype.hasLidarPoints = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.ValidationErrorLidarPoints.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ValidationErrorLidarPoints.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ValidationErrorLidarPoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ValidationErrorLidarPoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ValidationErrorLidarPoints.toObject = function(includeInstance, msg) {
  var f, obj = {
    leftPointsMapList: jspb.Message.toObjectList(msg.getLeftPointsMapList(),
    geometry_proto_transform_pb.Vector2f.toObject, includeInstance),
    rightPointsMapList: jspb.Message.toObjectList(msg.getRightPointsMapList(),
    geometry_proto_transform_pb.Vector2f.toObject, includeInstance),
    frontPointsMapList: jspb.Message.toObjectList(msg.getFrontPointsMapList(),
    geometry_proto_transform_pb.Vector2f.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ValidationErrorLidarPoints}
 */
proto.fox.proto.ValidationErrorLidarPoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ValidationErrorLidarPoints;
  return proto.fox.proto.ValidationErrorLidarPoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ValidationErrorLidarPoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ValidationErrorLidarPoints}
 */
proto.fox.proto.ValidationErrorLidarPoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Vector2f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2f.deserializeBinaryFromReader);
      msg.addLeftPointsMap(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Vector2f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2f.deserializeBinaryFromReader);
      msg.addRightPointsMap(value);
      break;
    case 3:
      var value = new geometry_proto_transform_pb.Vector2f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2f.deserializeBinaryFromReader);
      msg.addFrontPointsMap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ValidationErrorLidarPoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ValidationErrorLidarPoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ValidationErrorLidarPoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ValidationErrorLidarPoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeftPointsMapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      geometry_proto_transform_pb.Vector2f.serializeBinaryToWriter
    );
  }
  f = message.getRightPointsMapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      geometry_proto_transform_pb.Vector2f.serializeBinaryToWriter
    );
  }
  f = message.getFrontPointsMapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      geometry_proto_transform_pb.Vector2f.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Vector2f left_points_map = 1;
 * @return {!Array<!proto.fox.proto.Vector2f>}
 */
proto.fox.proto.ValidationErrorLidarPoints.prototype.getLeftPointsMapList = function() {
  return /** @type{!Array<!proto.fox.proto.Vector2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Vector2f, 1));
};


/**
 * @param {!Array<!proto.fox.proto.Vector2f>} value
 * @return {!proto.fox.proto.ValidationErrorLidarPoints} returns this
*/
proto.fox.proto.ValidationErrorLidarPoints.prototype.setLeftPointsMapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.Vector2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Vector2f}
 */
proto.fox.proto.ValidationErrorLidarPoints.prototype.addLeftPointsMap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.Vector2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ValidationErrorLidarPoints} returns this
 */
proto.fox.proto.ValidationErrorLidarPoints.prototype.clearLeftPointsMapList = function() {
  return this.setLeftPointsMapList([]);
};


/**
 * repeated Vector2f right_points_map = 2;
 * @return {!Array<!proto.fox.proto.Vector2f>}
 */
proto.fox.proto.ValidationErrorLidarPoints.prototype.getRightPointsMapList = function() {
  return /** @type{!Array<!proto.fox.proto.Vector2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Vector2f, 2));
};


/**
 * @param {!Array<!proto.fox.proto.Vector2f>} value
 * @return {!proto.fox.proto.ValidationErrorLidarPoints} returns this
*/
proto.fox.proto.ValidationErrorLidarPoints.prototype.setRightPointsMapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.Vector2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Vector2f}
 */
proto.fox.proto.ValidationErrorLidarPoints.prototype.addRightPointsMap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.Vector2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ValidationErrorLidarPoints} returns this
 */
proto.fox.proto.ValidationErrorLidarPoints.prototype.clearRightPointsMapList = function() {
  return this.setRightPointsMapList([]);
};


/**
 * repeated Vector2f front_points_map = 3;
 * @return {!Array<!proto.fox.proto.Vector2f>}
 */
proto.fox.proto.ValidationErrorLidarPoints.prototype.getFrontPointsMapList = function() {
  return /** @type{!Array<!proto.fox.proto.Vector2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Vector2f, 3));
};


/**
 * @param {!Array<!proto.fox.proto.Vector2f>} value
 * @return {!proto.fox.proto.ValidationErrorLidarPoints} returns this
*/
proto.fox.proto.ValidationErrorLidarPoints.prototype.setFrontPointsMapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.Vector2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Vector2f}
 */
proto.fox.proto.ValidationErrorLidarPoints.prototype.addFrontPointsMap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.Vector2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ValidationErrorLidarPoints} returns this
 */
proto.fox.proto.ValidationErrorLidarPoints.prototype.clearFrontPointsMapList = function() {
  return this.setFrontPointsMapList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.HardwareError.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.HardwareError.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.HardwareError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.HardwareError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HardwareError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subcodeList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.HardwareError}
 */
proto.fox.proto.HardwareError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.HardwareError;
  return proto.fox.proto.HardwareError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.HardwareError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.HardwareError}
 */
proto.fox.proto.HardwareError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.HardwareError.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint64());
      msg.setSubcodeList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.HardwareError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.HardwareError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.HardwareError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HardwareError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSubcodeList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.HardwareError.Code = {
  OK: 0,
  INVALID_CONFIGURATION: 1,
  TRACTION_RIGHT_CROSSCHECK: 2,
  TRACTION_LEFT_CROSSCHECK: 3,
  INTERLOCK_CROSSCHECK: 4,
  STEERING_CROSSCHECK: 5,
  KINEMATICS_INCONSISTENCY: 6,
  DEPRECATED_FOLLOWING: 7,
  TRACTION_LEFT_ENCODER_FAULT: 8,
  TRACTION_RIGHT_ENCODER_FAULT: 9,
  STEERING_ENCODER_FAULT: 10,
  CAN_UNREADY: 11,
  STEER_HOMING: 12,
  TILT_LIMIT_CROSSCHECK: 13,
  TRACTION_LEFT_FOLLOWING: 14,
  TRACTION_RIGHT_FOLLOWING: 15,
  STEERING_FOLLOWING: 16,
  FW_PACKAGE_MISMATCH: 17,
  MAST_SENSOR_OUT_OF_RANGE: 18,
  MAST_DRAW_WIRE_CROSSCHECK: 19,
  MAST_CONTROLLER_CAN_IFACE_ERROR: 20,
  MAST_CONTROLLER_CONFIG_BAD: 21,
  MAST_COIL_DRIVER_6_FAULT: 22,
  MAST_COIL_DRIVER_7_FAULT: 23,
  MAST_CONTROLLER_OVERVOLTAGE: 24,
  MAST_CONTROLLER_OVERTEMP: 25,
  MAST_CONTROLLER_UNDERVOLTAGE: 26,
  MAST_CONTROLLER_UNDERTEMP: 27,
  MAST_CONTROLLER_NV_FAILURE: 28,
  MAST_CONTROLLER_DRAW_WIRE_CROSSCHECK_WARNING: 29,
  STEERING_NOT_CALIBRATED: 30,
  ESTOP_WIRING_FAULT: 31,
  SIDE_SHIFT_OVERCURRENT: 32,
  TRANSIENT_CROSSCHECK_FAULT: 33,
  TRANSIENT_KINEMATICS_FAULT: 34,
  IMU_TILT_LIMIT_CROSSCHECK: 35,
  FRONT_LIDAR_CONTAMINATED: 512,
  LEFT_LIDAR_CONTAMINATED: 513,
  RIGHT_LIDAR_CONTAMINATED: 514,
  UNKNOWN: 999
};

/**
 * optional Code code = 1;
 * @return {!proto.fox.proto.HardwareError.Code}
 */
proto.fox.proto.HardwareError.prototype.getCode = function() {
  return /** @type {!proto.fox.proto.HardwareError.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.HardwareError.Code} value
 * @return {!proto.fox.proto.HardwareError} returns this
 */
proto.fox.proto.HardwareError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated uint64 subcode = 2;
 * @return {!Array<number>}
 */
proto.fox.proto.HardwareError.prototype.getSubcodeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.HardwareError} returns this
 */
proto.fox.proto.HardwareError.prototype.setSubcodeList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.HardwareError} returns this
 */
proto.fox.proto.HardwareError.prototype.addSubcode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.HardwareError} returns this
 */
proto.fox.proto.HardwareError.prototype.clearSubcodeList = function() {
  return this.setSubcodeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.DeviceError.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.DeviceError.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.DeviceError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.DeviceError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DeviceError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subcodeList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.DeviceError}
 */
proto.fox.proto.DeviceError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.DeviceError;
  return proto.fox.proto.DeviceError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.DeviceError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.DeviceError}
 */
proto.fox.proto.DeviceError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.DeviceError.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {!Array<!proto.fox.proto.DeviceError.SubCode>} */ (reader.readPackedEnum());
      msg.setSubcodeList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.DeviceError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.DeviceError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.DeviceError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DeviceError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSubcodeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.DeviceError.Code = {
  NONE: 0,
  PLC_CAN_ERROR: 1,
  STEERING_CAN_ERROR: 3,
  TRACTION_LEFT_CAN_ERROR: 4,
  TRACTION_RIGHT_CAN_ERROR: 5,
  MAST_CONTROLLER_CAN_ERROR: 6,
  PUMP_CAN_ERROR: 7,
  LIFT_WIRE_CAN_ERROR: 8,
  SIDE_SHIFT_CAN_ERROR: 19
};

/**
 * @enum {number}
 */
proto.fox.proto.DeviceError.SubCode = {
  MAIN_CONTACTOR_WELDED: 0,
  MAIN_CONTACTOR_OPEN: 1,
  POT_LOW_OVERCURRENT: 2,
  THROTTLE_WIPER_LOW: 3,
  THROTTLE_WIPER_HIGHT: 4,
  POT2_WIPER_LOW: 5,
  POT2_WIPER_HIGHT: 6,
  EEPROM_FAILURE: 7,
  HPD_SEQUENCING_FAULT: 10,
  BPLUS_UNDERVOLTAGE: 11,
  BPLUS_OVERVOLTAGE: 12,
  BPLUS_OVERVOLTAGE_CUTBACK: 13,
  BPLUS_UNDERVOLTAGE_CUTBACK: 14,
  SIN_COS_SENSOR_FAULT: 15,
  CONTROLLER_OVERTEMP_CUTBACK: 16,
  CONTROLLER_UNDERTEMP: 17,
  CONTROLLER_OVERTEMP: 20,
  COIL1_DRIVER_OPEN_SHORT: 21,
  COIL2_DRIVER_OPEN_SHORT: 22,
  COIL3_DRIVER_OPEN_SHORT: 23,
  COIL4_DRIVER_OPEN_SHORT: 24,
  PD_OPEN_SHORT: 25,
  MAIN_OPEN_SHORT: 26,
  EM_BRAKE_OPEN_SHORT: 27,
  PRECHARGE_FAILED: 30,
  DIGITAL6_OUT_OPEN_SHORT: 31,
  DIGITAL7_OUT_OPEN_SHORT: 32,
  CONTROLLER_OVERCURRENT: 33,
  CURRENT_SENSOR_FAULT: 34,
  MOTOR_TEMP_CUTBACK: 35,
  PARAMETER_CHANGE_FAULT: 36,
  MOTOR_OPEN: 37,
  EXTERNAL_SUPPLY_OUT_OF_RANGE: 40,
  MOTOR_TEMP_SENSOR_FAULT: 41,
  VCL_RUNTIME_ERROR: 42,
  FIVEV_SUPPLY_FAILURE: 43,
  OS_GENERAL_ERROR: 44,
  PDO_TIMEOUT: 45,
  ENCODER_FAULT: 46,
  STALL_DETECTED: 47,
  BAD_CALIBRATIONS: 50,
  EMER_REV_HPD: 52,
  MOTOR_TYPE_FAULT: 54,
  SUPERVISOR_FAULT: 55,
  VCL_OS_MISMATCH: 61,
  EM_BRAKE_FAILED_TO_SET: 62,
  ENCODER_LIMITED_OPERATION: 63,
  DUAL_SEVERE_FAULT: 65,
  FAULT_ON_OTHER_TRACTION: 66,
  ILLEGAL_MODEL_NUMBER: 67,
  PARAMETER_MISMATCH: 73,
  KSI_UNDERVOLTAGE: 74,
  KSI_OVERVOLTAGE: 75,
  INSULATION_RESISTANCE_LOW: 76,
  ENCODER_PULSE_COUNT_FAULT: 77,
  SUPERVISOR_INCOMPATIBLE: 80,
  DRIVER_SUPPLY: 86,
  UNKNOWN: 100,
  PASSIVE_BUS: 513,
  RECOVERED_FROM_OFF_BUS: 514,
  POSITION_ERROR: 515,
  VELOCITY_ERROR: 516,
  LIMIT_SPEED_LOW: 517,
  LIMIT_SPEED_HIGH: 518,
  POSITION_ERROR_AREA1: 519,
  POSITION_ERROR_AREA2: 520
};

/**
 * optional Code code = 1;
 * @return {!proto.fox.proto.DeviceError.Code}
 */
proto.fox.proto.DeviceError.prototype.getCode = function() {
  return /** @type {!proto.fox.proto.DeviceError.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.DeviceError.Code} value
 * @return {!proto.fox.proto.DeviceError} returns this
 */
proto.fox.proto.DeviceError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated SubCode subcode = 2;
 * @return {!Array<!proto.fox.proto.DeviceError.SubCode>}
 */
proto.fox.proto.DeviceError.prototype.getSubcodeList = function() {
  return /** @type {!Array<!proto.fox.proto.DeviceError.SubCode>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.fox.proto.DeviceError.SubCode>} value
 * @return {!proto.fox.proto.DeviceError} returns this
 */
proto.fox.proto.DeviceError.prototype.setSubcodeList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.fox.proto.DeviceError.SubCode} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.DeviceError} returns this
 */
proto.fox.proto.DeviceError.prototype.addSubcode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.DeviceError} returns this
 */
proto.fox.proto.DeviceError.prototype.clearSubcodeList = function() {
  return this.setSubcodeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.CarriedObstacleDetection.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.CarriedObstacleDetection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.CarriedObstacleDetection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CarriedObstacleDetection.toObject = function(includeInstance, msg) {
  var f, obj = {
    obstacleInPallet: (f = msg.getObstacleInPallet()) && geometry_proto_transform_pb.Vector2d.toObject(includeInstance, f),
    obstacleInScanner: (f = msg.getObstacleInScanner()) && geometry_proto_transform_pb.Polar2f.toObject(includeInstance, f),
    count: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    index: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.CarriedObstacleDetection}
 */
proto.fox.proto.CarriedObstacleDetection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.CarriedObstacleDetection;
  return proto.fox.proto.CarriedObstacleDetection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.CarriedObstacleDetection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.CarriedObstacleDetection}
 */
proto.fox.proto.CarriedObstacleDetection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Vector2d;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2d.deserializeBinaryFromReader);
      msg.setObstacleInPallet(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Polar2f;
      reader.readMessage(value,geometry_proto_transform_pb.Polar2f.deserializeBinaryFromReader);
      msg.setObstacleInScanner(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.CarriedObstacleDetection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.CarriedObstacleDetection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.CarriedObstacleDetection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CarriedObstacleDetection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObstacleInPallet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Vector2d.serializeBinaryToWriter
    );
  }
  f = message.getObstacleInScanner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Polar2f.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Vector2d obstacle_in_pallet = 1;
 * @return {?proto.fox.proto.Vector2d}
 */
proto.fox.proto.CarriedObstacleDetection.prototype.getObstacleInPallet = function() {
  return /** @type{?proto.fox.proto.Vector2d} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector2d, 1));
};


/**
 * @param {?proto.fox.proto.Vector2d|undefined} value
 * @return {!proto.fox.proto.CarriedObstacleDetection} returns this
*/
proto.fox.proto.CarriedObstacleDetection.prototype.setObstacleInPallet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.CarriedObstacleDetection} returns this
 */
proto.fox.proto.CarriedObstacleDetection.prototype.clearObstacleInPallet = function() {
  return this.setObstacleInPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.CarriedObstacleDetection.prototype.hasObstacleInPallet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Polar2f obstacle_in_scanner = 2;
 * @return {?proto.fox.proto.Polar2f}
 */
proto.fox.proto.CarriedObstacleDetection.prototype.getObstacleInScanner = function() {
  return /** @type{?proto.fox.proto.Polar2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Polar2f, 2));
};


/**
 * @param {?proto.fox.proto.Polar2f|undefined} value
 * @return {!proto.fox.proto.CarriedObstacleDetection} returns this
*/
proto.fox.proto.CarriedObstacleDetection.prototype.setObstacleInScanner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.CarriedObstacleDetection} returns this
 */
proto.fox.proto.CarriedObstacleDetection.prototype.clearObstacleInScanner = function() {
  return this.setObstacleInScanner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.CarriedObstacleDetection.prototype.hasObstacleInScanner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double count = 3;
 * @return {number}
 */
proto.fox.proto.CarriedObstacleDetection.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CarriedObstacleDetection} returns this
 */
proto.fox.proto.CarriedObstacleDetection.prototype.setCount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 index = 4;
 * @return {number}
 */
proto.fox.proto.CarriedObstacleDetection.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CarriedObstacleDetection} returns this
 */
proto.fox.proto.CarriedObstacleDetection.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.CarriedObstacleHypothesis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.CarriedObstacleHypothesis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CarriedObstacleHypothesis.toObject = function(includeInstance, msg) {
  var f, obj = {
    tracked: (f = msg.getTracked()) && proto.fox.proto.CarriedObstacleDetection.toObject(includeInstance, f),
    lastDetection: (f = msg.getLastDetection()) && proto.fox.proto.CarriedObstacleDetection.toObject(includeInstance, f),
    weight: jspb.Message.getFieldWithDefault(msg, 3, 0),
    upperLiftPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    maskSizeRestricted: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.CarriedObstacleHypothesis}
 */
proto.fox.proto.CarriedObstacleHypothesis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.CarriedObstacleHypothesis;
  return proto.fox.proto.CarriedObstacleHypothesis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.CarriedObstacleHypothesis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.CarriedObstacleHypothesis}
 */
proto.fox.proto.CarriedObstacleHypothesis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.CarriedObstacleDetection;
      reader.readMessage(value,proto.fox.proto.CarriedObstacleDetection.deserializeBinaryFromReader);
      msg.setTracked(value);
      break;
    case 2:
      var value = new proto.fox.proto.CarriedObstacleDetection;
      reader.readMessage(value,proto.fox.proto.CarriedObstacleDetection.deserializeBinaryFromReader);
      msg.setLastDetection(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeight(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpperLiftPosition(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMaskSizeRestricted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.CarriedObstacleHypothesis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.CarriedObstacleHypothesis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CarriedObstacleHypothesis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTracked();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.CarriedObstacleDetection.serializeBinaryToWriter
    );
  }
  f = message.getLastDetection();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.CarriedObstacleDetection.serializeBinaryToWriter
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUpperLiftPosition();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMaskSizeRestricted();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional CarriedObstacleDetection tracked = 1;
 * @return {?proto.fox.proto.CarriedObstacleDetection}
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.getTracked = function() {
  return /** @type{?proto.fox.proto.CarriedObstacleDetection} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.CarriedObstacleDetection, 1));
};


/**
 * @param {?proto.fox.proto.CarriedObstacleDetection|undefined} value
 * @return {!proto.fox.proto.CarriedObstacleHypothesis} returns this
*/
proto.fox.proto.CarriedObstacleHypothesis.prototype.setTracked = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.CarriedObstacleHypothesis} returns this
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.clearTracked = function() {
  return this.setTracked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.hasTracked = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CarriedObstacleDetection last_detection = 2;
 * @return {?proto.fox.proto.CarriedObstacleDetection}
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.getLastDetection = function() {
  return /** @type{?proto.fox.proto.CarriedObstacleDetection} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.CarriedObstacleDetection, 2));
};


/**
 * @param {?proto.fox.proto.CarriedObstacleDetection|undefined} value
 * @return {!proto.fox.proto.CarriedObstacleHypothesis} returns this
*/
proto.fox.proto.CarriedObstacleHypothesis.prototype.setLastDetection = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.CarriedObstacleHypothesis} returns this
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.clearLastDetection = function() {
  return this.setLastDetection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.hasLastDetection = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 weight = 3;
 * @return {number}
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CarriedObstacleHypothesis} returns this
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double upper_lift_position = 4;
 * @return {number}
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.getUpperLiftPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CarriedObstacleHypothesis} returns this
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.setUpperLiftPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool mask_size_restricted = 5;
 * @return {boolean}
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.getMaskSizeRestricted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.CarriedObstacleHypothesis} returns this
 */
proto.fox.proto.CarriedObstacleHypothesis.prototype.setMaskSizeRestricted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.CarriedObstacleBehaviorState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.CarriedObstacleBehaviorState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.CarriedObstacleBehaviorState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CarriedObstacleBehaviorState.toObject = function(includeInstance, msg) {
  var f, obj = {
    mode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    palletId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.CarriedObstacleBehaviorState}
 */
proto.fox.proto.CarriedObstacleBehaviorState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.CarriedObstacleBehaviorState;
  return proto.fox.proto.CarriedObstacleBehaviorState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.CarriedObstacleBehaviorState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.CarriedObstacleBehaviorState}
 */
proto.fox.proto.CarriedObstacleBehaviorState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.CarriedObstacleBehaviorMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPalletId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.CarriedObstacleBehaviorState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.CarriedObstacleBehaviorState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.CarriedObstacleBehaviorState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CarriedObstacleBehaviorState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPalletId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional CarriedObstacleBehaviorMode mode = 1;
 * @return {!proto.fox.proto.CarriedObstacleBehaviorMode}
 */
proto.fox.proto.CarriedObstacleBehaviorState.prototype.getMode = function() {
  return /** @type {!proto.fox.proto.CarriedObstacleBehaviorMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.CarriedObstacleBehaviorMode} value
 * @return {!proto.fox.proto.CarriedObstacleBehaviorState} returns this
 */
proto.fox.proto.CarriedObstacleBehaviorState.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 pallet_id = 2;
 * @return {number}
 */
proto.fox.proto.CarriedObstacleBehaviorState.prototype.getPalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CarriedObstacleBehaviorState} returns this
 */
proto.fox.proto.CarriedObstacleBehaviorState.prototype.setPalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.CarriedObstacleDetectorState.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.CarriedObstacleDetectorState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.CarriedObstacleDetectorState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.CarriedObstacleDetectorState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CarriedObstacleDetectorState.toObject = function(includeInstance, msg) {
  var f, obj = {
    hypothesesList: jspb.Message.toObjectList(msg.getHypothesesList(),
    proto.fox.proto.CarriedObstacleHypothesis.toObject, includeInstance),
    behaviorState: (f = msg.getBehaviorState()) && proto.fox.proto.CarriedObstacleBehaviorState.toObject(includeInstance, f),
    carriedObstacleDetectionStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.CarriedObstacleDetectorState}
 */
proto.fox.proto.CarriedObstacleDetectorState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.CarriedObstacleDetectorState;
  return proto.fox.proto.CarriedObstacleDetectorState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.CarriedObstacleDetectorState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.CarriedObstacleDetectorState}
 */
proto.fox.proto.CarriedObstacleDetectorState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.CarriedObstacleHypothesis;
      reader.readMessage(value,proto.fox.proto.CarriedObstacleHypothesis.deserializeBinaryFromReader);
      msg.addHypotheses(value);
      break;
    case 2:
      var value = new proto.fox.proto.CarriedObstacleBehaviorState;
      reader.readMessage(value,proto.fox.proto.CarriedObstacleBehaviorState.deserializeBinaryFromReader);
      msg.setBehaviorState(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.CarriedObstacleDetectorState.CarriedObstacleDetectionStatus} */ (reader.readEnum());
      msg.setCarriedObstacleDetectionStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.CarriedObstacleDetectorState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.CarriedObstacleDetectorState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.CarriedObstacleDetectorState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CarriedObstacleDetectorState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHypothesesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.CarriedObstacleHypothesis.serializeBinaryToWriter
    );
  }
  f = message.getBehaviorState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.CarriedObstacleBehaviorState.serializeBinaryToWriter
    );
  }
  f = message.getCarriedObstacleDetectionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.CarriedObstacleDetectorState.CarriedObstacleDetectionStatus = {
  NOT_SET: 0,
  NOT_DETECTED: 1,
  CLEARED: 2,
  DETECTED_TRUSTED: 3,
  DETECTED_NOT_TRUSTED: 4,
  CLEARED_TRIPPED: 5,
  DETECTED_TOO_LARGE: 6,
  CLEARED_NOT_CONFIDENT: 7
};

/**
 * repeated CarriedObstacleHypothesis hypotheses = 1;
 * @return {!Array<!proto.fox.proto.CarriedObstacleHypothesis>}
 */
proto.fox.proto.CarriedObstacleDetectorState.prototype.getHypothesesList = function() {
  return /** @type{!Array<!proto.fox.proto.CarriedObstacleHypothesis>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.CarriedObstacleHypothesis, 1));
};


/**
 * @param {!Array<!proto.fox.proto.CarriedObstacleHypothesis>} value
 * @return {!proto.fox.proto.CarriedObstacleDetectorState} returns this
*/
proto.fox.proto.CarriedObstacleDetectorState.prototype.setHypothesesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.CarriedObstacleHypothesis=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.CarriedObstacleHypothesis}
 */
proto.fox.proto.CarriedObstacleDetectorState.prototype.addHypotheses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.CarriedObstacleHypothesis, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.CarriedObstacleDetectorState} returns this
 */
proto.fox.proto.CarriedObstacleDetectorState.prototype.clearHypothesesList = function() {
  return this.setHypothesesList([]);
};


/**
 * optional CarriedObstacleBehaviorState behavior_state = 2;
 * @return {?proto.fox.proto.CarriedObstacleBehaviorState}
 */
proto.fox.proto.CarriedObstacleDetectorState.prototype.getBehaviorState = function() {
  return /** @type{?proto.fox.proto.CarriedObstacleBehaviorState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.CarriedObstacleBehaviorState, 2));
};


/**
 * @param {?proto.fox.proto.CarriedObstacleBehaviorState|undefined} value
 * @return {!proto.fox.proto.CarriedObstacleDetectorState} returns this
*/
proto.fox.proto.CarriedObstacleDetectorState.prototype.setBehaviorState = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.CarriedObstacleDetectorState} returns this
 */
proto.fox.proto.CarriedObstacleDetectorState.prototype.clearBehaviorState = function() {
  return this.setBehaviorState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.CarriedObstacleDetectorState.prototype.hasBehaviorState = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CarriedObstacleDetectionStatus carried_obstacle_detection_status = 3;
 * @return {!proto.fox.proto.CarriedObstacleDetectorState.CarriedObstacleDetectionStatus}
 */
proto.fox.proto.CarriedObstacleDetectorState.prototype.getCarriedObstacleDetectionStatus = function() {
  return /** @type {!proto.fox.proto.CarriedObstacleDetectorState.CarriedObstacleDetectionStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.CarriedObstacleDetectorState.CarriedObstacleDetectionStatus} value
 * @return {!proto.fox.proto.CarriedObstacleDetectorState} returns this
 */
proto.fox.proto.CarriedObstacleDetectorState.prototype.setCarriedObstacleDetectionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ThresholdState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ThresholdState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ThresholdState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ThresholdState.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusMap: (f = msg.getStatusMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ThresholdState}
 */
proto.fox.proto.ThresholdState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ThresholdState;
  return proto.fox.proto.ThresholdState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ThresholdState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ThresholdState}
 */
proto.fox.proto.ThresholdState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getStatusMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readEnum, null, 0, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ThresholdState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ThresholdState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ThresholdState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ThresholdState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeEnum);
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.ThresholdState.Status = {
  NONE: 0,
  APPROACHING: 1,
  INTERSECTING: 2
};

/**
 * map<int32, Status> status = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.fox.proto.ThresholdState.Status>}
 */
proto.fox.proto.ThresholdState.prototype.getStatusMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.fox.proto.ThresholdState.Status>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.fox.proto.ThresholdState} returns this
 */
proto.fox.proto.ThresholdState.prototype.clearStatusMap = function() {
  this.getStatusMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.MeasureRampAngleState.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.MeasureRampAngleState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.MeasureRampAngleState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.MeasureRampAngleState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MeasureRampAngleState.toObject = function(includeInstance, msg) {
  var f, obj = {
    truckIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.MeasureRampAngleState}
 */
proto.fox.proto.MeasureRampAngleState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.MeasureRampAngleState;
  return proto.fox.proto.MeasureRampAngleState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.MeasureRampAngleState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.MeasureRampAngleState}
 */
proto.fox.proto.MeasureRampAngleState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setTruckIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.MeasureRampAngleState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.MeasureRampAngleState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.MeasureRampAngleState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MeasureRampAngleState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTruckIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 truck_ids = 1;
 * @return {!Array<number>}
 */
proto.fox.proto.MeasureRampAngleState.prototype.getTruckIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.MeasureRampAngleState} returns this
 */
proto.fox.proto.MeasureRampAngleState.prototype.setTruckIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.MeasureRampAngleState} returns this
 */
proto.fox.proto.MeasureRampAngleState.prototype.addTruckIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.MeasureRampAngleState} returns this
 */
proto.fox.proto.MeasureRampAngleState.prototype.clearTruckIdsList = function() {
  return this.setTruckIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ValidationState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ValidationState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ValidationState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ValidationState.toObject = function(includeInstance, msg) {
  var f, obj = {
    motionValidationState: (f = msg.getMotionValidationState()) && proto.fox.proto.MotionValidationState.toObject(includeInstance, f),
    safetyOffZoneState: (f = msg.getSafetyOffZoneState()) && proto.fox.proto.PlaceLocationSafetyOffZoneState.toObject(includeInstance, f),
    actionValidationState: (f = msg.getActionValidationState()) && proto.fox.proto.ActionValidationState.toObject(includeInstance, f),
    validationError: (f = msg.getValidationError()) && proto.fox.proto.ValidationError.toObject(includeInstance, f),
    thresholdState: (f = msg.getThresholdState()) && proto.fox.proto.ThresholdState.toObject(includeInstance, f),
    activeSpeedConstraint: jspb.Message.getFieldWithDefault(msg, 6, 0),
    activeAccelerationConstraint: jspb.Message.getFieldWithDefault(msg, 7, 0),
    carriedObstacleState: (f = msg.getCarriedObstacleState()) && proto.fox.proto.CarriedObstacleDetectorState.toObject(includeInstance, f),
    measureRampAngleState: (f = msg.getMeasureRampAngleState()) && proto.fox.proto.MeasureRampAngleState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ValidationState}
 */
proto.fox.proto.ValidationState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ValidationState;
  return proto.fox.proto.ValidationState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ValidationState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ValidationState}
 */
proto.fox.proto.ValidationState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.MotionValidationState;
      reader.readMessage(value,proto.fox.proto.MotionValidationState.deserializeBinaryFromReader);
      msg.setMotionValidationState(value);
      break;
    case 2:
      var value = new proto.fox.proto.PlaceLocationSafetyOffZoneState;
      reader.readMessage(value,proto.fox.proto.PlaceLocationSafetyOffZoneState.deserializeBinaryFromReader);
      msg.setSafetyOffZoneState(value);
      break;
    case 3:
      var value = new proto.fox.proto.ActionValidationState;
      reader.readMessage(value,proto.fox.proto.ActionValidationState.deserializeBinaryFromReader);
      msg.setActionValidationState(value);
      break;
    case 4:
      var value = new proto.fox.proto.ValidationError;
      reader.readMessage(value,proto.fox.proto.ValidationError.deserializeBinaryFromReader);
      msg.setValidationError(value);
      break;
    case 5:
      var value = new proto.fox.proto.ThresholdState;
      reader.readMessage(value,proto.fox.proto.ThresholdState.deserializeBinaryFromReader);
      msg.setThresholdState(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActiveSpeedConstraint(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActiveAccelerationConstraint(value);
      break;
    case 8:
      var value = new proto.fox.proto.CarriedObstacleDetectorState;
      reader.readMessage(value,proto.fox.proto.CarriedObstacleDetectorState.deserializeBinaryFromReader);
      msg.setCarriedObstacleState(value);
      break;
    case 9:
      var value = new proto.fox.proto.MeasureRampAngleState;
      reader.readMessage(value,proto.fox.proto.MeasureRampAngleState.deserializeBinaryFromReader);
      msg.setMeasureRampAngleState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ValidationState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ValidationState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ValidationState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ValidationState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMotionValidationState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.MotionValidationState.serializeBinaryToWriter
    );
  }
  f = message.getSafetyOffZoneState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.PlaceLocationSafetyOffZoneState.serializeBinaryToWriter
    );
  }
  f = message.getActionValidationState();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.ActionValidationState.serializeBinaryToWriter
    );
  }
  f = message.getValidationError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.ValidationError.serializeBinaryToWriter
    );
  }
  f = message.getThresholdState();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.fox.proto.ThresholdState.serializeBinaryToWriter
    );
  }
  f = message.getActiveSpeedConstraint();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getActiveAccelerationConstraint();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCarriedObstacleState();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.fox.proto.CarriedObstacleDetectorState.serializeBinaryToWriter
    );
  }
  f = message.getMeasureRampAngleState();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.fox.proto.MeasureRampAngleState.serializeBinaryToWriter
    );
  }
};


/**
 * optional MotionValidationState motion_validation_state = 1;
 * @return {?proto.fox.proto.MotionValidationState}
 */
proto.fox.proto.ValidationState.prototype.getMotionValidationState = function() {
  return /** @type{?proto.fox.proto.MotionValidationState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MotionValidationState, 1));
};


/**
 * @param {?proto.fox.proto.MotionValidationState|undefined} value
 * @return {!proto.fox.proto.ValidationState} returns this
*/
proto.fox.proto.ValidationState.prototype.setMotionValidationState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ValidationState} returns this
 */
proto.fox.proto.ValidationState.prototype.clearMotionValidationState = function() {
  return this.setMotionValidationState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ValidationState.prototype.hasMotionValidationState = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PlaceLocationSafetyOffZoneState safety_off_zone_state = 2;
 * @return {?proto.fox.proto.PlaceLocationSafetyOffZoneState}
 */
proto.fox.proto.ValidationState.prototype.getSafetyOffZoneState = function() {
  return /** @type{?proto.fox.proto.PlaceLocationSafetyOffZoneState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlaceLocationSafetyOffZoneState, 2));
};


/**
 * @param {?proto.fox.proto.PlaceLocationSafetyOffZoneState|undefined} value
 * @return {!proto.fox.proto.ValidationState} returns this
*/
proto.fox.proto.ValidationState.prototype.setSafetyOffZoneState = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ValidationState} returns this
 */
proto.fox.proto.ValidationState.prototype.clearSafetyOffZoneState = function() {
  return this.setSafetyOffZoneState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ValidationState.prototype.hasSafetyOffZoneState = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ActionValidationState action_validation_state = 3;
 * @return {?proto.fox.proto.ActionValidationState}
 */
proto.fox.proto.ValidationState.prototype.getActionValidationState = function() {
  return /** @type{?proto.fox.proto.ActionValidationState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ActionValidationState, 3));
};


/**
 * @param {?proto.fox.proto.ActionValidationState|undefined} value
 * @return {!proto.fox.proto.ValidationState} returns this
*/
proto.fox.proto.ValidationState.prototype.setActionValidationState = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ValidationState} returns this
 */
proto.fox.proto.ValidationState.prototype.clearActionValidationState = function() {
  return this.setActionValidationState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ValidationState.prototype.hasActionValidationState = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ValidationError validation_error = 4;
 * @return {?proto.fox.proto.ValidationError}
 */
proto.fox.proto.ValidationState.prototype.getValidationError = function() {
  return /** @type{?proto.fox.proto.ValidationError} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ValidationError, 4));
};


/**
 * @param {?proto.fox.proto.ValidationError|undefined} value
 * @return {!proto.fox.proto.ValidationState} returns this
*/
proto.fox.proto.ValidationState.prototype.setValidationError = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ValidationState} returns this
 */
proto.fox.proto.ValidationState.prototype.clearValidationError = function() {
  return this.setValidationError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ValidationState.prototype.hasValidationError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ThresholdState threshold_state = 5;
 * @return {?proto.fox.proto.ThresholdState}
 */
proto.fox.proto.ValidationState.prototype.getThresholdState = function() {
  return /** @type{?proto.fox.proto.ThresholdState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ThresholdState, 5));
};


/**
 * @param {?proto.fox.proto.ThresholdState|undefined} value
 * @return {!proto.fox.proto.ValidationState} returns this
*/
proto.fox.proto.ValidationState.prototype.setThresholdState = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ValidationState} returns this
 */
proto.fox.proto.ValidationState.prototype.clearThresholdState = function() {
  return this.setThresholdState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ValidationState.prototype.hasThresholdState = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 active_speed_constraint = 6;
 * @return {number}
 */
proto.fox.proto.ValidationState.prototype.getActiveSpeedConstraint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ValidationState} returns this
 */
proto.fox.proto.ValidationState.prototype.setActiveSpeedConstraint = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 active_acceleration_constraint = 7;
 * @return {number}
 */
proto.fox.proto.ValidationState.prototype.getActiveAccelerationConstraint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ValidationState} returns this
 */
proto.fox.proto.ValidationState.prototype.setActiveAccelerationConstraint = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional CarriedObstacleDetectorState carried_obstacle_state = 8;
 * @return {?proto.fox.proto.CarriedObstacleDetectorState}
 */
proto.fox.proto.ValidationState.prototype.getCarriedObstacleState = function() {
  return /** @type{?proto.fox.proto.CarriedObstacleDetectorState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.CarriedObstacleDetectorState, 8));
};


/**
 * @param {?proto.fox.proto.CarriedObstacleDetectorState|undefined} value
 * @return {!proto.fox.proto.ValidationState} returns this
*/
proto.fox.proto.ValidationState.prototype.setCarriedObstacleState = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ValidationState} returns this
 */
proto.fox.proto.ValidationState.prototype.clearCarriedObstacleState = function() {
  return this.setCarriedObstacleState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ValidationState.prototype.hasCarriedObstacleState = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional MeasureRampAngleState measure_ramp_angle_state = 9;
 * @return {?proto.fox.proto.MeasureRampAngleState}
 */
proto.fox.proto.ValidationState.prototype.getMeasureRampAngleState = function() {
  return /** @type{?proto.fox.proto.MeasureRampAngleState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MeasureRampAngleState, 9));
};


/**
 * @param {?proto.fox.proto.MeasureRampAngleState|undefined} value
 * @return {!proto.fox.proto.ValidationState} returns this
*/
proto.fox.proto.ValidationState.prototype.setMeasureRampAngleState = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ValidationState} returns this
 */
proto.fox.proto.ValidationState.prototype.clearMeasureRampAngleState = function() {
  return this.setMeasureRampAngleState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ValidationState.prototype.hasMeasureRampAngleState = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ControlsError.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ControlsError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ControlsError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ControlsError.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ControlsError}
 */
proto.fox.proto.ControlsError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ControlsError;
  return proto.fox.proto.ControlsError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ControlsError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ControlsError}
 */
proto.fox.proto.ControlsError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.ControlsError.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ControlsError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ControlsError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ControlsError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ControlsError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.ControlsError.Code = {
  CODE_OK: 0,
  CODE_VEHICLE_TIMEOUT: 1,
  CODE_PATH_TIMEOUT: 2,
  CODE_FAULTED: 3,
  CODE_ESTOP: 4,
  CODE_STALLED: 5,
  CODE_MAST_STALLED: 6,
  CODE_PLC_TIMEOUT: 7,
  CODE_FRONT_SAFETY_SCANNER_ENGAGED: 8,
  CODE_LEFT_SAFETY_SCANNER_ENGAGED: 9,
  CODE_RIGHT_SAFETY_SCANNER_ENGAGED: 10,
  CODE_CROSS_TRACK_ERROR: 11,
  CODE_INTERNAL_ERROR: 12,
  CODE_CABIN_PRESENCE_SENSOR_ENGAGED: 13,
  CODE_PEDAL_DEPRESSED: 14,
  CODE_AUTO_MODE_OFF: 15,
  CODE_UNEXPECTED_MAST_RESTING: 16,
  CODE_LIFT_STALLED: 17,
  CODE_SIDE_SHIFTER_FEEDBACK_CALIBRATION: 18,
  CODE_SIDE_SHIFTER_STALLED: 19,
  CODE_NO_PROGRESS: 20,
  CODE_NO_SYSTEM_ACTIVE_TIMEOUT: 21,
  CODE_FORKS_IN_NEAR_SPACE: 22,
  CODE_NEAR_SPACE_FIELDS_NOT_CLEAR: 23
};

/**
 * optional Code code = 1;
 * @return {!proto.fox.proto.ControlsError.Code}
 */
proto.fox.proto.ControlsError.prototype.getCode = function() {
  return /** @type {!proto.fox.proto.ControlsError.Code} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.ControlsError.Code} value
 * @return {!proto.fox.proto.ControlsError} returns this
 */
proto.fox.proto.ControlsError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SpeedFrame.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SpeedFrame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SpeedFrame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SpeedFrame.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SpeedFrame}
 */
proto.fox.proto.SpeedFrame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SpeedFrame;
  return proto.fox.proto.SpeedFrame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SpeedFrame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SpeedFrame}
 */
proto.fox.proto.SpeedFrame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SpeedFrame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SpeedFrame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SpeedFrame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SpeedFrame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getError();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.fox.proto.SpeedFrame.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.SpeedFrame} returns this
 */
proto.fox.proto.SpeedFrame.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float error = 2;
 * @return {number}
 */
proto.fox.proto.SpeedFrame.prototype.getError = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.SpeedFrame} returns this
 */
proto.fox.proto.SpeedFrame.prototype.setError = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.CommandedSpeed.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.CommandedSpeed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.CommandedSpeed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CommandedSpeed.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.CommandedSpeed}
 */
proto.fox.proto.CommandedSpeed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.CommandedSpeed;
  return proto.fox.proto.CommandedSpeed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.CommandedSpeed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.CommandedSpeed}
 */
proto.fox.proto.CommandedSpeed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.CommandedSpeed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.CommandedSpeed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.CommandedSpeed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CommandedSpeed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.fox.proto.CommandedSpeed.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CommandedSpeed} returns this
 */
proto.fox.proto.CommandedSpeed.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float speed = 2;
 * @return {number}
 */
proto.fox.proto.CommandedSpeed.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.CommandedSpeed} returns this
 */
proto.fox.proto.CommandedSpeed.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.CommandBuffer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.CommandBuffer.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.CommandBuffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.CommandBuffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CommandBuffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    commandsList: jspb.Message.toObjectList(msg.getCommandsList(),
    proto.fox.proto.CommandedSpeed.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.CommandBuffer}
 */
proto.fox.proto.CommandBuffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.CommandBuffer;
  return proto.fox.proto.CommandBuffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.CommandBuffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.CommandBuffer}
 */
proto.fox.proto.CommandBuffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.CommandedSpeed;
      reader.readMessage(value,proto.fox.proto.CommandedSpeed.deserializeBinaryFromReader);
      msg.addCommands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.CommandBuffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.CommandBuffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.CommandBuffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.CommandBuffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.CommandedSpeed.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommandedSpeed commands = 1;
 * @return {!Array<!proto.fox.proto.CommandedSpeed>}
 */
proto.fox.proto.CommandBuffer.prototype.getCommandsList = function() {
  return /** @type{!Array<!proto.fox.proto.CommandedSpeed>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.CommandedSpeed, 1));
};


/**
 * @param {!Array<!proto.fox.proto.CommandedSpeed>} value
 * @return {!proto.fox.proto.CommandBuffer} returns this
*/
proto.fox.proto.CommandBuffer.prototype.setCommandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.CommandedSpeed=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.CommandedSpeed}
 */
proto.fox.proto.CommandBuffer.prototype.addCommands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.CommandedSpeed, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.CommandBuffer} returns this
 */
proto.fox.proto.CommandBuffer.prototype.clearCommandsList = function() {
  return this.setCommandsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.RampSpeedModel.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.RampSpeedModel.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.RampSpeedModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.RampSpeedModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RampSpeedModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    resolution: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    minDepth: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    maxDepth: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    currentIndex: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currentFrame: (f = msg.getCurrentFrame()) && proto.fox.proto.SpeedFrame.toObject(includeInstance, f),
    forkwardFramesList: jspb.Message.toObjectList(msg.getForkwardFramesList(),
    proto.fox.proto.SpeedFrame.toObject, includeInstance),
    steerwardFramesList: jspb.Message.toObjectList(msg.getSteerwardFramesList(),
    proto.fox.proto.SpeedFrame.toObject, includeInstance),
    recentCommands: (f = msg.getRecentCommands()) && proto.fox.proto.CommandBuffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.RampSpeedModel}
 */
proto.fox.proto.RampSpeedModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.RampSpeedModel;
  return proto.fox.proto.RampSpeedModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.RampSpeedModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.RampSpeedModel}
 */
proto.fox.proto.RampSpeedModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setResolution(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinDepth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxDepth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentIndex(value);
      break;
    case 5:
      var value = new proto.fox.proto.SpeedFrame;
      reader.readMessage(value,proto.fox.proto.SpeedFrame.deserializeBinaryFromReader);
      msg.setCurrentFrame(value);
      break;
    case 6:
      var value = new proto.fox.proto.SpeedFrame;
      reader.readMessage(value,proto.fox.proto.SpeedFrame.deserializeBinaryFromReader);
      msg.addForkwardFrames(value);
      break;
    case 7:
      var value = new proto.fox.proto.SpeedFrame;
      reader.readMessage(value,proto.fox.proto.SpeedFrame.deserializeBinaryFromReader);
      msg.addSteerwardFrames(value);
      break;
    case 8:
      var value = new proto.fox.proto.CommandBuffer;
      reader.readMessage(value,proto.fox.proto.CommandBuffer.deserializeBinaryFromReader);
      msg.setRecentCommands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.RampSpeedModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.RampSpeedModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.RampSpeedModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RampSpeedModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResolution();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getMinDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMaxDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getCurrentIndex();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCurrentFrame();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.fox.proto.SpeedFrame.serializeBinaryToWriter
    );
  }
  f = message.getForkwardFramesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.fox.proto.SpeedFrame.serializeBinaryToWriter
    );
  }
  f = message.getSteerwardFramesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.fox.proto.SpeedFrame.serializeBinaryToWriter
    );
  }
  f = message.getRecentCommands();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.fox.proto.CommandBuffer.serializeBinaryToWriter
    );
  }
};


/**
 * optional float resolution = 1;
 * @return {number}
 */
proto.fox.proto.RampSpeedModel.prototype.getResolution = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampSpeedModel} returns this
 */
proto.fox.proto.RampSpeedModel.prototype.setResolution = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float min_depth = 2;
 * @return {number}
 */
proto.fox.proto.RampSpeedModel.prototype.getMinDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampSpeedModel} returns this
 */
proto.fox.proto.RampSpeedModel.prototype.setMinDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float max_depth = 3;
 * @return {number}
 */
proto.fox.proto.RampSpeedModel.prototype.getMaxDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampSpeedModel} returns this
 */
proto.fox.proto.RampSpeedModel.prototype.setMaxDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 current_index = 4;
 * @return {number}
 */
proto.fox.proto.RampSpeedModel.prototype.getCurrentIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampSpeedModel} returns this
 */
proto.fox.proto.RampSpeedModel.prototype.setCurrentIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional SpeedFrame current_frame = 5;
 * @return {?proto.fox.proto.SpeedFrame}
 */
proto.fox.proto.RampSpeedModel.prototype.getCurrentFrame = function() {
  return /** @type{?proto.fox.proto.SpeedFrame} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.SpeedFrame, 5));
};


/**
 * @param {?proto.fox.proto.SpeedFrame|undefined} value
 * @return {!proto.fox.proto.RampSpeedModel} returns this
*/
proto.fox.proto.RampSpeedModel.prototype.setCurrentFrame = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RampSpeedModel} returns this
 */
proto.fox.proto.RampSpeedModel.prototype.clearCurrentFrame = function() {
  return this.setCurrentFrame(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RampSpeedModel.prototype.hasCurrentFrame = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated SpeedFrame forkward_frames = 6;
 * @return {!Array<!proto.fox.proto.SpeedFrame>}
 */
proto.fox.proto.RampSpeedModel.prototype.getForkwardFramesList = function() {
  return /** @type{!Array<!proto.fox.proto.SpeedFrame>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.SpeedFrame, 6));
};


/**
 * @param {!Array<!proto.fox.proto.SpeedFrame>} value
 * @return {!proto.fox.proto.RampSpeedModel} returns this
*/
proto.fox.proto.RampSpeedModel.prototype.setForkwardFramesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.fox.proto.SpeedFrame=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.SpeedFrame}
 */
proto.fox.proto.RampSpeedModel.prototype.addForkwardFrames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.fox.proto.SpeedFrame, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.RampSpeedModel} returns this
 */
proto.fox.proto.RampSpeedModel.prototype.clearForkwardFramesList = function() {
  return this.setForkwardFramesList([]);
};


/**
 * repeated SpeedFrame steerward_frames = 7;
 * @return {!Array<!proto.fox.proto.SpeedFrame>}
 */
proto.fox.proto.RampSpeedModel.prototype.getSteerwardFramesList = function() {
  return /** @type{!Array<!proto.fox.proto.SpeedFrame>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.SpeedFrame, 7));
};


/**
 * @param {!Array<!proto.fox.proto.SpeedFrame>} value
 * @return {!proto.fox.proto.RampSpeedModel} returns this
*/
proto.fox.proto.RampSpeedModel.prototype.setSteerwardFramesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.fox.proto.SpeedFrame=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.SpeedFrame}
 */
proto.fox.proto.RampSpeedModel.prototype.addSteerwardFrames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.fox.proto.SpeedFrame, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.RampSpeedModel} returns this
 */
proto.fox.proto.RampSpeedModel.prototype.clearSteerwardFramesList = function() {
  return this.setSteerwardFramesList([]);
};


/**
 * optional CommandBuffer recent_commands = 8;
 * @return {?proto.fox.proto.CommandBuffer}
 */
proto.fox.proto.RampSpeedModel.prototype.getRecentCommands = function() {
  return /** @type{?proto.fox.proto.CommandBuffer} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.CommandBuffer, 8));
};


/**
 * @param {?proto.fox.proto.CommandBuffer|undefined} value
 * @return {!proto.fox.proto.RampSpeedModel} returns this
*/
proto.fox.proto.RampSpeedModel.prototype.setRecentCommands = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.RampSpeedModel} returns this
 */
proto.fox.proto.RampSpeedModel.prototype.clearRecentCommands = function() {
  return this.setRecentCommands(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.RampSpeedModel.prototype.hasRecentCommands = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.MastHeightTilt.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.MastHeightTilt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.MastHeightTilt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastHeightTilt.toObject = function(includeInstance, msg) {
  var f, obj = {
    mastHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    mastTilt: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.MastHeightTilt}
 */
proto.fox.proto.MastHeightTilt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.MastHeightTilt;
  return proto.fox.proto.MastHeightTilt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.MastHeightTilt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.MastHeightTilt}
 */
proto.fox.proto.MastHeightTilt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMastHeight(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMastTilt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.MastHeightTilt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.MastHeightTilt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.MastHeightTilt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastHeightTilt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMastHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getMastTilt();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float mast_height = 1;
 * @return {number}
 */
proto.fox.proto.MastHeightTilt.prototype.getMastHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastHeightTilt} returns this
 */
proto.fox.proto.MastHeightTilt.prototype.setMastHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float mast_tilt = 2;
 * @return {number}
 */
proto.fox.proto.MastHeightTilt.prototype.getMastTilt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.MastHeightTilt} returns this
 */
proto.fox.proto.MastHeightTilt.prototype.setMastTilt = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.HandGeneratedTailPalletPolicyRow.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.HandGeneratedTailPalletPolicyRow.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.HandGeneratedTailPalletPolicyRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.HandGeneratedTailPalletPolicyRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HandGeneratedTailPalletPolicyRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    mastHeightTiltList: jspb.Message.toObjectList(msg.getMastHeightTiltList(),
    proto.fox.proto.MastHeightTilt.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.HandGeneratedTailPalletPolicyRow}
 */
proto.fox.proto.HandGeneratedTailPalletPolicyRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.HandGeneratedTailPalletPolicyRow;
  return proto.fox.proto.HandGeneratedTailPalletPolicyRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.HandGeneratedTailPalletPolicyRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.HandGeneratedTailPalletPolicyRow}
 */
proto.fox.proto.HandGeneratedTailPalletPolicyRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.MastHeightTilt;
      reader.readMessage(value,proto.fox.proto.MastHeightTilt.deserializeBinaryFromReader);
      msg.addMastHeightTilt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.HandGeneratedTailPalletPolicyRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.HandGeneratedTailPalletPolicyRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.HandGeneratedTailPalletPolicyRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HandGeneratedTailPalletPolicyRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMastHeightTiltList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.MastHeightTilt.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MastHeightTilt mast_height_tilt = 1;
 * @return {!Array<!proto.fox.proto.MastHeightTilt>}
 */
proto.fox.proto.HandGeneratedTailPalletPolicyRow.prototype.getMastHeightTiltList = function() {
  return /** @type{!Array<!proto.fox.proto.MastHeightTilt>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.MastHeightTilt, 1));
};


/**
 * @param {!Array<!proto.fox.proto.MastHeightTilt>} value
 * @return {!proto.fox.proto.HandGeneratedTailPalletPolicyRow} returns this
*/
proto.fox.proto.HandGeneratedTailPalletPolicyRow.prototype.setMastHeightTiltList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.MastHeightTilt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.MastHeightTilt}
 */
proto.fox.proto.HandGeneratedTailPalletPolicyRow.prototype.addMastHeightTilt = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.MastHeightTilt, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.HandGeneratedTailPalletPolicyRow} returns this
 */
proto.fox.proto.HandGeneratedTailPalletPolicyRow.prototype.clearMastHeightTiltList = function() {
  return this.setMastHeightTiltList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.HandGeneratedTailPalletPolicy.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.HandGeneratedTailPalletPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.HandGeneratedTailPalletPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.HandGeneratedTailPalletPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HandGeneratedTailPalletPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyRowList: jspb.Message.toObjectList(msg.getPolicyRowList(),
    proto.fox.proto.HandGeneratedTailPalletPolicyRow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.HandGeneratedTailPalletPolicy}
 */
proto.fox.proto.HandGeneratedTailPalletPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.HandGeneratedTailPalletPolicy;
  return proto.fox.proto.HandGeneratedTailPalletPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.HandGeneratedTailPalletPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.HandGeneratedTailPalletPolicy}
 */
proto.fox.proto.HandGeneratedTailPalletPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.HandGeneratedTailPalletPolicyRow;
      reader.readMessage(value,proto.fox.proto.HandGeneratedTailPalletPolicyRow.deserializeBinaryFromReader);
      msg.addPolicyRow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.HandGeneratedTailPalletPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.HandGeneratedTailPalletPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.HandGeneratedTailPalletPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.HandGeneratedTailPalletPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyRowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.HandGeneratedTailPalletPolicyRow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HandGeneratedTailPalletPolicyRow policy_row = 1;
 * @return {!Array<!proto.fox.proto.HandGeneratedTailPalletPolicyRow>}
 */
proto.fox.proto.HandGeneratedTailPalletPolicy.prototype.getPolicyRowList = function() {
  return /** @type{!Array<!proto.fox.proto.HandGeneratedTailPalletPolicyRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.HandGeneratedTailPalletPolicyRow, 1));
};


/**
 * @param {!Array<!proto.fox.proto.HandGeneratedTailPalletPolicyRow>} value
 * @return {!proto.fox.proto.HandGeneratedTailPalletPolicy} returns this
*/
proto.fox.proto.HandGeneratedTailPalletPolicy.prototype.setPolicyRowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.HandGeneratedTailPalletPolicyRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.HandGeneratedTailPalletPolicyRow}
 */
proto.fox.proto.HandGeneratedTailPalletPolicy.prototype.addPolicyRow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.HandGeneratedTailPalletPolicyRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.HandGeneratedTailPalletPolicy} returns this
 */
proto.fox.proto.HandGeneratedTailPalletPolicy.prototype.clearPolicyRowList = function() {
  return this.setPolicyRowList([]);
};


/**
 * @enum {number}
 */
proto.fox.proto.VisualServoStrategy = {
  NO_SERVO: 0,
  TAIL: 4
};

/**
 * @enum {number}
 */
proto.fox.proto.SafetyScannerMode = {
  ENABLED: 0,
  DISABLED: 1,
  DISABLED_IF_REVERSE: 2
};

/**
 * @enum {number}
 */
proto.fox.proto.SafetyFieldState = {
  VIOLATED: 0,
  FREE: 1
};

/**
 * @enum {number}
 */
proto.fox.proto.SafetyMode = {
  SAFETY_MODE_ON: 0,
  SAFETY_MODE_ALL_OFF: 1,
  SAFETY_MODE_ON_IF_CLEAR: 2,
  SAFETY_MODE_UNSPECIFIED: 3,
  SAFETY_MODE_FORWARD_ONLY: 4
};

/**
 * @enum {number}
 */
proto.fox.proto.MotionType = {
  MOTION_TYPE_UNSPECIFIED: 0,
  MOTION_TYPE_FREE_SPACE: 1,
  MOTION_TYPE_NEAR_SPACE_EXTRACTION: 2,
  MOTION_TYPE_NEAR_SPACE_INSERTION: 3
};

/**
 * @enum {number}
 */
proto.fox.proto.KinematicConstraintType = {
  CONSTRAINT_TYPE_UNKNOWN: 0,
  CONSTRAINT_TYPE_NO_CONSTRAINT: 1,
  CONSTRAINT_TYPE_AGGREGATED: 2,
  CONSTRAINT_TYPE_GRID_COLLISION: 3,
  CONSTRAINT_TYPE_SEMANTIC_COLLISION: 4,
  CONSTRAINT_TYPE_SAFETY_FIELD: 5,
  CONSTRAINT_TYPE_SAFETY_MODE: 6,
  CONSTRAINT_TYPE_SLOW_ZONE: 7,
  CONSTRAINT_TYPE_MAST_POSITION: 8,
  CONSTRAINT_TYPE_LOCALIZATION: 9,
  CONSTRAINT_TYPE_NEAR_SPACE: 10,
  CONSTRAINT_TYPE_PALLET_SAFETY: 11,
  CONSTRAINT_TYPE_UNEXPECTED_TRUCK_OBSTACLE: 12,
  CONSTRAINT_TYPE_FORKWARD: 13,
  CONSTRAINT_TYPE_CARRY: 14,
  CONSTRAINT_TYPE_VERIFIED_TRAILER: 15,
  CONSTRAINT_TYPE_MISSING_TRAILER: 16,
  CONSTRAINT_TYPE_LOW_FORKS: 17,
  CONSTRAINT_TYPE_RAMP_BLOCKED: 18,
  CONSTRAINT_TYPE_RAMP_LIP: 19
};

/**
 * @enum {number}
 */
proto.fox.proto.TravelDirection = {
  INDETERMINATE: 0,
  STEERWARD: 1,
  FORKWARD: -1
};

/**
 * @enum {number}
 */
proto.fox.proto.CarriedObstacleBehaviorMode = {
  INACTIVE: 0,
  LEARNING: 1,
  LEARNED: 2
};

goog.object.extend(exports, proto.fox.proto);
