import React, { Component } from "react";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import {Button, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Table, Box} from "@material-ui/core";
import * as payloads from "../../redux/payloads";
import {
    listForkliftCohortsRequest, listPickStatsDatastoreRequest,
    listPickStatsRequest,
    listRobotAccountsRequest,
} from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import ProgressSpinner from "../Utils/ProgressSpinner";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import moment, { Moment } from "moment-timezone";
import {successRateFromPickStats, speedFromPickStats} from "../ChartTimeSeries/TimeSeriesUtils";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    wrapper: {
      width: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "column"
    },
    card: {
      width: "100%"
    },
    content: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: 0,
      paddingLeft: 0
    },
    green: {
      color: "#008a4c"
    },
    yellow: {
      color: "#dbd400"
    },
    red: {
      color: "#a80600"
    }
  });

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  isLoading: boolean;
  loadingProgress: number;
  bucketsToDisplay: number;
  bucketWidthSeconds: number;
  endDate: Moment;
  stats: Array<m_pb.PickStats.AsObject>;
  robotAccounts: Array<payloads.RobotAccount>;
  cohorts: Array<m_pb.ForkliftCohort.AsObject>;
  robotsToDisplay: Array<string>;
}

interface KPIEntry {
  interventionRate: number;
  palletCount: number;
  palletsPerHour: number;
}

class RobotKPIs extends Component<Props, State> {
  state: State = {
    isLoading: true,
    loadingProgress: 0,
    bucketsToDisplay: 5,
    bucketWidthSeconds: 3600 * 24,
    // TODO(chris): maybe parameterize snapToEndOfDay
    endDate: moment().endOf("day"),
    stats: [],
    robotAccounts: [],
    cohorts: [],
    robotsToDisplay: []
  };

  componentDidMount() {
    this.props
    .dispatch(listForkliftCohortsRequest({ pageToken: 0, pageSize: 500 }))
    .then((payload: { response: m_pb.ListForkliftCohortsResponse }) => {
        let { cohorts: oldCohorts } = this.state;
        const { response } = payload;
        const cohorts = oldCohorts.map(c => c);
        // @ts-ignore
        cohorts.push(...response.toObject().cohortsList);
        this.setState({ cohorts }, () => {
          this.props
          .dispatch(listRobotAccountsRequest(0))
          .then((response: any) => {
            const { endDate, bucketWidthSeconds, bucketsToDisplay } = this.state;
            const eventFilter = new m_pb.EventFilter();
            eventFilter.setEndTime(endDate.unix() * 1e9);
            eventFilter.setStartTime((endDate.unix() * 1e9) - 1e9 * bucketWidthSeconds * bucketsToDisplay);
            // TODO: support robot filter? Maybe just client-side filtering though...
            this.setState({ robotAccounts: response.accounts, robotsToDisplay: response.accounts}, () => {
              this._fetchPickStats(eventFilter);
            });
          });
        });
    });
  }

  _fetchPickStats(filter: m_pb.EventFilter, nextPageToken = "", newFetch = true) {
    this.setState({ isLoading: true, loadingProgress: newFetch ? 0 : this.state.loadingProgress }, () => {
      this.props
        .dispatch(listPickStatsDatastoreRequest(filter, nextPageToken))
        .then((res: m_pb.ListPickStatsResponse.AsObject) => {
          // Append stats to existing state if this is a 2nd (or more) fetch of a particular dataset
          const stats = newFetch ? res.pickStatsList : this.state.stats.concat(res.pickStatsList);
          if (res.nextPageToken) {
            this.setState({
              stats,
              isLoading: true,
              loadingProgress: 100 * stats.length / res.count
            });
            this._fetchPickStats(filter, res.nextPageToken, false);
          } else {
            this.setState({
              stats,
              isLoading: false
            });
          }
        })
        .catch((e: ServiceError) => {
          this.setState({isLoading: false})
        })
    });
  }


  render() {
    const { classes } = this.props;
    const { robotAccounts, stats, bucketsToDisplay, bucketWidthSeconds, endDate, isLoading, loadingProgress } = this.state;
    const tableHeaders = [];
    for (let i = bucketsToDisplay - 1; i >= 0; i--) {
      tableHeaders.push(endDate.clone().subtract("days", i));
    }
    const successRatesByDay = successRateFromPickStats(stats, bucketWidthSeconds * 1e9, endDate.clone().subtract("days", bucketsToDisplay).unix() * 1e9, endDate.unix() * 1e9, "robot");
    const speedByDay = speedFromPickStats(stats, bucketWidthSeconds * 1e9, endDate.clone().subtract("days", bucketsToDisplay).unix() * 1e9, endDate.unix() * 1e9, false, false,"robot");
    const statsByRobot = new Map<string, Array<KPIEntry>>();
    for (let i = successRatesByDay.length - 1; i >= 0 ; i--) {
        const successRatesForDay = successRatesByDay[i];
        const speedForDay = speedByDay[i];
        for (let robot of robotAccounts) {
            const kpiEntryForDay: KPIEntry = {
                palletCount: 0,
                interventionRate: 0,
                palletsPerHour: 0
            };
            let existingStats: Array<KPIEntry> = statsByRobot.get(robot.robotName) || [];
            if (successRatesForDay.countByEntity.has(robot.robotName)) {
                kpiEntryForDay.palletCount = successRatesForDay.countByEntity.get(robot.robotName) || 0;
                kpiEntryForDay.interventionRate = 1 - (successRatesForDay.valueByEntity.get(robot.robotName) || 0);
            }
            if (speedForDay.countByEntity.has(robot.robotName)) {
                // Round to nearest tenth by multiplying PPH by 10, then rounding, then dividing by 10
                kpiEntryForDay.palletsPerHour = Math.round(10 * 3600 * 1e9 / (speedForDay.valueByEntity.get(robot.robotName) || Infinity)) / 10;
            }
            existingStats.push(kpiEntryForDay);
            statsByRobot.set(robot.robotName, existingStats);
        }
    }

    const progressSpinner = isLoading ? <ProgressSpinner/> : null;
    return (
      <div className={classes.wrapper}>
        <Card className={classes.card}>
          <CardHeader title={"Robot KPIs"} />
          <CardContent className={classes.content}>
          {progressSpinner}
          <TableContainer component={Paper}>
            <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Cohort</TableCell>
                    <TableCell>Robot</TableCell>
                    {tableHeaders.map((header: Moment) => {
                       return <TableCell align="right">{header.format("MM/DD/YYYY")}</TableCell>
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading && robotAccounts.sort((robot1, robot2) => robot1.cohortId < robot2.cohortId ? -1 : 1).map((robotAccount) => {
                      if (robotAccount.robotName === "")
                          return null;
                      let cohort = this.state.cohorts.find((cohort) => robotAccount.cohortId === cohort.id);
                      if (!cohort)
                          // || !cohort.active)
                          return null;
                      return <TableRow
                          key={robotAccount.robotName}
                        ><TableCell>{cohort?.displayName}</TableCell>
                          <TableCell>{robotAccount.robotName}</TableCell>
                          {
                              (statsByRobot.get(robotAccount.robotName) || []).map((stat) => {
                                return <TableCell align="right">
                                    <Box>
                                        {!!stat.palletCount && <>
                                            <div>Pallets:{stat.palletCount}</div>
                                            <div className={
                                                stat.interventionRate > .2 ?
                                                this.props.classes.red :
                                                stat.interventionRate > .1 ?
                                                this.props.classes.yellow :
                                                this.props.classes.green}>Int. %:{Math.round(100 * stat.interventionRate)}</div>
                                            <div className={
                                                stat.palletsPerHour > 24 ?
                                                this.props.classes.green :
                                                stat.palletsPerHour > 22 ?
                                                this.props.classes.yellow :
                                                this.props.classes.red}>PPH:{stat.palletsPerHour}</div>
                                        </>}
                                    </Box>
                                </TableCell>;
                            })
                        }</TableRow>;
                      })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default connect()(withStyles(styles)(RobotKPIs));
