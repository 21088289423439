// source: command_control/monitoring/proto/monitoring.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var command_control_proto_command_control_pb = require('../../../command_control/proto/command_control_pb.js');
goog.object.extend(proto, command_control_proto_command_control_pb);
var detection_proto_detection_pb = require('../../../detection/proto/detection_pb.js');
goog.object.extend(proto, detection_proto_detection_pb);
var log_run_description_pb = require('../../../log/run_description_pb.js');
goog.object.extend(proto, log_run_description_pb);
var log_fault_pb = require('../../../log/fault_pb.js');
goog.object.extend(proto, log_fault_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var robot_proto_config_pb = require('../../../robot/proto/config_pb.js');
goog.object.extend(proto, robot_proto_config_pb);
var config_proto_config_pb = require('../../../config/proto/config_pb.js');
goog.object.extend(proto, config_proto_config_pb);
var planner_proto_types_pb = require('../../../planner/proto/types_pb.js');
goog.object.extend(proto, planner_proto_types_pb);
goog.exportSymbol('proto.fox.proto.monitoring.Account', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.Annotation', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ApexStatsUrls', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.AutonomyVersion', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.BulkLogUpload', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.BulkLogUploadRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ConfigSyncError', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ConfigurationFileRevision', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ConfigurationHeaderConflict', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ConfigurationHeaderRevision', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ConfigurationRevisionHistory', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ConfigurationRevisionMetadata', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ConfigurationRevisionMetadata.EditorCase', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ConfigurationRevisionMetadata.ScopeCase', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.CreateAnnotationRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.CreateAutonomyVersionRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.CreateForkliftCohortRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.CreateOperatorQuestionRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.CreateRobotAccountKeyRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.CreateRobotAccountRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.CreateSiteRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.CreateSyncDemandRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.CreateTabletFlagOptionRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.DataLogUrls', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.DatasetType', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.DeleteAnnotationRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.DeleteRunAttributeRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.DockConfig', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.EnvironmentConfigInputs', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.EventFilter', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ExternalBug', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.Fault', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.FaultCodeAnswerChoice', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.FaultCodeQuestionConfiguration', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.FaultConfigurationVersion', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.FaultCount', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.FaultCountBucket', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.FaultCountTimeSeries', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.FaultRateAlertThreshold', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ForkliftCohort', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ForkliftConfigurationFileRevision', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetApexStatsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetForkliftCohortRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetLogUploadInfoRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetRobotAccountRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetRunMetadataRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetRunPlacedPalletsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetScalarMetricRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetTabularDatasetRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetTimeSeriesMetricRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GetViewerRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GoalMetadata', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.InterventionMechanism', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListAutonomyVersionsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListAutonomyVersionsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListBulkLogUploadsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListBulkLogUploadsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.EntityCase', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListExternalBugsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListExternalBugsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListFaultCodesRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListFaultCodesResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListFaultTranslationsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListFaultsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListFaultsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListForkliftCohortsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListForkliftCohortsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListGoalMetadatasRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListGoalMetadatasResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListMonitoringAccountsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListMonitoringAccountsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListOperatorQuestionsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListOperatorQuestionsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListOrganizationsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListOrganizationsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListPickStatsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListPickStatsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListRobotAccountsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListRobotAccountsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListRunMetadatasRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListRunMetadatasResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListRunStatsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListRunStatsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListSitesRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListSitesResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListStoppagesRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListStoppagesResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListSyncDemandsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListSyncDemandsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListTabletFlagOptionsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListTabletFlagOptionsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListTrailerStatsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListTrailerStatsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListTrailersRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ListTrailersResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.LogInRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.LogInResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.LogUploadInfo', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.OperationType', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.OperatorAnswerChoice', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.OperatorQuestion', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.OperatorQuestionTranslation', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.Organization', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ParseEnvironmentConfigRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.PickPlaceFilter', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.PickStats', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.PlacedPalletDetails', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.PlacedPalletIntervention', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.PublishFaultTranslationsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.PublishRobotHeartbeatRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.PublishRobotHeartbeatResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RevisionAcceptancePolicy', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RevisionAcceptanceStatus', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RevisionConflict', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RobotAccount', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RobotAccountKey', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RobotConfigurationRevisionState', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RobotHeartbeat', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RobotStatus', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RobotTabletConfiguration', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RobotTabletConfigurationSummary', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.Row', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RunAttributeFilter', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RunLogSummary', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RunMetadata', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RunPlacedPallets', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RunReview', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RunReviewStatus', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.RunStats', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ScalarMetric', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ScalarMetric.Result', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.ScalarMetricType', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.SetExcludeInterventionRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.SignUpRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.SignUpResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.Site', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.Stoppage', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.SyncDemand', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.TabletFlagOption', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.TabletOptionalFeature', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.TabularDataset', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.TimeBucketValue', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.TimeSeriesMetric', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.TimeSeriesMetricType', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.Trailer', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.TrailerStats', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateAnnotationRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateAutonomyVersionRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateForkliftCohortRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateRobotAccountIpRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateRobotAccountRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateRunAttributeRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.UpsertRunReviewRequest', null, global);
goog.exportSymbol('proto.fox.proto.monitoring.Viewer', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RobotAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.RobotAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RobotAccount.displayName = 'proto.fox.proto.monitoring.RobotAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.CreateForkliftCohortRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.CreateForkliftCohortRequest.displayName = 'proto.fox.proto.monitoring.CreateForkliftCohortRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateForkliftCohortRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateForkliftCohortRequest.displayName = 'proto.fox.proto.monitoring.UpdateForkliftCohortRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ForkliftCohort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ForkliftCohort.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ForkliftCohort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ForkliftCohort.displayName = 'proto.fox.proto.monitoring.ForkliftCohort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListForkliftCohortsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListForkliftCohortsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListForkliftCohortsRequest.displayName = 'proto.fox.proto.monitoring.ListForkliftCohortsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListForkliftCohortsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListForkliftCohortsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListForkliftCohortsResponse.displayName = 'proto.fox.proto.monitoring.ListForkliftCohortsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListRobotAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListRobotAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListRobotAccountsRequest.displayName = 'proto.fox.proto.monitoring.ListRobotAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListRobotAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListRobotAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListRobotAccountsResponse.displayName = 'proto.fox.proto.monitoring.ListRobotAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetRobotAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetRobotAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetRobotAccountRequest.displayName = 'proto.fox.proto.monitoring.GetRobotAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListMonitoringAccountsRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListMonitoringAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListMonitoringAccountsRequest.displayName = 'proto.fox.proto.monitoring.ListMonitoringAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListMonitoringAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListMonitoringAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListMonitoringAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListMonitoringAccountsResponse.displayName = 'proto.fox.proto.monitoring.ListMonitoringAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.CreateRobotAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.CreateRobotAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.CreateRobotAccountRequest.displayName = 'proto.fox.proto.monitoring.CreateRobotAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.CreateRobotAccountKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.CreateRobotAccountKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.displayName = 'proto.fox.proto.monitoring.CreateRobotAccountKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListSyncDemandsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListSyncDemandsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListSyncDemandsRequest.displayName = 'proto.fox.proto.monitoring.ListSyncDemandsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListSyncDemandsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListSyncDemandsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListSyncDemandsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListSyncDemandsResponse.displayName = 'proto.fox.proto.monitoring.ListSyncDemandsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.displayName = 'proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.displayName = 'proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.displayName = 'proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.CreateSyncDemandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.CreateSyncDemandRequest.displayName = 'proto.fox.proto.monitoring.CreateSyncDemandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.displayName = 'proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateRobotAccountIpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateRobotAccountIpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.displayName = 'proto.fox.proto.monitoring.UpdateRobotAccountIpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateRobotAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateRobotAccountRequest.displayName = 'proto.fox.proto.monitoring.UpdateRobotAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.displayName = 'proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.SyncDemand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.SyncDemand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.SyncDemand.displayName = 'proto.fox.proto.monitoring.SyncDemand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RobotAccountKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.RobotAccountKey.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.RobotAccountKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RobotAccountKey.displayName = 'proto.fox.proto.monitoring.RobotAccountKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.DataLogUrls = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.DataLogUrls, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.DataLogUrls.displayName = 'proto.fox.proto.monitoring.DataLogUrls';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RunReview = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.RunReview, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RunReview.displayName = 'proto.fox.proto.monitoring.RunReview';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpsertRunReviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpsertRunReviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpsertRunReviewRequest.displayName = 'proto.fox.proto.monitoring.UpsertRunReviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RunMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.RunMetadata.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.RunMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RunMetadata.displayName = 'proto.fox.proto.monitoring.RunMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GoalMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GoalMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GoalMetadata.displayName = 'proto.fox.proto.monitoring.GoalMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.displayName = 'proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetRunMetadataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetRunMetadataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetRunMetadataRequest.displayName = 'proto.fox.proto.monitoring.GetRunMetadataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetForkliftCohortRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetForkliftCohortRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetForkliftCohortRequest.displayName = 'proto.fox.proto.monitoring.GetForkliftCohortRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListRunMetadatasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListRunMetadatasRequest.displayName = 'proto.fox.proto.monitoring.ListRunMetadatasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListRunMetadatasResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListRunMetadatasResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListRunMetadatasResponse.displayName = 'proto.fox.proto.monitoring.ListRunMetadatasResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListGoalMetadatasRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListGoalMetadatasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListGoalMetadatasRequest.displayName = 'proto.fox.proto.monitoring.ListGoalMetadatasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListGoalMetadatasResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListGoalMetadatasResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListGoalMetadatasResponse.displayName = 'proto.fox.proto.monitoring.ListGoalMetadatasResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetViewerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetViewerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetViewerRequest.displayName = 'proto.fox.proto.monitoring.GetViewerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.Viewer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.Viewer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.Viewer.displayName = 'proto.fox.proto.monitoring.Viewer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetLogUploadInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetLogUploadInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetLogUploadInfoRequest.displayName = 'proto.fox.proto.monitoring.GetLogUploadInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.LogUploadInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.LogUploadInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.LogUploadInfo.displayName = 'proto.fox.proto.monitoring.LogUploadInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.Account.displayName = 'proto.fox.proto.monitoring.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.LogInRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.LogInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.LogInRequest.displayName = 'proto.fox.proto.monitoring.LogInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.LogInResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.LogInResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.LogInResponse.displayName = 'proto.fox.proto.monitoring.LogInResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.SignUpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.SignUpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.SignUpRequest.displayName = 'proto.fox.proto.monitoring.SignUpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.SignUpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.SignUpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.SignUpResponse.displayName = 'proto.fox.proto.monitoring.SignUpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RunAttributeFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.RunAttributeFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RunAttributeFilter.displayName = 'proto.fox.proto.monitoring.RunAttributeFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateRunAttributeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateRunAttributeRequest.displayName = 'proto.fox.proto.monitoring.UpdateRunAttributeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.DeleteRunAttributeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.DeleteRunAttributeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.DeleteRunAttributeRequest.displayName = 'proto.fox.proto.monitoring.DeleteRunAttributeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.EventFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.EventFilter.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.EventFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.EventFilter.displayName = 'proto.fox.proto.monitoring.EventFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.PickPlaceFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.PickPlaceFilter.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.PickPlaceFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.PickPlaceFilter.displayName = 'proto.fox.proto.monitoring.PickPlaceFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.TimeBucketValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.TimeBucketValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.TimeBucketValue.displayName = 'proto.fox.proto.monitoring.TimeBucketValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetTimeSeriesMetricRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.displayName = 'proto.fox.proto.monitoring.GetTimeSeriesMetricRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.TimeSeriesMetric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.TimeSeriesMetric.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.TimeSeriesMetric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.TimeSeriesMetric.displayName = 'proto.fox.proto.monitoring.TimeSeriesMetric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetScalarMetricRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetScalarMetricRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetScalarMetricRequest.displayName = 'proto.fox.proto.monitoring.GetScalarMetricRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ScalarMetric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ScalarMetric.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ScalarMetric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ScalarMetric.displayName = 'proto.fox.proto.monitoring.ScalarMetric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ScalarMetric.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ScalarMetric.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ScalarMetric.Result.displayName = 'proto.fox.proto.monitoring.ScalarMetric.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetRunPlacedPalletsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetRunPlacedPalletsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.displayName = 'proto.fox.proto.monitoring.GetRunPlacedPalletsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.PlacedPalletIntervention = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.PlacedPalletIntervention, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.PlacedPalletIntervention.displayName = 'proto.fox.proto.monitoring.PlacedPalletIntervention';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.PlacedPalletDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.PlacedPalletDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.PlacedPalletDetails.displayName = 'proto.fox.proto.monitoring.PlacedPalletDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RunPlacedPallets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.RunPlacedPallets.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.RunPlacedPallets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RunPlacedPallets.displayName = 'proto.fox.proto.monitoring.RunPlacedPallets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.SetExcludeInterventionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.SetExcludeInterventionRequest.displayName = 'proto.fox.proto.monitoring.SetExcludeInterventionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ExternalBug = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ExternalBug.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ExternalBug, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ExternalBug.displayName = 'proto.fox.proto.monitoring.ExternalBug';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListExternalBugsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListExternalBugsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListExternalBugsRequest.displayName = 'proto.fox.proto.monitoring.ListExternalBugsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListExternalBugsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListExternalBugsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListExternalBugsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListExternalBugsResponse.displayName = 'proto.fox.proto.monitoring.ListExternalBugsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.Annotation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.Annotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.Annotation.displayName = 'proto.fox.proto.monitoring.Annotation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.CreateAnnotationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.CreateAnnotationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.CreateAnnotationRequest.displayName = 'proto.fox.proto.monitoring.CreateAnnotationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateAnnotationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateAnnotationRequest.displayName = 'proto.fox.proto.monitoring.UpdateAnnotationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.DeleteAnnotationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.DeleteAnnotationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.DeleteAnnotationRequest.displayName = 'proto.fox.proto.monitoring.DeleteAnnotationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListPickStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListPickStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListPickStatsRequest.displayName = 'proto.fox.proto.monitoring.ListPickStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListPickStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListPickStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListPickStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListPickStatsResponse.displayName = 'proto.fox.proto.monitoring.ListPickStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListRunStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListRunStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListRunStatsRequest.displayName = 'proto.fox.proto.monitoring.ListRunStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListRunStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListRunStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListRunStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListRunStatsResponse.displayName = 'proto.fox.proto.monitoring.ListRunStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.Fault = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.Fault, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.Fault.displayName = 'proto.fox.proto.monitoring.Fault';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.Stoppage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.Stoppage.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.Stoppage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.Stoppage.displayName = 'proto.fox.proto.monitoring.Stoppage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListStoppagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListStoppagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListStoppagesRequest.displayName = 'proto.fox.proto.monitoring.ListStoppagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListStoppagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListStoppagesResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListStoppagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListStoppagesResponse.displayName = 'proto.fox.proto.monitoring.ListStoppagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.FaultCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.FaultCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.FaultCount.displayName = 'proto.fox.proto.monitoring.FaultCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.FaultCountBucket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.FaultCountBucket.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.FaultCountBucket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.FaultCountBucket.displayName = 'proto.fox.proto.monitoring.FaultCountBucket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.FaultCountTimeSeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.FaultCountTimeSeries.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.FaultCountTimeSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.FaultCountTimeSeries.displayName = 'proto.fox.proto.monitoring.FaultCountTimeSeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.displayName = 'proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RunStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.RunStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RunStats.displayName = 'proto.fox.proto.monitoring.RunStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.PickStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.PickStats.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.PickStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.PickStats.displayName = 'proto.fox.proto.monitoring.PickStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.displayName = 'proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ForkliftConfigurationFileRevision, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.displayName = 'proto.fox.proto.monitoring.ForkliftConfigurationFileRevision';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ConfigurationHeaderConflict = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ConfigurationHeaderConflict, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ConfigurationHeaderConflict.displayName = 'proto.fox.proto.monitoring.ConfigurationHeaderConflict';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ConfigurationHeaderRevision.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ConfigurationHeaderRevision, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ConfigurationHeaderRevision.displayName = 'proto.fox.proto.monitoring.ConfigurationHeaderRevision';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest.displayName = 'proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.displayName = 'proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.oneofGroups_);
};
goog.inherits(proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.displayName = 'proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.displayName = 'proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ConfigurationRevisionHistory.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ConfigurationRevisionHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ConfigurationRevisionHistory.displayName = 'proto.fox.proto.monitoring.ConfigurationRevisionHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.repeatedFields_, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_);
};
goog.inherits(proto.fox.proto.monitoring.ConfigurationRevisionMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ConfigurationRevisionMetadata.displayName = 'proto.fox.proto.monitoring.ConfigurationRevisionMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ConfigurationFileRevision = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ConfigurationFileRevision, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ConfigurationFileRevision.displayName = 'proto.fox.proto.monitoring.ConfigurationFileRevision';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.RobotConfigurationRevisionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RobotConfigurationRevisionState.displayName = 'proto.fox.proto.monitoring.RobotConfigurationRevisionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RevisionConflict = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.RevisionConflict.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.RevisionConflict, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RevisionConflict.displayName = 'proto.fox.proto.monitoring.RevisionConflict';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.displayName = 'proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.displayName = 'proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.displayName = 'proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.displayName = 'proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListTrailersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListTrailersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListTrailersRequest.displayName = 'proto.fox.proto.monitoring.ListTrailersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListTrailersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListTrailersResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListTrailersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListTrailersResponse.displayName = 'proto.fox.proto.monitoring.ListTrailersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.Trailer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.Trailer.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.Trailer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.Trailer.displayName = 'proto.fox.proto.monitoring.Trailer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ConfigSyncError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ConfigSyncError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ConfigSyncError.displayName = 'proto.fox.proto.monitoring.ConfigSyncError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListTrailerStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListTrailerStatsRequest.displayName = 'proto.fox.proto.monitoring.ListTrailerStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListTrailerStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListTrailerStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListTrailerStatsResponse.displayName = 'proto.fox.proto.monitoring.ListTrailerStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.TrailerStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.TrailerStats.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.TrailerStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.TrailerStats.displayName = 'proto.fox.proto.monitoring.TrailerStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetTabularDatasetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetTabularDatasetRequest.displayName = 'proto.fox.proto.monitoring.GetTabularDatasetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.TabularDataset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.TabularDataset.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.TabularDataset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.TabularDataset.displayName = 'proto.fox.proto.monitoring.TabularDataset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.Row = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.Row.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.Row, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.Row.displayName = 'proto.fox.proto.monitoring.Row';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListFaultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListFaultsRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListFaultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListFaultsRequest.displayName = 'proto.fox.proto.monitoring.ListFaultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListFaultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListFaultsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListFaultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListFaultsResponse.displayName = 'proto.fox.proto.monitoring.ListFaultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.PublishRobotHeartbeatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.displayName = 'proto.fox.proto.monitoring.PublishRobotHeartbeatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RobotHeartbeat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.RobotHeartbeat.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.RobotHeartbeat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RobotHeartbeat.displayName = 'proto.fox.proto.monitoring.RobotHeartbeat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RunLogSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.RunLogSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RunLogSummary.displayName = 'proto.fox.proto.monitoring.RunLogSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.PublishRobotHeartbeatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.displayName = 'proto.fox.proto.monitoring.PublishRobotHeartbeatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.displayName = 'proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.displayName = 'proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.DockConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.DockConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.DockConfig.displayName = 'proto.fox.proto.monitoring.DockConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ParseEnvironmentConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ParseEnvironmentConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.displayName = 'proto.fox.proto.monitoring.ParseEnvironmentConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.EnvironmentConfigInputs.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.EnvironmentConfigInputs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.EnvironmentConfigInputs.displayName = 'proto.fox.proto.monitoring.EnvironmentConfigInputs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListFaultCodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListFaultCodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListFaultCodesRequest.displayName = 'proto.fox.proto.monitoring.ListFaultCodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListFaultCodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListFaultCodesResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListFaultCodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListFaultCodesResponse.displayName = 'proto.fox.proto.monitoring.ListFaultCodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListOrganizationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListOrganizationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListOrganizationsRequest.displayName = 'proto.fox.proto.monitoring.ListOrganizationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListOrganizationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListOrganizationsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListOrganizationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListOrganizationsResponse.displayName = 'proto.fox.proto.monitoring.ListOrganizationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.Organization.displayName = 'proto.fox.proto.monitoring.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.CreateAutonomyVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.CreateAutonomyVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.CreateAutonomyVersionRequest.displayName = 'proto.fox.proto.monitoring.CreateAutonomyVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateAutonomyVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.displayName = 'proto.fox.proto.monitoring.UpdateAutonomyVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.AutonomyVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.AutonomyVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.AutonomyVersion.displayName = 'proto.fox.proto.monitoring.AutonomyVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListAutonomyVersionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListAutonomyVersionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListAutonomyVersionsRequest.displayName = 'proto.fox.proto.monitoring.ListAutonomyVersionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListAutonomyVersionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListAutonomyVersionsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListAutonomyVersionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListAutonomyVersionsResponse.displayName = 'proto.fox.proto.monitoring.ListAutonomyVersionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.BulkLogUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.BulkLogUploadRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.BulkLogUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.BulkLogUploadRequest.displayName = 'proto.fox.proto.monitoring.BulkLogUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.BulkLogUpload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.BulkLogUpload.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.BulkLogUpload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.BulkLogUpload.displayName = 'proto.fox.proto.monitoring.BulkLogUpload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListBulkLogUploadsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListBulkLogUploadsRequest.displayName = 'proto.fox.proto.monitoring.ListBulkLogUploadsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListBulkLogUploadsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListBulkLogUploadsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListBulkLogUploadsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListBulkLogUploadsResponse.displayName = 'proto.fox.proto.monitoring.ListBulkLogUploadsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.CreateSiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.CreateSiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.CreateSiteRequest.displayName = 'proto.fox.proto.monitoring.CreateSiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.Site = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.Site, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.Site.displayName = 'proto.fox.proto.monitoring.Site';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListSitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListSitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListSitesRequest.displayName = 'proto.fox.proto.monitoring.ListSitesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListSitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListSitesResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListSitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListSitesResponse.displayName = 'proto.fox.proto.monitoring.ListSitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RobotStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.RobotStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RobotStatus.displayName = 'proto.fox.proto.monitoring.RobotStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetApexStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetApexStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetApexStatsRequest.displayName = 'proto.fox.proto.monitoring.GetApexStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ApexStatsUrls = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ApexStatsUrls, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ApexStatsUrls.displayName = 'proto.fox.proto.monitoring.ApexStatsUrls';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest.displayName = 'proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.displayName = 'proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.FaultRateAlertThreshold = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.FaultRateAlertThreshold, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.FaultRateAlertThreshold.displayName = 'proto.fox.proto.monitoring.FaultRateAlertThreshold';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.displayName = 'proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.CreateOperatorQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.CreateOperatorQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.CreateOperatorQuestionRequest.displayName = 'proto.fox.proto.monitoring.CreateOperatorQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.displayName = 'proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.displayName = 'proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.displayName = 'proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.OperatorQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.OperatorQuestion.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.OperatorQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.OperatorQuestion.displayName = 'proto.fox.proto.monitoring.OperatorQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.OperatorQuestionTranslation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.OperatorQuestionTranslation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.OperatorQuestionTranslation.displayName = 'proto.fox.proto.monitoring.OperatorQuestionTranslation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.OperatorAnswerChoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.OperatorAnswerChoice.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.OperatorAnswerChoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.OperatorAnswerChoice.displayName = 'proto.fox.proto.monitoring.OperatorAnswerChoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.displayName = 'proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListOperatorQuestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListOperatorQuestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListOperatorQuestionsRequest.displayName = 'proto.fox.proto.monitoring.ListOperatorQuestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListOperatorQuestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListOperatorQuestionsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListOperatorQuestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListOperatorQuestionsResponse.displayName = 'proto.fox.proto.monitoring.ListOperatorQuestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest.displayName = 'proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.displayName = 'proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.displayName = 'proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.displayName = 'proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.FaultCodeQuestionConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.displayName = 'proto.fox.proto.monitoring.FaultCodeQuestionConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.FaultCodeAnswerChoice.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.FaultCodeAnswerChoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.FaultCodeAnswerChoice.displayName = 'proto.fox.proto.monitoring.FaultCodeAnswerChoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest.displayName = 'proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.displayName = 'proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.PublishFaultTranslationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.PublishFaultTranslationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.PublishFaultTranslationsRequest.displayName = 'proto.fox.proto.monitoring.PublishFaultTranslationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListFaultTranslationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListFaultTranslationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListFaultTranslationsRequest.displayName = 'proto.fox.proto.monitoring.ListFaultTranslationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest.displayName = 'proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.displayName = 'proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.FaultConfigurationVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.FaultConfigurationVersion.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.FaultConfigurationVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.FaultConfigurationVersion.displayName = 'proto.fox.proto.monitoring.FaultConfigurationVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.displayName = 'proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RobotTabletConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.RobotTabletConfiguration.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.RobotTabletConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RobotTabletConfiguration.displayName = 'proto.fox.proto.monitoring.RobotTabletConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest.displayName = 'proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.RobotTabletConfigurationSummary.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.RobotTabletConfigurationSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RobotTabletConfigurationSummary.displayName = 'proto.fox.proto.monitoring.RobotTabletConfigurationSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.displayName = 'proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.displayName = 'proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.displayName = 'proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.displayName = 'proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.displayName = 'proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest.displayName = 'proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.displayName = 'proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.TabletOptionalFeature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.TabletOptionalFeature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.TabletOptionalFeature.displayName = 'proto.fox.proto.monitoring.TabletOptionalFeature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.displayName = 'proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.ListTabletFlagOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListTabletFlagOptionsRequest.displayName = 'proto.fox.proto.monitoring.ListTabletFlagOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.monitoring.ListTabletFlagOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.displayName = 'proto.fox.proto.monitoring.ListTabletFlagOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.TabletFlagOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.TabletFlagOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.TabletFlagOption.displayName = 'proto.fox.proto.monitoring.TabletFlagOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.monitoring.CreateTabletFlagOptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.monitoring.CreateTabletFlagOptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.displayName = 'proto.fox.proto.monitoring.CreateTabletFlagOptionRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RobotAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RobotAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cohortId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lifecycleState: jspb.Message.getFieldWithDefault(msg, 4, ""),
    autonomyVersion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    autonomyVersionDockerTag: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lastHeartbeatTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    siteId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: (f = msg.getStatus()) && proto.fox.proto.monitoring.RobotStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RobotAccount}
 */
proto.fox.proto.monitoring.RobotAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RobotAccount;
  return proto.fox.proto.monitoring.RobotAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RobotAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RobotAccount}
 */
proto.fox.proto.monitoring.RobotAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLifecycleState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutonomyVersion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutonomyVersionDockerTag(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastHeartbeatTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    case 9:
      var value = new proto.fox.proto.monitoring.RobotStatus;
      reader.readMessage(value,proto.fox.proto.monitoring.RobotStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RobotAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RobotAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCohortId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLifecycleState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAutonomyVersion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAutonomyVersionDockerTag();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLastHeartbeatTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.fox.proto.monitoring.RobotStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotAccount} returns this
 */
proto.fox.proto.monitoring.RobotAccount.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cohort_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.getCohortId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotAccount} returns this
 */
proto.fox.proto.monitoring.RobotAccount.prototype.setCohortId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ip_address = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotAccount} returns this
 */
proto.fox.proto.monitoring.RobotAccount.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lifecycle_state = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.getLifecycleState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotAccount} returns this
 */
proto.fox.proto.monitoring.RobotAccount.prototype.setLifecycleState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string autonomy_version = 5;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.getAutonomyVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotAccount} returns this
 */
proto.fox.proto.monitoring.RobotAccount.prototype.setAutonomyVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string autonomy_version_docker_tag = 6;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.getAutonomyVersionDockerTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotAccount} returns this
 */
proto.fox.proto.monitoring.RobotAccount.prototype.setAutonomyVersionDockerTag = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 last_heartbeat_time = 7;
 * @return {number}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.getLastHeartbeatTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RobotAccount} returns this
 */
proto.fox.proto.monitoring.RobotAccount.prototype.setLastHeartbeatTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string site_id = 8;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotAccount} returns this
 */
proto.fox.proto.monitoring.RobotAccount.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional RobotStatus status = 9;
 * @return {?proto.fox.proto.monitoring.RobotStatus}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.getStatus = function() {
  return /** @type{?proto.fox.proto.monitoring.RobotStatus} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.RobotStatus, 9));
};


/**
 * @param {?proto.fox.proto.monitoring.RobotStatus|undefined} value
 * @return {!proto.fox.proto.monitoring.RobotAccount} returns this
*/
proto.fox.proto.monitoring.RobotAccount.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.RobotAccount} returns this
 */
proto.fox.proto.monitoring.RobotAccount.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.RobotAccount.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.CreateForkliftCohortRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.CreateForkliftCohortRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    locationCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createBucket: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    siteId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.CreateForkliftCohortRequest}
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.CreateForkliftCohortRequest;
  return proto.fox.proto.monitoring.CreateForkliftCohortRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.CreateForkliftCohortRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.CreateForkliftCohortRequest}
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationCode(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateBucket(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.CreateForkliftCohortRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.CreateForkliftCohortRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocationCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreateBucket();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string display_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateForkliftCohortRequest} returns this
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_name = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.getOrganizationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateForkliftCohortRequest} returns this
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.setOrganizationName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string location_code = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.getLocationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateForkliftCohortRequest} returns this
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.setLocationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool create_bucket = 4;
 * @return {boolean}
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.getCreateBucket = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.CreateForkliftCohortRequest} returns this
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.setCreateBucket = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string site_id = 5;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateForkliftCohortRequest} returns this
 */
proto.fox.proto.monitoring.CreateForkliftCohortRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateForkliftCohortRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateForkliftCohortRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    locationCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateForkliftCohortRequest}
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateForkliftCohortRequest;
  return proto.fox.proto.monitoring.UpdateForkliftCohortRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateForkliftCohortRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateForkliftCohortRequest}
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateForkliftCohortRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateForkliftCohortRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocationCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSiteId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string display_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateForkliftCohortRequest} returns this
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_name = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.prototype.getOrganizationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateForkliftCohortRequest} returns this
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.prototype.setOrganizationName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string location_code = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.prototype.getLocationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateForkliftCohortRequest} returns this
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.prototype.setLocationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string site_id = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.prototype.getSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateForkliftCohortRequest} returns this
 */
proto.fox.proto.monitoring.UpdateForkliftCohortRequest.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ForkliftCohort.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ForkliftCohort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ForkliftCohort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ForkliftCohort.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    robotNamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    organizationName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    locationCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    site: (f = msg.getSite()) && proto.fox.proto.monitoring.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ForkliftCohort}
 */
proto.fox.proto.monitoring.ForkliftCohort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ForkliftCohort;
  return proto.fox.proto.monitoring.ForkliftCohort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ForkliftCohort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ForkliftCohort}
 */
proto.fox.proto.monitoring.ForkliftCohort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addRobotNames(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationCode(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 7:
      var value = new proto.fox.proto.monitoring.Site;
      reader.readMessage(value,proto.fox.proto.monitoring.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ForkliftCohort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ForkliftCohort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ForkliftCohort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRobotNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOrganizationName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocationCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.fox.proto.monitoring.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ForkliftCohort} returns this
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ForkliftCohort} returns this
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string robot_names = 3;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.getRobotNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.ForkliftCohort} returns this
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.setRobotNamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ForkliftCohort} returns this
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.addRobotNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ForkliftCohort} returns this
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.clearRobotNamesList = function() {
  return this.setRobotNamesList([]);
};


/**
 * optional string organization_name = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.getOrganizationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ForkliftCohort} returns this
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.setOrganizationName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string location_code = 5;
 * @return {string}
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.getLocationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ForkliftCohort} returns this
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.setLocationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool active = 6;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ForkliftCohort} returns this
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional Site site = 7;
 * @return {?proto.fox.proto.monitoring.Site}
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.getSite = function() {
  return /** @type{?proto.fox.proto.monitoring.Site} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.Site, 7));
};


/**
 * @param {?proto.fox.proto.monitoring.Site|undefined} value
 * @return {!proto.fox.proto.monitoring.ForkliftCohort} returns this
*/
proto.fox.proto.monitoring.ForkliftCohort.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ForkliftCohort} returns this
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ForkliftCohort.prototype.hasSite = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListForkliftCohortsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListForkliftCohortsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListForkliftCohortsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListForkliftCohortsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListForkliftCohortsRequest}
 */
proto.fox.proto.monitoring.ListForkliftCohortsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListForkliftCohortsRequest;
  return proto.fox.proto.monitoring.ListForkliftCohortsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListForkliftCohortsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListForkliftCohortsRequest}
 */
proto.fox.proto.monitoring.ListForkliftCohortsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListForkliftCohortsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListForkliftCohortsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListForkliftCohortsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListForkliftCohortsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.ListForkliftCohortsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListForkliftCohortsRequest} returns this
 */
proto.fox.proto.monitoring.ListForkliftCohortsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_token = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListForkliftCohortsRequest.prototype.getPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListForkliftCohortsRequest} returns this
 */
proto.fox.proto.monitoring.ListForkliftCohortsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListForkliftCohortsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListForkliftCohortsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cohortsList: jspb.Message.toObjectList(msg.getCohortsList(),
    proto.fox.proto.monitoring.ForkliftCohort.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListForkliftCohortsResponse}
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListForkliftCohortsResponse;
  return proto.fox.proto.monitoring.ListForkliftCohortsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListForkliftCohortsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListForkliftCohortsResponse}
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.ForkliftCohort;
      reader.readMessage(value,proto.fox.proto.monitoring.ForkliftCohort.deserializeBinaryFromReader);
      msg.addCohorts(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListForkliftCohortsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListForkliftCohortsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCohortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.ForkliftCohort.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated ForkliftCohort cohorts = 1;
 * @return {!Array<!proto.fox.proto.monitoring.ForkliftCohort>}
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.prototype.getCohortsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ForkliftCohort>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ForkliftCohort, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ForkliftCohort>} value
 * @return {!proto.fox.proto.monitoring.ListForkliftCohortsResponse} returns this
*/
proto.fox.proto.monitoring.ListForkliftCohortsResponse.prototype.setCohortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ForkliftCohort=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ForkliftCohort}
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.prototype.addCohorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.ForkliftCohort, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListForkliftCohortsResponse} returns this
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.prototype.clearCohortsList = function() {
  return this.setCohortsList([]);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListForkliftCohortsResponse} returns this
 */
proto.fox.proto.monitoring.ListForkliftCohortsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListRobotAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListRobotAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListRobotAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRobotAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListRobotAccountsRequest}
 */
proto.fox.proto.monitoring.ListRobotAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListRobotAccountsRequest;
  return proto.fox.proto.monitoring.ListRobotAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListRobotAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListRobotAccountsRequest}
 */
proto.fox.proto.monitoring.ListRobotAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListRobotAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListRobotAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListRobotAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRobotAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.ListRobotAccountsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListRobotAccountsRequest} returns this
 */
proto.fox.proto.monitoring.ListRobotAccountsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_token = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListRobotAccountsRequest.prototype.getPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListRobotAccountsRequest} returns this
 */
proto.fox.proto.monitoring.ListRobotAccountsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListRobotAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListRobotAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotAccountsList: jspb.Message.toObjectList(msg.getRobotAccountsList(),
    proto.fox.proto.monitoring.RobotAccount.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListRobotAccountsResponse}
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListRobotAccountsResponse;
  return proto.fox.proto.monitoring.ListRobotAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListRobotAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListRobotAccountsResponse}
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.RobotAccount;
      reader.readMessage(value,proto.fox.proto.monitoring.RobotAccount.deserializeBinaryFromReader);
      msg.addRobotAccounts(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListRobotAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListRobotAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.RobotAccount.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated RobotAccount robot_accounts = 1;
 * @return {!Array<!proto.fox.proto.monitoring.RobotAccount>}
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.prototype.getRobotAccountsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.RobotAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.RobotAccount, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.RobotAccount>} value
 * @return {!proto.fox.proto.monitoring.ListRobotAccountsResponse} returns this
*/
proto.fox.proto.monitoring.ListRobotAccountsResponse.prototype.setRobotAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.RobotAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RobotAccount}
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.prototype.addRobotAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.RobotAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListRobotAccountsResponse} returns this
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.prototype.clearRobotAccountsList = function() {
  return this.setRobotAccountsList([]);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListRobotAccountsResponse} returns this
 */
proto.fox.proto.monitoring.ListRobotAccountsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetRobotAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetRobotAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetRobotAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetRobotAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetRobotAccountRequest}
 */
proto.fox.proto.monitoring.GetRobotAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetRobotAccountRequest;
  return proto.fox.proto.monitoring.GetRobotAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetRobotAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetRobotAccountRequest}
 */
proto.fox.proto.monitoring.GetRobotAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetRobotAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetRobotAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetRobotAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetRobotAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.GetRobotAccountRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.GetRobotAccountRequest} returns this
 */
proto.fox.proto.monitoring.GetRobotAccountRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListMonitoringAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListMonitoringAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListMonitoringAccountsRequest}
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListMonitoringAccountsRequest;
  return proto.fox.proto.monitoring.ListMonitoringAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListMonitoringAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListMonitoringAccountsRequest}
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccountIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListMonitoringAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListMonitoringAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string account_ids = 1;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest.prototype.getAccountIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.ListMonitoringAccountsRequest} returns this
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest.prototype.setAccountIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ListMonitoringAccountsRequest} returns this
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest.prototype.addAccountIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListMonitoringAccountsRequest} returns this
 */
proto.fox.proto.monitoring.ListMonitoringAccountsRequest.prototype.clearAccountIdsList = function() {
  return this.setAccountIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListMonitoringAccountsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListMonitoringAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListMonitoringAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListMonitoringAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListMonitoringAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.fox.proto.monitoring.Account.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListMonitoringAccountsResponse}
 */
proto.fox.proto.monitoring.ListMonitoringAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListMonitoringAccountsResponse;
  return proto.fox.proto.monitoring.ListMonitoringAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListMonitoringAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListMonitoringAccountsResponse}
 */
proto.fox.proto.monitoring.ListMonitoringAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.Account;
      reader.readMessage(value,proto.fox.proto.monitoring.Account.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListMonitoringAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListMonitoringAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListMonitoringAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListMonitoringAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.Account.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Account accounts = 1;
 * @return {!Array<!proto.fox.proto.monitoring.Account>}
 */
proto.fox.proto.monitoring.ListMonitoringAccountsResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.Account>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.Account, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.Account>} value
 * @return {!proto.fox.proto.monitoring.ListMonitoringAccountsResponse} returns this
*/
proto.fox.proto.monitoring.ListMonitoringAccountsResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.Account=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Account}
 */
proto.fox.proto.monitoring.ListMonitoringAccountsResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.Account, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListMonitoringAccountsResponse} returns this
 */
proto.fox.proto.monitoring.ListMonitoringAccountsResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.CreateRobotAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.CreateRobotAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.CreateRobotAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateRobotAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.CreateRobotAccountRequest}
 */
proto.fox.proto.monitoring.CreateRobotAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.CreateRobotAccountRequest;
  return proto.fox.proto.monitoring.CreateRobotAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.CreateRobotAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.CreateRobotAccountRequest}
 */
proto.fox.proto.monitoring.CreateRobotAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.CreateRobotAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.CreateRobotAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.CreateRobotAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateRobotAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateRobotAccountRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateRobotAccountRequest} returns this
 */
proto.fox.proto.monitoring.CreateRobotAccountRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.CreateRobotAccountKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.CreateRobotAccountKeyRequest}
 */
proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.CreateRobotAccountKeyRequest;
  return proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.CreateRobotAccountKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.CreateRobotAccountKeyRequest}
 */
proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.CreateRobotAccountKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateRobotAccountKeyRequest} returns this
 */
proto.fox.proto.monitoring.CreateRobotAccountKeyRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListSyncDemandsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListSyncDemandsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListSyncDemandsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListSyncDemandsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListSyncDemandsRequest}
 */
proto.fox.proto.monitoring.ListSyncDemandsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListSyncDemandsRequest;
  return proto.fox.proto.monitoring.ListSyncDemandsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListSyncDemandsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListSyncDemandsRequest}
 */
proto.fox.proto.monitoring.ListSyncDemandsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.command_control.SyncStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListSyncDemandsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListSyncDemandsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListSyncDemandsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListSyncDemandsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ListSyncDemandsRequest.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListSyncDemandsRequest} returns this
 */
proto.fox.proto.monitoring.ListSyncDemandsRequest.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional fox.proto.command_control.SyncStatus status = 2;
 * @return {!proto.fox.proto.command_control.SyncStatus}
 */
proto.fox.proto.monitoring.ListSyncDemandsRequest.prototype.getStatus = function() {
  return /** @type {!proto.fox.proto.command_control.SyncStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.command_control.SyncStatus} value
 * @return {!proto.fox.proto.monitoring.ListSyncDemandsRequest} returns this
 */
proto.fox.proto.monitoring.ListSyncDemandsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListSyncDemandsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListSyncDemandsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListSyncDemandsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListSyncDemandsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListSyncDemandsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    syncsList: jspb.Message.toObjectList(msg.getSyncsList(),
    proto.fox.proto.monitoring.SyncDemand.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListSyncDemandsResponse}
 */
proto.fox.proto.monitoring.ListSyncDemandsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListSyncDemandsResponse;
  return proto.fox.proto.monitoring.ListSyncDemandsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListSyncDemandsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListSyncDemandsResponse}
 */
proto.fox.proto.monitoring.ListSyncDemandsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.SyncDemand;
      reader.readMessage(value,proto.fox.proto.monitoring.SyncDemand.deserializeBinaryFromReader);
      msg.addSyncs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListSyncDemandsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListSyncDemandsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListSyncDemandsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListSyncDemandsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSyncsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.SyncDemand.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SyncDemand syncs = 1;
 * @return {!Array<!proto.fox.proto.monitoring.SyncDemand>}
 */
proto.fox.proto.monitoring.ListSyncDemandsResponse.prototype.getSyncsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.SyncDemand>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.SyncDemand, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.SyncDemand>} value
 * @return {!proto.fox.proto.monitoring.ListSyncDemandsResponse} returns this
*/
proto.fox.proto.monitoring.ListSyncDemandsResponse.prototype.setSyncsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.SyncDemand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.SyncDemand}
 */
proto.fox.proto.monitoring.ListSyncDemandsResponse.prototype.addSyncs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.SyncDemand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListSyncDemandsResponse} returns this
 */
proto.fox.proto.monitoring.ListSyncDemandsResponse.prototype.clearSyncsList = function() {
  return this.setSyncsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cohortId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest}
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest;
  return proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest}
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCohortId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string cohort_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.prototype.getCohortId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest} returns this
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsRequest.prototype.setCohortId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cohortConfigsList: jspb.Message.toObjectList(msg.getCohortConfigsList(),
    command_control_proto_command_control_pb.CohortEnvironmentConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse}
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse;
  return proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse}
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new command_control_proto_command_control_pb.CohortEnvironmentConfig;
      reader.readMessage(value,command_control_proto_command_control_pb.CohortEnvironmentConfig.deserializeBinaryFromReader);
      msg.addCohortConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCohortConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      command_control_proto_command_control_pb.CohortEnvironmentConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated fox.proto.command_control.CohortEnvironmentConfig cohort_configs = 1;
 * @return {!Array<!proto.fox.proto.command_control.CohortEnvironmentConfig>}
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.prototype.getCohortConfigsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.CohortEnvironmentConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, command_control_proto_command_control_pb.CohortEnvironmentConfig, 1));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.CohortEnvironmentConfig>} value
 * @return {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse} returns this
*/
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.prototype.setCohortConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.command_control.CohortEnvironmentConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.CohortEnvironmentConfig}
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.prototype.addCohortConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.command_control.CohortEnvironmentConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse} returns this
 */
proto.fox.proto.monitoring.ListCohortEnvironmentConfigsResponse.prototype.clearCohortConfigsList = function() {
  return this.setCohortConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: (f = msg.getConfig()) && robot_proto_config_pb.EnvironmentConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest}
 */
proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest;
  return proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest}
 */
proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new robot_proto_config_pb.EnvironmentConfig;
      reader.readMessage(value,robot_proto_config_pb.EnvironmentConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      robot_proto_config_pb.EnvironmentConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional fox.proto.EnvironmentConfig config = 1;
 * @return {?proto.fox.proto.EnvironmentConfig}
 */
proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.prototype.getConfig = function() {
  return /** @type{?proto.fox.proto.EnvironmentConfig} */ (
    jspb.Message.getWrapperField(this, robot_proto_config_pb.EnvironmentConfig, 1));
};


/**
 * @param {?proto.fox.proto.EnvironmentConfig|undefined} value
 * @return {!proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest} returns this
*/
proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest} returns this
 */
proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.UpsertCohortEnvironmentConfigRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.CreateSyncDemandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.CreateSyncDemandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.CreateSyncDemandRequest}
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.CreateSyncDemandRequest;
  return proto.fox.proto.monitoring.CreateSyncDemandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.CreateSyncDemandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.CreateSyncDemandRequest}
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.CreateSyncDemandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.CreateSyncDemandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateSyncDemandRequest} returns this
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_time = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.CreateSyncDemandRequest} returns this
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_time = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.CreateSyncDemandRequest} returns this
 */
proto.fox.proto.monitoring.CreateSyncDemandRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cohortId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest}
 */
proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest;
  return proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest}
 */
proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCohortId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cohort_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.prototype.getCohortId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotAccountCohortRequest.prototype.setCohortId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateRobotAccountIpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountIpRequest}
 */
proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateRobotAccountIpRequest;
  return proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateRobotAccountIpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountIpRequest}
 */
proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateRobotAccountIpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountIpRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ip_address = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountIpRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotAccountIpRequest.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateRobotAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateRobotAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cohortId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lifecycleState: jspb.Message.getFieldWithDefault(msg, 4, ""),
    autonomyVersion: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountRequest}
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateRobotAccountRequest;
  return proto.fox.proto.monitoring.UpdateRobotAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateRobotAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountRequest}
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLifecycleState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutonomyVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateRobotAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateRobotAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCohortId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLifecycleState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAutonomyVersion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cohort_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.getCohortId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.setCohortId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ip_address = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lifecycle_state = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.getLifecycleState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.setLifecycleState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string autonomy_version = 5;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.getAutonomyVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotAccountRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotAccountRequest.prototype.setAutonomyVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest}
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest;
  return proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest}
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.command_control.SyncStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest} returns this
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_time = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest} returns this
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 end_time = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest} returns this
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional fox.proto.command_control.SyncStatus status = 4;
 * @return {!proto.fox.proto.command_control.SyncStatus}
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.fox.proto.command_control.SyncStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.command_control.SyncStatus} value
 * @return {!proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest} returns this
 */
proto.fox.proto.monitoring.UpdateSyncDemandStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.SyncDemand.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.SyncDemand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.SyncDemand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.SyncDemand.toObject = function(includeInstance, msg) {
  var f, obj = {
    runName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, "0"),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, "0"),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    requestedByRobot: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    requestedBy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bulkUpload: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.SyncDemand}
 */
proto.fox.proto.monitoring.SyncDemand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.SyncDemand;
  return proto.fox.proto.monitoring.SyncDemand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.SyncDemand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.SyncDemand}
 */
proto.fox.proto.monitoring.SyncDemand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.command_control.SyncStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequestedByRobot(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestedBy(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBulkUpload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.SyncDemand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.SyncDemand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.SyncDemand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.SyncDemand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getRequestedByRobot();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRequestedBy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBulkUpload();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.SyncDemand.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.SyncDemand} returns this
 */
proto.fox.proto.monitoring.SyncDemand.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_time = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.SyncDemand.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, "0"));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.SyncDemand} returns this
 */
proto.fox.proto.monitoring.SyncDemand.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringIntField(this, 2, value);
};


/**
 * optional int64 end_time = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.SyncDemand.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, "0"));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.SyncDemand} returns this
 */
proto.fox.proto.monitoring.SyncDemand.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringIntField(this, 3, value);
};


/**
 * optional fox.proto.command_control.SyncStatus status = 4;
 * @return {!proto.fox.proto.command_control.SyncStatus}
 */
proto.fox.proto.monitoring.SyncDemand.prototype.getStatus = function() {
  return /** @type {!proto.fox.proto.command_control.SyncStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.command_control.SyncStatus} value
 * @return {!proto.fox.proto.monitoring.SyncDemand} returns this
 */
proto.fox.proto.monitoring.SyncDemand.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool requested_by_robot = 5;
 * @return {boolean}
 */
proto.fox.proto.monitoring.SyncDemand.prototype.getRequestedByRobot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.SyncDemand} returns this
 */
proto.fox.proto.monitoring.SyncDemand.prototype.setRequestedByRobot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string requested_by = 6;
 * @return {string}
 */
proto.fox.proto.monitoring.SyncDemand.prototype.getRequestedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.SyncDemand} returns this
 */
proto.fox.proto.monitoring.SyncDemand.prototype.setRequestedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool bulk_upload = 7;
 * @return {boolean}
 */
proto.fox.proto.monitoring.SyncDemand.prototype.getBulkUpload = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.SyncDemand} returns this
 */
proto.fox.proto.monitoring.SyncDemand.prototype.setBulkUpload = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.RobotAccountKey.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RobotAccountKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RobotAccountKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotAccountKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    privateKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    privateKeyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    x509CertificatesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RobotAccountKey}
 */
proto.fox.proto.monitoring.RobotAccountKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RobotAccountKey;
  return proto.fox.proto.monitoring.RobotAccountKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RobotAccountKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RobotAccountKey}
 */
proto.fox.proto.monitoring.RobotAccountKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKeyId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addX509Certificates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RobotAccountKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RobotAccountKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotAccountKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrivateKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrivateKeyId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getX509CertificatesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotAccountKey} returns this
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string private_key = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.getPrivateKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotAccountKey} returns this
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.setPrivateKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string private_key_id = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.getPrivateKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotAccountKey} returns this
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.setPrivateKeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string x509_certificates = 4;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.getX509CertificatesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.RobotAccountKey} returns this
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.setX509CertificatesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RobotAccountKey} returns this
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.addX509Certificates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RobotAccountKey} returns this
 */
proto.fox.proto.monitoring.RobotAccountKey.prototype.clearX509CertificatesList = function() {
  return this.setX509CertificatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.DataLogUrls.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.DataLogUrls.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.DataLogUrls} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.DataLogUrls.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sensor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    plc: jspb.Message.getFieldWithDefault(msg, 3, ""),
    video: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.DataLogUrls}
 */
proto.fox.proto.monitoring.DataLogUrls.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.DataLogUrls;
  return proto.fox.proto.monitoring.DataLogUrls.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.DataLogUrls} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.DataLogUrls}
 */
proto.fox.proto.monitoring.DataLogUrls.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSensor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlc(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.DataLogUrls.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.DataLogUrls.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.DataLogUrls} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.DataLogUrls.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSensor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlc();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVideo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.DataLogUrls.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DataLogUrls} returns this
 */
proto.fox.proto.monitoring.DataLogUrls.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sensor = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.DataLogUrls.prototype.getSensor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DataLogUrls} returns this
 */
proto.fox.proto.monitoring.DataLogUrls.prototype.setSensor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string plc = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.DataLogUrls.prototype.getPlc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DataLogUrls} returns this
 */
proto.fox.proto.monitoring.DataLogUrls.prototype.setPlc = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string video = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.DataLogUrls.prototype.getVideo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DataLogUrls} returns this
 */
proto.fox.proto.monitoring.DataLogUrls.prototype.setVideo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RunReview.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RunReview.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RunReview} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunReview.toObject = function(includeInstance, msg) {
  var f, obj = {
    runMetadataId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reviewerAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assignerAccountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RunReview}
 */
proto.fox.proto.monitoring.RunReview.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RunReview;
  return proto.fox.proto.monitoring.RunReview.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RunReview} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RunReview}
 */
proto.fox.proto.monitoring.RunReview.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunMetadataId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewerAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignerAccountId(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.monitoring.RunReviewStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RunReview.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RunReview.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RunReview} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunReview.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunMetadataId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReviewerAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssignerAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string run_metadata_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RunReview.prototype.getRunMetadataId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunReview} returns this
 */
proto.fox.proto.monitoring.RunReview.prototype.setRunMetadataId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reviewer_account_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.RunReview.prototype.getReviewerAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunReview} returns this
 */
proto.fox.proto.monitoring.RunReview.prototype.setReviewerAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assigner_account_id = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.RunReview.prototype.getAssignerAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunReview} returns this
 */
proto.fox.proto.monitoring.RunReview.prototype.setAssignerAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional RunReviewStatus status = 4;
 * @return {!proto.fox.proto.monitoring.RunReviewStatus}
 */
proto.fox.proto.monitoring.RunReview.prototype.getStatus = function() {
  return /** @type {!proto.fox.proto.monitoring.RunReviewStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.RunReviewStatus} value
 * @return {!proto.fox.proto.monitoring.RunReview} returns this
 */
proto.fox.proto.monitoring.RunReview.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpsertRunReviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpsertRunReviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpsertRunReviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpsertRunReviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runReview: (f = msg.getRunReview()) && proto.fox.proto.monitoring.RunReview.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpsertRunReviewRequest}
 */
proto.fox.proto.monitoring.UpsertRunReviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpsertRunReviewRequest;
  return proto.fox.proto.monitoring.UpsertRunReviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpsertRunReviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpsertRunReviewRequest}
 */
proto.fox.proto.monitoring.UpsertRunReviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.RunReview;
      reader.readMessage(value,proto.fox.proto.monitoring.RunReview.deserializeBinaryFromReader);
      msg.setRunReview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpsertRunReviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpsertRunReviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpsertRunReviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpsertRunReviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.RunReview.serializeBinaryToWriter
    );
  }
};


/**
 * optional RunReview run_review = 1;
 * @return {?proto.fox.proto.monitoring.RunReview}
 */
proto.fox.proto.monitoring.UpsertRunReviewRequest.prototype.getRunReview = function() {
  return /** @type{?proto.fox.proto.monitoring.RunReview} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.RunReview, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.RunReview|undefined} value
 * @return {!proto.fox.proto.monitoring.UpsertRunReviewRequest} returns this
*/
proto.fox.proto.monitoring.UpsertRunReviewRequest.prototype.setRunReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.UpsertRunReviewRequest} returns this
 */
proto.fox.proto.monitoring.UpsertRunReviewRequest.prototype.clearRunReview = function() {
  return this.setRunReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.UpsertRunReviewRequest.prototype.hasRunReview = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.RunMetadata.repeatedFields_ = [7,16,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RunMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RunMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    robotName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataLogUrls: (f = msg.getDataLogUrls()) && proto.fox.proto.monitoring.DataLogUrls.toObject(includeInstance, f),
    startTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    annotationsList: jspb.Message.toObjectList(msg.getAnnotationsList(),
    proto.fox.proto.monitoring.Annotation.toObject, includeInstance),
    description: (f = msg.getDescription()) && log_run_description_pb.RunDescription.toObject(includeInstance, f),
    summary: jspb.Message.getFieldWithDefault(msg, 9, ""),
    flagFile: jspb.Message.getFieldWithDefault(msg, 12, ""),
    reviewsList: jspb.Message.toObjectList(msg.getReviewsList(),
    proto.fox.proto.monitoring.RunReview.toObject, includeInstance),
    goalIdsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    numPendingLogFiles: jspb.Message.getFieldWithDefault(msg, 17, 0),
    numImportedLogFiles: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RunMetadata}
 */
proto.fox.proto.monitoring.RunMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RunMetadata;
  return proto.fox.proto.monitoring.RunMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RunMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RunMetadata}
 */
proto.fox.proto.monitoring.RunMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 4:
      var value = new proto.fox.proto.monitoring.DataLogUrls;
      reader.readMessage(value,proto.fox.proto.monitoring.DataLogUrls.deserializeBinaryFromReader);
      msg.setDataLogUrls(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 7:
      var value = new proto.fox.proto.monitoring.Annotation;
      reader.readMessage(value,proto.fox.proto.monitoring.Annotation.deserializeBinaryFromReader);
      msg.addAnnotations(value);
      break;
    case 8:
      var value = new log_run_description_pb.RunDescription;
      reader.readMessage(value,log_run_description_pb.RunDescription.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagFile(value);
      break;
    case 16:
      var value = new proto.fox.proto.monitoring.RunReview;
      reader.readMessage(value,proto.fox.proto.monitoring.RunReview.deserializeBinaryFromReader);
      msg.addReviews(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addGoalIds(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumPendingLogFiles(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumImportedLogFiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RunMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RunMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataLogUrls();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.monitoring.DataLogUrls.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.fox.proto.monitoring.Annotation.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      log_run_description_pb.RunDescription.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFlagFile();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.fox.proto.monitoring.RunReview.serializeBinaryToWriter
    );
  }
  f = message.getGoalIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getNumPendingLogFiles();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getNumImportedLogFiles();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string run_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string robot_name = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DataLogUrls data_log_urls = 4;
 * @return {?proto.fox.proto.monitoring.DataLogUrls}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getDataLogUrls = function() {
  return /** @type{?proto.fox.proto.monitoring.DataLogUrls} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.DataLogUrls, 4));
};


/**
 * @param {?proto.fox.proto.monitoring.DataLogUrls|undefined} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
*/
proto.fox.proto.monitoring.RunMetadata.prototype.setDataLogUrls = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.clearDataLogUrls = function() {
  return this.setDataLogUrls(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.hasDataLogUrls = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 start_time = 5;
 * @return {number}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 end_time = 6;
 * @return {number}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated Annotation annotations = 7;
 * @return {!Array<!proto.fox.proto.monitoring.Annotation>}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getAnnotationsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.Annotation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.Annotation, 7));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.Annotation>} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
*/
proto.fox.proto.monitoring.RunMetadata.prototype.setAnnotationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.fox.proto.monitoring.Annotation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Annotation}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.addAnnotations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.fox.proto.monitoring.Annotation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.clearAnnotationsList = function() {
  return this.setAnnotationsList([]);
};


/**
 * optional fox.proto.RunDescription description = 8;
 * @return {?proto.fox.proto.RunDescription}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getDescription = function() {
  return /** @type{?proto.fox.proto.RunDescription} */ (
    jspb.Message.getWrapperField(this, log_run_description_pb.RunDescription, 8));
};


/**
 * @param {?proto.fox.proto.RunDescription|undefined} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
*/
proto.fox.proto.monitoring.RunMetadata.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string summary = 9;
 * @return {string}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string flag_file = 12;
 * @return {string}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getFlagFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.setFlagFile = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated RunReview reviews = 16;
 * @return {!Array<!proto.fox.proto.monitoring.RunReview>}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getReviewsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.RunReview>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.RunReview, 16));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.RunReview>} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
*/
proto.fox.proto.monitoring.RunMetadata.prototype.setReviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.fox.proto.monitoring.RunReview=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RunReview}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.addReviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.fox.proto.monitoring.RunReview, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.clearReviewsList = function() {
  return this.setReviewsList([]);
};


/**
 * repeated string goal_ids = 15;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getGoalIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.setGoalIdsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.addGoalIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.clearGoalIdsList = function() {
  return this.setGoalIdsList([]);
};


/**
 * optional int64 num_pending_log_files = 17;
 * @return {number}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getNumPendingLogFiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.setNumPendingLogFiles = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 num_imported_log_files = 18;
 * @return {number}
 */
proto.fox.proto.monitoring.RunMetadata.prototype.getNumImportedLogFiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunMetadata} returns this
 */
proto.fox.proto.monitoring.RunMetadata.prototype.setNumImportedLogFiles = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GoalMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GoalMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GoalMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endState: jspb.Message.getFieldWithDefault(msg, 5, 0),
    environmentConfig: (f = msg.getEnvironmentConfig()) && robot_proto_config_pb.EnvironmentConfig.toObject(includeInstance, f),
    pickPlaceDetails: (f = msg.getPickPlaceDetails()) && proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GoalMetadata}
 */
proto.fox.proto.monitoring.GoalMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GoalMetadata;
  return proto.fox.proto.monitoring.GoalMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GoalMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GoalMetadata}
 */
proto.fox.proto.monitoring.GoalMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 5:
      var value = /** @type {!proto.fox.proto.command_control.GoalStatus.State} */ (reader.readEnum());
      msg.setEndState(value);
      break;
    case 6:
      var value = new robot_proto_config_pb.EnvironmentConfig;
      reader.readMessage(value,robot_proto_config_pb.EnvironmentConfig.deserializeBinaryFromReader);
      msg.setEnvironmentConfig(value);
      break;
    case 7:
      var value = new proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails;
      reader.readMessage(value,proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.deserializeBinaryFromReader);
      msg.setPickPlaceDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GoalMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GoalMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GoalMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEndState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getEnvironmentConfig();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      robot_proto_config_pb.EnvironmentConfig.serializeBinaryToWriter
    );
  }
  f = message.getPickPlaceDetails();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickZone: (f = msg.getPickZone()) && detection_proto_detection_pb.ZoneConfig.toObject(includeInstance, f),
    placeZone: (f = msg.getPlaceZone()) && detection_proto_detection_pb.ZoneConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails}
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails;
  return proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails}
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new detection_proto_detection_pb.ZoneConfig;
      reader.readMessage(value,detection_proto_detection_pb.ZoneConfig.deserializeBinaryFromReader);
      msg.setPickZone(value);
      break;
    case 2:
      var value = new detection_proto_detection_pb.ZoneConfig;
      reader.readMessage(value,detection_proto_detection_pb.ZoneConfig.deserializeBinaryFromReader);
      msg.setPlaceZone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickZone();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      detection_proto_detection_pb.ZoneConfig.serializeBinaryToWriter
    );
  }
  f = message.getPlaceZone();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      detection_proto_detection_pb.ZoneConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional fox.proto.ZoneConfig pick_zone = 1;
 * @return {?proto.fox.proto.ZoneConfig}
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.prototype.getPickZone = function() {
  return /** @type{?proto.fox.proto.ZoneConfig} */ (
    jspb.Message.getWrapperField(this, detection_proto_detection_pb.ZoneConfig, 1));
};


/**
 * @param {?proto.fox.proto.ZoneConfig|undefined} value
 * @return {!proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails} returns this
*/
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.prototype.setPickZone = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails} returns this
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.prototype.clearPickZone = function() {
  return this.setPickZone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.prototype.hasPickZone = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional fox.proto.ZoneConfig place_zone = 2;
 * @return {?proto.fox.proto.ZoneConfig}
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.prototype.getPlaceZone = function() {
  return /** @type{?proto.fox.proto.ZoneConfig} */ (
    jspb.Message.getWrapperField(this, detection_proto_detection_pb.ZoneConfig, 2));
};


/**
 * @param {?proto.fox.proto.ZoneConfig|undefined} value
 * @return {!proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails} returns this
*/
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.prototype.setPlaceZone = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails} returns this
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.prototype.clearPlaceZone = function() {
  return this.setPlaceZone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails.prototype.hasPlaceZone = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.GoalMetadata} returns this
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string run_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.GoalMetadata} returns this
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 start_time = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.GoalMetadata} returns this
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 end_time = 4;
 * @return {number}
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.GoalMetadata} returns this
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional fox.proto.command_control.GoalStatus.State end_state = 5;
 * @return {!proto.fox.proto.command_control.GoalStatus.State}
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.getEndState = function() {
  return /** @type {!proto.fox.proto.command_control.GoalStatus.State} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fox.proto.command_control.GoalStatus.State} value
 * @return {!proto.fox.proto.monitoring.GoalMetadata} returns this
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.setEndState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional fox.proto.EnvironmentConfig environment_config = 6;
 * @return {?proto.fox.proto.EnvironmentConfig}
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.getEnvironmentConfig = function() {
  return /** @type{?proto.fox.proto.EnvironmentConfig} */ (
    jspb.Message.getWrapperField(this, robot_proto_config_pb.EnvironmentConfig, 6));
};


/**
 * @param {?proto.fox.proto.EnvironmentConfig|undefined} value
 * @return {!proto.fox.proto.monitoring.GoalMetadata} returns this
*/
proto.fox.proto.monitoring.GoalMetadata.prototype.setEnvironmentConfig = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.GoalMetadata} returns this
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.clearEnvironmentConfig = function() {
  return this.setEnvironmentConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.hasEnvironmentConfig = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PickPlaceDetails pick_place_details = 7;
 * @return {?proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails}
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.getPickPlaceDetails = function() {
  return /** @type{?proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails, 7));
};


/**
 * @param {?proto.fox.proto.monitoring.GoalMetadata.PickPlaceDetails|undefined} value
 * @return {!proto.fox.proto.monitoring.GoalMetadata} returns this
*/
proto.fox.proto.monitoring.GoalMetadata.prototype.setPickPlaceDetails = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.GoalMetadata} returns this
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.clearPickPlaceDetails = function() {
  return this.setPickPlaceDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.GoalMetadata.prototype.hasPickPlaceDetails = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetRunMetadataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetRunMetadataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetRunMetadataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetRunMetadataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetRunMetadataRequest}
 */
proto.fox.proto.monitoring.GetRunMetadataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetRunMetadataRequest;
  return proto.fox.proto.monitoring.GetRunMetadataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetRunMetadataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetRunMetadataRequest}
 */
proto.fox.proto.monitoring.GetRunMetadataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetRunMetadataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetRunMetadataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetRunMetadataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetRunMetadataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string run_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.GetRunMetadataRequest.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.GetRunMetadataRequest} returns this
 */
proto.fox.proto.monitoring.GetRunMetadataRequest.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetForkliftCohortRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetForkliftCohortRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetForkliftCohortRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetForkliftCohortRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cohortId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetForkliftCohortRequest}
 */
proto.fox.proto.monitoring.GetForkliftCohortRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetForkliftCohortRequest;
  return proto.fox.proto.monitoring.GetForkliftCohortRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetForkliftCohortRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetForkliftCohortRequest}
 */
proto.fox.proto.monitoring.GetForkliftCohortRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetForkliftCohortRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetForkliftCohortRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetForkliftCohortRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetForkliftCohortRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCohortId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string cohort_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.GetForkliftCohortRequest.prototype.getCohortId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.GetForkliftCohortRequest} returns this
 */
proto.fox.proto.monitoring.GetForkliftCohortRequest.prototype.setCohortId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListRunMetadatasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListRunMetadatasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, 0),
    runFilter: (f = msg.getRunFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasRequest}
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListRunMetadatasRequest;
  return proto.fox.proto.monitoring.ListRunMetadatasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListRunMetadatasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasRequest}
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageToken(value);
      break;
    case 4:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setRunFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListRunMetadatasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListRunMetadatasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRunFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasRequest} returns this
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_token = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.prototype.getPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasRequest} returns this
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional EventFilter run_filter = 4;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.prototype.getRunFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 4));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasRequest} returns this
*/
proto.fox.proto.monitoring.ListRunMetadatasRequest.prototype.setRunFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasRequest} returns this
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.prototype.clearRunFilter = function() {
  return this.setRunFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListRunMetadatasRequest.prototype.hasRunFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListRunMetadatasResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListRunMetadatasResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadatasList: jspb.Message.toObjectList(msg.getMetadatasList(),
    proto.fox.proto.monitoring.RunMetadata.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasResponse}
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListRunMetadatasResponse;
  return proto.fox.proto.monitoring.ListRunMetadatasResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListRunMetadatasResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasResponse}
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.RunMetadata;
      reader.readMessage(value,proto.fox.proto.monitoring.RunMetadata.deserializeBinaryFromReader);
      msg.addMetadatas(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListRunMetadatasResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListRunMetadatasResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadatasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.RunMetadata.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated RunMetadata metadatas = 1;
 * @return {!Array<!proto.fox.proto.monitoring.RunMetadata>}
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.prototype.getMetadatasList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.RunMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.RunMetadata, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.RunMetadata>} value
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasResponse} returns this
*/
proto.fox.proto.monitoring.ListRunMetadatasResponse.prototype.setMetadatasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.RunMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RunMetadata}
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.prototype.addMetadatas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.RunMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasResponse} returns this
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.prototype.clearMetadatasList = function() {
  return this.setMetadatasList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasResponse} returns this
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListRunMetadatasResponse} returns this
 */
proto.fox.proto.monitoring.ListRunMetadatasResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListGoalMetadatasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListGoalMetadatasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, 0),
    goalIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasRequest}
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListGoalMetadatasRequest;
  return proto.fox.proto.monitoring.ListGoalMetadatasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListGoalMetadatasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasRequest}
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addGoalIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListGoalMetadatasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListGoalMetadatasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getGoalIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasRequest} returns this
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_token = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.prototype.getPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasRequest} returns this
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string goal_ids = 3;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.prototype.getGoalIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasRequest} returns this
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.prototype.setGoalIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasRequest} returns this
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.prototype.addGoalIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasRequest} returns this
 */
proto.fox.proto.monitoring.ListGoalMetadatasRequest.prototype.clearGoalIdsList = function() {
  return this.setGoalIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListGoalMetadatasResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListGoalMetadatasResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadatasList: jspb.Message.toObjectList(msg.getMetadatasList(),
    proto.fox.proto.monitoring.GoalMetadata.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasResponse}
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListGoalMetadatasResponse;
  return proto.fox.proto.monitoring.ListGoalMetadatasResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListGoalMetadatasResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasResponse}
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.GoalMetadata;
      reader.readMessage(value,proto.fox.proto.monitoring.GoalMetadata.deserializeBinaryFromReader);
      msg.addMetadatas(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListGoalMetadatasResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListGoalMetadatasResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadatasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.GoalMetadata.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated GoalMetadata metadatas = 1;
 * @return {!Array<!proto.fox.proto.monitoring.GoalMetadata>}
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.prototype.getMetadatasList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.GoalMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.GoalMetadata, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.GoalMetadata>} value
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasResponse} returns this
*/
proto.fox.proto.monitoring.ListGoalMetadatasResponse.prototype.setMetadatasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.GoalMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.GoalMetadata}
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.prototype.addMetadatas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.GoalMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasResponse} returns this
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.prototype.clearMetadatasList = function() {
  return this.setMetadatasList([]);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListGoalMetadatasResponse} returns this
 */
proto.fox.proto.monitoring.ListGoalMetadatasResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetViewerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetViewerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetViewerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetViewerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetViewerRequest}
 */
proto.fox.proto.monitoring.GetViewerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetViewerRequest;
  return proto.fox.proto.monitoring.GetViewerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetViewerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetViewerRequest}
 */
proto.fox.proto.monitoring.GetViewerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetViewerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetViewerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetViewerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetViewerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.Viewer.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.Viewer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.Viewer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Viewer.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAuthenticated: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    account: (f = msg.getAccount()) && proto.fox.proto.monitoring.Account.toObject(includeInstance, f),
    authToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.Viewer}
 */
proto.fox.proto.monitoring.Viewer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.Viewer;
  return proto.fox.proto.monitoring.Viewer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.Viewer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.Viewer}
 */
proto.fox.proto.monitoring.Viewer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAuthenticated(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.Account;
      reader.readMessage(value,proto.fox.proto.monitoring.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.Viewer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.Viewer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.Viewer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Viewer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAuthenticated();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.monitoring.Account.serializeBinaryToWriter
    );
  }
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_authenticated = 1;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Viewer.prototype.getIsAuthenticated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Viewer} returns this
 */
proto.fox.proto.monitoring.Viewer.prototype.setIsAuthenticated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Account account = 2;
 * @return {?proto.fox.proto.monitoring.Account}
 */
proto.fox.proto.monitoring.Viewer.prototype.getAccount = function() {
  return /** @type{?proto.fox.proto.monitoring.Account} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.Account, 2));
};


/**
 * @param {?proto.fox.proto.monitoring.Account|undefined} value
 * @return {!proto.fox.proto.monitoring.Viewer} returns this
*/
proto.fox.proto.monitoring.Viewer.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.Viewer} returns this
 */
proto.fox.proto.monitoring.Viewer.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.Viewer.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string auth_token = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.Viewer.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Viewer} returns this
 */
proto.fox.proto.monitoring.Viewer.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetLogUploadInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetLogUploadInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetLogUploadInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetLogUploadInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetLogUploadInfoRequest}
 */
proto.fox.proto.monitoring.GetLogUploadInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetLogUploadInfoRequest;
  return proto.fox.proto.monitoring.GetLogUploadInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetLogUploadInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetLogUploadInfoRequest}
 */
proto.fox.proto.monitoring.GetLogUploadInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetLogUploadInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetLogUploadInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetLogUploadInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetLogUploadInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.LogUploadInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.LogUploadInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.LogUploadInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.LogUploadInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    bucket: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pathPrefix: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accessToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    allowFullUploadOverEthernet: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.LogUploadInfo}
 */
proto.fox.proto.monitoring.LogUploadInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.LogUploadInfo;
  return proto.fox.proto.monitoring.LogUploadInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.LogUploadInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.LogUploadInfo}
 */
proto.fox.proto.monitoring.LogUploadInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucket(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPathPrefix(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowFullUploadOverEthernet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.LogUploadInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.LogUploadInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.LogUploadInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.LogUploadInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBucket();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPathPrefix();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAllowFullUploadOverEthernet();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string bucket = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.LogUploadInfo.prototype.getBucket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.LogUploadInfo} returns this
 */
proto.fox.proto.monitoring.LogUploadInfo.prototype.setBucket = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string path_prefix = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.LogUploadInfo.prototype.getPathPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.LogUploadInfo} returns this
 */
proto.fox.proto.monitoring.LogUploadInfo.prototype.setPathPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string access_token = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.LogUploadInfo.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.LogUploadInfo} returns this
 */
proto.fox.proto.monitoring.LogUploadInfo.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool allow_full_upload_over_ethernet = 4;
 * @return {boolean}
 */
proto.fox.proto.monitoring.LogUploadInfo.prototype.getAllowFullUploadOverEthernet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.LogUploadInfo} returns this
 */
proto.fox.proto.monitoring.LogUploadInfo.prototype.setAllowFullUploadOverEthernet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isApprovedForAccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isEmailVerified: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isStaff: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    organization: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.Account}
 */
proto.fox.proto.monitoring.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.Account;
  return proto.fox.proto.monitoring.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.Account}
 */
proto.fox.proto.monitoring.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsApprovedForAccess(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEmailVerified(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStaff(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsApprovedForAccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsEmailVerified();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsStaff();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.Account.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Account} returns this
 */
proto.fox.proto.monitoring.Account.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.Account.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Account} returns this
 */
proto.fox.proto.monitoring.Account.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_approved_for_access = 3;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Account.prototype.getIsApprovedForAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Account} returns this
 */
proto.fox.proto.monitoring.Account.prototype.setIsApprovedForAccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_email_verified = 4;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Account.prototype.getIsEmailVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Account} returns this
 */
proto.fox.proto.monitoring.Account.prototype.setIsEmailVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_staff = 5;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Account.prototype.getIsStaff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Account} returns this
 */
proto.fox.proto.monitoring.Account.prototype.setIsStaff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string organization = 6;
 * @return {string}
 */
proto.fox.proto.monitoring.Account.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Account} returns this
 */
proto.fox.proto.monitoring.Account.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.LogInRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.LogInRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.LogInRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.LogInRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.LogInRequest}
 */
proto.fox.proto.monitoring.LogInRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.LogInRequest;
  return proto.fox.proto.monitoring.LogInRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.LogInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.LogInRequest}
 */
proto.fox.proto.monitoring.LogInRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.LogInRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.LogInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.LogInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.LogInRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.LogInRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.LogInRequest} returns this
 */
proto.fox.proto.monitoring.LogInRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.LogInRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.LogInRequest} returns this
 */
proto.fox.proto.monitoring.LogInRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.LogInResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.LogInResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.LogInResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.LogInResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: (f = msg.getAccount()) && proto.fox.proto.monitoring.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.LogInResponse}
 */
proto.fox.proto.monitoring.LogInResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.LogInResponse;
  return proto.fox.proto.monitoring.LogInResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.LogInResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.LogInResponse}
 */
proto.fox.proto.monitoring.LogInResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.Account;
      reader.readMessage(value,proto.fox.proto.monitoring.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.LogInResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.LogInResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.LogInResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.LogInResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.monitoring.Account.serializeBinaryToWriter
    );
  }
};


/**
 * optional string auth_token = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.LogInResponse.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.LogInResponse} returns this
 */
proto.fox.proto.monitoring.LogInResponse.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Account account = 2;
 * @return {?proto.fox.proto.monitoring.Account}
 */
proto.fox.proto.monitoring.LogInResponse.prototype.getAccount = function() {
  return /** @type{?proto.fox.proto.monitoring.Account} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.Account, 2));
};


/**
 * @param {?proto.fox.proto.monitoring.Account|undefined} value
 * @return {!proto.fox.proto.monitoring.LogInResponse} returns this
*/
proto.fox.proto.monitoring.LogInResponse.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.LogInResponse} returns this
 */
proto.fox.proto.monitoring.LogInResponse.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.LogInResponse.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.SignUpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.SignUpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.SignUpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.SignUpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.SignUpRequest}
 */
proto.fox.proto.monitoring.SignUpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.SignUpRequest;
  return proto.fox.proto.monitoring.SignUpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.SignUpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.SignUpRequest}
 */
proto.fox.proto.monitoring.SignUpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.SignUpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.SignUpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.SignUpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.SignUpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.SignUpRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.SignUpRequest} returns this
 */
proto.fox.proto.monitoring.SignUpRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.SignUpRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.SignUpRequest} returns this
 */
proto.fox.proto.monitoring.SignUpRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.SignUpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.SignUpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.SignUpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.SignUpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: (f = msg.getAccount()) && proto.fox.proto.monitoring.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.SignUpResponse}
 */
proto.fox.proto.monitoring.SignUpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.SignUpResponse;
  return proto.fox.proto.monitoring.SignUpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.SignUpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.SignUpResponse}
 */
proto.fox.proto.monitoring.SignUpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.Account;
      reader.readMessage(value,proto.fox.proto.monitoring.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.SignUpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.SignUpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.SignUpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.SignUpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.monitoring.Account.serializeBinaryToWriter
    );
  }
};


/**
 * optional string auth_token = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.SignUpResponse.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.SignUpResponse} returns this
 */
proto.fox.proto.monitoring.SignUpResponse.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Account account = 2;
 * @return {?proto.fox.proto.monitoring.Account}
 */
proto.fox.proto.monitoring.SignUpResponse.prototype.getAccount = function() {
  return /** @type{?proto.fox.proto.monitoring.Account} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.Account, 2));
};


/**
 * @param {?proto.fox.proto.monitoring.Account|undefined} value
 * @return {!proto.fox.proto.monitoring.SignUpResponse} returns this
*/
proto.fox.proto.monitoring.SignUpResponse.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.SignUpResponse} returns this
 */
proto.fox.proto.monitoring.SignUpResponse.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.SignUpResponse.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RunAttributeFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RunAttributeFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RunAttributeFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunAttributeFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RunAttributeFilter}
 */
proto.fox.proto.monitoring.RunAttributeFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RunAttributeFilter;
  return proto.fox.proto.monitoring.RunAttributeFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RunAttributeFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RunAttributeFilter}
 */
proto.fox.proto.monitoring.RunAttributeFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RunAttributeFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RunAttributeFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RunAttributeFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunAttributeFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RunAttributeFilter.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunAttributeFilter} returns this
 */
proto.fox.proto.monitoring.RunAttributeFilter.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.RunAttributeFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunAttributeFilter} returns this
 */
proto.fox.proto.monitoring.RunAttributeFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateRunAttributeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateRunAttributeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    attributeKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    attributeValue: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateRunAttributeRequest}
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateRunAttributeRequest;
  return proto.fox.proto.monitoring.UpdateRunAttributeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateRunAttributeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateRunAttributeRequest}
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributeKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributeValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateRunAttributeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateRunAttributeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAttributeKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAttributeValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRunAttributeRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string attribute_key = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.prototype.getAttributeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRunAttributeRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.prototype.setAttributeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string attribute_value = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.prototype.getAttributeValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRunAttributeRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRunAttributeRequest.prototype.setAttributeValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.DeleteRunAttributeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.DeleteRunAttributeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.DeleteRunAttributeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.DeleteRunAttributeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    attributeKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.DeleteRunAttributeRequest}
 */
proto.fox.proto.monitoring.DeleteRunAttributeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.DeleteRunAttributeRequest;
  return proto.fox.proto.monitoring.DeleteRunAttributeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.DeleteRunAttributeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.DeleteRunAttributeRequest}
 */
proto.fox.proto.monitoring.DeleteRunAttributeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributeKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.DeleteRunAttributeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.DeleteRunAttributeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.DeleteRunAttributeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.DeleteRunAttributeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAttributeKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.DeleteRunAttributeRequest.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DeleteRunAttributeRequest} returns this
 */
proto.fox.proto.monitoring.DeleteRunAttributeRequest.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string attribute_key = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.DeleteRunAttributeRequest.prototype.getAttributeKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DeleteRunAttributeRequest} returns this
 */
proto.fox.proto.monitoring.DeleteRunAttributeRequest.prototype.setAttributeKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.EventFilter.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.EventFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.EventFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.EventFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.EventFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    runNameLike: jspb.Message.getFieldWithDefault(msg, 6, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    organizationNameLike: jspb.Message.getFieldWithDefault(msg, 14, ""),
    cohortDisplayNameLike: jspb.Message.getFieldWithDefault(msg, 13, ""),
    releaseChannel: jspb.Message.getFieldWithDefault(msg, 8, ""),
    flagFile: jspb.Message.getFieldWithDefault(msg, 9, ""),
    versionName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    gitBranchLike: jspb.Message.getFieldWithDefault(msg, 7, ""),
    robotIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    minimumRunMinutes: jspb.Message.getFieldWithDefault(msg, 11, 0),
    runNote: jspb.Message.getFieldWithDefault(msg, 12, ""),
    runIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    attributeFiltersList: jspb.Message.toObjectList(msg.getAttributeFiltersList(),
    proto.fox.proto.monitoring.RunAttributeFilter.toObject, includeInstance),
    logUploadThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    runId: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.EventFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.EventFilter;
  return proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.EventFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunNameLike(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationNameLike(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortDisplayNameLike(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseChannel(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagFile(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGitBranchLike(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addRobotIds(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinimumRunMinutes(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunNote(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addRunIds(value);
      break;
    case 5:
      var value = new proto.fox.proto.monitoring.RunAttributeFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.RunAttributeFilter.deserializeBinaryFromReader);
      msg.addAttributeFilters(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLogUploadThreshold(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.EventFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.EventFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunNameLike();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrganizationNameLike();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCohortDisplayNameLike();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getReleaseChannel();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFlagFile();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getGitBranchLike();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRobotIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getMinimumRunMinutes();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getRunNote();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRunIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getAttributeFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.fox.proto.monitoring.RunAttributeFilter.serializeBinaryToWriter
    );
  }
  f = message.getLogUploadThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string run_name_like = 6;
 * @return {string}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getRunNameLike = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setRunNameLike = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string organization_name_like = 14;
 * @return {string}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getOrganizationNameLike = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setOrganizationNameLike = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string cohort_display_name_like = 13;
 * @return {string}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getCohortDisplayNameLike = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setCohortDisplayNameLike = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string release_channel = 8;
 * @return {string}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getReleaseChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setReleaseChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string flag_file = 9;
 * @return {string}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getFlagFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setFlagFile = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string version_name = 10;
 * @return {string}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string git_branch_like = 7;
 * @return {string}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getGitBranchLike = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setGitBranchLike = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string robot_ids = 3;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getRobotIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setRobotIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.addRobotIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.clearRobotIdsList = function() {
  return this.setRobotIdsList([]);
};


/**
 * optional int64 minimum_run_minutes = 11;
 * @return {number}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getMinimumRunMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setMinimumRunMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string run_note = 12;
 * @return {string}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getRunNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setRunNote = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated string run_ids = 4;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getRunIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setRunIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.addRunIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.clearRunIdsList = function() {
  return this.setRunIdsList([]);
};


/**
 * repeated RunAttributeFilter attribute_filters = 5;
 * @return {!Array<!proto.fox.proto.monitoring.RunAttributeFilter>}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getAttributeFiltersList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.RunAttributeFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.RunAttributeFilter, 5));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.RunAttributeFilter>} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
*/
proto.fox.proto.monitoring.EventFilter.prototype.setAttributeFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.fox.proto.monitoring.RunAttributeFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RunAttributeFilter}
 */
proto.fox.proto.monitoring.EventFilter.prototype.addAttributeFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.fox.proto.monitoring.RunAttributeFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.clearAttributeFiltersList = function() {
  return this.setAttributeFiltersList([]);
};


/**
 * optional float log_upload_threshold = 15;
 * @return {number}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getLogUploadThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setLogUploadThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional string run_id = 16;
 * @return {string}
 */
proto.fox.proto.monitoring.EventFilter.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.EventFilter} returns this
 */
proto.fox.proto.monitoring.EventFilter.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.PickPlaceFilter.repeatedFields_ = [3,4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.PickPlaceFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.PickPlaceFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PickPlaceFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickRowDepthMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    pickRowDepthMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    excludedInterventionTypesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    excludedInterventionCausesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    excusedInterventionCausesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter}
 */
proto.fox.proto.monitoring.PickPlaceFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.PickPlaceFilter;
  return proto.fox.proto.monitoring.PickPlaceFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.PickPlaceFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter}
 */
proto.fox.proto.monitoring.PickPlaceFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPickRowDepthMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPickRowDepthMax(value);
      break;
    case 3:
      var value = /** @type {!Array<!proto.fox.proto.command_control.InterventionType>} */ (reader.readPackedEnum());
      msg.setExcludedInterventionTypesList(value);
      break;
    case 4:
      var value = /** @type {!Array<!proto.fox.proto.command_control.InterventionCause>} */ (reader.readPackedEnum());
      msg.setExcludedInterventionCausesList(value);
      break;
    case 6:
      var value = /** @type {!Array<!proto.fox.proto.command_control.InterventionCause>} */ (reader.readPackedEnum());
      msg.setExcusedInterventionCausesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.PickPlaceFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.PickPlaceFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PickPlaceFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickRowDepthMin();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getPickRowDepthMax();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getExcludedInterventionTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getExcludedInterventionCausesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getExcusedInterventionCausesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
};


/**
 * optional double pick_row_depth_min = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.getPickRowDepthMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter} returns this
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.setPickRowDepthMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double pick_row_depth_max = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.getPickRowDepthMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter} returns this
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.setPickRowDepthMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated fox.proto.command_control.InterventionType excluded_intervention_types = 3;
 * @return {!Array<!proto.fox.proto.command_control.InterventionType>}
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.getExcludedInterventionTypesList = function() {
  return /** @type {!Array<!proto.fox.proto.command_control.InterventionType>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.InterventionType>} value
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter} returns this
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.setExcludedInterventionTypesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.fox.proto.command_control.InterventionType} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter} returns this
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.addExcludedInterventionTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter} returns this
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.clearExcludedInterventionTypesList = function() {
  return this.setExcludedInterventionTypesList([]);
};


/**
 * repeated fox.proto.command_control.InterventionCause excluded_intervention_causes = 4;
 * @return {!Array<!proto.fox.proto.command_control.InterventionCause>}
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.getExcludedInterventionCausesList = function() {
  return /** @type {!Array<!proto.fox.proto.command_control.InterventionCause>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.InterventionCause>} value
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter} returns this
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.setExcludedInterventionCausesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.fox.proto.command_control.InterventionCause} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter} returns this
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.addExcludedInterventionCauses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter} returns this
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.clearExcludedInterventionCausesList = function() {
  return this.setExcludedInterventionCausesList([]);
};


/**
 * repeated fox.proto.command_control.InterventionCause excused_intervention_causes = 6;
 * @return {!Array<!proto.fox.proto.command_control.InterventionCause>}
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.getExcusedInterventionCausesList = function() {
  return /** @type {!Array<!proto.fox.proto.command_control.InterventionCause>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.InterventionCause>} value
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter} returns this
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.setExcusedInterventionCausesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.fox.proto.command_control.InterventionCause} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter} returns this
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.addExcusedInterventionCauses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PickPlaceFilter} returns this
 */
proto.fox.proto.monitoring.PickPlaceFilter.prototype.clearExcusedInterventionCausesList = function() {
  return this.setExcusedInterventionCausesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.TimeBucketValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.TimeBucketValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TimeBucketValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mean: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    median: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0),
    stdDev: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.TimeBucketValue}
 */
proto.fox.proto.monitoring.TimeBucketValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.TimeBucketValue;
  return proto.fox.proto.monitoring.TimeBucketValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.TimeBucketValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.TimeBucketValue}
 */
proto.fox.proto.monitoring.TimeBucketValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMean(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMedian(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStdDev(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.TimeBucketValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.TimeBucketValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TimeBucketValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMean();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMedian();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStdDev();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TimeBucketValue} returns this
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double mean = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.getMean = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TimeBucketValue} returns this
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.setMean = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double median = 5;
 * @return {number}
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.getMedian = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TimeBucketValue} returns this
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.setMedian = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TimeBucketValue} returns this
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double std_dev = 4;
 * @return {number}
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.getStdDev = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TimeBucketValue} returns this
 */
proto.fox.proto.monitoring.TimeBucketValue.prototype.setStdDev = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetTimeSeriesMetricRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFilter: (f = msg.getEventFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f),
    pickPlaceFilter: (f = msg.getPickPlaceFilter()) && proto.fox.proto.monitoring.PickPlaceFilter.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bucketDuration: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetTimeSeriesMetricRequest}
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetTimeSeriesMetricRequest;
  return proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetTimeSeriesMetricRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetTimeSeriesMetricRequest}
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setEventFilter(value);
      break;
    case 4:
      var value = new proto.fox.proto.monitoring.PickPlaceFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.PickPlaceFilter.deserializeBinaryFromReader);
      msg.setPickPlaceFilter(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.monitoring.TimeSeriesMetricType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBucketDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetTimeSeriesMetricRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getPickPlaceFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.monitoring.PickPlaceFilter.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBucketDuration();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional EventFilter event_filter = 1;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.getEventFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.GetTimeSeriesMetricRequest} returns this
*/
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.setEventFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.GetTimeSeriesMetricRequest} returns this
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.clearEventFilter = function() {
  return this.setEventFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.hasEventFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PickPlaceFilter pick_place_filter = 4;
 * @return {?proto.fox.proto.monitoring.PickPlaceFilter}
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.getPickPlaceFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.PickPlaceFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.PickPlaceFilter, 4));
};


/**
 * @param {?proto.fox.proto.monitoring.PickPlaceFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.GetTimeSeriesMetricRequest} returns this
*/
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.setPickPlaceFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.GetTimeSeriesMetricRequest} returns this
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.clearPickPlaceFilter = function() {
  return this.setPickPlaceFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.hasPickPlaceFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TimeSeriesMetricType type = 2;
 * @return {!proto.fox.proto.monitoring.TimeSeriesMetricType}
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.getType = function() {
  return /** @type {!proto.fox.proto.monitoring.TimeSeriesMetricType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.TimeSeriesMetricType} value
 * @return {!proto.fox.proto.monitoring.GetTimeSeriesMetricRequest} returns this
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 bucket_duration = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.getBucketDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.GetTimeSeriesMetricRequest} returns this
 */
proto.fox.proto.monitoring.GetTimeSeriesMetricRequest.prototype.setBucketDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.TimeSeriesMetric.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.TimeSeriesMetric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.TimeSeriesMetric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TimeSeriesMetric.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFilter: (f = msg.getEventFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bucketDuration: jspb.Message.getFieldWithDefault(msg, 3, 0),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.fox.proto.monitoring.TimeBucketValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.TimeSeriesMetric}
 */
proto.fox.proto.monitoring.TimeSeriesMetric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.TimeSeriesMetric;
  return proto.fox.proto.monitoring.TimeSeriesMetric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.TimeSeriesMetric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.TimeSeriesMetric}
 */
proto.fox.proto.monitoring.TimeSeriesMetric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setEventFilter(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.monitoring.TimeSeriesMetricType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBucketDuration(value);
      break;
    case 4:
      var value = new proto.fox.proto.monitoring.TimeBucketValue;
      reader.readMessage(value,proto.fox.proto.monitoring.TimeBucketValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.TimeSeriesMetric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.TimeSeriesMetric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TimeSeriesMetric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBucketDuration();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.fox.proto.monitoring.TimeBucketValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventFilter event_filter = 1;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.getEventFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.TimeSeriesMetric} returns this
*/
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.setEventFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.TimeSeriesMetric} returns this
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.clearEventFilter = function() {
  return this.setEventFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.hasEventFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimeSeriesMetricType type = 2;
 * @return {!proto.fox.proto.monitoring.TimeSeriesMetricType}
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.getType = function() {
  return /** @type {!proto.fox.proto.monitoring.TimeSeriesMetricType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.TimeSeriesMetricType} value
 * @return {!proto.fox.proto.monitoring.TimeSeriesMetric} returns this
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 bucket_duration = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.getBucketDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TimeSeriesMetric} returns this
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.setBucketDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated TimeBucketValue values = 4;
 * @return {!Array<!proto.fox.proto.monitoring.TimeBucketValue>}
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.TimeBucketValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.TimeBucketValue, 4));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.TimeBucketValue>} value
 * @return {!proto.fox.proto.monitoring.TimeSeriesMetric} returns this
*/
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.fox.proto.monitoring.TimeBucketValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.TimeBucketValue}
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.fox.proto.monitoring.TimeBucketValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.TimeSeriesMetric} returns this
 */
proto.fox.proto.monitoring.TimeSeriesMetric.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetScalarMetricRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetScalarMetricRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetScalarMetricRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetScalarMetricRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFilter: (f = msg.getEventFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetScalarMetricRequest}
 */
proto.fox.proto.monitoring.GetScalarMetricRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetScalarMetricRequest;
  return proto.fox.proto.monitoring.GetScalarMetricRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetScalarMetricRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetScalarMetricRequest}
 */
proto.fox.proto.monitoring.GetScalarMetricRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setEventFilter(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.monitoring.ScalarMetricType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetScalarMetricRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetScalarMetricRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetScalarMetricRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetScalarMetricRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional EventFilter event_filter = 1;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.GetScalarMetricRequest.prototype.getEventFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.GetScalarMetricRequest} returns this
*/
proto.fox.proto.monitoring.GetScalarMetricRequest.prototype.setEventFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.GetScalarMetricRequest} returns this
 */
proto.fox.proto.monitoring.GetScalarMetricRequest.prototype.clearEventFilter = function() {
  return this.setEventFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.GetScalarMetricRequest.prototype.hasEventFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScalarMetricType type = 2;
 * @return {!proto.fox.proto.monitoring.ScalarMetricType}
 */
proto.fox.proto.monitoring.GetScalarMetricRequest.prototype.getType = function() {
  return /** @type {!proto.fox.proto.monitoring.ScalarMetricType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.ScalarMetricType} value
 * @return {!proto.fox.proto.monitoring.GetScalarMetricRequest} returns this
 */
proto.fox.proto.monitoring.GetScalarMetricRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ScalarMetric.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ScalarMetric.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ScalarMetric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ScalarMetric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ScalarMetric.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFilter: (f = msg.getEventFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.fox.proto.monitoring.ScalarMetric.Result.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ScalarMetric}
 */
proto.fox.proto.monitoring.ScalarMetric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ScalarMetric;
  return proto.fox.proto.monitoring.ScalarMetric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ScalarMetric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ScalarMetric}
 */
proto.fox.proto.monitoring.ScalarMetric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setEventFilter(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.monitoring.ScalarMetricType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.fox.proto.monitoring.ScalarMetric.Result;
      reader.readMessage(value,proto.fox.proto.monitoring.ScalarMetric.Result.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ScalarMetric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ScalarMetric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ScalarMetric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ScalarMetric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.monitoring.ScalarMetric.Result.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ScalarMetric.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ScalarMetric.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ScalarMetric.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ScalarMetric.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ScalarMetric.Result}
 */
proto.fox.proto.monitoring.ScalarMetric.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ScalarMetric.Result;
  return proto.fox.proto.monitoring.ScalarMetric.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ScalarMetric.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ScalarMetric.Result}
 */
proto.fox.proto.monitoring.ScalarMetric.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ScalarMetric.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ScalarMetric.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ScalarMetric.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ScalarMetric.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.ScalarMetric.Result.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ScalarMetric.Result} returns this
 */
proto.fox.proto.monitoring.ScalarMetric.Result.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ScalarMetric.Result.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ScalarMetric.Result} returns this
 */
proto.fox.proto.monitoring.ScalarMetric.Result.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional EventFilter event_filter = 1;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.ScalarMetric.prototype.getEventFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.ScalarMetric} returns this
*/
proto.fox.proto.monitoring.ScalarMetric.prototype.setEventFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ScalarMetric} returns this
 */
proto.fox.proto.monitoring.ScalarMetric.prototype.clearEventFilter = function() {
  return this.setEventFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ScalarMetric.prototype.hasEventFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ScalarMetricType type = 2;
 * @return {!proto.fox.proto.monitoring.ScalarMetricType}
 */
proto.fox.proto.monitoring.ScalarMetric.prototype.getType = function() {
  return /** @type {!proto.fox.proto.monitoring.ScalarMetricType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.ScalarMetricType} value
 * @return {!proto.fox.proto.monitoring.ScalarMetric} returns this
 */
proto.fox.proto.monitoring.ScalarMetric.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated Result results = 3;
 * @return {!Array<!proto.fox.proto.monitoring.ScalarMetric.Result>}
 */
proto.fox.proto.monitoring.ScalarMetric.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ScalarMetric.Result>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ScalarMetric.Result, 3));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ScalarMetric.Result>} value
 * @return {!proto.fox.proto.monitoring.ScalarMetric} returns this
*/
proto.fox.proto.monitoring.ScalarMetric.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ScalarMetric.Result=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ScalarMetric.Result}
 */
proto.fox.proto.monitoring.ScalarMetric.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.monitoring.ScalarMetric.Result, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ScalarMetric} returns this
 */
proto.fox.proto.monitoring.ScalarMetric.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetRunPlacedPalletsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetRunPlacedPalletsRequest}
 */
proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetRunPlacedPalletsRequest;
  return proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetRunPlacedPalletsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetRunPlacedPalletsRequest}
 */
proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetRunPlacedPalletsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.GetRunPlacedPalletsRequest} returns this
 */
proto.fox.proto.monitoring.GetRunPlacedPalletsRequest.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.PlacedPalletIntervention.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.PlacedPalletIntervention} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mechanism: jspb.Message.getFieldWithDefault(msg, 3, 0),
    palletId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exclude: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.PlacedPalletIntervention}
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.PlacedPalletIntervention;
  return proto.fox.proto.monitoring.PlacedPalletIntervention.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.PlacedPalletIntervention} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.PlacedPalletIntervention}
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.monitoring.InterventionMechanism} */ (reader.readEnum());
      msg.setMechanism(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPalletId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExclude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.PlacedPalletIntervention.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.PlacedPalletIntervention} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMechanism();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPalletId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExclude();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PlacedPalletIntervention} returns this
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PlacedPalletIntervention} returns this
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional InterventionMechanism mechanism = 3;
 * @return {!proto.fox.proto.monitoring.InterventionMechanism}
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.getMechanism = function() {
  return /** @type {!proto.fox.proto.monitoring.InterventionMechanism} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.InterventionMechanism} value
 * @return {!proto.fox.proto.monitoring.PlacedPalletIntervention} returns this
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.setMechanism = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string pallet_id = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.getPalletId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PlacedPalletIntervention} returns this
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.setPalletId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool exclude = 5;
 * @return {boolean}
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.getExclude = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.PlacedPalletIntervention} returns this
 */
proto.fox.proto.monitoring.PlacedPalletIntervention.prototype.setExclude = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.PlacedPalletDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.PlacedPalletDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.PlacedPalletDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PlacedPalletDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    palletId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pickTime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.PlacedPalletDetails}
 */
proto.fox.proto.monitoring.PlacedPalletDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.PlacedPalletDetails;
  return proto.fox.proto.monitoring.PlacedPalletDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.PlacedPalletDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.PlacedPalletDetails}
 */
proto.fox.proto.monitoring.PlacedPalletDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPalletId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.PlacedPalletDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.PlacedPalletDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.PlacedPalletDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PlacedPalletDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPalletId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPickTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string pallet_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.PlacedPalletDetails.prototype.getPalletId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PlacedPalletDetails} returns this
 */
proto.fox.proto.monitoring.PlacedPalletDetails.prototype.setPalletId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_time = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.PlacedPalletDetails.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PlacedPalletDetails} returns this
 */
proto.fox.proto.monitoring.PlacedPalletDetails.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_time = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.PlacedPalletDetails.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PlacedPalletDetails} returns this
 */
proto.fox.proto.monitoring.PlacedPalletDetails.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 pick_time = 4;
 * @return {number}
 */
proto.fox.proto.monitoring.PlacedPalletDetails.prototype.getPickTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PlacedPalletDetails} returns this
 */
proto.fox.proto.monitoring.PlacedPalletDetails.prototype.setPickTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.RunPlacedPallets.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RunPlacedPallets.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RunPlacedPallets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RunPlacedPallets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunPlacedPallets.toObject = function(includeInstance, msg) {
  var f, obj = {
    runName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    placedPalletsList: jspb.Message.toObjectList(msg.getPlacedPalletsList(),
    proto.fox.proto.monitoring.PlacedPalletDetails.toObject, includeInstance),
    interventionsList: jspb.Message.toObjectList(msg.getInterventionsList(),
    proto.fox.proto.monitoring.PlacedPalletIntervention.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RunPlacedPallets}
 */
proto.fox.proto.monitoring.RunPlacedPallets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RunPlacedPallets;
  return proto.fox.proto.monitoring.RunPlacedPallets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RunPlacedPallets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RunPlacedPallets}
 */
proto.fox.proto.monitoring.RunPlacedPallets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.PlacedPalletDetails;
      reader.readMessage(value,proto.fox.proto.monitoring.PlacedPalletDetails.deserializeBinaryFromReader);
      msg.addPlacedPallets(value);
      break;
    case 4:
      var value = new proto.fox.proto.monitoring.PlacedPalletIntervention;
      reader.readMessage(value,proto.fox.proto.monitoring.PlacedPalletIntervention.deserializeBinaryFromReader);
      msg.addInterventions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RunPlacedPallets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RunPlacedPallets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RunPlacedPallets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunPlacedPallets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlacedPalletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.monitoring.PlacedPalletDetails.serializeBinaryToWriter
    );
  }
  f = message.getInterventionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.fox.proto.monitoring.PlacedPalletIntervention.serializeBinaryToWriter
    );
  }
};


/**
 * optional string run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RunPlacedPallets.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunPlacedPallets} returns this
 */
proto.fox.proto.monitoring.RunPlacedPallets.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PlacedPalletDetails placed_pallets = 2;
 * @return {!Array<!proto.fox.proto.monitoring.PlacedPalletDetails>}
 */
proto.fox.proto.monitoring.RunPlacedPallets.prototype.getPlacedPalletsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.PlacedPalletDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.PlacedPalletDetails, 2));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.PlacedPalletDetails>} value
 * @return {!proto.fox.proto.monitoring.RunPlacedPallets} returns this
*/
proto.fox.proto.monitoring.RunPlacedPallets.prototype.setPlacedPalletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.monitoring.PlacedPalletDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PlacedPalletDetails}
 */
proto.fox.proto.monitoring.RunPlacedPallets.prototype.addPlacedPallets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.monitoring.PlacedPalletDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RunPlacedPallets} returns this
 */
proto.fox.proto.monitoring.RunPlacedPallets.prototype.clearPlacedPalletsList = function() {
  return this.setPlacedPalletsList([]);
};


/**
 * repeated PlacedPalletIntervention interventions = 4;
 * @return {!Array<!proto.fox.proto.monitoring.PlacedPalletIntervention>}
 */
proto.fox.proto.monitoring.RunPlacedPallets.prototype.getInterventionsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.PlacedPalletIntervention>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.PlacedPalletIntervention, 4));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.PlacedPalletIntervention>} value
 * @return {!proto.fox.proto.monitoring.RunPlacedPallets} returns this
*/
proto.fox.proto.monitoring.RunPlacedPallets.prototype.setInterventionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.fox.proto.monitoring.PlacedPalletIntervention=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PlacedPalletIntervention}
 */
proto.fox.proto.monitoring.RunPlacedPallets.prototype.addInterventions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.fox.proto.monitoring.PlacedPalletIntervention, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RunPlacedPallets} returns this
 */
proto.fox.proto.monitoring.RunPlacedPallets.prototype.clearInterventionsList = function() {
  return this.setInterventionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.SetExcludeInterventionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.SetExcludeInterventionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    robotName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    runName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mechanism: jspb.Message.getFieldWithDefault(msg, 4, 0),
    exclude: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.SetExcludeInterventionRequest}
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.SetExcludeInterventionRequest;
  return proto.fox.proto.monitoring.SetExcludeInterventionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.SetExcludeInterventionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.SetExcludeInterventionRequest}
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.monitoring.InterventionMechanism} */ (reader.readEnum());
      msg.setMechanism(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExclude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.SetExcludeInterventionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.SetExcludeInterventionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMechanism();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getExclude();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.SetExcludeInterventionRequest} returns this
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string robot_name = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.SetExcludeInterventionRequest} returns this
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string run_name = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.SetExcludeInterventionRequest} returns this
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional InterventionMechanism mechanism = 4;
 * @return {!proto.fox.proto.monitoring.InterventionMechanism}
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.getMechanism = function() {
  return /** @type {!proto.fox.proto.monitoring.InterventionMechanism} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.InterventionMechanism} value
 * @return {!proto.fox.proto.monitoring.SetExcludeInterventionRequest} returns this
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.setMechanism = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool exclude = 6;
 * @return {boolean}
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.getExclude = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.SetExcludeInterventionRequest} returns this
 */
proto.fox.proto.monitoring.SetExcludeInterventionRequest.prototype.setExclude = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ExternalBug.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ExternalBug.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ExternalBug.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ExternalBug} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ExternalBug.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    runNamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    annotationIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ExternalBug}
 */
proto.fox.proto.monitoring.ExternalBug.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ExternalBug;
  return proto.fox.proto.monitoring.ExternalBug.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ExternalBug} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ExternalBug}
 */
proto.fox.proto.monitoring.ExternalBug.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addRunNames(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAnnotationIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ExternalBug.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ExternalBug.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ExternalBug} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ExternalBug.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRunNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAnnotationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ExternalBug.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ExternalBug} returns this
 */
proto.fox.proto.monitoring.ExternalBug.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ExternalBug.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ExternalBug} returns this
 */
proto.fox.proto.monitoring.ExternalBug.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string run_names = 3;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.ExternalBug.prototype.getRunNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.ExternalBug} returns this
 */
proto.fox.proto.monitoring.ExternalBug.prototype.setRunNamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ExternalBug} returns this
 */
proto.fox.proto.monitoring.ExternalBug.prototype.addRunNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ExternalBug} returns this
 */
proto.fox.proto.monitoring.ExternalBug.prototype.clearRunNamesList = function() {
  return this.setRunNamesList([]);
};


/**
 * repeated string annotation_ids = 4;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.ExternalBug.prototype.getAnnotationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.ExternalBug} returns this
 */
proto.fox.proto.monitoring.ExternalBug.prototype.setAnnotationIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ExternalBug} returns this
 */
proto.fox.proto.monitoring.ExternalBug.prototype.addAnnotationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ExternalBug} returns this
 */
proto.fox.proto.monitoring.ExternalBug.prototype.clearAnnotationIdsList = function() {
  return this.setAnnotationIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListExternalBugsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListExternalBugsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, 0),
    runFilter: (f = msg.getRunFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListExternalBugsRequest}
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListExternalBugsRequest;
  return proto.fox.proto.monitoring.ListExternalBugsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListExternalBugsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListExternalBugsRequest}
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageToken(value);
      break;
    case 3:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setRunFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListExternalBugsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListExternalBugsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRunFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListExternalBugsRequest} returns this
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_token = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.prototype.getPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListExternalBugsRequest} returns this
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional EventFilter run_filter = 3;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.prototype.getRunFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 3));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.ListExternalBugsRequest} returns this
*/
proto.fox.proto.monitoring.ListExternalBugsRequest.prototype.setRunFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ListExternalBugsRequest} returns this
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.prototype.clearRunFilter = function() {
  return this.setRunFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListExternalBugsRequest.prototype.hasRunFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListExternalBugsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListExternalBugsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    externalBugsList: jspb.Message.toObjectList(msg.getExternalBugsList(),
    proto.fox.proto.monitoring.ExternalBug.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListExternalBugsResponse}
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListExternalBugsResponse;
  return proto.fox.proto.monitoring.ListExternalBugsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListExternalBugsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListExternalBugsResponse}
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.ExternalBug;
      reader.readMessage(value,proto.fox.proto.monitoring.ExternalBug.deserializeBinaryFromReader);
      msg.addExternalBugs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListExternalBugsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListExternalBugsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExternalBugsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.ExternalBug.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated ExternalBug external_bugs = 1;
 * @return {!Array<!proto.fox.proto.monitoring.ExternalBug>}
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.prototype.getExternalBugsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ExternalBug>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ExternalBug, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ExternalBug>} value
 * @return {!proto.fox.proto.monitoring.ListExternalBugsResponse} returns this
*/
proto.fox.proto.monitoring.ListExternalBugsResponse.prototype.setExternalBugsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ExternalBug=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ExternalBug}
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.prototype.addExternalBugs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.ExternalBug, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListExternalBugsResponse} returns this
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.prototype.clearExternalBugsList = function() {
  return this.setExternalBugsList([]);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListExternalBugsResponse} returns this
 */
proto.fox.proto.monitoring.ListExternalBugsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.Annotation.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.Annotation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.Annotation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Annotation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 13, ""),
    runId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    intervention: jspb.Message.getFieldWithDefault(msg, 9, 0),
    interventionCause: jspb.Message.getFieldWithDefault(msg, 14, 0),
    palletDamage: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    goodsDamage: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    eventTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    externalBug: (f = msg.getExternalBug()) && proto.fox.proto.monitoring.ExternalBug.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.Annotation}
 */
proto.fox.proto.monitoring.Annotation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.Annotation;
  return proto.fox.proto.monitoring.Annotation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.Annotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.Annotation}
 */
proto.fox.proto.monitoring.Annotation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {!proto.fox.proto.command_control.InterventionType} */ (reader.readEnum());
      msg.setIntervention(value);
      break;
    case 14:
      var value = /** @type {!proto.fox.proto.command_control.InterventionCause} */ (reader.readEnum());
      msg.setInterventionCause(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPalletDamage(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoodsDamage(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventTime(value);
      break;
    case 15:
      var value = new proto.fox.proto.monitoring.ExternalBug;
      reader.readMessage(value,proto.fox.proto.monitoring.ExternalBug.deserializeBinaryFromReader);
      msg.setExternalBug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.Annotation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.Annotation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.Annotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Annotation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIntervention();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getInterventionCause();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getPalletDamage();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getGoodsDamage();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getEventTime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getExternalBug();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.fox.proto.monitoring.ExternalBug.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 13;
 * @return {string}
 */
proto.fox.proto.monitoring.Annotation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Annotation} returns this
 */
proto.fox.proto.monitoring.Annotation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string run_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.Annotation.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Annotation} returns this
 */
proto.fox.proto.monitoring.Annotation.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.Annotation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Annotation} returns this
 */
proto.fox.proto.monitoring.Annotation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional fox.proto.command_control.InterventionType intervention = 9;
 * @return {!proto.fox.proto.command_control.InterventionType}
 */
proto.fox.proto.monitoring.Annotation.prototype.getIntervention = function() {
  return /** @type {!proto.fox.proto.command_control.InterventionType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.fox.proto.command_control.InterventionType} value
 * @return {!proto.fox.proto.monitoring.Annotation} returns this
 */
proto.fox.proto.monitoring.Annotation.prototype.setIntervention = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional fox.proto.command_control.InterventionCause intervention_cause = 14;
 * @return {!proto.fox.proto.command_control.InterventionCause}
 */
proto.fox.proto.monitoring.Annotation.prototype.getInterventionCause = function() {
  return /** @type {!proto.fox.proto.command_control.InterventionCause} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.fox.proto.command_control.InterventionCause} value
 * @return {!proto.fox.proto.monitoring.Annotation} returns this
 */
proto.fox.proto.monitoring.Annotation.prototype.setInterventionCause = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional bool pallet_damage = 10;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Annotation.prototype.getPalletDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Annotation} returns this
 */
proto.fox.proto.monitoring.Annotation.prototype.setPalletDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool goods_damage = 11;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Annotation.prototype.getGoodsDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Annotation} returns this
 */
proto.fox.proto.monitoring.Annotation.prototype.setGoodsDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int64 event_time = 12;
 * @return {number}
 */
proto.fox.proto.monitoring.Annotation.prototype.getEventTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Annotation} returns this
 */
proto.fox.proto.monitoring.Annotation.prototype.setEventTime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional ExternalBug external_bug = 15;
 * @return {?proto.fox.proto.monitoring.ExternalBug}
 */
proto.fox.proto.monitoring.Annotation.prototype.getExternalBug = function() {
  return /** @type{?proto.fox.proto.monitoring.ExternalBug} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.ExternalBug, 15));
};


/**
 * @param {?proto.fox.proto.monitoring.ExternalBug|undefined} value
 * @return {!proto.fox.proto.monitoring.Annotation} returns this
*/
proto.fox.proto.monitoring.Annotation.prototype.setExternalBug = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.Annotation} returns this
 */
proto.fox.proto.monitoring.Annotation.prototype.clearExternalBug = function() {
  return this.setExternalBug(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.Annotation.prototype.hasExternalBug = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.CreateAnnotationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.CreateAnnotationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.CreateAnnotationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateAnnotationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotation: (f = msg.getAnnotation()) && proto.fox.proto.monitoring.Annotation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.CreateAnnotationRequest}
 */
proto.fox.proto.monitoring.CreateAnnotationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.CreateAnnotationRequest;
  return proto.fox.proto.monitoring.CreateAnnotationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.CreateAnnotationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.CreateAnnotationRequest}
 */
proto.fox.proto.monitoring.CreateAnnotationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.Annotation;
      reader.readMessage(value,proto.fox.proto.monitoring.Annotation.deserializeBinaryFromReader);
      msg.setAnnotation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.CreateAnnotationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.CreateAnnotationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.CreateAnnotationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateAnnotationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.Annotation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Annotation annotation = 1;
 * @return {?proto.fox.proto.monitoring.Annotation}
 */
proto.fox.proto.monitoring.CreateAnnotationRequest.prototype.getAnnotation = function() {
  return /** @type{?proto.fox.proto.monitoring.Annotation} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.Annotation, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.Annotation|undefined} value
 * @return {!proto.fox.proto.monitoring.CreateAnnotationRequest} returns this
*/
proto.fox.proto.monitoring.CreateAnnotationRequest.prototype.setAnnotation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.CreateAnnotationRequest} returns this
 */
proto.fox.proto.monitoring.CreateAnnotationRequest.prototype.clearAnnotation = function() {
  return this.setAnnotation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.CreateAnnotationRequest.prototype.hasAnnotation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateAnnotationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateAnnotationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotation: (f = msg.getAnnotation()) && proto.fox.proto.monitoring.Annotation.toObject(includeInstance, f),
    fieldMask: (f = msg.getFieldMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateAnnotationRequest}
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateAnnotationRequest;
  return proto.fox.proto.monitoring.UpdateAnnotationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateAnnotationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateAnnotationRequest}
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.Annotation;
      reader.readMessage(value,proto.fox.proto.monitoring.Annotation.deserializeBinaryFromReader);
      msg.setAnnotation(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setFieldMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateAnnotationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateAnnotationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.Annotation.serializeBinaryToWriter
    );
  }
  f = message.getFieldMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Annotation annotation = 1;
 * @return {?proto.fox.proto.monitoring.Annotation}
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.prototype.getAnnotation = function() {
  return /** @type{?proto.fox.proto.monitoring.Annotation} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.Annotation, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.Annotation|undefined} value
 * @return {!proto.fox.proto.monitoring.UpdateAnnotationRequest} returns this
*/
proto.fox.proto.monitoring.UpdateAnnotationRequest.prototype.setAnnotation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.UpdateAnnotationRequest} returns this
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.prototype.clearAnnotation = function() {
  return this.setAnnotation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.prototype.hasAnnotation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask field_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.prototype.getFieldMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.fox.proto.monitoring.UpdateAnnotationRequest} returns this
*/
proto.fox.proto.monitoring.UpdateAnnotationRequest.prototype.setFieldMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.UpdateAnnotationRequest} returns this
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.prototype.clearFieldMask = function() {
  return this.setFieldMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.UpdateAnnotationRequest.prototype.hasFieldMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.DeleteAnnotationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.DeleteAnnotationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.DeleteAnnotationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.DeleteAnnotationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotationId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.DeleteAnnotationRequest}
 */
proto.fox.proto.monitoring.DeleteAnnotationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.DeleteAnnotationRequest;
  return proto.fox.proto.monitoring.DeleteAnnotationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.DeleteAnnotationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.DeleteAnnotationRequest}
 */
proto.fox.proto.monitoring.DeleteAnnotationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnotationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.DeleteAnnotationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.DeleteAnnotationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.DeleteAnnotationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.DeleteAnnotationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string annotation_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.DeleteAnnotationRequest.prototype.getAnnotationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DeleteAnnotationRequest} returns this
 */
proto.fox.proto.monitoring.DeleteAnnotationRequest.prototype.setAnnotationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListPickStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListPickStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListPickStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFilter: (f = msg.getEventFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    excludeTimingStats: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    excludePalletContext: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    excludeDriveStats: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    excludeManualModeStats: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    excludeHumanAssistanceRequests: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    excludeRunNotes: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    excludeStoppages: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    excludeInterventions: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    excludePathFollowerStatus: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    excludePlasticDetections: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListPickStatsRequest;
  return proto.fox.proto.monitoring.ListPickStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListPickStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setEventFilter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeTimingStats(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludePalletContext(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeDriveStats(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeManualModeStats(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeHumanAssistanceRequests(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeRunNotes(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeStoppages(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeInterventions(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludePathFollowerStatus(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludePlasticDetections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListPickStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListPickStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListPickStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExcludeTimingStats();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getExcludePalletContext();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getExcludeDriveStats();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getExcludeManualModeStats();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExcludeHumanAssistanceRequests();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getExcludeRunNotes();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getExcludeStoppages();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getExcludeInterventions();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getExcludePathFollowerStatus();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getExcludePlasticDetections();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional EventFilter event_filter = 1;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getEventFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
*/
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setEventFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.clearEventFilter = function() {
  return this.setEventFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.hasEventFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool exclude_timing_stats = 3;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getExcludeTimingStats = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setExcludeTimingStats = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool exclude_pallet_context = 4;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getExcludePalletContext = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setExcludePalletContext = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool exclude_drive_stats = 5;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getExcludeDriveStats = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setExcludeDriveStats = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool exclude_manual_mode_stats = 6;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getExcludeManualModeStats = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setExcludeManualModeStats = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool exclude_human_assistance_requests = 7;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getExcludeHumanAssistanceRequests = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setExcludeHumanAssistanceRequests = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool exclude_run_notes = 8;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getExcludeRunNotes = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setExcludeRunNotes = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool exclude_stoppages = 9;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getExcludeStoppages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setExcludeStoppages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool exclude_interventions = 10;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getExcludeInterventions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setExcludeInterventions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool exclude_path_follower_status = 11;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getExcludePathFollowerStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setExcludePathFollowerStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool exclude_plastic_detections = 12;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.getExcludePlasticDetections = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListPickStatsRequest.prototype.setExcludePlasticDetections = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListPickStatsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListPickStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListPickStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListPickStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListPickStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickStatsList: jspb.Message.toObjectList(msg.getPickStatsList(),
    proto.fox.proto.monitoring.PickStats.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListPickStatsResponse}
 */
proto.fox.proto.monitoring.ListPickStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListPickStatsResponse;
  return proto.fox.proto.monitoring.ListPickStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListPickStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListPickStatsResponse}
 */
proto.fox.proto.monitoring.ListPickStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.PickStats;
      reader.readMessage(value,proto.fox.proto.monitoring.PickStats.deserializeBinaryFromReader);
      msg.addPickStats(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListPickStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListPickStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListPickStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListPickStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.PickStats.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated PickStats pick_stats = 1;
 * @return {!Array<!proto.fox.proto.monitoring.PickStats>}
 */
proto.fox.proto.monitoring.ListPickStatsResponse.prototype.getPickStatsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.PickStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.PickStats, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.PickStats>} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsResponse} returns this
*/
proto.fox.proto.monitoring.ListPickStatsResponse.prototype.setPickStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.PickStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PickStats}
 */
proto.fox.proto.monitoring.ListPickStatsResponse.prototype.addPickStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.PickStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListPickStatsResponse} returns this
 */
proto.fox.proto.monitoring.ListPickStatsResponse.prototype.clearPickStatsList = function() {
  return this.setPickStatsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ListPickStatsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsResponse} returns this
 */
proto.fox.proto.monitoring.ListPickStatsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListPickStatsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListPickStatsResponse} returns this
 */
proto.fox.proto.monitoring.ListPickStatsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListRunStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListRunStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListRunStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRunStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFilter: (f = msg.getEventFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListRunStatsRequest}
 */
proto.fox.proto.monitoring.ListRunStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListRunStatsRequest;
  return proto.fox.proto.monitoring.ListRunStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListRunStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListRunStatsRequest}
 */
proto.fox.proto.monitoring.ListRunStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setEventFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListRunStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListRunStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListRunStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRunStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventFilter event_filter = 1;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.ListRunStatsRequest.prototype.getEventFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.ListRunStatsRequest} returns this
*/
proto.fox.proto.monitoring.ListRunStatsRequest.prototype.setEventFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ListRunStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListRunStatsRequest.prototype.clearEventFilter = function() {
  return this.setEventFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListRunStatsRequest.prototype.hasEventFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListRunStatsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListRunStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListRunStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListRunStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRunStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    runStatsList: jspb.Message.toObjectList(msg.getRunStatsList(),
    proto.fox.proto.monitoring.RunStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListRunStatsResponse}
 */
proto.fox.proto.monitoring.ListRunStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListRunStatsResponse;
  return proto.fox.proto.monitoring.ListRunStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListRunStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListRunStatsResponse}
 */
proto.fox.proto.monitoring.ListRunStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.RunStats;
      reader.readMessage(value,proto.fox.proto.monitoring.RunStats.deserializeBinaryFromReader);
      msg.addRunStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListRunStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListRunStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListRunStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRunStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.RunStats.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RunStats run_stats = 1;
 * @return {!Array<!proto.fox.proto.monitoring.RunStats>}
 */
proto.fox.proto.monitoring.ListRunStatsResponse.prototype.getRunStatsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.RunStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.RunStats, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.RunStats>} value
 * @return {!proto.fox.proto.monitoring.ListRunStatsResponse} returns this
*/
proto.fox.proto.monitoring.ListRunStatsResponse.prototype.setRunStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.RunStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RunStats}
 */
proto.fox.proto.monitoring.ListRunStatsResponse.prototype.addRunStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.RunStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListRunStatsResponse} returns this
 */
proto.fox.proto.monitoring.ListRunStatsResponse.prototype.clearRunStatsList = function() {
  return this.setRunStatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.Fault.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.Fault.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.Fault} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Fault.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    robotTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    faultType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    errorType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    severity: jspb.Message.getFieldWithDefault(msg, 6, ""),
    runName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    id: jspb.Message.getFieldWithDefault(msg, 8, ""),
    action: jspb.Message.getFieldWithDefault(msg, 10, ""),
    region: jspb.Message.getFieldWithDefault(msg, 11, ""),
    manualMode: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.Fault}
 */
proto.fox.proto.monitoring.Fault.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.Fault;
  return proto.fox.proto.monitoring.Fault.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.Fault} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.Fault}
 */
proto.fox.proto.monitoring.Fault.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRobotTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFaultType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeverity(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.Fault.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.Fault.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.Fault} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Fault.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRobotTime();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getFaultType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getErrorType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSeverity();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getManualMode();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.Fault.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.Fault.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 robot_time = 9;
 * @return {number}
 */
proto.fox.proto.monitoring.Fault.prototype.getRobotTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setRobotTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 fault_type = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.Fault.prototype.getFaultType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setFaultType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string error_type = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.Fault.prototype.getErrorType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setErrorType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string error_code = 5;
 * @return {string}
 */
proto.fox.proto.monitoring.Fault.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string severity = 6;
 * @return {string}
 */
proto.fox.proto.monitoring.Fault.prototype.getSeverity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setSeverity = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string run_name = 7;
 * @return {string}
 */
proto.fox.proto.monitoring.Fault.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string id = 8;
 * @return {string}
 */
proto.fox.proto.monitoring.Fault.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string action = 10;
 * @return {string}
 */
proto.fox.proto.monitoring.Fault.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string region = 11;
 * @return {string}
 */
proto.fox.proto.monitoring.Fault.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool manual_mode = 12;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Fault.prototype.getManualMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Fault} returns this
 */
proto.fox.proto.monitoring.Fault.prototype.setManualMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.Stoppage.repeatedFields_ = [3,9,12,15,7,13,22,36,43,46];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.Stoppage.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.Stoppage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.Stoppage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Stoppage.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    runName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    faultsList: jspb.Message.toObjectList(msg.getFaultsList(),
    proto.fox.proto.monitoring.Fault.toObject, includeInstance),
    interventionTypesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    interventionCausesList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    interventionCauseCategoriesList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    palletIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    palletDamage: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    goodsDamage: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    manualStop: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    externalBugUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    annotationsList: jspb.Message.toObjectList(msg.getAnnotationsList(),
    proto.fox.proto.monitoring.Annotation.toObject, includeInstance),
    excused: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    id: jspb.Message.getFieldWithDefault(msg, 16, ""),
    startTimeUtc: jspb.Message.getFieldWithDefault(msg, 17, 0),
    endTimeUtc: jspb.Message.getFieldWithDefault(msg, 18, 0),
    humanAssistanceRequestStart: jspb.Message.getFieldWithDefault(msg, 19, 0),
    humanAssistanceRequestEnd: jspb.Message.getFieldWithDefault(msg, 20, 0),
    interventionRobotFaultCodeFirst: jspb.Message.getFieldWithDefault(msg, 21, ""),
    interventionRobotFaultCodesAllList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    manualTime: jspb.Message.getFieldWithDefault(msg, 23, 0),
    responseTime: jspb.Message.getFieldWithDefault(msg, 24, 0),
    interventionRobotFaultCodeTranslated: jspb.Message.getFieldWithDefault(msg, 25, ""),
    firstFaultDuration: jspb.Message.getFieldWithDefault(msg, 26, 0),
    robotFaultCategory: jspb.Message.getFieldWithDefault(msg, 31, ""),
    motionType: jspb.Message.getFieldWithDefault(msg, 27, ""),
    region: jspb.Message.getFieldWithDefault(msg, 28, ""),
    hasPicked: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    versionName: jspb.Message.getFieldWithDefault(msg, 30, ""),
    cohortName: jspb.Message.getFieldWithDefault(msg, 32, ""),
    robotName: jspb.Message.getFieldWithDefault(msg, 33, ""),
    interventionTrigger: jspb.Message.getFieldWithDefault(msg, 34, ""),
    interventionId: jspb.Message.getFieldWithDefault(msg, 35, ""),
    noteTextList: (f = jspb.Message.getRepeatedField(msg, 36)) == null ? undefined : f,
    objectiveId: jspb.Message.getFieldWithDefault(msg, 37, ""),
    faultTitle: jspb.Message.getFieldWithDefault(msg, 38, ""),
    numericCode: jspb.Message.getFieldWithDefault(msg, 39, 0),
    avoidableByRobot: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    operatorFeedback: jspb.Message.getFieldWithDefault(msg, 41, ""),
    harCount: jspb.Message.getFieldWithDefault(msg, 42, 0),
    secondaryHarPrimaryFaultsList: (f = jspb.Message.getRepeatedField(msg, 43)) == null ? undefined : f,
    manualSegmentCount: jspb.Message.getFieldWithDefault(msg, 44, 0),
    primaryInterventionAction: jspb.Message.getFieldWithDefault(msg, 45, ""),
    allInterventionActionsList: (f = jspb.Message.getRepeatedField(msg, 46)) == null ? undefined : f,
    batteryDischargeState: jspb.Message.getFieldWithDefault(msg, 47, 0),
    mastLiftHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 48, 0.0),
    mastShift: jspb.Message.getFloatingPointFieldWithDefault(msg, 49, 0.0),
    mastTilt: jspb.Message.getFloatingPointFieldWithDefault(msg, 50, 0.0),
    lastCommandedLiftHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 51, 0.0),
    lastCommandedShift: jspb.Message.getFloatingPointFieldWithDefault(msg, 52, 0.0),
    lastCommandedTilt: jspb.Message.getFloatingPointFieldWithDefault(msg, 53, 0.0),
    palletHeightMode: jspb.Message.getFieldWithDefault(msg, 54, 0),
    pickZoneId: jspb.Message.getFieldWithDefault(msg, 55, ""),
    placeZoneId: jspb.Message.getFieldWithDefault(msg, 56, ""),
    pickZoneName: jspb.Message.getFieldWithDefault(msg, 57, ""),
    placeZoneName: jspb.Message.getFieldWithDefault(msg, 58, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.Stoppage}
 */
proto.fox.proto.monitoring.Stoppage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.Stoppage;
  return proto.fox.proto.monitoring.Stoppage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.Stoppage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.Stoppage}
 */
proto.fox.proto.monitoring.Stoppage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 3:
      var value = new proto.fox.proto.monitoring.Fault;
      reader.readMessage(value,proto.fox.proto.monitoring.Fault.deserializeBinaryFromReader);
      msg.addFaults(value);
      break;
    case 9:
      var value = /** @type {!Array<!proto.fox.proto.command_control.InterventionType>} */ (reader.readPackedEnum());
      msg.setInterventionTypesList(value);
      break;
    case 12:
      var value = /** @type {!Array<!proto.fox.proto.command_control.InterventionCause>} */ (reader.readPackedEnum());
      msg.setInterventionCausesList(value);
      break;
    case 15:
      var value = /** @type {!Array<!proto.fox.proto.command_control.InterventionCause>} */ (reader.readPackedEnum());
      msg.setInterventionCauseCategoriesList(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addPalletIds(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPalletDamage(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoodsDamage(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualStop(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalBugUrl(value);
      break;
    case 13:
      var value = new proto.fox.proto.monitoring.Annotation;
      reader.readMessage(value,proto.fox.proto.monitoring.Annotation.deserializeBinaryFromReader);
      msg.addAnnotations(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcused(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTimeUtc(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTimeUtc(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHumanAssistanceRequestStart(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHumanAssistanceRequestEnd(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterventionRobotFaultCodeFirst(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addInterventionRobotFaultCodesAll(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setManualTime(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResponseTime(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterventionRobotFaultCodeTranslated(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFirstFaultDuration(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotFaultCategory(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setMotionType(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPicked(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortName(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterventionTrigger(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterventionId(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.addNoteText(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectiveId(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaultTitle(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumericCode(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvoidableByRobot(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorFeedback(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHarCount(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.addSecondaryHarPrimaryFaults(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualSegmentCount(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryInterventionAction(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllInterventionActions(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatteryDischargeState(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMastLiftHeight(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMastShift(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMastTilt(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLastCommandedLiftHeight(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLastCommandedShift(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLastCommandedTilt(value);
      break;
    case 54:
      var value = /** @type {!proto.fox.proto.command_control.PalletHeight} */ (reader.readEnum());
      msg.setPalletHeightMode(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickZoneId(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceZoneId(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickZoneName(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceZoneName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.Stoppage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.Stoppage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.Stoppage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Stoppage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFaultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.monitoring.Fault.serializeBinaryToWriter
    );
  }
  f = message.getInterventionTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      9,
      f
    );
  }
  f = message.getInterventionCausesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      12,
      f
    );
  }
  f = message.getInterventionCauseCategoriesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      15,
      f
    );
  }
  f = message.getPalletIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getPalletDamage();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getGoodsDamage();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getManualStop();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExternalBugUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAnnotationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.fox.proto.monitoring.Annotation.serializeBinaryToWriter
    );
  }
  f = message.getExcused();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getStartTimeUtc();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getEndTimeUtc();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getHumanAssistanceRequestStart();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getHumanAssistanceRequestEnd();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getInterventionRobotFaultCodeFirst();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getInterventionRobotFaultCodesAllList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getManualTime();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getResponseTime();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getInterventionRobotFaultCodeTranslated();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getFirstFaultDuration();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getRobotFaultCategory();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getMotionType();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getHasPicked();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getCohortName();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getInterventionTrigger();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getInterventionId();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getNoteTextList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      36,
      f
    );
  }
  f = message.getObjectiveId();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getFaultTitle();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getNumericCode();
  if (f !== 0) {
    writer.writeInt32(
      39,
      f
    );
  }
  f = message.getAvoidableByRobot();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getOperatorFeedback();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getHarCount();
  if (f !== 0) {
    writer.writeInt32(
      42,
      f
    );
  }
  f = message.getSecondaryHarPrimaryFaultsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      43,
      f
    );
  }
  f = message.getManualSegmentCount();
  if (f !== 0) {
    writer.writeInt32(
      44,
      f
    );
  }
  f = message.getPrimaryInterventionAction();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getAllInterventionActionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      46,
      f
    );
  }
  f = message.getBatteryDischargeState();
  if (f !== 0) {
    writer.writeInt32(
      47,
      f
    );
  }
  f = message.getMastLiftHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      48,
      f
    );
  }
  f = message.getMastShift();
  if (f !== 0.0) {
    writer.writeFloat(
      49,
      f
    );
  }
  f = message.getMastTilt();
  if (f !== 0.0) {
    writer.writeFloat(
      50,
      f
    );
  }
  f = message.getLastCommandedLiftHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      51,
      f
    );
  }
  f = message.getLastCommandedShift();
  if (f !== 0.0) {
    writer.writeFloat(
      52,
      f
    );
  }
  f = message.getLastCommandedTilt();
  if (f !== 0.0) {
    writer.writeFloat(
      53,
      f
    );
  }
  f = message.getPalletHeightMode();
  if (f !== 0.0) {
    writer.writeEnum(
      54,
      f
    );
  }
  f = message.getPickZoneId();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getPlaceZoneId();
  if (f.length > 0) {
    writer.writeString(
      56,
      f
    );
  }
  f = message.getPickZoneName();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getPlaceZoneName();
  if (f.length > 0) {
    writer.writeString(
      58,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 duration = 8;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string run_name = 10;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated Fault faults = 3;
 * @return {!Array<!proto.fox.proto.monitoring.Fault>}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getFaultsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.Fault>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.Fault, 3));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.Fault>} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
*/
proto.fox.proto.monitoring.Stoppage.prototype.setFaultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.monitoring.Fault=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Fault}
 */
proto.fox.proto.monitoring.Stoppage.prototype.addFaults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.monitoring.Fault, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.clearFaultsList = function() {
  return this.setFaultsList([]);
};


/**
 * repeated fox.proto.command_control.InterventionType intervention_types = 9;
 * @return {!Array<!proto.fox.proto.command_control.InterventionType>}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getInterventionTypesList = function() {
  return /** @type {!Array<!proto.fox.proto.command_control.InterventionType>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.InterventionType>} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setInterventionTypesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!proto.fox.proto.command_control.InterventionType} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.addInterventionTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.clearInterventionTypesList = function() {
  return this.setInterventionTypesList([]);
};


/**
 * repeated fox.proto.command_control.InterventionCause intervention_causes = 12;
 * @return {!Array<!proto.fox.proto.command_control.InterventionCause>}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getInterventionCausesList = function() {
  return /** @type {!Array<!proto.fox.proto.command_control.InterventionCause>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.InterventionCause>} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setInterventionCausesList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {!proto.fox.proto.command_control.InterventionCause} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.addInterventionCauses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.clearInterventionCausesList = function() {
  return this.setInterventionCausesList([]);
};


/**
 * repeated fox.proto.command_control.InterventionCause intervention_cause_categories = 15;
 * @return {!Array<!proto.fox.proto.command_control.InterventionCause>}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getInterventionCauseCategoriesList = function() {
  return /** @type {!Array<!proto.fox.proto.command_control.InterventionCause>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.InterventionCause>} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setInterventionCauseCategoriesList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {!proto.fox.proto.command_control.InterventionCause} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.addInterventionCauseCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.clearInterventionCauseCategoriesList = function() {
  return this.setInterventionCauseCategoriesList([]);
};


/**
 * repeated string pallet_ids = 7;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getPalletIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setPalletIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.addPalletIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.clearPalletIdsList = function() {
  return this.setPalletIdsList([]);
};


/**
 * optional bool pallet_damage = 4;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getPalletDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setPalletDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool goods_damage = 5;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getGoodsDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setGoodsDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool manual_stop = 6;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getManualStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setManualStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string external_bug_url = 11;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getExternalBugUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setExternalBugUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated Annotation annotations = 13;
 * @return {!Array<!proto.fox.proto.monitoring.Annotation>}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getAnnotationsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.Annotation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.Annotation, 13));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.Annotation>} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
*/
proto.fox.proto.monitoring.Stoppage.prototype.setAnnotationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.fox.proto.monitoring.Annotation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Annotation}
 */
proto.fox.proto.monitoring.Stoppage.prototype.addAnnotations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.fox.proto.monitoring.Annotation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.clearAnnotationsList = function() {
  return this.setAnnotationsList([]);
};


/**
 * optional bool excused = 14;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getExcused = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setExcused = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string id = 16;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int64 start_time_utc = 17;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getStartTimeUtc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setStartTimeUtc = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 end_time_utc = 18;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getEndTimeUtc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setEndTimeUtc = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 human_assistance_request_start = 19;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getHumanAssistanceRequestStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setHumanAssistanceRequestStart = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 human_assistance_request_end = 20;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getHumanAssistanceRequestEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setHumanAssistanceRequestEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string intervention_robot_fault_code_first = 21;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getInterventionRobotFaultCodeFirst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setInterventionRobotFaultCodeFirst = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated string intervention_robot_fault_codes_all = 22;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getInterventionRobotFaultCodesAllList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setInterventionRobotFaultCodesAllList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.addInterventionRobotFaultCodesAll = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.clearInterventionRobotFaultCodesAllList = function() {
  return this.setInterventionRobotFaultCodesAllList([]);
};


/**
 * optional int64 manual_time = 23;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getManualTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setManualTime = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int64 response_time = 24;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getResponseTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setResponseTime = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string intervention_robot_fault_code_translated = 25;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getInterventionRobotFaultCodeTranslated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setInterventionRobotFaultCodeTranslated = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional int64 first_fault_duration = 26;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getFirstFaultDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setFirstFaultDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional string robot_fault_category = 31;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getRobotFaultCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setRobotFaultCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string motion_type = 27;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getMotionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setMotionType = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string region = 28;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional bool has_picked = 29;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getHasPicked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setHasPicked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional string version_name = 30;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string cohort_name = 32;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getCohortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setCohortName = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string robot_name = 33;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string intervention_trigger = 34;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getInterventionTrigger = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setInterventionTrigger = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string intervention_id = 35;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getInterventionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setInterventionId = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * repeated string note_text = 36;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getNoteTextList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 36));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setNoteTextList = function(value) {
  return jspb.Message.setField(this, 36, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.addNoteText = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 36, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.clearNoteTextList = function() {
  return this.setNoteTextList([]);
};


/**
 * optional string objective_id = 37;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getObjectiveId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setObjectiveId = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string fault_title = 38;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getFaultTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setFaultTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional int32 numeric_code = 39;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getNumericCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 39, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setNumericCode = function(value) {
  return jspb.Message.setProto3IntField(this, 39, value);
};


/**
 * optional bool avoidable_by_robot = 40;
 * @return {boolean}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getAvoidableByRobot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setAvoidableByRobot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional string operator_feedback = 41;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getOperatorFeedback = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setOperatorFeedback = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional int32 har_count = 42;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getHarCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setHarCount = function(value) {
  return jspb.Message.setProto3IntField(this, 42, value);
};


/**
 * repeated string secondary_har_primary_faults = 43;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getSecondaryHarPrimaryFaultsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 43));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setSecondaryHarPrimaryFaultsList = function(value) {
  return jspb.Message.setField(this, 43, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.addSecondaryHarPrimaryFaults = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 43, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.clearSecondaryHarPrimaryFaultsList = function() {
  return this.setSecondaryHarPrimaryFaultsList([]);
};


/**
 * optional int32 manual_segment_count = 44;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getManualSegmentCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setManualSegmentCount = function(value) {
  return jspb.Message.setProto3IntField(this, 44, value);
};


/**
 * optional string primary_intervention_action = 45;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getPrimaryInterventionAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setPrimaryInterventionAction = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * repeated string all_intervention_actions = 46;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getAllInterventionActionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 46));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setAllInterventionActionsList = function(value) {
  return jspb.Message.setField(this, 46, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.addAllInterventionActions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 46, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.clearAllInterventionActionsList = function() {
  return this.setAllInterventionActionsList([]);
};


/**
 * optional int32 battery_discharge_state = 47;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getBatteryDischargeState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setBatteryDischargeState = function(value) {
  return jspb.Message.setProto3IntField(this, 47, value);
};


/**
 * optional float mast_lift_height = 48;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getMastLiftHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 48, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setMastLiftHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 48, value);
};


/**
 * optional float mast_shift = 49;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getMastShift = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 49, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setMastShift = function(value) {
  return jspb.Message.setProto3FloatField(this, 49, value);
};


/**
 * optional float mast_tilt = 50;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getMastTilt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 50, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setMastTilt = function(value) {
  return jspb.Message.setProto3FloatField(this, 50, value);
};


/**
 * optional float last_commanded_lift_height = 51;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getLastCommandedLiftHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 51, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setLastCommandedLiftHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 51, value);
};


/**
 * optional float last_commanded_shift = 52;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getLastCommandedShift = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 52, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setLastCommandedShift = function(value) {
  return jspb.Message.setProto3FloatField(this, 52, value);
};


/**
 * optional float last_commanded_tilt = 53;
 * @return {number}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getLastCommandedTilt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 53, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setLastCommandedTilt = function(value) {
  return jspb.Message.setProto3FloatField(this, 53, value);
};


/**
 * optional fox.proto.command_control.PalletHeight pallet_height_mode = 54;
 * @return {!proto.fox.proto.command_control.PalletHeight}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getPalletHeightMode = function() {
  return /** @type {!proto.fox.proto.command_control.PalletHeight} */ (jspb.Message.getFieldWithDefault(this, 54, 0));
};


/**
 * @param {!proto.fox.proto.command_control.PalletHeight} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setPalletHeightMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 54, value);
};


/**
 * optional string pick_zone_id = 55;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getPickZoneId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setPickZoneId = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional string place_zone_id = 56;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getPlaceZoneId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setPlaceZoneId = function(value) {
  return jspb.Message.setProto3StringField(this, 56, value);
};


/**
 * optional string pick_zone_name = 57;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getPickZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setPickZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional string place_zone_name = 58;
 * @return {string}
 */
proto.fox.proto.monitoring.Stoppage.prototype.getPlaceZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Stoppage} returns this
 */
proto.fox.proto.monitoring.Stoppage.prototype.setPlaceZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 58, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListStoppagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListStoppagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListStoppagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListStoppagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFilter: (f = msg.getEventFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListStoppagesRequest}
 */
proto.fox.proto.monitoring.ListStoppagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListStoppagesRequest;
  return proto.fox.proto.monitoring.ListStoppagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListStoppagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListStoppagesRequest}
 */
proto.fox.proto.monitoring.ListStoppagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setEventFilter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListStoppagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListStoppagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListStoppagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListStoppagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional EventFilter event_filter = 1;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.ListStoppagesRequest.prototype.getEventFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.ListStoppagesRequest} returns this
*/
proto.fox.proto.monitoring.ListStoppagesRequest.prototype.setEventFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ListStoppagesRequest} returns this
 */
proto.fox.proto.monitoring.ListStoppagesRequest.prototype.clearEventFilter = function() {
  return this.setEventFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListStoppagesRequest.prototype.hasEventFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ListStoppagesRequest.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListStoppagesRequest} returns this
 */
proto.fox.proto.monitoring.ListStoppagesRequest.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListStoppagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListStoppagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListStoppagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListStoppagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListStoppagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stoppagesList: jspb.Message.toObjectList(msg.getStoppagesList(),
    proto.fox.proto.monitoring.Stoppage.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListStoppagesResponse}
 */
proto.fox.proto.monitoring.ListStoppagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListStoppagesResponse;
  return proto.fox.proto.monitoring.ListStoppagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListStoppagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListStoppagesResponse}
 */
proto.fox.proto.monitoring.ListStoppagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.Stoppage;
      reader.readMessage(value,proto.fox.proto.monitoring.Stoppage.deserializeBinaryFromReader);
      msg.addStoppages(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListStoppagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListStoppagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListStoppagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListStoppagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoppagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.Stoppage.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated Stoppage stoppages = 1;
 * @return {!Array<!proto.fox.proto.monitoring.Stoppage>}
 */
proto.fox.proto.monitoring.ListStoppagesResponse.prototype.getStoppagesList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.Stoppage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.Stoppage, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.Stoppage>} value
 * @return {!proto.fox.proto.monitoring.ListStoppagesResponse} returns this
*/
proto.fox.proto.monitoring.ListStoppagesResponse.prototype.setStoppagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.Stoppage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Stoppage}
 */
proto.fox.proto.monitoring.ListStoppagesResponse.prototype.addStoppages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.Stoppage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListStoppagesResponse} returns this
 */
proto.fox.proto.monitoring.ListStoppagesResponse.prototype.clearStoppagesList = function() {
  return this.setStoppagesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ListStoppagesResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListStoppagesResponse} returns this
 */
proto.fox.proto.monitoring.ListStoppagesResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListStoppagesResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListStoppagesResponse} returns this
 */
proto.fox.proto.monitoring.ListStoppagesResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.FaultCount.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.FaultCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.FaultCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    faultType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    faultsPerPallet: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.FaultCount}
 */
proto.fox.proto.monitoring.FaultCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.FaultCount;
  return proto.fox.proto.monitoring.FaultCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.FaultCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.FaultCount}
 */
proto.fox.proto.monitoring.FaultCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFaultType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFaultsPerPallet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.FaultCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.FaultCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.FaultCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaultType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFaultsPerPallet();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional int32 fault_type = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.FaultCount.prototype.getFaultType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.FaultCount} returns this
 */
proto.fox.proto.monitoring.FaultCount.prototype.setFaultType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.FaultCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.FaultCount} returns this
 */
proto.fox.proto.monitoring.FaultCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double faults_per_pallet = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.FaultCount.prototype.getFaultsPerPallet = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.FaultCount} returns this
 */
proto.fox.proto.monitoring.FaultCount.prototype.setFaultsPerPallet = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.FaultCountBucket.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.FaultCountBucket.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.FaultCountBucket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.FaultCountBucket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultCountBucket.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    countsList: jspb.Message.toObjectList(msg.getCountsList(),
    proto.fox.proto.monitoring.FaultCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.FaultCountBucket}
 */
proto.fox.proto.monitoring.FaultCountBucket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.FaultCountBucket;
  return proto.fox.proto.monitoring.FaultCountBucket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.FaultCountBucket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.FaultCountBucket}
 */
proto.fox.proto.monitoring.FaultCountBucket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.FaultCount;
      reader.readMessage(value,proto.fox.proto.monitoring.FaultCount.deserializeBinaryFromReader);
      msg.addCounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.FaultCountBucket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.FaultCountBucket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.FaultCountBucket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultCountBucket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.monitoring.FaultCount.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.FaultCountBucket.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.FaultCountBucket} returns this
 */
proto.fox.proto.monitoring.FaultCountBucket.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated FaultCount counts = 2;
 * @return {!Array<!proto.fox.proto.monitoring.FaultCount>}
 */
proto.fox.proto.monitoring.FaultCountBucket.prototype.getCountsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.FaultCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.FaultCount, 2));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.FaultCount>} value
 * @return {!proto.fox.proto.monitoring.FaultCountBucket} returns this
*/
proto.fox.proto.monitoring.FaultCountBucket.prototype.setCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.monitoring.FaultCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.FaultCount}
 */
proto.fox.proto.monitoring.FaultCountBucket.prototype.addCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.monitoring.FaultCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.FaultCountBucket} returns this
 */
proto.fox.proto.monitoring.FaultCountBucket.prototype.clearCountsList = function() {
  return this.setCountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.FaultCountTimeSeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.FaultCountTimeSeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFilter: (f = msg.getEventFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f),
    bucketDuration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bucketsList: jspb.Message.toObjectList(msg.getBucketsList(),
    proto.fox.proto.monitoring.FaultCountBucket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.FaultCountTimeSeries}
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.FaultCountTimeSeries;
  return proto.fox.proto.monitoring.FaultCountTimeSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.FaultCountTimeSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.FaultCountTimeSeries}
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setEventFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBucketDuration(value);
      break;
    case 3:
      var value = new proto.fox.proto.monitoring.FaultCountBucket;
      reader.readMessage(value,proto.fox.proto.monitoring.FaultCountBucket.deserializeBinaryFromReader);
      msg.addBuckets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.FaultCountTimeSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.FaultCountTimeSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getBucketDuration();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBucketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.monitoring.FaultCountBucket.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventFilter event_filter = 1;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.getEventFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.FaultCountTimeSeries} returns this
*/
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.setEventFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.FaultCountTimeSeries} returns this
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.clearEventFilter = function() {
  return this.setEventFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.hasEventFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 bucket_duration = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.getBucketDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.FaultCountTimeSeries} returns this
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.setBucketDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated FaultCountBucket buckets = 3;
 * @return {!Array<!proto.fox.proto.monitoring.FaultCountBucket>}
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.getBucketsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.FaultCountBucket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.FaultCountBucket, 3));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.FaultCountBucket>} value
 * @return {!proto.fox.proto.monitoring.FaultCountTimeSeries} returns this
*/
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.setBucketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.monitoring.FaultCountBucket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.FaultCountBucket}
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.addBuckets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.monitoring.FaultCountBucket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.FaultCountTimeSeries} returns this
 */
proto.fox.proto.monitoring.FaultCountTimeSeries.prototype.clearBucketsList = function() {
  return this.setBucketsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFilter: (f = msg.getEventFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f),
    bucketDuration: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest}
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest;
  return proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest}
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setEventFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBucketDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getBucketDuration();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional EventFilter event_filter = 1;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.prototype.getEventFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest} returns this
*/
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.prototype.setEventFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest} returns this
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.prototype.clearEventFilter = function() {
  return this.setEventFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.prototype.hasEventFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 bucket_duration = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.prototype.getBucketDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest} returns this
 */
proto.fox.proto.monitoring.GetFaultCountTimeSeriesRequest.prototype.setBucketDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RunStats.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RunStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RunStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    runName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loadDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    setupDuration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    stoppageDuration: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalDuration: jspb.Message.getFieldWithDefault(msg, 7, 0),
    placedPalletTotalCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    placedPalletSuccessCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    cohortName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tallMode: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    versionName: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RunStats}
 */
proto.fox.proto.monitoring.RunStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RunStats;
  return proto.fox.proto.monitoring.RunStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RunStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RunStats}
 */
proto.fox.proto.monitoring.RunStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoadDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSetupDuration(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStoppageDuration(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalDuration(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlacedPalletTotalCount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlacedPalletSuccessCount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortName(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTallMode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RunStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RunStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RunStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoadDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSetupDuration();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStoppageDuration();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTotalDuration();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPlacedPalletTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPlacedPalletSuccessCount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCohortName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTallMode();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RunStats.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunStats} returns this
 */
proto.fox.proto.monitoring.RunStats.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string load_description = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.RunStats.prototype.getLoadDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunStats} returns this
 */
proto.fox.proto.monitoring.RunStats.prototype.setLoadDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 setup_duration = 5;
 * @return {number}
 */
proto.fox.proto.monitoring.RunStats.prototype.getSetupDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunStats} returns this
 */
proto.fox.proto.monitoring.RunStats.prototype.setSetupDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 stoppage_duration = 6;
 * @return {number}
 */
proto.fox.proto.monitoring.RunStats.prototype.getStoppageDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunStats} returns this
 */
proto.fox.proto.monitoring.RunStats.prototype.setStoppageDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 total_duration = 7;
 * @return {number}
 */
proto.fox.proto.monitoring.RunStats.prototype.getTotalDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunStats} returns this
 */
proto.fox.proto.monitoring.RunStats.prototype.setTotalDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 placed_pallet_total_count = 8;
 * @return {number}
 */
proto.fox.proto.monitoring.RunStats.prototype.getPlacedPalletTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunStats} returns this
 */
proto.fox.proto.monitoring.RunStats.prototype.setPlacedPalletTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 placed_pallet_success_count = 9;
 * @return {number}
 */
proto.fox.proto.monitoring.RunStats.prototype.getPlacedPalletSuccessCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunStats} returns this
 */
proto.fox.proto.monitoring.RunStats.prototype.setPlacedPalletSuccessCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string cohort_name = 10;
 * @return {string}
 */
proto.fox.proto.monitoring.RunStats.prototype.getCohortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunStats} returns this
 */
proto.fox.proto.monitoring.RunStats.prototype.setCohortName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool tall_mode = 11;
 * @return {boolean}
 */
proto.fox.proto.monitoring.RunStats.prototype.getTallMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.RunStats} returns this
 */
proto.fox.proto.monitoring.RunStats.prototype.setTallMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string version_name = 12;
 * @return {string}
 */
proto.fox.proto.monitoring.RunStats.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunStats} returns this
 */
proto.fox.proto.monitoring.RunStats.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.PickStats.repeatedFields_ = [33,38,28,29,40,55];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.PickStats.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.PickStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.PickStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PickStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    runName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectiveId: jspb.Message.getFieldWithDefault(msg, 43, ""),
    versionName: jspb.Message.getFieldWithDefault(msg, 39, ""),
    trailerId: jspb.Message.getFieldWithDefault(msg, 41, ""),
    pickNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    palletId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    startTimeUtc: jspb.Message.getFieldWithDefault(msg, 31, 0),
    endTimeUtc: jspb.Message.getFieldWithDefault(msg, 32, 0),
    pickTimeUtc: jspb.Message.getFieldWithDefault(msg, 64, 0),
    targetTimeUtc: jspb.Message.getFieldWithDefault(msg, 65, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pickDuration: jspb.Message.getFieldWithDefault(msg, 7, 0),
    placeDuration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    manualDuration: jspb.Message.getFieldWithDefault(msg, 9, 0),
    forwardTravel: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    backwardsTravel: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    forwardTravelDuration: jspb.Message.getFieldWithDefault(msg, 12, 0),
    backwardsTravelDuration: jspb.Message.getFieldWithDefault(msg, 13, 0),
    stoppedDuration: jspb.Message.getFieldWithDefault(msg, 16, 0),
    stoppageDuration: jspb.Message.getFieldWithDefault(msg, 30, 0),
    stoppageDurationsList: (f = jspb.Message.getRepeatedField(msg, 33)) == null ? undefined : f,
    stoppageResponseDelaysList: (f = jspb.Message.getRepeatedField(msg, 38)) == null ? undefined : f,
    stoppageResponseDelay: jspb.Message.getFieldWithDefault(msg, 42, 0),
    forwardSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    backwardsSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    interventions: jspb.Message.getFieldWithDefault(msg, 17, 0),
    humanInitiatedInterventions: jspb.Message.getFieldWithDefault(msg, 18, 0),
    palletsPerHour: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    pickOrientation: jspb.Message.getFieldWithDefault(msg, 20, 0),
    pickRowDepth: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    pickColumnOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    pickSkewAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    placeOrientation: jspb.Message.getFieldWithDefault(msg, 24, 0),
    placeRowDepth: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    placeColumnOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    placeSkewAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    interventionTypesList: (f = jspb.Message.getRepeatedField(msg, 28)) == null ? undefined : f,
    interventionCausesList: (f = jspb.Message.getRepeatedField(msg, 29)) == null ? undefined : f,
    interventionCauseCategoriesList: (f = jspb.Message.getRepeatedField(msg, 40)) == null ? undefined : f,
    cohortName: jspb.Message.getFieldWithDefault(msg, 34, ""),
    stackHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    stackCount: jspb.Message.getFieldWithDefault(msg, 36, 0),
    stackWeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 37, 0.0),
    pickNearSpaceExtractionDuration: jspb.Message.getFieldWithDefault(msg, 44, 0),
    pickFreeSpaceDurationWarehouse: jspb.Message.getFieldWithDefault(msg, 45, 0),
    pickFreeSpaceDurationRamp: jspb.Message.getFieldWithDefault(msg, 46, 0),
    pickFreeSpaceDurationTruck: jspb.Message.getFieldWithDefault(msg, 47, 0),
    pickNearSpaceInsertionDuration: jspb.Message.getFieldWithDefault(msg, 48, 0),
    placeNearSpaceExtractionDuration: jspb.Message.getFieldWithDefault(msg, 49, 0),
    placeFreeSpaceDurationTruck: jspb.Message.getFieldWithDefault(msg, 50, 0),
    placeFreeSpaceDurationRamp: jspb.Message.getFieldWithDefault(msg, 51, 0),
    placeFreeSpaceDurationWarehouse: jspb.Message.getFieldWithDefault(msg, 52, 0),
    placeNearSpaceInsertionDuration: jspb.Message.getFieldWithDefault(msg, 53, 0),
    pickWarehouseDuration: jspb.Message.getFieldWithDefault(msg, 75, 0),
    pickRampDuration: jspb.Message.getFieldWithDefault(msg, 76, 0),
    pickTruckDuration: jspb.Message.getFieldWithDefault(msg, 77, 0),
    placeWarehouseDuration: jspb.Message.getFieldWithDefault(msg, 78, 0),
    placeRampDuration: jspb.Message.getFieldWithDefault(msg, 79, 0),
    placeTruckDuration: jspb.Message.getFieldWithDefault(msg, 80, 0),
    interventionRobotFaultCode: jspb.Message.getFieldWithDefault(msg, 54, ""),
    interventionFaultNumber: jspb.Message.getFieldWithDefault(msg, 73, ""),
    interventionFaultTitle: jspb.Message.getFieldWithDefault(msg, 74, ""),
    interventionAvoidableByRobot: jspb.Message.getBooleanFieldWithDefault(msg, 81, false),
    interventionRobotFaultCodesAllList: (f = jspb.Message.getRepeatedField(msg, 55)) == null ? undefined : f,
    robotName: jspb.Message.getFieldWithDefault(msg, 56, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 57, ""),
    pickZoneId: jspb.Message.getFieldWithDefault(msg, 58, ""),
    placeZoneId: jspb.Message.getFieldWithDefault(msg, 66, ""),
    pickZoneName: jspb.Message.getFieldWithDefault(msg, 71, ""),
    placeZoneName: jspb.Message.getFieldWithDefault(msg, 72, ""),
    autonomouslyAttempted: jspb.Message.getBooleanFieldWithDefault(msg, 59, false),
    palletHeightMode: jspb.Message.getFieldWithDefault(msg, 60, 0),
    hangingPlasticDetected: jspb.Message.getBooleanFieldWithDefault(msg, 61, false),
    interventionTrigger: jspb.Message.getFieldWithDefault(msg, 62, ""),
    supportType: jspb.Message.getFieldWithDefault(msg, 63, ""),
    leftForkOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 67, 0.0),
    rightForkOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 68, 0.0),
    autonomousPickScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 69, 0.0),
    autonomousPlaceScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 70, 0.0),
    rampAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 82, 0.0),
    operationType: jspb.Message.getFieldWithDefault(msg, 83, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.PickStats}
 */
proto.fox.proto.monitoring.PickStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.PickStats;
  return proto.fox.proto.monitoring.PickStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.PickStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.PickStats}
 */
proto.fox.proto.monitoring.PickStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectiveId(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPalletId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTimeUtc(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTimeUtc(value);
      break;
    case 64:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickTimeUtc(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetTimeUtc(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickDuration(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaceDuration(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setManualDuration(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setForwardTravel(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackwardsTravel(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setForwardTravelDuration(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBackwardsTravelDuration(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStoppedDuration(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStoppageDuration(value);
      break;
    case 33:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setStoppageDurationsList(value);
      break;
    case 38:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setStoppageResponseDelaysList(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStoppageResponseDelay(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setForwardSpeed(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBackwardsSpeed(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterventions(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHumanInitiatedInterventions(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPalletsPerHour(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPickOrientation(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPickRowDepth(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPickColumnOffset(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPickSkewAngle(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlaceOrientation(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPlaceRowDepth(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPlaceColumnOffset(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPlaceSkewAngle(value);
      break;
    case 28:
      var value = /** @type {!Array<!proto.fox.proto.command_control.InterventionType>} */ (reader.readPackedEnum());
      msg.setInterventionTypesList(value);
      break;
    case 29:
      var value = /** @type {!Array<!proto.fox.proto.command_control.InterventionCause>} */ (reader.readPackedEnum());
      msg.setInterventionCausesList(value);
      break;
    case 40:
      var value = /** @type {!Array<!proto.fox.proto.command_control.InterventionCauseCategory>} */ (reader.readPackedEnum());
      msg.setInterventionCauseCategoriesList(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortName(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStackHeight(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStackCount(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStackWeight(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickNearSpaceExtractionDuration(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickFreeSpaceDurationWarehouse(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickFreeSpaceDurationRamp(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickFreeSpaceDurationTruck(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickNearSpaceInsertionDuration(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaceNearSpaceExtractionDuration(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaceFreeSpaceDurationTruck(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaceFreeSpaceDurationRamp(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaceFreeSpaceDurationWarehouse(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaceNearSpaceInsertionDuration(value);
      break;
    case 75:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickWarehouseDuration(value);
      break;
    case 76:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickRampDuration(value);
      break;
    case 77:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickTruckDuration(value);
      break;
    case 78:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaceWarehouseDuration(value);
      break;
    case 79:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaceRampDuration(value);
      break;
    case 80:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPlaceTruckDuration(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterventionRobotFaultCode(value);
      break;
    case 73:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterventionFaultNumber(value);
      break;
    case 74:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterventionFaultTitle(value);
      break;
    case 81:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInterventionAvoidableByRobot(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.addInterventionRobotFaultCodesAll(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickZoneId(value);
      break;
    case 66:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceZoneId(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickZoneName(value);
      break;
    case 72:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceZoneName(value);
      break;
    case 59:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutonomouslyAttempted(value);
      break;
    case 60:
      var value = /** @type {!proto.fox.proto.command_control.PalletHeight} */ (reader.readEnum());
      msg.setPalletHeightMode(value);
      break;
    case 61:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHangingPlasticDetected(value);
      break;
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterventionTrigger(value);
      break;
    case 63:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupportType(value);
      break;
    case 67:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLeftForkOffset(value);
      break;
    case 68:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRightForkOffset(value);
      break;
    case 69:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAutonomousPickScore(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAutonomousPlaceScore(value);
      break;
    case 82:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampAngle(value);
      break;
    case 83:
      var value = /** @type {!proto.fox.proto.monitoring.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.PickStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.PickStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.PickStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PickStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectiveId();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getPickNumber();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPalletId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStartTimeUtc();
  if (f !== 0) {
    writer.writeInt64(
      31,
      f
    );
  }
  f = message.getEndTimeUtc();
  if (f !== 0) {
    writer.writeInt64(
      32,
      f
    );
  }
  f = message.getPickTimeUtc();
  if (f !== 0) {
    writer.writeInt64(
      64,
      f
    );
  }
  f = message.getTargetTimeUtc();
  if (f !== 0) {
    writer.writeInt64(
      65,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPickDuration();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPlaceDuration();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getManualDuration();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getForwardTravel();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getBackwardsTravel();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getForwardTravelDuration();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getBackwardsTravelDuration();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getStoppedDuration();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getStoppageDuration();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getStoppageDurationsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      33,
      f
    );
  }
  f = message.getStoppageResponseDelaysList();
  if (f.length > 0) {
    writer.writePackedInt64(
      38,
      f
    );
  }
  f = message.getStoppageResponseDelay();
  if (f !== 0) {
    writer.writeInt64(
      42,
      f
    );
  }
  f = message.getForwardSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getBackwardsSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getInterventions();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getHumanInitiatedInterventions();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getPalletsPerHour();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getPickOrientation();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getPickRowDepth();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getPickColumnOffset();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getPickSkewAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getPlaceOrientation();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getPlaceRowDepth();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
  f = message.getPlaceColumnOffset();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getPlaceSkewAngle();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
  f = message.getInterventionTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      28,
      f
    );
  }
  f = message.getInterventionCausesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      29,
      f
    );
  }
  f = message.getInterventionCauseCategoriesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      40,
      f
    );
  }
  f = message.getCohortName();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getStackHeight();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getStackCount();
  if (f !== 0) {
    writer.writeInt32(
      36,
      f
    );
  }
  f = message.getStackWeight();
  if (f !== 0.0) {
    writer.writeDouble(
      37,
      f
    );
  }
  f = message.getPickNearSpaceExtractionDuration();
  if (f !== 0) {
    writer.writeInt64(
      44,
      f
    );
  }
  f = message.getPickFreeSpaceDurationWarehouse();
  if (f !== 0) {
    writer.writeInt64(
      45,
      f
    );
  }
  f = message.getPickFreeSpaceDurationRamp();
  if (f !== 0) {
    writer.writeInt64(
      46,
      f
    );
  }
  f = message.getPickFreeSpaceDurationTruck();
  if (f !== 0) {
    writer.writeInt64(
      47,
      f
    );
  }
  f = message.getPickNearSpaceInsertionDuration();
  if (f !== 0) {
    writer.writeInt64(
      48,
      f
    );
  }
  f = message.getPlaceNearSpaceExtractionDuration();
  if (f !== 0) {
    writer.writeInt64(
      49,
      f
    );
  }
  f = message.getPlaceFreeSpaceDurationTruck();
  if (f !== 0) {
    writer.writeInt64(
      50,
      f
    );
  }
  f = message.getPlaceFreeSpaceDurationRamp();
  if (f !== 0) {
    writer.writeInt64(
      51,
      f
    );
  }
  f = message.getPlaceFreeSpaceDurationWarehouse();
  if (f !== 0) {
    writer.writeInt64(
      52,
      f
    );
  }
  f = message.getPlaceNearSpaceInsertionDuration();
  if (f !== 0) {
    writer.writeInt64(
      53,
      f
    );
  }
  f = message.getPickWarehouseDuration();
  if (f !== 0) {
    writer.writeInt64(
      75,
      f
    );
  }
  f = message.getPickRampDuration();
  if (f !== 0) {
    writer.writeInt64(
      76,
      f
    );
  }
  f = message.getPickTruckDuration();
  if (f !== 0) {
    writer.writeInt64(
      77,
      f
    );
  }
  f = message.getPlaceWarehouseDuration();
  if (f !== 0) {
    writer.writeInt64(
      78,
      f
    );
  }
  f = message.getPlaceRampDuration();
  if (f !== 0) {
    writer.writeInt64(
      79,
      f
    );
  }
  f = message.getPlaceTruckDuration();
  if (f !== 0) {
    writer.writeInt64(
      80,
      f
    );
  }
  f = message.getInterventionRobotFaultCode();
  if (f.length > 0) {
    writer.writeString(
      54,
      f
    );
  }
  f = message.getInterventionFaultNumber();
  if (f.length > 0) {
    writer.writeString(
      73,
      f
    );
  }
  f = message.getInterventionFaultTitle();
  if (f.length > 0) {
    writer.writeString(
      74,
      f
    );
  }
  f = message.getInterventionAvoidableByRobot();
  if (f) {
    writer.writeBool(
      81,
      f
    );
  }
  f = message.getInterventionRobotFaultCodesAllList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      55,
      f
    );
  }
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      56,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getPickZoneId();
  if (f.length > 0) {
    writer.writeString(
      58,
      f
    );
  }
  f = message.getPlaceZoneId();
  if (f.length > 0) {
    writer.writeString(
      66,
      f
    );
  }
  f = message.getPickZoneName();
  if (f.length > 0) {
    writer.writeString(
      71,
      f
    );
  }
  f = message.getPlaceZoneName();
  if (f.length > 0) {
    writer.writeString(
      72,
      f
    );
  }
  f = message.getAutonomouslyAttempted();
  if (f) {
    writer.writeBool(
      59,
      f
    );
  }
  f = message.getPalletHeightMode();
  if (f !== 0.0) {
    writer.writeEnum(
      60,
      f
    );
  }
  f = message.getHangingPlasticDetected();
  if (f) {
    writer.writeBool(
      61,
      f
    );
  }
  f = message.getInterventionTrigger();
  if (f.length > 0) {
    writer.writeString(
      62,
      f
    );
  }
  f = message.getSupportType();
  if (f.length > 0) {
    writer.writeString(
      63,
      f
    );
  }
  f = message.getLeftForkOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      67,
      f
    );
  }
  f = message.getRightForkOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      68,
      f
    );
  }
  f = message.getAutonomousPickScore();
  if (f !== 0.0) {
    writer.writeFloat(
      69,
      f
    );
  }
  f = message.getAutonomousPlaceScore();
  if (f !== 0.0) {
    writer.writeFloat(
      70,
      f
    );
  }
  f = message.getRampAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      82,
      f
    );
  }
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      83,
      f
    );
  }
};


/**
 * optional string run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string objective_id = 43;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getObjectiveId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setObjectiveId = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string version_name = 39;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string trailer_id = 41;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional int64 pick_number = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string pallet_id = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPalletId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPalletId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 start_time = 4;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 end_time = 5;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 start_time_utc = 31;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getStartTimeUtc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setStartTimeUtc = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional int64 end_time_utc = 32;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getEndTimeUtc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setEndTimeUtc = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional int64 pick_time_utc = 64;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickTimeUtc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 64, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickTimeUtc = function(value) {
  return jspb.Message.setProto3IntField(this, 64, value);
};


/**
 * optional int64 target_time_utc = 65;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getTargetTimeUtc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 65, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setTargetTimeUtc = function(value) {
  return jspb.Message.setProto3IntField(this, 65, value);
};


/**
 * optional int64 duration = 6;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 pick_duration = 7;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 place_duration = 8;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 manual_duration = 9;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getManualDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setManualDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional double forward_travel = 10;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getForwardTravel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setForwardTravel = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double backwards_travel = 11;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getBackwardsTravel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setBackwardsTravel = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional int64 forward_travel_duration = 12;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getForwardTravelDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setForwardTravelDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 backwards_travel_duration = 13;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getBackwardsTravelDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setBackwardsTravelDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 stopped_duration = 16;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getStoppedDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setStoppedDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 stoppage_duration = 30;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getStoppageDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setStoppageDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * repeated int64 stoppage_durations = 33;
 * @return {!Array<number>}
 */
proto.fox.proto.monitoring.PickStats.prototype.getStoppageDurationsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 33));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setStoppageDurationsList = function(value) {
  return jspb.Message.setField(this, 33, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.addStoppageDurations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 33, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.clearStoppageDurationsList = function() {
  return this.setStoppageDurationsList([]);
};


/**
 * repeated int64 stoppage_response_delays = 38;
 * @return {!Array<number>}
 */
proto.fox.proto.monitoring.PickStats.prototype.getStoppageResponseDelaysList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 38));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setStoppageResponseDelaysList = function(value) {
  return jspb.Message.setField(this, 38, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.addStoppageResponseDelays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 38, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.clearStoppageResponseDelaysList = function() {
  return this.setStoppageResponseDelaysList([]);
};


/**
 * optional int64 stoppage_response_delay = 42;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getStoppageResponseDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setStoppageResponseDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 42, value);
};


/**
 * optional double forward_speed = 14;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getForwardSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setForwardSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double backwards_speed = 15;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getBackwardsSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setBackwardsSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional int32 interventions = 17;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getInterventions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setInterventions = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 human_initiated_interventions = 18;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getHumanInitiatedInterventions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setHumanInitiatedInterventions = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional double pallets_per_hour = 19;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPalletsPerHour = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPalletsPerHour = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional int32 pick_orientation = 20;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickOrientation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickOrientation = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional double pick_row_depth = 21;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickRowDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickRowDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double pick_column_offset = 22;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickColumnOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickColumnOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double pick_skew_angle = 23;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickSkewAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickSkewAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional int32 place_orientation = 24;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceOrientation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceOrientation = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional double place_row_depth = 25;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceRowDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceRowDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional double place_column_offset = 26;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceColumnOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceColumnOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional double place_skew_angle = 27;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceSkewAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceSkewAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * repeated fox.proto.command_control.InterventionType intervention_types = 28;
 * @return {!Array<!proto.fox.proto.command_control.InterventionType>}
 */
proto.fox.proto.monitoring.PickStats.prototype.getInterventionTypesList = function() {
  return /** @type {!Array<!proto.fox.proto.command_control.InterventionType>} */ (jspb.Message.getRepeatedField(this, 28));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.InterventionType>} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setInterventionTypesList = function(value) {
  return jspb.Message.setField(this, 28, value || []);
};


/**
 * @param {!proto.fox.proto.command_control.InterventionType} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.addInterventionTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 28, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.clearInterventionTypesList = function() {
  return this.setInterventionTypesList([]);
};


/**
 * repeated fox.proto.command_control.InterventionCause intervention_causes = 29;
 * @return {!Array<!proto.fox.proto.command_control.InterventionCause>}
 */
proto.fox.proto.monitoring.PickStats.prototype.getInterventionCausesList = function() {
  return /** @type {!Array<!proto.fox.proto.command_control.InterventionCause>} */ (jspb.Message.getRepeatedField(this, 29));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.InterventionCause>} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setInterventionCausesList = function(value) {
  return jspb.Message.setField(this, 29, value || []);
};


/**
 * @param {!proto.fox.proto.command_control.InterventionCause} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.addInterventionCauses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 29, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.clearInterventionCausesList = function() {
  return this.setInterventionCausesList([]);
};


/**
 * repeated fox.proto.command_control.InterventionCauseCategory intervention_cause_categories = 40;
 * @return {!Array<!proto.fox.proto.command_control.InterventionCauseCategory>}
 */
proto.fox.proto.monitoring.PickStats.prototype.getInterventionCauseCategoriesList = function() {
  return /** @type {!Array<!proto.fox.proto.command_control.InterventionCauseCategory>} */ (jspb.Message.getRepeatedField(this, 40));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.InterventionCauseCategory>} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setInterventionCauseCategoriesList = function(value) {
  return jspb.Message.setField(this, 40, value || []);
};


/**
 * @param {!proto.fox.proto.command_control.InterventionCauseCategory} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.addInterventionCauseCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 40, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.clearInterventionCauseCategoriesList = function() {
  return this.setInterventionCauseCategoriesList([]);
};


/**
 * optional string cohort_name = 34;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getCohortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setCohortName = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional double stack_height = 35;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getStackHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setStackHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional int32 stack_count = 36;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getStackCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setStackCount = function(value) {
  return jspb.Message.setProto3IntField(this, 36, value);
};


/**
 * optional double stack_weight = 37;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getStackWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 37, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setStackWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 37, value);
};


/**
 * optional int64 pick_near_space_extraction_duration = 44;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickNearSpaceExtractionDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickNearSpaceExtractionDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 44, value);
};


/**
 * optional int64 pick_free_space_duration_warehouse = 45;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickFreeSpaceDurationWarehouse = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickFreeSpaceDurationWarehouse = function(value) {
  return jspb.Message.setProto3IntField(this, 45, value);
};


/**
 * optional int64 pick_free_space_duration_ramp = 46;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickFreeSpaceDurationRamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 46, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickFreeSpaceDurationRamp = function(value) {
  return jspb.Message.setProto3IntField(this, 46, value);
};


/**
 * optional int64 pick_free_space_duration_truck = 47;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickFreeSpaceDurationTruck = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickFreeSpaceDurationTruck = function(value) {
  return jspb.Message.setProto3IntField(this, 47, value);
};


/**
 * optional int64 pick_near_space_insertion_duration = 48;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickNearSpaceInsertionDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 48, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickNearSpaceInsertionDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 48, value);
};


/**
 * optional int64 place_near_space_extraction_duration = 49;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceNearSpaceExtractionDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 49, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceNearSpaceExtractionDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 49, value);
};


/**
 * optional int64 place_free_space_duration_truck = 50;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceFreeSpaceDurationTruck = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceFreeSpaceDurationTruck = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional int64 place_free_space_duration_ramp = 51;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceFreeSpaceDurationRamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 51, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceFreeSpaceDurationRamp = function(value) {
  return jspb.Message.setProto3IntField(this, 51, value);
};


/**
 * optional int64 place_free_space_duration_warehouse = 52;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceFreeSpaceDurationWarehouse = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 52, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceFreeSpaceDurationWarehouse = function(value) {
  return jspb.Message.setProto3IntField(this, 52, value);
};


/**
 * optional int64 place_near_space_insertion_duration = 53;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceNearSpaceInsertionDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceNearSpaceInsertionDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 53, value);
};


/**
 * optional int64 pick_warehouse_duration = 75;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickWarehouseDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 75, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickWarehouseDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 75, value);
};


/**
 * optional int64 pick_ramp_duration = 76;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickRampDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 76, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickRampDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 76, value);
};


/**
 * optional int64 pick_truck_duration = 77;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickTruckDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 77, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickTruckDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 77, value);
};


/**
 * optional int64 place_warehouse_duration = 78;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceWarehouseDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 78, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceWarehouseDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 78, value);
};


/**
 * optional int64 place_ramp_duration = 79;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceRampDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 79, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceRampDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 79, value);
};


/**
 * optional int64 place_truck_duration = 80;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceTruckDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 80, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceTruckDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 80, value);
};


/**
 * optional string intervention_robot_fault_code = 54;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getInterventionRobotFaultCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setInterventionRobotFaultCode = function(value) {
  return jspb.Message.setProto3StringField(this, 54, value);
};


/**
 * optional string intervention_fault_number = 73;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getInterventionFaultNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 73, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setInterventionFaultNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 73, value);
};


/**
 * optional string intervention_fault_title = 74;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getInterventionFaultTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 74, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setInterventionFaultTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 74, value);
};


/**
 * optional bool intervention_avoidable_by_robot = 81;
 * @return {boolean}
 */
proto.fox.proto.monitoring.PickStats.prototype.getInterventionAvoidableByRobot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 81, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setInterventionAvoidableByRobot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 81, value);
};


/**
 * repeated string intervention_robot_fault_codes_all = 55;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.PickStats.prototype.getInterventionRobotFaultCodesAllList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 55));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setInterventionRobotFaultCodesAllList = function(value) {
  return jspb.Message.setField(this, 55, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.addInterventionRobotFaultCodesAll = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 55, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.clearInterventionRobotFaultCodesAllList = function() {
  return this.setInterventionRobotFaultCodesAllList([]);
};


/**
 * optional string robot_name = 56;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 56, value);
};


/**
 * optional string user_id = 57;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional string pick_zone_id = 58;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickZoneId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickZoneId = function(value) {
  return jspb.Message.setProto3StringField(this, 58, value);
};


/**
 * optional string place_zone_id = 66;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceZoneId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 66, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceZoneId = function(value) {
  return jspb.Message.setProto3StringField(this, 66, value);
};


/**
 * optional string pick_zone_name = 71;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPickZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPickZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 71, value);
};


/**
 * optional string place_zone_name = 72;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPlaceZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 72, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPlaceZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 72, value);
};


/**
 * optional bool autonomously_attempted = 59;
 * @return {boolean}
 */
proto.fox.proto.monitoring.PickStats.prototype.getAutonomouslyAttempted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 59, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setAutonomouslyAttempted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 59, value);
};


/**
 * optional fox.proto.command_control.PalletHeight pallet_height_mode = 60;
 * @return {!proto.fox.proto.command_control.PalletHeight}
 */
proto.fox.proto.monitoring.PickStats.prototype.getPalletHeightMode = function() {
  return /** @type {!proto.fox.proto.command_control.PalletHeight} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {!proto.fox.proto.command_control.PalletHeight} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setPalletHeightMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 60, value);
};


/**
 * optional bool hanging_plastic_detected = 61;
 * @return {boolean}
 */
proto.fox.proto.monitoring.PickStats.prototype.getHangingPlasticDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 61, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setHangingPlasticDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 61, value);
};


/**
 * optional string intervention_trigger = 62;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getInterventionTrigger = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 62, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setInterventionTrigger = function(value) {
  return jspb.Message.setProto3StringField(this, 62, value);
};


/**
 * optional string support_type = 63;
 * @return {string}
 */
proto.fox.proto.monitoring.PickStats.prototype.getSupportType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 63, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setSupportType = function(value) {
  return jspb.Message.setProto3StringField(this, 63, value);
};


/**
 * optional float left_fork_offset = 67;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getLeftForkOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 67, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setLeftForkOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 67, value);
};


/**
 * optional float right_fork_offset = 68;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getRightForkOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 68, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setRightForkOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 68, value);
};


/**
 * optional float autonomous_pick_score = 69;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getAutonomousPickScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 69, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setAutonomousPickScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 69, value);
};


/**
 * optional float autonomous_place_score = 70;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getAutonomousPlaceScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 70, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setAutonomousPlaceScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 70, value);
};


/**
 * optional float ramp_angle = 82;
 * @return {number}
 */
proto.fox.proto.monitoring.PickStats.prototype.getRampAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 82, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setRampAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 82, value);
};


/**
 * optional OperationType operation_type = 83;
 * @return {!proto.fox.proto.monitoring.OperationType}
 */
proto.fox.proto.monitoring.PickStats.prototype.getOperationType = function() {
  return /** @type {!proto.fox.proto.monitoring.OperationType} */ (jspb.Message.getFieldWithDefault(this, 83, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.OperationType} value
 * @return {!proto.fox.proto.monitoring.PickStats} returns this
 */
proto.fox.proto.monitoring.PickStats.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 83, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    revisionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest}
 */
proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest;
  return proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest}
 */
proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRevisionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRevisionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string revision_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.prototype.getRevisionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest} returns this
 */
proto.fox.proto.monitoring.GetConfigurationFileRevisionRequest.prototype.setRevisionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.toObject = function(includeInstance, msg) {
  var f, obj = {
    headerRevision: (f = msg.getHeaderRevision()) && proto.fox.proto.monitoring.ConfigurationHeaderRevision.toObject(includeInstance, f),
    contents: msg.getContents_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision}
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ForkliftConfigurationFileRevision;
  return proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision}
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.ConfigurationHeaderRevision;
      reader.readMessage(value,proto.fox.proto.monitoring.ConfigurationHeaderRevision.deserializeBinaryFromReader);
      msg.setHeaderRevision(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeaderRevision();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.ConfigurationHeaderRevision.serializeBinaryToWriter
    );
  }
  f = message.getContents_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional ConfigurationHeaderRevision header_revision = 1;
 * @return {?proto.fox.proto.monitoring.ConfigurationHeaderRevision}
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.prototype.getHeaderRevision = function() {
  return /** @type{?proto.fox.proto.monitoring.ConfigurationHeaderRevision} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.ConfigurationHeaderRevision, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.ConfigurationHeaderRevision|undefined} value
 * @return {!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision} returns this
*/
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.prototype.setHeaderRevision = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision} returns this
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.prototype.clearHeaderRevision = function() {
  return this.setHeaderRevision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.prototype.hasHeaderRevision = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes contents = 2;
 * @return {!(string|Uint8Array)}
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.prototype.getContents = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes contents = 2;
 * This is a type-conversion wrapper around `getContents()`
 * @return {string}
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.prototype.getContents_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContents()));
};


/**
 * optional bytes contents = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContents()`
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.prototype.getContents_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContents()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision} returns this
 */
proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.prototype.setContents = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ConfigurationHeaderConflict.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ConfigurationHeaderConflict.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ConfigurationHeaderConflict} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigurationHeaderConflict.toObject = function(includeInstance, msg) {
  var f, obj = {
    revisionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderConflict}
 */
proto.fox.proto.monitoring.ConfigurationHeaderConflict.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ConfigurationHeaderConflict;
  return proto.fox.proto.monitoring.ConfigurationHeaderConflict.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ConfigurationHeaderConflict} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderConflict}
 */
proto.fox.proto.monitoring.ConfigurationHeaderConflict.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRevisionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ConfigurationHeaderConflict.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ConfigurationHeaderConflict.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ConfigurationHeaderConflict} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigurationHeaderConflict.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRevisionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string revision_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationHeaderConflict.prototype.getRevisionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderConflict} returns this
 */
proto.fox.proto.monitoring.ConfigurationHeaderConflict.prototype.setRevisionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationHeaderConflict.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderConflict} returns this
 */
proto.fox.proto.monitoring.ConfigurationHeaderConflict.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ConfigurationHeaderRevision.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ConfigurationHeaderRevision} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    header: (f = msg.getHeader()) && config_proto_config_pb.ConfigurationHeader.toObject(includeInstance, f),
    acceptanceStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    acceptancePolicy: jspb.Message.getFieldWithDefault(msg, 6, 0),
    conflictsList: jspb.Message.toObjectList(msg.getConflictsList(),
    proto.fox.proto.monitoring.ConfigurationHeaderConflict.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision}
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ConfigurationHeaderRevision;
  return proto.fox.proto.monitoring.ConfigurationHeaderRevision.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ConfigurationHeaderRevision} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision}
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new config_proto_config_pb.ConfigurationHeader;
      reader.readMessage(value,config_proto_config_pb.ConfigurationHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 5:
      var value = /** @type {!proto.fox.proto.monitoring.RevisionAcceptanceStatus} */ (reader.readEnum());
      msg.setAcceptanceStatus(value);
      break;
    case 6:
      var value = /** @type {!proto.fox.proto.monitoring.RevisionAcceptancePolicy} */ (reader.readEnum());
      msg.setAcceptancePolicy(value);
      break;
    case 7:
      var value = new proto.fox.proto.monitoring.ConfigurationHeaderConflict;
      reader.readMessage(value,proto.fox.proto.monitoring.ConfigurationHeaderConflict.deserializeBinaryFromReader);
      msg.addConflicts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ConfigurationHeaderRevision.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ConfigurationHeaderRevision} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      config_proto_config_pb.ConfigurationHeader.serializeBinaryToWriter
    );
  }
  f = message.getAcceptanceStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAcceptancePolicy();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getConflictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.fox.proto.monitoring.ConfigurationHeaderConflict.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision} returns this
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional fox.proto.ConfigurationHeader header = 2;
 * @return {?proto.fox.proto.ConfigurationHeader}
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.getHeader = function() {
  return /** @type{?proto.fox.proto.ConfigurationHeader} */ (
    jspb.Message.getWrapperField(this, config_proto_config_pb.ConfigurationHeader, 2));
};


/**
 * @param {?proto.fox.proto.ConfigurationHeader|undefined} value
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision} returns this
*/
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision} returns this
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RevisionAcceptanceStatus acceptance_status = 5;
 * @return {!proto.fox.proto.monitoring.RevisionAcceptanceStatus}
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.getAcceptanceStatus = function() {
  return /** @type {!proto.fox.proto.monitoring.RevisionAcceptanceStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.RevisionAcceptanceStatus} value
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision} returns this
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.setAcceptanceStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional RevisionAcceptancePolicy acceptance_policy = 6;
 * @return {!proto.fox.proto.monitoring.RevisionAcceptancePolicy}
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.getAcceptancePolicy = function() {
  return /** @type {!proto.fox.proto.monitoring.RevisionAcceptancePolicy} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.RevisionAcceptancePolicy} value
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision} returns this
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.setAcceptancePolicy = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated ConfigurationHeaderConflict conflicts = 7;
 * @return {!Array<!proto.fox.proto.monitoring.ConfigurationHeaderConflict>}
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.getConflictsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ConfigurationHeaderConflict>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ConfigurationHeaderConflict, 7));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ConfigurationHeaderConflict>} value
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision} returns this
*/
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.setConflictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ConfigurationHeaderConflict=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderConflict}
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.addConflicts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.fox.proto.monitoring.ConfigurationHeaderConflict, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision} returns this
 */
proto.fox.proto.monitoring.ConfigurationHeaderRevision.prototype.clearConflictsList = function() {
  return this.setConflictsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest}
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest;
  return proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest}
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cohortId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    revisionsList: jspb.Message.toObjectList(msg.getRevisionsList(),
    proto.fox.proto.monitoring.ConfigurationHeaderRevision.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse}
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse;
  return proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse}
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortId(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.ConfigurationHeaderRevision;
      reader.readMessage(value,proto.fox.proto.monitoring.ConfigurationHeaderRevision.deserializeBinaryFromReader);
      msg.addRevisions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCohortId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRevisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.monitoring.ConfigurationHeaderRevision.serializeBinaryToWriter
    );
  }
};


/**
 * optional string cohort_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.prototype.getCohortId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse} returns this
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.prototype.setCohortId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ConfigurationHeaderRevision revisions = 2;
 * @return {!Array<!proto.fox.proto.monitoring.ConfigurationHeaderRevision>}
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.prototype.getRevisionsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ConfigurationHeaderRevision>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ConfigurationHeaderRevision, 2));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ConfigurationHeaderRevision>} value
 * @return {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse} returns this
*/
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.prototype.setRevisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ConfigurationHeaderRevision=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision}
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.prototype.addRevisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.monitoring.ConfigurationHeaderRevision, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse} returns this
 */
proto.fox.proto.monitoring.ListConfigurationHeaderRevisionsResponse.prototype.clearRevisionsList = function() {
  return this.setRevisionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.EntityCase = {
  ENTITY_NOT_SET: 0,
  ROBOT_NAME: 1,
  COHORT_ID: 2,
  FLEET: 3
};

/**
 * @return {proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.EntityCase}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.getEntityCase = function() {
  return /** @type {proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.EntityCase} */(jspb.Message.computeOneofCase(this, proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cohortId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fleet: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest;
  return proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFleet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest} returns this
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest} returns this
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.clearRobotName = function() {
  return jspb.Message.setOneofField(this, 1, proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.hasRobotName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string cohort_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.getCohortId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest} returns this
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.setCohortId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest} returns this
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.clearCohortId = function() {
  return jspb.Message.setOneofField(this, 2, proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.hasCohortId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool fleet = 3;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.getFleet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest} returns this
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.setFleet = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest} returns this
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.clearFleet = function() {
  return jspb.Message.setOneofField(this, 3, proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryRequest.prototype.hasFleet = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    revisionHistoriesList: jspb.Message.toObjectList(msg.getRevisionHistoriesList(),
    proto.fox.proto.monitoring.ConfigurationRevisionHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse;
  return proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.ConfigurationRevisionHistory;
      reader.readMessage(value,proto.fox.proto.monitoring.ConfigurationRevisionHistory.deserializeBinaryFromReader);
      msg.addRevisionHistories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRevisionHistoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.ConfigurationRevisionHistory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConfigurationRevisionHistory revision_histories = 1;
 * @return {!Array<!proto.fox.proto.monitoring.ConfigurationRevisionHistory>}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.prototype.getRevisionHistoriesList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ConfigurationRevisionHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ConfigurationRevisionHistory, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ConfigurationRevisionHistory>} value
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse} returns this
*/
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.prototype.setRevisionHistoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ConfigurationRevisionHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionHistory}
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.prototype.addRevisionHistories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.ConfigurationRevisionHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse} returns this
 */
proto.fox.proto.monitoring.ListConfigurationRevisionHistoryResponse.prototype.clearRevisionHistoriesList = function() {
  return this.setRevisionHistoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ConfigurationRevisionHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ConfigurationRevisionHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    configId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    revisionsList: jspb.Message.toObjectList(msg.getRevisionsList(),
    proto.fox.proto.monitoring.ConfigurationRevisionMetadata.toObject, includeInstance),
    robotStatusesList: jspb.Message.toObjectList(msg.getRobotStatusesList(),
    proto.fox.proto.monitoring.RobotConfigurationRevisionState.toObject, includeInstance),
    description: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionHistory}
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ConfigurationRevisionHistory;
  return proto.fox.proto.monitoring.ConfigurationRevisionHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ConfigurationRevisionHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionHistory}
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigId(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.ConfigurationRevisionMetadata;
      reader.readMessage(value,proto.fox.proto.monitoring.ConfigurationRevisionMetadata.deserializeBinaryFromReader);
      msg.addRevisions(value);
      break;
    case 3:
      var value = new proto.fox.proto.monitoring.RobotConfigurationRevisionState;
      reader.readMessage(value,proto.fox.proto.monitoring.RobotConfigurationRevisionState.deserializeBinaryFromReader);
      msg.addRobotStatuses(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ConfigurationRevisionHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ConfigurationRevisionHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRevisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.monitoring.ConfigurationRevisionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getRobotStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.monitoring.RobotConfigurationRevisionState.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string config_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.getConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionHistory} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ConfigurationRevisionMetadata revisions = 2;
 * @return {!Array<!proto.fox.proto.monitoring.ConfigurationRevisionMetadata>}
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.getRevisionsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ConfigurationRevisionMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ConfigurationRevisionMetadata, 2));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ConfigurationRevisionMetadata>} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionHistory} returns this
*/
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.setRevisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata}
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.addRevisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.monitoring.ConfigurationRevisionMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionHistory} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.clearRevisionsList = function() {
  return this.setRevisionsList([]);
};


/**
 * repeated RobotConfigurationRevisionState robot_statuses = 3;
 * @return {!Array<!proto.fox.proto.monitoring.RobotConfigurationRevisionState>}
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.getRobotStatusesList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.RobotConfigurationRevisionState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.RobotConfigurationRevisionState, 3));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.RobotConfigurationRevisionState>} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionHistory} returns this
*/
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.setRobotStatusesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.monitoring.RobotConfigurationRevisionState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RobotConfigurationRevisionState}
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.addRobotStatuses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.monitoring.RobotConfigurationRevisionState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionHistory} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.clearRobotStatusesList = function() {
  return this.setRobotStatusesList([]);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionHistory} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionHistory.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.repeatedFields_ = [6];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_ = [[3,4],[10,11]];

/**
 * @enum {number}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.ScopeCase = {
  SCOPE_NOT_SET: 0,
  ROBOT_NAME: 3,
  COHORT_ID: 4
};

/**
 * @return {proto.fox.proto.monitoring.ConfigurationRevisionMetadata.ScopeCase}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getScopeCase = function() {
  return /** @type {proto.fox.proto.monitoring.ConfigurationRevisionMetadata.ScopeCase} */(jspb.Message.computeOneofCase(this, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.EditorCase = {
  EDITOR_NOT_SET: 0,
  EDITOR_ROBOT_NAME: 10,
  EDITOR_ACCOUNT_ID: 11
};

/**
 * @return {proto.fox.proto.monitoring.ConfigurationRevisionMetadata.EditorCase}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getEditorCase = function() {
  return /** @type {proto.fox.proto.monitoring.ConfigurationRevisionMetadata.EditorCase} */(jspb.Message.computeOneofCase(this, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ConfigurationRevisionMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    configId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    revisionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    robotName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cohortId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, "0"),
    parentTimestampsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    path: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    checksumSha256: jspb.Message.getFieldWithDefault(msg, 9, ""),
    editorRobotName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    editorAccountId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    acceptancePolicy: jspb.Message.getFieldWithDefault(msg, 12, 0),
    description: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ConfigurationRevisionMetadata;
  return proto.fox.proto.monitoring.ConfigurationRevisionMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRevisionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readInt64String());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {!Array<string>} */ (reader.readPackedInt64String());
      msg.setParentTimestampsList(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setChecksumSha256(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditorRobotName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditorAccountId(value);
      break;
    case 12:
      var value = /** @type {!proto.fox.proto.monitoring.RevisionAcceptancePolicy} */ (reader.readEnum());
      msg.setAcceptancePolicy(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ConfigurationRevisionMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRevisionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (parseInt(f, 10) !== 0) {
    writer.writeInt64String(
      5,
      f
    );
  }
  f = message.getParentTimestampsList();
  if (f.length > 0) {
    writer.writePackedInt64String(
      6,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getChecksumSha256();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAcceptancePolicy();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string config_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string revision_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getRevisionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setRevisionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string robot_name = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setRobotName = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.clearRobotName = function() {
  return jspb.Message.setOneofField(this, 3, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.hasRobotName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string cohort_id = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getCohortId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setCohortId = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.clearCohortId = function() {
  return jspb.Message.setOneofField(this, 4, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.hasCohortId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 timestamp = 5;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, "0"));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3StringIntField(this, 5, value);
};


/**
 * repeated int64 parent_timestamps = 6;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getParentTimestampsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setParentTimestampsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.addParentTimestamps = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.clearParentTimestampsList = function() {
  return this.setParentTimestampsList([]);
};


/**
 * optional string path = 7;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_deleted = 8;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string checksum_sha256 = 9;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getChecksumSha256 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setChecksumSha256 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string editor_robot_name = 10;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getEditorRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setEditorRobotName = function(value) {
  return jspb.Message.setOneofField(this, 10, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.clearEditorRobotName = function() {
  return jspb.Message.setOneofField(this, 10, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.hasEditorRobotName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string editor_account_id = 11;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getEditorAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setEditorAccountId = function(value) {
  return jspb.Message.setOneofField(this, 11, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.clearEditorAccountId = function() {
  return jspb.Message.setOneofField(this, 11, proto.fox.proto.monitoring.ConfigurationRevisionMetadata.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.hasEditorAccountId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional RevisionAcceptancePolicy acceptance_policy = 12;
 * @return {!proto.fox.proto.monitoring.RevisionAcceptancePolicy}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getAcceptancePolicy = function() {
  return /** @type {!proto.fox.proto.monitoring.RevisionAcceptancePolicy} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.RevisionAcceptancePolicy} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setAcceptancePolicy = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string description = 13;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigurationRevisionMetadata} returns this
 */
proto.fox.proto.monitoring.ConfigurationRevisionMetadata.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ConfigurationFileRevision.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ConfigurationFileRevision} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.toObject = function(includeInstance, msg) {
  var f, obj = {
    contents: msg.getContents_asB64(),
    metadata: (f = msg.getMetadata()) && proto.fox.proto.monitoring.ConfigurationRevisionMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ConfigurationFileRevision}
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ConfigurationFileRevision;
  return proto.fox.proto.monitoring.ConfigurationFileRevision.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ConfigurationFileRevision} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ConfigurationFileRevision}
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContents(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.ConfigurationRevisionMetadata;
      reader.readMessage(value,proto.fox.proto.monitoring.ConfigurationRevisionMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ConfigurationFileRevision.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ConfigurationFileRevision} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContents_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.monitoring.ConfigurationRevisionMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes contents = 1;
 * @return {!(string|Uint8Array)}
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.prototype.getContents = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes contents = 1;
 * This is a type-conversion wrapper around `getContents()`
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.prototype.getContents_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContents()));
};


/**
 * optional bytes contents = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContents()`
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.prototype.getContents_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContents()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.fox.proto.monitoring.ConfigurationFileRevision} returns this
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.prototype.setContents = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional ConfigurationRevisionMetadata metadata = 2;
 * @return {?proto.fox.proto.monitoring.ConfigurationRevisionMetadata}
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.prototype.getMetadata = function() {
  return /** @type{?proto.fox.proto.monitoring.ConfigurationRevisionMetadata} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.ConfigurationRevisionMetadata, 2));
};


/**
 * @param {?proto.fox.proto.monitoring.ConfigurationRevisionMetadata|undefined} value
 * @return {!proto.fox.proto.monitoring.ConfigurationFileRevision} returns this
*/
proto.fox.proto.monitoring.ConfigurationFileRevision.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ConfigurationFileRevision} returns this
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ConfigurationFileRevision.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RobotConfigurationRevisionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RobotConfigurationRevisionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activeRevisionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currentRevisionConflict: (f = msg.getCurrentRevisionConflict()) && proto.fox.proto.monitoring.RevisionConflict.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RobotConfigurationRevisionState}
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RobotConfigurationRevisionState;
  return proto.fox.proto.monitoring.RobotConfigurationRevisionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RobotConfigurationRevisionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RobotConfigurationRevisionState}
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActiveRevisionId(value);
      break;
    case 3:
      var value = new proto.fox.proto.monitoring.RevisionConflict;
      reader.readMessage(value,proto.fox.proto.monitoring.RevisionConflict.deserializeBinaryFromReader);
      msg.setCurrentRevisionConflict(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RobotConfigurationRevisionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RobotConfigurationRevisionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActiveRevisionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrentRevisionConflict();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.monitoring.RevisionConflict.serializeBinaryToWriter
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotConfigurationRevisionState} returns this
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string active_revision_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.prototype.getActiveRevisionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotConfigurationRevisionState} returns this
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.prototype.setActiveRevisionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RevisionConflict current_revision_conflict = 3;
 * @return {?proto.fox.proto.monitoring.RevisionConflict}
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.prototype.getCurrentRevisionConflict = function() {
  return /** @type{?proto.fox.proto.monitoring.RevisionConflict} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.RevisionConflict, 3));
};


/**
 * @param {?proto.fox.proto.monitoring.RevisionConflict|undefined} value
 * @return {!proto.fox.proto.monitoring.RobotConfigurationRevisionState} returns this
*/
proto.fox.proto.monitoring.RobotConfigurationRevisionState.prototype.setCurrentRevisionConflict = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.RobotConfigurationRevisionState} returns this
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.prototype.clearCurrentRevisionConflict = function() {
  return this.setCurrentRevisionConflict(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.RobotConfigurationRevisionState.prototype.hasCurrentRevisionConflict = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.RevisionConflict.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RevisionConflict.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RevisionConflict.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RevisionConflict} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RevisionConflict.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetRevisionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    conflictsList: jspb.Message.toObjectList(msg.getConflictsList(),
    proto.fox.proto.monitoring.ConfigurationHeaderConflict.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RevisionConflict}
 */
proto.fox.proto.monitoring.RevisionConflict.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RevisionConflict;
  return proto.fox.proto.monitoring.RevisionConflict.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RevisionConflict} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RevisionConflict}
 */
proto.fox.proto.monitoring.RevisionConflict.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetRevisionId(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.ConfigurationHeaderConflict;
      reader.readMessage(value,proto.fox.proto.monitoring.ConfigurationHeaderConflict.deserializeBinaryFromReader);
      msg.addConflicts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RevisionConflict.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RevisionConflict.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RevisionConflict} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RevisionConflict.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetRevisionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConflictsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.monitoring.ConfigurationHeaderConflict.serializeBinaryToWriter
    );
  }
};


/**
 * optional string target_revision_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RevisionConflict.prototype.getTargetRevisionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RevisionConflict} returns this
 */
proto.fox.proto.monitoring.RevisionConflict.prototype.setTargetRevisionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ConfigurationHeaderConflict conflicts = 2;
 * @return {!Array<!proto.fox.proto.monitoring.ConfigurationHeaderConflict>}
 */
proto.fox.proto.monitoring.RevisionConflict.prototype.getConflictsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ConfigurationHeaderConflict>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ConfigurationHeaderConflict, 2));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ConfigurationHeaderConflict>} value
 * @return {!proto.fox.proto.monitoring.RevisionConflict} returns this
*/
proto.fox.proto.monitoring.RevisionConflict.prototype.setConflictsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ConfigurationHeaderConflict=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderConflict}
 */
proto.fox.proto.monitoring.RevisionConflict.prototype.addConflicts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.monitoring.ConfigurationHeaderConflict, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RevisionConflict} returns this
 */
proto.fox.proto.monitoring.RevisionConflict.prototype.clearConflictsList = function() {
  return this.setConflictsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.fox.proto.monitoring.ConfigurationFileRevision.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest;
  return proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.ConfigurationFileRevision;
      reader.readMessage(value,proto.fox.proto.monitoring.ConfigurationFileRevision.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.ConfigurationFileRevision.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConfigurationFileRevision files = 1;
 * @return {!Array<!proto.fox.proto.monitoring.ConfigurationFileRevision>}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ConfigurationFileRevision>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ConfigurationFileRevision, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ConfigurationFileRevision>} value
 * @return {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest} returns this
*/
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ConfigurationFileRevision=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ConfigurationFileRevision}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.ConfigurationFileRevision, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest} returns this
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsRequest.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.fox.proto.monitoring.ConfigurationFileRevision.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse;
  return proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.ConfigurationFileRevision;
      reader.readMessage(value,proto.fox.proto.monitoring.ConfigurationFileRevision.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.ConfigurationFileRevision.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConfigurationFileRevision files = 1;
 * @return {!Array<!proto.fox.proto.monitoring.ConfigurationFileRevision>}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ConfigurationFileRevision>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ConfigurationFileRevision, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ConfigurationFileRevision>} value
 * @return {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse} returns this
*/
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ConfigurationFileRevision=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ConfigurationFileRevision}
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.ConfigurationFileRevision, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse} returns this
 */
proto.fox.proto.monitoring.BatchCreateConfigurationFileRevisionsResponse.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    newRevisionsList: jspb.Message.toObjectList(msg.getNewRevisionsList(),
    proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.toObject, includeInstance),
    updatedHeadersList: jspb.Message.toObjectList(msg.getUpdatedHeadersList(),
    proto.fox.proto.monitoring.ConfigurationHeaderRevision.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest;
  return proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.ForkliftConfigurationFileRevision;
      reader.readMessage(value,proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.deserializeBinaryFromReader);
      msg.addNewRevisions(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.ConfigurationHeaderRevision;
      reader.readMessage(value,proto.fox.proto.monitoring.ConfigurationHeaderRevision.deserializeBinaryFromReader);
      msg.addUpdatedHeaders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewRevisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.ForkliftConfigurationFileRevision.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.monitoring.ConfigurationHeaderRevision.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ForkliftConfigurationFileRevision new_revisions = 1;
 * @return {!Array<!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision>}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.prototype.getNewRevisionsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ForkliftConfigurationFileRevision, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision>} value
 * @return {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest} returns this
*/
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.prototype.setNewRevisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ForkliftConfigurationFileRevision}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.prototype.addNewRevisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.ForkliftConfigurationFileRevision, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest} returns this
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.prototype.clearNewRevisionsList = function() {
  return this.setNewRevisionsList([]);
};


/**
 * repeated ConfigurationHeaderRevision updated_headers = 2;
 * @return {!Array<!proto.fox.proto.monitoring.ConfigurationHeaderRevision>}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.prototype.getUpdatedHeadersList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ConfigurationHeaderRevision>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ConfigurationHeaderRevision, 2));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ConfigurationHeaderRevision>} value
 * @return {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest} returns this
*/
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.prototype.setUpdatedHeadersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ConfigurationHeaderRevision=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.prototype.addUpdatedHeaders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.monitoring.ConfigurationHeaderRevision, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest} returns this
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateRequest.prototype.clearUpdatedHeadersList = function() {
  return this.setUpdatedHeadersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    headersList: jspb.Message.toObjectList(msg.getHeadersList(),
    proto.fox.proto.monitoring.ConfigurationHeaderRevision.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse;
  return proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.ConfigurationHeaderRevision;
      reader.readMessage(value,proto.fox.proto.monitoring.ConfigurationHeaderRevision.deserializeBinaryFromReader);
      msg.addHeaders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.ConfigurationHeaderRevision.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConfigurationHeaderRevision headers = 1;
 * @return {!Array<!proto.fox.proto.monitoring.ConfigurationHeaderRevision>}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.prototype.getHeadersList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.ConfigurationHeaderRevision>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.ConfigurationHeaderRevision, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.ConfigurationHeaderRevision>} value
 * @return {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse} returns this
*/
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.prototype.setHeadersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.ConfigurationHeaderRevision=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ConfigurationHeaderRevision}
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.prototype.addHeaders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.ConfigurationHeaderRevision, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse} returns this
 */
proto.fox.proto.monitoring.PublishForkliftConfigurationStateResponse.prototype.clearHeadersList = function() {
  return this.setHeadersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListTrailersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListTrailersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListTrailersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTrailersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, 0),
    runFilter: (f = msg.getRunFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListTrailersRequest}
 */
proto.fox.proto.monitoring.ListTrailersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListTrailersRequest;
  return proto.fox.proto.monitoring.ListTrailersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListTrailersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListTrailersRequest}
 */
proto.fox.proto.monitoring.ListTrailersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageToken(value);
      break;
    case 3:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setRunFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListTrailersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListTrailersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListTrailersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTrailersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRunFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.ListTrailersRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListTrailersRequest} returns this
 */
proto.fox.proto.monitoring.ListTrailersRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_token = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.ListTrailersRequest.prototype.getPageToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListTrailersRequest} returns this
 */
proto.fox.proto.monitoring.ListTrailersRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional EventFilter run_filter = 3;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.ListTrailersRequest.prototype.getRunFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 3));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.ListTrailersRequest} returns this
*/
proto.fox.proto.monitoring.ListTrailersRequest.prototype.setRunFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ListTrailersRequest} returns this
 */
proto.fox.proto.monitoring.ListTrailersRequest.prototype.clearRunFilter = function() {
  return this.setRunFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListTrailersRequest.prototype.hasRunFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListTrailersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListTrailersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListTrailersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListTrailersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTrailersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trailersList: jspb.Message.toObjectList(msg.getTrailersList(),
    proto.fox.proto.monitoring.Trailer.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListTrailersResponse}
 */
proto.fox.proto.monitoring.ListTrailersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListTrailersResponse;
  return proto.fox.proto.monitoring.ListTrailersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListTrailersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListTrailersResponse}
 */
proto.fox.proto.monitoring.ListTrailersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.Trailer;
      reader.readMessage(value,proto.fox.proto.monitoring.Trailer.deserializeBinaryFromReader);
      msg.addTrailers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListTrailersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListTrailersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListTrailersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTrailersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrailersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.Trailer.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated Trailer trailers = 1;
 * @return {!Array<!proto.fox.proto.monitoring.Trailer>}
 */
proto.fox.proto.monitoring.ListTrailersResponse.prototype.getTrailersList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.Trailer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.Trailer, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.Trailer>} value
 * @return {!proto.fox.proto.monitoring.ListTrailersResponse} returns this
*/
proto.fox.proto.monitoring.ListTrailersResponse.prototype.setTrailersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.Trailer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Trailer}
 */
proto.fox.proto.monitoring.ListTrailersResponse.prototype.addTrailers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.Trailer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListTrailersResponse} returns this
 */
proto.fox.proto.monitoring.ListTrailersResponse.prototype.clearTrailersList = function() {
  return this.setTrailersList([]);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.ListTrailersResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListTrailersResponse} returns this
 */
proto.fox.proto.monitoring.ListTrailersResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.Trailer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.Trailer.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.Trailer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.Trailer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Trailer.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    trailerId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.Trailer}
 */
proto.fox.proto.monitoring.Trailer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.Trailer;
  return proto.fox.proto.monitoring.Trailer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.Trailer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.Trailer}
 */
proto.fox.proto.monitoring.Trailer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addGoalIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.Trailer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.Trailer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.Trailer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Trailer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string goal_ids = 1;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.Trailer.prototype.getGoalIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.Trailer} returns this
 */
proto.fox.proto.monitoring.Trailer.prototype.setGoalIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Trailer} returns this
 */
proto.fox.proto.monitoring.Trailer.prototype.addGoalIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Trailer} returns this
 */
proto.fox.proto.monitoring.Trailer.prototype.clearGoalIdsList = function() {
  return this.setGoalIdsList([]);
};


/**
 * optional string trailer_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.Trailer.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Trailer} returns this
 */
proto.fox.proto.monitoring.Trailer.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ConfigSyncError.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ConfigSyncError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ConfigSyncError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigSyncError.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ConfigSyncError}
 */
proto.fox.proto.monitoring.ConfigSyncError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ConfigSyncError;
  return proto.fox.proto.monitoring.ConfigSyncError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ConfigSyncError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ConfigSyncError}
 */
proto.fox.proto.monitoring.ConfigSyncError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ConfigSyncError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ConfigSyncError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ConfigSyncError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ConfigSyncError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 error_code = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.ConfigSyncError.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ConfigSyncError} returns this
 */
proto.fox.proto.monitoring.ConfigSyncError.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ConfigSyncError.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ConfigSyncError} returns this
 */
proto.fox.proto.monitoring.ConfigSyncError.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListTrailerStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListTrailerStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFilter: (f = msg.getEventFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListTrailerStatsRequest}
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListTrailerStatsRequest;
  return proto.fox.proto.monitoring.ListTrailerStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListTrailerStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListTrailerStatsRequest}
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setEventFilter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListTrailerStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListTrailerStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional EventFilter event_filter = 1;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest.prototype.getEventFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.ListTrailerStatsRequest} returns this
*/
proto.fox.proto.monitoring.ListTrailerStatsRequest.prototype.setEventFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ListTrailerStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest.prototype.clearEventFilter = function() {
  return this.setEventFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest.prototype.hasEventFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListTrailerStatsRequest} returns this
 */
proto.fox.proto.monitoring.ListTrailerStatsRequest.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListTrailerStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListTrailerStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trailerStatsList: jspb.Message.toObjectList(msg.getTrailerStatsList(),
    proto.fox.proto.monitoring.TrailerStats.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListTrailerStatsResponse}
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListTrailerStatsResponse;
  return proto.fox.proto.monitoring.ListTrailerStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListTrailerStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListTrailerStatsResponse}
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.TrailerStats;
      reader.readMessage(value,proto.fox.proto.monitoring.TrailerStats.deserializeBinaryFromReader);
      msg.addTrailerStats(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListTrailerStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListTrailerStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrailerStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.TrailerStats.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated TrailerStats trailer_stats = 1;
 * @return {!Array<!proto.fox.proto.monitoring.TrailerStats>}
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.prototype.getTrailerStatsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.TrailerStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.TrailerStats, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.TrailerStats>} value
 * @return {!proto.fox.proto.monitoring.ListTrailerStatsResponse} returns this
*/
proto.fox.proto.monitoring.ListTrailerStatsResponse.prototype.setTrailerStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.TrailerStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.TrailerStats}
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.prototype.addTrailerStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.TrailerStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListTrailerStatsResponse} returns this
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.prototype.clearTrailerStatsList = function() {
  return this.setTrailerStatsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListTrailerStatsResponse} returns this
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListTrailerStatsResponse} returns this
 */
proto.fox.proto.monitoring.ListTrailerStatsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.TrailerStats.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.TrailerStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.TrailerStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TrailerStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    trailerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    objectiveId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    runIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    versionNamesList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    commodityType: jspb.Message.getFieldWithDefault(msg, 15, ""),
    setupDuration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stoppageDuration: jspb.Message.getFieldWithDefault(msg, 3, 0),
    harStoppageDuration: jspb.Message.getFieldWithDefault(msg, 28, 0),
    totalDuration: jspb.Message.getFieldWithDefault(msg, 4, 0),
    allPicksDuration: jspb.Message.getFieldWithDefault(msg, 24, 0),
    successfulPicksDuration: jspb.Message.getFieldWithDefault(msg, 25, 0),
    autonomousPicksDuration: jspb.Message.getFieldWithDefault(msg, 26, 0),
    placedPalletTotalCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    placedPalletSuccessCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    placedStackTotalCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    placedStackSuccessCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    placedStackSuccessCountNew: jspb.Message.getFieldWithDefault(msg, 30, 0),
    cohortName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    robotName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    dockName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    locationCode: jspb.Message.getFieldWithDefault(msg, 18, ""),
    robotCumulativeAutonomyTime: jspb.Message.getFieldWithDefault(msg, 19, 0),
    robotCumulativeOperatingHours: jspb.Message.getFieldWithDefault(msg, 20, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    totalInterventions: jspb.Message.getFieldWithDefault(msg, 22, 0),
    manuallyInitiatedInterventions: jspb.Message.getFieldWithDefault(msg, 32, 0),
    totalStoppages: jspb.Message.getFieldWithDefault(msg, 27, 0),
    autonomouslyAttemptedStacks: jspb.Message.getFieldWithDefault(msg, 23, 0),
    totalInterventionResponseTime: jspb.Message.getFieldWithDefault(msg, 31, 0),
    totalDurationExcludingTailPallets: jspb.Message.getFieldWithDefault(msg, 33, 0),
    startingBattery: jspb.Message.getFieldWithDefault(msg, 34, 0),
    endingBattery: jspb.Message.getFieldWithDefault(msg, 35, 0),
    operationType: jspb.Message.getFieldWithDefault(msg, 36, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.TrailerStats}
 */
proto.fox.proto.monitoring.TrailerStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.TrailerStats;
  return proto.fox.proto.monitoring.TrailerStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.TrailerStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.TrailerStats}
 */
proto.fox.proto.monitoring.TrailerStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectiveId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addRunIds(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addVersionNames(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommodityType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSetupDuration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStoppageDuration(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHarStoppageDuration(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalDuration(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAllPicksDuration(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSuccessfulPicksDuration(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAutonomousPicksDuration(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlacedPalletTotalCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlacedPalletSuccessCount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlacedStackTotalCount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlacedStackSuccessCount(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlacedStackSuccessCountNew(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationCode(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRobotCumulativeAutonomyTime(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRobotCumulativeOperatingHours(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalInterventions(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManuallyInitiatedInterventions(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalStoppages(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAutonomouslyAttemptedStacks(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalInterventionResponseTime(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalDurationExcludingTailPallets(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartingBattery(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndingBattery(value);
      break;
    case 36:
      var value = /** @type {!proto.fox.proto.monitoring.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.TrailerStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.TrailerStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TrailerStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObjectiveId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getRunIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getVersionNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getCommodityType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSetupDuration();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStoppageDuration();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getHarStoppageDuration();
  if (f !== 0) {
    writer.writeInt64(
      28,
      f
    );
  }
  f = message.getTotalDuration();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAllPicksDuration();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getSuccessfulPicksDuration();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getAutonomousPicksDuration();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getPlacedPalletTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPlacedPalletSuccessCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPlacedStackTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getPlacedStackSuccessCount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getPlacedStackSuccessCountNew();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
  f = message.getCohortName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getDockName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLocationCode();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getRobotCumulativeAutonomyTime();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getRobotCumulativeOperatingHours();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getTotalInterventions();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getManuallyInitiatedInterventions();
  if (f !== 0) {
    writer.writeInt32(
      32,
      f
    );
  }
  f = message.getTotalStoppages();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getAutonomouslyAttemptedStacks();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getTotalInterventionResponseTime();
  if (f !== 0) {
    writer.writeInt64(
      31,
      f
    );
  }
  f = message.getTotalDurationExcludingTailPallets();
  if (f !== 0) {
    writer.writeInt64(
      33,
      f
    );
  }
  f = message.getStartingBattery();
  if (f !== 0) {
    writer.writeInt32(
      34,
      f
    );
  }
  f = message.getEndingBattery();
  if (f !== 0) {
    writer.writeInt32(
      35,
      f
    );
  }
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      36,
      f
    );
  }
};


/**
 * optional string trailer_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string objective_id = 17;
 * @return {string}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getObjectiveId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setObjectiveId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * repeated string run_ids = 11;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getRunIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setRunIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.addRunIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.clearRunIdsList = function() {
  return this.setRunIdsList([]);
};


/**
 * repeated string version_names = 12;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getVersionNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setVersionNamesList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.addVersionNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.clearVersionNamesList = function() {
  return this.setVersionNamesList([]);
};


/**
 * optional string commodity_type = 15;
 * @return {string}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getCommodityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setCommodityType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 setup_duration = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getSetupDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setSetupDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 stoppage_duration = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getStoppageDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setStoppageDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 har_stoppage_duration = 28;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getHarStoppageDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setHarStoppageDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int64 total_duration = 4;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getTotalDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setTotalDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 all_picks_duration = 24;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getAllPicksDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setAllPicksDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int64 successful_picks_duration = 25;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getSuccessfulPicksDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setSuccessfulPicksDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int64 autonomous_picks_duration = 26;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getAutonomousPicksDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setAutonomousPicksDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int32 placed_pallet_total_count = 5;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getPlacedPalletTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setPlacedPalletTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 placed_pallet_success_count = 6;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getPlacedPalletSuccessCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setPlacedPalletSuccessCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 placed_stack_total_count = 13;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getPlacedStackTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setPlacedStackTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 placed_stack_success_count = 14;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getPlacedStackSuccessCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setPlacedStackSuccessCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 placed_stack_success_count_new = 30;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getPlacedStackSuccessCountNew = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setPlacedStackSuccessCountNew = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional string cohort_name = 7;
 * @return {string}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getCohortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setCohortName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string robot_name = 8;
 * @return {string}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 start_time = 9;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 end_time = 10;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string dock_name = 16;
 * @return {string}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getDockName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setDockName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string location_code = 18;
 * @return {string}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getLocationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setLocationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int64 robot_cumulative_autonomy_time = 19;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getRobotCumulativeAutonomyTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setRobotCumulativeAutonomyTime = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 robot_cumulative_operating_hours = 20;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getRobotCumulativeOperatingHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setRobotCumulativeOperatingHours = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string user_id = 21;
 * @return {string}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int32 total_interventions = 22;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getTotalInterventions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setTotalInterventions = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 manually_initiated_interventions = 32;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getManuallyInitiatedInterventions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setManuallyInitiatedInterventions = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional int32 total_stoppages = 27;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getTotalStoppages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setTotalStoppages = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int32 autonomously_attempted_stacks = 23;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getAutonomouslyAttemptedStacks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setAutonomouslyAttemptedStacks = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int64 total_intervention_response_time = 31;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getTotalInterventionResponseTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setTotalInterventionResponseTime = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional int64 total_duration_excluding_tail_pallets = 33;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getTotalDurationExcludingTailPallets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setTotalDurationExcludingTailPallets = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional int32 starting_battery = 34;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getStartingBattery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setStartingBattery = function(value) {
  return jspb.Message.setProto3IntField(this, 34, value);
};


/**
 * optional int32 ending_battery = 35;
 * @return {number}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getEndingBattery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setEndingBattery = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional OperationType operation_type = 36;
 * @return {!proto.fox.proto.monitoring.OperationType}
 */
proto.fox.proto.monitoring.TrailerStats.prototype.getOperationType = function() {
  return /** @type {!proto.fox.proto.monitoring.OperationType} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.OperationType} value
 * @return {!proto.fox.proto.monitoring.TrailerStats} returns this
 */
proto.fox.proto.monitoring.TrailerStats.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 36, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetTabularDatasetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetTabularDatasetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFilter: (f = msg.getEventFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f),
    datasetType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetTabularDatasetRequest}
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetTabularDatasetRequest;
  return proto.fox.proto.monitoring.GetTabularDatasetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetTabularDatasetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetTabularDatasetRequest}
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setEventFilter(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.monitoring.DatasetType} */ (reader.readEnum());
      msg.setDatasetType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetTabularDatasetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetTabularDatasetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getDatasetType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional EventFilter event_filter = 1;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest.prototype.getEventFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.GetTabularDatasetRequest} returns this
*/
proto.fox.proto.monitoring.GetTabularDatasetRequest.prototype.setEventFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.GetTabularDatasetRequest} returns this
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest.prototype.clearEventFilter = function() {
  return this.setEventFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest.prototype.hasEventFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DatasetType dataset_type = 2;
 * @return {!proto.fox.proto.monitoring.DatasetType}
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest.prototype.getDatasetType = function() {
  return /** @type {!proto.fox.proto.monitoring.DatasetType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.DatasetType} value
 * @return {!proto.fox.proto.monitoring.GetTabularDatasetRequest} returns this
 */
proto.fox.proto.monitoring.GetTabularDatasetRequest.prototype.setDatasetType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.TabularDataset.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.TabularDataset.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.TabularDataset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.TabularDataset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TabularDataset.toObject = function(includeInstance, msg) {
  var f, obj = {
    headersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.fox.proto.monitoring.Row.toObject, includeInstance),
    datasetType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.TabularDataset}
 */
proto.fox.proto.monitoring.TabularDataset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.TabularDataset;
  return proto.fox.proto.monitoring.TabularDataset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.TabularDataset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.TabularDataset}
 */
proto.fox.proto.monitoring.TabularDataset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addHeaders(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.Row;
      reader.readMessage(value,proto.fox.proto.monitoring.Row.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.monitoring.DatasetType} */ (reader.readEnum());
      msg.setDatasetType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.TabularDataset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.TabularDataset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.TabularDataset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TabularDataset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.monitoring.Row.serializeBinaryToWriter
    );
  }
  f = message.getDatasetType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * repeated string headers = 1;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.TabularDataset.prototype.getHeadersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.TabularDataset} returns this
 */
proto.fox.proto.monitoring.TabularDataset.prototype.setHeadersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.TabularDataset} returns this
 */
proto.fox.proto.monitoring.TabularDataset.prototype.addHeaders = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.TabularDataset} returns this
 */
proto.fox.proto.monitoring.TabularDataset.prototype.clearHeadersList = function() {
  return this.setHeadersList([]);
};


/**
 * repeated Row rows = 2;
 * @return {!Array<!proto.fox.proto.monitoring.Row>}
 */
proto.fox.proto.monitoring.TabularDataset.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.Row>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.Row, 2));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.Row>} value
 * @return {!proto.fox.proto.monitoring.TabularDataset} returns this
*/
proto.fox.proto.monitoring.TabularDataset.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.monitoring.Row=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Row}
 */
proto.fox.proto.monitoring.TabularDataset.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.monitoring.Row, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.TabularDataset} returns this
 */
proto.fox.proto.monitoring.TabularDataset.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * optional DatasetType dataset_type = 3;
 * @return {!proto.fox.proto.monitoring.DatasetType}
 */
proto.fox.proto.monitoring.TabularDataset.prototype.getDatasetType = function() {
  return /** @type {!proto.fox.proto.monitoring.DatasetType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.monitoring.DatasetType} value
 * @return {!proto.fox.proto.monitoring.TabularDataset} returns this
 */
proto.fox.proto.monitoring.TabularDataset.prototype.setDatasetType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.Row.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.Row.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.Row.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.Row} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Row.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.Row}
 */
proto.fox.proto.monitoring.Row.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.Row;
  return proto.fox.proto.monitoring.Row.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.Row} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.Row}
 */
proto.fox.proto.monitoring.Row.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.Row.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.Row.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.Row} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Row.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string values = 1;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.Row.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.Row} returns this
 */
proto.fox.proto.monitoring.Row.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Row} returns this
 */
proto.fox.proto.monitoring.Row.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.Row} returns this
 */
proto.fox.proto.monitoring.Row.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListFaultsRequest.repeatedFields_ = [1,3,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListFaultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListFaultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    runFilter: (f = msg.getRunFilter()) && proto.fox.proto.monitoring.EventFilter.toObject(includeInstance, f),
    faultCodesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    faultTypesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    faultSeveritiesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    includeActionAndRegion: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest}
 */
proto.fox.proto.monitoring.ListFaultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListFaultsRequest;
  return proto.fox.proto.monitoring.ListFaultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListFaultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest}
 */
proto.fox.proto.monitoring.ListFaultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRunNames(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.EventFilter;
      reader.readMessage(value,proto.fox.proto.monitoring.EventFilter.deserializeBinaryFromReader);
      msg.setRunFilter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFaultCodes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addFaultTypes(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addFaultSeverities(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeActionAndRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListFaultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListFaultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRunFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.monitoring.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getFaultCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getFaultTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getFaultSeveritiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIncludeActionAndRegion();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * repeated string run_names = 1;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.getRunNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.setRunNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.addRunNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.clearRunNamesList = function() {
  return this.setRunNamesList([]);
};


/**
 * optional EventFilter run_filter = 2;
 * @return {?proto.fox.proto.monitoring.EventFilter}
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.getRunFilter = function() {
  return /** @type{?proto.fox.proto.monitoring.EventFilter} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.EventFilter, 2));
};


/**
 * @param {?proto.fox.proto.monitoring.EventFilter|undefined} value
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
*/
proto.fox.proto.monitoring.ListFaultsRequest.prototype.setRunFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.clearRunFilter = function() {
  return this.setRunFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.hasRunFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string fault_codes = 3;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.getFaultCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.setFaultCodesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.addFaultCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.clearFaultCodesList = function() {
  return this.setFaultCodesList([]);
};


/**
 * repeated string fault_types = 5;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.getFaultTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.setFaultTypesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.addFaultTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.clearFaultTypesList = function() {
  return this.setFaultTypesList([]);
};


/**
 * repeated string fault_severities = 6;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.getFaultSeveritiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.setFaultSeveritiesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.addFaultSeverities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.clearFaultSeveritiesList = function() {
  return this.setFaultSeveritiesList([]);
};


/**
 * optional string next_page_token = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool include_action_and_region = 7;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.getIncludeActionAndRegion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListFaultsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultsRequest.prototype.setIncludeActionAndRegion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListFaultsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListFaultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListFaultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListFaultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    faultsList: jspb.Message.toObjectList(msg.getFaultsList(),
    proto.fox.proto.monitoring.Fault.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListFaultsResponse}
 */
proto.fox.proto.monitoring.ListFaultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListFaultsResponse;
  return proto.fox.proto.monitoring.ListFaultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListFaultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListFaultsResponse}
 */
proto.fox.proto.monitoring.ListFaultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.Fault;
      reader.readMessage(value,proto.fox.proto.monitoring.Fault.deserializeBinaryFromReader);
      msg.addFaults(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListFaultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListFaultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListFaultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.Fault.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated Fault faults = 1;
 * @return {!Array<!proto.fox.proto.monitoring.Fault>}
 */
proto.fox.proto.monitoring.ListFaultsResponse.prototype.getFaultsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.Fault>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.Fault, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.Fault>} value
 * @return {!proto.fox.proto.monitoring.ListFaultsResponse} returns this
*/
proto.fox.proto.monitoring.ListFaultsResponse.prototype.setFaultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.Fault=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Fault}
 */
proto.fox.proto.monitoring.ListFaultsResponse.prototype.addFaults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.Fault, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListFaultsResponse} returns this
 */
proto.fox.proto.monitoring.ListFaultsResponse.prototype.clearFaultsList = function() {
  return this.setFaultsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ListFaultsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListFaultsResponse} returns this
 */
proto.fox.proto.monitoring.ListFaultsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.ListFaultsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.ListFaultsResponse} returns this
 */
proto.fox.proto.monitoring.ListFaultsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.PublishRobotHeartbeatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    heartbeat: (f = msg.getHeartbeat()) && proto.fox.proto.monitoring.RobotHeartbeat.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.PublishRobotHeartbeatRequest}
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.PublishRobotHeartbeatRequest;
  return proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.PublishRobotHeartbeatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.PublishRobotHeartbeatRequest}
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.RobotHeartbeat;
      reader.readMessage(value,proto.fox.proto.monitoring.RobotHeartbeat.deserializeBinaryFromReader);
      msg.setHeartbeat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.PublishRobotHeartbeatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeartbeat();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.monitoring.RobotHeartbeat.serializeBinaryToWriter
    );
  }
};


/**
 * optional RobotHeartbeat heartbeat = 1;
 * @return {?proto.fox.proto.monitoring.RobotHeartbeat}
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.prototype.getHeartbeat = function() {
  return /** @type{?proto.fox.proto.monitoring.RobotHeartbeat} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.RobotHeartbeat, 1));
};


/**
 * @param {?proto.fox.proto.monitoring.RobotHeartbeat|undefined} value
 * @return {!proto.fox.proto.monitoring.PublishRobotHeartbeatRequest} returns this
*/
proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.prototype.setHeartbeat = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.PublishRobotHeartbeatRequest} returns this
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.prototype.clearHeartbeat = function() {
  return this.setHeartbeat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatRequest.prototype.hasHeartbeat = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.RobotHeartbeat.repeatedFields_ = [1,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RobotHeartbeat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RobotHeartbeat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotHeartbeat.toObject = function(includeInstance, msg) {
  var f, obj = {
    runLogSummariesList: jspb.Message.toObjectList(msg.getRunLogSummariesList(),
    proto.fox.proto.monitoring.RunLogSummary.toObject, includeInstance),
    faultState: (f = msg.getFaultState()) && log_fault_pb.FaultState.toObject(includeInstance, f),
    activeRunId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    goalStartTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    goalsList: jspb.Message.toObjectList(msg.getGoalsList(),
    command_control_proto_command_control_pb.Goal.toObject, includeInstance),
    driveStatus: (f = msg.getDriveStatus()) && planner_proto_types_pb.TricycleDriveControl.toObject(includeInstance, f),
    plcStatus: (f = msg.getPlcStatus()) && planner_proto_types_pb.PlcStatusFlags.toObject(includeInstance, f),
    batteryDischargeState: jspb.Message.getFieldWithDefault(msg, 8, 0),
    diskSpaceAvailable: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat}
 */
proto.fox.proto.monitoring.RobotHeartbeat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RobotHeartbeat;
  return proto.fox.proto.monitoring.RobotHeartbeat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RobotHeartbeat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat}
 */
proto.fox.proto.monitoring.RobotHeartbeat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.RunLogSummary;
      reader.readMessage(value,proto.fox.proto.monitoring.RunLogSummary.deserializeBinaryFromReader);
      msg.addRunLogSummaries(value);
      break;
    case 2:
      var value = new log_fault_pb.FaultState;
      reader.readMessage(value,log_fault_pb.FaultState.deserializeBinaryFromReader);
      msg.setFaultState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActiveRunId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalStartTime(value);
      break;
    case 5:
      var value = new command_control_proto_command_control_pb.Goal;
      reader.readMessage(value,command_control_proto_command_control_pb.Goal.deserializeBinaryFromReader);
      msg.addGoals(value);
      break;
    case 6:
      var value = new planner_proto_types_pb.TricycleDriveControl;
      reader.readMessage(value,planner_proto_types_pb.TricycleDriveControl.deserializeBinaryFromReader);
      msg.setDriveStatus(value);
      break;
    case 7:
      var value = new planner_proto_types_pb.PlcStatusFlags;
      reader.readMessage(value,planner_proto_types_pb.PlcStatusFlags.deserializeBinaryFromReader);
      msg.setPlcStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatteryDischargeState(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDiskSpaceAvailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RobotHeartbeat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RobotHeartbeat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotHeartbeat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunLogSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.RunLogSummary.serializeBinaryToWriter
    );
  }
  f = message.getFaultState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      log_fault_pb.FaultState.serializeBinaryToWriter
    );
  }
  f = message.getActiveRunId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGoalStartTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getGoalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      command_control_proto_command_control_pb.Goal.serializeBinaryToWriter
    );
  }
  f = message.getDriveStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      planner_proto_types_pb.TricycleDriveControl.serializeBinaryToWriter
    );
  }
  f = message.getPlcStatus();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      planner_proto_types_pb.PlcStatusFlags.serializeBinaryToWriter
    );
  }
  f = message.getBatteryDischargeState();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDiskSpaceAvailable();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * repeated RunLogSummary run_log_summaries = 1;
 * @return {!Array<!proto.fox.proto.monitoring.RunLogSummary>}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.getRunLogSummariesList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.RunLogSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.RunLogSummary, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.RunLogSummary>} value
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
*/
proto.fox.proto.monitoring.RobotHeartbeat.prototype.setRunLogSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.RunLogSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RunLogSummary}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.addRunLogSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.RunLogSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.clearRunLogSummariesList = function() {
  return this.setRunLogSummariesList([]);
};


/**
 * optional fox.proto.FaultState fault_state = 2;
 * @return {?proto.fox.proto.FaultState}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.getFaultState = function() {
  return /** @type{?proto.fox.proto.FaultState} */ (
    jspb.Message.getWrapperField(this, log_fault_pb.FaultState, 2));
};


/**
 * @param {?proto.fox.proto.FaultState|undefined} value
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
*/
proto.fox.proto.monitoring.RobotHeartbeat.prototype.setFaultState = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.clearFaultState = function() {
  return this.setFaultState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.hasFaultState = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string active_run_id = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.getActiveRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.setActiveRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 goal_start_time = 4;
 * @return {number}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.getGoalStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.setGoalStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated fox.proto.command_control.Goal goals = 5;
 * @return {!Array<!proto.fox.proto.command_control.Goal>}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.getGoalsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.Goal>} */ (
    jspb.Message.getRepeatedWrapperField(this, command_control_proto_command_control_pb.Goal, 5));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.Goal>} value
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
*/
proto.fox.proto.monitoring.RobotHeartbeat.prototype.setGoalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.fox.proto.command_control.Goal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.Goal}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.addGoals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.fox.proto.command_control.Goal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.clearGoalsList = function() {
  return this.setGoalsList([]);
};


/**
 * optional fox.proto.TricycleDriveControl drive_status = 6;
 * @return {?proto.fox.proto.TricycleDriveControl}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.getDriveStatus = function() {
  return /** @type{?proto.fox.proto.TricycleDriveControl} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.TricycleDriveControl, 6));
};


/**
 * @param {?proto.fox.proto.TricycleDriveControl|undefined} value
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
*/
proto.fox.proto.monitoring.RobotHeartbeat.prototype.setDriveStatus = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.clearDriveStatus = function() {
  return this.setDriveStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.hasDriveStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional fox.proto.PlcStatusFlags plc_status = 7;
 * @return {?proto.fox.proto.PlcStatusFlags}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.getPlcStatus = function() {
  return /** @type{?proto.fox.proto.PlcStatusFlags} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.PlcStatusFlags, 7));
};


/**
 * @param {?proto.fox.proto.PlcStatusFlags|undefined} value
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
*/
proto.fox.proto.monitoring.RobotHeartbeat.prototype.setPlcStatus = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.clearPlcStatus = function() {
  return this.setPlcStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.hasPlcStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 battery_discharge_state = 8;
 * @return {number}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.getBatteryDischargeState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.setBatteryDischargeState = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 disk_space_available = 9;
 * @return {number}
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.getDiskSpaceAvailable = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RobotHeartbeat} returns this
 */
proto.fox.proto.monitoring.RobotHeartbeat.prototype.setDiskSpaceAvailable = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RunLogSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RunLogSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunLogSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    runName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastEventModTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    metadataFileCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    metadataFileSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RunLogSummary}
 */
proto.fox.proto.monitoring.RunLogSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RunLogSummary;
  return proto.fox.proto.monitoring.RunLogSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RunLogSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RunLogSummary}
 */
proto.fox.proto.monitoring.RunLogSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastEventModTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMetadataFileCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMetadataFileSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RunLogSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RunLogSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RunLogSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLastEventModTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMetadataFileCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMetadataFileSize();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RunLogSummary} returns this
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 event_count = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.getEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunLogSummary} returns this
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.setEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 last_event_mod_time = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.getLastEventModTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunLogSummary} returns this
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.setLastEventModTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 metadata_file_count = 4;
 * @return {number}
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.getMetadataFileCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunLogSummary} returns this
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.setMetadataFileCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 metadata_file_size = 5;
 * @return {number}
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.getMetadataFileSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RunLogSummary} returns this
 */
proto.fox.proto.monitoring.RunLogSummary.prototype.setMetadataFileSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.PublishRobotHeartbeatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.PublishRobotHeartbeatResponse}
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.PublishRobotHeartbeatResponse;
  return proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.PublishRobotHeartbeatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.PublishRobotHeartbeatResponse}
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.PublishRobotHeartbeatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.PublishRobotHeartbeatResponse} returns this
 */
proto.fox.proto.monitoring.PublishRobotHeartbeatResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && robot_proto_config_pb.EnvironmentConfig.toObject(includeInstance, f),
    dockConfigsList: jspb.Message.toObjectList(msg.getDockConfigsList(),
    proto.fox.proto.monitoring.DockConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest;
  return proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new robot_proto_config_pb.EnvironmentConfig;
      reader.readMessage(value,robot_proto_config_pb.EnvironmentConfig.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.DockConfig;
      reader.readMessage(value,proto.fox.proto.monitoring.DockConfig.deserializeBinaryFromReader);
      msg.addDockConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      robot_proto_config_pb.EnvironmentConfig.serializeBinaryToWriter
    );
  }
  f = message.getDockConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.monitoring.DockConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional fox.proto.EnvironmentConfig template = 1;
 * @return {?proto.fox.proto.EnvironmentConfig}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.prototype.getTemplate = function() {
  return /** @type{?proto.fox.proto.EnvironmentConfig} */ (
    jspb.Message.getWrapperField(this, robot_proto_config_pb.EnvironmentConfig, 1));
};


/**
 * @param {?proto.fox.proto.EnvironmentConfig|undefined} value
 * @return {!proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest} returns this
*/
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest} returns this
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DockConfig dock_configs = 2;
 * @return {!Array<!proto.fox.proto.monitoring.DockConfig>}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.prototype.getDockConfigsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.DockConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.DockConfig, 2));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.DockConfig>} value
 * @return {!proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest} returns this
*/
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.prototype.setDockConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.monitoring.DockConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.DockConfig}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.prototype.addDockConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.monitoring.DockConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest} returns this
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigRequest.prototype.clearDockConfigsList = function() {
  return this.setDockConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    environmentConfig: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse;
  return proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnvironmentConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnvironmentConfig();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string environment_config = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.prototype.getEnvironmentConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse} returns this
 */
proto.fox.proto.monitoring.GenerateEnvironmentConfigResponse.prototype.setEnvironmentConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.DockConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.DockConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.DockConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.DockConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickZoneName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pickZoneId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rampLength: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    rampWidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    rampWallLength: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    rampLipToBumpers: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lipToHinge: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    rampLipToTagLine: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    hasRampWalls: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    placeZoneId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    placeZoneName: jspb.Message.getFieldWithDefault(msg, 19, ""),
    placeStrategy: jspb.Message.getFieldWithDefault(msg, 9, ""),
    placeLeftToRight: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    rampToPlaceZoneX: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    rampToPlaceZoneY: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    placeZoneTemplateName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    pickZoneTemplateName: jspb.Message.getFieldWithDefault(msg, 22, ""),
    lipToLeftTag: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    lipToRightTag: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    lipToClearHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    lipToRampWall: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    edgeToLeftTag: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    edgeToRightTag: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    rampToPlaceZoneRotation: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    pickZoneCenterY: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    useSplitPlaceZone: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    batchSize: jspb.Message.getFieldWithDefault(msg, 27, 0),
    numberOfBatches: jspb.Message.getFieldWithDefault(msg, 28, 0),
    omniPadding: jspb.Message.getBooleanFieldWithDefault(msg, 29, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.DockConfig}
 */
proto.fox.proto.monitoring.DockConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.DockConfig;
  return proto.fox.proto.monitoring.DockConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.DockConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.DockConfig}
 */
proto.fox.proto.monitoring.DockConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickZoneName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPickZoneId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampLength(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampWidth(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampWallLength(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampLipToBumpers(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToHinge(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampLipToTagLine(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRampWalls(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlaceZoneId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceZoneName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceStrategy(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlaceLeftToRight(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampToPlaceZoneX(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampToPlaceZoneY(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceZoneTemplateName(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickZoneTemplateName(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToLeftTag(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToRightTag(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToClearHeight(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToRampWall(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEdgeToLeftTag(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEdgeToRightTag(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampToPlaceZoneRotation(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPickZoneCenterY(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSplitPlaceZone(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatchSize(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfBatches(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOmniPadding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.DockConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.DockConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.DockConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.DockConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickZoneName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPickZoneId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRampLength();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getRampWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getRampWallLength();
  if (f !== 0.0) {
    writer.writeFloat(
      25,
      f
    );
  }
  f = message.getRampLipToBumpers();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getLipToHinge();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getRampLipToTagLine();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getHasRampWalls();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPlaceZoneId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPlaceZoneName();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPlaceStrategy();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPlaceLeftToRight();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getRampToPlaceZoneX();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getRampToPlaceZoneY();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getPlaceZoneTemplateName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPickZoneTemplateName();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getLipToLeftTag();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getLipToRightTag();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getLipToClearHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      23,
      f
    );
  }
  f = message.getLipToRampWall();
  if (f !== 0.0) {
    writer.writeFloat(
      24,
      f
    );
  }
  f = message.getEdgeToLeftTag();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getEdgeToRightTag();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getRampToPlaceZoneRotation();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getPickZoneCenterY();
  if (f !== 0.0) {
    writer.writeFloat(
      21,
      f
    );
  }
  f = message.getUseSplitPlaceZone();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getBatchSize();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getNumberOfBatches();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getOmniPadding();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
};


/**
 * optional string pick_zone_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getPickZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setPickZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 pick_zone_id = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getPickZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setPickZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float ramp_length = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getRampLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setRampLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float ramp_width = 4;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getRampWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setRampWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float ramp_wall_length = 25;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getRampWallLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setRampWallLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional float ramp_lip_to_bumpers = 5;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getRampLipToBumpers = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setRampLipToBumpers = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float lip_to_hinge = 14;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getLipToHinge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setLipToHinge = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float ramp_lip_to_tag_line = 6;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getRampLipToTagLine = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setRampLipToTagLine = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool has_ramp_walls = 7;
 * @return {boolean}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getHasRampWalls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setHasRampWalls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 place_zone_id = 8;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getPlaceZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setPlaceZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string place_zone_name = 19;
 * @return {string}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getPlaceZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setPlaceZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string place_strategy = 9;
 * @return {string}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getPlaceStrategy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setPlaceStrategy = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool place_left_to_right = 10;
 * @return {boolean}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getPlaceLeftToRight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setPlaceLeftToRight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional float ramp_to_place_zone_x = 11;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getRampToPlaceZoneX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setRampToPlaceZoneX = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float ramp_to_place_zone_y = 12;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getRampToPlaceZoneY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setRampToPlaceZoneY = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional string place_zone_template_name = 13;
 * @return {string}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getPlaceZoneTemplateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setPlaceZoneTemplateName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string pick_zone_template_name = 22;
 * @return {string}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getPickZoneTemplateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setPickZoneTemplateName = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional float lip_to_left_tag = 15;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getLipToLeftTag = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setLipToLeftTag = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float lip_to_right_tag = 16;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getLipToRightTag = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setLipToRightTag = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float lip_to_clear_height = 23;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getLipToClearHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setLipToClearHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional float lip_to_ramp_wall = 24;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getLipToRampWall = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setLipToRampWall = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional float edge_to_left_tag = 17;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getEdgeToLeftTag = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setEdgeToLeftTag = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional float edge_to_right_tag = 18;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getEdgeToRightTag = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setEdgeToRightTag = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float ramp_to_place_zone_rotation = 20;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getRampToPlaceZoneRotation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setRampToPlaceZoneRotation = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional float pick_zone_center_y = 21;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getPickZoneCenterY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setPickZoneCenterY = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional bool use_split_place_zone = 26;
 * @return {boolean}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getUseSplitPlaceZone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setUseSplitPlaceZone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional int32 batch_size = 27;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getBatchSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setBatchSize = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int32 number_of_batches = 28;
 * @return {number}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getNumberOfBatches = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setNumberOfBatches = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional bool omni_padding = 29;
 * @return {boolean}
 */
proto.fox.proto.monitoring.DockConfig.prototype.getOmniPadding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.DockConfig} returns this
 */
proto.fox.proto.monitoring.DockConfig.prototype.setOmniPadding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ParseEnvironmentConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    environmentConfigTextProto: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ParseEnvironmentConfigRequest}
 */
proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ParseEnvironmentConfigRequest;
  return proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ParseEnvironmentConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ParseEnvironmentConfigRequest}
 */
proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnvironmentConfigTextProto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ParseEnvironmentConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnvironmentConfigTextProto();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string environment_config_text_proto = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.prototype.getEnvironmentConfigTextProto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ParseEnvironmentConfigRequest} returns this
 */
proto.fox.proto.monitoring.ParseEnvironmentConfigRequest.prototype.setEnvironmentConfigTextProto = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.EnvironmentConfigInputs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.EnvironmentConfigInputs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && robot_proto_config_pb.EnvironmentConfig.toObject(includeInstance, f),
    dockConfigsList: jspb.Message.toObjectList(msg.getDockConfigsList(),
    proto.fox.proto.monitoring.DockConfig.toObject, includeInstance),
    dockMeasurementsCsv: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.EnvironmentConfigInputs}
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.EnvironmentConfigInputs;
  return proto.fox.proto.monitoring.EnvironmentConfigInputs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.EnvironmentConfigInputs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.EnvironmentConfigInputs}
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new robot_proto_config_pb.EnvironmentConfig;
      reader.readMessage(value,robot_proto_config_pb.EnvironmentConfig.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.DockConfig;
      reader.readMessage(value,proto.fox.proto.monitoring.DockConfig.deserializeBinaryFromReader);
      msg.addDockConfigs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockMeasurementsCsv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.EnvironmentConfigInputs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.EnvironmentConfigInputs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      robot_proto_config_pb.EnvironmentConfig.serializeBinaryToWriter
    );
  }
  f = message.getDockConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.monitoring.DockConfig.serializeBinaryToWriter
    );
  }
  f = message.getDockMeasurementsCsv();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional fox.proto.EnvironmentConfig template = 1;
 * @return {?proto.fox.proto.EnvironmentConfig}
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.getTemplate = function() {
  return /** @type{?proto.fox.proto.EnvironmentConfig} */ (
    jspb.Message.getWrapperField(this, robot_proto_config_pb.EnvironmentConfig, 1));
};


/**
 * @param {?proto.fox.proto.EnvironmentConfig|undefined} value
 * @return {!proto.fox.proto.monitoring.EnvironmentConfigInputs} returns this
*/
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.EnvironmentConfigInputs} returns this
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DockConfig dock_configs = 2;
 * @return {!Array<!proto.fox.proto.monitoring.DockConfig>}
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.getDockConfigsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.DockConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.DockConfig, 2));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.DockConfig>} value
 * @return {!proto.fox.proto.monitoring.EnvironmentConfigInputs} returns this
*/
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.setDockConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.monitoring.DockConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.DockConfig}
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.addDockConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.monitoring.DockConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.EnvironmentConfigInputs} returns this
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.clearDockConfigsList = function() {
  return this.setDockConfigsList([]);
};


/**
 * optional string dock_measurements_csv = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.getDockMeasurementsCsv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.EnvironmentConfigInputs} returns this
 */
proto.fox.proto.monitoring.EnvironmentConfigInputs.prototype.setDockMeasurementsCsv = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListFaultCodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListFaultCodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListFaultCodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultCodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListFaultCodesRequest}
 */
proto.fox.proto.monitoring.ListFaultCodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListFaultCodesRequest;
  return proto.fox.proto.monitoring.ListFaultCodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListFaultCodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListFaultCodesRequest}
 */
proto.fox.proto.monitoring.ListFaultCodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListFaultCodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListFaultCodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListFaultCodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultCodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListFaultCodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListFaultCodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListFaultCodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListFaultCodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultCodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    faultCodesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListFaultCodesResponse}
 */
proto.fox.proto.monitoring.ListFaultCodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListFaultCodesResponse;
  return proto.fox.proto.monitoring.ListFaultCodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListFaultCodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListFaultCodesResponse}
 */
proto.fox.proto.monitoring.ListFaultCodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addFaultCodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListFaultCodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListFaultCodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListFaultCodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultCodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaultCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string fault_codes = 1;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.ListFaultCodesResponse.prototype.getFaultCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.ListFaultCodesResponse} returns this
 */
proto.fox.proto.monitoring.ListFaultCodesResponse.prototype.setFaultCodesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.ListFaultCodesResponse} returns this
 */
proto.fox.proto.monitoring.ListFaultCodesResponse.prototype.addFaultCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListFaultCodesResponse} returns this
 */
proto.fox.proto.monitoring.ListFaultCodesResponse.prototype.clearFaultCodesList = function() {
  return this.setFaultCodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListOrganizationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListOrganizationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListOrganizationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOrganizationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListOrganizationsRequest}
 */
proto.fox.proto.monitoring.ListOrganizationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListOrganizationsRequest;
  return proto.fox.proto.monitoring.ListOrganizationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListOrganizationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListOrganizationsRequest}
 */
proto.fox.proto.monitoring.ListOrganizationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListOrganizationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListOrganizationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListOrganizationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOrganizationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListOrganizationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListOrganizationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListOrganizationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListOrganizationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOrganizationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(),
    proto.fox.proto.monitoring.Organization.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListOrganizationsResponse}
 */
proto.fox.proto.monitoring.ListOrganizationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListOrganizationsResponse;
  return proto.fox.proto.monitoring.ListOrganizationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListOrganizationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListOrganizationsResponse}
 */
proto.fox.proto.monitoring.ListOrganizationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.Organization;
      reader.readMessage(value,proto.fox.proto.monitoring.Organization.deserializeBinaryFromReader);
      msg.addOrganizations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListOrganizationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListOrganizationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListOrganizationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOrganizationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Organization organizations = 1;
 * @return {!Array<!proto.fox.proto.monitoring.Organization>}
 */
proto.fox.proto.monitoring.ListOrganizationsResponse.prototype.getOrganizationsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.Organization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.Organization, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.Organization>} value
 * @return {!proto.fox.proto.monitoring.ListOrganizationsResponse} returns this
*/
proto.fox.proto.monitoring.ListOrganizationsResponse.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.Organization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Organization}
 */
proto.fox.proto.monitoring.ListOrganizationsResponse.prototype.addOrganizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.Organization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListOrganizationsResponse} returns this
 */
proto.fox.proto.monitoring.ListOrganizationsResponse.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.Organization}
 */
proto.fox.proto.monitoring.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.Organization;
  return proto.fox.proto.monitoring.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.Organization}
 */
proto.fox.proto.monitoring.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.Organization.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Organization} returns this
 */
proto.fox.proto.monitoring.Organization.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.Organization.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Organization} returns this
 */
proto.fox.proto.monitoring.Organization.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.Organization.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Organization} returns this
 */
proto.fox.proto.monitoring.Organization.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.CreateAutonomyVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.CreateAutonomyVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.CreateAutonomyVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateAutonomyVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dockerTag: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.CreateAutonomyVersionRequest}
 */
proto.fox.proto.monitoring.CreateAutonomyVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.CreateAutonomyVersionRequest;
  return proto.fox.proto.monitoring.CreateAutonomyVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.CreateAutonomyVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.CreateAutonomyVersionRequest}
 */
proto.fox.proto.monitoring.CreateAutonomyVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockerTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.CreateAutonomyVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.CreateAutonomyVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.CreateAutonomyVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateAutonomyVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDockerTag();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string version_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateAutonomyVersionRequest.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateAutonomyVersionRequest} returns this
 */
proto.fox.proto.monitoring.CreateAutonomyVersionRequest.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string docker_tag = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateAutonomyVersionRequest.prototype.getDockerTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateAutonomyVersionRequest} returns this
 */
proto.fox.proto.monitoring.CreateAutonomyVersionRequest.prototype.setDockerTag = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateAutonomyVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dockerTag: jspb.Message.getFieldWithDefault(msg, 2, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateAutonomyVersionRequest}
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateAutonomyVersionRequest;
  return proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateAutonomyVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateAutonomyVersionRequest}
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockerTag(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateAutonomyVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDockerTag();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string version_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateAutonomyVersionRequest} returns this
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string docker_tag = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.prototype.getDockerTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateAutonomyVersionRequest} returns this
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.prototype.setDockerTag = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool active = 3;
 * @return {boolean}
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.UpdateAutonomyVersionRequest} returns this
 */
proto.fox.proto.monitoring.UpdateAutonomyVersionRequest.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.AutonomyVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.AutonomyVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.AutonomyVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.AutonomyVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dockerTag: jspb.Message.getFieldWithDefault(msg, 2, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.AutonomyVersion}
 */
proto.fox.proto.monitoring.AutonomyVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.AutonomyVersion;
  return proto.fox.proto.monitoring.AutonomyVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.AutonomyVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.AutonomyVersion}
 */
proto.fox.proto.monitoring.AutonomyVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockerTag(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.AutonomyVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.AutonomyVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.AutonomyVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.AutonomyVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDockerTag();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string version_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.AutonomyVersion.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.AutonomyVersion} returns this
 */
proto.fox.proto.monitoring.AutonomyVersion.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string docker_tag = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.AutonomyVersion.prototype.getDockerTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.AutonomyVersion} returns this
 */
proto.fox.proto.monitoring.AutonomyVersion.prototype.setDockerTag = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool active = 3;
 * @return {boolean}
 */
proto.fox.proto.monitoring.AutonomyVersion.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.AutonomyVersion} returns this
 */
proto.fox.proto.monitoring.AutonomyVersion.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListAutonomyVersionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListAutonomyVersionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListAutonomyVersionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListAutonomyVersionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListAutonomyVersionsRequest}
 */
proto.fox.proto.monitoring.ListAutonomyVersionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListAutonomyVersionsRequest;
  return proto.fox.proto.monitoring.ListAutonomyVersionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListAutonomyVersionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListAutonomyVersionsRequest}
 */
proto.fox.proto.monitoring.ListAutonomyVersionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListAutonomyVersionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListAutonomyVersionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListAutonomyVersionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListAutonomyVersionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListAutonomyVersionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListAutonomyVersionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListAutonomyVersionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListAutonomyVersionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListAutonomyVersionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autonomyVersionsList: jspb.Message.toObjectList(msg.getAutonomyVersionsList(),
    proto.fox.proto.monitoring.AutonomyVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListAutonomyVersionsResponse}
 */
proto.fox.proto.monitoring.ListAutonomyVersionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListAutonomyVersionsResponse;
  return proto.fox.proto.monitoring.ListAutonomyVersionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListAutonomyVersionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListAutonomyVersionsResponse}
 */
proto.fox.proto.monitoring.ListAutonomyVersionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.AutonomyVersion;
      reader.readMessage(value,proto.fox.proto.monitoring.AutonomyVersion.deserializeBinaryFromReader);
      msg.addAutonomyVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListAutonomyVersionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListAutonomyVersionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListAutonomyVersionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListAutonomyVersionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutonomyVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.AutonomyVersion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AutonomyVersion autonomy_versions = 1;
 * @return {!Array<!proto.fox.proto.monitoring.AutonomyVersion>}
 */
proto.fox.proto.monitoring.ListAutonomyVersionsResponse.prototype.getAutonomyVersionsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.AutonomyVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.AutonomyVersion, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.AutonomyVersion>} value
 * @return {!proto.fox.proto.monitoring.ListAutonomyVersionsResponse} returns this
*/
proto.fox.proto.monitoring.ListAutonomyVersionsResponse.prototype.setAutonomyVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.AutonomyVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.AutonomyVersion}
 */
proto.fox.proto.monitoring.ListAutonomyVersionsResponse.prototype.addAutonomyVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.AutonomyVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListAutonomyVersionsResponse} returns this
 */
proto.fox.proto.monitoring.ListAutonomyVersionsResponse.prototype.clearAutonomyVersionsList = function() {
  return this.setAutonomyVersionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.BulkLogUploadRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.BulkLogUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.BulkLogUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.BulkLogUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.BulkLogUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.BulkLogUploadRequest}
 */
proto.fox.proto.monitoring.BulkLogUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.BulkLogUploadRequest;
  return proto.fox.proto.monitoring.BulkLogUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.BulkLogUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.BulkLogUploadRequest}
 */
proto.fox.proto.monitoring.BulkLogUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRunNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.BulkLogUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.BulkLogUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.BulkLogUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.BulkLogUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string run_names = 1;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.BulkLogUploadRequest.prototype.getRunNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.BulkLogUploadRequest} returns this
 */
proto.fox.proto.monitoring.BulkLogUploadRequest.prototype.setRunNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.BulkLogUploadRequest} returns this
 */
proto.fox.proto.monitoring.BulkLogUploadRequest.prototype.addRunNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.BulkLogUploadRequest} returns this
 */
proto.fox.proto.monitoring.BulkLogUploadRequest.prototype.clearRunNamesList = function() {
  return this.setRunNamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.BulkLogUpload.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.BulkLogUpload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.BulkLogUpload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.BulkLogUpload.toObject = function(includeInstance, msg) {
  var f, obj = {
    relatedSyncDemandsList: jspb.Message.toObjectList(msg.getRelatedSyncDemandsList(),
    proto.fox.proto.monitoring.SyncDemand.toObject, includeInstance),
    requestedBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestedTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    complete: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    cancelled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.BulkLogUpload}
 */
proto.fox.proto.monitoring.BulkLogUpload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.BulkLogUpload;
  return proto.fox.proto.monitoring.BulkLogUpload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.BulkLogUpload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.BulkLogUpload}
 */
proto.fox.proto.monitoring.BulkLogUpload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.SyncDemand;
      reader.readMessage(value,proto.fox.proto.monitoring.SyncDemand.deserializeBinaryFromReader);
      msg.addRelatedSyncDemands(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestedBy(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequestedTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setComplete(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCancelled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.BulkLogUpload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.BulkLogUpload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.BulkLogUpload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelatedSyncDemandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.SyncDemand.serializeBinaryToWriter
    );
  }
  f = message.getRequestedBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestedTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getComplete();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCancelled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * repeated SyncDemand related_sync_demands = 1;
 * @return {!Array<!proto.fox.proto.monitoring.SyncDemand>}
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.getRelatedSyncDemandsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.SyncDemand>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.SyncDemand, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.SyncDemand>} value
 * @return {!proto.fox.proto.monitoring.BulkLogUpload} returns this
*/
proto.fox.proto.monitoring.BulkLogUpload.prototype.setRelatedSyncDemandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.SyncDemand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.SyncDemand}
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.addRelatedSyncDemands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.SyncDemand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.BulkLogUpload} returns this
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.clearRelatedSyncDemandsList = function() {
  return this.setRelatedSyncDemandsList([]);
};


/**
 * optional string requested_by = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.getRequestedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.BulkLogUpload} returns this
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.setRequestedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 requested_time = 3;
 * @return {number}
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.getRequestedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.BulkLogUpload} returns this
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.setRequestedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.BulkLogUpload} returns this
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool complete = 5;
 * @return {boolean}
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.getComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.BulkLogUpload} returns this
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.setComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool cancelled = 6;
 * @return {boolean}
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.getCancelled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.BulkLogUpload} returns this
 */
proto.fox.proto.monitoring.BulkLogUpload.prototype.setCancelled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListBulkLogUploadsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListBulkLogUploadsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    excludeComplete: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    excludeCancelled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    requestedBy: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListBulkLogUploadsRequest}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListBulkLogUploadsRequest;
  return proto.fox.proto.monitoring.ListBulkLogUploadsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListBulkLogUploadsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListBulkLogUploadsRequest}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeComplete(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeCancelled(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListBulkLogUploadsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListBulkLogUploadsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExcludeComplete();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getExcludeCancelled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRequestedBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool exclude_complete = 1;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.prototype.getExcludeComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListBulkLogUploadsRequest} returns this
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.prototype.setExcludeComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool exclude_cancelled = 2;
 * @return {boolean}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.prototype.getExcludeCancelled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.ListBulkLogUploadsRequest} returns this
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.prototype.setExcludeCancelled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string requested_by = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.prototype.getRequestedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListBulkLogUploadsRequest} returns this
 */
proto.fox.proto.monitoring.ListBulkLogUploadsRequest.prototype.setRequestedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListBulkLogUploadsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListBulkLogUploadsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListBulkLogUploadsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListBulkLogUploadsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bulkLogUploadsList: jspb.Message.toObjectList(msg.getBulkLogUploadsList(),
    proto.fox.proto.monitoring.BulkLogUpload.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListBulkLogUploadsResponse}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListBulkLogUploadsResponse;
  return proto.fox.proto.monitoring.ListBulkLogUploadsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListBulkLogUploadsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListBulkLogUploadsResponse}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.BulkLogUpload;
      reader.readMessage(value,proto.fox.proto.monitoring.BulkLogUpload.deserializeBinaryFromReader);
      msg.addBulkLogUploads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListBulkLogUploadsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListBulkLogUploadsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListBulkLogUploadsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBulkLogUploadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.BulkLogUpload.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BulkLogUpload bulk_log_uploads = 1;
 * @return {!Array<!proto.fox.proto.monitoring.BulkLogUpload>}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsResponse.prototype.getBulkLogUploadsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.BulkLogUpload>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.BulkLogUpload, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.BulkLogUpload>} value
 * @return {!proto.fox.proto.monitoring.ListBulkLogUploadsResponse} returns this
*/
proto.fox.proto.monitoring.ListBulkLogUploadsResponse.prototype.setBulkLogUploadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.BulkLogUpload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.BulkLogUpload}
 */
proto.fox.proto.monitoring.ListBulkLogUploadsResponse.prototype.addBulkLogUploads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.BulkLogUpload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListBulkLogUploadsResponse} returns this
 */
proto.fox.proto.monitoring.ListBulkLogUploadsResponse.prototype.clearBulkLogUploadsList = function() {
  return this.setBulkLogUploadsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.CreateSiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.CreateSiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateSiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nearestAirportCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customer: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subCustomer: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    streetAddress: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.CreateSiteRequest}
 */
proto.fox.proto.monitoring.CreateSiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.CreateSiteRequest;
  return proto.fox.proto.monitoring.CreateSiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.CreateSiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.CreateSiteRequest}
 */
proto.fox.proto.monitoring.CreateSiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNearestAirportCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubCustomer(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.CreateSiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.CreateSiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateSiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNearestAirportCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubCustomer();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStreetAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string nearest_airport_code = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.getNearestAirportCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateSiteRequest} returns this
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.setNearestAirportCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateSiteRequest} returns this
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sub_customer = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.getSubCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateSiteRequest} returns this
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.setSubCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateSiteRequest} returns this
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateSiteRequest} returns this
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateSiteRequest} returns this
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string street_address = 7;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.getStreetAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateSiteRequest} returns this
 */
proto.fox.proto.monitoring.CreateSiteRequest.prototype.setStreetAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.Site.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.Site.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.Site} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Site.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customer: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subCustomer: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    streetAddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    nearestAirportCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    formattedSiteId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.Site}
 */
proto.fox.proto.monitoring.Site.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.Site;
  return proto.fox.proto.monitoring.Site.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.Site} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.Site}
 */
proto.fox.proto.monitoring.Site.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubCustomer(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNearestAirportCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.Site.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.Site.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.Site} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.Site.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubCustomer();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStreetAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNearestAirportCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFormattedSiteId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.Site.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Site} returns this
 */
proto.fox.proto.monitoring.Site.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.Site.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Site} returns this
 */
proto.fox.proto.monitoring.Site.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sub_customer = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.Site.prototype.getSubCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Site} returns this
 */
proto.fox.proto.monitoring.Site.prototype.setSubCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.fox.proto.monitoring.Site.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Site} returns this
 */
proto.fox.proto.monitoring.Site.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.fox.proto.monitoring.Site.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Site} returns this
 */
proto.fox.proto.monitoring.Site.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string postal_code = 6;
 * @return {string}
 */
proto.fox.proto.monitoring.Site.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Site} returns this
 */
proto.fox.proto.monitoring.Site.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string street_address = 7;
 * @return {string}
 */
proto.fox.proto.monitoring.Site.prototype.getStreetAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Site} returns this
 */
proto.fox.proto.monitoring.Site.prototype.setStreetAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string nearest_airport_code = 8;
 * @return {string}
 */
proto.fox.proto.monitoring.Site.prototype.getNearestAirportCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Site} returns this
 */
proto.fox.proto.monitoring.Site.prototype.setNearestAirportCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string formatted_site_id = 9;
 * @return {string}
 */
proto.fox.proto.monitoring.Site.prototype.getFormattedSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.Site} returns this
 */
proto.fox.proto.monitoring.Site.prototype.setFormattedSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListSitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListSitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListSitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListSitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListSitesRequest}
 */
proto.fox.proto.monitoring.ListSitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListSitesRequest;
  return proto.fox.proto.monitoring.ListSitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListSitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListSitesRequest}
 */
proto.fox.proto.monitoring.ListSitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListSitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListSitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListSitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListSitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListSitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListSitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListSitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListSitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListSitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sitesList: jspb.Message.toObjectList(msg.getSitesList(),
    proto.fox.proto.monitoring.Site.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListSitesResponse}
 */
proto.fox.proto.monitoring.ListSitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListSitesResponse;
  return proto.fox.proto.monitoring.ListSitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListSitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListSitesResponse}
 */
proto.fox.proto.monitoring.ListSitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.Site;
      reader.readMessage(value,proto.fox.proto.monitoring.Site.deserializeBinaryFromReader);
      msg.addSites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListSitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListSitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListSitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListSitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.Site.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Site sites = 1;
 * @return {!Array<!proto.fox.proto.monitoring.Site>}
 */
proto.fox.proto.monitoring.ListSitesResponse.prototype.getSitesList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.Site>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.Site, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.Site>} value
 * @return {!proto.fox.proto.monitoring.ListSitesResponse} returns this
*/
proto.fox.proto.monitoring.ListSitesResponse.prototype.setSitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.Site=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.Site}
 */
proto.fox.proto.monitoring.ListSitesResponse.prototype.addSites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.Site, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListSitesResponse} returns this
 */
proto.fox.proto.monitoring.ListSitesResponse.prototype.clearSitesList = function() {
  return this.setSitesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RobotStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RobotStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeRunName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    goalActive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    interventionRequired: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    autoModeActive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    estopEngaged: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    batteryDischargeState: jspb.Message.getFieldWithDefault(msg, 6, 0),
    goalStartTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    currentSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    pickZoneName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    placeZoneName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    primaryFaultCode: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RobotStatus}
 */
proto.fox.proto.monitoring.RobotStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RobotStatus;
  return proto.fox.proto.monitoring.RobotStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RobotStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RobotStatus}
 */
proto.fox.proto.monitoring.RobotStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActiveRunName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoalActive(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInterventionRequired(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoModeActive(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEstopEngaged(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatteryDischargeState(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoalStartTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentSpeed(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickZoneName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceZoneName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryFaultCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RobotStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RobotStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveRunName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGoalActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getInterventionRequired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAutoModeActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEstopEngaged();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getBatteryDischargeState();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getGoalStartTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCurrentSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getPickZoneName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPlaceZoneName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPrimaryFaultCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string active_run_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.getActiveRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotStatus} returns this
 */
proto.fox.proto.monitoring.RobotStatus.prototype.setActiveRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool goal_active = 2;
 * @return {boolean}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.getGoalActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.RobotStatus} returns this
 */
proto.fox.proto.monitoring.RobotStatus.prototype.setGoalActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool intervention_required = 3;
 * @return {boolean}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.getInterventionRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.RobotStatus} returns this
 */
proto.fox.proto.monitoring.RobotStatus.prototype.setInterventionRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool auto_mode_active = 4;
 * @return {boolean}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.getAutoModeActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.RobotStatus} returns this
 */
proto.fox.proto.monitoring.RobotStatus.prototype.setAutoModeActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool estop_engaged = 5;
 * @return {boolean}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.getEstopEngaged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.RobotStatus} returns this
 */
proto.fox.proto.monitoring.RobotStatus.prototype.setEstopEngaged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 battery_discharge_state = 6;
 * @return {number}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.getBatteryDischargeState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RobotStatus} returns this
 */
proto.fox.proto.monitoring.RobotStatus.prototype.setBatteryDischargeState = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 goal_start_time = 7;
 * @return {number}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.getGoalStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RobotStatus} returns this
 */
proto.fox.proto.monitoring.RobotStatus.prototype.setGoalStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional float current_speed = 8;
 * @return {number}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.getCurrentSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.RobotStatus} returns this
 */
proto.fox.proto.monitoring.RobotStatus.prototype.setCurrentSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string pick_zone_name = 9;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.getPickZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotStatus} returns this
 */
proto.fox.proto.monitoring.RobotStatus.prototype.setPickZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string place_zone_name = 10;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.getPlaceZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotStatus} returns this
 */
proto.fox.proto.monitoring.RobotStatus.prototype.setPlaceZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string primary_fault_code = 11;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotStatus.prototype.getPrimaryFaultCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotStatus} returns this
 */
proto.fox.proto.monitoring.RobotStatus.prototype.setPrimaryFaultCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetApexStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetApexStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetApexStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetApexStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetApexStatsRequest}
 */
proto.fox.proto.monitoring.GetApexStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetApexStatsRequest;
  return proto.fox.proto.monitoring.GetApexStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetApexStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetApexStatsRequest}
 */
proto.fox.proto.monitoring.GetApexStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetApexStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetApexStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetApexStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetApexStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ApexStatsUrls.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ApexStatsUrls.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ApexStatsUrls} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ApexStatsUrls.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickStatsUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trailerStatsUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    interventionStatsUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ApexStatsUrls}
 */
proto.fox.proto.monitoring.ApexStatsUrls.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ApexStatsUrls;
  return proto.fox.proto.monitoring.ApexStatsUrls.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ApexStatsUrls} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ApexStatsUrls}
 */
proto.fox.proto.monitoring.ApexStatsUrls.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickStatsUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerStatsUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterventionStatsUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ApexStatsUrls.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ApexStatsUrls.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ApexStatsUrls} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ApexStatsUrls.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickStatsUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrailerStatsUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInterventionStatsUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string pick_stats_url = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ApexStatsUrls.prototype.getPickStatsUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ApexStatsUrls} returns this
 */
proto.fox.proto.monitoring.ApexStatsUrls.prototype.setPickStatsUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string trailer_stats_url = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.ApexStatsUrls.prototype.getTrailerStatsUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ApexStatsUrls} returns this
 */
proto.fox.proto.monitoring.ApexStatsUrls.prototype.setTrailerStatsUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string intervention_stats_url = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.ApexStatsUrls.prototype.getInterventionStatsUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ApexStatsUrls} returns this
 */
proto.fox.proto.monitoring.ApexStatsUrls.prototype.setInterventionStatsUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest}
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest;
  return proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest}
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertThresholdsList: jspb.Message.toObjectList(msg.getAlertThresholdsList(),
    proto.fox.proto.monitoring.FaultRateAlertThreshold.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse}
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse;
  return proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse}
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.FaultRateAlertThreshold;
      reader.readMessage(value,proto.fox.proto.monitoring.FaultRateAlertThreshold.deserializeBinaryFromReader);
      msg.addAlertThresholds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertThresholdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.FaultRateAlertThreshold.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FaultRateAlertThreshold alert_thresholds = 1;
 * @return {!Array<!proto.fox.proto.monitoring.FaultRateAlertThreshold>}
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.prototype.getAlertThresholdsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.FaultRateAlertThreshold>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.FaultRateAlertThreshold, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.FaultRateAlertThreshold>} value
 * @return {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse} returns this
*/
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.prototype.setAlertThresholdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.FaultRateAlertThreshold=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.FaultRateAlertThreshold}
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.prototype.addAlertThresholds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.FaultRateAlertThreshold, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse} returns this
 */
proto.fox.proto.monitoring.ListFaultRateAlertThresholdsResponse.prototype.clearAlertThresholdsList = function() {
  return this.setAlertThresholdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.FaultRateAlertThreshold.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.FaultRateAlertThreshold.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.FaultRateAlertThreshold} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultRateAlertThreshold.toObject = function(includeInstance, msg) {
  var f, obj = {
    faultCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.FaultRateAlertThreshold}
 */
proto.fox.proto.monitoring.FaultRateAlertThreshold.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.FaultRateAlertThreshold;
  return proto.fox.proto.monitoring.FaultRateAlertThreshold.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.FaultRateAlertThreshold} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.FaultRateAlertThreshold}
 */
proto.fox.proto.monitoring.FaultRateAlertThreshold.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaultCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.FaultRateAlertThreshold.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.FaultRateAlertThreshold.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.FaultRateAlertThreshold} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultRateAlertThreshold.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaultCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string fault_code = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.FaultRateAlertThreshold.prototype.getFaultCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.FaultRateAlertThreshold} returns this
 */
proto.fox.proto.monitoring.FaultRateAlertThreshold.prototype.setFaultCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float threshold = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.FaultRateAlertThreshold.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.FaultRateAlertThreshold} returns this
 */
proto.fox.proto.monitoring.FaultRateAlertThreshold.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    faultCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest}
 */
proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest;
  return proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest}
 */
proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaultCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaultCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string fault_code = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.prototype.getFaultCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest} returns this
 */
proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.prototype.setFaultCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float threshold = 2;
 * @return {number}
 */
proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest} returns this
 */
proto.fox.proto.monitoring.UpsertFaultRateAlertThresholdRequest.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.CreateOperatorQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.CreateOperatorQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.CreateOperatorQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateOperatorQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.CreateOperatorQuestionRequest}
 */
proto.fox.proto.monitoring.CreateOperatorQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.CreateOperatorQuestionRequest;
  return proto.fox.proto.monitoring.CreateOperatorQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.CreateOperatorQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.CreateOperatorQuestionRequest}
 */
proto.fox.proto.monitoring.CreateOperatorQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.CreateOperatorQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.CreateOperatorQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.CreateOperatorQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateOperatorQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string question = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateOperatorQuestionRequest.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateOperatorQuestionRequest} returns this
 */
proto.fox.proto.monitoring.CreateOperatorQuestionRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    locale: jspb.Message.getFieldWithDefault(msg, 2, ""),
    translation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest}
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest;
  return proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest}
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranslation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTranslation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string question_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.prototype.getQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest} returns this
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string locale = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest} returns this
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string translation = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.prototype.getTranslation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest} returns this
 */
proto.fox.proto.monitoring.UpsertOperatorQuestionTranslationRequest.prototype.setTranslation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answer: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest}
 */
proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest;
  return proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest}
 */
proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string answer = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest} returns this
 */
proto.fox.proto.monitoring.CreateOperatorAnswerChoiceRequest.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerChoiceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    locale: jspb.Message.getFieldWithDefault(msg, 2, ""),
    translation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest}
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest;
  return proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest}
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerChoiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranslation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerChoiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTranslation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string answer_choice_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.prototype.getAnswerChoiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest} returns this
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.prototype.setAnswerChoiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string locale = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest} returns this
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string translation = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.prototype.getTranslation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest} returns this
 */
proto.fox.proto.monitoring.UpsertOperatorAnswerChoiceTranslationRequest.prototype.setTranslation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.OperatorQuestion.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.OperatorQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.OperatorQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.OperatorQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.OperatorQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    translationsList: jspb.Message.toObjectList(msg.getTranslationsList(),
    proto.fox.proto.monitoring.OperatorQuestionTranslation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.OperatorQuestion}
 */
proto.fox.proto.monitoring.OperatorQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.OperatorQuestion;
  return proto.fox.proto.monitoring.OperatorQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.OperatorQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.OperatorQuestion}
 */
proto.fox.proto.monitoring.OperatorQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = new proto.fox.proto.monitoring.OperatorQuestionTranslation;
      reader.readMessage(value,proto.fox.proto.monitoring.OperatorQuestionTranslation.deserializeBinaryFromReader);
      msg.addTranslations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.OperatorQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.OperatorQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.OperatorQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.OperatorQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTranslationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.monitoring.OperatorQuestionTranslation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string question = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.OperatorQuestion.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.OperatorQuestion} returns this
 */
proto.fox.proto.monitoring.OperatorQuestion.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.OperatorQuestion.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.OperatorQuestion} returns this
 */
proto.fox.proto.monitoring.OperatorQuestion.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated OperatorQuestionTranslation translations = 3;
 * @return {!Array<!proto.fox.proto.monitoring.OperatorQuestionTranslation>}
 */
proto.fox.proto.monitoring.OperatorQuestion.prototype.getTranslationsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.OperatorQuestionTranslation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.OperatorQuestionTranslation, 3));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.OperatorQuestionTranslation>} value
 * @return {!proto.fox.proto.monitoring.OperatorQuestion} returns this
*/
proto.fox.proto.monitoring.OperatorQuestion.prototype.setTranslationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.monitoring.OperatorQuestionTranslation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.OperatorQuestionTranslation}
 */
proto.fox.proto.monitoring.OperatorQuestion.prototype.addTranslations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.monitoring.OperatorQuestionTranslation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.OperatorQuestion} returns this
 */
proto.fox.proto.monitoring.OperatorQuestion.prototype.clearTranslationsList = function() {
  return this.setTranslationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.OperatorQuestionTranslation.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.OperatorQuestionTranslation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.OperatorQuestionTranslation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.OperatorQuestionTranslation.toObject = function(includeInstance, msg) {
  var f, obj = {
    locale: jspb.Message.getFieldWithDefault(msg, 1, ""),
    translation: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.OperatorQuestionTranslation}
 */
proto.fox.proto.monitoring.OperatorQuestionTranslation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.OperatorQuestionTranslation;
  return proto.fox.proto.monitoring.OperatorQuestionTranslation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.OperatorQuestionTranslation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.OperatorQuestionTranslation}
 */
proto.fox.proto.monitoring.OperatorQuestionTranslation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranslation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.OperatorQuestionTranslation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.OperatorQuestionTranslation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.OperatorQuestionTranslation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.OperatorQuestionTranslation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTranslation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string locale = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.OperatorQuestionTranslation.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.OperatorQuestionTranslation} returns this
 */
proto.fox.proto.monitoring.OperatorQuestionTranslation.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string translation = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.OperatorQuestionTranslation.prototype.getTranslation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.OperatorQuestionTranslation} returns this
 */
proto.fox.proto.monitoring.OperatorQuestionTranslation.prototype.setTranslation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.OperatorAnswerChoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.OperatorAnswerChoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    answer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    avoidable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    translationsList: jspb.Message.toObjectList(msg.getTranslationsList(),
    proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoice}
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.OperatorAnswerChoice;
  return proto.fox.proto.monitoring.OperatorAnswerChoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.OperatorAnswerChoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoice}
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvoidable(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = new proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation;
      reader.readMessage(value,proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.deserializeBinaryFromReader);
      msg.addTranslations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.OperatorAnswerChoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.OperatorAnswerChoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvoidable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTranslationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string answer = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoice} returns this
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool avoidable = 2;
 * @return {boolean}
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.getAvoidable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoice} returns this
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.setAvoidable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoice} returns this
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated OperatorAnswerChoiceTranslation translations = 4;
 * @return {!Array<!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation>}
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.getTranslationsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation, 4));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation>} value
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoice} returns this
*/
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.setTranslationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation}
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.addTranslations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoice} returns this
 */
proto.fox.proto.monitoring.OperatorAnswerChoice.prototype.clearTranslationsList = function() {
  return this.setTranslationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.toObject = function(includeInstance, msg) {
  var f, obj = {
    locale: jspb.Message.getFieldWithDefault(msg, 1, ""),
    translation: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation}
 */
proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation;
  return proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation}
 */
proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranslation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTranslation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string locale = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation} returns this
 */
proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string translation = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.prototype.getTranslation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation} returns this
 */
proto.fox.proto.monitoring.OperatorAnswerChoiceTranslation.prototype.setTranslation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListOperatorQuestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListOperatorQuestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListOperatorQuestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOperatorQuestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListOperatorQuestionsRequest}
 */
proto.fox.proto.monitoring.ListOperatorQuestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListOperatorQuestionsRequest;
  return proto.fox.proto.monitoring.ListOperatorQuestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListOperatorQuestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListOperatorQuestionsRequest}
 */
proto.fox.proto.monitoring.ListOperatorQuestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListOperatorQuestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListOperatorQuestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListOperatorQuestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOperatorQuestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListOperatorQuestionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListOperatorQuestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListOperatorQuestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListOperatorQuestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOperatorQuestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.fox.proto.monitoring.OperatorQuestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListOperatorQuestionsResponse}
 */
proto.fox.proto.monitoring.ListOperatorQuestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListOperatorQuestionsResponse;
  return proto.fox.proto.monitoring.ListOperatorQuestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListOperatorQuestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListOperatorQuestionsResponse}
 */
proto.fox.proto.monitoring.ListOperatorQuestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.OperatorQuestion;
      reader.readMessage(value,proto.fox.proto.monitoring.OperatorQuestion.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListOperatorQuestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListOperatorQuestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListOperatorQuestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOperatorQuestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.OperatorQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OperatorQuestion questions = 1;
 * @return {!Array<!proto.fox.proto.monitoring.OperatorQuestion>}
 */
proto.fox.proto.monitoring.ListOperatorQuestionsResponse.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.OperatorQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.OperatorQuestion, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.OperatorQuestion>} value
 * @return {!proto.fox.proto.monitoring.ListOperatorQuestionsResponse} returns this
*/
proto.fox.proto.monitoring.ListOperatorQuestionsResponse.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.OperatorQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.OperatorQuestion}
 */
proto.fox.proto.monitoring.ListOperatorQuestionsResponse.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.OperatorQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListOperatorQuestionsResponse} returns this
 */
proto.fox.proto.monitoring.ListOperatorQuestionsResponse.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest}
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest;
  return proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest}
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.fox.proto.monitoring.OperatorAnswerChoice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse}
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse;
  return proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse}
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.OperatorAnswerChoice;
      reader.readMessage(value,proto.fox.proto.monitoring.OperatorAnswerChoice.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.OperatorAnswerChoice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OperatorAnswerChoice answers = 1;
 * @return {!Array<!proto.fox.proto.monitoring.OperatorAnswerChoice>}
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.OperatorAnswerChoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.OperatorAnswerChoice, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.OperatorAnswerChoice>} value
 * @return {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse} returns this
*/
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.OperatorAnswerChoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.OperatorAnswerChoice}
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.OperatorAnswerChoice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse} returns this
 */
proto.fox.proto.monitoring.ListOperatorAnswerChoicesResponse.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedQuestionsList: jspb.Message.toObjectList(msg.getUpdatedQuestionsList(),
    proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest;
  return proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.FaultCodeQuestionConfiguration;
      reader.readMessage(value,proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.deserializeBinaryFromReader);
      msg.addUpdatedQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FaultCodeQuestionConfiguration updated_questions = 1;
 * @return {!Array<!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration>}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.prototype.getUpdatedQuestionsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.FaultCodeQuestionConfiguration, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration>} value
 * @return {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest} returns this
*/
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.prototype.setUpdatedQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.prototype.addUpdatedQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.FaultCodeQuestionConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest} returns this
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsRequest.prototype.clearUpdatedQuestionsList = function() {
  return this.setUpdatedQuestionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedQuestionsList: jspb.Message.toObjectList(msg.getUpdatedQuestionsList(),
    proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse;
  return proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.FaultCodeQuestionConfiguration;
      reader.readMessage(value,proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.deserializeBinaryFromReader);
      msg.addUpdatedQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FaultCodeQuestionConfiguration updated_questions = 1;
 * @return {!Array<!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration>}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.prototype.getUpdatedQuestionsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.FaultCodeQuestionConfiguration, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration>} value
 * @return {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse} returns this
*/
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.prototype.setUpdatedQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration}
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.prototype.addUpdatedQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.FaultCodeQuestionConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse} returns this
 */
proto.fox.proto.monitoring.UpdateFaultCodeQuestionsResponse.prototype.clearUpdatedQuestionsList = function() {
  return this.setUpdatedQuestionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    numericCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstQuestionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstQuestionAnswerChoicesList: jspb.Message.toObjectList(msg.getFirstQuestionAnswerChoicesList(),
    proto.fox.proto.monitoring.FaultCodeAnswerChoice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration}
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.FaultCodeQuestionConfiguration;
  return proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration}
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumericCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstQuestionId(value);
      break;
    case 3:
      var value = new proto.fox.proto.monitoring.FaultCodeAnswerChoice;
      reader.readMessage(value,proto.fox.proto.monitoring.FaultCodeAnswerChoice.deserializeBinaryFromReader);
      msg.addFirstQuestionAnswerChoices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumericCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstQuestionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstQuestionAnswerChoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.monitoring.FaultCodeAnswerChoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 numeric_code = 1;
 * @return {number}
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.prototype.getNumericCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration} returns this
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.prototype.setNumericCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_question_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.prototype.getFirstQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration} returns this
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.prototype.setFirstQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FaultCodeAnswerChoice first_question_answer_choices = 3;
 * @return {!Array<!proto.fox.proto.monitoring.FaultCodeAnswerChoice>}
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.prototype.getFirstQuestionAnswerChoicesList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.FaultCodeAnswerChoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.FaultCodeAnswerChoice, 3));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.FaultCodeAnswerChoice>} value
 * @return {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration} returns this
*/
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.prototype.setFirstQuestionAnswerChoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.monitoring.FaultCodeAnswerChoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.FaultCodeAnswerChoice}
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.prototype.addFirstQuestionAnswerChoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.monitoring.FaultCodeAnswerChoice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration} returns this
 */
proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.prototype.clearFirstQuestionAnswerChoicesList = function() {
  return this.setFirstQuestionAnswerChoicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.FaultCodeAnswerChoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.FaultCodeAnswerChoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerChoiceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nextQuestionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nextQuestionAnswerChoicesList: jspb.Message.toObjectList(msg.getNextQuestionAnswerChoicesList(),
    proto.fox.proto.monitoring.FaultCodeAnswerChoice.toObject, includeInstance),
    avoidableByRobot: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.FaultCodeAnswerChoice}
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.FaultCodeAnswerChoice;
  return proto.fox.proto.monitoring.FaultCodeAnswerChoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.FaultCodeAnswerChoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.FaultCodeAnswerChoice}
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerChoiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextQuestionId(value);
      break;
    case 3:
      var value = new proto.fox.proto.monitoring.FaultCodeAnswerChoice;
      reader.readMessage(value,proto.fox.proto.monitoring.FaultCodeAnswerChoice.deserializeBinaryFromReader);
      msg.addNextQuestionAnswerChoices(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.command_control.FaultAvoidableByRobot} */ (reader.readEnum());
      msg.setAvoidableByRobot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.FaultCodeAnswerChoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.FaultCodeAnswerChoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerChoiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNextQuestionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNextQuestionAnswerChoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.monitoring.FaultCodeAnswerChoice.serializeBinaryToWriter
    );
  }
  f = message.getAvoidableByRobot();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string answer_choice_id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.getAnswerChoiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.FaultCodeAnswerChoice} returns this
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.setAnswerChoiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string next_question_id = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.getNextQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.FaultCodeAnswerChoice} returns this
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.setNextQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FaultCodeAnswerChoice next_question_answer_choices = 3;
 * @return {!Array<!proto.fox.proto.monitoring.FaultCodeAnswerChoice>}
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.getNextQuestionAnswerChoicesList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.FaultCodeAnswerChoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.FaultCodeAnswerChoice, 3));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.FaultCodeAnswerChoice>} value
 * @return {!proto.fox.proto.monitoring.FaultCodeAnswerChoice} returns this
*/
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.setNextQuestionAnswerChoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.monitoring.FaultCodeAnswerChoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.FaultCodeAnswerChoice}
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.addNextQuestionAnswerChoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.monitoring.FaultCodeAnswerChoice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.FaultCodeAnswerChoice} returns this
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.clearNextQuestionAnswerChoicesList = function() {
  return this.setNextQuestionAnswerChoicesList([]);
};


/**
 * optional fox.proto.command_control.FaultAvoidableByRobot avoidable_by_robot = 4;
 * @return {!proto.fox.proto.command_control.FaultAvoidableByRobot}
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.getAvoidableByRobot = function() {
  return /** @type {!proto.fox.proto.command_control.FaultAvoidableByRobot} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.command_control.FaultAvoidableByRobot} value
 * @return {!proto.fox.proto.monitoring.FaultCodeAnswerChoice} returns this
 */
proto.fox.proto.monitoring.FaultCodeAnswerChoice.prototype.setAvoidableByRobot = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest}
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest;
  return proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest}
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    faultCodeQuestionsList: jspb.Message.toObjectList(msg.getFaultCodeQuestionsList(),
    proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse}
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse;
  return proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse}
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.FaultCodeQuestionConfiguration;
      reader.readMessage(value,proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.deserializeBinaryFromReader);
      msg.addFaultCodeQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaultCodeQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.FaultCodeQuestionConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FaultCodeQuestionConfiguration fault_code_questions = 1;
 * @return {!Array<!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration>}
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.prototype.getFaultCodeQuestionsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.FaultCodeQuestionConfiguration, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration>} value
 * @return {!proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse} returns this
*/
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.prototype.setFaultCodeQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.FaultCodeQuestionConfiguration}
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.prototype.addFaultCodeQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.FaultCodeQuestionConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse} returns this
 */
proto.fox.proto.monitoring.ListFaultCodeQuestionsResponse.prototype.clearFaultCodeQuestionsList = function() {
  return this.setFaultCodeQuestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.PublishFaultTranslationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.PublishFaultTranslationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.PublishFaultTranslationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PublishFaultTranslationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.PublishFaultTranslationsRequest}
 */
proto.fox.proto.monitoring.PublishFaultTranslationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.PublishFaultTranslationsRequest;
  return proto.fox.proto.monitoring.PublishFaultTranslationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.PublishFaultTranslationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.PublishFaultTranslationsRequest}
 */
proto.fox.proto.monitoring.PublishFaultTranslationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.PublishFaultTranslationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.PublishFaultTranslationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.PublishFaultTranslationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.PublishFaultTranslationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string version_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.PublishFaultTranslationsRequest.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.PublishFaultTranslationsRequest} returns this
 */
proto.fox.proto.monitoring.PublishFaultTranslationsRequest.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListFaultTranslationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListFaultTranslationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListFaultTranslationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultTranslationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    locale: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListFaultTranslationsRequest}
 */
proto.fox.proto.monitoring.ListFaultTranslationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListFaultTranslationsRequest;
  return proto.fox.proto.monitoring.ListFaultTranslationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListFaultTranslationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListFaultTranslationsRequest}
 */
proto.fox.proto.monitoring.ListFaultTranslationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListFaultTranslationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListFaultTranslationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListFaultTranslationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultTranslationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string locale = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.ListFaultTranslationsRequest.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.ListFaultTranslationsRequest} returns this
 */
proto.fox.proto.monitoring.ListFaultTranslationsRequest.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest}
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest;
  return proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest}
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    faultConfigVersionsList: jspb.Message.toObjectList(msg.getFaultConfigVersionsList(),
    proto.fox.proto.monitoring.FaultConfigurationVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse}
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse;
  return proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse}
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.FaultConfigurationVersion;
      reader.readMessage(value,proto.fox.proto.monitoring.FaultConfigurationVersion.deserializeBinaryFromReader);
      msg.addFaultConfigVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaultConfigVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.FaultConfigurationVersion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FaultConfigurationVersion fault_config_versions = 1;
 * @return {!Array<!proto.fox.proto.monitoring.FaultConfigurationVersion>}
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.prototype.getFaultConfigVersionsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.FaultConfigurationVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.FaultConfigurationVersion, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.FaultConfigurationVersion>} value
 * @return {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse} returns this
*/
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.prototype.setFaultConfigVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.FaultConfigurationVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.FaultConfigurationVersion}
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.prototype.addFaultConfigVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.FaultConfigurationVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse} returns this
 */
proto.fox.proto.monitoring.ListFaultConfigurationVersionsResponse.prototype.clearFaultConfigVersionsList = function() {
  return this.setFaultConfigVersionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.FaultConfigurationVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.FaultConfigurationVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    faultTranslationsList: jspb.Message.toObjectList(msg.getFaultTranslationsList(),
    command_control_proto_command_control_pb.FaultTranslations.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.FaultConfigurationVersion}
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.FaultConfigurationVersion;
  return proto.fox.proto.monitoring.FaultConfigurationVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.FaultConfigurationVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.FaultConfigurationVersion}
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    case 2:
      var value = new command_control_proto_command_control_pb.FaultTranslations;
      reader.readMessage(value,command_control_proto_command_control_pb.FaultTranslations.deserializeBinaryFromReader);
      msg.addFaultTranslations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.FaultConfigurationVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.FaultConfigurationVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFaultTranslationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      command_control_proto_command_control_pb.FaultTranslations.serializeBinaryToWriter
    );
  }
};


/**
 * optional string version_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.FaultConfigurationVersion} returns this
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated fox.proto.command_control.FaultTranslations fault_translations = 2;
 * @return {!Array<!proto.fox.proto.command_control.FaultTranslations>}
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.prototype.getFaultTranslationsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.FaultTranslations>} */ (
    jspb.Message.getRepeatedWrapperField(this, command_control_proto_command_control_pb.FaultTranslations, 2));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.FaultTranslations>} value
 * @return {!proto.fox.proto.monitoring.FaultConfigurationVersion} returns this
*/
proto.fox.proto.monitoring.FaultConfigurationVersion.prototype.setFaultTranslationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.command_control.FaultTranslations=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.FaultTranslations}
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.prototype.addFaultTranslations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.command_control.FaultTranslations, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.FaultConfigurationVersion} returns this
 */
proto.fox.proto.monitoring.FaultConfigurationVersion.prototype.clearFaultTranslationsList = function() {
  return this.setFaultTranslationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest}
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest;
  return proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest}
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest} returns this
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RobotTabletConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RobotTabletConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    faultConfigurationVersion: (f = msg.getFaultConfigurationVersion()) && proto.fox.proto.monitoring.FaultConfigurationVersion.toObject(includeInstance, f),
    flagOptionsList: jspb.Message.toObjectList(msg.getFlagOptionsList(),
    command_control_proto_command_control_pb.FlagOption.toObject, includeInstance),
    enabledOptionalFeaturesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RobotTabletConfiguration}
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RobotTabletConfiguration;
  return proto.fox.proto.monitoring.RobotTabletConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RobotTabletConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RobotTabletConfiguration}
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 2:
      var value = new proto.fox.proto.monitoring.FaultConfigurationVersion;
      reader.readMessage(value,proto.fox.proto.monitoring.FaultConfigurationVersion.deserializeBinaryFromReader);
      msg.setFaultConfigurationVersion(value);
      break;
    case 3:
      var value = new command_control_proto_command_control_pb.FlagOption;
      reader.readMessage(value,command_control_proto_command_control_pb.FlagOption.deserializeBinaryFromReader);
      msg.addFlagOptions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addEnabledOptionalFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RobotTabletConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RobotTabletConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFaultConfigurationVersion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.monitoring.FaultConfigurationVersion.serializeBinaryToWriter
    );
  }
  f = message.getFlagOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      command_control_proto_command_control_pb.FlagOption.serializeBinaryToWriter
    );
  }
  f = message.getEnabledOptionalFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotTabletConfiguration} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FaultConfigurationVersion fault_configuration_version = 2;
 * @return {?proto.fox.proto.monitoring.FaultConfigurationVersion}
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.getFaultConfigurationVersion = function() {
  return /** @type{?proto.fox.proto.monitoring.FaultConfigurationVersion} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.monitoring.FaultConfigurationVersion, 2));
};


/**
 * @param {?proto.fox.proto.monitoring.FaultConfigurationVersion|undefined} value
 * @return {!proto.fox.proto.monitoring.RobotTabletConfiguration} returns this
*/
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.setFaultConfigurationVersion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.RobotTabletConfiguration} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.clearFaultConfigurationVersion = function() {
  return this.setFaultConfigurationVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.hasFaultConfigurationVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated fox.proto.command_control.FlagOption flag_options = 3;
 * @return {!Array<!proto.fox.proto.command_control.FlagOption>}
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.getFlagOptionsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.FlagOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, command_control_proto_command_control_pb.FlagOption, 3));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.FlagOption>} value
 * @return {!proto.fox.proto.monitoring.RobotTabletConfiguration} returns this
*/
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.setFlagOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.command_control.FlagOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.FlagOption}
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.addFlagOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.command_control.FlagOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RobotTabletConfiguration} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.clearFlagOptionsList = function() {
  return this.setFlagOptionsList([]);
};


/**
 * repeated string enabled_optional_features = 4;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.getEnabledOptionalFeaturesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.RobotTabletConfiguration} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.setEnabledOptionalFeaturesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RobotTabletConfiguration} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.addEnabledOptionalFeatures = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RobotTabletConfiguration} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfiguration.prototype.clearEnabledOptionalFeaturesList = function() {
  return this.setEnabledOptionalFeaturesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest}
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest;
  return proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest}
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RobotTabletConfigurationSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    faultConfigurationVersionName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    flagOptionsList: jspb.Message.toObjectList(msg.getFlagOptionsList(),
    command_control_proto_command_control_pb.FlagOption.toObject, includeInstance),
    enabledOptionalFeaturesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RobotTabletConfigurationSummary;
  return proto.fox.proto.monitoring.RobotTabletConfigurationSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaultConfigurationVersionName(value);
      break;
    case 3:
      var value = new command_control_proto_command_control_pb.FlagOption;
      reader.readMessage(value,command_control_proto_command_control_pb.FlagOption.deserializeBinaryFromReader);
      msg.addFlagOptions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addEnabledOptionalFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RobotTabletConfigurationSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFaultConfigurationVersionName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlagOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      command_control_proto_command_control_pb.FlagOption.serializeBinaryToWriter
    );
  }
  f = message.getEnabledOptionalFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fault_configuration_version_name = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.getFaultConfigurationVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.setFaultConfigurationVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated fox.proto.command_control.FlagOption flag_options = 3;
 * @return {!Array<!proto.fox.proto.command_control.FlagOption>}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.getFlagOptionsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.FlagOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, command_control_proto_command_control_pb.FlagOption, 3));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.FlagOption>} value
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary} returns this
*/
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.setFlagOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.command_control.FlagOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.FlagOption}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.addFlagOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.command_control.FlagOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.clearFlagOptionsList = function() {
  return this.setFlagOptionsList([]);
};


/**
 * repeated string enabled_optional_features = 4;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.getEnabledOptionalFeaturesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.setEnabledOptionalFeaturesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.addEnabledOptionalFeatures = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSummary.prototype.clearEnabledOptionalFeaturesList = function() {
  return this.setEnabledOptionalFeaturesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotTabletConfigurationsList: jspb.Message.toObjectList(msg.getRobotTabletConfigurationsList(),
    proto.fox.proto.monitoring.RobotTabletConfigurationSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse}
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse;
  return proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse}
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.RobotTabletConfigurationSummary;
      reader.readMessage(value,proto.fox.proto.monitoring.RobotTabletConfigurationSummary.deserializeBinaryFromReader);
      msg.addRobotTabletConfigurations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotTabletConfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.RobotTabletConfigurationSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RobotTabletConfigurationSummary robot_tablet_configurations = 1;
 * @return {!Array<!proto.fox.proto.monitoring.RobotTabletConfigurationSummary>}
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.prototype.getRobotTabletConfigurationsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.RobotTabletConfigurationSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.RobotTabletConfigurationSummary, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.RobotTabletConfigurationSummary>} value
 * @return {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse} returns this
*/
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.prototype.setRobotTabletConfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSummary}
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.prototype.addRobotTabletConfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.RobotTabletConfigurationSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse} returns this
 */
proto.fox.proto.monitoring.ListRobotTabletConfigurationsResponse.prototype.clearRobotTabletConfigurationsList = function() {
  return this.setRobotTabletConfigurationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    faultConfigurationVersionName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    flagIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    enabledOptionalFeaturesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest;
  return proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaultConfigurationVersionName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFlagIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addEnabledOptionalFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFaultConfigurationVersionName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlagIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getEnabledOptionalFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fault_configuration_version_name = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.getFaultConfigurationVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.setFaultConfigurationVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string flag_ids = 3;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.getFlagIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.setFlagIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.addFlagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.clearFlagIdsList = function() {
  return this.setFlagIdsList([]);
};


/**
 * repeated string enabled_optional_features = 4;
 * @return {!Array<string>}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.getEnabledOptionalFeaturesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.setEnabledOptionalFeaturesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.addEnabledOptionalFeatures = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationRequest.prototype.clearEnabledOptionalFeaturesList = function() {
  return this.setEnabledOptionalFeaturesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest}
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest;
  return proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest}
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest} returns this
 */
proto.fox.proto.monitoring.GetRobotTabletConfigurationSyncStatusRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasPendingUpdates: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus;
  return proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPendingUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasPendingUpdates();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool has_pending_updates = 1;
 * @return {boolean}
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.prototype.getHasPendingUpdates = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus} returns this
 */
proto.fox.proto.monitoring.RobotTabletConfigurationSyncStatus.prototype.setHasPendingUpdates = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hasPendingUpdates: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest;
  return proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPendingUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHasPendingUpdates();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string robot_name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool has_pending_updates = 2;
 * @return {boolean}
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.prototype.getHasPendingUpdates = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest} returns this
 */
proto.fox.proto.monitoring.UpdateRobotTabletConfigurationSyncStatusRequest.prototype.setHasPendingUpdates = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest}
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest;
  return proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest}
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tabletOptionalFeaturesList: jspb.Message.toObjectList(msg.getTabletOptionalFeaturesList(),
    proto.fox.proto.monitoring.TabletOptionalFeature.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse}
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse;
  return proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse}
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.TabletOptionalFeature;
      reader.readMessage(value,proto.fox.proto.monitoring.TabletOptionalFeature.deserializeBinaryFromReader);
      msg.addTabletOptionalFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTabletOptionalFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.TabletOptionalFeature.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TabletOptionalFeature tablet_optional_features = 1;
 * @return {!Array<!proto.fox.proto.monitoring.TabletOptionalFeature>}
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.prototype.getTabletOptionalFeaturesList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.TabletOptionalFeature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.TabletOptionalFeature, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.TabletOptionalFeature>} value
 * @return {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse} returns this
*/
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.prototype.setTabletOptionalFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.TabletOptionalFeature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.TabletOptionalFeature}
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.prototype.addTabletOptionalFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.TabletOptionalFeature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse} returns this
 */
proto.fox.proto.monitoring.ListTabletOptionalFeaturesResponse.prototype.clearTabletOptionalFeaturesList = function() {
  return this.setTabletOptionalFeaturesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.TabletOptionalFeature.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.TabletOptionalFeature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.TabletOptionalFeature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TabletOptionalFeature.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.TabletOptionalFeature}
 */
proto.fox.proto.monitoring.TabletOptionalFeature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.TabletOptionalFeature;
  return proto.fox.proto.monitoring.TabletOptionalFeature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.TabletOptionalFeature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.TabletOptionalFeature}
 */
proto.fox.proto.monitoring.TabletOptionalFeature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.TabletOptionalFeature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.TabletOptionalFeature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.TabletOptionalFeature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TabletOptionalFeature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.TabletOptionalFeature.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TabletOptionalFeature} returns this
 */
proto.fox.proto.monitoring.TabletOptionalFeature.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.TabletOptionalFeature.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TabletOptionalFeature} returns this
 */
proto.fox.proto.monitoring.TabletOptionalFeature.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.fox.proto.monitoring.TabletOptionalFeature.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TabletOptionalFeature} returns this
 */
proto.fox.proto.monitoring.TabletOptionalFeature.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest}
 */
proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest;
  return proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest}
 */
proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest} returns this
 */
proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest} returns this
 */
proto.fox.proto.monitoring.CreateTabletOptionalFeatureRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListTabletFlagOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListTabletFlagOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListTabletFlagOptionsRequest}
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListTabletFlagOptionsRequest;
  return proto.fox.proto.monitoring.ListTabletFlagOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListTabletFlagOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListTabletFlagOptionsRequest}
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListTabletFlagOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListTabletFlagOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.ListTabletFlagOptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tabletFlagOptionsList: jspb.Message.toObjectList(msg.getTabletFlagOptionsList(),
    proto.fox.proto.monitoring.TabletFlagOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.ListTabletFlagOptionsResponse}
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.ListTabletFlagOptionsResponse;
  return proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.ListTabletFlagOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.ListTabletFlagOptionsResponse}
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.monitoring.TabletFlagOption;
      reader.readMessage(value,proto.fox.proto.monitoring.TabletFlagOption.deserializeBinaryFromReader);
      msg.addTabletFlagOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.ListTabletFlagOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTabletFlagOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.monitoring.TabletFlagOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TabletFlagOption tablet_flag_options = 1;
 * @return {!Array<!proto.fox.proto.monitoring.TabletFlagOption>}
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.prototype.getTabletFlagOptionsList = function() {
  return /** @type{!Array<!proto.fox.proto.monitoring.TabletFlagOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.monitoring.TabletFlagOption, 1));
};


/**
 * @param {!Array<!proto.fox.proto.monitoring.TabletFlagOption>} value
 * @return {!proto.fox.proto.monitoring.ListTabletFlagOptionsResponse} returns this
*/
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.prototype.setTabletFlagOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.monitoring.TabletFlagOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.monitoring.TabletFlagOption}
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.prototype.addTabletFlagOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.monitoring.TabletFlagOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.monitoring.ListTabletFlagOptionsResponse} returns this
 */
proto.fox.proto.monitoring.ListTabletFlagOptionsResponse.prototype.clearTabletFlagOptionsList = function() {
  return this.setTabletFlagOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.TabletFlagOption.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.TabletFlagOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.TabletFlagOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TabletFlagOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    flagOption: (f = msg.getFlagOption()) && command_control_proto_command_control_pb.FlagOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.TabletFlagOption}
 */
proto.fox.proto.monitoring.TabletFlagOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.TabletFlagOption;
  return proto.fox.proto.monitoring.TabletFlagOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.TabletFlagOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.TabletFlagOption}
 */
proto.fox.proto.monitoring.TabletFlagOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new command_control_proto_command_control_pb.FlagOption;
      reader.readMessage(value,command_control_proto_command_control_pb.FlagOption.deserializeBinaryFromReader);
      msg.setFlagOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.TabletFlagOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.TabletFlagOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.TabletFlagOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.TabletFlagOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFlagOption();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      command_control_proto_command_control_pb.FlagOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.monitoring.TabletFlagOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.monitoring.TabletFlagOption} returns this
 */
proto.fox.proto.monitoring.TabletFlagOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional fox.proto.command_control.FlagOption flag_option = 2;
 * @return {?proto.fox.proto.command_control.FlagOption}
 */
proto.fox.proto.monitoring.TabletFlagOption.prototype.getFlagOption = function() {
  return /** @type{?proto.fox.proto.command_control.FlagOption} */ (
    jspb.Message.getWrapperField(this, command_control_proto_command_control_pb.FlagOption, 2));
};


/**
 * @param {?proto.fox.proto.command_control.FlagOption|undefined} value
 * @return {!proto.fox.proto.monitoring.TabletFlagOption} returns this
*/
proto.fox.proto.monitoring.TabletFlagOption.prototype.setFlagOption = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.TabletFlagOption} returns this
 */
proto.fox.proto.monitoring.TabletFlagOption.prototype.clearFlagOption = function() {
  return this.setFlagOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.TabletFlagOption.prototype.hasFlagOption = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.monitoring.CreateTabletFlagOptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flagOption: (f = msg.getFlagOption()) && command_control_proto_command_control_pb.FlagOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.monitoring.CreateTabletFlagOptionRequest}
 */
proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.monitoring.CreateTabletFlagOptionRequest;
  return proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.monitoring.CreateTabletFlagOptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.monitoring.CreateTabletFlagOptionRequest}
 */
proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new command_control_proto_command_control_pb.FlagOption;
      reader.readMessage(value,command_control_proto_command_control_pb.FlagOption.deserializeBinaryFromReader);
      msg.setFlagOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.monitoring.CreateTabletFlagOptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlagOption();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      command_control_proto_command_control_pb.FlagOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional fox.proto.command_control.FlagOption flag_option = 1;
 * @return {?proto.fox.proto.command_control.FlagOption}
 */
proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.prototype.getFlagOption = function() {
  return /** @type{?proto.fox.proto.command_control.FlagOption} */ (
    jspb.Message.getWrapperField(this, command_control_proto_command_control_pb.FlagOption, 1));
};


/**
 * @param {?proto.fox.proto.command_control.FlagOption|undefined} value
 * @return {!proto.fox.proto.monitoring.CreateTabletFlagOptionRequest} returns this
*/
proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.prototype.setFlagOption = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.monitoring.CreateTabletFlagOptionRequest} returns this
 */
proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.prototype.clearFlagOption = function() {
  return this.setFlagOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.monitoring.CreateTabletFlagOptionRequest.prototype.hasFlagOption = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.fox.proto.monitoring.RunReviewStatus = {
  RUN_REVIEW_STATUS_UNREVIEWED: 0,
  RUN_REVIEW_STATUS_DECLINED: 1,
  RUN_REVIEW_STATUS_STARTED: 2,
  RUN_REVIEW_STATUS_COMPLETE: 3
};

/**
 * @enum {number}
 */
proto.fox.proto.monitoring.TimeSeriesMetricType = {
  TIME_SERIES_METRIC_UNKNOWN: 0,
  PALLET_PICK_PLACE_SUCCESS_RATE: 1,
  PALLET_FULL_PLACE_DURATION: 2,
  PALLET_FULL_PLACE_DURATION_ALL_PLACES: 3,
  PALLET_FULL_PLACE_DURATION_WITH_INTERVENTION: 7,
  PALLET_FULL_PLACE_DURATION_INTERVENTION_COST: 6,
  MANUAL_MODE_DURATION: 4,
  MANUAL_MODE_PER_RUN: 9,
  MANUAL_MODE_SETUP_TIME: 10,
  RUN_TIME: 11,
  CUMULATIVE_PALLET_PICK_PLACE_SUCCESSES: 5,
  CUMULATIVE_PALLET_PICK_PLACE_ALL_PLACES: 8,
  SPEED_TOWARDS_FORKS: 12,
  SPEED_FROM_FORKS: 13,
  SPEED_ANY_DIRECTION: 14,
  MAST_LIFT_SPEED_LIFTING: 15,
  MAST_LIFT_SPEED_DESCENDING: 16,
  AUTO_INTERVENTION_RATE: 17,
  INTERVENTION_RATE: 20,
  VEHICLE_THROUGHPUT: 18,
  INTERVENTION_RESPONSE_TIME: 19
};

/**
 * @enum {number}
 */
proto.fox.proto.monitoring.ScalarMetricType = {
  SCALAR_METRIC_UNKNOWN: 0,
  INTERVENTION_BY_DESCRIPTION_COUNTS: 1,
  INTERVENTION_BY_DESCRIPTION_DURATION_MEANS: 2,
  INTERVENTION_BY_DESCRIPTION_DURATION_MEDIANS: 3
};

/**
 * @enum {number}
 */
proto.fox.proto.monitoring.InterventionMechanism = {
  UNKNOWN: 0,
  MANUAL_MODE: 1,
  ESTOP: 2,
  GOAL_RESUBMISSION: 3,
  PALLET_MANUAL_UPDATE: 4,
  ZONE_MANUAL_UPDATE: 5
};

/**
 * @enum {number}
 */
proto.fox.proto.monitoring.OperationType = {
  OPERATION_TYPE_UNKNOWN: 0,
  OPERATION_TYPE_TRAILER_UNLOAD: 1,
  OPERATION_TYPE_TRAILER_LOAD: 2
};

/**
 * @enum {number}
 */
proto.fox.proto.monitoring.RevisionAcceptanceStatus = {
  REVISION_ACCEPTANCE_STATUS_PENDING: 0,
  REVISION_ACCEPTANCE_STATUS_APPLIED: 1,
  REVISION_ACCEPTANCE_STATUS_REJECTED: 2,
  REVISION_ACCEPTANCE_STATUS_IGNORED: 3
};

/**
 * @enum {number}
 */
proto.fox.proto.monitoring.RevisionAcceptancePolicy = {
  REVISION_ACCEPTANCE_POLICY_BEST_EFFORT: 0,
  REVISION_ACCEPTANCE_POLICY_ALL_OR_NOTHING: 1,
  REVISION_ACCEPTANCE_POLICY_DRY_RUN: 2,
  REVISION_ACCEPTANCE_POLICY_FORCE: 3
};

/**
 * @enum {number}
 */
proto.fox.proto.monitoring.DatasetType = {
  DATASET_TYPE_UNKNOWN: 0,
  DATASET_TYPE_TRAILER_STATS: 1,
  DATASET_TYPE_PICK_STATS: 2,
  DATASET_TYPE_RUN_STATS: 3,
  DATASET_TYPE_INTERVENTION_STATS: 4
};

goog.object.extend(exports, proto.fox.proto.monitoring);
