import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {FormControl, MenuItem, Select, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import * as payloads from "../../redux/payloads";
import m_pb, {
    FaultConfigurationVersion,
    TabletFlagOption,
    TabletOptionalFeature, UpdateRobotTabletConfigurationRequest
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ApplicationState, createTabletOptionalFeature, updateRobotTabletConfiguration} from "../../redux";
import {
    selectFaultConfigurationVersions,
    selectRobotAccounts,
    selectSites,
    selectTabletConfigurations,
    selectTabletFlagOptions,
    selectTabletOptionalFeatures
} from "../../redux/selectors";
import {useAppDispatch} from "../../redux/hooks";
import {client} from "../../redux/store";

interface Props {
  classes: any;
  dispatch: any;
  open: boolean;
  onClose: () => void;
  robots: payloads.RobotAccount[];
  sites: m_pb.Site.AsObject[];
  tabletFlagOptions: TabletFlagOption.AsObject[];
  tabletOptionalFeatures: TabletOptionalFeature.AsObject[];
  faultConfigurationVersions: FaultConfigurationVersion.AsObject[];
}

const mapStateToProps = (state: ApplicationState) => ({
  robots: selectRobotAccounts(state),
  sites: selectSites(state),
  robotTabletConfigurations: selectTabletConfigurations(state),
  tabletFlagOptions: selectTabletFlagOptions(state),
  tabletOptionalFeatures: selectTabletOptionalFeatures(state),
  faultConfigurationVersions: selectFaultConfigurationVersions(state),
});

const EditTabletConfigurationsDialog = (props: Props) => {
    const { robots, sites, tabletFlagOptions, tabletOptionalFeatures, faultConfigurationVersions, onClose } = props;
    const dispatch = useAppDispatch();

    const [ error, setError ] = useState("");

    const [ entityType, setEntityType ] = useState<"robot"|"site">("robot");
    const [ entityNames, setEntityNames ] = useState<string[]>([]);

    const [ selectedTabletFlagOptions, setSelectedTabletFlagOptions ] = useState<TabletFlagOption.AsObject[]>([]);
    const [ selectedTabletOptionalFeatures, setSelectedTabletOptionalFeatures ] = useState<string[]>([]);
    const [ selectedFaultConfigurationVersion, setSelectedFaultConfigurationVersion ] = useState("");

    const _submit = () => {

        const selectedRobots: string[] = [];
        if (entityType === "robot") {
            for (const robotName of entityNames) {
                selectedRobots.push(robotName);
            }
        } else if (entityType === "site") {
            for (const siteName of entityNames) {
                const site = sites.find(site => site.formattedSiteId === siteName);
                if (site) {
                    const siteRobotNames = robots.filter(robot => robot.siteId === site.id).map(robot => robot.robotName);
                    selectedRobots.push(...siteRobotNames);
                }
            }
        }

        for (const robotName of selectedRobots) {
            const req = new UpdateRobotTabletConfigurationRequest();
            req.setRobotName(robotName);
            req.setEnabledOptionalFeaturesList(selectedTabletOptionalFeatures);
            req.setFlagIdsList(selectedTabletFlagOptions.map(flagOption => flagOption.id));
            req.setFaultConfigurationVersionName(selectedFaultConfigurationVersion);
            dispatch(updateRobotTabletConfiguration({client, req}))
                .then()
                .catch(err => setError(err.toString()));
        }
        onClose()
    }

    return <React.Fragment>
        <Dialog
            open={props.open}
            onClose={() => props.onClose && props.onClose()}
            maxWidth={false}
        >
            <DialogTitle>Edit Tablet Configurations</DialogTitle>
            <DialogContent>
                {error && <Typography>{error}</Typography>}
                <Select
                    id="entity-type-select"
                    value={entityType}
                    label="Entities to update"
                    onChange={e => {
                      setEntityType(e.target.value as "robot"|"site")
                      setEntityNames([])
                    }}
                >
                    <MenuItem value={"robot"}>Robots</MenuItem>
                    <MenuItem value={"site"}>Sites</MenuItem>
                </Select>
                {entityType === "robot" && <Autocomplete
                  disableCloseOnSelect
                  multiple
                  id="robots-autocomplete"
                  options={robots.map(robot => robot.robotName)}
                  onChange={(event: any, newValues: Array<string> | null) => {
                    setEntityNames(newValues || []);
                  }}
                  value={entityNames}
                  style={{
                    minWidth: 200,
                    height: 56,
                    margin: 6,
                    display: "inline"
                  }}
                  renderInput={params => (
                    <TextField {...params} label="robot names" />
                  )}
                />}
                {entityType === "site" && <Autocomplete
                  disableCloseOnSelect
                  multiple
                  id="sites-autocomplete"
                  options={sites.map(site => site.formattedSiteId)}
                  onChange={(event: any, newValues: Array<string> | null) => {
                    setEntityNames(newValues || []);
                  }}
                  value={entityNames}
                  style={{
                    minWidth: 200,
                    height: 56,
                    margin: 6,
                    display: "inline"
                  }}
                  renderInput={params => (
                    <TextField {...params} label="sites" />
                  )}
                />}
                <Autocomplete
                  disableCloseOnSelect
                  id="fault-configuration-autocomplete"
                  options={faultConfigurationVersions.map(faultConfig => faultConfig.versionName)}
                  onChange={(event: any, newValue: string | null) => {
                    setSelectedFaultConfigurationVersion(newValue || "");
                  }}
                  value={selectedFaultConfigurationVersion}
                  style={{
                    minWidth: 200,
                    height: 56,
                    margin: 6,
                    display: "inline"
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Fault Config version" />
                  )}
                />
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  id="flag-options-autocomplete"
                  options={tabletFlagOptions}
                  getOptionLabel={(flagOption: TabletFlagOption.AsObject) => flagOption.flagOption?.flagName || ""}
                  onChange={(event: any, newValues: Array<TabletFlagOption.AsObject> | null) => {
                    setSelectedTabletFlagOptions(newValues || []);
                  }}
                  value={selectedTabletFlagOptions}
                  style={{
                    minWidth: 200,
                    height: 56,
                    margin: 6,
                    display: "inline"
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Flag Options" />
                  )}
                />
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  id="optional-features-autocomplete"
                  options={tabletOptionalFeatures.map(optionalFeature => optionalFeature.name)}
                  onChange={(event: any, newValues: Array<string> | null) => {
                    setSelectedTabletOptionalFeatures(newValues || []);
                  }}
                  value={selectedTabletOptionalFeatures}
                  style={{
                    minWidth: 200,
                    height: 56,
                    margin: 6,
                    display: "inline"
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Optional Features" />
                  )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={() => _submit()}
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
};

export default connect(mapStateToProps)(EditTabletConfigurationsDialog);