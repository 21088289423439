import React, {FunctionComponent, useEffect, useState} from "react";
import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import m_pb, {
  FaultConfigurationVersion, ListFaultConfigurationVersionsRequest,
  ListRobotAccountsRequest,
  ListRobotTabletConfigurationsRequest,
  ListTabletFlagOptionsRequest,
  ListTabletOptionalFeaturesRequest,
  OperatorAnswerChoice,
  OperatorQuestion, RobotTabletConfigurationSummary,
  Site, TabletFlagOption, TabletOptionalFeature
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import {
    listFaultTranslationsRequest,
    publishFaultTranslations,
    uploadFaultTranslationsRequest
} from "../../redux/actions";
import {ServiceError} from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import Typography from "@material-ui/core/Typography";
import FaultTable from "../FaultConfiguration/FaultTable";
import {Box, Button, Paper, TextField, withStyles} from "@material-ui/core";
import OperatorQuestionsDialog from "../FaultConfiguration/OperatorQuestionsDialog";
import {connect} from "react-redux";
import {createStyles, WithStyles} from "@material-ui/core/styles";
import RobotSearch from "../RobotSearch";
import {useAppDispatch} from "../../redux/hooks";
import * as payloads from "../../redux/payloads";
import {
  fetchFaultConfigurationVersions,
  fetchRobotAccounts,
  fetchSites,
  fetchTabletConfigurations, fetchTabletFlagOptions,
  fetchTabletOptionalFeatures
} from "../../redux/reducers";
import {client} from "../../redux/store";
import TabletConfigurationTable, {TabletConfigurationDetails} from "./TabletConfigurationTable";
import EditTabletConfigurationsDialog from "./EditTabletConfigurationsDialog";
import AddOptionalFeaturesDialog from "./AddOptionalFeatures";
import AddFlagOptionsDialog from "./AddFlagOptionsDialog";


const styles = () => createStyles({
  searchContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    paddingRight: 24,
    paddingLeft: 24
  },
  contentPaper: {
    padding: 16,
    marginBottom: 3
  },
});

interface Props extends WithStyles<typeof styles> {
  robotsBySite: Map<Site.AsObject, payloads.RobotAccount[]>;
  robotTabletConfigurations: RobotTabletConfigurationSummary.AsObject[];
  tabletFlagOptions: TabletFlagOption.AsObject[];
  tabletOptionalFeatures: TabletOptionalFeature.AsObject[];
  faultConfigurationVersions: FaultConfigurationVersion.AsObject[];
  classes: any;
}

const Component: FunctionComponent<Props> = (props: Props) => {
  const { classes, robotsBySite, robotTabletConfigurations, tabletFlagOptions, tabletOptionalFeatures, faultConfigurationVersions } = props;
  const dispatch = useAppDispatch();
  const [ pageLoaded, setPageLoaded ] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [ editingConfigurations, setEditingConfigurations ] = useState(false);
  const [ addingOptionalFeatures, setAddingOptionalFeatures ] = useState(false);
  const [ addingFlagOptions, setAddingFlagOptions ] = useState(false);

  const tabletConfigurationDetails: TabletConfigurationDetails[] = [];

  if (robotsBySite) {
    for (const [site, robots] of robotsBySite.entries()) {
      for (const robot of robots) {
        const robotTabletConfiguration = robotTabletConfigurations.find((tabletConfig) => tabletConfig.robotName === robot.robotName);
        tabletConfigurationDetails.push({
          robotName: robot.robotName,
          siteCode: site.formattedSiteId,
          faultConfigurationVersionName: robotTabletConfiguration?.faultConfigurationVersionName || "",
          enabledOptionalFeatures: robotTabletConfiguration?.enabledOptionalFeaturesList || [],
          flagOptions: robotTabletConfiguration?.flagOptionsList.map(flagOption => flagOption.flagName) || [],
        });
      }
    }
  }

  const getTabletConfigurationData = () => {
    const tabletOptionalFeaturesRequest = new ListTabletOptionalFeaturesRequest();
    const tabletFlagOptionsRequest = new ListTabletFlagOptionsRequest();
    const robotTabletConfigurationsRequest = new ListRobotTabletConfigurationsRequest();
    const faultConfigurationVersionsRequest = new ListFaultConfigurationVersionsRequest();
    const promises = [
      dispatch(fetchTabletConfigurations({
        client: client,
        req: robotTabletConfigurationsRequest,
      })),
      dispatch(fetchTabletOptionalFeatures({
        client: client,
        req: tabletOptionalFeaturesRequest,
      })),
      dispatch(fetchTabletFlagOptions({
        client: client,
        req: tabletFlagOptionsRequest,
      })),
      dispatch(fetchFaultConfigurationVersions({
        client,
        req: faultConfigurationVersionsRequest,
      })),
    ]
    Promise.all(promises)
        .then(() => setPageLoaded(true))
        .catch((err) => {
          setErrorMessage(err);
          console.warn(err)
        })
  }

  const getRobots = () => {
    const siteRequest = new ListRobotAccountsRequest();

    dispatch(fetchSites({
      client: client,
      req: siteRequest,
    }))
    .then((response) => {
      const robotRequest = new ListRobotAccountsRequest();
      robotRequest.setPageToken(0)
      robotRequest.setPageSize(250)

      dispatch(fetchRobotAccounts({
        client: client,
        req: robotRequest,
      }))
      .then((response) => {
        setPageLoaded(true)
      })
      .catch((err) => {
        console.warn(err)
        setErrorMessage(err);
        setPageLoaded(true)
      })
    })
    .catch((err) => {
      setErrorMessage(err);
      console.warn(err)
    })
  }

  useEffect(() => {
    getRobots();
    getTabletConfigurationData();
    // TODO(chris) Get cohorts (once Justin merges RTK stuff)
    // TODO(chris): Get orgs (if useful)
  }, []);

  return <div style={{textAlign: "center"}}>
    <Box className={classes.hero}>
      <Typography variant="h3">Tablet Configuration</Typography>
    </Box>
    {errorMessage && <div>{errorMessage}</div>}
    {pageLoaded && <div>
        <Button
          style={{margin: "8px"}}
          variant="contained"
          color="primary"
          onClick={() => setEditingConfigurations(true)}
        >Edit Tablet Configurations</Button>
        <Button
          style={{margin: "8px"}}
          variant="contained"
          color="secondary"
          onClick={() => setAddingOptionalFeatures(true)}
        >Set up new Optional Features</Button>
        <Button
          style={{margin: "8px"}}
          variant="contained"
          color="secondary"
          onClick={() => setAddingFlagOptions(true)}
        >Set up new Flag Options</Button>

        <TabletConfigurationTable
          tabletConfigurationDetails={tabletConfigurationDetails}
        />
      </div>}
    {editingConfigurations && <EditTabletConfigurationsDialog
      open={true}
      onClose={() => {
          setEditingConfigurations(false);
      }}
    />}

    {addingOptionalFeatures && <AddOptionalFeaturesDialog
      open={true}
      onClose={() => {
          setAddingOptionalFeatures(false);
      }}
    />}

    {addingFlagOptions && <AddFlagOptionsDialog
      open={true}
      onClose={() => {
          setAddingFlagOptions(false);
      }}
    />}

  </div>

}

export default connect()(withStyles(styles)(Component));