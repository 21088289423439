import {connect} from "react-redux";
import {Box, Button, withStyles} from "@material-ui/core";
import {createStyles, WithStyles} from "@material-ui/core/styles";

import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import FoxGrid from "../FoxGrid/foxGrid";
import {CloudUpload} from "@material-ui/icons";
import {Column} from "react-table";
import React, {useMemo} from "react";
import {protoEnumFieldName} from "../../utils/protos";

const styles = () => createStyles({});

export interface TabletConfigurationDetails {
    robotName: string;
    siteCode: string;
    faultConfigurationVersionName: string;
    enabledOptionalFeatures: string[];
    flagOptions: string[];
}

interface Props<T extends object> extends WithStyles<typeof styles> {
  tabletConfigurationDetails: TabletConfigurationDetails[];
}

const TabletConfigurationTable = <T extends object>(props: Props<T>) => {

  const columns: Column<TabletConfigurationDetails>[] = useMemo(
      () => [
        {
          Header: 'Robot Name',
          accessor: 'robotName',
        },
        {
          Header: 'Site Code',
          accessor: 'siteCode',
        },
        {
          Header: 'Fault Configuration Version',
          accessor: 'faultConfigurationVersionName',
        },
        {
          Header: 'Optional Features Enabled',
          accessor: (faultTranslation) => faultTranslation.enabledOptionalFeatures.join(",\n"),
        },
        {
          Header: 'Flag Options',
          accessor: (faultTranslation) => faultTranslation.flagOptions.join(",\n"),
        },
      ],
      []
    );

  return <FoxGrid
            title={'Fault Text'}
            data={props.tabletConfigurationDetails}
            columns={columns as Column<any>[]}
  />;
}

export default connect()(withStyles(styles)(TabletConfigurationTable));
