import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import DockConfigurationItem from "./DockConfigurationItem";
import DockConfigurationEditor from "./DockConfigurationEditor";
import FileUploadButton from "../Utils/FileUploadButton";

const styles = () =>
  createStyles({
    buttonContainer: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      margin: "1em",
      display: "block"
    },
    tableContainer: {
      margin: "2em"
    },
    pageHeader: {
      textAlign: "center"
    }
  });

interface Props extends WithStyles<typeof styles> {
  placeZoneTemplateNames: string[];
  pickZoneTemplateNames: string[];
  updateDockConfigs: (dockConfigs: m_pb.DockConfig[]) => void;
  dockConfigs: m_pb.DockConfig[];
}

interface State {
  editingDockIndex: number | null;
  lastRowSaved: boolean;
}

export function csvToArray(str: string, delimiter = ",") {

  // Remove all carriage returns so we don't have to handle both \r\n and \n separately
  const strNoCarriageReturn = str.replace(/\r/gm, "");
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  let lineDelimiter = "\n";
  const headers = strNoCarriageReturn.slice(0, strNoCarriageReturn.indexOf(lineDelimiter)).split(delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = strNoCarriageReturn.slice(strNoCarriageReturn.indexOf(lineDelimiter) + 1).split(lineDelimiter);

  return rows.map(function (row) {
    const values = row.split(delimiter);
    return headers.reduce(function (object, header, index) {
      // @ts-ignore
      object[header] = values[index];
      return object;
    }, {});
  });
}

class DockConfigurations extends Component<Props, State> {
  state: State = {
    editingDockIndex: null,
    lastRowSaved: true
  };

  _addDock = () => {
    const { dockConfigs } = this.props;
    dockConfigs.push(new m_pb.DockConfig());
    this.props.updateDockConfigs(dockConfigs);
    this.setState({
      editingDockIndex: dockConfigs.length - 1,
      lastRowSaved: false
    });
  };

  _updateDockConfig = (index: number, updatedDockConfig: m_pb.DockConfig) => {
    const { dockConfigs } = this.props;
    dockConfigs[index] = updatedDockConfig;
    this.props.updateDockConfigs(dockConfigs);
    this.setState({ editingDockIndex: null, lastRowSaved: true });
  };

  _deleteDockConfig = (index: number) => {
    const { dockConfigs } = this.props;
    dockConfigs.splice(index, 1);
    this.props.updateDockConfigs(dockConfigs);
    // this.setState({editingDockIndex: null});
  };

  _cancelUpdate = (index: number) => {
    // Delete the last dock config if it's newly added and not saved
    if (
      !this.state.lastRowSaved &&
      index === this.props.dockConfigs.length - 1
    ) {
      this._deleteDockConfig(index);
    }
    this.setState({ editingDockIndex: null });
  };

  _importDockMeasurements = (fileData: string) => {
    const csvData = csvToArray(fileData);
    const nonEmptyRows = csvData.filter((row) => {
      // @ts-ignore
      return row.hasOwnProperty("pick_zone:id") ? row["pick_zone:id"] : null;
    });
    const dockConfigs = nonEmptyRows.map((row) => {
      const dockConfig = new m_pb.DockConfig();
      // @ts-ignore
      dockConfig.setPickZoneId(parseInt(row["pick_zone:id"] as string, 10));
      // @ts-ignore
      dockConfig.setPickZoneName(row["pick_zone:name"] as string);
      // @ts-ignore
      dockConfig.setPickZoneTemplateName(row["pick_zone:template_name"]);
      // @ts-ignore
      dockConfig.setRampLength(parseFloat(row["pick_zone:length"] || 0));
      // @ts-ignore
      dockConfig.setRampWidth(parseFloat(row["pick_zone:width"] || 0));
      // @ts-ignore
      dockConfig.setRampWallLength(parseFloat(row["pick_zone:ramp_wall_length"] || 0));
      // @ts-ignore
      dockConfig.setRampLipToBumpers(parseFloat(row["pick_zone:lip_to_bumpers"] || 0));
      // @ts-ignore
      dockConfig.setRampLipToTagLine(parseFloat(row["pick_zone:lip_to_tag_line"] || 0));
      // @ts-ignore
      dockConfig.setLipToHinge(parseFloat(row["pick_zone:lip_to_hinge"] || 0));
      // @ts-ignore
      dockConfig.setHasRampWalls(row["pick_zone:has_ramp_walls"] === "TRUE");
      // @ts-ignore
      dockConfig.setLipToLeftTag(parseFloat(row["pick_zone:lip_to_left_tag"] || 0));
      // @ts-ignore
      dockConfig.setLipToRightTag(parseFloat(row["pick_zone:lip_to_right_tag"] || 0));
      // @ts-ignore
      dockConfig.setLipToClearHeight(parseFloat(row["pick_zone:lip_to_clear_height"] || 0));
      // @ts-ignore
      dockConfig.setLipToRampWall(parseFloat(row["pick_zone:lip_to_ramp_wall"] || 0));
      // @ts-ignore
      dockConfig.setEdgeToLeftTag(parseFloat(row["pick_zone:edge_to_left_tag"] || 0));
      // @ts-ignore
      dockConfig.setEdgeToRightTag(parseFloat(row["pick_zone:edge_to_right_tag"] || 0));
      // @ts-ignore
      dockConfig.setPickZoneCenterY(parseFloat(row["pick_zone:center_y"] || 0));
      // @ts-ignore
      dockConfig.setPlaceZoneId(row["place_zone:id"]);
      // @ts-ignore
      dockConfig.setPlaceZoneName(row["place_zone:name"]);
      // @ts-ignore
      dockConfig.setPlaceLeftToRight(row["place_zone:left_to_right"] === "TRUE");
      // @ts-ignore
      dockConfig.setRampToPlaceZoneX(parseFloat(row["place_zone:ramp_to_place_zone_x"] || 0));
      // @ts-ignore
      dockConfig.setRampToPlaceZoneY(parseFloat(row["place_zone:ramp_to_place_zone_y"] || 0));
      // @ts-ignore
      dockConfig.setPlaceZoneTemplateName(row["place_zone:template_name"]);
      // @ts-ignore
      dockConfig.setPlaceStrategy(row["place_zone:place_strategy"]);
      // @ts-ignore
      dockConfig.setRampToPlaceZoneRotation(parseFloat(row["place_zone:ramp_to_place_zone_rotation"] || 0));
      // @ts-ignore
      dockConfig.setOmniPadding(row["place_zone:omni_padding"] === "TRUE");
      if (row["place_zone:use_split_place_zone"]) {
        dockConfig.setUseSplitPlaceZone(row["place_zone:use_split_place_zone"]);
        dockConfig.setBatchSize(parseInt(row["place_zone:batch_size"], 10));
        dockConfig.setNumberOfBatches(parseInt(row["place_zone:number_of_batches"], 10));
      }
      return dockConfig;
    });
    this.props.updateDockConfigs(dockConfigs);
  }

  render() {
    const { editingDockIndex } = this.state;
    const { placeZoneTemplateNames, pickZoneTemplateNames, dockConfigs } = this.props;
    const docksJsx = (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pick Zone Name</TableCell>
              <TableCell align="right">Pick Zone Id</TableCell>
              <TableCell align="right">Place Zone Name</TableCell>
              <TableCell align="right">Place Zone Setback</TableCell>
              <TableCell align="right">Place Zone Horizontal Offset</TableCell>
              <TableCell align="right">Place Zone Template</TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {dockConfigs.map((dock, index) => {
              return (
                <TableRow>
                  {index === editingDockIndex ? (
                    <TableCell colSpan={6}>
                      <DockConfigurationEditor
                        placeZoneTemplateNames={placeZoneTemplateNames}
                        pickZoneTemplateNames={pickZoneTemplateNames}
                        dockConfig={dockConfigs[editingDockIndex]}
                        submit={modifiedDockConfig => {
                          this._updateDockConfig(
                            editingDockIndex,
                            modifiedDockConfig
                          );
                        }}
                        cancel={() => {
                          this._cancelUpdate(index);
                        }}
                      />
                    </TableCell>
                  ) : (
                    <DockConfigurationItem
                      dockConfig={dock}
                      delete={() => this._deleteDockConfig(index)}
                      edit={() => this.setState({ editingDockIndex: index })}
                      canEdit={this.state.editingDockIndex === null}
                    />
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
    return (
      <div>
        <Typography className={this.props.classes.pageHeader}>Pick/Place Zone Parameters:</Typography>
        {docksJsx}
        <Container className={this.props.classes.buttonContainer}>
          <Button
            className={this.props.classes.button}
            disabled={editingDockIndex != null}
            onClick={this._addDock}
            variant="outlined"
            color="primary"
          >
            Configure a new dock
          </Button>
          <FileUploadButton
            label="Import dock measurements (from CSV)"
            className={this.props.classes.button}
            color="primary"
            disabled={editingDockIndex != null}
            onload={(fileData) => this._importDockMeasurements(fileData)}
          />
        </Container>
      </div>
    );
  }
}

export default connect()(withStyles(styles)(DockConfigurations));
