import React, { Component } from "react";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";

import { ApplicationState } from "../../redux";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import Datasheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import {
  Column,
  FormattedData,
  statsToRows,
  StylableCSVLink
} from "./StatSheetUtils";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid } from "react-window";
import moment from "moment";

export interface GridElement extends Datasheet.Cell<GridElement, number> {
  value: number | string;
}

class TrailerStatsReactDataSheet extends Datasheet<GridElement, number> {}

const styles = (theme: Theme) =>
  createStyles({
    sheet: {
      width: "100%",
      height: "100%",
      marginBottom: 24
    },
    wrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 24
    },
    cell: {
      border: "1px solid",
      textAlign: "center"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  loading?: boolean;
  dispatch: any;
  stats: Array<m_pb.TrailerStats.AsObject>;
  omittedFieldNames?: Array<string>;
}
interface State {
  rows: GridElement[][];
  topLeft: { rowIdx: number; colIdx: number };
  botRight: { rowIdx: number; colIdx: number };
}

const clipDigits = (val: number, places: number): number =>
  Math.round(val * 10 ** places) / 10 ** places;

const defaultColumnProperties = {};
type formatter = (val: number | string) => number | string;
const emptyFormatter: formatter = (v: number | string) => String(v);
// Ignore the provided field and just return a constant always
const constantFormatter = (value: string) => (v: number | string) => value
const durationFormatter = (v: number) => clipDigits(v / 1e9, 2);
const nanosToHoursFormatter = (v: number) => clipDigits(v / (3600 * 1e9), 2);
const timeFormatter = (ns: number) =>
  new Date(ns / 1e6).toISOString();
const stringListFormatter = (v: Array<string>) => v.join(", ");
const pphFormatter = (v1: number, v2: number) => v2 ? Math.round(36000 * 1e9/ (v2/v1)) / 10 : 0;

const columns: Array<Column> = [
  { key: "trailerId", name: "Source_Reference_ID", formatter: emptyFormatter },
  { key: "trailerId", name: "Source_Reference_Type", formatter: constantFormatter("TRAILER") },
  { key: "objectiveId", name: "Objective_ID", formatter: emptyFormatter },
  {
    key: "startTime",
    name: "Start_Time",
    width: 320,
    formatter: timeFormatter
  },
  {
    key: "endTime",
    name: "End_Time",
    width: 320,
    formatter: timeFormatter
  },
  { key: "setupDuration", name: "Setup_s", formatter: durationFormatter },
  {
    key: "stoppageDuration",
    name: "Stoppage_s",
    formatter: durationFormatter
  },
  {
    key: "harStoppageDuration",
    name: "HAR Stoppage_s",
    formatter: durationFormatter
  },
  {
    key: "totalDuration",
    name: "Total_s",
    formatter: durationFormatter
  },
  {
    key: "allPicksDuration",
    name: "All Picks Duration",
    formatter: durationFormatter
  },
  {
    key: "successfulPicksDuration",
    name: "Successful Picks Duration",
    formatter: durationFormatter
  },
  {
    key: "autonomousPicksDuration",
    name: "Autonomous Picks Duration",
    formatter: durationFormatter
  },
  {
    key: "robotName",
    name: "Robot_Name",
    formatter: emptyFormatter
  },
  {
    key: "runIdsList",
    name: "Run_Names",
    formatter: stringListFormatter
  },
  {
    key: "versionNamesList",
    name: "Versions",
    formatter: stringListFormatter
  },
  {
    key: "cohortName",
    name: "Cohort_Name",
    formatter: emptyFormatter
  },
  {
    key: "commodityType",
    name: "Commodity_Type",
    formatter: emptyFormatter
  },
  {
    key: "placedStackSuccessCount",
    name: "Successful_Stacks",
    formatter: emptyFormatter
  },
  {
    key: "placedStackSuccessCountNew",
    name: "Successful_Stacks (new)",
    formatter: emptyFormatter
  },
  {
    key: "placedStackTotalCount",
    name: "Total_Stacks",
    formatter: emptyFormatter
  },
  {
    key: "autonomouslyAttemptedStacks",
    name: "Autonomously Attempted Stacks",
    formatter: emptyFormatter
  },
  {
    key: "placedPalletSuccessCount",
    name: "Successful_Pallets",
    formatter: emptyFormatter
  },
  {
    key: "placedPalletTotalCount",
    name: "Total_Pallets",
    formatter: emptyFormatter
  },
  {
    key: "dockName",
    name: "Destination_Reference_ID",
    formatter: emptyFormatter
  },{
    key: "destinationReferenceType",
    name: "Destination_Reference_Type",
    formatter: constantFormatter("DOCK")
  },{
    key: "locationCode",
    name: "Location_Code",
    formatter: emptyFormatter
  },{
    key: "robotCumulativeAutonomyTime",
    name: "Robot_Cumulative_Autonomy_Hours",
    formatter: nanosToHoursFormatter
  },
  {
    key: "robotCumulativeOperatingHours",
    name: "Robot_Cumulative_Operating_Hours",
    formatter: emptyFormatter
  },
  {
    key: "userId",
    name: "Operator_ID",
    formatter: emptyFormatter
  },
  {
    key: "totalInterventions",
    name: "Interventions",
    formatter: emptyFormatter
  },
  {
    key: "totalStoppages",
    name: "Stoppages",
    formatter: emptyFormatter
  }, {
    key: "placedPalletTotalCount",
    key2: "allPicksDuration",
    name: "Pallets Per Hour (all)",
    compositeFormatter: pphFormatter,
    formatter: emptyFormatter
  },{
    key: "placedStackTotalCount",
    key2: "allPicksDuration",
    name: "Pulls Per Hour (all)",
    compositeFormatter: pphFormatter,
    formatter: emptyFormatter
  },
].map(c => ({ ...defaultColumnProperties, ...c }));

class TrailerStatsSheet extends Component<Props, State> {
  state = {
    rows: [],
    topLeft: { rowIdx: 0, colIdx: 0 },
    botRight: { rowIdx: 0, colIdx: 0 }
  };

  render() {
    const { classes, stats, omittedFieldNames, loading } = this.props;
    const formattedData: FormattedData = statsToRows(
      stats,
      omittedFieldNames || [],
      columns,
      defaultColumnProperties
    );
    // @ts-ignore
    const renderCell = ({ columnIndex, rowIndex, style }) => {
      const cell = formattedData.cells[rowIndex][columnIndex];
      return (
        <div style={style} className={classes.cell}>
          {cell.component || cell.value}
        </div>
      );
    };
    return (
      <div className={classes.wrapper}>
        { !loading && <StylableCSVLink
          filename="all_trailer_unloads.csv"
          csvData={formattedData.downloadable}
        />}
        <div className={classes.sheet}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <VariableSizeGrid
                  columnWidth={index => {
                    // Allow a comfortable amount of room for each column
                    return (
                      10 *
                      Math.max(
                        ...formattedData.cells
                          .map(row => row[index])
                          .map(cellValue => cellValue.value.toString().length)
                      )
                    );
                  }}
                  rowHeight={() => {
                    // Display 10 rows at a time in the viewport
                    return height / 10;
                  }}
                  columnCount={formattedData.cells[0].length}
                  height={height}
                  rowCount={formattedData.cells.length}
                  width={width}
                >
                  {renderCell}
                </VariableSizeGrid>
              );
            }}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(TrailerStatsSheet));
