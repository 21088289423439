// source: command_control/proto/command_control.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var detection_proto_detection_pb = require('../../detection/proto/detection_pb.js');
goog.object.extend(proto, detection_proto_detection_pb);
var geometry_proto_transform_pb = require('../../geometry/proto/transform_pb.js');
goog.object.extend(proto, geometry_proto_transform_pb);
var geometry_proto_geometry_pb = require('../../geometry/proto/geometry_pb.js');
goog.object.extend(proto, geometry_proto_geometry_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var log_fault_pb = require('../../log/fault_pb.js');
goog.object.extend(proto, log_fault_pb);
var planner_proto_planner_status_pb = require('../../planner/proto/planner_status_pb.js');
goog.object.extend(proto, planner_proto_planner_status_pb);
var planner_proto_types_pb = require('../../planner/proto/types_pb.js');
goog.object.extend(proto, planner_proto_types_pb);
var robot_proto_config_pb = require('../../robot/proto/config_pb.js');
goog.object.extend(proto, robot_proto_config_pb);
goog.exportSymbol('proto.fox.proto.command_control.AnswerAndFollowUpQuestion', null, global);
goog.exportSymbol('proto.fox.proto.command_control.CachedCohortEnvironmentConfig', null, global);
goog.exportSymbol('proto.fox.proto.command_control.CaptureDebugSnapshotRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ChangeDeploymentChannelRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ChangeDeploymentChannelResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.CohortEnvironmentConfig', null, global);
goog.exportSymbol('proto.fox.proto.command_control.CohortEnvironmentConfigStatus', null, global);
goog.exportSymbol('proto.fox.proto.command_control.Command', null, global);
goog.exportSymbol('proto.fox.proto.command_control.CreateUserRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.DebugInfo', null, global);
goog.exportSymbol('proto.fox.proto.command_control.DebugSnapshotInfo', null, global);
goog.exportSymbol('proto.fox.proto.command_control.DeleteEnvironmentConfigRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.DiskInfo', null, global);
goog.exportSymbol('proto.fox.proto.command_control.FaultAvoidableByRobot', null, global);
goog.exportSymbol('proto.fox.proto.command_control.FaultTranslation', null, global);
goog.exportSymbol('proto.fox.proto.command_control.FaultTranslations', null, global);
goog.exportSymbol('proto.fox.proto.command_control.FinishRunRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.FinishRunResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.FlagOption', null, global);
goog.exportSymbol('proto.fox.proto.command_control.FlagValue', null, global);
goog.exportSymbol('proto.fox.proto.command_control.GetEnvironmentConfigRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.GetFaultTranslationsRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.GetGoalRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.GetShutdownStatusRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.GetStartRunCommandRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.GetStartRunCommandResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.GetTabletConfigurationRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.GetUserByBadgeRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.Goal', null, global);
goog.exportSymbol('proto.fox.proto.command_control.Goal.GoalCase', null, global);
goog.exportSymbol('proto.fox.proto.command_control.GoalStatus', null, global);
goog.exportSymbol('proto.fox.proto.command_control.GoalStatus.State', null, global);
goog.exportSymbol('proto.fox.proto.command_control.GoalStatus.UIState', null, global);
goog.exportSymbol('proto.fox.proto.command_control.HeartbeatRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.HeartbeatResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.InterventionCause', null, global);
goog.exportSymbol('proto.fox.proto.command_control.InterventionCauseCategory', null, global);
goog.exportSymbol('proto.fox.proto.command_control.InterventionType', null, global);
goog.exportSymbol('proto.fox.proto.command_control.LidarReturns', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ListEnvironmentConfigsRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ListEnvironmentConfigsResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ListRobotReleasesRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ListRobotReleasesResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ListTrailerMetadatasRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ListTrailerMetadatasResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.LocalizableText', null, global);
goog.exportSymbol('proto.fox.proto.command_control.LocalizedText', null, global);
goog.exportSymbol('proto.fox.proto.command_control.LogInUserRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.LogOutUserRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ManualUpdate', null, global);
goog.exportSymbol('proto.fox.proto.command_control.NextGoalRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.NextGoalRequest.FinishedGoalCase', null, global);
goog.exportSymbol('proto.fox.proto.command_control.NextGoalResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.Note', null, global);
goog.exportSymbol('proto.fox.proto.command_control.Note.BugPriority', null, global);
goog.exportSymbol('proto.fox.proto.command_control.Note.Component', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ObjectNavigationGoal', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ObjectNavigationGoal.ObjectType', null, global);
goog.exportSymbol('proto.fox.proto.command_control.OperatorFeedback', null, global);
goog.exportSymbol('proto.fox.proto.command_control.Pallet', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PalletHeight', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PalletStack', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PalletType', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PalletUpdate', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PalletUpdate.UpdateType', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PathInfo', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PersistEnvironmentConfigRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PickPlacePalletsGoal', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PickPlacePalletsGoal.SubGoal', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PickPlaceZoneReadinessInfo', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PickPlaceZoneUpdate', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PickPlaceZoneUpdate.UpdateType', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PoseGoal', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PublishDebugInfoRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PublishLidarReturnsRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PublishLidarReturnsResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PublishPalletsRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PublishPalletsResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PublishPickPlaceZonesRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PublishPickPlaceZonesResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PullRobotReleasesRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PullRobotReleasesResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.PushGoalRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.Question', null, global);
goog.exportSymbol('proto.fox.proto.command_control.Release', null, global);
goog.exportSymbol('proto.fox.proto.command_control.RobotPose', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ShutdownStatus', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ShutdownStatus.PotentialAction', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Reason', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Status', null, global);
goog.exportSymbol('proto.fox.proto.command_control.ShutdownStatus.RequestedAction', null, global);
goog.exportSymbol('proto.fox.proto.command_control.StartRunRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.StartRunResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.StopServerRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.StopServerResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SubscribeGoalRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SubscribeLidarReturnsRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SubscribeLidarReturnsResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SubscribeManualUpdatesRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SubscribeNotesRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SubscribePalletsRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SubscribePalletsResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SubscribePickPlaceZonesRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SubscribePickPlaceZonesResponse', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SubscribeRobotPoseRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SupportTicket', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SupportTicketTimeline', null, global);
goog.exportSymbol('proto.fox.proto.command_control.SyncStatus', null, global);
goog.exportSymbol('proto.fox.proto.command_control.TabletConfiguration', null, global);
goog.exportSymbol('proto.fox.proto.command_control.TabletOperatorMode', null, global);
goog.exportSymbol('proto.fox.proto.command_control.TabletUser', null, global);
goog.exportSymbol('proto.fox.proto.command_control.TrailerMetadata', null, global);
goog.exportSymbol('proto.fox.proto.command_control.UICancelGoalRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.UpdateDefaultFlagValueRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.UpdateEnvironmentConfigRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.UpdateRobotPoseRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.UpdateShutdownStatusRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.UpdateTrailerMetadataRequest', null, global);
goog.exportSymbol('proto.fox.proto.command_control.UsbSyncInfo', null, global);
goog.exportSymbol('proto.fox.proto.command_control.WiFiInfo', null, global);
goog.exportSymbol('proto.fox.proto.command_control.WriteEnvironmentConfigUpdate', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.Release = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.Release, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.Release.displayName = 'proto.fox.proto.command_control.Release';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ListRobotReleasesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.ListRobotReleasesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ListRobotReleasesRequest.displayName = 'proto.fox.proto.command_control.ListRobotReleasesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ListRobotReleasesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.ListRobotReleasesResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.ListRobotReleasesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ListRobotReleasesResponse.displayName = 'proto.fox.proto.command_control.ListRobotReleasesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PullRobotReleasesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.PullRobotReleasesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PullRobotReleasesRequest.displayName = 'proto.fox.proto.command_control.PullRobotReleasesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PullRobotReleasesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.PullRobotReleasesResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.PullRobotReleasesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PullRobotReleasesResponse.displayName = 'proto.fox.proto.command_control.PullRobotReleasesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.StartRunRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.StartRunRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.StartRunRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.StartRunRequest.displayName = 'proto.fox.proto.command_control.StartRunRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.StartRunResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.StartRunResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.StartRunResponse.displayName = 'proto.fox.proto.command_control.StartRunResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.GetStartRunCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.GetStartRunCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.GetStartRunCommandRequest.displayName = 'proto.fox.proto.command_control.GetStartRunCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.GetStartRunCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.GetStartRunCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.GetStartRunCommandResponse.displayName = 'proto.fox.proto.command_control.GetStartRunCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.Command = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.Command.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.Command, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.Command.displayName = 'proto.fox.proto.command_control.Command';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.FinishRunRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.FinishRunRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.FinishRunRequest.displayName = 'proto.fox.proto.command_control.FinishRunRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.FinishRunResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.FinishRunResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.FinishRunResponse.displayName = 'proto.fox.proto.command_control.FinishRunResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.StopServerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.StopServerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.StopServerRequest.displayName = 'proto.fox.proto.command_control.StopServerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.StopServerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.StopServerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.StopServerResponse.displayName = 'proto.fox.proto.command_control.StopServerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.UsbSyncInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.UsbSyncInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.UsbSyncInfo.displayName = 'proto.fox.proto.command_control.UsbSyncInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.HeartbeatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.HeartbeatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.HeartbeatRequest.displayName = 'proto.fox.proto.command_control.HeartbeatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.HeartbeatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.HeartbeatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.HeartbeatResponse.displayName = 'proto.fox.proto.command_control.HeartbeatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.GetEnvironmentConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.GetEnvironmentConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.GetEnvironmentConfigRequest.displayName = 'proto.fox.proto.command_control.GetEnvironmentConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.UpdateEnvironmentConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.UpdateEnvironmentConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.displayName = 'proto.fox.proto.command_control.UpdateEnvironmentConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ListEnvironmentConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.ListEnvironmentConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ListEnvironmentConfigsRequest.displayName = 'proto.fox.proto.command_control.ListEnvironmentConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ListEnvironmentConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.ListEnvironmentConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.ListEnvironmentConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ListEnvironmentConfigsResponse.displayName = 'proto.fox.proto.command_control.ListEnvironmentConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.CohortEnvironmentConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.CohortEnvironmentConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.CohortEnvironmentConfig.displayName = 'proto.fox.proto.command_control.CohortEnvironmentConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.CachedCohortEnvironmentConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.CachedCohortEnvironmentConfig.displayName = 'proto.fox.proto.command_control.CachedCohortEnvironmentConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest.displayName = 'proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.displayName = 'proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PersistEnvironmentConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.PersistEnvironmentConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PersistEnvironmentConfigRequest.displayName = 'proto.fox.proto.command_control.PersistEnvironmentConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.displayName = 'proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.DeleteEnvironmentConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.DeleteEnvironmentConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.displayName = 'proto.fox.proto.command_control.DeleteEnvironmentConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.SupportTicket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.SupportTicket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.SupportTicket.displayName = 'proto.fox.proto.command_control.SupportTicket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.Note = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.Note.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.Note, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.Note.displayName = 'proto.fox.proto.command_control.Note';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.SubscribeNotesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.SubscribeNotesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.SubscribeNotesRequest.displayName = 'proto.fox.proto.command_control.SubscribeNotesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PalletUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.PalletUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PalletUpdate.displayName = 'proto.fox.proto.command_control.PalletUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.PickPlaceZoneUpdate.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.PickPlaceZoneUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PickPlaceZoneUpdate.displayName = 'proto.fox.proto.command_control.PickPlaceZoneUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.SubscribeManualUpdatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.SubscribeManualUpdatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.SubscribeManualUpdatesRequest.displayName = 'proto.fox.proto.command_control.SubscribeManualUpdatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.WriteEnvironmentConfigUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.WriteEnvironmentConfigUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.displayName = 'proto.fox.proto.command_control.WriteEnvironmentConfigUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ManualUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.ManualUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ManualUpdate.displayName = 'proto.fox.proto.command_control.ManualUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.GetShutdownStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.GetShutdownStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.GetShutdownStatusRequest.displayName = 'proto.fox.proto.command_control.GetShutdownStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ShutdownStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.ShutdownStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ShutdownStatus.displayName = 'proto.fox.proto.command_control.ShutdownStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.ShutdownStatus.PotentialAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ShutdownStatus.PotentialAction.displayName = 'proto.fox.proto.command_control.ShutdownStatus.PotentialAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.UpdateShutdownStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.UpdateShutdownStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.UpdateShutdownStatusRequest.displayName = 'proto.fox.proto.command_control.UpdateShutdownStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ChangeDeploymentChannelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.ChangeDeploymentChannelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ChangeDeploymentChannelRequest.displayName = 'proto.fox.proto.command_control.ChangeDeploymentChannelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ChangeDeploymentChannelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.ChangeDeploymentChannelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ChangeDeploymentChannelResponse.displayName = 'proto.fox.proto.command_control.ChangeDeploymentChannelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.UpdateTrailerMetadataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.UpdateTrailerMetadataRequest.displayName = 'proto.fox.proto.command_control.UpdateTrailerMetadataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ListTrailerMetadatasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.ListTrailerMetadatasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ListTrailerMetadatasRequest.displayName = 'proto.fox.proto.command_control.ListTrailerMetadatasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ListTrailerMetadatasResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.ListTrailerMetadatasResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.ListTrailerMetadatasResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ListTrailerMetadatasResponse.displayName = 'proto.fox.proto.command_control.ListTrailerMetadatasResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.UICancelGoalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.UICancelGoalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.UICancelGoalRequest.displayName = 'proto.fox.proto.command_control.UICancelGoalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.GetGoalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.GetGoalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.GetGoalRequest.displayName = 'proto.fox.proto.command_control.GetGoalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.RobotPose = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.RobotPose, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.RobotPose.displayName = 'proto.fox.proto.command_control.RobotPose';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.SubscribeRobotPoseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.SubscribeRobotPoseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.SubscribeRobotPoseRequest.displayName = 'proto.fox.proto.command_control.SubscribeRobotPoseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.UpdateRobotPoseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.UpdateRobotPoseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.UpdateRobotPoseRequest.displayName = 'proto.fox.proto.command_control.UpdateRobotPoseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PublishDebugInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.PublishDebugInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PublishDebugInfoRequest.displayName = 'proto.fox.proto.command_control.PublishDebugInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.DebugInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.DebugInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.DebugInfo.displayName = 'proto.fox.proto.command_control.DebugInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PathInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.PathInfo.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.PathInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PathInfo.displayName = 'proto.fox.proto.command_control.PathInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.Pallet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.Pallet.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.Pallet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.Pallet.displayName = 'proto.fox.proto.command_control.Pallet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.SubscribePalletsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.SubscribePalletsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.SubscribePalletsRequest.displayName = 'proto.fox.proto.command_control.SubscribePalletsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.SubscribePalletsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.SubscribePalletsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.SubscribePalletsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.SubscribePalletsResponse.displayName = 'proto.fox.proto.command_control.SubscribePalletsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PublishPalletsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.PublishPalletsRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.PublishPalletsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PublishPalletsRequest.displayName = 'proto.fox.proto.command_control.PublishPalletsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PublishPalletsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.PublishPalletsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PublishPalletsResponse.displayName = 'proto.fox.proto.command_control.PublishPalletsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.SubscribePickPlaceZonesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.SubscribePickPlaceZonesRequest.displayName = 'proto.fox.proto.command_control.SubscribePickPlaceZonesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.SubscribePickPlaceZonesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.displayName = 'proto.fox.proto.command_control.SubscribePickPlaceZonesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.PublishPickPlaceZonesRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.PublishPickPlaceZonesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PublishPickPlaceZonesRequest.displayName = 'proto.fox.proto.command_control.PublishPickPlaceZonesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PublishPickPlaceZonesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.PublishPickPlaceZonesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PublishPickPlaceZonesResponse.displayName = 'proto.fox.proto.command_control.PublishPickPlaceZonesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.LidarReturns = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.LidarReturns.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.LidarReturns, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.LidarReturns.displayName = 'proto.fox.proto.command_control.LidarReturns';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.SubscribeLidarReturnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.SubscribeLidarReturnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.SubscribeLidarReturnsRequest.displayName = 'proto.fox.proto.command_control.SubscribeLidarReturnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.SubscribeLidarReturnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.SubscribeLidarReturnsResponse.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.SubscribeLidarReturnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.SubscribeLidarReturnsResponse.displayName = 'proto.fox.proto.command_control.SubscribeLidarReturnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PublishLidarReturnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.PublishLidarReturnsRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.PublishLidarReturnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PublishLidarReturnsRequest.displayName = 'proto.fox.proto.command_control.PublishLidarReturnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PublishLidarReturnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.PublishLidarReturnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PublishLidarReturnsResponse.displayName = 'proto.fox.proto.command_control.PublishLidarReturnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PushGoalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.PushGoalRequest.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.PushGoalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PushGoalRequest.displayName = 'proto.fox.proto.command_control.PushGoalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.SubscribeGoalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.SubscribeGoalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.SubscribeGoalRequest.displayName = 'proto.fox.proto.command_control.SubscribeGoalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.NextGoalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fox.proto.command_control.NextGoalRequest.oneofGroups_);
};
goog.inherits(proto.fox.proto.command_control.NextGoalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.NextGoalRequest.displayName = 'proto.fox.proto.command_control.NextGoalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.NextGoalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.NextGoalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.NextGoalResponse.displayName = 'proto.fox.proto.command_control.NextGoalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.Goal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fox.proto.command_control.Goal.oneofGroups_);
};
goog.inherits(proto.fox.proto.command_control.Goal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.Goal.displayName = 'proto.fox.proto.command_control.Goal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.PickPlaceZoneReadinessInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.displayName = 'proto.fox.proto.command_control.PickPlaceZoneReadinessInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.GoalStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.GoalStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.GoalStatus.displayName = 'proto.fox.proto.command_control.GoalStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PoseGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.PoseGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PoseGoal.displayName = 'proto.fox.proto.command_control.PoseGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.ObjectNavigationGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.ObjectNavigationGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.ObjectNavigationGoal.displayName = 'proto.fox.proto.command_control.ObjectNavigationGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.PickPlacePalletsGoal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.PickPlacePalletsGoal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.PickPlacePalletsGoal.displayName = 'proto.fox.proto.command_control.PickPlacePalletsGoal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.TrailerMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.TrailerMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.TrailerMetadata.displayName = 'proto.fox.proto.command_control.TrailerMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.WiFiInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.WiFiInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.WiFiInfo.displayName = 'proto.fox.proto.command_control.WiFiInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.DiskInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.DiskInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.DiskInfo.displayName = 'proto.fox.proto.command_control.DiskInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.CaptureDebugSnapshotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.CaptureDebugSnapshotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.CaptureDebugSnapshotRequest.displayName = 'proto.fox.proto.command_control.CaptureDebugSnapshotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.DebugSnapshotInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.DebugSnapshotInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.DebugSnapshotInfo.displayName = 'proto.fox.proto.command_control.DebugSnapshotInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.LogInUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.LogInUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.LogInUserRequest.displayName = 'proto.fox.proto.command_control.LogInUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.LogOutUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.LogOutUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.LogOutUserRequest.displayName = 'proto.fox.proto.command_control.LogOutUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.CreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.CreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.CreateUserRequest.displayName = 'proto.fox.proto.command_control.CreateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.TabletUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.TabletUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.TabletUser.displayName = 'proto.fox.proto.command_control.TabletUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.GetUserByBadgeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.GetUserByBadgeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.GetUserByBadgeRequest.displayName = 'proto.fox.proto.command_control.GetUserByBadgeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.GetFaultTranslationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.GetFaultTranslationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.GetFaultTranslationsRequest.displayName = 'proto.fox.proto.command_control.GetFaultTranslationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.FaultTranslations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.FaultTranslations.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.FaultTranslations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.FaultTranslations.displayName = 'proto.fox.proto.command_control.FaultTranslations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.FaultTranslation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.FaultTranslation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.FaultTranslation.displayName = 'proto.fox.proto.command_control.FaultTranslation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.AnswerAndFollowUpQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.AnswerAndFollowUpQuestion.displayName = 'proto.fox.proto.command_control.AnswerAndFollowUpQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.Question = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.Question.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.Question, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.Question.displayName = 'proto.fox.proto.command_control.Question';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.OperatorFeedback = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.OperatorFeedback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.OperatorFeedback.displayName = 'proto.fox.proto.command_control.OperatorFeedback';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.GetTabletConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.GetTabletConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.GetTabletConfigurationRequest.displayName = 'proto.fox.proto.command_control.GetTabletConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.TabletConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.TabletConfiguration.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.TabletConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.TabletConfiguration.displayName = 'proto.fox.proto.command_control.TabletConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.FlagOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.FlagOption.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.FlagOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.FlagOption.displayName = 'proto.fox.proto.command_control.FlagOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.FlagValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.FlagValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.FlagValue.displayName = 'proto.fox.proto.command_control.FlagValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.LocalizableText = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.command_control.LocalizableText.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.command_control.LocalizableText, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.LocalizableText.displayName = 'proto.fox.proto.command_control.LocalizableText';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.LocalizedText = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.LocalizedText, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.LocalizedText.displayName = 'proto.fox.proto.command_control.LocalizedText';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.command_control.UpdateDefaultFlagValueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.command_control.UpdateDefaultFlagValueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.displayName = 'proto.fox.proto.command_control.UpdateDefaultFlagValueRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.Release.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.Release.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.Release} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Release.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.Release}
 */
proto.fox.proto.command_control.Release.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.Release;
  return proto.fox.proto.command_control.Release.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.Release} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.Release}
 */
proto.fox.proto.command_control.Release.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.Release.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.Release.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.Release} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Release.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreateTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.Release.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.Release} returns this
 */
proto.fox.proto.command_control.Release.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string channel = 2;
 * @return {string}
 */
proto.fox.proto.command_control.Release.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.Release} returns this
 */
proto.fox.proto.command_control.Release.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 create_time = 3;
 * @return {number}
 */
proto.fox.proto.command_control.Release.prototype.getCreateTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.Release} returns this
 */
proto.fox.proto.command_control.Release.prototype.setCreateTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ListRobotReleasesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ListRobotReleasesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ListRobotReleasesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListRobotReleasesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ListRobotReleasesRequest}
 */
proto.fox.proto.command_control.ListRobotReleasesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ListRobotReleasesRequest;
  return proto.fox.proto.command_control.ListRobotReleasesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ListRobotReleasesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ListRobotReleasesRequest}
 */
proto.fox.proto.command_control.ListRobotReleasesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ListRobotReleasesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ListRobotReleasesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ListRobotReleasesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListRobotReleasesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.ListRobotReleasesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ListRobotReleasesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ListRobotReleasesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ListRobotReleasesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListRobotReleasesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    releasesList: jspb.Message.toObjectList(msg.getReleasesList(),
    proto.fox.proto.command_control.Release.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ListRobotReleasesResponse}
 */
proto.fox.proto.command_control.ListRobotReleasesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ListRobotReleasesResponse;
  return proto.fox.proto.command_control.ListRobotReleasesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ListRobotReleasesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ListRobotReleasesResponse}
 */
proto.fox.proto.command_control.ListRobotReleasesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.Release;
      reader.readMessage(value,proto.fox.proto.command_control.Release.deserializeBinaryFromReader);
      msg.addReleases(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ListRobotReleasesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ListRobotReleasesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ListRobotReleasesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListRobotReleasesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReleasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.command_control.Release.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Release releases = 1;
 * @return {!Array<!proto.fox.proto.command_control.Release>}
 */
proto.fox.proto.command_control.ListRobotReleasesResponse.prototype.getReleasesList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.Release>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.Release, 1));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.Release>} value
 * @return {!proto.fox.proto.command_control.ListRobotReleasesResponse} returns this
*/
proto.fox.proto.command_control.ListRobotReleasesResponse.prototype.setReleasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.command_control.Release=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.Release}
 */
proto.fox.proto.command_control.ListRobotReleasesResponse.prototype.addReleases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.command_control.Release, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.ListRobotReleasesResponse} returns this
 */
proto.fox.proto.command_control.ListRobotReleasesResponse.prototype.clearReleasesList = function() {
  return this.setReleasesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PullRobotReleasesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PullRobotReleasesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PullRobotReleasesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PullRobotReleasesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PullRobotReleasesRequest}
 */
proto.fox.proto.command_control.PullRobotReleasesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PullRobotReleasesRequest;
  return proto.fox.proto.command_control.PullRobotReleasesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PullRobotReleasesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PullRobotReleasesRequest}
 */
proto.fox.proto.command_control.PullRobotReleasesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PullRobotReleasesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PullRobotReleasesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PullRobotReleasesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PullRobotReleasesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string channel = 1;
 * @return {string}
 */
proto.fox.proto.command_control.PullRobotReleasesRequest.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.PullRobotReleasesRequest} returns this
 */
proto.fox.proto.command_control.PullRobotReleasesRequest.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.PullRobotReleasesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PullRobotReleasesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PullRobotReleasesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PullRobotReleasesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PullRobotReleasesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    releasesList: jspb.Message.toObjectList(msg.getReleasesList(),
    proto.fox.proto.command_control.Release.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PullRobotReleasesResponse}
 */
proto.fox.proto.command_control.PullRobotReleasesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PullRobotReleasesResponse;
  return proto.fox.proto.command_control.PullRobotReleasesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PullRobotReleasesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PullRobotReleasesResponse}
 */
proto.fox.proto.command_control.PullRobotReleasesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.Release;
      reader.readMessage(value,proto.fox.proto.command_control.Release.deserializeBinaryFromReader);
      msg.addReleases(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PullRobotReleasesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PullRobotReleasesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PullRobotReleasesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PullRobotReleasesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReleasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.command_control.Release.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Release releases = 1;
 * @return {!Array<!proto.fox.proto.command_control.Release>}
 */
proto.fox.proto.command_control.PullRobotReleasesResponse.prototype.getReleasesList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.Release>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.Release, 1));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.Release>} value
 * @return {!proto.fox.proto.command_control.PullRobotReleasesResponse} returns this
*/
proto.fox.proto.command_control.PullRobotReleasesResponse.prototype.setReleasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.command_control.Release=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.Release}
 */
proto.fox.proto.command_control.PullRobotReleasesResponse.prototype.addReleases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.command_control.Release, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PullRobotReleasesResponse} returns this
 */
proto.fox.proto.command_control.PullRobotReleasesResponse.prototype.clearReleasesList = function() {
  return this.setReleasesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.StartRunRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.StartRunRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.StartRunRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.StartRunRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.StartRunRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: jspb.Message.getFieldWithDefault(msg, 1, ""),
    attributesMap: (f = msg.getAttributesMap()) ? f.toObject(includeInstance, undefined) : [],
    deploymentChannel: jspb.Message.getFieldWithDefault(msg, 3, ""),
    extraArgsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.StartRunRequest}
 */
proto.fox.proto.command_control.StartRunRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.StartRunRequest;
  return proto.fox.proto.command_control.StartRunRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.StartRunRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.StartRunRequest}
 */
proto.fox.proto.command_control.StartRunRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfig(value);
      break;
    case 2:
      var value = msg.getAttributesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentChannel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addExtraArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.StartRunRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.StartRunRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.StartRunRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.StartRunRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAttributesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getDeploymentChannel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExtraArgsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string config = 1;
 * @return {string}
 */
proto.fox.proto.command_control.StartRunRequest.prototype.getConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.StartRunRequest} returns this
 */
proto.fox.proto.command_control.StartRunRequest.prototype.setConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> attributes = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.fox.proto.command_control.StartRunRequest.prototype.getAttributesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.fox.proto.command_control.StartRunRequest} returns this
 */
proto.fox.proto.command_control.StartRunRequest.prototype.clearAttributesMap = function() {
  this.getAttributesMap().clear();
  return this;};


/**
 * optional string deployment_channel = 3;
 * @return {string}
 */
proto.fox.proto.command_control.StartRunRequest.prototype.getDeploymentChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.StartRunRequest} returns this
 */
proto.fox.proto.command_control.StartRunRequest.prototype.setDeploymentChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string extra_args = 4;
 * @return {!Array<string>}
 */
proto.fox.proto.command_control.StartRunRequest.prototype.getExtraArgsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.command_control.StartRunRequest} returns this
 */
proto.fox.proto.command_control.StartRunRequest.prototype.setExtraArgsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.StartRunRequest} returns this
 */
proto.fox.proto.command_control.StartRunRequest.prototype.addExtraArgs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.StartRunRequest} returns this
 */
proto.fox.proto.command_control.StartRunRequest.prototype.clearExtraArgsList = function() {
  return this.setExtraArgsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.StartRunResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.StartRunResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.StartRunResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.StartRunResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    command: (f = msg.getCommand()) && proto.fox.proto.command_control.Command.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.StartRunResponse}
 */
proto.fox.proto.command_control.StartRunResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.StartRunResponse;
  return proto.fox.proto.command_control.StartRunResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.StartRunResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.StartRunResponse}
 */
proto.fox.proto.command_control.StartRunResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.Command;
      reader.readMessage(value,proto.fox.proto.command_control.Command.deserializeBinaryFromReader);
      msg.setCommand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.StartRunResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.StartRunResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.StartRunResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.StartRunResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommand();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.command_control.Command.serializeBinaryToWriter
    );
  }
};


/**
 * optional Command command = 1;
 * @return {?proto.fox.proto.command_control.Command}
 */
proto.fox.proto.command_control.StartRunResponse.prototype.getCommand = function() {
  return /** @type{?proto.fox.proto.command_control.Command} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.Command, 1));
};


/**
 * @param {?proto.fox.proto.command_control.Command|undefined} value
 * @return {!proto.fox.proto.command_control.StartRunResponse} returns this
*/
proto.fox.proto.command_control.StartRunResponse.prototype.setCommand = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.StartRunResponse} returns this
 */
proto.fox.proto.command_control.StartRunResponse.prototype.clearCommand = function() {
  return this.setCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.StartRunResponse.prototype.hasCommand = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.GetStartRunCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.GetStartRunCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.GetStartRunCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetStartRunCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.GetStartRunCommandRequest}
 */
proto.fox.proto.command_control.GetStartRunCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.GetStartRunCommandRequest;
  return proto.fox.proto.command_control.GetStartRunCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.GetStartRunCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.GetStartRunCommandRequest}
 */
proto.fox.proto.command_control.GetStartRunCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.GetStartRunCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.GetStartRunCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.GetStartRunCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetStartRunCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.GetStartRunCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.GetStartRunCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    command: (f = msg.getCommand()) && proto.fox.proto.command_control.Command.toObject(includeInstance, f),
    logs: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stopped: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.GetStartRunCommandResponse}
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.GetStartRunCommandResponse;
  return proto.fox.proto.command_control.GetStartRunCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.GetStartRunCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.GetStartRunCommandResponse}
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.Command;
      reader.readMessage(value,proto.fox.proto.command_control.Command.deserializeBinaryFromReader);
      msg.setCommand(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogs(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStopped(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.GetStartRunCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.GetStartRunCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommand();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.command_control.Command.serializeBinaryToWriter
    );
  }
  f = message.getLogs();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStopped();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional Command command = 1;
 * @return {?proto.fox.proto.command_control.Command}
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.prototype.getCommand = function() {
  return /** @type{?proto.fox.proto.command_control.Command} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.Command, 1));
};


/**
 * @param {?proto.fox.proto.command_control.Command|undefined} value
 * @return {!proto.fox.proto.command_control.GetStartRunCommandResponse} returns this
*/
proto.fox.proto.command_control.GetStartRunCommandResponse.prototype.setCommand = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.GetStartRunCommandResponse} returns this
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.prototype.clearCommand = function() {
  return this.setCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.prototype.hasCommand = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string logs = 2;
 * @return {string}
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.prototype.getLogs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.GetStartRunCommandResponse} returns this
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.prototype.setLogs = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool stopped = 4;
 * @return {boolean}
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.prototype.getStopped = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.command_control.GetStartRunCommandResponse} returns this
 */
proto.fox.proto.command_control.GetStartRunCommandResponse.prototype.setStopped = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.Command.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.Command.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.Command.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.Command} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Command.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    argumentsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.Command}
 */
proto.fox.proto.command_control.Command.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.Command;
  return proto.fox.proto.command_control.Command.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.Command} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.Command}
 */
proto.fox.proto.command_control.Command.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addArguments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.Command.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.Command.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.Command} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Command.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArgumentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.fox.proto.command_control.Command.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.Command} returns this
 */
proto.fox.proto.command_control.Command.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string arguments = 2;
 * @return {!Array<string>}
 */
proto.fox.proto.command_control.Command.prototype.getArgumentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.command_control.Command} returns this
 */
proto.fox.proto.command_control.Command.prototype.setArgumentsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.Command} returns this
 */
proto.fox.proto.command_control.Command.prototype.addArguments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.Command} returns this
 */
proto.fox.proto.command_control.Command.prototype.clearArgumentsList = function() {
  return this.setArgumentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.FinishRunRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.FinishRunRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.FinishRunRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FinishRunRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.FinishRunRequest}
 */
proto.fox.proto.command_control.FinishRunRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.FinishRunRequest;
  return proto.fox.proto.command_control.FinishRunRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.FinishRunRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.FinishRunRequest}
 */
proto.fox.proto.command_control.FinishRunRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.FinishRunRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.FinishRunRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.FinishRunRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FinishRunRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.FinishRunResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.FinishRunResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.FinishRunResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FinishRunResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.FinishRunResponse}
 */
proto.fox.proto.command_control.FinishRunResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.FinishRunResponse;
  return proto.fox.proto.command_control.FinishRunResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.FinishRunResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.FinishRunResponse}
 */
proto.fox.proto.command_control.FinishRunResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.FinishRunResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.FinishRunResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.FinishRunResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FinishRunResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.StopServerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.StopServerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.StopServerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.StopServerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.StopServerRequest}
 */
proto.fox.proto.command_control.StopServerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.StopServerRequest;
  return proto.fox.proto.command_control.StopServerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.StopServerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.StopServerRequest}
 */
proto.fox.proto.command_control.StopServerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.StopServerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.StopServerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.StopServerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.StopServerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.StopServerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.StopServerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.StopServerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.StopServerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.StopServerResponse}
 */
proto.fox.proto.command_control.StopServerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.StopServerResponse;
  return proto.fox.proto.command_control.StopServerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.StopServerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.StopServerResponse}
 */
proto.fox.proto.command_control.StopServerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.StopServerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.StopServerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.StopServerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.StopServerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.UsbSyncInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.UsbSyncInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.UsbSyncInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UsbSyncInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    completedLastSync: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.UsbSyncInfo}
 */
proto.fox.proto.command_control.UsbSyncInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.UsbSyncInfo;
  return proto.fox.proto.command_control.UsbSyncInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.UsbSyncInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.UsbSyncInfo}
 */
proto.fox.proto.command_control.UsbSyncInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompletedLastSync(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.UsbSyncInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.UsbSyncInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.UsbSyncInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UsbSyncInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCompletedLastSync();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.fox.proto.command_control.UsbSyncInfo.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.UsbSyncInfo} returns this
 */
proto.fox.proto.command_control.UsbSyncInfo.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.fox.proto.command_control.UsbSyncInfo.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.UsbSyncInfo} returns this
 */
proto.fox.proto.command_control.UsbSyncInfo.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool completed_last_sync = 3;
 * @return {boolean}
 */
proto.fox.proto.command_control.UsbSyncInfo.prototype.getCompletedLastSync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.command_control.UsbSyncInfo} returns this
 */
proto.fox.proto.command_control.UsbSyncInfo.prototype.setCompletedLastSync = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.HeartbeatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.HeartbeatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.HeartbeatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.HeartbeatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.HeartbeatRequest}
 */
proto.fox.proto.command_control.HeartbeatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.HeartbeatRequest;
  return proto.fox.proto.command_control.HeartbeatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.HeartbeatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.HeartbeatRequest}
 */
proto.fox.proto.command_control.HeartbeatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.HeartbeatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.HeartbeatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.HeartbeatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.HeartbeatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.fox.proto.command_control.HeartbeatRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.HeartbeatRequest} returns this
 */
proto.fox.proto.command_control.HeartbeatRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.HeartbeatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.HeartbeatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.HeartbeatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    robotName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    debugInfo: (f = msg.getDebugInfo()) && proto.fox.proto.command_control.DebugInfo.toObject(includeInstance, f),
    deploymentChannel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    usbSyncInfo: (f = msg.getUsbSyncInfo()) && proto.fox.proto.command_control.UsbSyncInfo.toObject(includeInstance, f),
    pointToPointVersion: jspb.Message.getFieldWithDefault(msg, 7, ""),
    shutdownStatus: (f = msg.getShutdownStatus()) && proto.fox.proto.command_control.ShutdownStatus.toObject(includeInstance, f),
    wifiInfo: (f = msg.getWifiInfo()) && proto.fox.proto.command_control.WiFiInfo.toObject(includeInstance, f),
    diskInfo: (f = msg.getDiskInfo()) && proto.fox.proto.command_control.DiskInfo.toObject(includeInstance, f),
    loggedInUser: (f = msg.getLoggedInUser()) && proto.fox.proto.command_control.TabletUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.HeartbeatResponse}
 */
proto.fox.proto.command_control.HeartbeatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.HeartbeatResponse;
  return proto.fox.proto.command_control.HeartbeatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.HeartbeatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.HeartbeatResponse}
 */
proto.fox.proto.command_control.HeartbeatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 3:
      var value = new proto.fox.proto.command_control.DebugInfo;
      reader.readMessage(value,proto.fox.proto.command_control.DebugInfo.deserializeBinaryFromReader);
      msg.setDebugInfo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentChannel(value);
      break;
    case 5:
      var value = new proto.fox.proto.command_control.UsbSyncInfo;
      reader.readMessage(value,proto.fox.proto.command_control.UsbSyncInfo.deserializeBinaryFromReader);
      msg.setUsbSyncInfo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointToPointVersion(value);
      break;
    case 8:
      var value = new proto.fox.proto.command_control.ShutdownStatus;
      reader.readMessage(value,proto.fox.proto.command_control.ShutdownStatus.deserializeBinaryFromReader);
      msg.setShutdownStatus(value);
      break;
    case 9:
      var value = new proto.fox.proto.command_control.WiFiInfo;
      reader.readMessage(value,proto.fox.proto.command_control.WiFiInfo.deserializeBinaryFromReader);
      msg.setWifiInfo(value);
      break;
    case 10:
      var value = new proto.fox.proto.command_control.DiskInfo;
      reader.readMessage(value,proto.fox.proto.command_control.DiskInfo.deserializeBinaryFromReader);
      msg.setDiskInfo(value);
      break;
    case 11:
      var value = new proto.fox.proto.command_control.TabletUser;
      reader.readMessage(value,proto.fox.proto.command_control.TabletUser.deserializeBinaryFromReader);
      msg.setLoggedInUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.HeartbeatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.HeartbeatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.HeartbeatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDebugInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.command_control.DebugInfo.serializeBinaryToWriter
    );
  }
  f = message.getDeploymentChannel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUsbSyncInfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.fox.proto.command_control.UsbSyncInfo.serializeBinaryToWriter
    );
  }
  f = message.getPointToPointVersion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getShutdownStatus();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.fox.proto.command_control.ShutdownStatus.serializeBinaryToWriter
    );
  }
  f = message.getWifiInfo();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.fox.proto.command_control.WiFiInfo.serializeBinaryToWriter
    );
  }
  f = message.getDiskInfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.fox.proto.command_control.DiskInfo.serializeBinaryToWriter
    );
  }
  f = message.getLoggedInUser();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.fox.proto.command_control.TabletUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string robot_name = 2;
 * @return {string}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DebugInfo debug_info = 3;
 * @return {?proto.fox.proto.command_control.DebugInfo}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.getDebugInfo = function() {
  return /** @type{?proto.fox.proto.command_control.DebugInfo} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.DebugInfo, 3));
};


/**
 * @param {?proto.fox.proto.command_control.DebugInfo|undefined} value
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
*/
proto.fox.proto.command_control.HeartbeatResponse.prototype.setDebugInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.clearDebugInfo = function() {
  return this.setDebugInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.hasDebugInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string deployment_channel = 4;
 * @return {string}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.getDeploymentChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.setDeploymentChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional UsbSyncInfo usb_sync_info = 5;
 * @return {?proto.fox.proto.command_control.UsbSyncInfo}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.getUsbSyncInfo = function() {
  return /** @type{?proto.fox.proto.command_control.UsbSyncInfo} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.UsbSyncInfo, 5));
};


/**
 * @param {?proto.fox.proto.command_control.UsbSyncInfo|undefined} value
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
*/
proto.fox.proto.command_control.HeartbeatResponse.prototype.setUsbSyncInfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.clearUsbSyncInfo = function() {
  return this.setUsbSyncInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.hasUsbSyncInfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string point_to_point_version = 7;
 * @return {string}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.getPointToPointVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.setPointToPointVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional ShutdownStatus shutdown_status = 8;
 * @return {?proto.fox.proto.command_control.ShutdownStatus}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.getShutdownStatus = function() {
  return /** @type{?proto.fox.proto.command_control.ShutdownStatus} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.ShutdownStatus, 8));
};


/**
 * @param {?proto.fox.proto.command_control.ShutdownStatus|undefined} value
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
*/
proto.fox.proto.command_control.HeartbeatResponse.prototype.setShutdownStatus = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.clearShutdownStatus = function() {
  return this.setShutdownStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.hasShutdownStatus = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional WiFiInfo wifi_info = 9;
 * @return {?proto.fox.proto.command_control.WiFiInfo}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.getWifiInfo = function() {
  return /** @type{?proto.fox.proto.command_control.WiFiInfo} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.WiFiInfo, 9));
};


/**
 * @param {?proto.fox.proto.command_control.WiFiInfo|undefined} value
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
*/
proto.fox.proto.command_control.HeartbeatResponse.prototype.setWifiInfo = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.clearWifiInfo = function() {
  return this.setWifiInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.hasWifiInfo = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DiskInfo disk_info = 10;
 * @return {?proto.fox.proto.command_control.DiskInfo}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.getDiskInfo = function() {
  return /** @type{?proto.fox.proto.command_control.DiskInfo} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.DiskInfo, 10));
};


/**
 * @param {?proto.fox.proto.command_control.DiskInfo|undefined} value
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
*/
proto.fox.proto.command_control.HeartbeatResponse.prototype.setDiskInfo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.clearDiskInfo = function() {
  return this.setDiskInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.hasDiskInfo = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional TabletUser logged_in_user = 11;
 * @return {?proto.fox.proto.command_control.TabletUser}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.getLoggedInUser = function() {
  return /** @type{?proto.fox.proto.command_control.TabletUser} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.TabletUser, 11));
};


/**
 * @param {?proto.fox.proto.command_control.TabletUser|undefined} value
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
*/
proto.fox.proto.command_control.HeartbeatResponse.prototype.setLoggedInUser = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.HeartbeatResponse} returns this
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.clearLoggedInUser = function() {
  return this.setLoggedInUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.HeartbeatResponse.prototype.hasLoggedInUser = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.GetEnvironmentConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.GetEnvironmentConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.GetEnvironmentConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetEnvironmentConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.GetEnvironmentConfigRequest}
 */
proto.fox.proto.command_control.GetEnvironmentConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.GetEnvironmentConfigRequest;
  return proto.fox.proto.command_control.GetEnvironmentConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.GetEnvironmentConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.GetEnvironmentConfigRequest}
 */
proto.fox.proto.command_control.GetEnvironmentConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.GetEnvironmentConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.GetEnvironmentConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.GetEnvironmentConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetEnvironmentConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.UpdateEnvironmentConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: (f = msg.getConfig()) && robot_proto_config_pb.EnvironmentConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.UpdateEnvironmentConfigRequest}
 */
proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.UpdateEnvironmentConfigRequest;
  return proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.UpdateEnvironmentConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.UpdateEnvironmentConfigRequest}
 */
proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new robot_proto_config_pb.EnvironmentConfig;
      reader.readMessage(value,robot_proto_config_pb.EnvironmentConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.UpdateEnvironmentConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      robot_proto_config_pb.EnvironmentConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional fox.proto.EnvironmentConfig config = 1;
 * @return {?proto.fox.proto.EnvironmentConfig}
 */
proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.prototype.getConfig = function() {
  return /** @type{?proto.fox.proto.EnvironmentConfig} */ (
    jspb.Message.getWrapperField(this, robot_proto_config_pb.EnvironmentConfig, 1));
};


/**
 * @param {?proto.fox.proto.EnvironmentConfig|undefined} value
 * @return {!proto.fox.proto.command_control.UpdateEnvironmentConfigRequest} returns this
*/
proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.UpdateEnvironmentConfigRequest} returns this
 */
proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.UpdateEnvironmentConfigRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ListEnvironmentConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ListEnvironmentConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ListEnvironmentConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListEnvironmentConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ListEnvironmentConfigsRequest}
 */
proto.fox.proto.command_control.ListEnvironmentConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ListEnvironmentConfigsRequest;
  return proto.fox.proto.command_control.ListEnvironmentConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ListEnvironmentConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ListEnvironmentConfigsRequest}
 */
proto.fox.proto.command_control.ListEnvironmentConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ListEnvironmentConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ListEnvironmentConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ListEnvironmentConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListEnvironmentConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.ListEnvironmentConfigsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ListEnvironmentConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ListEnvironmentConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ListEnvironmentConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListEnvironmentConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    configsList: jspb.Message.toObjectList(msg.getConfigsList(),
    robot_proto_config_pb.EnvironmentConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ListEnvironmentConfigsResponse}
 */
proto.fox.proto.command_control.ListEnvironmentConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ListEnvironmentConfigsResponse;
  return proto.fox.proto.command_control.ListEnvironmentConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ListEnvironmentConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ListEnvironmentConfigsResponse}
 */
proto.fox.proto.command_control.ListEnvironmentConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new robot_proto_config_pb.EnvironmentConfig;
      reader.readMessage(value,robot_proto_config_pb.EnvironmentConfig.deserializeBinaryFromReader);
      msg.addConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ListEnvironmentConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ListEnvironmentConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ListEnvironmentConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListEnvironmentConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      robot_proto_config_pb.EnvironmentConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated fox.proto.EnvironmentConfig configs = 1;
 * @return {!Array<!proto.fox.proto.EnvironmentConfig>}
 */
proto.fox.proto.command_control.ListEnvironmentConfigsResponse.prototype.getConfigsList = function() {
  return /** @type{!Array<!proto.fox.proto.EnvironmentConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, robot_proto_config_pb.EnvironmentConfig, 1));
};


/**
 * @param {!Array<!proto.fox.proto.EnvironmentConfig>} value
 * @return {!proto.fox.proto.command_control.ListEnvironmentConfigsResponse} returns this
*/
proto.fox.proto.command_control.ListEnvironmentConfigsResponse.prototype.setConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.EnvironmentConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.EnvironmentConfig}
 */
proto.fox.proto.command_control.ListEnvironmentConfigsResponse.prototype.addConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.EnvironmentConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.ListEnvironmentConfigsResponse} returns this
 */
proto.fox.proto.command_control.ListEnvironmentConfigsResponse.prototype.clearConfigsList = function() {
  return this.setConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.CohortEnvironmentConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.CohortEnvironmentConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: (f = msg.getConfig()) && robot_proto_config_pb.EnvironmentConfig.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cohortId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.CohortEnvironmentConfig}
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.CohortEnvironmentConfig;
  return proto.fox.proto.command_control.CohortEnvironmentConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.CohortEnvironmentConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.CohortEnvironmentConfig}
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new robot_proto_config_pb.EnvironmentConfig;
      reader.readMessage(value,robot_proto_config_pb.EnvironmentConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.command_control.CohortEnvironmentConfigStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCohortId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.CohortEnvironmentConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.CohortEnvironmentConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      robot_proto_config_pb.EnvironmentConfig.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCohortId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional fox.proto.EnvironmentConfig config = 1;
 * @return {?proto.fox.proto.EnvironmentConfig}
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.prototype.getConfig = function() {
  return /** @type{?proto.fox.proto.EnvironmentConfig} */ (
    jspb.Message.getWrapperField(this, robot_proto_config_pb.EnvironmentConfig, 1));
};


/**
 * @param {?proto.fox.proto.EnvironmentConfig|undefined} value
 * @return {!proto.fox.proto.command_control.CohortEnvironmentConfig} returns this
*/
proto.fox.proto.command_control.CohortEnvironmentConfig.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.CohortEnvironmentConfig} returns this
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CohortEnvironmentConfigStatus status = 2;
 * @return {!proto.fox.proto.command_control.CohortEnvironmentConfigStatus}
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.prototype.getStatus = function() {
  return /** @type {!proto.fox.proto.command_control.CohortEnvironmentConfigStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.command_control.CohortEnvironmentConfigStatus} value
 * @return {!proto.fox.proto.command_control.CohortEnvironmentConfig} returns this
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string cohort_id = 3;
 * @return {string}
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.prototype.getCohortId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.CohortEnvironmentConfig} returns this
 */
proto.fox.proto.command_control.CohortEnvironmentConfig.prototype.setCohortId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.CachedCohortEnvironmentConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.CachedCohortEnvironmentConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    cohortConfig: (f = msg.getCohortConfig()) && proto.fox.proto.command_control.CohortEnvironmentConfig.toObject(includeInstance, f),
    lastSyncTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.CachedCohortEnvironmentConfig}
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.CachedCohortEnvironmentConfig;
  return proto.fox.proto.command_control.CachedCohortEnvironmentConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.CachedCohortEnvironmentConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.CachedCohortEnvironmentConfig}
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.CohortEnvironmentConfig;
      reader.readMessage(value,proto.fox.proto.command_control.CohortEnvironmentConfig.deserializeBinaryFromReader);
      msg.setCohortConfig(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSyncTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.CachedCohortEnvironmentConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.CachedCohortEnvironmentConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCohortConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.command_control.CohortEnvironmentConfig.serializeBinaryToWriter
    );
  }
  f = message.getLastSyncTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional CohortEnvironmentConfig cohort_config = 1;
 * @return {?proto.fox.proto.command_control.CohortEnvironmentConfig}
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.prototype.getCohortConfig = function() {
  return /** @type{?proto.fox.proto.command_control.CohortEnvironmentConfig} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.CohortEnvironmentConfig, 1));
};


/**
 * @param {?proto.fox.proto.command_control.CohortEnvironmentConfig|undefined} value
 * @return {!proto.fox.proto.command_control.CachedCohortEnvironmentConfig} returns this
*/
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.prototype.setCohortConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.CachedCohortEnvironmentConfig} returns this
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.prototype.clearCohortConfig = function() {
  return this.setCohortConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.prototype.hasCohortConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 last_sync_time = 2;
 * @return {number}
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.prototype.getLastSyncTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.CachedCohortEnvironmentConfig} returns this
 */
proto.fox.proto.command_control.CachedCohortEnvironmentConfig.prototype.setLastSyncTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest}
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest;
  return proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest}
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cachedConfigsList: jspb.Message.toObjectList(msg.getCachedConfigsList(),
    proto.fox.proto.command_control.CachedCohortEnvironmentConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse}
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse;
  return proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse}
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.CachedCohortEnvironmentConfig;
      reader.readMessage(value,proto.fox.proto.command_control.CachedCohortEnvironmentConfig.deserializeBinaryFromReader);
      msg.addCachedConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCachedConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.command_control.CachedCohortEnvironmentConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CachedCohortEnvironmentConfig cached_configs = 1;
 * @return {!Array<!proto.fox.proto.command_control.CachedCohortEnvironmentConfig>}
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.prototype.getCachedConfigsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.CachedCohortEnvironmentConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.CachedCohortEnvironmentConfig, 1));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.CachedCohortEnvironmentConfig>} value
 * @return {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse} returns this
*/
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.prototype.setCachedConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.command_control.CachedCohortEnvironmentConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.CachedCohortEnvironmentConfig}
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.prototype.addCachedConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.command_control.CachedCohortEnvironmentConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse} returns this
 */
proto.fox.proto.command_control.ListCachedCohortEnvironmentConfigsResponse.prototype.clearCachedConfigsList = function() {
  return this.setCachedConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PersistEnvironmentConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PersistEnvironmentConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PersistEnvironmentConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PersistEnvironmentConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: (f = msg.getConfig()) && robot_proto_config_pb.EnvironmentConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PersistEnvironmentConfigRequest}
 */
proto.fox.proto.command_control.PersistEnvironmentConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PersistEnvironmentConfigRequest;
  return proto.fox.proto.command_control.PersistEnvironmentConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PersistEnvironmentConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PersistEnvironmentConfigRequest}
 */
proto.fox.proto.command_control.PersistEnvironmentConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new robot_proto_config_pb.EnvironmentConfig;
      reader.readMessage(value,robot_proto_config_pb.EnvironmentConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PersistEnvironmentConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PersistEnvironmentConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PersistEnvironmentConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PersistEnvironmentConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      robot_proto_config_pb.EnvironmentConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional fox.proto.EnvironmentConfig config = 1;
 * @return {?proto.fox.proto.EnvironmentConfig}
 */
proto.fox.proto.command_control.PersistEnvironmentConfigRequest.prototype.getConfig = function() {
  return /** @type{?proto.fox.proto.EnvironmentConfig} */ (
    jspb.Message.getWrapperField(this, robot_proto_config_pb.EnvironmentConfig, 1));
};


/**
 * @param {?proto.fox.proto.EnvironmentConfig|undefined} value
 * @return {!proto.fox.proto.command_control.PersistEnvironmentConfigRequest} returns this
*/
proto.fox.proto.command_control.PersistEnvironmentConfigRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.PersistEnvironmentConfigRequest} returns this
 */
proto.fox.proto.command_control.PersistEnvironmentConfigRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.PersistEnvironmentConfigRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cohortConfig: (f = msg.getCohortConfig()) && proto.fox.proto.command_control.CohortEnvironmentConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest}
 */
proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest;
  return proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest}
 */
proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.CohortEnvironmentConfig;
      reader.readMessage(value,proto.fox.proto.command_control.CohortEnvironmentConfig.deserializeBinaryFromReader);
      msg.setCohortConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCohortConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.command_control.CohortEnvironmentConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional CohortEnvironmentConfig cohort_config = 1;
 * @return {?proto.fox.proto.command_control.CohortEnvironmentConfig}
 */
proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.prototype.getCohortConfig = function() {
  return /** @type{?proto.fox.proto.command_control.CohortEnvironmentConfig} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.CohortEnvironmentConfig, 1));
};


/**
 * @param {?proto.fox.proto.command_control.CohortEnvironmentConfig|undefined} value
 * @return {!proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest} returns this
*/
proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.prototype.setCohortConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest} returns this
 */
proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.prototype.clearCohortConfig = function() {
  return this.setCohortConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.PersistCohortEnvironmentConfigRequest.prototype.hasCohortConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.DeleteEnvironmentConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.DeleteEnvironmentConfigRequest}
 */
proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.DeleteEnvironmentConfigRequest;
  return proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.DeleteEnvironmentConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.DeleteEnvironmentConfigRequest}
 */
proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.DeleteEnvironmentConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.DeleteEnvironmentConfigRequest} returns this
 */
proto.fox.proto.command_control.DeleteEnvironmentConfigRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.SupportTicket.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.SupportTicket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.SupportTicket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SupportTicket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    robotName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    summary: jspb.Message.getFieldWithDefault(msg, 4, ""),
    interventionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createTime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    timestampHar: jspb.Message.getFieldWithDefault(msg, 7, ""),
    timestampIntervention: jspb.Message.getFieldWithDefault(msg, 8, ""),
    timeline: jspb.Message.getFieldWithDefault(msg, 9, ""),
    robotIsDown: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    latestRunName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    faultCode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    locale: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.SupportTicket}
 */
proto.fox.proto.command_control.SupportTicket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.SupportTicket;
  return proto.fox.proto.command_control.SupportTicket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.SupportTicket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.SupportTicket}
 */
proto.fox.proto.command_control.SupportTicket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterventionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreateTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestampHar(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestampIntervention(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeline(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRobotIsDown(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestRunName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaultCode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.SupportTicket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.SupportTicket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.SupportTicket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SupportTicket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInterventionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreateTime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTimestampHar();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTimestampIntervention();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTimeline();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRobotIsDown();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getLatestRunName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFaultCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string robot_name = 2;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string summary = 4;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string intervention_id = 5;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getInterventionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setInterventionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string create_time = 6;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getCreateTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setCreateTime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string timestamp_har = 7;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getTimestampHar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setTimestampHar = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string timestamp_intervention = 8;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getTimestampIntervention = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setTimestampIntervention = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string timeline = 9;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getTimeline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setTimeline = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool robot_is_down = 10;
 * @return {boolean}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getRobotIsDown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setRobotIsDown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string latest_run_name = 11;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getLatestRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setLatestRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string fault_code = 12;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getFaultCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setFaultCode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string locale = 13;
 * @return {string}
 */
proto.fox.proto.command_control.SupportTicket.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.SupportTicket} returns this
 */
proto.fox.proto.command_control.SupportTicket.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.Note.repeatedFields_ = [14,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.Note.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.Note.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.Note} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Note.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    faultId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    humanIntervention: jspb.Message.getFieldWithDefault(msg, 7, ""),
    intervention: jspb.Message.getFieldWithDefault(msg, 9, 0),
    interventionCause: jspb.Message.getFieldWithDefault(msg, 12, 0),
    palletDamage: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    goodsDamage: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    productSpill: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    humanAssistanceRequestId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    operatorFeedbackList: jspb.Message.toObjectList(msg.getOperatorFeedbackList(),
    proto.fox.proto.command_control.OperatorFeedback.toObject, includeInstance),
    interventionAvoidableByRobot: jspb.Message.getFieldWithDefault(msg, 16, 0),
    eventTimesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    component: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bugPriority: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.Note}
 */
proto.fox.proto.command_control.Note.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.Note;
  return proto.fox.proto.command_control.Note.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.Note} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.Note}
 */
proto.fox.proto.command_control.Note.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaultId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHumanIntervention(value);
      break;
    case 9:
      var value = /** @type {!proto.fox.proto.command_control.InterventionType} */ (reader.readEnum());
      msg.setIntervention(value);
      break;
    case 12:
      var value = /** @type {!proto.fox.proto.command_control.InterventionCause} */ (reader.readEnum());
      msg.setInterventionCause(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPalletDamage(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoodsDamage(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProductSpill(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHumanAssistanceRequestId(value);
      break;
    case 14:
      var value = new proto.fox.proto.command_control.OperatorFeedback;
      reader.readMessage(value,proto.fox.proto.command_control.OperatorFeedback.deserializeBinaryFromReader);
      msg.addOperatorFeedback(value);
      break;
    case 16:
      var value = /** @type {!proto.fox.proto.command_control.FaultAvoidableByRobot} */ (reader.readEnum());
      msg.setInterventionAvoidableByRobot(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setEventTimesList(value);
      break;
    case 5:
      var value = /** @type {!proto.fox.proto.command_control.Note.Component} */ (reader.readEnum());
      msg.setComponent(value);
      break;
    case 6:
      var value = /** @type {!proto.fox.proto.command_control.Note.BugPriority} */ (reader.readEnum());
      msg.setBugPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.Note.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.Note.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.Note} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Note.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFaultId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHumanIntervention();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIntervention();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getInterventionCause();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getPalletDamage();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getGoodsDamage();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getProductSpill();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getHumanAssistanceRequestId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getOperatorFeedbackList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.fox.proto.command_control.OperatorFeedback.serializeBinaryToWriter
    );
  }
  f = message.getInterventionAvoidableByRobot();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getEventTimesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getComponent();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBugPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.command_control.Note.Component = {
  MISCELLANEOUS: 0,
  PLANNER: 1,
  PERCEPTION: 2,
  UI: 3,
  HARDWARE: 4,
  SUMMARY: 5
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.Note.BugPriority = {
  NONE: 0,
  LOW: 10,
  HIGH: 20
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.Note.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string run_id = 2;
 * @return {string}
 */
proto.fox.proto.command_control.Note.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fault_id = 8;
 * @return {string}
 */
proto.fox.proto.command_control.Note.prototype.getFaultId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setFaultId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.fox.proto.command_control.Note.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string human_intervention = 7;
 * @return {string}
 */
proto.fox.proto.command_control.Note.prototype.getHumanIntervention = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setHumanIntervention = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional InterventionType intervention = 9;
 * @return {!proto.fox.proto.command_control.InterventionType}
 */
proto.fox.proto.command_control.Note.prototype.getIntervention = function() {
  return /** @type {!proto.fox.proto.command_control.InterventionType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.fox.proto.command_control.InterventionType} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setIntervention = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional InterventionCause intervention_cause = 12;
 * @return {!proto.fox.proto.command_control.InterventionCause}
 */
proto.fox.proto.command_control.Note.prototype.getInterventionCause = function() {
  return /** @type {!proto.fox.proto.command_control.InterventionCause} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.fox.proto.command_control.InterventionCause} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setInterventionCause = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional bool pallet_damage = 10;
 * @return {boolean}
 */
proto.fox.proto.command_control.Note.prototype.getPalletDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setPalletDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool goods_damage = 11;
 * @return {boolean}
 */
proto.fox.proto.command_control.Note.prototype.getGoodsDamage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setGoodsDamage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool product_spill = 13;
 * @return {boolean}
 */
proto.fox.proto.command_control.Note.prototype.getProductSpill = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setProductSpill = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 human_assistance_request_id = 15;
 * @return {number}
 */
proto.fox.proto.command_control.Note.prototype.getHumanAssistanceRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setHumanAssistanceRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated OperatorFeedback operator_feedback = 14;
 * @return {!Array<!proto.fox.proto.command_control.OperatorFeedback>}
 */
proto.fox.proto.command_control.Note.prototype.getOperatorFeedbackList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.OperatorFeedback>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.OperatorFeedback, 14));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.OperatorFeedback>} value
 * @return {!proto.fox.proto.command_control.Note} returns this
*/
proto.fox.proto.command_control.Note.prototype.setOperatorFeedbackList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.fox.proto.command_control.OperatorFeedback=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.OperatorFeedback}
 */
proto.fox.proto.command_control.Note.prototype.addOperatorFeedback = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.fox.proto.command_control.OperatorFeedback, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.clearOperatorFeedbackList = function() {
  return this.setOperatorFeedbackList([]);
};


/**
 * optional FaultAvoidableByRobot intervention_avoidable_by_robot = 16;
 * @return {!proto.fox.proto.command_control.FaultAvoidableByRobot}
 */
proto.fox.proto.command_control.Note.prototype.getInterventionAvoidableByRobot = function() {
  return /** @type {!proto.fox.proto.command_control.FaultAvoidableByRobot} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.fox.proto.command_control.FaultAvoidableByRobot} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setInterventionAvoidableByRobot = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * repeated int64 event_times = 4;
 * @return {!Array<number>}
 */
proto.fox.proto.command_control.Note.prototype.getEventTimesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setEventTimesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.addEventTimes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.clearEventTimesList = function() {
  return this.setEventTimesList([]);
};


/**
 * optional Component component = 5;
 * @return {!proto.fox.proto.command_control.Note.Component}
 */
proto.fox.proto.command_control.Note.prototype.getComponent = function() {
  return /** @type {!proto.fox.proto.command_control.Note.Component} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fox.proto.command_control.Note.Component} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setComponent = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional BugPriority bug_priority = 6;
 * @return {!proto.fox.proto.command_control.Note.BugPriority}
 */
proto.fox.proto.command_control.Note.prototype.getBugPriority = function() {
  return /** @type {!proto.fox.proto.command_control.Note.BugPriority} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.fox.proto.command_control.Note.BugPriority} value
 * @return {!proto.fox.proto.command_control.Note} returns this
 */
proto.fox.proto.command_control.Note.prototype.setBugPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.SubscribeNotesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.SubscribeNotesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.SubscribeNotesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeNotesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.SubscribeNotesRequest}
 */
proto.fox.proto.command_control.SubscribeNotesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.SubscribeNotesRequest;
  return proto.fox.proto.command_control.SubscribeNotesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.SubscribeNotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.SubscribeNotesRequest}
 */
proto.fox.proto.command_control.SubscribeNotesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.SubscribeNotesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.SubscribeNotesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.SubscribeNotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeNotesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PalletUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PalletUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PalletUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PalletUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    palletId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PalletUpdate}
 */
proto.fox.proto.command_control.PalletUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PalletUpdate;
  return proto.fox.proto.command_control.PalletUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PalletUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PalletUpdate}
 */
proto.fox.proto.command_control.PalletUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPalletId(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.command_control.PalletUpdate.UpdateType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PalletUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PalletUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PalletUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PalletUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPalletId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.command_control.PalletUpdate.UpdateType = {
  UNKNOWN: 0,
  REMOVE: 1,
  RESET: 2,
  MARK_STRAIGHT: 3,
  MARK_TURNED: 4,
  CLEAR_EXCEPTIONS: 5
};

/**
 * optional int32 pallet_id = 1;
 * @return {number}
 */
proto.fox.proto.command_control.PalletUpdate.prototype.getPalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.PalletUpdate} returns this
 */
proto.fox.proto.command_control.PalletUpdate.prototype.setPalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional UpdateType type = 2;
 * @return {!proto.fox.proto.command_control.PalletUpdate.UpdateType}
 */
proto.fox.proto.command_control.PalletUpdate.prototype.getType = function() {
  return /** @type {!proto.fox.proto.command_control.PalletUpdate.UpdateType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.command_control.PalletUpdate.UpdateType} value
 * @return {!proto.fox.proto.command_control.PalletUpdate} returns this
 */
proto.fox.proto.command_control.PalletUpdate.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PickPlaceZoneUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PickPlaceZoneUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PickPlaceZoneUpdate}
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PickPlaceZoneUpdate;
  return proto.fox.proto.command_control.PickPlaceZoneUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PickPlaceZoneUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PickPlaceZoneUpdate}
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setZoneIdsList(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.command_control.PickPlaceZoneUpdate.UpdateType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PickPlaceZoneUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PickPlaceZoneUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.UpdateType = {
  UNKNOWN: 0,
  REMOVE: 1,
  RESET: 2
};

/**
 * repeated int32 zone_ids = 3;
 * @return {!Array<number>}
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.prototype.getZoneIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.command_control.PickPlaceZoneUpdate} returns this
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.prototype.setZoneIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.PickPlaceZoneUpdate} returns this
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.prototype.addZoneIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PickPlaceZoneUpdate} returns this
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.prototype.clearZoneIdsList = function() {
  return this.setZoneIdsList([]);
};


/**
 * optional UpdateType type = 2;
 * @return {!proto.fox.proto.command_control.PickPlaceZoneUpdate.UpdateType}
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.prototype.getType = function() {
  return /** @type {!proto.fox.proto.command_control.PickPlaceZoneUpdate.UpdateType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.command_control.PickPlaceZoneUpdate.UpdateType} value
 * @return {!proto.fox.proto.command_control.PickPlaceZoneUpdate} returns this
 */
proto.fox.proto.command_control.PickPlaceZoneUpdate.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.SubscribeManualUpdatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.SubscribeManualUpdatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.SubscribeManualUpdatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeManualUpdatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.SubscribeManualUpdatesRequest}
 */
proto.fox.proto.command_control.SubscribeManualUpdatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.SubscribeManualUpdatesRequest;
  return proto.fox.proto.command_control.SubscribeManualUpdatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.SubscribeManualUpdatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.SubscribeManualUpdatesRequest}
 */
proto.fox.proto.command_control.SubscribeManualUpdatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.SubscribeManualUpdatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.SubscribeManualUpdatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.SubscribeManualUpdatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeManualUpdatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.WriteEnvironmentConfigUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.WriteEnvironmentConfigUpdate}
 */
proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.WriteEnvironmentConfigUpdate;
  return proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.WriteEnvironmentConfigUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.WriteEnvironmentConfigUpdate}
 */
proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.WriteEnvironmentConfigUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ManualUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ManualUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ManualUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ManualUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    palletUpdate: (f = msg.getPalletUpdate()) && proto.fox.proto.command_control.PalletUpdate.toObject(includeInstance, f),
    pickPlaceZoneUpdate: (f = msg.getPickPlaceZoneUpdate()) && proto.fox.proto.command_control.PickPlaceZoneUpdate.toObject(includeInstance, f),
    writeEnvironmentConfigUpdate: (f = msg.getWriteEnvironmentConfigUpdate()) && proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ManualUpdate}
 */
proto.fox.proto.command_control.ManualUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ManualUpdate;
  return proto.fox.proto.command_control.ManualUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ManualUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ManualUpdate}
 */
proto.fox.proto.command_control.ManualUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.PalletUpdate;
      reader.readMessage(value,proto.fox.proto.command_control.PalletUpdate.deserializeBinaryFromReader);
      msg.setPalletUpdate(value);
      break;
    case 2:
      var value = new proto.fox.proto.command_control.PickPlaceZoneUpdate;
      reader.readMessage(value,proto.fox.proto.command_control.PickPlaceZoneUpdate.deserializeBinaryFromReader);
      msg.setPickPlaceZoneUpdate(value);
      break;
    case 3:
      var value = new proto.fox.proto.command_control.WriteEnvironmentConfigUpdate;
      reader.readMessage(value,proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.deserializeBinaryFromReader);
      msg.setWriteEnvironmentConfigUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ManualUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ManualUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ManualUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ManualUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPalletUpdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.command_control.PalletUpdate.serializeBinaryToWriter
    );
  }
  f = message.getPickPlaceZoneUpdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.command_control.PickPlaceZoneUpdate.serializeBinaryToWriter
    );
  }
  f = message.getWriteEnvironmentConfigUpdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.command_control.WriteEnvironmentConfigUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * optional PalletUpdate pallet_update = 1;
 * @return {?proto.fox.proto.command_control.PalletUpdate}
 */
proto.fox.proto.command_control.ManualUpdate.prototype.getPalletUpdate = function() {
  return /** @type{?proto.fox.proto.command_control.PalletUpdate} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.PalletUpdate, 1));
};


/**
 * @param {?proto.fox.proto.command_control.PalletUpdate|undefined} value
 * @return {!proto.fox.proto.command_control.ManualUpdate} returns this
*/
proto.fox.proto.command_control.ManualUpdate.prototype.setPalletUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.ManualUpdate} returns this
 */
proto.fox.proto.command_control.ManualUpdate.prototype.clearPalletUpdate = function() {
  return this.setPalletUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.ManualUpdate.prototype.hasPalletUpdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PickPlaceZoneUpdate pick_place_zone_update = 2;
 * @return {?proto.fox.proto.command_control.PickPlaceZoneUpdate}
 */
proto.fox.proto.command_control.ManualUpdate.prototype.getPickPlaceZoneUpdate = function() {
  return /** @type{?proto.fox.proto.command_control.PickPlaceZoneUpdate} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.PickPlaceZoneUpdate, 2));
};


/**
 * @param {?proto.fox.proto.command_control.PickPlaceZoneUpdate|undefined} value
 * @return {!proto.fox.proto.command_control.ManualUpdate} returns this
*/
proto.fox.proto.command_control.ManualUpdate.prototype.setPickPlaceZoneUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.ManualUpdate} returns this
 */
proto.fox.proto.command_control.ManualUpdate.prototype.clearPickPlaceZoneUpdate = function() {
  return this.setPickPlaceZoneUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.ManualUpdate.prototype.hasPickPlaceZoneUpdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional WriteEnvironmentConfigUpdate write_environment_config_update = 3;
 * @return {?proto.fox.proto.command_control.WriteEnvironmentConfigUpdate}
 */
proto.fox.proto.command_control.ManualUpdate.prototype.getWriteEnvironmentConfigUpdate = function() {
  return /** @type{?proto.fox.proto.command_control.WriteEnvironmentConfigUpdate} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.WriteEnvironmentConfigUpdate, 3));
};


/**
 * @param {?proto.fox.proto.command_control.WriteEnvironmentConfigUpdate|undefined} value
 * @return {!proto.fox.proto.command_control.ManualUpdate} returns this
*/
proto.fox.proto.command_control.ManualUpdate.prototype.setWriteEnvironmentConfigUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.ManualUpdate} returns this
 */
proto.fox.proto.command_control.ManualUpdate.prototype.clearWriteEnvironmentConfigUpdate = function() {
  return this.setWriteEnvironmentConfigUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.ManualUpdate.prototype.hasWriteEnvironmentConfigUpdate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.GetShutdownStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.GetShutdownStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.GetShutdownStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetShutdownStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.GetShutdownStatusRequest}
 */
proto.fox.proto.command_control.GetShutdownStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.GetShutdownStatusRequest;
  return proto.fox.proto.command_control.GetShutdownStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.GetShutdownStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.GetShutdownStatusRequest}
 */
proto.fox.proto.command_control.GetShutdownStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.GetShutdownStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.GetShutdownStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.GetShutdownStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetShutdownStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ShutdownStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ShutdownStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ShutdownStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ShutdownStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestedAction: jspb.Message.getFieldWithDefault(msg, 1, 0),
    potentialAction: (f = msg.getPotentialAction()) && proto.fox.proto.command_control.ShutdownStatus.PotentialAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ShutdownStatus}
 */
proto.fox.proto.command_control.ShutdownStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ShutdownStatus;
  return proto.fox.proto.command_control.ShutdownStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ShutdownStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ShutdownStatus}
 */
proto.fox.proto.command_control.ShutdownStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.command_control.ShutdownStatus.RequestedAction} */ (reader.readEnum());
      msg.setRequestedAction(value);
      break;
    case 2:
      var value = new proto.fox.proto.command_control.ShutdownStatus.PotentialAction;
      reader.readMessage(value,proto.fox.proto.command_control.ShutdownStatus.PotentialAction.deserializeBinaryFromReader);
      msg.setPotentialAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ShutdownStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ShutdownStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ShutdownStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ShutdownStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestedAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPotentialAction();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.command_control.ShutdownStatus.PotentialAction.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.command_control.ShutdownStatus.RequestedAction = {
  NOTHING_REQUESTED: 0,
  SHUTDOWN_REQUESTED: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ShutdownStatus.PotentialAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    initiatedTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    waitTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 5, 0),
    action: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction}
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ShutdownStatus.PotentialAction;
  return proto.fox.proto.command_control.ShutdownStatus.PotentialAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction}
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInitiatedTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWaitTime(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Reason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 6:
      var value = /** @type {!proto.fox.proto.command_control.ShutdownStatus.RequestedAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ShutdownStatus.PotentialAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitiatedTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWaitTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Status = {
  WAITING: 0,
  REQUESTED: 1,
  CANCELED: 2
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Reason = {
  UNKNOWN: 0,
  END_OF_RUN: 1,
  UPDATE_VERSION: 2
};

/**
 * optional int64 initiated_time = 1;
 * @return {number}
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.getInitiatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction} returns this
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.setInitiatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 wait_time = 2;
 * @return {number}
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.getWaitTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction} returns this
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.setWaitTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Status status = 3;
 * @return {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Status}
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.getStatus = function() {
  return /** @type {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Status} value
 * @return {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction} returns this
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Reason reason = 5;
 * @return {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Reason}
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.getReason = function() {
  return /** @type {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Reason} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction.Reason} value
 * @return {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction} returns this
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional RequestedAction action = 6;
 * @return {!proto.fox.proto.command_control.ShutdownStatus.RequestedAction}
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.getAction = function() {
  return /** @type {!proto.fox.proto.command_control.ShutdownStatus.RequestedAction} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.fox.proto.command_control.ShutdownStatus.RequestedAction} value
 * @return {!proto.fox.proto.command_control.ShutdownStatus.PotentialAction} returns this
 */
proto.fox.proto.command_control.ShutdownStatus.PotentialAction.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional RequestedAction requested_action = 1;
 * @return {!proto.fox.proto.command_control.ShutdownStatus.RequestedAction}
 */
proto.fox.proto.command_control.ShutdownStatus.prototype.getRequestedAction = function() {
  return /** @type {!proto.fox.proto.command_control.ShutdownStatus.RequestedAction} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.command_control.ShutdownStatus.RequestedAction} value
 * @return {!proto.fox.proto.command_control.ShutdownStatus} returns this
 */
proto.fox.proto.command_control.ShutdownStatus.prototype.setRequestedAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional PotentialAction potential_action = 2;
 * @return {?proto.fox.proto.command_control.ShutdownStatus.PotentialAction}
 */
proto.fox.proto.command_control.ShutdownStatus.prototype.getPotentialAction = function() {
  return /** @type{?proto.fox.proto.command_control.ShutdownStatus.PotentialAction} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.ShutdownStatus.PotentialAction, 2));
};


/**
 * @param {?proto.fox.proto.command_control.ShutdownStatus.PotentialAction|undefined} value
 * @return {!proto.fox.proto.command_control.ShutdownStatus} returns this
*/
proto.fox.proto.command_control.ShutdownStatus.prototype.setPotentialAction = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.ShutdownStatus} returns this
 */
proto.fox.proto.command_control.ShutdownStatus.prototype.clearPotentialAction = function() {
  return this.setPotentialAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.ShutdownStatus.prototype.hasPotentialAction = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.UpdateShutdownStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.UpdateShutdownStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.UpdateShutdownStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UpdateShutdownStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    shutdownStatus: (f = msg.getShutdownStatus()) && proto.fox.proto.command_control.ShutdownStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.UpdateShutdownStatusRequest}
 */
proto.fox.proto.command_control.UpdateShutdownStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.UpdateShutdownStatusRequest;
  return proto.fox.proto.command_control.UpdateShutdownStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.UpdateShutdownStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.UpdateShutdownStatusRequest}
 */
proto.fox.proto.command_control.UpdateShutdownStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.ShutdownStatus;
      reader.readMessage(value,proto.fox.proto.command_control.ShutdownStatus.deserializeBinaryFromReader);
      msg.setShutdownStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.UpdateShutdownStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.UpdateShutdownStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.UpdateShutdownStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UpdateShutdownStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShutdownStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.command_control.ShutdownStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional ShutdownStatus shutdown_status = 1;
 * @return {?proto.fox.proto.command_control.ShutdownStatus}
 */
proto.fox.proto.command_control.UpdateShutdownStatusRequest.prototype.getShutdownStatus = function() {
  return /** @type{?proto.fox.proto.command_control.ShutdownStatus} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.ShutdownStatus, 1));
};


/**
 * @param {?proto.fox.proto.command_control.ShutdownStatus|undefined} value
 * @return {!proto.fox.proto.command_control.UpdateShutdownStatusRequest} returns this
*/
proto.fox.proto.command_control.UpdateShutdownStatusRequest.prototype.setShutdownStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.UpdateShutdownStatusRequest} returns this
 */
proto.fox.proto.command_control.UpdateShutdownStatusRequest.prototype.clearShutdownStatus = function() {
  return this.setShutdownStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.UpdateShutdownStatusRequest.prototype.hasShutdownStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ChangeDeploymentChannelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ChangeDeploymentChannelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ChangeDeploymentChannelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ChangeDeploymentChannelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ChangeDeploymentChannelRequest}
 */
proto.fox.proto.command_control.ChangeDeploymentChannelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ChangeDeploymentChannelRequest;
  return proto.fox.proto.command_control.ChangeDeploymentChannelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ChangeDeploymentChannelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ChangeDeploymentChannelRequest}
 */
proto.fox.proto.command_control.ChangeDeploymentChannelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ChangeDeploymentChannelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ChangeDeploymentChannelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ChangeDeploymentChannelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ChangeDeploymentChannelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string channel = 1;
 * @return {string}
 */
proto.fox.proto.command_control.ChangeDeploymentChannelRequest.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.ChangeDeploymentChannelRequest} returns this
 */
proto.fox.proto.command_control.ChangeDeploymentChannelRequest.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ChangeDeploymentChannelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ChangeDeploymentChannelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ChangeDeploymentChannelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ChangeDeploymentChannelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ChangeDeploymentChannelResponse}
 */
proto.fox.proto.command_control.ChangeDeploymentChannelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ChangeDeploymentChannelResponse;
  return proto.fox.proto.command_control.ChangeDeploymentChannelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ChangeDeploymentChannelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ChangeDeploymentChannelResponse}
 */
proto.fox.proto.command_control.ChangeDeploymentChannelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ChangeDeploymentChannelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ChangeDeploymentChannelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ChangeDeploymentChannelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ChangeDeploymentChannelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string channel = 1;
 * @return {string}
 */
proto.fox.proto.command_control.ChangeDeploymentChannelResponse.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.ChangeDeploymentChannelResponse} returns this
 */
proto.fox.proto.command_control.ChangeDeploymentChannelResponse.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.UpdateTrailerMetadataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.UpdateTrailerMetadataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trailerMetadata: (f = msg.getTrailerMetadata()) && proto.fox.proto.command_control.TrailerMetadata.toObject(includeInstance, f),
    updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.UpdateTrailerMetadataRequest}
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.UpdateTrailerMetadataRequest;
  return proto.fox.proto.command_control.UpdateTrailerMetadataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.UpdateTrailerMetadataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.UpdateTrailerMetadataRequest}
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.TrailerMetadata;
      reader.readMessage(value,proto.fox.proto.command_control.TrailerMetadata.deserializeBinaryFromReader);
      msg.setTrailerMetadata(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.UpdateTrailerMetadataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.UpdateTrailerMetadataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrailerMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.command_control.TrailerMetadata.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrailerMetadata trailer_metadata = 1;
 * @return {?proto.fox.proto.command_control.TrailerMetadata}
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.prototype.getTrailerMetadata = function() {
  return /** @type{?proto.fox.proto.command_control.TrailerMetadata} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.TrailerMetadata, 1));
};


/**
 * @param {?proto.fox.proto.command_control.TrailerMetadata|undefined} value
 * @return {!proto.fox.proto.command_control.UpdateTrailerMetadataRequest} returns this
*/
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.prototype.setTrailerMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.UpdateTrailerMetadataRequest} returns this
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.prototype.clearTrailerMetadata = function() {
  return this.setTrailerMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.prototype.hasTrailerMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.fox.proto.command_control.UpdateTrailerMetadataRequest} returns this
*/
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.UpdateTrailerMetadataRequest} returns this
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.UpdateTrailerMetadataRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ListTrailerMetadatasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ListTrailerMetadatasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ListTrailerMetadatasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListTrailerMetadatasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ListTrailerMetadatasRequest}
 */
proto.fox.proto.command_control.ListTrailerMetadatasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ListTrailerMetadatasRequest;
  return proto.fox.proto.command_control.ListTrailerMetadatasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ListTrailerMetadatasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ListTrailerMetadatasRequest}
 */
proto.fox.proto.command_control.ListTrailerMetadatasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ListTrailerMetadatasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ListTrailerMetadatasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ListTrailerMetadatasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListTrailerMetadatasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.ListTrailerMetadatasResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ListTrailerMetadatasResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ListTrailerMetadatasResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ListTrailerMetadatasResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListTrailerMetadatasResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trailerMetadatasList: jspb.Message.toObjectList(msg.getTrailerMetadatasList(),
    proto.fox.proto.command_control.TrailerMetadata.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ListTrailerMetadatasResponse}
 */
proto.fox.proto.command_control.ListTrailerMetadatasResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ListTrailerMetadatasResponse;
  return proto.fox.proto.command_control.ListTrailerMetadatasResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ListTrailerMetadatasResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ListTrailerMetadatasResponse}
 */
proto.fox.proto.command_control.ListTrailerMetadatasResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.TrailerMetadata;
      reader.readMessage(value,proto.fox.proto.command_control.TrailerMetadata.deserializeBinaryFromReader);
      msg.addTrailerMetadatas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ListTrailerMetadatasResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ListTrailerMetadatasResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ListTrailerMetadatasResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ListTrailerMetadatasResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrailerMetadatasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.command_control.TrailerMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrailerMetadata trailer_metadatas = 1;
 * @return {!Array<!proto.fox.proto.command_control.TrailerMetadata>}
 */
proto.fox.proto.command_control.ListTrailerMetadatasResponse.prototype.getTrailerMetadatasList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.TrailerMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.TrailerMetadata, 1));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.TrailerMetadata>} value
 * @return {!proto.fox.proto.command_control.ListTrailerMetadatasResponse} returns this
*/
proto.fox.proto.command_control.ListTrailerMetadatasResponse.prototype.setTrailerMetadatasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.command_control.TrailerMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.TrailerMetadata}
 */
proto.fox.proto.command_control.ListTrailerMetadatasResponse.prototype.addTrailerMetadatas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.command_control.TrailerMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.ListTrailerMetadatasResponse} returns this
 */
proto.fox.proto.command_control.ListTrailerMetadatasResponse.prototype.clearTrailerMetadatasList = function() {
  return this.setTrailerMetadatasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.UICancelGoalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.UICancelGoalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.UICancelGoalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UICancelGoalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.UICancelGoalRequest}
 */
proto.fox.proto.command_control.UICancelGoalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.UICancelGoalRequest;
  return proto.fox.proto.command_control.UICancelGoalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.UICancelGoalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.UICancelGoalRequest}
 */
proto.fox.proto.command_control.UICancelGoalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.UICancelGoalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.UICancelGoalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.UICancelGoalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UICancelGoalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string goal_id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.UICancelGoalRequest.prototype.getGoalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.UICancelGoalRequest} returns this
 */
proto.fox.proto.command_control.UICancelGoalRequest.prototype.setGoalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.GetGoalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.GetGoalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.GetGoalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetGoalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.GetGoalRequest}
 */
proto.fox.proto.command_control.GetGoalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.GetGoalRequest;
  return proto.fox.proto.command_control.GetGoalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.GetGoalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.GetGoalRequest}
 */
proto.fox.proto.command_control.GetGoalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.GetGoalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.GetGoalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.GetGoalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetGoalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string goal_id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.GetGoalRequest.prototype.getGoalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.GetGoalRequest} returns this
 */
proto.fox.proto.command_control.GetGoalRequest.prototype.setGoalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.RobotPose.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.RobotPose.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.RobotPose} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.RobotPose.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapTRobot: (f = msg.getMapTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.RobotPose}
 */
proto.fox.proto.command_control.RobotPose.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.RobotPose;
  return proto.fox.proto.command_control.RobotPose.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.RobotPose} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.RobotPose}
 */
proto.fox.proto.command_control.RobotPose.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTRobot(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.RobotPose.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.RobotPose.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.RobotPose} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.RobotPose.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapTRobot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional fox.proto.Transform2f map_t_robot = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.command_control.RobotPose.prototype.getMapTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.command_control.RobotPose} returns this
*/
proto.fox.proto.command_control.RobotPose.prototype.setMapTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.RobotPose} returns this
 */
proto.fox.proto.command_control.RobotPose.prototype.clearMapTRobot = function() {
  return this.setMapTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.RobotPose.prototype.hasMapTRobot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.fox.proto.command_control.RobotPose.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.RobotPose} returns this
 */
proto.fox.proto.command_control.RobotPose.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.SubscribeRobotPoseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.SubscribeRobotPoseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.SubscribeRobotPoseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeRobotPoseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.SubscribeRobotPoseRequest}
 */
proto.fox.proto.command_control.SubscribeRobotPoseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.SubscribeRobotPoseRequest;
  return proto.fox.proto.command_control.SubscribeRobotPoseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.SubscribeRobotPoseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.SubscribeRobotPoseRequest}
 */
proto.fox.proto.command_control.SubscribeRobotPoseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.SubscribeRobotPoseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.SubscribeRobotPoseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.SubscribeRobotPoseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeRobotPoseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.UpdateRobotPoseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.UpdateRobotPoseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.UpdateRobotPoseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UpdateRobotPoseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotPose: (f = msg.getRobotPose()) && proto.fox.proto.command_control.RobotPose.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.UpdateRobotPoseRequest}
 */
proto.fox.proto.command_control.UpdateRobotPoseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.UpdateRobotPoseRequest;
  return proto.fox.proto.command_control.UpdateRobotPoseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.UpdateRobotPoseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.UpdateRobotPoseRequest}
 */
proto.fox.proto.command_control.UpdateRobotPoseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.RobotPose;
      reader.readMessage(value,proto.fox.proto.command_control.RobotPose.deserializeBinaryFromReader);
      msg.setRobotPose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.UpdateRobotPoseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.UpdateRobotPoseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.UpdateRobotPoseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UpdateRobotPoseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotPose();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.command_control.RobotPose.serializeBinaryToWriter
    );
  }
};


/**
 * optional RobotPose robot_pose = 1;
 * @return {?proto.fox.proto.command_control.RobotPose}
 */
proto.fox.proto.command_control.UpdateRobotPoseRequest.prototype.getRobotPose = function() {
  return /** @type{?proto.fox.proto.command_control.RobotPose} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.RobotPose, 1));
};


/**
 * @param {?proto.fox.proto.command_control.RobotPose|undefined} value
 * @return {!proto.fox.proto.command_control.UpdateRobotPoseRequest} returns this
*/
proto.fox.proto.command_control.UpdateRobotPoseRequest.prototype.setRobotPose = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.UpdateRobotPoseRequest} returns this
 */
proto.fox.proto.command_control.UpdateRobotPoseRequest.prototype.clearRobotPose = function() {
  return this.setRobotPose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.UpdateRobotPoseRequest.prototype.hasRobotPose = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PublishDebugInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PublishDebugInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PublishDebugInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishDebugInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    debugInfo: (f = msg.getDebugInfo()) && proto.fox.proto.command_control.DebugInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PublishDebugInfoRequest}
 */
proto.fox.proto.command_control.PublishDebugInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PublishDebugInfoRequest;
  return proto.fox.proto.command_control.PublishDebugInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PublishDebugInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PublishDebugInfoRequest}
 */
proto.fox.proto.command_control.PublishDebugInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.DebugInfo;
      reader.readMessage(value,proto.fox.proto.command_control.DebugInfo.deserializeBinaryFromReader);
      msg.setDebugInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PublishDebugInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PublishDebugInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PublishDebugInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishDebugInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDebugInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.command_control.DebugInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional DebugInfo debug_info = 1;
 * @return {?proto.fox.proto.command_control.DebugInfo}
 */
proto.fox.proto.command_control.PublishDebugInfoRequest.prototype.getDebugInfo = function() {
  return /** @type{?proto.fox.proto.command_control.DebugInfo} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.DebugInfo, 1));
};


/**
 * @param {?proto.fox.proto.command_control.DebugInfo|undefined} value
 * @return {!proto.fox.proto.command_control.PublishDebugInfoRequest} returns this
*/
proto.fox.proto.command_control.PublishDebugInfoRequest.prototype.setDebugInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.PublishDebugInfoRequest} returns this
 */
proto.fox.proto.command_control.PublishDebugInfoRequest.prototype.clearDebugInfo = function() {
  return this.setDebugInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.PublishDebugInfoRequest.prototype.hasDebugInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.DebugInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.DebugInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.DebugInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.DebugInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    forkliftState: (f = msg.getForkliftState()) && planner_proto_types_pb.ForkliftState.toObject(includeInstance, f),
    runId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actionStatus: (f = msg.getActionStatus()) && planner_proto_planner_status_pb.ActionStatus.toObject(includeInstance, f),
    pathInfo: (f = msg.getPathInfo()) && proto.fox.proto.command_control.PathInfo.toObject(includeInstance, f),
    robotName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    mastHomed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    epochOffset: jspb.Message.getFieldWithDefault(msg, 7, 0),
    environmentConfig: (f = msg.getEnvironmentConfig()) && robot_proto_config_pb.EnvironmentConfig.toObject(includeInstance, f),
    faultState: (f = msg.getFaultState()) && log_fault_pb.FaultState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.DebugInfo}
 */
proto.fox.proto.command_control.DebugInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.DebugInfo;
  return proto.fox.proto.command_control.DebugInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.DebugInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.DebugInfo}
 */
proto.fox.proto.command_control.DebugInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new planner_proto_types_pb.ForkliftState;
      reader.readMessage(value,planner_proto_types_pb.ForkliftState.deserializeBinaryFromReader);
      msg.setForkliftState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 3:
      var value = new planner_proto_planner_status_pb.ActionStatus;
      reader.readMessage(value,planner_proto_planner_status_pb.ActionStatus.deserializeBinaryFromReader);
      msg.setActionStatus(value);
      break;
    case 4:
      var value = new proto.fox.proto.command_control.PathInfo;
      reader.readMessage(value,proto.fox.proto.command_control.PathInfo.deserializeBinaryFromReader);
      msg.setPathInfo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRobotName(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMastHomed(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEpochOffset(value);
      break;
    case 8:
      var value = new robot_proto_config_pb.EnvironmentConfig;
      reader.readMessage(value,robot_proto_config_pb.EnvironmentConfig.deserializeBinaryFromReader);
      msg.setEnvironmentConfig(value);
      break;
    case 9:
      var value = new log_fault_pb.FaultState;
      reader.readMessage(value,log_fault_pb.FaultState.deserializeBinaryFromReader);
      msg.setFaultState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.DebugInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.DebugInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.DebugInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.DebugInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForkliftState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      planner_proto_types_pb.ForkliftState.serializeBinaryToWriter
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      planner_proto_planner_status_pb.ActionStatus.serializeBinaryToWriter
    );
  }
  f = message.getPathInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.command_control.PathInfo.serializeBinaryToWriter
    );
  }
  f = message.getRobotName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMastHomed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getEpochOffset();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getEnvironmentConfig();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      robot_proto_config_pb.EnvironmentConfig.serializeBinaryToWriter
    );
  }
  f = message.getFaultState();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      log_fault_pb.FaultState.serializeBinaryToWriter
    );
  }
};


/**
 * optional fox.proto.ForkliftState forklift_state = 1;
 * @return {?proto.fox.proto.ForkliftState}
 */
proto.fox.proto.command_control.DebugInfo.prototype.getForkliftState = function() {
  return /** @type{?proto.fox.proto.ForkliftState} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.ForkliftState, 1));
};


/**
 * @param {?proto.fox.proto.ForkliftState|undefined} value
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
*/
proto.fox.proto.command_control.DebugInfo.prototype.setForkliftState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
 */
proto.fox.proto.command_control.DebugInfo.prototype.clearForkliftState = function() {
  return this.setForkliftState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.DebugInfo.prototype.hasForkliftState = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string run_id = 2;
 * @return {string}
 */
proto.fox.proto.command_control.DebugInfo.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
 */
proto.fox.proto.command_control.DebugInfo.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional fox.proto.ActionStatus action_status = 3;
 * @return {?proto.fox.proto.ActionStatus}
 */
proto.fox.proto.command_control.DebugInfo.prototype.getActionStatus = function() {
  return /** @type{?proto.fox.proto.ActionStatus} */ (
    jspb.Message.getWrapperField(this, planner_proto_planner_status_pb.ActionStatus, 3));
};


/**
 * @param {?proto.fox.proto.ActionStatus|undefined} value
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
*/
proto.fox.proto.command_control.DebugInfo.prototype.setActionStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
 */
proto.fox.proto.command_control.DebugInfo.prototype.clearActionStatus = function() {
  return this.setActionStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.DebugInfo.prototype.hasActionStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PathInfo path_info = 4;
 * @return {?proto.fox.proto.command_control.PathInfo}
 */
proto.fox.proto.command_control.DebugInfo.prototype.getPathInfo = function() {
  return /** @type{?proto.fox.proto.command_control.PathInfo} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.PathInfo, 4));
};


/**
 * @param {?proto.fox.proto.command_control.PathInfo|undefined} value
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
*/
proto.fox.proto.command_control.DebugInfo.prototype.setPathInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
 */
proto.fox.proto.command_control.DebugInfo.prototype.clearPathInfo = function() {
  return this.setPathInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.DebugInfo.prototype.hasPathInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string robot_name = 5;
 * @return {string}
 */
proto.fox.proto.command_control.DebugInfo.prototype.getRobotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
 */
proto.fox.proto.command_control.DebugInfo.prototype.setRobotName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool mast_homed = 6;
 * @return {boolean}
 */
proto.fox.proto.command_control.DebugInfo.prototype.getMastHomed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
 */
proto.fox.proto.command_control.DebugInfo.prototype.setMastHomed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 epoch_offset = 7;
 * @return {number}
 */
proto.fox.proto.command_control.DebugInfo.prototype.getEpochOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
 */
proto.fox.proto.command_control.DebugInfo.prototype.setEpochOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional fox.proto.EnvironmentConfig environment_config = 8;
 * @return {?proto.fox.proto.EnvironmentConfig}
 */
proto.fox.proto.command_control.DebugInfo.prototype.getEnvironmentConfig = function() {
  return /** @type{?proto.fox.proto.EnvironmentConfig} */ (
    jspb.Message.getWrapperField(this, robot_proto_config_pb.EnvironmentConfig, 8));
};


/**
 * @param {?proto.fox.proto.EnvironmentConfig|undefined} value
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
*/
proto.fox.proto.command_control.DebugInfo.prototype.setEnvironmentConfig = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
 */
proto.fox.proto.command_control.DebugInfo.prototype.clearEnvironmentConfig = function() {
  return this.setEnvironmentConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.DebugInfo.prototype.hasEnvironmentConfig = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional fox.proto.FaultState fault_state = 9;
 * @return {?proto.fox.proto.FaultState}
 */
proto.fox.proto.command_control.DebugInfo.prototype.getFaultState = function() {
  return /** @type{?proto.fox.proto.FaultState} */ (
    jspb.Message.getWrapperField(this, log_fault_pb.FaultState, 9));
};


/**
 * @param {?proto.fox.proto.FaultState|undefined} value
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
*/
proto.fox.proto.command_control.DebugInfo.prototype.setFaultState = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.DebugInfo} returns this
 */
proto.fox.proto.command_control.DebugInfo.prototype.clearFaultState = function() {
  return this.setFaultState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.DebugInfo.prototype.hasFaultState = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.PathInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PathInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PathInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PathInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PathInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    posesList: jspb.Message.toObjectList(msg.getPosesList(),
    geometry_proto_transform_pb.Transform2f.toObject, includeInstance),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0),
    valid: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PathInfo}
 */
proto.fox.proto.command_control.PathInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PathInfo;
  return proto.fox.proto.command_control.PathInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PathInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PathInfo}
 */
proto.fox.proto.command_control.PathInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.addPoses(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PathInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PathInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PathInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PathInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getValid();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated fox.proto.Transform2f poses = 1;
 * @return {!Array<!proto.fox.proto.Transform2f>}
 */
proto.fox.proto.command_control.PathInfo.prototype.getPosesList = function() {
  return /** @type{!Array<!proto.fox.proto.Transform2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {!Array<!proto.fox.proto.Transform2f>} value
 * @return {!proto.fox.proto.command_control.PathInfo} returns this
*/
proto.fox.proto.command_control.PathInfo.prototype.setPosesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.Transform2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Transform2f}
 */
proto.fox.proto.command_control.PathInfo.prototype.addPoses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.Transform2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PathInfo} returns this
 */
proto.fox.proto.command_control.PathInfo.prototype.clearPosesList = function() {
  return this.setPosesList([]);
};


/**
 * optional int32 state = 2;
 * @return {number}
 */
proto.fox.proto.command_control.PathInfo.prototype.getState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.PathInfo} returns this
 */
proto.fox.proto.command_control.PathInfo.prototype.setState = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool valid = 3;
 * @return {boolean}
 */
proto.fox.proto.command_control.PathInfo.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.command_control.PathInfo} returns this
 */
proto.fox.proto.command_control.PathInfo.prototype.setValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.Pallet.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.Pallet.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.Pallet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.Pallet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Pallet.toObject = function(includeInstance, msg) {
  var f, obj = {
    detectionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mapTPallet: (f = msg.getMapTPallet()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    shape: (f = msg.getShape()) && geometry_proto_transform_pb.Vector3f.toObject(includeInstance, f),
    supportType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    location: jspb.Message.getFieldWithDefault(msg, 5, 0),
    exceptionsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.Pallet}
 */
proto.fox.proto.command_control.Pallet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.Pallet;
  return proto.fox.proto.command_control.Pallet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.Pallet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.Pallet}
 */
proto.fox.proto.command_control.Pallet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDetectionId(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setMapTPallet(value);
      break;
    case 3:
      var value = new geometry_proto_transform_pb.Vector3f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector3f.deserializeBinaryFromReader);
      msg.setShape(value);
      break;
    case 14:
      var value = /** @type {!proto.fox.proto.SupportType} */ (reader.readEnum());
      msg.setSupportType(value);
      break;
    case 5:
      var value = /** @type {!proto.fox.proto.TrackedPallet.PalletLocation} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 10:
      var value = /** @type {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>} */ (reader.readPackedEnum());
      msg.setExceptionsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.Pallet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.Pallet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.Pallet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Pallet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetectionId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMapTPallet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getShape();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      geometry_proto_transform_pb.Vector3f.serializeBinaryToWriter
    );
  }
  f = message.getSupportType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getExceptionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
};


/**
 * optional int32 detection_id = 1;
 * @return {number}
 */
proto.fox.proto.command_control.Pallet.prototype.getDetectionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.Pallet} returns this
 */
proto.fox.proto.command_control.Pallet.prototype.setDetectionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional fox.proto.Transform3f map_t_pallet = 2;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.command_control.Pallet.prototype.getMapTPallet = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 2));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.command_control.Pallet} returns this
*/
proto.fox.proto.command_control.Pallet.prototype.setMapTPallet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.Pallet} returns this
 */
proto.fox.proto.command_control.Pallet.prototype.clearMapTPallet = function() {
  return this.setMapTPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.Pallet.prototype.hasMapTPallet = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional fox.proto.Vector3f shape = 3;
 * @return {?proto.fox.proto.Vector3f}
 */
proto.fox.proto.command_control.Pallet.prototype.getShape = function() {
  return /** @type{?proto.fox.proto.Vector3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector3f, 3));
};


/**
 * @param {?proto.fox.proto.Vector3f|undefined} value
 * @return {!proto.fox.proto.command_control.Pallet} returns this
*/
proto.fox.proto.command_control.Pallet.prototype.setShape = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.Pallet} returns this
 */
proto.fox.proto.command_control.Pallet.prototype.clearShape = function() {
  return this.setShape(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.Pallet.prototype.hasShape = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional fox.proto.SupportType support_type = 14;
 * @return {!proto.fox.proto.SupportType}
 */
proto.fox.proto.command_control.Pallet.prototype.getSupportType = function() {
  return /** @type {!proto.fox.proto.SupportType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.fox.proto.SupportType} value
 * @return {!proto.fox.proto.command_control.Pallet} returns this
 */
proto.fox.proto.command_control.Pallet.prototype.setSupportType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional fox.proto.TrackedPallet.PalletLocation location = 5;
 * @return {!proto.fox.proto.TrackedPallet.PalletLocation}
 */
proto.fox.proto.command_control.Pallet.prototype.getLocation = function() {
  return /** @type {!proto.fox.proto.TrackedPallet.PalletLocation} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fox.proto.TrackedPallet.PalletLocation} value
 * @return {!proto.fox.proto.command_control.Pallet} returns this
 */
proto.fox.proto.command_control.Pallet.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated fox.proto.TrackedPallet.PalletExceptionTypes exceptions = 10;
 * @return {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>}
 */
proto.fox.proto.command_control.Pallet.prototype.getExceptionsList = function() {
  return /** @type {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>} value
 * @return {!proto.fox.proto.command_control.Pallet} returns this
 */
proto.fox.proto.command_control.Pallet.prototype.setExceptionsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.fox.proto.TrackedPallet.PalletExceptionTypes} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.Pallet} returns this
 */
proto.fox.proto.command_control.Pallet.prototype.addExceptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.Pallet} returns this
 */
proto.fox.proto.command_control.Pallet.prototype.clearExceptionsList = function() {
  return this.setExceptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.SubscribePalletsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.SubscribePalletsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.SubscribePalletsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribePalletsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.SubscribePalletsRequest}
 */
proto.fox.proto.command_control.SubscribePalletsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.SubscribePalletsRequest;
  return proto.fox.proto.command_control.SubscribePalletsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.SubscribePalletsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.SubscribePalletsRequest}
 */
proto.fox.proto.command_control.SubscribePalletsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.SubscribePalletsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.SubscribePalletsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.SubscribePalletsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribePalletsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.SubscribePalletsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.SubscribePalletsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.SubscribePalletsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.SubscribePalletsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribePalletsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    palletsList: jspb.Message.toObjectList(msg.getPalletsList(),
    proto.fox.proto.command_control.Pallet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.SubscribePalletsResponse}
 */
proto.fox.proto.command_control.SubscribePalletsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.SubscribePalletsResponse;
  return proto.fox.proto.command_control.SubscribePalletsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.SubscribePalletsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.SubscribePalletsResponse}
 */
proto.fox.proto.command_control.SubscribePalletsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.Pallet;
      reader.readMessage(value,proto.fox.proto.command_control.Pallet.deserializeBinaryFromReader);
      msg.addPallets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.SubscribePalletsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.SubscribePalletsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.SubscribePalletsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribePalletsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPalletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.command_control.Pallet.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Pallet pallets = 1;
 * @return {!Array<!proto.fox.proto.command_control.Pallet>}
 */
proto.fox.proto.command_control.SubscribePalletsResponse.prototype.getPalletsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.Pallet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.Pallet, 1));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.Pallet>} value
 * @return {!proto.fox.proto.command_control.SubscribePalletsResponse} returns this
*/
proto.fox.proto.command_control.SubscribePalletsResponse.prototype.setPalletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.command_control.Pallet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.Pallet}
 */
proto.fox.proto.command_control.SubscribePalletsResponse.prototype.addPallets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.command_control.Pallet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.SubscribePalletsResponse} returns this
 */
proto.fox.proto.command_control.SubscribePalletsResponse.prototype.clearPalletsList = function() {
  return this.setPalletsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.PublishPalletsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PublishPalletsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PublishPalletsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PublishPalletsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishPalletsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    palletsList: jspb.Message.toObjectList(msg.getPalletsList(),
    proto.fox.proto.command_control.Pallet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PublishPalletsRequest}
 */
proto.fox.proto.command_control.PublishPalletsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PublishPalletsRequest;
  return proto.fox.proto.command_control.PublishPalletsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PublishPalletsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PublishPalletsRequest}
 */
proto.fox.proto.command_control.PublishPalletsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.Pallet;
      reader.readMessage(value,proto.fox.proto.command_control.Pallet.deserializeBinaryFromReader);
      msg.addPallets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PublishPalletsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PublishPalletsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PublishPalletsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishPalletsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPalletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.command_control.Pallet.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Pallet pallets = 1;
 * @return {!Array<!proto.fox.proto.command_control.Pallet>}
 */
proto.fox.proto.command_control.PublishPalletsRequest.prototype.getPalletsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.Pallet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.Pallet, 1));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.Pallet>} value
 * @return {!proto.fox.proto.command_control.PublishPalletsRequest} returns this
*/
proto.fox.proto.command_control.PublishPalletsRequest.prototype.setPalletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.command_control.Pallet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.Pallet}
 */
proto.fox.proto.command_control.PublishPalletsRequest.prototype.addPallets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.command_control.Pallet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PublishPalletsRequest} returns this
 */
proto.fox.proto.command_control.PublishPalletsRequest.prototype.clearPalletsList = function() {
  return this.setPalletsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PublishPalletsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PublishPalletsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PublishPalletsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishPalletsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PublishPalletsResponse}
 */
proto.fox.proto.command_control.PublishPalletsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PublishPalletsResponse;
  return proto.fox.proto.command_control.PublishPalletsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PublishPalletsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PublishPalletsResponse}
 */
proto.fox.proto.command_control.PublishPalletsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PublishPalletsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PublishPalletsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PublishPalletsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishPalletsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.SubscribePickPlaceZonesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.SubscribePickPlaceZonesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesRequest}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.SubscribePickPlaceZonesRequest;
  return proto.fox.proto.command_control.SubscribePickPlaceZonesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.SubscribePickPlaceZonesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesRequest}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.SubscribePickPlaceZonesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.SubscribePickPlaceZonesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickPlaceZonesList: jspb.Message.toObjectList(msg.getPickPlaceZonesList(),
    detection_proto_detection_pb.PickPlaceZone.toObject, includeInstance),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    detection_proto_detection_pb.TrackedTag.toObject, includeInstance),
    readinessInfosList: jspb.Message.toObjectList(msg.getReadinessInfosList(),
    proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.toObject, includeInstance),
    noGoZonesList: jspb.Message.toObjectList(msg.getNoGoZonesList(),
    detection_proto_detection_pb.NoGoZone.toObject, includeInstance),
    workspaceBoundariesList: jspb.Message.toObjectList(msg.getWorkspaceBoundariesList(),
    geometry_proto_geometry_pb.Quad2f.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.SubscribePickPlaceZonesResponse;
  return proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new detection_proto_detection_pb.PickPlaceZone;
      reader.readMessage(value,detection_proto_detection_pb.PickPlaceZone.deserializeBinaryFromReader);
      msg.addPickPlaceZones(value);
      break;
    case 2:
      var value = new detection_proto_detection_pb.TrackedTag;
      reader.readMessage(value,detection_proto_detection_pb.TrackedTag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 3:
      var value = new proto.fox.proto.command_control.PickPlaceZoneReadinessInfo;
      reader.readMessage(value,proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.deserializeBinaryFromReader);
      msg.addReadinessInfos(value);
      break;
    case 4:
      var value = new detection_proto_detection_pb.NoGoZone;
      reader.readMessage(value,detection_proto_detection_pb.NoGoZone.deserializeBinaryFromReader);
      msg.addNoGoZones(value);
      break;
    case 5:
      var value = new geometry_proto_geometry_pb.Quad2f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad2f.deserializeBinaryFromReader);
      msg.addWorkspaceBoundaries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickPlaceZonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      detection_proto_detection_pb.PickPlaceZone.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      detection_proto_detection_pb.TrackedTag.serializeBinaryToWriter
    );
  }
  f = message.getReadinessInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.serializeBinaryToWriter
    );
  }
  f = message.getNoGoZonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      detection_proto_detection_pb.NoGoZone.serializeBinaryToWriter
    );
  }
  f = message.getWorkspaceBoundariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      geometry_proto_geometry_pb.Quad2f.serializeBinaryToWriter
    );
  }
};


/**
 * repeated fox.proto.PickPlaceZone pick_place_zones = 1;
 * @return {!Array<!proto.fox.proto.PickPlaceZone>}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.getPickPlaceZonesList = function() {
  return /** @type{!Array<!proto.fox.proto.PickPlaceZone>} */ (
    jspb.Message.getRepeatedWrapperField(this, detection_proto_detection_pb.PickPlaceZone, 1));
};


/**
 * @param {!Array<!proto.fox.proto.PickPlaceZone>} value
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} returns this
*/
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.setPickPlaceZonesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.PickPlaceZone=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PickPlaceZone}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.addPickPlaceZones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.PickPlaceZone, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} returns this
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.clearPickPlaceZonesList = function() {
  return this.setPickPlaceZonesList([]);
};


/**
 * repeated fox.proto.TrackedTag tags = 2;
 * @return {!Array<!proto.fox.proto.TrackedTag>}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.fox.proto.TrackedTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, detection_proto_detection_pb.TrackedTag, 2));
};


/**
 * @param {!Array<!proto.fox.proto.TrackedTag>} value
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} returns this
*/
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.TrackedTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TrackedTag}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.TrackedTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} returns this
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated PickPlaceZoneReadinessInfo readiness_infos = 3;
 * @return {!Array<!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo>}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.getReadinessInfosList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.PickPlaceZoneReadinessInfo, 3));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo>} value
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} returns this
*/
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.setReadinessInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.addReadinessInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.command_control.PickPlaceZoneReadinessInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} returns this
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.clearReadinessInfosList = function() {
  return this.setReadinessInfosList([]);
};


/**
 * repeated fox.proto.NoGoZone no_go_zones = 4;
 * @return {!Array<!proto.fox.proto.NoGoZone>}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.getNoGoZonesList = function() {
  return /** @type{!Array<!proto.fox.proto.NoGoZone>} */ (
    jspb.Message.getRepeatedWrapperField(this, detection_proto_detection_pb.NoGoZone, 4));
};


/**
 * @param {!Array<!proto.fox.proto.NoGoZone>} value
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} returns this
*/
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.setNoGoZonesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.fox.proto.NoGoZone=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.NoGoZone}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.addNoGoZones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.fox.proto.NoGoZone, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} returns this
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.clearNoGoZonesList = function() {
  return this.setNoGoZonesList([]);
};


/**
 * repeated fox.proto.Quad2f workspace_boundaries = 5;
 * @return {!Array<!proto.fox.proto.Quad2f>}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.getWorkspaceBoundariesList = function() {
  return /** @type{!Array<!proto.fox.proto.Quad2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_geometry_pb.Quad2f, 5));
};


/**
 * @param {!Array<!proto.fox.proto.Quad2f>} value
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} returns this
*/
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.setWorkspaceBoundariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.fox.proto.Quad2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Quad2f}
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.addWorkspaceBoundaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.fox.proto.Quad2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.SubscribePickPlaceZonesResponse} returns this
 */
proto.fox.proto.command_control.SubscribePickPlaceZonesResponse.prototype.clearWorkspaceBoundariesList = function() {
  return this.setWorkspaceBoundariesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PublishPickPlaceZonesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickPlaceZonesList: jspb.Message.toObjectList(msg.getPickPlaceZonesList(),
    detection_proto_detection_pb.PickPlaceZone.toObject, includeInstance),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    detection_proto_detection_pb.TrackedTag.toObject, includeInstance),
    readinessInfosList: jspb.Message.toObjectList(msg.getReadinessInfosList(),
    proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.toObject, includeInstance),
    noGoZonesList: jspb.Message.toObjectList(msg.getNoGoZonesList(),
    detection_proto_detection_pb.NoGoZone.toObject, includeInstance),
    workspaceBoundariesList: jspb.Message.toObjectList(msg.getWorkspaceBoundariesList(),
    geometry_proto_geometry_pb.Quad2f.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PublishPickPlaceZonesRequest;
  return proto.fox.proto.command_control.PublishPickPlaceZonesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new detection_proto_detection_pb.PickPlaceZone;
      reader.readMessage(value,detection_proto_detection_pb.PickPlaceZone.deserializeBinaryFromReader);
      msg.addPickPlaceZones(value);
      break;
    case 2:
      var value = new detection_proto_detection_pb.TrackedTag;
      reader.readMessage(value,detection_proto_detection_pb.TrackedTag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 3:
      var value = new proto.fox.proto.command_control.PickPlaceZoneReadinessInfo;
      reader.readMessage(value,proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.deserializeBinaryFromReader);
      msg.addReadinessInfos(value);
      break;
    case 4:
      var value = new detection_proto_detection_pb.NoGoZone;
      reader.readMessage(value,detection_proto_detection_pb.NoGoZone.deserializeBinaryFromReader);
      msg.addNoGoZones(value);
      break;
    case 5:
      var value = new geometry_proto_geometry_pb.Quad2f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad2f.deserializeBinaryFromReader);
      msg.addWorkspaceBoundaries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PublishPickPlaceZonesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickPlaceZonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      detection_proto_detection_pb.PickPlaceZone.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      detection_proto_detection_pb.TrackedTag.serializeBinaryToWriter
    );
  }
  f = message.getReadinessInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.serializeBinaryToWriter
    );
  }
  f = message.getNoGoZonesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      detection_proto_detection_pb.NoGoZone.serializeBinaryToWriter
    );
  }
  f = message.getWorkspaceBoundariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      geometry_proto_geometry_pb.Quad2f.serializeBinaryToWriter
    );
  }
};


/**
 * repeated fox.proto.PickPlaceZone pick_place_zones = 1;
 * @return {!Array<!proto.fox.proto.PickPlaceZone>}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.getPickPlaceZonesList = function() {
  return /** @type{!Array<!proto.fox.proto.PickPlaceZone>} */ (
    jspb.Message.getRepeatedWrapperField(this, detection_proto_detection_pb.PickPlaceZone, 1));
};


/**
 * @param {!Array<!proto.fox.proto.PickPlaceZone>} value
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} returns this
*/
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.setPickPlaceZonesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.PickPlaceZone=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PickPlaceZone}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.addPickPlaceZones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.PickPlaceZone, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} returns this
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.clearPickPlaceZonesList = function() {
  return this.setPickPlaceZonesList([]);
};


/**
 * repeated fox.proto.TrackedTag tags = 2;
 * @return {!Array<!proto.fox.proto.TrackedTag>}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.fox.proto.TrackedTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, detection_proto_detection_pb.TrackedTag, 2));
};


/**
 * @param {!Array<!proto.fox.proto.TrackedTag>} value
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} returns this
*/
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.TrackedTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TrackedTag}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.TrackedTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} returns this
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated PickPlaceZoneReadinessInfo readiness_infos = 3;
 * @return {!Array<!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo>}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.getReadinessInfosList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.PickPlaceZoneReadinessInfo, 3));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo>} value
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} returns this
*/
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.setReadinessInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.addReadinessInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.command_control.PickPlaceZoneReadinessInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} returns this
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.clearReadinessInfosList = function() {
  return this.setReadinessInfosList([]);
};


/**
 * repeated fox.proto.NoGoZone no_go_zones = 4;
 * @return {!Array<!proto.fox.proto.NoGoZone>}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.getNoGoZonesList = function() {
  return /** @type{!Array<!proto.fox.proto.NoGoZone>} */ (
    jspb.Message.getRepeatedWrapperField(this, detection_proto_detection_pb.NoGoZone, 4));
};


/**
 * @param {!Array<!proto.fox.proto.NoGoZone>} value
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} returns this
*/
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.setNoGoZonesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.fox.proto.NoGoZone=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.NoGoZone}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.addNoGoZones = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.fox.proto.NoGoZone, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} returns this
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.clearNoGoZonesList = function() {
  return this.setNoGoZonesList([]);
};


/**
 * repeated fox.proto.Quad2f workspace_boundaries = 5;
 * @return {!Array<!proto.fox.proto.Quad2f>}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.getWorkspaceBoundariesList = function() {
  return /** @type{!Array<!proto.fox.proto.Quad2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_geometry_pb.Quad2f, 5));
};


/**
 * @param {!Array<!proto.fox.proto.Quad2f>} value
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} returns this
*/
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.setWorkspaceBoundariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.fox.proto.Quad2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Quad2f}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.addWorkspaceBoundaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.fox.proto.Quad2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesRequest} returns this
 */
proto.fox.proto.command_control.PublishPickPlaceZonesRequest.prototype.clearWorkspaceBoundariesList = function() {
  return this.setWorkspaceBoundariesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PublishPickPlaceZonesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PublishPickPlaceZonesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishPickPlaceZonesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesResponse}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PublishPickPlaceZonesResponse;
  return proto.fox.proto.command_control.PublishPickPlaceZonesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PublishPickPlaceZonesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PublishPickPlaceZonesResponse}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PublishPickPlaceZonesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PublishPickPlaceZonesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PublishPickPlaceZonesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishPickPlaceZonesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.LidarReturns.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.LidarReturns.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.LidarReturns.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.LidarReturns} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.LidarReturns.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mapTSensor: (f = msg.getMapTSensor()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    pointsMapList: jspb.Message.toObjectList(msg.getPointsMapList(),
    geometry_proto_transform_pb.Vector3f.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.LidarReturns}
 */
proto.fox.proto.command_control.LidarReturns.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.LidarReturns;
  return proto.fox.proto.command_control.LidarReturns.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.LidarReturns} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.LidarReturns}
 */
proto.fox.proto.command_control.LidarReturns.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setMapTSensor(value);
      break;
    case 3:
      var value = new geometry_proto_transform_pb.Vector3f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector3f.deserializeBinaryFromReader);
      msg.addPointsMap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.LidarReturns.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.LidarReturns.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.LidarReturns} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.LidarReturns.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMapTSensor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getPointsMapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      geometry_proto_transform_pb.Vector3f.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.fox.proto.command_control.LidarReturns.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.LidarReturns} returns this
 */
proto.fox.proto.command_control.LidarReturns.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional fox.proto.Transform3f map_t_sensor = 2;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.command_control.LidarReturns.prototype.getMapTSensor = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 2));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.command_control.LidarReturns} returns this
*/
proto.fox.proto.command_control.LidarReturns.prototype.setMapTSensor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.LidarReturns} returns this
 */
proto.fox.proto.command_control.LidarReturns.prototype.clearMapTSensor = function() {
  return this.setMapTSensor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.LidarReturns.prototype.hasMapTSensor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated fox.proto.Vector3f points_map = 3;
 * @return {!Array<!proto.fox.proto.Vector3f>}
 */
proto.fox.proto.command_control.LidarReturns.prototype.getPointsMapList = function() {
  return /** @type{!Array<!proto.fox.proto.Vector3f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Vector3f, 3));
};


/**
 * @param {!Array<!proto.fox.proto.Vector3f>} value
 * @return {!proto.fox.proto.command_control.LidarReturns} returns this
*/
proto.fox.proto.command_control.LidarReturns.prototype.setPointsMapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.Vector3f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Vector3f}
 */
proto.fox.proto.command_control.LidarReturns.prototype.addPointsMap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.Vector3f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.LidarReturns} returns this
 */
proto.fox.proto.command_control.LidarReturns.prototype.clearPointsMapList = function() {
  return this.setPointsMapList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.SubscribeLidarReturnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.SubscribeLidarReturnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.SubscribeLidarReturnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeLidarReturnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.SubscribeLidarReturnsRequest}
 */
proto.fox.proto.command_control.SubscribeLidarReturnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.SubscribeLidarReturnsRequest;
  return proto.fox.proto.command_control.SubscribeLidarReturnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.SubscribeLidarReturnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.SubscribeLidarReturnsRequest}
 */
proto.fox.proto.command_control.SubscribeLidarReturnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.SubscribeLidarReturnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.SubscribeLidarReturnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.SubscribeLidarReturnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeLidarReturnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.SubscribeLidarReturnsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.SubscribeLidarReturnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.SubscribeLidarReturnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.SubscribeLidarReturnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeLidarReturnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lidarReturnsList: jspb.Message.toObjectList(msg.getLidarReturnsList(),
    proto.fox.proto.command_control.LidarReturns.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.SubscribeLidarReturnsResponse}
 */
proto.fox.proto.command_control.SubscribeLidarReturnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.SubscribeLidarReturnsResponse;
  return proto.fox.proto.command_control.SubscribeLidarReturnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.SubscribeLidarReturnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.SubscribeLidarReturnsResponse}
 */
proto.fox.proto.command_control.SubscribeLidarReturnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.LidarReturns;
      reader.readMessage(value,proto.fox.proto.command_control.LidarReturns.deserializeBinaryFromReader);
      msg.addLidarReturns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.SubscribeLidarReturnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.SubscribeLidarReturnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.SubscribeLidarReturnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeLidarReturnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLidarReturnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.command_control.LidarReturns.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LidarReturns lidar_returns = 1;
 * @return {!Array<!proto.fox.proto.command_control.LidarReturns>}
 */
proto.fox.proto.command_control.SubscribeLidarReturnsResponse.prototype.getLidarReturnsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.LidarReturns>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.LidarReturns, 1));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.LidarReturns>} value
 * @return {!proto.fox.proto.command_control.SubscribeLidarReturnsResponse} returns this
*/
proto.fox.proto.command_control.SubscribeLidarReturnsResponse.prototype.setLidarReturnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.command_control.LidarReturns=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.LidarReturns}
 */
proto.fox.proto.command_control.SubscribeLidarReturnsResponse.prototype.addLidarReturns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.command_control.LidarReturns, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.SubscribeLidarReturnsResponse} returns this
 */
proto.fox.proto.command_control.SubscribeLidarReturnsResponse.prototype.clearLidarReturnsList = function() {
  return this.setLidarReturnsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.PublishLidarReturnsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PublishLidarReturnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PublishLidarReturnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PublishLidarReturnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishLidarReturnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lidarReturnsList: jspb.Message.toObjectList(msg.getLidarReturnsList(),
    proto.fox.proto.command_control.LidarReturns.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PublishLidarReturnsRequest}
 */
proto.fox.proto.command_control.PublishLidarReturnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PublishLidarReturnsRequest;
  return proto.fox.proto.command_control.PublishLidarReturnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PublishLidarReturnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PublishLidarReturnsRequest}
 */
proto.fox.proto.command_control.PublishLidarReturnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.LidarReturns;
      reader.readMessage(value,proto.fox.proto.command_control.LidarReturns.deserializeBinaryFromReader);
      msg.addLidarReturns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PublishLidarReturnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PublishLidarReturnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PublishLidarReturnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishLidarReturnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLidarReturnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.command_control.LidarReturns.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LidarReturns lidar_returns = 1;
 * @return {!Array<!proto.fox.proto.command_control.LidarReturns>}
 */
proto.fox.proto.command_control.PublishLidarReturnsRequest.prototype.getLidarReturnsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.LidarReturns>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.LidarReturns, 1));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.LidarReturns>} value
 * @return {!proto.fox.proto.command_control.PublishLidarReturnsRequest} returns this
*/
proto.fox.proto.command_control.PublishLidarReturnsRequest.prototype.setLidarReturnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.command_control.LidarReturns=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.LidarReturns}
 */
proto.fox.proto.command_control.PublishLidarReturnsRequest.prototype.addLidarReturns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.command_control.LidarReturns, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PublishLidarReturnsRequest} returns this
 */
proto.fox.proto.command_control.PublishLidarReturnsRequest.prototype.clearLidarReturnsList = function() {
  return this.setLidarReturnsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PublishLidarReturnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PublishLidarReturnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PublishLidarReturnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishLidarReturnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PublishLidarReturnsResponse}
 */
proto.fox.proto.command_control.PublishLidarReturnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PublishLidarReturnsResponse;
  return proto.fox.proto.command_control.PublishLidarReturnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PublishLidarReturnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PublishLidarReturnsResponse}
 */
proto.fox.proto.command_control.PublishLidarReturnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PublishLidarReturnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PublishLidarReturnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PublishLidarReturnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PublishLidarReturnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.PushGoalRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PushGoalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PushGoalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PushGoalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PushGoalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    goal: (f = msg.getGoal()) && proto.fox.proto.command_control.Goal.toObject(includeInstance, f),
    mutationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    trailerMetadatasList: jspb.Message.toObjectList(msg.getTrailerMetadatasList(),
    proto.fox.proto.command_control.TrailerMetadata.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PushGoalRequest}
 */
proto.fox.proto.command_control.PushGoalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PushGoalRequest;
  return proto.fox.proto.command_control.PushGoalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PushGoalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PushGoalRequest}
 */
proto.fox.proto.command_control.PushGoalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.Goal;
      reader.readMessage(value,proto.fox.proto.command_control.Goal.deserializeBinaryFromReader);
      msg.setGoal(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMutationId(value);
      break;
    case 3:
      var value = new proto.fox.proto.command_control.TrailerMetadata;
      reader.readMessage(value,proto.fox.proto.command_control.TrailerMetadata.deserializeBinaryFromReader);
      msg.addTrailerMetadatas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PushGoalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PushGoalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PushGoalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PushGoalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.command_control.Goal.serializeBinaryToWriter
    );
  }
  f = message.getMutationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrailerMetadatasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.command_control.TrailerMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional Goal goal = 1;
 * @return {?proto.fox.proto.command_control.Goal}
 */
proto.fox.proto.command_control.PushGoalRequest.prototype.getGoal = function() {
  return /** @type{?proto.fox.proto.command_control.Goal} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.Goal, 1));
};


/**
 * @param {?proto.fox.proto.command_control.Goal|undefined} value
 * @return {!proto.fox.proto.command_control.PushGoalRequest} returns this
*/
proto.fox.proto.command_control.PushGoalRequest.prototype.setGoal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.PushGoalRequest} returns this
 */
proto.fox.proto.command_control.PushGoalRequest.prototype.clearGoal = function() {
  return this.setGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.PushGoalRequest.prototype.hasGoal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string mutation_id = 2;
 * @return {string}
 */
proto.fox.proto.command_control.PushGoalRequest.prototype.getMutationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.PushGoalRequest} returns this
 */
proto.fox.proto.command_control.PushGoalRequest.prototype.setMutationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TrailerMetadata trailer_metadatas = 3;
 * @return {!Array<!proto.fox.proto.command_control.TrailerMetadata>}
 */
proto.fox.proto.command_control.PushGoalRequest.prototype.getTrailerMetadatasList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.TrailerMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.TrailerMetadata, 3));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.TrailerMetadata>} value
 * @return {!proto.fox.proto.command_control.PushGoalRequest} returns this
*/
proto.fox.proto.command_control.PushGoalRequest.prototype.setTrailerMetadatasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.command_control.TrailerMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.TrailerMetadata}
 */
proto.fox.proto.command_control.PushGoalRequest.prototype.addTrailerMetadatas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.command_control.TrailerMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PushGoalRequest} returns this
 */
proto.fox.proto.command_control.PushGoalRequest.prototype.clearTrailerMetadatasList = function() {
  return this.setTrailerMetadatasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.SubscribeGoalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.SubscribeGoalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.SubscribeGoalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeGoalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.SubscribeGoalRequest}
 */
proto.fox.proto.command_control.SubscribeGoalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.SubscribeGoalRequest;
  return proto.fox.proto.command_control.SubscribeGoalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.SubscribeGoalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.SubscribeGoalRequest}
 */
proto.fox.proto.command_control.SubscribeGoalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.SubscribeGoalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.SubscribeGoalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.SubscribeGoalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.SubscribeGoalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fox.proto.command_control.NextGoalRequest.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.fox.proto.command_control.NextGoalRequest.FinishedGoalCase = {
  FINISHED_GOAL_NOT_SET: 0,
  COMPLETED_GOAL_ID: 1,
  CANCELED_GOAL_ID: 2,
  ABORTED_GOAL_ID: 3
};

/**
 * @return {proto.fox.proto.command_control.NextGoalRequest.FinishedGoalCase}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.getFinishedGoalCase = function() {
  return /** @type {proto.fox.proto.command_control.NextGoalRequest.FinishedGoalCase} */(jspb.Message.computeOneofCase(this, proto.fox.proto.command_control.NextGoalRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.NextGoalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.NextGoalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.NextGoalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currentGoal: (f = msg.getCurrentGoal()) && proto.fox.proto.command_control.Goal.toObject(includeInstance, f),
    completedGoalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    canceledGoalId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    abortedGoalId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    finishedGoalError: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.NextGoalRequest}
 */
proto.fox.proto.command_control.NextGoalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.NextGoalRequest;
  return proto.fox.proto.command_control.NextGoalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.NextGoalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.NextGoalRequest}
 */
proto.fox.proto.command_control.NextGoalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 6:
      var value = new proto.fox.proto.command_control.Goal;
      reader.readMessage(value,proto.fox.proto.command_control.Goal.deserializeBinaryFromReader);
      msg.setCurrentGoal(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompletedGoalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanceledGoalId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbortedGoalId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinishedGoalError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.NextGoalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.NextGoalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.NextGoalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrentGoal();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.fox.proto.command_control.Goal.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFinishedGoalError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string run_id = 5;
 * @return {string}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.NextGoalRequest} returns this
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Goal current_goal = 6;
 * @return {?proto.fox.proto.command_control.Goal}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.getCurrentGoal = function() {
  return /** @type{?proto.fox.proto.command_control.Goal} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.Goal, 6));
};


/**
 * @param {?proto.fox.proto.command_control.Goal|undefined} value
 * @return {!proto.fox.proto.command_control.NextGoalRequest} returns this
*/
proto.fox.proto.command_control.NextGoalRequest.prototype.setCurrentGoal = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.NextGoalRequest} returns this
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.clearCurrentGoal = function() {
  return this.setCurrentGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.hasCurrentGoal = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string completed_goal_id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.getCompletedGoalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.NextGoalRequest} returns this
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.setCompletedGoalId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.fox.proto.command_control.NextGoalRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.command_control.NextGoalRequest} returns this
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.clearCompletedGoalId = function() {
  return jspb.Message.setOneofField(this, 1, proto.fox.proto.command_control.NextGoalRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.hasCompletedGoalId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string canceled_goal_id = 2;
 * @return {string}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.getCanceledGoalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.NextGoalRequest} returns this
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.setCanceledGoalId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.fox.proto.command_control.NextGoalRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.command_control.NextGoalRequest} returns this
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.clearCanceledGoalId = function() {
  return jspb.Message.setOneofField(this, 2, proto.fox.proto.command_control.NextGoalRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.hasCanceledGoalId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string aborted_goal_id = 3;
 * @return {string}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.getAbortedGoalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.NextGoalRequest} returns this
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.setAbortedGoalId = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.fox.proto.command_control.NextGoalRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.fox.proto.command_control.NextGoalRequest} returns this
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.clearAbortedGoalId = function() {
  return jspb.Message.setOneofField(this, 3, proto.fox.proto.command_control.NextGoalRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.hasAbortedGoalId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string finished_goal_error = 4;
 * @return {string}
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.getFinishedGoalError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.NextGoalRequest} returns this
 */
proto.fox.proto.command_control.NextGoalRequest.prototype.setFinishedGoalError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.NextGoalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.NextGoalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.NextGoalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.NextGoalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    goal: (f = msg.getGoal()) && proto.fox.proto.command_control.Goal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.NextGoalResponse}
 */
proto.fox.proto.command_control.NextGoalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.NextGoalResponse;
  return proto.fox.proto.command_control.NextGoalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.NextGoalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.NextGoalResponse}
 */
proto.fox.proto.command_control.NextGoalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.Goal;
      reader.readMessage(value,proto.fox.proto.command_control.Goal.deserializeBinaryFromReader);
      msg.setGoal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.NextGoalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.NextGoalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.NextGoalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.NextGoalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.command_control.Goal.serializeBinaryToWriter
    );
  }
};


/**
 * optional Goal goal = 1;
 * @return {?proto.fox.proto.command_control.Goal}
 */
proto.fox.proto.command_control.NextGoalResponse.prototype.getGoal = function() {
  return /** @type{?proto.fox.proto.command_control.Goal} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.Goal, 1));
};


/**
 * @param {?proto.fox.proto.command_control.Goal|undefined} value
 * @return {!proto.fox.proto.command_control.NextGoalResponse} returns this
*/
proto.fox.proto.command_control.NextGoalResponse.prototype.setGoal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.NextGoalResponse} returns this
 */
proto.fox.proto.command_control.NextGoalResponse.prototype.clearGoal = function() {
  return this.setGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.NextGoalResponse.prototype.hasGoal = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fox.proto.command_control.Goal.oneofGroups_ = [[3,4,6]];

/**
 * @enum {number}
 */
proto.fox.proto.command_control.Goal.GoalCase = {
  GOAL_NOT_SET: 0,
  POSE_GOAL: 3,
  OBJECT_NAVIGATION_GOAL: 4,
  PICK_PLACE_PALLETS_GOAL: 6
};

/**
 * @return {proto.fox.proto.command_control.Goal.GoalCase}
 */
proto.fox.proto.command_control.Goal.prototype.getGoalCase = function() {
  return /** @type {proto.fox.proto.command_control.Goal.GoalCase} */(jspb.Message.computeOneofCase(this, proto.fox.proto.command_control.Goal.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.Goal.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.Goal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.Goal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Goal.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: (f = msg.getStatus()) && proto.fox.proto.command_control.GoalStatus.toObject(includeInstance, f),
    poseGoal: (f = msg.getPoseGoal()) && proto.fox.proto.command_control.PoseGoal.toObject(includeInstance, f),
    objectNavigationGoal: (f = msg.getObjectNavigationGoal()) && proto.fox.proto.command_control.ObjectNavigationGoal.toObject(includeInstance, f),
    pickPlacePalletsGoal: (f = msg.getPickPlacePalletsGoal()) && proto.fox.proto.command_control.PickPlacePalletsGoal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.Goal}
 */
proto.fox.proto.command_control.Goal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.Goal;
  return proto.fox.proto.command_control.Goal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.Goal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.Goal}
 */
proto.fox.proto.command_control.Goal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = new proto.fox.proto.command_control.GoalStatus;
      reader.readMessage(value,proto.fox.proto.command_control.GoalStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 3:
      var value = new proto.fox.proto.command_control.PoseGoal;
      reader.readMessage(value,proto.fox.proto.command_control.PoseGoal.deserializeBinaryFromReader);
      msg.setPoseGoal(value);
      break;
    case 4:
      var value = new proto.fox.proto.command_control.ObjectNavigationGoal;
      reader.readMessage(value,proto.fox.proto.command_control.ObjectNavigationGoal.deserializeBinaryFromReader);
      msg.setObjectNavigationGoal(value);
      break;
    case 6:
      var value = new proto.fox.proto.command_control.PickPlacePalletsGoal;
      reader.readMessage(value,proto.fox.proto.command_control.PickPlacePalletsGoal.deserializeBinaryFromReader);
      msg.setPickPlacePalletsGoal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.Goal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.Goal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.Goal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Goal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreateTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.command_control.GoalStatus.serializeBinaryToWriter
    );
  }
  f = message.getPoseGoal();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.command_control.PoseGoal.serializeBinaryToWriter
    );
  }
  f = message.getObjectNavigationGoal();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.command_control.ObjectNavigationGoal.serializeBinaryToWriter
    );
  }
  f = message.getPickPlacePalletsGoal();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.fox.proto.command_control.PickPlacePalletsGoal.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.Goal.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.Goal} returns this
 */
proto.fox.proto.command_control.Goal.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string run_id = 7;
 * @return {string}
 */
proto.fox.proto.command_control.Goal.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.Goal} returns this
 */
proto.fox.proto.command_control.Goal.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 create_time = 5;
 * @return {number}
 */
proto.fox.proto.command_control.Goal.prototype.getCreateTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.Goal} returns this
 */
proto.fox.proto.command_control.Goal.prototype.setCreateTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 start_time = 8;
 * @return {number}
 */
proto.fox.proto.command_control.Goal.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.Goal} returns this
 */
proto.fox.proto.command_control.Goal.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional GoalStatus status = 2;
 * @return {?proto.fox.proto.command_control.GoalStatus}
 */
proto.fox.proto.command_control.Goal.prototype.getStatus = function() {
  return /** @type{?proto.fox.proto.command_control.GoalStatus} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.GoalStatus, 2));
};


/**
 * @param {?proto.fox.proto.command_control.GoalStatus|undefined} value
 * @return {!proto.fox.proto.command_control.Goal} returns this
*/
proto.fox.proto.command_control.Goal.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.Goal} returns this
 */
proto.fox.proto.command_control.Goal.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.Goal.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PoseGoal pose_goal = 3;
 * @return {?proto.fox.proto.command_control.PoseGoal}
 */
proto.fox.proto.command_control.Goal.prototype.getPoseGoal = function() {
  return /** @type{?proto.fox.proto.command_control.PoseGoal} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.PoseGoal, 3));
};


/**
 * @param {?proto.fox.proto.command_control.PoseGoal|undefined} value
 * @return {!proto.fox.proto.command_control.Goal} returns this
*/
proto.fox.proto.command_control.Goal.prototype.setPoseGoal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.fox.proto.command_control.Goal.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.Goal} returns this
 */
proto.fox.proto.command_control.Goal.prototype.clearPoseGoal = function() {
  return this.setPoseGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.Goal.prototype.hasPoseGoal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ObjectNavigationGoal object_navigation_goal = 4;
 * @return {?proto.fox.proto.command_control.ObjectNavigationGoal}
 */
proto.fox.proto.command_control.Goal.prototype.getObjectNavigationGoal = function() {
  return /** @type{?proto.fox.proto.command_control.ObjectNavigationGoal} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.ObjectNavigationGoal, 4));
};


/**
 * @param {?proto.fox.proto.command_control.ObjectNavigationGoal|undefined} value
 * @return {!proto.fox.proto.command_control.Goal} returns this
*/
proto.fox.proto.command_control.Goal.prototype.setObjectNavigationGoal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.fox.proto.command_control.Goal.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.Goal} returns this
 */
proto.fox.proto.command_control.Goal.prototype.clearObjectNavigationGoal = function() {
  return this.setObjectNavigationGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.Goal.prototype.hasObjectNavigationGoal = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PickPlacePalletsGoal pick_place_pallets_goal = 6;
 * @return {?proto.fox.proto.command_control.PickPlacePalletsGoal}
 */
proto.fox.proto.command_control.Goal.prototype.getPickPlacePalletsGoal = function() {
  return /** @type{?proto.fox.proto.command_control.PickPlacePalletsGoal} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.PickPlacePalletsGoal, 6));
};


/**
 * @param {?proto.fox.proto.command_control.PickPlacePalletsGoal|undefined} value
 * @return {!proto.fox.proto.command_control.Goal} returns this
*/
proto.fox.proto.command_control.Goal.prototype.setPickPlacePalletsGoal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.fox.proto.command_control.Goal.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.Goal} returns this
 */
proto.fox.proto.command_control.Goal.prototype.clearPickPlacePalletsGoal = function() {
  return this.setPickPlacePalletsGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.Goal.prototype.hasPickPlacePalletsGoal = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    unmetPickConditionsList: jspb.Message.toObjectList(msg.getUnmetPickConditionsList(),
    detection_proto_detection_pb.ZoneCondition.toObject, includeInstance),
    unmetPlaceConditionsList: jspb.Message.toObjectList(msg.getUnmetPlaceConditionsList(),
    detection_proto_detection_pb.ZoneCondition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo}
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PickPlaceZoneReadinessInfo;
  return proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo}
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setZoneId(value);
      break;
    case 2:
      var value = new detection_proto_detection_pb.ZoneCondition;
      reader.readMessage(value,detection_proto_detection_pb.ZoneCondition.deserializeBinaryFromReader);
      msg.addUnmetPickConditions(value);
      break;
    case 3:
      var value = new detection_proto_detection_pb.ZoneCondition;
      reader.readMessage(value,detection_proto_detection_pb.ZoneCondition.deserializeBinaryFromReader);
      msg.addUnmetPlaceConditions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUnmetPickConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      detection_proto_detection_pb.ZoneCondition.serializeBinaryToWriter
    );
  }
  f = message.getUnmetPlaceConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      detection_proto_detection_pb.ZoneCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 zone_id = 1;
 * @return {number}
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo} returns this
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated fox.proto.ZoneCondition unmet_pick_conditions = 2;
 * @return {!Array<!proto.fox.proto.ZoneCondition>}
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.getUnmetPickConditionsList = function() {
  return /** @type{!Array<!proto.fox.proto.ZoneCondition>} */ (
    jspb.Message.getRepeatedWrapperField(this, detection_proto_detection_pb.ZoneCondition, 2));
};


/**
 * @param {!Array<!proto.fox.proto.ZoneCondition>} value
 * @return {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo} returns this
*/
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.setUnmetPickConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.ZoneCondition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.ZoneCondition}
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.addUnmetPickConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.ZoneCondition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo} returns this
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.clearUnmetPickConditionsList = function() {
  return this.setUnmetPickConditionsList([]);
};


/**
 * repeated fox.proto.ZoneCondition unmet_place_conditions = 3;
 * @return {!Array<!proto.fox.proto.ZoneCondition>}
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.getUnmetPlaceConditionsList = function() {
  return /** @type{!Array<!proto.fox.proto.ZoneCondition>} */ (
    jspb.Message.getRepeatedWrapperField(this, detection_proto_detection_pb.ZoneCondition, 3));
};


/**
 * @param {!Array<!proto.fox.proto.ZoneCondition>} value
 * @return {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo} returns this
*/
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.setUnmetPlaceConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.ZoneCondition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.ZoneCondition}
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.addUnmetPlaceConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.ZoneCondition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.PickPlaceZoneReadinessInfo} returns this
 */
proto.fox.proto.command_control.PickPlaceZoneReadinessInfo.prototype.clearUnmetPlaceConditionsList = function() {
  return this.setUnmetPlaceConditionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.GoalStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.GoalStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.GoalStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GoalStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uiState: jspb.Message.getFieldWithDefault(msg, 2, 0),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.GoalStatus}
 */
proto.fox.proto.command_control.GoalStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.GoalStatus;
  return proto.fox.proto.command_control.GoalStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.GoalStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.GoalStatus}
 */
proto.fox.proto.command_control.GoalStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.command_control.GoalStatus.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.command_control.GoalStatus.UIState} */ (reader.readEnum());
      msg.setUiState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.GoalStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.GoalStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.GoalStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GoalStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUiState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.command_control.GoalStatus.State = {
  UNKNOWN: 0,
  PENDING: 1,
  EXECUTING: 2,
  COMPLETED: 3,
  CANCELLED: 4,
  ABORTED: 5
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.GoalStatus.UIState = {
  UI_STATE_UNKNOWN: 0,
  UI_STATE_SUBMITTED: 1,
  UI_STATE_CANCELLED: 2
};

/**
 * optional State state = 1;
 * @return {!proto.fox.proto.command_control.GoalStatus.State}
 */
proto.fox.proto.command_control.GoalStatus.prototype.getState = function() {
  return /** @type {!proto.fox.proto.command_control.GoalStatus.State} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.command_control.GoalStatus.State} value
 * @return {!proto.fox.proto.command_control.GoalStatus} returns this
 */
proto.fox.proto.command_control.GoalStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional UIState ui_state = 2;
 * @return {!proto.fox.proto.command_control.GoalStatus.UIState}
 */
proto.fox.proto.command_control.GoalStatus.prototype.getUiState = function() {
  return /** @type {!proto.fox.proto.command_control.GoalStatus.UIState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.command_control.GoalStatus.UIState} value
 * @return {!proto.fox.proto.command_control.GoalStatus} returns this
 */
proto.fox.proto.command_control.GoalStatus.prototype.setUiState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string error_message = 3;
 * @return {string}
 */
proto.fox.proto.command_control.GoalStatus.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.GoalStatus} returns this
 */
proto.fox.proto.command_control.GoalStatus.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PoseGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PoseGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PoseGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PoseGoal.toObject = function(includeInstance, msg) {
  var f, obj = {
    destination: (f = msg.getDestination()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PoseGoal}
 */
proto.fox.proto.command_control.PoseGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PoseGoal;
  return proto.fox.proto.command_control.PoseGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PoseGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PoseGoal}
 */
proto.fox.proto.command_control.PoseGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PoseGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PoseGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PoseGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PoseGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
};


/**
 * optional fox.proto.Transform2f destination = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.command_control.PoseGoal.prototype.getDestination = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.command_control.PoseGoal} returns this
*/
proto.fox.proto.command_control.PoseGoal.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.PoseGoal} returns this
 */
proto.fox.proto.command_control.PoseGoal.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.PoseGoal.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.ObjectNavigationGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.ObjectNavigationGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.ObjectNavigationGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ObjectNavigationGoal.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    objectType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.ObjectNavigationGoal}
 */
proto.fox.proto.command_control.ObjectNavigationGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.ObjectNavigationGoal;
  return proto.fox.proto.command_control.ObjectNavigationGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.ObjectNavigationGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.ObjectNavigationGoal}
 */
proto.fox.proto.command_control.ObjectNavigationGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setObjectId(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.command_control.ObjectNavigationGoal.ObjectType} */ (reader.readEnum());
      msg.setObjectType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.ObjectNavigationGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.ObjectNavigationGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.ObjectNavigationGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.ObjectNavigationGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getObjectType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.command_control.ObjectNavigationGoal.ObjectType = {
  UNKNOWN: 0,
  PALLET: 1
};

/**
 * optional int32 object_id = 1;
 * @return {number}
 */
proto.fox.proto.command_control.ObjectNavigationGoal.prototype.getObjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.ObjectNavigationGoal} returns this
 */
proto.fox.proto.command_control.ObjectNavigationGoal.prototype.setObjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ObjectType object_type = 3;
 * @return {!proto.fox.proto.command_control.ObjectNavigationGoal.ObjectType}
 */
proto.fox.proto.command_control.ObjectNavigationGoal.prototype.getObjectType = function() {
  return /** @type {!proto.fox.proto.command_control.ObjectNavigationGoal.ObjectType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.command_control.ObjectNavigationGoal.ObjectType} value
 * @return {!proto.fox.proto.command_control.ObjectNavigationGoal} returns this
 */
proto.fox.proto.command_control.ObjectNavigationGoal.prototype.setObjectType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.PickPlacePalletsGoal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.PickPlacePalletsGoal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.toObject = function(includeInstance, msg) {
  var f, obj = {
    pickZoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    placeZoneId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subGoal: jspb.Message.getFieldWithDefault(msg, 3, 0),
    palletHeight: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pickZoneName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    placeZoneName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.PickPlacePalletsGoal}
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.PickPlacePalletsGoal;
  return proto.fox.proto.command_control.PickPlacePalletsGoal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.PickPlacePalletsGoal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.PickPlacePalletsGoal}
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPickZoneId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlaceZoneId(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.command_control.PickPlacePalletsGoal.SubGoal} */ (reader.readEnum());
      msg.setSubGoal(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.command_control.PalletHeight} */ (reader.readEnum());
      msg.setPalletHeight(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickZoneName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceZoneName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.PickPlacePalletsGoal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.PickPlacePalletsGoal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPickZoneId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlaceZoneId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSubGoal();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPalletHeight();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPickZoneName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlaceZoneName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.SubGoal = {
  NONE: 0,
  NAVIGATE_TO_PICK: 1,
  PICK_PALLET: 2,
  NAVIGATE_TO_PLACE: 3,
  PLACE_PALLET: 4
};

/**
 * optional int32 pick_zone_id = 1;
 * @return {number}
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.getPickZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.PickPlacePalletsGoal} returns this
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.setPickZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 place_zone_id = 2;
 * @return {number}
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.getPlaceZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.PickPlacePalletsGoal} returns this
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.setPlaceZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SubGoal sub_goal = 3;
 * @return {!proto.fox.proto.command_control.PickPlacePalletsGoal.SubGoal}
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.getSubGoal = function() {
  return /** @type {!proto.fox.proto.command_control.PickPlacePalletsGoal.SubGoal} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.command_control.PickPlacePalletsGoal.SubGoal} value
 * @return {!proto.fox.proto.command_control.PickPlacePalletsGoal} returns this
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.setSubGoal = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional PalletHeight pallet_height = 4;
 * @return {!proto.fox.proto.command_control.PalletHeight}
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.getPalletHeight = function() {
  return /** @type {!proto.fox.proto.command_control.PalletHeight} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.command_control.PalletHeight} value
 * @return {!proto.fox.proto.command_control.PickPlacePalletsGoal} returns this
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.setPalletHeight = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string pick_zone_name = 5;
 * @return {string}
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.getPickZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.PickPlacePalletsGoal} returns this
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.setPickZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string place_zone_name = 6;
 * @return {string}
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.getPlaceZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.PickPlacePalletsGoal} returns this
 */
proto.fox.proto.command_control.PickPlacePalletsGoal.prototype.setPlaceZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.TrailerMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.TrailerMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.TrailerMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    zoneId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    commodityType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    trailerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    palletStack: jspb.Message.getFieldWithDefault(msg, 4, 0),
    palletType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    numPallets: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.TrailerMetadata}
 */
proto.fox.proto.command_control.TrailerMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.TrailerMetadata;
  return proto.fox.proto.command_control.TrailerMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.TrailerMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.TrailerMetadata}
 */
proto.fox.proto.command_control.TrailerMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoalId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setZoneId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommodityType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.command_control.PalletStack} */ (reader.readEnum());
      msg.setPalletStack(value);
      break;
    case 5:
      var value = /** @type {!proto.fox.proto.command_control.PalletType} */ (reader.readEnum());
      msg.setPalletType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumPallets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.TrailerMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.TrailerMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.TrailerMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCommodityType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPalletStack();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPalletType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getNumPallets();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string goal_id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.getGoalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.TrailerMetadata} returns this
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.setGoalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 zone_id = 2;
 * @return {number}
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.TrailerMetadata} returns this
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string commodity_type = 7;
 * @return {string}
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.getCommodityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.TrailerMetadata} returns this
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.setCommodityType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_id = 8;
 * @return {string}
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.TrailerMetadata} returns this
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string trailer_id = 3;
 * @return {string}
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.TrailerMetadata} returns this
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PalletStack pallet_stack = 4;
 * @return {!proto.fox.proto.command_control.PalletStack}
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.getPalletStack = function() {
  return /** @type {!proto.fox.proto.command_control.PalletStack} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.command_control.PalletStack} value
 * @return {!proto.fox.proto.command_control.TrailerMetadata} returns this
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.setPalletStack = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PalletType pallet_type = 5;
 * @return {!proto.fox.proto.command_control.PalletType}
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.getPalletType = function() {
  return /** @type {!proto.fox.proto.command_control.PalletType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fox.proto.command_control.PalletType} value
 * @return {!proto.fox.proto.command_control.TrailerMetadata} returns this
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.setPalletType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int32 num_pallets = 6;
 * @return {number}
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.getNumPallets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.TrailerMetadata} returns this
 */
proto.fox.proto.command_control.TrailerMetadata.prototype.setNumPallets = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.WiFiInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.WiFiInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.WiFiInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    interfaceName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ssid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    frequencyMhz: jspb.Message.getFieldWithDefault(msg, 4, 0),
    connectedDuration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    inactiveDuration: jspb.Message.getFieldWithDefault(msg, 6, 0),
    signalDbm: jspb.Message.getFieldWithDefault(msg, 7, 0),
    transmitRetries: jspb.Message.getFieldWithDefault(msg, 8, 0),
    transmitFailures: jspb.Message.getFieldWithDefault(msg, 9, 0),
    beaconLosses: jspb.Message.getFieldWithDefault(msg, 10, 0),
    ip4: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.WiFiInfo}
 */
proto.fox.proto.command_control.WiFiInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.WiFiInfo;
  return proto.fox.proto.command_control.WiFiInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.WiFiInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.WiFiInfo}
 */
proto.fox.proto.command_control.WiFiInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterfaceName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrequencyMhz(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConnectedDuration(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInactiveDuration(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSignalDbm(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTransmitRetries(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTransmitFailures(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBeaconLosses(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIp4(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.WiFiInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.WiFiInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.WiFiInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInterfaceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSsid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFrequencyMhz();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getConnectedDuration();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getInactiveDuration();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSignalDbm();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getTransmitRetries();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTransmitFailures();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getBeaconLosses();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getIp4();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.WiFiInfo} returns this
 */
proto.fox.proto.command_control.WiFiInfo.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string interface_name = 2;
 * @return {string}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.getInterfaceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.WiFiInfo} returns this
 */
proto.fox.proto.command_control.WiFiInfo.prototype.setInterfaceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ssid = 3;
 * @return {string}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.getSsid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.WiFiInfo} returns this
 */
proto.fox.proto.command_control.WiFiInfo.prototype.setSsid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 frequency_mhz = 4;
 * @return {number}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.getFrequencyMhz = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.WiFiInfo} returns this
 */
proto.fox.proto.command_control.WiFiInfo.prototype.setFrequencyMhz = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 connected_duration = 5;
 * @return {number}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.getConnectedDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.WiFiInfo} returns this
 */
proto.fox.proto.command_control.WiFiInfo.prototype.setConnectedDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 inactive_duration = 6;
 * @return {number}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.getInactiveDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.WiFiInfo} returns this
 */
proto.fox.proto.command_control.WiFiInfo.prototype.setInactiveDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 signal_dbm = 7;
 * @return {number}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.getSignalDbm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.WiFiInfo} returns this
 */
proto.fox.proto.command_control.WiFiInfo.prototype.setSignalDbm = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 transmit_retries = 8;
 * @return {number}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.getTransmitRetries = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.WiFiInfo} returns this
 */
proto.fox.proto.command_control.WiFiInfo.prototype.setTransmitRetries = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 transmit_failures = 9;
 * @return {number}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.getTransmitFailures = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.WiFiInfo} returns this
 */
proto.fox.proto.command_control.WiFiInfo.prototype.setTransmitFailures = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 beacon_losses = 10;
 * @return {number}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.getBeaconLosses = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.WiFiInfo} returns this
 */
proto.fox.proto.command_control.WiFiInfo.prototype.setBeaconLosses = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string ip4 = 11;
 * @return {string}
 */
proto.fox.proto.command_control.WiFiInfo.prototype.getIp4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.WiFiInfo} returns this
 */
proto.fox.proto.command_control.WiFiInfo.prototype.setIp4 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.DiskInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.DiskInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.DiskInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.DiskInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    diskUsedKb: jspb.Message.getFieldWithDefault(msg, 2, 0),
    diskAvailableKb: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.DiskInfo}
 */
proto.fox.proto.command_control.DiskInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.DiskInfo;
  return proto.fox.proto.command_control.DiskInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.DiskInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.DiskInfo}
 */
proto.fox.proto.command_control.DiskInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDiskUsedKb(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDiskAvailableKb(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.DiskInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.DiskInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.DiskInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.DiskInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDiskUsedKb();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDiskAvailableKb();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.fox.proto.command_control.DiskInfo.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.DiskInfo} returns this
 */
proto.fox.proto.command_control.DiskInfo.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 disk_used_kb = 2;
 * @return {number}
 */
proto.fox.proto.command_control.DiskInfo.prototype.getDiskUsedKb = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.DiskInfo} returns this
 */
proto.fox.proto.command_control.DiskInfo.prototype.setDiskUsedKb = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 disk_available_kb = 3;
 * @return {number}
 */
proto.fox.proto.command_control.DiskInfo.prototype.getDiskAvailableKb = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.DiskInfo} returns this
 */
proto.fox.proto.command_control.DiskInfo.prototype.setDiskAvailableKb = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.CaptureDebugSnapshotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.CaptureDebugSnapshotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.CaptureDebugSnapshotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.CaptureDebugSnapshotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.CaptureDebugSnapshotRequest}
 */
proto.fox.proto.command_control.CaptureDebugSnapshotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.CaptureDebugSnapshotRequest;
  return proto.fox.proto.command_control.CaptureDebugSnapshotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.CaptureDebugSnapshotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.CaptureDebugSnapshotRequest}
 */
proto.fox.proto.command_control.CaptureDebugSnapshotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.CaptureDebugSnapshotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.CaptureDebugSnapshotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.CaptureDebugSnapshotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.CaptureDebugSnapshotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.DebugSnapshotInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.DebugSnapshotInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.DebugSnapshotInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.DebugSnapshotInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    syncStatus: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.DebugSnapshotInfo}
 */
proto.fox.proto.command_control.DebugSnapshotInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.DebugSnapshotInfo;
  return proto.fox.proto.command_control.DebugSnapshotInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.DebugSnapshotInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.DebugSnapshotInfo}
 */
proto.fox.proto.command_control.DebugSnapshotInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.command_control.SyncStatus} */ (reader.readEnum());
      msg.setSyncStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.DebugSnapshotInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.DebugSnapshotInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.DebugSnapshotInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.DebugSnapshotInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSyncStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.fox.proto.command_control.DebugSnapshotInfo.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.DebugSnapshotInfo} returns this
 */
proto.fox.proto.command_control.DebugSnapshotInfo.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.fox.proto.command_control.DebugSnapshotInfo.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.DebugSnapshotInfo} returns this
 */
proto.fox.proto.command_control.DebugSnapshotInfo.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SyncStatus sync_status = 3;
 * @return {!proto.fox.proto.command_control.SyncStatus}
 */
proto.fox.proto.command_control.DebugSnapshotInfo.prototype.getSyncStatus = function() {
  return /** @type {!proto.fox.proto.command_control.SyncStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.command_control.SyncStatus} value
 * @return {!proto.fox.proto.command_control.DebugSnapshotInfo} returns this
 */
proto.fox.proto.command_control.DebugSnapshotInfo.prototype.setSyncStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.LogInUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.LogInUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.LogInUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.LogInUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgeId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.LogInUserRequest}
 */
proto.fox.proto.command_control.LogInUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.LogInUserRequest;
  return proto.fox.proto.command_control.LogInUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.LogInUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.LogInUserRequest}
 */
proto.fox.proto.command_control.LogInUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.LogInUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.LogInUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.LogInUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.LogInUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string badge_id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.LogInUserRequest.prototype.getBadgeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.LogInUserRequest} returns this
 */
proto.fox.proto.command_control.LogInUserRequest.prototype.setBadgeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.LogOutUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.LogOutUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.LogOutUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.LogOutUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.LogOutUserRequest}
 */
proto.fox.proto.command_control.LogOutUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.LogOutUserRequest;
  return proto.fox.proto.command_control.LogOutUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.LogOutUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.LogOutUserRequest}
 */
proto.fox.proto.command_control.LogOutUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.LogOutUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.LogOutUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.LogOutUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.LogOutUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.CreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.CreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.CreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.CreateUserRequest}
 */
proto.fox.proto.command_control.CreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.CreateUserRequest;
  return proto.fox.proto.command_control.CreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.CreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.CreateUserRequest}
 */
proto.fox.proto.command_control.CreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.CreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.CreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.CreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string badge_id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.getBadgeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.CreateUserRequest} returns this
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.setBadgeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.CreateUserRequest} returns this
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.CreateUserRequest} returns this
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.CreateUserRequest} returns this
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.CreateUserRequest} returns this
 */
proto.fox.proto.command_control.CreateUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.TabletUser.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.TabletUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.TabletUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.TabletUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.TabletUser}
 */
proto.fox.proto.command_control.TabletUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.TabletUser;
  return proto.fox.proto.command_control.TabletUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.TabletUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.TabletUser}
 */
proto.fox.proto.command_control.TabletUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.TabletUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.TabletUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.TabletUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.TabletUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.TabletUser.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.TabletUser} returns this
 */
proto.fox.proto.command_control.TabletUser.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.fox.proto.command_control.TabletUser.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.TabletUser} returns this
 */
proto.fox.proto.command_control.TabletUser.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.fox.proto.command_control.TabletUser.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.TabletUser} returns this
 */
proto.fox.proto.command_control.TabletUser.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.fox.proto.command_control.TabletUser.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.TabletUser} returns this
 */
proto.fox.proto.command_control.TabletUser.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.fox.proto.command_control.TabletUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.TabletUser} returns this
 */
proto.fox.proto.command_control.TabletUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.GetUserByBadgeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.GetUserByBadgeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.GetUserByBadgeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetUserByBadgeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgeId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.GetUserByBadgeRequest}
 */
proto.fox.proto.command_control.GetUserByBadgeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.GetUserByBadgeRequest;
  return proto.fox.proto.command_control.GetUserByBadgeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.GetUserByBadgeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.GetUserByBadgeRequest}
 */
proto.fox.proto.command_control.GetUserByBadgeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.GetUserByBadgeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.GetUserByBadgeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.GetUserByBadgeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetUserByBadgeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string badge_id = 1;
 * @return {string}
 */
proto.fox.proto.command_control.GetUserByBadgeRequest.prototype.getBadgeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.GetUserByBadgeRequest} returns this
 */
proto.fox.proto.command_control.GetUserByBadgeRequest.prototype.setBadgeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.GetFaultTranslationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.GetFaultTranslationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.GetFaultTranslationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetFaultTranslationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    locale: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.GetFaultTranslationsRequest}
 */
proto.fox.proto.command_control.GetFaultTranslationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.GetFaultTranslationsRequest;
  return proto.fox.proto.command_control.GetFaultTranslationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.GetFaultTranslationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.GetFaultTranslationsRequest}
 */
proto.fox.proto.command_control.GetFaultTranslationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.GetFaultTranslationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.GetFaultTranslationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.GetFaultTranslationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetFaultTranslationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string locale = 1;
 * @return {string}
 */
proto.fox.proto.command_control.GetFaultTranslationsRequest.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.GetFaultTranslationsRequest} returns this
 */
proto.fox.proto.command_control.GetFaultTranslationsRequest.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.FaultTranslations.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.FaultTranslations.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.FaultTranslations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.FaultTranslations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FaultTranslations.toObject = function(includeInstance, msg) {
  var f, obj = {
    locale: jspb.Message.getFieldWithDefault(msg, 1, ""),
    faultTranslationsList: jspb.Message.toObjectList(msg.getFaultTranslationsList(),
    proto.fox.proto.command_control.FaultTranslation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.FaultTranslations}
 */
proto.fox.proto.command_control.FaultTranslations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.FaultTranslations;
  return proto.fox.proto.command_control.FaultTranslations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.FaultTranslations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.FaultTranslations}
 */
proto.fox.proto.command_control.FaultTranslations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 2:
      var value = new proto.fox.proto.command_control.FaultTranslation;
      reader.readMessage(value,proto.fox.proto.command_control.FaultTranslation.deserializeBinaryFromReader);
      msg.addFaultTranslations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.FaultTranslations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.FaultTranslations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.FaultTranslations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FaultTranslations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFaultTranslationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.command_control.FaultTranslation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string locale = 1;
 * @return {string}
 */
proto.fox.proto.command_control.FaultTranslations.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.FaultTranslations} returns this
 */
proto.fox.proto.command_control.FaultTranslations.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FaultTranslation fault_translations = 2;
 * @return {!Array<!proto.fox.proto.command_control.FaultTranslation>}
 */
proto.fox.proto.command_control.FaultTranslations.prototype.getFaultTranslationsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.FaultTranslation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.FaultTranslation, 2));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.FaultTranslation>} value
 * @return {!proto.fox.proto.command_control.FaultTranslations} returns this
*/
proto.fox.proto.command_control.FaultTranslations.prototype.setFaultTranslationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.command_control.FaultTranslation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.FaultTranslation}
 */
proto.fox.proto.command_control.FaultTranslations.prototype.addFaultTranslations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.command_control.FaultTranslation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.FaultTranslations} returns this
 */
proto.fox.proto.command_control.FaultTranslations.prototype.clearFaultTranslationsList = function() {
  return this.setFaultTranslationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.FaultTranslation.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.FaultTranslation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.FaultTranslation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FaultTranslation.toObject = function(includeInstance, msg) {
  var f, obj = {
    faultCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    faultType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    faultDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    faultSuggestedIntervention: jspb.Message.getFieldWithDefault(msg, 3, ""),
    numericCode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    firstOperatorQuestion: (f = msg.getFirstOperatorQuestion()) && proto.fox.proto.command_control.Question.toObject(includeInstance, f),
    avoidableByRobotDefault: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.FaultTranslation}
 */
proto.fox.proto.command_control.FaultTranslation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.FaultTranslation;
  return proto.fox.proto.command_control.FaultTranslation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.FaultTranslation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.FaultTranslation}
 */
proto.fox.proto.command_control.FaultTranslation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaultCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaultType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaultDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaultSuggestedIntervention(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumericCode(value);
      break;
    case 5:
      var value = new proto.fox.proto.command_control.Question;
      reader.readMessage(value,proto.fox.proto.command_control.Question.deserializeBinaryFromReader);
      msg.setFirstOperatorQuestion(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvoidableByRobotDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.FaultTranslation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.FaultTranslation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.FaultTranslation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FaultTranslation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaultCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFaultType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFaultDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFaultSuggestedIntervention();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNumericCode();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getFirstOperatorQuestion();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.fox.proto.command_control.Question.serializeBinaryToWriter
    );
  }
  f = message.getAvoidableByRobotDefault();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string fault_code = 1;
 * @return {string}
 */
proto.fox.proto.command_control.FaultTranslation.prototype.getFaultCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.FaultTranslation} returns this
 */
proto.fox.proto.command_control.FaultTranslation.prototype.setFaultCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fault_type = 7;
 * @return {string}
 */
proto.fox.proto.command_control.FaultTranslation.prototype.getFaultType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.FaultTranslation} returns this
 */
proto.fox.proto.command_control.FaultTranslation.prototype.setFaultType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string fault_description = 2;
 * @return {string}
 */
proto.fox.proto.command_control.FaultTranslation.prototype.getFaultDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.FaultTranslation} returns this
 */
proto.fox.proto.command_control.FaultTranslation.prototype.setFaultDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fault_suggested_intervention = 3;
 * @return {string}
 */
proto.fox.proto.command_control.FaultTranslation.prototype.getFaultSuggestedIntervention = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.FaultTranslation} returns this
 */
proto.fox.proto.command_control.FaultTranslation.prototype.setFaultSuggestedIntervention = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 numeric_code = 4;
 * @return {number}
 */
proto.fox.proto.command_control.FaultTranslation.prototype.getNumericCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.command_control.FaultTranslation} returns this
 */
proto.fox.proto.command_control.FaultTranslation.prototype.setNumericCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Question first_operator_question = 5;
 * @return {?proto.fox.proto.command_control.Question}
 */
proto.fox.proto.command_control.FaultTranslation.prototype.getFirstOperatorQuestion = function() {
  return /** @type{?proto.fox.proto.command_control.Question} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.Question, 5));
};


/**
 * @param {?proto.fox.proto.command_control.Question|undefined} value
 * @return {!proto.fox.proto.command_control.FaultTranslation} returns this
*/
proto.fox.proto.command_control.FaultTranslation.prototype.setFirstOperatorQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.FaultTranslation} returns this
 */
proto.fox.proto.command_control.FaultTranslation.prototype.clearFirstOperatorQuestion = function() {
  return this.setFirstOperatorQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.FaultTranslation.prototype.hasFirstOperatorQuestion = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool avoidable_by_robot_default = 6;
 * @return {boolean}
 */
proto.fox.proto.command_control.FaultTranslation.prototype.getAvoidableByRobotDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.command_control.FaultTranslation} returns this
 */
proto.fox.proto.command_control.FaultTranslation.prototype.setAvoidableByRobotDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.AnswerAndFollowUpQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.AnswerAndFollowUpQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerChoice: jspb.Message.getFieldWithDefault(msg, 1, ""),
    avoidableByRobot: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextQuestion: (f = msg.getNextQuestion()) && proto.fox.proto.command_control.Question.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.AnswerAndFollowUpQuestion}
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.AnswerAndFollowUpQuestion;
  return proto.fox.proto.command_control.AnswerAndFollowUpQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.AnswerAndFollowUpQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.AnswerAndFollowUpQuestion}
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerChoice(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.command_control.FaultAvoidableByRobot} */ (reader.readEnum());
      msg.setAvoidableByRobot(value);
      break;
    case 3:
      var value = new proto.fox.proto.command_control.Question;
      reader.readMessage(value,proto.fox.proto.command_control.Question.deserializeBinaryFromReader);
      msg.setNextQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.AnswerAndFollowUpQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.AnswerAndFollowUpQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerChoice();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvoidableByRobot();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNextQuestion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.command_control.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional string answer_choice = 1;
 * @return {string}
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.prototype.getAnswerChoice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.AnswerAndFollowUpQuestion} returns this
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.prototype.setAnswerChoice = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FaultAvoidableByRobot avoidable_by_robot = 2;
 * @return {!proto.fox.proto.command_control.FaultAvoidableByRobot}
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.prototype.getAvoidableByRobot = function() {
  return /** @type {!proto.fox.proto.command_control.FaultAvoidableByRobot} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.command_control.FaultAvoidableByRobot} value
 * @return {!proto.fox.proto.command_control.AnswerAndFollowUpQuestion} returns this
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.prototype.setAvoidableByRobot = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Question next_question = 3;
 * @return {?proto.fox.proto.command_control.Question}
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.prototype.getNextQuestion = function() {
  return /** @type{?proto.fox.proto.command_control.Question} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.Question, 3));
};


/**
 * @param {?proto.fox.proto.command_control.Question|undefined} value
 * @return {!proto.fox.proto.command_control.AnswerAndFollowUpQuestion} returns this
*/
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.prototype.setNextQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.AnswerAndFollowUpQuestion} returns this
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.prototype.clearNextQuestion = function() {
  return this.setNextQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.AnswerAndFollowUpQuestion.prototype.hasNextQuestion = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.Question.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.Question.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.Question.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.Question} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Question.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: jspb.Message.getFieldWithDefault(msg, 1, ""),
    answerChoicesList: jspb.Message.toObjectList(msg.getAnswerChoicesList(),
    proto.fox.proto.command_control.AnswerAndFollowUpQuestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.Question}
 */
proto.fox.proto.command_control.Question.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.Question;
  return proto.fox.proto.command_control.Question.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.Question} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.Question}
 */
proto.fox.proto.command_control.Question.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 2:
      var value = new proto.fox.proto.command_control.AnswerAndFollowUpQuestion;
      reader.readMessage(value,proto.fox.proto.command_control.AnswerAndFollowUpQuestion.deserializeBinaryFromReader);
      msg.addAnswerChoices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.Question.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.Question.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.Question} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.Question.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAnswerChoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.command_control.AnswerAndFollowUpQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * optional string question = 1;
 * @return {string}
 */
proto.fox.proto.command_control.Question.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.Question} returns this
 */
proto.fox.proto.command_control.Question.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AnswerAndFollowUpQuestion answer_choices = 2;
 * @return {!Array<!proto.fox.proto.command_control.AnswerAndFollowUpQuestion>}
 */
proto.fox.proto.command_control.Question.prototype.getAnswerChoicesList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.AnswerAndFollowUpQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.AnswerAndFollowUpQuestion, 2));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.AnswerAndFollowUpQuestion>} value
 * @return {!proto.fox.proto.command_control.Question} returns this
*/
proto.fox.proto.command_control.Question.prototype.setAnswerChoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.command_control.AnswerAndFollowUpQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.AnswerAndFollowUpQuestion}
 */
proto.fox.proto.command_control.Question.prototype.addAnswerChoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.command_control.AnswerAndFollowUpQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.Question} returns this
 */
proto.fox.proto.command_control.Question.prototype.clearAnswerChoicesList = function() {
  return this.setAnswerChoicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.OperatorFeedback.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.OperatorFeedback.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.OperatorFeedback} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.OperatorFeedback.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: jspb.Message.getFieldWithDefault(msg, 1, ""),
    answer: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.OperatorFeedback}
 */
proto.fox.proto.command_control.OperatorFeedback.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.OperatorFeedback;
  return proto.fox.proto.command_control.OperatorFeedback.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.OperatorFeedback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.OperatorFeedback}
 */
proto.fox.proto.command_control.OperatorFeedback.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.OperatorFeedback.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.OperatorFeedback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.OperatorFeedback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.OperatorFeedback.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string question = 1;
 * @return {string}
 */
proto.fox.proto.command_control.OperatorFeedback.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.OperatorFeedback} returns this
 */
proto.fox.proto.command_control.OperatorFeedback.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string answer = 2;
 * @return {string}
 */
proto.fox.proto.command_control.OperatorFeedback.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.OperatorFeedback} returns this
 */
proto.fox.proto.command_control.OperatorFeedback.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.GetTabletConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.GetTabletConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.GetTabletConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetTabletConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.GetTabletConfigurationRequest}
 */
proto.fox.proto.command_control.GetTabletConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.GetTabletConfigurationRequest;
  return proto.fox.proto.command_control.GetTabletConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.GetTabletConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.GetTabletConfigurationRequest}
 */
proto.fox.proto.command_control.GetTabletConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.GetTabletConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.GetTabletConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.GetTabletConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.GetTabletConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.TabletConfiguration.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.TabletConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.TabletConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.TabletConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    flagOptionsList: jspb.Message.toObjectList(msg.getFlagOptionsList(),
    proto.fox.proto.command_control.FlagOption.toObject, includeInstance),
    allowedOperatorModesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    enabledOptionalFeaturesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.TabletConfiguration}
 */
proto.fox.proto.command_control.TabletConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.TabletConfiguration;
  return proto.fox.proto.command_control.TabletConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.TabletConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.TabletConfiguration}
 */
proto.fox.proto.command_control.TabletConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.FlagOption;
      reader.readMessage(value,proto.fox.proto.command_control.FlagOption.deserializeBinaryFromReader);
      msg.addFlagOptions(value);
      break;
    case 2:
      var value = /** @type {!Array<!proto.fox.proto.command_control.TabletOperatorMode>} */ (reader.readPackedEnum());
      msg.setAllowedOperatorModesList(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addEnabledOptionalFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.TabletConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.TabletConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.TabletConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlagOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.command_control.FlagOption.serializeBinaryToWriter
    );
  }
  f = message.getAllowedOperatorModesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getEnabledOptionalFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * repeated FlagOption flag_options = 1;
 * @return {!Array<!proto.fox.proto.command_control.FlagOption>}
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.getFlagOptionsList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.FlagOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.FlagOption, 1));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.FlagOption>} value
 * @return {!proto.fox.proto.command_control.TabletConfiguration} returns this
*/
proto.fox.proto.command_control.TabletConfiguration.prototype.setFlagOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.command_control.FlagOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.FlagOption}
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.addFlagOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.command_control.FlagOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.TabletConfiguration} returns this
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.clearFlagOptionsList = function() {
  return this.setFlagOptionsList([]);
};


/**
 * repeated TabletOperatorMode allowed_operator_modes = 2;
 * @return {!Array<!proto.fox.proto.command_control.TabletOperatorMode>}
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.getAllowedOperatorModesList = function() {
  return /** @type {!Array<!proto.fox.proto.command_control.TabletOperatorMode>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.TabletOperatorMode>} value
 * @return {!proto.fox.proto.command_control.TabletConfiguration} returns this
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.setAllowedOperatorModesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.fox.proto.command_control.TabletOperatorMode} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.TabletConfiguration} returns this
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.addAllowedOperatorModes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.TabletConfiguration} returns this
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.clearAllowedOperatorModesList = function() {
  return this.setAllowedOperatorModesList([]);
};


/**
 * repeated string enabled_optional_features = 3;
 * @return {!Array<string>}
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.getEnabledOptionalFeaturesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.fox.proto.command_control.TabletConfiguration} returns this
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.setEnabledOptionalFeaturesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.TabletConfiguration} returns this
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.addEnabledOptionalFeatures = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.TabletConfiguration} returns this
 */
proto.fox.proto.command_control.TabletConfiguration.prototype.clearEnabledOptionalFeaturesList = function() {
  return this.setEnabledOptionalFeaturesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.FlagOption.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.FlagOption.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.FlagOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.FlagOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FlagOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    flagName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: (f = msg.getDisplayName()) && proto.fox.proto.command_control.LocalizableText.toObject(includeInstance, f),
    flagValuesList: jspb.Message.toObjectList(msg.getFlagValuesList(),
    proto.fox.proto.command_control.FlagValue.toObject, includeInstance),
    tooltip: (f = msg.getTooltip()) && proto.fox.proto.command_control.LocalizableText.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.FlagOption}
 */
proto.fox.proto.command_control.FlagOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.FlagOption;
  return proto.fox.proto.command_control.FlagOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.FlagOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.FlagOption}
 */
proto.fox.proto.command_control.FlagOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagName(value);
      break;
    case 2:
      var value = new proto.fox.proto.command_control.LocalizableText;
      reader.readMessage(value,proto.fox.proto.command_control.LocalizableText.deserializeBinaryFromReader);
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new proto.fox.proto.command_control.FlagValue;
      reader.readMessage(value,proto.fox.proto.command_control.FlagValue.deserializeBinaryFromReader);
      msg.addFlagValues(value);
      break;
    case 4:
      var value = new proto.fox.proto.command_control.LocalizableText;
      reader.readMessage(value,proto.fox.proto.command_control.LocalizableText.deserializeBinaryFromReader);
      msg.setTooltip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.FlagOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.FlagOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.FlagOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FlagOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlagName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.command_control.LocalizableText.serializeBinaryToWriter
    );
  }
  f = message.getFlagValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.fox.proto.command_control.FlagValue.serializeBinaryToWriter
    );
  }
  f = message.getTooltip();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.command_control.LocalizableText.serializeBinaryToWriter
    );
  }
};


/**
 * optional string flag_name = 1;
 * @return {string}
 */
proto.fox.proto.command_control.FlagOption.prototype.getFlagName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.FlagOption} returns this
 */
proto.fox.proto.command_control.FlagOption.prototype.setFlagName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LocalizableText display_name = 2;
 * @return {?proto.fox.proto.command_control.LocalizableText}
 */
proto.fox.proto.command_control.FlagOption.prototype.getDisplayName = function() {
  return /** @type{?proto.fox.proto.command_control.LocalizableText} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.LocalizableText, 2));
};


/**
 * @param {?proto.fox.proto.command_control.LocalizableText|undefined} value
 * @return {!proto.fox.proto.command_control.FlagOption} returns this
*/
proto.fox.proto.command_control.FlagOption.prototype.setDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.FlagOption} returns this
 */
proto.fox.proto.command_control.FlagOption.prototype.clearDisplayName = function() {
  return this.setDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.FlagOption.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated FlagValue flag_values = 3;
 * @return {!Array<!proto.fox.proto.command_control.FlagValue>}
 */
proto.fox.proto.command_control.FlagOption.prototype.getFlagValuesList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.FlagValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.FlagValue, 3));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.FlagValue>} value
 * @return {!proto.fox.proto.command_control.FlagOption} returns this
*/
proto.fox.proto.command_control.FlagOption.prototype.setFlagValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.fox.proto.command_control.FlagValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.FlagValue}
 */
proto.fox.proto.command_control.FlagOption.prototype.addFlagValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.fox.proto.command_control.FlagValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.FlagOption} returns this
 */
proto.fox.proto.command_control.FlagOption.prototype.clearFlagValuesList = function() {
  return this.setFlagValuesList([]);
};


/**
 * optional LocalizableText tooltip = 4;
 * @return {?proto.fox.proto.command_control.LocalizableText}
 */
proto.fox.proto.command_control.FlagOption.prototype.getTooltip = function() {
  return /** @type{?proto.fox.proto.command_control.LocalizableText} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.LocalizableText, 4));
};


/**
 * @param {?proto.fox.proto.command_control.LocalizableText|undefined} value
 * @return {!proto.fox.proto.command_control.FlagOption} returns this
*/
proto.fox.proto.command_control.FlagOption.prototype.setTooltip = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.FlagOption} returns this
 */
proto.fox.proto.command_control.FlagOption.prototype.clearTooltip = function() {
  return this.setTooltip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.FlagOption.prototype.hasTooltip = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.FlagValue.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.FlagValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.FlagValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FlagValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayText: (f = msg.getDisplayText()) && proto.fox.proto.command_control.LocalizableText.toObject(includeInstance, f),
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.FlagValue}
 */
proto.fox.proto.command_control.FlagValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.FlagValue;
  return proto.fox.proto.command_control.FlagValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.FlagValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.FlagValue}
 */
proto.fox.proto.command_control.FlagValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = new proto.fox.proto.command_control.LocalizableText;
      reader.readMessage(value,proto.fox.proto.command_control.LocalizableText.deserializeBinaryFromReader);
      msg.setDisplayText(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.FlagValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.FlagValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.FlagValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.FlagValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayText();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.command_control.LocalizableText.serializeBinaryToWriter
    );
  }
  f = message.getDefault();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.fox.proto.command_control.FlagValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.FlagValue} returns this
 */
proto.fox.proto.command_control.FlagValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LocalizableText display_text = 2;
 * @return {?proto.fox.proto.command_control.LocalizableText}
 */
proto.fox.proto.command_control.FlagValue.prototype.getDisplayText = function() {
  return /** @type{?proto.fox.proto.command_control.LocalizableText} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.command_control.LocalizableText, 2));
};


/**
 * @param {?proto.fox.proto.command_control.LocalizableText|undefined} value
 * @return {!proto.fox.proto.command_control.FlagValue} returns this
*/
proto.fox.proto.command_control.FlagValue.prototype.setDisplayText = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.command_control.FlagValue} returns this
 */
proto.fox.proto.command_control.FlagValue.prototype.clearDisplayText = function() {
  return this.setDisplayText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.command_control.FlagValue.prototype.hasDisplayText = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool default = 3;
 * @return {boolean}
 */
proto.fox.proto.command_control.FlagValue.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.command_control.FlagValue} returns this
 */
proto.fox.proto.command_control.FlagValue.prototype.setDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.command_control.LocalizableText.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.LocalizableText.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.LocalizableText.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.LocalizableText} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.LocalizableText.toObject = function(includeInstance, msg) {
  var f, obj = {
    localizedTextList: jspb.Message.toObjectList(msg.getLocalizedTextList(),
    proto.fox.proto.command_control.LocalizedText.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.LocalizableText}
 */
proto.fox.proto.command_control.LocalizableText.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.LocalizableText;
  return proto.fox.proto.command_control.LocalizableText.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.LocalizableText} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.LocalizableText}
 */
proto.fox.proto.command_control.LocalizableText.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.command_control.LocalizedText;
      reader.readMessage(value,proto.fox.proto.command_control.LocalizedText.deserializeBinaryFromReader);
      msg.addLocalizedText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.LocalizableText.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.LocalizableText.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.LocalizableText} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.LocalizableText.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocalizedTextList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.command_control.LocalizedText.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LocalizedText localized_text = 1;
 * @return {!Array<!proto.fox.proto.command_control.LocalizedText>}
 */
proto.fox.proto.command_control.LocalizableText.prototype.getLocalizedTextList = function() {
  return /** @type{!Array<!proto.fox.proto.command_control.LocalizedText>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.command_control.LocalizedText, 1));
};


/**
 * @param {!Array<!proto.fox.proto.command_control.LocalizedText>} value
 * @return {!proto.fox.proto.command_control.LocalizableText} returns this
*/
proto.fox.proto.command_control.LocalizableText.prototype.setLocalizedTextList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.command_control.LocalizedText=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.command_control.LocalizedText}
 */
proto.fox.proto.command_control.LocalizableText.prototype.addLocalizedText = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.command_control.LocalizedText, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.command_control.LocalizableText} returns this
 */
proto.fox.proto.command_control.LocalizableText.prototype.clearLocalizedTextList = function() {
  return this.setLocalizedTextList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.LocalizedText.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.LocalizedText.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.LocalizedText} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.LocalizedText.toObject = function(includeInstance, msg) {
  var f, obj = {
    locale: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.LocalizedText}
 */
proto.fox.proto.command_control.LocalizedText.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.LocalizedText;
  return proto.fox.proto.command_control.LocalizedText.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.LocalizedText} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.LocalizedText}
 */
proto.fox.proto.command_control.LocalizedText.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.LocalizedText.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.LocalizedText.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.LocalizedText} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.LocalizedText.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string locale = 1;
 * @return {string}
 */
proto.fox.proto.command_control.LocalizedText.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.LocalizedText} returns this
 */
proto.fox.proto.command_control.LocalizedText.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.fox.proto.command_control.LocalizedText.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.LocalizedText} returns this
 */
proto.fox.proto.command_control.LocalizedText.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.command_control.UpdateDefaultFlagValueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    flagName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    defaultValue: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.command_control.UpdateDefaultFlagValueRequest}
 */
proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.command_control.UpdateDefaultFlagValueRequest;
  return proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.command_control.UpdateDefaultFlagValueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.command_control.UpdateDefaultFlagValueRequest}
 */
proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.command_control.UpdateDefaultFlagValueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlagName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDefaultValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string flag_name = 1;
 * @return {string}
 */
proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.prototype.getFlagName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.UpdateDefaultFlagValueRequest} returns this
 */
proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.prototype.setFlagName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string default_value = 2;
 * @return {string}
 */
proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.prototype.getDefaultValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.command_control.UpdateDefaultFlagValueRequest} returns this
 */
proto.fox.proto.command_control.UpdateDefaultFlagValueRequest.prototype.setDefaultValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.fox.proto.command_control.CohortEnvironmentConfigStatus = {
  ENVIRONMENT_CONFIG_STATUS_ACTIVE: 0,
  ENVIRONMENT_CONFIG_STATUS_ARCHIVED: 1
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.SupportTicketTimeline = {
  LAST_15_MINUTES: 0,
  MINUTES_15_TO_AN_HOUR: 1,
  EARLIER_TODAY: 2,
  BEFORE_TODAY: 3,
  NOT_SURE: 4
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.InterventionType = {
  INTERVENTION_NONE: 0,
  INTERVENTION_CONSOLE_UPDATE: 2,
  INTERVENTION_REMOVE_OBSTACLE: 3,
  INTERVENTION_TUCK_PLASTIC: 4,
  INTERVENTION_REPALLETIZE: 5,
  INTERVENTION_REMOVE_LOAD_RESTRAINT: 11,
  INTERVENTION_MANUAL_DRIVE: 6,
  INTERVENTION_MANUAL_PICK: 7,
  INTERVENTION_MANUAL_PLACE: 8,
  INTERVENTION_MANUAL_PALLET_MOVE: 9,
  INTERVENTION_DOWN_STACK: 12,
  INTERVENTION_TOGGLE_ESTOP: 13,
  INTERVENTION_OTHER: 10
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.InterventionCause = {
  INTERVENTION_CAUSE_UNKNOWN: 0,
  INTERVENTION_CAUSE_OPERATIONAL_STOP: 1,
  INTERVENTION_CAUSE_OPERATOR_ERROR: 11,
  INTERVENTION_CAUSE_STUCK: 2,
  INTERVENTION_CAUSE_UNSTABLE_PALLET: 3,
  INTERVENTION_CAUSE_HANGING_PLASTIC: 13,
  INTERVENTION_CAUSE_LOAD_RESTRAINT: 4,
  INTERVENTION_CAUSE_PALLET_TOO_TALL: 5,
  INTERVENTION_CAUSE_TAIL_PALLET: 6,
  INTERVENTION_CAUSE_PREVENT_COLLISION: 16,
  INTERVENTION_CAUSE_COLLISION: 7,
  INTERVENTION_CAUSE_DROPPED_PALLET: 8,
  INTERVENTION_CAUSE_SAFETY: 9,
  INTERVENTION_CAUSE_RUN_CRASHED: 12,
  INTERVENTION_CAUSE_STICKY_PALLET: 14,
  INTERVENTION_CAUSE_OTHER: 10,
  INTERVENTION_CAUSE_DEBRIS: 15
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.InterventionCauseCategory = {
  INTERVENTION_CAUSE_CATEGORY_UNCATEGORIZED: 0,
  INTERVENTION_CAUSE_CATEGORY_AUTONOMY: 1,
  INTERVENTION_CAUSE_CATEGORY_ENVIRONMENT: 2,
  INTERVENTION_CAUSE_CATEGORY_OPERATOR: 3
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.PalletHeight = {
  PALLET_HEIGHT_UNKNOWN: 0,
  PALLET_HEIGHT_NORMAL: 1,
  PALLET_HEIGHT_TALL: 2,
  PALLET_HEIGHT_AUTO: 3
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.PalletType = {
  PALLET_TYPE_UNKNOWN: 0,
  PALLET_TYPE_CHEP: 1,
  PALLET_TYPE_PECO: 2,
  PALLET_TYPE_IGPS: 3,
  PALLET_TYPE_STRINGER: 4,
  PALLET_TYPE_OTHER: 5
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.PalletStack = {
  PALLET_STACK_UNKNOWN: 0,
  PALLET_STACK_SINGLE: 1,
  PALLET_STACK_DOUBLE: 2,
  PALLET_STACK_MIX: 3
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.SyncStatus = {
  SYNC_UNKNOWN: 0,
  SYNC_NEW: 1,
  SYNC_UPLOAD_STARTED: 2,
  SYNC_UPLOAD_SUCCEEDED: 3,
  SYNC_FILES_UNAVAILABLE: 4
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.FaultAvoidableByRobot = {
  AVOIDABILITY_UNKNOWN: 0,
  AVOIDABILITY_AVOIDABLE: 1,
  AVOIDABILITY_UNAVOIDABLE: 2
};

/**
 * @enum {number}
 */
proto.fox.proto.command_control.TabletOperatorMode = {
  STANDARD_USER: 0,
  FOX_USER: 1
};

goog.object.extend(exports, proto.fox.proto.command_control);
