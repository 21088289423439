
import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
 } from '@material-ui/core/styles';
import { useAppDispatch } from '../../redux/hooks';
import { useAsyncDebounce } from 'react-table';
import { SearchTarget, setIncludeInactive, setSearchString, setSearchTarget } from '../../redux';
import { Box, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Switch, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';

 const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  textFieldFormControl: {
    width: 250,
    marginRight: 28
  },
  radioFormControl: {
    marginLeft: 8,
  },
 });

interface Props extends WithStyles<typeof styles> {
  searchString: string;
  includeInactive: boolean;
  searchTarget: SearchTarget;
}

const Component: FunctionComponent<Props> = (props: Props) => {
  const { classes, searchString, includeInactive, searchTarget } = props;
  const dispatch = useAppDispatch();
  const [ searchText, setSearchText ] = useState('');
  const [ radio, setRadio ] = useState<SearchTarget>(localStorage.getItem('robot-status-searchTarget') as SearchTarget || 'robot');
  const [ toggle, setToggle ] = useState<boolean>(localStorage.getItem('robot-status-include-inactive') ? JSON.parse(localStorage.getItem('robot-status-include-inactive')!) : false);

  const onChangeText = useAsyncDebounce(value => {
    dispatch(setSearchString(searchText));
  }, 200);

  const onChangeRadio = (event) => {
    setRadio(event.target.value);
    localStorage.setItem('robot-status-searchTarget', event.target.value)
  }

  const onChangeToggle = (event) => {
    setToggle(event.target.checked);
    localStorage.setItem('robot-status-include-inactive', event.target.checked);
  }

  useEffect(() => {
    dispatch(setSearchTarget(radio))
  }, [radio]);
  
  useEffect(() => {
    dispatch(setIncludeInactive(toggle))
  }, [toggle])

  return (
    <Box className={classes.container}>
      <FormControl className={classes.textFieldFormControl}>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          type='search'
          size='small'
          autoComplete="off"
          spellCheck={false}
          onChange={e => {
            setSearchText(e.target.value)
            onChangeText(e.target.value);
          }}
          value={searchText}
          InputProps={{
            startAdornment: (
              <Search />
            )
          }}
        />
      </FormControl>
      <Box>
        <FormControl className={classes.radioFormControl}>
          <RadioGroup row value={searchTarget}>
            <FormControlLabel
              value={'robot'}
              control={<Radio size={'small'}/>}
              label={'Robot'}
              labelPlacement={'end'}
              onChange={onChangeRadio}
            />
            <FormControlLabel
              value={'cohort'}
              control={<Radio size={'small'}/>}
              label={'Cohort'}
              labelPlacement={'end'}
              onChange={onChangeRadio}
            />
          </RadioGroup>
        </FormControl>
        <FormGroup row>
            <FormControlLabel
              label={'Include Inactive'}
              control={
                <Switch
                  checked={includeInactive}
                  onChange={onChangeToggle}
                />
              }
            />
        </FormGroup>
      </Box>
    </Box>
  )
}

export default withStyles(styles)(Component);
