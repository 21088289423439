import { connect } from 'react-redux';
import TabletConfiguration from './TabletConfiguration';
import { ApplicationState } from '../../redux';
import {
  selectRobotsBySite,
  selectTabletConfigurations,
  selectTabletFlagOptions,
  selectTabletOptionalFeatures
} from '../../redux/selectors';

const mapStateToProps = (state: ApplicationState) => ({
  robotsBySite: selectRobotsBySite(state),
  robotTabletConfigurations: selectTabletConfigurations(state),
  tabletFlagOptions: selectTabletFlagOptions(state),
  tabletOptionalFeatures: selectTabletOptionalFeatures(state),
});

export default connect(mapStateToProps)(TabletConfiguration);
