import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { MonitoringClient, ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import {
  CreateTabletOptionalFeatureRequest,
  ListTabletOptionalFeaturesRequest, ListTabletOptionalFeaturesResponse, TabletFlagOption, TabletOptionalFeature,
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { BrowserHeaders } from "browser-headers";
import { NormalizedEntities, upsertEntity } from '../normalizedEntities'
import {authHeader} from "../sagas";
import {Tablet} from "@material-ui/icons";

export const fetchTabletOptionalFeatures = createAsyncThunk<
ListTabletOptionalFeaturesResponse | null,
  { client: MonitoringClient, req: ListTabletOptionalFeaturesRequest },
  { rejectValue: ServiceError }
>(
  'tabletOptionalFeatures/list',
  async (arg: {client: MonitoringClient, req: ListTabletOptionalFeaturesRequest}, { rejectWithValue }) => {
    const data = new Promise<ListTabletOptionalFeaturesResponse | null>((resolve, reject) => {
      arg.client.listTabletOptionalFeatures(arg.req, authHeader(), (err, response) => {
        if (err) {
          reject(err)
        } else {
          resolve(response)
        }
      })
    })

    try {
      return await data;
    } catch (err) {
      return rejectWithValue(err as ServiceError)
    }
  }
);

export const createTabletOptionalFeature = createAsyncThunk<
TabletOptionalFeature | null,
  { client: MonitoringClient, req: CreateTabletOptionalFeatureRequest },
  { rejectValue: ServiceError }
>(
  'tabletOptionalFeatures/create',
  async (arg: {client: MonitoringClient, req: CreateTabletOptionalFeatureRequest}, { rejectWithValue }) => {
    const data = new Promise<TabletOptionalFeature | null>((resolve, reject) => {
      arg.client.createTabletOptionalFeature(arg.req, authHeader(), (err, response) => {
        if (err) {
          reject(err)
        } else {
          resolve(response)
        }
      })
    })

    try {
      return await data;
    } catch (err) {
      return rejectWithValue(err as ServiceError)
    }
  }
);

export interface TabletOptionalFeatureEntityState {
  entities: NormalizedEntities<TabletOptionalFeature.AsObject, string>
  loading: 'idle' | 'pending';
  reqId: string | undefined;
  error: ServiceError | undefined;
}

const initialState: TabletOptionalFeatureEntityState = {
  entities: {
    byId: new Map<string, TabletOptionalFeature.AsObject>(),
    allIds: [],
  },
  loading: 'idle',
  reqId:  undefined,
  error: undefined,
}

const tabletOptionalFeaturesEntitySlice = createSlice({
  name: 'tabletOptionalFeatureEntities',
  initialState: initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchTabletOptionalFeatures.pending,
      (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.reqId = action.meta.requestId;
        }
      })
    .addCase(fetchTabletOptionalFeatures.fulfilled,
      (state, action) => {
        const { requestId } = action.meta;

        if (
          state.loading === 'pending' &&
          state.reqId === requestId
        ) {
          state.loading = 'idle';
          state.reqId = undefined;

          if (action.payload) {
            state.entities = action.payload.getTabletOptionalFeaturesList()
              .reduce((agg, a, i) => {
                const asObject = a.toObject();

                if (asObject.id) {
                  agg.byId.set(asObject.id, asObject)
                  if (!agg.allIds.includes(asObject.id)) {
                    agg.allIds.push(asObject.id)
                  }
                }

                return agg;
              }, state.entities)
          }
        }
      })
    .addCase(fetchTabletOptionalFeatures.rejected,
      (state, action) => {
        const { requestId } = action.meta;

        if (
          state.loading === 'pending' &&
          state.reqId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.payload;
          state.reqId = undefined;
        }
      })
   .addCase(createTabletOptionalFeature.pending,
      (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.reqId = action.meta.requestId;
        }
      })
    .addCase(createTabletOptionalFeature.fulfilled,
      (state, action) => {
        const { requestId } = action.meta;

        if (
          state.loading === 'pending' &&
          state.reqId === requestId
        ) {
          state.loading = 'idle';
          state.reqId = undefined;

          if (action.payload) {
            const optionalFeature = action.payload.toObject();
            if (optionalFeature.id) {
              state.entities.byId.set(optionalFeature.id, optionalFeature)
              if (!state.entities.allIds.includes(optionalFeature.id)) {
                state.entities.allIds = [...state.entities.allIds, optionalFeature.id]
              }
            }
          }
        }
      })
    .addCase(createTabletOptionalFeature.rejected,
      (state, action) => {
        const { requestId } = action.meta;

        if (
          state.loading === 'pending' &&
          state.reqId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.payload;
          state.reqId = undefined;
        }
      })
  }
})

export default tabletOptionalFeaturesEntitySlice.reducer;
