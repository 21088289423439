import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography } from "@material-ui/core";
import { ForkliftCohort } from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import Divider from "@material-ui/core/Divider";
import ProgressSpinner from "../Utils/ProgressSpinner";
import ConfigurationFiles from "./ConfigurationFiles";
import { combineStyles, commonStyles } from "../Utils/CommonStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ConfigEntity } from "./RobotCohortSelector";
import { isEmpty } from "lodash";

const localStyles = () =>
  createStyles({
    pageWrapper: {
      flexDirection: "row",
      margin: 32,
      alignItems: "space-around"
    },
    title: {
      textAlign: "center",
      paddingTop: 10,
      display: "block"
    },
    dataDisplay: {
      display: "flex"
    },
    divider: {
      marginTop: 20,
      marginBottom: 20,
      flex: 10
    },
    halfDisplay: {
      display: "flex",
      maxHeight: "50%",
      height: "30vh",
      overflow: "auto"
    },
    selectorDisplay: {
      display: "flex"
    },
    comparisonDropdown: {
      marginLeft: "24px !important",
      marginTop: "24px !important"
    }
  });
const styles = combineStyles(localStyles, commonStyles);

export function getOptions(
  comparisonEntity: { isRobot: boolean; isCohort: boolean },
  objectArray: any[]
): any[] {
  const { isCohort, isRobot } = comparisonEntity;
  const identifier = isRobot ? `robotName` : isCohort ? `displayName` : "";
  if (
    objectArray === undefined ||
    isEmpty(objectArray) ||
    isEmpty(identifier)
  ) {
    !isEmpty(identifier) &&
      console.error(
        "Error: Each child in an Autocomplete should have a valid value",
        objectArray
      );
    return [];
  }
  return objectArray.reduce((total: Array<any>, option: any) => {
    const value = option ? option[identifier] : "";
    if (!isEmpty(value)) {
      total.push({ displayName: value });
    }
    return total;
  }, []);
}

interface ConfigComparison extends ConfigEntity {
  isRobot: boolean;
  isCohort: boolean;
}

interface Props extends WithStyles<typeof styles> {
  robotName: string | undefined;
  displayName: string;
  cohortId: string | null;
  cohorts: ForkliftCohort.AsObject[];
  robotAccounts: any[];
  isCohort: boolean;
  isRobot: boolean;
  entityRevisionHistory: string;
  comparisonHistory: {
    compareEntity: string;
    comparisonName: string;
    comparisonRevisionHistory: string;
  };
  alertError: any;
  updatePath: any;
}
interface State {
  comparisonEntity: ConfigComparison;
  showCompareCohort: boolean;
  showCompareRobot: boolean;
  isLoading: boolean;
  isDivided: boolean;
  cohort: ForkliftCohort.AsObject | undefined;
}

function ConfigurationPage(props: Props) {
  const {
    robotName,
    displayName,
    cohortId,
    cohorts,
    robotAccounts,
    isCohort,
    isRobot,
    updatePath,
    entityRevisionHistory,
    comparisonHistory,
    alertError,
    classes
  } = props;

  // use states
  const [comparisonEntity, setComparisonEntity] = useState({
    displayName: comparisonHistory.comparisonName || "",
    isRobot: false,
    isCohort: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDivided, setIsDivided] = useState(false);
  const [cohort, setCohort] = useState<ForkliftCohort.AsObject>({
    id: "",
    displayName: "",
    robotNamesList: [],
    organizationName: "",
    locationCode: "",
    active: false,
  });
  const [dataClass, setDataClass] = useState(classes.dataDisplay);

  const [showCompareCohort, setShowCompareCohort] = useState(false);
  const [showCompareRobot, setShowCompareRobot] = useState(false);
  const [isCompareRobot, setIsCompareRobot] = useState(false);
  const [isCompareCohort, setIsCompareCohort] = useState(false);
  const [dropdownValue, setDropdownValue] = useState({
    displayName: comparisonHistory.comparisonName
  });

  // use effects
  useEffect(() => {
    setIsLoading(true);
    const newCohort = cohorts.find(cohort => {
      return cohort.id === cohortId;
    });
    newCohort && setCohort(newCohort);
    return setIsLoading(false);
  }, [cohorts, cohortId]);

  useEffect(() => {
    setIsLoading(true);
    // reset display
    setIsDivided(false);
    setShowCompareCohort(false);
    setShowCompareRobot(false);
    return setIsLoading(false);
  }, [isCohort, isRobot]);

  useEffect(() => {
    setIsLoading(true);
    const { compareEntity, comparisonName } = comparisonHistory;
    const compareRobot = compareEntity === "robot";
    const compareCohort = compareEntity === "cohort";
    if (compareRobot) {
      setShowCompareRobot(true);
    } else if (compareCohort) {
      setShowCompareCohort(true);
    } else if (isEmpty(compareEntity)) {
      setShowCompareCohort(false);
      setShowCompareRobot(false);
    }
    setComparisonEntity({
      displayName: comparisonName,
      isRobot: compareRobot,
      isCohort: compareCohort
    });
    setDropdownValue({
      displayName: comparisonHistory.comparisonName
    });
    // set isCompare
    setIsCompareRobot(compareRobot);
    setIsCompareCohort(compareCohort);
    return setIsLoading(false);
  }, [comparisonHistory]);

  useEffect(() => {
    setIsLoading(true);
    const newDataClass = isDivided ? classes.halfDisplay : classes.dataDisplay;
    setDataClass(newDataClass);
    return setIsLoading(false);
  }, [isDivided]);

  // utility functions
  function _getComparisonOptions(objectArray: any[]): any[] {
    const identifier = isCompareRobot
      ? `robotName`
      : isCompareCohort
      ? `displayName`
      : "";

    if (objectArray === undefined || isEmpty(objectArray)) return [];
    const result = objectArray.map(option => {
      return { displayName: option[identifier] };
    });

    return result;
  }

  function _getOptionsDisabled(): (option: { displayName: string }) => boolean {
    return (option: { displayName: string }) =>
      option.displayName === displayName ||
      option.displayName === comparisonEntity.displayName;
  }

  // handle event
  function _handleShowCohorts() {
    // handle url
    updatePath(
      ["compareEntity", "comparisonName", "comparisonRevisionHistory"],
      ["cohort", "", ""],
      showCompareCohort
    );
    // handle state management
    setShowCompareCohort(!showCompareCohort);
    setIsCompareCohort(true);
    setComparisonEntity({
      displayName: "",
      isCohort: !showCompareCohort,
      isRobot: false
    });
  }

  function _handleShowRobots() {
    // handle url
    updatePath(
      ["compareEntity", "comparisonName", "comparisonRevisionHistory"],
      ["robot", "", ""],
      showCompareRobot
    );
    // handle state management
    setShowCompareRobot(!showCompareRobot);
    setIsCompareRobot(true);
    setComparisonEntity({
      displayName: "",
      isCohort: false,
      isRobot: true
    });
  }
  function _handleDivider() {
    setIsDivided(!isDivided);
  }

  function _handleComparisonChange(
    event: React.ChangeEvent<{}>,
    value: { displayName: string } | null,
    reason: string
  ) {
    if (!isEmpty(reason) && reason === "clear") {
      _handleOnClear();
    } else {
      const isComparisonCohort = comparisonEntity.isCohort;
      const calculatedId = cohortId;
      // update url
      if (isComparisonCohort && isEmpty(calculatedId) && value) {
        // id matters for cohort
        const correspondingCohort = cohorts.find(cohort => {
          return cohort.displayName === value.displayName;
        });
        const newComparisonEntity = {
          ...comparisonEntity,
          ...correspondingCohort,
          cohortId: correspondingCohort && correspondingCohort.id
        };
        setComparisonEntity(newComparisonEntity);
      } else if (value) {
        // add to entity
        setComparisonEntity({ ...comparisonEntity, ...value });
      } else {
        // reset state
        setComparisonEntity({
          displayName: "",
          isCohort: false,
          isRobot: false
        });
      }
      if (value === null) {
        updatePath("comparisonName", null, true);
      } else if (value) {
        updatePath("comparisonName", value.displayName);
      }
    }
  }

  function _handleAutocompleteOptions(objectArray: any[]) {
    return getOptions(comparisonEntity, objectArray);
  }
  function _handleOnClear() {
    setComparisonEntity({ displayName: "", isCohort: false, isRobot: false });
  }

  function renderConfigurationFiles(isComparision: boolean) {
    const comparisonCohort = cohorts.find(cohort => {
      return cohort.displayName === comparisonEntity.displayName;
    });
    const cohortParam = isComparision ? comparisonCohort : cohort;

    let params = {
      allowCreateFile: !isComparision,
      updatePath: updatePath,
      entityRevisionHistory: isComparision
        ? comparisonHistory.comparisonRevisionHistory
        : entityRevisionHistory,
      displayName: isComparision
        ? comparisonHistory.comparisonName
        : displayName,
      robotName,
      isRobot: isComparision ? isCompareRobot : isRobot,
      isCohort: isComparision ? isCompareCohort : isCohort,
      alertError,
      cohort: cohortParam,
      cohortId:
        isComparision && isCompareCohort && comparisonCohort
          ? comparisonCohort.id
          : cohortId
    };
    return <ConfigurationFiles {...params} />;
  }

  function renderComparisonPanel() {
    const robotsOfCohort = robotAccounts.filter(option => {
      return option.cohortId === cohortId;
    });
    const dropdownOptions: any[] = isRobot
      ? // is robot
        showCompareRobot
        ? // all bots
          robotAccounts
        : // only own cohort not null
        !isEmpty(cohort)
        ? [cohort]
        : []
      : // is cohort
      showCompareRobot
      ? // only bots in cohort
        robotsOfCohort
      : // all other cohorts
        cohorts;
    const dataClass = isDivided ? classes.halfDisplay : classes.dataDisplay;
    const options = _handleAutocompleteOptions(dropdownOptions);
    const cohortBtnText = showCompareCohort
      ? "Hide"
      : isRobot
      ? "Compare with"
      : "Compare with other";
    return (
      <>
        {/* Comparison Panel View */}
        <Button
          variant="contained"
          color="primary"
          onClick={_handleShowCohorts}
          style={{ marginLeft: 24 }}
          disabled={showCompareRobot}
        >
          {cohortBtnText} Cohort Files
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={_handleShowRobots}
          style={{ marginLeft: 24 }}
          disabled={showCompareCohort}
        >
          {showCompareRobot ? "Hide" : "Compare with"} Robot Files
        </Button>
        <>
          {(showCompareRobot || showCompareCohort) && (
            <div className={classes.selectorDisplay}>
              <Autocomplete
                className={classes.comparisonDropdown}
                id="combo-box-demo"
                options={options}
                getOptionDisabled={_getOptionsDisabled()}
                getOptionLabel={option => option.displayName}
                style={{ width: 300, height: 56, margin: 6 }}
                renderInput={params => (
                  <TextField {...params} variant="outlined" />
                )}
                value={{ displayName: comparisonEntity.displayName }}
                onChange={_handleComparisonChange}
              />
            </div>
          )}
          {!isEmpty(comparisonEntity.displayName) && (
            <div className={dataClass}>
              {(showCompareCohort || showCompareRobot) &&
                renderConfigurationFiles(true)}
            </div>
          )}
        </>
      </>
    );
  }

  return (
    <div className={classes.pageWrapper}>
      {displayName && (
        <>
          <Typography variant="h4" gutterBottom className={classes.title}>
            {displayName}
          </Typography>
          {isLoading ? (
            <ProgressSpinner />
          ) : (
            <>
              <div className={dataClass}>
                {(isRobot || isCohort) && renderConfigurationFiles(false)}
              </div>

              <div className={classes.dataDisplay}>
                <Divider className={classes.divider} />
                <Button
                  className={classes.sideBtn}
                  size={"small"}
                  onClick={_handleDivider}
                >
                  {isDivided ? "Unpin" : "Pin"} Divider
                </Button>
              </div>
              {renderComparisonPanel()}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default connect()(withStyles(styles)(ConfigurationPage));
