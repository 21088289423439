import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { connect } from "react-redux";
interface Props extends WithStyles<typeof styles> {}

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      padding: 80,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }
  });

const ProgressSpinner = (props: Props) => (
  <div className={props.classes.wrapper}>
    <CircularProgress />
  </div>
);
export default connect()(withStyles(styles)(ProgressSpinner));
