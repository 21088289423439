import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";

import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = () =>
  createStyles({
    fieldGroup: {
      width: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "row"
    },
    buttonGroup: {
      width: "100%",
      padding: 0,
      display: "flex",
      justifyContent: "center"
    },
    wideInput: {
      width: "20em",
      margin: ".5em"
    },
    normalInput: {
      width: "15em",
      margin: ".5em"
    },
    narrowInput: {
      width: "10em",
      margin: ".5em"
    },
    button: {
      margin: ".5em"
    }
  });

interface Props extends WithStyles<typeof styles> {
  dockConfig: m_pb.DockConfig;
  placeZoneTemplateNames: string[];
  submit: (editedTemplate: m_pb.DockConfig) => void;
  cancel: () => void;
}

interface State {
  pickZoneName: string;
  pickZoneId: number;
  rampLength: number;
  rampWidth: number;
  rampWallLength: number;
  rampLipToBumpers: number;
  lipToHinge: number;
  rampLipToTagLine: number;
  hasRampWalls: boolean;
  placeZoneId: number;
  placeZoneName: string;
  placeStrategy: string;
  placeLeftToRight: boolean;
  rampToPlaceZoneX: number;
  rampToPlaceZoneY: number;
  placeZoneTemplateName: string;
  pickZoneTemplateName: string;
  rampToPlaceZoneRotation: number;
  lipToLeftTag: number;
  lipToRightTag: number;
  lipToClearHeight: number;
  lipToRampWall: number;
  edgeToLeftTag: number;
  edgeToRightTag: number;
  omniPadding: boolean;
  useSplitPlaceZone: boolean;
  batchSize: number;
  numberOfBatches: number;
  // TODO(chris): support unit conversion
}

class DockConfigurationEditor extends Component<Props, State> {
  state: State = {
    pickZoneName: this.props.dockConfig.getPickZoneName(),
    pickZoneId: this.props.dockConfig.getPickZoneId(),
    rampLength: this.props.dockConfig.getRampLength(),
    rampWidth: this.props.dockConfig.getRampWidth(),
    rampWallLength: this.props.dockConfig.getRampWallLength(),
    rampLipToBumpers: this.props.dockConfig.getRampLipToBumpers(),
    lipToHinge: this.props.dockConfig.getLipToHinge(),
    rampLipToTagLine: this.props.dockConfig.getRampLipToTagLine(),
    hasRampWalls: this.props.dockConfig.getHasRampWalls(),
    placeZoneId: this.props.dockConfig.getPlaceZoneId(),
    placeZoneName: this.props.dockConfig.getPlaceZoneName(),
    placeStrategy: this.props.dockConfig.getPlaceStrategy(),
    placeLeftToRight: this.props.dockConfig.getPlaceLeftToRight(),
    rampToPlaceZoneX: this.props.dockConfig.getRampToPlaceZoneX(),
    rampToPlaceZoneY: this.props.dockConfig.getRampToPlaceZoneY(),
    placeZoneTemplateName: this.props.dockConfig.getPlaceZoneTemplateName(),
    pickZoneTemplateName: this.props.dockConfig.getPickZoneTemplateName(),
    // TODO(chris): make this field editable
    rampToPlaceZoneRotation:
      this.props.dockConfig.getRampToPlaceZoneRotation() || 0,
    lipToLeftTag: this.props.dockConfig.getLipToLeftTag(),
    lipToRightTag: this.props.dockConfig.getLipToRightTag(),
    lipToClearHeight: this.props.dockConfig.getLipToClearHeight(),
    lipToRampWall: this.props.dockConfig.getLipToRampWall(),
    edgeToLeftTag: this.props.dockConfig.getEdgeToLeftTag(),
    edgeToRightTag: this.props.dockConfig.getEdgeToRightTag(),
    omniPadding: this.props.dockConfig.getOmniPadding(),
    useSplitPlaceZone: this.props.dockConfig.getUseSplitPlaceZone(),
    batchSize: this.props.dockConfig.getBatchSize() || 2,
    numberOfBatches: this.props.dockConfig.getNumberOfBatches() || 1
  };

  _submit = () => {
    const editedDockConfig = this.props.dockConfig;
    editedDockConfig.setPickZoneName(this.state.pickZoneName);
    editedDockConfig.setPickZoneId(this.state.pickZoneId);
    editedDockConfig.setRampLength(this.state.rampLength);
    editedDockConfig.setRampWidth(this.state.rampWidth);
    editedDockConfig.setRampWallLength(this.state.rampWallLength);
    editedDockConfig.setRampLipToBumpers(this.state.rampLipToBumpers);
    editedDockConfig.setLipToHinge(this.state.lipToHinge);
    editedDockConfig.setRampLipToTagLine(this.state.rampLipToTagLine);
    editedDockConfig.setLipToLeftTag(this.state.lipToLeftTag);
    editedDockConfig.setLipToRightTag(this.state.lipToRightTag);
    editedDockConfig.setLipToClearHeight(this.state.lipToClearHeight);
    editedDockConfig.setLipToRampWall(this.state.lipToRampWall);
    editedDockConfig.setEdgeToLeftTag(this.state.edgeToLeftTag);
    editedDockConfig.setEdgeToRightTag(this.state.edgeToRightTag);
    editedDockConfig.setHasRampWalls(this.state.hasRampWalls);
    editedDockConfig.setPlaceZoneId(this.state.placeZoneId);
    editedDockConfig.setPlaceZoneName(this.state.placeZoneName);
    editedDockConfig.setPlaceStrategy(this.state.placeStrategy);
    editedDockConfig.setPlaceLeftToRight(this.state.placeLeftToRight);
    editedDockConfig.setRampToPlaceZoneX(this.state.rampToPlaceZoneX);
    editedDockConfig.setRampToPlaceZoneY(this.state.rampToPlaceZoneY);
    editedDockConfig.setPlaceZoneTemplateName(this.state.placeZoneTemplateName);
    editedDockConfig.setPickZoneTemplateName(this.state.pickZoneTemplateName);
    editedDockConfig.setRampToPlaceZoneRotation(
      this.state.rampToPlaceZoneRotation
    );
    editedDockConfig.setOmniPadding(this.state.omniPadding);
    editedDockConfig.setUseSplitPlaceZone(this.state.useSplitPlaceZone);
    editedDockConfig.setBatchSize(this.state.batchSize);
    editedDockConfig.setNumberOfBatches(this.state.numberOfBatches);
    this.props.submit(editedDockConfig);
  };

  render() {
    return (
      <div>
        <Container className={this.props.classes.fieldGroup}>
          <TextField
            id="rows-input"
            label="Dock Name"
            variant="outlined"
            className={this.props.classes.wideInput}
            defaultValue={this.state.pickZoneName}
            onChange={e => this.setState({ pickZoneName: e.target.value })}
          />
          <TextField
            id="rows-input"
            label="Dock Id (from left dock tag)"
            variant="outlined"
            type="number"
            className={this.props.classes.narrowInput}
            defaultValue={this.state.pickZoneId}
            onChange={e =>
              this.setState({ pickZoneId: parseInt(e.target.value, 10) })
            }
          />
          <TextField
            id="rows-input"
            label="Ramp Length"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.rampLength}
            onChange={e =>
              this.setState({ rampLength: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Ramp Width"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.rampWidth}
            onChange={e =>
              this.setState({ rampWidth: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Ramp Wall Length"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.rampWallLength}
            onChange={e =>
              this.setState({ rampWallLength: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Ramp Lip to Bumpers"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.rampLipToBumpers}
            onChange={e =>
              this.setState({ rampLipToBumpers: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Ramp Lip to Hinge"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.lipToHinge}
            onChange={e =>
              this.setState({ lipToHinge: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Ramp Lip to Tag Line"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.rampLipToTagLine}
            onChange={e =>
              this.setState({ rampLipToTagLine: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Lip to Left Tag"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.lipToLeftTag}
            onChange={e =>
              this.setState({ lipToLeftTag: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Lip to Right Tag"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.lipToRightTag}
            onChange={e =>
              this.setState({ lipToRightTag: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Lip to Clear Height"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.lipToClearHeight}
            onChange={e =>
              this.setState({ lipToClearHeight: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Lip to Ramp Wall"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.lipToRampWall}
            onChange={e =>
              this.setState({ lipToRampWall: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Edge to Left Tag"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.edgeToLeftTag}
            onChange={e =>
              this.setState({ edgeToLeftTag: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Edge to Right Tag"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.edgeToRightTag}
            onChange={e =>
              this.setState({ edgeToRightTag: parseFloat(e.target.value) })
            }
          />
          <FormControl>
            <InputLabel id="has-ramp-walls-label">Has Ramp Walls?</InputLabel>
            <Select
              className={this.props.classes.normalInput}
              labelId="has-ramp-walls-label"
              id="has-ramp-walls-select"
              value={this.state.hasRampWalls}
              label="Has Ramp Walls?"
              onChange={e =>
                this.setState({ hasRampWalls: e.target.value === "true" })
              }
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Container>
        <Container className={this.props.classes.fieldGroup}>
          <TextField
            id="rows-input"
            label="Place Zone Name"
            variant="outlined"
            className={this.props.classes.wideInput}
            defaultValue={this.state.placeZoneName}
            onChange={e => this.setState({ placeZoneName: e.target.value })}
          />
          <FormControl>
            <InputLabel id="place-strategy-label">Place Strategy</InputLabel>
            <Select
              labelId="place-strategy-label"
              id="place-strategy-select"
              className={this.props.classes.normalInput}
              value={this.state.placeStrategy}
              label="Place Strategy"
              onChange={e =>
                this.setState({ placeStrategy: e.target.value as string })
              }
            >
              <MenuItem value="PLACE_LEFT">PLACE_LEFT</MenuItem>
              <MenuItem value="PLACE_RIGHT">PLACE_RIGHT</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="rows-input"
            label="Ramp to Place Zone X"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.rampToPlaceZoneX}
            onChange={e =>
              this.setState({ rampToPlaceZoneX: parseFloat(e.target.value) })
            }
          />
          <TextField
            id="rows-input"
            label="Ramp to Place Zone Y"
            variant="outlined"
            type="number"
            className={this.props.classes.normalInput}
            defaultValue={this.state.rampToPlaceZoneY}
            onChange={e =>
              this.setState({ rampToPlaceZoneY: parseFloat(e.target.value) })
            }
          />
          <FormControlLabel
            label="Use Omni Padding?"
            className={this.props.classes.normalInput}
            control={
              <Checkbox
                id="omni-padding-input"
                checked={this.state.omniPadding}
                onChange={e =>
                  this.setState({ omniPadding: e.target.checked })
                }
              />
            }
          />
          <FormControlLabel
            label="Use Split Place Zone?"
            className={this.props.classes.normalInput}
            control={
              <Checkbox
                id="split-place-zone-checkbox"
                checked={this.state.useSplitPlaceZone}
                onChange={e =>
                  this.setState({ useSplitPlaceZone: e.target.checked })
                }
              />
            }
          />
          {this.state.useSplitPlaceZone &&
            <>
              <TextField
                id="batch-size"
                label="Batch Size"
                className={this.props.classes.normalInput}
                variant="outlined"
                type="number"
                defaultValue={this.state.batchSize}
                onChange={e =>
                  this.setState({ batchSize: parseInt(e.target.value, 10) })
                }
              />
              <TextField
                id="num-batches"
                label="Number of Batches"
                className={this.props.classes.normalInput}
                variant="outlined"
                type="number"
                defaultValue={this.state.numberOfBatches}
                onChange={e =>
                  this.setState({ numberOfBatches: parseInt(e.target.value, 10) })
                }
              />
            </>
          }
          <FormControl>
            <InputLabel id="place-zone-template-label">
              Place Zone Template
            </InputLabel>
            <Select
              labelId="place-zone-template-label"
              id="place-zone-template-select"
              className={this.props.classes.normalInput}
              value={this.state.placeZoneTemplateName}
              label="Place Zone Template"
              onChange={e =>
                this.setState({
                  placeZoneTemplateName: e.target.value as string
                })
              }
            >
              {this.props.placeZoneTemplateNames.map((templateName: string) => {
                return <MenuItem value={templateName}>{templateName}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="pick-zone-template-label">
              Pick Zone Template
            </InputLabel>
            <Select
              labelId="pick-zone-template-label"
              id="pick-zone-template-select"
              className={this.props.classes.normalInput}
              value={this.state.pickZoneTemplateName}
              label="Pick Zone Template"
              onChange={e =>
                this.setState({
                  pickZoneTemplateName: e.target.value as string
                })
              }
            >
              {this.props.pickZoneTemplateNames.map((templateName: string) => {
                return <MenuItem value={templateName}>{templateName}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Container>
        <Container className={this.props.classes.buttonGroup}>
          <Button
            variant="contained"
            className={this.props.classes.button}
            color="primary"
            onClick={this._submit}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            className={this.props.classes.button}
            color="primary"
            onClick={this.props.cancel}
          >
            Cancel
          </Button>
        </Container>
      </div>
    );
  }
}

export default connect()(withStyles(styles)(DockConfigurationEditor));
