import { connect } from 'react-redux';
import RobotStatusPage from './RobotStatusPage';
import { ApplicationState } from '../../redux';
import { selectSites, selectRobotAccounts, selectRobotsBySite, selectRobotsByCohortFiltered } from '../../redux/selectors';

const mapStateToProps = (state: ApplicationState) => ({
  robotsByCohort: selectRobotsByCohortFiltered(state),
});

export default connect(mapStateToProps)(RobotStatusPage);
