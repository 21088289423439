import React, { FunctionComponent } from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { createStyles, withStyles, Theme, WithStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({

  });

interface Props extends WithStyles<typeof styles> {

}

const Component: FunctionComponent<Props & SvgIconProps> = (props: Props & SvgIconProps) => {
    return(
        <SvgIcon {...props} viewBox="100 1300 800 672.8" >
          <path fill="#e55c20" d="M503.9 1648.9l-344.8-344.8-56.7 250.6 247.8 247.8 153.7 153.8 404.3-404.4-59.6-247.9z"/>
          <path fill="none" stroke="#e55c20" strokeMiterlimit="10" d="M908.2 1551.9l-404.3 404.4-401.5-401.6 56.7-250.6 344.8 344.8L848.6 1304z"/>
          <path fill="#f7941d" d="M737 1375.9l-231.7 231.7-231.8-231.7z"/>
          <path fill="#121212" d="M615.4 1816.6l-110.1 110.2-110.2-110.2z"/>
          <path fill="#e55c20" d="M1058.3,1973h-28.5"/>
        </SvgIcon>  
    );
};

export default withStyles(styles)(Component);
