import React, { Component } from "react";

import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  NavLink as RouterLink
} from "react-router-dom";

import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CssBaseline from "@material-ui/core/CssBaseline";
import orange from "@material-ui/core/colors/orange";
import blueGrey from "@material-ui/core/colors/blueGrey";
import red from "@material-ui/core/colors/red";
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'

import { createApplicationStartedAction, logoutRequest } from "./redux/actions";

import LandingPage from "./components/LandingPage/LandingPage";
import ListRuns from "./components/ListRuns/ListRuns";
import ListPickStats from "./components/ListPickStats/ListPickStats";
import ListStoppages from "./components/ListInterventions/ListInterventions";
import ListRunStats from "./components/ListRunStats/ListRunStats";
import LogIn from "./components/Auth/LogIn";
import SignUp from "./components/Auth/SignUp";
import Unapproved from "./components/Auth/Unapproved";
import ChartTimeSeries from "./components/ChartTimeSeries/ChartTimeSeries";
import ChartTimeSeriesNew from "./components/ChartTimeSeries/ChartTimeSeriesNew";
import FaultChartPage from "./components/ChartFaultCountTImeSeries/FaultChartPage";
import CohortsDashboard from "./components/CohortsDashboard/CohortsDashboard";
import RobotsDashboard from "./components/RobotsDashboard/RobotsDashboard";
import ChartScalars from "./components/ChartScalars/ChartScalars";
import ManageConfigs from "./components/ManageConfigs/ManageConfigs";
import FaultConfiguration from "./components/FaultConfiguration/FaultConfiguration";
import TabletConfiguration from "./components/TabletConfiguration";

import * as paths from "./utils/Paths";
import { getTrelloToken, trelloApiKey } from "./utils/trello";
import { ApplicationState, ViewerState } from "./redux";
import RunPage from "./components/RunPage/RunPage";
import { Drawer, List, ListItem, ListItemText } from "@material-ui/core";
import ListExternalBugs from "./components/ListExternalBugs/ListExternalBugs";
import ListTrailerStats from "./components/ListTrailerStats/ListTrailerStats";
import OperationsPage from "./components/Operations/OperationsPage";
import EnvironmentConfigPage from "./components/EnvironmentConfigs/EnvironmentConfigPage";
import ListFaultsPage from "./components/ListFaults/ListFaultsPage";
import ListTrailerStatsTest from "./components/ListTrailerStats/ListTrailerStatsTest";
import ListInterventions from "./components/ListInterventions/ListInterventionsNew";
import LogUploadsPage from "./components/LogUploads/LogUploadsPage";
import AppMenuItem from "./components/Utils/AppMenuItem";
import FaultBasedAlertsPage from "./components/FaultBasedAlerts/FaultBasedAlerts";
import RobotStatusPage from "./components/RobotStatusPage";

export const primary = orange;
export const secondary = blueGrey;

const theme: Theme = createMuiTheme({
  palette: {
    primary,
    secondary,
    error: red
  },
  typography: {}
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: 0,
      height: "100vh",
      width: "100%",
      overflow: "auto"
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20
    },
    content: {
      padding: 32
    }
  });

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
  viewer: ViewerState;
}
interface State {
  drawerOpen: boolean;
  subDrawerOpen: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  return { viewer: state.viewer };
};

class App extends Component<Props, State> {
  state = {
    drawerOpen: false,
    subDrawerOpen: false
  };

  componentDidMount() {
    this.props.dispatch(createApplicationStartedAction());
  }

  render() {
    const { classes, viewer } = this.props;
    const { drawerOpen, subDrawerOpen } = this.state;
    const allowTrello = false;
    return (
      <Router>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <main id="main" className={classes.root}>
            <Drawer
              onBackdropClick={() => this.setState({ drawerOpen: false })}
              open={drawerOpen}
            >
              <List>
                <ListItem
                  onClick={() => this.setState({ drawerOpen: false })}
                  {...{ to: paths.listPickStats() }}
                  component={RouterLink as any}
                  button={true}
                >
                  <ListItemText>Per-Pick Stats</ListItemText>
                </ListItem>
                <ListItem
                  onClick={() => this.setState({ drawerOpen: false })}
                  {...{ to: paths.listStoppages() }}
                  component={RouterLink as any}
                  button={true}
                >
                  <ListItemText>Stoppages</ListItemText>
                </ListItem>
                <ListItem
                  onClick={() => this.setState({ drawerOpen: false })}
                  {...{ to: paths.listTrailerStats() }}
                  component={RouterLink as any}
                  button={true}
                >
                  <ListItemText>Trailer Stats</ListItemText>
                </ListItem>
                <ListItem
                  onClick={() => this.setState({ drawerOpen: false })}
                  {...{ to: paths.listRunStats() }}
                  component={RouterLink as any}
                  button={true}
                >
                  <ListItemText>Run Stats</ListItemText>
                </ListItem>
                <ListItem
                  onClick={() => this.setState({ drawerOpen: false })}
                  {...{ to: paths.listInterventions() }}
                  component={RouterLink as any}
                  button={true}
                >
                  <ListItemText>Interventions</ListItemText>
                </ListItem>
                {viewer.account && viewer.account.isStaff && (
                  <>
                    <AppMenuItem
                      onClick={() => this.setState({drawerOpen: false})}
                      text={"Data Visualizations"}
                      path={""}
                      childMenuItems={
                        [
                          {onClick: () => this.setState({drawerOpen: false}), text: "Time Series", path: paths.metricTimeSeries()},
                          {onClick: () => this.setState({drawerOpen: false}), text: "Interactive Time Series", path: paths.metricTimeSeriesInteractive()},
                          {onClick: () => this.setState({drawerOpen: false}), text: "Fault Charts", path: paths.metricFaultCountTimeSeries()},
                        ]
                      }
                    />
                    <AppMenuItem
                      onClick={() => this.setState({drawerOpen: false})}
                      text={"Tools"}
                      path={""}
                      childMenuItems={
                        [
                          {onClick: () => this.setState({drawerOpen: false}), text: "Log Uploads", path: paths.logUploads()},
                          {onClick: () => this.setState({drawerOpen: false}), text: "Fault-Based Alerts", path: paths.faultBasedAlerts()},
                          {onClick: () => this.setState({drawerOpen: false}), text: "Generate Environment Configs", path: paths.environmentConfigs()},
                        ]
                      }
                    />
                    <AppMenuItem
                      onClick={() => this.setState({drawerOpen: false})}
                      text={"Fleet Management"}
                      path={""}
                      childMenuItems={
                        [
                          {onClick: () => this.setState({drawerOpen: false}), text: "Robots", path: paths.robotsDashboard()},
                          {onClick: () => this.setState({drawerOpen: false}), text: "Cohorts", path: paths.cohortsDashboard()},
                          {onClick: () => this.setState({drawerOpen: false}), text: "Robot Status", path: paths.robotStatus()},
                          {onClick: () => this.setState({drawerOpen: false}), text: "Config Files", path: paths.manageConfigs()},
                          {onClick: () => this.setState({drawerOpen: false}), text: "Operations", path: paths.operationsPage()},
                          {onClick: () => this.setState({drawerOpen: false}), text: "Fault Configuration", path: paths.faultConfiguration()},
                          {onClick: () => this.setState({drawerOpen: false}), text: "Tablet Configuration", path: paths.tabletConfiguration()},

                        ]
                      }
                    />
                    <ListItem
                      onClick={() => this.setState({ drawerOpen: false })}
                      {...{ to: paths.listRuns() }}
                      component={RouterLink as any}
                      button={true}
                    >
                      <ListItemText>Runs</ListItemText>
                    </ListItem>
                    <ListItem
                      onClick={() => this.setState({ drawerOpen: false })}
                      {...{ to: paths.faultStats() }}
                      component={RouterLink as any}
                      button={true}
                    >
                      <ListItemText>Fault Stats</ListItemText>
                    </ListItem>
                    <ListItem
                      onClick={() => this.setState({ drawerOpen: false })}
                      {...{ to: paths.listTrailerStatsTest() }}
                      component={RouterLink as any}
                      button={true}
                    >
                      <ListItemText>Trailer Stats Expanded</ListItemText>
                    </ListItem>
                  </>
                )}
              </List>
            </Drawer>
            <AppBar position="static" color="secondary">
              <Toolbar>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Menu"
                  onClick={() => this.setState({ drawerOpen: !drawerOpen })}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.grow}
                >
                  ForkMon
                </Typography>
                {allowTrello && getTrelloToken() === undefined && (
                  <Button
                    color="inherit"
                    onClick={() =>
                      window.location.replace(
                        `https://trello.com/1/authorize?expiration=1day&name=DebugVisToken&scope=read,write&response_type=fragment&key=${trelloApiKey}&return_url=${window.location.href}`
                      )
                    }
                  >
                    Connect Trello
                  </Button>
                )}
                {!viewer.account && (
                  <Button color="primary" href={paths.logInPath()}>
                    Log In
                  </Button>
                )}
                {viewer.account && (
                  <Button
                    color="inherit"
                    onClick={() => this.props.dispatch(logoutRequest())}
                  >
                    Log Out
                  </Button>
                )}
              </Toolbar>
            </AppBar>
            <Route exact path={paths.landingPath()} component={LandingPage} />
            <Route exact path={paths.faultBasedAlerts()} component={FaultBasedAlertsPage} />
            <Route exact path={paths.listRuns()} component={ListRuns} />
            <Route
              exact
              path={paths.listExternalBugs()}
              component={ListExternalBugs}
            />
            <Route
              exact
              path={paths.listPickStats()}
              component={ListPickStats}
            />
            <Route
              exact
              path={paths.listStoppages()}
              component={ListStoppages}
            />
            <Route
              exact
              path={paths.listInterventions()}
              component={ListInterventions}
            />

            <Route exact path={paths.listRunStats()} component={ListRunStats} />
            <Route
              exact
              path={paths.listTrailerStats()}
              component={ListTrailerStats}
            />
            <Route
              exact
              path={paths.listTrailerStatsTest()}
              component={ListTrailerStatsTest}
            />
            <Route path={paths.logInPath()} component={LogIn} />
            <Route path={paths.signUpPath()} component={SignUp} />
            <Route path={paths.unapproved()} component={Unapproved} />
            <Route path={paths.logUploads()} component={LogUploadsPage} />
            <Route path={paths.robotStatus()} component={RobotStatusPage} />
            <Route path={paths.runPattern()} component={RunPage} />
            <Route
              path={paths.cohortsDashboard()}
              component={CohortsDashboard}
            />
            <Route path={paths.robotsDashboard()} component={RobotsDashboard} />
            <Route
              path={paths.metricTimeSeries()}
              component={ChartTimeSeries}
            />
            <Route
              path={paths.metricTimeSeriesInteractive()}
              component={ChartTimeSeriesNew}
            />
            <Route
              exact
              path={paths.manageConfigs()}
              component={ManageConfigs}
            />
            <Route
              exact
              path={paths.faultConfiguration()}
              component={FaultConfiguration}
            />
            <Route
              exact
              path={paths.tabletConfiguration()}
              component={TabletConfiguration}
            />
            <Route
              exact
              path={paths.operationsPage()}
              component={OperationsPage}
            />
            <Route
              exact
              path={paths.environmentConfigs()}
              component={EnvironmentConfigPage}
            />
            <Route exact path={paths.faultStats()} component={ListFaultsPage} />
            <Route
              path={paths.metricFaultCountTimeSeries()}
              component={FaultChartPage}
            />
            <Route exact path={paths.metrics()} component={ChartTimeSeries} />
          </main>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(App));
