// source: detection/proto/detection.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var geometry_proto_transform_pb = require('../../geometry/proto/transform_pb.js');
goog.object.extend(proto, geometry_proto_transform_pb);
var geometry_proto_geometry_pb = require('../../geometry/proto/geometry_pb.js');
goog.object.extend(proto, geometry_proto_geometry_pb);
goog.exportSymbol('proto.fox.proto.AnchorTag', null, global);
goog.exportSymbol('proto.fox.proto.BackWallDetectorState', null, global);
goog.exportSymbol('proto.fox.proto.ConveyorZoneConfig', null, global);
goog.exportSymbol('proto.fox.proto.Detections', null, global);
goog.exportSymbol('proto.fox.proto.ForksDetectionState', null, global);
goog.exportSymbol('proto.fox.proto.ImuAngles', null, global);
goog.exportSymbol('proto.fox.proto.LidarLevelState', null, global);
goog.exportSymbol('proto.fox.proto.LidarLevelState.LidarLevelness', null, global);
goog.exportSymbol('proto.fox.proto.LoadHeightHistory', null, global);
goog.exportSymbol('proto.fox.proto.NoGoZone', null, global);
goog.exportSymbol('proto.fox.proto.NoGoZoneConfig', null, global);
goog.exportSymbol('proto.fox.proto.OpenSpaceObservation', null, global);
goog.exportSymbol('proto.fox.proto.OpenSpaceObservation.ObservationType', null, global);
goog.exportSymbol('proto.fox.proto.PaddingOverride', null, global);
goog.exportSymbol('proto.fox.proto.PalletDetection', null, global);
goog.exportSymbol('proto.fox.proto.PalletDetection.PalletDetectionType', null, global);
goog.exportSymbol('proto.fox.proto.PalletObservation', null, global);
goog.exportSymbol('proto.fox.proto.PalletObservation.ObservationType', null, global);
goog.exportSymbol('proto.fox.proto.PalletWeightEstimate', null, global);
goog.exportSymbol('proto.fox.proto.PerceptionState', null, global);
goog.exportSymbol('proto.fox.proto.PersistentTag', null, global);
goog.exportSymbol('proto.fox.proto.PersonDetection', null, global);
goog.exportSymbol('proto.fox.proto.PersonDetections', null, global);
goog.exportSymbol('proto.fox.proto.PickPlaceZone', null, global);
goog.exportSymbol('proto.fox.proto.PickPlaceZone.Error', null, global);
goog.exportSymbol('proto.fox.proto.PlaceLocation', null, global);
goog.exportSymbol('proto.fox.proto.PlaceLocation.Status', null, global);
goog.exportSymbol('proto.fox.proto.PlaceZoneConfig', null, global);
goog.exportSymbol('proto.fox.proto.PlaceZoneConfig.EnableStatus', null, global);
goog.exportSymbol('proto.fox.proto.PlaceZoneConfig.LocationOverride', null, global);
goog.exportSymbol('proto.fox.proto.PlaceZoneConfig.Orientation', null, global);
goog.exportSymbol('proto.fox.proto.PlaceZoneConfig.PlacePattern', null, global);
goog.exportSymbol('proto.fox.proto.PlaceZoneConfig.PlaceStrategy', null, global);
goog.exportSymbol('proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation', null, global);
goog.exportSymbol('proto.fox.proto.PlaceZoneIndex', null, global);
goog.exportSymbol('proto.fox.proto.Pocket', null, global);
goog.exportSymbol('proto.fox.proto.PoseTimePoint2f', null, global);
goog.exportSymbol('proto.fox.proto.RampConfig', null, global);
goog.exportSymbol('proto.fox.proto.RampPickConfig', null, global);
goog.exportSymbol('proto.fox.proto.RampPickConfig.PickType', null, global);
goog.exportSymbol('proto.fox.proto.SlowDetections', null, global);
goog.exportSymbol('proto.fox.proto.SupportType', null, global);
goog.exportSymbol('proto.fox.proto.TagDetection', null, global);
goog.exportSymbol('proto.fox.proto.TrackedPallet', null, global);
goog.exportSymbol('proto.fox.proto.TrackedPallet.PalletExceptionTypes', null, global);
goog.exportSymbol('proto.fox.proto.TrackedPallet.PalletLocation', null, global);
goog.exportSymbol('proto.fox.proto.TrackedPallet.PalletSupportType', null, global);
goog.exportSymbol('proto.fox.proto.TrackedTag', null, global);
goog.exportSymbol('proto.fox.proto.TrailerStatus', null, global);
goog.exportSymbol('proto.fox.proto.TruckAlignmentState', null, global);
goog.exportSymbol('proto.fox.proto.UntrackedPalletDetection', null, global);
goog.exportSymbol('proto.fox.proto.WeatherGuards', null, global);
goog.exportSymbol('proto.fox.proto.ZoneCondition', null, global);
goog.exportSymbol('proto.fox.proto.ZoneCondition.RequirementLevel', null, global);
goog.exportSymbol('proto.fox.proto.ZoneConfig', null, global);
goog.exportSymbol('proto.fox.proto.ZoneConfig.ZoneType', null, global);
goog.exportSymbol('proto.fox.proto.ZoneRegion', null, global);
goog.exportSymbol('proto.fox.proto.ZoneTrait', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PaddingOverride = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PaddingOverride, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PaddingOverride.displayName = 'proto.fox.proto.PaddingOverride';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlaceZoneIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PlaceZoneIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlaceZoneIndex.displayName = 'proto.fox.proto.PlaceZoneIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.AnchorTag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.AnchorTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.AnchorTag.displayName = 'proto.fox.proto.AnchorTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlaceZoneConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.PlaceZoneConfig.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.PlaceZoneConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlaceZoneConfig.displayName = 'proto.fox.proto.PlaceZoneConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.PlaceZoneConfig.LocationOverride.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.PlaceZoneConfig.LocationOverride, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlaceZoneConfig.LocationOverride.displayName = 'proto.fox.proto.PlaceZoneConfig.LocationOverride';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.displayName = 'proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.RampPickConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.RampPickConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.RampPickConfig.displayName = 'proto.fox.proto.RampPickConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.RampConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.RampConfig.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.RampConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.RampConfig.displayName = 'proto.fox.proto.RampConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ConveyorZoneConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.ConveyorZoneConfig.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.ConveyorZoneConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ConveyorZoneConfig.displayName = 'proto.fox.proto.ConveyorZoneConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ZoneConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.ZoneConfig.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.ZoneConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ZoneConfig.displayName = 'proto.fox.proto.ZoneConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.TagDetection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.TagDetection.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.TagDetection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.TagDetection.displayName = 'proto.fox.proto.TagDetection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PalletDetection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.PalletDetection.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.PalletDetection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PalletDetection.displayName = 'proto.fox.proto.PalletDetection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PersonDetection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PersonDetection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PersonDetection.displayName = 'proto.fox.proto.PersonDetection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PersonDetections = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.PersonDetections.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.PersonDetections, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PersonDetections.displayName = 'proto.fox.proto.PersonDetections';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.TrailerStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.TrailerStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.TrailerStatus.displayName = 'proto.fox.proto.TrailerStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SlowDetections = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.SlowDetections.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.SlowDetections, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SlowDetections.displayName = 'proto.fox.proto.SlowDetections';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.UntrackedPalletDetection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.UntrackedPalletDetection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.UntrackedPalletDetection.displayName = 'proto.fox.proto.UntrackedPalletDetection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Detections = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.Detections.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.Detections, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Detections.displayName = 'proto.fox.proto.Detections';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LoadHeightHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.LoadHeightHistory.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.LoadHeightHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LoadHeightHistory.displayName = 'proto.fox.proto.LoadHeightHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PalletObservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PalletObservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PalletObservation.displayName = 'proto.fox.proto.PalletObservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.OpenSpaceObservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.OpenSpaceObservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.OpenSpaceObservation.displayName = 'proto.fox.proto.OpenSpaceObservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Pocket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.Pocket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Pocket.displayName = 'proto.fox.proto.Pocket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PalletWeightEstimate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PalletWeightEstimate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PalletWeightEstimate.displayName = 'proto.fox.proto.PalletWeightEstimate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.TrackedPallet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.TrackedPallet.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.TrackedPallet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.TrackedPallet.displayName = 'proto.fox.proto.TrackedPallet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.TrackedTag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.TrackedTag.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.TrackedTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.TrackedTag.displayName = 'proto.fox.proto.TrackedTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PersistentTag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PersistentTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PersistentTag.displayName = 'proto.fox.proto.PersistentTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ZoneCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ZoneCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ZoneCondition.displayName = 'proto.fox.proto.ZoneCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ZoneRegion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ZoneRegion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ZoneRegion.displayName = 'proto.fox.proto.ZoneRegion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.TruckAlignmentState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.TruckAlignmentState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.TruckAlignmentState.displayName = 'proto.fox.proto.TruckAlignmentState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ImuAngles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.ImuAngles.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.ImuAngles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ImuAngles.displayName = 'proto.fox.proto.ImuAngles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlaceLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.PlaceLocation.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.PlaceLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlaceLocation.displayName = 'proto.fox.proto.PlaceLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.WeatherGuards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.WeatherGuards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.WeatherGuards.displayName = 'proto.fox.proto.WeatherGuards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.BackWallDetectorState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.BackWallDetectorState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.BackWallDetectorState.displayName = 'proto.fox.proto.BackWallDetectorState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PickPlaceZone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.PickPlaceZone.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.PickPlaceZone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PickPlaceZone.displayName = 'proto.fox.proto.PickPlaceZone';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.NoGoZoneConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.NoGoZoneConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.NoGoZoneConfig.displayName = 'proto.fox.proto.NoGoZoneConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.NoGoZone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.NoGoZone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.NoGoZone.displayName = 'proto.fox.proto.NoGoZone';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PoseTimePoint2f = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PoseTimePoint2f, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PoseTimePoint2f.displayName = 'proto.fox.proto.PoseTimePoint2f';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.LidarLevelState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.LidarLevelState.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.LidarLevelState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.LidarLevelState.displayName = 'proto.fox.proto.LidarLevelState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ForksDetectionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.ForksDetectionState.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.ForksDetectionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ForksDetectionState.displayName = 'proto.fox.proto.ForksDetectionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PerceptionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PerceptionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PerceptionState.displayName = 'proto.fox.proto.PerceptionState';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PaddingOverride.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PaddingOverride.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PaddingOverride} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PaddingOverride.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    padding: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PaddingOverride}
 */
proto.fox.proto.PaddingOverride.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PaddingOverride;
  return proto.fox.proto.PaddingOverride.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PaddingOverride} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PaddingOverride}
 */
proto.fox.proto.PaddingOverride.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPadding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PaddingOverride.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PaddingOverride.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PaddingOverride} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PaddingOverride.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPadding();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.fox.proto.PaddingOverride.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PaddingOverride} returns this
 */
proto.fox.proto.PaddingOverride.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float padding = 2;
 * @return {number}
 */
proto.fox.proto.PaddingOverride.prototype.getPadding = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PaddingOverride} returns this
 */
proto.fox.proto.PaddingOverride.prototype.setPadding = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlaceZoneIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlaceZoneIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlaceZoneIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceZoneIndex.toObject = function(includeInstance, msg) {
  var f, obj = {
    row: jspb.Message.getFieldWithDefault(msg, 1, 0),
    column: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlaceZoneIndex}
 */
proto.fox.proto.PlaceZoneIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlaceZoneIndex;
  return proto.fox.proto.PlaceZoneIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlaceZoneIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlaceZoneIndex}
 */
proto.fox.proto.PlaceZoneIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRow(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setColumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlaceZoneIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlaceZoneIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlaceZoneIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceZoneIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRow();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getColumn();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 row = 1;
 * @return {number}
 */
proto.fox.proto.PlaceZoneIndex.prototype.getRow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceZoneIndex} returns this
 */
proto.fox.proto.PlaceZoneIndex.prototype.setRow = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 column = 2;
 * @return {number}
 */
proto.fox.proto.PlaceZoneIndex.prototype.getColumn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceZoneIndex} returns this
 */
proto.fox.proto.PlaceZoneIndex.prototype.setColumn = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.AnchorTag.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.AnchorTag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.AnchorTag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.AnchorTag.toObject = function(includeInstance, msg) {
  var f, obj = {
    origin: (f = msg.getOrigin()) && geometry_proto_transform_pb.Vector2f.toObject(includeInstance, f),
    tagId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.AnchorTag}
 */
proto.fox.proto.AnchorTag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.AnchorTag;
  return proto.fox.proto.AnchorTag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.AnchorTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.AnchorTag}
 */
proto.fox.proto.AnchorTag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Vector2f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2f.deserializeBinaryFromReader);
      msg.setOrigin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTagId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.AnchorTag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.AnchorTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.AnchorTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.AnchorTag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrigin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Vector2f.serializeBinaryToWriter
    );
  }
  f = message.getTagId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional Vector2f origin = 1;
 * @return {?proto.fox.proto.Vector2f}
 */
proto.fox.proto.AnchorTag.prototype.getOrigin = function() {
  return /** @type{?proto.fox.proto.Vector2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector2f, 1));
};


/**
 * @param {?proto.fox.proto.Vector2f|undefined} value
 * @return {!proto.fox.proto.AnchorTag} returns this
*/
proto.fox.proto.AnchorTag.prototype.setOrigin = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.AnchorTag} returns this
 */
proto.fox.proto.AnchorTag.prototype.clearOrigin = function() {
  return this.setOrigin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.AnchorTag.prototype.hasOrigin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 tag_id = 2;
 * @return {number}
 */
proto.fox.proto.AnchorTag.prototype.getTagId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.AnchorTag} returns this
 */
proto.fox.proto.AnchorTag.prototype.setTagId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.PlaceZoneConfig.repeatedFields_ = [11,8,9,17,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlaceZoneConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlaceZoneConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlaceZoneConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceZoneConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    numColumns: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numRows: jspb.Message.getFieldWithDefault(msg, 2, 0),
    columnPadding: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    rowPadding: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    omniPadding: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    orientationList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    palletShape: (f = msg.getPalletShape()) && geometry_proto_transform_pb.Vector3f.toObject(includeInstance, f),
    columnPaddingOverrideList: jspb.Message.toObjectList(msg.getColumnPaddingOverrideList(),
    proto.fox.proto.PaddingOverride.toObject, includeInstance),
    rowPaddingOverrideList: jspb.Message.toObjectList(msg.getRowPaddingOverrideList(),
    proto.fox.proto.PaddingOverride.toObject, includeInstance),
    locationOverrideList: jspb.Message.toObjectList(msg.getLocationOverrideList(),
    proto.fox.proto.PlaceZoneConfig.LocationOverride.toObject, includeInstance),
    leftToRight: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    placeStrategyList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    placePattern: jspb.Message.getFieldWithDefault(msg, 13, 0),
    filterMin: (f = msg.getFilterMin()) && proto.fox.proto.PlaceZoneIndex.toObject(includeInstance, f),
    filterMax: (f = msg.getFilterMax()) && proto.fox.proto.PlaceZoneIndex.toObject(includeInstance, f),
    minMajorAisleWidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    minMinorAisleWidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    splitPlaceZoneInformation: (f = msg.getSplitPlaceZoneInformation()) && proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlaceZoneConfig}
 */
proto.fox.proto.PlaceZoneConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlaceZoneConfig;
  return proto.fox.proto.PlaceZoneConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlaceZoneConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlaceZoneConfig}
 */
proto.fox.proto.PlaceZoneConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumColumns(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumRows(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setColumnPadding(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRowPadding(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOmniPadding(value);
      break;
    case 11:
      var value = /** @type {!Array<!proto.fox.proto.PlaceZoneConfig.Orientation>} */ (reader.readPackedEnum());
      msg.setOrientationList(value);
      break;
    case 7:
      var value = new geometry_proto_transform_pb.Vector3f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector3f.deserializeBinaryFromReader);
      msg.setPalletShape(value);
      break;
    case 8:
      var value = new proto.fox.proto.PaddingOverride;
      reader.readMessage(value,proto.fox.proto.PaddingOverride.deserializeBinaryFromReader);
      msg.addColumnPaddingOverride(value);
      break;
    case 9:
      var value = new proto.fox.proto.PaddingOverride;
      reader.readMessage(value,proto.fox.proto.PaddingOverride.deserializeBinaryFromReader);
      msg.addRowPaddingOverride(value);
      break;
    case 17:
      var value = new proto.fox.proto.PlaceZoneConfig.LocationOverride;
      reader.readMessage(value,proto.fox.proto.PlaceZoneConfig.LocationOverride.deserializeBinaryFromReader);
      msg.addLocationOverride(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLeftToRight(value);
      break;
    case 12:
      var value = /** @type {!Array<!proto.fox.proto.PlaceZoneConfig.PlaceStrategy>} */ (reader.readPackedEnum());
      msg.setPlaceStrategyList(value);
      break;
    case 13:
      var value = /** @type {!proto.fox.proto.PlaceZoneConfig.PlacePattern} */ (reader.readEnum());
      msg.setPlacePattern(value);
      break;
    case 14:
      var value = new proto.fox.proto.PlaceZoneIndex;
      reader.readMessage(value,proto.fox.proto.PlaceZoneIndex.deserializeBinaryFromReader);
      msg.setFilterMin(value);
      break;
    case 15:
      var value = new proto.fox.proto.PlaceZoneIndex;
      reader.readMessage(value,proto.fox.proto.PlaceZoneIndex.deserializeBinaryFromReader);
      msg.setFilterMax(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinMajorAisleWidth(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinMinorAisleWidth(value);
      break;
    case 20:
      var value = new proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation;
      reader.readMessage(value,proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.deserializeBinaryFromReader);
      msg.setSplitPlaceZoneInformation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlaceZoneConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlaceZoneConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlaceZoneConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceZoneConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumColumns();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNumRows();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getColumnPadding();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getRowPadding();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getOmniPadding();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getOrientationList();
  if (f.length > 0) {
    writer.writePackedEnum(
      11,
      f
    );
  }
  f = message.getPalletShape();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      geometry_proto_transform_pb.Vector3f.serializeBinaryToWriter
    );
  }
  f = message.getColumnPaddingOverrideList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.fox.proto.PaddingOverride.serializeBinaryToWriter
    );
  }
  f = message.getRowPaddingOverrideList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.fox.proto.PaddingOverride.serializeBinaryToWriter
    );
  }
  f = message.getLocationOverrideList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.fox.proto.PlaceZoneConfig.LocationOverride.serializeBinaryToWriter
    );
  }
  f = message.getLeftToRight();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPlaceStrategyList();
  if (f.length > 0) {
    writer.writePackedEnum(
      12,
      f
    );
  }
  f = message.getPlacePattern();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getFilterMin();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.fox.proto.PlaceZoneIndex.serializeBinaryToWriter
    );
  }
  f = message.getFilterMax();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.fox.proto.PlaceZoneIndex.serializeBinaryToWriter
    );
  }
  f = message.getMinMajorAisleWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getMinMinorAisleWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getSplitPlaceZoneInformation();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.PlaceZoneConfig.Orientation = {
  STRAIGHT: 0,
  TURNED: 1,
  OMNI: 2
};

/**
 * @enum {number}
 */
proto.fox.proto.PlaceZoneConfig.PlaceStrategy = {
  PLACE_STRAIGHT: 0,
  PLACE_LEFT: 1,
  PLACE_RIGHT: 2
};

/**
 * @enum {number}
 */
proto.fox.proto.PlaceZoneConfig.PlacePattern = {
  PLACE_PATTERN_ROW: 0,
  PLACE_PATTERN_COLUMN: 1,
  PLACE_PATTERN_STAIRSTEP_ROW: 2,
  PLACE_PATTERN_STAIRSTEP_COLUMN: 3
};

/**
 * @enum {number}
 */
proto.fox.proto.PlaceZoneConfig.EnableStatus = {
  ENABLE_DEFAULT: 0,
  ENABLE_FALSE: 1,
  ENABLE_TRUE: 2
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlaceZoneConfig.LocationOverride.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlaceZoneConfig.LocationOverride} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: (f = msg.getIndex()) && proto.fox.proto.PlaceZoneIndex.toObject(includeInstance, f),
    placeOrientationList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    placeStrategyList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    enableStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderAfter: (f = msg.getOrderAfter()) && proto.fox.proto.PlaceZoneIndex.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride}
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlaceZoneConfig.LocationOverride;
  return proto.fox.proto.PlaceZoneConfig.LocationOverride.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlaceZoneConfig.LocationOverride} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride}
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.PlaceZoneIndex;
      reader.readMessage(value,proto.fox.proto.PlaceZoneIndex.deserializeBinaryFromReader);
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {!Array<!proto.fox.proto.PlaceZoneConfig.Orientation>} */ (reader.readPackedEnum());
      msg.setPlaceOrientationList(value);
      break;
    case 3:
      var value = /** @type {!Array<!proto.fox.proto.PlaceZoneConfig.PlaceStrategy>} */ (reader.readPackedEnum());
      msg.setPlaceStrategyList(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.PlaceZoneConfig.EnableStatus} */ (reader.readEnum());
      msg.setEnableStatus(value);
      break;
    case 5:
      var value = new proto.fox.proto.PlaceZoneIndex;
      reader.readMessage(value,proto.fox.proto.PlaceZoneIndex.deserializeBinaryFromReader);
      msg.setOrderAfter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlaceZoneConfig.LocationOverride.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlaceZoneConfig.LocationOverride} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.PlaceZoneIndex.serializeBinaryToWriter
    );
  }
  f = message.getPlaceOrientationList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getPlaceStrategyList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getEnableStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOrderAfter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.fox.proto.PlaceZoneIndex.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaceZoneIndex index = 1;
 * @return {?proto.fox.proto.PlaceZoneIndex}
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.getIndex = function() {
  return /** @type{?proto.fox.proto.PlaceZoneIndex} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlaceZoneIndex, 1));
};


/**
 * @param {?proto.fox.proto.PlaceZoneIndex|undefined} value
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride} returns this
*/
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.setIndex = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride} returns this
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.clearIndex = function() {
  return this.setIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.hasIndex = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Orientation place_orientation = 2;
 * @return {!Array<!proto.fox.proto.PlaceZoneConfig.Orientation>}
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.getPlaceOrientationList = function() {
  return /** @type {!Array<!proto.fox.proto.PlaceZoneConfig.Orientation>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.fox.proto.PlaceZoneConfig.Orientation>} value
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride} returns this
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.setPlaceOrientationList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.fox.proto.PlaceZoneConfig.Orientation} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride} returns this
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.addPlaceOrientation = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride} returns this
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.clearPlaceOrientationList = function() {
  return this.setPlaceOrientationList([]);
};


/**
 * repeated PlaceStrategy place_strategy = 3;
 * @return {!Array<!proto.fox.proto.PlaceZoneConfig.PlaceStrategy>}
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.getPlaceStrategyList = function() {
  return /** @type {!Array<!proto.fox.proto.PlaceZoneConfig.PlaceStrategy>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.fox.proto.PlaceZoneConfig.PlaceStrategy>} value
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride} returns this
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.setPlaceStrategyList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.fox.proto.PlaceZoneConfig.PlaceStrategy} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride} returns this
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.addPlaceStrategy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride} returns this
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.clearPlaceStrategyList = function() {
  return this.setPlaceStrategyList([]);
};


/**
 * optional EnableStatus enable_status = 4;
 * @return {!proto.fox.proto.PlaceZoneConfig.EnableStatus}
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.getEnableStatus = function() {
  return /** @type {!proto.fox.proto.PlaceZoneConfig.EnableStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.PlaceZoneConfig.EnableStatus} value
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride} returns this
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.setEnableStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PlaceZoneIndex order_after = 5;
 * @return {?proto.fox.proto.PlaceZoneIndex}
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.getOrderAfter = function() {
  return /** @type{?proto.fox.proto.PlaceZoneIndex} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlaceZoneIndex, 5));
};


/**
 * @param {?proto.fox.proto.PlaceZoneIndex|undefined} value
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride} returns this
*/
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.setOrderAfter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride} returns this
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.clearOrderAfter = function() {
  return this.setOrderAfter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlaceZoneConfig.LocationOverride.prototype.hasOrderAfter = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    useSplitPlaceZone: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    batchSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numberOfBatches: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation}
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation;
  return proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation}
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSplitPlaceZone(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatchSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfBatches(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUseSplitPlaceZone();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBatchSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNumberOfBatches();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional bool use_split_place_zone = 1;
 * @return {boolean}
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.prototype.getUseSplitPlaceZone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation} returns this
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.prototype.setUseSplitPlaceZone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 batch_size = 2;
 * @return {number}
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.prototype.getBatchSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation} returns this
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.prototype.setBatchSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 number_of_batches = 3;
 * @return {number}
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.prototype.getNumberOfBatches = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation} returns this
 */
proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation.prototype.setNumberOfBatches = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 num_columns = 1;
 * @return {number}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getNumColumns = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.setNumColumns = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 num_rows = 2;
 * @return {number}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getNumRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.setNumRows = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float column_padding = 3;
 * @return {number}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getColumnPadding = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.setColumnPadding = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float row_padding = 4;
 * @return {number}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getRowPadding = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.setRowPadding = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool omni_padding = 16;
 * @return {boolean}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getOmniPadding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.setOmniPadding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * repeated Orientation orientation = 11;
 * @return {!Array<!proto.fox.proto.PlaceZoneConfig.Orientation>}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getOrientationList = function() {
  return /** @type {!Array<!proto.fox.proto.PlaceZoneConfig.Orientation>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<!proto.fox.proto.PlaceZoneConfig.Orientation>} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.setOrientationList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!proto.fox.proto.PlaceZoneConfig.Orientation} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.addOrientation = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.clearOrientationList = function() {
  return this.setOrientationList([]);
};


/**
 * optional Vector3f pallet_shape = 7;
 * @return {?proto.fox.proto.Vector3f}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getPalletShape = function() {
  return /** @type{?proto.fox.proto.Vector3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector3f, 7));
};


/**
 * @param {?proto.fox.proto.Vector3f|undefined} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
*/
proto.fox.proto.PlaceZoneConfig.prototype.setPalletShape = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.clearPalletShape = function() {
  return this.setPalletShape(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlaceZoneConfig.prototype.hasPalletShape = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated PaddingOverride column_padding_override = 8;
 * @return {!Array<!proto.fox.proto.PaddingOverride>}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getColumnPaddingOverrideList = function() {
  return /** @type{!Array<!proto.fox.proto.PaddingOverride>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.PaddingOverride, 8));
};


/**
 * @param {!Array<!proto.fox.proto.PaddingOverride>} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
*/
proto.fox.proto.PlaceZoneConfig.prototype.setColumnPaddingOverrideList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.fox.proto.PaddingOverride=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PaddingOverride}
 */
proto.fox.proto.PlaceZoneConfig.prototype.addColumnPaddingOverride = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.fox.proto.PaddingOverride, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.clearColumnPaddingOverrideList = function() {
  return this.setColumnPaddingOverrideList([]);
};


/**
 * repeated PaddingOverride row_padding_override = 9;
 * @return {!Array<!proto.fox.proto.PaddingOverride>}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getRowPaddingOverrideList = function() {
  return /** @type{!Array<!proto.fox.proto.PaddingOverride>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.PaddingOverride, 9));
};


/**
 * @param {!Array<!proto.fox.proto.PaddingOverride>} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
*/
proto.fox.proto.PlaceZoneConfig.prototype.setRowPaddingOverrideList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.fox.proto.PaddingOverride=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PaddingOverride}
 */
proto.fox.proto.PlaceZoneConfig.prototype.addRowPaddingOverride = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.fox.proto.PaddingOverride, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.clearRowPaddingOverrideList = function() {
  return this.setRowPaddingOverrideList([]);
};


/**
 * repeated LocationOverride location_override = 17;
 * @return {!Array<!proto.fox.proto.PlaceZoneConfig.LocationOverride>}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getLocationOverrideList = function() {
  return /** @type{!Array<!proto.fox.proto.PlaceZoneConfig.LocationOverride>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.PlaceZoneConfig.LocationOverride, 17));
};


/**
 * @param {!Array<!proto.fox.proto.PlaceZoneConfig.LocationOverride>} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
*/
proto.fox.proto.PlaceZoneConfig.prototype.setLocationOverrideList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.fox.proto.PlaceZoneConfig.LocationOverride=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PlaceZoneConfig.LocationOverride}
 */
proto.fox.proto.PlaceZoneConfig.prototype.addLocationOverride = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.fox.proto.PlaceZoneConfig.LocationOverride, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.clearLocationOverrideList = function() {
  return this.setLocationOverrideList([]);
};


/**
 * optional bool left_to_right = 10;
 * @return {boolean}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getLeftToRight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.setLeftToRight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated PlaceStrategy place_strategy = 12;
 * @return {!Array<!proto.fox.proto.PlaceZoneConfig.PlaceStrategy>}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getPlaceStrategyList = function() {
  return /** @type {!Array<!proto.fox.proto.PlaceZoneConfig.PlaceStrategy>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<!proto.fox.proto.PlaceZoneConfig.PlaceStrategy>} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.setPlaceStrategyList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {!proto.fox.proto.PlaceZoneConfig.PlaceStrategy} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.addPlaceStrategy = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.clearPlaceStrategyList = function() {
  return this.setPlaceStrategyList([]);
};


/**
 * optional PlacePattern place_pattern = 13;
 * @return {!proto.fox.proto.PlaceZoneConfig.PlacePattern}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getPlacePattern = function() {
  return /** @type {!proto.fox.proto.PlaceZoneConfig.PlacePattern} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.fox.proto.PlaceZoneConfig.PlacePattern} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.setPlacePattern = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional PlaceZoneIndex filter_min = 14;
 * @return {?proto.fox.proto.PlaceZoneIndex}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getFilterMin = function() {
  return /** @type{?proto.fox.proto.PlaceZoneIndex} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlaceZoneIndex, 14));
};


/**
 * @param {?proto.fox.proto.PlaceZoneIndex|undefined} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
*/
proto.fox.proto.PlaceZoneConfig.prototype.setFilterMin = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.clearFilterMin = function() {
  return this.setFilterMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlaceZoneConfig.prototype.hasFilterMin = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PlaceZoneIndex filter_max = 15;
 * @return {?proto.fox.proto.PlaceZoneIndex}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getFilterMax = function() {
  return /** @type{?proto.fox.proto.PlaceZoneIndex} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlaceZoneIndex, 15));
};


/**
 * @param {?proto.fox.proto.PlaceZoneIndex|undefined} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
*/
proto.fox.proto.PlaceZoneConfig.prototype.setFilterMax = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.clearFilterMax = function() {
  return this.setFilterMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlaceZoneConfig.prototype.hasFilterMax = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional float min_major_aisle_width = 18;
 * @return {number}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getMinMajorAisleWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.setMinMajorAisleWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float min_minor_aisle_width = 19;
 * @return {number}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getMinMinorAisleWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.setMinMinorAisleWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional SplitPlaceZoneInformation split_place_zone_information = 20;
 * @return {?proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation}
 */
proto.fox.proto.PlaceZoneConfig.prototype.getSplitPlaceZoneInformation = function() {
  return /** @type{?proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation, 20));
};


/**
 * @param {?proto.fox.proto.PlaceZoneConfig.SplitPlaceZoneInformation|undefined} value
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
*/
proto.fox.proto.PlaceZoneConfig.prototype.setSplitPlaceZoneInformation = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlaceZoneConfig} returns this
 */
proto.fox.proto.PlaceZoneConfig.prototype.clearSplitPlaceZoneInformation = function() {
  return this.setSplitPlaceZoneInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlaceZoneConfig.prototype.hasSplitPlaceZoneInformation = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.RampPickConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.RampPickConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.RampPickConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RampPickConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    liftAdjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    useSteerWheel: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    minLipDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.RampPickConfig}
 */
proto.fox.proto.RampPickConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.RampPickConfig;
  return proto.fox.proto.RampPickConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.RampPickConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.RampPickConfig}
 */
proto.fox.proto.RampPickConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.RampPickConfig.PickType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLiftAdjustment(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSteerWheel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinLipDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.RampPickConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.RampPickConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.RampPickConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RampPickConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLiftAdjustment();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getUseSteerWheel();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMinLipDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.RampPickConfig.PickType = {
  PICK_TYPE_UNKNOWN: 0,
  PICK_TYPE_NONE: 1,
  PICK_TYPE_FIXED: 2,
  PICK_TYPE_SERVO: 3
};

/**
 * optional PickType type = 1;
 * @return {!proto.fox.proto.RampPickConfig.PickType}
 */
proto.fox.proto.RampPickConfig.prototype.getType = function() {
  return /** @type {!proto.fox.proto.RampPickConfig.PickType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.RampPickConfig.PickType} value
 * @return {!proto.fox.proto.RampPickConfig} returns this
 */
proto.fox.proto.RampPickConfig.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float lift_adjustment = 2;
 * @return {number}
 */
proto.fox.proto.RampPickConfig.prototype.getLiftAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampPickConfig} returns this
 */
proto.fox.proto.RampPickConfig.prototype.setLiftAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool use_steer_wheel = 3;
 * @return {boolean}
 */
proto.fox.proto.RampPickConfig.prototype.getUseSteerWheel = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.RampPickConfig} returns this
 */
proto.fox.proto.RampPickConfig.prototype.setUseSteerWheel = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional float min_lip_distance = 4;
 * @return {number}
 */
proto.fox.proto.RampPickConfig.prototype.getMinLipDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampPickConfig} returns this
 */
proto.fox.proto.RampPickConfig.prototype.setMinLipDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.RampConfig.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.RampConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.RampConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.RampConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RampConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    length: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    hasWalls: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    lipToRampWall: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    rampWallLength: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    rampPickConfigList: jspb.Message.toObjectList(msg.getRampPickConfigList(),
    proto.fox.proto.RampPickConfig.toObject, includeInstance),
    lipToTagLine: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    lipToBumpers: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    lipToClearHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    lipToHinge: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    lipToLeftTag: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    lipToRightTag: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    edgeToLeftTag: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    edgeToRightTag: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    hasWeatherGuards: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.RampConfig}
 */
proto.fox.proto.RampConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.RampConfig;
  return proto.fox.proto.RampConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.RampConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.RampConfig}
 */
proto.fox.proto.RampConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWidth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLength(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasWalls(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToRampWall(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampWallLength(value);
      break;
    case 6:
      var value = new proto.fox.proto.RampPickConfig;
      reader.readMessage(value,proto.fox.proto.RampPickConfig.deserializeBinaryFromReader);
      msg.addRampPickConfig(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToTagLine(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToBumpers(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToClearHeight(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToHinge(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToLeftTag(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToRightTag(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEdgeToLeftTag(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setEdgeToRightTag(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasWeatherGuards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.RampConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.RampConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.RampConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.RampConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLength();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getHasWalls();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLipToRampWall();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getRampWallLength();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getRampPickConfigList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.fox.proto.RampPickConfig.serializeBinaryToWriter
    );
  }
  f = message.getLipToTagLine();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLipToBumpers();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getLipToClearHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getLipToHinge();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getLipToLeftTag();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getLipToRightTag();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getEdgeToLeftTag();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getEdgeToRightTag();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getHasWeatherGuards();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional float width = 1;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float length = 3;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool has_walls = 5;
 * @return {boolean}
 */
proto.fox.proto.RampConfig.prototype.getHasWalls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setHasWalls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional float lip_to_ramp_wall = 15;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getLipToRampWall = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setLipToRampWall = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float ramp_wall_length = 16;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getRampWallLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setRampWallLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * repeated RampPickConfig ramp_pick_config = 6;
 * @return {!Array<!proto.fox.proto.RampPickConfig>}
 */
proto.fox.proto.RampConfig.prototype.getRampPickConfigList = function() {
  return /** @type{!Array<!proto.fox.proto.RampPickConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.RampPickConfig, 6));
};


/**
 * @param {!Array<!proto.fox.proto.RampPickConfig>} value
 * @return {!proto.fox.proto.RampConfig} returns this
*/
proto.fox.proto.RampConfig.prototype.setRampPickConfigList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.fox.proto.RampPickConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.RampPickConfig}
 */
proto.fox.proto.RampConfig.prototype.addRampPickConfig = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.fox.proto.RampPickConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.clearRampPickConfigList = function() {
  return this.setRampPickConfigList([]);
};


/**
 * optional float lip_to_tag_line = 7;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getLipToTagLine = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setLipToTagLine = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float lip_to_bumpers = 8;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getLipToBumpers = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setLipToBumpers = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float lip_to_clear_height = 9;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getLipToClearHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setLipToClearHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float lip_to_hinge = 10;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getLipToHinge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setLipToHinge = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float lip_to_left_tag = 11;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getLipToLeftTag = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setLipToLeftTag = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float lip_to_right_tag = 12;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getLipToRightTag = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setLipToRightTag = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float edge_to_left_tag = 13;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getEdgeToLeftTag = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setEdgeToLeftTag = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float edge_to_right_tag = 14;
 * @return {number}
 */
proto.fox.proto.RampConfig.prototype.getEdgeToRightTag = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setEdgeToRightTag = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional bool has_weather_guards = 17;
 * @return {boolean}
 */
proto.fox.proto.RampConfig.prototype.getHasWeatherGuards = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.RampConfig} returns this
 */
proto.fox.proto.RampConfig.prototype.setHasWeatherGuards = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.ConveyorZoneConfig.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ConveyorZoneConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ConveyorZoneConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ConveyorZoneConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    height: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    clearedHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    tiltDeg: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    anchorTagsList: jspb.Message.toObjectList(msg.getAnchorTagsList(),
    proto.fox.proto.AnchorTag.toObject, includeInstance),
    originToPalletFace: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    straight: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    turned: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    frontObstaclePadding: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    leftObstaclePadding: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    rightObstaclePadding: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ConveyorZoneConfig}
 */
proto.fox.proto.ConveyorZoneConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ConveyorZoneConfig;
  return proto.fox.proto.ConveyorZoneConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ConveyorZoneConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ConveyorZoneConfig}
 */
proto.fox.proto.ConveyorZoneConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeight(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClearedHeight(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTiltDeg(value);
      break;
    case 5:
      var value = new proto.fox.proto.AnchorTag;
      reader.readMessage(value,proto.fox.proto.AnchorTag.deserializeBinaryFromReader);
      msg.addAnchorTags(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOriginToPalletFace(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStraight(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTurned(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFrontObstaclePadding(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLeftObstaclePadding(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRightObstaclePadding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ConveyorZoneConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ConveyorZoneConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ConveyorZoneConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getClearedHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTiltDeg();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getAnchorTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.fox.proto.AnchorTag.serializeBinaryToWriter
    );
  }
  f = message.getOriginToPalletFace();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getStraight();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTurned();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getFrontObstaclePadding();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getLeftObstaclePadding();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getRightObstaclePadding();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
};


/**
 * optional float width = 1;
 * @return {number}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
 */
proto.fox.proto.ConveyorZoneConfig.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float height = 2;
 * @return {number}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
 */
proto.fox.proto.ConveyorZoneConfig.prototype.setHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float cleared_height = 3;
 * @return {number}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.getClearedHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
 */
proto.fox.proto.ConveyorZoneConfig.prototype.setClearedHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float tilt_deg = 4;
 * @return {number}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.getTiltDeg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
 */
proto.fox.proto.ConveyorZoneConfig.prototype.setTiltDeg = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * repeated AnchorTag anchor_tags = 5;
 * @return {!Array<!proto.fox.proto.AnchorTag>}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.getAnchorTagsList = function() {
  return /** @type{!Array<!proto.fox.proto.AnchorTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.AnchorTag, 5));
};


/**
 * @param {!Array<!proto.fox.proto.AnchorTag>} value
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
*/
proto.fox.proto.ConveyorZoneConfig.prototype.setAnchorTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.fox.proto.AnchorTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.AnchorTag}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.addAnchorTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.fox.proto.AnchorTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
 */
proto.fox.proto.ConveyorZoneConfig.prototype.clearAnchorTagsList = function() {
  return this.setAnchorTagsList([]);
};


/**
 * optional float origin_to_pallet_face = 6;
 * @return {number}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.getOriginToPalletFace = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
 */
proto.fox.proto.ConveyorZoneConfig.prototype.setOriginToPalletFace = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool straight = 7;
 * @return {boolean}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.getStraight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
 */
proto.fox.proto.ConveyorZoneConfig.prototype.setStraight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool turned = 8;
 * @return {boolean}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.getTurned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
 */
proto.fox.proto.ConveyorZoneConfig.prototype.setTurned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional float front_obstacle_padding = 9;
 * @return {number}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.getFrontObstaclePadding = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
 */
proto.fox.proto.ConveyorZoneConfig.prototype.setFrontObstaclePadding = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float left_obstacle_padding = 10;
 * @return {number}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.getLeftObstaclePadding = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
 */
proto.fox.proto.ConveyorZoneConfig.prototype.setLeftObstaclePadding = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float right_obstacle_padding = 11;
 * @return {number}
 */
proto.fox.proto.ConveyorZoneConfig.prototype.getRightObstaclePadding = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ConveyorZoneConfig} returns this
 */
proto.fox.proto.ConveyorZoneConfig.prototype.setRightObstaclePadding = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.ZoneConfig.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ZoneConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ZoneConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ZoneConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ZoneConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    templateName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    placeConfig: (f = msg.getPlaceConfig()) && proto.fox.proto.PlaceZoneConfig.toObject(includeInstance, f),
    rampConfig: (f = msg.getRampConfig()) && proto.fox.proto.RampConfig.toObject(includeInstance, f),
    conveyorZoneConfig: (f = msg.getConveyorZoneConfig()) && proto.fox.proto.ConveyorZoneConfig.toObject(includeInstance, f),
    hasBackWall: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    zoneTApproachesList: jspb.Message.toObjectList(msg.getZoneTApproachesList(),
    geometry_proto_transform_pb.Transform2f.toObject, includeInstance),
    zoneType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    parentZoneId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    parentZoneTZone: (f = msg.getParentZoneTZone()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    mapTZoneExpected: (f = msg.getMapTZoneExpected()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ZoneConfig}
 */
proto.fox.proto.ZoneConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ZoneConfig;
  return proto.fox.proto.ZoneConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ZoneConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ZoneConfig}
 */
proto.fox.proto.ZoneConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateName(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setZoneId(value);
      break;
    case 2:
      var value = new proto.fox.proto.PlaceZoneConfig;
      reader.readMessage(value,proto.fox.proto.PlaceZoneConfig.deserializeBinaryFromReader);
      msg.setPlaceConfig(value);
      break;
    case 3:
      var value = new proto.fox.proto.RampConfig;
      reader.readMessage(value,proto.fox.proto.RampConfig.deserializeBinaryFromReader);
      msg.setRampConfig(value);
      break;
    case 12:
      var value = new proto.fox.proto.ConveyorZoneConfig;
      reader.readMessage(value,proto.fox.proto.ConveyorZoneConfig.deserializeBinaryFromReader);
      msg.setConveyorZoneConfig(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBackWall(value);
      break;
    case 5:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.addZoneTApproaches(value);
      break;
    case 6:
      var value = /** @type {!proto.fox.proto.ZoneConfig.ZoneType} */ (reader.readEnum());
      msg.setZoneType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParentZoneId(value);
      break;
    case 9:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setParentZoneTZone(value);
      break;
    case 11:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTZoneExpected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ZoneConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ZoneConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ZoneConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ZoneConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTemplateName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPlaceConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.PlaceZoneConfig.serializeBinaryToWriter
    );
  }
  f = message.getRampConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.RampConfig.serializeBinaryToWriter
    );
  }
  f = message.getConveyorZoneConfig();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.fox.proto.ConveyorZoneConfig.serializeBinaryToWriter
    );
  }
  f = message.getHasBackWall();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getZoneTApproachesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getZoneType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getParentZoneId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getParentZoneTZone();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getMapTZoneExpected();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.ZoneConfig.ZoneType = {
  UNKNOWN: 0,
  TRUCK: 1,
  WAREHOUSE: 2,
  CONVEYOR: 3
};

/**
 * optional string name = 7;
 * @return {string}
 */
proto.fox.proto.ZoneConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string template_name = 10;
 * @return {string}
 */
proto.fox.proto.ZoneConfig.prototype.getTemplateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.setTemplateName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 zone_id = 1;
 * @return {number}
 */
proto.fox.proto.ZoneConfig.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PlaceZoneConfig place_config = 2;
 * @return {?proto.fox.proto.PlaceZoneConfig}
 */
proto.fox.proto.ZoneConfig.prototype.getPlaceConfig = function() {
  return /** @type{?proto.fox.proto.PlaceZoneConfig} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlaceZoneConfig, 2));
};


/**
 * @param {?proto.fox.proto.PlaceZoneConfig|undefined} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
*/
proto.fox.proto.ZoneConfig.prototype.setPlaceConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.clearPlaceConfig = function() {
  return this.setPlaceConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ZoneConfig.prototype.hasPlaceConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RampConfig ramp_config = 3;
 * @return {?proto.fox.proto.RampConfig}
 */
proto.fox.proto.ZoneConfig.prototype.getRampConfig = function() {
  return /** @type{?proto.fox.proto.RampConfig} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.RampConfig, 3));
};


/**
 * @param {?proto.fox.proto.RampConfig|undefined} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
*/
proto.fox.proto.ZoneConfig.prototype.setRampConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.clearRampConfig = function() {
  return this.setRampConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ZoneConfig.prototype.hasRampConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ConveyorZoneConfig conveyor_zone_config = 12;
 * @return {?proto.fox.proto.ConveyorZoneConfig}
 */
proto.fox.proto.ZoneConfig.prototype.getConveyorZoneConfig = function() {
  return /** @type{?proto.fox.proto.ConveyorZoneConfig} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ConveyorZoneConfig, 12));
};


/**
 * @param {?proto.fox.proto.ConveyorZoneConfig|undefined} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
*/
proto.fox.proto.ZoneConfig.prototype.setConveyorZoneConfig = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.clearConveyorZoneConfig = function() {
  return this.setConveyorZoneConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ZoneConfig.prototype.hasConveyorZoneConfig = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool has_back_wall = 4;
 * @return {boolean}
 */
proto.fox.proto.ZoneConfig.prototype.getHasBackWall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.setHasBackWall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated Transform2f zone_t_approaches = 5;
 * @return {!Array<!proto.fox.proto.Transform2f>}
 */
proto.fox.proto.ZoneConfig.prototype.getZoneTApproachesList = function() {
  return /** @type{!Array<!proto.fox.proto.Transform2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Transform2f, 5));
};


/**
 * @param {!Array<!proto.fox.proto.Transform2f>} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
*/
proto.fox.proto.ZoneConfig.prototype.setZoneTApproachesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.fox.proto.Transform2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Transform2f}
 */
proto.fox.proto.ZoneConfig.prototype.addZoneTApproaches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.fox.proto.Transform2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.clearZoneTApproachesList = function() {
  return this.setZoneTApproachesList([]);
};


/**
 * optional ZoneType zone_type = 6;
 * @return {!proto.fox.proto.ZoneConfig.ZoneType}
 */
proto.fox.proto.ZoneConfig.prototype.getZoneType = function() {
  return /** @type {!proto.fox.proto.ZoneConfig.ZoneType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.fox.proto.ZoneConfig.ZoneType} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.setZoneType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 parent_zone_id = 8;
 * @return {number}
 */
proto.fox.proto.ZoneConfig.prototype.getParentZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.setParentZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Transform2f parent_zone_t_zone = 9;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.ZoneConfig.prototype.getParentZoneTZone = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 9));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
*/
proto.fox.proto.ZoneConfig.prototype.setParentZoneTZone = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.clearParentZoneTZone = function() {
  return this.setParentZoneTZone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ZoneConfig.prototype.hasParentZoneTZone = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Transform2f map_t_zone_expected = 11;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.ZoneConfig.prototype.getMapTZoneExpected = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 11));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.ZoneConfig} returns this
*/
proto.fox.proto.ZoneConfig.prototype.setMapTZoneExpected = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ZoneConfig} returns this
 */
proto.fox.proto.ZoneConfig.prototype.clearMapTZoneExpected = function() {
  return this.setMapTZoneExpected(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ZoneConfig.prototype.hasMapTZoneExpected = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.TagDetection.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.TagDetection.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.TagDetection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.TagDetection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TagDetection.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    size: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    robotTTag: (f = msg.getRobotTTag()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    detectedCornersList: jspb.Message.toObjectList(msg.getDetectedCornersList(),
    geometry_proto_transform_pb.Vector2f.toObject, includeInstance),
    reprojectionError: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    aligned: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    mapTRobot: (f = msg.getMapTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    timeNs: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.TagDetection}
 */
proto.fox.proto.TagDetection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.TagDetection;
  return proto.fox.proto.TagDetection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.TagDetection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.TagDetection}
 */
proto.fox.proto.TagDetection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSize(value);
      break;
    case 3:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setRobotTTag(value);
      break;
    case 4:
      var value = new geometry_proto_transform_pb.Vector2f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2f.deserializeBinaryFromReader);
      msg.addDetectedCorners(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setReprojectionError(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAligned(value);
      break;
    case 7:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTRobot(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeNs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.TagDetection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.TagDetection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.TagDetection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TagDetection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getRobotTTag();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getDetectedCornersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      geometry_proto_transform_pb.Vector2f.serializeBinaryToWriter
    );
  }
  f = message.getReprojectionError();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAligned();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getMapTRobot();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getTimeNs();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.fox.proto.TagDetection.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TagDetection} returns this
 */
proto.fox.proto.TagDetection.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float size = 2;
 * @return {number}
 */
proto.fox.proto.TagDetection.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TagDetection} returns this
 */
proto.fox.proto.TagDetection.prototype.setSize = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Transform3f robot_t_tag = 3;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.TagDetection.prototype.getRobotTTag = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 3));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.TagDetection} returns this
*/
proto.fox.proto.TagDetection.prototype.setRobotTTag = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TagDetection} returns this
 */
proto.fox.proto.TagDetection.prototype.clearRobotTTag = function() {
  return this.setRobotTTag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TagDetection.prototype.hasRobotTTag = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Vector2f detected_corners = 4;
 * @return {!Array<!proto.fox.proto.Vector2f>}
 */
proto.fox.proto.TagDetection.prototype.getDetectedCornersList = function() {
  return /** @type{!Array<!proto.fox.proto.Vector2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Vector2f, 4));
};


/**
 * @param {!Array<!proto.fox.proto.Vector2f>} value
 * @return {!proto.fox.proto.TagDetection} returns this
*/
proto.fox.proto.TagDetection.prototype.setDetectedCornersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.fox.proto.Vector2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Vector2f}
 */
proto.fox.proto.TagDetection.prototype.addDetectedCorners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.fox.proto.Vector2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.TagDetection} returns this
 */
proto.fox.proto.TagDetection.prototype.clearDetectedCornersList = function() {
  return this.setDetectedCornersList([]);
};


/**
 * optional float reprojection_error = 5;
 * @return {number}
 */
proto.fox.proto.TagDetection.prototype.getReprojectionError = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TagDetection} returns this
 */
proto.fox.proto.TagDetection.prototype.setReprojectionError = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool aligned = 6;
 * @return {boolean}
 */
proto.fox.proto.TagDetection.prototype.getAligned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.TagDetection} returns this
 */
proto.fox.proto.TagDetection.prototype.setAligned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional Transform2f map_t_robot = 7;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.TagDetection.prototype.getMapTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 7));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.TagDetection} returns this
*/
proto.fox.proto.TagDetection.prototype.setMapTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TagDetection} returns this
 */
proto.fox.proto.TagDetection.prototype.clearMapTRobot = function() {
  return this.setMapTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TagDetection.prototype.hasMapTRobot = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 time_ns = 8;
 * @return {number}
 */
proto.fox.proto.TagDetection.prototype.getTimeNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TagDetection} returns this
 */
proto.fox.proto.TagDetection.prototype.setTimeNs = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.PalletDetection.repeatedFields_ = [5,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PalletDetection.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PalletDetection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PalletDetection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PalletDetection.toObject = function(includeInstance, msg) {
  var f, obj = {
    robotTPallet: (f = msg.getRobotTPallet()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    shape: (f = msg.getShape()) && geometry_proto_transform_pb.Vector3f.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    detectedCornersList: jspb.Message.toObjectList(msg.getDetectedCornersList(),
    geometry_proto_transform_pb.Vector2f.toObject, includeInstance),
    cornersVisibleList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 12)) == null ? undefined : f,
    straight: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    blocked: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    stringer: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    leftPocketImage: (f = msg.getLeftPocketImage()) && geometry_proto_geometry_pb.Quad2f.toObject(includeInstance, f),
    rightPocketImage: (f = msg.getRightPocketImage()) && geometry_proto_geometry_pb.Quad2f.toObject(includeInstance, f),
    cornersRobot: (f = msg.getCornersRobot()) && geometry_proto_geometry_pb.Quad3f.toObject(includeInstance, f),
    leftPocketRobot: (f = msg.getLeftPocketRobot()) && geometry_proto_geometry_pb.Quad3f.toObject(includeInstance, f),
    rightPocketRobot: (f = msg.getRightPocketRobot()) && geometry_proto_geometry_pb.Quad3f.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PalletDetection}
 */
proto.fox.proto.PalletDetection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PalletDetection;
  return proto.fox.proto.PalletDetection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PalletDetection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PalletDetection}
 */
proto.fox.proto.PalletDetection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setRobotTPallet(value);
      break;
    case 3:
      var value = new geometry_proto_transform_pb.Vector3f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector3f.deserializeBinaryFromReader);
      msg.setShape(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 5:
      var value = new geometry_proto_transform_pb.Vector2f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2f.deserializeBinaryFromReader);
      msg.addDetectedCorners(value);
      break;
    case 12:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setCornersVisibleList(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStraight(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBlocked(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStringer(value);
      break;
    case 6:
      var value = new geometry_proto_geometry_pb.Quad2f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad2f.deserializeBinaryFromReader);
      msg.setLeftPocketImage(value);
      break;
    case 7:
      var value = new geometry_proto_geometry_pb.Quad2f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad2f.deserializeBinaryFromReader);
      msg.setRightPocketImage(value);
      break;
    case 8:
      var value = new geometry_proto_geometry_pb.Quad3f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad3f.deserializeBinaryFromReader);
      msg.setCornersRobot(value);
      break;
    case 9:
      var value = new geometry_proto_geometry_pb.Quad3f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad3f.deserializeBinaryFromReader);
      msg.setLeftPocketRobot(value);
      break;
    case 10:
      var value = new geometry_proto_geometry_pb.Quad3f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad3f.deserializeBinaryFromReader);
      msg.setRightPocketRobot(value);
      break;
    case 11:
      var value = /** @type {!proto.fox.proto.PalletDetection.PalletDetectionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PalletDetection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PalletDetection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PalletDetection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PalletDetection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRobotTPallet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getShape();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      geometry_proto_transform_pb.Vector3f.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDetectedCornersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      geometry_proto_transform_pb.Vector2f.serializeBinaryToWriter
    );
  }
  f = message.getCornersVisibleList();
  if (f.length > 0) {
    writer.writePackedFloat(
      12,
      f
    );
  }
  f = message.getStraight();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getBlocked();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getStringer();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getLeftPocketImage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      geometry_proto_geometry_pb.Quad2f.serializeBinaryToWriter
    );
  }
  f = message.getRightPocketImage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      geometry_proto_geometry_pb.Quad2f.serializeBinaryToWriter
    );
  }
  f = message.getCornersRobot();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      geometry_proto_geometry_pb.Quad3f.serializeBinaryToWriter
    );
  }
  f = message.getLeftPocketRobot();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      geometry_proto_geometry_pb.Quad3f.serializeBinaryToWriter
    );
  }
  f = message.getRightPocketRobot();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      geometry_proto_geometry_pb.Quad3f.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.PalletDetection.PalletDetectionType = {
  UNKNOWN: 0,
  YOLO: 1,
  LIDAR_YOLO: 2,
  LIDAR_GUESS: 3,
  CONVEYOR_YOLO_MATCH: 4
};

/**
 * optional Transform3f robot_t_pallet = 2;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.PalletDetection.prototype.getRobotTPallet = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 2));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.PalletDetection} returns this
*/
proto.fox.proto.PalletDetection.prototype.setRobotTPallet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.clearRobotTPallet = function() {
  return this.setRobotTPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletDetection.prototype.hasRobotTPallet = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Vector3f shape = 3;
 * @return {?proto.fox.proto.Vector3f}
 */
proto.fox.proto.PalletDetection.prototype.getShape = function() {
  return /** @type{?proto.fox.proto.Vector3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector3f, 3));
};


/**
 * @param {?proto.fox.proto.Vector3f|undefined} value
 * @return {!proto.fox.proto.PalletDetection} returns this
*/
proto.fox.proto.PalletDetection.prototype.setShape = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.clearShape = function() {
  return this.setShape(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletDetection.prototype.hasShape = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float score = 4;
 * @return {number}
 */
proto.fox.proto.PalletDetection.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * repeated Vector2f detected_corners = 5;
 * @return {!Array<!proto.fox.proto.Vector2f>}
 */
proto.fox.proto.PalletDetection.prototype.getDetectedCornersList = function() {
  return /** @type{!Array<!proto.fox.proto.Vector2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Vector2f, 5));
};


/**
 * @param {!Array<!proto.fox.proto.Vector2f>} value
 * @return {!proto.fox.proto.PalletDetection} returns this
*/
proto.fox.proto.PalletDetection.prototype.setDetectedCornersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.fox.proto.Vector2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Vector2f}
 */
proto.fox.proto.PalletDetection.prototype.addDetectedCorners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.fox.proto.Vector2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.clearDetectedCornersList = function() {
  return this.setDetectedCornersList([]);
};


/**
 * repeated float corners_visible = 12;
 * @return {!Array<number>}
 */
proto.fox.proto.PalletDetection.prototype.getCornersVisibleList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.setCornersVisibleList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.addCornersVisible = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.clearCornersVisibleList = function() {
  return this.setCornersVisibleList([]);
};


/**
 * optional float straight = 13;
 * @return {number}
 */
proto.fox.proto.PalletDetection.prototype.getStraight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.setStraight = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float blocked = 14;
 * @return {number}
 */
proto.fox.proto.PalletDetection.prototype.getBlocked = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.setBlocked = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float stringer = 15;
 * @return {number}
 */
proto.fox.proto.PalletDetection.prototype.getStringer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.setStringer = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional Quad2f left_pocket_image = 6;
 * @return {?proto.fox.proto.Quad2f}
 */
proto.fox.proto.PalletDetection.prototype.getLeftPocketImage = function() {
  return /** @type{?proto.fox.proto.Quad2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_geometry_pb.Quad2f, 6));
};


/**
 * @param {?proto.fox.proto.Quad2f|undefined} value
 * @return {!proto.fox.proto.PalletDetection} returns this
*/
proto.fox.proto.PalletDetection.prototype.setLeftPocketImage = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.clearLeftPocketImage = function() {
  return this.setLeftPocketImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletDetection.prototype.hasLeftPocketImage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Quad2f right_pocket_image = 7;
 * @return {?proto.fox.proto.Quad2f}
 */
proto.fox.proto.PalletDetection.prototype.getRightPocketImage = function() {
  return /** @type{?proto.fox.proto.Quad2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_geometry_pb.Quad2f, 7));
};


/**
 * @param {?proto.fox.proto.Quad2f|undefined} value
 * @return {!proto.fox.proto.PalletDetection} returns this
*/
proto.fox.proto.PalletDetection.prototype.setRightPocketImage = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.clearRightPocketImage = function() {
  return this.setRightPocketImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletDetection.prototype.hasRightPocketImage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Quad3f corners_robot = 8;
 * @return {?proto.fox.proto.Quad3f}
 */
proto.fox.proto.PalletDetection.prototype.getCornersRobot = function() {
  return /** @type{?proto.fox.proto.Quad3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_geometry_pb.Quad3f, 8));
};


/**
 * @param {?proto.fox.proto.Quad3f|undefined} value
 * @return {!proto.fox.proto.PalletDetection} returns this
*/
proto.fox.proto.PalletDetection.prototype.setCornersRobot = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.clearCornersRobot = function() {
  return this.setCornersRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletDetection.prototype.hasCornersRobot = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Quad3f left_pocket_robot = 9;
 * @return {?proto.fox.proto.Quad3f}
 */
proto.fox.proto.PalletDetection.prototype.getLeftPocketRobot = function() {
  return /** @type{?proto.fox.proto.Quad3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_geometry_pb.Quad3f, 9));
};


/**
 * @param {?proto.fox.proto.Quad3f|undefined} value
 * @return {!proto.fox.proto.PalletDetection} returns this
*/
proto.fox.proto.PalletDetection.prototype.setLeftPocketRobot = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.clearLeftPocketRobot = function() {
  return this.setLeftPocketRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletDetection.prototype.hasLeftPocketRobot = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Quad3f right_pocket_robot = 10;
 * @return {?proto.fox.proto.Quad3f}
 */
proto.fox.proto.PalletDetection.prototype.getRightPocketRobot = function() {
  return /** @type{?proto.fox.proto.Quad3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_geometry_pb.Quad3f, 10));
};


/**
 * @param {?proto.fox.proto.Quad3f|undefined} value
 * @return {!proto.fox.proto.PalletDetection} returns this
*/
proto.fox.proto.PalletDetection.prototype.setRightPocketRobot = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.clearRightPocketRobot = function() {
  return this.setRightPocketRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletDetection.prototype.hasRightPocketRobot = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PalletDetectionType type = 11;
 * @return {!proto.fox.proto.PalletDetection.PalletDetectionType}
 */
proto.fox.proto.PalletDetection.prototype.getType = function() {
  return /** @type {!proto.fox.proto.PalletDetection.PalletDetectionType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.fox.proto.PalletDetection.PalletDetectionType} value
 * @return {!proto.fox.proto.PalletDetection} returns this
 */
proto.fox.proto.PalletDetection.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PersonDetection.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PersonDetection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PersonDetection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PersonDetection.toObject = function(includeInstance, msg) {
  var f, obj = {
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    corners: (f = msg.getCorners()) && geometry_proto_geometry_pb.Quad2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PersonDetection}
 */
proto.fox.proto.PersonDetection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PersonDetection;
  return proto.fox.proto.PersonDetection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PersonDetection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PersonDetection}
 */
proto.fox.proto.PersonDetection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 2:
      var value = new geometry_proto_geometry_pb.Quad2f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad2f.deserializeBinaryFromReader);
      msg.setCorners(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PersonDetection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PersonDetection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PersonDetection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PersonDetection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getCorners();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_geometry_pb.Quad2f.serializeBinaryToWriter
    );
  }
};


/**
 * optional float score = 1;
 * @return {number}
 */
proto.fox.proto.PersonDetection.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PersonDetection} returns this
 */
proto.fox.proto.PersonDetection.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional Quad2f corners = 2;
 * @return {?proto.fox.proto.Quad2f}
 */
proto.fox.proto.PersonDetection.prototype.getCorners = function() {
  return /** @type{?proto.fox.proto.Quad2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_geometry_pb.Quad2f, 2));
};


/**
 * @param {?proto.fox.proto.Quad2f|undefined} value
 * @return {!proto.fox.proto.PersonDetection} returns this
*/
proto.fox.proto.PersonDetection.prototype.setCorners = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PersonDetection} returns this
 */
proto.fox.proto.PersonDetection.prototype.clearCorners = function() {
  return this.setCorners(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PersonDetection.prototype.hasCorners = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.PersonDetections.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PersonDetections.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PersonDetections.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PersonDetections} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PersonDetections.toObject = function(includeInstance, msg) {
  var f, obj = {
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    proto.fox.proto.PersonDetection.toObject, includeInstance),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PersonDetections}
 */
proto.fox.proto.PersonDetections.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PersonDetections;
  return proto.fox.proto.PersonDetections.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PersonDetections} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PersonDetections}
 */
proto.fox.proto.PersonDetections.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.PersonDetection;
      reader.readMessage(value,proto.fox.proto.PersonDetection.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PersonDetections.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PersonDetections.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PersonDetections} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PersonDetections.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.PersonDetection.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated PersonDetection people = 1;
 * @return {!Array<!proto.fox.proto.PersonDetection>}
 */
proto.fox.proto.PersonDetections.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.fox.proto.PersonDetection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.PersonDetection, 1));
};


/**
 * @param {!Array<!proto.fox.proto.PersonDetection>} value
 * @return {!proto.fox.proto.PersonDetections} returns this
*/
proto.fox.proto.PersonDetections.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.PersonDetection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PersonDetection}
 */
proto.fox.proto.PersonDetections.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.PersonDetection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PersonDetections} returns this
 */
proto.fox.proto.PersonDetections.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.fox.proto.PersonDetections.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PersonDetections} returns this
 */
proto.fox.proto.PersonDetections.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.TrailerStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.TrailerStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.TrailerStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TrailerStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    personInTrailer: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    clear: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.TrailerStatus}
 */
proto.fox.proto.TrailerStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.TrailerStatus;
  return proto.fox.proto.TrailerStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.TrailerStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.TrailerStatus}
 */
proto.fox.proto.TrailerStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPersonInTrailer(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.TrailerStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.TrailerStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.TrailerStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TrailerStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPersonInTrailer();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getClear();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.fox.proto.TrailerStatus.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TrailerStatus} returns this
 */
proto.fox.proto.TrailerStatus.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float person_in_trailer = 2;
 * @return {number}
 */
proto.fox.proto.TrailerStatus.prototype.getPersonInTrailer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TrailerStatus} returns this
 */
proto.fox.proto.TrailerStatus.prototype.setPersonInTrailer = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float clear = 3;
 * @return {number}
 */
proto.fox.proto.TrailerStatus.prototype.getClear = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TrailerStatus} returns this
 */
proto.fox.proto.TrailerStatus.prototype.setClear = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.SlowDetections.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SlowDetections.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SlowDetections.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SlowDetections} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SlowDetections.toObject = function(includeInstance, msg) {
  var f, obj = {
    peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
    proto.fox.proto.PersonDetections.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SlowDetections}
 */
proto.fox.proto.SlowDetections.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SlowDetections;
  return proto.fox.proto.SlowDetections.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SlowDetections} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SlowDetections}
 */
proto.fox.proto.SlowDetections.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.PersonDetections;
      reader.readMessage(value,proto.fox.proto.PersonDetections.deserializeBinaryFromReader);
      msg.addPeople(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SlowDetections.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SlowDetections.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SlowDetections} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SlowDetections.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeopleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.PersonDetections.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PersonDetections people = 1;
 * @return {!Array<!proto.fox.proto.PersonDetections>}
 */
proto.fox.proto.SlowDetections.prototype.getPeopleList = function() {
  return /** @type{!Array<!proto.fox.proto.PersonDetections>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.PersonDetections, 1));
};


/**
 * @param {!Array<!proto.fox.proto.PersonDetections>} value
 * @return {!proto.fox.proto.SlowDetections} returns this
*/
proto.fox.proto.SlowDetections.prototype.setPeopleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.PersonDetections=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PersonDetections}
 */
proto.fox.proto.SlowDetections.prototype.addPeople = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.PersonDetections, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.SlowDetections} returns this
 */
proto.fox.proto.SlowDetections.prototype.clearPeopleList = function() {
  return this.setPeopleList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.UntrackedPalletDetection.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.UntrackedPalletDetection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.UntrackedPalletDetection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.UntrackedPalletDetection.toObject = function(includeInstance, msg) {
  var f, obj = {
    confidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.UntrackedPalletDetection}
 */
proto.fox.proto.UntrackedPalletDetection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.UntrackedPalletDetection;
  return proto.fox.proto.UntrackedPalletDetection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.UntrackedPalletDetection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.UntrackedPalletDetection}
 */
proto.fox.proto.UntrackedPalletDetection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setConfidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.UntrackedPalletDetection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.UntrackedPalletDetection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.UntrackedPalletDetection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.UntrackedPalletDetection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfidence();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float confidence = 1;
 * @return {number}
 */
proto.fox.proto.UntrackedPalletDetection.prototype.getConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.UntrackedPalletDetection} returns this
 */
proto.fox.proto.UntrackedPalletDetection.prototype.setConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.Detections.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Detections.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Detections.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Detections} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Detections.toObject = function(includeInstance, msg) {
  var f, obj = {
    palletsList: jspb.Message.toObjectList(msg.getPalletsList(),
    proto.fox.proto.PalletDetection.toObject, includeInstance),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.fox.proto.TagDetection.toObject, includeInstance),
    untrackedPalletDetection: (f = msg.getUntrackedPalletDetection()) && proto.fox.proto.UntrackedPalletDetection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Detections}
 */
proto.fox.proto.Detections.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Detections;
  return proto.fox.proto.Detections.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Detections} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Detections}
 */
proto.fox.proto.Detections.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.PalletDetection;
      reader.readMessage(value,proto.fox.proto.PalletDetection.deserializeBinaryFromReader);
      msg.addPallets(value);
      break;
    case 2:
      var value = new proto.fox.proto.TagDetection;
      reader.readMessage(value,proto.fox.proto.TagDetection.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 3:
      var value = new proto.fox.proto.UntrackedPalletDetection;
      reader.readMessage(value,proto.fox.proto.UntrackedPalletDetection.deserializeBinaryFromReader);
      msg.setUntrackedPalletDetection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Detections.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Detections.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Detections} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Detections.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPalletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.PalletDetection.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.fox.proto.TagDetection.serializeBinaryToWriter
    );
  }
  f = message.getUntrackedPalletDetection();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.UntrackedPalletDetection.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PalletDetection pallets = 1;
 * @return {!Array<!proto.fox.proto.PalletDetection>}
 */
proto.fox.proto.Detections.prototype.getPalletsList = function() {
  return /** @type{!Array<!proto.fox.proto.PalletDetection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.PalletDetection, 1));
};


/**
 * @param {!Array<!proto.fox.proto.PalletDetection>} value
 * @return {!proto.fox.proto.Detections} returns this
*/
proto.fox.proto.Detections.prototype.setPalletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.PalletDetection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PalletDetection}
 */
proto.fox.proto.Detections.prototype.addPallets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.PalletDetection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.Detections} returns this
 */
proto.fox.proto.Detections.prototype.clearPalletsList = function() {
  return this.setPalletsList([]);
};


/**
 * repeated TagDetection tags = 2;
 * @return {!Array<!proto.fox.proto.TagDetection>}
 */
proto.fox.proto.Detections.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.fox.proto.TagDetection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.TagDetection, 2));
};


/**
 * @param {!Array<!proto.fox.proto.TagDetection>} value
 * @return {!proto.fox.proto.Detections} returns this
*/
proto.fox.proto.Detections.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.fox.proto.TagDetection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TagDetection}
 */
proto.fox.proto.Detections.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.fox.proto.TagDetection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.Detections} returns this
 */
proto.fox.proto.Detections.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional UntrackedPalletDetection untracked_pallet_detection = 3;
 * @return {?proto.fox.proto.UntrackedPalletDetection}
 */
proto.fox.proto.Detections.prototype.getUntrackedPalletDetection = function() {
  return /** @type{?proto.fox.proto.UntrackedPalletDetection} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.UntrackedPalletDetection, 3));
};


/**
 * @param {?proto.fox.proto.UntrackedPalletDetection|undefined} value
 * @return {!proto.fox.proto.Detections} returns this
*/
proto.fox.proto.Detections.prototype.setUntrackedPalletDetection = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Detections} returns this
 */
proto.fox.proto.Detections.prototype.clearUntrackedPalletDetection = function() {
  return this.setUntrackedPalletDetection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Detections.prototype.hasUntrackedPalletDetection = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.LoadHeightHistory.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LoadHeightHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LoadHeightHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LoadHeightHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LoadHeightHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastObservation: (f = msg.getLastObservation()) && proto.fox.proto.PoseTimePoint2f.toObject(includeInstance, f),
    loadHeightObservationsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
    loadHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LoadHeightHistory}
 */
proto.fox.proto.LoadHeightHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LoadHeightHistory;
  return proto.fox.proto.LoadHeightHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LoadHeightHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LoadHeightHistory}
 */
proto.fox.proto.LoadHeightHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.PoseTimePoint2f;
      reader.readMessage(value,proto.fox.proto.PoseTimePoint2f.deserializeBinaryFromReader);
      msg.setLastObservation(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setLoadHeightObservationsList(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLoadHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LoadHeightHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LoadHeightHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LoadHeightHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LoadHeightHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastObservation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.PoseTimePoint2f.serializeBinaryToWriter
    );
  }
  f = message.getLoadHeightObservationsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      2,
      f
    );
  }
  f = message.getLoadHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional PoseTimePoint2f last_observation = 1;
 * @return {?proto.fox.proto.PoseTimePoint2f}
 */
proto.fox.proto.LoadHeightHistory.prototype.getLastObservation = function() {
  return /** @type{?proto.fox.proto.PoseTimePoint2f} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PoseTimePoint2f, 1));
};


/**
 * @param {?proto.fox.proto.PoseTimePoint2f|undefined} value
 * @return {!proto.fox.proto.LoadHeightHistory} returns this
*/
proto.fox.proto.LoadHeightHistory.prototype.setLastObservation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.LoadHeightHistory} returns this
 */
proto.fox.proto.LoadHeightHistory.prototype.clearLastObservation = function() {
  return this.setLastObservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.LoadHeightHistory.prototype.hasLastObservation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated float load_height_observations = 2;
 * @return {!Array<number>}
 */
proto.fox.proto.LoadHeightHistory.prototype.getLoadHeightObservationsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LoadHeightHistory} returns this
 */
proto.fox.proto.LoadHeightHistory.prototype.setLoadHeightObservationsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LoadHeightHistory} returns this
 */
proto.fox.proto.LoadHeightHistory.prototype.addLoadHeightObservations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LoadHeightHistory} returns this
 */
proto.fox.proto.LoadHeightHistory.prototype.clearLoadHeightObservationsList = function() {
  return this.setLoadHeightObservationsList([]);
};


/**
 * optional float load_height = 3;
 * @return {number}
 */
proto.fox.proto.LoadHeightHistory.prototype.getLoadHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LoadHeightHistory} returns this
 */
proto.fox.proto.LoadHeightHistory.prototype.setLoadHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PalletObservation.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PalletObservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PalletObservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PalletObservation.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapTPallet: (f = msg.getMapTPallet()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    timeNs: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mapTRobot: (f = msg.getMapTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    cornersRobot: (f = msg.getCornersRobot()) && geometry_proto_geometry_pb.Quad3f.toObject(includeInstance, f),
    leftPocketRobot: (f = msg.getLeftPocketRobot()) && geometry_proto_geometry_pb.Quad3f.toObject(includeInstance, f),
    rightPocketRobot: (f = msg.getRightPocketRobot()) && geometry_proto_geometry_pb.Quad3f.toObject(includeInstance, f),
    supportType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    supportTypeV2: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PalletObservation}
 */
proto.fox.proto.PalletObservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PalletObservation;
  return proto.fox.proto.PalletObservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PalletObservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PalletObservation}
 */
proto.fox.proto.PalletObservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setMapTPallet(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.PalletObservation.ObservationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeNs(value);
      break;
    case 5:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTRobot(value);
      break;
    case 6:
      var value = new geometry_proto_geometry_pb.Quad3f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad3f.deserializeBinaryFromReader);
      msg.setCornersRobot(value);
      break;
    case 7:
      var value = new geometry_proto_geometry_pb.Quad3f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad3f.deserializeBinaryFromReader);
      msg.setLeftPocketRobot(value);
      break;
    case 8:
      var value = new geometry_proto_geometry_pb.Quad3f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad3f.deserializeBinaryFromReader);
      msg.setRightPocketRobot(value);
      break;
    case 9:
      var value = /** @type {!proto.fox.proto.TrackedPallet.PalletSupportType} */ (reader.readEnum());
      msg.setSupportType(value);
      break;
    case 10:
      var value = /** @type {!proto.fox.proto.SupportType} */ (reader.readEnum());
      msg.setSupportTypeV2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PalletObservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PalletObservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PalletObservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PalletObservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapTPallet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTimeNs();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMapTRobot();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getCornersRobot();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      geometry_proto_geometry_pb.Quad3f.serializeBinaryToWriter
    );
  }
  f = message.getLeftPocketRobot();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      geometry_proto_geometry_pb.Quad3f.serializeBinaryToWriter
    );
  }
  f = message.getRightPocketRobot();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      geometry_proto_geometry_pb.Quad3f.serializeBinaryToWriter
    );
  }
  f = message.getSupportType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getSupportTypeV2();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.PalletObservation.ObservationType = {
  UNKNOWN: 0,
  DETECTION: 1,
  CARRY: 2,
  GUESS: 3,
  CAMERA: 4,
  YOLO: 5,
  LIDAR: 6,
  TEMPLATE: 7,
  CONVEYOR_YOLO_MATCH: 8
};

/**
 * optional Transform3f map_t_pallet = 1;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.PalletObservation.prototype.getMapTPallet = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 1));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.PalletObservation} returns this
*/
proto.fox.proto.PalletObservation.prototype.setMapTPallet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletObservation} returns this
 */
proto.fox.proto.PalletObservation.prototype.clearMapTPallet = function() {
  return this.setMapTPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletObservation.prototype.hasMapTPallet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ObservationType type = 2;
 * @return {!proto.fox.proto.PalletObservation.ObservationType}
 */
proto.fox.proto.PalletObservation.prototype.getType = function() {
  return /** @type {!proto.fox.proto.PalletObservation.ObservationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.PalletObservation.ObservationType} value
 * @return {!proto.fox.proto.PalletObservation} returns this
 */
proto.fox.proto.PalletObservation.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.fox.proto.PalletObservation.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PalletObservation} returns this
 */
proto.fox.proto.PalletObservation.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int64 time_ns = 4;
 * @return {number}
 */
proto.fox.proto.PalletObservation.prototype.getTimeNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PalletObservation} returns this
 */
proto.fox.proto.PalletObservation.prototype.setTimeNs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Transform2f map_t_robot = 5;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.PalletObservation.prototype.getMapTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 5));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.PalletObservation} returns this
*/
proto.fox.proto.PalletObservation.prototype.setMapTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletObservation} returns this
 */
proto.fox.proto.PalletObservation.prototype.clearMapTRobot = function() {
  return this.setMapTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletObservation.prototype.hasMapTRobot = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Quad3f corners_robot = 6;
 * @return {?proto.fox.proto.Quad3f}
 */
proto.fox.proto.PalletObservation.prototype.getCornersRobot = function() {
  return /** @type{?proto.fox.proto.Quad3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_geometry_pb.Quad3f, 6));
};


/**
 * @param {?proto.fox.proto.Quad3f|undefined} value
 * @return {!proto.fox.proto.PalletObservation} returns this
*/
proto.fox.proto.PalletObservation.prototype.setCornersRobot = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletObservation} returns this
 */
proto.fox.proto.PalletObservation.prototype.clearCornersRobot = function() {
  return this.setCornersRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletObservation.prototype.hasCornersRobot = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Quad3f left_pocket_robot = 7;
 * @return {?proto.fox.proto.Quad3f}
 */
proto.fox.proto.PalletObservation.prototype.getLeftPocketRobot = function() {
  return /** @type{?proto.fox.proto.Quad3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_geometry_pb.Quad3f, 7));
};


/**
 * @param {?proto.fox.proto.Quad3f|undefined} value
 * @return {!proto.fox.proto.PalletObservation} returns this
*/
proto.fox.proto.PalletObservation.prototype.setLeftPocketRobot = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletObservation} returns this
 */
proto.fox.proto.PalletObservation.prototype.clearLeftPocketRobot = function() {
  return this.setLeftPocketRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletObservation.prototype.hasLeftPocketRobot = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Quad3f right_pocket_robot = 8;
 * @return {?proto.fox.proto.Quad3f}
 */
proto.fox.proto.PalletObservation.prototype.getRightPocketRobot = function() {
  return /** @type{?proto.fox.proto.Quad3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_geometry_pb.Quad3f, 8));
};


/**
 * @param {?proto.fox.proto.Quad3f|undefined} value
 * @return {!proto.fox.proto.PalletObservation} returns this
*/
proto.fox.proto.PalletObservation.prototype.setRightPocketRobot = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PalletObservation} returns this
 */
proto.fox.proto.PalletObservation.prototype.clearRightPocketRobot = function() {
  return this.setRightPocketRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PalletObservation.prototype.hasRightPocketRobot = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional TrackedPallet.PalletSupportType support_type = 9;
 * @return {!proto.fox.proto.TrackedPallet.PalletSupportType}
 */
proto.fox.proto.PalletObservation.prototype.getSupportType = function() {
  return /** @type {!proto.fox.proto.TrackedPallet.PalletSupportType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.fox.proto.TrackedPallet.PalletSupportType} value
 * @return {!proto.fox.proto.PalletObservation} returns this
 */
proto.fox.proto.PalletObservation.prototype.setSupportType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional SupportType support_type_v2 = 10;
 * @return {!proto.fox.proto.SupportType}
 */
proto.fox.proto.PalletObservation.prototype.getSupportTypeV2 = function() {
  return /** @type {!proto.fox.proto.SupportType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.fox.proto.SupportType} value
 * @return {!proto.fox.proto.PalletObservation} returns this
 */
proto.fox.proto.PalletObservation.prototype.setSupportTypeV2 = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.OpenSpaceObservation.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.OpenSpaceObservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.OpenSpaceObservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.OpenSpaceObservation.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowDepth: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timeNs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mapTRobot: (f = msg.getMapTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.OpenSpaceObservation}
 */
proto.fox.proto.OpenSpaceObservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.OpenSpaceObservation;
  return proto.fox.proto.OpenSpaceObservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.OpenSpaceObservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.OpenSpaceObservation}
 */
proto.fox.proto.OpenSpaceObservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRowDepth(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.OpenSpaceObservation.ObservationType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeNs(value);
      break;
    case 4:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTRobot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.OpenSpaceObservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.OpenSpaceObservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.OpenSpaceObservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.OpenSpaceObservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTimeNs();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMapTRobot();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.OpenSpaceObservation.ObservationType = {
  UNKNOWN: 0,
  LIDAR: 1,
  PALLETS: 2
};

/**
 * optional float row_depth = 1;
 * @return {number}
 */
proto.fox.proto.OpenSpaceObservation.prototype.getRowDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.OpenSpaceObservation} returns this
 */
proto.fox.proto.OpenSpaceObservation.prototype.setRowDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional ObservationType type = 2;
 * @return {!proto.fox.proto.OpenSpaceObservation.ObservationType}
 */
proto.fox.proto.OpenSpaceObservation.prototype.getType = function() {
  return /** @type {!proto.fox.proto.OpenSpaceObservation.ObservationType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.OpenSpaceObservation.ObservationType} value
 * @return {!proto.fox.proto.OpenSpaceObservation} returns this
 */
proto.fox.proto.OpenSpaceObservation.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 time_ns = 3;
 * @return {number}
 */
proto.fox.proto.OpenSpaceObservation.prototype.getTimeNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.OpenSpaceObservation} returns this
 */
proto.fox.proto.OpenSpaceObservation.prototype.setTimeNs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Transform2f map_t_robot = 4;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.OpenSpaceObservation.prototype.getMapTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 4));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.OpenSpaceObservation} returns this
*/
proto.fox.proto.OpenSpaceObservation.prototype.setMapTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.OpenSpaceObservation} returns this
 */
proto.fox.proto.OpenSpaceObservation.prototype.clearMapTRobot = function() {
  return this.setMapTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.OpenSpaceObservation.prototype.hasMapTRobot = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Pocket.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Pocket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Pocket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Pocket.toObject = function(includeInstance, msg) {
  var f, obj = {
    palletTPocket: (f = msg.getPalletTPocket()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    robotTPallet: (f = msg.getRobotTPallet()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    height: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    timeNs: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Pocket}
 */
proto.fox.proto.Pocket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Pocket;
  return proto.fox.proto.Pocket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Pocket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Pocket}
 */
proto.fox.proto.Pocket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setPalletTPocket(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setRobotTPallet(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeight(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeNs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Pocket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Pocket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Pocket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Pocket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPalletTPocket();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getRobotTPallet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTimeNs();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional Transform3f pallet_t_pocket = 1;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.Pocket.prototype.getPalletTPocket = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 1));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.Pocket} returns this
*/
proto.fox.proto.Pocket.prototype.setPalletTPocket = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Pocket} returns this
 */
proto.fox.proto.Pocket.prototype.clearPalletTPocket = function() {
  return this.setPalletTPocket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Pocket.prototype.hasPalletTPocket = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Transform3f robot_t_pallet = 2;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.Pocket.prototype.getRobotTPallet = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 2));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.Pocket} returns this
*/
proto.fox.proto.Pocket.prototype.setRobotTPallet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Pocket} returns this
 */
proto.fox.proto.Pocket.prototype.clearRobotTPallet = function() {
  return this.setRobotTPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Pocket.prototype.hasRobotTPallet = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float width = 3;
 * @return {number}
 */
proto.fox.proto.Pocket.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Pocket} returns this
 */
proto.fox.proto.Pocket.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float height = 4;
 * @return {number}
 */
proto.fox.proto.Pocket.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Pocket} returns this
 */
proto.fox.proto.Pocket.prototype.setHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int64 time_ns = 5;
 * @return {number}
 */
proto.fox.proto.Pocket.prototype.getTimeNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Pocket} returns this
 */
proto.fox.proto.Pocket.prototype.setTimeNs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PalletWeightEstimate.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PalletWeightEstimate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PalletWeightEstimate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PalletWeightEstimate.toObject = function(includeInstance, msg) {
  var f, obj = {
    numberOfReadings: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxPressureReading: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currentAggregatedPressureReading: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PalletWeightEstimate}
 */
proto.fox.proto.PalletWeightEstimate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PalletWeightEstimate;
  return proto.fox.proto.PalletWeightEstimate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PalletWeightEstimate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PalletWeightEstimate}
 */
proto.fox.proto.PalletWeightEstimate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfReadings(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxPressureReading(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentAggregatedPressureReading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PalletWeightEstimate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PalletWeightEstimate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PalletWeightEstimate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PalletWeightEstimate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumberOfReadings();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaxPressureReading();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getCurrentAggregatedPressureReading();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional int32 number_of_readings = 1;
 * @return {number}
 */
proto.fox.proto.PalletWeightEstimate.prototype.getNumberOfReadings = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PalletWeightEstimate} returns this
 */
proto.fox.proto.PalletWeightEstimate.prototype.setNumberOfReadings = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float max_pressure_reading = 2;
 * @return {number}
 */
proto.fox.proto.PalletWeightEstimate.prototype.getMaxPressureReading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PalletWeightEstimate} returns this
 */
proto.fox.proto.PalletWeightEstimate.prototype.setMaxPressureReading = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float current_aggregated_pressure_reading = 3;
 * @return {number}
 */
proto.fox.proto.PalletWeightEstimate.prototype.getCurrentAggregatedPressureReading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PalletWeightEstimate} returns this
 */
proto.fox.proto.PalletWeightEstimate.prototype.setCurrentAggregatedPressureReading = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.TrackedPallet.repeatedFields_ = [4,8,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.TrackedPallet.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.TrackedPallet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.TrackedPallet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TrackedPallet.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mapTPallet: (f = msg.getMapTPallet()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    shape: (f = msg.getShape()) && geometry_proto_transform_pb.Vector3f.toObject(includeInstance, f),
    supportType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    supportTypeV2: jspb.Message.getFieldWithDefault(msg, 14, 0),
    location: jspb.Message.getFieldWithDefault(msg, 5, 0),
    carriageTPallet: (f = msg.getCarriageTPallet()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    historyList: jspb.Message.toObjectList(msg.getHistoryList(),
    proto.fox.proto.PalletObservation.toObject, includeInstance),
    pocketsList: jspb.Message.toObjectList(msg.getPocketsList(),
    proto.fox.proto.Pocket.toObject, includeInstance),
    loadHeightHistory: (f = msg.getLoadHeightHistory()) && proto.fox.proto.LoadHeightHistory.toObject(includeInstance, f),
    exceptionsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    carriedLoadDistanceEstimate: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    weightEstimate: (f = msg.getWeightEstimate()) && proto.fox.proto.PalletWeightEstimate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.TrackedPallet}
 */
proto.fox.proto.TrackedPallet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.TrackedPallet;
  return proto.fox.proto.TrackedPallet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.TrackedPallet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.TrackedPallet}
 */
proto.fox.proto.TrackedPallet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setMapTPallet(value);
      break;
    case 3:
      var value = new geometry_proto_transform_pb.Vector3f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector3f.deserializeBinaryFromReader);
      msg.setShape(value);
      break;
    case 7:
      var value = /** @type {!proto.fox.proto.TrackedPallet.PalletSupportType} */ (reader.readEnum());
      msg.setSupportType(value);
      break;
    case 14:
      var value = /** @type {!proto.fox.proto.SupportType} */ (reader.readEnum());
      msg.setSupportTypeV2(value);
      break;
    case 5:
      var value = /** @type {!proto.fox.proto.TrackedPallet.PalletLocation} */ (reader.readEnum());
      msg.setLocation(value);
      break;
    case 6:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setCarriageTPallet(value);
      break;
    case 4:
      var value = new proto.fox.proto.PalletObservation;
      reader.readMessage(value,proto.fox.proto.PalletObservation.deserializeBinaryFromReader);
      msg.addHistory(value);
      break;
    case 8:
      var value = new proto.fox.proto.Pocket;
      reader.readMessage(value,proto.fox.proto.Pocket.deserializeBinaryFromReader);
      msg.addPockets(value);
      break;
    case 9:
      var value = new proto.fox.proto.LoadHeightHistory;
      reader.readMessage(value,proto.fox.proto.LoadHeightHistory.deserializeBinaryFromReader);
      msg.setLoadHeightHistory(value);
      break;
    case 10:
      var value = /** @type {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>} */ (reader.readPackedEnum());
      msg.setExceptionsList(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCarriedLoadDistanceEstimate(value);
      break;
    case 13:
      var value = new proto.fox.proto.PalletWeightEstimate;
      reader.readMessage(value,proto.fox.proto.PalletWeightEstimate.deserializeBinaryFromReader);
      msg.setWeightEstimate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.TrackedPallet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.TrackedPallet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.TrackedPallet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TrackedPallet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMapTPallet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getShape();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      geometry_proto_transform_pb.Vector3f.serializeBinaryToWriter
    );
  }
  f = message.getSupportType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getSupportTypeV2();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCarriageTPallet();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.fox.proto.PalletObservation.serializeBinaryToWriter
    );
  }
  f = message.getPocketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.fox.proto.Pocket.serializeBinaryToWriter
    );
  }
  f = message.getLoadHeightHistory();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.fox.proto.LoadHeightHistory.serializeBinaryToWriter
    );
  }
  f = message.getExceptionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getCarriedLoadDistanceEstimate();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getWeightEstimate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.fox.proto.PalletWeightEstimate.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.TrackedPallet.PalletSupportType = {
  STRINGER: 0,
  BLOCK: 1,
  SUPPORT_UNKNOWN: -1
};

/**
 * @enum {number}
 */
proto.fox.proto.TrackedPallet.PalletLocation = {
  UNKNOWN: 0,
  RESTING: 1,
  FORKS_INSIDE: 2,
  CARRIED: 3
};

/**
 * @enum {number}
 */
proto.fox.proto.TrackedPallet.PalletExceptionTypes = {
  PALLET_EXCEPTION_UNKNOWN: 0,
  AIRBAG: 1,
  PYRAMID: 2,
  SLIDING: 3,
  LEANING: 4,
  UNPICKABLE_TAIL_PALLET: 5,
  COLLAPSED_PALLET: 6,
  TOO_TALL_PALLET: 7,
  TAIL_PALLET_CLOSE_TO_RAMP_LIP: 8
};

/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.fox.proto.TrackedPallet.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Transform3f map_t_pallet = 2;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.TrackedPallet.prototype.getMapTPallet = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 2));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
*/
proto.fox.proto.TrackedPallet.prototype.setMapTPallet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.clearMapTPallet = function() {
  return this.setMapTPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TrackedPallet.prototype.hasMapTPallet = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Vector3f shape = 3;
 * @return {?proto.fox.proto.Vector3f}
 */
proto.fox.proto.TrackedPallet.prototype.getShape = function() {
  return /** @type{?proto.fox.proto.Vector3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector3f, 3));
};


/**
 * @param {?proto.fox.proto.Vector3f|undefined} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
*/
proto.fox.proto.TrackedPallet.prototype.setShape = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.clearShape = function() {
  return this.setShape(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TrackedPallet.prototype.hasShape = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PalletSupportType support_type = 7;
 * @return {!proto.fox.proto.TrackedPallet.PalletSupportType}
 */
proto.fox.proto.TrackedPallet.prototype.getSupportType = function() {
  return /** @type {!proto.fox.proto.TrackedPallet.PalletSupportType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.fox.proto.TrackedPallet.PalletSupportType} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.setSupportType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional SupportType support_type_v2 = 14;
 * @return {!proto.fox.proto.SupportType}
 */
proto.fox.proto.TrackedPallet.prototype.getSupportTypeV2 = function() {
  return /** @type {!proto.fox.proto.SupportType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.fox.proto.SupportType} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.setSupportTypeV2 = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional PalletLocation location = 5;
 * @return {!proto.fox.proto.TrackedPallet.PalletLocation}
 */
proto.fox.proto.TrackedPallet.prototype.getLocation = function() {
  return /** @type {!proto.fox.proto.TrackedPallet.PalletLocation} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fox.proto.TrackedPallet.PalletLocation} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.setLocation = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Transform2f carriage_t_pallet = 6;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.TrackedPallet.prototype.getCarriageTPallet = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 6));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
*/
proto.fox.proto.TrackedPallet.prototype.setCarriageTPallet = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.clearCarriageTPallet = function() {
  return this.setCarriageTPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TrackedPallet.prototype.hasCarriageTPallet = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated PalletObservation history = 4;
 * @return {!Array<!proto.fox.proto.PalletObservation>}
 */
proto.fox.proto.TrackedPallet.prototype.getHistoryList = function() {
  return /** @type{!Array<!proto.fox.proto.PalletObservation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.PalletObservation, 4));
};


/**
 * @param {!Array<!proto.fox.proto.PalletObservation>} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
*/
proto.fox.proto.TrackedPallet.prototype.setHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.fox.proto.PalletObservation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PalletObservation}
 */
proto.fox.proto.TrackedPallet.prototype.addHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.fox.proto.PalletObservation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.clearHistoryList = function() {
  return this.setHistoryList([]);
};


/**
 * repeated Pocket pockets = 8;
 * @return {!Array<!proto.fox.proto.Pocket>}
 */
proto.fox.proto.TrackedPallet.prototype.getPocketsList = function() {
  return /** @type{!Array<!proto.fox.proto.Pocket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.Pocket, 8));
};


/**
 * @param {!Array<!proto.fox.proto.Pocket>} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
*/
proto.fox.proto.TrackedPallet.prototype.setPocketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.fox.proto.Pocket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Pocket}
 */
proto.fox.proto.TrackedPallet.prototype.addPockets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.fox.proto.Pocket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.clearPocketsList = function() {
  return this.setPocketsList([]);
};


/**
 * optional LoadHeightHistory load_height_history = 9;
 * @return {?proto.fox.proto.LoadHeightHistory}
 */
proto.fox.proto.TrackedPallet.prototype.getLoadHeightHistory = function() {
  return /** @type{?proto.fox.proto.LoadHeightHistory} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.LoadHeightHistory, 9));
};


/**
 * @param {?proto.fox.proto.LoadHeightHistory|undefined} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
*/
proto.fox.proto.TrackedPallet.prototype.setLoadHeightHistory = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.clearLoadHeightHistory = function() {
  return this.setLoadHeightHistory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TrackedPallet.prototype.hasLoadHeightHistory = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated PalletExceptionTypes exceptions = 10;
 * @return {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>}
 */
proto.fox.proto.TrackedPallet.prototype.getExceptionsList = function() {
  return /** @type {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.setExceptionsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.fox.proto.TrackedPallet.PalletExceptionTypes} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.addExceptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.clearExceptionsList = function() {
  return this.setExceptionsList([]);
};


/**
 * optional float carried_load_distance_estimate = 11;
 * @return {number}
 */
proto.fox.proto.TrackedPallet.prototype.getCarriedLoadDistanceEstimate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.setCarriedLoadDistanceEstimate = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional PalletWeightEstimate weight_estimate = 13;
 * @return {?proto.fox.proto.PalletWeightEstimate}
 */
proto.fox.proto.TrackedPallet.prototype.getWeightEstimate = function() {
  return /** @type{?proto.fox.proto.PalletWeightEstimate} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PalletWeightEstimate, 13));
};


/**
 * @param {?proto.fox.proto.PalletWeightEstimate|undefined} value
 * @return {!proto.fox.proto.TrackedPallet} returns this
*/
proto.fox.proto.TrackedPallet.prototype.setWeightEstimate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TrackedPallet} returns this
 */
proto.fox.proto.TrackedPallet.prototype.clearWeightEstimate = function() {
  return this.setWeightEstimate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TrackedPallet.prototype.hasWeightEstimate = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.TrackedTag.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.TrackedTag.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.TrackedTag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.TrackedTag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TrackedTag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mapTTag: (f = msg.getMapTTag()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    historyList: jspb.Message.toObjectList(msg.getHistoryList(),
    proto.fox.proto.TagDetection.toObject, includeInstance),
    inferred: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    odometryAtConfirmation: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.TrackedTag}
 */
proto.fox.proto.TrackedTag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.TrackedTag;
  return proto.fox.proto.TrackedTag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.TrackedTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.TrackedTag}
 */
proto.fox.proto.TrackedTag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setMapTTag(value);
      break;
    case 4:
      var value = new proto.fox.proto.TagDetection;
      reader.readMessage(value,proto.fox.proto.TagDetection.deserializeBinaryFromReader);
      msg.addHistory(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInferred(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOdometryAtConfirmation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.TrackedTag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.TrackedTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.TrackedTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TrackedTag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMapTTag();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.fox.proto.TagDetection.serializeBinaryToWriter
    );
  }
  f = message.getInferred();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOdometryAtConfirmation();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.fox.proto.TrackedTag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TrackedTag} returns this
 */
proto.fox.proto.TrackedTag.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Transform3f map_t_tag = 2;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.TrackedTag.prototype.getMapTTag = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 2));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.TrackedTag} returns this
*/
proto.fox.proto.TrackedTag.prototype.setMapTTag = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.TrackedTag} returns this
 */
proto.fox.proto.TrackedTag.prototype.clearMapTTag = function() {
  return this.setMapTTag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.TrackedTag.prototype.hasMapTTag = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated TagDetection history = 4;
 * @return {!Array<!proto.fox.proto.TagDetection>}
 */
proto.fox.proto.TrackedTag.prototype.getHistoryList = function() {
  return /** @type{!Array<!proto.fox.proto.TagDetection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.TagDetection, 4));
};


/**
 * @param {!Array<!proto.fox.proto.TagDetection>} value
 * @return {!proto.fox.proto.TrackedTag} returns this
*/
proto.fox.proto.TrackedTag.prototype.setHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.fox.proto.TagDetection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TagDetection}
 */
proto.fox.proto.TrackedTag.prototype.addHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.fox.proto.TagDetection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.TrackedTag} returns this
 */
proto.fox.proto.TrackedTag.prototype.clearHistoryList = function() {
  return this.setHistoryList([]);
};


/**
 * optional bool inferred = 5;
 * @return {boolean}
 */
proto.fox.proto.TrackedTag.prototype.getInferred = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.TrackedTag} returns this
 */
proto.fox.proto.TrackedTag.prototype.setInferred = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional float odometry_at_confirmation = 6;
 * @return {number}
 */
proto.fox.proto.TrackedTag.prototype.getOdometryAtConfirmation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TrackedTag} returns this
 */
proto.fox.proto.TrackedTag.prototype.setOdometryAtConfirmation = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PersistentTag.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PersistentTag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PersistentTag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PersistentTag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    leftId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rightId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    parentsTTag: (f = msg.getParentsTTag()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PersistentTag}
 */
proto.fox.proto.PersistentTag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PersistentTag;
  return proto.fox.proto.PersistentTag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PersistentTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PersistentTag}
 */
proto.fox.proto.PersistentTag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeftId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRightId(value);
      break;
    case 4:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setParentsTTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PersistentTag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PersistentTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PersistentTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PersistentTag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLeftId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRightId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getParentsTTag();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.fox.proto.PersistentTag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PersistentTag} returns this
 */
proto.fox.proto.PersistentTag.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 left_id = 2;
 * @return {number}
 */
proto.fox.proto.PersistentTag.prototype.getLeftId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PersistentTag} returns this
 */
proto.fox.proto.PersistentTag.prototype.setLeftId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 right_id = 3;
 * @return {number}
 */
proto.fox.proto.PersistentTag.prototype.getRightId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PersistentTag} returns this
 */
proto.fox.proto.PersistentTag.prototype.setRightId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Transform2f parents_t_tag = 4;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.PersistentTag.prototype.getParentsTTag = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 4));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.PersistentTag} returns this
*/
proto.fox.proto.PersistentTag.prototype.setParentsTTag = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PersistentTag} returns this
 */
proto.fox.proto.PersistentTag.prototype.clearParentsTTag = function() {
  return this.setParentsTTag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PersistentTag.prototype.hasParentsTTag = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ZoneCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ZoneCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ZoneCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ZoneCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    trait: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requirementLevel: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ZoneCondition}
 */
proto.fox.proto.ZoneCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ZoneCondition;
  return proto.fox.proto.ZoneCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ZoneCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ZoneCondition}
 */
proto.fox.proto.ZoneCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.ZoneTrait} */ (reader.readEnum());
      msg.setTrait(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.ZoneCondition.RequirementLevel} */ (reader.readEnum());
      msg.setRequirementLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ZoneCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ZoneCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ZoneCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ZoneCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrait();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRequirementLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.ZoneCondition.RequirementLevel = {
  HARD: 0,
  SOFT: 1
};

/**
 * optional ZoneTrait trait = 1;
 * @return {!proto.fox.proto.ZoneTrait}
 */
proto.fox.proto.ZoneCondition.prototype.getTrait = function() {
  return /** @type {!proto.fox.proto.ZoneTrait} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.ZoneTrait} value
 * @return {!proto.fox.proto.ZoneCondition} returns this
 */
proto.fox.proto.ZoneCondition.prototype.setTrait = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional RequirementLevel requirement_level = 2;
 * @return {!proto.fox.proto.ZoneCondition.RequirementLevel}
 */
proto.fox.proto.ZoneCondition.prototype.getRequirementLevel = function() {
  return /** @type {!proto.fox.proto.ZoneCondition.RequirementLevel} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.ZoneCondition.RequirementLevel} value
 * @return {!proto.fox.proto.ZoneCondition} returns this
 */
proto.fox.proto.ZoneCondition.prototype.setRequirementLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ZoneRegion.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ZoneRegion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ZoneRegion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ZoneRegion.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapTFront: (f = msg.getMapTFront()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    depth: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    alignmentScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    frontTFront3: (f = msg.getFrontTFront3()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    front3IsEstimated: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ZoneRegion}
 */
proto.fox.proto.ZoneRegion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ZoneRegion;
  return proto.fox.proto.ZoneRegion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ZoneRegion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ZoneRegion}
 */
proto.fox.proto.ZoneRegion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTFront(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWidth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDepth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAlignmentScore(value);
      break;
    case 5:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setFrontTFront3(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFront3IsEstimated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ZoneRegion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ZoneRegion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ZoneRegion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ZoneRegion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapTFront();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAlignmentScore();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getFrontTFront3();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getFront3IsEstimated();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional Transform2f map_t_front = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.ZoneRegion.prototype.getMapTFront = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.ZoneRegion} returns this
*/
proto.fox.proto.ZoneRegion.prototype.setMapTFront = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ZoneRegion} returns this
 */
proto.fox.proto.ZoneRegion.prototype.clearMapTFront = function() {
  return this.setMapTFront(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ZoneRegion.prototype.hasMapTFront = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float width = 2;
 * @return {number}
 */
proto.fox.proto.ZoneRegion.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ZoneRegion} returns this
 */
proto.fox.proto.ZoneRegion.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float depth = 3;
 * @return {number}
 */
proto.fox.proto.ZoneRegion.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ZoneRegion} returns this
 */
proto.fox.proto.ZoneRegion.prototype.setDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float alignment_score = 4;
 * @return {number}
 */
proto.fox.proto.ZoneRegion.prototype.getAlignmentScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ZoneRegion} returns this
 */
proto.fox.proto.ZoneRegion.prototype.setAlignmentScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional Transform3f front_t_front3 = 5;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.ZoneRegion.prototype.getFrontTFront3 = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 5));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.ZoneRegion} returns this
*/
proto.fox.proto.ZoneRegion.prototype.setFrontTFront3 = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ZoneRegion} returns this
 */
proto.fox.proto.ZoneRegion.prototype.clearFrontTFront3 = function() {
  return this.setFrontTFront3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ZoneRegion.prototype.hasFrontTFront3 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool front3_is_estimated = 6;
 * @return {boolean}
 */
proto.fox.proto.ZoneRegion.prototype.getFront3IsEstimated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.ZoneRegion} returns this
 */
proto.fox.proto.ZoneRegion.prototype.setFront3IsEstimated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.TruckAlignmentState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.TruckAlignmentState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.TruckAlignmentState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TruckAlignmentState.toObject = function(includeInstance, msg) {
  var f, obj = {
    leftWallDepth: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    rightWallDepth: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.TruckAlignmentState}
 */
proto.fox.proto.TruckAlignmentState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.TruckAlignmentState;
  return proto.fox.proto.TruckAlignmentState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.TruckAlignmentState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.TruckAlignmentState}
 */
proto.fox.proto.TruckAlignmentState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLeftWallDepth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRightWallDepth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.TruckAlignmentState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.TruckAlignmentState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.TruckAlignmentState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.TruckAlignmentState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeftWallDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getRightWallDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float left_wall_depth = 1;
 * @return {number}
 */
proto.fox.proto.TruckAlignmentState.prototype.getLeftWallDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TruckAlignmentState} returns this
 */
proto.fox.proto.TruckAlignmentState.prototype.setLeftWallDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float right_wall_depth = 2;
 * @return {number}
 */
proto.fox.proto.TruckAlignmentState.prototype.getRightWallDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.TruckAlignmentState} returns this
 */
proto.fox.proto.TruckAlignmentState.prototype.setRightWallDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.ImuAngles.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ImuAngles.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ImuAngles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ImuAngles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ImuAngles.toObject = function(includeInstance, msg) {
  var f, obj = {
    rampPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    floorPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    imuPitchBufferList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    rampMeasured: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    floorMeasured: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ImuAngles}
 */
proto.fox.proto.ImuAngles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ImuAngles;
  return proto.fox.proto.ImuAngles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ImuAngles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ImuAngles}
 */
proto.fox.proto.ImuAngles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampPitch(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloorPitch(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setImuPitchBufferList(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRampMeasured(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFloorMeasured(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ImuAngles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ImuAngles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ImuAngles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ImuAngles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRampPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getFloorPitch();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getImuPitchBufferList();
  if (f.length > 0) {
    writer.writePackedFloat(
      3,
      f
    );
  }
  f = message.getRampMeasured();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFloorMeasured();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional float ramp_pitch = 1;
 * @return {number}
 */
proto.fox.proto.ImuAngles.prototype.getRampPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ImuAngles} returns this
 */
proto.fox.proto.ImuAngles.prototype.setRampPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float floor_pitch = 2;
 * @return {number}
 */
proto.fox.proto.ImuAngles.prototype.getFloorPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ImuAngles} returns this
 */
proto.fox.proto.ImuAngles.prototype.setFloorPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated float imu_pitch_buffer = 3;
 * @return {!Array<number>}
 */
proto.fox.proto.ImuAngles.prototype.getImuPitchBufferList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.ImuAngles} returns this
 */
proto.fox.proto.ImuAngles.prototype.setImuPitchBufferList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.ImuAngles} returns this
 */
proto.fox.proto.ImuAngles.prototype.addImuPitchBuffer = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ImuAngles} returns this
 */
proto.fox.proto.ImuAngles.prototype.clearImuPitchBufferList = function() {
  return this.setImuPitchBufferList([]);
};


/**
 * optional bool ramp_measured = 4;
 * @return {boolean}
 */
proto.fox.proto.ImuAngles.prototype.getRampMeasured = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.ImuAngles} returns this
 */
proto.fox.proto.ImuAngles.prototype.setRampMeasured = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool floor_measured = 5;
 * @return {boolean}
 */
proto.fox.proto.ImuAngles.prototype.getFloorMeasured = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.ImuAngles} returns this
 */
proto.fox.proto.ImuAngles.prototype.setFloorMeasured = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.PlaceLocation.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlaceLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlaceLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlaceLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapTPallet: (f = msg.getMapTPallet()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    palletId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    row: jspb.Message.getFieldWithDefault(msg, 4, 0),
    column: jspb.Message.getFieldWithDefault(msg, 5, 0),
    approachFacesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlaceLocation}
 */
proto.fox.proto.PlaceLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlaceLocation;
  return proto.fox.proto.PlaceLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlaceLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlaceLocation}
 */
proto.fox.proto.PlaceLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTPallet(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.PlaceLocation.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPalletId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRow(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setColumn(value);
      break;
    case 6:
      var value = /** @type {!Array<!proto.fox.proto.PalletFace>} */ (reader.readPackedEnum());
      msg.setApproachFacesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlaceLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlaceLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlaceLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlaceLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapTPallet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPalletId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRow();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getColumn();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getApproachFacesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.PlaceLocation.Status = {
  UNKNOWN: 0,
  FREE: 1,
  FILLED: 2,
  BLOCKED: 3,
  OBSCURED: 4,
  INACTIVE: 5,
  BLOCKED_ELIGIBLE: 6
};

/**
 * optional Transform2f map_t_pallet = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.PlaceLocation.prototype.getMapTPallet = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.PlaceLocation} returns this
*/
proto.fox.proto.PlaceLocation.prototype.setMapTPallet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlaceLocation} returns this
 */
proto.fox.proto.PlaceLocation.prototype.clearMapTPallet = function() {
  return this.setMapTPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlaceLocation.prototype.hasMapTPallet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {!proto.fox.proto.PlaceLocation.Status}
 */
proto.fox.proto.PlaceLocation.prototype.getStatus = function() {
  return /** @type {!proto.fox.proto.PlaceLocation.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.PlaceLocation.Status} value
 * @return {!proto.fox.proto.PlaceLocation} returns this
 */
proto.fox.proto.PlaceLocation.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 pallet_id = 3;
 * @return {number}
 */
proto.fox.proto.PlaceLocation.prototype.getPalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceLocation} returns this
 */
proto.fox.proto.PlaceLocation.prototype.setPalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 row = 4;
 * @return {number}
 */
proto.fox.proto.PlaceLocation.prototype.getRow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceLocation} returns this
 */
proto.fox.proto.PlaceLocation.prototype.setRow = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 column = 5;
 * @return {number}
 */
proto.fox.proto.PlaceLocation.prototype.getColumn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlaceLocation} returns this
 */
proto.fox.proto.PlaceLocation.prototype.setColumn = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated PalletFace approach_faces = 6;
 * @return {!Array<!proto.fox.proto.PalletFace>}
 */
proto.fox.proto.PlaceLocation.prototype.getApproachFacesList = function() {
  return /** @type {!Array<!proto.fox.proto.PalletFace>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.fox.proto.PalletFace>} value
 * @return {!proto.fox.proto.PlaceLocation} returns this
 */
proto.fox.proto.PlaceLocation.prototype.setApproachFacesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.fox.proto.PalletFace} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PlaceLocation} returns this
 */
proto.fox.proto.PlaceLocation.prototype.addApproachFaces = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PlaceLocation} returns this
 */
proto.fox.proto.PlaceLocation.prototype.clearApproachFacesList = function() {
  return this.setApproachFacesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.WeatherGuards.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.WeatherGuards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.WeatherGuards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.WeatherGuards.toObject = function(includeInstance, msg) {
  var f, obj = {
    leftWeatherGuardMap: (f = msg.getLeftWeatherGuardMap()) && geometry_proto_geometry_pb.Quad2f.toObject(includeInstance, f),
    rightWeatherGuardMap: (f = msg.getRightWeatherGuardMap()) && geometry_proto_geometry_pb.Quad2f.toObject(includeInstance, f),
    weatherGuardsDetected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    leftWeatherGuardDetected: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    rightWeatherGuardDetected: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.WeatherGuards}
 */
proto.fox.proto.WeatherGuards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.WeatherGuards;
  return proto.fox.proto.WeatherGuards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.WeatherGuards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.WeatherGuards}
 */
proto.fox.proto.WeatherGuards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_geometry_pb.Quad2f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad2f.deserializeBinaryFromReader);
      msg.setLeftWeatherGuardMap(value);
      break;
    case 2:
      var value = new geometry_proto_geometry_pb.Quad2f;
      reader.readMessage(value,geometry_proto_geometry_pb.Quad2f.deserializeBinaryFromReader);
      msg.setRightWeatherGuardMap(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWeatherGuardsDetected(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLeftWeatherGuardDetected(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRightWeatherGuardDetected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.WeatherGuards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.WeatherGuards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.WeatherGuards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.WeatherGuards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeftWeatherGuardMap();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_geometry_pb.Quad2f.serializeBinaryToWriter
    );
  }
  f = message.getRightWeatherGuardMap();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_geometry_pb.Quad2f.serializeBinaryToWriter
    );
  }
  f = message.getWeatherGuardsDetected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLeftWeatherGuardDetected();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getRightWeatherGuardDetected();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional Quad2f left_weather_guard_map = 1;
 * @return {?proto.fox.proto.Quad2f}
 */
proto.fox.proto.WeatherGuards.prototype.getLeftWeatherGuardMap = function() {
  return /** @type{?proto.fox.proto.Quad2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_geometry_pb.Quad2f, 1));
};


/**
 * @param {?proto.fox.proto.Quad2f|undefined} value
 * @return {!proto.fox.proto.WeatherGuards} returns this
*/
proto.fox.proto.WeatherGuards.prototype.setLeftWeatherGuardMap = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.WeatherGuards} returns this
 */
proto.fox.proto.WeatherGuards.prototype.clearLeftWeatherGuardMap = function() {
  return this.setLeftWeatherGuardMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.WeatherGuards.prototype.hasLeftWeatherGuardMap = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Quad2f right_weather_guard_map = 2;
 * @return {?proto.fox.proto.Quad2f}
 */
proto.fox.proto.WeatherGuards.prototype.getRightWeatherGuardMap = function() {
  return /** @type{?proto.fox.proto.Quad2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_geometry_pb.Quad2f, 2));
};


/**
 * @param {?proto.fox.proto.Quad2f|undefined} value
 * @return {!proto.fox.proto.WeatherGuards} returns this
*/
proto.fox.proto.WeatherGuards.prototype.setRightWeatherGuardMap = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.WeatherGuards} returns this
 */
proto.fox.proto.WeatherGuards.prototype.clearRightWeatherGuardMap = function() {
  return this.setRightWeatherGuardMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.WeatherGuards.prototype.hasRightWeatherGuardMap = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool weather_guards_detected = 3;
 * @return {boolean}
 */
proto.fox.proto.WeatherGuards.prototype.getWeatherGuardsDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.WeatherGuards} returns this
 */
proto.fox.proto.WeatherGuards.prototype.setWeatherGuardsDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool left_weather_guard_detected = 4;
 * @return {boolean}
 */
proto.fox.proto.WeatherGuards.prototype.getLeftWeatherGuardDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.WeatherGuards} returns this
 */
proto.fox.proto.WeatherGuards.prototype.setLeftWeatherGuardDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool right_weather_guard_detected = 5;
 * @return {boolean}
 */
proto.fox.proto.WeatherGuards.prototype.getRightWeatherGuardDetected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.WeatherGuards} returns this
 */
proto.fox.proto.WeatherGuards.prototype.setRightWeatherGuardDetected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.BackWallDetectorState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.BackWallDetectorState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.BackWallDetectorState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.BackWallDetectorState.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxDepth: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    numDetections: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.BackWallDetectorState}
 */
proto.fox.proto.BackWallDetectorState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.BackWallDetectorState;
  return proto.fox.proto.BackWallDetectorState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.BackWallDetectorState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.BackWallDetectorState}
 */
proto.fox.proto.BackWallDetectorState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxDepth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumDetections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.BackWallDetectorState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.BackWallDetectorState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.BackWallDetectorState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.BackWallDetectorState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getNumDetections();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional float max_depth = 1;
 * @return {number}
 */
proto.fox.proto.BackWallDetectorState.prototype.getMaxDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.BackWallDetectorState} returns this
 */
proto.fox.proto.BackWallDetectorState.prototype.setMaxDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int32 num_detections = 2;
 * @return {number}
 */
proto.fox.proto.BackWallDetectorState.prototype.getNumDetections = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.BackWallDetectorState} returns this
 */
proto.fox.proto.BackWallDetectorState.prototype.setNumDetections = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.PickPlaceZone.repeatedFields_ = [10,11,17,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PickPlaceZone.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PickPlaceZone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PickPlaceZone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PickPlaceZone.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0),
    palletRegion: (f = msg.getPalletRegion()) && proto.fox.proto.ZoneRegion.toObject(includeInstance, f),
    rampRegion: (f = msg.getRampRegion()) && proto.fox.proto.ZoneRegion.toObject(includeInstance, f),
    physicalRampRegion: (f = msg.getPhysicalRampRegion()) && proto.fox.proto.ZoneRegion.toObject(includeInstance, f),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.fox.proto.TrackedTag.toObject, includeInstance),
    placeLocationsList: jspb.Message.toObjectList(msg.getPlaceLocationsList(),
    proto.fox.proto.PlaceLocation.toObject, includeInstance),
    clearedHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    frontObstaclePadding: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    leftObstaclePadding: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    rightObstaclePadding: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    expectStraight: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    expectTurned: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    hasBackWall: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    hasLeftWall: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    hasRightWall: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    hasRampWalls: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    lipToRampWall: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    rampWallLength: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    anchoringScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    rowDepth: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    historyList: jspb.Message.toObjectList(msg.getHistoryList(),
    proto.fox.proto.OpenSpaceObservation.toObject, includeInstance),
    zoneTApproachesList: jspb.Message.toObjectList(msg.getZoneTApproachesList(),
    geometry_proto_transform_pb.Transform2f.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 19, 0),
    truckAlignment: (f = msg.getTruckAlignment()) && proto.fox.proto.TruckAlignmentState.toObject(includeInstance, f),
    imuAngles: (f = msg.getImuAngles()) && proto.fox.proto.ImuAngles.toObject(includeInstance, f),
    hasWeatherGuards: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    detectedWeatherGuards: (f = msg.getDetectedWeatherGuards()) && proto.fox.proto.WeatherGuards.toObject(includeInstance, f),
    backWallDetectorState: (f = msg.getBackWallDetectorState()) && proto.fox.proto.BackWallDetectorState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PickPlaceZone}
 */
proto.fox.proto.PickPlaceZone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PickPlaceZone;
  return proto.fox.proto.PickPlaceZone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PickPlaceZone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PickPlaceZone}
 */
proto.fox.proto.PickPlaceZone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 8:
      var value = new proto.fox.proto.ZoneRegion;
      reader.readMessage(value,proto.fox.proto.ZoneRegion.deserializeBinaryFromReader);
      msg.setPalletRegion(value);
      break;
    case 9:
      var value = new proto.fox.proto.ZoneRegion;
      reader.readMessage(value,proto.fox.proto.ZoneRegion.deserializeBinaryFromReader);
      msg.setRampRegion(value);
      break;
    case 23:
      var value = new proto.fox.proto.ZoneRegion;
      reader.readMessage(value,proto.fox.proto.ZoneRegion.deserializeBinaryFromReader);
      msg.setPhysicalRampRegion(value);
      break;
    case 10:
      var value = new proto.fox.proto.TrackedTag;
      reader.readMessage(value,proto.fox.proto.TrackedTag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 11:
      var value = new proto.fox.proto.PlaceLocation;
      reader.readMessage(value,proto.fox.proto.PlaceLocation.deserializeBinaryFromReader);
      msg.addPlaceLocations(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClearedHeight(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFrontObstaclePadding(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLeftObstaclePadding(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRightObstaclePadding(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpectStraight(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpectTurned(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBackWall(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasLeftWall(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRightWall(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasRampWalls(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLipToRampWall(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampWallLength(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAnchoringScore(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRowDepth(value);
      break;
    case 17:
      var value = new proto.fox.proto.OpenSpaceObservation;
      reader.readMessage(value,proto.fox.proto.OpenSpaceObservation.deserializeBinaryFromReader);
      msg.addHistory(value);
      break;
    case 18:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.addZoneTApproaches(value);
      break;
    case 19:
      var value = /** @type {!proto.fox.proto.PickPlaceZone.Error} */ (reader.readEnum());
      msg.setError(value);
      break;
    case 21:
      var value = new proto.fox.proto.TruckAlignmentState;
      reader.readMessage(value,proto.fox.proto.TruckAlignmentState.deserializeBinaryFromReader);
      msg.setTruckAlignment(value);
      break;
    case 24:
      var value = new proto.fox.proto.ImuAngles;
      reader.readMessage(value,proto.fox.proto.ImuAngles.deserializeBinaryFromReader);
      msg.setImuAngles(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasWeatherGuards(value);
      break;
    case 29:
      var value = new proto.fox.proto.WeatherGuards;
      reader.readMessage(value,proto.fox.proto.WeatherGuards.deserializeBinaryFromReader);
      msg.setDetectedWeatherGuards(value);
      break;
    case 35:
      var value = new proto.fox.proto.BackWallDetectorState;
      reader.readMessage(value,proto.fox.proto.BackWallDetectorState.deserializeBinaryFromReader);
      msg.setBackWallDetectorState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PickPlaceZone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PickPlaceZone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PickPlaceZone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PickPlaceZone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPalletRegion();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.fox.proto.ZoneRegion.serializeBinaryToWriter
    );
  }
  f = message.getRampRegion();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.fox.proto.ZoneRegion.serializeBinaryToWriter
    );
  }
  f = message.getPhysicalRampRegion();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.fox.proto.ZoneRegion.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.fox.proto.TrackedTag.serializeBinaryToWriter
    );
  }
  f = message.getPlaceLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.fox.proto.PlaceLocation.serializeBinaryToWriter
    );
  }
  f = message.getClearedHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      28,
      f
    );
  }
  f = message.getFrontObstaclePadding();
  if (f !== 0.0) {
    writer.writeFloat(
      30,
      f
    );
  }
  f = message.getLeftObstaclePadding();
  if (f !== 0.0) {
    writer.writeFloat(
      31,
      f
    );
  }
  f = message.getRightObstaclePadding();
  if (f !== 0.0) {
    writer.writeFloat(
      32,
      f
    );
  }
  f = message.getExpectStraight();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getExpectTurned();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getHasBackWall();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getHasLeftWall();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getHasRightWall();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getHasRampWalls();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getLipToRampWall();
  if (f !== 0.0) {
    writer.writeFloat(
      25,
      f
    );
  }
  f = message.getRampWallLength();
  if (f !== 0.0) {
    writer.writeFloat(
      26,
      f
    );
  }
  f = message.getAnchoringScore();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getRowDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.fox.proto.OpenSpaceObservation.serializeBinaryToWriter
    );
  }
  f = message.getZoneTApproachesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getTruckAlignment();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.fox.proto.TruckAlignmentState.serializeBinaryToWriter
    );
  }
  f = message.getImuAngles();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.fox.proto.ImuAngles.serializeBinaryToWriter
    );
  }
  f = message.getHasWeatherGuards();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getDetectedWeatherGuards();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.fox.proto.WeatherGuards.serializeBinaryToWriter
    );
  }
  f = message.getBackWallDetectorState();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.fox.proto.BackWallDetectorState.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.PickPlaceZone.Error = {
  OK: 0,
  INSUFFICIENT_WIDTH: 1,
  BAD_CONFIG: 2
};

/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.fox.proto.PickPlaceZone.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 type = 7;
 * @return {number}
 */
proto.fox.proto.PickPlaceZone.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional ZoneRegion pallet_region = 8;
 * @return {?proto.fox.proto.ZoneRegion}
 */
proto.fox.proto.PickPlaceZone.prototype.getPalletRegion = function() {
  return /** @type{?proto.fox.proto.ZoneRegion} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ZoneRegion, 8));
};


/**
 * @param {?proto.fox.proto.ZoneRegion|undefined} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
*/
proto.fox.proto.PickPlaceZone.prototype.setPalletRegion = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.clearPalletRegion = function() {
  return this.setPalletRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.hasPalletRegion = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ZoneRegion ramp_region = 9;
 * @return {?proto.fox.proto.ZoneRegion}
 */
proto.fox.proto.PickPlaceZone.prototype.getRampRegion = function() {
  return /** @type{?proto.fox.proto.ZoneRegion} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ZoneRegion, 9));
};


/**
 * @param {?proto.fox.proto.ZoneRegion|undefined} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
*/
proto.fox.proto.PickPlaceZone.prototype.setRampRegion = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.clearRampRegion = function() {
  return this.setRampRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.hasRampRegion = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ZoneRegion physical_ramp_region = 23;
 * @return {?proto.fox.proto.ZoneRegion}
 */
proto.fox.proto.PickPlaceZone.prototype.getPhysicalRampRegion = function() {
  return /** @type{?proto.fox.proto.ZoneRegion} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ZoneRegion, 23));
};


/**
 * @param {?proto.fox.proto.ZoneRegion|undefined} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
*/
proto.fox.proto.PickPlaceZone.prototype.setPhysicalRampRegion = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.clearPhysicalRampRegion = function() {
  return this.setPhysicalRampRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.hasPhysicalRampRegion = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * repeated TrackedTag tags = 10;
 * @return {!Array<!proto.fox.proto.TrackedTag>}
 */
proto.fox.proto.PickPlaceZone.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.fox.proto.TrackedTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.TrackedTag, 10));
};


/**
 * @param {!Array<!proto.fox.proto.TrackedTag>} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
*/
proto.fox.proto.PickPlaceZone.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.fox.proto.TrackedTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.TrackedTag}
 */
proto.fox.proto.PickPlaceZone.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.fox.proto.TrackedTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated PlaceLocation place_locations = 11;
 * @return {!Array<!proto.fox.proto.PlaceLocation>}
 */
proto.fox.proto.PickPlaceZone.prototype.getPlaceLocationsList = function() {
  return /** @type{!Array<!proto.fox.proto.PlaceLocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.PlaceLocation, 11));
};


/**
 * @param {!Array<!proto.fox.proto.PlaceLocation>} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
*/
proto.fox.proto.PickPlaceZone.prototype.setPlaceLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.fox.proto.PlaceLocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.PlaceLocation}
 */
proto.fox.proto.PickPlaceZone.prototype.addPlaceLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.fox.proto.PlaceLocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.clearPlaceLocationsList = function() {
  return this.setPlaceLocationsList([]);
};


/**
 * optional float cleared_height = 28;
 * @return {number}
 */
proto.fox.proto.PickPlaceZone.prototype.getClearedHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setClearedHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional float front_obstacle_padding = 30;
 * @return {number}
 */
proto.fox.proto.PickPlaceZone.prototype.getFrontObstaclePadding = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setFrontObstaclePadding = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional float left_obstacle_padding = 31;
 * @return {number}
 */
proto.fox.proto.PickPlaceZone.prototype.getLeftObstaclePadding = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setLeftObstaclePadding = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional float right_obstacle_padding = 32;
 * @return {number}
 */
proto.fox.proto.PickPlaceZone.prototype.getRightObstaclePadding = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setRightObstaclePadding = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * optional bool expect_straight = 33;
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.getExpectStraight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setExpectStraight = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool expect_turned = 34;
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.getExpectTurned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setExpectTurned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool has_back_wall = 12;
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.getHasBackWall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setHasBackWall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool has_left_wall = 13;
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.getHasLeftWall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setHasLeftWall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool has_right_wall = 14;
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.getHasRightWall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setHasRightWall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool has_ramp_walls = 20;
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.getHasRampWalls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setHasRampWalls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional float lip_to_ramp_wall = 25;
 * @return {number}
 */
proto.fox.proto.PickPlaceZone.prototype.getLipToRampWall = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setLipToRampWall = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional float ramp_wall_length = 26;
 * @return {number}
 */
proto.fox.proto.PickPlaceZone.prototype.getRampWallLength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setRampWallLength = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional float anchoring_score = 15;
 * @return {number}
 */
proto.fox.proto.PickPlaceZone.prototype.getAnchoringScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setAnchoringScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional float row_depth = 16;
 * @return {number}
 */
proto.fox.proto.PickPlaceZone.prototype.getRowDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setRowDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * repeated OpenSpaceObservation history = 17;
 * @return {!Array<!proto.fox.proto.OpenSpaceObservation>}
 */
proto.fox.proto.PickPlaceZone.prototype.getHistoryList = function() {
  return /** @type{!Array<!proto.fox.proto.OpenSpaceObservation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.OpenSpaceObservation, 17));
};


/**
 * @param {!Array<!proto.fox.proto.OpenSpaceObservation>} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
*/
proto.fox.proto.PickPlaceZone.prototype.setHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.fox.proto.OpenSpaceObservation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.OpenSpaceObservation}
 */
proto.fox.proto.PickPlaceZone.prototype.addHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.fox.proto.OpenSpaceObservation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.clearHistoryList = function() {
  return this.setHistoryList([]);
};


/**
 * repeated Transform2f zone_t_approaches = 18;
 * @return {!Array<!proto.fox.proto.Transform2f>}
 */
proto.fox.proto.PickPlaceZone.prototype.getZoneTApproachesList = function() {
  return /** @type{!Array<!proto.fox.proto.Transform2f>} */ (
    jspb.Message.getRepeatedWrapperField(this, geometry_proto_transform_pb.Transform2f, 18));
};


/**
 * @param {!Array<!proto.fox.proto.Transform2f>} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
*/
proto.fox.proto.PickPlaceZone.prototype.setZoneTApproachesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.fox.proto.Transform2f=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Transform2f}
 */
proto.fox.proto.PickPlaceZone.prototype.addZoneTApproaches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.fox.proto.Transform2f, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.clearZoneTApproachesList = function() {
  return this.setZoneTApproachesList([]);
};


/**
 * optional Error error = 19;
 * @return {!proto.fox.proto.PickPlaceZone.Error}
 */
proto.fox.proto.PickPlaceZone.prototype.getError = function() {
  return /** @type {!proto.fox.proto.PickPlaceZone.Error} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.fox.proto.PickPlaceZone.Error} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setError = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional TruckAlignmentState truck_alignment = 21;
 * @return {?proto.fox.proto.TruckAlignmentState}
 */
proto.fox.proto.PickPlaceZone.prototype.getTruckAlignment = function() {
  return /** @type{?proto.fox.proto.TruckAlignmentState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.TruckAlignmentState, 21));
};


/**
 * @param {?proto.fox.proto.TruckAlignmentState|undefined} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
*/
proto.fox.proto.PickPlaceZone.prototype.setTruckAlignment = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.clearTruckAlignment = function() {
  return this.setTruckAlignment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.hasTruckAlignment = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional ImuAngles imu_angles = 24;
 * @return {?proto.fox.proto.ImuAngles}
 */
proto.fox.proto.PickPlaceZone.prototype.getImuAngles = function() {
  return /** @type{?proto.fox.proto.ImuAngles} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ImuAngles, 24));
};


/**
 * @param {?proto.fox.proto.ImuAngles|undefined} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
*/
proto.fox.proto.PickPlaceZone.prototype.setImuAngles = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.clearImuAngles = function() {
  return this.setImuAngles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.hasImuAngles = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional bool has_weather_guards = 27;
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.getHasWeatherGuards = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.setHasWeatherGuards = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional WeatherGuards detected_weather_guards = 29;
 * @return {?proto.fox.proto.WeatherGuards}
 */
proto.fox.proto.PickPlaceZone.prototype.getDetectedWeatherGuards = function() {
  return /** @type{?proto.fox.proto.WeatherGuards} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.WeatherGuards, 29));
};


/**
 * @param {?proto.fox.proto.WeatherGuards|undefined} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
*/
proto.fox.proto.PickPlaceZone.prototype.setDetectedWeatherGuards = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.clearDetectedWeatherGuards = function() {
  return this.setDetectedWeatherGuards(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.hasDetectedWeatherGuards = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional BackWallDetectorState back_wall_detector_state = 35;
 * @return {?proto.fox.proto.BackWallDetectorState}
 */
proto.fox.proto.PickPlaceZone.prototype.getBackWallDetectorState = function() {
  return /** @type{?proto.fox.proto.BackWallDetectorState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.BackWallDetectorState, 35));
};


/**
 * @param {?proto.fox.proto.BackWallDetectorState|undefined} value
 * @return {!proto.fox.proto.PickPlaceZone} returns this
*/
proto.fox.proto.PickPlaceZone.prototype.setBackWallDetectorState = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PickPlaceZone} returns this
 */
proto.fox.proto.PickPlaceZone.prototype.clearBackWallDetectorState = function() {
  return this.setBackWallDetectorState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PickPlaceZone.prototype.hasBackWallDetectorState = function() {
  return jspb.Message.getField(this, 35) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.NoGoZoneConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.NoGoZoneConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.NoGoZoneConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NoGoZoneConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    depth: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    isOptional: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.NoGoZoneConfig}
 */
proto.fox.proto.NoGoZoneConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.NoGoZoneConfig;
  return proto.fox.proto.NoGoZoneConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.NoGoZoneConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.NoGoZoneConfig}
 */
proto.fox.proto.NoGoZoneConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDepth(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOptional(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.NoGoZoneConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.NoGoZoneConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.NoGoZoneConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NoGoZoneConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDepth();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getIsOptional();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.fox.proto.NoGoZoneConfig.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.NoGoZoneConfig} returns this
 */
proto.fox.proto.NoGoZoneConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float depth = 2;
 * @return {number}
 */
proto.fox.proto.NoGoZoneConfig.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.NoGoZoneConfig} returns this
 */
proto.fox.proto.NoGoZoneConfig.prototype.setDepth = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool is_optional = 3;
 * @return {boolean}
 */
proto.fox.proto.NoGoZoneConfig.prototype.getIsOptional = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.NoGoZoneConfig} returns this
 */
proto.fox.proto.NoGoZoneConfig.prototype.setIsOptional = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.NoGoZone.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.NoGoZone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.NoGoZone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NoGoZone.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    region: (f = msg.getRegion()) && proto.fox.proto.ZoneRegion.toObject(includeInstance, f),
    leftTag: (f = msg.getLeftTag()) && proto.fox.proto.TrackedTag.toObject(includeInstance, f),
    rightTag: (f = msg.getRightTag()) && proto.fox.proto.TrackedTag.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.NoGoZone}
 */
proto.fox.proto.NoGoZone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.NoGoZone;
  return proto.fox.proto.NoGoZone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.NoGoZone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.NoGoZone}
 */
proto.fox.proto.NoGoZone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.fox.proto.ZoneRegion;
      reader.readMessage(value,proto.fox.proto.ZoneRegion.deserializeBinaryFromReader);
      msg.setRegion(value);
      break;
    case 3:
      var value = new proto.fox.proto.TrackedTag;
      reader.readMessage(value,proto.fox.proto.TrackedTag.deserializeBinaryFromReader);
      msg.setLeftTag(value);
      break;
    case 4:
      var value = new proto.fox.proto.TrackedTag;
      reader.readMessage(value,proto.fox.proto.TrackedTag.deserializeBinaryFromReader);
      msg.setRightTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.NoGoZone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.NoGoZone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.NoGoZone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NoGoZone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRegion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.ZoneRegion.serializeBinaryToWriter
    );
  }
  f = message.getLeftTag();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.TrackedTag.serializeBinaryToWriter
    );
  }
  f = message.getRightTag();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.TrackedTag.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.fox.proto.NoGoZone.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.NoGoZone} returns this
 */
proto.fox.proto.NoGoZone.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ZoneRegion region = 2;
 * @return {?proto.fox.proto.ZoneRegion}
 */
proto.fox.proto.NoGoZone.prototype.getRegion = function() {
  return /** @type{?proto.fox.proto.ZoneRegion} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ZoneRegion, 2));
};


/**
 * @param {?proto.fox.proto.ZoneRegion|undefined} value
 * @return {!proto.fox.proto.NoGoZone} returns this
*/
proto.fox.proto.NoGoZone.prototype.setRegion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.NoGoZone} returns this
 */
proto.fox.proto.NoGoZone.prototype.clearRegion = function() {
  return this.setRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.NoGoZone.prototype.hasRegion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TrackedTag left_tag = 3;
 * @return {?proto.fox.proto.TrackedTag}
 */
proto.fox.proto.NoGoZone.prototype.getLeftTag = function() {
  return /** @type{?proto.fox.proto.TrackedTag} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.TrackedTag, 3));
};


/**
 * @param {?proto.fox.proto.TrackedTag|undefined} value
 * @return {!proto.fox.proto.NoGoZone} returns this
*/
proto.fox.proto.NoGoZone.prototype.setLeftTag = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.NoGoZone} returns this
 */
proto.fox.proto.NoGoZone.prototype.clearLeftTag = function() {
  return this.setLeftTag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.NoGoZone.prototype.hasLeftTag = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TrackedTag right_tag = 4;
 * @return {?proto.fox.proto.TrackedTag}
 */
proto.fox.proto.NoGoZone.prototype.getRightTag = function() {
  return /** @type{?proto.fox.proto.TrackedTag} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.TrackedTag, 4));
};


/**
 * @param {?proto.fox.proto.TrackedTag|undefined} value
 * @return {!proto.fox.proto.NoGoZone} returns this
*/
proto.fox.proto.NoGoZone.prototype.setRightTag = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.NoGoZone} returns this
 */
proto.fox.proto.NoGoZone.prototype.clearRightTag = function() {
  return this.setRightTag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.NoGoZone.prototype.hasRightTag = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PoseTimePoint2f.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PoseTimePoint2f.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PoseTimePoint2f} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PoseTimePoint2f.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapTRobot: (f = msg.getMapTRobot()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    timeNs: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PoseTimePoint2f}
 */
proto.fox.proto.PoseTimePoint2f.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PoseTimePoint2f;
  return proto.fox.proto.PoseTimePoint2f.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PoseTimePoint2f} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PoseTimePoint2f}
 */
proto.fox.proto.PoseTimePoint2f.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTRobot(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeNs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PoseTimePoint2f.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PoseTimePoint2f.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PoseTimePoint2f} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PoseTimePoint2f.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapTRobot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getTimeNs();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional Transform2f map_t_robot = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.PoseTimePoint2f.prototype.getMapTRobot = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.PoseTimePoint2f} returns this
*/
proto.fox.proto.PoseTimePoint2f.prototype.setMapTRobot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PoseTimePoint2f} returns this
 */
proto.fox.proto.PoseTimePoint2f.prototype.clearMapTRobot = function() {
  return this.setMapTRobot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PoseTimePoint2f.prototype.hasMapTRobot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 time_ns = 2;
 * @return {number}
 */
proto.fox.proto.PoseTimePoint2f.prototype.getTimeNs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PoseTimePoint2f} returns this
 */
proto.fox.proto.PoseTimePoint2f.prototype.setTimeNs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.LidarLevelState.repeatedFields_ = [2,3,4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.LidarLevelState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.LidarLevelState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.LidarLevelState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LidarLevelState.toObject = function(includeInstance, msg) {
  var f, obj = {
    isInitialized: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    leftIndexesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    rightIndexesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    sickIndexesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    mapTRobotInitialized: (f = msg.getMapTRobotInitialized()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    maxDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    headingsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f,
    levelness: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.LidarLevelState}
 */
proto.fox.proto.LidarLevelState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.LidarLevelState;
  return proto.fox.proto.LidarLevelState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.LidarLevelState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.LidarLevelState}
 */
proto.fox.proto.LidarLevelState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInitialized(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setLeftIndexesList(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setRightIndexesList(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setSickIndexesList(value);
      break;
    case 5:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTRobotInitialized(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxDistance(value);
      break;
    case 7:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setHeadingsList(value);
      break;
    case 8:
      var value = /** @type {!proto.fox.proto.LidarLevelState.LidarLevelness} */ (reader.readEnum());
      msg.setLevelness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.LidarLevelState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.LidarLevelState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.LidarLevelState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.LidarLevelState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsInitialized();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLeftIndexesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getRightIndexesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getSickIndexesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getMapTRobotInitialized();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getMaxDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getHeadingsList();
  if (f.length > 0) {
    writer.writePackedFloat(
      7,
      f
    );
  }
  f = message.getLevelness();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.LidarLevelState.LidarLevelness = {
  UNKNOWN: 0,
  LEVEL: 1,
  UNLEVEL: 2
};

/**
 * optional bool is_initialized = 1;
 * @return {boolean}
 */
proto.fox.proto.LidarLevelState.prototype.getIsInitialized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.setIsInitialized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated int32 left_indexes = 2;
 * @return {!Array<number>}
 */
proto.fox.proto.LidarLevelState.prototype.getLeftIndexesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.setLeftIndexesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.addLeftIndexes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.clearLeftIndexesList = function() {
  return this.setLeftIndexesList([]);
};


/**
 * repeated int32 right_indexes = 3;
 * @return {!Array<number>}
 */
proto.fox.proto.LidarLevelState.prototype.getRightIndexesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.setRightIndexesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.addRightIndexes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.clearRightIndexesList = function() {
  return this.setRightIndexesList([]);
};


/**
 * repeated int32 sick_indexes = 4;
 * @return {!Array<number>}
 */
proto.fox.proto.LidarLevelState.prototype.getSickIndexesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.setSickIndexesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.addSickIndexes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.clearSickIndexesList = function() {
  return this.setSickIndexesList([]);
};


/**
 * optional Transform2f map_t_robot_initialized = 5;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.LidarLevelState.prototype.getMapTRobotInitialized = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 5));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.LidarLevelState} returns this
*/
proto.fox.proto.LidarLevelState.prototype.setMapTRobotInitialized = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.clearMapTRobotInitialized = function() {
  return this.setMapTRobotInitialized(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.LidarLevelState.prototype.hasMapTRobotInitialized = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional float max_distance = 6;
 * @return {number}
 */
proto.fox.proto.LidarLevelState.prototype.getMaxDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.setMaxDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * repeated float headings = 7;
 * @return {!Array<number>}
 */
proto.fox.proto.LidarLevelState.prototype.getHeadingsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.setHeadingsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.addHeadings = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.clearHeadingsList = function() {
  return this.setHeadingsList([]);
};


/**
 * optional LidarLevelness levelness = 8;
 * @return {!proto.fox.proto.LidarLevelState.LidarLevelness}
 */
proto.fox.proto.LidarLevelState.prototype.getLevelness = function() {
  return /** @type {!proto.fox.proto.LidarLevelState.LidarLevelness} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.fox.proto.LidarLevelState.LidarLevelness} value
 * @return {!proto.fox.proto.LidarLevelState} returns this
 */
proto.fox.proto.LidarLevelState.prototype.setLevelness = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.ForksDetectionState.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ForksDetectionState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ForksDetectionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ForksDetectionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ForksDetectionState.toObject = function(includeInstance, msg) {
  var f, obj = {
    leftHistogramList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    rightHistogramList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
    count: jspb.Message.getFieldWithDefault(msg, 3, 0),
    leftCenter: (f = msg.getLeftCenter()) && geometry_proto_transform_pb.Vector2f.toObject(includeInstance, f),
    rightCenter: (f = msg.getRightCenter()) && geometry_proto_transform_pb.Vector2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ForksDetectionState}
 */
proto.fox.proto.ForksDetectionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ForksDetectionState;
  return proto.fox.proto.ForksDetectionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ForksDetectionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ForksDetectionState}
 */
proto.fox.proto.ForksDetectionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedDouble());
      msg.setLeftHistogramList(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedDouble());
      msg.setRightHistogramList(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 4:
      var value = new geometry_proto_transform_pb.Vector2f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2f.deserializeBinaryFromReader);
      msg.setLeftCenter(value);
      break;
    case 5:
      var value = new geometry_proto_transform_pb.Vector2f;
      reader.readMessage(value,geometry_proto_transform_pb.Vector2f.deserializeBinaryFromReader);
      msg.setRightCenter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ForksDetectionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ForksDetectionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ForksDetectionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ForksDetectionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeftHistogramList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
  f = message.getRightHistogramList();
  if (f.length > 0) {
    writer.writePackedDouble(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLeftCenter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      geometry_proto_transform_pb.Vector2f.serializeBinaryToWriter
    );
  }
  f = message.getRightCenter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      geometry_proto_transform_pb.Vector2f.serializeBinaryToWriter
    );
  }
};


/**
 * repeated double left_histogram = 1;
 * @return {!Array<number>}
 */
proto.fox.proto.ForksDetectionState.prototype.getLeftHistogramList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.ForksDetectionState} returns this
 */
proto.fox.proto.ForksDetectionState.prototype.setLeftHistogramList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.ForksDetectionState} returns this
 */
proto.fox.proto.ForksDetectionState.prototype.addLeftHistogram = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ForksDetectionState} returns this
 */
proto.fox.proto.ForksDetectionState.prototype.clearLeftHistogramList = function() {
  return this.setLeftHistogramList([]);
};


/**
 * repeated double right_histogram = 2;
 * @return {!Array<number>}
 */
proto.fox.proto.ForksDetectionState.prototype.getRightHistogramList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.ForksDetectionState} returns this
 */
proto.fox.proto.ForksDetectionState.prototype.setRightHistogramList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.ForksDetectionState} returns this
 */
proto.fox.proto.ForksDetectionState.prototype.addRightHistogram = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ForksDetectionState} returns this
 */
proto.fox.proto.ForksDetectionState.prototype.clearRightHistogramList = function() {
  return this.setRightHistogramList([]);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.fox.proto.ForksDetectionState.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ForksDetectionState} returns this
 */
proto.fox.proto.ForksDetectionState.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Vector2f left_center = 4;
 * @return {?proto.fox.proto.Vector2f}
 */
proto.fox.proto.ForksDetectionState.prototype.getLeftCenter = function() {
  return /** @type{?proto.fox.proto.Vector2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector2f, 4));
};


/**
 * @param {?proto.fox.proto.Vector2f|undefined} value
 * @return {!proto.fox.proto.ForksDetectionState} returns this
*/
proto.fox.proto.ForksDetectionState.prototype.setLeftCenter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForksDetectionState} returns this
 */
proto.fox.proto.ForksDetectionState.prototype.clearLeftCenter = function() {
  return this.setLeftCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForksDetectionState.prototype.hasLeftCenter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Vector2f right_center = 5;
 * @return {?proto.fox.proto.Vector2f}
 */
proto.fox.proto.ForksDetectionState.prototype.getRightCenter = function() {
  return /** @type{?proto.fox.proto.Vector2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Vector2f, 5));
};


/**
 * @param {?proto.fox.proto.Vector2f|undefined} value
 * @return {!proto.fox.proto.ForksDetectionState} returns this
*/
proto.fox.proto.ForksDetectionState.prototype.setRightCenter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ForksDetectionState} returns this
 */
proto.fox.proto.ForksDetectionState.prototype.clearRightCenter = function() {
  return this.setRightCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ForksDetectionState.prototype.hasRightCenter = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PerceptionState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PerceptionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PerceptionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PerceptionState.toObject = function(includeInstance, msg) {
  var f, obj = {
    lidarLevelState: (f = msg.getLidarLevelState()) && proto.fox.proto.LidarLevelState.toObject(includeInstance, f),
    forksDetectionState: (f = msg.getForksDetectionState()) && proto.fox.proto.ForksDetectionState.toObject(includeInstance, f),
    forkTipGroundDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PerceptionState}
 */
proto.fox.proto.PerceptionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PerceptionState;
  return proto.fox.proto.PerceptionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PerceptionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PerceptionState}
 */
proto.fox.proto.PerceptionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.LidarLevelState;
      reader.readMessage(value,proto.fox.proto.LidarLevelState.deserializeBinaryFromReader);
      msg.setLidarLevelState(value);
      break;
    case 2:
      var value = new proto.fox.proto.ForksDetectionState;
      reader.readMessage(value,proto.fox.proto.ForksDetectionState.deserializeBinaryFromReader);
      msg.setForksDetectionState(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setForkTipGroundDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PerceptionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PerceptionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PerceptionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PerceptionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLidarLevelState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fox.proto.LidarLevelState.serializeBinaryToWriter
    );
  }
  f = message.getForksDetectionState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.ForksDetectionState.serializeBinaryToWriter
    );
  }
  f = message.getForkTipGroundDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional LidarLevelState lidar_level_state = 1;
 * @return {?proto.fox.proto.LidarLevelState}
 */
proto.fox.proto.PerceptionState.prototype.getLidarLevelState = function() {
  return /** @type{?proto.fox.proto.LidarLevelState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.LidarLevelState, 1));
};


/**
 * @param {?proto.fox.proto.LidarLevelState|undefined} value
 * @return {!proto.fox.proto.PerceptionState} returns this
*/
proto.fox.proto.PerceptionState.prototype.setLidarLevelState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PerceptionState} returns this
 */
proto.fox.proto.PerceptionState.prototype.clearLidarLevelState = function() {
  return this.setLidarLevelState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PerceptionState.prototype.hasLidarLevelState = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ForksDetectionState forks_detection_state = 2;
 * @return {?proto.fox.proto.ForksDetectionState}
 */
proto.fox.proto.PerceptionState.prototype.getForksDetectionState = function() {
  return /** @type{?proto.fox.proto.ForksDetectionState} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ForksDetectionState, 2));
};


/**
 * @param {?proto.fox.proto.ForksDetectionState|undefined} value
 * @return {!proto.fox.proto.PerceptionState} returns this
*/
proto.fox.proto.PerceptionState.prototype.setForksDetectionState = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PerceptionState} returns this
 */
proto.fox.proto.PerceptionState.prototype.clearForksDetectionState = function() {
  return this.setForksDetectionState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PerceptionState.prototype.hasForksDetectionState = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float fork_tip_ground_distance = 3;
 * @return {number}
 */
proto.fox.proto.PerceptionState.prototype.getForkTipGroundDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PerceptionState} returns this
 */
proto.fox.proto.PerceptionState.prototype.setForkTipGroundDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.fox.proto.SupportType = {
  UNKNOWN_TYPE: 0,
  BLOCK: 1,
  STRINGER: 2
};

/**
 * @enum {number}
 */
proto.fox.proto.ZoneTrait = {
  UNKNOWN: 0,
  DETECTED_FOUR_TAGS: 1,
  DETECTED_BACK_PALLETS: 2,
  DETECTED_PALLET: 3,
  DETECTED_TRUCK_SIDE_WALLS: 4,
  DETECTED_TRUCK_BACK_WALL: 5,
  SUFFICIENT_WIDTH: 6,
  SUFFICIENT_DEPTH: 7,
  SUFFICIENT_SPACE: 8,
  FOUND_PLACE_LOCATIONS: 10
};

goog.object.extend(exports, proto.fox.proto);
