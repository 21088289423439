import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import {
    Button,
    createStyles,
    Typography,
    withStyles,
    Box,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { CloudUpload } from '@material-ui/icons'
import { listBulkLogUploadsRequest, listSyncDemands } from "../../redux/actions";
import BulkLogUploadDialog from "./BulkLogUploadDialog";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import moment from "moment";
import { protoEnumFieldName } from "../../utils/protos";
import FoxGrid from "../FoxGrid/foxGrid";
import { Column } from "react-table";

const styles = (theme: Theme) =>
  createStyles({
    content: {
      padding: '24px',
      backgroundColor: '#78909c08',
      borderTop: '1px solid #0000000a',
    },
    hero: {
      height: '256px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleIcon: {
      fontSize: 48,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '32px',
    }
  });

interface Props {
  classes: any;
  dispatch: any;
}

const LogUploadsPage = (props: Props) => {
    const { classes } = props;
    const [ creatingRequest, setCreatingRequest ] = useState<boolean>(false);
    const [ bulkLogUploads, setBulkLogUploads ] = useState<m_pb.BulkLogUpload.AsObject[]>([]);
    const [ syncDemands, setSyncDemands ] = useState<m_pb.SyncDemand.AsObject[]>([]);

    useEffect(() => {
      props.dispatch(listBulkLogUploadsRequest())
        .then((resp: m_pb.ListBulkLogUploadsResponse.AsObject) => {
            setBulkLogUploads(resp.bulkLogUploadsList);
        });
    }, []);

    useEffect(() => {
      props.dispatch(listSyncDemands())
        .then((resp: m_pb.ListSyncDemandsResponse.AsObject) => {
            setSyncDemands(resp.syncsList);
        });
    }, []);

    const data = syncDemands.filter((syncDemand) => !syncDemand.bulkUpload);
    const columns: Column<m_pb.SyncDemand.AsObject>[] = useMemo(
      () => [
        {
          Header: 'Run Name',
          accessor: 'runName',
        },
        {
          Header: 'Requested By',
          accessor: 'requestedBy'
        },
        {
          Header: 'Status',
          accessor: (syncDemand) => protoEnumFieldName(cc_pb.SyncStatus, syncDemand.status)
        },
      ],
      []
    );

    const bulkColumns: Column<m_pb.BulkLogUpload.AsObject>[] = useMemo(
      () => [
        {
          Header: 'Requested By',
          accessor: 'requestedBy'
        },
        {
          Header: 'Requested Date',
          accessor: (logUpload) => moment(logUpload.requestedTime / 1e6).format("YYYY-MM-DD hh:mm:ss")
        },
        {
          Header: 'Status',
          accessor: (logUpload) => logUpload.cancelled ? "Cancelled" : logUpload.complete ? "Complete" : "In-progress"
        },
        {
          Header: 'Progress',
          accessor: (logUpload) => {
            return(
              `Completed ${logUpload.relatedSyncDemandsList.filter(syncDemand => syncDemand.status === cc_pb.SyncStatus.SYNC_UPLOAD_SUCCEEDED).length} / ${logUpload.relatedSyncDemandsList.length} logs`
            )
          }
        },
      ],
      []
    )

    return(
      <div>
        <Box className={classes.hero}>
          <Typography className={props.classes.header} variant="h3">Manage Log Uploads</Typography>
        </Box>
        <BulkLogUploadDialog
            open={creatingRequest}
            onClose={() => setCreatingRequest(false)}
        />
        <Box className={classes.content}>
          <Box className={classes.buttonContainer}>
            <Button
                className={props.classes.createButton}
                color="primary" variant="contained"
                onClick={() => setCreatingRequest(true)}
            >
              Request log uploads
            </Button>
          </Box>
          
          <FoxGrid
            title={'Bulk Log Uploads'}
            icon={<CloudUpload className={classes.titleIcon} color={'secondary'}/>}
            data={bulkLogUploads}
            columns={bulkColumns as Column<any>[]}
          />
          <Box marginTop={'64px'}/>
          <FoxGrid
            title={'Individual Log Upload Requests'}
            icon={<CloudUpload className={classes.titleIcon} color={'secondary'}/>}
            data={data}
            columns={columns as Column<any>[]}
            renderEmptyRows={true}
          />
        </Box>
      </div>
    )
};

export default connect()(withStyles(styles)(LogUploadsPage));
