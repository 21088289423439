import { configureStore, Middleware } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";
import { middleware as thunkMiddleware } from "redux-saga-thunk";
import { MonitoringClient } from "../_proto/command_control/monitoring/proto/monitoring_pb_service";
import rootSaga from "./sagas";
import rootReducer from './'

const sagaMiddleware = createSagaMiddleware();
// Create the Redux store, which stores all of the application's state.

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({ serializableCheck: false })
    .concat(thunkMiddleware as Middleware)
    .concat(sagaMiddleware)
})

const MONITORING_RPC_URL = `${process.env.MONITORING_RPC_HOST}/monitoring/api/rpc/v1`;

console.log(`Connecting to rpc at ${MONITORING_RPC_URL}`);

export const client = new MonitoringClient(MONITORING_RPC_URL);

sagaMiddleware.run(rootSaga, client);

export default store;
