import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';

/**
 * @see
 * https://redux.js.org/usage/structuring-reducers/initializing-state
 * At the start of the application, all reducers are dispatched a dummy init action
 * The initially undefined state is replaced with an initialState, provided by the reducer (or in our case createSlice);
 */

export type SearchTarget = 'robot' | 'cohort';

type RobotStatusState = {
  searchString: string;
  searchTarget: SearchTarget;
  includeInactive: boolean;
};

const initialState: RobotStatusState = {
  searchString: '',
  searchTarget: 'robot',
  includeInactive: false,
};

/**
* @see
* https://redux-toolkit.js.org/api/createSlice
* createSlice is a utility function that simplifies the creation of typed actions, reducers, and state.
* You provide an initial state, reducers that act on that state, and in return receive a set of typed actions & action creators
* You also receive a single merged reducer which you can then export
*/

const robotStatusSlice = createSlice({
  name: 'robotStatus',
  initialState: initialState,
  reducers: {
    // Specify new reducers here. They are combined and exported as a single reducer
    setSearchString(state, action: PayloadAction<string>) {
      // State is an immer WritableDraft (not the original state) and can be directly mutated
      state.searchString = action.payload;
    },
    setIncludeInactive(state, action: PayloadAction<boolean>) {
      // State is an immer WritableDraft (not the original state) and can be directly mutated
      state.includeInactive = action.payload;
    },
    setSearchTarget(state, action: PayloadAction<SearchTarget>) {
      state.searchTarget = action.payload;
    }
  },
});

// Typed action creators are now available
export const { setSearchString, setIncludeInactive, setSearchTarget } = robotStatusSlice.actions;

// Also a single reducer is created automatically (no need to merge!)
export default robotStatusSlice.reducer;
