const authTokenKey = "trello-auth-token";
const authTokenUpdatedKey = "trello-auth-token-last-updated";
// The trello API key is designed to be publicly accessible / safe to commit.
export const trelloApiKey = "a076c01daca77f9bfc8ef48832c3161f";

export function getTrelloToken(): string | undefined {
  const cached = localStorage.getItem(authTokenKey);
  const lastUpdated = localStorage.getItem(authTokenUpdatedKey);
  const cachedIsStale =
    lastUpdated &&
    new Date().valueOf() - new Date(lastUpdated).valueOf() >
      18 * 60 * 60 * 1000;
  if (cached && !cachedIsStale) {
    return cached;
  }
  if (cachedIsStale) {
    localStorage.removeItem(authTokenKey);
    localStorage.removeItem(authTokenUpdatedKey);
  }
  const hash = window.location.hash;
  if (!hash.startsWith("#token=")) {
    return undefined;
  }
  const parts = hash.split("=");
  if (parts.length < 2) {
    return undefined;
  }
  const token = parts[1];
  localStorage.setItem(authTokenKey, token);
  localStorage.setItem(authTokenUpdatedKey, new Date().toString());
  return token;
}
