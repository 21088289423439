// source: log/fault.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var detection_proto_detection_pb = require('../detection/proto/detection_pb.js');
goog.object.extend(proto, detection_proto_detection_pb);
var planner_proto_planner_status_pb = require('../planner/proto/planner_status_pb.js');
goog.object.extend(proto, planner_proto_planner_status_pb);
var planner_proto_types_pb = require('../planner/proto/types_pb.js');
goog.object.extend(proto, planner_proto_types_pb);
var robot_proto_fault_pb = require('../robot/proto/fault_pb.js');
goog.object.extend(proto, robot_proto_fault_pb);
goog.exportSymbol('proto.fox.proto.DriveStuck', null, global);
goog.exportSymbol('proto.fox.proto.Fault', null, global);
goog.exportSymbol('proto.fox.proto.Fault.DetailsCase', null, global);
goog.exportSymbol('proto.fox.proto.FaultState', null, global);
goog.exportSymbol('proto.fox.proto.Hesitation', null, global);
goog.exportSymbol('proto.fox.proto.MastStuck', null, global);
goog.exportSymbol('proto.fox.proto.OffPath', null, global);
goog.exportSymbol('proto.fox.proto.PathBlocked', null, global);
goog.exportSymbol('proto.fox.proto.PickPlaceZoneError', null, global);
goog.exportSymbol('proto.fox.proto.PlanningFailure', null, global);
goog.exportSymbol('proto.fox.proto.SafetyScannerEngaged', null, global);
goog.exportSymbol('proto.fox.proto.SensorId', null, global);
goog.exportSymbol('proto.fox.proto.ShallowPick', null, global);
goog.exportSymbol('proto.fox.proto.StaleSensorData', null, global);
goog.exportSymbol('proto.fox.proto.Stoppage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.FaultState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.FaultState.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.FaultState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.FaultState.displayName = 'proto.fox.proto.FaultState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Fault = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fox.proto.Fault.oneofGroups_);
};
goog.inherits(proto.fox.proto.Fault, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Fault.displayName = 'proto.fox.proto.Fault';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PathBlocked = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PathBlocked, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PathBlocked.displayName = 'proto.fox.proto.PathBlocked';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Stoppage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.Stoppage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Stoppage.displayName = 'proto.fox.proto.Stoppage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.StaleSensorData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.StaleSensorData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.StaleSensorData.displayName = 'proto.fox.proto.StaleSensorData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.OffPath = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.OffPath, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.OffPath.displayName = 'proto.fox.proto.OffPath';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlanningFailure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PlanningFailure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlanningFailure.displayName = 'proto.fox.proto.PlanningFailure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.SafetyScannerEngaged = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.SafetyScannerEngaged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.SafetyScannerEngaged.displayName = 'proto.fox.proto.SafetyScannerEngaged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.MastStuck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.MastStuck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.MastStuck.displayName = 'proto.fox.proto.MastStuck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.DriveStuck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.DriveStuck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.DriveStuck.displayName = 'proto.fox.proto.DriveStuck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ShallowPick = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.ShallowPick, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ShallowPick.displayName = 'proto.fox.proto.ShallowPick';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Hesitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.Hesitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Hesitation.displayName = 'proto.fox.proto.Hesitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PickPlaceZoneError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PickPlaceZoneError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PickPlaceZoneError.displayName = 'proto.fox.proto.PickPlaceZoneError';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.FaultState.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.FaultState.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.FaultState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.FaultState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.FaultState.toObject = function(includeInstance, msg) {
  var f, obj = {
    faultsList: jspb.Message.toObjectList(msg.getFaultsList(),
    proto.fox.proto.Fault.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.FaultState}
 */
proto.fox.proto.FaultState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.FaultState;
  return proto.fox.proto.FaultState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.FaultState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.FaultState}
 */
proto.fox.proto.FaultState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fox.proto.Fault;
      reader.readMessage(value,proto.fox.proto.Fault.deserializeBinaryFromReader);
      msg.addFaults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.FaultState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.FaultState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.FaultState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.FaultState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fox.proto.Fault.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Fault faults = 1;
 * @return {!Array<!proto.fox.proto.Fault>}
 */
proto.fox.proto.FaultState.prototype.getFaultsList = function() {
  return /** @type{!Array<!proto.fox.proto.Fault>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.Fault, 1));
};


/**
 * @param {!Array<!proto.fox.proto.Fault>} value
 * @return {!proto.fox.proto.FaultState} returns this
*/
proto.fox.proto.FaultState.prototype.setFaultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fox.proto.Fault=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Fault}
 */
proto.fox.proto.FaultState.prototype.addFaults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fox.proto.Fault, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.FaultState} returns this
 */
proto.fox.proto.FaultState.prototype.clearFaultsList = function() {
  return this.setFaultsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fox.proto.Fault.oneofGroups_ = [[4,5,6,7,8,9,13,14,17,18,19,20,21]];

/**
 * @enum {number}
 */
proto.fox.proto.Fault.DetailsCase = {
  DETAILS_NOT_SET: 0,
  PATH_BLOCKED: 4,
  PLANNING_FAILURE: 5,
  SAFETY_SCANNER_ENGAGED: 6,
  MAST_STUCK: 7,
  DRIVE_STUCK: 8,
  SHALLOW_PICK: 9,
  STALE_SENSOR_DATA: 13,
  HESITATION: 14,
  OFF_PATH: 17,
  PICK_PLACE_ZONE_ERROR: 18,
  STOPPAGE: 19,
  VALIDATION_ERROR: 20,
  ROBOT_FAULT: 21
};

/**
 * @return {proto.fox.proto.Fault.DetailsCase}
 */
proto.fox.proto.Fault.prototype.getDetailsCase = function() {
  return /** @type {proto.fox.proto.Fault.DetailsCase} */(jspb.Message.computeOneofCase(this, proto.fox.proto.Fault.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Fault.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Fault.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Fault} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Fault.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 10, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endTimeConfirmationDelay: jspb.Message.getFieldWithDefault(msg, 15, 0),
    endTimePendingConfirmation: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    note: jspb.Message.getFieldWithDefault(msg, 3, ""),
    suggestedIntervention: jspb.Message.getFieldWithDefault(msg, 11, ""),
    description: jspb.Message.getFieldWithDefault(msg, 22, ""),
    isConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    pathBlocked: (f = msg.getPathBlocked()) && proto.fox.proto.PathBlocked.toObject(includeInstance, f),
    planningFailure: (f = msg.getPlanningFailure()) && proto.fox.proto.PlanningFailure.toObject(includeInstance, f),
    safetyScannerEngaged: (f = msg.getSafetyScannerEngaged()) && proto.fox.proto.SafetyScannerEngaged.toObject(includeInstance, f),
    mastStuck: (f = msg.getMastStuck()) && proto.fox.proto.MastStuck.toObject(includeInstance, f),
    driveStuck: (f = msg.getDriveStuck()) && proto.fox.proto.DriveStuck.toObject(includeInstance, f),
    shallowPick: (f = msg.getShallowPick()) && proto.fox.proto.ShallowPick.toObject(includeInstance, f),
    staleSensorData: (f = msg.getStaleSensorData()) && proto.fox.proto.StaleSensorData.toObject(includeInstance, f),
    hesitation: (f = msg.getHesitation()) && proto.fox.proto.Hesitation.toObject(includeInstance, f),
    offPath: (f = msg.getOffPath()) && proto.fox.proto.OffPath.toObject(includeInstance, f),
    pickPlaceZoneError: (f = msg.getPickPlaceZoneError()) && proto.fox.proto.PickPlaceZoneError.toObject(includeInstance, f),
    stoppage: (f = msg.getStoppage()) && proto.fox.proto.Stoppage.toObject(includeInstance, f),
    validationError: (f = msg.getValidationError()) && planner_proto_types_pb.ValidationError.toObject(includeInstance, f),
    robotFault: (f = msg.getRobotFault()) && robot_proto_fault_pb.RobotFault.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Fault}
 */
proto.fox.proto.Fault.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Fault;
  return proto.fox.proto.Fault.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Fault} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Fault}
 */
proto.fox.proto.Fault.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTimeConfirmationDelay(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEndTimePendingConfirmation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuggestedIntervention(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConfirmed(value);
      break;
    case 4:
      var value = new proto.fox.proto.PathBlocked;
      reader.readMessage(value,proto.fox.proto.PathBlocked.deserializeBinaryFromReader);
      msg.setPathBlocked(value);
      break;
    case 5:
      var value = new proto.fox.proto.PlanningFailure;
      reader.readMessage(value,proto.fox.proto.PlanningFailure.deserializeBinaryFromReader);
      msg.setPlanningFailure(value);
      break;
    case 6:
      var value = new proto.fox.proto.SafetyScannerEngaged;
      reader.readMessage(value,proto.fox.proto.SafetyScannerEngaged.deserializeBinaryFromReader);
      msg.setSafetyScannerEngaged(value);
      break;
    case 7:
      var value = new proto.fox.proto.MastStuck;
      reader.readMessage(value,proto.fox.proto.MastStuck.deserializeBinaryFromReader);
      msg.setMastStuck(value);
      break;
    case 8:
      var value = new proto.fox.proto.DriveStuck;
      reader.readMessage(value,proto.fox.proto.DriveStuck.deserializeBinaryFromReader);
      msg.setDriveStuck(value);
      break;
    case 9:
      var value = new proto.fox.proto.ShallowPick;
      reader.readMessage(value,proto.fox.proto.ShallowPick.deserializeBinaryFromReader);
      msg.setShallowPick(value);
      break;
    case 13:
      var value = new proto.fox.proto.StaleSensorData;
      reader.readMessage(value,proto.fox.proto.StaleSensorData.deserializeBinaryFromReader);
      msg.setStaleSensorData(value);
      break;
    case 14:
      var value = new proto.fox.proto.Hesitation;
      reader.readMessage(value,proto.fox.proto.Hesitation.deserializeBinaryFromReader);
      msg.setHesitation(value);
      break;
    case 17:
      var value = new proto.fox.proto.OffPath;
      reader.readMessage(value,proto.fox.proto.OffPath.deserializeBinaryFromReader);
      msg.setOffPath(value);
      break;
    case 18:
      var value = new proto.fox.proto.PickPlaceZoneError;
      reader.readMessage(value,proto.fox.proto.PickPlaceZoneError.deserializeBinaryFromReader);
      msg.setPickPlaceZoneError(value);
      break;
    case 19:
      var value = new proto.fox.proto.Stoppage;
      reader.readMessage(value,proto.fox.proto.Stoppage.deserializeBinaryFromReader);
      msg.setStoppage(value);
      break;
    case 20:
      var value = new planner_proto_types_pb.ValidationError;
      reader.readMessage(value,planner_proto_types_pb.ValidationError.deserializeBinaryFromReader);
      msg.setValidationError(value);
      break;
    case 21:
      var value = new robot_proto_fault_pb.RobotFault;
      reader.readMessage(value,robot_proto_fault_pb.RobotFault.deserializeBinaryFromReader);
      msg.setRobotFault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Fault.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Fault.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Fault} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Fault.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndTimeConfirmationDelay();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getEndTimePendingConfirmation();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSuggestedIntervention();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getIsConfirmed();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getPathBlocked();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.PathBlocked.serializeBinaryToWriter
    );
  }
  f = message.getPlanningFailure();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.fox.proto.PlanningFailure.serializeBinaryToWriter
    );
  }
  f = message.getSafetyScannerEngaged();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.fox.proto.SafetyScannerEngaged.serializeBinaryToWriter
    );
  }
  f = message.getMastStuck();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.fox.proto.MastStuck.serializeBinaryToWriter
    );
  }
  f = message.getDriveStuck();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.fox.proto.DriveStuck.serializeBinaryToWriter
    );
  }
  f = message.getShallowPick();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.fox.proto.ShallowPick.serializeBinaryToWriter
    );
  }
  f = message.getStaleSensorData();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.fox.proto.StaleSensorData.serializeBinaryToWriter
    );
  }
  f = message.getHesitation();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.fox.proto.Hesitation.serializeBinaryToWriter
    );
  }
  f = message.getOffPath();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.fox.proto.OffPath.serializeBinaryToWriter
    );
  }
  f = message.getPickPlaceZoneError();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.fox.proto.PickPlaceZoneError.serializeBinaryToWriter
    );
  }
  f = message.getStoppage();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.fox.proto.Stoppage.serializeBinaryToWriter
    );
  }
  f = message.getValidationError();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      planner_proto_types_pb.ValidationError.serializeBinaryToWriter
    );
  }
  f = message.getRobotFault();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      robot_proto_fault_pb.RobotFault.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 10;
 * @return {string}
 */
proto.fox.proto.Fault.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 start_time = 1;
 * @return {number}
 */
proto.fox.proto.Fault.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_time = 2;
 * @return {number}
 */
proto.fox.proto.Fault.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 end_time_confirmation_delay = 15;
 * @return {number}
 */
proto.fox.proto.Fault.prototype.getEndTimeConfirmationDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.setEndTimeConfirmationDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional bool end_time_pending_confirmation = 16;
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.getEndTimePendingConfirmation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.setEndTimePendingConfirmation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string note = 3;
 * @return {string}
 */
proto.fox.proto.Fault.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string suggested_intervention = 11;
 * @return {string}
 */
proto.fox.proto.Fault.prototype.getSuggestedIntervention = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.setSuggestedIntervention = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string description = 22;
 * @return {string}
 */
proto.fox.proto.Fault.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional bool is_confirmed = 12;
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.getIsConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.setIsConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional PathBlocked path_blocked = 4;
 * @return {?proto.fox.proto.PathBlocked}
 */
proto.fox.proto.Fault.prototype.getPathBlocked = function() {
  return /** @type{?proto.fox.proto.PathBlocked} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PathBlocked, 4));
};


/**
 * @param {?proto.fox.proto.PathBlocked|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setPathBlocked = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearPathBlocked = function() {
  return this.setPathBlocked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasPathBlocked = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PlanningFailure planning_failure = 5;
 * @return {?proto.fox.proto.PlanningFailure}
 */
proto.fox.proto.Fault.prototype.getPlanningFailure = function() {
  return /** @type{?proto.fox.proto.PlanningFailure} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlanningFailure, 5));
};


/**
 * @param {?proto.fox.proto.PlanningFailure|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setPlanningFailure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearPlanningFailure = function() {
  return this.setPlanningFailure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasPlanningFailure = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SafetyScannerEngaged safety_scanner_engaged = 6;
 * @return {?proto.fox.proto.SafetyScannerEngaged}
 */
proto.fox.proto.Fault.prototype.getSafetyScannerEngaged = function() {
  return /** @type{?proto.fox.proto.SafetyScannerEngaged} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.SafetyScannerEngaged, 6));
};


/**
 * @param {?proto.fox.proto.SafetyScannerEngaged|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setSafetyScannerEngaged = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearSafetyScannerEngaged = function() {
  return this.setSafetyScannerEngaged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasSafetyScannerEngaged = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional MastStuck mast_stuck = 7;
 * @return {?proto.fox.proto.MastStuck}
 */
proto.fox.proto.Fault.prototype.getMastStuck = function() {
  return /** @type{?proto.fox.proto.MastStuck} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.MastStuck, 7));
};


/**
 * @param {?proto.fox.proto.MastStuck|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setMastStuck = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearMastStuck = function() {
  return this.setMastStuck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasMastStuck = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DriveStuck drive_stuck = 8;
 * @return {?proto.fox.proto.DriveStuck}
 */
proto.fox.proto.Fault.prototype.getDriveStuck = function() {
  return /** @type{?proto.fox.proto.DriveStuck} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.DriveStuck, 8));
};


/**
 * @param {?proto.fox.proto.DriveStuck|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setDriveStuck = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearDriveStuck = function() {
  return this.setDriveStuck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasDriveStuck = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ShallowPick shallow_pick = 9;
 * @return {?proto.fox.proto.ShallowPick}
 */
proto.fox.proto.Fault.prototype.getShallowPick = function() {
  return /** @type{?proto.fox.proto.ShallowPick} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ShallowPick, 9));
};


/**
 * @param {?proto.fox.proto.ShallowPick|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setShallowPick = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearShallowPick = function() {
  return this.setShallowPick(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasShallowPick = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional StaleSensorData stale_sensor_data = 13;
 * @return {?proto.fox.proto.StaleSensorData}
 */
proto.fox.proto.Fault.prototype.getStaleSensorData = function() {
  return /** @type{?proto.fox.proto.StaleSensorData} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.StaleSensorData, 13));
};


/**
 * @param {?proto.fox.proto.StaleSensorData|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setStaleSensorData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearStaleSensorData = function() {
  return this.setStaleSensorData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasStaleSensorData = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Hesitation hesitation = 14;
 * @return {?proto.fox.proto.Hesitation}
 */
proto.fox.proto.Fault.prototype.getHesitation = function() {
  return /** @type{?proto.fox.proto.Hesitation} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.Hesitation, 14));
};


/**
 * @param {?proto.fox.proto.Hesitation|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setHesitation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearHesitation = function() {
  return this.setHesitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasHesitation = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional OffPath off_path = 17;
 * @return {?proto.fox.proto.OffPath}
 */
proto.fox.proto.Fault.prototype.getOffPath = function() {
  return /** @type{?proto.fox.proto.OffPath} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.OffPath, 17));
};


/**
 * @param {?proto.fox.proto.OffPath|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setOffPath = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearOffPath = function() {
  return this.setOffPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasOffPath = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional PickPlaceZoneError pick_place_zone_error = 18;
 * @return {?proto.fox.proto.PickPlaceZoneError}
 */
proto.fox.proto.Fault.prototype.getPickPlaceZoneError = function() {
  return /** @type{?proto.fox.proto.PickPlaceZoneError} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PickPlaceZoneError, 18));
};


/**
 * @param {?proto.fox.proto.PickPlaceZoneError|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setPickPlaceZoneError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearPickPlaceZoneError = function() {
  return this.setPickPlaceZoneError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasPickPlaceZoneError = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional Stoppage stoppage = 19;
 * @return {?proto.fox.proto.Stoppage}
 */
proto.fox.proto.Fault.prototype.getStoppage = function() {
  return /** @type{?proto.fox.proto.Stoppage} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.Stoppage, 19));
};


/**
 * @param {?proto.fox.proto.Stoppage|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setStoppage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearStoppage = function() {
  return this.setStoppage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasStoppage = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional ValidationError validation_error = 20;
 * @return {?proto.fox.proto.ValidationError}
 */
proto.fox.proto.Fault.prototype.getValidationError = function() {
  return /** @type{?proto.fox.proto.ValidationError} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.ValidationError, 20));
};


/**
 * @param {?proto.fox.proto.ValidationError|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setValidationError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearValidationError = function() {
  return this.setValidationError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasValidationError = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional RobotFault robot_fault = 21;
 * @return {?proto.fox.proto.RobotFault}
 */
proto.fox.proto.Fault.prototype.getRobotFault = function() {
  return /** @type{?proto.fox.proto.RobotFault} */ (
    jspb.Message.getWrapperField(this, robot_proto_fault_pb.RobotFault, 21));
};


/**
 * @param {?proto.fox.proto.RobotFault|undefined} value
 * @return {!proto.fox.proto.Fault} returns this
*/
proto.fox.proto.Fault.prototype.setRobotFault = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.fox.proto.Fault.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Fault} returns this
 */
proto.fox.proto.Fault.prototype.clearRobotFault = function() {
  return this.setRobotFault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Fault.prototype.hasRobotFault = function() {
  return jspb.Message.getField(this, 21) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PathBlocked.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PathBlocked.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PathBlocked} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PathBlocked.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PathBlocked}
 */
proto.fox.proto.PathBlocked.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PathBlocked;
  return proto.fox.proto.PathBlocked.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PathBlocked} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PathBlocked}
 */
proto.fox.proto.PathBlocked.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PathBlocked.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PathBlocked.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PathBlocked} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PathBlocked.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Stoppage.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Stoppage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Stoppage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Stoppage.toObject = function(includeInstance, msg) {
  var f, obj = {
    manualStop: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    goalActive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Stoppage}
 */
proto.fox.proto.Stoppage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Stoppage;
  return proto.fox.proto.Stoppage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Stoppage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Stoppage}
 */
proto.fox.proto.Stoppage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualStop(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoalActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Stoppage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Stoppage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Stoppage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Stoppage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManualStop();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getGoalActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool manual_stop = 1;
 * @return {boolean}
 */
proto.fox.proto.Stoppage.prototype.getManualStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.Stoppage} returns this
 */
proto.fox.proto.Stoppage.prototype.setManualStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool goal_active = 2;
 * @return {boolean}
 */
proto.fox.proto.Stoppage.prototype.getGoalActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.Stoppage} returns this
 */
proto.fox.proto.Stoppage.prototype.setGoalActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.StaleSensorData.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.StaleSensorData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.StaleSensorData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.StaleSensorData.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensorId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.StaleSensorData}
 */
proto.fox.proto.StaleSensorData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.StaleSensorData;
  return proto.fox.proto.StaleSensorData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.StaleSensorData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.StaleSensorData}
 */
proto.fox.proto.StaleSensorData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.SensorId} */ (reader.readEnum());
      msg.setSensorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.StaleSensorData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.StaleSensorData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.StaleSensorData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.StaleSensorData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensorId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional SensorId sensor_id = 1;
 * @return {!proto.fox.proto.SensorId}
 */
proto.fox.proto.StaleSensorData.prototype.getSensorId = function() {
  return /** @type {!proto.fox.proto.SensorId} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.SensorId} value
 * @return {!proto.fox.proto.StaleSensorData} returns this
 */
proto.fox.proto.StaleSensorData.prototype.setSensorId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.OffPath.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.OffPath.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.OffPath} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.OffPath.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.OffPath}
 */
proto.fox.proto.OffPath.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.OffPath;
  return proto.fox.proto.OffPath.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.OffPath} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.OffPath}
 */
proto.fox.proto.OffPath.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.OffPath.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.OffPath.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.OffPath} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.OffPath.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlanningFailure.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlanningFailure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlanningFailure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlanningFailure.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionError: (f = msg.getActionError()) && planner_proto_planner_status_pb.ActionError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlanningFailure}
 */
proto.fox.proto.PlanningFailure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlanningFailure;
  return proto.fox.proto.PlanningFailure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlanningFailure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlanningFailure}
 */
proto.fox.proto.PlanningFailure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new planner_proto_planner_status_pb.ActionError;
      reader.readMessage(value,planner_proto_planner_status_pb.ActionError.deserializeBinaryFromReader);
      msg.setActionError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlanningFailure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlanningFailure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlanningFailure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlanningFailure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      planner_proto_planner_status_pb.ActionError.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActionError action_error = 1;
 * @return {?proto.fox.proto.ActionError}
 */
proto.fox.proto.PlanningFailure.prototype.getActionError = function() {
  return /** @type{?proto.fox.proto.ActionError} */ (
    jspb.Message.getWrapperField(this, planner_proto_planner_status_pb.ActionError, 1));
};


/**
 * @param {?proto.fox.proto.ActionError|undefined} value
 * @return {!proto.fox.proto.PlanningFailure} returns this
*/
proto.fox.proto.PlanningFailure.prototype.setActionError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlanningFailure} returns this
 */
proto.fox.proto.PlanningFailure.prototype.clearActionError = function() {
  return this.setActionError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlanningFailure.prototype.hasActionError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.SafetyScannerEngaged.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.SafetyScannerEngaged.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.SafetyScannerEngaged} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetyScannerEngaged.toObject = function(includeInstance, msg) {
  var f, obj = {
    scannerIndex: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.SafetyScannerEngaged}
 */
proto.fox.proto.SafetyScannerEngaged.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.SafetyScannerEngaged;
  return proto.fox.proto.SafetyScannerEngaged.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.SafetyScannerEngaged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.SafetyScannerEngaged}
 */
proto.fox.proto.SafetyScannerEngaged.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScannerIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.SafetyScannerEngaged.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.SafetyScannerEngaged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.SafetyScannerEngaged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.SafetyScannerEngaged.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScannerIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 scanner_index = 1;
 * @return {number}
 */
proto.fox.proto.SafetyScannerEngaged.prototype.getScannerIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.SafetyScannerEngaged} returns this
 */
proto.fox.proto.SafetyScannerEngaged.prototype.setScannerIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.MastStuck.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.MastStuck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.MastStuck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastStuck.toObject = function(includeInstance, msg) {
  var f, obj = {
    mastStatus: (f = msg.getMastStatus()) && planner_proto_types_pb.MastControl.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.MastStuck}
 */
proto.fox.proto.MastStuck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.MastStuck;
  return proto.fox.proto.MastStuck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.MastStuck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.MastStuck}
 */
proto.fox.proto.MastStuck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new planner_proto_types_pb.MastControl;
      reader.readMessage(value,planner_proto_types_pb.MastControl.deserializeBinaryFromReader);
      msg.setMastStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.MastStuck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.MastStuck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.MastStuck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.MastStuck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMastStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      planner_proto_types_pb.MastControl.serializeBinaryToWriter
    );
  }
};


/**
 * optional MastControl mast_status = 1;
 * @return {?proto.fox.proto.MastControl}
 */
proto.fox.proto.MastStuck.prototype.getMastStatus = function() {
  return /** @type{?proto.fox.proto.MastControl} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.MastControl, 1));
};


/**
 * @param {?proto.fox.proto.MastControl|undefined} value
 * @return {!proto.fox.proto.MastStuck} returns this
*/
proto.fox.proto.MastStuck.prototype.setMastStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.MastStuck} returns this
 */
proto.fox.proto.MastStuck.prototype.clearMastStatus = function() {
  return this.setMastStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.MastStuck.prototype.hasMastStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.DriveStuck.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.DriveStuck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.DriveStuck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DriveStuck.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.DriveStuck}
 */
proto.fox.proto.DriveStuck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.DriveStuck;
  return proto.fox.proto.DriveStuck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.DriveStuck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.DriveStuck}
 */
proto.fox.proto.DriveStuck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.DriveStuck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.DriveStuck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.DriveStuck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.DriveStuck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ShallowPick.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ShallowPick.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ShallowPick} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ShallowPick.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ShallowPick}
 */
proto.fox.proto.ShallowPick.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ShallowPick;
  return proto.fox.proto.ShallowPick.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ShallowPick} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ShallowPick}
 */
proto.fox.proto.ShallowPick.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ShallowPick.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ShallowPick.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ShallowPick} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ShallowPick.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Hesitation.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Hesitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Hesitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Hesitation.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Hesitation}
 */
proto.fox.proto.Hesitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Hesitation;
  return proto.fox.proto.Hesitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Hesitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Hesitation}
 */
proto.fox.proto.Hesitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Hesitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Hesitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Hesitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Hesitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PickPlaceZoneError.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PickPlaceZoneError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PickPlaceZoneError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PickPlaceZoneError.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PickPlaceZoneError}
 */
proto.fox.proto.PickPlaceZoneError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PickPlaceZoneError;
  return proto.fox.proto.PickPlaceZoneError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PickPlaceZoneError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PickPlaceZoneError}
 */
proto.fox.proto.PickPlaceZoneError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setZoneId(value);
      break;
    case 3:
      var value = /** @type {!proto.fox.proto.PickPlaceZone.Error} */ (reader.readEnum());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PickPlaceZoneError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PickPlaceZoneError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PickPlaceZoneError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PickPlaceZoneError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getError();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 zone_id = 1;
 * @return {number}
 */
proto.fox.proto.PickPlaceZoneError.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPlaceZoneError} returns this
 */
proto.fox.proto.PickPlaceZoneError.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PickPlaceZone.Error error = 3;
 * @return {!proto.fox.proto.PickPlaceZone.Error}
 */
proto.fox.proto.PickPlaceZoneError.prototype.getError = function() {
  return /** @type {!proto.fox.proto.PickPlaceZone.Error} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.fox.proto.PickPlaceZone.Error} value
 * @return {!proto.fox.proto.PickPlaceZoneError} returns this
 */
proto.fox.proto.PickPlaceZoneError.prototype.setError = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.fox.proto.SensorId = {
  SENSOR_ID_UNKNOWN: 0,
  SENSOR_ID_IMAGE: 1,
  SENSOR_ID_LEFT_SCAN: 2,
  SENSOR_ID_RIGHT_SCAN: 3,
  SENSOR_ID_STEERWARD_SCAN: 4,
  SENSOR_ID_WHEEL_ODOMETRY: 5
};

goog.object.extend(exports, proto.fox.proto);
