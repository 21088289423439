import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {
    Button,
    createStyles,
    Typography,
    withStyles,
    Box,
    ListItem, TextField
} from "@material-ui/core";
import {Theme} from "@material-ui/core/styles";

import {
    listFaultCodesRequest,
    listFaultRateAlertThresholdsRequest,
    upsertFaultRateAlertThresholdRequest
} from "../../redux/actions";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import List from "@material-ui/core/List";
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = (theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column"
    },
    header: {
      textAlign: "center",
    },
    tableHeader: {
      textAlign: "center",
      margin: "1.5rem",
    },
    createButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    createButton: {
      margin: "1.5rem",
    },
    dialog: {
      position: "absolute",
      top: "60%"
    }
  });

interface Props {
  classes: any;
  dispatch: any;
}

const FaultBasedAlertsPage = (props: Props) => {

    const [ faultRateAlerts, setFaultRateAlerts ] = useState<m_pb.FaultRateAlertThreshold.AsObject[]>([]);
    const [ faultCodes, setFaultCodes ] = useState<string[]>([]);
    const [ dirtyIndex, setDirtyIndex ] = useState<number | null>(null);
    const [ creatingNewAlert, setCreatingNewAlert ] = useState<boolean>(false);

    useEffect(() => {
        props.dispatch(listFaultRateAlertThresholdsRequest())
            .then((resp: m_pb.ListFaultRateAlertThresholdsResponse.AsObject) => {
                setFaultRateAlerts(resp.alertThresholdsList);
            });
    }, []);
    useEffect(() => {
        props.dispatch(listFaultCodesRequest())
        .then((res: m_pb.ListFaultCodesResponse.AsObject) => {
          setFaultCodes(res.faultCodesList);
        });
    }, []);

    return <div>
        <Typography className={props.classes.header} variant="h3">Intervention Rate Alerts</Typography>
        <Box className={props.classes.createButtonContainer}>
            <Button
                className={props.classes.createButton}
                color="primary" variant="contained"
                disabled={creatingNewAlert}
                onClick={() => {
                    setCreatingNewAlert(true);
                    setDirtyIndex(0);
                    setFaultRateAlerts([new m_pb.FaultRateAlertThreshold().toObject(), ...faultRateAlerts]);
                }}
            >Add Alert</Button>
        </Box>
        <List component="nav">
              {faultRateAlerts.map((faultRateAlert, index) => {
                    return <ListItem>
                        <Autocomplete
                          id={`fault-code-autocomplete-${index}`}
                          options={faultCodes.filter(faultCode => !faultRateAlerts.find(alert => alert.faultCode === faultCode))}
                          disabled={!(creatingNewAlert && index === dirtyIndex)}
                          onChange={(event: any, newValue: string | null) => {
                            faultRateAlert.faultCode = newValue || "";
                            const newFaultRateAlerts = [...faultRateAlerts];
                            newFaultRateAlerts.splice(index, 1, faultRateAlert);
                            setFaultRateAlerts(newFaultRateAlerts);
                            setDirtyIndex(index);
                          }}
                          value={faultRateAlert.faultCode}
                          renderInput={params => (
                            <TextField {...params} label="Fault Code" />
                          )}
                          style={{
                            minWidth: "20rem",
                            height: 56,
                            margin: ".5rem",
                            display: "inline"
                          }}
                        />
                        <TextField
                            label="Alert Threshold (fault rate per pallet move)"
                            variant="outlined"
                            disabled={!!dirtyIndex && !(index === dirtyIndex)}
                            style={{margin: ".5rem"}}
                            type="number"
                            value={Math.round(10000 * faultRateAlert.threshold) / 10000}
                            onChange={(e) => {
                                faultRateAlert.threshold = parseFloat(e.target.value);
                                const newFaultRateAlerts = [...faultRateAlerts];
                                newFaultRateAlerts.splice(index, 1, faultRateAlert);
                                setFaultRateAlerts(newFaultRateAlerts);
                                setDirtyIndex(index);
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            style={{margin: ".5rem"}}
                            disabled={!(dirtyIndex === index)}
                            onClick={() => {
                                props.dispatch(upsertFaultRateAlertThresholdRequest(faultRateAlert.faultCode, faultRateAlert.threshold));
                                setDirtyIndex(null);
                                setCreatingNewAlert(false);
                            }}
                        >Save</Button>
                        {dirtyIndex === index && creatingNewAlert && <Button
                            variant="outlined"
                            color="primary"
                            style={{margin: ".5rem"}}
                            onClick={() => {
                                const newFaultRateAlerts = [...faultRateAlerts];
                                newFaultRateAlerts.splice(index, 1);
                                setFaultRateAlerts(newFaultRateAlerts);
                                setDirtyIndex(null);
                                setCreatingNewAlert(false);
                            }}
                        >Cancel</Button>}
                    </ListItem>
                  })
              }
        </List>
    </div>

};

export default connect()(withStyles(styles)(FaultBasedAlertsPage));
