import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MonitoringClient, ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import {
    FaultConfigurationVersion,
    ListFaultConfigurationVersionsRequest,
    ListFaultConfigurationVersionsResponse,
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { BrowserHeaders } from "browser-headers";
import { NormalizedEntities, upsertEntity } from '../normalizedEntities'
import {authHeader} from "../sagas";

export const fetchFaultConfigurationVersions = createAsyncThunk<
ListFaultConfigurationVersionsResponse | null,
  { client: MonitoringClient, req: ListFaultConfigurationVersionsRequest },
  { rejectValue: ServiceError }
>(
  'faultConfigurationVersions/list',
  async (arg: {client: MonitoringClient, req: ListFaultConfigurationVersionsRequest}, { rejectWithValue }) => {
    const data = new Promise<ListFaultConfigurationVersionsResponse | null>((resolve, reject) => {
      arg.client.listFaultConfigurationVersions(arg.req, authHeader(), (err, response) => {
        if (err) {
          reject(err)
        } else {
          resolve(response)
        }
      })
    })

    try {
      return await data;
    } catch (err) {
      return rejectWithValue(err as ServiceError)
    }
  }
);

export interface FaultConfigurationVersionsEntityState {
  entities: NormalizedEntities<FaultConfigurationVersion.AsObject, string>
  loading: 'idle' | 'pending';
  reqId: string | undefined;
  error: ServiceError | undefined;
}

const initialState: FaultConfigurationVersionsEntityState = {
  entities: {
    byId: new Map<string, FaultConfigurationVersion.AsObject>(),
    allIds: [],
  },
  loading: 'idle',
  reqId:  undefined,
  error: undefined,
}

const faultConfigurationVersionsEntitySlice = createSlice({
  name: 'faultConfigurationVersionsEntities',
  initialState: initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchFaultConfigurationVersions.pending,
      (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.reqId = action.meta.requestId;
        }
      })
    .addCase(fetchFaultConfigurationVersions.fulfilled,
      (state, action) => {
        const { requestId } = action.meta;

        if (
          state.loading === 'pending' &&
          state.reqId === requestId
        ) {
          state.loading = 'idle';
          state.reqId = undefined;

          if (action.payload) {
            state.entities = action.payload.getFaultConfigVersionsList()
              .reduce((agg, a, i) => {
                const asObject = a.toObject();
                if (asObject.versionName) {
                  agg.byId.set(asObject.versionName, asObject)
                  if (!agg.allIds.includes(asObject.versionName)) {
                    agg.allIds.push(asObject.versionName)
                  }
                }
                return agg;
              }, state.entities)
          }
        }
      })
    .addCase(fetchFaultConfigurationVersions.rejected,
      (state, action) => {
        const { requestId } = action.meta;

        if (
          state.loading === 'pending' &&
          state.reqId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.payload;
          state.reqId = undefined;
        }
      })
  }
})

export default faultConfigurationVersionsEntitySlice.reducer;
