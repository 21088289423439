import React, { Component } from "react";

import {
  createStyles,
  FormControl,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import { createSiteRequest, createSyncDemand } from "../../redux/actions";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import moment from "moment";
import { Moment } from "moment-timezone/moment-timezone";
import { Alert } from "@material-ui/lab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateTimePicker from "react-datetime-picker";

const styles = (theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column"
    }
  });

type Props = {
  classes: any;
  dispatch: any;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};
type State = {
  customer: string;
  subCustomer: string;
  state: string;
  city: string;
  streetAddress: string;
  postalCode: string;
  nearestAirportCode: string;
  error: string;
};

class CreateSyncDemandDialog extends Component<Props, State> {
  state: State = {
    customer: "",
    subCustomer: "",
    state: "",
    city: "",
    streetAddress: "",
    postalCode: "",
    nearestAirportCode: "",
    error: ""
  };

  _submit = () => {
    const {
      customer,
      subCustomer,
      state,
      city,
      streetAddress,
      postalCode,
      nearestAirportCode
    } = this.state;
    const { onSuccess } = this.props;
    this.props
      .dispatch(
        createSiteRequest(
          customer,
          subCustomer,
          city,
          state,
          postalCode,
          streetAddress,
          nearestAirportCode
        )
      )
      .then((payload: any) => {
        onSuccess();
      })
      .catch((error: any) => {
        this.setState({ error });
      });
  };

  render() {
    const {
      customer,
      subCustomer,
      state,
      city,
      streetAddress,
      postalCode,
      nearestAirportCode,
      error
    } = this.state;
    const { classes, open, onClose } = this.props;
    return (
      <React.Fragment>
        <Dialog open={open} onClose={() => onClose && onClose()}>
          <DialogTitle>Create a new site</DialogTitle>
          <DialogContent>
            <div>
              {error && <Alert severity="warning">{error}</Alert>}
              <FormControl>
                <TextField
                  label="Customer Name"
                  value={customer}
                  onChange={e => {
                    this.setState({ customer: e.target.value });
                  }}
                />
                <TextField
                  label="Sub-Customer Name (if any)"
                  value={subCustomer}
                  onChange={e => {
                    this.setState({ subCustomer: e.target.value });
                  }}
                />
                <TextField
                  label="Street Address"
                  value={streetAddress}
                  onChange={e => {
                    this.setState({ streetAddress: e.target.value });
                  }}
                />
                <TextField
                  label="City"
                  value={city}
                  onChange={e => {
                    this.setState({ city: e.target.value });
                  }}
                />
                <TextField
                  label="State"
                  value={state}
                  onChange={e => {
                    this.setState({ state: e.target.value });
                  }}
                />
                <TextField
                  label="Zip Code"
                  value={postalCode}
                  onChange={e => {
                    this.setState({ postalCode: e.target.value });
                  }}
                />
                <TextField
                  label="Nearest Airport 3-letter code"
                  value={nearestAirportCode}
                  onChange={e => {
                    this.setState({ nearestAirportCode: e.target.value });
                  }}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onClose()} color="secondary">
              Cancel
            </Button>
            <Button onClick={() => this._submit()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default connect()(withStyles(styles)(CreateSyncDemandDialog));
