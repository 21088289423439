import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState, tabletConfigurationEntities } from '..';
import { ForkliftCohort, RobotStatus, Site } from '../../_proto/command_control/monitoring/proto/monitoring_pb';
import { RobotAccount } from '../payloads';

const selectSiteEntities = (state: ApplicationState) => state.siteEntities.entities;
const selectCohortEntities = (state: ApplicationState) => state.cohorts.entities;
const selectRobotAccountEntities = (state: ApplicationState) => state.entities.robotAccounts;
const selectRobotSearchString = (state: ApplicationState) => state.robotStatus.searchString;
const selectRobotSearchTarget = (state: ApplicationState) => state.robotStatus.searchTarget;
const selectRobotSearchInactive = (state: ApplicationState) => state.robotStatus.includeInactive;
const selectTabletConfigurationEntities = (state: ApplicationState) => state.tabletConfigurationEntities.entities;
const selectFlagOptionEntities = (state: ApplicationState) => state.flagOptionEntities.entities;
const selectTabletOptionalFeatureEntities = (state: ApplicationState) => state.tabletOptionalFeatures.entities;
const selectFaultConfigurationVersionEntities = (state: ApplicationState) => state.faultConfigurationVersions.entities;

export const selectSites = createSelector(
  [selectSiteEntities],
  (siteEntities) => siteEntities.allIds.map((id) => siteEntities.byId.get(id)!)
);

export const selectCohorts = createSelector(
  [selectCohortEntities],
  (cohortEntities) => cohortEntities.allIds.map((id) => cohortEntities.byId.get(id)!)
);

export const selectRobotAccounts = createSelector(
  [selectRobotAccountEntities],
  (entities) => entities.allIds.map((id) => entities.byId.get(id)!)
);

export const selectTabletConfigurations = createSelector(
    [selectTabletConfigurationEntities],
    (tabletConfigurationEntities) => tabletConfigurationEntities.allIds.map((id) => tabletConfigurationEntities.byId.get(id)!)
);

export const selectTabletFlagOptions = createSelector(
    [selectFlagOptionEntities],
    (tabletFlagOptionEntities) => tabletFlagOptionEntities.allIds.map((id) => tabletFlagOptionEntities.byId.get(id)!)
);

export const selectTabletOptionalFeatures = createSelector(
    [selectTabletOptionalFeatureEntities],
    (tabletOptionalFeatureEntities) => tabletOptionalFeatureEntities.allIds.map((id) => tabletOptionalFeatureEntities.byId.get(id)!)
);

export const selectFaultConfigurationVersions = createSelector(
    [selectFaultConfigurationVersionEntities],
    (faultConfigurationVersionEntities) => faultConfigurationVersionEntities.allIds.map((id) => faultConfigurationVersionEntities.byId.get(id)!)
);

export const selectRobotsBySite = createSelector(
  [selectSites, selectRobotAccounts],
  (sites, robots) => {
    return sites.reduce((agg, site) => {
      agg.set(site, robots.filter((robot) => robot.siteId === site.id))

      return agg;
    }, new Map<Site.AsObject, RobotAccount[]>())
  }
)

export const selectRobotsByCohortFiltered = createSelector(
  [selectCohorts, selectRobotAccounts, selectRobotSearchString, selectRobotSearchTarget, selectRobotSearchInactive],
  (cohorts, robots, searchString, searchTarget, includeInactive) => {
    switch(searchTarget) {
      case 'robot':
        return cohorts.reduce((agg, cohort) => {
          const bots = robots.filter((robot) => {
            const matchText = robot.cohortId === cohort.id && robot.robotName.toLowerCase().includes(searchString.toLocaleLowerCase());
            const matchInactive = !!(includeInactive || (robot.status && robot.status.getActiveRunName() && (new Date().valueOf() - robot.lastHeartbeatTime < 300000)));

            return matchText && matchInactive;
          });

          if (bots.length > 0) {
            agg.set(cohort, bots);
          }
    
          return agg;
        }, new Map<ForkliftCohort.AsObject, RobotAccount[]>());
      case 'cohort':
        return cohorts.reduce((agg, cohort) => {
          if (`${cohort.displayName}`.toLowerCase().includes(searchString.toLowerCase())) {
            const bots = robots.filter((robot) => {
              const matchCohortId = robot.cohortId === cohort.id;
              const matchInactive = !!(includeInactive || (robot.status && robot.status.getActiveRunName() && (new Date().valueOf() - robot.lastHeartbeatTime < 300000)));

              return matchCohortId && matchInactive;
            });

            if (bots.length > 0) {
              agg.set(cohort, bots);
            }
          }    
    
          return agg;
        }, new Map<ForkliftCohort.AsObject, RobotAccount[]>());
      default:
        return new Map<ForkliftCohort.AsObject, RobotAccount[]>();
    }
  },
)
