import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import {SplitEntityTypes} from "./ChartTimeSeriesNew";
import moment from "moment";
import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import { protoEnumFieldName } from "../../utils/protos";

export const successRateFromPickStats = (
  pickStats: Array<m_pb.PickStats.AsObject>,
  bucketWidth: number,
  startTime: number,
  endTime: number,
  splitByEntityType = "",
  splitThreshold: number | null = 0
) => {
  // Set up buckets
  const timeDelta = endTime - startTime;
  const numBuckets = Math.ceil(timeDelta / bucketWidth);
  const timeBuckets = [];
  let nextBucketEndTime = endTime;
  while (timeBuckets.length < numBuckets) {
    timeBuckets.push({
      endTime: nextBucketEndTime,
      startTime: nextBucketEndTime - bucketWidth,
      successCount: 0,
      successCountByEntity: new Map<string, number>(),
      count: 0,
      countByEntity: new Map<string, number>(),
      value: 0,
      valueByEntity: new Map<string, number>()
    });
    nextBucketEndTime = nextBucketEndTime - bucketWidth;
  }
  // group stats by bucket
  const uniqueEntites = new Set<string>();
  for (const pickStat of pickStats) {
    const bucketIndex = Math.floor(
      (endTime - pickStat.startTimeUtc) / bucketWidth
    );
    if (bucketIndex < 0 || bucketIndex >= numBuckets)
      continue;
    let entityKey = _getEntityKey(pickStat, splitByEntityType, splitThreshold || 0);
    uniqueEntites.add(entityKey);
    if (pickStat.stoppageDurationsList.length === 0) {
      timeBuckets[bucketIndex].successCount += 1;
      timeBuckets[bucketIndex].successCountByEntity.set(
        entityKey,
        (timeBuckets[bucketIndex].successCountByEntity.get(entityKey) || 0) + 1
      );
    }
    timeBuckets[bucketIndex].count += 1;
    timeBuckets[bucketIndex].countByEntity.set(
      entityKey,
      (timeBuckets[bucketIndex].countByEntity.get(entityKey) || 0) + 1
    );
  }
  // Then calculate metrics over buckets
  for (const timeBucket of timeBuckets) {
    for (const entityKey of Array.from(uniqueEntites)) {
      const totalCount = timeBucket.countByEntity.get(entityKey) || 0;
      const successCount = timeBucket.successCountByEntity.get(entityKey) || 0;
      timeBucket.valueByEntity.set(
        entityKey,
        totalCount ? successCount / totalCount : 0
      );
    }
    timeBucket.value = timeBucket.count
      ? timeBucket.successCount / timeBucket.count
      : 0;
  }
  return timeBuckets;
};

export const interventionRateFromPickStats = (
  pickStats: Array<m_pb.PickStats.AsObject>,
  bucketWidth: number,
  startTime: number,
  endTime: number,
  excludeOperatorInitiated: boolean = true,
  splitByEntityType = "",
  splitThreshold: number | null = 0,
) => {
  // Set up buckets
  const timeDelta = endTime - startTime;
  const numBuckets = Math.ceil(timeDelta / bucketWidth);
  const timeBuckets = [];
  let nextBucketEndTime = endTime;
  while (timeBuckets.length < numBuckets) {
    timeBuckets.push({
      endTime: nextBucketEndTime,
      startTime: nextBucketEndTime - bucketWidth,
      count: 0,
      countByEntity: new Map<string, number>(),
      interventionCount: 0,
      interventionCountByEntity: new Map<string, number>(),
      value: 0,
      valueByEntity: new Map<string, number>()
    });
    nextBucketEndTime = nextBucketEndTime - bucketWidth;
  }
  // group stats by bucket
  const uniqueEntites = new Set<string>();
  for (const pickStat of pickStats) {
    const bucketIndex = Math.floor(
      (endTime - pickStat.startTimeUtc) / bucketWidth
    );
    if (bucketIndex < 0 || bucketIndex >= numBuckets)
      continue;
    let entityKey = _getEntityKey(pickStat, splitByEntityType, splitThreshold || 0);
    uniqueEntites.add(entityKey);
    if (pickStat.autonomouslyAttempted) {
      const qualifyingInterventions = excludeOperatorInitiated ? pickStat.interventions - pickStat.humanInitiatedInterventions : pickStat.interventions;
      timeBuckets[bucketIndex].interventionCount += qualifyingInterventions;
      timeBuckets[bucketIndex].interventionCountByEntity.set(
        entityKey,
        (timeBuckets[bucketIndex].interventionCountByEntity.get(entityKey) || 0) + qualifyingInterventions
      );
      timeBuckets[bucketIndex].count += 1;
      timeBuckets[bucketIndex].countByEntity.set(
        entityKey,
        (timeBuckets[bucketIndex].countByEntity.get(entityKey) || 0) + 1
      );
    }
  }
  // Then calculate metrics over buckets
  for (const timeBucket of timeBuckets) {
    for (const entityKey of Array.from(uniqueEntites)) {
      const totalCount = timeBucket.countByEntity.get(entityKey) || 0;
      const interventionCount = timeBucket.interventionCountByEntity.get(entityKey) || 0;
      timeBucket.valueByEntity.set(
        entityKey,
        totalCount ? interventionCount / totalCount : 0
      );
    }
    timeBucket.value = timeBucket.count
      ? timeBucket.interventionCount / timeBucket.count
      : 0;
  }
  return timeBuckets;
};

export const speedFromPickStats = (
  pickStats: Array<m_pb.PickStats.AsObject>,
  bucketWidth: number,
  startTime: number,
  endTime: number,
  excludeInterventions = true,
  excludeResponseDelays = true,
  splitByEntityType = "",
  splitThreshold: number | null = 0,
  percentile: number | null = 0
) => {
  // Set up buckets
  const timeDelta = endTime - startTime;
  const numBuckets = Math.ceil(timeDelta / bucketWidth);
  const timeBuckets = [];
  let nextBucketEndTime = endTime;
  while (timeBuckets.length < numBuckets) {
    timeBuckets.push({
      endTime: nextBucketEndTime,
      startTime: nextBucketEndTime - bucketWidth,
      duration: new Array<number>(),
      durationByEntity: new Map<string, Array<number>>(),
      count: 0,
      countByEntity: new Map<string, number>(),
      value: 0,
      valueByEntity: new Map<string, number>()
    });
    nextBucketEndTime = nextBucketEndTime - bucketWidth;
  }
  // group stats by bucket
  const uniqueEntites = new Set<string>();
  for (const pickStat of pickStats) {
    const bucketIndex = Math.floor(
      (endTime - pickStat.startTimeUtc) / bucketWidth
    );
    if (bucketIndex < 0 || bucketIndex >= numBuckets)
      continue;
    let entityKey = _getEntityKey(pickStat, splitByEntityType, splitThreshold || 0);
    uniqueEntites.add(entityKey);
    if (pickStat.autonomouslyAttempted && (pickStat.interventions === 0 || !excludeInterventions)) {
      timeBuckets[bucketIndex].count += 1;
      timeBuckets[bucketIndex].countByEntity.set(
        entityKey,
        (timeBuckets[bucketIndex].countByEntity.get(entityKey) || 0) + 1
      );
      const eligibleDuration = pickStat.duration - (excludeResponseDelays ? pickStat.stoppageResponseDelay : 0);
      timeBuckets[bucketIndex].duration.push(eligibleDuration);
      timeBuckets[bucketIndex].durationByEntity.set(
        entityKey,
        (timeBuckets[bucketIndex].durationByEntity.get(entityKey) || []).concat(eligibleDuration)
      );
    }
  }
  // Then calculate metrics over buckets
  for (const timeBucket of timeBuckets) {
    for (const entityKey of Array.from(uniqueEntites)) {
      const durations = timeBucket.durationByEntity.get(entityKey) || [0];
      if (percentile) {
        timeBucket.valueByEntity.set(
          entityKey,
          // Use 1 - since we're going to compute speed (as the inverse of duration)
          quantile(durations, 1 - percentile/100)
        );
      } else {
        timeBucket.valueByEntity.set(
          entityKey,
          avg(durations)
        );
      }
    }
    const durations = timeBucket.duration || [0];
    if (percentile) {
      timeBucket.value = quantile(durations, 1 - percentile / 100);
    } else {
      timeBucket.value = avg(durations);
    }
  }
  return timeBuckets;
};

export const pullCountFromPickStats = (
  pickStats: Array<m_pb.PickStats.AsObject>,
  bucketWidth: number,
  startTime: number,
  endTime: number,
  splitByEntityType = "",
  splitThreshold: number | null = 0
) => {
  // Set up buckets
  const timeDelta = endTime - startTime;
  const numBuckets = Math.ceil(timeDelta / bucketWidth);
  const timeBuckets = [];
  let nextBucketEndTime = endTime;
  while (timeBuckets.length < numBuckets) {
    timeBuckets.push({
      endTime: nextBucketEndTime,
      startTime: nextBucketEndTime - bucketWidth,
      count: 0,
      countByEntity: new Map<string, number>(),
      value: 0,
      valueByEntity: new Map<string, number>()
    });
    nextBucketEndTime = nextBucketEndTime - bucketWidth;
  }
  // group stats by bucket
  const uniqueEntites = new Set<string>();
  for (const pickStat of pickStats) {
    const bucketIndex = Math.floor(
      (endTime - pickStat.startTimeUtc) / bucketWidth
    );
    if (bucketIndex < 0 || bucketIndex >= numBuckets)
      continue;
    let entityKey = _getEntityKey(pickStat, splitByEntityType, splitThreshold || 0);
    uniqueEntites.add(entityKey);
    // Only count auto pulls
    if (pickStat.autonomouslyAttempted) {
      timeBuckets[bucketIndex].count += 1;
      timeBuckets[bucketIndex].countByEntity.set(
        entityKey,
        (timeBuckets[bucketIndex].countByEntity.get(entityKey) || 0) + 1
      );
    }
  }
  // Then calculate metrics over buckets
  for (const timeBucket of timeBuckets) {
    for (const entityKey of Array.from(uniqueEntites)) {
      const totalCount = timeBucket.countByEntity.get(entityKey) || 0;
      timeBucket.valueByEntity.set(
        entityKey,
        totalCount
      );
    }
    timeBucket.value = timeBucket.count;
  }
  return timeBuckets;
};

export const bucketTrailerStatsByMonth = (
    trailer_stats: Array<m_pb.TrailerStats.AsObject>,
    // startTime: number,
    // endTime: number
) => {
  // One week = 1e9 nanos/s * 3600 s/hr * 24 hr/day * 30 days/week
  const bucketWidth = 1e9 * 3600 * 24 * 30;
  const endTime = trailer_stats.reduce((a,b) => Math.max(a, b.startTime), 0);
  const startTime = trailer_stats.reduce((a,b) => a ? Math.min(a, b.startTime) : b.startTime, 0);
  // Use floor to avoid partial buckets with skewed results
  const numBuckets = Math.floor((endTime - startTime) / bucketWidth);
  return trailer_stats.reduce<m_pb.TrailerStats.AsObject[][]>((agg, stat) => {
    const index = Math.floor((endTime - stat.startTime) / bucketWidth);
    // Filter trailers outside of time range
    if (index < 0 || index >= numBuckets) {
      return agg;
    }
    if (agg[index]) {
      agg[index].push(stat);
    } else {
      agg[index] = [stat];
    }
    return agg;
  },  new Array(numBuckets))
}

const asc = (arr: number[]) => arr.sort((a: number, b: number) => a - b);
const avg = (arr: number[]) => arr.reduce((a, b) => a + b, 0) / arr.length;
const quantile = (arr: number[], q: number) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

const _getEntityKey = (pickStat: m_pb.PickStats.AsObject, splitEntityType: string, splitThreshold: number) => {
  switch (splitEntityType) {
    case SplitEntityTypes.ROBOT:
      return pickStat.robotName;
    case SplitEntityTypes.COHORT:
      return pickStat.cohortName;
    case SplitEntityTypes.VERSION:
      return pickStat.versionName;
    case SplitEntityTypes.USER_ID:
      return pickStat.userId;
    case SplitEntityTypes.PICK_ZONE_ID:
      return pickStat.pickZoneId;
    case SplitEntityTypes.PALLET_HEIGHT_SETTING:
      return protoEnumFieldName(cc_pb.PalletHeight, pickStat.palletHeightMode)
    case SplitEntityTypes.PALLET_SUPPORT_TYPE:
      return pickStat.supportType;
    case SplitEntityTypes.PLASTIC_DETECTED:
      return pickStat.hangingPlasticDetected ? "Yes" : "No";
    case SplitEntityTypes.LOAD_HEIGHT:
      return pickStat.stackHeight < (splitThreshold)
              ? `< ${splitThreshold}`
              : `>= ${splitThreshold}`;
    case SplitEntityTypes.PICK_DEPTH:
      return pickStat.pickRowDepth < (splitThreshold)
              ? `< ${splitThreshold}`
              : `>= ${splitThreshold}`;
    case SplitEntityTypes.TIME_OF_DAY:
     return moment(pickStat.startTimeUtc / 1e6)
              .subtract(5, "hours")
              .hour() < (splitThreshold)
              ? `< ${splitThreshold}`
              : `>= ${splitThreshold}`;
    default:
      return "";
  }
}
