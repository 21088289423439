import {connect} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {FormControl, MenuItem, Paper, Select, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import m_pb, {
    CreateTabletOptionalFeatureRequest,
    TabletOptionalFeature
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import {ApplicationState, createTabletOptionalFeature} from "../../redux";
import {
    selectTabletOptionalFeatures
} from "../../redux/selectors";
import {useAppDispatch} from "../../redux/hooks";
import {client} from "../../redux/store";
import {Column} from "react-table";
import FoxGrid from "../FoxGrid/foxGrid";

interface Props {
  classes: any;
  dispatch: any;
  open: boolean;
  onClose: () => void;
  tabletOptionalFeatures: TabletOptionalFeature.AsObject[];
}

const mapStateToProps = (state: ApplicationState) => ({
  tabletOptionalFeatures: selectTabletOptionalFeatures(state),
});

const AddOptionalFeaturesDialog = (props: Props) => {
    const { tabletOptionalFeatures, onClose } = props;
    const dispatch = useAppDispatch();


    const [ error, setError ] = useState<string>("");

    const [ featureName, setFeatureName ] = useState<string>("");
    const [ featureDescription, setFeatureDescription ] = useState<string>("");

    const _submit = () => {
        const validator =  new RegExp(`^[A-Z]+(?:_[A-Z]+)*$`);
        if (!validator.test(featureName)) {
            setError("Invalid feature name. Must be ALL_CAPS_SNAKE_CASE");
            return
        }
        const req = new CreateTabletOptionalFeatureRequest();
        req.setName(featureName);
        req.setDescription(featureDescription);
        // Save the new optional feature
        dispatch(createTabletOptionalFeature({client, req}))
            .then(() => onClose())
            .catch(err => setError(err.toString()));
    }

      const existingOptionalFeatureColumns: Column<TabletOptionalFeature.AsObject>[] = useMemo(
      () => [
        {
          Header: 'Feature Name',
          accessor: 'name',
        },
        {
          Header: 'Description',
          accessor: 'description',
        },
      ],
      []
    );

    return <React.Fragment>
        <Dialog
            open={props.open}
            onClose={() => props.onClose && props.onClose()}
            maxWidth={false}
        >
            <DialogTitle>Add New Tablet Optional Feature</DialogTitle>
            <DialogContent>
                {error && <Typography>{error}</Typography>}
                <Paper style={{display: "flex", justifyContent: "center", margin: "24px"}} elevation={4}>
                    <TextField
                        label={"Feature name"}
                        value={featureName}
                        style={{padding: "12px"}}
                        onChange={(e) => setFeatureName(e.target.value)}
                    />
                    <TextField
                        label={"Feature description"}
                        value={featureDescription}
                        style={{padding: "12px"}}
                        onChange={(e) => setFeatureDescription(e.target.value)}
                    />
                </Paper>
                {tabletOptionalFeatures && <div>
                    <FoxGrid
                        title={'Existing optional features'}
                        data={props.tabletOptionalFeatures}
                        columns={existingOptionalFeatureColumns as Column<any>[]}
                        icon={<React.Fragment/>}
                    />
                </div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={() => _submit()}
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
};

export default connect(mapStateToProps)(AddOptionalFeaturesDialog);