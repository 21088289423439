export function logInPath(next?: string): string {
  const suffix = next ? "?next=" + next : "";
  return `/monitoring/log-in${suffix}`;
}

export function signUpPath(next?: string): string {
  const suffix = next ? "?next=" + next : "";
  return `/monitoring/sign-up${suffix}`;
}

export function resetPasswordPath(): string {
  return "/monitoring/accounts/password_reset/";
}

export function landingPath(): string {
  return `/monitoring`;
}

export function unapproved(): string {
  return `/monitoring/unapproved-email`;
}

export function cohortsDashboard(): string {
  return `/monitoring/cohorts`;
}

export function robotsDashboard(): string {
  return `/monitoring/robot-accounts`;
}

export function metricScalars(): string {
  return `/monitoring/metrics/scalars`;
}

export function logUploads(): string {
  return `/monitoring/log-uploads`;
}

export function robotStatus(): string {
  return `/monitoring/robot-status`;
}

export function metricTimeSeries(): string {
  return `/monitoring/metrics/time-series`;
}

export function metricTimeSeriesInteractive(): string {
  return `/monitoring/metrics/time-series-interactive`;
}

export function metricFaultCountTimeSeries(): string {
  return `/monitoring/metrics/fault-count`;
}

export function faultStats(): string {
  return `/monitoring/metrics/fault-stats`;
}

export function faultBasedAlerts(): string {
  return `/monitoring/fault-alerts`;
}

export function metrics(): string {
  return `/monitoring/metrics`;
}

export function listRunStats(): string {
  return `/monitoring/run-stats`;
}
export function listTrailerStats(): string {
  return `/monitoring/trailer-stats`;
}
export function listTrailerStatsTest(): string {
  return `/monitoring/trailer-stats-test`;
}
export function listRuns(): string {
  return `/monitoring/runs`;
}
export function listExternalBugs(): string {
  return `/monitoring/external-bugs`;
}
export function listPickStats(): string {
  return `/monitoring/picks`;
}
export function listStoppages(): string {
  return `/monitoring/stoppages/list`;
}
export function listInterventions(): string {
  return `/monitoring/interventions/list`;
}
export function runPattern(): string {
  return `/monitoring/runs/:runName`;
}
export function manageConfigs(): string {
  return `/monitoring/manage-configs`;
}
export function faultConfiguration(): string {
  return `/monitoring/fault-configuration`;
}
export function tabletConfiguration(): string {
  return `/monitoring/tablet-configuration`;
}
export function operationsPage(): string {
  return `/monitoring/operations`;
}
export function environmentConfigs(): string {
  return `/monitoring/environment-configs`;
}
export function run(runName: string): string {
  return `/monitoring/runs/${runName}`;
}
export function annotation(runName: string, annotationId: string): string {
  return `/monitoring/runs/${runName}/#annotation-${annotationId}`;
}

function stackdriverLogsBaseUrl(logName: string) {
  return `https://console.cloud.google.com/logs/viewer?project=studied-biplane-165901&dateRangeUnbound=backwardInTime&advancedFilter=logName="projects/studied-biplane-165901/logs/${logName}"`;
}

export function bitbucketSource(commit: string) {
  return `http://bitbucket.org/foxbots/foxbots/src/${commit}`;
}

export function robotLogsPath(robotName?: string, runId?: string) {
  const baseUrl = stackdriverLogsBaseUrl("gcplogs-docker-driver");
  const robotFilter = robotName
    ? `
  jsonPayload.instance.name="${robotName}"`
    : "";
  const runFilter = runId
    ? `
  jsonPayload.instance.id="${runId}"`
    : "";
  return `${baseUrl}${robotFilter}${runFilter}`;
}

export function uiServiceLogsPath(robotName?: string, runId?: string) {
  const baseUrl = stackdriverLogsBaseUrl("forklift_ui_local_service");
  const robotFilter = robotName
    ? `
  labels.robotId="${robotName}"`
    : "";
  const runFilter = runId
    ? `
  labels.runId="${runId}"`
    : "";
  return `${baseUrl}${robotFilter}${runFilter}`;
}

export function uiRequestLogsPath(robotName?: string, runId?: string) {
  const baseUrl = stackdriverLogsBaseUrl("forklift_ui_local_requests");
  const robotFilter = robotName
    ? `
  labels.robotId="${robotName}"`
    : "";
  const runFilter = runId
    ? `
  labels.runId="${runId}"`
    : "";
  return `${baseUrl}${robotFilter}${runFilter}`;
}
