import { combineReducers } from "redux";
import {
  viewer,
  entities,
  siteEntities,
  tabletConfigurationEntities,
  robotStatus,
  cohorts,
  flagOptionEntities,
  tabletOptionalFeatures,
  faultConfigurationVersions
} from './reducers';
import { enableMapSet as enableMapSetTransitive } from "@reduxjs/toolkit/node_modules/immer";
import { enableMapSet } from "immer";

enableMapSet();
enableMapSetTransitive();

const rootReducer = combineReducers({
  entities,
  viewer,
  siteEntities,
  robotStatus,
  tabletConfigurationEntities,
  flagOptionEntities,
  tabletOptionalFeatures,
  faultConfigurationVersions,
  cohorts,
});

export type ApplicationState = ReturnType<typeof rootReducer>;

export default rootReducer
export * from "./normalizedEntities";
export * from "./reducers";
