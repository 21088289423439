import React, { Component } from "react";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";

import { ApplicationState, orderedEntities } from "../../redux";
import { RunMetadata, Annotation, Account } from "../../redux/payloads";
import { Button, TextField, Tooltip, Typography } from "@material-ui/core";
import cc_pb from "../../_proto/command_control/proto/command_control_pb";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { protoEnumFieldName } from "../../utils/protos";
import { bitbucketSource, robotLogsPath } from "../../utils/Paths";
import * as paths from "../../utils/Paths";
import {
  deleteRunAttributeRequest,
  getRunPlacedPalletsRequest,
  updateRunAttributeRequest
} from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import moment from "moment-timezone";
import RunReviewersDialog from "./RunReviewersDialog";
import { secondary } from "../../App";
import { isEmpty } from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    main: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center"
    },
    description: {
      paddingLeft: 8
    },
    button: {
      margin: 0,
      padding: 0
    },
    listItemNormalPriority: {},
    listItemHighPriority: {
      backgroundColor: secondary[50]
    },
    collapse: {
      width: "100%",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 4,
      height: "100%"
    },
    annotation: {
      padding: 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "start"
    },
    times: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start"
    },
    video: {
      width: "100%",
      height: "auto"
    },
    inline: {
      display: "inline"
    },
    timeButton: {
      margin: 8
    },

    listItemTextSecondaryShort: {
      width: 100
    },
    listItemTextSecondaryLight: {
      color: "darkgray"
    },
    paddedListItemTextSecondary: {
      paddingLeft: 8
    },
    listItemTextSecondary: {
      width: 152
    },
    listItemTextSecondaryLong: {
      width: 180
    },
    listItemTextSecondaryLongest: {
      width: 200
    },

    smallPaddingLeft: {
      paddingLeft: 16
    },
    textLogs: {
      paddingLeft: 16,
      fontWeight: "bold"
    },
    annotationsHeader: {
      paddingLeft: 8,
      fontWeight: "bold"
    },
    startCommand: {
      padding: 16
    },
    channel: {
      padding: 16,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start"
    },
    subHeading: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center"
    },
    attributeRow: {
      display: "flex",
      alignItems: "center"
    },
    topHeading: {
      display: "flex",
      alignItems: "start",
      flexDirection: "row"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  const runMetadatas: Array<RunMetadata> = orderedEntities(
    state.entities.runMetadatas
  );
  return { runMetadatas, viewer: state.viewer.account };
};

interface Props extends WithStyles<typeof styles> {
  run: RunMetadata;
  viewer: Account | null;
  dispatch: any;
}
interface State {
  open: boolean;
  isAddingAttribute: boolean;
  newAttributeKey: string;
  newAttributeValue: string;
  waitingOnAttribute: boolean;
  showReviewDialog: boolean;
  placedPallets: m_pb.RunPlacedPallets.AsObject | null;
}

function _maxStatus(reviews: Array<m_pb.RunReview.AsObject>): string | null {
  if (reviews.length === 0) {
    return null;
  }
  const reviewValues: Array<number> = reviews.map(r => r.status.valueOf());
  const maxStatus = Math.max(...reviewValues);
  return protoEnumFieldName(m_pb.RunReviewStatus, maxStatus).replace(
    "RUN_REVIEW_STATUS_",
    ""
  );
}

class RunListItem extends Component<Props, State> {
  state = {
    open: false,
    isAddingAttribute: false,
    newAttributeKey: "",
    newAttributeValue: "",
    waitingOnAttribute: false,
    showReviewDialog: false,
    placedPallets: null
  };
  video: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.video = React.createRef();
  }

  componentDidMount(): void {
    this.props
      .dispatch(getRunPlacedPalletsRequest(this.props.run.runId))
      .then((placedPallets: m_pb.RunPlacedPallets.AsObject) =>
        this.setState({ placedPallets })
      );
  }

  _updateRunAttribute() {
    const { newAttributeKey, newAttributeValue } = this.state;
    if (!newAttributeKey) {
      throw new Error("No attribute key");
    }
    const { runId } = this.props.run;
    this.setState({ waitingOnAttribute: true }, () =>
      this.props
        .dispatch(
          updateRunAttributeRequest(runId, newAttributeKey, newAttributeValue)
        )
        .then(() =>
          this.setState({
            waitingOnAttribute: false,
            isAddingAttribute: false,
            newAttributeKey: "",
            newAttributeValue: ""
          })
        )
        .catch((e: ServiceError) => {
          alert(`Failed to update attribute: ${e}`);
          this.setState({ waitingOnAttribute: false });
        })
    );
  }
  _deleteRunAttribute(key: string) {
    const { runId } = this.props.run;
    this.setState({ waitingOnAttribute: true }, () =>
      this.props
        .dispatch(deleteRunAttributeRequest(runId, key))
        .then(() =>
          this.setState({
            waitingOnAttribute: false,
            newAttributeKey: "",
            newAttributeValue: ""
          })
        )
        .catch((e: ServiceError) => {
          alert(`Failed to delete attribute: ${e}`);
          this.setState({ waitingOnAttribute: false });
        })
    );
  }

  render() {
    const { run, classes, viewer } = this.props;
    const {
      open,
      waitingOnAttribute,
      isAddingAttribute,
      placedPallets,
      showReviewDialog
    } = this.state;
    const { description } = run;
    const pilot = description.attributes.get("pilot");
    const pilotSubheading = pilot ? ` (${pilot})` : "";
    const reviewDialog = showReviewDialog ? (
      <RunReviewersDialog
        run={run}
        onClose={() => this.setState({ showReviewDialog: false })}
      />
    ) : null;
    const startTime = moment(
      (description.startTime + description.robotEpochOffset) / 1e6
    );
    const isReviewing =
      viewer && !!run.runReviews.find(r => r.reviewerAccountId == viewer.id);
    const intervenedPallets = placedPallets
      ? new Set(
          // @ts-ignore
          placedPallets.interventionsList
            .filter(
              (i: m_pb.PlacedPalletIntervention.AsObject) =>
                !i.exclude && i.palletId !== ""
            )
            .map((i: m_pb.PlacedPalletIntervention.AsObject) => i.palletId)
        )
      : new Set();
    const successes = placedPallets
      ? (placedPallets as any).placedPalletsList.filter(
          (p: m_pb.PlacedPalletDetails.AsObject) =>
            !intervenedPallets.has(p.palletId)
        ).length
      : 0;
    const palletCount = placedPallets
      ? (placedPallets as any).placedPalletsList.length
      : 0;
    const maxStatus = _maxStatus(run.runReviews);

    const firstRun = placedPallets
      ? (placedPallets as any).placedPalletsList[0]
      : {};
    const lastRun = placedPallets
      ? (placedPallets as any).placedPalletsList[palletCount - 1]
      : {};

    const palletsPerHour =
      !isEmpty(firstRun) && !isEmpty(lastRun) && palletCount > 0
        ? palletCount /
          ((lastRun.endTime - firstRun.startTime) / (1e9 * 60) / 60)
        : 0;

    return (
      <ListItem
        divider
        className={classes.listItem}
        classes={{
          root: isReviewing
            ? classes.listItemHighPriority
            : classes.listItemNormalPriority
        }}
      >
        {reviewDialog}
        {/* run id and note  */}
        <div className={classes.subHeading}>
          <Link color="secondary" href={paths.run(run.runId)} target="_blank">
            <Typography>{`${run.runId}${pilotSubheading}`}</Typography>
          </Link>

          {run.summary.length > 0 && (
            <Typography
              color="textSecondary"
              variant={"caption"}
              className={classes.description}
            >
              {run.summary}
            </Typography>
          )}
        </div>
        <div className={classes.main}>
          <div className={classes.subHeading}>
            <Tooltip title="Channel">
              <Typography
                display={"inline"}
                color="textSecondary"
                className={classes.listItemTextSecondaryShort}
              >
                {run.description.channel}
              </Typography>
            </Tooltip>
            <Tooltip title="Flag File">
              <Typography
                display={"inline"}
                color="textSecondary"
                className={[
                  classes.listItemTextSecondaryLight,
                  classes.listItemTextSecondary
                ].join(" ")}
              >
                {run.flagFile}
              </Typography>
            </Tooltip>
            <Tooltip title="Success Rate">
              <Typography
                display={"inline"}
                color="textSecondary"
                className={classes.listItemTextSecondaryLong}
              >
                {placedPallets
                  ? `${successes}/${palletCount} picks (${(
                      (100 * successes) /
                      (palletCount || 1)
                    ).toFixed(1)}%)`
                  : "processing picks"}
              </Typography>
            </Tooltip>

            <Tooltip title="PPH">
              <Typography
                display={"inline"}
                color="textSecondary"
                className={[
                  classes.listItemTextSecondaryLight,
                  classes.listItemTextSecondaryShort
                ].join(" ")}
              >
                {palletsPerHour > 0
                  ? `${palletsPerHour.toFixed(2)} pph`
                  : `N/A`}
              </Typography>
            </Tooltip>

            <Tooltip title="Start Time">
              <Typography
                display={"inline"}
                color="textSecondary"
                className={classes.listItemTextSecondary}
              >
                {startTime.format("h:mm a MMM D")}
              </Typography>
            </Tooltip>
            <Tooltip title="Run Minutes">
              <Typography
                display={"inline"}
                color="textSecondary"
                className={[
                  classes.listItemTextSecondaryLight,
                  classes.listItemTextSecondaryShort
                ].join(" ")}
              >
                {`${(
                  (description.endTime - description.startTime) /
                  (1e9 * 60)
                ).toFixed(1)} minutes`}
              </Typography>
            </Tooltip>
            <Tooltip title="Version">
              <Typography
                display={"inline"}
                color="textSecondary"
                className={[
                  classes.listItemTextSecondaryLong,
                  classes.paddedListItemTextSecondary
                ].join(" ")}
              >
                {run.description.versionName}
              </Typography>
            </Tooltip>

            <Link
              target={"_blank"}
              color="secondary"
              className={[
                classes.listItemTextSecondaryLight,
                classes.listItemTextSecondaryShort
              ].join(" ")}
              href={robotLogsPath(run.robotName, run.runId)}
            >
              Robot Logs
            </Link>

            {description.gitBranch && description.gitCommitHash && (
              <Tooltip title="Git_Branch@Git_Commit">
                <Link
                  target={"_blank"}
                  color="secondary"
                  className={classes.listItemTextSecondary}
                  href={bitbucketSource(description.gitCommitHash)}
                >
                  {`${description.gitBranch}@${description.gitCommitHash}`}
                </Link>
              </Tooltip>
            )}
            <Tooltip title="Annotation Bugs">
              <Typography
                display={"inline"}
                color="textSecondary"
                className={[
                  classes.listItemTextSecondaryLight,
                  classes.listItemTextSecondaryLongest
                ].join(" ")}
              >
                {`${run.annotations.filter(a => !!a.externalBug).length}/${
                  run.annotations.length
                } annotations have bugs`}
              </Typography>
            </Tooltip>
            <Tooltip title="Log Files Uploaded">
              <Typography
                display={"inline"}
                color="textSecondary"
                className={[
                  classes.paddedListItemTextSecondary,
                  classes.listItemTextSecondaryLong
                ].join(" ")}
              >
                {`${run.numImportedLogFiles}/${
                  run.numImportedLogFiles + run.numPendingLogFiles
                } files uploaded`}
              </Typography>
            </Tooltip>
            <div className={classes.startCommand}>
              <Button
                onClick={() => this.setState({ showReviewDialog: true })}
                color={maxStatus !== null ? "primary" : "secondary"}
                className={classes.listItemTextSecondaryLong}
              >
                {maxStatus || "Assign reviewer"}
              </Button>
            </div>
          </div>
          <IconButton
            className={classes.button}
            onClick={() => this.setState({ open: !open })}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <Collapse
          in={open}
          className={classes.collapse}
          timeout="auto"
          unmountOnExit
        >
          <div>
            {run.annotations.map((annotation: Annotation, i) => {
              return (
                <Card className={classes.annotation} key={annotation.id}>
                  <Typography color={"textSecondary"}>{`${protoEnumFieldName(
                    cc_pb.InterventionType,
                    annotation.intervention
                  ).replace("INTERVENTION_", "")} (${
                    annotation.eventTime
                  })`}</Typography>
                  <Typography>{annotation.description}</Typography>
                </Card>
              );
            })}
            <div className={classes.startCommand}>
              <Typography variant="caption">Start command:</Typography>{" "}
              <Typography>{description.startCommand}</Typography>
            </div>
            <div className={classes.startCommand}>
              <Typography variant="caption">Attributes:</Typography>{" "}
              {Array.from(run.description.attributes.entries()).map(it => {
                const key = it[0];
                const value = it[1];
                return (
                  <div key={key} className={classes.attributeRow}>
                    <IconButton onClick={() => this._deleteRunAttribute(key)}>
                      <DeleteIcon />
                    </IconButton>
                    <Typography>{`${key}: ${value}`}</Typography>
                  </div>
                );
              })}
              {!isAddingAttribute && (
                <Button
                  onClick={() => this.setState({ isAddingAttribute: true })}
                  color={"secondary"}
                  variant={"outlined"}
                  size={"small"}
                >
                  Add Attribute
                </Button>
              )}
              {isAddingAttribute && (
                <div>
                  <div>
                    <TextField
                      disabled={waitingOnAttribute}
                      label={"key"}
                      onChange={e =>
                        this.setState({ newAttributeKey: e.target.value })
                      }
                    />
                    <TextField
                      disabled={waitingOnAttribute}
                      label={"value"}
                      onChange={e =>
                        this.setState({ newAttributeValue: e.target.value })
                      }
                    />
                  </div>
                  <Button
                    onClick={() => this.setState({ isAddingAttribute: false })}
                    color={"secondary"}
                    variant={"outlined"}
                    disabled={waitingOnAttribute}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={waitingOnAttribute}
                    color={"primary"}
                    variant={"outlined"}
                    onClick={() => this._updateRunAttribute()}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
            <div className={classes.startCommand}>
              <Typography variant="caption">Channel:</Typography>{" "}
              <Typography>{description.channel}</Typography>
            </div>
          </div>
        </Collapse>
      </ListItem>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(RunListItem));
