import React, {Component} from 'react';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Area,
    AreaChart,
    Label
} from 'recharts';
import {connect} from "react-redux";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({});

interface Props extends WithStyles<typeof styles> {
  data: any[];
  dataKey: string;
  xAxisDataKey: string;
  label: string;
}

interface State {
}

class Histogram extends Component<Props, State> {

  render() {
    const {data, dataKey, label, xAxisDataKey} = this.props;
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/*<CartesianGrid strokeDasharray="3 3" />*/}
          <XAxis dataKey={xAxisDataKey} scale="linear">
            <Label value={label} offset={0} position="insideBottom" />
          </XAxis>
          {/*<YAxis />*/}
          <Tooltip />
          {/*<Area dataKey={dataKey} fill="#8884d8" type="monotone" />*/}
          <Bar dataKey={dataKey} fill="#8884d8"/>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default connect()(withStyles(styles)(Histogram));

