import React, { Component } from "react";

import { createStyles, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactJson from "react-json-view";

import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

const styles = (theme: Theme) =>
  createStyles({
    configRow: {
      paddingTop: 8,
      paddingBottom: 16,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    }
  });

type Props = {
  classes: any;
  onClose: () => void;
  goal: m_pb.GoalMetadata.AsObject;
};

class GoalDetailsDialog extends Component<Props> {
  render() {
    const { classes, onClose, goal } = this.props;
    const { environmentConfig, pickPlaceDetails } = goal;
    const pickZone = pickPlaceDetails ? pickPlaceDetails.pickZone : null;
    const placeZone = pickPlaceDetails ? pickPlaceDetails.placeZone : null;
    return (
      <React.Fragment>
        <Dialog
          open={true}
          onClose={() => onClose && onClose()}
          aria-labelledby="goal-details-title"
        >
          <DialogTitle id="goal-details-title">Goal Details</DialogTitle>
          <DialogContent>
            <Card>
              <CardHeader title={"Config"} />

              <CardContent>
                <div className={classes.configRow}>
                  {environmentConfig && (
                    <ReactJson
                      name={"environmentConfig"}
                      src={environmentConfig}
                      collapsed={true}
                    />
                  )}
                  {!environmentConfig && (
                    <Typography>No config saved for this goal.</Typography>
                  )}
                </div>
                <div className={classes.configRow}>
                  {pickZone && (
                    <ReactJson
                      name={"pickZone"}
                      src={pickZone}
                      collapsed={true}
                    />
                  )}
                  {!pickZone && (
                    <Typography>No pick zone saved for this goal.</Typography>
                  )}
                </div>
                <div className={classes.configRow}>
                  {placeZone && (
                    <ReactJson
                      name={"placeZone"}
                      src={placeZone}
                      collapsed={true}
                    />
                  )}
                  {!placeZone && (
                    <Typography>No place zone saved for this goal.</Typography>
                  )}
                </div>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onClose()} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = () => undefined;

export default connect(mapStateToProps)(withStyles(styles)(GoalDetailsDialog));
