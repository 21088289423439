import React, { Component, CSSProperties } from "react";

import { createStyles, withStyles } from "@material-ui/core";

import { Theme, WithStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {
  x: number;
  y: number;
  displayText: string;
}

class Tooltip extends Component<Props> {
  render() {
    const positionStyle: CSSProperties = {
      position: "absolute",
      left: this.props.x + 10,
      top: this.props.y + 5,
      zIndex: 1000,
      whiteSpace: "pre",
      padding: "6px",
    };
    return (
      <React.Fragment>
        <Card style={positionStyle} variant="outlined">
          <div>{this.props.displayText}</div>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect()(withStyles(styles)(Tooltip));
