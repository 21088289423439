import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router";
import moment from "moment-timezone";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as d3_chromatic from "d3-scale-chromatic";
import * as d3_scale from "d3-scale";

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { secondary } from "../../App";
import CardContent from "@material-ui/core/CardContent";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { combineStyles, commonStyles } from "../Utils/CommonStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const localStyles = (theme: Theme) =>
  createStyles({
    root: {},
    wrapper: {},
    card: { paddingTop: 0, paddingLeft: 0, paddingRight: 0 },
    content: {
      height: 700,
      width: "100%"
    },
    chipArray: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap"
    },
    textField: {
      margin: 4,
      marginLeft: 8,
      marginRight: 8
    },
    csvLink: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      color: secondary[400]
    }
  });
const styles = combineStyles(localStyles, commonStyles);

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
  bucketWidth: number;
  eventFilter: m_pb.EventFilter;
  faults: Array<m_pb.Fault.AsObject>;
  pickStats: Array<m_pb.PickStats.AsObject>;
}
interface State {
  highlightedKey: string | null;
  normalizeByPicks: boolean;
  snapToEndOFDay: boolean;
}

const second = 1e9;
enum BucketWidths {
  MINUTE = 60 * second,
  HOUR = 60 * 60 * second,
  DAY = 24 * 60 * 60 * second,
  WEEK = 7 * 24 * 60 * 60 * second,
  MONTH = 30 * 24 * 60 * 60 * second
}

type formatter = (val: moment.Moment) => string;
function xAxisFormatter(width: BucketWidths): formatter {
  switch (width) {
    case BucketWidths.MINUTE: {
      return val => val.format("DD MMM hh:mm:ss");
    }
    case BucketWidths.HOUR: {
      return val => val.format("DD MMM hh:mm a");
    }
    case BucketWidths.DAY: {
      return val => val.format("DD MMM hha");
    }
    case BucketWidths.WEEK: {
      return val => val.format("DD MMM");
    }
    case BucketWidths.MONTH: {
      return val => val.format("DD MMM");
    }
  }
  throw new Error(`Unrecognized bucket width: ${width}`);
}
class FaultTimeSeriesChart extends Component<
  Props & RouteComponentProps,
  State
> {
  state: State = {
    highlightedKey: null,
    normalizeByPicks: true,
    snapToEndOFDay: true
  };

  handleMouseEnterLegend = (o: any) => {
    const { dataKey: highlightedKey } = o;
    this.setState({ highlightedKey });
  };

  handleMouseLeaveLegend = (o: any) => {
    this.setState({ highlightedKey: null });
  };

  render() {
    const { classes } = this.props;
    const {
      highlightedKey,
      snapToEndOFDay,
      normalizeByPicks
    } = this.state;
    const {
      bucketWidth,
      faults,
      eventFilter
    } = this.props;
    const endTime = snapToEndOFDay
      ? moment(eventFilter.getEndTime() / 1e6)
          .endOf("day")
          .unix() * 1e9
      : eventFilter.getEndTime();
    let lineChartData: Array<any> = [];
    const displayedFaultTypes = new Set<string>();
    if (faults) {
      const timeBuckets: Array<any> = [];
      for (const fault of faults) {
        if (fault.startTime > endTime) {
          continue;
        }
        const timeBucketIndex = Math.ceil((endTime - fault.startTime) / ( bucketWidth));
        const timeBucket = timeBuckets[timeBucketIndex] || {};
        if (Object.keys(timeBucket).includes(fault.errorCode)) {
          timeBucket[fault.errorCode] = timeBucket[fault.errorCode] + 1;
        } else {
          timeBucket[fault.errorCode] = 1;
        }
        timeBuckets[timeBucketIndex] = timeBucket;
      }
      for (const pick of this.props.pickStats) {
        const timeBucketIndex = Math.ceil((endTime - pick.startTimeUtc) / (bucketWidth));
        const timeBucket = timeBuckets[timeBucketIndex] || {};
        if (timeBucket.pickCount) {
          timeBucket.pickCount += 1;
        }
        else {
          timeBucket.pickCount = 1;
        }
        timeBuckets[timeBucketIndex] = timeBucket;
      }
      for (const [i, timeBucket] of timeBuckets.entries()) {
        let data: any = { time: (endTime - (i * bucketWidth)) / 1e6 };
        if (timeBucket) {
          for (const faultType of Object.keys(timeBucket).filter(key => key !== "pickCount")) {
            const faultName = faultType
            displayedFaultTypes.add(faultName);
            if (normalizeByPicks) {
              data[faultName] = timeBucket[faultType] / (timeBucket.pickCount || 1);
            } else {
              data[faultName] = timeBucket[faultType];
            }
          }
        }
        lineChartData.push(data);
      }
    }
    const xFormatter = xAxisFormatter(bucketWidth);
    const color = d3_scale.scaleSequential(d3_chromatic.interpolateRainbow);
    return (
      <div className={classes.wrapper}>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.normalizeByPicks}
              onChange={e =>
                this.setState({ normalizeByPicks: e.target.checked })
              }
            />
          }
          label="Normalize by picks"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.snapToEndOFDay}
              onChange={e =>
                this.setState({ snapToEndOFDay: e.target.checked })
              }
            />
          }
          label="Snap display to end of day"
        />
        <CardContent className={classes.content}>
          <ResponsiveContainer>
            <LineChart
              data={lineChartData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis
                tickFormatter={secs => xFormatter(moment(secs))}
                dataKey="time"
                scale="time"
                type="number"
                allowDataOverflow
                domain={["dataMin", "dataMax"]}
              />
              <YAxis name={normalizeByPicks ? "Faults per pallet": "Faults"} unit={normalizeByPicks ? " / pick" : ""} />
              {Array.from(displayedFaultTypes)
                .sort()
                .map((faultType, i) => {
                  return (
                    <Line
                      key={faultType}
                      strokeOpacity={
                        highlightedKey === null ||
                        highlightedKey === faultType
                          ? 1
                          : 0.15
                      }
                      dataKey={faultType}
                      stroke={color(i / displayedFaultTypes.size)}
                      isAnimationActive={false}
                      name={faultType}
                    />
                  );
                })}
              <Tooltip
                formatter={value => (value as number).toFixed(3)}
                labelFormatter={label => {
                  return `${xFormatter(moment(label))} - ${xFormatter(
                    moment((label as number) + (bucketWidth as number) / 1e6)
                  )}`;
                }}
              />
              <Legend
                onMouseEnter={this.handleMouseEnterLegend}
                onMouseLeave={this.handleMouseLeaveLegend}
              />
            </LineChart>
          </ResponsiveContainer>
        </CardContent>
      </div>
    );
  }
}

export default withRouter(
  connect()(withStyles(styles)(FaultTimeSeriesChart))
);
