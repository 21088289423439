import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";

import detection_pb from "../../_proto/detection/proto/detection_pb";
import {
    Button, Checkbox,
    FormControl, FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

const styles = () => createStyles({
  largeInput: {
      width: "18em",
      margin: ".5em"
  },
  normalInput: {
      width: "12em",
      margin: ".5em"
  },
  smallInput: {
      width: "6em",
      margin: ".5em"
  }
});

interface Props extends WithStyles<typeof styles> {
  placeZoneTemplate: detection_pb.ZoneConfig;
  submit: (editedTemplate: detection_pb.ZoneConfig) => void;
  cancel: () => void;
}

interface State {
  numRows: number;
  numColumns: number;
  rowPadding: number;
  columnPadding: number;
  placePattern: detection_pb.PlaceZoneConfig.PlacePattern;
  templateName: string;
  placeStrategy: Array<detection_pb.PlaceZoneConfig.PlaceStrategy>;
  placeLeftToRight: boolean;
  orientation: Array<detection_pb.PlaceZoneConfig.Orientation>;
}

class PlaceZoneTemplateEditor extends Component<Props, State> {
  state: State = {
    numRows: this.props.placeZoneTemplate.getPlaceConfig()?.getNumRows() || 1,
    numColumns:
      this.props.placeZoneTemplate.getPlaceConfig()?.getNumColumns() || 1,
    rowPadding:
      this.props.placeZoneTemplate.getPlaceConfig()?.getRowPadding() || 0.3,
    columnPadding:
      this.props.placeZoneTemplate.getPlaceConfig()?.getColumnPadding() || 0.35,
    placePattern:
      this.props.placeZoneTemplate.getPlaceConfig()?.getPlacePattern() ||
      detection_pb.PlaceZoneConfig.PlacePattern.PLACE_PATTERN_ROW,
    templateName: this.props.placeZoneTemplate.getTemplateName(),
    placeStrategy: this.props.placeZoneTemplate.getPlaceConfig()?.getPlaceStrategyList() || [],
    orientation: this.props.placeZoneTemplate.getPlaceConfig()?.getOrientationList() || [],
    placeLeftToRight: !!this.props.placeZoneTemplate.getPlaceConfig()?.getLeftToRight()
  };

  _submit = () => {
    const editedTemplate = this.props.placeZoneTemplate;
    editedTemplate.getPlaceConfig()?.setNumRows(this.state.numRows);
    editedTemplate.getPlaceConfig()?.setNumColumns(this.state.numColumns);
    editedTemplate.getPlaceConfig()?.setRowPadding(this.state.rowPadding);
    editedTemplate.getPlaceConfig()?.setColumnPadding(this.state.columnPadding);
    editedTemplate.getPlaceConfig()?.setPlacePattern(this.state.placePattern);
    editedTemplate.getPlaceConfig()?.setPlaceStrategyList(this.state.placeStrategy);
    editedTemplate.getPlaceConfig()?.setOrientationList(this.state.orientation);
    editedTemplate.getPlaceConfig()?.setLeftToRight(this.state.placeLeftToRight);
    editedTemplate.setTemplateName(this.state.templateName);
    editedTemplate.setZoneType(detection_pb.ZoneConfig.ZoneType.WAREHOUSE);
    this.props.submit(editedTemplate);
  };

  render() {
    return (
      <div>
        <TextField
          id="template-name-input"
          label="Name"
          className={this.props.classes.normalInput}
          variant="outlined"
          defaultValue={this.state.templateName}
          onChange={e => this.setState({ templateName: e.target.value })}
        />
        <TextField
          id="rows-input"
          label="Number of Rows"
          className={this.props.classes.smallInput}
          variant="outlined"
          type="number"
          defaultValue={this.state.numRows}
          onChange={e =>
            this.setState({ numRows: parseInt(e.target.value, 10) })
          }
        />
        <TextField
          id="columns-input"
          label="Number of Columns"
          className={this.props.classes.smallInput}
          variant="outlined"
          type="number"
          defaultValue={this.state.numColumns}
          onChange={e =>
            this.setState({ numColumns: parseInt(e.target.value, 10) })
          }
        />
        <TextField
          id="row-padding-input"
          label="Row Padding"
          className={this.props.classes.smallInput}
          variant="outlined"
          type="number"
          defaultValue={this.state.rowPadding}
          onChange={e =>
            this.setState({ rowPadding: parseFloat(e.target.value) })
          }
        />
        <TextField
          id="column-padding-input"
          label="Column Padding"
          className={this.props.classes.smallInput}
          variant="outlined"
          type="number"
          defaultValue={this.state.columnPadding}
          onChange={e =>
            this.setState({ columnPadding: parseFloat(e.target.value) })
          }
        />
        <FormControl>
          <InputLabel id="place-pattern-select-label">Place Pattern</InputLabel>
          <Select
            labelId="place-pattern-select-label"
            id="place-pattern-select"
            className={this.props.classes.smallInput}
            value={this.state.placePattern}
            label="Place Pattern"
            onChange={e =>
              this.setState({
                placePattern: e.target
                  .value as detection_pb.PlaceZoneConfig.PlacePattern
              })
            }
          >
            <MenuItem
              value={
                detection_pb.PlaceZoneConfig.PlacePattern.PLACE_PATTERN_ROW
              }
            >
              Rows
            </MenuItem>
            <MenuItem
              value={
                detection_pb.PlaceZoneConfig.PlacePattern.PLACE_PATTERN_COLUMN
              }
            >
              Columns
            </MenuItem>
            <MenuItem
              value={
                detection_pb.PlaceZoneConfig.PlacePattern
                  .PLACE_PATTERN_STAIRSTEP_ROW
              }
            >
              Stairstep (row first)
            </MenuItem>
            <MenuItem
              value={
                detection_pb.PlaceZoneConfig.PlacePattern
                  .PLACE_PATTERN_STAIRSTEP_COLUMN
              }
            >
              Stairstep (column first)
            </MenuItem>
          </Select>
        </FormControl>
          <Autocomplete
            style={{display: "inline-flex", width: "18em"}}
            multiple
            options={[detection_pb.PlaceZoneConfig.PlaceStrategy.PLACE_LEFT,
                detection_pb.PlaceZoneConfig.PlaceStrategy.PLACE_RIGHT,
                detection_pb.PlaceZoneConfig.PlaceStrategy.PLACE_STRAIGHT]}
            getOptionLabel={(option) => {
                // @ts-ignore
                return Object.keys(detection_pb.PlaceZoneConfig.PlaceStrategy).find(key => detection_pb.PlaceZoneConfig.PlaceStrategy[key] === option) || ""}}
            value={this.state.placeStrategy}
            onChange={(event: any, newValues: Array<detection_pb.PlaceZoneConfig.PlaceStrategy> | null) => {
                  this.setState({placeStrategy: newValues || []});
                }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={this.props.classes.largeInput}
                label="Place Strategy"
              />
            )}
          />
          <Autocomplete
            style={{display: "inline-flex", width: "18em"}}
            multiple
            options={[detection_pb.PlaceZoneConfig.Orientation.STRAIGHT,
                detection_pb.PlaceZoneConfig.Orientation.TURNED]}
            getOptionLabel={(option) => {
                // @ts-ignore
                return Object.keys(detection_pb.PlaceZoneConfig.Orientation).find(key => detection_pb.PlaceZoneConfig.Orientation[key] === option) || ""}}
            value={this.state.orientation}
            onChange={(event: any, newValues: Array<detection_pb.PlaceZoneConfig.Orientation> | null) => {
                  this.setState({orientation: newValues || []});
                }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={this.props.classes.largeInput}
                label="Place Orientation"
              />
            )}
          />
        <FormControlLabel
          label="Place From Left to Right?"
          className={this.props.classes.normalInput}
          control={
              <Checkbox
                  id="place-left-right-input"
                  checked={this.state.placeLeftToRight}
                  onChange={e =>
                    this.setState({ placeLeftToRight: e.target.checked })
                  }
              />
            }
        />
        <Button variant="outlined" color="primary" onClick={this._submit}>
          Save
        </Button>
        <Button variant="outlined" color="primary" onClick={this.props.cancel}>
          Cancel
        </Button>
      </div>
    );
  }
}

export default connect()(withStyles(styles)(PlaceZoneTemplateEditor));
