import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";

import { ApplicationState, ViewerState } from "../../redux";
import { landingPath, logInPath } from "../../utils/Paths";

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return { viewer: state.viewer };
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
  viewer: ViewerState;
  message: string;
}

class LogIn extends Component<Props> {
  render() {
    const { classes, viewer } = this.props;

    const { account } = viewer;
    if (!account) {
      return <Redirect to={logInPath()} />;
    }
    if (account.isApprovedForAccess && account.isEmailVerified) {
      return <Redirect to={landingPath()} />;
    }
    const message = !account.isEmailVerified
      ? "Your email address has not been verified. Please check your email for a verification link."
      : "Your account is not yet approved for access.";
    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {message}
          </Typography>
        </Paper>
      </main>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(LogIn));
