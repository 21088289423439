import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";

import { ApplicationState, orderedEntities } from "../../redux";
import * as payloads from "../../redux/payloads";
import detection_pb from "../../_proto/detection/proto/detection_pb";
import {Button, Container, FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

const styles = () => createStyles({
  normalInput: {
      width: "15em",
      margin: ".5em"
  }
});

const mapStateToProps = (state: ApplicationState) => {
  const robotAccounts: Array<payloads.RobotAccount> = orderedEntities(
    state.entities.robotAccounts
  );
  return { robotAccounts };
};

interface Props extends WithStyles<typeof styles> {
  pickZoneTemplate: detection_pb.ZoneConfig;
  submit: (editedTemplate: detection_pb.ZoneConfig) => void;
  cancel: () => void;
}

interface State {
  rampLength: number;
  rampWidth: number;
  rampHasWalls: boolean;
  lipToTagLine: number;
  lipToBumpers: number;
  templateName: string;
}

class PickZoneTemplateEditor extends Component<Props, State> {
  state: State = {
    rampLength: this.props.pickZoneTemplate.getRampConfig()?.getLength() || 0,
    rampWidth: this.props.pickZoneTemplate.getRampConfig()?.getWidth() || 0,
    rampHasWalls: !!this.props.pickZoneTemplate.getRampConfig()?.getHasWalls(),
    lipToTagLine: this.props.pickZoneTemplate.getRampConfig()?.getLipToTagLine() || 0,
    lipToBumpers: this.props.pickZoneTemplate.getRampConfig()?.getLipToBumpers() || 0,
    templateName: this.props.pickZoneTemplate.getTemplateName(),
  };

  _submit = () => {
    const editedTemplate = this.props.pickZoneTemplate;
    editedTemplate.getRampConfig()?.setLength(this.state.rampLength);
    editedTemplate.getRampConfig()?.setWidth(this.state.rampWidth);
    editedTemplate.getRampConfig()?.setHasWalls(this.state.rampHasWalls);
    editedTemplate.getRampConfig()?.setLipToTagLine(this.state.lipToTagLine);
    editedTemplate.getRampConfig()?.setLipToBumpers(this.state.lipToBumpers);
    editedTemplate.setTemplateName(this.state.templateName);
    editedTemplate.setZoneType(detection_pb.ZoneConfig.ZoneType.TRUCK);
    this.props.submit(editedTemplate);
  };

  render() {
    return (
      <div>
        <TextField
          id="pick-template-name"
          label="Name"
          variant="outlined"
          defaultValue={this.state.templateName}
          onChange={e => this.setState({ templateName: e.target.value })}
        />
        <TextField
          id="pick-template-ramp-length"
          label="Ramp Length"
          variant="outlined"
          type="number"
          defaultValue={this.state.rampLength}
          onChange={e =>
            this.setState({ rampLength: parseFloat(e.target.value) })
          }
        />
        <TextField
          id="pick-template-ramp-width"
          label="Ramp Width"
          variant="outlined"
          type="number"
          defaultValue={this.state.rampWidth}
          onChange={e =>
            this.setState({ rampWidth: parseFloat(e.target.value) })
          }
        />
        <FormControl>
          <InputLabel id="pick-template-has-ramp-walls-label">Has Ramp Walls?</InputLabel>
          <Select
            className={this.props.classes.normalInput}
            labelId="pick-template-has-ramp-walls-label"
            id="pick-template-has-ramp-walls-select"
            value={this.state.rampHasWalls}
            label="Has Ramp Walls?"
            onChange={e =>
              this.setState({ rampHasWalls: e.target.value === "true" })
            }
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="pick-template-lip-to-tag-line"
          label="Lip to Tag Line"
          variant="outlined"
          type="number"
          defaultValue={this.state.lipToTagLine}
          onChange={e =>
            this.setState({ lipToTagLine: parseFloat(e.target.value) })
          }
        />
        <TextField
          id="pick-template-lip-to-bumpers"
          label="Lip to Bumpers"
          variant="outlined"
          type="number"
          defaultValue={this.state.lipToBumpers}
          onChange={e =>
            this.setState({ lipToBumpers: parseFloat(e.target.value) })
          }
        />
        <Button variant="outlined" color="primary" onClick={this._submit}>
          Save
        </Button>
        <Button variant="outlined" color="primary" onClick={this.props.cancel}>
          Cancel
        </Button>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(PickZoneTemplateEditor)
);