import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";

import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { Button, TableCell, TableRow } from "@material-ui/core";

const styles = () => createStyles({});

interface Props extends WithStyles<typeof styles> {
  dockConfig: m_pb.DockConfig;
  delete: () => void;
  edit: () => void;
  canEdit: boolean;
}

class DockConfigurationItem extends Component<Props> {
  render() {
    const { dockConfig } = this.props;
    return (
      <>
        <TableCell>{dockConfig.getPickZoneName()}</TableCell>
        <TableCell align="right">{dockConfig.getPickZoneId()}</TableCell>
        <TableCell align="right">{dockConfig.getPlaceZoneName()}</TableCell>
        <TableCell align="right">{dockConfig.getRampToPlaceZoneX()}</TableCell>
        <TableCell align="right">{dockConfig.getRampToPlaceZoneY()}</TableCell>
        <TableCell align="right">
          {dockConfig.getPlaceZoneTemplateName()}
        </TableCell>
        <TableCell align="right">
          <Button onClick={this.props.edit} disabled={!this.props.canEdit}>
            Edit
          </Button>
        </TableCell>
        <TableCell align="right">
          <Button onClick={this.props.delete} disabled={!this.props.canEdit}>
            Delete
          </Button>
        </TableCell>
      </>
    );
  }
}

export default connect()(withStyles(styles)(DockConfigurationItem));
