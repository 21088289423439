import React, { Component } from "react";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import RobotKPIs from "./RobotKPIs";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    wrapper: {
      width: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "column"
    },
    card: {
      width: "100%"
    },
    content: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: 0,
      paddingLeft: 0
    }
  });

interface Props extends WithStyles<typeof styles> {
}
interface State {
}

class OperationsPage extends Component<Props, State> {
  render() {
    return (
      <div>
        <RobotKPIs/>
      </div>
    );
  }
}

export default connect()(withStyles(styles)(OperationsPage));
