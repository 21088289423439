import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router";

import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";

import { ApplicationState } from "../../redux";
import {listTrailerStatsDatastoreRequest} from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { grpc } from "@improbable-eng/grpc-web";
import { logInPath } from "../../utils/Paths";
import TrailerStatsSheet from "../Sheets/TrailerStatsSheet";
import EventFilterSearchBar from "../Utils/EventFilterSearchBar";
import LabeledCircularProgress from "../Utils/LabeledCircularProgress";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      height: "100%",
      padding: 0,
      paddingBottom: 16,
      display: "flex",
      flexDirection: "column"
    }
  });

const mapStateToProps = (state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}
interface State {
  isLoading: boolean;
  redirectTo: string | null;
  stats: Array<m_pb.TrailerStats.AsObject>;
  loadingProgress: number;
}

class ListTrailerStatsTest extends Component<Props, State> {
  state: State = {
    isLoading: false,
    redirectTo: null,
    stats: [],
    loadingProgress: 0
  };

  _fetch(filter: m_pb.EventFilter, nextPageToken = "", newFetch = true) {
    this.setState({ isLoading: true, loadingProgress: newFetch ? 0 : this.state.loadingProgress }, () => {
      const finish = () => this.setState({ isLoading: false });
      this.props
        .dispatch(listTrailerStatsDatastoreRequest(filter, nextPageToken))
        .then((res: m_pb.ListTrailerStatsResponse.AsObject) => {
          const stats = newFetch ? res.trailerStatsList : this.state.stats.concat(res.trailerStatsList);
          stats.sort((s1, s2) =>
              s1.startTime > s2.startTime ? -1 : 1
          )
          if (res.nextPageToken) {
            this.setState({
              stats,
              isLoading: true,
              loadingProgress: 100 * stats.length / res.count
            });
            this._fetch(filter, res.nextPageToken, false);
          } else {
            this.setState({
              stats,
              isLoading: false
            });
          }
        })
        .catch((e: ServiceError) => {
          switch (e.code) {
            case grpc.Code.Unauthenticated: {
              this.setState({
                redirectTo: logInPath(window.location.pathname)
              });
              break;
            }
            // TODO(malcolm): Add pages for permission denied, 500 error
          }
        })
        // .finally(finish);
    });
  }

  render() {
    const { classes } = this.props;
    const { isLoading, stats, redirectTo, loadingProgress } = this.state;
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }
    const progressSpinner = isLoading ? <LabeledCircularProgress loadingProgress={loadingProgress} /> : null;
    const sheet = stats.length ? <TrailerStatsSheet stats={stats} loading={isLoading}/> : null;
    const pullCount = stats.reduce((autoPulls, trailerEntry) => autoPulls += trailerEntry.autonomouslyAttemptedStacks, 0);
    const trailerCount = stats.filter(trailerEntry => trailerEntry.autonomouslyAttemptedStacks > 0).length;
    const vehicleInterventions = stats.reduce((vehicleInterventions, trailerEntry) => vehicleInterventions + trailerEntry.totalInterventions - trailerEntry.manuallyInitiatedInterventions, 0);
    const autonomousPicksDuration = stats.reduce((autonomousPicksDuration, trailerEntry) => autonomousPicksDuration + trailerEntry.autonomousPicksDuration, 0);
    const interventionResponseDuration = stats.reduce((interventionResponseDuration, trailerEntry) => interventionResponseDuration + trailerEntry.totalInterventionResponseTime, 0);
    return (
      <div className={classes.wrapper}>
        <EventFilterSearchBar
          disable={isLoading}
          title={"Trailer Unload Statistics"}
          onRequestSubmit={f => this._fetch(f)}
        />
        <div>Pull Count: {pullCount}</div>
        <div>Trailer Count: {trailerCount}</div>
        <div>Intervention Rate: {Math.round(1000 * vehicleInterventions / pullCount) / 1000}</div>
        <div>Vehicle Throughput: {Math.round(10 * 3600 * 1e9 * pullCount / (autonomousPicksDuration - interventionResponseDuration) ) / 10} pulls/hr</div>
        <div>Intervention Response Time: {Math.round(vehicleInterventions ? interventionResponseDuration / vehicleInterventions / 1e9 : 0)} seconds</div>
        {progressSpinner}
        {sheet}
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(ListTrailerStatsTest));
