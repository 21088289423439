import { connect } from 'react-redux';
import RobotSearch from './RobotSearch';
import { ApplicationState } from '../../redux';

const mapStateToProps = (state: ApplicationState) => ({
  searchString: state.robotStatus.searchString,
  includeInactive: state.robotStatus.includeInactive,
  searchTarget: state.robotStatus.searchTarget,
});

export default connect(mapStateToProps)(RobotSearch);
