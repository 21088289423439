import React, { Component } from "react";

import { connect } from "react-redux";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import { ApplicationState, orderedEntities } from "../../redux";
import { RunMetadata, Account } from "../../redux/payloads";
import { Button } from "@material-ui/core";
import { logInPath } from "../../utils/Paths";
import * as payloads from "../../redux/payloads";
import {
  listAutonomyVersionsRequest,
  listForkliftCohortsRequest,
  listRobotAccountsRequest
} from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { grpc } from "@improbable-eng/grpc-web";
import ProgressSpinner from "../Utils/ProgressSpinner";
import { Redirect } from "react-router";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import RobotAccountListItem from "./RobotAccountListItem";
import CreateSyncDemandDialog from "../RunPage/CreateSyncDemandDialog";
import AutonomyVersionDialog from "./AutonomyVersionDialog";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    wrapper: {
      width: "100%",
      padding: 0,
      display: "flex",
      flexDirection: "column"
    },
    card: {
      width: "100%"
    },
    content: {
      width: "95%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: 0,
      paddingLeft: 0
    },
    shaded: {
      background: "#f4f6f7"
    },
    none: {},
    buttonsContainer: {
      margin: "auto",
      width: "90%",
      display: "flex",
      justifyContent: "flex-end",
    },
  });

const mapStateToProps = (state: ApplicationState) => {
  const robotAccounts: Array<payloads.RobotAccount> = orderedEntities(
    state.entities.robotAccounts
  );
  return { robotAccounts, viewer: state.viewer.account };
};

interface Props extends WithStyles<typeof styles> {
  run: RunMetadata;
  viewer: Account | null;
  dispatch: any;
  robotAccounts: Array<payloads.RobotAccount>;
}
interface State {
  waiting: boolean;
  autonomyVersionDialogOpen: boolean;
  redirectTo: string | null;
  responseCount: number;
  cohorts: Array<m_pb.ForkliftCohort.AsObject>;
  autonomyVersions: Array<m_pb.AutonomyVersion.AsObject>;
}
class RobotsDashboard extends Component<Props, State> {
  state: State = {
    waiting: false,
    redirectTo: null,
    responseCount: 0,
    cohorts: [],
    autonomyVersions: []
  };

  componentDidMount() {
    this._loadAutonomyVersions();
    this.props
      .dispatch(listForkliftCohortsRequest({ pageToken: 0, pageSize: 500 }))
      .then((payload: { response: m_pb.ListForkliftCohortsResponse }) => {
        let { cohorts: oldCohorts } = this.state;
        const { response } = payload;
        const cohorts = oldCohorts.map(c => c);
        // @ts-ignore
        cohorts.push(...response.toObject().cohortsList);
        this.setState({ cohorts }, () => {
          this._fetchMoreRobots();
        });
      })
      .catch((e: ServiceError) => {
        switch (e.code) {
          case grpc.Code.Unauthenticated: {
            this.setState({
              redirectTo: logInPath(window.location.pathname)
            });
            break;
          }
          // TODO(malcolm): Add pages for permission denied, 500 error
        }
      });
  }

  _loadAutonomyVersions() {
    this.props.dispatch(listAutonomyVersionsRequest())
      .then((resp: m_pb.ListAutonomyVersionsResponse.AsObject) => {
        this.setState({autonomyVersions: resp.autonomyVersionsList})
      });
  }

  _fetchMoreRobots() {
    this.setState({ waiting: true }, () => {
      const { robotAccounts } = this.props;
      this.props
        .dispatch(listRobotAccountsRequest(robotAccounts.length))
        .then((response: any) => {
          this.setState({ responseCount: response.count, waiting: false });
        })
        .catch((e: ServiceError) => {
          switch (e.code) {
            case grpc.Code.Unauthenticated: {
              this.setState({
                redirectTo: logInPath(window.location.pathname)
              });
              break;
            }
            // TODO(malcolm): Add pages for permission denied, 500 error
          }
          this.setState({ waiting: false });
        });
    });
  }

  render() {
    const { classes, robotAccounts } = this.props;
    const { waiting, responseCount, redirectTo, cohorts, autonomyVersions } = this.state;
    if (redirectTo) {
      // @ts-ignore
      return <Redirect to={redirectTo} />;
    }
    const hasMore = responseCount > robotAccounts.length;
    const progressSpinner = waiting ? <ProgressSpinner /> : null;
    const rowClassName = [classes.none, classes.shaded];

    return (
      <div className={classes.wrapper}>
        <Card className={classes.card}>
          <CardHeader title={"Robots"} />
          <div className={classes.buttonsContainer}>
            <Button variant="outlined" color="primary" onClick={() =>
              this.setState({
                autonomyVersionDialogOpen: true
              })}>Add Autonomy Version</Button>
          </div>
          <CardContent className={classes.content}>
            <List component="nav" className={classes.root}>
              {robotAccounts.map(
                (a, i) =>
                  a.id && (
                    <RobotAccountListItem
                      key={a.robotName}
                      cohorts={cohorts}
                      robotAccount={a}
                      rowClassName={rowClassName[i % 2]}
                      autonomyVersions={autonomyVersions.filter(version => version.active)}
                    />
                  )
              )}
            </List>
            {progressSpinner}
            <Button
              fullWidth
              disabled={waiting || !hasMore}
              onClick={() => this._fetchMoreRobots()}
            >
              Load More
            </Button>
          </CardContent>
          <AutonomyVersionDialog
            open={this.state.autonomyVersionDialogOpen}
            onClose={() =>
              this.setState({
                autonomyVersionDialogOpen: false
              })
            }
            onSuccess={() => {
              this.setState({
                autonomyVersionDialogOpen: false
              })
              this._loadAutonomyVersions();
            }
            }
          />

        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(RobotsDashboard));
