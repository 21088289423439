export const COLORS = [
  "#ff0000",
  "#004cff",
  "#ff6f00",
  "#ff00e1",
  "#28a100",
  "#7300ff",
  "#c9db00",
  "#fc8781",
  "#81fcf0",
  "#97fc81",
  "#8193fc",
  "#fcbd81",
  "#a881fc"
];
