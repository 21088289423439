// source: planner/proto/planner_status.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var geometry_proto_transform_pb = require('../../geometry/proto/transform_pb.js');
goog.object.extend(proto, geometry_proto_transform_pb);
var geometry_proto_geometry_pb = require('../../geometry/proto/geometry_pb.js');
goog.object.extend(proto, geometry_proto_geometry_pb);
var planner_proto_types_pb = require('../../planner/proto/types_pb.js');
goog.object.extend(proto, planner_proto_types_pb);
var detection_proto_detection_pb = require('../../detection/proto/detection_pb.js');
goog.object.extend(proto, detection_proto_detection_pb);
goog.exportSymbol('proto.fox.proto.Action', null, global);
goog.exportSymbol('proto.fox.proto.Action.State', null, global);
goog.exportSymbol('proto.fox.proto.Action.TypeCase', null, global);
goog.exportSymbol('proto.fox.proto.ActionError', null, global);
goog.exportSymbol('proto.fox.proto.ActionError.BadForksPositionDetails', null, global);
goog.exportSymbol('proto.fox.proto.ActionError.Code', null, global);
goog.exportSymbol('proto.fox.proto.ActionError.Persistence', null, global);
goog.exportSymbol('proto.fox.proto.ActionPlanner', null, global);
goog.exportSymbol('proto.fox.proto.ActionStatus', null, global);
goog.exportSymbol('proto.fox.proto.ActionStatus.StatusCase', null, global);
goog.exportSymbol('proto.fox.proto.NavigateToPickStatus', null, global);
goog.exportSymbol('proto.fox.proto.NavigateToPickStatus.State', null, global);
goog.exportSymbol('proto.fox.proto.NavigateToPlaceStatus', null, global);
goog.exportSymbol('proto.fox.proto.NavigateToPlaceStatus.State', null, global);
goog.exportSymbol('proto.fox.proto.NavigateToPoseAction', null, global);
goog.exportSymbol('proto.fox.proto.NavigationStatus', null, global);
goog.exportSymbol('proto.fox.proto.NavigationStatus.State', null, global);
goog.exportSymbol('proto.fox.proto.PickPalletAction', null, global);
goog.exportSymbol('proto.fox.proto.PickPalletStatus', null, global);
goog.exportSymbol('proto.fox.proto.PickPalletStatus.State', null, global);
goog.exportSymbol('proto.fox.proto.PlacePalletAction', null, global);
goog.exportSymbol('proto.fox.proto.PlacePalletStatus', null, global);
goog.exportSymbol('proto.fox.proto.PlacePalletStatus.State', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.NavigateToPoseAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.NavigateToPoseAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.NavigateToPoseAction.displayName = 'proto.fox.proto.NavigateToPoseAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PickPalletAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PickPalletAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PickPalletAction.displayName = 'proto.fox.proto.PickPalletAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlacePalletAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PlacePalletAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlacePalletAction.displayName = 'proto.fox.proto.PlacePalletAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fox.proto.Action.oneofGroups_);
};
goog.inherits(proto.fox.proto.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.Action.displayName = 'proto.fox.proto.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.NavigationStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.NavigationStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.NavigationStatus.displayName = 'proto.fox.proto.NavigationStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.NavigateToPickStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.NavigateToPickStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.NavigateToPickStatus.displayName = 'proto.fox.proto.NavigateToPickStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.NavigateToPlaceStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.NavigateToPlaceStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.NavigateToPlaceStatus.displayName = 'proto.fox.proto.NavigateToPlaceStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PickPalletStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PickPalletStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PickPalletStatus.displayName = 'proto.fox.proto.PickPalletStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.PlacePalletStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fox.proto.PlacePalletStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.PlacePalletStatus.displayName = 'proto.fox.proto.PlacePalletStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ActionError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.ActionError.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.ActionError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ActionError.displayName = 'proto.fox.proto.ActionError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ActionError.BadForksPositionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.ActionError.BadForksPositionDetails.repeatedFields_, null);
};
goog.inherits(proto.fox.proto.ActionError.BadForksPositionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ActionError.BadForksPositionDetails.displayName = 'proto.fox.proto.ActionError.BadForksPositionDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fox.proto.ActionStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fox.proto.ActionStatus.repeatedFields_, proto.fox.proto.ActionStatus.oneofGroups_);
};
goog.inherits(proto.fox.proto.ActionStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fox.proto.ActionStatus.displayName = 'proto.fox.proto.ActionStatus';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.NavigateToPoseAction.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.NavigateToPoseAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.NavigateToPoseAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NavigateToPoseAction.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.NavigateToPoseAction}
 */
proto.fox.proto.NavigateToPoseAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.NavigateToPoseAction;
  return proto.fox.proto.NavigateToPoseAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.NavigateToPoseAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.NavigateToPoseAction}
 */
proto.fox.proto.NavigateToPoseAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.NavigateToPoseAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.NavigateToPoseAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.NavigateToPoseAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NavigateToPoseAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PickPalletAction.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PickPalletAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PickPalletAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PickPalletAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetPalletId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    targetPalletSupportType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    supportTypeV2: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mapTTargetPallet: (f = msg.getMapTTargetPallet()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    lowerTinesIndex: (f = msg.getLowerTinesIndex()) && planner_proto_types_pb.TrajectoryIndex.toObject(includeInstance, f),
    raiseTinesIndex: (f = msg.getRaiseTinesIndex()) && planner_proto_types_pb.TrajectoryIndex.toObject(includeInstance, f),
    rampAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PickPalletAction}
 */
proto.fox.proto.PickPalletAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PickPalletAction;
  return proto.fox.proto.PickPalletAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PickPalletAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PickPalletAction}
 */
proto.fox.proto.PickPalletAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetPalletId(value);
      break;
    case 5:
      var value = /** @type {!proto.fox.proto.TrackedPallet.PalletSupportType} */ (reader.readEnum());
      msg.setTargetPalletSupportType(value);
      break;
    case 6:
      var value = /** @type {!proto.fox.proto.SupportType} */ (reader.readEnum());
      msg.setSupportTypeV2(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setMapTTargetPallet(value);
      break;
    case 3:
      var value = new planner_proto_types_pb.TrajectoryIndex;
      reader.readMessage(value,planner_proto_types_pb.TrajectoryIndex.deserializeBinaryFromReader);
      msg.setLowerTinesIndex(value);
      break;
    case 4:
      var value = new planner_proto_types_pb.TrajectoryIndex;
      reader.readMessage(value,planner_proto_types_pb.TrajectoryIndex.deserializeBinaryFromReader);
      msg.setRaiseTinesIndex(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRampAngle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PickPalletAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PickPalletAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PickPalletAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PickPalletAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetPalletId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTargetPalletSupportType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSupportTypeV2();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getMapTTargetPallet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getLowerTinesIndex();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      planner_proto_types_pb.TrajectoryIndex.serializeBinaryToWriter
    );
  }
  f = message.getRaiseTinesIndex();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      planner_proto_types_pb.TrajectoryIndex.serializeBinaryToWriter
    );
  }
  f = message.getRampAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional int32 target_pallet_id = 1;
 * @return {number}
 */
proto.fox.proto.PickPalletAction.prototype.getTargetPalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPalletAction} returns this
 */
proto.fox.proto.PickPalletAction.prototype.setTargetPalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TrackedPallet.PalletSupportType target_pallet_support_type = 5;
 * @return {!proto.fox.proto.TrackedPallet.PalletSupportType}
 */
proto.fox.proto.PickPalletAction.prototype.getTargetPalletSupportType = function() {
  return /** @type {!proto.fox.proto.TrackedPallet.PalletSupportType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fox.proto.TrackedPallet.PalletSupportType} value
 * @return {!proto.fox.proto.PickPalletAction} returns this
 */
proto.fox.proto.PickPalletAction.prototype.setTargetPalletSupportType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional SupportType support_type_v2 = 6;
 * @return {!proto.fox.proto.SupportType}
 */
proto.fox.proto.PickPalletAction.prototype.getSupportTypeV2 = function() {
  return /** @type {!proto.fox.proto.SupportType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.fox.proto.SupportType} value
 * @return {!proto.fox.proto.PickPalletAction} returns this
 */
proto.fox.proto.PickPalletAction.prototype.setSupportTypeV2 = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Transform3f map_t_target_pallet = 2;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.PickPalletAction.prototype.getMapTTargetPallet = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 2));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.PickPalletAction} returns this
*/
proto.fox.proto.PickPalletAction.prototype.setMapTTargetPallet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PickPalletAction} returns this
 */
proto.fox.proto.PickPalletAction.prototype.clearMapTTargetPallet = function() {
  return this.setMapTTargetPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PickPalletAction.prototype.hasMapTTargetPallet = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TrajectoryIndex lower_tines_index = 3;
 * @return {?proto.fox.proto.TrajectoryIndex}
 */
proto.fox.proto.PickPalletAction.prototype.getLowerTinesIndex = function() {
  return /** @type{?proto.fox.proto.TrajectoryIndex} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.TrajectoryIndex, 3));
};


/**
 * @param {?proto.fox.proto.TrajectoryIndex|undefined} value
 * @return {!proto.fox.proto.PickPalletAction} returns this
*/
proto.fox.proto.PickPalletAction.prototype.setLowerTinesIndex = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PickPalletAction} returns this
 */
proto.fox.proto.PickPalletAction.prototype.clearLowerTinesIndex = function() {
  return this.setLowerTinesIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PickPalletAction.prototype.hasLowerTinesIndex = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TrajectoryIndex raise_tines_index = 4;
 * @return {?proto.fox.proto.TrajectoryIndex}
 */
proto.fox.proto.PickPalletAction.prototype.getRaiseTinesIndex = function() {
  return /** @type{?proto.fox.proto.TrajectoryIndex} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.TrajectoryIndex, 4));
};


/**
 * @param {?proto.fox.proto.TrajectoryIndex|undefined} value
 * @return {!proto.fox.proto.PickPalletAction} returns this
*/
proto.fox.proto.PickPalletAction.prototype.setRaiseTinesIndex = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PickPalletAction} returns this
 */
proto.fox.proto.PickPalletAction.prototype.clearRaiseTinesIndex = function() {
  return this.setRaiseTinesIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PickPalletAction.prototype.hasRaiseTinesIndex = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float ramp_angle = 7;
 * @return {number}
 */
proto.fox.proto.PickPalletAction.prototype.getRampAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PickPalletAction} returns this
 */
proto.fox.proto.PickPalletAction.prototype.setRampAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlacePalletAction.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlacePalletAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlacePalletAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlacePalletAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    carriageTCarried: (f = msg.getCarriageTCarried()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    lowerTinesIndex: (f = msg.getLowerTinesIndex()) && planner_proto_types_pb.TrajectoryIndex.toObject(includeInstance, f),
    mastStartIndex: (f = msg.getMastStartIndex()) && planner_proto_types_pb.TrajectoryIndex.toObject(includeInstance, f),
    mapTPalletPlace: (f = msg.getMapTPalletPlace()) && geometry_proto_transform_pb.Transform3f.toObject(includeInstance, f),
    carriedObstacles: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    truckTPalletPlace: (f = msg.getTruckTPalletPlace()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    carriedPalletId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    placeLocationIndex: jspb.Message.getFieldWithDefault(msg, 8, 0),
    targetPalletTallMode: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlacePalletAction}
 */
proto.fox.proto.PlacePalletAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlacePalletAction;
  return proto.fox.proto.PlacePalletAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlacePalletAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlacePalletAction}
 */
proto.fox.proto.PlacePalletAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setCarriageTCarried(value);
      break;
    case 2:
      var value = new planner_proto_types_pb.TrajectoryIndex;
      reader.readMessage(value,planner_proto_types_pb.TrajectoryIndex.deserializeBinaryFromReader);
      msg.setLowerTinesIndex(value);
      break;
    case 3:
      var value = new planner_proto_types_pb.TrajectoryIndex;
      reader.readMessage(value,planner_proto_types_pb.TrajectoryIndex.deserializeBinaryFromReader);
      msg.setMastStartIndex(value);
      break;
    case 4:
      var value = new geometry_proto_transform_pb.Transform3f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform3f.deserializeBinaryFromReader);
      msg.setMapTPalletPlace(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCarriedObstacles(value);
      break;
    case 6:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setTruckTPalletPlace(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCarriedPalletId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPlaceLocationIndex(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetPalletTallMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlacePalletAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlacePalletAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlacePalletAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlacePalletAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarriageTCarried();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getLowerTinesIndex();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      planner_proto_types_pb.TrajectoryIndex.serializeBinaryToWriter
    );
  }
  f = message.getMastStartIndex();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      planner_proto_types_pb.TrajectoryIndex.serializeBinaryToWriter
    );
  }
  f = message.getMapTPalletPlace();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      geometry_proto_transform_pb.Transform3f.serializeBinaryToWriter
    );
  }
  f = message.getCarriedObstacles();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTruckTPalletPlace();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getCarriedPalletId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPlaceLocationIndex();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTargetPalletTallMode();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional Transform2f carriage_t_carried = 1;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.PlacePalletAction.prototype.getCarriageTCarried = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 1));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.PlacePalletAction} returns this
*/
proto.fox.proto.PlacePalletAction.prototype.setCarriageTCarried = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlacePalletAction} returns this
 */
proto.fox.proto.PlacePalletAction.prototype.clearCarriageTCarried = function() {
  return this.setCarriageTCarried(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlacePalletAction.prototype.hasCarriageTCarried = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TrajectoryIndex lower_tines_index = 2;
 * @return {?proto.fox.proto.TrajectoryIndex}
 */
proto.fox.proto.PlacePalletAction.prototype.getLowerTinesIndex = function() {
  return /** @type{?proto.fox.proto.TrajectoryIndex} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.TrajectoryIndex, 2));
};


/**
 * @param {?proto.fox.proto.TrajectoryIndex|undefined} value
 * @return {!proto.fox.proto.PlacePalletAction} returns this
*/
proto.fox.proto.PlacePalletAction.prototype.setLowerTinesIndex = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlacePalletAction} returns this
 */
proto.fox.proto.PlacePalletAction.prototype.clearLowerTinesIndex = function() {
  return this.setLowerTinesIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlacePalletAction.prototype.hasLowerTinesIndex = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TrajectoryIndex mast_start_index = 3;
 * @return {?proto.fox.proto.TrajectoryIndex}
 */
proto.fox.proto.PlacePalletAction.prototype.getMastStartIndex = function() {
  return /** @type{?proto.fox.proto.TrajectoryIndex} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.TrajectoryIndex, 3));
};


/**
 * @param {?proto.fox.proto.TrajectoryIndex|undefined} value
 * @return {!proto.fox.proto.PlacePalletAction} returns this
*/
proto.fox.proto.PlacePalletAction.prototype.setMastStartIndex = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlacePalletAction} returns this
 */
proto.fox.proto.PlacePalletAction.prototype.clearMastStartIndex = function() {
  return this.setMastStartIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlacePalletAction.prototype.hasMastStartIndex = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Transform3f map_t_pallet_place = 4;
 * @return {?proto.fox.proto.Transform3f}
 */
proto.fox.proto.PlacePalletAction.prototype.getMapTPalletPlace = function() {
  return /** @type{?proto.fox.proto.Transform3f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform3f, 4));
};


/**
 * @param {?proto.fox.proto.Transform3f|undefined} value
 * @return {!proto.fox.proto.PlacePalletAction} returns this
*/
proto.fox.proto.PlacePalletAction.prototype.setMapTPalletPlace = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlacePalletAction} returns this
 */
proto.fox.proto.PlacePalletAction.prototype.clearMapTPalletPlace = function() {
  return this.setMapTPalletPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlacePalletAction.prototype.hasMapTPalletPlace = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool carried_obstacles = 5;
 * @return {boolean}
 */
proto.fox.proto.PlacePalletAction.prototype.getCarriedObstacles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlacePalletAction} returns this
 */
proto.fox.proto.PlacePalletAction.prototype.setCarriedObstacles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional Transform2f truck_t_pallet_place = 6;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.PlacePalletAction.prototype.getTruckTPalletPlace = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 6));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.PlacePalletAction} returns this
*/
proto.fox.proto.PlacePalletAction.prototype.setTruckTPalletPlace = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlacePalletAction} returns this
 */
proto.fox.proto.PlacePalletAction.prototype.clearTruckTPalletPlace = function() {
  return this.setTruckTPalletPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlacePalletAction.prototype.hasTruckTPalletPlace = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 carried_pallet_id = 7;
 * @return {number}
 */
proto.fox.proto.PlacePalletAction.prototype.getCarriedPalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlacePalletAction} returns this
 */
proto.fox.proto.PlacePalletAction.prototype.setCarriedPalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 place_location_index = 8;
 * @return {number}
 */
proto.fox.proto.PlacePalletAction.prototype.getPlaceLocationIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.PlacePalletAction} returns this
 */
proto.fox.proto.PlacePalletAction.prototype.setPlaceLocationIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool target_pallet_tall_mode = 9;
 * @return {boolean}
 */
proto.fox.proto.PlacePalletAction.prototype.getTargetPalletTallMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.PlacePalletAction} returns this
 */
proto.fox.proto.PlacePalletAction.prototype.setTargetPalletTallMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fox.proto.Action.oneofGroups_ = [[9,11,13]];

/**
 * @enum {number}
 */
proto.fox.proto.Action.TypeCase = {
  TYPE_NOT_SET: 0,
  NAVIGATE_TO_POSE: 9,
  PICK_PALLET: 11,
  PLACE_PALLET: 13
};

/**
 * @return {proto.fox.proto.Action.TypeCase}
 */
proto.fox.proto.Action.prototype.getTypeCase = function() {
  return /** @type {proto.fox.proto.Action.TypeCase} */(jspb.Message.computeOneofCase(this, proto.fox.proto.Action.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    goalId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    trajectoryId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    completesGoal: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    endIndex: (f = msg.getEndIndex()) && planner_proto_types_pb.TrajectoryIndex.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 6, 0),
    planner: jspb.Message.getFieldWithDefault(msg, 16, 0),
    navigateToPose: (f = msg.getNavigateToPose()) && proto.fox.proto.NavigateToPoseAction.toObject(includeInstance, f),
    pickPallet: (f = msg.getPickPallet()) && proto.fox.proto.PickPalletAction.toObject(includeInstance, f),
    placePallet: (f = msg.getPlacePallet()) && proto.fox.proto.PlacePalletAction.toObject(includeInstance, f),
    recoveryAttempts: jspb.Message.getFieldWithDefault(msg, 14, 0),
    successfulRecoveryAttempts: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.Action}
 */
proto.fox.proto.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.Action;
  return proto.fox.proto.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.Action}
 */
proto.fox.proto.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoalId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrajectoryId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompletesGoal(value);
      break;
    case 5:
      var value = new planner_proto_types_pb.TrajectoryIndex;
      reader.readMessage(value,planner_proto_types_pb.TrajectoryIndex.deserializeBinaryFromReader);
      msg.setEndIndex(value);
      break;
    case 6:
      var value = /** @type {!proto.fox.proto.Action.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 16:
      var value = /** @type {!proto.fox.proto.ActionPlanner} */ (reader.readEnum());
      msg.setPlanner(value);
      break;
    case 9:
      var value = new proto.fox.proto.NavigateToPoseAction;
      reader.readMessage(value,proto.fox.proto.NavigateToPoseAction.deserializeBinaryFromReader);
      msg.setNavigateToPose(value);
      break;
    case 11:
      var value = new proto.fox.proto.PickPalletAction;
      reader.readMessage(value,proto.fox.proto.PickPalletAction.deserializeBinaryFromReader);
      msg.setPickPallet(value);
      break;
    case 13:
      var value = new proto.fox.proto.PlacePalletAction;
      reader.readMessage(value,proto.fox.proto.PlacePalletAction.deserializeBinaryFromReader);
      msg.setPlacePallet(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecoveryAttempts(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSuccessfulRecoveryAttempts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGoalId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrajectoryId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCompletesGoal();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEndIndex();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      planner_proto_types_pb.TrajectoryIndex.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPlanner();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getNavigateToPose();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.fox.proto.NavigateToPoseAction.serializeBinaryToWriter
    );
  }
  f = message.getPickPallet();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.fox.proto.PickPalletAction.serializeBinaryToWriter
    );
  }
  f = message.getPlacePallet();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.fox.proto.PlacePalletAction.serializeBinaryToWriter
    );
  }
  f = message.getRecoveryAttempts();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getSuccessfulRecoveryAttempts();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.Action.State = {
  UNKNOWN: 0,
  FUTURE: 1,
  ACTIVE: 2,
  INTERRUPTED: 3,
  COMPLETE: 4,
  FAILED: 5
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.Action.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string goal_id = 2;
 * @return {string}
 */
proto.fox.proto.Action.prototype.getGoalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.setGoalId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 trajectory_id = 3;
 * @return {number}
 */
proto.fox.proto.Action.prototype.getTrajectoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.setTrajectoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool completes_goal = 4;
 * @return {boolean}
 */
proto.fox.proto.Action.prototype.getCompletesGoal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.setCompletesGoal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional TrajectoryIndex end_index = 5;
 * @return {?proto.fox.proto.TrajectoryIndex}
 */
proto.fox.proto.Action.prototype.getEndIndex = function() {
  return /** @type{?proto.fox.proto.TrajectoryIndex} */ (
    jspb.Message.getWrapperField(this, planner_proto_types_pb.TrajectoryIndex, 5));
};


/**
 * @param {?proto.fox.proto.TrajectoryIndex|undefined} value
 * @return {!proto.fox.proto.Action} returns this
*/
proto.fox.proto.Action.prototype.setEndIndex = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.clearEndIndex = function() {
  return this.setEndIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Action.prototype.hasEndIndex = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional State state = 6;
 * @return {!proto.fox.proto.Action.State}
 */
proto.fox.proto.Action.prototype.getState = function() {
  return /** @type {!proto.fox.proto.Action.State} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.fox.proto.Action.State} value
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ActionPlanner planner = 16;
 * @return {!proto.fox.proto.ActionPlanner}
 */
proto.fox.proto.Action.prototype.getPlanner = function() {
  return /** @type {!proto.fox.proto.ActionPlanner} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.fox.proto.ActionPlanner} value
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.setPlanner = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional NavigateToPoseAction navigate_to_pose = 9;
 * @return {?proto.fox.proto.NavigateToPoseAction}
 */
proto.fox.proto.Action.prototype.getNavigateToPose = function() {
  return /** @type{?proto.fox.proto.NavigateToPoseAction} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.NavigateToPoseAction, 9));
};


/**
 * @param {?proto.fox.proto.NavigateToPoseAction|undefined} value
 * @return {!proto.fox.proto.Action} returns this
*/
proto.fox.proto.Action.prototype.setNavigateToPose = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.fox.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.clearNavigateToPose = function() {
  return this.setNavigateToPose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Action.prototype.hasNavigateToPose = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PickPalletAction pick_pallet = 11;
 * @return {?proto.fox.proto.PickPalletAction}
 */
proto.fox.proto.Action.prototype.getPickPallet = function() {
  return /** @type{?proto.fox.proto.PickPalletAction} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PickPalletAction, 11));
};


/**
 * @param {?proto.fox.proto.PickPalletAction|undefined} value
 * @return {!proto.fox.proto.Action} returns this
*/
proto.fox.proto.Action.prototype.setPickPallet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.fox.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.clearPickPallet = function() {
  return this.setPickPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Action.prototype.hasPickPallet = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PlacePalletAction place_pallet = 13;
 * @return {?proto.fox.proto.PlacePalletAction}
 */
proto.fox.proto.Action.prototype.getPlacePallet = function() {
  return /** @type{?proto.fox.proto.PlacePalletAction} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlacePalletAction, 13));
};


/**
 * @param {?proto.fox.proto.PlacePalletAction|undefined} value
 * @return {!proto.fox.proto.Action} returns this
*/
proto.fox.proto.Action.prototype.setPlacePallet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.fox.proto.Action.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.clearPlacePallet = function() {
  return this.setPlacePallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.Action.prototype.hasPlacePallet = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional int32 recovery_attempts = 14;
 * @return {number}
 */
proto.fox.proto.Action.prototype.getRecoveryAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.setRecoveryAttempts = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 successful_recovery_attempts = 15;
 * @return {number}
 */
proto.fox.proto.Action.prototype.getSuccessfulRecoveryAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.Action} returns this
 */
proto.fox.proto.Action.prototype.setSuccessfulRecoveryAttempts = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.NavigationStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.NavigationStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.NavigationStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NavigationStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.NavigationStatus}
 */
proto.fox.proto.NavigationStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.NavigationStatus;
  return proto.fox.proto.NavigationStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.NavigationStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.NavigationStatus}
 */
proto.fox.proto.NavigationStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.NavigationStatus.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.NavigationStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.NavigationStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.NavigationStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NavigationStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.NavigationStatus.State = {
  UNKNOWN: 0,
  COMPLETE: 1,
  ABORTED: 2,
  EXECUTING: 3
};

/**
 * optional State state = 1;
 * @return {!proto.fox.proto.NavigationStatus.State}
 */
proto.fox.proto.NavigationStatus.prototype.getState = function() {
  return /** @type {!proto.fox.proto.NavigationStatus.State} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.NavigationStatus.State} value
 * @return {!proto.fox.proto.NavigationStatus} returns this
 */
proto.fox.proto.NavigationStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.NavigateToPickStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.NavigateToPickStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.NavigateToPickStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NavigateToPickStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.NavigateToPickStatus}
 */
proto.fox.proto.NavigateToPickStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.NavigateToPickStatus;
  return proto.fox.proto.NavigateToPickStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.NavigateToPickStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.NavigateToPickStatus}
 */
proto.fox.proto.NavigateToPickStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.NavigateToPickStatus.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.NavigateToPickStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.NavigateToPickStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.NavigateToPickStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NavigateToPickStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.NavigateToPickStatus.State = {
  UNKNOWN: 0,
  COMPLETE: 1,
  ABORTED: 2,
  NAVIGATE_TO_PICK_ZONE: 3,
  NAVIGATE_TO_PICK: 4,
  NO_PALLETS: 5
};

/**
 * optional State state = 1;
 * @return {!proto.fox.proto.NavigateToPickStatus.State}
 */
proto.fox.proto.NavigateToPickStatus.prototype.getState = function() {
  return /** @type {!proto.fox.proto.NavigateToPickStatus.State} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.NavigateToPickStatus.State} value
 * @return {!proto.fox.proto.NavigateToPickStatus} returns this
 */
proto.fox.proto.NavigateToPickStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.NavigateToPlaceStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.NavigateToPlaceStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.NavigateToPlaceStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NavigateToPlaceStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.NavigateToPlaceStatus}
 */
proto.fox.proto.NavigateToPlaceStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.NavigateToPlaceStatus;
  return proto.fox.proto.NavigateToPlaceStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.NavigateToPlaceStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.NavigateToPlaceStatus}
 */
proto.fox.proto.NavigateToPlaceStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.NavigateToPlaceStatus.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.NavigateToPlaceStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.NavigateToPlaceStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.NavigateToPlaceStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.NavigateToPlaceStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.NavigateToPlaceStatus.State = {
  UNKNOWN: 0,
  COMPLETE: 1,
  ABORTED: 2,
  NAVIGATE_TO_PLACE_ZONE: 3,
  NAVIGATE_TO_PLACE: 4
};

/**
 * optional State state = 1;
 * @return {!proto.fox.proto.NavigateToPlaceStatus.State}
 */
proto.fox.proto.NavigateToPlaceStatus.prototype.getState = function() {
  return /** @type {!proto.fox.proto.NavigateToPlaceStatus.State} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.NavigateToPlaceStatus.State} value
 * @return {!proto.fox.proto.NavigateToPlaceStatus} returns this
 */
proto.fox.proto.NavigateToPlaceStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PickPalletStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PickPalletStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PickPalletStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PickPalletStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0),
    transitionPose: (f = msg.getTransitionPose()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PickPalletStatus}
 */
proto.fox.proto.PickPalletStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PickPalletStatus;
  return proto.fox.proto.PickPalletStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PickPalletStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PickPalletStatus}
 */
proto.fox.proto.PickPalletStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.PickPalletStatus.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setTransitionPose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PickPalletStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PickPalletStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PickPalletStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PickPalletStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTransitionPose();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.PickPalletStatus.State = {
  UNKNOWN: 0,
  COMPLETE: 1,
  ABORTED: 2,
  EXECUTING: 3
};

/**
 * optional State state = 1;
 * @return {!proto.fox.proto.PickPalletStatus.State}
 */
proto.fox.proto.PickPalletStatus.prototype.getState = function() {
  return /** @type {!proto.fox.proto.PickPalletStatus.State} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.PickPalletStatus.State} value
 * @return {!proto.fox.proto.PickPalletStatus} returns this
 */
proto.fox.proto.PickPalletStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Transform2f transition_pose = 2;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.PickPalletStatus.prototype.getTransitionPose = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 2));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.PickPalletStatus} returns this
*/
proto.fox.proto.PickPalletStatus.prototype.setTransitionPose = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PickPalletStatus} returns this
 */
proto.fox.proto.PickPalletStatus.prototype.clearTransitionPose = function() {
  return this.setTransitionPose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PickPalletStatus.prototype.hasTransitionPose = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.PlacePalletStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.PlacePalletStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.PlacePalletStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlacePalletStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0),
    transitionPose: (f = msg.getTransitionPose()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.PlacePalletStatus}
 */
proto.fox.proto.PlacePalletStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.PlacePalletStatus;
  return proto.fox.proto.PlacePalletStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.PlacePalletStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.PlacePalletStatus}
 */
proto.fox.proto.PlacePalletStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.PlacePalletStatus.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 2:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setTransitionPose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.PlacePalletStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.PlacePalletStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.PlacePalletStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.PlacePalletStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTransitionPose();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.PlacePalletStatus.State = {
  UNKNOWN: 0,
  COMPLETE: 1,
  ABORTED: 2,
  EXECUTING: 3
};

/**
 * optional State state = 1;
 * @return {!proto.fox.proto.PlacePalletStatus.State}
 */
proto.fox.proto.PlacePalletStatus.prototype.getState = function() {
  return /** @type {!proto.fox.proto.PlacePalletStatus.State} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.PlacePalletStatus.State} value
 * @return {!proto.fox.proto.PlacePalletStatus} returns this
 */
proto.fox.proto.PlacePalletStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Transform2f transition_pose = 2;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.PlacePalletStatus.prototype.getTransitionPose = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 2));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.PlacePalletStatus} returns this
*/
proto.fox.proto.PlacePalletStatus.prototype.setTransitionPose = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.PlacePalletStatus} returns this
 */
proto.fox.proto.PlacePalletStatus.prototype.clearTransitionPose = function() {
  return this.setTransitionPose(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.PlacePalletStatus.prototype.hasTransitionPose = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.ActionError.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ActionError.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ActionError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ActionError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ActionError.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    persistence: jspb.Message.getFieldWithDefault(msg, 4, 0),
    code: jspb.Message.getFieldWithDefault(msg, 5, 0),
    palletId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    palletExceptionsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    badForksPositionDetails: (f = msg.getBadForksPositionDetails()) && proto.fox.proto.ActionError.BadForksPositionDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ActionError}
 */
proto.fox.proto.ActionError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ActionError;
  return proto.fox.proto.ActionError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ActionError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ActionError}
 */
proto.fox.proto.ActionError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.ActionError.Persistence} */ (reader.readEnum());
      msg.setPersistence(value);
      break;
    case 5:
      var value = /** @type {!proto.fox.proto.ActionError.Code} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPalletId(value);
      break;
    case 7:
      var value = /** @type {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>} */ (reader.readPackedEnum());
      msg.setPalletExceptionsList(value);
      break;
    case 8:
      var value = new proto.fox.proto.ActionError.BadForksPositionDetails;
      reader.readMessage(value,proto.fox.proto.ActionError.BadForksPositionDetails.deserializeBinaryFromReader);
      msg.setBadForksPositionDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ActionError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ActionError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ActionError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ActionError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPersistence();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPalletId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPalletExceptionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getBadForksPositionDetails();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.fox.proto.ActionError.BadForksPositionDetails.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.fox.proto.ActionError.Persistence = {
  PERSISTENCE_UNKNOWN: 0,
  PERSISTENCE_TRANSIENT: 1,
  PERSISTENCE_FATAL: 2
};

/**
 * @enum {number}
 */
proto.fox.proto.ActionError.Code = {
  CODE_OK: 0,
  CODE_PATH_BLOCKED: 1,
  CODE_PATH_PLANNING_FAILED: 2,
  CODE_NO_PALLET: 3,
  CODE_ZONE_NOT_READY: 4,
  CODE_BAD_CARRIED_PALLET: 5,
  CODE_PLACE_ZONE_FULL: 6,
  CODE_PLACE_LOCATION_BLOCKED: 7,
  CODE_NOT_PICKABLE: 8,
  CODE_PICK_ZONE_EMPTY: 9,
  CODE_LATTICE_PLANNER_FAILED: 10,
  CODE_RECOVERY_PLAN_FAILED: 11,
  CODE_BAD_FORKS_POSITION: 12,
  CODE_NO_FORKS_DETECTION: 13,
  CODE_MAST_PLANNING_FAILURE: 14,
  CODE_EXCEEDED_RECOVERY_ATTEMPTS: 15,
  CODE_EXCEEDED_MAX_PLACE_PLAN_RETRIES: 16,
  CODE_INVALID_OR_EMPTY_TRAJECTORY: 17,
  CODE_MULTI_SEGMENT_VALIDATION_FAILURE: 18,
  CODE_GOAL_BLOCKED: 19,
  CODE_START_BLOCKED: 20,
  CODE_INVALID_REQUEST: 21,
  AIRBAG_DETECTED: 22,
  PYRAMID_PALLET: 23,
  LEANING_PALLET: 24,
  UNPICKABLE_TAIL_PALLET: 25,
  TOO_TALL_PALLET: 26,
  CLOSE_TAIL_PALLET: 27
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.ActionError.BadForksPositionDetails.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ActionError.BadForksPositionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ActionError.BadForksPositionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ActionError.BadForksPositionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetPalletSupportType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    supportTypeV2: jspb.Message.getFieldWithDefault(msg, 4, 0),
    targetPalletPickFace: jspb.Message.getFieldWithDefault(msg, 2, 0),
    requiredForksPositionIndexList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ActionError.BadForksPositionDetails}
 */
proto.fox.proto.ActionError.BadForksPositionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ActionError.BadForksPositionDetails;
  return proto.fox.proto.ActionError.BadForksPositionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ActionError.BadForksPositionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ActionError.BadForksPositionDetails}
 */
proto.fox.proto.ActionError.BadForksPositionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.fox.proto.TrackedPallet.PalletSupportType} */ (reader.readEnum());
      msg.setTargetPalletSupportType(value);
      break;
    case 4:
      var value = /** @type {!proto.fox.proto.SupportType} */ (reader.readEnum());
      msg.setSupportTypeV2(value);
      break;
    case 2:
      var value = /** @type {!proto.fox.proto.PalletFace} */ (reader.readEnum());
      msg.setTargetPalletPickFace(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setRequiredForksPositionIndexList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ActionError.BadForksPositionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ActionError.BadForksPositionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ActionError.BadForksPositionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetPalletSupportType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSupportTypeV2();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTargetPalletPickFace();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRequiredForksPositionIndexList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional TrackedPallet.PalletSupportType target_pallet_support_type = 1;
 * @return {!proto.fox.proto.TrackedPallet.PalletSupportType}
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.getTargetPalletSupportType = function() {
  return /** @type {!proto.fox.proto.TrackedPallet.PalletSupportType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.fox.proto.TrackedPallet.PalletSupportType} value
 * @return {!proto.fox.proto.ActionError.BadForksPositionDetails} returns this
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.setTargetPalletSupportType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SupportType support_type_v2 = 4;
 * @return {!proto.fox.proto.SupportType}
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.getSupportTypeV2 = function() {
  return /** @type {!proto.fox.proto.SupportType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.SupportType} value
 * @return {!proto.fox.proto.ActionError.BadForksPositionDetails} returns this
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.setSupportTypeV2 = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PalletFace target_pallet_pick_face = 2;
 * @return {!proto.fox.proto.PalletFace}
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.getTargetPalletPickFace = function() {
  return /** @type {!proto.fox.proto.PalletFace} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.fox.proto.PalletFace} value
 * @return {!proto.fox.proto.ActionError.BadForksPositionDetails} returns this
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.setTargetPalletPickFace = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated int32 required_forks_position_index = 3;
 * @return {!Array<number>}
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.getRequiredForksPositionIndexList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.fox.proto.ActionError.BadForksPositionDetails} returns this
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.setRequiredForksPositionIndexList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.ActionError.BadForksPositionDetails} returns this
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.addRequiredForksPositionIndex = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ActionError.BadForksPositionDetails} returns this
 */
proto.fox.proto.ActionError.BadForksPositionDetails.prototype.clearRequiredForksPositionIndexList = function() {
  return this.setRequiredForksPositionIndexList([]);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fox.proto.ActionError.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ActionError} returns this
 */
proto.fox.proto.ActionError.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.fox.proto.ActionError.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ActionError} returns this
 */
proto.fox.proto.ActionError.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.fox.proto.ActionError.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ActionError} returns this
 */
proto.fox.proto.ActionError.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Persistence persistence = 4;
 * @return {!proto.fox.proto.ActionError.Persistence}
 */
proto.fox.proto.ActionError.prototype.getPersistence = function() {
  return /** @type {!proto.fox.proto.ActionError.Persistence} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.fox.proto.ActionError.Persistence} value
 * @return {!proto.fox.proto.ActionError} returns this
 */
proto.fox.proto.ActionError.prototype.setPersistence = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Code code = 5;
 * @return {!proto.fox.proto.ActionError.Code}
 */
proto.fox.proto.ActionError.prototype.getCode = function() {
  return /** @type {!proto.fox.proto.ActionError.Code} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.fox.proto.ActionError.Code} value
 * @return {!proto.fox.proto.ActionError} returns this
 */
proto.fox.proto.ActionError.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int32 pallet_id = 6;
 * @return {number}
 */
proto.fox.proto.ActionError.prototype.getPalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ActionError} returns this
 */
proto.fox.proto.ActionError.prototype.setPalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated TrackedPallet.PalletExceptionTypes pallet_exceptions = 7;
 * @return {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>}
 */
proto.fox.proto.ActionError.prototype.getPalletExceptionsList = function() {
  return /** @type {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.fox.proto.TrackedPallet.PalletExceptionTypes>} value
 * @return {!proto.fox.proto.ActionError} returns this
 */
proto.fox.proto.ActionError.prototype.setPalletExceptionsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.fox.proto.TrackedPallet.PalletExceptionTypes} value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.ActionError} returns this
 */
proto.fox.proto.ActionError.prototype.addPalletExceptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ActionError} returns this
 */
proto.fox.proto.ActionError.prototype.clearPalletExceptionsList = function() {
  return this.setPalletExceptionsList([]);
};


/**
 * optional BadForksPositionDetails bad_forks_position_details = 8;
 * @return {?proto.fox.proto.ActionError.BadForksPositionDetails}
 */
proto.fox.proto.ActionError.prototype.getBadForksPositionDetails = function() {
  return /** @type{?proto.fox.proto.ActionError.BadForksPositionDetails} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.ActionError.BadForksPositionDetails, 8));
};


/**
 * @param {?proto.fox.proto.ActionError.BadForksPositionDetails|undefined} value
 * @return {!proto.fox.proto.ActionError} returns this
*/
proto.fox.proto.ActionError.prototype.setBadForksPositionDetails = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ActionError} returns this
 */
proto.fox.proto.ActionError.prototype.clearBadForksPositionDetails = function() {
  return this.setBadForksPositionDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ActionError.prototype.hasBadForksPositionDetails = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fox.proto.ActionStatus.repeatedFields_ = [14,13];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fox.proto.ActionStatus.oneofGroups_ = [[2,3,4,6,7,9]];

/**
 * @enum {number}
 */
proto.fox.proto.ActionStatus.StatusCase = {
  STATUS_NOT_SET: 0,
  POSE_GOAL_STATUS: 2,
  OBJECT_NAVIGATION_GOAL_STATUS: 3,
  NAVIGATE_TO_PICK_STATUS: 4,
  NAVIGATE_TO_PLACE_STATUS: 6,
  PICK_PALLET_STATUS: 7,
  PLACE_PALLET_STATUS: 9
};

/**
 * @return {proto.fox.proto.ActionStatus.StatusCase}
 */
proto.fox.proto.ActionStatus.prototype.getStatusCase = function() {
  return /** @type {proto.fox.proto.ActionStatus.StatusCase} */(jspb.Message.computeOneofCase(this, proto.fox.proto.ActionStatus.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fox.proto.ActionStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.fox.proto.ActionStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fox.proto.ActionStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ActionStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    goalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetPalletId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    carriedPalletId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    carriageTCarriedPallet: (f = msg.getCarriageTCarriedPallet()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    trajectoryId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    mapTPalletPlace: (f = msg.getMapTPalletPlace()) && geometry_proto_transform_pb.Transform2f.toObject(includeInstance, f),
    poseGoalStatus: (f = msg.getPoseGoalStatus()) && proto.fox.proto.NavigationStatus.toObject(includeInstance, f),
    objectNavigationGoalStatus: (f = msg.getObjectNavigationGoalStatus()) && proto.fox.proto.NavigationStatus.toObject(includeInstance, f),
    navigateToPickStatus: (f = msg.getNavigateToPickStatus()) && proto.fox.proto.NavigateToPickStatus.toObject(includeInstance, f),
    navigateToPlaceStatus: (f = msg.getNavigateToPlaceStatus()) && proto.fox.proto.NavigateToPlaceStatus.toObject(includeInstance, f),
    pickPalletStatus: (f = msg.getPickPalletStatus()) && proto.fox.proto.PickPalletStatus.toObject(includeInstance, f),
    placePalletStatus: (f = msg.getPlacePalletStatus()) && proto.fox.proto.PlacePalletStatus.toObject(includeInstance, f),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.fox.proto.Action.toObject, includeInstance),
    errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
    proto.fox.proto.ActionError.toObject, includeInstance),
    targetPallet: (f = msg.getTargetPallet()) && detection_proto_detection_pb.TrackedPallet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fox.proto.ActionStatus}
 */
proto.fox.proto.ActionStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fox.proto.ActionStatus;
  return proto.fox.proto.ActionStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fox.proto.ActionStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fox.proto.ActionStatus}
 */
proto.fox.proto.ActionStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGoalId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetPalletId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCarriedPalletId(value);
      break;
    case 11:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setCarriageTCarriedPallet(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrajectoryId(value);
      break;
    case 12:
      var value = new geometry_proto_transform_pb.Transform2f;
      reader.readMessage(value,geometry_proto_transform_pb.Transform2f.deserializeBinaryFromReader);
      msg.setMapTPalletPlace(value);
      break;
    case 2:
      var value = new proto.fox.proto.NavigationStatus;
      reader.readMessage(value,proto.fox.proto.NavigationStatus.deserializeBinaryFromReader);
      msg.setPoseGoalStatus(value);
      break;
    case 3:
      var value = new proto.fox.proto.NavigationStatus;
      reader.readMessage(value,proto.fox.proto.NavigationStatus.deserializeBinaryFromReader);
      msg.setObjectNavigationGoalStatus(value);
      break;
    case 4:
      var value = new proto.fox.proto.NavigateToPickStatus;
      reader.readMessage(value,proto.fox.proto.NavigateToPickStatus.deserializeBinaryFromReader);
      msg.setNavigateToPickStatus(value);
      break;
    case 6:
      var value = new proto.fox.proto.NavigateToPlaceStatus;
      reader.readMessage(value,proto.fox.proto.NavigateToPlaceStatus.deserializeBinaryFromReader);
      msg.setNavigateToPlaceStatus(value);
      break;
    case 7:
      var value = new proto.fox.proto.PickPalletStatus;
      reader.readMessage(value,proto.fox.proto.PickPalletStatus.deserializeBinaryFromReader);
      msg.setPickPalletStatus(value);
      break;
    case 9:
      var value = new proto.fox.proto.PlacePalletStatus;
      reader.readMessage(value,proto.fox.proto.PlacePalletStatus.deserializeBinaryFromReader);
      msg.setPlacePalletStatus(value);
      break;
    case 14:
      var value = new proto.fox.proto.Action;
      reader.readMessage(value,proto.fox.proto.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 13:
      var value = new proto.fox.proto.ActionError;
      reader.readMessage(value,proto.fox.proto.ActionError.deserializeBinaryFromReader);
      msg.addErrors(value);
      break;
    case 15:
      var value = new detection_proto_detection_pb.TrackedPallet;
      reader.readMessage(value,detection_proto_detection_pb.TrackedPallet.deserializeBinaryFromReader);
      msg.setTargetPallet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fox.proto.ActionStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fox.proto.ActionStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fox.proto.ActionStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fox.proto.ActionStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetPalletId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCarriedPalletId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCarriageTCarriedPallet();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getTrajectoryId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getMapTPalletPlace();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      geometry_proto_transform_pb.Transform2f.serializeBinaryToWriter
    );
  }
  f = message.getPoseGoalStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.fox.proto.NavigationStatus.serializeBinaryToWriter
    );
  }
  f = message.getObjectNavigationGoalStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.fox.proto.NavigationStatus.serializeBinaryToWriter
    );
  }
  f = message.getNavigateToPickStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.fox.proto.NavigateToPickStatus.serializeBinaryToWriter
    );
  }
  f = message.getNavigateToPlaceStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.fox.proto.NavigateToPlaceStatus.serializeBinaryToWriter
    );
  }
  f = message.getPickPalletStatus();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.fox.proto.PickPalletStatus.serializeBinaryToWriter
    );
  }
  f = message.getPlacePalletStatus();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.fox.proto.PlacePalletStatus.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.fox.proto.Action.serializeBinaryToWriter
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.fox.proto.ActionError.serializeBinaryToWriter
    );
  }
  f = message.getTargetPallet();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      detection_proto_detection_pb.TrackedPallet.serializeBinaryToWriter
    );
  }
};


/**
 * optional string goal_id = 1;
 * @return {string}
 */
proto.fox.proto.ActionStatus.prototype.getGoalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.setGoalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 target_pallet_id = 5;
 * @return {number}
 */
proto.fox.proto.ActionStatus.prototype.getTargetPalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.setTargetPalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 carried_pallet_id = 10;
 * @return {number}
 */
proto.fox.proto.ActionStatus.prototype.getCarriedPalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.setCarriedPalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional Transform2f carriage_t_carried_pallet = 11;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.ActionStatus.prototype.getCarriageTCarriedPallet = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 11));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.ActionStatus} returns this
*/
proto.fox.proto.ActionStatus.prototype.setCarriageTCarriedPallet = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.clearCarriageTCarriedPallet = function() {
  return this.setCarriageTCarriedPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ActionStatus.prototype.hasCarriageTCarriedPallet = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 trajectory_id = 8;
 * @return {number}
 */
proto.fox.proto.ActionStatus.prototype.getTrajectoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.setTrajectoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Transform2f map_t_pallet_place = 12;
 * @return {?proto.fox.proto.Transform2f}
 */
proto.fox.proto.ActionStatus.prototype.getMapTPalletPlace = function() {
  return /** @type{?proto.fox.proto.Transform2f} */ (
    jspb.Message.getWrapperField(this, geometry_proto_transform_pb.Transform2f, 12));
};


/**
 * @param {?proto.fox.proto.Transform2f|undefined} value
 * @return {!proto.fox.proto.ActionStatus} returns this
*/
proto.fox.proto.ActionStatus.prototype.setMapTPalletPlace = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.clearMapTPalletPlace = function() {
  return this.setMapTPalletPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ActionStatus.prototype.hasMapTPalletPlace = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NavigationStatus pose_goal_status = 2;
 * @return {?proto.fox.proto.NavigationStatus}
 */
proto.fox.proto.ActionStatus.prototype.getPoseGoalStatus = function() {
  return /** @type{?proto.fox.proto.NavigationStatus} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.NavigationStatus, 2));
};


/**
 * @param {?proto.fox.proto.NavigationStatus|undefined} value
 * @return {!proto.fox.proto.ActionStatus} returns this
*/
proto.fox.proto.ActionStatus.prototype.setPoseGoalStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.fox.proto.ActionStatus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.clearPoseGoalStatus = function() {
  return this.setPoseGoalStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ActionStatus.prototype.hasPoseGoalStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NavigationStatus object_navigation_goal_status = 3;
 * @return {?proto.fox.proto.NavigationStatus}
 */
proto.fox.proto.ActionStatus.prototype.getObjectNavigationGoalStatus = function() {
  return /** @type{?proto.fox.proto.NavigationStatus} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.NavigationStatus, 3));
};


/**
 * @param {?proto.fox.proto.NavigationStatus|undefined} value
 * @return {!proto.fox.proto.ActionStatus} returns this
*/
proto.fox.proto.ActionStatus.prototype.setObjectNavigationGoalStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.fox.proto.ActionStatus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.clearObjectNavigationGoalStatus = function() {
  return this.setObjectNavigationGoalStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ActionStatus.prototype.hasObjectNavigationGoalStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NavigateToPickStatus navigate_to_pick_status = 4;
 * @return {?proto.fox.proto.NavigateToPickStatus}
 */
proto.fox.proto.ActionStatus.prototype.getNavigateToPickStatus = function() {
  return /** @type{?proto.fox.proto.NavigateToPickStatus} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.NavigateToPickStatus, 4));
};


/**
 * @param {?proto.fox.proto.NavigateToPickStatus|undefined} value
 * @return {!proto.fox.proto.ActionStatus} returns this
*/
proto.fox.proto.ActionStatus.prototype.setNavigateToPickStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.fox.proto.ActionStatus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.clearNavigateToPickStatus = function() {
  return this.setNavigateToPickStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ActionStatus.prototype.hasNavigateToPickStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NavigateToPlaceStatus navigate_to_place_status = 6;
 * @return {?proto.fox.proto.NavigateToPlaceStatus}
 */
proto.fox.proto.ActionStatus.prototype.getNavigateToPlaceStatus = function() {
  return /** @type{?proto.fox.proto.NavigateToPlaceStatus} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.NavigateToPlaceStatus, 6));
};


/**
 * @param {?proto.fox.proto.NavigateToPlaceStatus|undefined} value
 * @return {!proto.fox.proto.ActionStatus} returns this
*/
proto.fox.proto.ActionStatus.prototype.setNavigateToPlaceStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.fox.proto.ActionStatus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.clearNavigateToPlaceStatus = function() {
  return this.setNavigateToPlaceStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ActionStatus.prototype.hasNavigateToPlaceStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PickPalletStatus pick_pallet_status = 7;
 * @return {?proto.fox.proto.PickPalletStatus}
 */
proto.fox.proto.ActionStatus.prototype.getPickPalletStatus = function() {
  return /** @type{?proto.fox.proto.PickPalletStatus} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PickPalletStatus, 7));
};


/**
 * @param {?proto.fox.proto.PickPalletStatus|undefined} value
 * @return {!proto.fox.proto.ActionStatus} returns this
*/
proto.fox.proto.ActionStatus.prototype.setPickPalletStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.fox.proto.ActionStatus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.clearPickPalletStatus = function() {
  return this.setPickPalletStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ActionStatus.prototype.hasPickPalletStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PlacePalletStatus place_pallet_status = 9;
 * @return {?proto.fox.proto.PlacePalletStatus}
 */
proto.fox.proto.ActionStatus.prototype.getPlacePalletStatus = function() {
  return /** @type{?proto.fox.proto.PlacePalletStatus} */ (
    jspb.Message.getWrapperField(this, proto.fox.proto.PlacePalletStatus, 9));
};


/**
 * @param {?proto.fox.proto.PlacePalletStatus|undefined} value
 * @return {!proto.fox.proto.ActionStatus} returns this
*/
proto.fox.proto.ActionStatus.prototype.setPlacePalletStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.fox.proto.ActionStatus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.clearPlacePalletStatus = function() {
  return this.setPlacePalletStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ActionStatus.prototype.hasPlacePalletStatus = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated Action actions = 14;
 * @return {!Array<!proto.fox.proto.Action>}
 */
proto.fox.proto.ActionStatus.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.fox.proto.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.Action, 14));
};


/**
 * @param {!Array<!proto.fox.proto.Action>} value
 * @return {!proto.fox.proto.ActionStatus} returns this
*/
proto.fox.proto.ActionStatus.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.fox.proto.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.Action}
 */
proto.fox.proto.ActionStatus.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.fox.proto.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * repeated ActionError errors = 13;
 * @return {!Array<!proto.fox.proto.ActionError>}
 */
proto.fox.proto.ActionStatus.prototype.getErrorsList = function() {
  return /** @type{!Array<!proto.fox.proto.ActionError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fox.proto.ActionError, 13));
};


/**
 * @param {!Array<!proto.fox.proto.ActionError>} value
 * @return {!proto.fox.proto.ActionStatus} returns this
*/
proto.fox.proto.ActionStatus.prototype.setErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.fox.proto.ActionError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fox.proto.ActionError}
 */
proto.fox.proto.ActionStatus.prototype.addErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.fox.proto.ActionError, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};


/**
 * optional TrackedPallet target_pallet = 15;
 * @return {?proto.fox.proto.TrackedPallet}
 */
proto.fox.proto.ActionStatus.prototype.getTargetPallet = function() {
  return /** @type{?proto.fox.proto.TrackedPallet} */ (
    jspb.Message.getWrapperField(this, detection_proto_detection_pb.TrackedPallet, 15));
};


/**
 * @param {?proto.fox.proto.TrackedPallet|undefined} value
 * @return {!proto.fox.proto.ActionStatus} returns this
*/
proto.fox.proto.ActionStatus.prototype.setTargetPallet = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fox.proto.ActionStatus} returns this
 */
proto.fox.proto.ActionStatus.prototype.clearTargetPallet = function() {
  return this.setTargetPallet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fox.proto.ActionStatus.prototype.hasTargetPallet = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * @enum {number}
 */
proto.fox.proto.ActionPlanner = {
  UNKNOWN_PLANNER: 0,
  PICK_PLANNER: 1,
  PLACE_PLANNER: 2,
  NAVIGATE_TO_POSE_PLANNER: 3,
  TRAILER_SCAN_PLANNER: 4,
  TRAILER_EXIT_PLANNER: 5,
  WAREHOUSE_PICK_PLANNER: 6,
  WAREHOUSE_SCAN_PLANNER: 11,
  TRAILER_PLACE_PLANNER: 7,
  GRACEFUL_STOP_PLANNER: 8,
  AOB_SCAN_PLANNER: 9,
  CONVEYOR_PICK_PLANNER: 10,
  CONVEYOR_SCAN_PLANNER: 12
};

goog.object.extend(exports, proto.fox.proto);
