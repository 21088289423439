import {connect} from "react-redux";
import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Chip} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import {createBulkLogUpload} from "../../redux/actions";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";

interface Props {
  open: boolean;
  dispatch: any;
  onClose: () => void;
}

const LogUploadsRequestDialog = (props: Props) => {

    const _submit = () => {
        if (runNames) {
            props.dispatch(createBulkLogUpload(runNames))
                .then((resp: m_pb.BulkLogUpload.AsObject) => {
                    setRunNames([]);
            });
        }
    };

    const [runNamesTextInput, setRunNamesTextInput] = useState<string>();
    const [runNames, setRunNames] = useState<string[]>([]);

    return <React.Fragment>
        <Dialog
            open={props.open}
            onClose={() => props.onClose && props.onClose()}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Request Bulk Run Log Uploads</DialogTitle>
            <DialogContent>
                <div>
                    <div>Upload logs from runs</div>
                    {runNames && runNames.map(runName => {
                        return <Chip
                            label={runName}
                            onDelete={() => {
                                setRunNames(runNames.filter(run => run !== runName))
                            }}
                        />
                    })}
                    <div>
                        <TextField
                            label="run names"
                            placeholder="insert comma-separated run names"
                            onBlur={(event: React.ChangeEvent<{ value: string }>) => {
                              setRunNames((runNames || []).concat(event.target.value.split(",").map(run_name => run_name.trim()).filter(run => !!run)));
                              setRunNamesTextInput("");
                            }}
                            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                              setRunNamesTextInput(event.target.value);
                            }}
                            value={runNamesTextInput}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={() => _submit()}
                    color="primary"
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>

};

export default connect()(LogUploadsRequestDialog);