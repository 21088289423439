import React, { Component } from "react";

import { connect } from "react-redux";

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ProgressSpinner from "../Utils/ProgressSpinner";
import EventFilterSearchBar from "../Utils/EventFilterSearchBar";
import m_pb from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import {
  listFaultCodesRequest,
  listFaultsEventFilterRequest,
} from "../../redux/actions";
import { combineStyles, commonStyles } from "../Utils/CommonStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FaultsSheet from "../Sheets/FaultsSheet";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import { logInPath } from "../../utils/Paths";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      height: "100%",
      padding: 0,
      paddingBottom: 16,
      display: "flex",
      flexDirection: "column"
    }
  });

const FAULT_SEVERITIES = [
  "FAULT_SEVERITY_CLEAR",
  "FAULT_SEVERITY_INFO",
  "FAULT_SEVERITY_WARNING",
  "FAULT_SEVERITY_ERROR",
  "FAULT_SEVERITY_FATAL"
];

const FAULT_TYPES = [
  "planning_fault",
  "validation_fault",
  "localization_fault",
  "controls_fault",
  "hardware_fault",
  "perception_fault",
  "sensor_fault",
  "human_assistance_request"
];

interface Props extends WithStyles<typeof styles> {
  dispatch: any;
}

interface State {
  isLoading: boolean;
  // TODO(chris): maybe drop fault codes in redux store?
  faultCodes: Array<string>;
  searchFaultCodes: Array<string>;
  searchFaultTypes: Array<string>;
  searchFaultSeverities: Array<string>;
  resultsLimit: number;
  faults: Array<m_pb.Fault.AsObject>;
  eventFilter: m_pb.EventFilter;
  error: string;
  includeExtraFields: boolean;
}

class ListFaultsPage extends Component<Props, State> {
  state: State = {
    isLoading: false,
    faultCodes: [],
    faults: [],
    eventFilter: new m_pb.EventFilter(),
    searchFaultCodes: [],
    searchFaultTypes: [],
    searchFaultSeverities: [],
    resultsLimit: 10000,
    error: ""
  };

  _fetchMore(nextPageToken = "", newFetch = true) {
    const {
      eventFilter,
      searchFaultCodes,
      searchFaultTypes,
      searchFaultSeverities,
      resultsLimit
    } = this.state;
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.props
          .dispatch(
            listFaultsEventFilterRequest(
              eventFilter,
              searchFaultCodes,
              searchFaultTypes,
              searchFaultSeverities,
              nextPageToken,
              this.state.includeExtraFields
            )
          )
          .then((res: m_pb.ListFaultsResponse.AsObject) => {
            const faults = newFetch
              ? res.faultsList
              : this.state.faults.concat(res.faultsList);
            if (res.nextPageToken && faults.length < resultsLimit) {
              this.setState({ faults: faults, isLoading: true });
              this._fetchMore(res.nextPageToken, false);
            } else {
              this.setState({ faults: faults, isLoading: false });
            }
          })
          .catch((e: ServiceError) => {
            this.setState({ isLoading: false, error: e.message });
          });
      }
    );
  }

  _fetchFaultTypes() {
    this.setState({ isLoading: true }, () => {
      this.props
        .dispatch(listFaultCodesRequest())
        .then((res: m_pb.ListFaultCodesResponse.AsObject) => {
          this.setState({ faultCodes: res.faultCodesList, isLoading: false });
        });
    });
  }

  componentDidMount() {
    this._fetchFaultTypes();
  }

  render() {
    const { classes } = this.props;
    const { isLoading, faults, includeExtraFields } = this.state;
    const progressSpinner = isLoading ? <ProgressSpinner /> : null;
    const sheet = faults.length ? (
      <FaultsSheet stats={faults} loading={isLoading} />
    ) : null;
    return (
      <div className={classes.wrapper}>
        <EventFilterSearchBar disable={isLoading} title={"Fault Stats"}
          onRequestSubmit={f =>
            this.setState({ eventFilter: f })
          }
          hideSubmitButton={true}
        />
        <AppBar position={"static"} color={"default"}>
          <Toolbar>
            <Autocomplete
              multiple
              id="filters-autocomplete"
              options={this.state.faultCodes}
              onChange={(event: any, newValues: Array<string> | null) => {
                this.setState({ searchFaultCodes: newValues || [] });
              }}
              value={this.state.searchFaultCodes}
              style={{
                minWidth: 250,
                height: 56,
                margin: 6,
                display: "inline"
              }}
              renderInput={params => (
                <TextField {...params} label="Search for faults with code(s)" />
              )}
            />
            <Autocomplete
              multiple
              id="fault-types-autocomplete"
              options={FAULT_TYPES}
              onChange={(event: any, newValues: Array<string> | null) => {
                this.setState({ searchFaultTypes: newValues || [] });
              }}
              value={this.state.searchFaultTypes}
              style={{
                minWidth: 250,
                height: 56,
                margin: 6,
                display: "inline"
              }}
              renderInput={params => (
                <TextField {...params} label="Search for faults of type(s)" />
              )}
            />
            <Autocomplete
              multiple
              id="fault-severity-autocomplete"
              options={FAULT_SEVERITIES}
              onChange={(event: any, newValues: Array<string> | null) => {
                this.setState({ searchFaultSeverities: newValues || [] });
              }}
              value={this.state.searchFaultSeverities}
              style={{
                minWidth: 250,
                height: 56,
                margin: 6,
                display: "inline"
              }}
              renderInput={params => (
                <TextField {...params} label="Search for faults of severity" />
              )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeExtraFields}
                  onChange={e =>
                    this.setState({ includeExtraFields: e.target.checked })
                  }
                />
              }
              label="Include Region and Action (will take longer to load)"
            />
            <Button
              color={"secondary"}
              size={"small"}
              variant={"contained"}
              onClick={() => this._fetchMore()}
            >
              Submit
            </Button>
          </Toolbar>
        </AppBar>
        {progressSpinner}
        {sheet}
      </div>
    );
  }
}

export default connect()(withStyles(styles)(ListFaultsPage));
