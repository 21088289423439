import React, {Component} from "react";
import {connect} from "react-redux";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import {Button, Dialog, DialogTitle, FormControl, TextField} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckIcon from "@material-ui/icons/Check";
import Typography from "@material-ui/core/Typography";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import {enc} from "crypto-js";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import {ApplicationState} from "../../redux";
import {batchCreateConfigurationFileRevisionsRequest} from "../../redux/actions";
import m_pb, {RevisionAcceptancePolicy} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import {ServiceError} from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import {isEmpty} from "lodash";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (_theme: Theme) =>
  createStyles({
    modalContentContainer: {
      padding: 20,
      flexDirection: "column",
      display: "flex"
    },
    modalTitle: {
      textAlign: "center"
    },
    filePathContainer: {
      display: "flex",
      alignItems: "baseline",
      minWidth: 150
    }
  });

const mapStateToProps = (_state: ApplicationState) => {
  return {};
};

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  onModalClose: () => void;
  onSuccess: () => void;
  dispatch: any;
  entityType: string;
  entityIdentifier: string;
  // Props to support new revisions on existing config files
  configId?: string;
  filepath?: string;
  stringData?: string;
  parentTimestamps?: string[];
}
interface State {
  filepath: string;
  description: string;
  fileContentMechanism: string;
  fileData: ArrayBuffer | null;
  stringData: string;
  errorMessage: string;
  localFileName: string | null;
  scope: string;
  acceptancePolicy: RevisionAcceptancePolicy
}
// These interfaces make typescript happy with the reader load event
interface FileReaderEventTarget extends EventTarget {
  result?: ArrayBuffer;
}

interface FileReaderEvent extends ProgressEvent {
  target: FileReaderEventTarget | null;
}

const scopeErrorMsg =
  "Modal did not capture scope. Please reload page and try again.";

class CreateConfigFileModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // Saved filepaths are prefixed by e.g. robot/${user-typed-path}, so we trim here to avoid duplication
    const trimmedFilepath = props.filepath
      ? props.filepath.split("/").slice(1).join("/")
      : "";
    this.state = {
      filepath: trimmedFilepath,
      description: "",
      fileContentMechanism: props.stringData ? "type" : "",
      stringData: props.stringData ? props.stringData.replace(/\n$/, "") : "",
      fileData: null,
      errorMessage: "",
      localFileName: null,
      scope: "",
      acceptancePolicy: RevisionAcceptancePolicy.REVISION_ACCEPTANCE_POLICY_BEST_EFFORT
    };
  }

  componentDidMount() {
    const { entityType, filepath } = this.props;
    if (isEmpty(entityType) && filepath && !isEmpty(filepath)) {
      // no entityType, use filepath
      const fileScope = filepath.split("/")[0];
      if (isEmpty(fileScope)) {
        // scope not passed in entityType nor filepath
        this.setState({
          errorMessage: scopeErrorMsg
        });
      } else {
        this.setState({
          scope: fileScope
        });
      }
    } else if (isEmpty(entityType)) {
      // no entityType nor filepath
      this.setState({
        errorMessage: scopeErrorMsg
      });
    } else {
      // YES has entity type
      this.setState({
        scope: entityType
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { entityType, filepath } = this.props;
    const { errorMessage } = this.state;
    // if scope changed
    if (
      entityType !== prevProps.entityType ||
      filepath !== prevProps.filepath
    ) {
      if (isEmpty(entityType) && filepath && !isEmpty(filepath)) {
        // no entityType, use filepath
        const fileScope = filepath.split("/")[0];
        if (isEmpty(fileScope) && !errorMessage.includes(scopeErrorMsg)) {
          // scope not passed in entityType nor filepath
          this.setState({
            errorMessage: errorMessage.concat("\n", scopeErrorMsg)
          });
        } else {
          this.setState({
            scope: fileScope
          });
        }
      } else if (isEmpty(entityType) && !errorMessage.includes(scopeErrorMsg)) {
        // no entityType nor filepath
        this.setState({
          errorMessage: errorMessage.concat("\n", scopeErrorMsg)
        });
      } else {
        // YES has entity type
        this.setState({
          errorMessage: "",
          scope: entityType
        });
      }
    }
  }

  _readFileDataAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // @ts-ignore
      reader.onload = (event: FileReaderEvent) => {
        if (event && event.target && event.target.result) {
          resolve(event.target.result);
        } else {
          reject();
        }
      };

      reader.onerror = err => {
        reject(err);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  _isValidFilePath = (filepath: string) => {
    // Allow any number of alphanumeric characters, underscores, .'s, and /'s
    return filepath.length > 0 && filepath.match("^[a-zA-Z0-9_/.]*$");
  };

  _submit = () => {
    const { entityType, entityIdentifier, configId, parentTimestamps } =
      this.props;
    const configurationFileRevision = new m_pb.ConfigurationFileRevision();
    const revisionMetadata = new m_pb.ConfigurationRevisionMetadata();
    if (configId) {
      revisionMetadata.setConfigId(configId);
    }
    if (parentTimestamps) {
      revisionMetadata.setParentTimestampsList(parentTimestamps);
    } else {
      // Convention for new config files is empty list for parent timestamp
      revisionMetadata.setParentTimestampsList([]);
    }
    revisionMetadata.setAcceptancePolicy(this.state.acceptancePolicy);
    // Set cohortId or robot name based on the entity it's being created for
    if (entityType === "robot") {
      revisionMetadata.setRobotName(entityIdentifier);
    } else if (entityType === "cohort") {
      revisionMetadata.setCohortId(entityIdentifier);
    }
    revisionMetadata.setDescription(this.state.description);
    revisionMetadata.setPath(entityType + "/" + this.state.filepath);
    if (this.state.fileContentMechanism === "upload" && this.state.fileData) {
      configurationFileRevision.setContents(
        new Uint8Array(this.state.fileData)
      );
      const wordArray = enc.Base64.parse(
        configurationFileRevision.getContents_asB64()
      );
      revisionMetadata.setChecksumSha256(Base64.stringify(sha256(wordArray)));
    } else if (this.state.fileContentMechanism === "type") {
      // Add a newline character since unix files have a trailing newline by convention
      const base64EncFileData = window.btoa(this.state.stringData + "\n");
      // Javascript setter for a proto bytes type expects base64 encoded data
      configurationFileRevision.setContents(base64EncFileData);
      revisionMetadata.setChecksumSha256(
        Base64.stringify(sha256(this.state.stringData + "\n"))
      );
    } else {
      console.log("No data to upload");
      return;
    }

    configurationFileRevision.setMetadata(revisionMetadata);
    this.props
      .dispatch(
        batchCreateConfigurationFileRevisionsRequest([
          configurationFileRevision
        ])
      )
      .then(() => {
        this.props.onSuccess();
      })
      .catch((e: ServiceError) => {
        this.setState({ errorMessage: e.message });
      });
  };

  _showError(errorMessage: string) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Error creating config file — {this.state.errorMessage}
        {!isEmpty(errorMessage) ? ` — ${errorMessage}` : ""}
      </Alert>
    );
  }

  render() {
    const { open, classes, onModalClose } = this.props;
    const { fileContentMechanism, errorMessage } = this.state;
    const onFileContentOptionChange = (
      event: React.ChangeEvent<{ value: string }>
    ) => {
      this.setState({ fileContentMechanism: event.target.value });
    };
    const imageUploaded = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        console.log("No files uploaded");
        return null;
      }
      const file = event.target.files[0];
      this._readFileDataAsArrayBuffer(file).then((result: ArrayBuffer) => {
        this.setState({ fileData: result, localFileName: file.name });
      });
    };
    const fileContentTyped = (event: React.ChangeEvent<{ value: string }>) => {
      this.setState({ stringData: event.target.value });
    };

    // TODO: add a preview for uploaded files
    return (
      <Dialog
        onClose={onModalClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="sm"
        disableBackdropClick={true}
        fullWidth={true}
      >
        <DialogTitle className={classes.modalTitle}>
          Create Config File
        </DialogTitle>
        {errorMessage && this._showError(errorMessage)}
        <DialogContent className={classes.modalContentContainer}>
          <FormControl>
            <div className={classes.filePathContainer}>
              <Typography
                variant="body1"
                display="inline"
                color="textSecondary"
              >
                /{this.state.scope}/
              </Typography>
              <TextField
                disabled={!!this.props.filepath}
                label="file path"
                onChange={(event: React.ChangeEvent<{ value: string }>) => {
                  this.setState({ filepath: event.target.value });
                }}
                value={this.state.filepath}
              />
            </div>
            <TextField
              label="description (optional)"
              variant="outlined"
              multiline
              rows={3}
              onChange={event => {
                this.setState({ description: event.target.value });
              }}
            />
            <Select
              id="acceptance-policy-select"
              value={this.state.acceptancePolicy}
              onChange={(e) => {this.setState({acceptancePolicy: e.target.value as RevisionAcceptancePolicy})}}
            >
              <MenuItem value={m_pb.RevisionAcceptancePolicy.REVISION_ACCEPTANCE_POLICY_BEST_EFFORT}>Normal</MenuItem>
              <MenuItem value={m_pb.RevisionAcceptancePolicy.REVISION_ACCEPTANCE_POLICY_FORCE}>Force Apply</MenuItem>
            </Select>
            <FormLabel component="legend">
              Select an option to provide file content
            </FormLabel>
            <RadioGroup
              value={fileContentMechanism}
              onChange={onFileContentOptionChange}
            >
              <FormControlLabel
                value="upload"
                control={<Radio />}
                label="Upload a file"
              />
              <FormControlLabel
                value="type"
                control={<Radio />}
                label="Type File Content"
              />
            </RadioGroup>
            {fileContentMechanism === "upload" && (
              <>
                <Button variant="contained" component="label">
                  {this.state.fileData ? "Change File" : "Select File"}
                  <input type="file" hidden onChange={imageUploaded} />
                </Button>
                {this.state.fileData && (
                  <Typography variant="body2">
                    <CheckIcon />
                    {this.state.localFileName} selected
                  </Typography>
                )}
              </>
            )}
            {fileContentMechanism === "type" && (
              <TextField
                label="File contents"
                variant="outlined"
                multiline
                onChange={fileContentTyped}
                value={this.state.stringData}
              />
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onModalClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={this._submit}
            disabled={
              !this._isValidFilePath(this.state.filepath) ||
              (!this.state.fileData && !this.state.stringData)
            }
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(CreateConfigFileModal)
);
