import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import ConfigurationFileSystemView from "./ConfigurationFileSystemView";
import {
  ListConfigurationRevisionHistoryRequest,
  ConfigurationRevisionHistory as ConfigurationRevisionHistoryProto,
  RobotConfigurationRevisionState
} from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { listConfigurationRevisionHistoryRequest } from "../../redux/actions";
import { ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import ProgressSpinner from "../Utils/ProgressSpinner";
import ConfigurationRevisionHistory from "./ConfigurationRevisionHistory";
import { combineStyles, commonStyles } from "../Utils/CommonStyles";
import { ContactSupportOutlined } from "@material-ui/icons";
import { isEmpty } from "lodash";

const localStyles = (theme: Theme) => createStyles({});
const styles = combineStyles(localStyles, commonStyles);

const mapDispatchToProps = (
  dispatch: (arg0: {
    type: string;
    payload: any;
    error?: boolean | undefined;
    meta: any;
  }) => any
) => {
  return {
    listHistoryRequest: (value: ListConfigurationRevisionHistoryRequest) =>
      dispatch(listConfigurationRevisionHistoryRequest(value))
  };
};
interface Props extends WithStyles<typeof styles> {
  robotName?: string;
  cohortId?: string | null;
  displayName: string;
  updatePath: any;
  isRobot: boolean;
  isCohort: boolean;
  allowCreateFile: boolean;
  entityRevisionHistory: string;
  alertError: any;
  cohort: any;
  listHistoryRequest: any;
}
interface ConfigFileMeta {
  filePath: string;
  configId: string;
  activeRevisionId: string;
}
interface State {
  // for Robot Config Page
  activeFiles: ConfigFileMeta[];
  deletedFiles: ConfigFileMeta[];
  selectedFileEntity: string;
  revisionHistories: Map<string, ConfigurationRevisionHistoryProto>;
  selectedFileId: string;
  activeRevisionsByConfigId: Map<string, RobotConfigurationRevisionState[]>;
  isLoading: boolean;
}
function ConfigurationFiles(props: Props) {
  const {
    classes,
    robotName,
    cohortId,
    displayName,
    updatePath,
    isRobot,
    isCohort,
    allowCreateFile,
    entityRevisionHistory,
    listHistoryRequest,
    alertError,
    cohort
  } = props;

  // robot configs
  const [activeFiles, setActiveFiles] = useState<ConfigFileMeta[]>([]);
  const [deletedFiles, setDeletedFiles] = useState<ConfigFileMeta[]>([]);
  const [selectedFileEntity, setSelectedFileEntity] = useState("");
  // TODO(emily) or entityRevisionHistory?
  const [selectedFileId, setSelectedFileId] = useState(entityRevisionHistory);
  const [isLoading, setIsLoading] = useState(false);
  const [revisionHistories, setRevisionHistories] = useState(new Map());
  const [activeRevisionsByConfigId, setActiveRevisionsByConfigId] = useState(
    new Map()
  );

  // new entity selected
  useEffect(() => {
    // reset states
    setSelectedFileId("");
    setSelectedFileEntity("");
    setDeletedFiles([]);
    setActiveFiles([]);
    setRevisionHistories(new Map());
    setActiveRevisionsByConfigId(new Map());
    // get data
    _loadData();
  }, [displayName]);

  useEffect(() => {
    setSelectedFileId(entityRevisionHistory);
  }, [entityRevisionHistory]);

  function _reloadData() {
    _loadData();
  }

  function _loadData() {
    setIsLoading(true);
    let configHistoryReq;
    if (cohortId && isCohort) {
      configHistoryReq = new ListConfigurationRevisionHistoryRequest();
      configHistoryReq.setCohortId(cohortId);
    } else if (isRobot) {
      configHistoryReq = new ListConfigurationRevisionHistoryRequest();
      configHistoryReq.setRobotName(displayName || robotName || "");
    }
    if (
      configHistoryReq &&
      (configHistoryReq.hasRobotName() || configHistoryReq.hasCohortId())
    ) {
      listHistoryRequest(configHistoryReq)
        .then((payload: ConfigurationRevisionHistoryProto[]) => {
          const activeConfigFiles: ConfigFileMeta[] = [];
          const deletedConfigFiles: ConfigFileMeta[] = [];
          const revisionHistoriesMap = new Map<
            string,
            ConfigurationRevisionHistoryProto
          >();
          const activeRevisionsByConfigIdMap = new Map<
            string,
            RobotConfigurationRevisionState[]
          >(activeRevisionsByConfigId);
          payload.forEach(configRevisionHistory => {
            if (
              configRevisionHistory.getRevisionsList() &&
              configRevisionHistory.getRevisionsList().length
            ) {
              revisionHistoriesMap.set(
                configRevisionHistory.getConfigId(),
                configRevisionHistory
              );
              activeRevisionsByConfigIdMap.set(
                configRevisionHistory.getConfigId(),
                configRevisionHistory.getRobotStatusesList()
              );
              const latestRevision =
                configRevisionHistory.getRevisionsList()[0];
              if (latestRevision.getIsDeleted()) {
                deletedConfigFiles.push({
                  configId: configRevisionHistory.getConfigId(),
                  filePath: latestRevision.getPath(),
                  activeRevisionId: latestRevision.getRevisionId()
                });
              } else {
                // TODO: handle past renames for more convenient display
                activeConfigFiles.push({
                  configId: configRevisionHistory.getConfigId(),
                  filePath: latestRevision.getPath(),
                  activeRevisionId: latestRevision.getRevisionId()
                });
              }
            }
          });
          setActiveFiles(activeConfigFiles);
          setDeletedFiles(deletedConfigFiles);
          setRevisionHistories(revisionHistoriesMap);
          setActiveRevisionsByConfigId(activeRevisionsByConfigIdMap);
          setIsLoading(false);
        })
        .catch((e: ServiceError) => {
          console.error(e);
          setIsLoading(false);
          alertError(e);
        });
    } else {
      const error = `Unable to send request for revision history with parameters: 
       ${configHistoryReq && configHistoryReq.toString()};`;
      console.error(error);
      setIsLoading(false);
      alertError(error);
    }
  }

  function renderConfigurationRevisionHistoryList(
    selectedCohortFileId: string
  ) {
    let revisionHistory: ConfigurationRevisionHistoryProto;
    if (revisionHistories.has(selectedCohortFileId)) {
      revisionHistory = revisionHistories.get(
        selectedCohortFileId
      ) as ConfigurationRevisionHistoryProto;
    } else if (isEmpty(revisionHistories)) {
      return null;
    } else {
      const msg = `Unable to load revision history for nonexistent fileId: ${selectedCohortFileId}${
        displayName ? ` for ${displayName}` : ""
      }`;
      console.error(msg);
      alertError(msg);
      return null;
    }

    const activeRevisions = activeRevisionsByConfigId.get(selectedCohortFileId);

    const configurationRevisionHistoryProps = {
      description: revisionHistory.getDescription(),
      revisions: revisionHistory.getRevisionsList(),
      configId: selectedCohortFileId,
      entityType: selectedFileEntity, // "cohort"
      entityIdentifier: robotName || cohortId || "",
      reloadData: () => _reloadData(),
      activeRevisions: activeRevisions || [],
      alertError,
      entityCount: isCohort ? cohort.robotNamesList.length : 1,
      allowCreateFile
    };

    return (
      <ConfigurationRevisionHistory {...configurationRevisionHistoryProps} />
    );
  }

  function onFileClick(entityType: string, configId: string) {
    setSelectedFileId(configId);
    setSelectedFileEntity(entityType);

    // update url
    const entryName = allowCreateFile
      ? "entityRevisionHistory"
      : "comparisonRevisionHistory";
    updatePath(entryName, configId);
  }

  function _getFileSystemView() {
    const name = isCohort ? "cohort" : "robot";
    const fileSystemProps = {
      entityType: name,
      entityIdentifier: isCohort && cohortId ? cohortId : displayName,
      entityCount: isCohort ? cohort.robotNamesList.length : 1,
      onFileClick: (cohortId: string) => onFileClick(name, cohortId),
      activeFiles: activeFiles,
      deletedFiles: deletedFiles,
      reloadData: () => _reloadData(),
      updatePath,
      activeRevisionsByConfigId,
      selectedFileConfigId: selectedFileId || entityRevisionHistory,
      allowCreateFile
    };
    return <ConfigurationFileSystemView {...fileSystemProps} />;
  }
  return (
    <>
      {/* ROBOT DATA */}
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <>
          <div className={classes.fileSystemWrapper}>
            {_getFileSystemView()}
          </div>
          <div className={classes.historyWrapper}>
            {selectedFileId &&
              renderConfigurationRevisionHistoryList(selectedFileId)}
          </div>
        </>
      )}
    </>
  );
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ConfigurationFiles));
