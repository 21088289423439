import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";

interface Props {
  disabled: boolean;
  className: string;
  color: "inherit" | "primary" | "secondary" | "default";
  onload: (fileData: string, localFileName: string) => void;
  label: string;
}

const handleUpload = (onload: (fileData: string, localFileName: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
  if (!event.target.files) {
    console.log("No files uploaded");
    return null;
  }
  const file = event.target.files[0];
  readFileDataAsArrayBuffer(file).then((result: string) => {
    onload(result, file.name);
  });
};

const readFileDataAsArrayBuffer = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      // @ts-ignore
      reader.onload = (event: FileReaderEvent) => {
        if (event && event.target && event.target.result) {
          resolve(event.target.result);
        } else {
          reject();
        }
      };
      reader.onerror = err => {
        reject(err);
      };
      reader.readAsText(file);
    });
  };


const FileUploadButton = (props: Props) => (
  <Button
    disabled={props.disabled}
    className={props.className}
    variant="outlined"
    component="label"
    color={props.color}
  >
    {props.label}
    <input type="file" hidden onChange={handleUpload(props.onload)} value={""} />
  </Button>
);

export default connect()(FileUploadButton);
