import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { MonitoringClient, ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";

import { BrowserHeaders } from "browser-headers";
import { NormalizedEntities, upsertEntity } from '../normalizedEntities'
import { ForkliftCohort, ListForkliftCohortsRequest, ListForkliftCohortsResponse } from '../../_proto/command_control/monitoring/proto/monitoring_pb';

const authTokenName = "monitoringJwtToken";

function authHeader(): BrowserHeaders {
  const token: string | null = localStorage.getItem(authTokenName);
  if (!token) {
    return new BrowserHeaders();
  }
  return new BrowserHeaders({ Authorization: `Bearer ${token}` });
}

export const fetchCohorts = createAsyncThunk<
  ListForkliftCohortsResponse | null,
    { client: MonitoringClient, req: ListForkliftCohortsRequest },
    { rejectValue: ServiceError }
  >(
    'cohorts/listCohorts',
    async (arg: {client: MonitoringClient, req: ListForkliftCohortsRequest}, { rejectWithValue }) => {
      const data = new Promise<ListForkliftCohortsResponse | null>((resolve, reject) => {
        arg.client.listForkliftCohorts(arg.req, authHeader(), (err, response) => {
          if (err) {
            reject(err)
          } else {
            resolve(response)
          }
        })
      })

      try {
        return await data;
      } catch (err) {
        return rejectWithValue(err as ServiceError)
      }
    }
  );

export interface CohortEntityState {
  entities: NormalizedEntities<ForkliftCohort.AsObject, string>
  loading: 'idle' | 'pending';
  reqId: string | undefined;
  error: ServiceError | undefined;
}

const initialState: CohortEntityState = {
  entities: {
    byId: new Map<string, ForkliftCohort.AsObject>(),
    allIds: [],
  },
  loading: 'idle',
  reqId:  undefined,
  error: undefined,
}

const cohortEntitySlice = createSlice({
  name: 'cohortEntities',
  initialState: initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchCohorts.pending,
      (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.reqId = action.meta.requestId;
        }
      })
    .addCase(fetchCohorts.fulfilled,
      (state, action) => {
        const { requestId } = action.meta;

        if (
          state.loading === 'pending' &&
          state.reqId === requestId
        ) {
          state.loading = 'idle';
          state.reqId = undefined;

          if (action.payload) {
            state.entities = action.payload.getCohortsList()
              .reduce((agg, a, i) => {
                const asObject = a.toObject();

                if (asObject.id) {
                  agg.byId.set(asObject.id, asObject)
                  if (!agg.allIds.includes(asObject.id)) {
                    agg.allIds.push(asObject.id)
                  }
                }

                return agg;
              }, state.entities)
          }
        }
      })
    .addCase(fetchCohorts.rejected,
      (state, action) => {
        const { requestId } = action.meta;

        if (
          state.loading === 'pending' &&
          state.reqId === requestId
        ) {
          state.loading = 'idle';
          state.error = action.payload;
          state.reqId = undefined;
        }
      })
  }
})

export default cohortEntitySlice.reducer;
