import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
 } from '@material-ui/core/styles';
 import {
  Typography,
  Box,
  Paper,
  Tooltip,
  ButtonBase,
  Fade,
} from "@material-ui/core";
import FoxIcon from '../FoxIcon';
import BatteryIcon from '../BatteryIcon';

import * as payloads from "../../redux/payloads";

const styles = (theme: Theme) => createStyles({
  autoAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 12,
  },
  batteryIcon: {
    transform: 'rotate(-0.25turn)',
    color: 'rgba(0, 0, 0, .38)',
    fontSize: 34,
  },
  tabOverflow: {
    overflow: 'hidden',
    width: 140,
    borderLeft: '1px solid rgba(0, 0, 0, 10%)',                            
  },
  tabAngledEdge: {
    height: 20,
    width: 100,
    paddingLeft: 12,
    borderRadius: 0,
    margin: '0 0 0 0px',
    overflow: 'hidden',
    position: 'relative',                            
    WebkitTransform: 'skew(16deg)',
    MozTransformOrigin: 'skew(16deg)',
    OTransformOrigin: 'skew(16deg)',
    borderRight: '1px solid rgba(0, 0, 0, 10%)',
    borderTop: '1px solid rgba(0, 0, 0, 10%)',
  },
  tabAngledEdgeCompensator: {
    position: 'absolute',
    WebkitTransform: 'skew(-16deg)',
    MozTransformOrigin: 'skew(-16deg)',
    OTransformOrigin: 'skew(-16deg)',
  },
  tabInnerContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  tabTypography: {
    marginLeft: 6,
  },
  statusPaper: {
    height: 176,
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 1,
  },
  statusUpperContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
  },
  statusTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 8,
    flexGrow: 1,
  },
  statusLowerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  autoIndicator: {
    marginLeft: 6,
    fontWeight: 'bold',
    fontSize: 18,
  },
  slidingOverlayOpen: {
    position: 'absolute',
    transition: '.5s ease',
    width: '100%',
    height: 176,
    zIndex: 12,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 8,
    paddingTop: 8,
    paddingBottom: 8,
  },
  slidingOverlayClosed: {
    position: 'absolute',
    transition: '.5s ease',
    width: '0%',
    height: 176,
    zIndex: 12,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 8,
    paddingBottom: 8,
  },
  foxIcon: {
    fontSize: 12,
  }
});

type StatusDigest = {
  title: string;
  subtitle: string;
  tabColor: string;
}

interface Props extends WithStyles<typeof styles> {
  robot: payloads.RobotAccount
}

const Component: FunctionComponent<Props> = (props: Props) => {
  const { classes, robot } = props;
  const status = robot.status?.toObject();
  const [ overlayTransitionComplete, setOverlayTransitionComplete ] = useState(false);
  const [ overlayOpen, setOverlayOpen ] = useState(false);

  const getDigest: (robot: payloads.RobotAccount) => StatusDigest = (robot: payloads.RobotAccount) => {
    const status = robot.status?.toObject();

    if (status) {
      if (new Date().valueOf() - robot.lastHeartbeatTime < 300000) {
        // Data is fresh
        if (status.activeRunName) {
          // Status available  
          if (status.goalActive) {
            // Has an active goal
            if (status.interventionRequired) {
              if (!status.autoModeActive) {
                // Manual mode, being handled
                return({
                  title: 'Intervention',
                  subtitle: 'In manual mode',
                  tabColor: '#ffcf44'
                })
              } else {
                // Auto mode, needs assistance
                return({
                  title: 'Intervention',
                  subtitle: 'Assistance Required',
                  tabColor: '#e7847a'
                })
              }
            } else {
              // Healthy run
              return({
                title: 'Active',
                subtitle: `Picking from ${status.pickZoneName}`,
                tabColor: '#98cee3'
              })
            }
          } else {
            if (status.currentSpeed > 0.01) {
              return({
                title: 'Active',
                subtitle: `Moving, awaiting goal`,
                tabColor: '#98cee3'
              })
            } else {
              return({
                title: 'Idle',
                subtitle: `Stationary, awaiting goal`,
                tabColor: '#98cee3'
              })
            }
          }  
        } else {
          // Idle status
          return({
            title: 'Inactive',
            subtitle: `No active run`,
            tabColor: '#272727'
          })
        }
      } else {
        // No status available, nothing to display
        return({
          title: 'Inactive',
          subtitle: `Unknown`,
          tabColor: '#272727'
        })
      }

      } else {
        // Stale data
        return({
          title: 'Inactive',
          subtitle: 'Stale heartbeat',
          tabColor: '#272727'
        })
      }

      
  }

  const { tabColor, subtitle, title } = getDigest(robot);
  const extendedData = [
    `Run: ${status?.activeRunName || ''}`,
    `Fault: ${status?.primaryFaultCode || ''}`,
    `Picking: ${status?.pickZoneName || ''}`,
    `Placing: ${status?.placeZoneName || ''}`,
    `Battery: ${status?.batteryDischargeState || 0}%`,
    `Speed: ${status?.currentSpeed || 0}`,
    `E Stop: ${status?.estopEngaged || false}`,
    `Auto Mode: ${status?.autoModeActive || false}`,
  ]

  return(
    <Box>
      <Box
        style={title === 'Inactive' ? {opacity: .5, position: 'relative'} : {position: 'relative'}}
        onMouseEnter={() => setOverlayOpen(true)}
        onMouseLeave={() => setOverlayOpen(false)}        
      >
        <Box className={classes.tabOverflow}>
          <Box className={classes.tabAngledEdge}
          >
            <Box className={classes.tabAngledEdgeCompensator}>
              <Box className={classes.tabInnerContainer}>
                <FoxIcon className={classes.foxIcon}/>
                <Typography variant={'caption'} className={classes.tabTypography}>
                  {robot.id}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Paper
          className={overlayOpen ? classes.slidingOverlayOpen : classes.slidingOverlayClosed }
          onTransitionEnd={() => {
            overlayOpen ? setOverlayTransitionComplete(true) : setOverlayTransitionComplete(false)
          }}
        >
          {
            extendedData.map((data, i) => {               
              return(
                <Fade in={overlayTransitionComplete && overlayOpen} timeout={i * 150} key={i}>
                  <Typography variant={'caption'} noWrap>
                    {data}
                  </Typography>
                </Fade>
              )
            })
          }

        </Paper>
        <Paper
          className={classes.statusPaper}
        >
          <Box className={classes.statusUpperContainer}>
            <Box className={classes.statusTextContainer}>
              <Typography variant={'h5'}>
                {title}
              </Typography>

              <Typography variant={'caption'}>
                {subtitle}
              </Typography>
            </Box>
            <Box style={{width: 21.12, height: 32, backgroundColor: tabColor}}/>
          </Box>

          <Box flexGrow={1}/>

          <Box className={classes.statusLowerContainer}>
            <Tooltip title={status ? status.autoModeActive ? 'Auto' : 'Manual' : 'Manual'}>
              <ButtonBase>
                <Typography
                variant={'overline'}
                className={classes.autoIndicator}
                style={
                  status ? status.autoModeActive ? {color: '#69cb99'} : {} : {}
                }
              >
                {status ? status.autoModeActive ? 'A' : 'M' : 'M'}
              </Typography>
              </ButtonBase>
            </Tooltip>


            <Box flexGrow={1}/>
            <Tooltip title={status ? `${status.batteryDischargeState}%` : '0%'}>
              <ButtonBase>
                <BatteryIcon className={classes.batteryIcon} level={status ? status.batteryDischargeState / 100 : 0}/>
              </ButtonBase>
            </Tooltip>
            
          </Box>
        </Paper>
      </Box>
    </Box>    
  )
}

export default withStyles(styles)(Component);
