import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as payloads from "../payloads";
import * as actions from "../actions";
import { ActionTypes } from "../actions";
import { MonitoringClient, ServiceError } from "../../_proto/command_control/monitoring/proto/monitoring_pb_service";
import { ListRobotAccountsRequest, ListRobotAccountsResponse, RobotAccount } from "../../_proto/command_control/monitoring/proto/monitoring_pb";
import { BrowserHeaders } from "browser-headers";
import { NormalizedEntities, upsertEntity } from '../normalizedEntities'
import {authHeader} from "../sagas";

const authTokenName = "monitoringJwtToken";


export const fetchRobotAccounts = createAsyncThunk<
  ListRobotAccountsResponse | null,
  { client: MonitoringClient, req: ListRobotAccountsRequest },
  { rejectValue: ServiceError }
>(
  'robots/listRobots',
  async (arg: {client: MonitoringClient, req: ListRobotAccountsRequest}, { rejectWithValue }) => {
    const robotAccounts = new Promise<ListRobotAccountsResponse | null>((resolve, reject) => {
      arg.client.listRobotAccounts(arg.req, authHeader(), (err, response) => {
        if (err) {
          reject(err)
        } else {
          resolve(response)
        }
      })
    })

    try {
      return await robotAccounts;
    } catch (err) {
      return rejectWithValue(err as ServiceError)
    }
  }
);

export interface EntityState {
  runMetadatas: NormalizedEntities<payloads.RunMetadata, string>;
  accounts: NormalizedEntities<payloads.Account, string>;
  robotAccounts: NormalizedEntities<payloads.RobotAccount, string>;
  robotAccountsLoading: 'idle' | 'pending';
  robotAccountsReqId: string | undefined;
  robotAccountsErr: ServiceError | undefined;
}

const initialState: EntityState = {
  runMetadatas: {
    byId: new Map<string, payloads.RunMetadata>(),
    allIds: []
  },
  accounts: {
    byId: new Map<string, payloads.Account>(),
    allIds: []
  },
  robotAccounts: {
    byId: new Map<string, payloads.RobotAccount>(),
    allIds: []
  },
  robotAccountsLoading: 'idle',
  robotAccountsReqId: undefined,
  robotAccountsErr: undefined,
}

const entitySlice = createSlice({
  name: 'entities',
  initialState: initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(ActionTypes.LIST_ACCOUNTS_RECEIVE,
        (state, action: actions.ListAccountsReceiveTypeDef) => {
          action.payload.accounts.forEach((a) => {
            state.accounts = upsertEntity(payloads.Account.fromProto(a), {
              byId: new Map(state.accounts.byId),
              allIds: state.accounts.allIds,
            })
          })
        })
      .addCase(ActionTypes.LIST_ROBOT_ACCOUNTS_RECEIVE,
        (state, action: actions.ListRobotAccountsReceiveTypeDef) => {
          action.payload.accounts.forEach((a) => {
            state.robotAccounts = upsertEntity(a, {
              byId: new Map(state.robotAccounts.byId),
              allIds: state.robotAccounts.allIds,
            })
          })
        })
      .addCase(ActionTypes.UPDATE_ROBOT_ACCOUNT_COHORT_RECEIVE,
        (state, action: actions.UpdateRobotAccountCohortReceiveTypeDef) => {
          state.robotAccounts = upsertEntity(payloads.RobotAccount.fromProto(action.payload.response), {
            byId: new Map(state.robotAccounts.byId),
            allIds: state.robotAccounts.allIds,
          })
        })
      .addCase(ActionTypes.UPDATE_ROBOT_ACCOUNT_RECEIVE,
        (state, action: actions.UpdateRobotAccountReceiveTypeDef) => {
          state.robotAccounts = upsertEntity(payloads.RobotAccount.fromProto(action.payload.response), {
            byId: new Map(state.robotAccounts.byId),
            allIds: state.robotAccounts.allIds,
          })
        })
      .addCase(ActionTypes.LIST_RUN_METADATA_RECEIVE,
        (state, action: actions.ListRunMetadatasReceiveTypeDef) => {
          action.payload.metadatas.forEach((m) => {
            state.runMetadatas = upsertEntity(m, {
              byId: new Map(state.runMetadatas.byId),
              allIds: state.runMetadatas.allIds,
            })
          })
        })
      .addCase(ActionTypes.RUN_METADATA_RECEIVE,
        (state, action: actions.GetRunMetadatasReceiveTypeDef) => {
          state.runMetadatas = upsertEntity(action.payload.run, {
            byId: new Map(state.runMetadatas.byId),
            allIds: state.runMetadatas.allIds,
          })
        })
      .addCase(ActionTypes.UPSERT_RUN_REVIEW_RECEIVE,
        (state, action: actions.UpsertRunReviewReceiveTypeDef) => {
          state.runMetadatas = upsertEntity(payloads.RunMetadata.fromProto(action.payload.run), {
            byId: new Map(state.runMetadatas.byId),
            allIds: state.runMetadatas.allIds,
          })
        })
      .addCase(ActionTypes.RUN_ATTRIBUTES_UPDATE_RECEIVE,
        (state, action: actions.UpdateRunAttributeReceiveTypeDef) => {
          state.runMetadatas = upsertEntity(action.payload.run, {
            byId: new Map(state.runMetadatas.byId),
            allIds: state.runMetadatas.allIds,
          })
        })
      .addCase(ActionTypes.RUN_ATTRIBUTES_DELETE_RECEIVE,
        (state, action: actions.DeleteRunAttributeReceiveTypeDef) => {
          state.runMetadatas = upsertEntity(action.payload.run, {
            byId: new Map(state.runMetadatas.byId),
            allIds: state.runMetadatas.allIds,
          })
        })
      .addCase(fetchRobotAccounts.pending,
        (state, action) => {
          if (state.robotAccountsLoading === 'idle') {
            state.robotAccountsLoading = 'pending';
            state.robotAccountsReqId = action.meta.requestId;
          }
        })
      .addCase(fetchRobotAccounts.fulfilled,
        (state, action) => {
          const { requestId } = action.meta;

          if (
            state.robotAccountsLoading === 'pending' &&
            state.robotAccountsReqId === requestId
          ) {
            state.robotAccountsLoading = 'idle';
            state.robotAccountsReqId = undefined;

            if (action.payload) {
              state.robotAccounts = action.payload.getRobotAccountsList()
                .reduce((agg, a, i) => {
                  const robotAccount = payloads.RobotAccount.fromProto(a);

                  if (robotAccount.id) {
                    agg.byId.set(robotAccount.id, robotAccount)
                    if (!agg.allIds.includes(robotAccount.id)) {
                      agg.allIds.push(robotAccount.id)
                    }
                  }

                  return agg;
                }, state.robotAccounts)
            }
          }
        })
      .addCase(fetchRobotAccounts.rejected,
        (state, action) => {
          const { requestId } = action.meta;

          if (
            state.robotAccountsLoading === 'pending' &&
            state.robotAccountsReqId === requestId
          ) {
            state.robotAccountsLoading = 'idle';
            state.robotAccountsErr = action.payload;
            state.robotAccountsReqId = undefined;
          }
        })
  }
})

export default entitySlice.reducer;