import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as payloads from "../payloads";
import * as actions from "../actions";
import { ActionTypes } from "../actions";

export interface ViewerState {
  // waitingForLoad is true when the page is first loading and waiting for a
  // response from the server to get the current session's user, if there is
  // one. Pages should not perform auth redirects because of a null account when
  // waitingForLoad is true.

  waitingForLoad: boolean;
  account: payloads.Account | null;
}

const initialState: ViewerState = {
  account: null,
  waitingForLoad: true
}

const viewerSlice = createSlice({
  name: 'viewer',
  initialState: initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(ActionTypes.SIGN_UP_RECEIVE,
        (state, action: actions.SignUpReceiveTypeDef) => {
          state.account = action.payload.account;
          state.waitingForLoad = false;
        })
      .addCase(ActionTypes.LOG_IN_RECEIVE,
        (state, action: actions.LogInReceiveTypeDef) => {
          state.account = action.payload.account;
          state.waitingForLoad = false;
        })
      .addCase(ActionTypes.LOG_OUT_RECEIVE,
        (state, action) => {
          state.account = null;
        })
      .addCase(ActionTypes.VIEWER_RECEIVE,
        (state, action: actions.ViewerReceiveTypeDef) => {
          state.account = action.payload.account;
          state.waitingForLoad = false;
        })
  }
});

export default viewerSlice.reducer;
